import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      gap: theme.spacing(1),
    },
    title: {
      ...theme.typography.medium16,
    },
    detectedBrandTerm: {
      width: '100%',
    },
  }),
  { name: 'BrandTermsItem' }
);
