import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      gap: theme.spacing(0.5),

      '& > button': {
        minWidth: 0,

        '&:hover': {
          boxShadow: 'unset',
        },
      },
    },

    button: {
      borderRadius: 4,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
    buttonCancel: {
      color: COLORS.primary.default,
    },
  }),
  { name: 'CopyInputActions' }
);
