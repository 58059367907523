import { useEffect } from 'react';

import { TALKING_POINTS_VIEW_STATUS } from 'src/data/performance';
import { createStateSelectorHook } from 'src/lib/sweetState';
import {
  useTalkingPointsViewsActions,
  useWorkspaceActions,
  useCurrentWorkspaceId,
  TalkingPointsViewsStore,
  useCurrentCustomerId,
  useWorkspaceAssociatedTalkingPointsViewsIds,
} from 'src/store';

/**
 * This hook is used to maintain the "talking points views" store state.
 */
export const useMaintainTalkingPointsViewsStoreState = () => {
  useQueryTalkingPointsViews();
  usePollCreatedTalkingPointsViews();
};

/**
 * Query the talking points views for the current workspace
 */
const useQueryTalkingPointsViews = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const currentCustomerId = useCurrentCustomerId();
  const talkingPointsViewsActions = useTalkingPointsViewsActions();

  useEffect(() => {
    if (!currentCustomerId || !currentWorkspaceId) {
      return;
    }

    // Query the talking points views for the current workspace
    talkingPointsViewsActions.queryWorkspaceTalkingPointsViewList(
      currentCustomerId,
      currentWorkspaceId
    );

    // Clear the store when the workspace changes
    return () => {
      talkingPointsViewsActions.clearStore();
    };
  }, [currentCustomerId, currentWorkspaceId, talkingPointsViewsActions]);
};

/**
 * If we have any associated TP views in "created" status, we need to poll their status
 */
const usePollCreatedTalkingPointsViews = () => {
  const { loadedTalkingPointsViews } = useTalkingPointsState();
  const associatedTalkingPointsViewsIds = useWorkspaceAssociatedTalkingPointsViewsIds();
  const workspaceActions = useWorkspaceActions();
  const talkingPointsViewsActions = useTalkingPointsViewsActions();

  useEffect(() => {
    if (!loadedTalkingPointsViews) {
      return;
    }

    const abortController = new AbortController();

    const talkingPointsViews = talkingPointsViewsActions.getTalkingPointsViews();
    const associatedNewViews = talkingPointsViews.filter(
      (item) =>
        item.status === TALKING_POINTS_VIEW_STATUS.created &&
        associatedTalkingPointsViewsIds.includes(item.id)
    );
    associatedNewViews.forEach((item) => {
      talkingPointsViewsActions
        .waitForTalkingPointViewCreation(item.id, {
          abortSignal: abortController.signal,
        })
        .catch(() => {
          // Do nothing
        });
    });

    return () => {
      abortController.abort();
    };
  }, [
    associatedTalkingPointsViewsIds,
    loadedTalkingPointsViews,
    talkingPointsViewsActions,
    workspaceActions,
  ]);
};

const useTalkingPointsState = createStateSelectorHook(TalkingPointsViewsStore, [
  'loadedTalkingPointsViews',
]);
