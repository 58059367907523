import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';

import { useStyles } from './useStyles';

/**
 * CustomTooltip size
 * @enum {string}
 */
export const CUSTOM_TOOLTIP_SIZE = {
  default: 'default',
  small: 'small',
};

export const CustomTooltip = ({ classes, size, fitContent, children, ...restProps }) => {
  const styles = useStyles({ classes });
  return (
    <Tooltip
      classes={{
        tooltip: cx(styles.tooltip, {
          [styles.sizeSmall]: size === CUSTOM_TOOLTIP_SIZE.small,
          [styles.fitContent]: fitContent,
        }),
      }}
      arrow
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.displayName = 'CustomTooltip';
CustomTooltip.propTypes = {
  classes: PropTypes.shape(),
  size: PropTypes.oneOf(Object.values(CUSTOM_TOOLTIP_SIZE)),
  fitContent: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
CustomTooltip.defaultProps = {
  classes: null,
  fitContent: false,
  size: CUSTOM_TOOLTIP_SIZE.default,
};
