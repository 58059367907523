/* eslint-disable no-param-reassign */
import { Transforms, Element as SlateElement, Node as SlateNode } from 'slate';

import { SLATE_TEXT_FORMATS } from 'src/lib/slate';

export const withParagraphs = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // If the element is a paragraph, ensure its children are valid.
    if (SlateElement.isElement(node) && node.type === SLATE_TEXT_FORMATS.PARAGRAPH) {
      Array.from(SlateNode.children(editor, path)).forEach(([child, childPath]) => {
        if (SlateElement.isElement(child) && !editor.isInline(child)) {
          Transforms.unwrapNodes(editor, { at: childPath });
        }
      });
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  return editor;
};
