import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconMale = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 24 24"
      data-fill-none
    >
      <g transform="translate(0.5, 0)">
        <path
          d="M14.25 17.49V21.125C14.25 21.3902 14.1446 21.6446 13.9571 21.8321C13.7696 22.0196 13.5152 22.125 13.25 22.125H9.75C9.48478 22.125 9.23043 22.0196 9.04289 21.8321C8.85536 21.6446 8.75 21.3902 8.75 21.125V17.49"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9759 23.1251C20.1268 21.3303 18.7854 19.8135 17.1078 18.7513C15.4302 17.6891 13.4855 17.1252 11.4999 17.1252C9.51436 17.1252 7.56963 17.6891 5.89206 18.7513C4.21449 19.8135 2.87307 21.3303 2.02393 23.1251"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 10.125C5.5 11.5837 6.07946 12.9826 7.11091 14.0141C8.14236 15.0455 9.54131 15.625 11 15.625C12.4587 15.625 13.8576 15.0455 14.8891 14.0141C15.9205 12.9826 16.5 11.5837 16.5 10.125C16.5 8.66631 15.9205 7.26736 14.8891 6.23591C13.8576 5.20446 12.4587 4.625 11 4.625C9.54131 4.625 8.14236 5.20446 7.11091 6.23591C6.07946 7.26736 5.5 8.66631 5.5 10.125Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.26074 7.33203C7.11227 8.21555 8.13323 8.91827 9.26257 9.39816C10.3919 9.87804 11.6064 10.1252 12.8335 10.125C14.0764 10.1255 15.3063 9.87201 16.4478 9.38013"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  ))
);
