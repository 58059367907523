import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { pluralize } from 'src/utils/format';
import { formatBrandVoicesNames } from 'src/data/brandTerm';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import NotificationSuccess from 'src/assets/icons/NotificationSuccess';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  activeBrandVoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSettingsClick: PropTypes.func.isRequired,
};

const defaultProps = {
  className: null,
};

export const SuccessMessage = ({ className, activeBrandVoices, onSettingsClick }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)}>
      <NotificationSuccess className={styles.icon} />
      <CustomTypography
        className={styles.description}
        variant={TYPOGRAPHY_VARIANT.paragraph14}
      >
        This text complies with{' '}
        <button
          className={styles.brandVoicesNames}
          onClick={onSettingsClick}
        >
          {formatBrandVoicesNames(activeBrandVoices)}
        </button>{' '}
        brand {pluralize(activeBrandVoices.length, 'vocabulary', 'vocabularies')}
      </CustomTypography>
    </div>
  );
};

SuccessMessage.propTypes = propTypes;
SuccessMessage.defaultProps = defaultProps;
