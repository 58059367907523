import React from 'react';
import PropTypes from 'prop-types';

import { ROUTES } from 'src/routes';
import { formatRoute } from 'src/lib/routing';
import { SUBSCRIPTION_PLAN } from 'src/data/subscription';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';

export const Upgrade = ({ onBenchmarkUpgradeClick, projectId, variationId }) => {
  const styles = useStyles();

  const handleUpgradeClick = () => {
    onBenchmarkUpgradeClick();
    window.open(
      formatRoute(ROUTES.CHECKOUT, {
        plan: SUBSCRIPTION_PLAN.business_self_serve,
        projectId,
        variationId,
      })
    );
  };

  return (
    <div className={styles.root}>
      <CustomTypography
        className={styles.upgradeText}
        variant={TYPOGRAPHY_VARIANT.paragraph12}
      >
        The text is only compared to 50 copy assets that are supported by your starter subscription.
        To benchmark this text against all of your data,{' '}
        <span onClick={handleUpgradeClick}>upgrade to the Business tier.</span>
      </CustomTypography>
    </div>
  );
};

Upgrade.propTypes = {
  onBenchmarkUpgradeClick: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  variationId: PropTypes.string,
};

Upgrade.defaultProps = {
  projectId: null,
  variationId: null,
};
