/* eslint-disable no-param-reassign */
import { isValidUrl } from 'src/lib/string';
import { SLATE_TEXT_FORMATS } from 'src/lib/slate';
import { wrapLink } from '../utils';

export const withInlines = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return (
      element.type === SLATE_TEXT_FORMATS.LINK ||
      element.type === SLATE_TEXT_FORMATS.BANK_MESSAGE ||
      element.type === SLATE_TEXT_FORMATS.PRODUCT_DESCRIPTION ||
      element.type === SLATE_TEXT_FORMATS.RESOURCE_URL ||
      element.type === SLATE_TEXT_FORMATS.PROMPT_TAG ||
      element.type === SLATE_TEXT_FORMATS.PROMPT_IMAGE ||
      element.type === SLATE_TEXT_FORMATS.PROMPT_DOCUMENT ||
      isInline(element)
    );
  };

  editor.insertText = (text) => {
    if (text && isValidUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isValidUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
