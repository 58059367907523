import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_PROJECT_FOLDER_FOR_SEARCH } from 'src/graphql/projectFolder';
import { FRAGMENT_PROJECT_FOR_SEARCH } from './schema';

const QUERY = gql`
  query searchProjectAndProjectFolders($workspaceId: ID!, $term: String!) {
    searchProjectAndProjectFolders(term: $term, workspaceId: $workspaceId) {
      ...ProjectForSearch
      ...ProjectFolderForSearch
    }
  }
  ${FRAGMENT_PROJECT_FOR_SEARCH}
  ${FRAGMENT_PROJECT_FOLDER_FOR_SEARCH}
`;

export const searchProjectAndProjectFolders = (workspaceId, term) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      workspaceId,
      term,
    },
  }).then(({ data }) => getSingleProp(data));
};
