import { useCallback, useMemo } from 'react';

import * as events from 'src/lib/events';
import { useTrackingEvent } from 'src/services/useTrackingEvent';

export const useTrackingEvents = () => {
  const trackingEvent = useTrackingEvent();

  const trackCtaClick = useCallback(
    (notification) => {
      trackingEvent(events.NOTIFICATIONS.clickedCtaButton, {
        notificationId: notification?.id,
        notificationTitle: notification?.title,
      });
    },
    [trackingEvent]
  );

  const trackCloseClick = useCallback(
    (notification) => {
      trackingEvent(events.NOTIFICATIONS.clickedClose, {
        notificationId: notification?.id,
        notificationTitle: notification?.title,
      });
    },
    [trackingEvent]
  );

  return useMemo(
    () => ({
      trackCtaClick,
      trackCloseClick,
    }),
    [trackCtaClick, trackCloseClick]
  );
};
