import { createStore } from 'react-sweet-state';

import { APPLICATION_BAR_HEIGHT, TEXT_DETAILS_PANEL_TAB } from 'src/data/ui';
import { getQueryParams } from 'src/lib/browser';
import * as ls from 'src/lib/localData';

const INITIAL_STATE = {
  appbarHeight: APPLICATION_BAR_HEIGHT,
  fullNavbarHeight: APPLICATION_BAR_HEIGHT,
  measuredNotificationsHeight: 0,
  notificationsHeight: 0,

  appbarVisible: true,
  zendeskWidgetVisible: true,

  welcomePopupWasShown: false,
  globalPopupVisibleCount: 0,
  localPopupVisibleCount: 0,

  showWebinarBanner: false,
  textDetailsPanelTab: TEXT_DETAILS_PANEL_TAB.performance,
};

const calculateAppbarHeight =
  () =>
  ({ getState, setState }) => {
    const { measuredNotificationsHeight } = getState();

    const notificationsHeight = measuredNotificationsHeight;
    const fullNavbarHeight = APPLICATION_BAR_HEIGHT + notificationsHeight;

    setState({ fullNavbarHeight, notificationsHeight });
  };

export const ACTIONS = {
  init:
    () =>
    ({ setState }) => {
      const params = getQueryParams();

      setState({
        appbarVisible: params['appbar-visible'] !== 'false',
        zendeskWidgetVisible: params['zendesk-widget-visible'] !== 'false',
        textDetailsPanelTab: TEXT_DETAILS_PANEL_TAB.clamp(ls.textDetailsPanelTab.read()),
      });
    },

  setNotificationsHeight:
    (height) =>
    ({ setState, dispatch }) => {
      setState({ measuredNotificationsHeight: height });
      dispatch(calculateAppbarHeight());
    },

  markWelcomePopupAsShown:
    () =>
    ({ setState }) => {
      setState({ welcomePopupWasShown: true });
    },

  wasWelcomePopupShown:
    () =>
    ({ getState }) => {
      return getState().welcomePopupWasShown;
    },

  setShowWebinarBanner:
    (showWebinarBanner) =>
    ({ setState }) => {
      setState({ showWebinarBanner });
    },

  displayZendeskWidget:
    () =>
    ({ setState }) => {
      setState({ zendeskWidgetVisible: true });
    },

  hideZendeskWidget:
    () =>
    ({ setState }) => {
      setState({ zendeskWidgetVisible: false });
    },

  setGlobalPopupVisibleCount:
    (value) =>
    ({ setState }) => {
      setState({ globalPopupVisibleCount: value });
    },
  increaseLocalPopupVisibleCount:
    () =>
    ({ getState, setState }) => {
      const { localPopupVisibleCount } = getState();
      setState({ localPopupVisibleCount: localPopupVisibleCount + 1 });
    },
  decreaseLocalPopupVisibleCount:
    () =>
    ({ getState, setState }) => {
      const { localPopupVisibleCount } = getState();
      setState({ localPopupVisibleCount: Math.max(localPopupVisibleCount - 1, 0) });
    },

  setTextDetailsPanelTab:
    (newTab) =>
    ({ setState }) => {
      setState({ textDetailsPanelTab: newTab });
      // Store selected tab for future sessions
      ls.textDetailsPanelTab.write(newTab);
    },
  getTextDetailsPanelTab:
    () =>
    ({ getState }) => {
      return getState().textDetailsPanelTab;
    },
};

export const UiStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'global-ui',
});
