import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconChannelLinkedin = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 20 20"
        data-fill-none
      >
        <path
          d="M5.77188 17H2.53332V6.82259H5.77188V17ZM12.4956 9.81365C12.1173 9.81365 11.7546 9.96219 11.4872 10.2266C11.2198 10.491 11.0695 10.8496 11.0695 11.2235V17H7.66437V6.82259H11.0695V7.87012C11.8676 7.20211 12.876 6.82943 13.9216 6.816C16.034 6.816 17.5 8.36424 17.5 11.3026V17H13.9216V11.2235C13.9225 11.0378 13.8862 10.8538 13.815 10.682C13.7437 10.5102 13.6388 10.3539 13.5063 10.2223C13.3738 10.0907 13.2163 9.98627 13.0428 9.91501C12.8694 9.84374 12.6834 9.80706 12.4956 9.80706V9.81365ZM5.83185 3.64706C5.83185 3.97282 5.73415 4.29126 5.55109 4.56212C5.36804 4.83297 5.10786 5.04408 4.80345 5.16874C4.49904 5.2934 4.16408 5.32602 3.84092 5.26247C3.51776 5.19892 3.22092 5.04205 2.98794 4.81171C2.75496 4.58136 2.59629 4.28788 2.53201 3.96838C2.46773 3.64889 2.50072 3.31772 2.62681 3.01676C2.7529 2.7158 2.96643 2.45856 3.24039 2.27758C3.51435 2.0966 3.83644 2 4.16593 2C4.60544 2.00348 5.02576 2.17854 5.33531 2.48705C5.64485 2.79555 5.81854 3.21251 5.81853 3.64706H5.83185Z"
          fill="#0A66C2"
          stroke="#0A66C2"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
