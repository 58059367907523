import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent } from '@material-ui/core';

import { CustomDialog } from 'src/components/CustomDialog';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { getHeroImageComponent, prepareContentSentences } from './utils';
import { useStyles } from './useStyles';

export const GenericNotificationPopup = ({ open, notification, onClose, onButtonClick }) => {
  const styles = useStyles();

  const ImageComponent = getHeroImageComponent(notification.type);
  const sentences = prepareContentSentences(notification.message);

  return (
    <CustomDialog
      open={open}
      className={styles.root}
      classes={{
        dialogActions: styles.dialogActions,
      }}
      onClose={onClose}
      data-testid="GenericNotificationPopup"
      hideActions={!notification.linkText}
      submitLabel={notification.linkText}
      onSubmit={onButtonClick}
      displayCloseIcon
    >
      <DialogContent className={styles.dialogContent}>
        {ImageComponent}

        <CustomTypography
          className={styles.title}
          variant={TYPOGRAPHY_VARIANT.h2}
        >
          {notification.title}
        </CustomTypography>

        {sentences.map((sentence) => (
          <CustomTypography
            key={sentence}
            className={styles.message}
            variant={TYPOGRAPHY_VARIANT.regular16}
          >
            {sentence}
          </CustomTypography>
        ))}
      </DialogContent>
    </CustomDialog>
  );
};

GenericNotificationPopup.propTypes = {
  open: PropTypes.bool,
  notification: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};
GenericNotificationPopup.defaultProps = {
  open: false,
};
