import { trim } from 'lodash';

import { hashString } from 'src/lib/string';
import { PROJECT_TYPE } from 'src/data/project/const';
import { GENDER_TONE } from 'src/data/demographics';
import { alignLanguage } from './language';
import { LANGUAGE } from './const';

const LINE_DELIMITER = '\n';
const VALUE_DELIMITER = ',';

const DEFAULT_RECOMPOSE_PARAMS = {
  genderTone: GENDER_TONE.neutral,
};

const prepareStringValue = (str) => trim(str || '');
const prepareArrayValue = (arr) => {
  const aligned = arr || [];
  return prepareStringValue(aligned.join(VALUE_DELIMITER));
};

const getCommonParamsFromRequest = (request) => ({
  briefTitle: request.briefTitle,
  briefBody: request.briefBody,
  keywords: request.keywords,
  language: alignLanguage(request.language) || LANGUAGE.DEFAULT,
  toneOfVoice: request.tovId,
});

const getCanvasParamsFromRequest = (request) => ({
  ...getCommonParamsFromRequest(request),
  assetType: request.assetType,
});
const serializeCanvasParams = (params) => {
  const list = [
    `briefTitle=${prepareStringValue(params.briefTitle)}`,
    `briefBody=${prepareStringValue(params.briefBody)}`,
    `keywords=${prepareStringValue(params.keywords)}`,
    `language=${prepareStringValue(params.language)}`,
    `assetType=${prepareStringValue(params.assetType)}`,
  ];
  return list.join(LINE_DELIMITER);
};

const getSuggestParamsFromRequest = (request) => ({
  ...getCanvasParamsFromRequest(request),
  suggestionMode: request.suggestionMode,
  inputText: request.inputText,
  suggestionAnnotationType: request.suggestionAnnotationType,
  suggestionAnnotationContent: request.suggestionAnnotationContent,
  suggestionAnnotationMinAge: request.suggestionAnnotationMinAge,
  suggestionAnnotationMaxAge: request.suggestionAnnotationMaxAge,
  link: request.link,
});
const serializeSuggestParams = (params) => {
  const list = [
    `suggestionMode=${prepareStringValue(params.suggestionMode)}`,
    `inputText=${prepareStringValue(params.inputText)}`,
    `toneOfVoice=${prepareStringValue(params.toneOfVoice)}`,
    `suggestionAnnotationType=${prepareStringValue(params.suggestionAnnotationType)}`,
    `suggestionAnnotationContent=${prepareStringValue(params.suggestionAnnotationContent)}`,
    `suggestionAnnotationMinAge=${prepareStringValue(params.suggestionAnnotationMinAge)}`,
    `suggestionAnnotationMaxAge=${prepareStringValue(params.suggestionAnnotationMaxAge)}`,
    `link=${prepareStringValue(params.link)}`,
    `keywords=${prepareStringValue(params.keywords)}`,
    `language=${prepareStringValue(params.language)}`,
  ];
  return list.join(LINE_DELIMITER);
};

const getRecomposeParamsFromRequest = (request) => ({
  ...getCommonParamsFromRequest(request),
  recomposeMode: request.recomposeMode,
  inputTexts: request.inputTexts,
  genderTone: request.genderTone,
  agePreset: request.agePreset,
});
const serializeRecomposeParams = (params) => {
  const alignedParams = {
    ...DEFAULT_RECOMPOSE_PARAMS,
    ...params,
  };
  const list = [
    `inputTexts=${prepareArrayValue(alignedParams.inputTexts)}`,
    `recomposeMode=${prepareStringValue(alignedParams.recomposeMode)}`,
    `toneOfVoice=${prepareStringValue(alignedParams.toneOfVoice)}`,
    `genderTone=${prepareStringValue(alignedParams.genderTone)}`,
    `agePreset=${prepareStringValue(alignedParams.agePreset)}`,
    `language=${prepareStringValue(alignedParams.language)}`,
  ];
  return list.join(LINE_DELIMITER);
};

const serializeRequestParams = (projectType, request) => {
  if (projectType === PROJECT_TYPE.canvas) {
    const params = getCanvasParamsFromRequest(request);
    return serializeCanvasParams(params);
  }

  if (request.suggestionMode) {
    const params = getSuggestParamsFromRequest(request);
    return serializeSuggestParams(params);
  }

  const params = getRecomposeParamsFromRequest(request);
  return serializeRecomposeParams(params);
};

export const hashGenerationRequestParams = (projectType, request) => {
  const serialized = serializeRequestParams(projectType, request);
  return hashString(serialized);
};

export const hashCanvasGenerationParams = (params) => {
  const serialized = serializeCanvasParams(params);
  return hashString(serialized);
};

export const hashSuggestGenerationParams = (params) => {
  const serialized = serializeSuggestParams(params);
  return hashString(serialized);
};

export const hashRecomposeGenerationParams = (params) => {
  const serialized = serializeRecomposeParams(params);
  return hashString(serialized);
};
