import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ButtonText } from 'src/components/ButtonText';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  availableBrandTermsCount: PropTypes.number.isRequired,
  activeBrandVoiceCount: PropTypes.number.isRequired,
  onCtaClick: PropTypes.func.isRequired,
};

const defaultProps = {
  className: null,
};

export const EmptyTermsBanner = ({
  className,
  availableBrandTermsCount,
  activeBrandVoiceCount,
  onCtaClick,
}) => {
  const styles = useStyles();

  const { title, description, cta } = (() => {
    if (availableBrandTermsCount > 0 && activeBrandVoiceCount) {
      return {
        title: 'Brand vocabulary is disabled.',
        cta: 'Enable brand vocabulary',
      };
    } else if (availableBrandTermsCount === 0) {
      return {
        title: 'No brand vocabulary exists',
        description:
          'Set clear guidelines by flagging terms you would like Anyword to\nuse and terms you would like Anyword to refrain from using.',
        cta: 'Create brand vocabulary',
      };
    }
    return {};
  })();

  return (
    <div className={cx(styles.root, className)}>
      <CustomTypography
        className={styles.title}
        variant={TYPOGRAPHY_VARIANT.medium12}
      >
        {title}
      </CustomTypography>

      {description && (
        <CustomTypography
          className={styles.description}
          variant={TYPOGRAPHY_VARIANT.paragraph12}
        >
          {description}
        </CustomTypography>
      )}

      {cta && onCtaClick && (
        <ButtonText
          className={styles.buttonCta}
          onClick={onCtaClick}
        >
          {cta}
        </ButtonText>
      )}
    </div>
  );
};

EmptyTermsBanner.propTypes = propTypes;
EmptyTermsBanner.defaultProps = defaultProps;
