import { orderBy, pick } from 'lodash';

import { getAgeGroupLabel, getGenderLabel, GENDER } from 'src/data/demographics';

export const AVATAR_TYPE = {
  avatarOne: 'avatar_one',
  avatarTwo: 'avatar_two',
  avatarThree: 'avatar_three',
  avatarFour: 'avatar_four',
  avatarFive: 'avatar_five',
  avatarSix: 'avatar_six',
  avatarSeven: 'avatar_seven',
  avatarEight: 'avatar_eight',
  avatarNine: 'avatar_nine',
  avatarTen: 'avatar_ten',
};

export const CUSTOMER_PERSONA_EMPTY_LABEL = 'Broad Audience';

export const EMPTY_PERSONA = {
  id: null,
  name: CUSTOMER_PERSONA_EMPTY_LABEL,
};

export const formatPersonaDescription = (persona) => {
  const { minAge, maxAge, gender } = persona || {};

  const lines = [];
  if (minAge || maxAge) {
    lines.push(`${minAge} - ${getAgeGroupLabel(maxAge)}`);
  }
  if (gender && gender !== GENDER.doesntMatter) {
    lines.push(getGenderLabel(gender));
  }
  return lines.join(', ');
};

export const mapPersonaForBackendInput = (persona) => {
  if (persona?.id) {
    return pick(persona, ['name', 'gender', 'minAge', 'maxAge', 'painPoints']);
  }
  return null;
};

export const sortCustomerPersonas = (list) => orderBy(list, ['name'], ['asc']);
