import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_CO_ASSET } from 'src/graphql/continuousOptimization';
import { get } from 'lodash';

const QUERY = gql`
  query AudienceSegmentAssets($projectId: Int!, $segmentId: ID) {
    project(id: $projectId) {
      id

      continuousOptimizationSegment(id: $segmentId) {
        landingPageAssets {
          ...LandingPageAsset
        }
        keywords
      }
    }
  }
  ${FRAGMENT_CO_ASSET}
`;

export const queryAudienceSegmentAssets = async ({ projectId, segmentId }) => {
  const response = await executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
      segmentId,
    },
  });

  const data = getSingleProp(response.data);
  const assets = get(data, 'continuousOptimizationSegment[0].landingPageAssets', []);
  const keywords = get(data, 'continuousOptimizationSegment[0].keywords', []);
  return { assets, keywords };
};
