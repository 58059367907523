import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 541,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      color: COLORS.gray.black,
    },
    dialogContent: {
      padding: theme.spacing(3, 4, 4),
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(2),
      padding: theme.spacing(2, 0),
    },
    itemTitle: {
      color: COLORS.gray.black,
    },
    itemDescription: {
      color: COLORS.gray.gray30,
    },
    contentTitle: {
      color: COLORS.gray.black,
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'AccountsDataExtractionPopup' }
);
