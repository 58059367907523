import { useCallback, useState } from 'react';

export const useMenuOpenedState = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  const handleMenuOpen = useCallback(() => {
    setMenuOpened(true);
  }, []);
  const handleMenuClose = useCallback(() => {
    setMenuOpened(false);
  }, []);

  return {
    menuOpened,
    handleMenuOpen,
    handleMenuClose,
  };
};
