import {
  convertRewriteToolToSuggestTool,
  getToolDescription,
  getToolTitle,
} from 'src/data/generationTool';
import { getAssetChannelLabel, getAssetLabel } from 'src/data/asset';
import { GENERATION_SOURCE_TYPE, AI_GENERATION_MODE } from './const';

const describeSource = (sourceType) => {
  switch (sourceType) {
    case GENERATION_SOURCE_TYPE.improveListScore:
      return 'Content improver';
    case GENERATION_SOURCE_TYPE.changeTov:
      return 'More like this - change tone of voice';
    default:
      return '';
  }
};

export const getGenerationResultDescription = (generationData) => {
  const { generationTool, assetType, groupId, sourceType } = generationData;

  const alignedTool = convertRewriteToolToSuggestTool(generationTool);
  let title = '';
  let description = '';

  if (alignedTool) {
    title = getToolTitle(alignedTool);
    description = getToolDescription(alignedTool);
  } else {
    title = getAssetChannelLabel(assetType);
    description = getAssetLabel(assetType);
  }

  if (!description && groupId) {
    description = getAssetLabel(assetType);
  }

  const source = describeSource(sourceType);

  return {
    title,
    description,
    source,
  };
};

export const isSuggestGenerationResult = (result) =>
  result ? Object.hasOwn(result, 'suggestionMode') : false;

export const isRecomposeGenerationResult = (result) =>
  result ? Object.hasOwn(result, 'recomposeMode') : false;

export const removeTalkingPointsFromGenerationResult = (result) => ({
  ...result,
  keywords: null, // clear "legacy" keywords as well
  generationGuidance: {
    ...result.generationGuidance,
    keywords: [],
    features: [],
    benefits: [],
    pains: [],
    hashtags: [],
  },
});

export const getGenerationResultListId = (result) =>
  isSuggestGenerationResult(result)
    ? `${AI_GENERATION_MODE.suggest}_${result?.id}`
    : `${AI_GENERATION_MODE.recompose}_${result?.id}`;
