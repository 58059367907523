import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import {
  createBrandVoice,
  updateBrandVoice,
  deleteBrandVoice,
  queryBrandVoicesAssets,
  queryWorkspaceAssociatedBrandVoices,
} from 'src/graphql/brandVoices';
import { queryCustomerBrandVoices } from 'src/graphql/customer';
import { isAbortError } from 'src/lib/errors';
import { captureMessage } from 'src/lib/sentry';

const INITIAL_STATE = {
  brandVoices: [],
  loadingBrandVoices: false,
  isSavingBrandVoice: false,
  deletingBrandVoice: false,

  customerBrandVoices: [],
  loadingCustomerBrandVoices: false,

  brandVoicesAssets: [],
  loadingBrandVoiceAssets: false,

  defaultBrandVoice: null,
};

/**
 * Public actions
 */
export const ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  mergeBrandVoiceInCache:
    (brandVoice) =>
    ({ getState, setState }) => {
      const { brandVoices: list, customerBrandVoices: customerList } = getState();

      const itemExists = list.some((t) => t.id === brandVoice.id);
      const customerItemExists = customerList.some((t) => t.id === brandVoice.id);
      const newList = itemExists
        ? list.map((item) => (item.id === brandVoice.id ? { ...item, ...brandVoice } : item))
        : [brandVoice, ...list];
      const newCustomerList = customerItemExists
        ? list.map((item) => (item.id === brandVoice.id ? { ...item, ...brandVoice } : item))
        : [brandVoice, ...list];
      setState({ brandVoices: newList, customerBrandVoices: newCustomerList });
    },

  deleteBrandVoiceFromCache:
    (brandVoiceId) =>
    ({ getState, setState }) => {
      const { brandVoices: list, customerBrandVoices: customerList } = getState();
      const newList = list.filter((brandVoice) => brandVoice.id !== brandVoiceId);
      const newCustomerList = customerList.filter((brandVoice) => brandVoice.id !== brandVoiceId);
      setState({ brandVoices: newList, customerBrandVoices: newCustomerList });
    },

  queryCustomerBrandVoices:
    () =>
    async ({ setState }, { notifications, currentCustomerId }) => {
      try {
        setState({ loadingCustomerBrandVoices: true });
        const customer = await queryCustomerBrandVoices(currentCustomerId);

        setState({
          customerBrandVoices: customer.brandVoices,
          loadingCustomerBrandVoices: false,
        });
      } catch (error) {
        notifications.displayError(error);
      }
    },

  queryWorkspaceBrandVoices:
    ({ workspaceId }, options) =>
    async ({ setState }, { notifications, currentCustomerId }) => {
      try {
        setState({ loadingBrandVoices: true });
        const result = await queryWorkspaceAssociatedBrandVoices(
          {
            customerId: currentCustomerId,
            id: workspaceId,
          },
          options
        );

        setState({
          brandVoices: result?.associatedBrandVoices,
          defaultBrandVoice: result?.defaultBrandVoice || result?.associatedBrandVoices?.[0],
          loadingBrandVoices: false,
        });
      } catch (error) {
        if (isAbortError(error)) {
          return;
        }

        notifications.displayError(error);
      }
    },

  createBrandVoice:
    ({ name, workspaceId }) =>
    async ({ setState, dispatch }, { currentCustomerId }) => {
      setState({ isSavingBrandVoice: true });
      try {
        const createdBrandVoice = await createBrandVoice({
          name,
          customerId: currentCustomerId,
          workspaceId,
        });
        dispatch(ACTIONS.mergeBrandVoiceInCache(createdBrandVoice));
        return createdBrandVoice;
      } finally {
        setState({ isSavingBrandVoice: false });
      }
    },

  updateBrandVoice:
    ({ id, name }) =>
    async ({ setState, dispatch }) => {
      setState({ isSavingBrandVoice: true });
      try {
        const updatedBrandVoice = await updateBrandVoice({
          id,
          name,
        });
        dispatch(ACTIONS.mergeBrandVoiceInCache(updatedBrandVoice));
      } finally {
        setState({ isSavingBrandVoice: false });
      }
    },

  deleteBrandVoice:
    (itemId) =>
    async ({ setState, dispatch }) => {
      setState({ deletingBrandVoice: true });
      try {
        await deleteBrandVoice(itemId);
        dispatch(ACTIONS.deleteBrandVoiceFromCache(itemId));
      } finally {
        setState({ deletingBrandVoice: false });
      }
    },

  isSavingBrandVoice:
    () =>
    ({ getState }) => {
      return getState().isSavingBrandVoice;
    },

  getBrandVoices:
    () =>
    ({ getState }) => {
      return getState().brandVoices;
    },

  queryBrandVoicesAssets:
    ({ customerId, workspaceId } = {}) =>
    async ({ setState }, { currentCustomerId, currentWorkspaceId }) => {
      // Support both `customerId` and `workspaceId` as parameters or values from the store container
      const localCustomerId = customerId || currentCustomerId;
      const localWorkspaceId = workspaceId || currentWorkspaceId;
      if (!localCustomerId || !localWorkspaceId) {
        return;
      }

      setState({ loadingBrandVoiceAssets: true });
      try {
        let brandVoicesAssets = await queryBrandVoicesAssets(localCustomerId, localWorkspaceId);
        brandVoicesAssets = brandVoicesAssets.map((bv) => {
          if (bv.targetAudiences && bv.targetAudiences?.length > 0) {
            return {
              ...bv,
              targetAudiences: bv.targetAudiences.map((ta) => {
                return { ...ta, isSuggested: false };
              }),
            };
          } else {
            return bv;
          }
        });

        setState({
          brandVoicesAssets,
          loadingBrandVoiceAssets: false,
        });
      } catch (error) {
        captureMessage('Error while getting brand voices assets', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'BrandVoicesStore -> queryBrandVoicesAssets',
          },
        });
      } finally {
        setState({ loadingBrandVoiceAssets: false });
      }
    },

  getBrandVoicesAssets:
    () =>
    ({ getState }) => {
      return getState().brandVoicesAssets;
    },

  getBrandVoicesBrandTerms:
    (brandVoiceIds) =>
    ({ getState }) => {
      const { brandVoicesAssets } = getState();
      return brandVoicesAssets
        .filter((bv) => brandVoiceIds.includes(bv.id))
        .flatMap((bv) => bv.brandTerms);
    },
};

export const BrandVoicesStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'brand-voices-store',
});
