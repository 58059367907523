export const CUSTOM_BUTTON_VARIANT = {
  contained: 'contained',
  outlined: 'outlined',
  text: 'text',
};

export const CUSTOM_BUTTON_COLOR = {
  primary: 'primary',
  upsell: 'upsell',
  danger: 'danger',
  gray: 'gray',
  silver: 'silver',
  default: 'default',
};

export const CUSTOM_BUTTON_SIZE = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  smaller: 'smaller',
};
