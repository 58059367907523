import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2, 1.5),
      backgroundColor: COLORS.neutral.white,
      border: theme.shape.borderGray70,
      borderRadius: theme.shape.borderRadius,

      display: 'flex',
      flexFlow: 'column nowrap',
    },
    row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      columnGap: theme.spacing(0.75),

      '& + &': {
        marginTop: theme.spacing(1.5),
      },
    },
    rect: {
      borderRadius: theme.shape.borderRadiusMinimal,
      width: 260,
      height: 14,
    },
    rectSmall: {
      borderRadius: theme.shape.borderRadiusMinimal,
      width: 77,
      height: 14,
    },
    iconArrow: {
      fontSize: theme.typography.pxToRem(14),
      strokeWidth: 2.5,
      color: COLORS.gray.gray60,
    },
  }),
  { name: 'DetectedBrandTermSkeleton' }
);
