import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useStyles } from './useStyles';
import { PROMPT_NODE_ID } from '../../../../utils';

export const ImageNode = ({ element, children, ...attributes }) => {
  const styles = useStyles();
  return (
    <div
      id={PROMPT_NODE_ID.image}
      className={styles.wrapper}
    >
      <Typography
        className={styles.root}
        component="span"
      >
        {children}
      </Typography>
    </div>
  );
};

ImageNode.propTypes = {
  attributes: PropTypes.shape({}),
  element: PropTypes.shape().isRequired,
  children: PropTypes.node,
};
