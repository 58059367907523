import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const EditIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon {...props} ref={ref} viewBox="0 0 11 10">
        <path
          d="M0 7.91681V10.0005H2.23047L8.81185 3.85223L6.58137 1.76855L0 7.91681Z"
          fill="currentColor"
        />
        <path
          d="M10.5319 1.45947L9.143 0.162039C8.91103 -0.0546639 8.53333 -0.0546639 8.30136 0.162039L7.21289 1.17887L9.44336 3.26255L10.5318 2.24572C10.7638 2.02901 10.7638 1.67617 10.5319 1.45947Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);
