import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  () => ({
    root: {
      minWidth: 100,
      height: 32,
      backgroundColor: COLORS.primary.silver,
    },
  }),
  { name: 'InviteUsersButton' }
);
