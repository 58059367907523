import { makeStyles } from '@material-ui/core/styles';
import { COLOR_TEXT_DISABLED, COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},

    subtitle: {
      display: 'flex',
      flexDirection: 'column',
    },

    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      gap: theme.spacing(1),
    },
    firstBox: {
      justifyContent: 'space-between',
      height: theme.typography.pxToRem(48),
    },
    success: {},

    checkAgain: {
      color: COLORS.primary.default,
      backgroundColor: 'transparent',
      lineHeight: theme.typography.pxToRem(16),
      display: 'flex',
      flexDirection: 'row',
      minWidth: 'fit-content',
      alignItems: 'center',
      paddingRight: theme.spacing(1),
      fontSize: theme.typography.pxToRem(13),
      '&:disabled': {
        cursor: 'not-allowed',
      },
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },
    checkAgainIcon: {
      color: COLORS.primary.default,
      marginRight: theme.spacing(0.25),
      paddingTop: theme.spacing(0.25),
      '& > svg': {
        fontSize: theme.typography.pxToRem(16) + '!important',
      },
    },

    disabled: {
      color: COLOR_TEXT_DISABLED,
      cursor: 'not-allowed',
    },
    resultBox: {
      padding: theme.spacing(1),
      backgroundColor: COLORS.neutral.silver,
      borderRadius: theme.shape.borderRadius,

      '&$success': {
        backgroundColor: '#E4FAF2',
      },
    },
    border: {
      border: '1px solid #E9E9E9',
    },
  }),
  { name: 'PlagiarismChecker' }
);
