import { createContainer } from 'react-sweet-state';

import { UiStore, ACTIONS } from './UiStore';

export const UiStoreContainer = createContainer(UiStore, {
  onInit: () => ({ setState, dispatch }, { state }) => {
    dispatch(ACTIONS.init());

    if (state) {
      setState(state);
    }
  },
  onUpdate: () => ({ setState }, { state }) => {
    if (state) {
      setState(state);
    }
  },
});
