import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { CUSTOMER_FULL_FRAGMENT } from './schema';

const QUERY = gql`
  query Customer($id: ID!, $includePaymentMethod: Boolean!) {
    customer(id: $id) {
      ...CustomerFull
    }
  }
  ${CUSTOMER_FULL_FRAGMENT}
`;

export const queryCustomer = ({ id, includePaymentMethod }, options = null) =>
  executeGqlQuery({
    query: QUERY,
    variables: { id, includePaymentMethod: !!includePaymentMethod },
    options: {
      abortSignal: options?.abortSignal,
    },
  }).then(({ data }) => getSingleProp(data));
