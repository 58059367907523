import { useEffect } from 'react';

import * as events from 'src/lib/events';
import { PAGES, useCipEvents } from 'src/services';

export const useTrackPageVisited = (view) => {
  const { trackPageVisited } = useCipEvents();

  useEffect(() => {
    trackPageVisited(events.CIP.talkingPointsPageVisited, PAGES.talkingPoints);
  }, [trackPageVisited, view]);
};
