import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconUnderline = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.871582 11.1738C0.871582 10.7596 1.20737 10.4238 1.62158 10.4238H10.3784C10.7926 10.4238 11.1284 10.7596 11.1284 11.1738C11.1284 11.588 10.7926 11.9238 10.3784 11.9238H1.62158C1.20737 11.9238 0.871582 11.588 0.871582 11.1738Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.61176 0.0742188C4.02597 0.0742188 4.36176 0.410005 4.36176 0.824219V7.19279C4.36176 8.09758 5.0952 8.83101 5.99997 8.83101C6.90473 8.83101 7.63818 8.09758 7.63818 7.19279V0.824219C7.63818 0.410005 7.97397 0.0742188 8.38818 0.0742188C8.8024 0.0742188 9.13818 0.410005 9.13818 0.824219V7.19279C9.13818 8.92602 7.73315 10.331 5.99997 10.331C4.26679 10.331 2.86176 8.92602 2.86176 7.19279V0.824219C2.86176 0.410005 3.19754 0.0742188 3.61176 0.0742188Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconUnderline.displayName = 'IconUnderline';
