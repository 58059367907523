import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconInformation = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 16 16"
      data-fill-none
    >
      <path
        d="M7.99999 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 7.99999C14.6667 4.3181 11.6819 1.33333 7.99999 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 7.99999C1.33333 11.6819 4.3181 14.6667 7.99999 14.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.6667V8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.33333H8.00667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
