import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CONTENT_VALIDATION } from 'src/graphql/schema';

const MUTATION = gql`
  mutation UpdateSavedVariationContentValidation($id: ID!) {
    updateSavedVariationContentValidation(id: $id) {
      id
      contentSafety {
        ...ContentValidation
      }
      engagementScoreSupportSize
    }
  }
  ${FRAGMENT_CONTENT_VALIDATION}
`;

export const updateSavedVariationContentValidation = (id) => {
  const variables = { id };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
