import gql from 'graphql-tag';

export const FACEBOOK_USER_AD_ACCOUNT_FRAGMENT = gql`
  fragment FacebookUserAdAccount on FacebookUserAdAccount {
    id
    name
  }
`;

export const FACEBOOK_USER_PAGE_FRAGMENT = gql`
  fragment FacebookUserPage on FacebookUserPage {
    id
    name
    likesCount
  }
`;
