import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconSearchBorder = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
        data-fill-none
      >
        <path
          d="M1.5 8.25C1.5 10.0402 2.21116 11.7571 3.47703 13.023C4.7429 14.2888 6.45979 15 8.25 15C10.0402 15 11.7571 14.2888 13.023 13.023C14.2888 11.7571 15 10.0402 15 8.25C15 6.45979 14.2888 4.7429 13.023 3.47703C11.7571 2.21116 10.0402 1.5 8.25 1.5C6.45979 1.5 4.7429 2.21116 3.47703 3.47703C2.21116 4.7429 1.5 6.45979 1.5 8.25Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.09998 7.72498C5.09998 8.42117 5.37654 9.08885 5.86882 9.58113C6.3611 10.0734 7.02878 10.35 7.72498 10.35C8.42117 10.35 9.08885 10.0734 9.58113 9.58113C10.0734 9.08885 10.35 8.42117 10.35 7.72498C10.35 7.02878 10.0734 6.3611 9.58113 5.86882C9.08885 5.37654 8.42117 5.09998 7.72498 5.09998C7.02878 5.09998 6.3611 5.37654 5.86882 5.86882C5.37654 6.3611 5.09998 7.02878 5.09998 7.72498Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.58142 9.58093L11.4 11.4001"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
