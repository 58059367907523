import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateUser($customerId: ID!, $defaultWorkspaceId: ID) {
    updateUser(customerId: $customerId, defaultWorkspaceId: $defaultWorkspaceId) {
      id
    }
  }
`;

export const updateUser = (customerId, defaultWorkspaceId) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, defaultWorkspaceId },
  }).then(({ data }) => getSingleProp(data));
