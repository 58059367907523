import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      color: theme.palette.text.primary,
      alignItems: 'flex-start',
      '&$dashed $text': {
        textDecoration: 'underline',
        textDecorationStyle: 'dashed',
        textDecorationColor: '#91B6FF',
        textUnderlineOffset: '3px',
      },
      '& + &$extraMarginTop': {
        marginTop: theme.spacing(3),
      },
    },
    extraMarginTop: {},
    tooltip: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(19),
      backgroundColor: '#28354D',
      color: '#F7F9FD',
    },
    arrow: {
      color: '#28354D',
    },
    dashed: {},
    header: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    iconCheck: {
      marginRight: theme.spacing(1),
      fontSize: theme.typography.pxToRem(16),
      color: '#41C493',
    },
    iconTimes: {
      fontSize: theme.typography.pxToRem(10),
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
      marginLeft: theme.spacing(0.25),
      color: theme.palette.error.main,
    },
    iconCross: {
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(10),
    },
    text: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(21),
      color: 'inherit',
      whiteSpace: 'break-spaces',

      '& b': {
        fontWeight: theme.typography.fontWeightSemiBold,
      },
    },
  }),
  { name: 'PlanFeature' }
);
