import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},

    searchButton: {
      width: 165,
      height: 32,
      lineHeight: theme.typography.pxToRem(16),
      fontSize: theme.typography.pxToRem(14),
      '&:disabled': {
        cursor: 'not-allowed',
      },
    },

    searchIcon: {
      lineHeight: theme.typography.pxToRem(16),
      '&:disabled': {
        color: COLORS.neutral.silver,
        cursor: 'not-allowed',
      },
    },
    subtitle: {},
  }),
  { name: 'FirstTimeButton' }
);
