import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { getTrackingPayload } from 'src/lib/trackingPayload';

const MUTATION = gql`
  mutation SendTrackingParams($trackingPayload: JSON!, $source: String!) {
    sendTrackingParams(trackingPayload: $trackingPayload, source: $source) {
      id
    }
  }
`;

export const sendTrackingParams = (source) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: {
      trackingPayload: JSON.stringify(getTrackingPayload() || {}),
      source,
    },
  }).then(({ data }) => getSingleProp(data));
