import numeral from 'numeral';

export const formatMoney = (num) => numeral(num).format('$0,0[.]00');

export const formatMoneyFixedTrailing = (num) => numeral(num).format('$0,0.00');

export const formatNumber = (num) => Number(num).toLocaleString('en');

export const formatPercentage = (num, format) => `${numeral(num).format(format || '0[.]0[0]')}%`;

export const formatPercentageWithOneDecimal = (num) => `${numeral(num).format('0.0')}%`;

export const pluralize = (count, single, plural) => {
  if (count === 1) {
    return single;
  }
  return plural != null ? plural : `${single}s`;
};

const NUMBER_WORDS = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
];
export const numberToWord = (num) => {
  const rounded = Math.round(num);
  if (rounded < 0 || rounded >= 12) {
    return `${num}`;
  }
  return NUMBER_WORDS[rounded];
};

export const formatByObject = (value, obj) => {
  if (!value || !obj) return value;

  let str = value.toString();

  Object.keys(obj).forEach((key) => {
    str = str.replace(new RegExp(`\\{${key}\\}`, 'gi'), obj[key]);
  });

  return str;
};

export const formatFileSize = (file, decimalPlaces = 2) => {
  if (file?.size >= 1048576) {
    return `${(file?.size / 1048576).toFixed(decimalPlaces)}MB`;
  } else {
    return `${(file?.size / 1024).toFixed(decimalPlaces)}KB`;
  }
};

export const nthNumber = (number) => {
  if (number > 3 && number < 21) return `${number}th`;
  switch (number % 10) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};
