import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_BOOKMARK } from 'src/graphql/user/schema';

const MUTATION = gql`
  mutation CreateUserBookmark(
    $type: BookmarkTypeEnum!
    $name: String
    $link: String!
    $metadata: JSON
  ) {
    createBookmark(type: $type, name: $name, link: $link, metadata: $metadata) {
      ...Bookmark
    }
  }
  ${FRAGMENT_BOOKMARK}
`;

export const createUserBookmark = ({ type, name, link, metadata }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: {
      type,
      name,
      link,
      metadata: metadata ? JSON.stringify(metadata) : null,
    },
  }).then(({ data }) => getSingleProp(data));
