import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';

import { InfoIcon } from 'src/assets/icons/Info';
import { useStyles } from './useStyles';

export const PanelNotification = forwardRef(
  ({ className, notification, onCtaClick, onClose, floating }, ref) => {
    const styles = useStyles();

    const handleCtaClick = () => {
      onCtaClick(notification);
    };

    const handleCloseClick = () => {
      onClose(notification);
    };

    return (
      <Alert
        className={cx(styles.root, className)}
        classes={{
          message: styles.alertMessage,
          action: styles.alertActions,
        }}
        ref={ref}
        severity="info"
        elevation={floating ? 8 : 0}
        icon={<InfoIcon className={styles.infoIcon} />}
        action={
          <>
            <IconButton
              className={styles.buttonClose}
              onClick={handleCloseClick}
              aria-label="close"
              color="inherit"
              size="small"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>

            {notification.link && (
              <Button
                className={styles.buttonCta}
                href={notification.link}
                onClick={handleCtaClick}
                variant="contained"
                color="secondary"
                size="small"
              >
                {notification.linkText || 'Try It Now'}
              </Button>
            )}
          </>
        }
      >
        <AlertTitle className={styles.title}>{notification.title}</AlertTitle>

        {notification.message}
      </Alert>
    );
  }
);

PanelNotification.propTypes = {
  className: PropTypes.string,
  notification: PropTypes.shape().isRequired,
  onCtaClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

PanelNotification.defaultProps = {
  className: '',
};
