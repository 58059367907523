import { createHook } from 'react-sweet-state';

import { CustomerStore } from './CustomerStore';

const selector = (state) => {
  const customer = state.currentCustomer;
  return (customer && customer.id) || null;
};

const useHook = createHook(CustomerStore, {
  selector,
});

export default function useCurrentCustomerId() {
  const [id] = useHook();
  return id;
}
