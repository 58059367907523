import { CHANNEL } from 'src/data/channel';

export const SUGGESTED_ANNOTATIONS_ORIGIN = {
  anyword: 'anyword',
};

export const USER_ANNOTATIONS_ORIGIN = 'user';

const SUGGESTED_ANNOTATIONS_ORIGIN_PROPS = {
  [SUGGESTED_ANNOTATIONS_ORIGIN.anyword]: {
    label: "Anyword's data",
  },
};

export const getSuggestedAnnotationsOriginLabel = (origin) =>
  SUGGESTED_ANNOTATIONS_ORIGIN_PROPS[origin]?.label;

export const getDefaultSuggestedAnnotationsOrigin = (isAppModeInfinity) =>
  isAppModeInfinity
    ? SUGGESTED_ANNOTATIONS_ORIGIN[CHANNEL.google]
    : SUGGESTED_ANNOTATIONS_ORIGIN.anyword;
