import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query BlogSeoAnalysis($projectId: ID!) {
    blogSeoAnalysis(projectId: $projectId) {
      status
      data {
        keywords
        wordCountRange
        paragraphRange
        headlineRange
      }
    }
  }
`;

export const queryBlogSeoAnalysis = (projectId) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
    },
  }).then(({ data }) => getSingleProp(data));
