import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import * as storage from '../../lib/storage';

const MUTATION = gql`
  mutation AcceptExternalSignup(
    $token: String!
    $password: String!
    $signupDevice: String!
    $impactClickId: String
  ) {
    acceptExternalSignup(
      token: $token
      password: $password
      signupDevice: $signupDevice
      impactClickId: $impactClickId
    ) {
      token
    }
  }
`;

export const acceptExternalSignup = ({ token, password, signupDevice }) => {
  const impactClickId = storage.readItem('impactClickId');
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { token, password, signupDevice, impactClickId },
  }).then(({ data }) => getSingleProp(data));
};
