import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomIconButton } from 'src/components/CustomIconButton';
import { CUSTOM_TOOLTIP_SIZE, CustomTooltip } from 'src/components/CustomTooltip';
import { useStyles } from './useStyles';

export const ToolbarButton = ({
  className,
  tooltip,
  selected,
  disabled,
  children,
  classes,
  ...restProps
}) => {
  const styles = useStyles({ classes });

  return (
    <CustomTooltip
      title={tooltip || ''}
      size={CUSTOM_TOOLTIP_SIZE.small}
      arrow
    >
      <span>
        <CustomIconButton
          className={cx(styles.root, className, { [styles.selected]: selected })}
          disabled={disabled}
          {...restProps}
        >
          {children}
        </CustomIconButton>
      </span>
    </CustomTooltip>
  );
};

ToolbarButton.propTypes = {
  className: PropTypes.string,
  tooltip: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape(),
};

ToolbarButton.defaultProps = {
  className: '',
  tooltip: '',
  selected: false,
  disabled: false,
  classes: {},
};
