import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChannelBrazeIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
      >
        <path
          d="M7.22909 9.16364C7.36 7.91273 8.14545 6.89455 9.06182 6.89455C9.97818 6.89455 10.4727 7.91273 10.3418 9.16364C10.2109 10.4145 9.45455 11.4473 8.59636 11.4473C7.73818 11.4473 7.04 10.6618 7.22909 9.16364ZM8 14.8364C5.96377 14.8393 4.0327 13.9324 2.73455 12.3636C3.86909 12.7273 5.13455 12.4073 6.12364 11.4327C6.22809 11.3279 6.32528 11.2162 6.41455 11.0982C6.86545 12.0727 7.73818 12.5673 8.61091 12.5673C10.0945 12.5673 11.3455 11.04 11.4909 9.14909C11.6364 7.25818 10.5745 5.76 9.16364 5.76C8.65168 5.7529 8.14791 5.88892 7.70909 6.15273L8.14545 3.60727C8.18895 3.40352 8.14112 3.19094 8.01455 3.02545C7.88364 2.82182 7.76727 2.73455 7.59273 2.73455H5.55636C5.43597 2.74082 5.33766 2.83299 5.32364 2.95273C5.26953 3.19245 5.23066 3.43536 5.20727 3.68C5.19273 3.84 5.30909 3.89818 5.44 3.89818H6.92364C6.69091 5.35273 6.22545 8.17455 6.09455 8.88727C5.95732 9.5619 5.62915 10.1829 5.14909 10.6764C4.33455 11.4909 2.83636 11.68 1.94909 10.7927C1.64364 10.4727 1.16364 9.68727 1.16364 8C1.16364 4.22438 4.22438 1.16364 8 1.16364C11.7756 1.16364 14.8364 4.22438 14.8364 8C14.8364 11.7756 11.7756 14.8364 8 14.8364ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0Z"
          fill="#212124"
        />
      </SvgIcon>
    );
  })
);
