import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      gap: theme.spacing(0.5),
    },

    button: {
      ...theme.typography.medium14,
      minWidth: 0,
      borderRadius: theme.shape.borderRadiusSmall,
      padding: theme.spacing(1, 2),

      '&:hover': {
        boxShadow: 'unset',
      },
    },
    grayButton: {
      background: COLORS.gray.gray90,
      color: COLORS.primary.default,

      '&:hover': {
        background: COLORS.primary.silver,
        color: COLORS.primary.default,
      },
    },

    icon: {
      fontSize: theme.typography.pxToRem(14),
    },
  }),
  { name: 'CopyInputInfinityActions' }
);
