import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation SendRegistrationConfirmation {
    sendRegistrationConfirmation {
      id
    }
  }
`;

export const sendRegistrationConfirmation = () =>
  executeGqlMutation({
    mutation: MUTATION,
  }).then(({ data }) => getSingleProp(data));
