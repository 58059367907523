import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormControl, Tooltip, Typography } from '@material-ui/core';

import { FieldLabel } from 'src/components/FieldLabel';
import { ChipInput } from 'src/components/ChipInput';
import { TextCounter } from '../TextCounter';
import useStyles from './useStyles';

export const FieldChipInput = memo(
  ({
    className,
    classes,
    label,
    subtitle,
    error,
    placeholder,
    value,
    onChange,
    onEnterPress,
    hint,
    disabled,
    maxChipsExceeded,
    inputDisabled,
    tooltip,
    maxChips,
    AutocompleteProps,
    InputProps,
    chipClasses,
    rows,
    isQuestion,
    isOptional,
    isItalic,
    renderTags,
    displayCounter,
    inputProps,
    onInputChange,
    titleBold,
    ...restProps
  }) => {
    const styles = useStyles({ classes });

    return (
      <FormControl
        className={cx(styles.root, className)}
        data-testid="FieldChipInput"
        {...restProps}
      >
        <div className={styles.header}>
          <div className={styles.title}>
            <FieldLabel
              className={styles.label}
              label={label}
              hint={hint}
              isQuestion={isQuestion}
              isOptional={isOptional}
              titleBold={titleBold}
            />

            {displayCounter && (
              <TextCounter
                className={styles.counter}
                count={value?.length || 0}
                maxCount={maxChips || 0}
              />
            )}
          </div>

          {subtitle && <Typography className={styles.subtitle}>{subtitle}</Typography>}
        </div>

        <Tooltip title={tooltip || ''}>
          <div className={styles.inputWrapper}>
            <ChipInput
              className={styles.input}
              value={value}
              onChange={onChange}
              onEnterPress={onEnterPress}
              placeholder={placeholder}
              disabled={disabled}
              inputDisabled={maxChipsExceeded || inputDisabled}
              error={Boolean(error)}
              maxChips={maxChips}
              AutocompleteProps={AutocompleteProps}
              InputProps={InputProps}
              chipClasses={chipClasses}
              rows={rows}
              multiline={rows > 1}
              isItalic={isItalic}
              renderTags={renderTags}
              inputProps={{ ...inputProps, dataTestId: label }}
              onInputChange={onInputChange}
            />
          </div>
        </Tooltip>

        {error && <Typography className={styles.error}>{error}</Typography>}
      </FormControl>
    );
  }
);

FieldChipInput.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  label: PropTypes.string,
  subtitle: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  onEnterPress: PropTypes.func,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  maxChipsExceeded: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  tooltip: PropTypes.string,
  maxChips: PropTypes.number,
  AutocompleteProps: PropTypes.shape(),
  InputProps: PropTypes.shape(),
  chipClasses: PropTypes.shape(),
  rows: PropTypes.number,
  isQuestion: PropTypes.bool,
  isOptional: PropTypes.bool,
  isItalic: PropTypes.bool,
  renderTags: PropTypes.func,
  displayCounter: PropTypes.bool,
  inputProps: PropTypes.shape(),
  onInputChange: PropTypes.func,
  titleBold: PropTypes.bool,
};

FieldChipInput.defaultProps = {
  className: null,
  classes: null,
  label: '',
  subtitle: '',
  error: null,
  placeholder: '',
  onChange: null,
  onEnterPress: null,
  hint: null,
  disabled: false,
  maxChipsExceeded: false,
  inputDisabled: false,
  tooltip: '',
  maxChips: null,
  AutocompleteProps: null,
  InputProps: null,
  chipClasses: null,
  rows: 1,
  isQuestion: false,
  isOptional: false,
  isItalic: false,
  renderTags: null,
  displayCounter: false,
  inputProps: null,
  onInputChange: null,
  titleBold: false,
};
