import { useParams } from 'react-router-dom-v5-compat';

import { useBrandVoices } from 'src/services';
import { useMemo } from 'react';

export const useBrandVoiceFromUrl = () => {
  const { brandVoiceId } = useParams();
  const { brandVoices } = useBrandVoices();

  return useMemo(
    () => brandVoices?.find((brandVoice) => brandVoice.id === brandVoiceId),
    [brandVoices, brandVoiceId]
  );
};
