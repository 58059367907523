import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { useStyles } from './useStyles';

export const TermText = ({ className, text, isOriginal, isSuggestion }) => {
  const styles = useStyles();

  return (
    <div
      className={cx(styles.root, className, {
        [styles.isOriginal]: isOriginal,
        [styles.isSuggestion]: isSuggestion,
      })}
    >
      <TruncatedTypography
        className={styles.text}
        variant={TYPOGRAPHY_VARIANT.regular14}
      >
        {text}
      </TruncatedTypography>
    </div>
  );
};

TermText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  isOriginal: PropTypes.bool,
  isSuggestion: PropTypes.bool,
};

TermText.defaultProps = {
  className: '',
  isOriginal: false,
  isSuggestion: false,
};
