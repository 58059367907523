import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import { captureMessage } from 'src/lib/sentry';
import {
  queryCustomerMultiPromptTemplates,
  deleteCustomerMultiPromptTemplate,
  updateExpertMultiPromptTemplate,
  releaseExpertMultiPromptTemplate,
  deleteReleasedExpertMultiPromptTemplate,
} from 'src/graphql/customerMultiPromptTemplates';

const mapMultiPromptTemplatesResult = (result) =>
  result.map((tool) => {
    tool.fields = JSON.parse(tool.fields || null) || [];
    tool.blocks = JSON.parse(tool.blocks || null) || [];
    return tool;
  });

const STORE_NAME = 'multi-prompt-templates-store';

const INITIAL_STATE = {
  multiPromptTemplates: [],
  loadingMultiPromptTemplates: false,
  savingMultiPromptTemplates: false,
  deletingMultiPromptTemplate: false,
  multiPromptBriefExtractionResult: null,
  errors: null,

  customerMultiPromptTemplates: [],
  loadingCustomerMultiPromptTemplates: false,
  shouldPrepopulateCsv: false,
};

/**
 * Private actions
 */
const PRIVATE_ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  updateTemplate:
    (template) =>
    ({ getState, setState }) => {
      const { multiPromptTemplates } = getState();
      // Assuming template is a response from BE and fields and blocks are strings
      template.fields = JSON.parse(template.fields || null) || [];
      template.blocks = JSON.parse(template.blocks || null) || [];

      const updatedTemplates = multiPromptTemplates.map((existingTemplate) => {
        if (existingTemplate.id === template.id) {
          return { ...existingTemplate, ...template };
        }
        return existingTemplate;
      });

      setState({ multiPromptTemplates: updatedTemplates });
    },
};

/**
 * Public actions
 */
export const ACTIONS = {
  init:
    (customerId, workspaceId) =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.clearStore());

      if (customerId && workspaceId) {
        dispatch(ACTIONS.queryWorkspaceMultiPromptTemplates({ customerId, workspaceId }));
      }
    },

  update:
    (customerId, workspaceId) =>
    ({ dispatch }) => {
      if (customerId && workspaceId) {
        dispatch(ACTIONS.queryWorkspaceMultiPromptTemplates({ customerId, workspaceId }));
      }
    },

  getLoadingMultiPromptTemplates:
    () =>
    ({ getState }) => {
      return getState().loadingMultiPromptTemplates;
    },

  queryCustomerMultiPromptTemplates:
    () =>
    async ({ setState }, { currentCustomerId }) => {
      setState({ loadingCustomerMultiPromptTemplates: true });
      try {
        const result = await queryCustomerMultiPromptTemplates({ customerId: currentCustomerId });
        const mappedResult = mapMultiPromptTemplatesResult(result);
        setState({ customerMultiPromptTemplates: mappedResult });
      } catch (error) {
        // Report "warning" to Sentry
        captureMessage('Error while calling "queryMultiPromptTemplates" GQL query', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'MultiPromptTemplatesStore -> queryAccountMultiPromptTemplates',
          },
        });
      } finally {
        setState({ loadingCustomerMultiPromptTemplates: false });
      }
    },

  queryWorkspaceMultiPromptTemplates:
    ({ customerId, workspaceId }) =>
    async ({ setState }, { currentCustomerId }) => {
      setState({ loadingMultiPromptTemplates: true });
      try {
        const result = await queryCustomerMultiPromptTemplates({
          customerId: customerId || currentCustomerId,
          workspaceId: workspaceId,
        });
        const mappedResult = mapMultiPromptTemplatesResult(result);
        setState({ multiPromptTemplates: mappedResult });
      } catch (error) {
        // Report "warning" to Sentry
        captureMessage('Error while calling "queryCustomerMultiPromptTemplates" GQL query', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'MultiPromptTemplatesStore -> queryWorkspaceMultiPromptTemplates',
          },
        });
      } finally {
        setState({ loadingMultiPromptTemplates: false });
      }
    },

  deleteMultiPromptTemplate:
    (customerId, id) =>
    async ({ setState, getState }) => {
      setState({ loadingMultiPromptTemplates: true });
      try {
        await deleteCustomerMultiPromptTemplate(customerId, id);
        const multiPromptTemplates = getState().multiPromptTemplates.filter((t) => t.id !== id);
        setState({ multiPromptTemplates });
      } finally {
        setState({ loadingMultiPromptTemplates: false });
      }
    },

  getMultiPromptTemplates:
    (includeDraft) =>
    ({ getState }) => {
      const templates = getState().multiPromptTemplates;
      if (!includeDraft) {
        return templates.filter((t) => t.status !== 'draft');
      }
      return templates;
    },

  getMultiPromptTemplate:
    (id) =>
    ({ getState }) => {
      const { multiPromptTemplates } = getState();
      return multiPromptTemplates.find((t) => t.id === id);
    },

  getMultiPromptTemplateProperty:
    (id, prop) =>
    ({ getState }) => {
      const { multiPromptTemplates } = getState();
      return multiPromptTemplates.find((t) => t.id === id)?.[prop];
    },

  getMultiPromptTemplatesProperty:
    (prop) =>
    ({ getState }) => {
      const { multiPromptTemplates } = getState();
      return multiPromptTemplates.map((t) => t[prop]);
    },

  getMultiPromptTemplateFields:
    (id) =>
    ({ getState }) => {
      const { multiPromptTemplates } = getState();
      const fields = multiPromptTemplates.find((t) => t.id === id)?.fields;
      if (!fields) {
        return null;
      }
      const fieldsData = { titles: {}, placeholders: {}, order: [] };
      fields.forEach((field) => {
        const fieldKey = field.name.toLowerCase().replace(/ /g, '_');
        fieldsData.titles[fieldKey] = field.name;
        fieldsData.placeholders[fieldKey] = field.description;
        fieldsData.order.push(fieldKey);
      });
      return fieldsData;
    },

  getMultiPromptBriefExtractionResultByChainId:
    (chainId) =>
    ({ getState }) => {
      const { multiPromptBriefExtractionResult } = getState();

      return multiPromptBriefExtractionResult ? multiPromptBriefExtractionResult[chainId] : null;
    },

  clearMultiPromptBriefExtractionResultByChainId:
    (chainId) =>
    ({ setState, getState }) => {
      const { multiPromptBriefExtractionResult } = getState();

      if (multiPromptBriefExtractionResult) {
        const newMultiPromptBriefExtractionResult = { ...multiPromptBriefExtractionResult };
        delete newMultiPromptBriefExtractionResult[chainId];
        setState({ multiPromptBriefExtractionResult: newMultiPromptBriefExtractionResult });
      }
    },

  setMultiPromptBriefExtractionResultByChainId:
    (chainId, briefExtractionResult) =>
    ({ getState, setState }) => {
      const { multiPromptBriefExtractionResult } = getState();
      const newMultiPromptBriefExtractionResult = {
        ...(multiPromptBriefExtractionResult || {}),
        [chainId]: briefExtractionResult,
      };
      setState({ multiPromptBriefExtractionResult: newMultiPromptBriefExtractionResult });
    },

  getMultiPromptTemplateBlocks:
    (id) =>
    ({ getState }) => {
      const { multiPromptTemplates } = getState();
      const blocks = multiPromptTemplates.find((t) => t.id === id)?.blocks;
      if (!blocks) {
        return {};
      }
      const blocksData = {};
      blocks.forEach((block) => {
        blocksData[block.name] = block.type;
      });
      return blocksData;
    },

  getMultiPromptTemplateSubTools:
    (id) =>
    ({ getState }) => {
      const { multiPromptTemplates } = getState();
      const blocks = multiPromptTemplates.find((t) => t.id === id)?.blocks;
      if (!blocks) {
        return null;
      }
      const tools = blocks.map((block) => block.type);
      return [...new Set([...tools])];
    },

  updateExpertMultiPromptTemplate:
    (expertTemplateFields) =>
    async ({ dispatch }) => {
      const template = await updateExpertMultiPromptTemplate(expertTemplateFields);
      dispatch(PRIVATE_ACTIONS.updateTemplate(template));
    },

  releaseTemplate:
    (id) =>
    async ({ dispatch }) => {
      const template = await releaseExpertMultiPromptTemplate({
        id,
      });
      dispatch(PRIVATE_ACTIONS.updateTemplate(template));
    },

  deleteReleasedTemplate:
    (id) =>
    async ({ dispatch }) => {
      const template = await deleteReleasedExpertMultiPromptTemplate({
        id,
      });
      dispatch(PRIVATE_ACTIONS.updateTemplate(template));
    },

  getShouldPrepopulateCsv:
    () =>
    ({ getState }) => {
      return getState().shouldPrepopulateCsv;
    },

  setShouldPrepopulateCsv:
    (shouldPrepopulateCsv) =>
    ({ setState }) => {
      setState({ shouldPrepopulateCsv });
    },
};

export const MultiPromptTemplatesStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: STORE_NAME,
});
