import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

export const PersonaAvatarOne = memo(
  forwardRef(({ opaque, ...props }, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 72 72"
      data-fill-none
    >
      <path
        d="M72 36.0027C71.9995 43.1227 69.8876 50.0827 65.9316 56.0025C61.9755 61.9223 56.3529 66.5361 49.7747 69.2604C43.1965 71.9848 35.9582 72.6973 28.9751 71.3079C21.9919 69.9185 15.5776 66.4897 10.5432 61.4549C5.50879 56.4201 2.08039 50.0055 0.691545 43.0222C-0.6973 36.039 0.01578 28.8007 2.74061 22.2228C5.46545 15.6448 10.0797 10.0225 15.9998 6.0669C21.9199 2.11129 28.88 2.06658e-08 36 0C45.5474 0.00290854 54.7028 3.79706 61.4536 10.5483C68.2044 17.2996 71.9978 26.4554 72 36.0027V36.0027Z"
        fill="#8CB2FF"
      />
      <path
        d="M67.5467 36.0038C67.5467 42.2424 65.6967 48.3409 62.2307 53.5281C58.7648 58.7153 53.8384 62.7582 48.0747 65.1456C42.311 67.533 35.9688 68.1576 29.8501 66.9406C23.7314 65.7235 18.111 62.7193 13.6996 58.3079C9.28829 53.8966 6.28413 48.2762 5.06704 42.1575C3.84995 36.0388 4.4746 29.6966 6.86201 23.9329C9.24941 18.1692 13.2923 13.2428 18.4795 9.77687C23.6667 6.31089 29.7652 4.46094 36.0038 4.46094C44.3679 4.46602 52.388 7.79091 58.3023 13.7052C64.2167 19.6196 67.5416 27.6397 67.5467 36.0038V36.0038Z"
        fill={opaque ? '#8CB2FF' : 'white'}
      />
      <path
        d="M55.6049 37.3777C55.6049 37.3777 55.6049 37.4682 55.6049 37.6218C55.5774 38.1539 55.4979 39.465 55.3306 40.4771C55.221 41.0083 55.0076 41.5126 54.7025 41.961C54.2928 42.5709 53.8429 43.1528 53.3557 43.7027C53.1736 43.9235 52.9785 44.1333 52.7715 44.3309C52.3189 44.7807 51.8142 45.2415 51.8142 45.2415C51.8142 45.2415 51.4302 46.5663 51.0874 47.5949C50.9557 47.9898 50.5662 48.8538 50.1356 49.7617C49.4554 51.2017 48.6737 52.7569 48.6737 52.7569L48.3335 57.4362C48.3335 57.4362 48.4817 64.8858 48.8245 66.8689C48.9903 67.7225 49.3445 68.5284 49.8613 69.2278C42.079 72.4618 33.4113 72.8751 25.3566 70.3962C25.417 70.27 25.491 70.1082 25.5733 69.9162C26.3738 68.1172 26.9655 66.2325 27.337 64.2988C27.4465 63.691 27.5216 63.0775 27.5619 62.4611C27.6743 60.6536 27.7319 55.3736 27.7457 53.8787C27.7457 53.6264 27.7457 53.481 27.7457 53.481L27.6634 53.2835L27.625 53.182L27.4604 52.7898L24.3198 45.2552H24.2979C24.1602 45.2443 24.0241 45.2185 23.8919 45.1784C23.1022 44.9025 22.3429 44.5459 21.6263 44.1142C21.6263 44.1142 21.47 43.7713 21.2588 43.2392C21.1838 43.0563 21.1043 42.8506 21.0202 42.6221C20.7509 41.9042 20.5229 41.1716 20.3372 40.4278C20.2714 40.1535 20.2165 39.8792 20.1699 39.6269C20.1699 39.5775 20.1534 39.5254 20.1479 39.4733C20.0115 38.8295 20.0388 38.1618 20.2275 37.5313C20.9763 35.647 22.2901 35.0957 22.2901 35.0957L22.5809 32.3199L22.9676 28.6307L24.3829 23.3891L28.0775 21.7215L35.3571 18.4301L36.7285 17.8047L43.7393 18.0296H43.8435L49.3841 21.8888L53.0897 24.4863L53.6163 27.5857L54.3075 34.5635L54.6037 35.2081L54.6311 35.2685L55.6049 37.3777Z"
        fill="#DEE6F7"
      />
      <path
        d="M19.6159 39.5395C19.6159 39.5395 19.2429 39.6382 19.1826 37.6195C19.1222 35.6007 18.5627 30.872 18.3844 29.8819C18.2061 28.8917 17.6191 26.3683 18.8369 24.8405C20.0548 23.3127 22.6221 21.87 22.6221 21.87C22.6221 21.87 23.2584 18.6499 24.4104 16.9328C25.5624 15.2158 26.9009 12.2151 29.8111 12.185C32.7213 12.1548 34.7044 12.1493 35.9085 11.705C37.1126 11.2606 38.5992 10.2458 41.0431 11.1564C43.487 12.067 46.8662 12.8734 47.135 13.2903C47.4038 13.7073 49.7188 17.5198 49.7188 17.5198L51.6964 21.6341C51.6964 21.6341 53.7974 23.0906 54.6971 24.3989C55.5967 25.7072 55.1716 28.1676 55.0948 29.2071C55.018 30.2467 54.612 35.2277 54.612 35.2277L50.2235 30.0848C50.2235 30.0848 47.3627 26.9525 44.5265 26.2997C41.6904 25.6469 37.8751 25.2629 36.0017 25.6551C34.1284 26.0474 30.6422 27.1664 28.5659 28.6476C26.4895 30.1287 24.0785 32.7975 23.4833 34.0839C22.8881 35.3703 20.7762 39.1719 20.4662 39.3502C20.1981 39.4689 19.9091 39.5332 19.6159 39.5395Z"
        fill="#43C493"
      />
      <path
        d="M37.5124 30.4844C37.7669 34.0475 37.2118 37.622 35.8886 40.9401C35.6143 41.6505 35.2852 42.4597 35.6143 43.1481C35.7757 43.4476 36.006 43.7042 36.2863 43.8969C36.9591 44.4178 37.7338 44.7916 38.5601 44.9941"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.1562 49.1649C38.15 49.2176 39.1442 49.0702 40.0799 48.7314C41.0157 48.3926 41.8738 47.8694 42.6036 47.1928C42.6856 47.1246 42.7521 47.0394 42.7983 46.9432C42.8269 46.8518 42.8297 46.7543 42.8065 46.6614C42.7832 46.5685 42.7349 46.4838 42.6667 46.4166C42.5276 46.2859 42.3659 46.1818 42.1894 46.1094C42.5113 46.2314 42.8038 46.4201 43.0477 46.6631C43.2916 46.906 43.4814 47.1978 43.6047 47.5192"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.3789 52.5392C40.4047 52.5392 41.5348 52.4815 42.2863 51.7812"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.2388 30.0627C48.0089 27.4515 44.605 26.0746 41.2039 25.6138C39.367 25.3387 37.4966 25.3814 35.6743 25.74C33.8503 26.1295 32.1305 26.9057 30.4628 27.767C28.4139 28.8312 26.3979 30.0627 24.9058 31.8237C23.09 33.9631 22.1849 36.7169 20.6845 39.0867C20.5581 39.343 20.3367 39.5398 20.0674 39.6353C19.9039 39.65 19.74 39.6111 19.6005 39.5246C19.4609 39.438 19.3533 39.3084 19.2939 39.1553C19.1828 38.8463 19.1398 38.517 19.1677 38.1898C19.1925 35.6187 18.9675 33.0512 18.4957 30.5235C18.2215 29.0589 17.8676 27.5064 18.3723 26.113C18.7371 25.0982 19.5133 24.2863 20.328 23.5786C21.7942 22.3614 23.372 21.2851 25.0402 20.364C29.0503 18.0353 33.4663 16.0001 38.1017 16.1482C43.4173 16.3156 48.1899 19.308 52.5236 22.391C53.6208 23.1782 54.7975 24.0806 55.1458 25.3917C55.2816 26.0439 55.3066 26.7142 55.2199 27.3747L54.6329 35.255L50.2388 30.0627Z"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6094 21.6338C22.8425 19.8043 23.7367 18.1367 24.6226 16.5211C25.0732 15.6126 25.6382 14.7654 26.304 14.0004C27.2054 13.0514 28.3901 12.4201 29.6805 12.2011C31.3591 11.9268 33.1008 12.3602 34.7685 12.0283C36.1591 11.754 37.4263 10.9476 38.8416 10.8407C40.6245 10.7063 42.2729 11.6855 43.9817 12.2121C44.9499 12.5083 45.9867 12.6893 46.8288 13.2379C47.7943 13.8825 48.373 14.9522 48.9106 15.9808C49.8295 17.7307 50.7511 19.4861 51.3792 21.3595"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6183 22.6406C21.0294 26.4415 21.5891 30.3316 23.2257 33.8123"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1621 18.7188C27.8638 21.9168 28.2375 25.142 29.2592 28.1871"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7422 16.4844C35.8388 19.4275 36.2269 22.5876 35.875 25.7086"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.6007 17.2344C44.2562 19.9992 43.6281 22.8847 43 25.6522"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.1161 20.2656C49.8512 22.8851 49.1463 25.6718 48.4414 28.3022"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.098 35.0585C54.3761 34.8527 55.2923 36.3229 55.5857 37.5764C55.8792 38.8299 55.5144 40.1547 55.0015 41.3396C54.3844 42.7686 53.46 44.2086 51.9844 44.7353"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.6406 40.7693C52.8271 40.2207 53.0329 39.62 53.4827 39.236C53.6303 39.0989 53.8115 39.0031 54.008 38.9584C54.2045 38.9136 54.4093 38.9214 54.6018 38.9809"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.7891 40.2269C53.002 40.2226 53.2135 40.263 53.4099 40.3452C53.6064 40.4275 53.7836 40.55 53.9301 40.7047"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.4594 53.0312C48.2619 56.8712 48.0644 60.7112 48.336 64.5512C48.4512 66.197 48.7063 67.9551 49.8254 69.1647"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6124 53.1172C27.2888 56.0246 27.5548 58.9595 27.4808 61.8943C27.4067 64.8292 26.9596 67.8216 25.3633 70.2902"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5333 39.4531C20.3873 39.8692 20.3264 40.3104 20.3542 40.7505C20.382 41.1905 20.4979 41.6205 20.6952 42.015C21.0952 42.8011 21.6321 43.5097 22.2805 44.1078C22.8702 44.692 23.7617 45.2049 24.0222 45.1857"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.6953 38.8438C22.0464 39.0412 22.4633 39.118 22.7678 39.3923C23.1463 39.7132 23.2807 40.2316 23.3904 40.7171"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0898 40.4375C22.4508 40.443 22.811 40.4733 23.1678 40.5281"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4922 29.9344C26.5009 30.0729 26.5429 30.2072 26.6146 30.326C26.6862 30.4449 26.7854 30.5447 26.9038 30.6171C27.0222 30.6894 27.1563 30.7322 27.2947 30.7418C27.4331 30.7514 27.5718 30.7276 27.699 30.6722C29.5916 29.8768 31.764 29.9289 33.7662 29.3995C33.8305 29.3965 33.8933 29.38 33.9507 29.3509C34.0081 29.3218 34.0586 29.281 34.0991 29.2309C34.1395 29.1809 34.1689 29.1229 34.1852 29.0607C34.2016 28.9986 34.2047 28.9336 34.1941 28.8702C34.2266 28.2808 34.222 27.6899 34.1804 27.101C34.1817 26.7399 34.0871 26.385 33.9061 26.0725C33.7747 25.9062 33.6082 25.7708 33.4186 25.676C33.229 25.5812 33.0209 25.5293 32.809 25.5239C32.3811 25.5172 31.9553 25.584 31.55 25.7214C29.7425 26.2398 27.8938 27.0928 26.9091 28.6946C26.6653 29.0573 26.5284 29.4812 26.5141 29.9179L26.4922 29.9344Z"
        fill="black"
      />
      <path
        d="M49.5312 29.934C49.5231 30.0727 49.4815 30.2074 49.41 30.3266C49.3385 30.4458 49.2393 30.5459 49.1207 30.6184C49.0021 30.6909 48.8678 30.7336 48.7291 30.7429C48.5904 30.7522 48.4516 30.7278 48.3244 30.6718C46.4346 29.8764 44.2595 29.9285 42.2599 29.3991C42.1972 29.3949 42.1361 29.3777 42.0803 29.3487C42.0246 29.3196 41.9754 29.2794 41.936 29.2304C41.8966 29.1815 41.8677 29.1249 41.8513 29.0642C41.8348 29.0036 41.831 28.9402 41.8403 28.878C41.8032 28.2889 41.8032 27.698 41.8403 27.1088C41.8391 26.7478 41.9337 26.3928 42.1146 26.0803C42.2456 25.9136 42.412 25.7779 42.6017 25.6831C42.7914 25.5882 42.9997 25.5365 43.2117 25.5317C43.6396 25.525 44.0654 25.5918 44.4707 25.7292C46.2782 26.2476 48.1269 27.1006 49.1116 28.7024C49.3578 29.0639 49.4949 29.4886 49.5066 29.9258L49.5312 29.934Z"
        fill="black"
      />
      <path
        d="M52.9506 35.2746C52.9506 48.8984 46.2525 59.9412 37.9883 59.9412C30.1465 59.9412 23.7254 49.9901 23.0781 37.329V34.6328"
        stroke="black"
        strokeWidth="0.549238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5938 53.2031C27.5938 53.2031 29.3025 63.9003 37.7423 63.9003C46.1821 63.9003 48.1487 53.6694 48.1487 53.6694C48.1487 53.6694 43.3405 60.2001 37.9864 59.9451C32.6324 59.69 27.5938 53.2031 27.5938 53.2031Z"
        fill="black"
      />
      <path
        d="M49.7109 29.875C49.7109 29.875 50.5941 40.9177 51.9957 41.7379C53.3973 42.558 53.4659 42.3221 53.4659 42.3221C53.4659 42.3221 52.8186 38.5699 53.3589 37.4508C53.7359 36.6924 54.162 35.9594 54.6344 35.2565L49.7109 29.875Z"
        fill="black"
      />
      <path
        d="M24.9143 31.8281C24.9143 31.8281 25.2188 39.936 24.5495 41.0002C24.2896 41.4598 23.9835 41.8917 23.6361 42.2894L22.1797 36.7132L24.9143 31.8281Z"
        fill="black"
      />
      <path
        d="M20.9943 43C20.9943 43 20.7364 49.0699 25.9671 49.9861L24.2391 45.4055L22.4946 44.2754L20.9943 43Z"
        fill="black"
      />
      <path
        d="M54.2547 43C54.2547 43 55.2942 47.8521 52.9463 49.1769C51.3335 50.0848 50.0938 49.7557 50.0938 49.7557L51.7916 45.1806L52.288 44.5497L54.2547 43Z"
        fill="black"
      />
      <path
        d="M30.5788 39.076C31.0408 39.076 31.4153 38.2668 31.4153 37.2685C31.4153 36.2702 31.0408 35.4609 30.5788 35.4609C30.1167 35.4609 29.7422 36.2702 29.7422 37.2685C29.7422 38.2668 30.1167 39.076 30.5788 39.076Z"
        fill="black"
      />
      <path
        d="M44.8053 39.076C45.2673 39.076 45.6419 38.2668 45.6419 37.2685C45.6419 36.2702 45.2673 35.4609 44.8053 35.4609C44.3433 35.4609 43.9688 36.2702 43.9688 37.2685C43.9688 38.2668 44.3433 39.076 44.8053 39.076Z"
        fill="black"
      />
    </SvgIcon>
  ))
);
PersonaAvatarOne.propTypes = {
  opaque: PropTypes.bool,
};
PersonaAvatarOne.defaultProps = {
  opaque: false,
};
