import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOMER_RESOURCE } from './schema';

const MUTATION = gql`
  mutation AddResourcesSync(
    $customerId: ID!
    $workspaceId: ID!
    $resources: [PerformanceCenterCustomerResourceInputType!]!
  ) {
    addResourcesSync(customerId: $customerId, workspaceId: $workspaceId, resources: $resources) {
      ...PerformanceCenterCustomerResource
    }
  }
  ${FRAGMENT_CUSTOMER_RESOURCE}
`;

export const createResourcesSync = ({ customerId, workspaceId, resources }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, workspaceId, resources },
  }).then(({ data }) => getSingleProp(data));
};
