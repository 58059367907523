import { useCallback } from 'react';
import { useErrorHandler } from 'src/services';
import { BENCHMARK_POPUP } from '../types';
import { usePopups } from '../services';

/**
 * Hook for managing the benchmark popup.
 * @returns {Function} A function to open the benchmark popup.
 */
export const useBenchmarkPopup = () => {
  const popups = usePopups();
  const handleError = useErrorHandler();

  /**
   * Display benchmark popup
   * @param {BenchmarkPopupParams} params - The parameters for the benchmark popup.
   * @returns {Promise<void>}
   */
  return useCallback(
    /**
     * Opens the benchmark popup and handles user interactions.
     * @param {Object} params - The parameters for the benchmark popup
     * @param {string} params.assetType - asset type
     * @param {string} params.channel - channel
     * @param {boolean} params.loadingBenchmark - whether the benchmark is loading
     * @param {Function} params.onBenchmarkApplyClick - callback to be called when the benchmark is applied
     * @param {Object} params.benchmarkConfig - variation benchmark configuration
     * @returns {Promise<void>}
     */
    ({ assetType, channel, loadingBenchmark, onBenchmarkApplyClick, benchmarkConfig }) => {
      return new Promise((resolve) => {
        popups.openPopup(BENCHMARK_POPUP, {
          onClose: () => {
            popups.closePopup(BENCHMARK_POPUP);
            resolve();
          },
          onSubmit: async (userGoal, selectedAccount, comparisonSetName) => {
            try {
              popups.closePopup(BENCHMARK_POPUP);
              onBenchmarkApplyClick(userGoal, selectedAccount, comparisonSetName);
            } catch (error) {
              handleError(error);
              resolve();
            }
          },
          assetType,
          channel,
          loadingBenchmark,
          benchmarkConfig,
        });
      });
    },
    [popups, handleError]
  );
};
