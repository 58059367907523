import { useEffect } from 'react';

import { activateWorkspaceInExternalExtension } from 'src/data/infinity';
import { isApplicationModeInfinity } from 'src/data/system';
import { useCurrentCustomerId, useCurrentWorkspaceId } from 'src/store';

/**
 * Notify "external" Extension that customer/workspace was changed in the Platform
 */
export const useNotifyAboutWorkspaceChanges = () => {
  const customerId = useCurrentCustomerId();
  const workspaceId = useCurrentWorkspaceId();

  useEffect(() => {
    // "embedded" Platform should not send out "external" messages
    if (isApplicationModeInfinity()) {
      return;
    }

    // We care only about full workspace data, skipping what we might have during Platform loading flow
    if (!customerId || !workspaceId) {
      return;
    }

    activateWorkspaceInExternalExtension(customerId, workspaceId).catch(() => {
      /* do nothing */
    });
  }, [customerId, workspaceId]);
};
