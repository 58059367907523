import React, { useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export const SvgStringToHtml = ({ className, svgString, classes }) => {
  const useApp = () => {
    const svgWrapperRef = useRef();
    React.useEffect(() => {
      svgWrapperRef.current.innerHTML = svgString;
    }, []);
    return {
      svgWrapperRef,
    };
  };
  const { svgWrapperRef } = useApp();
  return (
    <div
      ref={svgWrapperRef}
      className={cx(className, classes.iconSvgString)}
    ></div>
  );
};

SvgStringToHtml.propTypes = {
  svgString: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.shape({
    iconSvgString: PropTypes.string,
  }),
};

SvgStringToHtml.defaultProps = {
  className: '',
  classes: {},
};
