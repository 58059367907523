import { SLATE_TEXT_FORMATS } from 'src/lib/slate';

export const EDITOR_TOOLBAR_BUTTON_HEIGHT = 24;
export const EDITOR_TOOLBAR_BUTTON_WIDTH = 24;
export const EDITOR_TOOLBAR_BUTTON_SPACING = 4;
export const EDITOR_TOOLBAR_GROUP_SPACING = 16;

export const VOID_ELEMENTS = {
  DIVIDER: 'divider',
};

export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const LIST_TYPES = [SLATE_TEXT_FORMATS.NUMBERED_LIST, SLATE_TEXT_FORMATS.BULLETED_LIST];

export const HEADING_TYPES = [
  SLATE_TEXT_FORMATS.HEADING_ONE,
  SLATE_TEXT_FORMATS.HEADING_TWO,
  SLATE_TEXT_FORMATS.HEADING_THREE,
  SLATE_TEXT_FORMATS.HEADING_FOUR,
  SLATE_TEXT_FORMATS.HEADING_FIVE,
  SLATE_TEXT_FORMATS.HEADING_SIX,
];
