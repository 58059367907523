import { useEffect } from 'react';

import { useMainActions } from '../store';
import { ITEM_TYPE } from '../lib';

export const useAutoExpandActiveMenuItem = (navigationItems, activePath) => {
  const pathName = activePath?.split('?')?.[0];
  const mainActions = useMainActions();
  //link, menu, collapse are clickable . submenu - opens upon hover
  //link|menu
  //  menu -> link|collapse
  //    collapse -> link|submenu
  //      submenu -> link
  useEffect(() => {
    navigationItems.forEach((navItem) => {
      if ([ITEM_TYPE.submenu, ITEM_TYPE.link].includes(navItem.type)) {
        return;
      }

      if (navItem.type === ITEM_TYPE.menu) {
        navItem.items.forEach((menuItem) => {
          if (menuItem.type === ITEM_TYPE.link) {
            if (pathName.startsWith(menuItem.path)) {
              mainActions.toggleMenuItem(navItem.id, true);
            }
          } else if (menuItem.type === ITEM_TYPE.collapse) {
            menuItem.items.forEach((collapseItem) => {
              if (collapseItem.type === ITEM_TYPE.link || collapseItem.type === ITEM_TYPE.submenu) {
                if (pathName.startsWith(collapseItem.path)) {
                  mainActions.expandMenuState({
                    menuItemId: navItem.id,
                    collapseItemId: menuItem.id,
                  });
                }
              }
            });
          }
        });
      }
    });
  }, [navigationItems, pathName, mainActions]);
};
