import { defineEnumClampFunction } from 'src/lib/enum';

export const APPLICATION_BAR_HEIGHT = 66;

export const DOCUMENT_SCORE_PANEL_WIDTH = 54;
export const DOCUMENT_SCORE_PANEL_HEIGHT = 57;
export const DOCUMENT_LOCKED_SCORE_PANEL_HEIGHT = 30;
export const DOCUMENT_SCORE_SIZE = 35;
export const DOCUMENT_GENERATION_FORM_WIDTH = 429;
export const DOCUMENT_LEFT_PANEL_WIDTH = 510;

export const VARIATION_DETAILS_PANEL_WIDTH = 520;
export const BLOG_ACTIONS_BAR_HEIGHT = 54;
export const TEXT_DETAILS_PANEL_WIDTH = 580;

export const ZENDESK_HEIGHT = 76;
export const TABLE_PAGINATION_HEIGHT = 55;

export const TEXT_DETAILS_PANEL_TAB = {
  performance: 'performance',
  brandPolicy: 'brandPolicy',
};
defineEnumClampFunction(TEXT_DETAILS_PANEL_TAB, TEXT_DETAILS_PANEL_TAB.performance);

export const TEXT_DETAILS_PANEL_TAB_PROPS = {
  [TEXT_DETAILS_PANEL_TAB.performance]: {
    label: 'Performance',
  },
  [TEXT_DETAILS_PANEL_TAB.brandPolicy]: {
    label: 'Brand & policy',
  },
};
