import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ListSubheader } from '@material-ui/core';

import { useStyles } from './useStyles';

export const ItemSubheader = forwardRef(({ className, item }, ref) => {
  const styles = useStyles();

  return (
    <ListSubheader
      className={cx(styles.root, className)}
      ref={ref}
      disableGutters
      disableSticky
    >
      {item.label}
    </ListSubheader>
  );
});

ItemSubheader.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
};

ItemSubheader.defaultProps = {
  className: '',
};
