import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { COMPARISON_SET_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation PerformanceCenterCalculateBenchmarkModel(
    $customerId: ID!
    $accountId: ID!
    $dataSource: PerformanceCenterDataSource!
    $name: String!
    $description: String!
    $assetType: String!
  ) {
    performanceCenterCalculateBenchmarkModel(
      customerId: $customerId
      accountId: $accountId
      dataSource: $dataSource
      name: $name
      description: $description
      assetType: $assetType
    ) {
      ...ComparisonSet
    }
  }
  ${COMPARISON_SET_FRAGMENT}
`;

export const performanceCenterCalculateBenchmarkModel = ({
  customerId,
  accountId,
  dataSource,
  name,
  description,
  assetType,
}) => {
  const variables = { customerId, accountId, dataSource, name, description, assetType };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
