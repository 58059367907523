import { isEmpty, mapValues } from 'lodash';

import { PerformanceStore } from 'src/store';
import { createStateSelectorHook } from 'src/lib/sweetState';
import { PERFORMANCE_EXTRACTION_STATUS } from 'src/data/performance';

const usePerformanceState = createStateSelectorHook(PerformanceStore, ['userAccounts']);

const CHANNEL_ACCOUNT_STATUS = {
  connectedAccounts: [PERFORMANCE_EXTRACTION_STATUS.running, PERFORMANCE_EXTRACTION_STATUS.done],
  disconnectedAccounts: [
    PERFORMANCE_EXTRACTION_STATUS.disconnected,
    PERFORMANCE_EXTRACTION_STATUS.doneWithErrors,
    PERFORMANCE_EXTRACTION_STATUS.nullableResults,
  ],
  runningAccounts: [PERFORMANCE_EXTRACTION_STATUS.running],
};

export const useUserChannelAccounts = () => {
  const { userAccounts } = usePerformanceState();

  if (isEmpty(userAccounts)) {
    return mapValues(CHANNEL_ACCOUNT_STATUS, () => ({}));
  }

  return Object.keys(CHANNEL_ACCOUNT_STATUS).reduce((statusAcc, status) => {
    const channels = Object.keys(userAccounts || {});
    const accountsPerStatus = { ...statusAcc };

    channels.forEach((channel) => {
      if (!accountsPerStatus[status]) {
        accountsPerStatus[status] = {};
      }

      accountsPerStatus[status][channel] = (userAccounts[channel] || []).filter((account) =>
        CHANNEL_ACCOUNT_STATUS[status].includes(account.status)
      );
    });

    return accountsPerStatus;
  }, {});
};
