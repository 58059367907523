import { GENERATION_TOOL } from 'src/data/generationTool';
import { defineDefaultEnumValue, defineEnumClampFunction } from 'src/lib/enum';
import * as env from 'src/lib/env';

export const INFINITY_EXTENSION_STORE_URL = `https://chrome.google.com/webstore/detail/${env.getInfinityExtensionID()}`;

/**
 * Messages that Extension sends to the "embedded" Platform
 * @readonly
 * @enum {string}
 */
export const INCOMING_MESSAGE = {
  displayGenerationForm: 'displayGenerationForm',
  displayInsightsPanel: 'displayInsightsPanel',
  displaySuggestionsPanel: 'displaySuggestionsPanel',
  displayImportBrief: 'displayImportBrief',
  displayVariationsForm: 'displayVariationsForm',
  windowFocused: 'windowFocused',
  platformInfoCheck: 'platformInfoCheck',
  login: 'login',
  logout: 'logout',
  scoreText: 'scoreText',
  activateWorkspace: 'activateWorkspace',

  // deprecated - starting from from Infinity 0.0.44.1 we track events through BG script
  trackEvent: 'trackEvent',
};

/**
 * Messages that "embedded" Platform sends to the Extension
 * @readonly
 * @enum {string}
 */
export const OUTGOING_MESSAGE = {
  platformReady: 'platformReady',
  platformInfo: 'platformInfo',
  frameViewReady: 'frameViewReady',
  closeFrame: 'closeFrame',
  submitInstructions: 'submitInstructions',
  displayFullscreen: 'displayFullscreen',
  scoreTextResult: 'scoreTextResult',
  displaySuggestionInsights: 'displaySuggestionInsights',
  displayVariationInsights: 'displayVariationInsights',
  applySuggestion: 'applySuggestion',
  classifyTextResult: 'classifyTextResult',
  dictionaryBriefs: 'dictionaryBriefs',
  dictionaryResources: 'dictionaryResources',
  dictionaryWorkspaces: 'dictionaryWorkspaces',
  importedBrief: 'importedBrief',
  switchVariationsGenerationVariant: 'switchVariationsGenerationVariant',
};

/**
 * Messages that "external" Platform sends to the Extension
 * @readonly
 * @enum {string}
 */
export const EXTERNAL_MESSAGE = {
  login: 'login',
  logout: 'logout',
  activateWorkspace: 'activateWorkspace',
};

export const ERROR_CODE = {
  quotaEnded: 'quota_ended',
};

export const DEFAULT_INFINITY_GENERATION_TOOL = GENERATION_TOOL.facebookPrimaryTextProduct;

/**
 * Infinity user flow type
 * @enum {string}
 */
export const INFINITY_USER_FLOW = {
  selectionText: 'selection_text',
  attachedInput: 'attached_input',
};

/**
 * Website host type for Infinity extension
 * This enum should be synced with HOST_TYPE in Infinity extension repo
 *
 * @enum {string}
 */
export const INFINITY_HOST_TYPE = {
  unknown: 'unknown',
  chatgpt: 'chatgpt',
  canva: 'canva',
  notion: 'notion',
  google: 'google',
  googleAds: 'googleAds',
  googleDocs: 'googleDocs',
  googleSheets: 'googleSheets',
  gmail: 'gmail',
  facebook: 'facebook',
  metaAds: 'metaAds',
  instagram: 'instagram',
  twitter: 'twitter',
  twitterAds: 'twitterAds',
  youtube: 'youtube',
  linkedIn: 'linkedIn',
  linkedInAds: 'linkedInAds',
};
defineDefaultEnumValue(INFINITY_HOST_TYPE, INFINITY_HOST_TYPE.unknown);
defineEnumClampFunction(INFINITY_HOST_TYPE, INFINITY_HOST_TYPE.unknown);

/**
 * Variations generation view variant
 * @enum {string}
 */
export const VARIATIONS_GENERATION_VARIANT = {
  default: 'default',
  advanced: 'advanced',
};
