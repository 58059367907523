import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 753,
    },
    dialogContent: {
      '&:first-child': {
        padding: theme.spacing(2, 5, 4),
      },
    },
    field: {
      width: '100%',
      margin: theme.spacing(1, 0, 0.5),
    },
  }),
  { name: 'TemplatesExpertEditPopup' }
);
