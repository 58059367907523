import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query GeneratePreview($landingPageAssetId: ID!, $htmlText: String!) {
    generatePreview(landingPageAssetId: $landingPageAssetId, htmlText: $htmlText) {
      screenshotBase64
      screenshotId
      screenshotUrl
    }
  }
`;

export const queryCOGeneratePreview = ({ landingPageAssetId, htmlText }, options) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      landingPageAssetId,
      htmlText,
    },
    options,
  }).then(({ data }) => getSingleProp(data));
};
