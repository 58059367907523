import { useCallback, useMemo } from 'react';
import { flatConnectedAccounts } from 'src/data/performance';

import * as events from 'src/lib/events';
import * as gtm from 'src/lib/gtm';
import { PAGES, useTrackingEvent, useTrackGtmEvent, useChannelAccounts } from 'src/services';
import { getIntegrationFromChannel } from 'src/utils/trackingEvents';

const getCipChannel = (channel) => `${channel}_cip`;

export const useCipEvents = () => {
  const trackingEvent = useTrackingEvent();
  const trackGtmEvent = useTrackGtmEvent();
  const { connectedAccounts } = useChannelAccounts();
  const flattenConnectedAccounts = flatConnectedAccounts(connectedAccounts);

  const trackToggleTable = useCallback(
    (channel, table) => {
      trackingEvent(events.CIP.toggleTable, {
        table,
        integration: getIntegrationFromChannel(channel),
        page: PAGES.copyAnalytics,
      });
    },
    [trackingEvent]
  );

  const trackConnectAdAccounts = useCallback(
    (channel, accountId, action = '') => {
      const attributes = {
        extraData: { accountId },
        integration: getIntegrationFromChannel(channel),
        page: PAGES.resourcesIntegrations,
        action,
      };

      trackingEvent(events.CIP.connectAdAccounts, attributes);
      trackGtmEvent(gtm.EVENTS.cipAccountsConnected, attributes);

      const isFirstIntegration = !flattenConnectedAccounts?.length < 1;

      if (isFirstIntegration) {
        trackGtmEvent(gtm.EVENTS.firstIntegration);
      }
    },
    [trackingEvent, trackGtmEvent, flattenConnectedAccounts]
  );

  const trackImproveButtonClick = useCallback(
    (channel, generationTool) => {
      trackingEvent(events.CIP.improveButtonClick, {
        channel: getCipChannel(channel),
        page: PAGES.copyAnalytics,
        generationTool,
      });
    },
    [trackingEvent]
  );

  const trackPageVisited = useCallback(
    (event, page) => {
      trackingEvent(event, { page });
    },
    [trackingEvent]
  );

  const trackFilterApplied = useCallback(
    (channel, filterType) => {
      trackingEvent(events.CIP.filterApplied, {
        filterType,
        integration: getIntegrationFromChannel(channel),
        page: PAGES.copyAnalytics,
      });
    },
    [trackingEvent]
  );

  const trackAddResource = useCallback(
    (fileName) => {
      trackingEvent(events.CIP.resourceAdded, {
        page: PAGES.resourcesIntegrations,
        extraData: {
          fileName,
        },
      });
    },
    [trackingEvent]
  );

  const trackChannelConnected = useCallback(
    (channel, origin) => {
      trackingEvent(events.CIP.channelConnected, {
        channel,
        origin,
        page: PAGES.resourcesIntegrations,
      });
    },
    [trackingEvent]
  );

  const trackConnectToChannel = useCallback(
    (channel, origin) => {
      trackingEvent(events.CIP.connectChannelClick, {
        channel,
        origin,
        page: PAGES.resourcesIntegrations,
      });
    },
    [trackingEvent]
  );

  const trackDisconnectChannel = useCallback(
    (channel) => {
      trackingEvent(events.CIP.disconnectChannel, {
        channel,
        page: PAGES.resourcesIntegrations,
      });
    },
    [trackingEvent]
  );

  return useMemo(
    () => ({
      trackToggleTable,
      trackConnectAdAccounts,
      trackImproveButtonClick,
      trackPageVisited,
      trackFilterApplied,
      trackChannelConnected,
      trackDisconnectChannel,
      trackConnectToChannel,
      trackAddResource,
    }),
    [
      trackToggleTable,
      trackConnectAdAccounts,
      trackImproveButtonClick,
      trackPageVisited,
      trackFilterApplied,
      trackChannelConnected,
      trackDisconnectChannel,
      trackConnectToChannel,
      trackAddResource,
    ]
  );
};
