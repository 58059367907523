import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { SUBSCRIPTION_PLAN, SUBSCRIPTION_TIER } from 'src/data/subscription';
import { PlanFeature } from '../PlanFeature';
import useStyles from './useStyles';

export const SubscriptionPlanFeatures = memo(
  ({ className, classes, features, textFormatObject, removed, currentPlan, currentTier }) => {
    const styles = useStyles({ classes });

    return (
      <div className={cx(styles.root, className)}>
        {features.map((feature) => (
          <PlanFeature
            className={styles.feature}
            key={feature.text}
            feature={feature}
            textFormatObject={textFormatObject}
            removed={removed}
            notFeature={feature.notFeature}
            plan={currentPlan}
            tier={currentTier}
          />
        ))}
      </div>
    );
  }
);

SubscriptionPlanFeatures.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  features: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      isHeader: PropTypes.bool,
      items: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
  textFormatObject: PropTypes.shape(),
  removed: PropTypes.bool,
  currentPlan: PropTypes.oneOf(Object.values(SUBSCRIPTION_PLAN)),
  currentTier: PropTypes.oneOf(Object.values(SUBSCRIPTION_TIER)),
};

SubscriptionPlanFeatures.defaultProps = {
  className: null,
  classes: null,
  textFormatObject: null,
  removed: false,
};
