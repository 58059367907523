import { authorizeInSalesforce } from 'src/utils/lib/oauth';
import { authorizeSalesforce, querySalesforceAuthorizationLink } from 'src/graphql/salesforce';
import { ChannelAuthError } from 'src/lib/errors';

export const connectToSalesforce = async () => {
  // Query auth link from BE
  const authUrl = await querySalesforceAuthorizationLink();

  // Ask user to allow our app to use their data
  const authData = await authorizeInSalesforce(authUrl);

  if (authData?.code) return authorizeSalesforce(authData.code);

  throw new ChannelAuthError('Error getting Salesforce authorization code');
};
