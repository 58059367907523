import { useCallback, useEffect } from 'react';

import { useUiActions } from 'src/store';

export const useMaintainAppNotificationsHeight = (notificationElement, options) => {
  const uiActions = useUiActions();

  const floating = !!options?.floating;

  const handleResize = useCallback(() => {
    const elementHeight = !floating ? notificationElement?.clientHeight || 0 : 0;
    uiActions.setNotificationsHeight(elementHeight);
  }, [notificationElement, uiActions, floating]);

  // Trigger "resize" if callback changed, for example because of new "notification" element
  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      uiActions.setNotificationsHeight(0);
    };
  }, [handleResize, uiActions]);
};
