import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateAllProjectVariations($projectId: ID!, $scoringMethod: ScoringMethodInput!) {
    updateAllProjectVariations(projectId: $projectId, scoringMethod: $scoringMethod) {
      id
    }
  }
`;

export const updateAllProjectVariations = ({ projectId, scoringMethod }, options) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: {
      projectId,
      scoringMethod,
      forceScoring: true,
    },
    options,
  }).then(({ data }) => getSingleProp(data));
};
