import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { useStyles } from './useStyles';
import { useCalculateMetaFromText } from './useCalculateMetaFromText';

export const CopyMetadata = ({ copyText }) => {
  const styles = useStyles();
  const { readingTimeFormatted, wordsCount } = useCalculateMetaFromText(copyText);

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <Typography className={styles.key}>Reading time</Typography>
        <Typography className={styles.value}>{readingTimeFormatted}</Typography>
      </div>
      <div className={styles.row}>
        <Typography className={styles.key}>Words</Typography>
        <Typography className={styles.value}>{wordsCount}</Typography>
      </div>
    </div>
  );
};

CopyMetadata.propTypes = {
  copyText: PropTypes.string.isRequired,
};
