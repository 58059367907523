import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { CUSTOMER_BILLING_CONFIGURATION_FRAGMENT } from './schema';

const QUERY = gql`
  query CustomerQueryQuota($id: ID!) {
    customer(id: $id) {
      id
      lifecycle
      billingConfiguration {
        ...CustomerBillingConfiguration
      }
    }
  }
  ${CUSTOMER_BILLING_CONFIGURATION_FRAGMENT}
`;

export const queryCustomerBillingConfig = (id, options = null) =>
  executeGqlQuery({
    query: QUERY,
    variables: { id },
    options: {
      abortSignal: options?.abortSignal,
    },
  }).then(({ data }) => getSingleProp(data));
