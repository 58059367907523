import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomTypography } from 'src/components/CustomTypography';
import { TruncatedTooltip } from 'src/components/TruncatedTooltip';
import { useStyles } from './useStyles';

export const TruncatedTypography = ({
  className,
  classes,
  children,
  title,
  variant,
  hideTooltip,
  ...restProps
}) => {
  const styles = useStyles({ classes });

  return (
    <TruncatedTooltip
      title={hideTooltip ? '' : title || children}
      className={styles.tooltip}
      {...restProps}
    >
      {(ref) => (
        <CustomTypography
          className={cx(styles.root, className)}
          ref={ref}
          variant={variant}
        >
          {children}
        </CustomTypography>
      )}
    </TruncatedTooltip>
  );
};

TruncatedTypography.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  hideTooltip: PropTypes.bool,
};
TruncatedTypography.defaultProps = {
  className: '',
  classes: null,
  title: null,
  hideTooltip: false,
};
