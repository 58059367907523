import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    root: {},
    icon: {
      height: 26,
      width: 28,
    },
    contentWrapper: {},
    title: {},
    annotations: {},
    annotation: {
      flex: 1,
    },
  }),
  { name: 'Loader' }
);
