import { useCallback, useEffect, useState } from 'react';
import { Editor, Range as SlateRange, Text as SlateText } from 'slate';
import { compact } from 'lodash';

import {
  getCharactersPointsBoundaries,
  getEditorParagraphs,
  getParagraphsCharacterPoints,
} from 'src/lib/slate';

const getCharBoundaries = (charsData, paragraph, start, end) => {
  const paragraphChars = charsData[paragraph];
  if (!paragraphChars || paragraphChars.length === 0 || !(start >= 0 && end >= 0)) {
    return null;
  }

  return getCharactersPointsBoundaries(paragraphChars, start, end);
};

const calculateNewRangeRefs = (editor, marks) => {
  if (!marks || marks.length === 0) {
    return [];
  }

  const result = [];
  const paragraphs = getEditorParagraphs(editor);
  const charsData = getParagraphsCharacterPoints(editor, [...paragraphs]);

  marks.forEach(({ type, paragraph, start, end }) => {
    const boundaries = getCharBoundaries(charsData, paragraph, start, end);
    if (!boundaries) {
      return;
    }

    const rangeRef = Editor.rangeRef(
      editor,
      {
        anchor: boundaries.start,
        focus: boundaries.end,
        decoration: type,
      },
      { affinity: 'inward' }
    );
    result.push(rangeRef);
  });

  return result;
};

const getIntersectionsWithNode = (editor, rangeRefs, nodePath) => {
  const nodeRange = Editor.range(editor, nodePath);
  const intersections = rangeRefs.map((rangeRef) => {
    if (!rangeRef.current) {
      return null;
    }
    return SlateRange.intersection(rangeRef.current, nodeRange);
  });
  return compact(intersections);
};

export const useDecoratePositionedMarks = (editor, marks) => {
  const [rangeRefs, setRangeRefs] = useState([]);

  useEffect(() => {
    if (!editor || !marks || marks.length === 0) {
      return;
    }

    // When "positioned marks" change, we should calculate new range references
    const newRangeRefs = calculateNewRangeRefs(editor, marks);

    // Old references should be cleared and replaced with the new ones
    setRangeRefs((prevRangeRefs) => {
      prevRangeRefs.forEach((ref) => ref.unref());
      return newRangeRefs;
    });
  }, [editor, marks]);

  return useCallback(
    ([node, path]) => {
      // We care only about "text" nodes, as we highlight only them
      if (!SlateText.isText(node)) {
        return [];
      }

      return getIntersectionsWithNode(editor, rangeRefs, path);
    },
    [editor, rangeRefs]
  );
};
