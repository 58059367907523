import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconYoutube = memo(
  forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
      <path
        d="M14.9925 6.60156C14.9925 6.60156 7.49157 6.60157 5.60893 7.09077C4.6009 7.37242 3.77086 8.20247 3.4892 9.22533C3.00001 11.108 3 15.0068 3 15.0068C3 15.0068 3.00001 18.9204 3.4892 20.7734C3.77086 21.7963 4.58607 22.6115 5.60893 22.8931C7.5064 23.3972 14.9925 23.3972 14.9925 23.3972C14.9925 23.3972 22.5084 23.3972 24.391 22.908C25.4139 22.6263 26.229 21.8259 26.4959 20.7882C26.9999 18.9204 26.9999 15.0216 26.9999 15.0216C26.9999 15.0216 27.0147 11.108 26.4959 9.22533C26.229 8.20247 25.4139 7.38726 24.391 7.12043C22.5084 6.60159 14.9925 6.60156 14.9925 6.60156V6.60156ZM12.6059 11.4046L18.8468 15.0068L12.6059 18.5941V11.4046V11.4046Z"
        fill="#FF0000"
      />
    </SvgIcon>
  ))
);
