import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { trim } from 'lodash';

import { useCustomFeatureLimitsData } from 'src/store';
import { extractPerformanceCenterBenchmarks } from 'src/graphql/performance';
import { doesParsedGraphQLErrorContainErrorCode, parseGraphQLError } from 'src/lib/graphql';
import { BENCHMARK_ERROR } from 'src/data/benchmark';
import { FEATURE_LIMITS, featureHasUsages } from 'src/data/featureLimits';
import { isAbortError } from 'src/lib/errors';

const DEFAULT_DEBOUNCE_TIMEOUT = 500;

export const useBenchmarkDebounced = (
  { projectId, assetType, text, adAccountId, userGoal, comparisonSetName, variationId },
  hookOptions
) => {
  const featureLimitsData = useCustomFeatureLimitsData(FEATURE_LIMITS.resourcesRowsPerCustomer);

  const disableRequests = hookOptions?.disabled || false;
  const debounceTimeout = hookOptions?.debounceTimeout || DEFAULT_DEBOUNCE_TIMEOUT;
  const refreshKey = hookOptions?.refreshKey || 0;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Debounced "fetch" function
   */
  const fetchData = useDebouncedCallback(async (requestParams, requestOptions) => {
    try {
      const result = await extractPerformanceCenterBenchmarks(requestParams, requestOptions);
      setData(result);
    } catch (error) {
      if (isAbortError(error)) {
        return;
      }

      const parsedError = parseGraphQLError(error);
      const hasNotEnoughDatasetError = doesParsedGraphQLErrorContainErrorCode({
        parsedError,
        errorCode: BENCHMARK_ERROR.notEnoughDatasetError,
      });
      const hasSystemError = doesParsedGraphQLErrorContainErrorCode({
        parsedError,
        errorCode: BENCHMARK_ERROR.systemError,
      });
      if (hasNotEnoughDatasetError) {
        setData({
          adAccountId,
          comparisonSet: { userGoal, name: comparisonSetName },
          shouldUpgrade: !featureHasUsages(featureLimitsData),
        });
      } else if (hasSystemError) {
        setData({
          adAccountId,
          comparisonSet: { userGoal, name: comparisonSetName },
          position: -1,
        });
      }
    } finally {
      setLoading(false);
    }
  }, debounceTimeout);

  /**
   * React to input parameters changes
   */
  useEffect(() => {
    // Clear and do not query, if host component doesn't want it now
    if (disableRequests) {
      setData(null);
      return undefined;
    }

    // Return early, if no "text" to analyze was provided
    if (trim(text).length === 0) {
      setData(null);
      return undefined;
    }

    // Mark as "loading" even when we wait for "debounced" request to fire
    setLoading(true);

    // Schedule debounced request
    const abortController = new AbortController();

    fetchData(
      {
        projectId,
        assetType,
        text,
        adAccountId,
        userGoal,
        comparisonSetName,
        variationId,
      },
      {
        abortSignal: abortController.signal,
      }
    );

    // Clean up possible pending requests when we want to schedule new request or component unmounts
    return () => {
      abortController.abort();
      setLoading(false);
    };
  }, [
    disableRequests,
    fetchData,
    projectId,
    assetType,
    text,
    adAccountId,
    userGoal,
    comparisonSetName,
    variationId,
    refreshKey,
  ]);

  return useMemo(
    () => ({
      benchmarkConfig: data,
      loadingBenchmark: loading,
    }),
    [data, loading]
  );
};
