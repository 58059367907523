import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const Loader = ({ className }) => {
  const styles = useStyles();

  return (
    <div className={cx(className, styles.root)}>
      <Skeleton
        variant="wave"
        className={styles.rect}
      />
      <Skeleton
        variant="wave"
        className={styles.rectSmall}
      />
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
};

Loader.defaultProps = {
  className: null,
};
