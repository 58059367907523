import * as Sentry from '@sentry/react';

import * as storage from 'src/lib/storage';
import * as gtm from 'src/lib/gtm';
import * as env from 'src/lib/env';
import {
  shouldSentryEventBeSend,
  prepareIgnoreErrors,
  prepareDenyUrls,
  createSentryPath,
  transformEventBeforeSend,
  getGraphQLBreadcrumbData,
} from './sentryFilters';
import { getEventTitle, SentryTestException } from './utils';

const ALM_DEBUG_FLAG = 'ALM_DEBUG_FLAG';

let initialized = false;

const checkDsn = () => !!env.getSentryDSN();

const reportEventToGTM = (event) => {
  gtm.pushDataLayerEvent(gtm.EVENTS.systemSentryEvent, {
    eventId: event.event_id,
    level: event.level,
    message: getEventTitle(event),
  });
};

export const init = () => {
  if (!checkDsn()) {
    return;
  }

  try {
    Sentry.init({
      dsn: env.getSentryDSN(),
      environment: env.getAppEnv(),

      // Alternatively, use `process.env.npm_package_version` for a dynamic release version
      // if your build tool supports it.
      release: `${process.env.REACT_APP_REPOSITORY_TAG}`,
      integrations: [new Sentry.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0,

      ignoreErrors: prepareIgnoreErrors(),
      denyUrls: prepareDenyUrls(),

      /*
        Can use that to modify the event’s data or drop it completely (by returning null)
        https://docs.sentry.io/platforms/node/configuration/filtering/
       */
      beforeSend: (event, hint) => {
        // setTimeout to add this low priority task to the end of the thread
        setTimeout(() => {
          const flag = storage.readItem(ALM_DEBUG_FLAG);
          if (flag) {
            // eslint-disable-next-line no-console
            console.log('ALM_DEBUG_FLAG.event', event);
            // eslint-disable-next-line no-console
            console.log('ALM_DEBUG_FLAG.hint', hint);
          }
        });

        // Should we drop this event
        if (!shouldSentryEventBeSend(event, hint)) {
          return null;
        }

        // Transform event if needed and return it
        const transformed = transformEventBeforeSend(event, hint);

        // Report event to GTM before sending it
        reportEventToGTM(transformed);
        return transformed;
      },

      beforeBreadcrumb: (breadcrumb, hint) => {
        if (breadcrumb.category === 'ui.click') {
          const { path } = hint.event;
          // eslint-disable-next-line no-param-reassign
          breadcrumb.message = createSentryPath(path);
        }

        if (breadcrumb.category === 'fetch') {
          const graphqlData = getGraphQLBreadcrumbData(breadcrumb, hint);
          if (graphqlData) {
            return {
              ...breadcrumb,
              category: 'graphql',
              data: {
                ...breadcrumb.data,
                ...graphqlData,
              },
            };
          }
        }

        return breadcrumb;
      },
    });

    if (process.env.REACT_APP_REPOSITORY_TAG) {
      Sentry.getCurrentScope().setTag('version', process.env.REACT_APP_REPOSITORY_TAG);
    }
    if (process.env.REACT_APP_COMMIT_HASH) {
      Sentry.getCurrentScope().setTag('commit', process.env.REACT_APP_COMMIT_HASH);
    }

    initialized = true;
  } catch (e) {
    if (process.env.REACT_APP_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn('Error in sentry init', e);
    }
    initialized = false;
  }
};

export const captureException = (error, options) => {
  if (!checkDsn() || !initialized) {
    // Redirect everything to console in 'development' environment
    // eslint-disable-next-line no-console
    console.error(error, options);
    return;
  }

  Sentry.captureException(error, options);
};

export const captureMessage = (message, options) => {
  const alignedOptions = options || {};
  if (!alignedOptions.level) {
    alignedOptions.level = 'info';
  }

  if (!checkDsn() || !initialized) {
    // Redirect everything to console in 'development' environment
    // eslint-disable-next-line no-console
    console.log(message, alignedOptions);
    return;
  }

  Sentry.captureMessage(message, alignedOptions);
};

export const showReportDialog = () => {
  if (!checkDsn() || !initialized) {
    return;
  }

  Sentry.showReportDialog();
};

export const sentrySetUser = ({ createdAt, email, id, isConfirmed, isKeywee, customers }) => {
  Sentry.setUser({
    createdAt,
    email,
    uid: id,
    isConfirmed,
    isKeywee,
    customers: customers.map((customer) => customer.name).join(','),
  });
};

export const sentryClearUser = () => {
  Sentry.getCurrentScope().setUser(null);
};

/*
  Sentry Debug Helper
 */
window.sentryTest = (message, attributes) => {
  captureMessage(message || 'Test message xxx', attributes);
};
window.sentryTestException = (message, attributes) => {
  captureException(new SentryTestException(message || 'Test exception'), attributes);
};
