import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},

    keywordsCounter: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.gray.gray30,
    },

    dotDelimiter: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.gray.gray40,
    },

    subtitle: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(0.75),
    },

    keywordsGroups: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(5),
    },

    targetCounter: {
      color: '#8FB1FF',
      background: '#F0F5FF',
    },
    relatedCounter: {
      color: COLORS.warning.active,
      background: 'rgba(255, 237, 191, 0.25)',
    },
    border: {
      border: '1px solid #E9E9E9',
    },
  }),
  { name: 'SEOTracker' }
);
