import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(2),
    },
    container: {
      gap: theme.spacing(2),
    },

    annotationsGroups: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
    },

    expandButton: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      justifyContent: 'left',
      width: 'fit-content',
    },

    boxGroupTitle: {},
  }),
  { name: 'TalkingPoints' }
);
