import { Editor as SlateEditor, Range as SlateRange } from 'slate';
import { ReactEditor } from 'slate-react';

export const editorHasValidSelection = (editor) => {
  const { selection } = editor;
  return (
    selection != null &&
    !SlateRange.isCollapsed(selection) &&
    SlateEditor.string(editor, selection).length > 0
  );
};

export const editorIsFocusedAndHasValidSelection = (editor) => {
  return ReactEditor.isFocused(editor) && editorHasValidSelection(editor);
};
