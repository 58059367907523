import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconDot = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 8 8"
    >
      <circle
        cx="4"
        cy="4"
        r="4"
        fill="currentColor"
      />
    </SvgIcon>
  ))
);
