import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query GenerationToolSettings {
    generationToolSetting {
      useCase
      badgeNew
      badgeImproved
    }
  }
`;

export const queryGenerationToolSettings = () => {
  return executeGqlQuery({
    query: QUERY,
  }).then(({ data }) => getSingleProp(data));
};
