import { useEffect } from 'react';

import { useBrandTermsActions } from 'src/store';
import { useErrorHandler } from 'src/services';

export const usePreloadBrandTerms = (brandVoice) => {
  const handleError = useErrorHandler();
  const brandTermsActions = useBrandTermsActions();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await brandTermsActions.queryBrandTerms(brandVoice.id);
      } catch (error) {
        handleError(error);
      }
    };

    if (brandVoice?.id) {
      fetchData();
    }
  }, [brandVoice, handleError, brandTermsActions]);
};
