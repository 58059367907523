import { Editor, Element, Transforms } from 'slate';

import { DEFAULT_SLATE_VALUE } from 'src/lib/slate';

export const withNormalizedPlaceholder = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // Reset formatting of the single empty node, so placeholder will always look the same
    if (Element.isElement(node)) {
      const isSingleNode = editor.children.length === 1;
      const isNodeEmpty = Editor.isEmpty(editor, node);
      if (isSingleNode && isNodeEmpty) {
        Transforms.setNodes(editor, DEFAULT_SLATE_VALUE[0], { at: path });
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  return editor;
};
