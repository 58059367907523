import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_AUDIENCE_TARGET } from './schema';

const MUTATION = gql`
  mutation RemoveAudienceTarget($projectId: Int!, $id: ID!) {
    removeAudienceTarget(projectId: $projectId, id: $id) {
      ...AudienceTarget
    }
  }
  ${FRAGMENT_AUDIENCE_TARGET}
`;

export const deleteAudienceTargeting = ({ projectId, id }) => {
  const variables = { projectId, id };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
