import { round } from 'lodash';
import { DateTime } from 'luxon';

import {
  ANNUAL_BILLING_CYCLE_DISCOUNT_PERCENT,
  BILLING_CYCLES,
  BILLING_METHOD_TYPE,
  SUBSCRIPTION_DATA_DRIVEN_TIER,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_STATUS,
} from './const';
import {
  getSubscriptionPlanWordsLimit,
  getSubscriptionPlanTiers,
  isDDTeamsPlan,
  isStarterPlan,
  isBusinessSelfServePlan,
} from './subscriptionPlan';
import { getSubscriptionTierPrice, getSubscriptionTierWordsLimit } from './subscriptionTier';
import { getCustomerBillingPeriodEnd, getTierOrPlanProperty } from './utils';

export const getSubscriptionPrice = (
  plan,
  tier,
  billingCycle = BILLING_CYCLES.monthly,
  fullPlanCostUsd,
  forceMonthlyCalculation
) => {
  let price = fullPlanCostUsd || getTierOrPlanProperty(plan, tier, 'price', 0);
  if (billingCycle === BILLING_CYCLES.annually) {
    if (isDDTeamsPlan(plan) || isStarterPlan(plan) || isBusinessSelfServePlan(plan)) {
      const annualPrice = getTierOrPlanProperty(plan, tier, 'priceAnnually', 0);
      if (forceMonthlyCalculation && price !== annualPrice) {
        price = price / 12;
      } else {
        price = getTierOrPlanProperty(plan, tier, 'priceAnnuallyPerMonth', 0);
      }
    } else {
      price *= 1 - ANNUAL_BILLING_CYCLE_DISCOUNT_PERCENT;
    }
  }
  return price;
};

export const getSubscriptionFullPeriodPrice = (plan, tier, billingCycle) => {
  const monthlyPrice = getSubscriptionPrice(plan, tier, billingCycle);

  if (billingCycle === BILLING_CYCLES.monthly) {
    return monthlyPrice;
  }

  return getTierOrPlanProperty(plan, tier, 'priceAnnually', false);
};

export const getNextPricedTier = (currentPlan, currentTier) => {
  if (!currentTier) {
    return null;
  }

  const planTiers = getSubscriptionPlanTiers(currentPlan);

  if (!planTiers) {
    return null;
  }
  const tierPrice = getSubscriptionPrice(currentPlan, currentTier);
  const tierPriceMap = Object.values(planTiers)
    .filter((value) => value !== SUBSCRIPTION_DATA_DRIVEN_TIER.pro_beta_agency_tier_1)
    .map((value) => ({
      value,
      price: getSubscriptionTierPrice(value, currentPlan),
    }));

  const pricierTiers = tierPriceMap.filter((data) => data.price > tierPrice);
  const nextPricedTierData = pricierTiers.reduce(
    (acc, data) => (acc && acc.price < data.price ? acc : data),
    null
  );

  return nextPricedTierData ? nextPricedTierData.value : null;
};

export const getNextBillingPeriodStart = (customer) => {
  const currentEnd = getCustomerBillingPeriodEnd(customer);

  return getNextBillingPeriodStartByPeriodEnd(currentEnd);
};

export const getNextBillingPeriodStartByPeriodEnd = (periodEnd) => {
  if (!periodEnd) {
    return null;
  }

  const endDate = DateTime.fromISO(periodEnd);
  const nextStartDate = endDate.plus({ days: 1 });

  return nextStartDate.toISODate();
};

export const calculateTierPriceInfo = (price, percentOff) => {
  const result = {
    price,
    discount: 0,
    priceWithDiscount: price,
  };
  if (percentOff > 0) {
    result.discount = round((result.price / 100) * percentOff, 2);
    result.priceWithDiscount = result.price - result.discount;
  }

  return result;
};

export const getSubscriptionTierPlanLimitChange = (oldTier, plan) => {
  const oldLimit = getSubscriptionTierWordsLimit(oldTier);
  const newLimit = getSubscriptionPlanWordsLimit(plan);
  return newLimit - oldLimit;
};

export const getSubscriptionTiersLimitChange = (oldTier, newTier) => {
  if (oldTier === newTier) {
    return 0;
  }

  const oldLimit = getSubscriptionTierWordsLimit(oldTier);
  const newLimit = getSubscriptionTierWordsLimit(newTier);
  return newLimit - oldLimit;
};

export const getSubscriptionTiersPriceChange = (
  currentPlan,
  currentTier,
  newTier,
  currentPercentOff = 0,
  billingCycle = BILLING_CYCLES.monthly
) => {
  if (currentTier === newTier) {
    return 0;
  }

  const currentPrice = getSubscriptionPrice(currentPlan, currentTier, billingCycle);
  const currentInfo = calculateTierPriceInfo(currentPrice, currentPercentOff);
  const newPrice = getSubscriptionPrice(currentPlan, newTier, billingCycle);
  return newPrice - currentInfo.priceWithDiscount;
};

export const promotionActiveForTier = (promotionInfo, tier) => {
  if (!promotionInfo) {
    return false;
  }

  const validTiers = promotionInfo.validTiers || [];
  return validTiers.includes(tier);
};

export const promotionCodeValidForPlan = (promotionInfo, plan) => {
  if (!promotionInfo) {
    return false;
  }

  const validPlans = promotionInfo.validPlans || [];
  return validPlans.includes(plan);
};

export const promotionValidForPrice = (promotionInfo, plan, tier, billingCycle) => {
  if (!promotionInfo) {
    return false;
  }

  if (promotionInfo.minimumPrice) {
    const price = getSubscriptionFullPeriodPrice(plan, tier, billingCycle);
    if (price < promotionInfo.minimumPrice) {
      return false;
    }
  }

  return true;
};

export const canSubscriptionBeChanged = (billingMethod) => {
  if (!billingMethod) {
    return false;
  }

  if (billingMethod.type === BILLING_METHOD_TYPE.stripe) {
    return billingMethod.subscriptionStatus === SUBSCRIPTION_STATUS.active;
  }

  return false;
};

export const canSubscriptionBeCancelled = (billingMethod) => {
  if (!billingMethod) {
    return false;
  }

  if (billingMethod.type === BILLING_METHOD_TYPE.stripe) {
    return (
      billingMethod.subscriptionStatus === SUBSCRIPTION_STATUS.active ||
      billingMethod.subscriptionStatus === SUBSCRIPTION_STATUS.pastDue
    );
  }

  return false;
};

export const canSubscriptionBeReactivated = (billingMethod) => {
  if (!billingMethod) {
    return false;
  }

  if (billingMethod.type === BILLING_METHOD_TYPE.stripe) {
    return billingMethod.subscriptionStatus === SUBSCRIPTION_STATUS.active;
  }

  return false;
};

export const PLAN_PRICES = {
  [SUBSCRIPTION_PLAN.starter]: {
    monthly: 49,
    annual: 39,
    yearlyDiscountPercent: 20,
  },
  [SUBSCRIPTION_PLAN.dd_teams]: {
    monthly: 99,
    annual: 79,
    yearlyDiscountPercent: 20,
  },
  [SUBSCRIPTION_PLAN.business_self_serve]: {
    monthly: 499,
    annual: 349,
    yearlyDiscountPercent: 30,
  },
};

export const SEAT_PRICE_PER_MONTH = {
  monthly: 59,
  annual: 49,
};
