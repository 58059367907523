import PropTypes from 'prop-types';
import { uniqueId, isArray, omit, lowerCase } from 'lodash';

import { countSentences, countTextSyllables, countWords } from 'src/lib/string';

export const getAnnotationGroupByType = (groups, type) => {
  const group = groups.find((group) => group.type === type);
  return group?.value || [];
};

const addIdToItem = (annotationItem) => ({
  id: uniqueId('annotation_'),
  ...annotationItem,
});

const addIdToValueItems = (annotationValue) => {
  if (isArray(annotationValue)) {
    return annotationValue.map(addIdToItem);
  }
  return annotationValue;
};

export const addIdToAnnotationsItems = (annotations) =>
  annotations?.map((annotation) => ({
    ...annotation,
    value: addIdToValueItems(annotation.value),
  })) || [];

export const preparePropTypesFromChildComponent = (propTypes) =>
  PropTypes.shape(omit(propTypes, ['className', 'classes']));

export const getScoreDescription = (score, descriptionText = false) => {
  let education;
  let educationInline;
  let description;

  if (score < 10) {
    education = 'Professional';
    educationInline = 'professional';
    description = 'Extremely difficult to read';
  } else if (score < 30) {
    education = 'College graduate';
    educationInline = 'college graduate';
    description = 'Very difficult to read';
  } else if (score < 50) {
    education = 'College student';
    educationInline = 'college';
    description = 'Difficult to read';
  } else if (score < 60) {
    education = 'Grade 10-12';
    educationInline = '10th to 12th grade';
    description = 'Fairly difficult to read';
  } else if (score < 70) {
    education = 'Grade 8-9';
    educationInline = '8th & 9th grade';
    description = 'Plain english';
  } else if (score < 80) {
    education = 'Grade 7';
    educationInline = '7th grade';
    description = 'Fairly easy to read';
  } else if (score < 90) {
    education = 'Grade 6';
    educationInline = '6th grade';
    description = 'Easy to read';
  } else if (score <= 100) {
    education = 'Grade 5';
    educationInline = '5th grade';
    description = 'Very easy to read';
  }

  if (descriptionText) {
    return {
      description: `Based on the Flesch Reading-Ease test this text is ${lowerCase(
        description
      )}. It is likely to be understood by a reader who has at least a ${educationInline} education.`,
    };
  }
  return {
    education,
    description,
  };
};

export const getFleschReadingEaseScore = (copyText) => {
  const wordsCount = countWords(copyText);
  const sentencesCount = countSentences(copyText);
  const syllablesCount = countTextSyllables(copyText);

  const score =
    206.835 - 1.015 * (wordsCount / sentencesCount) - 84.6 * (syllablesCount / wordsCount);

  if (score < 0) {
    return 0;
  }

  if (score > 100) {
    return 100;
  }

  return Math.floor(score);
};
