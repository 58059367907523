import {
  AUDIENCE_TARGET_TYPE,
  TARGET_EVALUATION,
} from 'src/data/audiencesTargeting';
import isURL from 'validator/lib/isURL';
import {
  KEYWORDS_DESCRIPRTION,
  FIRST_STEP_DESCRIPTION,
  STEPS,
  MAX_KEYWORDS_PER_SEGMENT,
} from './const';

export const checkValidState = (state) => {
  if (!state.targetType) {
    return false;
  }

  const fullDataRequired = [
    AUDIENCE_TARGET_TYPE.REFERRER_SOURCE,
    AUDIENCE_TARGET_TYPE.UTM,
  ].includes(state.targetType);
  if (fullDataRequired) {
    return Boolean(state.targetEvaluation && state.value);
  }

  return true;
};

export const checkValidUrl = (state) => {
  const urlValidationRequired =
    state.targetType === AUDIENCE_TARGET_TYPE.REFERRER_SOURCE &&
    state.targetEvaluation === TARGET_EVALUATION.IS_EXACTLY;
  if (urlValidationRequired) {
    return isURL(state.value);
  }

  return true;
};

export const getDescription = (step) =>
  step === STEPS.first ? FIRST_STEP_DESCRIPTION : KEYWORDS_DESCRIPRTION;

export const getStepIndex = (step) => Object.values(STEPS).indexOf(step) + 1;

export const validateKeywordsValue = (value) => {
  if (value.length > MAX_KEYWORDS_PER_SEGMENT) {
    return false;
  }
  return true;
};
