import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { getChannelPerformanceLabel } from 'src/data/channel';
import { TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { ChannelIcon } from 'src/components/ChannelIcon';
import { IconEmail } from 'src/assets/icons/IconEmail';
import { TalkingPoints } from '../../components/TalkingPoints';
import { useStyles } from './useStyles';
import {
  GENERIC_RESOURCE,
  getResourceDocumentLabel,
  isEmailCsvResourceDocument,
} from 'src/data/performance';
import { AVAILABLE_INTEGRATION_CHANNELS } from '../../const';

export const TalkingPointsPopup = ({
  open,
  channel,
  dataSource,
  talkingPoints,
  onClose,
  onCopyTalkingPointClick,
  title,
  label,
  copies,
}) => {
  const styles = useStyles();

  const isEmailResource = dataSource === GENERIC_RESOURCE || isEmailCsvResourceDocument(dataSource);
  const localTitle = isEmailResource
    ? getResourceDocumentLabel(dataSource) || 'Email'
    : getChannelPerformanceLabel(channel);

  return (
    <Dialog
      open={open}
      classes={{ paper: styles.root }}
      onClose={onClose}
      maxWidth="md"
      data-testid="TalkingPointsPopup"
    >
      <DialogTitle
        className={styles.dialogTitle}
        disableTypography
      >
        {isEmailResource ? (
          <IconEmail className={styles.icon} />
        ) : (
          <ChannelIcon
            className={styles.channelIcon}
            channel={channel}
          />
        )}
        <div className={styles.titleWrapper}>
          <TruncatedTypography
            className={styles.title}
            variant={TYPOGRAPHY_VARIANT.medium16}
          >
            {title || localTitle}
          </TruncatedTypography>
          {label && (
            <TruncatedTypography
              className={styles.label}
              variant={TYPOGRAPHY_VARIANT.regular12}
            >
              {label}
            </TruncatedTypography>
          )}
        </div>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <TalkingPoints
          className={styles.talkingPoints}
          channel={channel}
          copies={copies}
          talkingPoints={talkingPoints}
          onCopyTalkingPointClick={onCopyTalkingPointClick}
          isIntegrationChannel={AVAILABLE_INTEGRATION_CHANNELS.includes(channel)}
        />
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button
          className={styles.buttonClose}
          onClick={onClose}
          variant="text"
          color="primary"
          aria-label="Close"
          data-testid="TalkingPointsPopup__CloseButton"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TalkingPointsPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  channel: PropTypes.string,
  talkingPoints: PropTypes.array,
  onCopyTalkingPointClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  label: PropTypes.string,
};
TalkingPointsPopup.defaultProps = {
  className: null,
  open: false,
  channel: null,
  talkingPoints: [],
  title: null,
  label: null,
};
