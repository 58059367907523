import { createContainer } from 'react-sweet-state';

import { ProjectStore } from './ProjectStore';

export const ProjectStoreContainer = createContainer(ProjectStore, {
  onInit: () => ({ setState }, { state }) => {
    if (state) {
      setState(state);
    }
  },
  onUpdate: () => ({ setState }, { state }) => {
    if (state) {
      setState(state);
    }
  },
});
