import { alpha, makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      minWidth: 100,
      height: 32,

      backgroundColor: theme.palette.secondary.main,

      '&:hover': {
        backgroundColor: theme.palette.secondary.main,

        '&[disabled]': {
          backgroundColor: alpha(theme.palette.secondary.main, 0.35),
        },
      },
      '&[disabled]': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.35),
      },
    },
  }),
  { name: 'ViewSubscriptionStatus' }
);
