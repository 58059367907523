import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MenuItem } from '@material-ui/core';

import { ContextMenu } from 'src/components/ContextMenu';
import { IconSettings } from 'src/assets/icons/IconSettings';
import IconDelete from 'src/assets/icons/Delete';
import { WorkspaceName } from '../WorkspaceName';
import { useStyles } from './useStyles';

const propTypes = {
  classes: PropTypes.shape(),
  workspace: PropTypes.shape().isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};
const defaultProps = {
  classes: null,
  selected: false,
};

export const WorkspaceMenuItem = ({
  classes,
  workspace,
  selected,
  onClick,
  onEditClick,
  onDeleteClick,
}) => {
  const styles = useStyles({ classes });

  const handleClick = useCallback(() => {
    onClick(workspace);
  }, [workspace, onClick]);

  const handleDeleteClick = useCallback(
    (workspace) => {
      onDeleteClick(workspace);
    },
    [onDeleteClick]
  );

  const contextMenuItems = useMemo(
    () => [
      {
        id: 'edit',
        dataTestId: `EditWorkspaceButton_${workspace.id}`,
        label: 'Settings',
        onClick: () => onEditClick(workspace),
        icon: <IconSettings style={{ fontSize: 16 }} />,
      },

      {
        id: 'delete',
        dataTestId: `DeleteWorkspaceButton_${workspace.id}`,
        label: 'Delete',
        onClick: () => handleDeleteClick(workspace),
        icon: <IconDelete style={{ fontSize: 16 }} />,
      },
    ],
    [handleDeleteClick, onEditClick, workspace]
  );

  return (
    <MenuItem
      className={cx(styles.root, {
        [styles.selected]: selected,
      })}
      onClick={handleClick}
      data-testid={`workspaceMenuItem_${workspace.id}`}
    >
      <WorkspaceName
        className={styles.labelName}
        name={workspace.name}
        boldName={selected}
      />

      <ContextMenu
        className={styles.menuButton}
        dataTestId={`Workspace_${workspace.id}`}
        items={contextMenuItems}
      />
    </MenuItem>
  );
};

WorkspaceMenuItem.displayName = 'WorkspaceMenuItem';
WorkspaceMenuItem.propTypes = propTypes;
WorkspaceMenuItem.defaultProps = defaultProps;
