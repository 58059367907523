import { useEffect } from 'react';

import { useCurrentUser, useCurrentCustomer } from 'src/store';
import * as LogRocket from 'src/lib/logrocket';

/**
 * Identify user and customer in tracking services
 */
export const UserAnalyticsUpdater = () => {
  const currentUser = useCurrentUser();
  const currentCustomer = useCurrentCustomer();

  useEffect(() => {
    if (!currentUser || !currentCustomer) {
      return;
    }

    // We should actually "start" LogRocket recording only when user is logged-in
    // also, do not record LogRocket sessions for Keywee/Anyword users
    if (!currentUser.isKeywee) {
      LogRocket.start();
    }

    LogRocket.identifyUser({
      user: currentUser,
      customer: currentCustomer,
    });
  }, [currentUser, currentCustomer]);

  return null;
};
