import * as env from 'src/lib/env';
import { openOAuthPopup } from 'src/utils/lib/oauth';

const AUTH_URL = `${env.getBackendRootUrl()}/saml/sign_in`;

const EMAIL = 'foo@teads.com';

export const loginToTeads = () => {
  return openOAuthPopup(`${AUTH_URL}?email=${EMAIL}`);
};
