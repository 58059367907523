import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChannelThoughtLeadershipIcon = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 24 24"
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill="#F8ECDF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7012 6.95999C11.8735 6.89382 12.0642 6.89354 12.2367 6.95919L19.0167 9.53919C19.3087 9.65029 19.5012 9.9307 19.5 10.2431C19.4988 10.5554 19.3041 10.8343 19.0113 10.9432L12.2313 13.4632C12.062 13.5261 11.8757 13.5258 11.7067 13.4624L4.98666 10.9424C4.69493 10.833 4.50122 10.5546 4.50001 10.2431C4.49879 9.93152 4.69032 9.65166 4.98118 9.53999L11.7012 6.95999ZM11.9712 8.46308L7.364 10.2319L11.9712 11.9596L16.6195 10.2319L11.9712 8.46308Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.95 10.5103C8.36421 10.5103 8.7 10.846 8.7 11.2603V14.0732C9.82785 14.7089 10.9254 15.0103 12 15.0103C13.0746 15.0103 14.1721 14.7089 15.3 14.0732V11.2603C15.3 10.846 15.6358 10.5103 16.05 10.5103C16.4642 10.5103 16.8 10.846 16.8 11.2603V14.5003C16.8 14.7594 16.6662 15.0001 16.4462 15.137C14.9987 16.0377 13.5133 16.5103 12 16.5103C10.4867 16.5103 9.00125 16.0377 7.55377 15.137C7.33376 15.0001 7.2 14.7594 7.2 14.5003V11.2603C7.2 10.846 7.53578 10.5103 7.95 10.5103Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51106 9.65039C5.92528 9.65039 6.26106 9.98618 6.26106 10.4004L6.26106 16.8004C6.26106 17.2146 5.92528 17.5504 5.51106 17.5504C5.09685 17.5504 4.76106 17.2146 4.76106 16.8004L4.76106 10.4004C4.76106 9.98618 5.09685 9.65039 5.51106 9.65039Z"
        fill="currentColor"
      />
    </SvgIcon>
  ))
);
