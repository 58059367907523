import { useCallback } from 'react';
import * as events from 'src/lib/events';
import { useGetCommonEventAuthAttributes } from './useGetCommonEventAuthAttributes';
import { useGetSystemEventAttributes } from './useGetSystemEventAttributes';

// Re-exporting these for backwards compatibility
export const PAGES = events.EVENT_PAGE;
export const SUB_PAGES = events.EVENT_SUB_PAGE;

export function useTrackingEvent() {
  const getCommonAuthAttributes = useGetCommonEventAuthAttributes();
  const getSystemEventAttributes = useGetSystemEventAttributes();

  return useCallback(
    (name, attributes = null, options = null) => {
      const mergedAttributes = {
        ...getCommonAuthAttributes(),
        ...getSystemEventAttributes(),
        ...attributes,
      };

      if (options?.noAuth) {
        events.trackUserEventNoAuth(name, mergedAttributes);
      } else {
        events.trackEvent(name, mergedAttributes);
      }
    },
    [getCommonAuthAttributes, getSystemEventAttributes]
  );
}
