import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { GENERATION_TOOL } from 'src/data/generationTool';
import { getGenerationToolIcon } from './utils';
import { SvgStringToHtml } from 'src/components/SvgStringToHtml';

export const GenerationToolIcon = memo(({ className, classes, tool, iconSvgString }) => {
  if (iconSvgString) {
    return (
      <SvgStringToHtml
        className={className}
        classes={classes}
        svgString={iconSvgString}
      />
    );
  }

  const Icon = getGenerationToolIcon(tool);

  return <Icon className={className} />;
});

GenerationToolIcon.propTypes = {
  className: PropTypes.string,
  tool: PropTypes.oneOf(Object.values(GENERATION_TOOL)).isRequired,
  iconSvgString: PropTypes.string,
  classes: PropTypes.shape({
    iconSvgString: PropTypes.string,
  }),
};

GenerationToolIcon.defaultProps = {
  className: null,
  classes: {},
  iconSvgString: null,
};
