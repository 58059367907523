import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { Keyword } from './components';
import { useStyles } from './useStyles';

export const KeywordsGroup = ({
  classes,
  title,
  keywords,
  onToggleMarkedKeyword,
  markedKeywordsMap,
}) => {
  const styles = useStyles({ classes });

  const handleMarkKeyword = useCallback(
    (count, label) => {
      if (count) {
        onToggleMarkedKeyword(label);
      }
    },
    [onToggleMarkedKeyword]
  );

  if (keywords.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Typography className={styles.title}>{title}</Typography>

      <div className={styles.keywordsWrapper}>
        {keywords.map(({ count, label }) => (
          <Keyword
            key={label}
            classes={{ counter: styles.counter }}
            count={count}
            label={label}
            isUsed={!!count}
            clickable={!!count}
            onClick={() => handleMarkKeyword(count, label)}
            isSelected={Object.hasOwn(markedKeywordsMap, label)}
          />
        ))}
      </div>
    </div>
  );
};

KeywordsGroup.propTypes = {
  classes: PropTypes.shape({}),
  keywords: PropTypes.array,
  title: PropTypes.string.isRequired,
  onToggleMarkedKeyword: PropTypes.func.isRequired,
  markedKeywordsMap: PropTypes.objectOf(PropTypes.bool),
};

KeywordsGroup.defaultProps = {
  classes: null,
  keywords: [],
  markedKeywordsMap: {},
};
