import React from 'react';

const KEY_TAB = 9;

const handleKeyDown = (event) => {
  if (event.keyCode === KEY_TAB) {
    event.preventDefault();
  }
};

export default function useInterceptTab() {
  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown, false);

    return () => window.removeEventListener('keydown', handleKeyDown);
  });
}
