import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import mixpanel from 'src/lib/mixpanel';
import { getOnboardingStepsForStage } from 'src/data/onboarding';

const NAME = 'onboarding';

const INITIAL_STATE = {
  currentStage: null,
  steps: [],
  currentStepId: null,

  isActive: false,
  isLastStepActive: false,
  stepsCount: 0,
  currentStepIndex: 0,
};

const getStepAfter = (steps, stepId) => {
  const stepIndex = steps.indexOf(stepId);
  return steps[stepIndex + 1];
};

const PRIVATE_ACTIONS = {
  calculateDependentValues:
    () =>
    ({ getState, setState, dispatch }) => {
      const { steps, currentStepId } = getState();

      setState({
        isLastStepActive: dispatch(PRIVATE_ACTIONS.isLastStep(currentStepId)),
        stepsCount: steps.length,
        currentStepIndex: steps.indexOf(currentStepId),
      });
    },

  isLastStep:
    (step) =>
    ({ getState }) => {
      const { steps } = getState();
      return steps[steps.length - 1] === step;
    },
};

/**
 * Public available actions
 */
const ACTIONS = {
  startOnboarding:
    (stage) =>
    ({ setState, dispatch }) => {
      const steps = getOnboardingStepsForStage(stage);

      setState({
        isActive: true,
        currentStage: stage,
        steps,
        currentStepId: steps[0],
      });

      dispatch(PRIVATE_ACTIONS.calculateDependentValues());
    },

  stopOnboarding:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  finishStep:
    (stepId) =>
    ({ getState, setState, dispatch }) => {
      // Do nothing if onboarding is not active
      const { isActive, currentStepId, steps } = getState();
      if (!isActive) {
        return;
      }

      // Do nothing if we are tying to finish non-current step
      if (currentStepId !== stepId) {
        return;
      }

      // Track that user finished this step on 'mixpanel'
      mixpanel.track(`${mixpanel.EVENTS.ONBOARDING_PREFIX}.${stepId}.done`);

      // Set next step as active one
      const nextStepId = getStepAfter(steps, stepId);
      setState({ currentStepId: nextStepId });

      dispatch(PRIVATE_ACTIONS.calculateDependentValues());
    },

  onPause:
    () =>
    ({ setState }) => {
      setState({ isActive: false });
    },

  onContinue:
    () =>
    ({ setState }) => {
      setState({ isActive: true });
    },

  getIsActive:
    () =>
    ({ getState }) => {
      return getState().isActive;
    },

  getCurrentStage:
    () =>
    ({ getState }) => {
      return getState().currentStage;
    },

  getCurrentStepId:
    () =>
    ({ getState }) => {
      return getState().currentStepId;
    },

  isLastStep: PRIVATE_ACTIONS.isLastStep,
};

export const OnboardingStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});
