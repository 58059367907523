import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_MULTI_PROMPT_TEMPLATES } from './schema';

const QUERY = gql`
  query QueryCustomerMultiPromptTemplates($customerId: ID!, $workspaceId: ID) {
    customerMultiPromptTemplates(customerId: $customerId, workspaceId: $workspaceId) {
      ...CustomerMultiPromptTemplates
    }
  }
  ${FRAGMENT_MULTI_PROMPT_TEMPLATES}
`;

export const queryCustomerMultiPromptTemplates = ({ customerId, workspaceId }) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      customerId,
      workspaceId,
    },
  }).then(({ data }) => {
    return getSingleProp(data).templates;
  });
