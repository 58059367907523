import React from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import cx from 'classnames';
import { Button } from '@material-ui/core';

import { IconNewLock } from 'src/assets/icons/NewLock';
import { useStyles } from './useStyles';

export const SidebarButton = ({
  className,
  classes,
  item,
  active,
  onSubmenuItemClick,
  children,
  onClick,
  activeIcon,
  ...restProps
}) => {
  const styles = useStyles({ classes });

  const StartIcon = (active && activeIcon) || item.icon;

  return (
    <div className={styles.wrapper}>
      <Button
        className={cx(styles.root, className, {
          [styles.active]: active,
          [styles.isEmptySubmenuItem]: item.isEmptyItem,
          [styles.isSubmenu]: item.displaySubmenu,
          [styles.outlined]: Boolean(item.outlined),
          [styles.narrow]: Boolean(item.narrow),
        })}
        classes={{
          label: styles.label,
          startIcon: styles.startIcon,
          endIcon: styles.endIcon,
        }}
        style={item.style}
        onClick={onClick}
        startIcon={StartIcon ? <StartIcon className={styles.iconStart} /> : undefined}
        endIcon={item.locked ? <IconNewLock /> : item.endIcon && <item.endIcon />}
        data-testid={`navigationSidebarLink_${camelCase(item.label)}`}
        data-stonly-id={item.dataStonlyId}
        {...restProps}
      >
        {children || item.label}
      </Button>
    </div>
  );
};

SidebarButton.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape().isRequired,
  active: PropTypes.bool,
  onSubmenuItemClick: PropTypes.func,
};

SidebarButton.defaultProps = {
  className: '',
  active: false,
  onSubmenuItemClick: null,
};
