import { useMemo } from 'react';

import { useCurrentWorkspaces } from './useCurrentWorkspaces';
import { useCurrentWorkspaceId } from './useCurrentWorkspaceId';

export const useCurrentWorkspace = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const workspaces = useCurrentWorkspaces();

  return useMemo(
    () => workspaces.find((item) => item.id === currentWorkspaceId),
    [workspaces, currentWorkspaceId]
  );
};
