import React, { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const PersonaAvatarSix = memo(
  forwardRef(({ opaque, ...props }, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 72 72"
        data-fill-none
      >
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M72 36C72 43.1201 69.8886 50.0804 65.9329 56.0005C61.9772 61.9207 56.3548 66.5349 49.7766 69.2597C43.1985 71.9844 35.9601 72.6973 28.9768 71.3083C21.9934 69.9192 15.5789 66.4905 10.5442 61.4558C5.50948 56.4212 2.08081 50.0066 0.691746 43.0233C-0.697322 36.0399 0.0155982 28.8015 2.74035 22.2234C5.46511 15.6453 10.0793 10.0228 15.9995 6.06709C21.9197 2.11136 28.8799 0 36 0C45.5467 0.00363475 54.7013 3.79765 61.4518 10.5482C68.2024 17.2987 71.9964 26.4533 72 36Z"
            fill="#8CB2FF"
          />
          <path
            d="M67.5467 36.0038C67.5467 42.2424 65.6967 48.3409 62.2307 53.5281C58.7648 58.7153 53.8384 62.7582 48.0747 65.1456C42.311 67.533 35.9688 68.1576 29.8501 66.9406C23.7314 65.7235 18.111 62.7193 13.6996 58.308C9.28829 53.8966 6.28413 48.2762 5.06704 42.1575C3.84995 36.0388 4.4746 29.6966 6.86201 23.9329C9.24941 18.1692 13.2923 13.2428 18.4795 9.77687C23.6667 6.31089 29.7652 4.46094 36.0038 4.46094C44.3695 4.46094 52.3925 7.78419 58.308 13.6996C64.2234 19.6151 67.5467 27.6381 67.5467 36.0038Z"
            fill="white"
          />
          <path
            d="M54.7724 40.0425C54.6994 40.4156 54.5767 40.7772 54.4076 41.1177C53.8588 42.2181 53.2057 43.2633 52.4574 44.2391C52.0844 44.7109 50.4085 45.2375 50.4085 45.2375L50.3921 45.3006C50.3153 45.5557 50.0273 46.5404 49.7475 47.3989C49.7283 47.462 49.7063 47.5278 49.6817 47.5936C49.657 47.6594 49.635 47.7253 49.6049 47.8076V47.8213C49.1221 49.0583 47.4517 52.3991 47.2871 52.7282V52.7447L46.9278 57.3801V57.4734C46.9278 58.0686 47.0897 64.9559 47.4188 66.8594C47.5404 67.517 47.7801 68.1471 48.1265 68.7191C48.3232 69.0445 48.5432 69.3552 48.7847 69.6489C40.9886 72.5965 32.4135 72.7602 24.5105 70.1125C24.5845 69.8053 24.6668 69.4514 24.7573 69.0674C25.262 66.8923 25.9669 63.7573 26.1287 62.4489V62.3968C26.1589 62.1664 26.1809 61.8839 26.2028 61.5739V61.5383C26.3756 58.8338 26.3125 53.4825 26.3125 53.4825L25.6762 51.952L23.2734 46.1646L22.8977 45.2567C22.0748 45.221 20.7034 43.8853 20.6046 43.792C20.6046 43.792 18.9918 41.1644 18.4789 39.0441C18.3061 38.3228 18.2622 37.6727 18.4515 37.179C18.5951 36.8074 18.8 36.4625 19.0577 36.1586C19.5195 35.6048 20.1594 35.2286 20.8679 35.0944L21.5427 28.6295L22.958 23.3989L35.3173 17.8281L42.4323 18.0558L51.6812 24.4658L52.2078 27.5653L52.899 34.5431L53.3406 35.0642L53.5847 35.355L54.6078 36.5618L54.7697 36.7511C54.7697 36.7511 54.7697 36.8745 54.7971 37.0802C54.8904 38.0659 54.8821 39.0585 54.7724 40.0425Z"
            fill="#8CB2FF"
          />
          <path
            d="M51.6585 35.3557C51.9806 35.16 52.3547 35.0671 52.731 35.0891C53.1072 35.1112 53.4679 35.2472 53.765 35.4791C54.3549 35.964 54.7495 36.646 54.8758 37.3991C55.087 38.5406 54.972 39.7185 54.544 40.7975C54.1159 41.87 53.5398 42.8773 52.8324 43.79C52.3222 44.4839 51.6749 45.2053 50.8164 45.2602"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.3633 40.3723C51.3879 39.9353 51.5758 39.5236 51.8898 39.2188C52.2038 38.9139 52.6209 38.7383 53.0584 38.7266"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.7902 35.2307C21.468 35.035 21.0939 34.9421 20.7177 34.9641C20.3414 34.9862 19.9807 35.1222 19.6836 35.3541C19.0999 35.8364 18.7102 36.5134 18.5865 37.2604C18.3762 38.4021 18.4922 39.58 18.9211 40.6588C19.3423 41.7346 19.9129 42.7457 20.6162 43.6622C21.1264 44.3534 21.7737 45.0748 22.6295 45.1324"
            stroke="#080808"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.0857 40.2629C22.0611 39.826 21.8732 39.4142 21.5592 39.1094C21.2452 38.8046 20.8281 38.6289 20.3906 38.6172"
            stroke="#080808"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.1967 53.125C46.891 56.4841 46.8113 59.8599 46.9581 63.2297C47.0541 65.3115 47.279 67.4866 48.4201 69.2311"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.3279 53.4766C26.4678 58.9787 26.4623 64.6318 24.5039 69.7719"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.685 35.2734C51.685 48.8945 44.9869 59.94 36.7227 59.94C28.8808 59.94 22.4461 49.9889 21.8125 37.3278"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.3242 53.4766C26.3242 53.4766 28.033 64.1737 36.4728 64.1737C44.9126 64.1737 46.8956 53.9346 46.8956 53.9346C46.8956 53.9346 42.0874 60.4654 36.7471 60.2103C31.4067 59.9552 26.3242 53.4766 26.3242 53.4766Z"
            fill="black"
          />
          <path
            d="M32.7089 41.8281C32.5136 42.0057 32.3831 42.2434 32.3379 42.5035C32.2927 42.7635 32.3354 43.0313 32.4593 43.2644C32.5833 43.4975 32.7813 43.6826 33.0222 43.7906C33.2632 43.8986 33.5332 43.9232 33.7896 43.8606C33.9377 44.549 34.4808 45.0894 35.0815 45.4569C35.8579 45.9669 36.8047 46.1483 37.7147 45.9616C38.6061 45.7394 39.3851 44.9824 39.4701 44.069C39.7207 44.1863 40.0063 44.2045 40.2698 44.1201C40.5333 44.0356 40.7551 43.8548 40.8909 43.6137C41.0161 43.3672 41.0704 43.0907 41.0476 42.8151C41.0249 42.5395 40.926 42.2757 40.762 42.053"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.4688 46.2344C42.7779 46.3115 43.0645 46.4605 43.3051 46.6692C43.5458 46.878 43.7338 47.1406 43.8539 47.4357"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.2988 47.089C42.6487 48.5592 41.6037 50.257 40.3502 51.2582C39.0967 52.2593 37.3495 52.7667 35.841 52.2154C34.2803 51.6476 33.2901 50.1281 32.5742 48.6278L32.6812 48.54C34.3989 49.1566 36.243 49.3366 38.0475 49.0637C39.8521 48.7908 41.5605 48.0736 43.019 46.9766L43.2988 47.089Z"
            fill="white"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.1992 42.1741C29.6771 41.9427 30.0998 41.6116 30.439 41.2031"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.9883 40.2188C41.9866 40.5625 42.1027 40.8965 42.3174 41.165C42.4546 41.3406 42.6329 41.475 41.9883 40.2188Z"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.1999 40.4198C29.6619 40.4198 30.0364 39.6105 30.0364 38.6122C30.0364 37.614 29.6619 36.8047 29.1999 36.8047C28.7378 36.8047 28.3633 37.614 28.3633 38.6122C28.3633 39.6105 28.7378 40.4198 29.1999 40.4198Z"
            fill="black"
          />
          <path
            d="M44.3092 40.4198C44.7713 40.4198 45.1458 39.6105 45.1458 38.6122C45.1458 37.614 44.7713 36.8047 44.3092 36.8047C43.8472 36.8047 43.4727 37.614 43.4727 38.6122C43.4727 39.6105 43.8472 40.4198 44.3092 40.4198Z"
            fill="black"
          />
          <path
            d="M38.7072 29.4413C39.7903 29.4413 40.6684 28.2023 40.6684 26.6738C40.6684 25.1453 39.7903 23.9062 38.7072 23.9062C37.6241 23.9062 36.7461 25.1453 36.7461 26.6738C36.7461 28.2023 37.6241 29.4413 38.7072 29.4413Z"
            fill="black"
          />
          <path
            d="M42.0126 27.0733C43.5577 27.0733 44.8103 26.011 44.8103 24.7007C44.8103 23.3904 43.5577 22.3281 42.0126 22.3281C40.4674 22.3281 39.2148 23.3904 39.2148 24.7007C39.2148 26.011 40.4674 27.0733 42.0126 27.0733Z"
            fill="black"
          />
          <path
            d="M46.5176 27.9792C47.99 27.9792 49.1837 26.3189 49.1837 24.2708C49.1837 22.2228 47.99 20.5625 46.5176 20.5625C45.0452 20.5625 43.8516 22.2228 43.8516 24.2708C43.8516 26.3189 45.0452 27.9792 46.5176 27.9792Z"
            fill="black"
          />
          <path
            d="M49.5138 29.448C50.2333 29.448 50.8167 28.6289 50.8167 27.6185C50.8167 26.6082 50.2333 25.7891 49.5138 25.7891C48.7942 25.7891 48.2109 26.6082 48.2109 27.6185C48.2109 28.6289 48.7942 29.448 49.5138 29.448Z"
            fill="black"
          />
          <path
            d="M50.0907 34.5512C51.4374 34.5512 52.5291 33.2176 52.5291 31.5725C52.5291 29.9274 51.4374 28.5938 50.0907 28.5938C48.7441 28.5938 47.6523 29.9274 47.6523 31.5725C47.6523 33.2176 48.7441 34.5512 50.0907 34.5512Z"
            fill="black"
          />
          <path
            d="M51.2119 36.6864C52.1465 36.6864 52.9042 35.6881 52.9042 34.4565C52.9042 33.2249 52.1465 32.2266 51.2119 32.2266C50.2772 32.2266 49.5195 33.2249 49.5195 34.4565C49.5195 35.6881 50.2772 36.6864 51.2119 36.6864Z"
            fill="black"
          />
          <path
            d="M35.4484 27.0737C36.7617 27.0737 37.8264 26.1478 37.8264 25.0056C37.8264 23.8634 36.7617 22.9375 35.4484 22.9375C34.135 22.9375 33.0703 23.8634 33.0703 25.0056C33.0703 26.1478 34.135 27.0737 35.4484 27.0737Z"
            fill="black"
          />
          <path
            d="M31.1418 27.6165C32.6763 27.6165 33.9203 26.6512 33.9203 25.4606C33.9203 24.2699 32.6763 23.3047 31.1418 23.3047C29.6073 23.3047 28.3633 24.2699 28.3633 25.4606C28.3633 26.6512 29.6073 27.6165 31.1418 27.6165Z"
            fill="black"
          />
          <path
            d="M27.8214 27.9787C28.644 27.9787 29.3108 27.3119 29.3108 26.4894C29.3108 25.6668 28.644 25 27.8214 25C26.9988 25 26.332 25.6668 26.332 26.4894C26.332 27.3119 26.9988 27.9787 27.8214 27.9787Z"
            fill="black"
          />
          <path
            d="M24.9944 31.0278C26.2987 31.0278 27.356 29.8096 27.356 28.3069C27.356 26.8041 26.2987 25.5859 24.9944 25.5859C23.6901 25.5859 22.6328 26.8041 22.6328 28.3069C22.6328 29.8096 23.6901 31.0278 24.9944 31.0278Z"
            fill="black"
          />
          <path
            d="M23.1785 33.1304C24.4313 33.1304 25.4468 32.1149 25.4468 30.8621C25.4468 29.6093 24.4313 28.5938 23.1785 28.5938C21.9257 28.5938 20.9102 29.6093 20.9102 30.8621C20.9102 32.1149 21.9257 33.1304 23.1785 33.1304Z"
            fill="black"
          />
          <path
            d="M22.0389 36.6907C23.4022 36.6907 24.5075 35.3853 24.5075 33.775C24.5075 32.1648 23.4022 30.8594 22.0389 30.8594C20.6755 30.8594 19.5703 32.1648 19.5703 33.775C19.5703 35.3853 20.6755 36.6907 22.0389 36.6907Z"
            fill="black"
          />
          <path
            d="M14.2185 34.9639C16.3347 34.9639 18.0503 33.7286 18.0503 32.2046C18.0503 30.6807 16.3347 29.4453 14.2185 29.4453C12.1023 29.4453 10.3867 30.6807 10.3867 32.2046C10.3867 33.7286 12.1023 34.9639 14.2185 34.9639Z"
            fill="black"
          />
          <path
            d="M19.7107 33.503C21.8269 33.503 23.5424 32.2676 23.5424 30.7437C23.5424 29.2198 21.8269 27.9844 19.7107 27.9844C17.5944 27.9844 15.8789 29.2198 15.8789 30.7437C15.8789 32.2676 17.5944 33.503 19.7107 33.503Z"
            fill="black"
          />
          <path
            d="M19.5701 49.7374C21.6863 49.7374 23.4018 48.502 23.4018 46.9781C23.4018 45.4541 21.6863 44.2188 19.5701 44.2188C17.4538 44.2188 15.7383 45.4541 15.7383 46.9781C15.7383 48.502 17.4538 49.7374 19.5701 49.7374Z"
            fill="black"
          />
          <path
            d="M16.0668 31.2273C17.3226 31.2273 18.3406 30.2093 18.3406 28.9535C18.3406 27.6977 17.3226 26.6797 16.0668 26.6797C14.811 26.6797 13.793 27.6977 13.793 28.9535C13.793 30.2093 14.811 31.2273 16.0668 31.2273Z"
            fill="black"
          />
          <path
            d="M17.4379 45.0242C18.6937 45.0242 19.7117 44.0062 19.7117 42.7504C19.7117 41.4946 18.6937 40.4766 17.4379 40.4766C16.1821 40.4766 15.1641 41.4946 15.1641 42.7504C15.1641 44.0062 16.1821 45.0242 17.4379 45.0242Z"
            fill="black"
          />
          <path
            d="M15.1332 38.9617C16.389 38.9617 17.407 37.9437 17.407 36.6879C17.407 35.4321 16.389 34.4141 15.1332 34.4141C13.8774 34.4141 12.8594 35.4321 12.8594 36.6879C12.8594 37.9437 13.8774 38.9617 15.1332 38.9617Z"
            fill="black"
          />
          <path
            d="M17.0469 27.0734C18.4405 27.0734 19.5703 26.2297 19.5703 25.189C19.5703 24.1483 18.4405 23.3047 17.0469 23.3047C15.6532 23.3047 14.5234 24.1483 14.5234 25.189C14.5234 26.2297 15.6532 27.0734 17.0469 27.0734Z"
            fill="black"
          />
          <path
            d="M16.0664 41.8312C17.46 41.8312 18.5898 40.9875 18.5898 39.9468C18.5898 38.9062 17.46 38.0625 16.0664 38.0625C14.6727 38.0625 13.543 38.9062 13.543 39.9468C13.543 40.9875 14.6727 41.8312 16.0664 41.8312Z"
            fill="black"
          />
          <path
            d="M18.8871 23.2654C19.0868 22.7352 18.5994 22.0608 17.7984 21.7591C16.9975 21.4574 16.1863 21.6426 15.9866 22.1728C15.7869 22.703 16.2743 23.3774 17.0752 23.6791C17.8762 23.9808 18.6874 23.7955 18.8871 23.2654Z"
            fill="black"
          />
          <path
            d="M24.5723 49.3392C24.7806 48.126 24.1897 47.0119 23.2524 46.851C22.3151 46.69 21.3863 47.5431 21.178 48.7563C20.9697 49.9696 21.5606 51.0836 22.4979 51.2446C23.4352 51.4055 24.364 50.5525 24.5723 49.3392Z"
            fill="black"
          />
          <path
            d="M18.4697 21.9045C20.3148 21.9045 21.8105 21.1284 21.8105 20.171C21.8105 19.2136 20.3148 18.4375 18.4697 18.4375C16.6246 18.4375 15.1289 19.2136 15.1289 20.171C15.1289 21.1284 16.6246 21.9045 18.4697 21.9045Z"
            fill="black"
          />
          <path
            d="M20.5879 19.229C22.5436 19.229 24.1289 18.2613 24.1289 17.0676C24.1289 15.8739 22.5436 14.9062 20.5879 14.9062C18.6322 14.9062 17.0469 15.8739 17.0469 17.0676C17.0469 18.2613 18.6322 19.229 20.5879 19.229Z"
            fill="black"
          />
          <path
            d="M22.043 15.5975C22.6686 15.5975 23.1758 14.7624 23.1758 13.7323C23.1758 12.7022 22.6686 11.8672 22.043 11.8672C21.4173 11.8672 20.9102 12.7022 20.9102 13.7323C20.9102 14.7624 21.4173 15.5975 22.043 15.5975Z"
            fill="black"
          />
          <path
            d="M27.3617 17.071C29.4491 17.071 31.1413 15.6613 31.1413 13.9222C31.1413 12.1832 29.4491 10.7734 27.3617 10.7734C25.2742 10.7734 23.582 12.1832 23.582 13.9222C23.582 15.6613 25.2742 17.071 27.3617 17.071Z"
            fill="black"
          />
          <path
            d="M30.9409 13.7319C31.9029 13.7319 32.6826 12.4977 32.6826 10.9753C32.6826 9.45291 31.9029 8.21875 30.9409 8.21875C29.979 8.21875 29.1992 9.45291 29.1992 10.9753C29.1992 12.4977 29.979 13.7319 30.9409 13.7319Z"
            fill="black"
          />
          <path
            d="M25.3637 20.0086C27.1739 20.0086 28.6414 18.5411 28.6414 16.7308C28.6414 14.9206 27.1739 13.4531 25.3637 13.4531C23.5534 13.4531 22.0859 14.9206 22.0859 16.7308C22.0859 18.5411 23.5534 20.0086 25.3637 20.0086Z"
            fill="black"
          />
          <path
            d="M24.937 27.288C27.6334 27.288 29.8193 25.1021 29.8193 22.4057C29.8193 19.7093 27.6334 17.5234 24.937 17.5234C22.2406 17.5234 20.0547 19.7093 20.0547 22.4057C20.0547 25.1021 22.2406 27.288 24.937 27.288Z"
            fill="black"
          />
          <path
            d="M21.4995 29.8818C24.1959 29.8818 26.3818 27.6959 26.3818 24.9995C26.3818 22.3031 24.1959 20.1172 21.4995 20.1172C18.8031 20.1172 16.6172 22.3031 16.6172 24.9995C16.6172 27.6959 18.8031 29.8818 21.4995 29.8818Z"
            fill="black"
          />
          <path
            d="M16.0688 32.7586L15.5586 35.2738L17.8845 43.9412L18.9817 46.5799L21.4064 44.5584C21.4064 44.5584 18.6635 43.2802 18.5757 37.2679C18.5757 37.2679 18.5949 35.1284 20.8852 35.0736C23.1755 35.0187 20.3943 30.7344 20.3943 30.7344L16.0688 32.7586Z"
            fill="black"
          />
          <path
            d="M38.1749 27.0737C39.4883 27.0737 40.553 26.1478 40.553 25.0056C40.553 23.8634 39.4883 22.9375 38.1749 22.9375C36.8616 22.9375 35.7969 23.8634 35.7969 25.0056C35.7969 26.1478 36.8616 27.0737 38.1749 27.0737Z"
            fill="black"
          />
          <path
            d="M42.4816 27.6165C44.0162 27.6165 45.2602 26.6512 45.2602 25.4606C45.2602 24.2699 44.0162 23.3047 42.4816 23.3047C40.9471 23.3047 39.7031 24.2699 39.7031 25.4606C39.7031 26.6512 40.9471 27.6165 42.4816 27.6165Z"
            fill="black"
          />
          <path
            d="M45.8019 27.9787C46.6244 27.9787 47.2912 27.3119 47.2912 26.4894C47.2912 25.6668 46.6244 25 45.8019 25C44.9793 25 44.3125 25.6668 44.3125 26.4894C44.3125 27.3119 44.9793 27.9787 45.8019 27.9787Z"
            fill="black"
          />
          <path
            d="M48.6311 31.0278C49.9354 31.0278 50.9927 29.8096 50.9927 28.3069C50.9927 26.8041 49.9354 25.5859 48.6311 25.5859C47.3269 25.5859 46.2695 26.8041 46.2695 28.3069C46.2695 29.8096 47.3269 31.0278 48.6311 31.0278Z"
            fill="black"
          />
          <path
            d="M50.4441 33.1304C51.6969 33.1304 52.7125 32.1149 52.7125 30.8621C52.7125 29.6093 51.6969 28.5938 50.4441 28.5938C49.1914 28.5938 48.1758 29.6093 48.1758 30.8621C48.1758 32.1149 49.1914 33.1304 50.4441 33.1304Z"
            fill="black"
          />
          <path
            d="M51.5858 36.6907C52.9491 36.6907 54.0543 35.3853 54.0543 33.775C54.0543 32.1648 52.9491 30.8594 51.5858 30.8594C50.2224 30.8594 49.1172 32.1648 49.1172 33.775C49.1172 35.3853 50.2224 36.6907 51.5858 36.6907Z"
            fill="black"
          />
          <path
            d="M59.406 34.9639C61.5222 34.9639 63.2378 33.7286 63.2378 32.2046C63.2378 30.6807 61.5222 29.4453 59.406 29.4453C57.2898 29.4453 55.5742 30.6807 55.5742 32.2046C55.5742 33.7286 57.2898 34.9639 59.406 34.9639Z"
            fill="black"
          />
          <path
            d="M53.9138 33.503C56.03 33.503 57.7456 32.2676 57.7456 30.7437C57.7456 29.2198 56.03 27.9844 53.9138 27.9844C51.7976 27.9844 50.082 29.2198 50.082 30.7437C50.082 32.2676 51.7976 33.503 53.9138 33.503Z"
            fill="black"
          />
          <path
            d="M57.5551 31.2273C58.8109 31.2273 59.8289 30.2093 59.8289 28.9535C59.8289 27.6977 58.8109 26.6797 57.5551 26.6797C56.2993 26.6797 55.2812 27.6977 55.2812 28.9535C55.2812 30.2093 56.2993 31.2273 57.5551 31.2273Z"
            fill="black"
          />
          <path
            d="M56.5781 27.0734C57.9718 27.0734 59.1015 26.2297 59.1015 25.189C59.1015 24.1483 57.9718 23.3047 56.5781 23.3047C55.1845 23.3047 54.0547 24.1483 54.0547 25.189C54.0547 26.2297 55.1845 27.0734 56.5781 27.0734Z"
            fill="black"
          />
          <path
            d="M56.5484 23.694C57.3494 23.3923 57.8368 22.7179 57.6371 22.1878C57.4374 21.6576 56.6262 21.4724 55.8252 21.774C55.0243 22.0757 54.5369 22.7501 54.7366 23.2803C54.9363 23.8105 55.7475 23.9957 56.5484 23.694Z"
            fill="black"
          />
          <path
            d="M55.1494 21.9045C56.9945 21.9045 58.4902 21.1284 58.4902 20.171C58.4902 19.2136 56.9945 18.4375 55.1494 18.4375C53.3043 18.4375 51.8086 19.2136 51.8086 20.171C51.8086 21.1284 53.3043 21.9045 55.1494 21.9045Z"
            fill="black"
          />
          <path
            d="M53.0332 19.229C54.9889 19.229 56.5742 18.2613 56.5742 17.0676C56.5742 15.8739 54.9889 14.9062 53.0332 14.9062C51.0776 14.9062 49.4922 15.8739 49.4922 17.0676C49.4922 18.2613 51.0776 19.229 53.0332 19.229Z"
            fill="black"
          />
          <path
            d="M51.582 15.5975C52.2076 15.5975 52.7148 14.7624 52.7148 13.7323C52.7148 12.7022 52.2076 11.8672 51.582 11.8672C50.9564 11.8672 50.4492 12.7022 50.4492 13.7323C50.4492 14.7624 50.9564 15.5975 51.582 15.5975Z"
            fill="black"
          />
          <path
            d="M46.2601 17.071C48.3476 17.071 50.0398 15.6613 50.0398 13.9222C50.0398 12.1832 48.3476 10.7734 46.2601 10.7734C44.1727 10.7734 42.4805 12.1832 42.4805 13.9222C42.4805 15.6613 44.1727 17.071 46.2601 17.071Z"
            fill="black"
          />
          <path
            d="M33.7105 13.5366C34.6724 13.5366 35.4522 12.3024 35.4522 10.78C35.4522 9.2576 34.6724 8.02344 33.7105 8.02344C32.7485 8.02344 31.9688 9.2576 31.9688 10.78C31.9688 12.3024 32.7485 13.5366 33.7105 13.5366Z"
            fill="black"
          />
          <path
            d="M48.2582 20.0086C50.0684 20.0086 51.5359 18.5411 51.5359 16.7308C51.5359 14.9206 50.0684 13.4531 48.2582 13.4531C46.448 13.4531 44.9805 14.9206 44.9805 16.7308C44.9805 18.5411 46.448 20.0086 48.2582 20.0086Z"
            fill="black"
          />
          <path
            d="M48.687 27.288C51.3834 27.288 53.5693 25.1021 53.5693 22.4057C53.5693 19.7093 51.3834 17.5234 48.687 17.5234C45.9906 17.5234 43.8047 19.7093 43.8047 22.4057C43.8047 25.1021 45.9906 27.288 48.687 27.288Z"
            fill="black"
          />
          <path
            d="M52.1206 29.8818C54.817 29.8818 57.0029 27.6959 57.0029 24.9995C57.0029 22.3031 54.817 20.1172 52.1206 20.1172C49.4242 20.1172 47.2383 22.3031 47.2383 24.9995C47.2383 27.6959 49.4242 29.8818 52.1206 29.8818Z"
            fill="black"
          />
          <path
            d="M57.5569 32.7586L58.0671 35.2738L55.7412 43.9412L54.644 46.5799L52.2193 44.5584C52.2193 44.5584 54.9622 43.2802 55.05 37.2679C55.05 37.2679 55.0308 35.1284 52.7405 35.0736C50.4502 35.0187 53.2315 30.7344 53.2315 30.7344L57.5569 32.7586Z"
            fill="black"
          />
          <path
            d="M34.552 9.73985C34.552 9.73985 37.1056 9.34488 37.2592 12.3236C37.2592 12.3236 39.8128 7.61139 43.5211 8.75791C47.2295 9.90442 42.7312 12.768 42.7312 12.768L48.2663 18.5856L45.7977 23.4212L40.6686 26.6742L32.6786 25.1849L26.957 24.6966L27.8183 17.5213L28.8112 14.208L34.552 9.73985Z"
            fill="black"
          />
          <path
            d="M58.9652 34.0033C59.0859 34.0335 63.5266 39.0803 61.4585 40.8604C59.3904 42.6405 58.3262 39.9306 58.3262 39.9306C58.3262 39.9306 59.939 41.9548 58.6004 42.9889C57.2619 44.0229 56.1977 43.414 56.1977 43.414C56.1977 43.414 57.5691 45.2545 56.077 46.22C54.5849 47.1855 54.0446 46.8207 54.0446 46.8207C54.0446 46.8207 53.8526 49.0917 51.2002 49.7226C48.5479 50.3535 48.6768 50.0764 48.6768 50.0764L50.2402 45.5754L52.2014 44.8952L55.2734 39.6453L54.7659 36.7516L53.7045 34.9468L57.9312 31.9297L58.9652 34.0033Z"
            fill="black"
          />
          <path
            d="M28.2257 33.6782L32.7816 32.2794C32.996 32.2144 33.1827 32.08 33.3123 31.8972C33.4419 31.7145 33.5071 31.4938 33.4974 31.27L33.4371 29.9424C33.4294 29.7744 33.38 29.611 33.2933 29.4669C33.2066 29.3228 33.0853 29.2025 32.9405 29.1171C32.7957 29.0316 32.6318 28.9835 32.4637 28.9773C32.2957 28.971 32.1287 29.0067 31.9779 29.0812L27.3809 31.3687C27.1757 31.4673 27.0099 31.6323 26.9104 31.8369C26.8108 32.0415 26.7833 32.2738 26.8323 32.496L26.9283 32.9322C26.9569 33.062 27.0108 33.185 27.0869 33.294C27.1631 33.4031 27.2599 33.4961 27.372 33.5676C27.4841 33.6392 27.6092 33.688 27.7401 33.7112C27.8711 33.7344 28.0053 33.7316 28.1352 33.7029L28.2257 33.6782Z"
            fill="black"
          />
          <path
            d="M44.9524 33.6795L40.3965 32.2807C40.1821 32.2157 39.9953 32.0813 39.8657 31.8986C39.7361 31.7158 39.671 31.4952 39.6806 31.2713L39.741 29.9438C39.7484 29.7754 39.7979 29.6115 39.8847 29.4671C39.9716 29.3227 40.0931 29.2022 40.2383 29.1166C40.3836 29.0311 40.5478 28.9832 40.7163 28.9772C40.8847 28.9712 41.052 29.0074 41.2029 29.0825L45.7999 31.3701C46.0047 31.469 46.1703 31.634 46.2698 31.8386C46.3693 32.0431 46.397 32.2752 46.3485 32.4974L46.2498 32.9335C46.2218 33.0632 46.1685 33.1862 46.0929 33.2953C46.0174 33.4044 45.9211 33.4975 45.8095 33.5694C45.6979 33.6412 45.5732 33.6904 45.4426 33.714C45.312 33.7376 45.1781 33.7352 45.0484 33.707L44.9524 33.6795Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="72"
              height="72"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);

PersonaAvatarSix.propTypes = {
  opaque: PropTypes.bool,
};
PersonaAvatarSix.defaultProps = {
  opaque: false,
};
