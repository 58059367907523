import { getQueryParamValue } from 'src/lib/browser';
import { defineEnumClampFunction } from 'src/lib/enum';
import * as localData from 'src/lib/localData';
import { ROUTE_PARAMS } from 'src/routes';

/**
 * Platform Application modes
 * @readonly
 * @enum {string}
 */
export const APPLICATION_MODE = {
  default: 'default',
  infinityExtension: 'infinity_extension',
};
defineEnumClampFunction(APPLICATION_MODE, APPLICATION_MODE.default);

let applicationMode;

/**
 * Get the current application mode.
 * Mode should not change during session.
 * @returns {APPLICATION_MODE} - application mode
 */
export const getApplicationMode = () => {
  if (applicationMode) {
    return applicationMode;
  }

  const queryMode = getQueryParamValue(ROUTE_PARAMS.applicationMode);
  const sessionMode = localData.sessionApplicationMode.read();
  applicationMode = APPLICATION_MODE.clamp(queryMode || sessionMode);
  return applicationMode;
};

export const isApplicationModeInfinity = (mode) => {
  const localMode = mode || getApplicationMode();
  return localMode === APPLICATION_MODE.infinityExtension;
};

/**
 * Get the current application host, if Platform is embedded in iframe.
 * @returns {string} - application host
 */
export const getApplicationHost = () => {
  const queryHost = getQueryParamValue(ROUTE_PARAMS.host);
  const sessionHost = localData.sessionApplicationHost.read();
  return queryHost || sessionHost || null;
};

/**
 * Get the current extension installation ID, if Platform is embedded in iframe.
 * @returns {string} - installation ID
 */
export const getExtensionInstallationId = () => {
  const queryHost = getQueryParamValue(ROUTE_PARAMS.installationId);
  return queryHost || null;
};
