import { useCallback } from 'react';

import { useHistory } from 'src/lib/hooks/useHistory';
import { preparePlatformUrl } from 'src/lib/url';
import { useIsApplicationModeInfinity } from './useIsApplicationModeInfinity';

export const useHistoryPushOrInfinityOpen = () => {
  const history = useHistory();
  const isApplicationModeInfinity = useIsApplicationModeInfinity();

  return useCallback(
    (route) => {
      if (isApplicationModeInfinity) {
        const url = preparePlatformUrl(route);
        window.open(url, '_blank');
      } else {
        history.push(route);
      }
    },
    [isApplicationModeInfinity, history]
  );
};
