import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { IconDot } from 'src/assets/icons/Dot';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { DETECTED_BRAND_TERM_TYPE, getDetectedBrandTermTypeTitle } from 'src/data/brandTerm';
import { useStyles } from './useStyles';

export const TermType = ({ className, detectedBrandTermType }) => {
  const styles = useStyles();

  return (
    <div
      className={cx(styles.root, className)}
      data-type={detectedBrandTermType}
    >
      <IconDot className={styles.icon} />
      <CustomTypography
        className={styles.text}
        variant={TYPOGRAPHY_VARIANT.medium14}
      >
        {getDetectedBrandTermTypeTitle(detectedBrandTermType)}:
      </CustomTypography>
    </div>
  );
};

TermType.propTypes = {
  className: PropTypes.string,
  detectedBrandTermType: PropTypes.oneOf(Object.values(DETECTED_BRAND_TERM_TYPE)).isRequired,
};

TermType.defaultProps = {
  className: '',
};
