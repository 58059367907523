import React, { Fragment } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useEffectOnce, useHistory } from 'src/lib/hooks';
import { stripGraphQLErrorPrefix } from 'src/lib/graphql';
import { captureMessage } from 'src/lib/sentry';
import { ROUTES } from 'src/routes';
import PageLayout from 'src/components/PageLayout';
import { useUserActions } from 'src/store';
import { getQueryParams } from 'src/lib/browser';
import useStyles from './useStyles';

const OAuthCallback = () => {
  const styles = useStyles();
  const history = useHistory();
  const userActions = useUserActions();

  useEffectOnce(() => {
    const queryParams = getQueryParams();

    const result = {
      token: queryParams.token,
      wasUserCreated: queryParams.created_user === 'true',
      errorMessage: queryParams.error_message,
    };

    // If we are opened in a popup, then send result to parent window ...
    if (window.opener && window.opener !== window) {
      window.opener.postMessage(result, '*');
    } else {
      // ... otherwise process login here and do a redirect in this window
      userActions
        .loginOAuth(result)
        .then(() => {
          history.replace(ROUTES.ROOT);
        })
        .catch((loginError) => {
          const message = stripGraphQLErrorPrefix(loginError);
          captureMessage(`OAuth login error: ${message}`, {
            level: 'info',
          });

          history.replace(ROUTES.SIGN_IN);
        });
    }
  });

  return (
    <Fragment>
      <PageLayout className={styles.root}>
        <CircularProgress size={48} />
      </PageLayout>
    </Fragment>
  );
};

export default OAuthCallback;
