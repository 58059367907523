import React, { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const CheckboxIndeterminate = forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    viewBox="0 0  16 16"
  >
    <path
      d="M2.66667 0.5H13.3333C14.53 0.5 15.5 1.47005 15.5 2.66667V13.3333C15.5 14.53 14.53 15.5 13.3333 15.5H2.66667C1.47005 15.5 0.5 14.53 0.5 13.3333V2.66667C0.5 1.47005 1.47005 0.5 2.66667 0.5Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <rect
      x="4"
      y="7.33334"
      width="8"
      height="2"
      rx="1"
      fill="white"
    />
  </SvgIcon>
));
