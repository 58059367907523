import { makeStyles } from '@material-ui/core';

import { NAVIGATION_BAR_HEIGHT } from 'src/data/common';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      height: NAVIGATION_BAR_HEIGHT,
      padding: theme.spacing(1.25, 3),
      backgroundColor: theme.palette.darkGray,
      alignItems: 'center',
    },
    logo: {
      marginRight: theme.spacing(2),
    },
    divider: {
      width: 1,
      height: 12,
      backgroundColor: COLORS.neutral.silver,
    },
    backBtn: {
      height: '30px',
      flexShrink: 0,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      color: '#FFFFFF',
      minWidth: 'unset',
      marginLeft: theme.spacing(-1),
    },
    startIcon: {
      marginRight: 0,
    },
    backIcon: {
      height: '7px',
      width: '3px',
      marginRight: theme.spacing(0.5),
    },
  }),
  {
    name: 'NavigationBar',
  }
);
