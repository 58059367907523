import { createContainer } from 'react-sweet-state';

import { WorkspaceStore, ACTIONS } from './WorkspaceStore';

export const WorkspaceStoreContainer = createContainer(WorkspaceStore, {
  onInit: () => ({ setState, dispatch }, { currentCustomerId, state }) => {
    dispatch(ACTIONS.init(currentCustomerId));

    if (state) {
      setState(state);
    }
  },
  onUpdate: () => ({ setState, dispatch }, { currentCustomerId, state }) => {
    dispatch(ACTIONS.init(currentCustomerId));

    if (state) {
      setState(state);
    }
  },
});
