import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import AgeGenderHistogram from 'src/components/AgeGenderHistogram';
import { ContentSensitivityInfo } from 'src/components/ContentSensitivityInfo';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: 260,
    },
    histogram: {
      padding: theme.spacing(2),
    },
    sensitivity: {
      width: '100%',
      marginTop: theme.spacing(0.5),
      borderTop: theme.shape.borderLightGray,
      padding: theme.spacing(2.5, 2, 2, 2),
    },
  }),
  { name: 'TooltipContent' }
);

export const TooltipContent = ({
  histogramData,
  contentSensitivity,
  loadingSensitivity,
}) => {
  const styles = useStyles();

  const sensitivityVisible = !!contentSensitivity || loadingSensitivity;

  // If no data is ready, return empty string, this will make Tooltip not activatable
  if (!histogramData) {
    return '';
  }

  return (
    <div className={styles.root}>
      <AgeGenderHistogram
        className={styles.histogram}
        histogramData={histogramData}
      />

      {sensitivityVisible && (
        <ContentSensitivityInfo
          className={styles.sensitivity}
          sensitivity={contentSensitivity}
          loading={loadingSensitivity}
          smallerFont
        />
      )}
    </div>
  );
};

TooltipContent.propTypes = {
  histogramData: PropTypes.shape(),
  contentSensitivity: PropTypes.shape(),
  loadingSensitivity: PropTypes.bool,
};
TooltipContent.defaultProps = {
  histogramData: null,
  contentSensitivity: null,
  loadingSensitivity: false,
};
