import { difference } from 'lodash';

import { MARKETING_USER_POSITION } from 'src/data/user';
import { GENERATION_TOOL } from 'src/data/generationTool';
import { PROJECT_TYPE } from './const';

const RECOMMENDED_BUCKETS = [
  {
    userRoles: [
      MARKETING_USER_POSITION.seoMarketer,
      MARKETING_USER_POSITION.contentMarketer,
      MARKETING_USER_POSITION.audienceDevelopmentManager,
    ],
    recommended: [
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.facebookPrimaryTextProduct,
      },
      { projectType: PROJECT_TYPE.continuousOptimization },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.landingHeadline,
      },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.instagramCaption,
      },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.facebookPost,
      },
    ],
  },
  {
    userRoles: [MARKETING_USER_POSITION.eCommerceShopOwner],
    recommended: [
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.facebookPrimaryTextProduct,
      },
      { projectType: PROJECT_TYPE.continuousOptimization },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.landingHeadline,
      },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.googleDescriptionProduct,
      },
    ],
  },
  {
    userRoles: [
      MARKETING_USER_POSITION.paidAdvertisingMarketer,
      MARKETING_USER_POSITION.emailMarketer,
      MARKETING_USER_POSITION.fullStackMarketer,
      MARKETING_USER_POSITION.vpMarketing,
    ],
    recommended: [
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.facebookPrimaryTextProduct,
      },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.googleDescriptionProduct,
      },
      { projectType: PROJECT_TYPE.continuousOptimization },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.landingHeadline,
      },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.emailColdOutreachSubjectLine,
      },
    ],
  },
  {
    userRoles: [
      MARKETING_USER_POSITION.copywriter,
      MARKETING_USER_POSITION.influencer,
      MARKETING_USER_POSITION.webDesigner,
    ],
    recommended: [
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.facebookPrimaryTextProduct,
      },
      { projectType: PROJECT_TYPE.continuousOptimization },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.landingHeadline,
      },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.instagramCaption,
      },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.facebookPost,
      },
    ],
  },
  {
    userRoles: [
      MARKETING_USER_POSITION.onlineBusinessOwner,
      MARKETING_USER_POSITION.agencyOwner,
      MARKETING_USER_POSITION.startupFounder,
    ],
    recommended: [
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.facebookPrimaryTextProduct,
      },
      { projectType: PROJECT_TYPE.continuousOptimization },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.landingHeadline,
      },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.googleDescriptionProduct,
      },
      {
        projectType: PROJECT_TYPE.document,
        generationTool: GENERATION_TOOL.emailColdOutreachSubjectLine,
      },
    ],
  },
];

const FALLBACK_RECOMMENDED = [
  {
    projectType: PROJECT_TYPE.document,
    generationTool: GENERATION_TOOL.facebookPrimaryTextProduct,
  },
  { projectType: PROJECT_TYPE.continuousOptimization },
  {
    projectType: PROJECT_TYPE.document,
    generationTool: GENERATION_TOOL.landingHeadline,
  },
  {
    projectType: PROJECT_TYPE.document,
    generationTool: GENERATION_TOOL.instagramCaption,
  },
  {
    projectType: PROJECT_TYPE.document,
    generationTool: GENERATION_TOOL.googleDescriptionProduct,
  },
];

const findBucket = (userRoles) =>
  RECOMMENDED_BUCKETS.find((bucket) => {
    const diff = difference(userRoles, bucket.userRoles);
    return diff.length === 0;
  });

export const getRecommendedProjectTypes = (userRoles) => {
  const relevantBucket = userRoles.length > 0 ? findBucket(userRoles) : null;
  if (relevantBucket) {
    return relevantBucket.recommended;
  }

  return FALLBACK_RECOMMENDED;
};
