import { useCallback } from 'react';

import { useNotificationActions } from 'src/store';
import { doesParsedGraphQLErrorContainErrorCode, parseGraphQLError } from 'src/lib/graphql';
import { useErrorHandler } from './useErrorHandler';

export const useGraphQLErrorHandler = () => {
  const notificationActions = useNotificationActions();
  const handleError = useErrorHandler();

  return useCallback(
    ({ error, extra, errorCodeMappingList }) => {
      if (errorCodeMappingList) {
        const parsedError = parseGraphQLError(error);

        for (let i = 0; i < errorCodeMappingList?.length; i++) {
          const errorCodeMapping = errorCodeMappingList[i];
          const containsErrorCode = doesParsedGraphQLErrorContainErrorCode({
            parsedError,
            errorCode: errorCodeMapping?.code,
          });

          if (!containsErrorCode) continue;

          notificationActions.displayError({ message: errorCodeMapping.message });

          return;
        }
      }

      handleError(error, extra);
      return;
    },
    [notificationActions, handleError]
  );
};
