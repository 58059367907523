import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateUserReferrerSource($referrerSource: String, $referrerOtherSource: String) {
    updateUserReferrerSource(
      referrerSource: $referrerSource
      referrerOtherSource: $referrerOtherSource
    ) {
      id
    }
  }
`;

export const updateUserReferrerSource = (referrerSource, referrerOtherSource) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { referrerSource, referrerOtherSource },
  }).then(({ data }) => getSingleProp(data));
