import { BRIEF_TYPE } from './const';
import { CONTENT_TYPE } from '../contentType';

export const contentTypeFromBriefType = (briefType) =>
  [BRIEF_TYPE.unifiedProduct, BRIEF_TYPE.unifiedPromotionalEmail].includes(briefType)
    ? CONTENT_TYPE.product
    : CONTENT_TYPE.content;

export const brandBriefsAvailableForBriefType = (briefType) =>
  contentTypeFromBriefType(briefType) === CONTENT_TYPE.product;

export const isExtractingBriefType = (briefType) =>
  [
    BRIEF_TYPE.unifiedContent,
    BRIEF_TYPE.unifiedProduct,
    BRIEF_TYPE.unifiedPromotionalEmail,
  ].includes(briefType);

export const isManualBriefType = (briefType) =>
  [
    BRIEF_TYPE.unifiedProduct,
    BRIEF_TYPE.unifiedContent,
    BRIEF_TYPE.socialManual,
    BRIEF_TYPE.unifiedPromotionalEmail,
    BRIEF_TYPE.email,
  ].includes(briefType);
