import React, { forwardRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormControl, FormLabel, MenuItem, Typography, Select } from '@material-ui/core';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import { CustomTooltip } from 'src/components/CustomTooltip';
import { useStyles } from './useStyles';

const EMPTY_VALUE = '';

export const SelectItems = forwardRef(
  (
    {
      className,
      classes,
      label,
      value,
      placeholder,
      hint,
      options,
      onChange,
      error,
      disabled,
      TooltipProps,
      ...restProps
    },
    ref
  ) => {
    const styles = useStyles({ classes });

    const [tooltipOpened, setTooltipOpened] = useState(false);
    const openTooltip = useCallback(() => {
      setTooltipOpened(true);
    }, []);
    const closeTooltip = useCallback(() => {
      setTooltipOpened(false);
    }, []);

    const [selectOpened, setSelectOpened] = useState(false);
    const openSelect = useCallback(() => {
      setTooltipOpened(false);
      setSelectOpened(true);
    }, []);
    const closeSelect = useCallback(() => {
      setTooltipOpened(false);
      setSelectOpened(false);
    }, []);

    const clampedValue = options.find((option) => option.value === value) ? value : EMPTY_VALUE;
    const isEmpty = clampedValue === EMPTY_VALUE;

    const handleChange = useCallback(
      (event) => {
        onChange(event.target.value);
      },
      [onChange]
    );

    return (
      <FormControl
        className={cx(styles.wrapper, { [styles.empty]: isEmpty })}
        ref={ref}
        disabled={disabled}
        data-testid="SelectItems"
        {...restProps}
      >
        {label && <FormLabel className={styles.label}>{label}</FormLabel>}

        <CustomTooltip
          title={!selectOpened ? hint : ''}
          open={tooltipOpened}
          onOpen={openTooltip}
          onClose={closeTooltip}
          enterNextDelay={1000}
          placement="top"
          {...TooltipProps}
        >
          <div>
            <Select
              className={cx(styles.root, className)}
              classes={{
                select: styles.select_select,
                icon: styles.select_icon,
              }}
              value={clampedValue}
              onChange={handleChange}
              variant="outlined"
              IconComponent={IconExpandMore}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                variant: 'menu',
              }}
              open={selectOpened}
              onOpen={openSelect}
              onClose={closeSelect}
              error={Boolean(error)}
              displayEmpty
              data-testid="SelectItems__Select"
            >
              {placeholder && (
                <MenuItem
                  className={styles.emptyOption}
                  value={EMPTY_VALUE}
                  disabled
                  data-testid="SelectItems__Select__EMPTY_VALUE"
                >
                  {placeholder}
                </MenuItem>
              )}

              {options.map((v) => (
                <MenuItem
                  className={styles.option}
                  key={v.value}
                  value={v.value}
                  data-testid={`SelectItems__Select__${v.label}`}
                >
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </CustomTooltip>

        {error && <Typography className={styles.error}>{error}</Typography>}
      </FormControl>
    );
  }
);

SelectItems.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  TooltipProps: PropTypes.shape(),
  disabled: PropTypes.bool,
};

SelectItems.defaultProps = {
  className: '',
  classes: null,
  label: '',
  value: null,
  placeholder: '',
  hint: '',
  error: null,
  TooltipProps: null,
  disabled: false,
};
