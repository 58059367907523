import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CO_VARIANT } from './schema';

const MUTATION = gql`
  mutation CreateCOVariant(
    $assetId: ID!
    $htmlText: String!
    $variationId: ID
    $screenshotId: ID
    $isCustom: Boolean
  ) {
    createContinuousOptimizationVariant(
      landingPageAssetId: $assetId
      htmlText: $htmlText
      variationId: $variationId
      screenshotId: $screenshotId
      isCustom: $isCustom
    ) {
      ...ContinuousOptimizationVariant
    }
  }
  ${FRAGMENT_CO_VARIANT}
`;

export const createCOVariant = ({ assetId, htmlText, variationId, screenshotId, isCustom }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { assetId, htmlText, variationId, screenshotId, isCustom },
  }).then(({ data }) => getSingleProp(data));
