import { useCallback } from 'react';

import {
  useWorkspaceActions,
  useCustomerActions,
  useCustomerCanCreateFeatureItem,
} from 'src/store';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { useRestrictActionPopup } from 'src/services';
import {
  useCreateOrUpdateWorkspace,
  useDeleteWorkspace,
  useUpgradePopup,
} from 'src/services/popup';
import { ROUTES } from 'src/routes';
import { useHistory } from 'src/lib/hooks';
import { captureMessage } from 'src/lib/sentry';

export const useHandlers = ({ closeMenu }) => {
  const customerActions = useCustomerActions();
  const createWorkspace = useCreateOrUpdateWorkspace();
  const deleteWorkspace = useDeleteWorkspace();
  const workspaceStoreActions = useWorkspaceActions();
  const openUpgradePopup = useUpgradePopup();
  const openRestrictActionPopup = useRestrictActionPopup();
  const history = useHistory();

  const customerCanCreateWorkspaces = useCustomerCanCreateFeatureItem(
    FEATURE_LIMITS.workspacesPerCustomer
  );

  const handleMenuClose = useCallback(() => {
    closeMenu();
  }, [closeMenu]);

  const handleDeleteWorkspace = useCallback(
    (workspace) => {
      handleMenuClose();
      deleteWorkspace(workspace);
    },
    [handleMenuClose, deleteWorkspace]
  );

  const handleWorkspaceClick = useCallback(
    async (workspace) => {
      handleMenuClose();
      try {
        await workspaceStoreActions.selectCurrentWorkspace(workspace.id);
      } catch (error) {
        captureMessage("Couldn't select workspace", {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'WorkspaceItem -> handleWorkspaceClick',
          },
        });
      }
      customerActions.refreshCurrentCustomerFeatureLimit();
      history.push(ROUTES.ROOT);
    },
    [handleMenuClose, workspaceStoreActions, customerActions, history]
  );

  const handleCreateOrUpdateWorkspace = useCallback(
    (workspace) => {
      handleMenuClose();

      // If this is "creation", check if it's allowed
      if (!workspace) {
        if (!customerActions.getIsCurrentUserAdmin()) {
          openRestrictActionPopup();
          return;
        }
        if (!customerCanCreateWorkspaces) {
          openUpgradePopup(FEATURE_LIMITS.workspacesPerCustomer);
          return;
        }
      }

      createWorkspace(workspace);
    },
    [
      customerActions,
      customerCanCreateWorkspaces,
      handleMenuClose,
      createWorkspace,
      openRestrictActionPopup,
      openUpgradePopup,
    ]
  );

  return {
    handleMenuClose,
    handleWorkspaceClick,
    handleCreateOrUpdateWorkspace,
    handleDeleteWorkspace,
  };
};
