import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query VariationFeedbackReasons($id: ID!, $rating: Int!) {
    variationFeedbackReasons(id: $id, rating: $rating) {
      reasons
    }
  }
`;

export const variationFeedbackReasons = (id, rating) => {
  const variables = { id, rating };

  return executeGqlQuery({
    query: QUERY,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
