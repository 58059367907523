import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      fontSize: theme.typography.pxToRem(14),
      borderRadius: theme.shape.borderRadiusSmall,
      padding: theme.spacing(0, 0.25),
    },
    assetBackground: { backgroundColor: COLORS.primary.silver },
  }),
  { name: 'PromptTagNode' }
);
