import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_TEXT_ANNOTATIONS } from './schema';

const MUTATION = gql`
  mutation ExtractCopyAnnotations(
    $customerId: ID!
    $generationTool: GenerationToolEnum!
    $text: String!
    $productName: String
    $maxWordsPerKeyword: Int!
  ) {
    extractCopyAnnotations(
      customerId: $customerId
      generationTool: $generationTool
      text: $text
      productName: $productName
      maxWordsPerKeyword: $maxWordsPerKeyword
    ) {
      ...TextAnnotations
    }
  }
  ${FRAGMENT_TEXT_ANNOTATIONS}
`;

export const extractCopyAnnotations = async (
  { customerId, generationTool, text, maxWordsPerKeyword, productName = null },
  options
) => {
  const variables = { customerId, generationTool, text, maxWordsPerKeyword, productName };

  const result = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  return getSingleProp(result.data);
};
