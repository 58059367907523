import { createContainer } from 'react-sweet-state';

import { TalkingPointsViewsStore } from './TalkingPointsViewsStore';

export const TalkingPointsViewsStoreContainer = createContainer(TalkingPointsViewsStore, {
  onInit:
    () =>
    ({ setState, dispatch }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState, dispatch }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
