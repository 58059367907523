import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateContinuousOptimizationSegment($id: ID!, $keywords: [String!]) {
    updateContinuousOptimizationSegment(id: $id, keywords: $keywords) {
      id
      keywords
    }
  }
`;

export const updateContinuousOptimizationSegment = ({ id, keywords }) => {
  const variables = {
    id,
    keywords,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
