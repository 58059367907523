import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_TONE_OF_VOICE } from 'src/graphql/toneOfVoice';

const MUTATION = gql`
  mutation UpdateWorkspaceAnalyzerTovDefault(
    $generationTool: GenerationToolEnum!
    $customTovId: ID!
    $workspaceId: ID!
  ) {
    updateWorkspaceAnalyzerTovDefault(
      generationTool: $generationTool
      customTovId: $customTovId
      workspaceId: $workspaceId
    ) {
      ...CustomToneOfVoice
    }
  }
  ${FRAGMENT_CUSTOM_TONE_OF_VOICE}
`;

export const updateWorkspaceAnalyzerTovDefault = ({ generationTool, customTovId, workspaceId }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: {
      generationTool,
      customTovId,
      workspaceId,
    },
  }).then(({ data }) => getSingleProp(data));
};
