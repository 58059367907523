import { useEffect, useRef, useState } from 'react';

const FILTER_TIMEOUT = 100;

export const useFilteredProjects = (projects, favoritesOnly) => {
  const projectsRef = useRef();
  const [filteringResult, setFilteringResult] = useState({ projects, filtersActive: false });

  useEffect(() => {
    const applyFilters = () => {
      const filteredByFavorite = filterByFavorite(projects, favoritesOnly);

      setFilteringResult({
        projects: filteredByFavorite,
        filtersActive: favoritesOnly,
      });
    };

    // If 'projects' list changed on its own - apply filter right away
    if (projectsRef.current !== projects) {
      projectsRef.current = projects;
      applyFilters();
      return undefined;
    }

    // If 'searchText' changed, wait some time, and then apply filtering
    const timeout = setTimeout(applyFilters, FILTER_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [projects, favoritesOnly]);

  return filteringResult.filtersActive ? filteringResult.projects : projects;
};

const filterByFavorite = (projects, favoritesOnly) => {
  if (!favoritesOnly) {
    return projects;
  }

  return projects.filter((project) => project.favorite);
};
