import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(-1),
    },
    resultBox: {},

    title: {
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.neutral.black,
      fontSize: theme.typography.pxToRem(14),
    },

    url: {
      color: COLORS.primary.default,
      wordBreak: 'break-word',
    },

    text: {
      paddingTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
    },
  }),
  { name: 'PlagiarismCheckResult' }
);
