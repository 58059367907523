import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  title: {
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.primary,
  },
  description: {
    margin: theme.spacing(0.5, 0),
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },
  wrapperAge: {
    width: '100%',
    height: 110,
  },
  DataUnavailable: {
    fill: '#9FA8BD',
    fontSize: theme.typography.pxToRem(12),
  },
}));
