import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IconButton } from '@material-ui/core';

import { CustomTooltip } from 'src/components/CustomTooltip';
import IconDelete from 'src/assets/icons/Delete';
import { PersonaAvatar } from 'src/components/PersonaAvatar';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { PersonaPainPoints } from 'src/components/PersonaPainPoints';
import { ContextMenu } from 'src/components/ContextMenu';
import { IconDisk } from 'src/assets/icons/IconDisk';
import { IconEditPencil } from 'src/assets/icons/IconEditPencil';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { getAgeGroupLabel, getGenderLabel } from 'src/data/demographics';
import { useStyles } from './useStyles';

const MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

export const PersonaDetailsPanel = ({
  className,
  persona,
  onEdit,
  onDelete,
  onSave,
  brandVoices,
}) => {
  const styles = useStyles();

  const { name, avatarType, minAge, maxAge, gender, painPoints, isSuggested } = persona || {};
  const brandVoiceItems = brandVoices.map((bv) => ({
    id: bv.id,
    label: bv.name,
    onClick: () => onSave?.(bv, persona),
  }));

  const handleDeleteClick = useCallback(() => {
    onDelete?.(persona);
  }, [onDelete, persona]);

  const handleEditClick = useCallback(() => {
    onEdit?.(persona);
  }, [onEdit, persona]);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.header}>
        <PersonaAvatar
          className={styles.avatar}
          avatarType={avatarType}
        />
        <div>
          <TruncatedTypography
            className={styles.name}
            variant={TYPOGRAPHY_VARIANT.semiBold14}
          >
            {name}
          </TruncatedTypography>
          <CustomTypography
            className={styles.description}
            variant={TYPOGRAPHY_VARIANT.regular12}
          >
            {minAge} - {getAgeGroupLabel(maxAge)} <span>•</span> {getGenderLabel(gender)}
          </CustomTypography>
        </div>

        <div className={styles.actions}>
          {isSuggested && (
            <>
              {brandVoices.length === 1 && (
                <CustomTooltip title={`Save to ${brandVoices[0].name}'s audiences`}>
                  <IconButton
                    onClick={() => onSave?.(brandVoices[0], persona)}
                    className={styles.iconButton}
                  >
                    <IconDisk />
                  </IconButton>
                </CustomTooltip>
              )}
              {brandVoiceItems.length > 1 && (
                <ContextMenu
                  items={brandVoiceItems}
                  small
                  renderButton={(props) => (
                    <CustomTooltip title="Save to audiences">
                      <IconButton
                        className={styles.iconButton}
                        {...props}
                      >
                        <IconDisk />
                      </IconButton>
                    </CustomTooltip>
                  )}
                  title="Save to one of these Brand Voices:"
                  menuProps={MENU_PROPS}
                />
              )}
            </>
          )}

          <CustomTooltip title="Edit">
            <IconButton
              className={styles.iconButton}
              onClick={handleEditClick}
            >
              <IconEditPencil className={styles.iconEditPersona} />
            </IconButton>
          </CustomTooltip>

          <CustomTooltip title="Delete">
            <IconButton
              className={styles.iconButton}
              onClick={handleDeleteClick}
            >
              <IconDelete />
            </IconButton>
          </CustomTooltip>
        </div>
      </div>
      <PersonaPainPoints
        className={styles.painPoints}
        painPoints={painPoints}
      />
    </div>
  );
};

PersonaDetailsPanel.propTypes = {
  className: PropTypes.string,
  persona: PropTypes.shape({
    isSuggested: PropTypes.bool,
  }).isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  brandVoices: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
};
PersonaDetailsPanel.defaultProps = {
  className: '',
  onEdit: null,
  onDelete: null,
  onSave: null,
  brandVoices: [],
};
