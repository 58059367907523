import ProgressPopup from 'src/components/ProgressPopup';
import ConfirmPopup from 'src/components/ConfirmPopup';
import { PopupInformation } from 'src/components/PopupInformation';
import { ProjectSettingsPopup } from 'src/views/ProjectSettingsPopup';
import CancelSubscriptionPopup from 'src/views/CancelSubscriptionPopup';
import AffectedProjectsPopup from 'src/views/AffectedProjectsPopup';
import WelcomePopup from 'src/views/WelcomePopup';
import ReportVariationPopup from 'src/views/ReportVariationPopup';
import QuotaEndedPopup from 'src/views/QuotaEndedPopup';
import { ExplainerVideoPopup } from 'src/views/ExplainerVideoPopup';
import { PopupVariantScreenshotPreview } from 'src/components/PopupVariantScreenshotPreview';
import { WorkspacePopup } from 'src/views/WorkspacePopup';
import { CustomerPersonaPopup } from 'src/views/CustomerPersonaPopup';
import { EditPaymentMethodPopup } from 'src/views/EditPaymentMethodPopup';
import { DowngradePlanConfirmationPopup } from 'src/views/DowngradePlanConfirmationPopup';
import { DowngradeTierConfirmationPopup } from 'src/views/DowngradeTierConfirmationPopup';
import { PopupAudienceTarget } from 'src/components/PopupAudienceTarget';
import { DataDrivenDocumentsPopup } from 'src/components/DataDrivenDocumentsPopup';
import { GenericNotificationPopup } from 'src/components/GenericNotificationPopup';
import { ConfirmAddToCampaignPopup } from 'src/views/ProjectOptimizationView/components/ConfirmAddToCampaignPopup';
import { ScoringMethodPopup } from 'src/views/ScoringMethodPopup';
import GenerationToolPopup from 'src/views/GenerationToolPopup';
import { InviteUsersPopup } from 'src/views/InviteUsersPopup';
import { RenameCustomerPopup } from 'src/views/RenameCustomerPopup';
import { InviteSubmitPopup } from 'src/views/InviteSubmitPopup';
import SharePopup from 'src/views/SharePopup';
import { ImportBriefFromUrlPopup } from 'src/views/ImportBriefFromUrlPopup';
import { CustomNotificationPopup } from 'src/components/CustomNotificationPopup';
import { AdAccountsSelectionPopup } from 'src/views/AdAccountsSelectionPopup';
import { BrandTermPopup } from 'src/views/BrandTermPopup';
import { BusinessBookDemoPopup } from 'src/components/BusinessBookDemoPopup';
import { BrandBriefPopup } from 'src/views/BrandBriefPopup';
import { CustomFormulaPopup } from 'src/views/CustomFormulaPopup';
import { ToneOfVoicePopup } from 'src/views/ToneOfVoicePopup';
import { UpgradePopup } from 'src/views/UpgradePopup';
import { UpgradePopover } from 'src/views/UpgradePopover';
import { GeneratedDetailsPopup } from 'src/views/ProjectDocumentView/components/GeneratedDetailsPopup';
import { NoWorkspacesPopup } from 'src/views/NoWorkspacesPopup';
import { TalkingPointsPopup } from 'src/views/TalkingPointsPage/views/TalkingPointsPopup';
import { AddResourcePopup } from 'src/views/AddResourcePopup';
import { PersonaSelectionPopup } from 'src/views/PersonaSelectionPopup';
import { EditSuggestedCustomerPersonaPopup } from 'src/views/EditSuggestedCustomerPersonaPopup';
import { ManageWebsiteAutomationPopup } from 'src/views/ManageWebsiteAutomationsPopup';
import { ConnectToChannelPopup } from 'src/views/ConnectToChannelPopup';
import { AfterCancelSubscriptionPopup } from 'src/views/AfterCancelSubscriptionPopup';
import { AccountsDataExtractionPopup } from 'src/views/AccountsDataExtractionPopup';
import { BrandVoicePopup } from 'src/views/BrandVoicePopup';
import { CustomModelPopup } from 'src/views/CustomModelPopup';
import { AccountExtractionLoaderPopup } from 'src/views/AccountExtractionLoaderPopup';
import { TemplatesExpertEditPopup } from 'src/views/TemplatesExpertEditPopup';
import { CustomModelSuccessPopup } from 'src/views/CustomModelSuccessPopup';
import { WelcomeRetrialPopup } from 'src/views/WelcomeRetrialPopop';
import { BlogCustomModelPopup } from 'src/views/BlogCustomModelPopup';
import { AddFileResourcePopup } from 'src/views/AddFileResourcePopup';
import { CreateTalkingPointsViewPopup } from 'src/views/CreateTalkingPointsViewPopup';
import { BrandVocabularyPopup } from 'src/views/BrandVocabularyPopup';
import { CustomDialog } from 'src/components/CustomDialog';
import { ProjectFolderPopup } from 'src/views/ProjectFolderPopup';
import { DeleteProjectFolderPopup } from 'src/views/DeleteProjectFolderPopup';
import { ChangeHeadlinePopup } from 'src/components/ChangeHeadlinePopup';
import { ConnectToChannelManuallyPopup } from 'src/views/ConnectToChannelManuallyPopup';
import { RenameChannelAccountPopup } from 'src/views/RenameChannelAccountPopup';
import { BrandTermsImportPopup } from 'src/views/BrandTermsImportPopup';
import BenchmarkPopup from 'src/views/BenchmarkPopup';
import { AnalyzerToneOfVoiceSelectionPopup } from 'src/views/AnalyzerToneOfVoiceSelectionPopup';
import { ImportToneOfVoicePopup } from 'src/views/ImportToneOfVoicePopup';
import { BulkDeleteResourcesPopup } from 'src/views/BulkDeleteResourcesPopup';

export const PROGRESS_POPUP = 'PROGRESS';
export const CONFIRM_POPUP = 'CONFIRM';
export const INFORMATION_POPUP = 'INFORMATION';
export const PROJECT_SETTINGS_POPUP = 'PROJECT_SETTINGS';
export const SUBSCRIPTION_CANCEL_POPUP = 'SUBSCRIPTION_CANCEL';
export const AFFECTED_PROJECTS_POPUP = 'AFFECTED_PROJECTS';
export const WELCOME_POPUP = 'WELCOME';
export const WELCOME_RETRIAL_POPUP = 'WELCOME_RETRIAL_POPUP';
export const REPORT_VARIATION_POPUP = 'REPORT_VARIATION';
export const QUOTA_ENDED_POPUP = 'QUOTA_ENDED';
export const EXPLAINER_VIDEO_POPUP = 'EXPLAINER_VIDEO_POPUP';
export const VARIANT_SCREENSHOT_PREVIEW_POPUP = 'VARIANT_SCREENSHOT_PREVIEW_POPUP';
export const WORKSPACE_POPUP = 'WORKSPACE_POPUP';
export const CUSTOMER_PERSONA_POPUP = 'CUSTOMER_PERSONA_POPUP';
export const GENERIC_NOTIFICATION_POPUP = 'GENERIC_NOTIFICATION';
export const CUSTOM_NOTIFICATION_POPUP = 'CUSTOM_NOTIFICATION_POPUP';
export const EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD';
export const DOWNGRADE_CONFIRMATION_PLAN = 'DOWNGRADE_CONFIRMATION_PLAN';
export const DOWNGRADE_CONFIRMATION_TIER = 'DOWNGRADE_CONFIRMATION_TIER';
export const AUDIENCE_TARGET_POPUP = 'AUDIENCE_TARGET';
export const DATA_DRIVEN_DOCUMENTS_POPUP = 'DATA_DRIVEN_DOCUMENTS_POPUP';
export const CONFIRM_ADD_TO_CAMPAIGN_POPUP = 'CONFIRM_ADD_TO_CAMPAIGN_POPUP';
export const SCORING_METHOD_POPUP = 'SCORING_METHOD';
export const GENERATION_TOOL_POPUP = 'GENERATION_TOOL';
export const INVITE_USERS_POPUP = 'INVITE_USERS';
export const RENAME_CUSTOMER_POPUP = 'RENAME_CUSTOMER_POPUP';
export const CHANGE_HEADLINE_POPUP = 'CHANGE_HEADLINE_POPUP';
export const INVITE_ACCEPT_POPUP = 'INVITE_ACCEPT_POPUP';
export const SHARE_POPUP = 'SHARE';
export const BLOG_SECTION_INSTRUCTIONS_FROM_URL = 'BLOG_SECTION_INSTRUCTIONS_FROM_URL';
export const AD_ACCOUNTS_SELECTION_POPUP = 'AD_ACCOUNTS_SELECTION_POPUP';
export const BRAND_TERM_POPUP = 'BRAND_TERM';
export const BUSINESS_BOOK_DEMO_POPUP = 'BUSINESS_BOOK_DEMO_POPUP';
export const IMPORT_BRIEF_FROM_URL_POPUP = 'IMPORT_BRIEF_FROM_URL';
export const BRAND_BRIEF_POPUP = 'BRAND_BRIEF';
export const CUSTOM_FORMULA_POPUP = 'CUSTOM_FORMULA_POPUP';
export const TONE_OF_VOICE_POPUP = 'TONE_OF_VOICE_POPUP';
export const UPGRADE_POPUP = 'UPGRADE_POPUP';
export const UPGRADE_POPOVER = 'UPGRADE_POPOVER';
export const GENERATED_DETAILS_POPUP = 'GENERATED_DETAILS_POPUP';
export const NO_WORKSPACES_POPUP = 'NO_WORKSPACES_POPUP';
export const TALKING_POINTS_POPUP = 'TALKING_POINTS_POPUP';
export const ADD_RESOURCE_POPUP = 'ADD_RESOURCE_POPUP';
export const ADD_FILE_RESOURCE_POPUP = 'ADD_FILE_RESOURCE_POPUP';
export const PERSONA_SELECTION_POPUP = 'PERSONA_SELECTION';
export const EDIT_SUGGESTED_CUSTOMER_PERSONA_POPUP = 'EDIT_SUGGESTED_CUSTOMER_PERSONA_POPUP';
export const MANAGE_WEBSITE_AUTOMATION_POPUP = 'MANAGE_WEBSITE_AUTOMATION';
export const CONNECT_TO_CHANNEL_POPUP = 'CONNECT_TO_CHANNEL_POPUP';
export const AFTER_CANCEL_SUBSCRIPTION_POPUP = 'AFTER_CANCEL_SUBSCRIPTION_POPUP';
export const ACCOUNTS_DATA_EXTRACTION_POPUP = 'ACCOUNTS_DATA_EXTRACTION_POPUP';
export const BRAND_VOICE_POPUP = 'BRAND_VOICE_POPUP';
export const CUSTOM_MODEL_POPUP = 'CUSTOM_MODEL_POPUP';
export const EXTRACTION_LOADER_POPUP = 'EXTRACTION_LOADER_POPUP';
export const CUSTOM_MODEL_SUCCESS_POPUP = 'CUSTOM_MODEL_SUCCESS_POPUP';
export const TEMPLATES_EXPERT_EDIT_POPUP = 'TEMPLATES_EXPERT_EDIT_POPUP';
export const BLOG_CUSTOM_MODEL_POPUP = 'BLOG_CUSTOM_MODEL_POPUP';
export const CREATE_TALKING_POINTS_VIEW_POPUP = 'CREATE_TALKING_POINTS_VIEW_POPUP';
export const BRAND_VOCABULARY_POPUP = 'BRAND_VOCABULARY_POPUP';
export const CUSTOM_DIALOG = 'CUSTOM_DIALOG';
export const PROJECT_FORMULA_SELECTION = 'PROJECT_FORMULA_SELECTION';
export const PROJECT_FOLDER = 'PROJECT_FOLDER';
export const DELETE_PROJECT_FOLDER = 'DELETE_PROJECT_FOLDER';
export const CONNECT_CHANNEL_MANUALLY_POPUP = 'CONNECT_CHANNEL_MANUALLY_POPUP';
export const RENAME_CHANNEL_ACCOUNT_POPUP = 'RENAME_CHANNEL_ACCOUNT_POPUP';
export const BRAND_TERMS_IMPORT_POPUP = 'BRAND_TERMS_IMPORT';
export const BENCHMARK_POPUP = 'BENCHMARK_POPUP';
export const ANALYZER_TONE_OF_VOICE_SELECTION_POPUP = 'ANALYZER_TONE_OF_VOICE_SELECTION_POPUP';
export const IMPORT_TONE_OF_VOICE_POPUP = 'IMPORT_TONE_OF_VOICE_POPUP';
export const BULK_DELETE_RESOURCES = 'BULK_DELETE_RESOURCES';

export const MODAL_TYPES = [
  {
    type: PROJECT_SETTINGS_POPUP,
    component: ProjectSettingsPopup,
  },
  {
    type: PROGRESS_POPUP,
    component: ProgressPopup,
  },
  {
    type: CONFIRM_POPUP,
    component: ConfirmPopup,
  },
  {
    type: INFORMATION_POPUP,
    component: PopupInformation,
  },
  {
    type: SUBSCRIPTION_CANCEL_POPUP,
    component: CancelSubscriptionPopup,
  },
  {
    type: AFFECTED_PROJECTS_POPUP,
    component: AffectedProjectsPopup,
  },
  {
    type: WELCOME_POPUP,
    component: WelcomePopup,
  },
  {
    type: WELCOME_RETRIAL_POPUP,
    component: WelcomeRetrialPopup,
  },
  {
    type: REPORT_VARIATION_POPUP,
    component: ReportVariationPopup,
  },
  {
    type: QUOTA_ENDED_POPUP,
    component: QuotaEndedPopup,
  },
  {
    type: EXPLAINER_VIDEO_POPUP,
    component: ExplainerVideoPopup,
  },
  {
    type: VARIANT_SCREENSHOT_PREVIEW_POPUP,
    component: PopupVariantScreenshotPreview,
  },
  {
    type: WORKSPACE_POPUP,
    component: WorkspacePopup,
  },
  {
    type: CUSTOMER_PERSONA_POPUP,
    component: CustomerPersonaPopup,
  },
  {
    type: EDIT_PAYMENT_METHOD,
    component: EditPaymentMethodPopup,
  },
  {
    type: DOWNGRADE_CONFIRMATION_PLAN,
    component: DowngradePlanConfirmationPopup,
  },
  {
    type: DOWNGRADE_CONFIRMATION_TIER,
    component: DowngradeTierConfirmationPopup,
  },
  {
    type: AUDIENCE_TARGET_POPUP,
    component: PopupAudienceTarget,
  },
  {
    type: DATA_DRIVEN_DOCUMENTS_POPUP,
    component: DataDrivenDocumentsPopup,
  },
  {
    type: GENERIC_NOTIFICATION_POPUP,
    component: GenericNotificationPopup,
  },
  {
    type: CUSTOM_NOTIFICATION_POPUP,
    component: CustomNotificationPopup,
  },
  {
    type: CONFIRM_ADD_TO_CAMPAIGN_POPUP,
    component: ConfirmAddToCampaignPopup,
  },
  {
    type: SCORING_METHOD_POPUP,
    component: ScoringMethodPopup,
  },
  {
    type: GENERATION_TOOL_POPUP,
    component: GenerationToolPopup,
  },
  {
    type: INVITE_USERS_POPUP,
    component: InviteUsersPopup,
  },
  {
    type: RENAME_CUSTOMER_POPUP,
    component: RenameCustomerPopup,
  },
  {
    type: CHANGE_HEADLINE_POPUP,
    component: ChangeHeadlinePopup,
  },
  {
    type: INVITE_ACCEPT_POPUP,
    component: InviteSubmitPopup,
  },
  {
    type: SHARE_POPUP,
    component: SharePopup,
  },
  {
    type: AD_ACCOUNTS_SELECTION_POPUP,
    component: AdAccountsSelectionPopup,
  },
  {
    type: BRAND_TERM_POPUP,
    component: BrandTermPopup,
  },
  {
    type: BUSINESS_BOOK_DEMO_POPUP,
    component: BusinessBookDemoPopup,
  },
  {
    type: IMPORT_BRIEF_FROM_URL_POPUP,
    component: ImportBriefFromUrlPopup,
  },
  {
    type: BRAND_BRIEF_POPUP,
    component: BrandBriefPopup,
  },
  {
    type: CUSTOM_FORMULA_POPUP,
    component: CustomFormulaPopup,
  },
  {
    type: TONE_OF_VOICE_POPUP,
    component: ToneOfVoicePopup,
  },
  {
    type: UPGRADE_POPUP,
    component: UpgradePopup,
  },
  {
    type: UPGRADE_POPOVER,
    component: UpgradePopover,
  },
  {
    type: GENERATED_DETAILS_POPUP,
    component: GeneratedDetailsPopup,
  },
  {
    type: NO_WORKSPACES_POPUP,
    component: NoWorkspacesPopup,
  },
  {
    type: TALKING_POINTS_POPUP,
    component: TalkingPointsPopup,
  },
  {
    type: ADD_RESOURCE_POPUP,
    component: AddResourcePopup,
  },
  {
    type: ADD_FILE_RESOURCE_POPUP,
    component: AddFileResourcePopup,
  },
  {
    type: PERSONA_SELECTION_POPUP,
    component: PersonaSelectionPopup,
  },
  {
    type: EDIT_SUGGESTED_CUSTOMER_PERSONA_POPUP,
    component: EditSuggestedCustomerPersonaPopup,
  },
  {
    type: MANAGE_WEBSITE_AUTOMATION_POPUP,
    component: ManageWebsiteAutomationPopup,
  },
  {
    type: CONNECT_TO_CHANNEL_POPUP,
    component: ConnectToChannelPopup,
  },
  {
    type: AFTER_CANCEL_SUBSCRIPTION_POPUP,
    component: AfterCancelSubscriptionPopup,
  },
  {
    type: ACCOUNTS_DATA_EXTRACTION_POPUP,
    component: AccountsDataExtractionPopup,
  },
  {
    type: BRAND_VOICE_POPUP,
    component: BrandVoicePopup,
  },
  {
    type: CUSTOM_MODEL_POPUP,
    component: CustomModelPopup,
  },
  {
    type: EXTRACTION_LOADER_POPUP,
    component: AccountExtractionLoaderPopup,
  },
  {
    type: CUSTOM_MODEL_SUCCESS_POPUP,
    component: CustomModelSuccessPopup,
  },
  {
    type: TEMPLATES_EXPERT_EDIT_POPUP,
    component: TemplatesExpertEditPopup,
  },
  {
    type: BLOG_CUSTOM_MODEL_POPUP,
    component: BlogCustomModelPopup,
  },
  {
    type: CREATE_TALKING_POINTS_VIEW_POPUP,
    component: CreateTalkingPointsViewPopup,
  },
  {
    type: BRAND_VOCABULARY_POPUP,
    component: BrandVocabularyPopup,
  },
  {
    type: CUSTOM_DIALOG,
    component: CustomDialog,
  },
  {
    type: PROJECT_FOLDER,
    component: ProjectFolderPopup,
  },
  {
    type: DELETE_PROJECT_FOLDER,
    component: DeleteProjectFolderPopup,
  },
  {
    type: CONNECT_CHANNEL_MANUALLY_POPUP,
    component: ConnectToChannelManuallyPopup,
  },
  {
    type: RENAME_CHANNEL_ACCOUNT_POPUP,
    component: RenameChannelAccountPopup,
  },
  {
    type: BRAND_TERMS_IMPORT_POPUP,
    component: BrandTermsImportPopup,
  },
  {
    type: BENCHMARK_POPUP,
    component: BenchmarkPopup,
  },
  {
    type: ANALYZER_TONE_OF_VOICE_SELECTION_POPUP,
    component: AnalyzerToneOfVoiceSelectionPopup,
  },
  {
    type: IMPORT_TONE_OF_VOICE_POPUP,
    component: ImportToneOfVoicePopup,
  },
  {
    type: BULK_DELETE_RESOURCES,
    component: BulkDeleteResourcesPopup,
  },
];
