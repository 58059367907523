import React from 'react';
import ReactDOM from 'react-dom/client';
import { defaults } from 'react-sweet-state';
import TagManager from 'react-gtm-module';
import { enableMapSet } from 'immer';

import './index.css';
import * as sentry from 'src/lib/sentry';
import mixpanel from 'src/lib/mixpanel';
import * as logRocket from 'src/lib/logrocket';
import App from './App';
import { isLocalhost, isProduction, isStaging, isTeadsProduction, isTesting } from './lib/url';
import { injectChurnkeyScript } from 'src/services/churnkey';

// Enable Map/Set support in "immer"
enableMapSet();

const host = window.location.href;
const isStagingHost = isStaging(host);
const isProductionHost = isProduction(host) || isTeadsProduction(host);
const isTestingHost = isTesting(host);
const acceptableHost = isLocalhost(host) || isTestingHost || isStagingHost || isProductionHost;

if (acceptableHost) {
  if (!isTestingHost) {
    TagManager.initialize({
      gtmId: 'GTM-MGXL637',
    });
    sentry.init();
  }

  if (isProductionHost) {
    logRocket.init();
  }

  mixpanel.init();
  injectChurnkeyScript();

  const root = ReactDOM.createRoot(document.getElementById('app'));
  root.render(
    // TODO: StrictMode brakes the app(useEffect() multiple call, etc.), check if possible to enable
    // <React.StrictMode>
    <App />
    // </React.StrictMode>
  );
}

if (process.env.REACT_APP_ENV === 'development') {
  defaults.devtools = true;
}
