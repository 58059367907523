import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_BRAND_TERM } from './schema';

const QUERY = gql`
  query BrandTerms($brandVoiceId: ID!) {
    brandTerms(brandVoiceId: $brandVoiceId) {
      ...BrandTerm
    }
  }
  ${FRAGMENT_BRAND_TERM}
`;

export const queryBrandTerms = async (brandVoiceId) => {
  const variables = { brandVoiceId };

  const result = await executeGqlQuery({
    query: QUERY,
    variables,
  });
  return getSingleProp(result.data);
};
