import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation SwitchBlogPostBuilderOutlineSections($sectionId1: ID!, $sectionId2: ID!) {
    switchBlogPostBuilderOutlineSections(sectionId1: $sectionId1, sectionId2: $sectionId2) {
      id
    }
  }
`;

export const switchBlogPostBuilderOutlineSections = ({ sectionId1, sectionId2 }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { sectionId1, sectionId2 },
  }).then(({ data }) => getSingleProp(data));
};
