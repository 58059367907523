import { GENERATION_TOOL } from '../generationTool';

export const TONE_OF_VOICE_PERSONALITY = {
  advisor: 'advisor',
  motivator: 'motivator',
  entertainer: 'entertainer',
  empathetic: 'empathetic',
  trendsetter: 'trendsetter',
  innovator: 'innovator',
  educator: 'educator',
};

export const PRESET_TONE_OF_VOICE = {
  engaging: 'engaging',
  ...TONE_OF_VOICE_PERSONALITY,
};

export const MANUAL_TONE_OF_VOICE = {
  friendly: 'friendly',
  confident: 'confident',
  witty: 'witty',
  urgent: 'urgent',
  creative: 'creative',
  informative: 'informative',
};

export const MANUAL_TONE_OF_VOICE_PROPS = {
  [MANUAL_TONE_OF_VOICE.friendly]: {
    title: 'Friendly',
  },
  [MANUAL_TONE_OF_VOICE.confident]: {
    title: 'Confident',
  },
  [MANUAL_TONE_OF_VOICE.witty]: {
    title: 'Witty',
  },
  [MANUAL_TONE_OF_VOICE.urgent]: {
    title: 'Urgent',
  },
  [MANUAL_TONE_OF_VOICE.creative]: {
    title: 'Creative',
  },

  [MANUAL_TONE_OF_VOICE.informative]: {
    title: 'Informative',
  },
};

export const PRESET_TONE_OF_VOICE_PROPS = {
  [PRESET_TONE_OF_VOICE.engaging]: {
    id: PRESET_TONE_OF_VOICE.engaging,
    title: 'Neutral',
    description: 'The standard AI tone of voice',
    label: '',
  },
  [PRESET_TONE_OF_VOICE.innovator]: {
    id: PRESET_TONE_OF_VOICE.innovator,
    title: 'Innovator',
    description: 'Share new developments and ideas',
    label: 'Apple, Tesla, Google',
  },
  [PRESET_TONE_OF_VOICE.trendsetter]: {
    id: PRESET_TONE_OF_VOICE.trendsetter,
    title: 'Trendsetter',
    description: 'Connect with social media natives',
    label: 'Netflix, TikTok, Spotify',
  },
  [PRESET_TONE_OF_VOICE.advisor]: {
    id: PRESET_TONE_OF_VOICE.advisor,
    title: 'Advisor',
    description: 'Establish credibility with expert advice',
    label: 'J.P. Morgan, PwC, McKinsey & Co',
  },
  [PRESET_TONE_OF_VOICE.motivator]: {
    id: PRESET_TONE_OF_VOICE.motivator,
    title: 'Motivator',
    description: 'Inspire readers, driving them toward action',
    label: 'Nike, Under Armour, Peloton',
  },
  [PRESET_TONE_OF_VOICE.entertainer]: {
    id: PRESET_TONE_OF_VOICE.entertainer,
    title: 'Entertainer',
    description: 'Create fun copy that makes people smile',
    label: 'Snickers, Old Spice, Duolingo',
  },
  [PRESET_TONE_OF_VOICE.empathetic]: {
    id: PRESET_TONE_OF_VOICE.empathetic,
    title: 'Empathetic',
    description: 'Show care and connect on a deeper level',
    label: 'Dove, Airbnb, Cadbury',
  },
  [PRESET_TONE_OF_VOICE.educator]: {
    id: PRESET_TONE_OF_VOICE.educator,
    title: 'Educator',
    description: 'Provide clear, helpful content to educate the audience',
    label: 'Coursera, edX, Khan Academy',
  },
};

export const DEFAULT_SUGGEST_TOV_MODE = PRESET_TONE_OF_VOICE.engaging;

export const TOV_INPUT_TYPE = {
  samples: 'samples',
  guidelines: 'guidelines',
};

export const DRAFT_TOV_ID = 'draft_tov';

export const OTHER_TEST_OPTION_ID = 'other-option';

export const TOV_TEST_OPTION = {
  fbPostWebinar: 'fb-post-webinar',
  liPostRenewable: 'li-post-renewable-',
  liPostWorkplace: 'li-post-workplace-',
  emailFeedbackSurvey: 'email-feedback-survey-',
  emailFeatureAnnouncement: 'email-feature-announcement',
  otherOption: OTHER_TEST_OPTION_ID,
};

export const TOV_TEST_OPTION_PROPS = {
  [TOV_TEST_OPTION.fbPostWebinar]: {
    title: 'Facebook Post - Webinar Invitation',
    text: `Join ACME Corp's exclusive webinar! Discover the latest industry trends, expert insights, and actionable strategies to elevate your business.\nLearn from field leaders and network with like-minded professionals. Our experts will share knowledge on the latest advancements and how to apply them for success.\nRegister now to secure your spot and gain valuable insights. Visit our website for details. We look forward to seeing you there!`,
    generationTool: GENERATION_TOOL.facebookPost,
  },
  [TOV_TEST_OPTION.liPostRenewable]: {
    title: 'LinkedIn Post - Renewable Energy',
    text: `Exciting news! ACME Corp’s new community solar projects and renewable energy agreements are transforming 11 states. These projects will add clean energy to local grids, create jobs, support rural areas, and advance ACME Corp’s renewable targets.\nOur portfolio includes over 600 renewable energy projects across 10 countries. Learn how these initiatives will deliver savings to customers and enhance America’s energy grid. Find out more: [Insert link]`,
    generationTool: GENERATION_TOOL.linkedinPost,
  },
  [TOV_TEST_OPTION.liPostWorkplace]: {
    title: 'LinkedIn Post - Best Work Place',
    text: `We are thrilled to announce that AcmeCorp has been named one of Glassdoor's Best Places to Work for 2024! This honor reflects our team's dedication and the inclusive, supportive work environment we've built. We're grateful to every member of our organization for their contributions. Thank you to Glassdoor and our incredible team. Here's to continued success and growth!`,
    generationTool: GENERATION_TOOL.linkedinPost,
  },
  [TOV_TEST_OPTION.emailFeedbackSurvey]: {
    title: 'Feedback Survey Email',
    text: `Dear [Customer Name],\nAt Acme Corp., we strive to provide exceptional experiences for customers like you. We would greatly appreciate your feedback through a short, 5-minute survey.\nYour honest responses will help us improve our products/services. The survey is anonymous, and your participation will make a real difference.\nClick here to take the survey: [Survey Link]\nThank you for your time and support!\nSincerely,\nThe Acme Corp. Team`,
    generationTool: GENERATION_TOOL.emailBody,
  },
  [TOV_TEST_OPTION.emailFeatureAnnouncement]: {
    title: 'Feature Announcement Email',
    text: `Dear [Customer Name],\nWe're excited to introduce a new feature that enhances your Acme Corp experience! Our latest update includes advanced data analytics for deeper insights and informed decisions. Enjoy real-time analysis and customizable reporting tailored to your needs.\nWe believe this innovation will boost your productivity and streamline workflows. Log in to explore this new feature today!\nThank you for your continued support.\nBest regards,\nThe Acme Corp Team`,
    generationTool: GENERATION_TOOL.emailBody,
  },
  [TOV_TEST_OPTION.otherOption]: {
    title: 'Other',
    text: '',
    generationTool: GENERATION_TOOL.freestyle,
  },
};

export const TONE_OF_VOICE_CREATION_MODE = {
  manual: 'manual',
  learnFromText: 'learnFromText',
  importFromPDF: 'importFromPDF',
  importFromURL: 'importFromURL',
  importFromDescription: 'importFromDescription',
};
