import { makeStyles } from '@material-ui/core';

import { COLORS } from 'src/data/ui';
import {
  EDITOR_TOOLBAR_BUTTON_SPACING,
  EDITOR_TOOLBAR_BUTTON_WIDTH,
  EDITOR_TOOLBAR_BUTTON_HEIGHT,
} from '../../const';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      // If size/margin/padding changes please check and update it also in useResponsiveFormatButtons hook
      minWidth: EDITOR_TOOLBAR_BUTTON_WIDTH,
      minHeight: EDITOR_TOOLBAR_BUTTON_HEIGHT,
      padding: theme.spacing(0),
      borderRadius: theme.shape.borderRadiusSmall,
      color: COLORS.gray.gray30,

      '& + &': {
        marginLeft: EDITOR_TOOLBAR_BUTTON_SPACING,
      },

      '&:hover, &:focus': {
        backgroundColor: COLORS.gray.gray90,
      },

      '&$selected': {
        backgroundColor: COLORS.gray.gray80,

        '&:hover': {
          backgroundColor: COLORS.gray.gray90,
        },
      },

      '&:active': {
        backgroundColor: COLORS.gray.gray80,
      },

      '&:disabled': {
        color: theme.palette.text.secondaryBluish,
        opacity: 0.6,
      },
    },
    selected: {},
  }),
  { name: 'ToolbarButton' }
);
