import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './useStyles';

const propTypes = {
  classes: PropTypes.shape(),
};

const defaultProps = {
  classes: null,
};

const KeyTab = ({ classes, ...restProps }) => {
  const styles = useStyles({ classes });

  return (
    <span className={styles.root} {...restProps}>
      TAB
    </span>
  );
};

KeyTab.propTypes = propTypes;
KeyTab.defaultProps = defaultProps;

export default KeyTab;
