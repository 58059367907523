import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadiusMedium,

      '& $textTitle + $textDescription': {
        marginTop: '4px',
      },

      '&$hasClose': {
        paddingRight: '40px',
      },
      '&$onlyDescription': {
        '& $textDescription': {
          fontSize: theme.typography.pxToRem(14),
        },
      },
      '&$elevated': {
        boxShadow: '0px 6px 20px rgba(47, 57, 62, 0.3)',
      },

      '&$filledInfo': {
        '& $buttonClose path': {
          fill: '#4A5459',
        },
      },
      '&$filledSuccess': {
        '& $buttonClose path': {
          fill: '#32A97C',
        },
      },
    },
    filledInfo: {},
    filledSuccess: {},
    standardWarning: {},
    standardError: {},
    hasClose: {},
    onlyDescription: {},
    elevated: {},

    message: {},
    textTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      color: 'inherit',
      whiteSpace: 'pre-wrap',

      '& a': {
        textDecoration: 'underline',
      },
    },
    textDescription: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: 'inherit',
      whiteSpace: 'pre-wrap',

      '& p': {
        margin: '0',
      },

      '& p + p': {
        marginTop: theme.spacing(2),
      },

      '& a': {
        textDecoration: 'underline',
      },
    },
    buttonClose: {
      position: 'absolute',
      top: '10px',
      right: '6px',

      padding: '10px',
      color: 'inherit',

      '& svg': {
        fontSize: '8px',
      },

      '& path': {
        fill: 'inherit',
      },
    },
    icon: {},
  }),
  { name: 'CustomAlert' }
);
