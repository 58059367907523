import { createStore } from 'react-sweet-state';
import { findIndex, uniq } from 'lodash';

import { captureMessage } from 'src/lib/sentry';
import {
  createCustomerPersona,
  deleteCustomerPersona,
  extractBriefPersonas,
  queryCustomerPersonas,
  updateCustomerPersona,
  extractCustomerPersona,
} from 'src/graphql/customerPersona';
import { mapToolContentForBriefInput } from 'src/data/brief';
import { isToolExcludedFromSuggestedPersonas } from 'src/data/generationTool';
import { isAbortError } from 'src/lib/errors';
import { updateCustomerPersonas } from 'src/graphql/customerPersona/updateCustomerPersonas';

const NAME = 'global-customer-persona';

const unwrapNodes = (edges) => edges.map((edge) => edge.node);

const unwrapPersonas = (customerPersonaEdges) => {
  const nodes = unwrapNodes(customerPersonaEdges);
  return nodes.map((node) => node);
};

const INITIAL_STATE = {
  customerPersonas: [],
  suggestedCustomerPersonas: [],
  suggestedCustomerPersonasSeenIds: [],
  loadedCustomerPersonas: false,
  loadingCustomerPersonas: false,
  loadingSuggestedCustomerPersonas: false,
  extractingCustomerPersonas: false,
  savingCustomerPersona: false,
};

/**
 * Private actions
 */
export const PRIVATE_ACTIONS = {
  mergePersonaInCache:
    (customerPersona) =>
    ({ getState, setState }) => {
      const isSuggestedPersona = customerPersona.isSuggested;
      const { customerPersonas, suggestedCustomerPersonas } = getState();
      const list = isSuggestedPersona ? [...suggestedCustomerPersonas] : [...customerPersonas];

      const existingIndex = findIndex(list, (p) => p.id === customerPersona.id);

      if (existingIndex !== -1) {
        list[existingIndex] = customerPersona;
      } else {
        list.unshift(customerPersona);
      }

      if (isSuggestedPersona) {
        setState({ suggestedCustomerPersonas: list });
      } else {
        setState({ customerPersonas: list });
      }
    },

  deletePersonaFromCache:
    (customerPersona, isPersonaSuggested) =>
    ({ getState, setState }) => {
      const { suggestedCustomerPersonas, customerPersonas } = getState();
      const list = isPersonaSuggested ? suggestedCustomerPersonas : customerPersonas;
      const newList = list.filter((p) => p.id !== customerPersona.id);

      if (isPersonaSuggested) {
        setState({ suggestedCustomerPersonas: newList });
      } else {
        setState({ customerPersonas: newList });
      }
    },

  setPersonasFromEdges:
    ({ edges, generationTool, shouldNotBeSuggested }) =>
    ({ setState }) => {
      const customerPersonas = [];
      const suggestedCustomerPersonas = [];
      const toolExcludedFromSuggestedPersonas = isToolExcludedFromSuggestedPersonas(generationTool);

      unwrapPersonas(edges).forEach((persona) => {
        if (shouldNotBeSuggested) {
          persona.isSuggested = false;
        }
        if (persona.isSuggested) {
          if (!toolExcludedFromSuggestedPersonas) {
            suggestedCustomerPersonas.push(persona);
          }
        } else {
          customerPersonas.push(persona);
        }
      });

      setState({
        customerPersonas,
        suggestedCustomerPersonas,
      });
    },
};

/**
 * Public available actions
 */
export const ACTIONS = {
  setLoadingSuggestedCustomerPersonas:
    (loadingSuggestedCustomerPersonas) =>
    ({ setState }) => {
      setState({ loadingSuggestedCustomerPersonas });
    },

  queryCustomerPersonas:
    (brandVoiceId, projectId, generationTool) =>
    ({ setState, dispatch }, { notifications }) => {
      setState({ loadingCustomerPersonas: true });

      if (!brandVoiceId) {
        dispatch(ACTIONS.clearState());
        return;
      }

      queryCustomerPersonas(brandVoiceId, projectId)
        .then((result) => {
          const { edges } = result;
          setState({ loadedCustomerPersonas: true });
          dispatch(
            PRIVATE_ACTIONS.setPersonasFromEdges({
              edges,
              generationTool,
              shouldNotBeSuggested: true,
            })
          );
        })
        .catch((error) => {
          // Display notification to user
          notifications.displayWarning(
            'Network error',
            'Error fetching target audiences. Please try reloading the page.'
          );

          // Report "warning" to Sentry
          captureMessage('Error while calling "queryCustomerPersonas" GQL query', {
            level: 'warning',
            extra: {
              error: error.toString(),
              source: 'CustomerPersonaStore -> queryCustomerPersonas',
            },
          });
        })
        .finally(() => {
          setState({ loadingCustomerPersonas: false });
        });
    },

  extractSuggestedCustomerPersonas:
    ({
      briefContent,
      projectId,
      generationTool,
      abortSignal,
      promptImage,
      promptDocument,
      freestyleToolAllInstructionsData,
      urlExtractions,
    }) =>
    async ({ dispatch }) => {
      const brief = mapToolContentForBriefInput(generationTool, briefContent);

      try {
        const { edges } = await extractBriefPersonas(
          {
            brief,
            projectId,
            promptImageId: promptImage?.id,
            promptDocumentId: promptDocument?.id,
            freestyleToolAllInstructionsData,
            urlExtractions,
          },
          { abortSignal }
        );
        dispatch(PRIVATE_ACTIONS.setPersonasFromEdges({ edges }));
      } catch (error) {
        if (!isAbortError(error)) {
          // Report "warning" to Sentry
          captureMessage('Error while calling "extractBriefPersonas" GQL query', {
            level: 'warning',
            extra: {
              error: error.toString(),
              source: 'CustomerPersonaStore -> extractSuggestedCustomerPersonas',
            },
          });
        }
      }
    },

  clearSuggestedCustomerPersonas:
    () =>
    ({ setState }) => {
      setState({ suggestedCustomerPersonas: [] });
    },

  updateCustomerPersona:
    (
      { id, name, minAge, maxAge, gender, painPoints, avatarType, projectId, brandVoiceId },
      approve
    ) =>
    async ({ setState, dispatch }) => {
      setState({ savingCustomerPersona: true });

      try {
        const newCustomerPersona = await updateCustomerPersona({
          id,
          name,
          minAge,
          maxAge,
          gender,
          painPoints,
          avatarType,
          projectId,
          approve,
          brandVoiceId,
        });
        if (approve) {
          dispatch(PRIVATE_ACTIONS.mergePersonaInCache(newCustomerPersona));
        }
        return newCustomerPersona;
      } finally {
        setState({ savingCustomerPersona: false });
      }
    },

  createCustomerPersona:
    (item, brandVoiceId) =>
    async ({ setState, dispatch }) => {
      setState({ savingCustomerPersona: true });
      try {
        const newCustomerPersona = await createCustomerPersona({
          brandVoiceId,
          ...item,
        });

        dispatch(
          PRIVATE_ACTIONS.mergePersonaInCache({
            ...newCustomerPersona,
            isNew: true,
          })
        );
        return newCustomerPersona;
      } finally {
        setState({ savingCustomerPersona: false });
      }
    },

  deleteCustomerPersona:
    (id) =>
    async ({ dispatch }) => {
      const persona = dispatch(ACTIONS.getCustomerPersona(id));
      const deletedCustomerPersona = await deleteCustomerPersona(id);
      dispatch(
        PRIVATE_ACTIONS.deletePersonaFromCache(deletedCustomerPersona, persona?.isSuggested)
      );
    },

  saveSuggestedPersona:
    (brandVoice, persona) =>
    async ({ dispatch }) => {
      // Update persona data on BE, removing link to a particular project
      const savedPersona = await dispatch(
        ACTIONS.updateCustomerPersona({
          ...persona,
          projectId: null,
          brandVoiceId: brandVoice?.id,
        })
      );

      // Remove persona from suggested list
      dispatch(PRIVATE_ACTIONS.deletePersonaFromCache(savedPersona, true));
    },

  setCustomerPersonaSeen:
    (personaId) =>
    ({ getState, setState }) => {
      const { suggestedCustomerPersonasSeenIds } = getState();
      const newSeenSuggestedIds = uniq([...suggestedCustomerPersonasSeenIds, personaId]);
      setState({ suggestedCustomerPersonasSeenIds: newSeenSuggestedIds });
    },

  getCustomerPersonas:
    () =>
    ({ getState }) => {
      return getState().customerPersonas;
    },

  getCustomerPersona:
    (personaId) =>
    ({ getState }) => {
      const { customerPersonas, suggestedCustomerPersonas } = getState();
      return [...customerPersonas, ...suggestedCustomerPersonas].find(({ id }) => id === personaId);
    },

  clearState:
    () =>
    ({ setState }) => {
      setState({
        customerPersonas: [],
        suggestedCustomerPersonas: [],
        loadingCustomerPersonas: false,
        loadedCustomerPersonas: false,
      });
    },
  isSavingCustomerPersona:
    () =>
    ({ getState }) => {
      return getState().savingCustomerPersona;
    },
  isExtractingCustomerPersonas:
    () =>
    ({ getState }) => {
      return getState().extractingCustomerPersonas;
    },

  extractCustomerPersonas:
    ({ brandVoiceId, urls, text, resourceId, count = 1 }) =>
    async ({ setState }) => {
      setState({ extractingCustomerPersonas: true });
      try {
        return await extractCustomerPersona({
          brandVoiceId,
          urls,
          text,
          resourceId,
          count,
        });
      } finally {
        setState({ extractingCustomerPersonas: false });
      }
    },

  updateCustomerPersonas:
    (personas, brandVoiceId) =>
    async ({ setState, dispatch }) => {
      setState({ savingCustomerPersona: true });

      try {
        const newCustomerPersonas = await updateCustomerPersonas({
          brandVoiceId,
          personas,
          approve: true,
        });

        newCustomerPersonas.forEach((newCustomerPersona) => {
          dispatch(
            PRIVATE_ACTIONS.mergePersonaInCache({
              ...newCustomerPersona,
              isNew: true,
            })
          );
        });
        return newCustomerPersonas;
      } finally {
        setState({ savingCustomerPersona: false });
      }
    },
};

export const CustomerPersonaStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});
