import { trackUserEvent, trackEventNoAuth } from 'src/graphql/event';
import { trackEvent as trackLogRocketEvent } from 'src/lib/logrocket';

export const PROJECT = {
  variationCopy: 'project.variation.copy',
  exportSaved: 'project.saved.exportCSV',
  saveFromRecompose: 'project.own_text.save',
  blogPostDraftNext: 'project.blog_post.clicked_next',
  blogPostSubmitDraft: 'project.blog_post.clicked_continueToEditor',
  blogPostDraftRegenerateOutline: 'project.blog_draft.clicked_regenerateOutline',
  blogPostDraftRegenerateAllOutlines: 'project.blog_draft.clicked_regenerateAllOutlines',
  blogPostDraftMoreTitlesSuggest: 'project.blog_draft.clicked_moreTitlesSuggest',
  blogPostCopy: 'project.blog_post.copy',
  blogPostUserResponseRephrase: 'project.blog_post.user_response_rephrase',
  blogPostUserResponseContinue: 'project.blog_post.user_response_continue',
  blogInsightsPanelExpandSeoTracker: 'project.blog_post.insights_panel.expand_seo_tracker',
  blogInsightsPanelExpandBrandRules: 'project.blog_post.insights_panel.expand_brand_rules',
  blogInsightsBrandRuleApply: 'project.blog_post.insights_panel.brand_rule_apply',
  blogPersona: 'project.blog_post.persona_selected',
  blogPostClickedToneOfVoice: 'project.blog_post.clicked_tone_of_voice',
  blogPostClickedTargetAudience: 'project.blog_post.clicked_target_audience',
  blogResearchPanelAskedSetQuestion: 'project.blog_post.research_panel.set_question_asked',
  blogResearchPanelAskedCustomQuestion: 'project.blog_post.research_panel.custom_question_asked',
  blogResearchPanelAnswerClicked: 'project.blog_post.research_panel.answer_clicked',
  blogResearchPanelAnswerCopy: 'project.blog_post.research_panel.answer_copy',
  insightsPanelPlagiarismCheckerClicked:
    'project.blog_post.insights_panel.plagiarism_checker_clicked',
  copySectionContent: 'project.blog_post.copy_section_content',
  blogPromptPlusIconClicked: 'project.blog_post.prompt.plus_icon_clicked',
  enteredProject: 'project.entered_project',
  clickedTalkingPointConnectToChannel: 'project.talking_points.channel_connect.clicked',
  createTalkingPointView: 'project.talking_points.created',
  clickedVariationDetails: 'project.variation.clicked_details',
  clickedRecomposeDetails: 'project.variation.clicked_details_recompose',
  clickedExplainerVideo: 'project.clicked_explainer_video',
  clickedVariationAddTemplateOnDetails: 'project.variation.score_panel.clicked_add_to_templates',
  clickedRecomposeAddTemplateOnDetails: 'project.recompose.score_panel.clicked_add_to_templates',
  briefUpdate: 'project.brief_update',
  clickedDocumentRephraseText: 'project.document.rephrase_text',
  clickedDocumentAcceptRephraseText: 'project.document.accept_rephrase_text',
  clickedDocumentImproveScore: 'project.document.improve_score',
  clickedDocumentAcceptImproveScoreResult: 'project.document.accept_improve_score_result',
  clickedScoreHintClose: 'project.onboarding.clicked_close_score_hint',
  checkedScoreHintDontShow: 'project.onboarding.checked_score_hint_dont_show',
  clickAssetActionsHintClose: 'project.onboarding.clicked_close_asset_actions_hint',
  checkedAssetActionsHintDontShow: 'project.onboarding.checked_asset_actions_hint_dont_show',

  // Events for "locked score" views
  clickedLockedGetScore: 'project.locked_score.get_score.clicked',
  clickedLockedUndo: 'project.locked_score.undo.clicked',
  clickedLockedImproveScore: 'project.improve_score.locked.clicked',
  clickedLockedUpgradeToDD: 'project.locked_score.upgrade_to_dd.clicked',

  // Events for "score dataset" views
  clickedScoreDatasetLink: 'project.score_dataset.score_dataset_link.clicked',
  clickedScoreCustomModelTab: 'project.score_dataset.custom_dataset_tab.clicked',
  applyScoreCustomModel: 'project.score_dataset.custom_dataset.applied',

  clickedGenerateFromSectionPanel: 'project.generate_panel.generate_button.clicked',
  clickedRegenerateFromSectionPanel: 'project.generate_panel.regenerate_button.clicked',
  clickedGenerateWithInstructionsFromURL:
    'project.generate_panel.generate_button.instructionsFromURL',
  clickedGenerateWithInstructionsFromParagraph:
    'project.generate_panel.generate_button.instructionsFromParagraph',
  clickedOpenImportInstructionsFromURL: 'project.import_instructions.clicked_openPopup',
  approveInstructionsFromURL: 'project.import_instructions.approve_instructions',
  generateAllSectionsClicked: 'project.generate_all_sections.clicked',
  generateAllSectionsGenerated: 'project.generate_all_sections.generated',
  openTalkingPointsMenuOpened: 'project.annotations.menu_open.clicked',
  talkingPointsMenuAccountFiltered: 'project.annotations.menu.filter.account.clicked',
  talkingPointsMenuSearched: 'project.annotations.menu.searched',
  talkingPointClicked: 'project.annotations.clicked',
  talkingPointsCreationChannelSelectionPopupOpened:
    'project.annotations.creation.channel_selection_popup.opened',
  talkingPointsCreationChannelSelected:
    'project.annotations.creation.channel_selection_popup.selected',
  talkingPointsCreated: 'project.annotations.creation.channel_selection_popup.created',
  toggledBrandRules: 'project.brand_rules_toggled',

  // Folders-related
  folderCreated: 'project.folder.created',
  folderDeleted: 'project.folder.deleted',
  projectMovedToFolder: 'project.moved_to_folder',
};

// TODO: move all events relevant to "blog builder" here
export const BLOG = {
  sectionFormatToggled: 'blog.section.format_toggled',
  sectionGenerateRewrite: 'project.blog_post.rewrite',
  sectionGenerateContinue: 'blog.section.generate_continue',
  sectionTriggeredContinueMenu: 'blog.section.triggered_continue_menu',
  sectionTriggeredEditMenu: 'blog.section.triggered_edit_menu',
  generationPanelOpened: 'blog.generation_panel.opened',
};

export const DOCUMENT = {
  clickedBrandVoiceSettings: 'document.brand_voice_settings.clicked',
  clickedOpenCreateCustomModelPopup: 'document.open_create_custom_model_popup.clicked',
  clickedCustomModelPromotionalBanner: 'document.custom_model_promotional_banner.clicked',
  clickedHideCustomModelBanner: 'document.custom_model_promotional_banner.hide',
  clickedTargetAudience: 'document.target_audience.clicked',
  clickedToneOfVoice: 'document.tone_of_voice.clicked',
  clickedCreateNewToneOfVoice: 'document.tone_of_voice_new.clicked',
  addedTalkingPoint: 'document.talking_points.added',
  viewedHistoryResultDetails: 'document.history.viewed_result_details',
  shareClicked: 'document.share_button.clicked',
  variationBenchmarkSettingsChange: 'document.variation.benchmark.settings_changed',
  variationBenchmarkSettingsDialogOpen: 'document.variation.benchmark.opened_settings_dialog',
  variationBenchmarkConnectToChannelClicked: 'document.variation.benchmark.channel_connect.clicked',
  variationBenchmarkUpgradeClicked: 'document.variation.benchmark.upgrade.clicked',
  benchmarkPopupOpen: 'document.benchmark_popup.opened_popup',
  variationBenchmarkCreateNewSetClicked:
    'document.variation.benchmark.settings.create_new_set_clicked',
  variationBenchmarkSaveNewSetClicked: 'document.variation.benchmark.settings.save_new_set_clicked',
  variationBenchmarkPopulateNewSetClicked:
    'document.variation.benchmark.settings.populate_new_set_clicked',

  variationBenchmarkSettingsStep1Clicked:
    'document.variation.benchmark.settings.step_1.next_clicked',
  variationBenchmarkSettingsStep2Clicked:
    'document.variation.benchmark.settings.step_2.next_clicked',
  variationBenchmarkSettingsStep3Clicked:
    'document.variation.benchmark.settings.step_3.next_clicked',
  variationAddToDocumentClicked: 'document.variation.add_to_document.clicked',
  variationImproveScoreClicked: 'document.variation.improve_score.clicked',
  variationScoreThisTextClicked: 'document.variation.score_this_text_clicked',
  variationImproveScoreUsingDefaultSuggestion:
    'document.variation.improve_score.default_suggestion',
  variationImproveScoreUsingGeneratedSuggestion:
    'document.variation.improve_score.generated_suggestion',
  variationImproveScoreUsingRemovedTalkingPoints:
    'document.variation.improve_score.removed_talking_points',
  variationManualInstructionsGeneration: 'document.variation.manual_instructions.generation',
  variationEditClicked: 'document.variation.edit.clicked',
  variationSaveTextClicked: 'document.variation.save_text.clicked',
  variationGroupCopyClicked: 'document.variation.group.copy.clicked',
  variationGroupSaveClicked: 'document.variation.group.save.clicked',
  variationGroupDeleteClicked: 'document.variation.group.delete.clicked',
  variationCopyClicked: 'document.variation.copy.clicked',
  variationDeleteClicked: 'document.variation.delete.clicked',
  variationRateClicked: 'document.variation.rate.clicked',
  variationFeedBackReasons: 'document.variation.feedback.reasons',
  variationCopy: 'document.variation.copy',
  variationBoostPerformance: 'document.variation.boost_performance',
  variationGetUnlimitedBoostsClicked: 'document.variation.get_unlimited_boosts.clicked',

  annotationExtractedFromUrl: 'document.annotation.extracted_from_url',
  annotationExtractedFromBrief: 'document.annotation.extracted_from_brief',

  openedGoogleImportExamples: 'document.opened_google_import_examples',
  openedFBImportExamples: 'document.opened_fb_import_examples',

  savedGoogleImportExamples: 'document.saved_google_import_examples',
  savedFBImportExamples: 'document.saved_fb_import_examples',

  freestyleSaveInstructions: 'document.freestyle.save_instructions',
  freestyleSuggestPromptClicked: 'document.freestyle.suggest_prompt_clicked',
  freestyleGenerateWithSuggestedPrompt: 'document.freestyle.generate_with_suggested_prompt',

  clickedGenerateRecompose: 'document.recompose.generate_button.clicked',
  clickedGenerateSuggest: 'document.suggest.generate_button.clicked',
  generateWithCallToAction: 'document.generate_with_call_to_action',

  briefImportedFromUrl: 'document.briefs.brief_imported_from_url',
  brandBriefLoaded: 'document.briefs.brand_brief_loaded',

  documentEditorCopyClick: 'document.editor.copy_clicked',

  multiPromptSetup: 'document.multi_prompt.setup',
  multiPromptGetStarted: 'document.multi_prompt.get_started',
  multiPromptChangeBrief: 'document.multi_prompt.change_brief',

  displayModeToggle: 'document.display_mode_toggle',

  clearVariationRatingsClicked: 'document.clear_variation_ratings.clicked',

  insightsPanelTabChanged: 'document.insights_panel.tab_changed',
};

export const HOME = {
  buttonClicked: 'home.buttonClicked',
  confirmG2ReviewBoostClaimedClick: 'home.g2_review_boost_claimed_popup.close.clicked',
};

export const WTM = {
  projectCreated: 'wtm.project_created',
  generateMore: 'wtm.generate_more.clicked',
  addYourOwn: 'wtm.add_your_own.clicked',
  addToCampaign: 'wtm.add_to_campaign.clicked',
  verifySetup: 'wtm.verify_setup.clicked',
  runCampaign: 'wtm.run_campaign.clicked',
  pauseCampaign: 'wtm.pause_campaign.clicked',
  createWtmVisited: 'wtm.create_wtm.visited',
  gatedWtmVisited: 'wtm.gated_wtm.visited',
  clickedGenerateRecompose: 'wtm.recompose.generate_button.clicked',
  clickedGenerateSuggest: 'wtm.suggest.generate_button.clicked',
  variationSaveTextClicked: 'wtm.variation.save_text.clicked',
};

export const PURCHASE = {
  navbarPurchaseClick: 'purchase_flow.clicked_purchase_navbar',
  settingsPurchaseClick: 'purchase_flow.clicked_purchase_settings',
  trialEndedPurchaseClick: 'purchase_flow.clicked_purchase_trial_ended',
  subscriptionPageOpened: 'purchase_flow.opened_subscription_page',
  tiersDropdownOpened: 'purchase_flow.clicked_tiers_dropdown',
  confirmTier: 'purchase_flow.clicked_tiers_cta_button',
  abandonedSubscriptionPage: 'purchase_flow.abandoned_subscription_page',
  paymentFormEdit: 'purchase_flow.edited_cc_form',
  confirmPayment: 'purchase_flow.clicked_cc_purchase',
  completedPurchase: 'purchase_flow.completed_purchase',
};

export const MODIFY_PLAN = {
  settingsModifyClick: 'modify_flow.clicked_modify_settings',
  subscriptionPageOpened: 'modify_flow.opened_subscription_page',
  tiersDropdownOpened: 'modify_flow.clicked_tiers_dropdown',
  confirmUpgrade: 'modify_flow.clicked_confirm_upgrade',
  confirmDowngrade: 'modify_flow.clicked_confirm_downgrade',
  abandonedSubscriptionPage: 'modify_flow.abandoned_subscription_page',
  upgradeDialogUpgradeClick: 'upgrade_dialog.clicked_upgrade',
};

export const BUSINESS_DEMO = {
  businessDemoBooked: 'business_demo.booked',
  businessDemoRequested: 'business_demo.requested',
};

export const QUOTA_ENDED = {
  popupOpened: 'no_words_popup.opened_popup',
  popupCancelled: 'no_words_popup.abandoned_popup',
};

export const SETTINGS = {
  openedPaymentDetailsPage: 'settings.payment_method.opened_editing_page',
  savedPaymentMethodChanges: 'settings.payment_method.saved_changes',
  clickedInvite: 'settings.invite.clicked',
  clickedSendInvitation: 'settings.invite.clicked_send',
  clickedUserProfile: 'settings.user_profile.clicked',
  changeUserRole: 'settings.user_role.change',
  clickedRemoveUser: 'settings.user.clicked_remove',
  switchToAnnuallyViewMoreClicked: 'settings.switch_to_annually.view_more.clicked',
  defaultWorkspaceUpdated: 'default_workspace_updated',
};

export const NOTIFICATIONS = {
  clickedCtaButton: 'notification.clicked_CTA_button',
  clickedClose: 'notification.clicked_close',
};

export const SYSTEM = {
  gtmInaccessible: 'system.gtm_inaccessible',
  navigationItemClicked: 'system.navigation.item_clicked',
  applicationLoaded: 'system.application_loaded',
  logout: 'system.logout',
};

export const WORKSPACES = {
  clickedExplainerVideo: 'workspaces.clicked_explainer_video',
};

export const PERSONAS = {
  clickedExplainerVideo: 'personas.clicked_explainer_video',
  clickedAddOnInsights: 'personas.insights.add_clicked',
  clickedEditOnInsights: 'personas.insights.edit_clicked',
  clickedReplaceOnInsights: 'personas.insights.replace_clicked',
};

export const SUGGESTED_AUDIENCES = {
  generation: 'suggested_audience.generation',
  saved: 'suggested_audience.saved',
  edited: 'suggested_audience.edited',
  deleted: 'suggested_audience.deleted',
};

export const AUTH = {
  disposableEmailBlocked: 'auth.disposable_email_blocked',
};

export const SHARING = {
  changedSharedPermissions: 'share_popup.changed_share_permissions',
  copyLinkClicked: 'share_popup.copy_link_clicked',
  viewedSharedDocument: 'shared_page.document_viewed',
  sharedSignUpClicked: 'shared_page.sign_up_button.clicked',
  sharedShareClicked: 'shared_page.share_button.clicked',
  sharedExportClicked: 'shared_page.csv_export.clicked',
};

export const TRACK_EVENT_SOURCE = {
  settings: 'settings',
  navbar: 'navbar',
};

export const APP = {
  currentUserInitialRequestCompleted: 'app.current_user_initial_request.completed',
  welcomePopupProjectTypeSelected: 'app.welcome_popup.project_type_selected',
  welcomePopupPlansToUseSelected: 'app.welcome_popup.plans_to_use_selected',
  welcomeRetrialPopupOpened: 'app.welcome_retrial_popup.opened',
  welcomeRetrialPopupSubmitClicked: 'app.welcome_retrial_popup.submit.clicked',
  welcomeRetrialPopupCloseClicked: 'app.welcome_retrial_popup.close.clicked',
};

export const BRAND_VOICE = {
  brandVoiceCreated: 'brand_voice.created',
  brandVoiceUpdated: 'brand_voice.updated',
  brandVoiceDeleted: 'brand_voice.deleted',
};

export const BRAND_VOCABULARY = {
  termCreated: 'brand_vocabulary.term.created',
  termUpdated: 'brand_vocabulary.term.updated',
  termDeleted: 'brand_vocabulary.term.deleted',
  detectedTermApplied: 'brand_vocabulary.detected_term_applied',
  pageVisited: 'brand_vocabulary.page.visited',
  importInitiated: 'brand_vocabulary.import.submit',
  importCompleted: 'brand_vocabulary.import.completed',
  importFailed: 'brand_vocabulary.import.failed',
  approvedAllImportedTerms: 'brand_vocabulary.import.approve_all',
  discardedAllImportedTerms: 'brand_vocabulary.import.remove_all',
  approvedImportedTerm: 'brand_vocabulary.import.term.approve',
  discardedImportedTerm: 'brand_vocabulary.import.term.remove',
};

export const BRAND_BRIEFS = {
  pageVisited: 'brand_briefs.page.visited',
  briefCreated: 'brand_briefs.created',
  briefUpdated: 'brand_briefs.updated',
  briefExtracted: 'brand_briefs.extracted',
};

export const CUSTOM_FORMULAS = {
  pageVisited: 'custom_formulas.page.visited',
  formulaCreated: 'custom_formulas.created',
  formulaUpdated: 'custom_formulas.updated',
  formulaExtracted: 'custom_formulas.extracted',
};

export const CUSTOM_MODEL = {
  pageVisited: 'custom_model.page.visited',
  clickedModelTrained: 'custom_model.train_model.clicked',
};

export const PROMPTS = {
  promptsAndFormulasPageVisited: 'prompts_and_formulas.page.visited',
  pageVisited: 'prompts.page.visited',
  promptCreated: 'prompts.created',
  promptUpdated: 'prompts.updated',
  briefImportedFromUrl: 'prompts.brief.imported.from.url',
  promptOpenedSaved: 'prompts.open.saved',
  promptOpenedPreset: 'prompts.open.preset',
  promptMessageBankInserted: 'prompts.message.bank.inserted',
  promptResourceInserted: 'prompts.resource.inserted',
  promptPlusIconClicked: 'prompts.plus.icon.clicked',
  promptAddResourceFromAddInfoPopup: 'prompts.resouce.added.from.menu',
  promptMessageBankAssetFromAddInfoPopup: 'prompts.asset.added.from.menu',
};

export const MULTI_PROMPT_TEMPLATES = {
  pageVisited: 'multi_prompt_template.page.visited',
  templateCreated: 'multi_prompt_template.created',
  templateUpdated: 'multi_prompt_template.updated',
  templateDeleted: 'multi_prompt_template.deleted',
  templateEditClicked: 'multi_prompt_template.edit.clicked',
  templateCreateClicked: 'multi_prompt_template.create.clicked',
  templateDescriptionNextClicked: 'multi_prompt_template.description.next.clicked',
  templateFieldsNextClicked: 'multi_prompt_template.fields.next.clicked',
  templateFieldsEdited: 'multi_prompt_template.fields.edited',
  templateFieldsAdded: 'multi_prompt_template.fields.added',
  templateFieldsDeleted: 'multi_prompt_template.fields.deleted',
  templatePromptsEdited: 'multi_prompt_template.prompts.edited',
  templatePromptsAdded: 'multi_prompt_template.prompts.added',
  templatePromptsDeleted: 'multi_prompt_template.prompts.deleted',
  templateUploadNextClicked: 'multi_prompt_template.upload.next.clicked',
  templateCsvUploaded: 'multi_prompt_template.csv.uploaded',
  templateCsvRemoved: 'multi_prompt_template.csv.removed',
  templateFieldSelectionNextClicked: 'multi_prompt_template.field_selection.next.clicked',
  templateFieldSelected: 'multi_prompt_template.field.selected',
  templateFieldSelectAll: 'multi_prompt_template.field.select_all',
  templateFieldUnselectAll: 'multi_prompt_template.field.unselect_all',
  templateFieldSetPrimaryKey: 'multi_prompt_template.field.set_primary_key',
};

export const TONE_OF_VOICE = {
  pageVisited: 'custom_tone_of_voice.page.visited',
  toneOfVoiceCreated: 'custom_tone_of_voice.created',
  toneOfVoiceUpdated: 'custom_tone_of_voice.updated',
  toneOfVoiceExtracted: 'custom_tone_of_voice.extracted',
  clickCustomToneOfVoiceNew: 'custom_tone_of_voice.create_new.clicked',
  toneOfVoicePopupShown: 'custom_tone_of_voice.popup.shown',
  toneOfVoicePopupStepSelected: 'custom_tone_of_voice.popup_step.selected',
  toneOfVoiceDraftCreated: 'custom_tone_of_voice.draft.created',
  toneOfVoiceDraftUpdated: 'custom_tone_of_voice.draft.updated',
  analyzedToneOfVoiceApplied: 'custom_tone_of_voice.analyzed.applied',
  toneOfVoiceTestClicked: 'custom_tone_of_voice.test.clicked',
  toneOfVoiceTestSampleChanged: 'custom_tone_of_voice.test.sample_changed',
  toneOfVoiceTestTovChanged: 'custom_tone_of_voice.test.tov_changed',
  toneOfVoiceTestRefreshClicked: 'custom_tone_of_voice.test.refresh_clicked',
};

export const TARGET_AUDIENCE = {
  pageVisited: 'target_audience.page.visited',
  targetAudienceExtracted: 'target_audience.extracted',
  targetAudienceCreated: 'target_audience.created',
  targetAudienceUpdated: 'target_audience.updated',
};

export const RESOURCES = {
  resourceCreated: 'resource.created',
  resourceUpdated: 'resource.updated',
  resourceDeleted: 'resource.deleted',
  resourcesBulkDeleted: 'resource.bulk_deleted',
};

export const LIMITATION_POPUP = {
  resourceLimitOpened: 'resource_limit_popup.opened',
  resourceLimitClosed: 'resource_limit_popup.closed',
  deleteResourceLinkClicked: 'resource_limit_popup.delete_resources_link.clicked',
  writeReviewButtonClicked: 'boost_limit_popup.write_review.button.clicked',
};

export const CIP = {
  toggleTable: 'cip.table.toggled',
  connectAdAccounts: 'cip.accounts.connected',
  disconnectChannel: 'cip.channel.disconnected',
  connectChannelClick: 'cip.channel.connect.clicked',
  channelConnected: 'cip.channel.connected',
  improveButtonClick: 'cip.improve_button.clicked',
  connectedChannelsPageVisited: 'cip.connected_channels.page.visited',
  talkingPointsPageVisited: 'cip.talking_points.page.visited',
  customScoringModelPageVisited: 'cip.custom_scoring_model.page.visited',
  filterApplied: 'cip.filter.applied',
  resourceAdded: 'cip.resource.added',
  copyIntelligencePageVisited: 'cip.copy_intelligence.page.visited',
  clickedDoNotWantToWait: 'cip.connection_flow.do_not_want_to_wait.clicked',
};

export const COPY_ANALYTICS = {
  pageVisited: 'analytics.page.visited',
  connectToChannelClick: 'analytics.channel_connect.clicked',
};

export const TALKING_POINTS_VIEW = {
  talkingPointCopied: 'talking_points.copy_to_clipboard.clicked',
  connectToChannelClick: 'talking_points.channel_connect.clicked',
  talkingPointDeleted: 'talking_points.deleted',
};

export const DATA_EXTRACTION_NAVIGATION_POPUP = {
  analyticsClick: 'data_extraction_navigation_popup.analytics.clicked',
  benchmarkClick: 'data_extraction_navigation_popup.benchmark.clicked',
};

export const DASHBOARD = {
  clickedCreateProjectMenuButton: 'dashboard.create_project_menu_button.clicked',
  clickedCreateDataDrivenButton: 'dashboard.create_data_driven_editor_button.clicked',
  clickedCreateBlogButton: 'dashboard.create_blog_button.clicked',
  clickedNewTemplateCardButton: 'dashboard.new_template_card_button.clicked',
  clickedWorkspaceSettingsUpgradeButton: 'dashboard.workspace_settings_upgrade_button.clicked',

  // Dashboard Banners
  clickedBusinessBanner: 'dashboard.business_banner.clicked',
  clickedIntegrationsBanner: 'dashboard.integrations_banner.clicked',
  clickedPersonaBanner: 'dashboard.persona_banner.clicked',
  clickedToneOfVoiceBanner: 'dashboard.tone_of_voice_banner.clicked',
  clickedExtensionBanner: 'dashboard.extension_banner.clicked',
  clickedWebinarBanner: 'dashboard.webinar_banner.clicked',
};

export const INFINITY = {
  loggedIn: 'infinity.system.logged_in',
  clickedPromptCategory: 'infinity.prompt.category_clicked',
  toggledBrandVoice: 'infinity.prompt.brand_voice.toggled',
  selectedToneOfVoice: 'infinity.prompt.tone_of_voice_selected',
  selectedPersona: 'infinity.brand_voice.persona_selected',
  appliedPrompt: 'infinity.prompt.applied',
  selectedMarketingPrompt: 'infinity.prompt.selected_marketing_prompt',
  clickedGetUnlimitedBoosts: 'infinity.suggestions.get_unlimited_boosts.clicked',
  selectedVariationsGenerationTool: 'infinity.variations.generation_tool.selected',
  toggledVariationsAdvancedOptions: 'infinity.variations.advanced_options.toggled',
  appliedVariation: 'infinity.variations.applied',
  appliedSuggestion: 'infinity.suggestions.applied',
  copiedSuggestion: 'infinity.suggestions.copied',
  insightsAppliedText: 'infinity.insights.applied_text',
  onboardingPageViewed: 'infinity.onboarding-screen.viewed',
  onboardingPlatformButtonClicked: 'infinity.onboarding-screen.platform-button.clicked',
  goodbyePageViewed: 'infinity.goodbye-screen.viewed',
  goodbyeReinstallButtonClicked: 'infinity.goodbye-screen.reinstall.clicked',
};

/**
 * Possible values for the `page` property of the `track event` call
 * @readonly
 * @enum {string}
 */
export const EVENT_PAGE = {
  accountSettings: 'account_settings',
  blogView: 'blog_view',
  blogWizard: 'blog_wizard',
  brandBriefs: 'brand_briefs',
  brandTerms: 'brand_terms',
  checkout: 'checkout',
  copyAnalytics: 'copy_analytics',
  customFormula: 'custom_formula',
  customModels: 'custom_models',
  documents: 'documents',
  editor: 'editor',
  homePage: 'home_page',
  infinity: 'infinity',
  login: 'login',
  onboarding: 'onboarding',
  prompts: 'prompts',
  resourcesIntegrations: 'resources_integrations',
  talkingPoints: 'talking_points',
  targetAudience: 'target_audience',
  toneOfVoice: 'tone_of_voice',
  wtm: 'wtm',
  brandVoice: 'brand_voice',
  templateBuilder: 'template-builder',
  multiPromptBuilder: 'multi_prompt_builder',
  templatesPopup: 'templates_popup',
  mobile: 'mobile',
  notVerified: 'not_verified',
};

/**
 * Possible values for the `subPage` property of the `track event` call
 * @readonly
 * @enum {string}
 */
export const EVENT_SUB_PAGE = {
  listView: 'list_view',
  listViewInsights: 'list_view_insights',
  editor: 'editor',
  generationPanel: 'generation_panel',
  insightsPanel: 'insights_panel',
};

/**
 * Possible values for the `reason` property of the `logout` event
 * @enum {string}
 */
export const LOGOUT_REASON = {
  userAction: 'user_action',
  authTokenGuard: 'auth_token_guard',
  userGuard: 'user_guard',
  extensionMessage: 'extension_message',
};

const REQUIRED_TRACKING_EVENT_ATTRIBUTES = [
  'userId',
  'customerId',
  'workspaceId',
  'applicationHost',
  'page',
];
const EXCLUDE_REQUIRED_TRACKING_EVENT_ATTRIBUTES_VALIDATION = {
  [APP.currentUserInitialRequestCompleted]: {
    ...REQUIRED_TRACKING_EVENT_ATTRIBUTES.reduce((acc, k) => ({ ...acc, [k]: true }), {}),
  },
  [SYSTEM.navigationItemClicked]: {
    page: true,
  },
};

/**
 * Adds the `infinity.` prefix to the event name
 * @param {string} name - the event name
 * @returns {string} - the event name with the prefix
 */
export const prefixExtensionEventName = (name) => `infinity.${name}`;

export function trackEvent(name, incomingAttributes = null) {
  const attributes = {
    ...incomingAttributes,
    eventCategory: parseEventCategory(name),
  };

  const attributesValidation = REQUIRED_TRACKING_EVENT_ATTRIBUTES.filter(
    (k) =>
      !Object.hasOwn(attributes, k) &&
      !EXCLUDE_REQUIRED_TRACKING_EVENT_ATTRIBUTES_VALIDATION[name]?.[k]
  );
  if (attributesValidation.length > 0 && process.env.NODE_ENV === 'development') {
    console.error(
      `[Tracking Event] ${name} missing required properties: [${attributesValidation.join(',')}]`,
      incomingAttributes
    );
  }

  // Send event to LogRocket
  trackLogRocketEvent(name, attributes);

  // Send event to Platform
  return trackUserEvent(name, attributes).catch((error) => {
    console.warn('Anyword: error tracking FE event', { error });
  });
}

export function trackUserEventNoAuth(name, incomingAttributes = null) {
  const attributes = {
    ...incomingAttributes,
    eventCategory: parseEventCategory(name),
  };

  // Send event to LogRocket
  trackLogRocketEvent(name, attributes);

  // Send event to Platform
  return trackEventNoAuth(name, attributes).catch((error) => {
    console.warn('Anyword: error tracking FE event', { error });
  });
}

const parseEventCategory = (name) => {
  return name?.split('.')[0] || '';
};

window.trackInternalEvent = trackEvent;
