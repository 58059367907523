import React, { forwardRef } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconFolderRoot = forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    viewBox="0 0 20 20"
    data-fill-none
  >
    <path
      d="M16.691 6.22307L15.466 2.90088H7.46997"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.98568 6.22312L7.31563 2.56198C7.18161 2.26012 6.88234 2.06544 6.55206 2.06544H1.65207C1.18903 2.06319 0.8125 2.43789 0.8125 2.90093V17.0994C0.8125 17.5609 1.18653 17.9349 1.64799 17.9349H18.352C18.8135 17.9349 19.1875 17.5609 19.1875 17.0994V7.06019C19.1875 6.59882 18.8135 6.2247 18.352 6.2247L8.98568 6.22312Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.8125 14.5938H19.1875"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
));
