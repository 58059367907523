import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { PROJECT_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation DeleteProject($projectId: Int!, $ifEmpty: Boolean) {
    deleteProject(projectId: $projectId, ifEmpty: $ifEmpty) {
      ...Project
    }
  }
  ${PROJECT_FRAGMENT}
`;

export const deleteProject = (projectId, ifEmpty) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { projectId, ifEmpty },
  }).then(({ data }) => getSingleProp(data));
