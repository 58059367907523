import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const CheckmarkIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 16 16" data-fill-none>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9558 5.23423C13.1057 5.38425 13.19 5.5877 13.19 5.79983C13.19 6.01196 13.1057 6.21541 12.9558 6.36543L6.55577 12.7654C6.40575 12.9154 6.2023 12.9997 5.99017 12.9997C5.77804 12.9997 5.57459 12.9154 5.42457 12.7654L2.22457 9.56543C2.07884 9.41455 1.99821 9.21247 2.00003 9.00271C2.00185 8.79295 2.08599 8.5923 2.23431 8.44397C2.38264 8.29565 2.58329 8.21151 2.79305 8.20969C3.00281 8.20787 3.20489 8.2885 3.35577 8.43423L5.99017 11.0686L11.8246 5.23423C11.9746 5.08425 12.178 5 12.3902 5C12.6023 5 12.8057 5.08425 12.9558 5.23423Z"
      fill="currentColor"
    />
  </SvgIcon>
));
