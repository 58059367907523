import { makeStyles } from '@material-ui/core/styles';

import { LABEL_HEIGHT, LABEL_WIDTH } from '../../const';

export const useStyles = makeStyles(
  () => ({
    root: {},
    foreignObject: {
      width: LABEL_WIDTH,
      height: LABEL_HEIGHT,
    },
  }),
  { name: 'CustomizedDataLabels' }
);
