import { IconBold } from 'src/assets/icons/rich-text-editor/IconBold';
import { IconItalic } from 'src/assets/icons/rich-text-editor/IconItalic';
import { IconUnderline } from 'src/assets/icons/rich-text-editor/IconUnderline';
import { IconStrikethrough } from 'src/assets/icons/rich-text-editor/IconStrikethrough';
import { IconEditorLink } from 'src/assets/icons/rich-text-editor/IconEditorLink';
import { IconHeadingOne } from 'src/assets/icons/rich-text-editor/IconHeadingOne';
import { IconHeadingTwo } from 'src/assets/icons/rich-text-editor/IconHeadingTwo';
import { IconHeadingThree } from 'src/assets/icons/rich-text-editor/IconHeadingThree';
import { IconBulletedList } from 'src/assets/icons/rich-text-editor/IconBulletedList';
import { IconNumberedList } from 'src/assets/icons/rich-text-editor/IconNumberedList';
import { IconBlockQuote } from 'src/assets/icons/rich-text-editor/IconBlockQuote';
import { IconAlignRight } from 'src/assets/icons/rich-text-editor/IconAlignRight';
import { IconAlignLeft } from 'src/assets/icons/rich-text-editor/IconAlignLeft';
import { IconAlignCenter } from 'src/assets/icons/rich-text-editor/IconAlignCenter';
import { IconUndo } from 'src/assets/icons/rich-text-editor/IconUndo';
import { IconRedo } from 'src/assets/icons/rich-text-editor/IconRedo';
import { IconClearFormatting } from 'src/assets/icons/rich-text-editor/IconClearFormatting';
import { SLATE_EDITOR_ACTION } from 'src/lib/slate';

const ICONS = {
  [SLATE_EDITOR_ACTION.toggleBold]: IconBold,
  [SLATE_EDITOR_ACTION.toggleItalic]: IconItalic,
  [SLATE_EDITOR_ACTION.toggleUnderline]: IconUnderline,
  [SLATE_EDITOR_ACTION.toggleStrikethrough]: IconStrikethrough,
  [SLATE_EDITOR_ACTION.insertLink]: IconEditorLink,
  [SLATE_EDITOR_ACTION.toggleH1]: IconHeadingOne,
  [SLATE_EDITOR_ACTION.toggleH2]: IconHeadingTwo,
  [SLATE_EDITOR_ACTION.toggleH3]: IconHeadingThree,
  [SLATE_EDITOR_ACTION.toggleBulletedList]: IconBulletedList,
  [SLATE_EDITOR_ACTION.toggleNumberedList]: IconNumberedList,
  [SLATE_EDITOR_ACTION.toggleBlockQuote]: IconBlockQuote,
  [SLATE_EDITOR_ACTION.alignTextRight]: IconAlignRight,
  [SLATE_EDITOR_ACTION.alignTextCenter]: IconAlignCenter,
  [SLATE_EDITOR_ACTION.alignTextLeft]: IconAlignLeft,
  [SLATE_EDITOR_ACTION.undo]: IconUndo,
  [SLATE_EDITOR_ACTION.redo]: IconRedo,
  [SLATE_EDITOR_ACTION.clearFormat]: IconClearFormatting,
};

export const getActionIcon = (action) => ICONS[action] || null;
