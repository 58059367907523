import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { createPopper } from '@popperjs/core';

import { useDOMElement } from 'src/lib/domUtils';
import { expandPadding } from '../../utils';
import { SPOTLIGHT_TYPE } from './const';
import useStyles from './useStyles';

const calcSpotlightSize = (options, elementWidth, elementHeight) => {
  const paddings = expandPadding(options.padding);
  const isCircle = options.type === SPOTLIGHT_TYPE.circle;

  let width = elementWidth + paddings.left + paddings.right;
  let height = elementHeight + paddings.top + paddings.bottom;
  if (isCircle) {
    width = Math.max(width, height);
    height = Math.max(width, height);
  }

  return { width, height };
};

const createModifiers = (options) => {
  const paddings = expandPadding(options.padding);

  return [
    {
      name: 'offset',
      options: {
        offset: ({ popper, reference }) => {
          const skidding = (paddings.right - paddings.left) / 2;

          // We need this offset, cause spotlight has initial "bottom" placement
          const verticalOffset = -0.5 * (popper.height + reference.height);
          const distance = verticalOffset + (paddings.bottom - paddings.top) / 2;

          return [skidding, distance];
        },
      },
    },
    {
      name: 'size',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles', 'popperOffsets'],
      fn: ({ state }) => {
        /* eslint-disable no-param-reassign */
        const { width, height } = state.rects.reference;

        const size = calcSpotlightSize(options, width, height);

        state.styles.popper.width = `${size.width}px`;
        state.styles.popper.height = `${size.height}px`;
        /* eslint-enable no-param-reassign */
      },
      effect: ({ state }) => {
        /* eslint-disable no-param-reassign */
        const { offsetWidth, offsetHeight } = state.elements.reference;

        const size = calcSpotlightSize(options, offsetWidth, offsetHeight);

        state.elements.popper.style.width = `${size.width}px`;
        state.elements.popper.style.height = `${size.height}px`;
        /* eslint-enable no-param-reassign */
      },
    },
    {
      name: 'borderStyle',
      enabled: true,
      phase: 'write',
      requires: ['popperOffsets', 'size'],
      effect: ({ state }) => {
        /* eslint-disable no-param-reassign */
        switch (options.type) {
          case SPOTLIGHT_TYPE.circle:
            state.elements.popper.style.borderRadius = '50%';
            break;
          case SPOTLIGHT_TYPE.pill:
            state.elements.popper.style.borderRadius = '256px'; // HINT: arbitrary number, can be increased for taller spotlights
            break;
          case SPOTLIGHT_TYPE.rectangle:
            state.elements.popper.style.borderRadius = '0';
            break;
          case SPOTLIGHT_TYPE.button:
            state.elements.popper.style.borderRadius = '7px';
            break;
          default:
            state.elements.popper.style.borderRadius = '8px';
        }
        /* eslint-enable no-param-reassign */
      },
    },
    {
      name: 'hide',
      enabled: false,
    },
  ];
};

const Spotlight = ({ className, rootElement, target, options }) => {
  const styles = useStyles();

  const rootRef = useRef(null);
  const [hidden, setHidden] = useState(false);

  const targetElement = useDOMElement(rootElement, target);

  useEffect(() => {
    if (!targetElement || !rootRef.current) {
      setHidden(true);
      return undefined;
    }

    const spotlightElement = rootRef.current;

    const popperInstance = createPopper(targetElement, spotlightElement, {
      placement: 'bottom',
      modifiers: createModifiers(options),
    });
    setHidden(false);

    const interval = setInterval(() => {
      popperInstance.update();
    }, 100);

    return () => {
      setHidden(true);

      clearInterval(interval);
      popperInstance.destroy();
    };
  }, [targetElement, options]);

  if (!targetElement) {
    return null;
  }

  return (
    <div
      className={cx(styles.root, className, { [styles.hidden]: hidden })}
      ref={rootRef}
    />
  );
};

Spotlight.propTypes = {
  className: PropTypes.string,
  rootElement: PropTypes.instanceOf(Element),
  target: PropTypes.string.isRequired,
  options: PropTypes.shape().isRequired,
};

const defaultProps = {
  className: null,
  rootElement: null,
};
Spotlight.defaultProps = defaultProps;

export default Spotlight;
