import { inDevelopmentMode } from './env';

/* eslint-disable no-console */

export const trace = (...args) => {
  if (!inDevelopmentMode()) {
    return;
  }
  console.trace('%c[Anyword]', 'background: grey; color: black', ...args);
};

export const message = (...args) => {
  if (!inDevelopmentMode()) {
    return;
  }
  console.log('%c[Anyword]', 'background: blue; color: white', ...args);
};

export const warning = (text, error) => {
  if (!inDevelopmentMode()) {
    return;
  }
  console.warn('%c[Anyword]', 'background: yellow; color: black', text, error);
};

export const error = (e) => {
  if (!inDevelopmentMode()) {
    return;
  }

  console.error('%c[Anyword]', 'background: red; color: white', e);
};
