import { useCallback } from 'react';

import { usePopups } from 'src/services/popup';
import { AD_ACCOUNTS_SELECTION_POPUP } from '../types';

export const useAdAccountsSelectionPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({
      channel,
      brandVoiceId,
      customModel,
      isTrainingModel,
      onConnectSuccess,
      isTalkingPointsView,
    }) => {
      return new Promise((resolve) => {
        popups.openPopup(AD_ACCOUNTS_SELECTION_POPUP, {
          channel,
          onClose: async () => {
            resolve();
            popups.closePopup(AD_ACCOUNTS_SELECTION_POPUP);
          },
          brandVoiceId,
          customModel,
          isTrainingModel,
          onConnectSuccess,
          isTalkingPointsView,
        });
      });
    },
    [popups]
  );
};
