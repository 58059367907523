import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& .recharts-text': {
        fill: COLORS.gray.gray30,
        fontSize: theme.typography.pxToRem(9),
        fontWeight: theme.typography.fontWeightMedium,
      },

      '& .recharts-bar-rectangle path': {
        fill: COLORS.primary.silver,
      },

      '&$highlightYoungest': {
        '& .recharts-bar-rectangle path[name="13-17"]': {
          fill: theme.palette.primary.main,
        },
        '& .recharts-bar-rectangle path[name="18-24"]': {
          fill: theme.palette.primary.main,
        },
        '& .recharts-bar-rectangle path[name="25-34"]': {
          fill: theme.palette.primary.main,
        },
      },
      '&$highlightMiddle': {
        '& .recharts-bar-rectangle path[name="25-34"]': {
          fill: theme.palette.primary.main,
        },
        '& .recharts-bar-rectangle path[name="35-44"]': {
          fill: theme.palette.primary.main,
        },
        '& .recharts-bar-rectangle path[name="45-54"]': {
          fill: theme.palette.primary.main,
        },
      },
      '&$highlightOldest': {
        '& .recharts-bar-rectangle path[name="45-54"]': {
          fill: theme.palette.primary.main,
        },
        '& .recharts-bar-rectangle path[name="55-64"]': {
          fill: theme.palette.primary.main,
        },
        '& .recharts-bar-rectangle path[name="65+"]': {
          fill: theme.palette.primary.main,
        },
      },
    },
    highlightYoungest: {},
    highlightMiddle: {},
    highlightOldest: {},

    labelEmpty: {
      fill: '#9FA8BD',
      fontSize: theme.typography.pxToRem(12),
    },
  }),
  { name: 'HistogramAge' }
);
