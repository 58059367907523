import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/public/createUploadLink';
import { pick } from 'lodash';

import { isApplicationModeInfinity } from 'src/data/system';
import { getApplicationSessionId } from 'src/lib/app';
import * as env from 'src/lib/env';
import { captureMessage } from 'src/lib/sentry';
import Authentication from 'src/utils/Authentication';
import { ROUTES } from 'src/routes';
import mixpanel from 'src/lib/mixpanel';
import * as gtm from 'src/lib/gtm';
import { prepareACDebugContext, getGqlOperationName } from 'src/lib/graphql';
import pubsub, { PUBSUB } from 'src/lib/pubsub';
import { isAbortError } from 'src/lib/errors';

// This variable will be used to pass customer ID to backend
let currentCustomerId = null;
pubsub.subscribe(PUBSUB.CUSTOMER_ID_CHANGED, (_, { customerId }) => {
  currentCustomerId = customerId;
});

// Should contain same values as backend/app/controllers/generic_graphql_controller.rb
const QUERIES_WITHOUT_AUTHENTICATION = [
  'Authenticate',
  'SignUp',
  'ConfirmRegistration',
  'ConfirmTokenLogin',
  'SendResetPasswordRequest',
  'ResetPassword',
  'DomainSettings',
  'AcceptUserInvitation',
  'InviteDetails',
  'ViewSharedDocument',
  'ViewDocumentVariationDetails',
];

const queryRequiresAuth = (operationName) =>
  operationName && !QUERIES_WITHOUT_AUTHENTICATION.includes(operationName);

const requestLink = new ApolloLink((operation, forward) => {
  // "auth" token can be passed in context, or we should use the global one
  const { authToken } = operation.getContext();
  const token = Authentication.getToken();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      // It is useful to have FE version in BE logs
      'X-Frontend-Version': process.env.REACT_APP_REPOSITORY_TAG || '',
      // Authentication token
      'X-Access-Token': authToken || token || '',
      // Mixpanel distinct ID - TODO: check if we still need this one
      'X-Mixpanel-Distinct-id': mixpanel.getDistinctId() || '',
      // Current customer ID
      'X-Anyword-Customer-Id': currentCustomerId || '',
      // Application type, we are using this to tell requests from platform, extension and "embedded" platform apart
      'X-Application-Type': isApplicationModeInfinity() ? 'extension-platform' : 'platform',
      // Session ID, to be able to track same sessions in logs
      'X-Session-Id': getApplicationSessionId(),
    },
  }));

  return forward(operation);
});

/**
 * Middleware to trace AC calls params, which will be piped to browser console
 */
const traceAuthorCompleteLink = new ApolloLink((operation, forward) => {
  const { traceAuthorComplete, traceAuthorCompleteTitle = '' } = operation.getContext();
  if (!traceAuthorComplete) {
    return forward(operation);
  }

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'author-complete-tracing-enabled': true,
    },
  }));

  return forward(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext();

    if (headers) {
      const traceId = headers.get('author-complete-trace-id');
      if (traceId) {
        pubsub.publish(PUBSUB.TRACE_AC_CALL, {
          traceId,
          title: traceAuthorCompleteTitle,
        });
      }
    }

    return response;
  });
});

const httpLink = createUploadLink({
  uri: `${env.getBackendUrl()}/graphql`,
  credentials: 'same-origin',
});

const errorsInclude = (errors, text) => errors.some(({ message }) => message.includes(text));

const sendMessageToSentry = (operation, error, message, extra) => {
  captureMessage(message, {
    level: 'info',
    contexts: {
      graphql: pick(operation, ['operationName', 'variables']),
    },
    extra: {
      error: error.toString(),
      source: 'ApolloClient.sendMessageToSentry',
      ...extra,
    },
  });
};

const sendEventToGTM = (event, operation) => {
  gtm.pushDataLayerEvent(event, {
    graphql: pick(operation, ['operationName', 'variables']),
  });
};

const rewriteErrorMessage = (error, message) => {
  try {
    error.message = message;
  } catch (e) {
    // do nothing
  }
};

/**
 * Configure an "error link" to handle various error cases.
 * Note `console.warn` calls - Sentry provides a log of console messages with each error, so these might be useful.
 */
const errorHandlerLink = onError(({ operation, response, networkError, graphQLErrors }) => {
  if (networkError) {
    // In case of 401 error, logout and navigate to sign-in page
    if (networkError.statusCode === 401) {
      // Post a warning to browser console
      console.warn('Anyword: network request failed with status 401', { operation, networkError });
      // Post event to GTM
      sendEventToGTM(gtm.EVENTS.systemError401, operation);

      // We don't want any errors to propagate to UI from here
      if (response) {
        response.errors = null;
      }

      Authentication.signOut();
      if (!isApplicationModeInfinity()) {
        window.location.replace(ROUTES.SIGN_IN);
      }
      return;
    }

    // In case of 403 error, logout and navigate to verification page
    if (networkError.statusCode === 403 && Authentication.isAuthenticated()) {
      // Post a warning to browser console
      console.warn('Anyword: network request failed with status 403', { operation, networkError });

      if (window.location.pathname !== ROUTES.NOT_VERIFIED && !isApplicationModeInfinity()) {
        window.location.replace(ROUTES.NOT_VERIFIED);
        return;
      }

      return;
    }

    // If we have any 'statusCode' then BE was reached and we've got some response
    if (networkError.statusCode > 0) {
      // If failed query requires signed-in state, but we are signed-out, it's probably after-logout draining query.
      //   We will be ignoring these in UI - just adding informational messages to Sentry.
      if (queryRequiresAuth(operation.operationName) && !Authentication.isAuthenticated()) {
        sendMessageToSentry(
          operation,
          networkError,
          'Error in auth-required GraphQL call, which was made in signed-out state'
        );

        // We don't want any errors to propagate to UI from here
        if (response) {
          response.errors = null;
        }
        return;
      }

      // Post a warning to browser console
      console.warn(`Anyword: network request failed with status ${networkError.statusCode}`, {
        operation,
        networkError,
      });
      // Post event to GTM
      sendEventToGTM(gtm.EVENTS.systemError500, operation);

      return;
    }

    // ApolloClient v3 started pushing "abort errors" through error link as well - do not report them
    if (isAbortError(networkError)) {
      return;
    }

    console.warn('Anyword: possible connection issue', {
      online: window.navigator.onLine,
    });

    // Try rewriting error message to our generic one
    rewriteErrorMessage(networkError, 'Please check your connection and try again.');
  }

  if (graphQLErrors && !isApplicationModeInfinity()) {
    // If we've got "Your trial has expired" error - navigate to "trial ended" page
    if (errorsInclude(graphQLErrors, 'Your trial has expired')) {
      window.location.replace(ROUTES.LOCKED);
      return;
    }

    // If account was "churned" - logout
    if (errorsInclude(graphQLErrors, 'Your account is no longer available')) {
      Authentication.signOut();
      window.location.replace(ROUTES.SIGN_IN);
    }
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const gqlClient = new ApolloClient({
  link: ApolloLink.from([errorHandlerLink, traceAuthorCompleteLink, requestLink, httpLink]),
  cache: new InMemoryCache(),
  queryDeduplication: false,
  defaultOptions,
});

/**
 * Execute GQL query
 * @param {DocumentNode} query - GQL query
 * @param {object} [variables] - query variables
 * @param {object} [options] - additional options
 * @param {object} [options.abortSignal] - AbortController signal
 * @param {object} [options.authToken] - we can pass auth token here, if we want to use it instead of current global one
 * @returns {Promise<ApolloQueryResult>}
 */
export const executeGqlQuery = ({ query, variables, options }) => {
  const params = {
    query,
    variables,
    fetchPolicy: 'no-cache',
  };

  if (options?.abortSignal) {
    params.context = {
      fetchOptions: {
        signal: options.abortSignal,
      },
    };
  }

  if (options?.authToken) {
    params.context = {
      ...params.context,
      authToken: options.authToken,
    };
  }

  return gqlClient.query(params);
};

/**
 * Execute GQL mutation
 * @param {DocumentNode} mutation - GQL mutation
 * @param {object} [variables] - mutation variables
 * @param {object} [options] - additional options
 * @param {object} [options.abortSignal] - AbortController signal
 * @param {object} [options.traceAcRequests] - should we trace AC requests
 * @param {object} [options.authToken] - we can pass auth token here, if we want to use it instead of current global one
 * @returns {Promise<ApolloQueryResult>}
 */
export const executeGqlMutation = ({ mutation, variables, options }) => {
  const params = {
    mutation,
    variables,
  };

  if (options?.traceAcRequests) {
    const mutationName = getGqlOperationName(mutation);
    params.context = prepareACDebugContext(mutationName, variables);
  }

  if (options?.abortSignal) {
    params.context = {
      ...params.context,
      fetchOptions: {
        signal: options.abortSignal,
      },
    };
  }

  if (options?.authToken) {
    params.context = {
      ...params.context,
      authToken: options.authToken,
    };
  }

  return gqlClient.mutate(params);
};
