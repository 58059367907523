import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { captureMessage } from 'src/lib/sentry';
import { PopupContext } from './context';
import { usePopupReducer, openPopup, closePopup, closeAllPopups } from './reducer';
import { useMaintainPopupVisibleCount } from './services';
import { MODAL_TYPES } from './types';
import { CommonPopup } from './views/CommonPopup';

const checkModalTypeValid = (modalTypes, popupName) => {
  const hasType = modalTypes.some((modalType) => modalType.type === popupName);
  if (hasType) {
    return;
  }

  captureMessage(`PopupService: Unknown popup type '${popupName}'`, {
    level: 'warning',
  });
};

export const PopupService = ({ children }) => {
  const [state, dispatch] = usePopupReducer();
  useMaintainPopupVisibleCount(state);

  const providerValue = useMemo(
    () => ({
      dispatch,

      openPopup: (popupName, popupProps) => {
        checkModalTypeValid(MODAL_TYPES, popupName);

        dispatch(openPopup(popupName, popupProps));
      },
      closePopup: (popupName) => {
        checkModalTypeValid(MODAL_TYPES, popupName);

        dispatch(closePopup(popupName));
      },
      closeAllPopups: () => {
        dispatch(closeAllPopups());
      },
    }),
    [dispatch]
  );

  return (
    <PopupContext.Provider value={providerValue}>
      {children}

      <CommonPopup
        modalTypes={MODAL_TYPES}
        state={state}
      />
    </PopupContext.Provider>
  );
};

PopupService.propTypes = {
  children: PropTypes.node.isRequired,
};
