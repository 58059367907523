import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ResetPassword($resetToken: String!, $newPassword: String!) {
    resetPassword(resetToken: $resetToken, newPassword: $newPassword) {
      token
    }
  }
`;

export const resetPassword = (resetToken, newPassword) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { resetToken, newPassword },
  }).then(({ data }) => getSingleProp(data));
