import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_FREESTYLE_TOOL } from './schema';

const MUTATION = gql`
  mutation DeleteCustomerSuggestionFreestyleTool($id: ID!) {
    deleteCustomerSuggestionFreestyleTool(id: $id) {
      ...SuggestionFreestyleTool
    }
  }
  ${FRAGMENT_FREESTYLE_TOOL}
`;

export const deleteSuggestionFreestyleTool = (id) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
