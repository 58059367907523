import { createStateSelectorHook } from 'src/lib/sweetState';
import { CommonStore } from 'src/store';
import {
  useContentTaxonomies,
  useDefaultContentTaxonomies,
} from 'src/store/common';

const useState = createStateSelectorHook(CommonStore, [
  'loadingContentTaxonomies',
]);

export const useViewState = (contentType) => {
  const state = useState();
  const contentTaxonomies = useContentTaxonomies(contentType);
  const defaultContentTaxonomies = useDefaultContentTaxonomies(contentType);

  return {
    ...state,
    contentTaxonomies,
    defaultContentTaxonomies,
  };
};
