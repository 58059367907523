import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconCopy = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
        data-fill-none
      >
        <path
          d="M13.3333 6L7.33333 6C6.59695 6 6 6.59695 6 7.33333L6 13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667L13.3333 14.6667C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333L14.6667 7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.33337 10.0002H2.66671C2.31309 10.0002 1.97395 9.85969 1.7239 9.60964C1.47385 9.35959 1.33337 9.02045 1.33337 8.66683L1.33337 2.66683C1.33337 2.31321 1.47385 1.97407 1.7239 1.72402C1.97395 1.47397 2.31309 1.3335 2.66671 1.3335L8.66671 1.3335C9.02033 1.3335 9.35947 1.47397 9.60952 1.72402C9.85956 1.97407 10 2.31321 10 2.66683V3.3335"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
