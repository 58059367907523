const NON_WORDS_REGEX = /\s+/g;

export const splitToWords = (text = '') => text.split(NON_WORDS_REGEX).filter(Boolean);

export const countWords = (text) => splitToWords(text || '').length;

export const countSentences = (text) => text?.split(/[.!?]+\s/).filter(Boolean).length || 0;

export const countParagraphs = (text) => {
  if (text === '') {
    return 0;
  }

  return text?.split(/[\r\n]{2,}(?!\s*$)/).length || 0;
};

export const countTextSyllables = (text) => {
  if (!text) {
    return 0;
  }

  return text
    .trim()
    .split(/\W+/) // splitToWords count dots and delimiters as part of the word.
    .reduce((count, word) => count + countWordSyllables(word), 0);
};

const countWordSyllables = (word) => {
  if (!word) {
    return 0;
  }

  word = word.toLowerCase().trim(); //word.downcase!

  const hasNonEnglishLetters = /[^a-zA-Z]/.test(word);
  if (hasNonEnglishLetters) {
    return 0;
  }

  if (word.length <= 3 && word.length > 1) {
    return 1;
  }

  word = word.replace(/(?:[^laeiouyc]es|ed|[^laeiouy]e)$/, '');
  word = word.replace(/^y/, '');

  return word.match(/[aeiouy]{1,2}/g)?.length || 0;
};

export const countChars = (text) => (text || '').trim().length;

export const haveTrimmedEnd = (text = '') => {
  if (text.length === 0) {
    return false;
  }

  return !text[text.length - 1].match(NON_WORDS_REGEX);
};

export const appendText = (source, addition, separator) => {
  const preparedSource = source || '';
  const preparedAddition = addition || '';
  const preparedSeparator = separator || '';

  const lastSourceChar = preparedSource[preparedSource.length - 1];
  const firstAdditionChar = preparedAddition[0];

  const needsSeparator =
    lastSourceChar && firstAdditionChar && ![lastSourceChar, firstAdditionChar].includes(separator);

  if (!preparedSeparator || !needsSeparator) {
    return `${preparedSource}${preparedAddition}`;
  }

  return `${preparedSource}${separator}${preparedAddition}`;
};

export const replaceBetween = (str, start, end, what) => {
  return str.slice(0, start) + what + str.slice(end + 1);
};

export const toPascalCase = (text) => {
  return text.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase());
};

export const areEmptyOrEqualStrings = (str1, str2) => str1 === str2 || (!str1 && !str2);

/**
 * Get a hash of input string
 * Source: https://stackoverflow.com/a/7616484
 *
 * @param str
 * @returns {number}
 */
export const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const chr = str.charCodeAt(i);
    /* eslint-disable no-bitwise */
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
    /* eslint-enable no-bitwise */
  }
  return hash;
};

/**
 *
 * @param {String} str - String to split
 * @param {Array} tokens - Array of strings
 * @returns {Array} Array of string separated by the tokens provided
 */
export const splitStrByMultipleTokens = (str, tokens) => {
  let strToSplit = str;
  const tempChar = tokens[0];
  for (let i = 1; i < tokens.length; i += 1) {
    strToSplit = strToSplit.split(tokens[i]).join(tempChar);
  }
  strToSplit = strToSplit.split(tempChar).filter(Boolean);
  return strToSplit;
};

/**
 * Loosely validate a URL `string`.
 * Source: https://github.com/segmentio/is-url
 *
 * @param {String} string
 * @return {Boolean}
 */
export const isValidUrl = (str) => {
  /* eslint-disable no-useless-escape */
  const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
  const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;
  /* eslint-disable no-useless-escape */
  if (typeof str !== 'string') {
    return false;
  }

  const match = str.match(protocolAndDomainRE);

  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];

  if (!everythingAfterProtocol) {
    return false;
  }

  if (
    localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)
  ) {
    return true;
  }

  return false;
};

export const getWordBoundaryRegExp = (str) => new RegExp(`\(^|\\W)${str}\(?!\\w)`, 'gi');

/**
 * Get a string with indefinite article for provided text
 * @param {string} text - text to get article for
 * @returns {string} - indefinite article
 */
export const determineIndefiniteArticle = (text) => {
  if (!text) {
    return '';
  }

  const firstLetter = text[0] || '';
  if (['a', 'e', 'i', 'o', 'u'].includes(firstLetter.toLowerCase())) {
    return 'an';
  }

  return 'a';
};

export const isSentenceEndCharacter = (char) => {
  return /[.!?]/.test(char);
};

export const isSpaceCharacter = (char) => {
  return /\s/.test(char);
};

/**
 * Check if text contains hebrew characters
 * @param {string} text - text to check
 * @returns {boolean} - true if text contains hebrew characters
 */
export function containsHebrew(text) {
  return /[\u0590-\u05FF]/.test(text);
}
