import React from 'react';
import PropTypes from 'prop-types';

import { InsightsLeafDefault } from '../InsightsLeafDefault';
import { InsightsLeafBrandTerm } from '../InsightsLeafBrandTerm';

const getLeafComponent = (leaf) => {
  if (leaf.data?.brandTerm) {
    return InsightsLeafBrandTerm;
  }

  return InsightsLeafDefault;
};

export const InsightsLeaf = ({ attributes, leaf, children }) => {
  const LeafComponent = getLeafComponent(leaf);
  return (
    <LeafComponent
      attributes={attributes}
      leaf={leaf}
    >
      {children}
    </LeafComponent>
  );
};

InsightsLeaf.propTypes = {
  attributes: PropTypes.shape({}),
  leaf: PropTypes.shape({
    data: PropTypes.shape({
      brandTerm: PropTypes.shape(),
    }),
  }),
  children: PropTypes.shape({}),
};

InsightsLeaf.defaultProps = {
  attributes: null,
  leaf: null,
  children: null,
};
