import { useCallback, useRef } from 'react';

import { focusEditor } from 'src/components/RichSlate/utils';

export const useHandlers = ({
  editor,
  setEditMode,
  onSaveChanges,
  onCancelChanges,
  onEditorContentChange,
}) => {
  const hasChanges = useRef(false);

  const handleCancelClick = useCallback(() => {
    setEditMode(false);

    if (onCancelChanges) {
      onCancelChanges(hasChanges.current);
    }
  }, [onCancelChanges, setEditMode]);

  const handleSaveClick = useCallback(() => {
    setEditMode(false);

    if (onSaveChanges) {
      onSaveChanges();
    }
  }, [setEditMode, onSaveChanges]);

  const handleEditorContentChange = useCallback(
    (newValue, operations) => {
      hasChanges.current = true;
      onEditorContentChange(newValue, operations);
    },
    [onEditorContentChange]
  );

  const handleEditClick = useCallback(() => {
    setEditMode(true);

    if (editor) {
      focusEditor({ editor, atEnd: true, ignoreSelection: true });
    }
    hasChanges.current = true;
  }, [editor, setEditMode]);

  return { handleCancelClick, handleSaveClick, handleEditorContentChange, handleEditClick };
};
