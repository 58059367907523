import { useCallback } from 'react';

import { formatProjectRoute } from 'src/lib/routing';
import { ROUTE_PARAMS } from 'src/routes';

const prepareRouteParams = (options) => {
  const params = {};
  if (options?.generationTool) {
    params[ROUTE_PARAMS.generationTool] = options.generationTool;
  }
  if (options?.textToImprove) {
    params[ROUTE_PARAMS.textToImprove] = encodeURIComponent(options.textToImprove);
  }
  if (options?.keywords?.length) {
    params[ROUTE_PARAMS.keywords] = encodeURIComponent(options.keywords);
  }

  return params;
};

export const useNavigateToProject = () => {
  /**
   * Navigate user to an existing project for selected "generation tool" and prefill "text to improve"
   */
  return useCallback((projectId, options) => {
    // Navigate to the project page
    const params = prepareRouteParams(options);
    window.open(formatProjectRoute(projectId, params), '_blank');
  }, []);
};
