export const BLOCKED_REASON = {
  maxWordsExceeded: 'MAX_WORDS_EXCEEDED',
  nonEnglish: 'NON_ENGLISH',
  invalidBrief: 'INVALID_BRIEF',
  limitedScoring: 'LIMITED_SCORING',
  emptyText: 'EMPTY_TEXT',
  invalidCampaignKeywordsAndProductName: 'INVALID_CAMPAIGN_KEYWORDS_AND_PRODUCT_NAME',
};

const BLOCKED_REASON_COPY = {
  [BLOCKED_REASON.maxWordsExceeded]:
    'Your text is too long to be scored.\nPlease edit your text to 200 words or under.',
  [BLOCKED_REASON.nonEnglish]: 'Scoring is available for English text only',
  [BLOCKED_REASON.limitedScoring]:
    'Get real-time scoring and analytics\nfor every copy edit you make',
  [BLOCKED_REASON.invalidBrief]:
    'To generate a score, describe your product/content in the section above.',
  [BLOCKED_REASON.emptyText]: 'Write something to see its predicted score and analytics',
  [BLOCKED_REASON.invalidCampaignKeywordsAndProductName]:
    'Campaign keywords and product name are needed to score copy',
};

export const getBlockedReasonCopy = (blockedReason) => BLOCKED_REASON_COPY[blockedReason] || '';

export const ANYWORD_FACEBOOK_LINK =
  'https://www.facebook.com/groups/anyworders/?ref=anyword_dashboard';

export const ANYWORD_WEBINAR_LINK =
  'https://anyword.com/webinar/anyword-walkthrough/?utm_source=inapp&utm_medium=dashboard_banner&utm_campaign=anyword-walkthrough';

export const BOOST_WEBINAR_LINK =
  'https://anyword.com/webinar/anyword-boost-extension/?utm_term=Invite&utm_campaign=Webinars%20-%20Infinity&utm_medium=sitebanner&_hsmi=268015274&_hsenc=p2ANqtz-8vtEYqGnj1aLo9PxjRKlyt7tAsJb27Jd2e1oUUiQJqpjrHUceJTH1yDILceHfiGb5E3DtxCTseCx21RzDwQqtXqK6slw&utm_content=268015274&utm_source=website';

export const NAVIGATION_BAR_HEIGHT = 50;

export const ASYNC_TASK_STATUS = {
  created: 'created',
  submitted: 'submitted',
  done: 'done',
  doneWithErrors: 'done_with_errors',
};

export const UPGRADE_DIALOG_ACTIONS_ORIGIN = {
  settingsPage: 'settings_page',
  analyticsPage: 'analytics_page',
  talkingPointsPage: 'talking_points_page',
  wtmPage: 'wtm_page',
  editorPage: 'editor_page',
  brandVoicePage: 'brand_voice_page',
  cipPageConnectDialog: 'cip_page_connect_dialog',
  cipPageChannel: 'cip_page_channel',
  cipPageResource: 'cip_page_resource',
  cipPageWebsite: 'cip_page_website',
  workspaceDialog: 'workspace_dialog',
  customModel: 'custom_model',
  blogWizard: 'blog_wizard',
  targetAudiencePopup: 'target_audience_popup',
  brandTermsPage: 'brand_terms_page',
};

export const BOOK_DEMO_ORIGIN = {
  scorePanelBrandTerms: 'score_panel_brand_terms',
  brandTermsPage: 'brand_terms_page',
  brandTermsPopup: 'brand_terms_popup',
  brandBriefsPage: 'brand_briefs_page',
  brandBriefsPopup: 'brand_briefs_popup',
  scoringMethodPopup: 'scoring_method_popup',
  subscriptionPage: 'subscription_page',
  customFormulaPage: 'custom_formula_page',
  customModelsPage: 'custom_models_page',
  customFormulaPopup: 'custom_formula_popup',
  TemplateBuilder: 'template_builder_page',
  targetAudiencePage: 'target_audience_page',
  targetAudiencePopup: 'target_audience_popup',
  customModels: 'copy_scoring_models',
  addWorkspace: 'add_workspace',
  addUser: 'add_user',
  toneOfVoicePage: 'custom_tone_of_voice_page',
  toneOfVoicePopup: 'custom_tone_of_voice_popup',
  customerPersonaPopup: 'customer_persona_popup',
  checkPlagiarism: 'check_plagiarism',
  ...UPGRADE_DIALOG_ACTIONS_ORIGIN,
};

export const UPGRADE_ORIGIN = {
  subscriptionPage: 'subscription_page',
  scoringPopup: 'scoring_popup',
  ...UPGRADE_DIALOG_ACTIONS_ORIGIN,
};

export const BRAND_VOICE_ASSET = {
  customModel: 'customModel',
  targetAudience: 'targetAudience',
  toneOfVoice: 'toneOfVoice',
  brandRule: 'brandRule',
  messagingBank: 'messagingBank',
  formula: 'formula',
};

export const SORT_DIRECTION = {
  asc: 'asc',
  desc: 'desc',
};

export const toggleSorting = (currentField, currentDirection, newField) => {
  const sameField = currentField === newField;
  const sortedAsc = currentDirection === SORT_DIRECTION.asc;
  const newDirection = sameField && sortedAsc ? SORT_DIRECTION.desc : SORT_DIRECTION.asc;

  return {
    sortField: newField,
    sortDirection: newDirection,
  };
};

export const DEFAULT_TAXONOMY_NAME = 'General';
export const MAX_RESOURCE_PER_TYPE = 5;
