import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    muiAppBar: {
      backgroundColor: theme.palette.common.white,
      color: theme.typography.body2.color,
    },
    shadow: {
      boxShadow: '0px 8px 12px rgba(83, 122, 221, 0.06)',
    },
    toolbar: {
      maxWidth: theme.maxContainerWidth,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(7.5),
      paddingRight: theme.spacing(7.5),

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
    },
    staticToolBar: {
      height: ({ toolbarHeight }) => toolbarHeight,
    },
    loader: {
      position: 'absolute',
      width: '100%',
    },
    underline: {
      textDecoration: 'underline',
    },
  }),
  {
    name: 'UnauthenticatedNavBar',
  }
);
