import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_RECOMPOSE_REQUEST } from './schema';

const MUTATION = gql`
  mutation RephraseTextsForLpco(
    $projectId: ID!
    $generationTool: GenerationToolEnum!
    $brief: BriefInput!
    $scoringMethod: ScoringMethodInput
    $text: String!
    $recomposeMode: String
    $keywords: String
    $language: String!
    $languageTargetFormality: LanguageTargetFormalityEnum
    $numberOfVariations: Int!
    $tone: String
    $agePreset: AgePresetEnum
    $tovId: ID
    $enterpriseModelId: ID
    $debugAcPrompt: String
    $landingPageAssetId: ID
    $maxChars: Int
  ) {
    rephraseTextsForLpco(
      projectId: $projectId
      generationTool: $generationTool
      brief: $brief
      scoringMethod: $scoringMethod
      text: $text
      recomposeMode: $recomposeMode
      keywords: $keywords
      language: $language
      languageTargetFormality: $languageTargetFormality
      numberOfVariations: $numberOfVariations
      tone: $tone
      agePreset: $agePreset
      tovId: $tovId
      enterpriseModelId: $enterpriseModelId
      debugAcPrompt: $debugAcPrompt
      landingPageAssetId: $landingPageAssetId
      maxChars: $maxChars
    ) {
      ...RecomposeRequest
    }
  }
  ${FRAGMENT_RECOMPOSE_REQUEST}
`;

export const rephraseTextsForLpco = async ({
  projectId,
  generationTool,
  brief,
  scoringMethod,
  text,
  recomposeMode,
  keywords,
  language,
  numberOfVariations,
  tone,
  agePreset,
  tovId,
  enterpriseModelId,
  debugAcPrompt,
  landingPageAssetId,
  languageTargetFormality,
  maxChars,
}) => {
  const variables = {
    projectId,
    generationTool,
    brief,
    scoringMethod,
    text,
    recomposeMode,
    keywords,
    language,
    numberOfVariations,
    tone,
    agePreset,
    tovId,
    enterpriseModelId,
    debugAcPrompt,
    landingPageAssetId,
    languageTargetFormality,
    maxChars,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
