import { useMemo } from 'react';

import { createStateSelectorHook } from 'src/lib/sweetState';
import { TalkingPointsViewsStore } from 'src/store';
import { CHANNEL, alignChannelFromBackend } from 'src/data/channel';
import {
  EMAIL_CSV_RESOURCES,
  GENERIC_RESOURCE,
  INTEGRATION_CHANNELS,
  RESOURCE_CHANNELS,
  isPlatformChannel,
} from 'src/data/performance';

const AVAILABLE_INTEGRATION_CHANNELS = [
  GENERIC_RESOURCE,
  ...EMAIL_CSV_RESOURCES,
  ...INTEGRATION_CHANNELS.filter((channel) => !isPlatformChannel(channel)),
];
const useTalkingPointsState = createStateSelectorHook(TalkingPointsViewsStore, [
  'talkingPointsViews',
  'customerTalkingPointsViews',
]);

export const useTalkingPointsViews = ({ shouldGetAllViews = false } = {}) => {
  const { talkingPointsViews, customerTalkingPointsViews } = useTalkingPointsState();

  return useMemo(() => {
    const alignedTalkingPointsViews = shouldGetAllViews
      ? customerTalkingPointsViews
      : talkingPointsViews;

    return alignedTalkingPointsViews.reduce(
      (acc, item) => {
        const channel = alignChannelFromBackend(item.dataSource);
        if (AVAILABLE_INTEGRATION_CHANNELS.includes(channel)) {
          acc.integrations.push({ ...item, channel });
        } else if ([...RESOURCE_CHANNELS, CHANNEL.generalDocument].includes(channel)) {
          acc.resources.push({ ...item, channel });
        }
        return acc;
      },
      { integrations: [], resources: [] }
    );
  }, [talkingPointsViews, customerTalkingPointsViews, shouldGetAllViews]);
};
