import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_LINKEDIN_SOCIAL_AD_ACCOUNT } from './schema';

const QUERY = gql`
  query LinkedinSocialAdAccounts {
    me {
      id

      linkedinSocialData {
        adAccounts {
          edges {
            node {
              ...LinkedinUserAdAccount
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_LINKEDIN_SOCIAL_AD_ACCOUNT}
`;

export function queryLinkedinSocialAdAccounts() {
  return executeGqlQuery({
    query: QUERY,
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => unwrapEdges(result?.linkedinSocialData?.adAccounts?.edges || []));
}
