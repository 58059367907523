import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_RECOMPOSE_REQUEST } from './schema';

const MUTATION = gql`
  mutation RephraseTextsForDocumentEditor(
    $projectId: ID!
    $originalVariationId: ID
    $assetType: String
    $generationTool: GenerationToolEnum!
    $brief: BriefInput
    $scoringMethod: ScoringMethodInput
    $productName: String
    $text: String!
    $recomposeMode: String
    $keywords: String
    $language: String!
    $languageTargetFormality: LanguageTargetFormalityEnum
    $numberOfVariations: Int!
    $debugAcPrompt: String
    $sourceType: String
    $onlyImproveScore: Boolean
    $forceImproveReason: Boolean
    $customerPersonaId: ID
    $annotations: [SuggestionAnnotationInput!]
    $manualInstructions: String
    $brandVoiceIdsForBrandTerms: [ID!]
    $tovInput: TovInput
  ) {
    rephraseTextsForDocumentEditor(
      projectId: $projectId
      originalVariationId: $originalVariationId
      assetType: $assetType
      generationTool: $generationTool
      brief: $brief
      scoringMethod: $scoringMethod
      productName: $productName
      text: $text
      recomposeMode: $recomposeMode
      keywords: $keywords
      language: $language
      languageTargetFormality: $languageTargetFormality
      numberOfVariations: $numberOfVariations
      debugAcPrompt: $debugAcPrompt
      sourceType: $sourceType
      onlyImproveScore: $onlyImproveScore
      forceImproveReason: $forceImproveReason
      customerPersonaId: $customerPersonaId
      annotations: $annotations
      manualInstructions: $manualInstructions
      brandVoiceIdsForBrandTerms: $brandVoiceIdsForBrandTerms
      tovInput: $tovInput
    ) {
      ...RecomposeRequest
    }
  }
  ${FRAGMENT_RECOMPOSE_REQUEST}
`;

export const rephraseTextsForDocumentEditor = async (
  {
    projectId,
    originalVariationId,
    assetType,
    generationTool,
    brief,
    scoringMethod,
    productName,
    text,
    recomposeMode,
    keywords,
    language,
    numberOfVariations,
    debugAcPrompt,
    languageTargetFormality,
    sourceType,
    onlyImproveScore,
    forceImproveReason,
    customerPersonaId,
    annotations,
    manualInstructions,
    brandVoiceIdsForBrandTerms,
    tovInput,
  },
  options
) => {
  const variables = {
    projectId,
    originalVariationId,
    assetType,
    generationTool,
    brief,
    scoringMethod,
    productName,
    text,
    recomposeMode,
    keywords,
    language,
    numberOfVariations,
    debugAcPrompt,
    languageTargetFormality,
    sourceType,
    onlyImproveScore,
    forceImproveReason,
    customerPersonaId,
    annotations,
    manualInstructions,
    brandVoiceIdsForBrandTerms,
    tovInput,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
