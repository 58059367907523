import React, { forwardRef } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconFolderOpenedOutlined = forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    viewBox="0 0 20 20"
    data-fill-none
  >
    <path
      d="M16.875 8.125V5.625C16.875 5.29348 16.7433 4.97554 16.5089 4.74112C16.2745 4.5067 15.9565 4.375 15.625 4.375H6.875V3.125C6.875 2.79348 6.7433 2.47554 6.50888 2.24112C6.27446 2.0067 5.95652 1.875 5.625 1.875H1.875C1.54348 1.875 1.22554 2.0067 0.991117 2.24112C0.756696 2.47554 0.625 2.79348 0.625 3.125V16.7083C0.629704 17.0487 0.756813 17.376 0.983069 17.6304C1.20933 17.8847 1.5196 18.0491 1.85711 18.0934C2.19463 18.1377 2.53681 18.059 2.82106 17.8717C3.1053 17.6844 3.31259 17.401 3.405 17.0733L5.36417 9.02583C5.43983 8.76576 5.59791 8.5373 5.81463 8.37483C6.03135 8.21237 6.29498 8.12469 6.56583 8.125H18.125C18.3166 8.12493 18.5057 8.16892 18.6776 8.25355C18.8495 8.33819 18.9996 8.46121 19.1164 8.61312C19.2332 8.76502 19.3135 8.94174 19.3511 9.12962C19.3887 9.3175 19.3826 9.51151 19.3333 9.69667L17.5 17.1967C17.4291 17.463 17.2722 17.6984 17.0536 17.8662C16.8351 18.0341 16.5672 18.1251 16.2917 18.125H2.03917"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
));
