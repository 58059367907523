import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';
import { APP_LOGO_VARIANT } from './const';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'inline-flex',

      [`&[data-variant="${APP_LOGO_VARIANT.default}"]`]: {
        '& $logo': {
          // no change in "default" color
        },
      },
      [`&[data-variant="${APP_LOGO_VARIANT.light}"]`]: {
        '& $logo': {
          color: COLORS.neutral.white,
        },
      },
      [`&[data-variant="${APP_LOGO_VARIANT.black}"]`]: {
        '& $logo': {
          color: COLORS.neutral.black,
        },
      },
    },
    logo: {},
    logoAnyword: {
      fontSize: theme.typography.pxToRem(24),
      width: 'auto',
      color: COLORS.primary.default,
    },
    logoTeads: {
      fontSize: theme.typography.pxToRem(72),
      width: 'auto',
      color: '#000',
    },
  }),
  { name: 'AppLogo' }
);
