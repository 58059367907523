import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import NotificationSuccess from 'src/assets/icons/NotificationSuccess';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  analyzerToneOfVoice: PropTypes.string.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
};

const defaultProps = {
  className: null,
};

export const SuccessMessage = ({ className, analyzerToneOfVoice, onSettingsClick }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)}>
      <NotificationSuccess className={styles.icon} />
      <CustomTypography
        variant={TYPOGRAPHY_VARIANT.regular14}
        className={styles.label}
      >
        This text is generally aligned with{' '}
        <button
          onClick={onSettingsClick}
          className={styles.toneOfVoiceNameButton}
        >
          ‘{analyzerToneOfVoice.name}’
        </button>{' '}
        tone of voice
      </CustomTypography>
    </div>
  );
};

SuccessMessage.propTypes = propTypes;
SuccessMessage.defaultProps = defaultProps;
