import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation AuthorizeMarketo($clientId: String!, $clientSecret: String!, $identityUrl: String!) {
    authorizeMarketo(clientId: $clientId, clientSecret: $clientSecret, identityUrl: $identityUrl) {
      authState
      adAccounts {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const authorizeMarketo = ({ clientId, clientSecret, identityUrl }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { clientId, clientSecret, identityUrl },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => unwrapEdges(result?.adAccounts?.edges || []));
};
