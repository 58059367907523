import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_JOB } from 'src/graphql/job';

const MUTATION = gql`
  mutation ImportBrandTerms(
    $workspaceId: ID!
    $brandVoiceId: ID!
    $input: PerformanceCenterCustomerResourceInputType!
  ) {
    importBrandTerms(workspaceId: $workspaceId, brandVoiceId: $brandVoiceId, input: $input) {
      ...Job
    }
  }
  ${FRAGMENT_JOB}
`;

export const importBrandTerms = ({ workspaceId, brandVoiceId, input }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { workspaceId, brandVoiceId, input },
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
