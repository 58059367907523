import { get } from 'lodash';

import {
  BILLING_CYCLES,
  DEFAULT_SUBSCRIPTION_PLAN,
  SUBSCRIPTION_BASIC_TIER,
  SUBSCRIPTION_DATA_DRIVEN_TIER,
  SUBSCRIPTION_PLAN,
  SUBSCRIPTION_SEAT_TIER,
} from './const';
import {
  clampSubscriptionTier,
  getPlanByTier,
  getSubscriptionTierSeatsLimit,
  getSubscriptionTierWordsLimit,
  getTierProperty,
} from './subscriptionTier';
import {
  clampSubscriptionPlan,
  getPlanProperty,
  isBasicPlan,
  isBusinessSelfServePlan,
  isDataDrivenPlan,
  isEnterpriseOrBusinessPlan,
  isStarterPlan,
  isSubscriptionPlanDeprecated,
} from './subscriptionPlan';
import { canSubscriptionBeReactivated, PLAN_PRICES, SEAT_PRICE_PER_MONTH } from './billing';
import { customerInTrial, isCustomerSubscriptionCanceled } from '../customer';

export const getCustomerPlan = (customer) => get(customer, 'billingConfiguration.plan');

export const getCustomerTier = (customer) => get(customer, 'billingConfiguration.wordsTier');

export const getCustomerOldTier = (customer) => get(customer, 'planMigrationStatus.oldTier');

export const getCustomerOldPlan = (customer) => {
  const oldPlan = get(customer, 'planMigrationStatus.oldPlan');
  if (oldPlan === SUBSCRIPTION_PLAN.dataDriven100) {
    return SUBSCRIPTION_PLAN.dataDriven;
  }
  return oldPlan;
};

export const getCustomerPendingTier = (customer) =>
  get(customer, 'billingConfiguration.planChangeRequest.wordsTier');

export const getCustomerPendingPlan = (customer) =>
  get(customer, 'billingConfiguration.planChangeRequest.plan');

export const getCustomerBillingCyclePeriod = (customer, suppressDefault) => {
  const billingCyclePeriod = customer?.billingConfiguration?.billingCyclePeriod;

  if (!billingCyclePeriod && !suppressDefault) {
    return BILLING_CYCLES.annually;
  }

  return billingCyclePeriod;
};

export const getCustomerBillingPeriodEnd = (customer) =>
  get(customer, 'billingConfiguration.currentPeriod.end');

export const getCustomerBillingMethod = (customer) => get(customer, 'billingConfiguration.method');

export const getCustomerBillingQueryQuota = (customer) =>
  get(customer, 'billingConfiguration.queryQuota');

export const getCustomerBillingDetails = (customer) =>
  get(customer, 'paymentMethod.billingDetails');

export const isSubscriptionPendingCancellation = (customer) => {
  const billingMethod = getCustomerBillingMethod(customer);
  const canBeReactivated = canSubscriptionBeReactivated(billingMethod);
  const cancelAtPeriodEnd = get(customer, 'billingConfiguration.cancelAtPeriodEnd');
  return canBeReactivated && cancelAtPeriodEnd;
};

export const getTierOrPlanProperty = (plan, tier, property, defaultValue = undefined) => {
  const tierValue = getTierProperty(tier, property, undefined, plan);
  if (tierValue) {
    return tierValue;
  }
  const planValue = getPlanProperty(plan, property);
  if (planValue) {
    return planValue;
  }
  return defaultValue;
};

export const getNumberOfMonthsDiscount = (plan, tier) =>
  getTierOrPlanProperty(plan, tier, 'numberOfMonthsDiscount', 0);

export const getPercentOfAnnualDiscount = (plan, tier) =>
  getTierOrPlanProperty(plan, tier, 'percentOfAnnualDiscount', 0);

export const getAnnualDiscountLabel = (plan, tier) => {
  const originalPrice = PLAN_PRICES[plan]?.annual;
  if (!originalPrice) {
    return '';
  }

  const seats = getTierOrPlanProperty(plan, tier, 'seats');
  const yearlyPricePerMonth = originalPrice + Math.max(0, seats - 3) * SEAT_PRICE_PER_MONTH.annual;

  const percent = Math.floor(
    100 * (1 - yearlyPricePerMonth / getTierOrPlanProperty(plan, tier, 'price'))
  );

  const numberOfMonthsDiscount = getNumberOfMonthsDiscount(plan, tier);
  return numberOfMonthsDiscount
    ? `${numberOfMonthsDiscount} months free`
    : percent
    ? `${percent}% off`
    : '';
};

export const isGreaterThanTier = (selectedTier, tier) =>
  getSubscriptionTierWordsLimit(selectedTier) > getSubscriptionTierWordsLimit(tier);

export const isLowerThanTier = (selectedTier, tier) =>
  getSubscriptionTierWordsLimit(selectedTier) < getSubscriptionTierWordsLimit(tier);

export const isDowngradingPlan = (currentPlan, currentTier, plan) => {
  // If plans are the same, then it can't be treated as downgrading
  if (currentPlan === plan) {
    return false;
  }

  // "ace" and "business" are the highest plans
  if (isEnterpriseOrBusinessPlan(currentPlan)) {
    return true;
  }

  if (isBusinessSelfServePlan(currentPlan)) {
    return [
      SUBSCRIPTION_PLAN.basic,
      SUBSCRIPTION_PLAN.starter,
      SUBSCRIPTION_PLAN.dd_teams,
    ].includes(plan);
  }

  if (isDataDrivenPlan(currentPlan, currentTier)) {
    return [SUBSCRIPTION_PLAN.basic, SUBSCRIPTION_PLAN.starter].includes(plan);
  }

  if (isStarterPlan(currentPlan)) {
    return isBasicPlan(plan);
  }

  return false;
};

export const isDowngradingSamePlanTier = ({ currentPlan, currentTier, plan, tier }) =>
  currentPlan === plan && isLowerSeatTier(currentPlan, currentTier, plan, tier);

const isLowerSeatTier = (currentPlan, currentTier, plan, tier) => {
  return (
    getSubscriptionTierSeatsLimit(currentTier, currentPlan) >
    getSubscriptionTierSeatsLimit(tier, plan)
  );
};

export const isMoreExpensiveTier = (currentTier, tier, plan) =>
  getTierProperty(tier, 'price', undefined, plan) >
  getTierProperty(currentTier, 'price', undefined, plan);

export const isDowngradingPlanOrTier = ({ currentPlan, currentTier, plan, tier }) => {
  if (isDowngradingPlan(currentPlan, currentTier, plan) && isMoreExpensiveTier(currentTier, tier)) {
    return false;
  }

  return (
    isDowngradingPlan(currentPlan, currentTier, plan) ||
    isDowngradingSamePlanTier({ currentPlan, currentTier, plan, tier })
  );
};

export const isPurchasingSubscription = (customer) => {
  const currentPlan = getCustomerPlan(customer);
  const isInTrial = customerInTrial(customer);
  const customerSubscriptionCanceled = isCustomerSubscriptionCanceled(customer);

  return isBasicPlan(currentPlan) || isInTrial || customerSubscriptionCanceled;
};

export const actualizeSubscriptionPlan = (plan, defaultValue = DEFAULT_SUBSCRIPTION_PLAN) => {
  const clamped = clampSubscriptionPlan(plan);
  if (!isSubscriptionPlanDeprecated(clamped)) {
    return clamped;
  }
  return defaultValue;
};

export const actualizeSubscriptionTierForPlan = (tier, plan) => {
  const clamped = clampSubscriptionTier(tier, null);
  const tierPlan = getPlanByTier(tier, null, plan);
  // TODO: business_self_serve this functionality is redundant

  // Return early if tier is active and belongs to required plan
  if (clamped && tierPlan === plan) {
    return clamped;
  }

  // Tier fallbacks according to required plan
  switch (plan) {
    case SUBSCRIPTION_PLAN.basicTiered:
      return SUBSCRIPTION_BASIC_TIER.basic_tier_20k;

    case SUBSCRIPTION_PLAN.dataDriven:
      return SUBSCRIPTION_DATA_DRIVEN_TIER.data_driven_30k;

    case SUBSCRIPTION_PLAN.proAgency:
      return SUBSCRIPTION_DATA_DRIVEN_TIER.pro_beta_agency_tier_1;

    case SUBSCRIPTION_PLAN.dd_teams:
    case SUBSCRIPTION_PLAN.business_self_serve:
      return SUBSCRIPTION_SEAT_TIER.seats_3;

    default:
      return null;
  }
};

export const isUpgradeFromMonthlyToAnnually = ({ currentBillingCycle, billingCycle }) =>
  currentBillingCycle === BILLING_CYCLES.monthly && billingCycle === BILLING_CYCLES.annually;
