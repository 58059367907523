import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import { createTaxonomyScoringMethod } from 'src/data/scoring';

export const NAME = 'popup-scoring-method';

const INITIAL_STATE = {
  selectedTaxonomyId: null,
  isLoading: false,
  error: false,
};

export const PRIVATE_ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },
};

/**
 * Publicly available actions
 */
export const ACTIONS = {
  initStore:
    () =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.clearStore());
    },

  initSelection:
    (value) =>
    ({ setState }) => {
      if (!value) {
        return;
      }

      setState({ selectedTaxonomyId: value.id });
    },

  selectTaxonomy:
    (taxonomyId) =>
    ({ setState }) => {
      setState({ selectedTaxonomyId: taxonomyId, error: false });
    },

  getScoringMethod:
    () =>
    ({ getState, setState }) => {
      const { selectedTaxonomyId } = getState();
      const scoringMethod = createTaxonomyScoringMethod(selectedTaxonomyId);

      const isValid = scoringMethod.id != null;

      if (isValid) return scoringMethod;

      setState({ error: true });
      return null;
    },

  setIsLoading:
    (value) =>
    ({ setState }) => {
      setState({ isLoading: value });
    },
};

export const PopupStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});
