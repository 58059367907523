import React from 'react';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const Loader = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <Skeleton
          className={styles.skeleton}
          animation="wave"
          width={111}
          height={26}
        />
      </div>
      <div className={styles.wrapper}>
        <Skeleton
          className={styles.skeleton}
          animation="wave"
          width={139}
          height={24}
        />
        <Skeleton
          className={styles.skeleton}
          animation="wave"
          width={91}
          height={24}
        />
      </div>
    </div>
  );
};
