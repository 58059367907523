import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconBox = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
        data-fill-none
      >
        <path
          d="M8.59353 0.966685L13.9269 3.63335C14.149 3.74374 14.3359 3.9139 14.4667 4.12471C14.5974 4.33553 14.6667 4.57863 14.6669 4.82668V11.18C14.6667 11.4281 14.5974 11.6712 14.4667 11.882C14.3359 12.0928 14.149 12.263 13.9269 12.3734L8.59353 15.04C8.40829 15.1327 8.204 15.181 7.99686 15.181C7.78972 15.181 7.58543 15.1327 7.4002 15.04L2.06686 12.3734C1.84495 12.2615 1.6587 12.0899 1.52912 11.8779C1.39954 11.6659 1.3318 11.4218 1.33353 11.1734V4.82668C1.33366 4.57863 1.40299 4.33553 1.53371 4.12471C1.66444 3.9139 1.85139 3.74374 2.07353 3.63335L7.40686 0.966685C7.59123 0.875069 7.79432 0.827393 8.00019 0.827393C8.20607 0.827393 8.40916 0.875069 8.59353 0.966685V0.966685Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.54688 4.10669L8.00021 7.33336L14.4535 4.10669"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 15.1733V7.33325"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
