import { useCallback } from 'react';

import { useCustomerActions, usePerformanceActions } from 'src/store';
import { useCipEvents, useErrorHandler } from 'src/services';
import { ADD_FILE_RESOURCE_POPUP } from '../types';
import { usePopups } from '../services';

export const useAddFileResourcePopup = () => {
  const popups = usePopups();
  const performanceActions = usePerformanceActions();
  const customerActions = useCustomerActions();

  const cipEvents = useCipEvents();
  const handleError = useErrorHandler();

  return useCallback(
    ({
      type,
      brandVoiceId,
      onCreateSuccess,
      onConnectSuccess,
      allowReplace = false,
      isTalkingPointsView = false,
    }) => {
      return new Promise((resolve) => {
        popups.openPopup(ADD_FILE_RESOURCE_POPUP, {
          onClose: () => {
            popups.closePopup(ADD_FILE_RESOURCE_POPUP);
            resolve();
          },
          onSubmit: async ({ resource }) => {
            try {
              if (!allowReplace) {
                popups.closePopup(ADD_FILE_RESOURCE_POPUP);
              }
              const result = await performanceActions.createResourceAccount({
                csvFile: resource,
                channel: type,
                brandVoiceId,
                onCreateSuccess,
                onConnectSuccess,
                isTalkingPointsView,
              });
              cipEvents.trackAddResource(type, result.name);

              // Refresh customer limits and rows allocation, cause campaign status has been changed, do not wait for result
              performanceActions.queryWorkspaceResources();
              customerActions.refreshCurrentCustomerFeatureLimit();
              customerActions.refreshCurrentCustomerRowsAllocation();

              if (allowReplace) {
                popups.closePopup(ADD_FILE_RESOURCE_POPUP);
              }
              resolve({ channel: result.dataSource, ...result });
            } catch (error) {
              performanceActions.setCreatingResource(false);
              handleError(error);
              resolve();
            }
          },
          type,
          allowReplace,
        });
      });
    },
    [popups, performanceActions, customerActions, cipEvents, handleError]
  );
};
