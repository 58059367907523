import React, { useLayoutEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { createPopper } from '@popperjs/core';

import { useDOMElement } from 'src/lib/domUtils';
import { DEFAULT_TOOLTIP_MARGIN } from '../../const';
import { expandPadding } from '../../utils';
import { TooltipFactory } from '../TooltipFactory';
import Backdrop from '../Backdrop';
import Beacon from '../Beacon';
import useStyles from './useStyles';

const getTooltipSkidding = (options, placement) => {
  const tooltipSkidding = get(options, 'tooltip.skidding') || 0;

  const mainSpotlightPadding = get(options, 'spotlights[0].padding');
  const paddings = expandPadding(mainSpotlightPadding);
  if (placement.endsWith('start')) {
    return -1 * paddings.left + tooltipSkidding;
  }
  if (placement.endsWith('end')) {
    return paddings.right + tooltipSkidding;
  }
  return tooltipSkidding;
};

const getTooltipDistance = (options, placement) => {
  const tooltipDistance = get(options, 'tooltip.distance') || 0;

  const mainSpotlightPadding = get(options, 'spotlights[0].padding');
  const paddings = expandPadding(mainSpotlightPadding);
  const margin = get(options, 'tooltipMargin', DEFAULT_TOOLTIP_MARGIN);
  if (placement.startsWith('top')) {
    return paddings.top + margin + tooltipDistance;
  }
  if (placement.startsWith('bottom')) {
    return paddings.bottom + margin + tooltipDistance;
  }
  if (placement.startsWith('left')) {
    return paddings.left + margin + tooltipDistance;
  }
  return paddings.right + margin + tooltipDistance;
};

export const Runner = ({
  rootElement,
  step,
  onPause,
  onContinue,
  onNextStep,
  onSkipTour,
  isLastStep,
  stepsCount,
  currentStepIndex,
  dontShowAgain,
  onDontShowAgainChange,
}) => {
  const tooltipRef = useRef(null);

  // Step options
  const options = useMemo(() => (step && step.options) || {}, [step]);
  const backdropVisible = get(options, 'showOverlay', false);

  const styles = useStyles({ zIndex: options.zIndex });

  // Calculate step "counter"
  const counter = useMemo(() => {
    const stepCounter = get(step, 'content.counter');
    if (stepCounter !== undefined) {
      return stepCounter;
    }

    if (currentStepIndex >= 0 && stepsCount > 0) {
      return `${currentStepIndex + 1}/${stepsCount}`;
    }

    return '';
  }, [step, stepsCount, currentStepIndex]);

  const tooltipTargetElement = useDOMElement(rootElement, get(step, 'target'));
  const beaconTargetElement = useDOMElement(rootElement, options.beaconTarget);

  useLayoutEffect(() => {
    if (!step || !tooltipRef.current) {
      return undefined;
    }

    // Focus on target element
    tooltipTargetElement.focus();
    const popperInstance = createPopper(tooltipTargetElement, tooltipRef.current, {
      placement: step.options.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: ({ placement }) => [
              getTooltipSkidding(options, placement),
              getTooltipDistance(options, placement),
            ],
          },
        },
      ],
    });

    const interval = setInterval(() => popperInstance.update(), 100);

    return () => {
      clearInterval(interval);
      popperInstance.destroy();
    };
  }, [step, tooltipTargetElement, options]);

  if (!step) {
    return null;
  }

  return (
    <div
      className={styles.root}
      data-testid="Runner"
    >
      {backdropVisible && (beaconTargetElement || tooltipTargetElement) && (
        <Backdrop
          className={styles.backdrop}
          rootElement={rootElement}
          step={step}
        />
      )}

      {beaconTargetElement && (
        <Beacon
          classes={{ root: styles.beacon }}
          targetElement={beaconTargetElement}
          size={options.beaconSize}
          color={options.beaconColor}
          placement={options.beaconPlacement}
        />
      )}

      {tooltipTargetElement && (
        <TooltipFactory
          className={styles.tooltip}
          ref={tooltipRef}
          step={step}
          onPause={onPause}
          onContinue={onContinue}
          onSkipClick={onSkipTour}
          onNextClick={onNextStep}
          isLastStep={isLastStep}
          counter={counter}
          dontShowAgain={dontShowAgain}
          onDontShowAgainChange={onDontShowAgainChange}
        />
      )}
    </div>
  );
};

Runner.propTypes = {
  rootElement: PropTypes.instanceOf(Element).isRequired,
  step: PropTypes.shape({
    target: PropTypes.string,
    options: PropTypes.shape(),
  }).isRequired,
  onPause: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onSkipTour: PropTypes.func,
  isLastStep: PropTypes.bool,
  stepsCount: PropTypes.number,
  currentStepIndex: PropTypes.number,
  dontShowAgain: PropTypes.bool,
  onDontShowAgainChange: PropTypes.func.isRequired,
};

Runner.defaultProps = {
  onSkipTour: null,
  isLastStep: false,
  stepsCount: 0,
  currentStepIndex: 0,
  dontShowAgain: false,
};
