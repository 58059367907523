import { useCallback } from 'react';

import { usePopups } from '../services';
import { BRAND_VOICE_POPUP } from '../types';

export const useBrandVoicePopup = () => {
  const popups = usePopups();

  return useCallback(
    (brandVoice) => {
      popups.openPopup(BRAND_VOICE_POPUP, {
        onClose: () => {
          popups.closePopup(BRAND_VOICE_POPUP);
        },
        brandVoice,
      });
    },
    [popups]
  );
};
