import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';

import { CustomAlert } from 'src/components/CustomAlert';
import { NOTIFICATION_TIMEOUTS } from 'src/data/notifications';
import { useNotificationActions } from 'src/store';
import useViewState from './useViewState';
import { levelToSeverity } from './utils';
import useStyles from './useStyles';

const ANCHOR_ORIGIN = {
  vertical: 'top',
  horizontal: 'center',
};

const NotificationsService = () => {
  const styles = useStyles();

  const [open, setOpen] = useState(false);
  const [shouldRender, setShouldRender] = useState(false);
  const [current, setCurrent] = useState(null);

  const { notifications, upgradeAvailable } = useViewState();
  const notificationActions = useNotificationActions();

  // Decide on auto-hide timeout
  const autoHideDuration = useMemo(() => {
    if (current) {
      // If notification is 'sticky', then we won't close it automatically
      if (current.options?.sticky) {
        return null;
      }
      if (current.options?.timeout) {
        return current.options?.timeout;
      }
      return 10000;
    }

    return NOTIFICATION_TIMEOUTS.default;
  }, [current]);

  const clickAwayProps = useMemo(
    () => (autoHideDuration == null ? { mouseEvent: false } : null),
    [autoHideDuration]
  );

  // Based on 'notifications' changes set 'current' notification
  useEffect(() => {
    if (notifications.length > 0 && notifications[0] !== current) {
      setCurrent(notifications[0]);
      setShouldRender(true);
    }
  }, [notifications, current]);

  // Based on 'current' open snackbar
  useEffect(() => {
    if (current) {
      setOpen(true);
    }
  }, [current]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleExited = useCallback(() => {
    if (current) {
      notificationActions.hide(current.id);
      setShouldRender(false);
    }
  }, [current, notificationActions]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Snackbar
      className={cx(styles.root, {
        [styles.hasUpgradeAvailable]: upgradeAvailable,
      })}
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={ANCHOR_ORIGIN}
      ClickAwayListenerProps={clickAwayProps}
      TransitionComponent={Fade}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      data-testid="notificationsService"
    >
      {current && (
        <CustomAlert
          className={styles.notification}
          severity={levelToSeverity(current.level)}
          title={current.title}
          description={current.description}
          onClose={handleClose}
          hideCloseButton={current.options?.hideCloseButton}
          hideIcon={current.options?.hideIcon}
        />
      )}
    </Snackbar>
  );
};

export default NotificationsService;
