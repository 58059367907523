import { useEffect } from 'react';

import { replaceSlateEditorText } from './transforms';

export const useMergeForcedTextValue = (editor, forcedTextValue) => {
  useEffect(() => {
    if (forcedTextValue == null || !editor) {
      return;
    }

    replaceSlateEditorText(editor, forcedTextValue);
  }, [forcedTextValue, editor]);
};
