import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IconButton } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';

import { useStyles } from './useStyles';

export const PopupCloseButton = ({ className, dataTestId, ...props }) => {
  const styles = useStyles();

  return (
    <IconButton
      className={cx(styles.root, className)}
      data-testid={`${dataTestId ? dataTestId + '_' : ''}popupCloseButton`}
      {...props}
    >
      <IconClose />
    </IconButton>
  );
};

PopupCloseButton.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
PopupCloseButton.defaultProps = {
  className: null,
  dataTestId: null,
};
