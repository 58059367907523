import React, { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const Performance = forwardRef((props, ref) => {
  return (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        d="M17.7864 4.60937V1.01562H14.1926"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7865 1.01562L13.7535 5.04861C13.524 5.27856 13.2362 5.44168 12.9211 5.52047C12.6059 5.59926 12.2753 5.59074 11.9646 5.49583L7.40451 4.28194C7.10045 4.19975 6.78007 4.19929 6.47577 4.28063C6.17147 4.36196 5.89404 4.5222 5.67153 4.74514L1.01562 9.40104"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.01562 18.9844H18.9844"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.20841 13.793H2.81258C2.65437 13.795 2.50322 13.8588 2.39134 13.9707C2.27946 14.0826 2.21569 14.2337 2.21362 14.3919V18.9839H5.80737V14.3919C5.8053 14.2337 5.74154 14.0826 5.62965 13.9707C5.51777 13.8588 5.36663 13.795 5.20841 13.793Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1979 8.60254H8.80208C8.64387 8.60461 8.49272 8.66838 8.38084 8.78026C8.26896 8.89214 8.20519 9.04329 8.20312 9.2015V18.9845H11.7969V9.2015C11.7948 9.04329 11.731 8.89214 11.6192 8.78026C11.5073 8.66838 11.3561 8.60461 11.1979 8.60254Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1874 11.3975H14.7916C14.6334 11.3995 14.4822 11.4633 14.3703 11.5752C14.2585 11.6871 14.1947 11.8382 14.1926 11.9964V18.9843H17.7864V11.9964C17.7843 11.8382 17.7205 11.6871 17.6087 11.5752C17.4968 11.4633 17.3456 11.3995 17.1874 11.3975Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
});
