import { GENERATION_TOOL } from 'src/data/generationTool';

import { INFINITY_HOST_TYPE } from './const';

const DEFAULT_TOOL_RULES = {
  [INFINITY_HOST_TYPE.facebook]: GENERATION_TOOL.facebookPost,
  [INFINITY_HOST_TYPE.metaAds]: ({ text, allowMixed }) => {
    if (allowMixed) {
      return GENERATION_TOOL.facebookMixtureAd;
    }

    return isLongText(text)
      ? GENERATION_TOOL.facebookPrimaryTextProduct
      : GENERATION_TOOL.facebookHeadlineProduct;
  },
  [INFINITY_HOST_TYPE.instagram]: GENERATION_TOOL.instagramCaption,
  [INFINITY_HOST_TYPE.twitter]: GENERATION_TOOL.tweet,
  [INFINITY_HOST_TYPE.twitterAds]: ({ text, allowMixed }) => {
    if (allowMixed) {
      return GENERATION_TOOL.twitterMixtureAd;
    }

    return isLongText(text)
      ? GENERATION_TOOL.twitterCopyProduct
      : GENERATION_TOOL.twitterTitleProduct;
  },
  [INFINITY_HOST_TYPE.gmail]: ({ text, allowMixed }) => {
    if (allowMixed) {
      return GENERATION_TOOL.emailMixture;
    }

    return isLongText(text) ? GENERATION_TOOL.emailColdOutreachBody : GENERATION_TOOL.emailSubject;
  },
  [INFINITY_HOST_TYPE.youtube]: ({ text, allowMixed }) => {
    return isLongText(text) || allowMixed
      ? GENERATION_TOOL.youtubeDescription
      : GENERATION_TOOL.youtubeTitle;
  },
  [INFINITY_HOST_TYPE.linkedIn]: GENERATION_TOOL.linkedinPost,
  [INFINITY_HOST_TYPE.linkedInAds]: ({ text, allowMixed }) => {
    if (allowMixed) {
      return GENERATION_TOOL.linkedinMixtureAd;
    }

    return isLongText(text)
      ? GENERATION_TOOL.linkedinIntroSingleImage
      : GENERATION_TOOL.linkedinHeadlineSingleImage;
  },
  [INFINITY_HOST_TYPE.googleAds]: ({ allowMixed }) => {
    return allowMixed ? GENERATION_TOOL.googleRSA : GENERATION_TOOL.googleDescriptionProduct;
  },
};

const isLongText = (text) => {
  return text?.length > 120;
};

export const getInfinityHostDefaultGenerationTool = ({ hostType, text, allowMixed }) => {
  const defaultTool = DEFAULT_TOOL_RULES[hostType];
  if (typeof defaultTool === 'function') {
    return defaultTool({ text, allowMixed });
  }
  return defaultTool;
};

export const infinityHostHasDefaultGenerationTool = (hostType) => {
  return DEFAULT_TOOL_RULES[hostType] !== undefined;
};
