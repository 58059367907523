import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconFemale = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 24 24"
      data-fill-none
    >
      <g transform="translate(0.5, 0)">
        <path
          d="M17.25 18.845V23.125"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.75 18.845V23.125"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.25 17.49V21.125C14.25 21.3902 14.1446 21.6446 13.9571 21.8321C13.7696 22.0196 13.5152 22.125 13.25 22.125H9.75C9.48478 22.125 9.23043 22.0196 9.04289 21.8321C8.85536 21.6446 8.75 21.3902 8.75 21.125V17.49"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.7268 18.145C21.0859 17.9836 21.3671 17.6876 21.5098 17.3207C21.6526 16.9539 21.6454 16.5456 21.4898 16.184L20.1138 12.974C19.8735 12.4134 19.7497 11.8099 19.7498 11.2V9.125C19.7498 6.93696 18.8806 4.83854 17.3334 3.29137C15.7863 1.74419 13.6878 0.875 11.4998 0.875C9.31178 0.875 7.21336 1.74419 5.66618 3.29137C4.119 4.83854 3.24981 6.93696 3.24981 9.125V11.2C3.24981 11.8096 3.12597 12.4128 2.88581 12.973L1.50981 16.184C1.35424 16.5456 1.34704 16.9539 1.48978 17.3207C1.63252 17.6876 1.91376 17.9836 2.27281 18.145"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.9759 23.1251C20.1268 21.3303 18.7854 19.8135 17.1078 18.7513C15.4302 17.6891 13.4855 17.1252 11.4999 17.1252C9.51436 17.1252 7.56963 17.6891 5.89206 18.7513C4.21449 19.8135 2.87307 21.3303 2.02393 23.1251"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 5.125C11.5 5.125 10.066 8.725 6.469 8.87C6.23529 9.65361 6.18785 10.481 6.33047 11.2862C6.47309 12.0914 6.80183 12.8521 7.29048 13.5078C7.77913 14.1634 8.41418 14.6959 9.14502 15.0627C9.87586 15.4295 10.6823 15.6205 11.5 15.6205C12.3177 15.6205 13.1241 15.4295 13.855 15.0627C14.5858 14.6959 15.2209 14.1634 15.7095 13.5078C16.1982 12.8521 16.5269 12.0914 16.6695 11.2862C16.8122 10.481 16.7647 9.65361 16.531 8.87C12.934 8.722 11.5 5.125 11.5 5.125Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  ))
);
