import { replace, trimStart } from 'lodash';

export const TONE_OF_VOICE_TRAIT = {
  formal: 'formal',
  friendly: 'friendly',
  authentic: 'authentic',
  matterOfFact: 'matter-of-fact',
  enthusiastic: 'enthusiastic',
  conversational: 'conversational',
  informative: 'informative',
  confident: 'confident',
  empathetic: 'empathetic',
  humorous: 'humorous',
  witty: 'witty',
  persuasive: 'persuasive',

  respectful: 'respectful',
  creative: 'creative',
};

const TOV_TRAIT_BUT_NOT_MAP = {
  [TONE_OF_VOICE_TRAIT.formal]: ['cold', 'corporate', 'old-fashioned'],
  [TONE_OF_VOICE_TRAIT.friendly]: ['unprofessional', 'overly familiar', 'overly casual'],
  [TONE_OF_VOICE_TRAIT.authentic]: ['unprofessional', 'overly casual', 'disrespectful'],
  [TONE_OF_VOICE_TRAIT.matterOfFact]: ['indifferent', 'monotonous'],
  [TONE_OF_VOICE_TRAIT.enthusiastic]: ['over-the-top', 'too salesy'],
  [TONE_OF_VOICE_TRAIT.conversational]: ['slangy', 'disrespectful'],
  [TONE_OF_VOICE_TRAIT.informative]: ['dry', 'overly technical', 'wordy'],
  [TONE_OF_VOICE_TRAIT.confident]: ['arrogant', 'bossy'],
  [TONE_OF_VOICE_TRAIT.empathetic]: ['dramatic', 'assumptive', 'patronizing'],
  [TONE_OF_VOICE_TRAIT.humorous]: ['inappropriate', 'childish', 'corny'],
  [TONE_OF_VOICE_TRAIT.witty]: ['irreverent', 'sarcastic', 'snarky'],
  [TONE_OF_VOICE_TRAIT.persuasive]: ['over-the-top', 'too salesy'],
  [TONE_OF_VOICE_TRAIT.respectful]: ['hesitant', 'distant', 'overly formal'],
  [TONE_OF_VOICE_TRAIT.creative]: ['quirky', 'abstract', 'exaggerated'],
};

export const normalizeTraitButNot = (traitButNot) => {
  return trimStart(replace(traitButNot, /^(overly|too) /, ''));
};

export const getToneOfVoiceTraitButNots = (trait) => TOV_TRAIT_BUT_NOT_MAP[trait] || [];

export const toneOfVoiceTraitHasButNots = (trait) => getToneOfVoiceTraitButNots(trait).length > 0;

export const TONE_OF_VOICE_TRAIT_MAX_COUNT = 5;
