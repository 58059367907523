import { useCallback } from 'react';

import { useBrandTermsActions, useProjectActions } from 'src/store';
import { useBrandVocabularyPopup } from './useBrandVocabularyPopup';

export const useSetupProjectVocabulary = () => {
  const openBrandTermSetsPopup = useBrandVocabularyPopup();
  const brandTermsActions = useBrandTermsActions();
  const projectActions = useProjectActions();

  return useCallback(() => {
    const brandVoiceIds = brandTermsActions.getBrandVoiceIdsForBrandTerms();

    openBrandTermSetsPopup({
      brandVoiceIds,
      onClose: (selectedBrandVoiceIds) => {
        if (!selectedBrandVoiceIds) {
          return;
        }

        // Update brand terms for displaying in UI
        brandTermsActions.setBrandVoiceIdsForBrandTerms(selectedBrandVoiceIds);

        // Update project data for future sessions
        const currentProject = projectActions.getCurrentProject();
        if (currentProject) {
          projectActions
            .updateProjectBrandVoicesForBrandTerms({
              id: currentProject.id,
              brandVoiceIds: selectedBrandVoiceIds,
            })
            .catch(() => {
              /* we do not actually care, if this will be saved or not  */
            });
        }
      },
    });
  }, [brandTermsActions, openBrandTermSetsPopup, projectActions]);
};
