import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    boxGroupTitle: {},

    histograms: {
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: theme.spacing(2),
    },

    histogramsWrapper: {
      height: 95,
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
    },

    chartAudience: {},
    histogramAge: {
      flex: 1,
    },
    histogramGender: {
      flex: 1,
    },
  }),
  { name: 'Histograms' }
);
