import { createContainer } from 'react-sweet-state';

import { CommonStore, PRIVATE_ACTIONS } from './CommonStore';

export const CommonStoreContainer = createContainer(CommonStore, {
  onInit:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState, dispatch }, { currentUserConfirmed, state }) => {
      if (currentUserConfirmed) {
        dispatch(PRIVATE_ACTIONS.initContentTaxonomies());
      } else {
        dispatch(PRIVATE_ACTIONS.clearContentTaxonomies());
      }

      if (state) {
        setState(state);
      }
    },
});
