import { useMemo } from 'react';
import { createStateSelectorHook } from 'src/lib/sweetState';
import { BrandVoicesStore } from './BrandVoicesStore';

const useBrandVoicesState = createStateSelectorHook(BrandVoicesStore, [
  'brandVoicesAssets',
  'loadingBrandVoiceAssets',
]);

export const useBrandVoicesAssets = () => {
  const { brandVoicesAssets, loadingBrandVoiceAssets } = useBrandVoicesState();

  return useMemo(() => {
    const toneOfVoices = brandVoicesAssets.flatMap((brandVoice) =>
      brandVoice.toneOfVoices?.map((toneOfVoice) => ({
        brandVoiceId: brandVoice.id,
        brandVoiceName: brandVoice.name,
        ...toneOfVoice,
      }))
    );

    const customModels = brandVoicesAssets.flatMap((brandVoice) =>
      brandVoice.customModels?.map((customModel) => ({
        brandVoiceId: brandVoice.id,
        brandVoiceName: brandVoice.name,
        ...customModel,
      }))
    );

    const customFormulas = brandVoicesAssets.flatMap((brandVoice) =>
      brandVoice.customFormulas?.map((customFormula) => ({
        brandVoiceId: brandVoice.id,
        brandVoiceName: brandVoice.name,
        ...customFormula,
      }))
    );

    const targetAudiences = brandVoicesAssets.flatMap((brandVoice) =>
      brandVoice.targetAudiences?.map((targetAudience) => ({
        brandVoiceId: brandVoice.id,
        brandVoiceName: brandVoice.name,
        ...targetAudience,
      }))
    );

    const brandBriefs = brandVoicesAssets.flatMap((brandVoice) =>
      brandVoice.brandBriefs?.map((brandBrief) => ({
        brandVoiceId: brandVoice.id,
        brandVoiceName: brandVoice.name,
        ...brandBrief,
      }))
    );

    const brandTerms = brandVoicesAssets.flatMap((brandVoice) =>
      brandVoice.brandTerms?.map((brandTerm) => ({
        brandVoiceId: brandVoice.id,
        brandVoiceName: brandVoice.name,
        ...brandTerm,
      }))
    );

    return {
      customModels,
      toneOfVoices,
      customFormulas,
      targetAudiences,
      brandBriefs,
      brandVoicesAssets,
      loadingBrandVoiceAssets,
      brandTerms,
    };
  }, [brandVoicesAssets, loadingBrandVoiceAssets]);
};
