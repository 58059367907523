import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const Close = React.memo(
  React.forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 13 13"
    >
      <path
        d="M0.439504 0.566299C-0.146282 1.15209 -0.146281 2.10183 0.439505 2.68762L4.24991 6.49803L0.439472 10.3062C-0.14649 10.8918 -0.146491 11.8412 0.439472 12.4269L0.56647 12.5538C1.15243 13.1394 2.10246 13.1394 2.68843 12.5538L6.49819 8.74631L10.309 12.5571C10.8948 13.1429 11.8445 13.1429 12.4303 12.5571L12.5573 12.4302C13.1431 11.8444 13.1431 10.8946 12.5573 10.3088L8.74715 6.4987L12.5609 2.68724C13.1469 2.10163 13.1469 1.15217 12.5609 0.566556L12.4339 0.439635C11.8479 -0.145975 10.8979 -0.145975 10.3119 0.439635L6.49887 4.25042L2.68778 0.43934C2.102 -0.146446 1.15225 -0.146447 0.566464 0.43934L0.439504 0.566299Z"
        fill={props.color || '#8E969D'} // TODO: replace with currentColor
      />
    </SvgIcon>
  ))
);

export const IconClose = Close;
export default Close;
