import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import { sortBrandBriefs, brandBriefTypeHasTitle } from 'src/data/brief';
import {
  queryBrandBriefs,
  createBrandBrief,
  updateBrandBrief,
  deleteBrandBrief,
} from 'src/graphql/brief';

const INITIAL_STATE = {
  brandBriefs: [],
  loadingBrandBriefs: false, // start with "loading" state
  savingBrandBrief: false,
  deletingBrandBrief: false,
  extractingBrandBrief: false,

  abortController: null,
  errorBriefGeneral: null,
  errorBriefGeneralCode: null,
};

const PRIVATE_ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  mergeBrandBriefInCache:
    (item) =>
    ({ getState, setState }) => {
      const { brandBriefs: list } = getState();
      const itemExists = list.some((t) => t.id === item.id);
      const newList = itemExists ? list.map((t) => (t.id === item.id ? item : t)) : [...list, item];
      setState({ brandBriefs: sortBrandBriefs(newList) });
    },

  removeBrandBriefFromCache:
    (itemId) =>
    ({ getState, setState }) => {
      const { brandBriefs } = getState();
      const filteredList = brandBriefs.filter((t) => t.id !== itemId);
      setState({ brandBriefs: filteredList });
    },
};

/**
 * Public actions
 */
const ACTIONS = {
  init:
    () =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.clearStore());
    },

  queryBrandBriefs:
    (brandVoiceId) =>
    async ({ setState }) => {
      setState({ loadingBrandBriefs: true });
      try {
        const result = await queryBrandBriefs(brandVoiceId);
        setState({ brandBriefs: sortBrandBriefs(result) });
      } finally {
        setState({ loadingBrandBriefs: false });
      }
    },

  createBrandBrief:
    (item, brandVoiceId) =>
    async ({ setState, dispatch }) => {
      setState({ savingBrandBrief: true });
      try {
        const createdBrandBrief = await createBrandBrief({
          brandVoiceId,
          ...item,
        });
        dispatch(PRIVATE_ACTIONS.mergeBrandBriefInCache(createdBrandBrief));
        return createdBrandBrief;
      } finally {
        setState({ savingBrandBrief: false });
      }
    },

  updateBrandBrief:
    (item) =>
    async ({ setState, dispatch }) => {
      setState({ savingBrandBrief: true });

      if (!brandBriefTypeHasTitle(item.briefType)) {
        // if brand brief type was updated from the one that has title
        item.title = '';
      }
      try {
        const updatedBrandBrief = await updateBrandBrief(item);
        dispatch(PRIVATE_ACTIONS.mergeBrandBriefInCache(updatedBrandBrief));
        return updatedBrandBrief;
      } finally {
        setState({ savingBrandBrief: false });
      }
    },

  deleteBrandBrief:
    (id) =>
    async ({ setState, dispatch }) => {
      setState({ deletingBrandBrief: true });
      try {
        await deleteBrandBrief(id);
        dispatch(PRIVATE_ACTIONS.removeBrandBriefFromCache(id));
      } finally {
        setState({ deletingBrandBrief: false });
      }
    },

  isSavingBrandBrief:
    () =>
    ({ getState }) => {
      return getState().savingBrandBrief;
    },
};

export const BriefsStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'briefs-store',
});
