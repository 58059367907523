import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    tooltipContent: {
      display: 'flex',
      flexDirection: 'column',
      rowsGap: theme.spacing(0.5),
    },
    button: {
      minWidth: 0,
      height: 'auto',
      zIndex: 41,
      padding: 0,
    },
    avatar: {
      width: 32,
      height: 32,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(24),
    },
  }),
  { name: 'ProfileButton' }
);
