import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { CUSTOMER_PERSONA_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation ExtractBriefPersonas(
    $brief: BriefInput!
    $projectId: ID!
    $brandVoiceId: ID
    $promptImageId: ID
    $promptDocumentId: ID
    $freestyleToolAllInstructionsData: JSON
    $urlExtractions: [UrlExtractionInput!]
  ) {
    extractBriefPersonas(
      brief: $brief
      projectId: $projectId
      brandVoiceId: $brandVoiceId
      promptImageId: $promptImageId
      promptDocumentId: $promptDocumentId
      freestyleToolAllInstructionsData: $freestyleToolAllInstructionsData
      urlExtractions: $urlExtractions
    ) {
      edges {
        node {
          ...CustomerPersona
        }
      }
    }
  }
  ${CUSTOMER_PERSONA_FRAGMENT}
`;

export const extractBriefPersonas = async (
  {
    brief,
    projectId,
    brandVoiceId,
    promptImageId,
    promptDocumentId,
    freestyleToolAllInstructionsData,
    urlExtractions,
  },
  options = null
) => {
  const result = await executeGqlMutation({
    mutation: MUTATION,
    variables: {
      brief,
      projectId,
      brandVoiceId,
      promptImageId,
      promptDocumentId,
      freestyleToolAllInstructionsData: freestyleToolAllInstructionsData
        ? JSON.stringify(freestyleToolAllInstructionsData)
        : null,
      urlExtractions,
    },
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  return getSingleProp(result.data) || [];
};
