import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_BRAND_VOICE } from 'src/graphql/brandVoices';

const QUERY = gql`
  query WorkspaceAssociatedBrandVoices($customerId: ID!, $id: ID) {
    workspaces(customerId: $customerId, id: $id) {
      edges {
        node {
          associatedBrandVoices {
            ...BrandVoice
          }
          defaultBrandVoice {
            ...BrandVoice
          }
        }
      }
    }
  }
  ${FRAGMENT_BRAND_VOICE}
`;

export const queryWorkspaceAssociatedBrandVoices = async ({ customerId, id }, options) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: { customerId, id },
    options: {
      abortSignal: options?.abortSignal,
    },
  });

  const data = getSingleProp(result.data);
  return data?.edges[0]?.node;
};
