import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';

import { CustomRadio } from 'src/components/CustomRadio';
import { CustomRadioGroup } from 'src/components/CustomRadioGroup';
import { CustomFormControlLabel } from 'src/components/CustomFormControlLabel';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { usePopups, CUSTOM_DIALOG } from 'src/services/popup';
import { WORKSPACE_ACCESS_LEVEL, getWorkspaceAccessLevelLabel } from 'src/data/workspace';

export const usePromptWorkspaceAccessLevel = () => {
  const styles = useStyles();
  const popups = usePopups();

  return useCallback(
    (account) => {
      return new Promise((resolve) => {
        let workspaceAccessLevel = WORKSPACE_ACCESS_LEVEL.allWorkspaces;

        const handleSubmit = () => {
          popups.closePopup(CUSTOM_DIALOG);
          resolve(workspaceAccessLevel);
        };

        const handleCancel = () => {
          popups.closePopup(CUSTOM_DIALOG);
          resolve(null);
        };

        popups.openPopup(CUSTOM_DIALOG, {
          title: 'Set workspace access level for this account',
          cancelLabel: 'Back',
          submitLabel: 'Continue',
          children: (
            <DialogContent className={styles.root}>
              <CustomTypography
                className={styles.label}
                variant={TYPOGRAPHY_VARIANT.medium14}
              >
                Enable access to {account?.name} in:
              </CustomTypography>

              <CustomRadioGroup
                className={styles.radioGroup}
                onChange={(event) => (workspaceAccessLevel = event.target.value)}
                defaultValue={workspaceAccessLevel}
              >
                {Object.values(WORKSPACE_ACCESS_LEVEL).map((level) => (
                  <CustomFormControlLabel
                    className={styles.formControlLabel}
                    classes={{
                      label: styles.formControlLabel_label,
                    }}
                    key={level}
                    value={level}
                    control={<CustomRadio color="primary" />}
                    label={getWorkspaceAccessLevelLabel(level)}
                  />
                ))}
              </CustomRadioGroup>
            </DialogContent>
          ),
          onSubmit: handleSubmit,
          onCancel: handleCancel,
          onClose: handleCancel,
        });
      });
    },
    [styles, popups]
  );
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 560,
      padding: theme.spacing(2, 4),
    },
    label: {},
    radioGroup: {
      marginTop: theme.spacing(1),
    },
    formControlLabel: {},
    formControlLabel_label: {
      ...theme.typography.regular14,
    },
  }),
  { name: 'PromptWorkspaceLevel' }
);
