import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { NavigationSidebarPanel } from 'src/views/NavigationSidebarPanel';
import { GeneralNotifications } from 'src/views/NotificationsCenter';
import { useScrollToTopOnLocationChange } from 'src/lib/hooks';
import { useCurrentCustomerId, useProjectActions } from 'src/store';
import { WebinarBanner } from 'src/views/WebinarBanner';
import { useStyles } from './useStyles';

export const PrivateRouteWrapper = ({
  classes,
  children,
  displayNavigation,
  displayNotifications,
  onlyImportantNotifications,
  floatingNotifications,
  hideOverflow,
  showWebinarBanner,
}) => {
  const styles = useStyles({ classes });
  const scrollContainerRef = useRef();
  const currentCustomerId = useCurrentCustomerId();
  const projectActions = useProjectActions();

  useScrollToTopOnLocationChange(scrollContainerRef);

  // When we select another customer - clear state of current one
  useEffect(() => {
    const oldCustomerId = currentCustomerId;
    return () => {
      if (oldCustomerId) {
        projectActions.clearStore();
      }
    };
  }, [currentCustomerId, projectActions]);

  return (
    <div className={styles.root}>
      {displayNavigation && showWebinarBanner && <WebinarBanner className={styles.webinarBanner} />}
      {displayNavigation && (
        <NavigationSidebarPanel className={showWebinarBanner ? styles.extraMarginTop : null} />
      )}

      <div
        className={cx(styles.content, {
          [styles.overflowHidden]: hideOverflow,
          [styles.extraMarginTop]: displayNavigation && showWebinarBanner,
        })}
        ref={scrollContainerRef}
      >
        {displayNotifications && (
          <GeneralNotifications
            onlyImportant={onlyImportantNotifications}
            floating={floatingNotifications}
          />
        )}
        {children}
      </div>
    </div>
  );
};

PrivateRouteWrapper.propTypes = {
  classes: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  displayNavigation: PropTypes.bool,
  displayNotifications: PropTypes.bool,
  onlyImportantNotifications: PropTypes.bool,
  floatingNotifications: PropTypes.bool,
  hideOverflow: PropTypes.bool,
};

PrivateRouteWrapper.defaultProps = {
  classes: null,
  displayNavigation: false,
  displayNotifications: false,
  onlyImportantNotifications: false,
  floatingNotifications: false,
  hideOverflow: false,
};
