import { useCallback, useRef } from 'react';
import { noop } from 'lodash';

import { NOTIFICATION_LEVEL } from 'src/data/notifications';
import {
  getResourceDocumentErrorMessage,
  RESOURCE_CONTENT_TYPE,
  RESOURCE_FILE_UPLOAD_ERROR_CODE,
} from 'src/data/performance';
import { parseError } from 'src/lib/common';
import { useNotificationActions, usePerformanceActions } from 'src/store';

const DOCUMENT_TYPE = RESOURCE_CONTENT_TYPE.document;

/**
 * Result callback
 * @callback ResultCallback
 * @param {{ resourceId: String, file: File }} data - Resource id or file
 */

/**
 * Error callback
 * @callback ErrorCallback
 * @param {string|null} error - Error message
 */

/**
 * This callback is displayed as part of the Requester class.
 * @callback UploadingChangeCallback
 * @param {boolean} uploading - Error message
 */

/**
 * Process the selected file and check if we already have a resource for the same file
 *
 * @param {ResultCallback} onResult - Callback to be called when the file is processed
 * @param {ErrorCallback} onError - Callback to be called when an error occurs
 * @param {UploadingChangeCallback} onUploadingChange - Callback to be called when the uploading flag changes
 * @returns {{handleSelectFile: ((function(*, *): Promise<void>)|*), uploadingResourceFile: boolean}}
 */
export const useSelectResourceFileHandler = ({ onResult, onError, onUploadingChange }) => {
  const notificationActions = useNotificationActions();
  const performanceActions = usePerformanceActions();

  // Maintain references to callbacks
  const refOnResult = useRef(onResult);
  refOnResult.current = onResult || noop;
  const refOnError = useRef(onError);
  refOnError.current = onError || noop;
  const refChangeUploadingFlag = useRef(onUploadingChange);
  refChangeUploadingFlag.current = onUploadingChange || noop;

  return useCallback(
    async (acceptedFiles, fileRejections) => {
      // Display error if we have any rejected file
      const rejectedFileErrorCode = fileRejections?.[0]?.errors?.[0]?.code;
      if (rejectedFileErrorCode) {
        const error = getResourceDocumentErrorMessage(rejectedFileErrorCode, { object: true });
        notificationActions.displayNotification({ level: NOTIFICATION_LEVEL.error, ...error });
        return;
      }

      // Do not proceed if user did not select any file
      const acceptedFile = acceptedFiles?.[0];
      if (!acceptedFile) {
        return;
      }

      refChangeUploadingFlag.current(true);
      try {
        // Check if we already have a resource for the same file
        const existenceResult = await performanceActions.validateResourcesExistence({
          file: acceptedFile,
          type: DOCUMENT_TYPE,
        });

        // If we don't have related resource on BE, we can proceed with the file
        if (!existenceResult?.[0]?.exist) {
          refOnResult.current({ file: acceptedFile });
          return;
        }

        // Check if the resource is associated with the current workspace (loaded on FE)
        const documentResource = performanceActions.getDocumentResource(existenceResult[0].id);
        if (!documentResource) {
          const error = getResourceDocumentErrorMessage(
            RESOURCE_FILE_UPLOAD_ERROR_CODE.fileExistsButNotAssociatedToWorkspace,
            { object: true }
          );
          notificationActions.displayNotification({ level: NOTIFICATION_LEVEL.error, ...error });
          return;
        }

        // Proceed with existing resource id
        refOnResult.current({ resourceId: documentResource.id });
      } catch (error) {
        const { title } = parseError(error);
        refOnError.current(title);
      } finally {
        refChangeUploadingFlag.current(false);
      }
    },
    [notificationActions, performanceActions]
  );
};
