import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},

    checkmark: {
      color: COLORS.success.default,
      lineHeight: theme.typography.pxToRem(14),
      fontSize: theme.typography.pxToRem(20),
    },

    notFound: {
      lineHeight: theme.typography.pxToRem(14),
      marginRight: 'auto',
      fontSize: theme.typography.pxToRem(13),
    },

    checkAgain: {},
    checkAgainIcon: {},
    disabled: {},
  }),
  { name: 'PlagiarismNotFound' }
);
