import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ArrowBackChevron = (props) => (
  <SvgIcon {...props} viewBox="0 0 7 12" data-fill-none>
    <path
      d="M6 11L1 6L6 1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
