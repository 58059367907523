import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { CUSTOMER_MEMBER_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation UpdateCustomerMemberRole($customerId: ID!, $email: String!, $role: UserRoleEnum!) {
    updateCustomerMemberRole(customerId: $customerId, email: $email, role: $role) {
      ...CustomerMember
    }
  }
  ${CUSTOMER_MEMBER_FRAGMENT}
`;

export const updateCustomerMemberRole = ({ customerId, email, role }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, email, role },
  }).then(({ data }) => getSingleProp(data));
