import gql from 'graphql-tag';

import { BRIEF_DEBUG_DATA_VISIBLE } from 'src/lib/featureFlags';

const BRIEF_BASIC_FIELDS = `
  id
  type
  status
  errors
`;

const BRIEF_COMMON_FIELDS = `
  ${BRIEF_BASIC_FIELDS}
  name
  language
  ${BRIEF_DEBUG_DATA_VISIBLE ? 'debugData' : ''}
`;

const EXTRACTED_BRIEF_FIELDS = `
  computedTitle
  computedArticle
  acTitleAiProvided
  acArticleAiProvided
`;

export const BRIEF_FROM_LIST_FRAGMENT = gql`
  fragment BriefFromList on Brief {
    ... on UnifiedProductBrief {
      ${BRIEF_BASIC_FIELDS}
    }

    ... on UnifiedContentBrief {
      ${BRIEF_BASIC_FIELDS}
    }
    
    ... on EmailBrief {
      ${BRIEF_BASIC_FIELDS}
    }

    ... on UnifiedPromotionalEmailBrief {
      ${BRIEF_BASIC_FIELDS}
    }
    
    ... on BlogPostBrief {
      ${BRIEF_BASIC_FIELDS}
    }
    
    ... on BlogPostBuilderBrief {
      ${BRIEF_BASIC_FIELDS}
    }
    
    ... on SocialManualBrief {
      ${BRIEF_BASIC_FIELDS}
    }
   
    
    ... on CanvasBrief {
      ${BRIEF_BASIC_FIELDS}
    }
  }
`;

export const UNIFIED_PRODUCT_BRIEF = gql`
  fragment UnifiedProductBrief on UnifiedProductBrief {
    ${BRIEF_COMMON_FIELDS}
    url
    ${EXTRACTED_BRIEF_FIELDS}
  }
`;

export const UNIFIED_CONTENT_BRIEF = gql`
  fragment UnifiedContentBrief on UnifiedContentBrief {
    ${BRIEF_COMMON_FIELDS}
    url
    ${EXTRACTED_BRIEF_FIELDS}
  }
`;

export const EMAIL_BRIEF_FRAGMENT = gql`
  fragment EmailBrief on EmailBrief {
    ${BRIEF_COMMON_FIELDS}
    body
  }
`;

export const FRAGMENT_UNIFIED_PROMOTIONAL_EMAIL_BRIEF = gql`
  fragment UnifiedPromotionalEmailBrief on UnifiedPromotionalEmailBrief {
    ${BRIEF_COMMON_FIELDS}
    url
    ${EXTRACTED_BRIEF_FIELDS}
    emailPromotion
  }
`;

export const FRAGMENT_BLOG_POST_BRIEF = gql`
  fragment BlogPostBrief on BlogPostBrief {
    ${BRIEF_COMMON_FIELDS}
    title
    body
  }
`;

export const FRAGMENT_BLOG_POST_BUILDER_BRIEF = gql`
  fragment BlogPostBuilderBrief on BlogPostBuilderBrief {
    ${BRIEF_COMMON_FIELDS}
    description
    keywords
    targetKeywords
    supportingKeywords
    url
    brandVoice {
      id
      name
    }
    tov
    blogLanguage
    promptImageId
    promptDocumentId
    freestyleToolAllInstructionsData
  }
`;

export const FRAGMENT_SHOPIFY_PRODUCT_BRIEF = gql`
  fragment ShopifyProductBrief on ShopifyProductBrief {
    ${BRIEF_COMMON_FIELDS}
    body
    lastModifiedAt
    productName
    productImageUrl
    productStatus
  }
`;

export const FRAGMENT_SOCIAL_MANUAL_BRIEF = gql`
  fragment SocialManualBrief on SocialManualBrief {
    ${BRIEF_COMMON_FIELDS}
    description
  }
`;

export const CANVAS_BRIEF_FRAGMENT = gql`
  fragment CanvasBrief on CanvasBrief {
    ${BRIEF_COMMON_FIELDS}
    freeText
    ${EXTRACTED_BRIEF_FIELDS}
  }
`;

export const BRIEF_FRAGMENT = gql`
  fragment Brief on Brief {
    ... on UnifiedProductBrief {
      ...UnifiedProductBrief
    }

    ... on UnifiedContentBrief {
      ...UnifiedContentBrief
    }

    ... on EmailBrief {
      ...EmailBrief
    }

    ... on UnifiedPromotionalEmailBrief {
      ...UnifiedPromotionalEmailBrief
    }

    ... on BlogPostBrief {
      ...BlogPostBrief
    }

    ... on BlogPostBuilderBrief {
      ...BlogPostBuilderBrief
    }

    ... on ShopifyProductBrief {
      ...ShopifyProductBrief
    }

    ... on SocialManualBrief {
      ...SocialManualBrief
    }

    ... on CanvasBrief {
      ...CanvasBrief
    }
  }
  ${UNIFIED_PRODUCT_BRIEF}
  ${UNIFIED_CONTENT_BRIEF}
  ${EMAIL_BRIEF_FRAGMENT}
  ${FRAGMENT_UNIFIED_PROMOTIONAL_EMAIL_BRIEF}
  ${FRAGMENT_BLOG_POST_BRIEF}
  ${FRAGMENT_BLOG_POST_BUILDER_BRIEF}
  ${FRAGMENT_SHOPIFY_PRODUCT_BRIEF}
  ${FRAGMENT_SOCIAL_MANUAL_BRIEF}
  ${CANVAS_BRIEF_FRAGMENT}
`;

export const FRAGMENT_BRAND_BRIEF = gql`
  fragment BrandBrief on BrandBrief {
    id
    name
    title
    briefType
    body
    lastModifyingUser {
      name
    }
    updatedAt
    brandVoice {
      id
      name
    }
  }
`;
