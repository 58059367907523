import { useCallback } from 'react';

import { useCustomerActions, useCustomerPersonaActions, useNotificationActions } from 'src/store';
import { CONFIRM_POPUP, usePopups } from 'src/services/popup';
import { CONFIRM_POPUP_MODE } from 'src/components/ConfirmPopup';
import { useErrorHandler } from 'src/services/useErrorHandler';
import { useTrackingEvent, PAGES, SUB_PAGES } from 'src/services/useTrackingEvent';
import { SUGGESTED_AUDIENCES } from 'src/lib/events';

export const useDeleteCustomerPersona = () => {
  const popups = usePopups();
  const customerActions = useCustomerActions();
  const customerPersonaStoreActions = useCustomerPersonaActions();
  const notificationActions = useNotificationActions();
  const handleError = useErrorHandler();
  const trackingEvent = useTrackingEvent();

  return useCallback(
    (customerPersona) =>
      new Promise((resolve) => {
        const onConfirm = () => {
          popups.closePopup(CONFIRM_POPUP);

          customerPersonaStoreActions
            .deleteCustomerPersona(customerPersona.id)
            .then(() => {
              notificationActions.displaySuccess(
                `Target audience '${customerPersona.name}' deleted`
              );
              if (customerPersona.isSuggested) {
                trackingEvent(SUGGESTED_AUDIENCES.deleted, {
                  page: PAGES.editor,
                  subPage: SUB_PAGES.generationPanel,
                });
              }
              resolve(true);
              customerActions.refreshCurrentCustomerFeatureLimit();
            })
            .catch((error) => {
              handleError(error);
              resolve(false);
            });
        };

        const onCancel = () => {
          popups.closePopup(CONFIRM_POPUP);
          resolve(false);
        };

        //TODO - align this popup with all other brand voice popups
        popups.openPopup(CONFIRM_POPUP, {
          title: `Delete this target audience?`,
          content: 'This action cannot be undone.',
          confirm: 'Delete',
          cancel: 'Cancel',
          mode: CONFIRM_POPUP_MODE.warning,
          onConfirm,
          onCancel,
        });
      }),
    [
      popups,
      customerPersonaStoreActions,
      notificationActions,
      customerActions,
      handleError,
      trackingEvent,
    ]
  );
};
