import { useState } from 'react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';
import {
  withLists,
  withParagraphs,
  withHtml,
  withDividers,
  withNormalizedPlaceholder,
  withNewLine,
  withVariationBlocks,
  withInlines,
  withPreviousSelection,
} from './plugins';

import { applyPlugins } from './utils';

const PLUGINS = [
  withReact,
  withHistory,
  withLists,
  withParagraphs,
  withHtml,
  withDividers,
  withNormalizedPlaceholder,
  withNewLine,
  withVariationBlocks,
  withInlines,
  withPreviousSelection,
];

export const useRichSlateEditor = () => {
  const [editor] = useState(() => applyPlugins(createEditor(), PLUGINS));
  return editor;
};
