import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CircularProgress, FormControl, Select } from '@material-ui/core';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import { ErrorMessage } from 'src/components/ErrorMessage';
import { FieldHeader } from 'src/components/FieldHeader';
import { Information } from 'src/components/Information';
import { CustomInput } from 'src/components/CustomInput';
import { useStyles } from './useStyles';

const DEFAULT_MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const FieldSelect = ({
  className,
  classes,
  label,
  labelTooltip,
  error,
  value,
  onChange,
  subtitle,
  hint,
  isHintQuestion,
  isOptional,
  placeholder,
  renderValue,
  headerClasses,
  loading,
  LabelProps,
  MenuProps,
  InputProps,
  dataTestId,
  ...restProps
}) => {
  const styles = useStyles({ classes });

  return (
    <FormControl data-testid={dataTestId || "fieldSelect"} className={cx(styles.root, className)}>
      {label && (
        <div className={styles.labelWrapper}>
          <FieldHeader
            label={label}
            subtitle={subtitle}
            hint={hint}
            isHintQuestion={isHintQuestion}
            isOptional={isOptional}
            classes={headerClasses}
            LabelProps={LabelProps}
          />
          {labelTooltip && (
            <Information
              content={labelTooltip}
              className={styles.labelTooltip}
              classes={{ content: styles.labelTooltipContent }}
            />
          )}
        </div>
      )}
      <Select
        classes={{
          icon: loading ? styles.loadingSelectIcon : styles.selectIcon,
          select: styles.select,
        }}
        value={value}
        onChange={onChange}
        error={error}
        variant="outlined"
        IconComponent={loading ? CircularProgress : IconExpandMore}
        MenuProps={{
          ...DEFAULT_MENU_PROPS,
          ...MenuProps,
          classes: { paper: styles.menuPaper },
        }}
        displayEmpty={!!placeholder}
        renderValue={(valueToRender) => {
          const valueExists = Array.isArray(valueToRender)
            ? valueToRender.length
            : valueToRender != null;

          return (
            <div className={styles.valueContainer}>
              {valueExists ? (
                renderValue ? (
                  renderValue(valueToRender)
                ) : (
                  valueToRender
                )
              ) : (
                <span className={styles.placeholder}>{placeholder}</span>
              )}
            </div>
          );
        }}
        input={
          <CustomInput
            label={label}
            notched={false}
            {...InputProps}
            classes={{
              notchedOutline: styles.notchedOutline,
            }}
          />
        }
        {...restProps}
      />
      <ErrorMessage error={error} />
    </FormControl>
  );
};

FieldSelect.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  error: PropTypes.string,
  hint: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelTooltip: PropTypes.string,
  onChange: PropTypes.func,
  subtitle: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isHintQuestion: PropTypes.bool,
  isOptional: PropTypes.bool,
  renderValue: PropTypes.func,
  headerClasses: PropTypes.shape({}),
  LabelProps: PropTypes.shape({}),
  MenuProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  loading: PropTypes.bool,
  dataTestId: PropTypes.string,
};

FieldSelect.defaultProps = {
  classes: null,
  className: null,
  error: null,
  hint: null,
  label: '',
  labelTooltip: '',
  onChange: null,
  subtitle: '',
  value: undefined,
  placeholder: undefined,
  isHintQuestion: false,
  isOptional: false,
  renderValue: undefined,
  headerClasses: null,
  LabelProps: null,
  MenuProps: null,
  InputProps: null,
  loading: false,
  dataTestId: null,
};
