import { useCallback } from 'react';

import { EDIT_SUGGESTED_CUSTOMER_PERSONA_POPUP, usePopups } from 'src/services/popup';

export const useEditSuggestedCustomerPersonaPopup = () => {
  const popups = usePopups();

  return useCallback(
    (customerPersona = null, onSubmit = null, brandVoice) => {
      popups.openPopup(EDIT_SUGGESTED_CUSTOMER_PERSONA_POPUP, {
        customerPersona,
        brandVoice,
        onClose: () => {
          popups.closePopup(EDIT_SUGGESTED_CUSTOMER_PERSONA_POPUP);
        },
        onSubmit: async (data) => {
          await onSubmit(data);
          popups.closePopup(EDIT_SUGGESTED_CUSTOMER_PERSONA_POPUP);
        },
      });
    },
    [popups]
  );
};
