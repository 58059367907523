import { useCallback, useEffect } from 'react';
import pubsub, { PUBSUB } from 'src/lib/pubsub';

export const useListenToGenerationPanelMenuOpen = (menuType, onClose) => {
  const handleGenerationPanelMenuOpen = useCallback(
    (_, { openedMenuType }) => {
      if (openedMenuType !== menuType) {
        onClose();
      }
    },
    [menuType, onClose]
  );

  useEffect(() => {
    const token = pubsub.subscribe(
      PUBSUB.OPEN_GENERATION_PANEL_MENU,
      handleGenerationPanelMenuOpen
    );

    return () => pubsub.unsubscribe(token);
  }, [handleGenerationPanelMenuOpen]);
};
