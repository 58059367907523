import { useEffect } from 'react';

import { useCurrentCustomer } from 'src/store';
import { customerSubscriptionPaused } from 'src/data/customer';
import { useChurnkey } from 'src/services/churnkey';

export const CustomerSubscriptionPausedGuard = () => {
  const customer = useCurrentCustomer();
  const { checkPause, hide } = useChurnkey();
  const isCustomerSubscriptionPaused = customerSubscriptionPaused(customer);

  useEffect(() => {
    // hide Churnkey modals if user logged out or account is changed
    hide();

    if (!isCustomerSubscriptionPaused) return;

    checkPause();

    // customer?.id is needed to trigger useEffect on logout or account change
  }, [isCustomerSubscriptionPaused, customer?.id, checkPause, hide]);

  return null;
};
