import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const Loader = ({ classes }) => {
  const styles = useStyles({ classes });

  return (
    <div className={styles.root}>
      <Skeleton
        className={styles.icon}
        variant="circle"
        animation="wave"
      />

      <div className={styles.contentWrapper}>
        <Skeleton
          className={styles.title}
          variant="text"
          animation="wave"
          height={26}
          width={47}
        />

        <div className={styles.annotations}>
          {Array(3)
            .fill()
            .map((_, i) => (
              <Skeleton
                key={`AnnotationGroupLoaderSkeleton-${i}`}
                className={styles.annotation}
                variant="text"
                height={26}
                animation="wave"
              />
            ))}
        </div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  classes: PropTypes.shape(),
};

Loader.defaultProps = {
  classes: null,
};
