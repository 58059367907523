import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    expanded: {},

    toggle: {
      marginLeft: theme.spacing(-1.25),
    },
    toggle__button: {
      marginTop: theme.spacing(-0.5),
      marginBottom: theme.spacing(-0.5),

      '$expanded &': {
        marginBottom: theme.spacing(0),
      },
    },
    toggleTitle: {
      marginLeft: theme.spacing(0.25),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(19),
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.neutral.blackBlue,
    },
    content: {
      '&$vertical': {
        display: 'flex',
        flexFlow: 'column nowrap',
        marginTop: theme.spacing(0.5),
      },
    },
    vertical: {},
  }),
  { name: 'BriefItem' }
);
