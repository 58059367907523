import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomTypography } from 'src/components/CustomTypography';
import { DiagramGender } from 'src/components/DiagramGender';
import { getGenderHistogramTone, getGenderToneTitle } from 'src/data/demographics';
import { InsightBox } from '../InsightBox';
import { Loader } from './components/Loader';
import { useStyles } from './useStyles';

export const HistogramGenderScore = ({ className, classes, data, loading }) => {
  const styles = useStyles({ classes });

  const genderTone = useMemo(() => getGenderHistogramTone(data || []), [data]);
  const genderTitle = getGenderToneTitle(genderTone);

  return (
    <InsightBox className={cx(styles.root, className)}>
      <CustomTypography
        className={styles.title}
        variant="medium12"
      >
        {genderTitle}
      </CustomTypography>

      {loading ? <Loader /> : <DiagramGender data={data} />}
    </InsightBox>
  );
};

HistogramGenderScore.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  loading: PropTypes.bool,
};

HistogramGenderScore.defaultProps = {
  className: null,
  classes: null,
  data: [],
  loading: false,
};
