import { useEffect, useRef } from 'react';
import { DateTime } from 'luxon';

import { isOnboardingStageDone, ONBOARDING_STAGE } from 'src/data/onboarding';
import { usePopups } from 'src/services/popup';
import { useUiActions, useCurrentUser, useOnboardingActions } from 'src/store';

const finishedOnboarding = (user) =>
  isOnboardingStageDone(user.onboardingStages, ONBOARDING_STAGE.webinarBanner2);

const ZONE = 'America/New_York';
const TARGET_DATE_OBJECT = { year: 2024, month: 7, day: 30, hour: 12, minute: 30 };

export const useWebinarBanner = () => {
  const popups = usePopups();
  const onboardingActions = useOnboardingActions();
  const currentUser = useCurrentUser();
  const uiActions = useUiActions();
  const userIdRef = useRef();

  useEffect(() => {
    // Get the current date and time in EST/EDT
    const currentDate = DateTime.local().setZone(ZONE);
    // Specify the target date and time
    const targetDate = DateTime.fromObject(TARGET_DATE_OBJECT).setZone(ZONE);
    // Compare the current date and time with the target date and time
    if (currentDate >= targetDate) {
      return;
    }
    // User may have already finished webinar banner
    if (!currentUser || finishedOnboarding(currentUser)) {
      return;
    }

    // We wanna show webinar banner only one time per session
    if (userIdRef.current === currentUser?.id) {
      return;
    }

    userIdRef.current = currentUser?.id;
    uiActions.setShowWebinarBanner(true);
  }, [popups, currentUser, uiActions, onboardingActions]);
};
