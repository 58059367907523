import { trim } from 'lodash';

import { getAssetTypeMaxCopyLength } from 'src/data/asset';
import {
  getToolTitle,
  getGroupByTool,
  GENERATION_TOOL_GROUP,
  getToolDescription,
  isToolSupportCustomerPersona,
  getToolDefaultAssetType,
  isGenerationToolInSocialGroup,
  GENERATION_TOOL,
} from 'src/data/generationTool';
import { GENERATION_MODE_TYPE } from 'src/data/generation';
import { CUSTOMER_PERSONA_EMPTY_LABEL } from 'src/data/persona';
import { GENDER, getAgeGroupLabel, getGenderLabel } from 'src/data/demographics';

const convertToSingleLine = (str) => str.replace(/\n+/g, ' ');
const truncateSpaces = (str) => str.replace(/\s+/g, ' ');

const freeTextPrefix = (generationTool) => {
  if (getGroupByTool(generationTool) === GENERATION_TOOL_GROUP.email) {
    return 'Promotion';
  }
  return 'Brief';
};

const CUSTOM_TOOL_TITLES = {
  [GENERATION_TOOL.emailSubjectLine]: 'Email Subject line',
  [GENERATION_TOOL.emailColdOutreachBody]: 'cold email to a prospect',
};

const formatTitleLine = (params) => {
  const result = [];

  const customTitle = CUSTOM_TOOL_TITLES[params.generationTool];
  if (customTitle) {
    result.push(`Write a ${customTitle}`);
  } else {
    const toolTitle = getToolTitle(params.generationTool);
    result.push(`Write a ${toolTitle}`);

    const toolDescription = getToolDescription(params.generationTool);
    if (toolDescription) {
      result.push(toolDescription);
    }
  }

  return result.join(' ');
};

const shouldSkipCountAndLimitsLines = (params) =>
  isGenerationToolInSocialGroup(params.generationTool) ||
  [GENERATION_TOOL.emailSubjectLine, GENERATION_TOOL.emailColdOutreachBody].includes(
    params.generationTool
  );
const formatCountAndLimitsLines = (params) => {
  if (shouldSkipCountAndLimitsLines(params)) {
    return '';
  }

  const result = [];

  const assetType = getToolDefaultAssetType(params.generationTool);
  const maxLength = getAssetTypeMaxCopyLength(assetType);
  if (maxLength > 0) {
    result.push(`Each one up to ${maxLength} chars long.`);
  }

  return result.join('\n');
};

/*
 * Brief lines
 */
const prepareBriefContent = (str) => convertToSingleLine(truncateSpaces(trim(str)));
const extractedBriefOrFreeText = (params) =>
  prepareBriefContent(params.extractedBrief || params.brief.freeText);
const formatBriefLines = (params) => {
  const result = [];

  if (params.generationTool === GENERATION_TOOL.emailSubjectLine) {
    result.push(`For this email ${extractedBriefOrFreeText(params)}`);
  } else if (isGenerationToolInSocialGroup(params.generationTool)) {
    result.push(extractedBriefOrFreeText(params));
  } else {
    result.push('');
    result.push(`Write the ads using the following context:`);

    if (params.extractedBrief) {
      const prefix = freeTextPrefix(params.generationTool);
      result.push(`${prefix}: ${prepareBriefContent(params.extractedBrief)}`);
    } else {
      if (params.brief.title != null) {
        result.push(`Product name: ${prepareBriefContent(params.brief.title)}`);
      }
      if (params.brief.body != null) {
        result.push(`Product description: ${prepareBriefContent(params.brief.body)}`);
      }
      if (params.brief.freeText != null) {
        const prefix = freeTextPrefix(params.generationTool);
        result.push(`${prefix}: ${prepareBriefContent(params.brief.freeText)}`);
      }
    }
  }

  return result.join('\n');
};

export const formatPersonaPromptComponent = (persona) => {
  const result = [];

  if (persona) {
    result.push(trim(persona.name));
    if (persona.minAge && persona.maxAge) {
      result.push(`age: ${persona.minAge} to ${getAgeGroupLabel(persona.maxAge)}`);
    }
    if (persona.gender && persona.gender !== GENDER.doesntMatter) {
      result.push(`gender: ${getGenderLabel(persona.gender)}`);
    }
    if (persona.painPoints?.length > 0) {
      result.push(`persona’s pain points are: ${persona.painPoints.join(', ')}`);
    }
  } else {
    result.push(CUSTOMER_PERSONA_EMPTY_LABEL);
  }

  return result.join(', ');
};

export const formatGenerationPrompt = (params) => {
  const result = [];

  if (params.suggestionMode) {
    // Title line
    const title = formatTitleLine(params);
    result.push(title);

    const countAndLimits = formatCountAndLimitsLines(params);
    if (countAndLimits) {
      result.push('');
      result.push(countAndLimits);
    }

    // Brief
    result.push(formatBriefLines(params));

    // Customer persona
    if (isToolSupportCustomerPersona(params.generationTool)) {
      result.push('');
      result.push(
        `Customer persona to write for: ${formatPersonaPromptComponent(params.customerPersona)}`
      );
    }

    // "personalized"/"use examples" does not use annotations
    if (params.suggestionMode !== GENERATION_MODE_TYPE.personalized) {
      // Annotations / talking points
      if (params.annotations?.length > 0) {
        const annotationsStr = params.annotations.map(({ content }) => content).join(', ');
        result.push(`Must use the following talking points with these ads: ${annotationsStr}`);
      }
    }
  } else {
    // "recompose"-specific formatting
  }

  return result.join('\n');
};
