import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query QueryCOAssetsStatus($projectId: Int!) {
    project(id: $projectId) {
      id
      assetProcessingStatus
    }
  }
`;

export function queryCOAssetsStatus(projectId) {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
    },
  }).then(({ data }) => getSingleProp(data).assetProcessingStatus);
}
