import { Editor as SlateEditor, Node as SlateNode, Text as SlateText } from 'slate';
import { last } from 'lodash';

const getNodeCharactersPoints = (editor, nodePath) => {
  const textNodes = SlateEditor.nodes(editor, {
    at: SlateEditor.range(editor, nodePath),
    match: (node) => SlateText.isText(node),
  });

  return [...textNodes]
    .map(([node, path]) => {
      const text = SlateNode.string(node);
      const chars = text.split('');
      return chars.map((char, index) => ({ char, path, offset: index }));
    })
    .flat();
};

export const getCharactersPointsBoundaries = (charactersPoints, start, end) => {
  if (!charactersPoints || !(start >= 0 && end >= 0)) {
    return null;
  }

  const startCharData = charactersPoints[start];
  let endCharData = charactersPoints[end];
  if (!endCharData) {
    const lastCharData = last(charactersPoints);
    endCharData = {
      path: lastCharData.path,
      offset: lastCharData.offset + 1,
    };
  }

  return {
    start: startCharData,
    end: endCharData,
  };
};

export const getParagraphsCharacterPoints = (editor, paragraphs) => {
  const result = [];

  // Get Slate paragraphs - element of type PARAGRAPH
  const slateParagraphsData = paragraphs.map(([, path]) => getNodeCharactersPoints(editor, path));

  // Each Slate paragraph can contain line-breaks, which we should split into real text paragraphs
  slateParagraphsData.forEach((paragraphData) => {
    let currentParagraph = [];

    paragraphData.forEach((charData) => {
      if (charData.char === '\n') {
        result.push(currentParagraph);
        currentParagraph = [];
      } else {
        currentParagraph.push(charData);
      }
    });

    result.push(currentParagraph);
  });

  return result;
};
