import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    paper: {
      maxWidth: '860px',
      minHeight: '400px',
    },

    dialogContent: {
      position: 'relative',
      background: '#fff',
      padding: '40px!important',
      borderRadius: theme.shape.borderRadius,
    },

    buttonClose: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      padding: '8px',

      '& svg': {
        fontSize: '22px',
      },
    },
    preview: {
      margin: theme.spacing(0, 3),
    },
    magnifierContainer: {
      backgroundColor: '#F4F6FA',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(3),
    },
    buttonEdit: {
      float: 'right',
      marginTop: theme.spacing(3),
    },
  }),
  { name: 'PopupVariantScreenshotPreview' }
);
