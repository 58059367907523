import { useMemo } from 'react';

import { createFieldSelectorHook } from 'src/lib/sweetState';
import { CommonStore } from './CommonStore';

const useTaxonomiesStored = createFieldSelectorHook(CommonStore, 'contentTaxonomies');
const useDefaultTaxonomiesStored = createFieldSelectorHook(CommonStore, 'defaultContentTaxonomies');

const useTaxonomies = (useTaxonomiesHook, contentType) => {
  const taxonomies = useTaxonomiesHook();

  return useMemo(() => {
    if (!contentType) {
      return taxonomies;
    }

    return taxonomies;
  }, [taxonomies, contentType]);
};

export const useContentTaxonomies = (contentType) =>
  useTaxonomies(useTaxonomiesStored, contentType);

export const useDefaultContentTaxonomies = (contentType) =>
  useTaxonomies(useDefaultTaxonomiesStored, contentType);
