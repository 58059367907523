import * as storage from 'src/lib/storage';

const createStringOption = (key, options) => ({
  read: (defaultValue = null) => {
    const value = storage.readItem(key, options);
    if (value == null) {
      return defaultValue;
    }
    return value;
  },

  write: (value) => {
    storage.writeItem(key, value, options);
  },

  remove: () => {
    storage.removeItem(key, options);
  },
});

const VALUE_TRUE = '1';
const VALUE_FALSE = '0';
const createBooleanOption = (key, options) => ({
  read: (defaultValue = null) => {
    const value = storage.readItem(key, options);
    if (value == null) {
      return defaultValue;
    }
    return value === VALUE_TRUE;
  },

  write: (value) => {
    storage.writeItem(key, value ? VALUE_TRUE : VALUE_FALSE, options);
  },

  remove: () => {
    storage.removeItem(key, options);
  },
});

const KEY_CURRENT_CUSTOMER_ID = 'kcc.user.currentCustomerId';
export const currentCustomerId = {
  read: (options) => {
    const sessionValue = !options?.skipSession
      ? storage.readItem(KEY_CURRENT_CUSTOMER_ID, { session: true })
      : null;
    const localStorageValue = storage.readItem(KEY_CURRENT_CUSTOMER_ID);
    return sessionValue || localStorageValue || null;
  },

  write: (value) => {
    storage.writeItem(KEY_CURRENT_CUSTOMER_ID, value, { session: true });
    storage.writeItem(KEY_CURRENT_CUSTOMER_ID, value);
  },
};

const KEY_CURRENT_WORKSPACE_ID = 'kcc.customer.currentWorkspaceId';
export const currentWorkspaceId = {
  read: (options) => {
    const sessionValue = !options?.skipSession
      ? storage.readItem(KEY_CURRENT_WORKSPACE_ID, { session: true })
      : null;
    const localStorageValue = storage.readItem(KEY_CURRENT_WORKSPACE_ID);
    return sessionValue || localStorageValue || null;
  },

  write: (value) => {
    storage.writeItem(KEY_CURRENT_WORKSPACE_ID, value, { session: true });
    storage.writeItem(KEY_CURRENT_WORKSPACE_ID, value);
  },
};

export const lastModified = createStringOption('kcc.system.lastModified');

export const lastUsedProjectType = createStringOption('kcc.project.lastUsedProjectType');

export const projectsFilter = createStringOption('ui.projects.filter');

export const projectsTypeFilter = createStringOption('ui.projects.type.filter');

export const projectLastUsedSavedChannel = createStringOption('ui.project.lastUsedSavedChannel');

export const projectLastUsedSavedFormat = createStringOption('ui.project.lastUsedSavedFormat');

export const lastUsedContentTaxonomyId = createStringOption(
  'kcc.project.lastUsedContentTaxonomyId'
);

export const coTutorialWasShownInSession = createBooleanOption('ui.co.tutorialWasShown', {
  session: true,
});

export const selectedProjectCategory = createStringOption('ALM_SELECTED_CATEGORY');

export const socialPostAccessToken = createStringOption('kcc.socialPostAccessToken');

export const socialPostProjectId = createStringOption('kcc.socialPostProjectId');

export const lastGeneratedScoreHintTimestamp = createStringOption(
  'onboarding.score.lastGeneratedTimestamp'
);

export const lastGeneratedPersonaHintTimestamp = createStringOption(
  'onboarding.persona.lastGeneratedTimestamp'
);

export const favoritesOnly = createBooleanOption('ui.projects.favoritesOnly');

export const sessionApplicationMode = createStringOption('system.applicationMode', {
  session: true,
});
export const sessionApplicationHost = createStringOption('system.applicationHost', {
  session: true,
});

export const documentGenerationLanguage = createStringOption('document.generationLanguage');

export const infinityBoostGenerationTool = createStringOption('infinity.boostGenerationTool');

export const developmentMode = createBooleanOption('system.developmentMode');

export const textDetailsPanelTab = createStringOption('document.textDetailsPanelTab');
