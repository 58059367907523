import { isBasicPlan, getCustomerPlan } from 'src/data/subscription';
import { customerInTrial, customerTrialEnded } from 'src/data/customer';
import { useCurrentCustomer } from './useCurrentCustomer';

export const useIsCustomerOnBasicPlan = () => {
  const currentCustomer = useCurrentCustomer();
  const isCustomerInTrial =
    customerInTrial(currentCustomer) || customerTrialEnded(currentCustomer);
  const plan = getCustomerPlan(currentCustomer);
  return isBasicPlan(plan) && !isCustomerInTrial;
};
