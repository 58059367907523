import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { USER_INVITE_FRAGMENT } from 'src/graphql/user/schema';

const MUTATION = gql`
  mutation AddCustomerUser(
    $customerId: ID!
    $email: String!
    $name: String
    $defaultWorkspaceId: ID
  ) {
    addCustomerUser(
      customerId: $customerId
      email: $email
      name: $name
      defaultWorkspaceId: $defaultWorkspaceId
    ) {
      ...UserInvite
    }
  }
  ${USER_INVITE_FRAGMENT}
`;

export const addCustomerUser = ({ customerId, email, name, defaultWorkspaceId }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, email, name, defaultWorkspaceId },
  }).then(({ data }) => getSingleProp(data));
