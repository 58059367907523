import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation TrackEventNoAuth($name: String!, $attributes: JSON) {
    trackEventNoAuth(name: $name, attributes: $attributes) {
      id
    }
  }
`;

export const trackEventNoAuth = (name, attributes) => {
  const variables = {
    name,
    attributes: JSON.stringify(attributes),
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
