import {
  createActionsHook as rssCreateActionsHook,
  createStateHook,
} from 'react-sweet-state';
import pick from 'lodash/pick';

export const createActionsHook = rssCreateActionsHook;

export const createStateSelectorHook = (Store, stateFields) =>
  createStateHook(Store, {
    selector: (state) => pick(state, stateFields),
  });

export const createFieldSelectorHook = (Store, stateField) =>
  createStateHook(Store, {
    selector: (state) => state[stateField],
  });
