import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& h1': {
        fontSize: theme.typography.pxToRem(32),
        fontWeight: theme.typography.fontWeightBold,
      },
      '& h2': {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: theme.typography.fontWeightBold,
      },
      '& h3': {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold,
      },
      '& ul': {
        listStyleType: 'disc',
        listStylePosition: 'outside',
        marginLeft: '1.5em',
      },
      '& ol': {
        listStyleType: 'decimal',
        listStylePosition: 'outside',
        marginLeft: '1.5em',
      },
    },
  }),
  { name: 'CustomEditable' }
);
