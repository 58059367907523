import { useCallback } from 'react';

import { usePopups } from 'src/services/popup';
import { BULK_DELETE_RESOURCES } from '../../services/popup/types';

export const useBulkDeleteResourcesPopup = () => {
  const popups = usePopups();

  return useCallback(
    (resourcesIds) => {
      return new Promise((resolve) => {
        popups.openPopup(BULK_DELETE_RESOURCES, {
          resourcesIds,
          onClose: () => {
            resolve();
            popups.closePopup(BULK_DELETE_RESOURCES);
          },
        });
      });
    },
    [popups]
  );
};
