import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 608,
      maxHeight: 'min(711px, calc(100% - 70px))',

      '&[data-popper-reference-hidden]': {
        visibility: 'hidden',
      },
    },

    title: {
      fontWeight: theme.typography.fontWeightMedium,
    },

    detailsWrapper: {
      padding: theme.spacing(2, 4, 3),
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: 7,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: theme.spacing(0.5),
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        boxShadow: '0 0 1px rgba(255, 255, 255, 0.5)',
      },
    },

    details: {
      minHeight: 100,
      paddingTop: theme.spacing(2),
      borderTop: theme.shape.borderGray70,
      '&:first-child': {
        borderTop: 'none',
        paddingTop: 0,
      },
    },

    baseItemRoot: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },

    baseItemTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      color: COLORS.gray.black,
      fontWeight: theme.typography.fontWeightMedium,
    },

    baseItemBody: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: COLORS.gray.black,
      marginBottom: theme.spacing(1),
    },
  }),
  {
    name: 'GeneratedDetailsPopup',
  }
);
