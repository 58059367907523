import get from 'lodash/get';
import { customerFreemiumAllowed } from 'src/data/accessRights';
import { getCustomerPlan, SUBSCRIPTION_STATUS, isBasicPlan } from 'src/data/subscription';
import { alignChannelWithBackend } from 'src/data/channel';

/**
 * Customer lifecycle
 */
export const CUSTOMER_LIFECYCLE = {
  active: 'active',
  inTrial: 'in_trial',
  trialEnded: 'trial_ended',
  notPaying: 'not_paying',
  paused: 'paused',
  pocEnded: 'poc_ended',
  inMobileConfirmed: 'in_mobile_confirmed',
};

const LIFECYCLE_LABEL = {
  [CUSTOMER_LIFECYCLE.active]: 'Active',
  [CUSTOMER_LIFECYCLE.inTrial]: 'In trial',
  [CUSTOMER_LIFECYCLE.trialEnded]: 'Trial ended',
  [CUSTOMER_LIFECYCLE.pocEnded]: 'POC ended',
  [CUSTOMER_LIFECYCLE.notPaying]: 'Subscription ended',
};

export function getCustomerLifecycleLabel(lifecycle) {
  return LIFECYCLE_LABEL[lifecycle] || lifecycle;
}

export function customerHasActiveSubscription(customer) {
  return customer?.lifecycle === CUSTOMER_LIFECYCLE.active;
}

export function customerInTrial(customer) {
  return customer?.lifecycle === CUSTOMER_LIFECYCLE.inTrial;
}

export function customerTrialEnded(customer) {
  return (
    customer?.lifecycle === CUSTOMER_LIFECYCLE.trialEnded ||
    customer?.lifecycle === CUSTOMER_LIFECYCLE.pocEnded
  );
}

export function customerSubscriptionExpired(customer) {
  return customer?.lifecycle === CUSTOMER_LIFECYCLE.notPaying;
}

export function customerSubscriptionPaused(customer) {
  return customer?.lifecycle === CUSTOMER_LIFECYCLE.paused;
}

export function isCustomerQuotaEnded(customer) {
  const quota = get(customer, 'billingConfiguration.queryQuota');

  // If customer don't have limit set, then treat quota as infinite
  if (!quota?.planWordsLimit) {
    return false;
  }

  // Check 'plan' and 'overage' quotas at once
  const used = (quota.planWordsUsed || 0) + (quota.overageWordsUsed || 0);
  const limit = (quota.planWordsLimit || 0) + (quota.overageWordsLimit || 0);

  return used >= limit;
}

export function getCustomerQuotaUsed(customer) {
  const quota = get(customer, 'billingConfiguration.queryQuota');

  // If customer doesn't have limit set, then it is though he has used zero credits.
  if (!quota?.planWordsLimit) {
    return 0;
  }

  return (quota.planWordsUsed || 0) + (quota.overageWordsUsed || 0);
}

export function getCustomerQuotaLeft(customer) {
  const quota = get(customer, 'billingConfiguration.queryQuota');

  // If customer doesn't have limit set, then treat quota as infinite
  if (!quota?.planWordsLimit) {
    return Infinity;
  }

  const used = (quota.planWordsUsed || 0) + (quota.overageWordsUsed || 0);
  const limit = (quota.planWordsLimit || 0) + (quota.overageWordsLimit || 0);

  return limit - used;
}

/**
 * Customer segments
 */
export const CUSTOMER_SEGMENT = {
  marketer: 'Marketer',
  publisher: 'Publisher',
  other: 'Other',
};

export const customerHasEmptyMigrationStatus = (customer) =>
  customer?.planMigrationStatus?.acceptStatus === null;

/**
 * Other
 */

export function isCustomerFeatureEnabled(customer, featureFlag) {
  if (!customer) return false;

  const customerFeatures = customer.featureFlags || [];
  return customerFeatures.includes(featureFlag);
}

export const isCustomerOnFreemium = (customer) => {
  const plan = getCustomerPlan(customer);

  return isBasicPlan(plan) && customerFreemiumAllowed(customer);
};

export const isCustomerSubscriptionCanceled = (customer) => {
  const { subscriptionStatus } = customer?.billingConfiguration?.method || {};

  return subscriptionStatus === SUBSCRIPTION_STATUS.canceled;
};

/**
 * Check if customer reached rows allocation limit for a channel
 * @param {object} customer - customer object
 * @param {CHANNEL} channel - channel
 * @return {object} - isExceedLimit -  if customer reached rows allocation limit for a channel, limit - the number of used rows up to the limit
 */
export const getCustomerRowsAllocationLimitInfo = (customer, channel) => {
  const alignedChannel = alignChannelWithBackend(channel);
  const allocation = customer?.rowsAllocationPerChannel?.find(
    (item) => item.channel === alignedChannel
  );
  if (!allocation) {
    return false;
  }
  return {
    isExceedLimit: allocation.rowsAllocated < allocation.rowsCount,
    limit: allocation.rowsAllocated,
  };
};
