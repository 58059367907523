import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';

import Score, { SCORE_SIZE } from 'src/components/Score';
import { GenerationToolIcon } from 'src/components/GenerationToolIcon';
import { BLOCKED_REASON } from 'src/data/common';
import { GENERATION_TOOL } from 'src/data/generationTool';
import { DiamondIcon } from '../../assets/icons/Diamond';
import { useStyles } from './useStyles';

export const DisplayVariation = ({
  className,
  classes,
  title,
  copyText,
  score,
  hideScore,
  scoreSize,
  scoreLocked,
  generationTool,
  blockedReason,
}) => {
  const styles = useStyles({ classes });

  const generationToolIcon = generationTool && (
    <GenerationToolIcon
      className={styles.icon}
      tool={generationTool}
    />
  );

  return (
    <div className={cx(styles.root, className)}>
      {title && <Typography className={styles.title}>{title}</Typography>}

      <div className={styles.copyTextContainer}>
        <Typography
          component="span"
          className={styles.copyText}
        >
          {copyText}
        </Typography>
        {!hideScore && (
          <div className={styles.scoreWrapper}>
            {scoreLocked && blockedReason !== BLOCKED_REASON.maxWordsExceeded ? (
              <DiamondIcon className={styles.diamondIcon} />
            ) : (
              <Score
                score={score}
                size={scoreSize}
                generationToolIcon={generationToolIcon}
                blockedReason={blockedReason}
                locked={scoreLocked}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

DisplayVariation.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  copyText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  score: PropTypes.number,
  classes: PropTypes.shape(),
  hideScore: PropTypes.bool,
  scoreLocked: PropTypes.bool,
  scoreSize: PropTypes.oneOf(Object.values(SCORE_SIZE)),
  generationTool: PropTypes.oneOf(Object.values(GENERATION_TOOL)),
  blockedReason: PropTypes.oneOf(Object.values(BLOCKED_REASON)),
};
DisplayVariation.defaultProps = {
  className: null,
  title: null,
  copyText: null,
  score: null,
  classes: null,
  hideScore: false,
  scoreLocked: false,
  scoreSize: null,
  blockedReason: null,
  generationTool: null,
};
