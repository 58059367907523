import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_TEXT_ANNOTATIONS } from './schema';

const MUTATION = gql`
  mutation ExtractTextAnnotations(
    $customerId: ID!
    $generationTool: GenerationToolEnum!
    $text: String!
    $variationId: ID
    $productName: String
  ) {
    extractTextAnnotations(
      customerId: $customerId
      generationTool: $generationTool
      text: $text
      variationId: $variationId
      productName: $productName
    ) {
      ...TextAnnotations
    }
  }
  ${FRAGMENT_TEXT_ANNOTATIONS}
`;

export const extractTextAnnotations = async (
  { customerId, generationTool, text, variationId, productName = null },
  options
) => {
  const variables = { customerId, generationTool, text, variationId, productName };

  const result = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  return getSingleProp(result.data);
};
