import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import {
  FRAGMENT_PERFORMANCE_CENTER_INTEGRATION_REQUEST,
  FRAGMENT_PERFORMANCE_CENTER_TEXT_VARIATIONS_RESULT,
} from './schema';

const QUERY = gql`
  query PerformanceCenterRequest(
    $accountId: ID!
    $customerId: ID!
    $dataSource: PerformanceCenterDataSource!
    $textVariationsResultsCount: Int!
  ) {
    performanceCenterRequest(
      accountId: $accountId
      customerId: $customerId
      dataSource: $dataSource
    ) {
      ...PerformanceCenterIntegrationRequest

      performanceCenterTextVariationsResults(first: $textVariationsResultsCount) {
        edges {
          node {
            ...PerformanceCenterTextVariationsResult
          }
        }
      }
    }
  }
  ${FRAGMENT_PERFORMANCE_CENTER_INTEGRATION_REQUEST}
  ${FRAGMENT_PERFORMANCE_CENTER_TEXT_VARIATIONS_RESULT}
`;

export const performanceCenterRequest = ({
  customerId,
  accountId,
  dataSource,
  textVariationsResultsCount,
}) => {
  return executeGqlQuery({
    query: QUERY,
    variables: { customerId, accountId, dataSource, textVariationsResultsCount },
  }).then(({ data }) => {
    let results = getSingleProp(data);

    const copies = unwrapEdges(results?.performanceCenterTextVariationsResults?.edges || []);

    if (copies.length) {
      results = { ...results, copies };
    }

    return results;
  });
};
