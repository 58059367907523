import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    isBlog: {},

    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    boxGroupTitle: {
      flex: 1,
    },
    buttonSettings: {
      margin: theme.spacing(-1, 0), // we want reasonable clickable area for the button, but don't want to influence panel vertical free space

      '& svg': {
        fontSize: theme.typography.pxToRem(16),
        color: COLORS.gray.gray30,
      },
    },
    wrapper: {
      display: 'flex',
      flexFlow: 'column nowrap',
      gap: theme.spacing(1),
      padding: theme.spacing(3, 2),
      backgroundColor: COLORS.neutral.white,
      borderRadius: theme.shape.borderRadiusSemiLarge,
    },
  }),
  { name: 'ToneOfVoice' }
);
