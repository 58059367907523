import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_FREESTYLE_TOOL } from './schema';

const MUTATION = gql`
  mutation CreateCustomerSuggestionFreestyleTool(
    $customerId: ID!
    $workspaceId: ID
    $name: String!
    $allInstructionsData: JSON
    $generationTool: String
  ) {
    createCustomerSuggestionFreestyleTool(
      customerId: $customerId
      workspaceId: $workspaceId
      name: $name
      allInstructionsData: $allInstructionsData
      generationTool: $generationTool
    ) {
      ...SuggestionFreestyleTool
    }
  }
  ${FRAGMENT_FREESTYLE_TOOL}
`;

export const createSuggestionFreestyleTool = ({
  customerId,
  workspaceId,
  name,
  allInstructionsData,
  generationTool,
}) => {
  const variables = {
    customerId,
    workspaceId,
    name,
    allInstructionsData: JSON.stringify(allInstructionsData),
    generationTool,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => {
    const result = getSingleProp(data);
    result.allInstructionsData = JSON.parse(result.allInstructionsData || null);
    return result;
  });
};
