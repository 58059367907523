import { useMemo } from 'react';

import { useCurrentCustomer } from 'src/store';
import { customerTrialEnded } from 'src/data/customer';

export const useIsCustomerTrialEnded = () => {
  const customer = useCurrentCustomer();

  return useMemo(() => customerTrialEnded(customer), [customer]);
};
