import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const ItemLoading = forwardRef(({ className }, ref) => {
  const styles = useStyles();

  return (
    <MenuItem
      className={className}
      ref={ref}
      disabled
    >
      <Skeleton
        className={styles.skeleton}
        variant="text"
      />
    </MenuItem>
  );
});

ItemLoading.propTypes = {
  className: PropTypes.string,
};

ItemLoading.defaultProps = {
  className: '',
};
