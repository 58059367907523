import { useCallback } from 'react';

import { useMultiPromptTemplateActions, useNotificationActions } from 'src/store';
import { useErrorHandler } from 'src/services';

export const useHandlers = ({ onClose, validate, fields, template }) => {
  const multiPromptTemplateActions = useMultiPromptTemplateActions();
  const handleError = useErrorHandler();
  const notifications = useNotificationActions();

  const handleSubmit = useCallback(async () => {
    if (!validate()) {
      return;
    }
    try {
      await multiPromptTemplateActions.updateExpertMultiPromptTemplate({
        id: template.id,
        templateGroup: fields.templateGroup,
        smallImage: fields.smallImage,
        bigImage: fields.bigImage,
        iconImage: fields.iconImage,
      });
      onClose();
      notifications.displaySuccess('Template saved');
    } catch (error) {
      handleError(error);
    }
  }, [
    validate,
    multiPromptTemplateActions,
    handleError,
    template.id,
    fields.templateGroup,
    fields.smallImage,
    fields.bigImage,
    fields.iconImage,
    onClose,
    notifications,
  ]);

  return { handleSubmit };
};
