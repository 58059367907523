import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import cx from 'classnames';
import TextLink from 'src/components/TextLink';

import { useStyles } from './useStyles';

export const PlagiarismCheckResult = ({ checkResult, classes }) => {
  const styles = useStyles({ classes });

  return (
    <div className={cx(styles.root, styles.resultBox)}>
      <Typography className={styles.title}>{checkResult.title}</Typography>
      <TextLink
        className={styles.url}
        href={checkResult.url}
        target="_blank"
        rel="noopener"
      >
        {checkResult.url}
      </TextLink>
      <Typography className={styles.text}>{checkResult.snippet}</Typography>
    </div>
  );
};

PlagiarismCheckResult.propTypes = {
  checkResult: PropTypes.array.isRequired,
  classes: PropTypes.shape(),
};

PlagiarismCheckResult.defaultProps = {
  classes: null,
};
