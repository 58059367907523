import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom-v5-compat';
import { ClickAwayListener, Typography } from '@material-ui/core';

import { useCurrentCustomer, useCustomFeatureLimitsData } from 'src/store';
import { ROUTES } from 'src/routes';
import { useBusinessBookDemo } from 'src/services';
import { usePopper } from 'src/lib/hooks';
import {
  getCustomerPlan,
  getCustomerTier,
  isDDTeamsPlan,
  MAX_SEAT_TIER,
  getSubscriptionPlanLabel,
  getTierProperty,
  isEnterpriseOrBusinessPlan,
  SUBSCRIPTION_PLAN,
  isBasicPlan,
  isBusinessSelfServePlan,
  getPlanAndTierForNextLimit,
} from 'src/data/subscription';
import {
  formatBusinessSelfServeCheckoutLink,
  formatDDTeamsCheckoutLink,
  formatRoute,
} from 'src/lib/routing';
import { BusinessBookDemoButton } from 'src/components/BusinessBookDemoButton';
import { CustomButton, CUSTOM_BUTTON_COLOR } from 'src/components/CustomButton';
import { ArrowIcon } from 'src/components/ArrowIcon';
import { LanternIcon } from 'src/assets/icons/Lantern';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { BOOK_DEMO_ORIGIN } from 'src/data/common';
import * as events from 'src/lib/events';
import { useStyles } from './useStyles';

const getFeatureFromOrigin = (origin) => {
  switch (origin) {
    case BOOK_DEMO_ORIGIN.brandBriefsPopup:
    case BOOK_DEMO_ORIGIN.brandBriefsPage:
      return FEATURE_LIMITS.brandBriefPerCustomer;

    case BOOK_DEMO_ORIGIN.customFormulaPopup:
    case BOOK_DEMO_ORIGIN.customFormulaPage:
      return FEATURE_LIMITS.customFormulasPerCustomer;

    case BOOK_DEMO_ORIGIN.brandTermsPopup:
    case BOOK_DEMO_ORIGIN.brandTermsPage:
      return FEATURE_LIMITS.brandRulesPerCustomer;

    case BOOK_DEMO_ORIGIN.targetAudiencePopup:
    case BOOK_DEMO_ORIGIN.targetAudiencePage:
      return FEATURE_LIMITS.audiencePersonasPerCustomer;

    case BOOK_DEMO_ORIGIN.addWorkspace:
      return FEATURE_LIMITS.workspacesPerCustomer;

    case BOOK_DEMO_ORIGIN.addUser:
      return FEATURE_LIMITS.membersPerCustomer;

    case BOOK_DEMO_ORIGIN.toneOfVoicePopup:
    case BOOK_DEMO_ORIGIN.toneOfVoicePage:
      return FEATURE_LIMITS.tonesOfVoicePerCustomer;
    case BOOK_DEMO_ORIGIN.checkPlagiarism:
      return FEATURE_LIMITS.plagiarismChecksPerCustomer;

    default:
      return null;
  }
};

export const UpgradePopover = ({
  className,
  open,
  referenceElement,
  onClose,
  onClick,
  placement,
  origin,
}) => {
  const styles = useStyles();

  const currentCustomer = useCurrentCustomer();
  const plan = getCustomerPlan(currentCustomer);
  const tier = getCustomerTier(currentCustomer);
  const isDataDriven = isDDTeamsPlan(plan, tier);
  const isBusinessSelfServe = isBusinessSelfServePlan(plan);
  const isBusinessOrEnterprise = isEnterpriseOrBusinessPlan(plan, tier);
  const isBasic = isBasicPlan(plan, tier);
  const isTopSeatTier = tier === MAX_SEAT_TIER?.[plan];
  const isMemberLimit = origin === BOOK_DEMO_ORIGIN.addUser;
  const isWorkspaceLimit = origin === BOOK_DEMO_ORIGIN.addWorkspace;
  const isPlagiarismLimit = origin === BOOK_DEMO_ORIGIN.checkPlagiarism;
  const [popperElement, setPopperElement] = useState(null);

  const feature = getFeatureFromOrigin(origin);
  const { currentCount, limit, label } = useCustomFeatureLimitsData(feature);

  const upgradeTarget = getPlanAndTierForNextLimit(plan, tier, feature);
  const formatCheckoutLink =
    upgradeTarget.plan === SUBSCRIPTION_PLAN.business_self_serve
      ? formatBusinessSelfServeCheckoutLink
      : formatDDTeamsCheckoutLink;

  const getPlanAndTierLabel = () => {
    const seatsCount = getTierProperty(tier, 'seats', 0, plan);
    return getSubscriptionPlanLabel(plan) + (seatsCount ? ' (' + seatsCount + ' seats)' : '');
  };

  const getMemberLimitSubtitle = () => {
    if (isBusinessSelfServe) {
      if (isTopSeatTier) {
        return 'Your plan does not support any more seats. Please contact Sales to upgrade to an Enterprise plan.';
      }
      return "You've reached the seat limit for your plan: " + getPlanAndTierLabel() + '.';
    }
    if (isDataDriven) {
      if (isTopSeatTier) {
        return 'Your plan does not support any more seats. Please upgrade your plan to add more seats.';
      }
      return "You've reached the seat limit for your plan: " + getPlanAndTierLabel() + '.';
    }
    return 'Upgrade now to get 3+ seats, unlimited performance improvements, more brand features, 5+ workspaces and more!';
  };

  const getPlagiarismLimitSubtitle = () => {
    if (isBasic) {
      return 'Upgrade now to get unlimited words and unlock our plagiarism checker tool.';
    }
    return 'Upgrade to get more plagiarism checks, and take your copywriting to the next level.';
  };

  const options = useMemo(
    () => ({
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: () => [0, 4],
          },
        },
      ],
    }),
    [placement]
  );

  usePopper({ popperElement, referenceElement, options });

  const { handleBusinessBookDemoClick } = useBusinessBookDemo(origin);

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClose();
  };

  const handleUpgradeClicked = () => {
    const cta = shouldBookDemo
      ? events.HUBSPOT_SUBSCRIPTION_ACTION.bookDemo
      : events.HUBSPOT_SUBSCRIPTION_ACTION.buyNow;
    // TODO: business_self_serve looks weird! fix
    const plan =
      isDataDriven || isBusinessSelfServe
        ? events.convertSubscriptionPlanForHubspot(SUBSCRIPTION_PLAN.business)
        : events.convertSubscriptionPlanForHubspot(SUBSCRIPTION_PLAN.dataDriven);
    events.trackHubspotEvent(events.HUBSPOT_EVENT.clickedUpgradeButton, {
      cta: cta,
      origin,
      plan,
    });
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    handleUpgradeClicked();
    onClose();
  };

  if (!open || !referenceElement) {
    return null;
  }

  const shouldBookDemo =
    (isBusinessSelfServe && isTopSeatTier) ||
    (isBusinessSelfServe && !isMemberLimit && !isWorkspaceLimit) ||
    isBusinessOrEnterprise;

  return createPortal(
    <ClickAwayListener onClickAway={handleClose}>
      <div
        onClick={(e) => e.stopPropagation()}
        className={cx(
          styles.root,
          { [styles.wide]: isMemberLimit || isPlagiarismLimit },
          className
        )}
        ref={setPopperElement}
      >
        {isMemberLimit ? (
          <div className={styles.titlesContainer}>
            <Typography className={styles.title}>
              <LanternIcon className={styles.icon} />
              Add additional users
            </Typography>
            <Typography className={styles.subtitle}>{getMemberLimitSubtitle()}</Typography>
          </div>
        ) : isPlagiarismLimit ? (
          <div className={styles.titlesContainer}>
            <Typography className={styles.title}>
              <LanternIcon className={styles.icon} />
              {isBasic ? 'Make sure your copy is unique!' : 'You’ve reached your monthly limit'}
            </Typography>
            <Typography className={styles.subtitle}>{getPlagiarismLimitSubtitle()}</Typography>
          </div>
        ) : (
          <div className={styles.titlesContainer}>
            <div className={styles.titleWrapper}>
              <LanternIcon className={styles.icon} />
              <Typography className={styles.title}>
                You're using {currentCount}/{limit} {label}
              </Typography>
            </div>

            <Typography className={styles.subtitle}>
              To add additional {label},{' '}
              {shouldBookDemo
                ? 'and unlock premium Enterprise features book a demo today.'
                : 'upgrade your plan and take your copywriting to the next level.'}
            </Typography>
          </div>
        )}

        {shouldBookDemo ? (
          <BusinessBookDemoButton
            className={styles.button}
            onClick={handleBusinessBookDemoClick}
            origin={origin}
            hideLabel
            fullWidth
          />
        ) : isPlagiarismLimit && isBasic ? (
          <CustomButton
            className={styles.button}
            component={Link}
            to={formatRoute(ROUTES.UPGRADE)}
            onClick={handleClick}
            color={CUSTOM_BUTTON_COLOR.upsell}
            fullWidth
          >
            {'Find the Right Plan for You '}
            <ArrowIcon className={styles.arrowIcon} />
          </CustomButton>
        ) : (
          <CustomButton
            className={styles.button}
            component={Link}
            to={formatCheckoutLink(upgradeTarget.tier, true)}
            onClick={handleClick}
            color={CUSTOM_BUTTON_COLOR.upsell}
            fullWidth
          >
            {isMemberLimit && (isDataDriven || isBusinessSelfServe)
              ? 'Add more seats'
              : upgradeTarget.plan === SUBSCRIPTION_PLAN.business_self_serve
              ? 'Upgrade to Business'
              : 'Upgrade to Data-Driven'}
            <ArrowIcon className={styles.arrowIcon} />
          </CustomButton>
        )}
      </div>
    </ClickAwayListener>,
    document.body
  );
};

UpgradePopover.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  referenceElement: PropTypes.instanceOf(Element),
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  placement: PropTypes.string,
  origin: PropTypes.string,
};

UpgradePopover.defaultProps = {
  className: null,
  open: false,
  referenceElement: null,
  onClick: null,
  placement: 'bottom-end',
  origin: null,
};
