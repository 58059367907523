import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CO_CONVERSION_GOAL } from './schema';

const MUTATION = gql`
  mutation UpdateCOConversionGoal($id: ID!, $url: String) {
    updateContinuousOptimizationLinkCallToAction(id: $id, url: $url) {
      ...ContinuousOptimizationConversionGoal
    }
  }
  ${FRAGMENT_CO_CONVERSION_GOAL}
`;

export function updateCOConversionGoal(id, url) {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { id, url },
  }).then(({ data }) => getSingleProp(data));
}
