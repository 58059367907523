import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query FacebookPermissions($customerId: ID!) {
    facebookPermissions(customerId: $customerId)
  }
`;

export const queryFacebookPermissions = (customerId) => {
  return executeGqlQuery({
    query: QUERY,
    variables: { customerId },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => result);
};
