import { PROJECT_TYPE } from './const';

export const isContinuousOptimizationType = (projectType) => {
  return [PROJECT_TYPE.wtmHomePage, PROJECT_TYPE.continuousOptimization].includes(projectType);
};

export const isDocumentProject = (projectType) => {
  return !isContinuousOptimizationType(projectType) && projectType !== PROJECT_TYPE.blogPostBuilder;
};

export const isBlogProject = (projectType) => projectType === PROJECT_TYPE.blogPostBuilder;
