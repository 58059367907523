import { useCallback } from 'react';

import { usePopups } from '../services';
import { IMPORT_BRIEF_FROM_URL_POPUP } from '../types';

export const useImportBriefFromUrlPopup = (hookOptions) => {
  const popups = usePopups();
  const hookBriefType = hookOptions?.briefType;
  const hookGenerationTool = hookOptions?.generationTool;
  const hookOnSubmit = hookOptions?.onSubmit;
  const hookOnClose = hookOptions?.onClose;
  const hookIsCloseHidden = hookOptions?.isCloseHidden;

  return useCallback(
    (callbackOptions) => {
      const briefType = callbackOptions?.briefType || hookBriefType;
      const generationTool = callbackOptions?.generationTool || hookGenerationTool;
      const onSubmit = callbackOptions?.onSubmit || hookOnSubmit;
      const onClose = callbackOptions?.onClose || hookOnClose;
      const isCloseHidden = callbackOptions?.isCloseHidden || hookIsCloseHidden;

      popups.openPopup(IMPORT_BRIEF_FROM_URL_POPUP, {
        briefType,
        generationTool,
        isCloseHidden,
        onSubmit: (...args) => {
          onSubmit(...args);
          popups.closePopup(IMPORT_BRIEF_FROM_URL_POPUP);
        },
        onClose: () => {
          if (onClose) {
            onClose();
          }
          popups.closePopup(IMPORT_BRIEF_FROM_URL_POPUP);
        },
      });
    },
    [popups, hookBriefType, hookGenerationTool, hookOnSubmit, hookOnClose, hookIsCloseHidden]
  );
};
