import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, ListItem, ListItemText, Button } from '@material-ui/core';

import { SearchMenuItem } from 'src/components/SearchMenuItem';
import { filterWorkspaces, sortWorkspaces } from 'src/data/workspace';
import { WorkspaceMenuItem } from '../WorkspaceMenuItem';
import { useStyles, MAX_WORKSPACE_ITEMS } from './useStyles';

const moveSelectedUp = (workspaces, selectedId) => {
  if (!selectedId || workspaces.length <= 1) {
    return workspaces;
  }

  const selectedWorkspace = workspaces.find((c) => c.id === selectedId);
  if (!selectedWorkspace) {
    return workspaces;
  }

  const filtered = workspaces.filter((c) => c.id !== selectedId);
  return [selectedWorkspace, ...filtered];
};

export const WorkspaceMenu = ({
  classes,
  open,
  anchor,
  onClose,
  workspaces,
  selectedWorkspaceId,
  onWorkspaceClick,
  onCreateOrUpdateClick,
  onDeleteClick,
  addButtonRef,
}) => {
  const styles = useStyles({ classes });

  const [search, setSearch] = useState('');
  useEffect(() => {
    if (open) {
      setSearch('');
    }
  }, [open]);

  const sortedWorkspaces = sortWorkspaces(workspaces);

  const reorderedWorkspaces = useMemo(
    () => moveSelectedUp(sortedWorkspaces, selectedWorkspaceId),
    [sortedWorkspaces, selectedWorkspaceId]
  );

  const filteredWorkspaces = useMemo(() => {
    if (search) {
      return filterWorkspaces(reorderedWorkspaces, search);
    }
    return reorderedWorkspaces;
  }, [search, reorderedWorkspaces]);

  const searchVisible = sortedWorkspaces.length > MAX_WORKSPACE_ITEMS;

  return (
    <Menu
      classes={{ paper: styles.paper }}
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      getContentAnchorEl={null}
      data-testid="WorkspaceMenu"
    >
      <ListItem className={styles.header}>
        <ListItemText
          className={styles.productWorkspaces}
          primary="Workspaces"
        />
        <Button
          ref={addButtonRef}
          className={styles.addNew}
          onClick={() => onCreateOrUpdateClick()}
          data-testid="workspaceMenuAddNewButton"
        >
          + Add new
        </Button>
      </ListItem>
      {searchVisible && (
        <div className={styles.searchItem}>
          <SearchMenuItem
            value={search}
            onChange={setSearch}
            dark
          />
        </div>
      )}
      <div
        className={styles.workspaceItems}
        data-testid="WorkspaceMenu__CustomItems"
      >
        {filteredWorkspaces.map((workspace) => (
          <WorkspaceMenuItem
            classes={{ root: styles.workspaceItem }}
            key={workspace.id}
            workspace={workspace}
            onClick={onWorkspaceClick}
            selected={workspace.id === selectedWorkspaceId}
            onEditClick={onCreateOrUpdateClick}
            onDeleteClick={onDeleteClick}
          />
        ))}

        {filteredWorkspaces.length === 0 && (
          <MenuItem
            className={styles.item}
            disabled
          >
            <ListItemText
              className={styles.itemText}
              primary="No results"
            />
          </MenuItem>
        )}
      </div>
    </Menu>
  );
};

WorkspaceMenu.propTypes = {
  classes: PropTypes.shape(),
  anchor: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  workspaces: PropTypes.arrayOf(PropTypes.shape({})),
  selectedWorkspaceId: PropTypes.string,
  onWorkspaceClick: PropTypes.func.isRequired,
  onCreateOrUpdateClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};
WorkspaceMenu.defaultProps = {
  classes: null,
  anchor: null,
  open: false,
  workspaces: [],
  selectedWorkspaceId: null,
};
