import { createStateSelectorHook } from 'src/lib/sweetState';
import { PerformanceStore } from 'src/store';

const usePerformanceState = createStateSelectorHook(PerformanceStore, ['isCreatingResource']);

export const useIsCreatingResource = () => {
  const { isCreatingResource } = usePerformanceState();

  return isCreatingResource;
};
