import React from 'react';
import { Button, Tooltip } from '@material-ui/core';

import { UserAvatar } from 'src/components/UserAvatar';
import { useStyles } from './useStyles';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';

export const ProfileButton = ({ onClick, name, email, planLabel }) => {
  const styles = useStyles();

  return (
    <Tooltip
      disableFocusListener
      title={
        <div className={styles.tooltipContent}>
          <CustomTypography variant={TYPOGRAPHY_VARIANT.bold12}>{name}</CustomTypography>
          <CustomTypography variant={TYPOGRAPHY_VARIANT.regular12}>{email}</CustomTypography>
          <CustomTypography variant={TYPOGRAPHY_VARIANT.regular12}>
            Current plan: {planLabel}
          </CustomTypography>
        </div>
      }
    >
      {/* 
        HINT: we want to allow user to logout or change customer if "Churnkey Pause Wall" is displayed,
              therefore "z-index: 41" is used to display ProfileButton on top of "Churnkey Pause Wall" overlay("z-index: 40")
      */}
      <Button
        className={styles.button}
        onClick={onClick}
        data-testid="profileButton"
        data-onboarding="ProfileButton"
      >
        <UserAvatar
          name={name}
          className={styles.avatar}
        />
      </Button>
    </Tooltip>
  );
};
