import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { getChannelPerformanceLabel } from 'src/data/channel';
import { getResourceDocumentLabel, isEmailCsvResourceDocument } from 'src/data/performance';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import benchmarkImg from './assets/benchmark.svg';
import talkingPointsImg from './assets/talking-points.svg';
import copyImg from './assets/copy.svg';
import { useStyles } from './useStyles';

const ITEMS = [
  {
    title: 'Benchmark',
    description:
      'Evaluate how each new text you generate compares with content you previously published.',
    img: benchmarkImg,
  },
  {
    title: 'High Performing Talking Points',
    description:
      'Analyze which talking points in your previously published content were most effective.',
    img: talkingPointsImg,
  },
  {
    title: 'Copy Review',
    description: 'Rewrite previously published copy and increase its performance with AI.',
    img: copyImg,
  },
];

export const AccountsDataExtractionPopup = ({ open, onClose, account }) => {
  const styles = useStyles();

  const noLastItem = isEmailCsvResourceDocument(account.channel);

  return (
    <Dialog
      classes={{
        paper: styles.root,
      }}
      open={open}
      onClose={onClose}
      data-testid="AccountsDataExtractionPopup"
    >
      <DialogTitle>
        <CustomTypography
          className={styles.title}
          variant={TYPOGRAPHY_VARIANT.semiBold16}
        >
          {getChannelPerformanceLabel(account.channel) || getResourceDocumentLabel(account.channel)}{' '}
          connected successfully!
        </CustomTypography>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <CustomTypography
          className={styles.contentTitle}
          variant={TYPOGRAPHY_VARIANT.h3}
        >
          Get the most out of this integration
        </CustomTypography>
        {(noLastItem ? ITEMS.slice(0, 2) : ITEMS).map((item) => (
          <div
            className={styles.item}
            key={item.title}
          >
            <img
              src={item.img}
              alt={item.title}
            />
            <div>
              <CustomTypography
                className={styles.itemTitle}
                variant={TYPOGRAPHY_VARIANT.medium14}
              >
                {item.title}
              </CustomTypography>
              <CustomTypography
                className={styles.itemDescription}
                variant={TYPOGRAPHY_VARIANT.paragraph14}
              >
                {item.description}
              </CustomTypography>
            </div>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AccountsDataExtractionPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
AccountsDataExtractionPopup.defaultProps = {};
