import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    tooltip: {
      margin: theme.spacing(0.5), // display tooltip closer to reference element
      padding: 0,
      background: 'none',
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shape.boxShadowLarge,
    },
  }),
  { name: 'InsightsLeaf' }
);
