import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import useStyles from './useStyles';

export const StonlyIcon = ({ className, stonlyId }) => {
  const styles = useStyles();

  return (
    <HelpOutlineIcon
      className={cx(styles.root, className)}
      data-stonly-id={stonlyId}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

StonlyIcon.propTypes = {
  className: PropTypes.string,
  stonlyId: PropTypes.string,
};

StonlyIcon.defaultProps = {
  className: null,
  stonlyId: '',
};
