import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_WORKSPACE_ASSOCIATED_ACCOUNTS } from './schema';

const QUERY = gql`
  query WorkspaceAssociatedAccounts($customerId: ID!, $id: ID) {
    workspaces(customerId: $customerId, id: $id) {
      edges {
        node {
          ...WorkspaceAssociatedAccounts
        }
      }
    }
  }
  ${FRAGMENT_WORKSPACE_ASSOCIATED_ACCOUNTS}
`;

export const queryWorkspaceAssociatedAccounts = async ({ customerId, id }) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: { customerId, id },
  });

  const data = getSingleProp(result.data);
  return data?.edges[0]?.node?.associatedAccounts || [];
};
