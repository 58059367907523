import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_MULTI_PROMPT_BLOCKS } from './schema';

const MUTATION = gql`
  mutation CreateMultiPromptBlocks(
    $customerId: ID!
    $description: String!
    $inputFields: [JSON!]!
    $toolList: JSON!
  ) {
    createMultiPromptBlocks(
      customerId: $customerId
      description: $description
      inputFields: $inputFields
      toolList: $toolList
    ) {
      ...MultiPromptBlocks
    }
  }
  ${FRAGMENT_MULTI_PROMPT_BLOCKS}
`;

export const createMultiPromptBlocks = async ({
  customerId,
  description,
  inputFields,
  toolList,
}) => {
  const variables = {
    customerId,
    description,
    inputFields,
    toolList,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
