import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      width: 372,
      padding: theme.spacing(3),
      backgroundColor: theme.palette.onboarding.tooltipBackground,
      borderRadius: theme.shape.borderRadius,
      opacity: 1,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
      }),
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(0, 0, 1, 0),
    },
    starIcon: {
      width: 16,
      height: 16,
      marginLeft: theme.spacing(1.125),
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.text.primary,
      whiteSpace: 'pre-wrap',
    },
    counter: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      color: '#6D81A1',
    },
    description: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: '#6D81A1',
      whiteSpace: 'pre-wrap',
    },
    proFeatureDescWrapper: {
      marginTop: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: '#6D81A1',
      fontWeight: theme.typography.fontWeightMedium,
      whiteSpace: 'pre-wrap',
    },
    cta: {
      marginTop: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(17),
      whiteSpace: 'pre-wrap',
      color: '#628BD9',
      opacity: 0.9,
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(3),
      paddingTop: theme.spacing(1),
      borderTop: '1px solid #ECECEC',
    },
    buttonNext: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(17),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.primary.main,
      textDecoration: 'none',
      margin: theme.spacing(0, 1),
    },
    buttonClose: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      '& svg': {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.disabled,
      },
    },
    checkbox: {},
    checkboxIcon: {
      fontSize: theme.typography.pxToRem(13),
      color: '#6D81A1',
    },
    checkboxLabel: {
      fontSize: theme.typography.pxToRem(12),
      color: '#6D81A1',
    },
  }),
  { name: 'Tooltip' }
);
