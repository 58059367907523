import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    image: {
      width: 70,
      height: 70,
      marginBottom: theme.spacing(3),
    },
    channelIcon: {
      height: 40,
      width: 'auto',
    },
    title: {
      marginTop: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.neutral.blackBlue,
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(24),
      color: COLORS.neutral.blackBlue,
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    button: {
      marginTop: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(0.75, 2),
    },
    buttonReset: {
      marginTop: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      textDecoration: 'none',
    },
  }),
  { name: 'PerformanceEmptyState' }
);
