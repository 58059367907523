import React, { memo } from 'react';
import PropTypes from 'prop-types';

export const YoutubeEmbed = memo(({ className, title, videoId }) => {
  return (
    <iframe
      className={className}
      title={title}
      width="780"
      height="435"
      src={`https://www.youtube.com/embed/${videoId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
});

YoutubeEmbed.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  videoId: PropTypes.string.isRequired,
};

YoutubeEmbed.defaultProps = {
  className: null,
  title: null,
};
