import { createStore } from 'react-sweet-state';
import { cloneDeep, map, without } from 'lodash';

import { captureMessage } from 'src/lib/sentry';
import { convertAccountsListToChannelMap } from 'src/data/performance';
import { isApplicationModeInfinity } from 'src/data/system';
import {
  queryWorkspaces,
  createWorkspace,
  updateWorkspace,
  deleteWorkspace,
  queryWorkspace,
  updateWorkspaceAnalyzerTovDefault,
} from 'src/graphql/workspace';
import * as ls from 'src/lib/localData';

const NAME = 'workspace-store';

const persistWorkspaceSelection = (workspaceId) => {
  try {
    ls.currentWorkspaceId.write(workspaceId);
  } catch (error) {
    captureMessage("Couldn't persist workspace selection", {
      level: 'warning',
      extra: {
        error: error.toString(),
        source: 'WorkspaceStore -> persistWorkspaceSelection',
      },
    });
  }
};

const unwrapNodes = (edges) => edges.map((edge) => edge.node);

const unwrapWorkspaces = (workspaceEdges) => {
  const nodes = unwrapNodes(workspaceEdges);
  return nodes.map((node) => node);
};

const INITIAL_STATE = {
  currentWorkspaces: [],
  loadingWorkspaces: false,
  removingWorkspace: false,
  workspacesFetched: false,

  customerId: null,

  loadingWorkspace: false,

  currentWorkspaceId: null,

  updatingWorkspaceAnalyzerTovDefault: false,
};

const prepareWorkspace = (workspace) => {
  let associatedFreestyleTools = workspace?.associatedFreestyleTools;
  try {
    associatedFreestyleTools = workspace?.associatedFreestyleTools.map((freestyleTool) => {
      return {
        ...freestyleTool,
        allInstructionsData: JSON.parse(freestyleTool.allInstructionsData || null),
      };
    });
  } catch (e) {
    // do nothing
  }

  return {
    ...workspace,
    associatedAccountsMap: convertAccountsListToChannelMap(workspace?.associatedAccounts),
    associatedFreestyleTools,
  };
};

const inferCurrentWorkspaceId = (workspaces, userId) => {
  if (!workspaces) {
    return null;
  }

  // In Extension We don't care about preserving "selected workspace" per tab - always use last selected per browser
  const shouldSkipSession = isApplicationModeInfinity();

  // Check if saved in LS workspace is in 'workspaces' list
  const lsWorkspaceId = ls.currentWorkspaceId.read({ skipSession: shouldSkipSession });
  const lsWorkspace = lsWorkspaceId ? workspaces.find((w) => String(w.id) === lsWorkspaceId) : null;

  // Check if  user default workspace is in 'workspaces' list
  const userDefaultWorkspace = userId
    ? workspaces.find((w) => (w.defaultForUserIds || []).includes(userId))
    : null;

  const firstWorkspace = workspaces[0];

  const currentWorkspace = userDefaultWorkspace || lsWorkspace || firstWorkspace;

  return currentWorkspace ? currentWorkspace.id : null;
};

/**
 * Private actions
 */
export const PRIVATE_ACTIONS = {
  addWorkspaceToCache:
    (workspace) =>
    ({ getState, setState }) => {
      const preparedWorkspace = prepareWorkspace(workspace);
      const { currentWorkspaces: list } = getState();
      const newList = [preparedWorkspace, ...list];
      setState({ currentWorkspaces: newList });
    },

  mergeWorkspaceInCache:
    (workspace) =>
    ({ getState, setState }) => {
      const { currentWorkspaces } = getState();
      const preparedWorkspace = prepareWorkspace(workspace);

      const updatedWorkspaces = currentWorkspaces.reduce((acc, w) => {
        if (w.id === preparedWorkspace.id) {
          acc.push({ ...w, ...preparedWorkspace });
        } else {
          acc.push(w);
        }
        return acc;
      }, []);
      setState({ currentWorkspaces: updatedWorkspaces });
    },

  deleteWorkspaceFromCache:
    (workspace) =>
    ({ getState, setState }) => {
      const { currentWorkspaces: list } = getState();
      const newList = list.filter((w) => w.id !== workspace.id);
      setState({ currentWorkspaces: newList });
    },

  getWorkspace:
    (id) =>
    ({ getState }) => {
      const { currentWorkspaces } = getState();
      return currentWorkspaces.find((item) => item.id === id);
    },

  clearState:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },
};

/**
 * Public available actions
 */

export const ACTIONS = {
  init:
    (currentCustomerId) =>
    async ({ getState, setState, dispatch }, { notifications, currentUserId }) => {
      const { customerId } = getState();

      if (!currentCustomerId) {
        dispatch(PRIVATE_ACTIONS.clearState());
        return;
      }

      // in case customerId stayed the same we don't need to refetch workspaces
      if (customerId === currentCustomerId) {
        return;
      }

      dispatch(PRIVATE_ACTIONS.clearState());
      setState({ customerId: currentCustomerId });

      try {
        // Refresh workspaces list for the current customer
        const workspaces = await dispatch(ACTIONS.refreshWorkspaces());

        // Preselect current workspace
        const currentWorkspaceId = inferCurrentWorkspaceId(workspaces, String(currentUserId));
        if (currentWorkspaceId) {
          await dispatch(ACTIONS.selectCurrentWorkspace(currentWorkspaceId));
        } else {
          setState({ currentWorkspaceId: null });
        }
        setState({ workspacesFetched: true });
      } catch (error) {
        // Display notification to user
        notifications.displayWarning(
          'Network error',
          'Error fetching workspaces. Please try reloading the page.'
        );

        // Report "warning" to Sentry
        captureMessage('Error while calling "queryWorkspaces" GQL query', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'WorkspaceStore -> init',
          },
        });
      }
    },

  refreshWorkspaces:
    () =>
    async ({ setState }, { currentCustomerId }) => {
      setState({ loadingWorkspaces: true });

      try {
        const result = await queryWorkspaces(currentCustomerId);
        const workspaces = unwrapWorkspaces(result?.edges || []);

        setState({ currentWorkspaces: workspaces });
        return workspaces;
      } finally {
        setState({ loadingWorkspaces: false });
      }
    },

  updateWorkspace:
    (workspace) =>
    ({ dispatch }) => {
      return updateWorkspace(workspace).then((newWorkspace) => {
        dispatch(PRIVATE_ACTIONS.mergeWorkspaceInCache(newWorkspace));
        return newWorkspace;
      });
    },

  createWorkspace:
    (workspace) =>
    ({ dispatch }) => {
      return createWorkspace(workspace).then((newWorkspace) => {
        dispatch(PRIVATE_ACTIONS.addWorkspaceToCache(newWorkspace));

        dispatch(ACTIONS.selectCurrentWorkspace(newWorkspace.id));
      });
    },

  deleteWorkspace:
    (id) =>
    ({ getState, dispatch, setState }) => {
      const { currentWorkspaces, currentWorkspaceId } = getState();
      if (currentWorkspaceId === id) {
        // if user remove the current workspace set another workspace as the current
        const otherWorkspaces = currentWorkspaces.filter((workspace) => workspace.id !== id);
        dispatch(ACTIONS.selectCurrentWorkspace(otherWorkspaces[0].id)).catch((error) => {
          captureMessage("Couldn't select workspace", {
            level: 'warning',
            extra: {
              error: error.toString(),
              source: 'WorkspaceStore -> deleteWorkspace',
            },
          });
        });
      }
      setState({ removingWorkspace: true });
      return deleteWorkspace(id).then((deletedWorkspace) => {
        setState({ removingWorkspace: false });
        dispatch(PRIVATE_ACTIONS.deleteWorkspaceFromCache(deletedWorkspace));
        return deletedWorkspace;
      });
    },

  storeWorkspace:
    (workspace) =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.mergeWorkspaceInCache(workspace));
    },

  selectCurrentWorkspace:
    (id) =>
    async ({ setState }) => {
      setState({ currentWorkspaceId: id });

      // Write selected ID to LS for future use
      persistWorkspaceSelection(id);
    },

  getCurrentWorkspace:
    () =>
    ({ getState }) => {
      const { currentWorkspaces, currentWorkspaceId } = getState();

      return currentWorkspaces.find((workspace) => workspace.id === currentWorkspaceId);
    },

  getCurrentWorkspaceId:
    () =>
    ({ getState }) => {
      return getState().currentWorkspaceId;
    },

  queryWorkspace:
    (id) =>
    async ({ setState }, { currentCustomerId }) => {
      setState({ loadingWorkspace: true });

      try {
        const result = await queryWorkspace({ customerId: currentCustomerId, id });
        const workspaces = unwrapWorkspaces(result?.edges || []);

        return prepareWorkspace(workspaces[0]);
      } finally {
        setState({ loadingWorkspace: false });
      }
    },

  getCurrentWorkspaces:
    () =>
    ({ getState }) => {
      return getState().currentWorkspaces;
    },

  toggleWorkspaceDefaultForUserId:
    (workspaceId, userId) =>
    ({ getState, setState }) => {
      const { currentWorkspaces } = getState();
      const userIdString = String(userId);

      const selectedWorkspace = currentWorkspaces.find((workspace) => workspace.id === workspaceId);
      const workspaceIsDefaultForUser =
        selectedWorkspace?.defaultForUserIds.includes(userIdString) || false;

      const newList = currentWorkspaces.map((workspace) => {
        // Add/remove "default for this user id" from the requested workspace ...
        if (workspace.id === workspaceId) {
          return {
            ...workspace,
            defaultForUserIds: workspaceIsDefaultForUser
              ? without(selectedWorkspace.defaultForUserIds, userIdString)
              : [...selectedWorkspace.defaultForUserIds, userIdString],
          };

          // ... remove "default for this user id" in other workspaces, if it was just set for the requested one
        } else if (
          !workspaceIsDefaultForUser &&
          workspace.defaultForUserIds.includes(userIdString)
        ) {
          return {
            ...workspace,
            defaultForUserIds: without(selectedWorkspace?.defaultForUserIds, userIdString),
          };
        }
        return workspace;
      });
      setState({ currentWorkspaces: newList });
    },

  updateWorkspaceAnalyzerTovDefault:
    (generationTool, customTovId) =>
    async ({ dispatch, setState }) => {
      const currentWorkspace = dispatch(ACTIONS.getCurrentWorkspace());

      setState({ updatingWorkspaceAnalyzerTovDefault: true });
      try {
        const customTov = await updateWorkspaceAnalyzerTovDefault({
          generationTool,
          customTovId,
          workspaceId: currentWorkspace.id,
        });

        const workspaceDefaultTovAnalyzerForTool = currentWorkspace.defaultTovAnalyzerForTool || [];

        let newList = [];
        if (map(workspaceDefaultTovAnalyzerForTool, 'generationTool').includes(generationTool)) {
          newList = workspaceDefaultTovAnalyzerForTool.map((item) =>
            item.generationTool === generationTool
              ? { ...item, customTovId: customTov.id, customTovName: customTov.name }
              : item
          );
        } else {
          const newItem = {
            generationTool,
            customTovId: customTov.id,
            customTovName: customTov.name,
          };
          newList = [...workspaceDefaultTovAnalyzerForTool, newItem];
        }

        const newWorkspace = {
          ...currentWorkspace,
          defaultTovAnalyzerForTool: newList,
        };

        dispatch(PRIVATE_ACTIONS.mergeWorkspaceInCache(newWorkspace));
      } finally {
        setState({ updatingWorkspaceAnalyzerTovDefault: false });
      }
    },
};

export const WorkspaceStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});
