import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Editor } from 'slate';
import { useFocused, useSelected, useSlateStatic } from 'slate-react';

import { EditIcon } from 'src/assets/icons/EditIcon';
import { UnlinkIcon } from 'src/assets/icons/Unlink';
import { selectFocusedLeaf } from '../../../../lib';
import { isLinkActive, unwrapLink } from '../../../../utils';
import { CustomSlateContext } from '../../../CustomSlate';
import { InlineChromiumBugfix } from '../InlineChromiumBugfix';
import { useStyles } from './useStyles';

export const Link = ({ style, attributes, element, children }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const styles = useStyles();
  const { openLinkPopup, closeLinkPopup, isLinkInsertOpen, openLinkInsertPopup } =
    useContext(CustomSlateContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedText, setSelectedText] = useState('');

  const handleClear = useCallback(() => {
    if (isLinkActive(editor)) {
      unwrapLink(editor);
    }
  }, [editor]);

  const handleEditClick = () => {
    selectFocusedLeaf(editor);

    openLinkInsertPopup();
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      openLinkPopup();
    } else {
      closeLinkPopup();
    }
  }, [openLinkPopup, closeLinkPopup, isOpen]);

  useEffect(() => {
    setIsOpen(selected && focused);
  }, [selected, focused]);

  useEffect(() => {
    if (isLinkInsertOpen) setIsOpen(false);
  }, [isLinkInsertOpen]);

  useEffect(() => {
    if (editor.selection) {
      setSelectedText(Editor.string(editor, editor.selection));
    }
  }, [editor, editor.selection]);

  useEffect(() => {
    if (selectedText) {
      setIsOpen(false);
    }
  }, [selectedText]);

  const formatUrlPrefix = (url) => {
    url = url.trim();

    if (!/^https?:\/\//i.test(url)) {
      url = 'http://' + url;
    }

    return url;
  };

  return (
    <>
      <InlineChromiumBugfix />
      <div className={styles.root}>
        <a
          className={styles.link}
          href={element.url}
          style={style}
          {...attributes}
        >
          {children}
        </a>

        {isOpen && (
          <div
            className={styles.popupContainer}
            contentEditable={false}
          >
            Visit URL:
            <a
              className={cx(styles.link, styles.ellipsized)}
              href={formatUrlPrefix(element.url)}
              rel="noreferrer"
              referrerPolicy="no-referrer"
              target="_blank"
            >
              {element.url}
            </a>
            <EditIcon
              className={styles.editIcon}
              onClick={handleEditClick}
            />
            <UnlinkIcon
              className={styles.unlinkIcon}
              onClick={handleClear}
            />
          </div>
        )}
      </div>
      <InlineChromiumBugfix />
    </>
  );
};

Link.propTypes = {
  attributes: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  element: PropTypes.shape().isRequired,
  style: PropTypes.object,
};
