import { useHistory } from 'react-router-dom';

import { useEffectOnce } from './useEffectOnce';

const getLocationString = ({ pathname, search, hash }) => {
  return `${pathname}${search}${hash}`;
};

export function useLocationBlocker() {
  const history = useHistory();

  useEffectOnce(() =>
    history.block((location, action) => {
      return (
        action !== 'PUSH' || getLocationString(location) !== getLocationString(history.location)
      );
    })
  );
}
