import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ResizeObserverPolyfill from 'resize-observer-polyfill';
import Tooltip from '@material-ui/core/Tooltip';

import { useStyles } from './useStyles';

const TruncatedTooltip = ({ className, title, children, ...restProps }) => {
  const styles = useStyles();

  const [element, setElement] = useState(null);
  const [overflowed, setOverflowed] = useState(false);

  const classes = useMemo(
    () => ({
      tooltip: cx(styles.tooltip, className),
    }),
    [styles, className]
  );

  const updateOverflowed = useCallback((el) => {
    setOverflowed(el && (el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight));
  }, []);

  const refCallback = useCallback(
    (ref) => {
      setElement(ref);
      updateOverflowed(ref);
    },
    [updateOverflowed]
  );

  // Listen to element resizing
  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const resizeObserver = new ResizeObserverPolyfill(() => {
      if (element) {
        updateOverflowed(element);
      }
    });
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [element, updateOverflowed]);

  // Listen to child changes
  useEffect(() => {
    updateOverflowed(element);
  }, [element, children, updateOverflowed]);

  return (
    <Tooltip
      classes={classes}
      title={overflowed ? title : ''}
      {...restProps}
    >
      {children(refCallback)}
    </Tooltip>
  );
};

TruncatedTooltip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired,
};
TruncatedTooltip.defaultProps = {
  className: null,
};

export default TruncatedTooltip;
