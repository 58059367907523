import { useEffect } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import pubsub, { PUBSUB } from 'src/lib/pubsub';
import { ROUTES } from 'src/routes';

export const useSubscribeForPubsub = (setIsInsightsPanelOpen) => {
  const location = useLocation();
  // Listen for "display variation details" topic
  useEffect(() => {
    const onDisplayVariationDetails = () => {
      setIsInsightsPanelOpen(true);
    };

    const token = pubsub.subscribe(PUBSUB.DISPLAY_VARIATION_DETAILS, onDisplayVariationDetails);
    return () => pubsub.unsubscribe(token);
  }, [setIsInsightsPanelOpen]);

  // Listen for "display variation details" topic
  useEffect(() => {
    const onCloseVariationDetails = () => {
      setIsInsightsPanelOpen(false);
    };

    const token = pubsub.subscribe(PUBSUB.CLOSE_VARIATION_DETAILS, onCloseVariationDetails);
    return () => pubsub.unsubscribe(token);
  }, [setIsInsightsPanelOpen]);

  useEffect(() => {
    if (location.pathname.includes(ROUTES.PROJECTS) && location.pathname !== ROUTES.PROJECTS) {
      return;
    }
    setIsInsightsPanelOpen(false);
  }, [location, setIsInsightsPanelOpen]);
};
