import { createStore } from 'react-sweet-state';
import { unionBy, get } from 'lodash';

import { isAbortError } from 'src/lib/errors';
import Authentication from 'src/utils/Authentication';
import {
  getOnboardingStageValue,
  isOnboardingStageFinished,
  ONBOARDING_STAGE_STATUS,
} from 'src/data/onboarding';
import { BOOKMARK_TYPE, formatProjectTypeBookmarkName } from 'src/data/bookmark';
import { getRecommendedProjectTypes, isDocumentProject, PROJECT_TYPE } from 'src/data/project';
import {
  authenticate,
  register,
  confirmRegistration,
  queryCurrentUser,
  sendTrackingParams,
  updateOnboardingStage,
  createExternalSignup,
  acceptExternalSignup,
  createUserBookmark,
  createUserBookmarks,
  deleteUserBookmark,
  updateUserCompanyInformation,
  updateUser,
  confirmTokenLogin,
} from 'src/graphql/user';
import mixpanel from 'src/lib/mixpanel';
import * as gtm from 'src/lib/gtm';
import * as ls from 'src/lib/localData';
import { ROUTE_PARAMS, ROUTES } from 'src/routes';
import { getTrackingPayload } from 'src/lib/trackingPayload';
import { isMobileDevice, getDeviceType } from 'src/lib/userAgent';
import { formatProjectCreateRoute } from 'src/lib/routing';
import { parseJson } from 'src/lib/common';
import { sentryClearUser, sentrySetUser } from 'src/lib/sentry';
import { prepareUser } from './utils';
import * as events from 'src/lib/events';
import { getAnonymousSessionId } from 'src/lib/app';
import { encodeSha1 } from 'src/utils/encodeSha1';
import { logout } from '../../graphql/user/logout';

export const NAME = 'user';

const INITIAL_STATE = {
  currentUser: null,
  currentUserLoading: false,

  abortController: null,
};

/**
 * Private actions
 */
export const PRIVATE_ACTIONS = {
  init:
    () =>
    async ({ dispatch }) => {
      if (window.location.pathname === ROUTES.MAGIC_LINK_CALLBACK) {
        Authentication.signOut();
        return;
      }

      try {
        await dispatch(PRIVATE_ACTIONS.refreshCurrentUser());

        events.trackEvent(events.APP.currentUserInitialRequestCompleted, {
          sessionId: getAnonymousSessionId(),
        });
      } catch (e) {
        /* do nothing */
      }
    },

  mergeIntoCurrentUser:
    (userData) =>
    ({ getState, setState }) => {
      const { currentUser } = getState();
      const newValue = {
        ...currentUser,
        ...userData,
      };
      setState({ currentUser: newValue });
    },

  mergeIntoOnboardingStages:
    (stageData) =>
    ({ getState, dispatch }) => {
      const { currentUser } = getState();
      if (!currentUser) {
        return;
      }
      const newStages = unionBy([stageData], currentUser.onboardingStages, 'name');
      dispatch(PRIVATE_ACTIONS.mergeIntoCurrentUser({ onboardingStages: newStages }));
    },

  refreshCurrentUser:
    () =>
    async ({ getState, setState }) => {
      if (!Authentication.isAuthenticated()) {
        throw new Error('Not authenticated');
      }

      // Cancel previous active request
      const { abortController: existingAbortController } = getState();
      if (existingAbortController) {
        existingAbortController.abort();
      }

      const abortController = new AbortController();
      setState({ currentUserLoading: true, abortController });

      try {
        const result = await queryCurrentUser({ abortSignal: abortController.signal });

        const user = prepareUser(result);

        setState({
          currentUser: user,
          currentUserLoading: false,
          abortController: null,
        });

        sentrySetUser(user);

        return user;
      } catch (e) {
        // We don't use "finally" for setting "loading" to "false", cause when current request gets aborted,
        // then we want "loading" to remain "true" for the new request
        if (!isAbortError(e)) {
          setState({ currentUserLoading: false });
          throw e;
        }
      }
    },

  trackUser: (source) => () => {
    sendTrackingParams(source).catch(() => {
      /* do nothing */
    });
  },

  clearState:
    () =>
    ({ setState }) => {
      setState({
        currentUser: null,
      });

      sentryClearUser();
    },
};

/**
 * Public available actions
 */
export const ACTIONS = {
  login:
    (email, password) =>
    ({ dispatch }) => {
      const device = getDeviceType();

      return authenticate({ email, password, device })
        .then(({ token }) => {
          return dispatch(ACTIONS.loginWithToken(token));
        })
        .catch((error) => {
          Authentication.signOut();
          throw error;
        });
    },

  loginOAuth:
    (data) =>
    ({ dispatch }) => {
      const { token, wasUserCreated } = data;

      return dispatch(ACTIONS.loginWithToken(token)).then((currentUser) => {
        if (wasUserCreated) {
          const customer = currentUser.customers?.[0]; // new user only has one customer
          gtm.pushDataLayerEvent(gtm.EVENTS.googleAuthFirstTime, {
            mobile: isMobileDevice(),
            email: currentUser.email,
            customer_id: customer ? customer.id : '',
            customer_email_sha1: encodeSha1(currentUser.email),
          });
          dispatch(PRIVATE_ACTIONS.trackUser('oauth'));
        }

        return currentUser;
      });
    },

  loginWithToken:
    (token) =>
    ({ dispatch }) => {
      Authentication.signIn(token);

      return dispatch(PRIVATE_ACTIONS.refreshCurrentUser());
    },

  register:
    (email, password, inviteToken) =>
    ({ dispatch }) => {
      const signUpDevice = getDeviceType();

      return register(email, password, signUpDevice, inviteToken)
        .then(({ token }) => {
          return dispatch(ACTIONS.loginWithToken(token));
        })
        .then((currentUser) => {
          dispatch(PRIVATE_ACTIONS.trackUser('email_signup'));

          return currentUser;
        });
    },

  confirmEmail:
    (confirmationToken) =>
    ({ dispatch }) => {
      const confirmationDevice = getDeviceType();

      return confirmRegistration(confirmationToken, confirmationDevice)
        .then(({ token }) => {
          return dispatch(ACTIONS.loginWithToken(token));
        })
        .then(async (currentUser) => {
          const customer = currentUser.customers?.[0]; // new user only has one customer
          gtm.pushDataLayerEvent(gtm.EVENTS.userConfirmation, {
            mobile: isMobileDevice(),
            customer_id: customer ? customer.id : '',
            customer_email_sha1: await encodeSha1(currentUser.email),
          });
          dispatch(PRIVATE_ACTIONS.trackUser('email_verify'));

          return currentUser;
        });
    },

  createExternalSignup: (email) => () => {
    // Store "tracking payload" in signup invite, until it can be copied to "User" entity
    const trackingPayload = JSON.stringify(getTrackingPayload() || {});
    return createExternalSignup({
      email,
      trackingPayload,
      source: 'external_email_signup_request',
    });
  },

  acceptExternalSignup:
    (signupToken, password) =>
    ({ dispatch }) => {
      const signupDevice = getDeviceType();

      return acceptExternalSignup({ token: signupToken, password, signupDevice })
        .then(({ token }) => {
          return dispatch(ACTIONS.loginWithToken(token));
        })
        .then(async (currentUser) => {
          const customer = currentUser.customers?.[0]; // new user only has one customer
          gtm.pushDataLayerEvent(gtm.EVENTS.userConfirmation, {
            mobile: isMobileDevice(),
            customer_id: customer ? customer.id : '',
            customer_email_sha1: await encodeSha1(currentUser.email),
          });
          dispatch(PRIVATE_ACTIONS.trackUser('external_email_signup'));

          return currentUser;
        });
    },

  logout:
    (silent = false, backendLogout = false) =>
    ({ dispatch }) => {
      if (backendLogout) {
        // Notify BE about the "logout", no need to await it or handle errors
        logout(Authentication.getToken()).catch(() => null);
      }

      // Clear store state of log-outing user
      dispatch(PRIVATE_ACTIONS.clearState());

      // Reset in-browser auth state
      Authentication.signOut();

      // Track 'logout' on mixpanel
      if (!silent) {
        mixpanel.track(mixpanel.EVENTS.USER_LOGOUT);
      }
      // Clear mixpanel local state
      mixpanel.reset();

      // Clear some "session" local values when user logs out
      ls.coTutorialWasShownInSession.remove();

      // Clear selected category on logout
      ls.selectedProjectCategory.remove();
    },

  verifyAuthentication:
    () =>
    ({ dispatch }) => {
      return dispatch(PRIVATE_ACTIONS.refreshCurrentUser())
        .then(() => {
          Authentication.notifyExtension();
        })
        .catch((error) => {
          Authentication.signOut();
          throw error;
        });
    },

  refreshCurrentUser:
    () =>
    ({ dispatch }) => {
      return dispatch(PRIVATE_ACTIONS.refreshCurrentUser());
    },

  finishOnboardingStage:
    (stageName) =>
    ({ getState, dispatch }) => {
      const { currentUser } = getState();

      // Maybe this stage was already finished
      if (isOnboardingStageFinished(currentUser.onboardingStages, stageName)) {
        return Promise.resolve();
      }

      return updateOnboardingStage({
        name: stageName,
        status: ONBOARDING_STAGE_STATUS.finished,
      }).then((stageData) => {
        dispatch(PRIVATE_ACTIONS.mergeIntoOnboardingStages(stageData));
      });
    },

  isFinishedOnboardingStage:
    (stageName) =>
    ({ getState }) => {
      const { currentUser } = getState();
      const onboardingStages = get(currentUser, 'onboardingStages');
      return isOnboardingStageFinished(onboardingStages, stageName);
    },

  updateOnboardingStageValue:
    (stageName, value, status) =>
    async ({ dispatch }) => {
      const stageData = await updateOnboardingStage({
        name: stageName,
        value,
        status,
      });
      dispatch(PRIVATE_ACTIONS.mergeIntoOnboardingStages(stageData));
    },

  getOnboardingStageValue:
    (stageName) =>
    ({ getState }) => {
      const { currentUser } = getState();
      const onboardingStages = get(currentUser, 'onboardingStages');
      return getOnboardingStageValue(onboardingStages, stageName);
    },

  createProjectTypeBookmark:
    (projectType, generationTool) =>
    ({ getState, dispatch }) => {
      const name = formatProjectTypeBookmarkName(projectType);
      const link = isDocumentProject(projectType)
        ? formatProjectCreateRoute(projectType, {
            [ROUTE_PARAMS.generationTool]: generationTool,
          })
        : formatProjectCreateRoute(projectType);
      const metadata = { projectType, generationTool };

      return createUserBookmark({
        type: BOOKMARK_TYPE.projectType,
        name,
        link,
        metadata,
      }).then((bookmark) => {
        const { currentUser } = getState();
        const newList = [...currentUser.bookmarks, bookmark];
        dispatch(PRIVATE_ACTIONS.mergeIntoCurrentUser({ bookmarks: newList }));
      });
    },

  getProjectTypeBookmark:
    ({ projectType, generationTool }) =>
    ({ getState }) => {
      const { currentUser } = getState();

      const bookmarks = (currentUser && currentUser.bookmarks) || [];
      return bookmarks.find((bookmark) => {
        // If it's a newer bookmark, and has metadata, then search by it
        const metadata = parseJson(bookmark.metadata);
        if (metadata) {
          return (
            metadata.projectType === projectType &&
            (!generationTool || metadata.generationTool === generationTool)
          );
        }

        // If there is no metadata, then try searching by link URL
        const link = formatProjectCreateRoute(projectType, {
          [ROUTE_PARAMS.generationTool]: generationTool,
        });
        return bookmark.link === link;
      });
    },

  isGenerationToolBookmarked:
    (generationTool) =>
    ({ dispatch }) => {
      const bookmark = dispatch(
        ACTIONS.getProjectTypeBookmark({
          projectType: PROJECT_TYPE.document,
          generationTool,
        })
      );
      return bookmark != null;
    },

  deleteProjectTypeBookmark:
    (projectType, generationTool) =>
    ({ getState, dispatch }) => {
      const bookmark = dispatch(ACTIONS.getProjectTypeBookmark({ projectType, generationTool }));
      if (!bookmark) {
        return Promise.resolve();
      }

      return deleteUserBookmark(bookmark.id).then(() => {
        const { currentUser } = getState();
        const newList = (currentUser.bookmarks || []).filter((item) => item.id !== bookmark.id);
        dispatch(PRIVATE_ACTIONS.mergeIntoCurrentUser({ bookmarks: newList }));
      });
    },

  updateCompanyInformation:
    ({ userRoles, companySize, contactInformation }) =>
    ({ dispatch }) => {
      return updateUserCompanyInformation({ userRoles, companySize, contactInformation }).then(
        (updatedUser) => {
          // Parse "company information" from JSON
          const { segment, companyInformation } = prepareUser(updatedUser);

          // Merge updated info into "current user"
          dispatch(PRIVATE_ACTIONS.mergeIntoCurrentUser({ segment, companyInformation }));
        }
      );
    },

  createInitialUserProjectBookmarks:
    (userRoles) =>
    ({ getState, dispatch }) => {
      // Recommended "project/asset" types for these user roles
      const recommendations = getRecommendedProjectTypes(userRoles);

      // Prepare bookmarks list, based on recommendations
      const bookmarks = recommendations.map(({ projectType, generationTool }) => {
        const name = formatProjectTypeBookmarkName(projectType);
        const link = isDocumentProject(projectType)
          ? formatProjectCreateRoute(PROJECT_TYPE.document, {
              [ROUTE_PARAMS.generationTool]: generationTool,
            })
          : formatProjectCreateRoute(projectType);
        return {
          type: BOOKMARK_TYPE.projectType,
          name,
          link,
          metadata: { projectType, generationTool, recommended: true },
        };
      });

      // Send to BE and merge into "current user" data
      return createUserBookmarks(bookmarks).then((createdBookmarks) => {
        const { currentUser } = getState();
        const newList = [...currentUser.bookmarks, ...createdBookmarks];
        dispatch(PRIVATE_ACTIONS.mergeIntoCurrentUser({ bookmarks: newList }));
      });
    },

  getCurrentUser:
    () =>
    ({ getState }) => {
      return getState().currentUser;
    },

  getCurrentUserId:
    () =>
    ({ getState }) => {
      const { currentUser } = getState();
      return currentUser ? currentUser.id : null;
    },

  setName:
    (name) =>
    ({ getState, setState }) => {
      const { currentUser } = getState();

      setState({ currentUser: { ...currentUser, name } });
    },

  updateUser: (customerId, defaultWorkspaceId) => () => {
    return updateUser(customerId, defaultWorkspaceId);
  },
  confirmTokenLogin:
    (authToken) =>
    ({ dispatch }) => {
      const loginDevice = getDeviceType();

      return confirmTokenLogin(authToken, loginDevice).then(({ token }) => {
        return dispatch(ACTIONS.loginWithToken(token));
      });
    },
};

const Store = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});

export default Store;
