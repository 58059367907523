import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ButtonText } from 'src/components/ButtonText';
import { PersonaAvatar } from 'src/components/PersonaAvatar';
import { useStyles } from './useStyles';

export const ButtonAddPersona = ({ className, align, onClick }) => {
  const styles = useStyles({ align });

  return (
    <div
      className={cx(className, styles.root)}
      onClick={onClick}
    >
      <div className={styles.wrapper}>
        <PersonaAvatar className={styles.avatar} />
        <ButtonText className={styles.button}>+ Add</ButtonText>
      </div>
    </div>
  );
};

ButtonAddPersona.propTypes = {
  className: PropTypes.string,
  align: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ButtonAddPersona.defaultProps = {
  className: null,
};
