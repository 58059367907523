import { useCallback, useEffect, useState } from 'react';
import { Text as SlateText } from 'slate';

import { createDetectedBrandTermsRangeRefs } from './decorations';
import { getRangeRefsIntersectionsWithNode } from './queries';

export const useDecorateTextInsights = (editor, marks) => {
  const [rangeRefs, setRangeRefs] = useState([]);

  useEffect(() => {
    if (!editor || !marks || marks.length === 0) {
      setRangeRefs([]);
      return;
    }

    // When "positioned marks" change, we should calculate new range references
    const newRangeRefs = createDetectedBrandTermsRangeRefs(editor, marks);

    // Old references should be cleared and replaced with the new ones
    setRangeRefs((prevRangeRefs) => {
      prevRangeRefs.forEach((ref) => ref.unref());
      return newRangeRefs;
    });
  }, [editor, marks]);

  return useCallback(
    ([node, path]) => {
      // We care only about "text" nodes, as we highlight only them
      if (!SlateText.isText(node)) {
        return [];
      }

      return getRangeRefsIntersectionsWithNode(editor, rangeRefs, path);
    },
    [editor, rangeRefs]
  );
};
