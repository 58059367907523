import { createTheme, darken, alpha } from '@material-ui/core/styles';

import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_TEXT_PRIMARY,
  COLOR_TEXT_SECONDARY,
  COLOR_TEXT_SECONDARY_BLUISH,
  COLOR_TEXT_DISABLED,
  COLOR_DIVIDER,
  COLOR_DIVIDER_GRAY,
  COLOR_DIVIDER_LIGHT_GRAY,
  COLOR_DIVIDER_LIGHT_BLUE,
  COLOR_DARK_GRAY,
  COLOR_DARK_HOVER,
  COLOR_DARK_HOVER_SECONDARY,
  COLOR_DARK_SELECTED,
  COLOR_ICON_INACTIVE,
  COLOR_ICON_DISABLED,
  COLOR_FAVORITE,
  COLOR_M_GREY,
  COLORS,
  FONTS,
  APPLICATION_BAR_HEIGHT,
} from 'src/data/ui';

export const createAppTheme = () => {
  const theme = createTheme({
    maxContainerWidth: 1920,
    centeredContainerMaxWidth: 1080,
    centeredContainerMinWidth: 800,
    narrowestContainerWidth: 340,

    palette: {
      primary: {
        main: COLOR_PRIMARY,
      },
      secondary: {
        main: COLOR_SECONDARY,
      },
      cancel: {
        main: '#DA5667',
        disabled: 'rgba(218, 86, 103, 0.35)',
      },

      error: {
        main: '#F66969',
      },

      background: {
        default: COLORS.neutral.silver,
        paper: '#fff',
        panel: '#F7F9FE',
        disabled: '#DADFE9',
      },

      divider: COLOR_DIVIDER,
      dividerGray: COLOR_DIVIDER_GRAY,
      dividerLightGray: COLOR_DIVIDER_LIGHT_GRAY,
      dividerLightBlue: COLOR_DIVIDER_LIGHT_BLUE,
      hintBorder: '#DBE8FF',
      darkGray: COLOR_DARK_GRAY,
      darkHover: COLOR_DARK_HOVER,
      darkHoverSecondary: COLOR_DARK_HOVER_SECONDARY,
      darkSelected: COLOR_DARK_SELECTED,

      iconInactive: COLOR_ICON_INACTIVE,
      iconDisabled: COLOR_ICON_DISABLED,

      mGrey: COLOR_M_GREY,

      logo: '#132853',
      ctaBg: '#D8FDEF',
      favorite: COLOR_FAVORITE,

      badge: {
        red: {
          background: '#FFEBEF',
          text: '#FF5981',
        },
      },

      text: {
        primary: COLORS.gray.black,
        secondary: COLOR_TEXT_SECONDARY,
        secondaryBluish: COLOR_TEXT_SECONDARY_BLUISH,
        input: COLOR_TEXT_PRIMARY,
        disabled: COLOR_TEXT_DISABLED,
        content: '#6A7785',
      },

      bubble: {
        commonText: '#BABABA',
        suggestionText: '#30353C',
      },

      onboarding: {
        tooltipBackground: '#F4F7FF',
      },

      gray: {
        ...COLORS.gray,
      },
    },

    typography: {
      fontFamily: FONTS.Roboto,
      fontSize: 14,
      fontRobotoSlab: FONTS.RobotoSlab,

      fontWeightSemiBold: 600,
      fontWeightBold: 700,

      h1: { letterSpacing: 'normal' },
      h2: { letterSpacing: 'normal' },
      h3: { letterSpacing: 'normal' },
      h4: { letterSpacing: 'normal' },
      h5: { letterSpacing: 'normal' },
      h6: { letterSpacing: 'normal' },
      bold16: {},
      bold14: {},
      bold12: {},
      semiBold16: {},
      semiBold14: {},
      semiBold12: {},
      medium16: {},
      medium14: {},
      medium12: {},
      regular16: {},
      regular14: {},
      regular12: {},
      paragraph16: {},
      paragraph14: {},
      paragraph12: {},
      body1: {
        letterSpacing: 'normal',
      },
      body2: {
        letterSpacing: 'normal',
      },
      button: {
        textTransform: 'none',
      },
    },

    shape: {
      appbarHeight: APPLICATION_BAR_HEIGHT,
      appbarButtonHeight: 25,
      actionBarHeight: 40,

      border: `1px solid ${COLOR_DIVIDER}`,
      borderGray: `1px solid ${COLOR_DIVIDER_GRAY}`,
      borderDarkGray: `1px solid ${COLORS.neutral.darkGray}`,
      borderLightGray: `1px solid ${COLOR_DIVIDER_LIGHT_GRAY}`,
      borderLightBlue: `1px solid ${COLOR_DIVIDER_LIGHT_BLUE}`,
      borderPrimaryLight: `1px solid ${COLORS.primary.light}`,
      borderPrimaryMedium: `1px solid ${COLORS.primary.medium}`,
      borderPrimary: `1px solid ${COLORS.primary.default}`,
      borderGray50: `1px solid ${COLORS.gray.gray50}`,
      borderGray60: `1px solid ${COLORS.gray.gray60}`,
      borderGray70: `1px solid ${COLORS.gray.gray70}`,
      borderGray80: `1px solid ${COLORS.gray.gray80}`,
      borderDashedGray50: `1px dashed ${COLORS.gray.gray50}`,

      borderRadiusMinimal: 2,
      borderRadiusSmall: 4,
      borderRadiusMedium: 6,
      borderRadius: 8,
      borderRadiusSemiLarge: 12,
      borderRadiusLarge: 16,
      borderRadiusExtraLarge: 40,

      // Shadow for paper-like panels
      boxShadow: '0 1px 1px 0 #E2E7EA',
      boxShadow2: '0px 4px 12px rgba(115, 136, 189, 0.25)',
      boxShadowNormal: '0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.1)',
      boxShadowMedium: '0px 2px 4px -2px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)',
      boxShadowLarge: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
      boxShadowExtraLarge:
        '0px 8px 10px -6px rgba(0, 0, 0, 0.1),  0px 20px 25px -5px rgba(0, 0, 0, 0.1)',

      boxShadowButton: '0px 6px 10px rgba(65, 120, 228, 0.1)',
      boxShadowBriefContent: '0px 1px 3px 0px rgba(69, 79, 91, 0.1)',

      boxShadowTop: '0px -4px 3px rgba(50, 50, 50, 0.75)',
    },

    zIndex: {
      onboarding: 1400,
      bubble: 1250,
      bubbleOnboarding: 1500,
      absoluteElement: 100,
    },

    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButton: {
        disableElevation: true,
      },
      MuiTab: {
        disableRipple: true,
      },
    },
  });

  // Further typography customization
  theme.typography = {
    ...theme.typography,

    h1: {
      ...theme.typography.h1,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: theme.typography.pxToRem(38),
      fontWeight: theme.typography.fontWeightMedium,
    },
    h2: {
      ...theme.typography.h2,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(32),
      fontWeight: theme.typography.fontWeightMedium,
    },
    h3: {
      ...theme.typography.h3,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium,
    },
    h4: {
      ...theme.typography.h4,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightMedium,
    },
    h5: {
      ...theme.typography.h5,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(19),
      fontWeight: theme.typography.fontWeightMedium,
    },
    h6: {
      ...theme.typography.h6,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
    bold16: {
      ...theme.typography.bold16,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightBold,
    },
    bold14: {
      ...theme.typography.bold14,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    bold12: {
      ...theme.typography.bold12,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
    },
    semiBold16: {
      ...theme.typography.semiBold16,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    semiBold14: {
      ...theme.typography.semiBold14,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    semiBold12: {
      ...theme.typography.semiBold12,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    medium16: {
      ...theme.typography.medium16,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightMedium,
    },
    medium14: {
      ...theme.typography.medium14,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },
    medium12: {
      ...theme.typography.medium12,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
    regular16: {
      ...theme.typography.regular16,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
    },
    regular14: {
      ...theme.typography.regular14,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
    },
    regular12: {
      ...theme.typography.regular12,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
    },
    paragraph16: {
      ...theme.typography.paragraph16,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
    },
    paragraph14: {
      ...theme.typography.paragraph14,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    paragraph12: {
      ...theme.typography.paragraph12,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
    },
  };

  // Further overrides
  theme.overrides.MuiCssBaseline = {
    '@global': {
      body: {
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
  };

  theme.overrides.MuiAppBar = {
    root: {
      zIndex: theme.zIndex.drawer + 1,
    },
  };

  theme.overrides.MuiToolbar = {
    regular: {
      height: theme.shape.appbarHeight,

      [theme.breakpoints.up('sm')]: {
        height: theme.shape.appbarHeight,
      },
    },

    gutters: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },
    },
  };

  theme.overrides.MuiTooltip = {
    popper: {
      opacity: 1,
    },

    tooltip: {
      padding: '15px',
      whiteSpace: 'pre-wrap',

      maxWidth: '315px',
      backgroundColor: COLOR_DARK_GRAY,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
      color: COLORS.neutral.white,
      boxShadow: '0px 7px 10px rgba(55, 73, 107, 0.0818127)',
    },

    arrow: {
      color: COLOR_DARK_GRAY,
    },
  };

  theme.overrides.MuiTypography = {
    root: {
      letterSpacing: 'normal',

      '&.hint-block': {
        padding: '15px',
        border: `1px solid ${theme.palette.divider}`,
        borderLeft: `24px solid ${theme.palette.hintBorder}`,
        borderRadius: theme.shape.borderRadius,

        '& p': {
          margin: '0',
        },

        '& p + p': {
          marginTop: theme.spacing(0.5),
        },
      },
    },
  };

  theme.overrides.MuiFormLabel = {
    root: {
      color: '#979797',
    },
  };

  theme.overrides.MuiFormControlLabel = {
    root: {
      marginLeft: 0,
      marginRight: 0,
    },
    label: {
      ...theme.typography.regular14,
      color: COLORS.gray.black,
    },
  };

  theme.overrides.MuiFormHelperText = {
    root: {
      marginTop: 5,
    },
    contained: {
      marginLeft: 0,
      marginRight: 0,
    },
  };

  theme.overrides.MuiInputBase = {
    root: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.text.primary,
      borderColor: COLORS.primary.light,

      '&$disabled': {
        backgroundColor: COLORS.gray.gray80,
        cursor: 'not-allowed',
      },
    },
    input: {
      height: 'auto',
      padding: theme.spacing(2),

      '&$disabled': {
        backgroundColor: COLORS.gray.gray80,
        color: COLORS.gray.gray50,
        cursor: 'not-allowed',
        '& p': {
          color: COLORS.gray.gray50,
        },
        '& svg': {
          color: COLORS.gray.gray50,
        },
      },
    },
  };

  theme.overrides.MuiInputLabel = {
    outlined: {
      transform: 'translate(14px, 13px) scale(1)',
    },
  };

  theme.overrides.MuiInput = {
    input: {
      fontSize: theme.typography.pxToRem(16),
    },
    underline: {
      '&:before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },

      '&:hover:not($disabled):before': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },

      '&:after': {
        borderColor: '#83A3DF',
        borderWidth: '1px',
      },
    },
  };

  theme.overrides.MuiOutlinedInput = {
    root: {
      overflow: 'hidden',
      backgroundColor: theme.palette.common.white,

      '&:hover $notchedOutline': {
        borderColor: COLORS.gray.gray50,
      },

      '&$disabled $notchedOutline': {
        borderColor: COLORS.gray.gray60,
      },

      '&$focused $notchedOutline': {
        borderColor: COLORS.primary.default,
        borderWidth: 1,
      },
    },

    input: {
      padding: theme.spacing(2),

      '&::-webkit-input-placeholder': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(19),
        color: COLORS.neutral.mediumLightGray,
        opacity: 1,
      },
    },

    multiline: {
      padding: 0,
    },

    inputMultiline: {
      padding: '14px 12px',

      '&:placeholder-shown': {
        overflow: 'hidden',
      },
    },

    notchedOutline: {
      borderColor: COLORS.gray.gray60,
    },
  };

  theme.overrides.MuiButton = {
    root: {
      minWidth: 60,
      height: 40,
      padding: theme.spacing(1, 2),
      borderRadius: 4,
      boxShadow: 'unset',
    },
    label: {
      letterSpacing: 'normal',
    },

    startIcon: {
      marginLeft: 'unset',
      marginRight: theme.spacing(0.75),
    },
    endIcon: {
      marginLeft: theme.spacing(0.75),
      marginRight: 'unset',
    },

    sizeSmall: {
      minWidth: 75,
      height: 'auto',
      padding: theme.spacing(0.5, 2),
      ...theme.typography.paragraph12,
    },

    text: {
      color: theme.palette.text.secondary,
    },
    textSizeSmall: {
      minWidth: '75px',
      height: '24px',
      padding: '2px 8px',
    },

    iconSizeSmall: {
      '& > *:first-child': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    iconSizeMedium: {
      '& > *:first-child': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    iconSizeLarge: {
      '& > *:first-child': {
        fontSize: theme.typography.pxToRem(16),
      },
    },

    outlined: {
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: COLORS.gray.gray90,
      borderWidth: 0,

      '&:hover': {
        backgroundColor: COLORS.gray.gray80,
        borderWidth: 0,
      },

      '&$disabled': {
        borderWidth: 0,
      },
    },
    outlinedPrimary: {
      borderWidth: 0,

      '&:hover': {
        backgroundColor: COLORS.primary.silver,
        borderWidth: 0,
      },
    },
    outlinedSecondary: {
      borderWidth: 0,

      '&:hover': {
        borderWidth: 0,
        backgroundColor: COLORS.success.light,
      },
      '&$disabled': {
        borderWidth: 0,
      },
    },
    outlinedSizeLarge: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightMedium,
    },

    contained: {
      color: '#9FA8BD',
      backgroundColor: '#F8F8F8',
      boxShadow: 'none',

      '&:hover': {
        backgroundColor: '#F8F8F8',
        boxShadow: theme.shape.boxShadowButton,

        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: '#F8F8F8',
          boxShadow: 'none',
        },
        '&$disabled': {
          backgroundColor: theme.palette.background.disabled,
        },
      },
      '&$focusVisible': {
        boxShadow: theme.shape.boxShadowButton,
      },
      '&:active': {
        boxShadow: 'none',
      },
      '&$disabled': {
        backgroundColor: '#F8F8F8',
        boxShadow: 'none',
      },
    },
    containedPrimary: {
      color: COLORS.neutral.white,
      backgroundColor: COLORS.primary.default,

      '&:hover': {
        color: COLORS.neutral.white,
        backgroundColor: COLORS.primary.active,
        boxShadow: theme.shape.boxShadowButton,

        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
        },
        '&$disabled': {
          backgroundColor: theme.palette.background.disabled,
        },
      },
      '&$focusVisible': {
        boxShadow: theme.shape.boxShadowButton,
      },
      '&:active': {
        boxShadow: 'none',
      },
    },
    containedSecondary: {
      color: theme.palette.common.white,

      '&:hover': {
        color: theme.palette.common.white,
      },
    },
    containedSizeLarge: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightMedium,
    },
    sizeLarge: {
      height: 48,
      padding: theme.spacing(1.5),
    },

    textPrimary: {
      '&:hover': {
        backgroundColor: COLORS.primary.silver,
      },
    },
    textSecondary: {
      '&:hover': {
        backgroundColor: COLORS.success.light,
      },
    },
  };

  theme.overrides.MuiIconButton = {
    root: {
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadiusSmall,
      '&$disabled': {
        color: '#BDBDBD',
      },
    },
  };

  theme.overrides.MuiRadio = {
    root: {
      color: COLORS.gray.gray50,
      '& svg': {
        fontSize: theme.typography.pxToRem(16),
      },
      '&:hover': {
        backgroundColor: 'unset !important',
        color: COLORS.gray.gray30,
      },
    },
  };

  theme.overrides.MuiSlider = {
    rail: {
      backgroundColor: '#F4F6FC',
    },
    track: {
      height: '1px',
    },
    thumb: {
      width: '19px',
      height: '19px',
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.common.white,
    },
  };

  const TABS_HEIGHT = 32;
  theme.overrides.MuiTabs = {
    root: {
      padding: 0,
      border: 'none',
      minHeight: TABS_HEIGHT,
    },
    indicator: {
      height: 2,
      borderRadius: 2,
      marginBottom: 0,
    },
  };

  theme.overrides.MuiTab = {
    root: {
      padding: '6px',
      ...theme.typography.regular14,
      letterSpacing: 'normal',
      color: theme.palette.text.secondary,

      opacity: '1',
      minWidth: '50px',
      minHeight: TABS_HEIGHT,

      '&$selected': {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
      },

      '& + &': {
        marginLeft: '8px',
      },

      [theme.breakpoints.up('sm')]: {
        minWidth: 100,
      },

      [theme.breakpoints.up('md')]: {
        minWidth: 'auto',
      },
    },
    textColorInherit: {
      opacity: '1',
    },
  };

  theme.overrides.MuiMenu = {
    paper: {
      border: theme.shape.borderGray70,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 0px 23px -3px rgba(23, 43, 77, 0.21)',
      padding: theme.spacing(1),
    },
    list: {
      padding: 0,
      rowGap: theme.spacing(0.5),
    },
  };

  theme.overrides.MuiAutocomplete = {
    paper: {
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 0px 23px -3px rgba(23, 43, 77, 0.21)',
    },
    listbox: {
      padding: theme.spacing(1),
    },
    noOptions: {
      ...theme.typography.regular14,
      color: COLORS.gray.gray50,
    },
    option: {
      borderRadius: theme.shape.borderRadius,
      ...theme.typography.regular14,
      padding: '12px 16px !important',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',

      '& + &': {
        marginTop: theme.spacing(0.5),
      },
    },
  };

  theme.overrides.MuiMenuItem = {
    root: {
      height: 16,
      minHeight: 40,
      borderRadius: theme.shape.borderRadius,

      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.neutral.black,
      whiteSpace: 'pre-wrap',

      [theme.breakpoints.up('sm')]: {
        minHeight: 38,
      },

      '& + &': {
        marginTop: theme.spacing(0.5),
      },
    },
  };

  theme.overrides.MuiListItem = {
    root: {
      '&$selected': {
        backgroundColor: COLORS.primary.silver,
      },

      '&:hover, &$selected:hover': {
        backgroundColor: COLORS.gray.gray90,
      },
    },
  };

  theme.overrides.MuiListItemText = {
    primary: {
      fontSize: theme.typography.body2.fontSize,
    },
  };

  theme.overrides.MuiDialog = {
    paperScrollPaper: {
      maxHeight: 'calc(100% - 50px)',
      overflowY: 'auto',
    },
  };

  theme.overrides.MuiDialogTitle = {
    root: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(4, 4, 2),
      textAlign: 'left',
    },
  };

  theme.overrides.MuiDialogContent = {
    root: {
      padding: theme.spacing(3, 4),
      maxHeight: 800,
      overflowY: 'auto',

      // Clear MUI-provided style
      '&:first-child': {
        paddingTop: null,
      },
    },
  };

  theme.overrides.MuiDialogActions = {
    root: {
      borderTop: theme.shape.borderGray70,
      padding: theme.spacing(2, 4),
      margin: 0,
    },
    spacing: {
      '& > :not(:first-child)': {
        marginLeft: theme.spacing(0.5),
      },
    },
  };

  theme.overrides.MuiStepLabel = {
    label: {
      color: theme.palette.text.disabled,

      '&$active': {
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.input,
      },
      '&$completed': {
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.text.input,
      },
    },
  };

  theme.overrides.MuiSvgIcon = {
    root: {
      '&.left': {
        marginRight: theme.spacing(1),
      },
      '&.right': {
        marginLeft: theme.spacing(1),
      },

      '&[data-fill-none]': {
        fill: 'none',
      },
    },
  };

  theme.overrides.MuiAlert = {
    root: {
      fontSize: theme.typography.pxToRem(14),
    },
    filledInfo: {
      backgroundColor: COLORS.gray.black,
    },
    filledSuccess: {
      backgroundColor: '#42C493',
    },
    standardWarning: {
      backgroundColor: '#FFF9F1',
    },
    standardError: {
      backgroundColor: '#DA5667',
      color: '#fff',
      '& $icon': {
        color: '#fff',
      },
    },
    outlinedError: {
      color: COLORS.error.active,
      backgroundColor: COLORS.error.extraLight,
      borderColor: COLORS.error.light,
      '& $icon': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  };

  theme.overrides.MuiToggleButtonGroup = {
    root: {
      backgroundColor: theme.palette.common.white,
    },
  };

  theme.overrides.MuiButtonGroup = {
    root: {
      boxShadow: 'none !important',
    },
  };

  theme.overrides.MuiAccordion = {
    root: {
      '&:before': {
        display: 'none',
      },
    },
  };

  const TOGGLE_BUTTON_SELECTED_BG = '#B3C3E0';
  const TOGGLE_BUTTON_DISABLED_BG = '#EBEEF4';
  theme.overrides.MuiToggleButton = {
    root: {
      flex: '1',

      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary,

      padding: '12px 6px',
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,

      '&$disabled': {
        color: COLORS.gray.gray60,
      },

      '&$selected': {
        backgroundColor: TOGGLE_BUTTON_SELECTED_BG,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.common.white,

        '&:hover': {
          backgroundColor: darken(TOGGLE_BUTTON_SELECTED_BG, 0.1),
        },
      },

      '&$disabled$selected': {
        backgroundColor: TOGGLE_BUTTON_DISABLED_BG,
        color: '#A0AEC6',
      },
    },
  };

  theme.overrides.MuiChip = {
    root: {
      boxShadow: 'none !important',
      '&:focus': {
        backgroundColor: 'initial',
      },
      '&:hover': {
        backgroundColor: 'initial',
      },
    },
    label: {
      padding: '0 !important',
    },
    icon: {
      margin: `${theme.spacing(0, 0.75, 0, 0)} !important`,
      padding: '0 !important',
      color: COLORS.gray.gray30,
      '&:hover': {
        color: COLORS.gray.gray30,
      },
    },
    deleteIcon: {
      margin: `${theme.spacing(0, 0, 0, 0.75)} !important`,
      padding: '0 !important',
      color: COLORS.gray.gray30,
      '&:hover': {
        color: COLORS.gray.gray30,
      },
    },
    clickable: {
      '&$outlinedPrimary': {
        '&:hover': {
          backgroundColor: `${COLORS.primary.light} !important`,
        },
      },
    },
    deletable: {
      '&$outlinedPrimary': {
        '&:hover': {
          backgroundColor: `${COLORS.primary.light} !important`,
        },
      },
    },
    deleteIconOutlinedColorPrimary: {
      '&:hover': {
        color: COLORS.gray.gray30,
      },
      '&:active': {
        color: COLORS.gray.gray30,
      },
    },
  };

  theme.overrides.MuiBackdrop = {
    root: {
      backgroundColor: alpha(COLORS.neutral.darkGray, 0.7),
    },
  };

  theme.overrides.MuiLink = {
    root: {
      fontFamily: FONTS.Roboto,
    },
  };

  theme.overrides.MuiSkeleton = {
    text: {
      borderRadius: theme.shape.borderRadiusMinimal,
    },
  };

  theme.overrides.MuiTable = {
    root: {
      border: theme.shape.borderGray60,
      borderRadius: theme.shape.borderRadius,
    },
  };

  theme.overrides.MuiTableCell = {
    head: {
      padding: theme.spacing(1, 2),
      backgroundColor: COLORS.gray.gray90,

      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
      },

      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
    stickyHeader: {
      backgroundColor: COLORS.gray.gray90,
    },
  };

  theme.overrides.MuiTableBody = {};

  theme.overrides.MuiTableRow = {
    root: {
      '&:last-child > td': {
        border: 'none',
        '&:first-child': {
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderBottomRightRadius: 'unset',
        },
        '&:last-child': {
          borderBottomRightRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: 'unset',
        },
      },
    },
  };

  return theme;
};
