import { COMPANY_SIZE } from 'src/data/company';
import { useCurrentUser, useIsUserAllowedToBookDemo } from 'src/store';
import { getUserCompanySize } from '../data/user';

const URL_SMB = 'https://anyword.chilipiper.com/book/anyword-demo';
const URL_ENTERPRISE = 'https://anyword.chilipiper.com/book/me/arrod-la-roque';

const getUrlFromCompanySize = (companySize) => {
  switch (companySize) {
    case COMPANY_SIZE.size_1:
    case COMPANY_SIZE.size_2_10:
      return null;

    case COMPANY_SIZE.size_201_500:
    case COMPANY_SIZE.size_501_1000:
    case COMPANY_SIZE.size_1000_more:
      return URL_ENTERPRISE;

    default:
      return URL_SMB;
  }
};

export const useBookDemoUrl = () => {
  const currentUser = useCurrentUser();
  const userAllowedToBookDemo = useIsUserAllowedToBookDemo();
  if (!userAllowedToBookDemo) {
    return null;
  }

  const companySize = getUserCompanySize(currentUser);
  return getUrlFromCompanySize(companySize);
};
