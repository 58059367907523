import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query GenerationToolRatings($generationTool: GenerationToolEnum!, $projectId: ID!) {
    generationToolRatings(generationTool: $generationTool, projectId: $projectId)
  }
`;

export const generationToolRatings = ({ generationTool, projectId }) => {
  const variables = { generationTool, projectId };

  return executeGqlQuery({
    query: QUERY,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
