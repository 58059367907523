import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  CustomButton,
  CUSTOM_BUTTON_VARIANT,
  CUSTOM_BUTTON_SIZE,
} from 'src/components/CustomButton';
import { TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { getDetectedBrandTermType } from 'src/data/brandTerm';
import { TermType } from '../TermType';
import { useStyles } from './useStyles';

export const TermHeader = ({ className, classes, detectedBrandTerm, onApplyClick, disabled }) => {
  const styles = useStyles({ classes });

  const detectedBrandTermType = getDetectedBrandTermType(detectedBrandTerm);
  const ruleText = detectedBrandTerm.termInText;

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.term}>
        <TermType
          className={styles.termType}
          detectedBrandTermType={detectedBrandTermType}
        />

        <TruncatedTypography
          className={styles.termText}
          variant={TYPOGRAPHY_VARIANT.regular14}
        >
          {ruleText}
        </TruncatedTypography>
      </div>

      {onApplyClick && (
        <CustomButton
          className={styles.buttonApply}
          onClick={onApplyClick}
          disabled={disabled}
          variant={CUSTOM_BUTTON_VARIANT.text}
          size={CUSTOM_BUTTON_SIZE.small}
        >
          Apply
        </CustomButton>
      )}
    </div>
  );
};

TermHeader.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  detectedBrandTerm: PropTypes.shape().isRequired,
  onApplyClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TermHeader.defaultProps = {
  className: '',
  classes: null,
  disabled: false,
};
