import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const SavedTemplatesIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 20 20"
        data-fill-none
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.33203 5.56445C5.33203 5.15024 5.66782 4.81445 6.08203 4.81445H14.157C14.5712 4.81445 14.907 5.15024 14.907 5.56445V8.59256C14.907 9.00677 14.5712 9.34256 14.157 9.34256H6.08203C5.66782 9.34256 5.33203 9.00677 5.33203 8.59256V5.56445ZM6.83203 6.31445V7.84256H13.407V6.31445H6.83203Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.33203 11.332C5.33203 10.9178 5.66782 10.582 6.08203 10.582H9.11013C9.52435 10.582 9.86013 10.9178 9.86013 11.332V14.3601C9.86013 14.7743 9.52435 15.1101 9.11013 15.1101H6.08203C5.66782 15.1101 5.33203 14.7743 5.33203 14.3601V11.332ZM6.83203 12.082V13.6101H8.36013V12.082H6.83203Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3789 11.332C10.3789 10.9178 10.7147 10.582 11.1289 10.582H14.157C14.5712 10.582 14.907 10.9178 14.907 11.332V14.3601C14.907 14.7743 14.5712 15.1101 14.157 15.1101H11.1289C10.7147 15.1101 10.3789 14.7743 10.3789 14.3601V11.332ZM11.8789 12.082V13.6101H13.407V12.082H11.8789Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.33381 2.07812C4.17897 2.07812 4.03048 2.13963 3.92099 2.24912C3.81151 2.3586 3.75 2.5071 3.75 2.66193V17.3338C3.75 17.4886 3.81151 17.6371 3.92099 17.7466C4.03048 17.8561 4.17897 17.9176 4.33381 17.9176H16.9219V5.215C16.9219 5.21494 16.9219 5.21505 16.9219 5.215C16.9218 5.06041 16.8604 4.912 16.7512 4.80261M16.7512 4.80261L14.1976 2.24899C14.1975 2.24893 14.1976 2.24904 14.1976 2.24899C14.0882 2.13976 13.9398 2.0782 13.7852 2.07812C13.7851 2.07812 13.7852 2.07812 13.7852 2.07812H4.33381M2.86033 1.18846C3.25112 0.797668 3.78115 0.578125 4.33381 0.578125H13.7852C14.3374 0.578243 14.8672 0.797594 15.2579 1.18799L17.8118 3.74195C18.2022 4.13259 18.4218 4.6624 18.4219 5.21468V18.6676C18.4219 19.0818 18.0861 19.4176 17.6719 19.4176H4.33381C3.78115 19.4176 3.25112 19.1981 2.86033 18.8073C2.46954 18.4165 2.25 17.8865 2.25 17.3338V2.66193C2.25 2.10927 2.46954 1.57925 2.86033 1.18846Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);
