import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    input_root: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    input_input: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    error: {
      ...theme.typography.regular12,
      marginTop: theme.spacing(0.5),
    },
  }),
  { name: 'FieldContentTaxonomy' }
);
