import LogRocket from 'logrocket';
import { pickBy } from 'lodash';

import { getUserRoleForCustomer } from 'src/data/user';
import { getCustomerPlan } from 'src/data/subscription';
import { isApplicationModeInfinity } from 'src/data/system';
import { getLogRocketAppId } from 'src/lib/env';

let inited = false;
let started = false;

export const init = () => {
  // We don't want to use LogRocket in Infinity Extension sessions
  if (isApplicationModeInfinity()) {
    return;
  }

  inited = true;
};

export const start = () => {
  if (!inited) {
    return;
  }

  const appId = getLogRocketAppId();
  if (!appId) {
    return;
  }

  try {
    LogRocket.init(appId, {
      release: process.env.REACT_APP_REPOSITORY_TAG,
    });
    started = true;
  } catch (error) {
    // We don't care about this error
  }
};

export const identifyUser = ({ user, customer }) => {
  if (!started || !user || !customer) {
    return;
  }

  try {
    LogRocket.identify(String(user.id), {
      name: user.name,
      email: user.email,
      createdAt: user.createdAt,
      customerId: customer.id,
      role: getUserRoleForCustomer(user, customer.id),
      plan: getCustomerPlan(customer),
    });
  } catch (error) {
    // We don't care about this error
  }
};

export const trackEvent = (eventName, attributes) => {
  if (!started) {
    return;
  }

  // Filter out null or undefined attributes
  const preparedAttributes = pickBy(attributes, (value) => value != null);

  try {
    LogRocket.track(eventName, preparedAttributes);
  } catch (error) {
    // We don't care about this error
  }
};
