import gql from 'graphql-tag';

export const FRAGMENT_BRAND_TERM = gql`
  fragment BrandTerm on BrandTerm {
    id
    term
    approvalType
    replaceWith
    partOfSpeech
    caseSensitive
    capitalizeAtStart
    consistentHyphenation
    commonMistakes
    description
    tags
    status
    updatedAt
    lastModifyingUser {
      name
    }
    brandVoice {
      id
      name
    }
  }
`;

export const FRAGMENT_DETECTED_BRAND_TERM = gql`
  fragment DetectedBrandTerm on DetectedBrandTerm {
    brandTerm {
      ...BrandTerm
    }
    termInText
    termStartPosition
    termEndPosition
    replacement
    reason
  }
  ${FRAGMENT_BRAND_TERM}
`;
