import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';

import CustomInput from 'src/components/CustomInput';
import { IconBox } from 'src/assets/icons/IconBox';
import ListOption from './components/ListOption';
import DefaultOptions from './components/DefaultOptions';
import useStyles from './useStyles';
import { DEFAULT_TAXONOMY_NAME } from 'src/data/common';

const filterOptions = (options, state) => {
  const { inputValue, getOptionLabel } = state || {};
  const input = `${inputValue}`.trim().toLowerCase();
  if (!input) {
    return [];
  }

  const filteredOptions = options.filter((option) => {
    const candidate = getOptionLabel(option).toLowerCase();
    return candidate.indexOf(input) > -1;
  });

  return filteredOptions.slice(0, 20);
};

const ContentTaxonomyAutocomplete = ({
  className,
  classes,
  options,
  defaultOptions,
  selectedOption,
  placeholder,
  onChange,
  loading,
  disabled,
  name,
  error,
}) => {
  const styles = useStyles({ classes });

  const [wasChanged, setWasChanged] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChange = useCallback(
    (event, newValue) => {
      onChange(newValue);
    },
    [onChange]
  );

  const handleInputChange = useCallback(
    (event, newValue, type) => {
      // Do not clear "input value" when "useAutocomplete" resets it due to "value" becoming null
      if (!newValue && type === 'reset') {
        return;
      }

      setInputValue(newValue);

      // If "input value" is being directly set by user, and not by "useAutocomplete" (after "value" was selected)
      //   then clear "value" - user would have to select something new
      if (newValue && type !== 'reset') {
        setWasChanged(true);
        onChange(null);
      }
    },
    [onChange]
  );

  const {
    popupOpen,
    groupedOptions,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
  } = useAutocomplete({
    options,
    value: selectedOption,
    onChange: handleChange,
    inputValue,
    onInputChange: handleInputChange,
    getOptionLabel: (option) => option.name,
    filterOptions,
    freeSolo: true,
    clearOnEscape: true,
    clearOnBlur: false,
    selectOnFocus: true,
    disableClearable: false,
  });

  const haveResults = groupedOptions.length > 0;
  const haveVisibleResults = haveResults && popupOpen;

  // Default items should be displayed if user entered something, but no results were found
  const showDefaultOptions =
    (!selectedOption && wasChanged && !loading && !haveResults) ||
    selectedOption?.name === DEFAULT_TAXONOMY_NAME;

  const handleSelectDefault = useCallback(
    (newValue) => {
      onChange(newValue);
    },
    [onChange]
  );

  return (
    <div
      className={cx(styles.root, className, {
        [styles.haveVisibleResults]: haveVisibleResults,
      })}
      {...getRootProps()}
    >
      <CustomInput
        className={styles.customInput}
        classes={{
          focused: styles.focused,
          notchedOutline: styles.notchedOutline,
          input: styles.input,
        }}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            <IconBox className={styles.icon} />
          </InputAdornment>
        }
        inputProps={getInputProps()}
        disabled={disabled}
        name={name}
        error={error}
      />

      {groupedOptions.length > 0 ? (
        <>
          <div className={styles.listbox}>
            <ul
              className={styles.list}
              {...getListboxProps()}
            >
              {groupedOptions.map((option, index) => (
                <ListOption
                  key={option}
                  className={styles.option}
                  option={option}
                  inputValue={inputValue}
                  {...getOptionProps({ option, index })}
                />
              ))}
            </ul>
          </div>
        </>
      ) : null}

      {showDefaultOptions && (
        <DefaultOptions
          className={styles.defaultOptions}
          options={defaultOptions}
          onSelect={handleSelectDefault}
          disabled={disabled}
        />
      )}
    </div>
  );
};

ContentTaxonomyAutocomplete.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedOption: PropTypes.shape({
    name: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.bool,
};

ContentTaxonomyAutocomplete.defaultProps = {
  className: null,
  classes: null,
  selectedOption: null,
  loading: false,
  disabled: false,
  placeholder: '',
  error: false,
};

export default ContentTaxonomyAutocomplete;
