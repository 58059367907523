import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const RadioChecked = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 26 26">
    <rect
      x="1"
      y="1"
      width="24"
      height="24"
      rx="12"
      stroke="currentColor"
      fill="transparent"
    />
    <rect
      x="6.33331"
      y="6.33301"
      width="13.3333"
      height="13.3333"
      rx="6.66667"
      fill="currentColor"
    />
  </SvgIcon>
));

export default React.memo(RadioChecked);
