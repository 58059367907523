import { useMemo } from 'react';

import { useBrandVoicesAssets } from 'src/store';

const actualizeBrandTermData = (brandTerms) => (detectedBrandTerm) => {
  const detectedTermData = detectedBrandTerm.brandTerm;
  if (detectedTermData) {
    const listTermData = brandTerms.find(({ id }) => id === detectedTermData.id);
    if (listTermData && listTermData.updatedAt > detectedTermData.updatedAt) {
      return {
        ...detectedBrandTerm,
        brandTerm: listTermData,
      };
    }
  }

  return detectedBrandTerm;
};

export const useActualizeDetectedBrandTermsData = (detectedBrandTerms) => {
  const { brandTerms } = useBrandVoicesAssets();

  return useMemo(() => {
    if (!detectedBrandTerms?.length > 0) {
      return [];
    }

    return detectedBrandTerms.map(actualizeBrandTermData(brandTerms));
  }, [detectedBrandTerms, brandTerms]);
};
