import { escapeRegExp, orderBy } from 'lodash';

export const sortWorkspaces = (workspaces) => orderBy(workspaces, ['name'], ['asc']);

export const filterWorkspaces = (workspaces, search) => {
  if (!search) {
    return workspaces;
  }

  const regex = new RegExp(escapeRegExp(search), 'i');
  return workspaces.filter((c) => regex.test(c.name));
};

export const WORKSPACE_ACCESS_LEVEL = {
  allWorkspaces: 'allWorkspaces',
  onlyCurrentWorkspace: 'onlyCurrentWorkspace',
};

const WORKSPACE_ACCESS_LEVEL_LABEL = {
  [WORKSPACE_ACCESS_LEVEL.allWorkspaces]: 'All workspaces',
  [WORKSPACE_ACCESS_LEVEL.onlyCurrentWorkspace]: 'Only current workspace',
};

export const getWorkspaceAccessLevelLabel = (level) => WORKSPACE_ACCESS_LEVEL_LABEL[level];
