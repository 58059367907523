import { UniqMap } from 'src/lib/UniqMap';
import { PROJECT_CATEGORY, PROJECT_TYPE } from './const';

const PROJECT_TYPE_PROPS = new UniqMap();

// canvas category
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.canvas, {
  category: PROJECT_CATEGORY.canvas,
  label: 'Document',
  title: 'Document',
});

// "ad" category
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.facebookAd, {
  category: PROJECT_CATEGORY.ads,
  label: 'Facebook Ad',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.googleAd, {
  category: PROJECT_CATEGORY.ads,
  label: 'Google Search Ad',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.outbrainAd, {
  category: PROJECT_CATEGORY.ads,
  label: 'Outbrain Headline',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.taboolaAd, {
  category: PROJECT_CATEGORY.ads,
  label: 'Taboola Headline',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.twitterAd, {
  category: PROJECT_CATEGORY.ads,
  label: 'X Ad',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.linkedinAd, {
  category: PROJECT_CATEGORY.ads,
  label: 'LinkedIn Text Ad',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.pinterestAd, {
  category: PROJECT_CATEGORY.ads,
  label: 'Pinterest Ad',
});

// Teads
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.teadsSingleImage, {
  category: PROJECT_CATEGORY.ads,
  label: 'Teads Single Image',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.teadsSingleVideo, {
  category: PROJECT_CATEGORY.ads,
  label: 'Teads Single Video',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.teadsCarousel, {
  category: PROJECT_CATEGORY.ads,
  label: 'Teads Carousel',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.teadsSocialLayout1, {
  category: PROJECT_CATEGORY.ads,
  label: 'Teads Social Layout I',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.teadsSocialLayout2, {
  category: PROJECT_CATEGORY.ads,
  label: 'Teads Social Layout II',
});

PROJECT_TYPE_PROPS.add(PROJECT_TYPE.continuousOptimization, {
  category: PROJECT_CATEGORY.websitePersonalization,
  label: 'Website Automation',
  title: 'Project',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.wtmHomePage, {
  category: PROJECT_CATEGORY.websitePersonalization,
  label: 'Website Automation',
  title: 'Project',
});

// "landingPage" category
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.landingPageBuilder, {
  category: PROJECT_CATEGORY.landingPage,
  label: 'New Landing Page Copy',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.seoMetaDescription, {
  category: PROJECT_CATEGORY.landingPage,
  label: 'SEO Meta Description',
});

// "productDescription" category
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.productDescription, {
  category: PROJECT_CATEGORY.productDescription,
  label: 'Product Description',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.featuresToBenefits, {
  category: PROJECT_CATEGORY.productDescription,
  label: 'Features to Benefits',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.amazonProductDescription, {
  category: PROJECT_CATEGORY.productListing,
  label: 'Amazon Product Listing',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.walmartProductListing, {
  category: PROJECT_CATEGORY.productListing,
  label: 'Walmart Product Listing',
});

// "social" category
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.facebookSocial, {
  category: PROJECT_CATEGORY.social,
  label: 'Facebook Post',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.instagramSocial, {
  category: PROJECT_CATEGORY.social,
  label: 'Instagram Caption',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.twitterSocial, {
  category: PROJECT_CATEGORY.social,
  label: 'X Post',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.linkedinSocial, {
  category: PROJECT_CATEGORY.social,
  label: 'LinkedIn Post',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.pinterestSocial, {
  category: PROJECT_CATEGORY.social,
  label: 'Pinterest Pin',
});
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.youtubeSocial, {
  category: PROJECT_CATEGORY.social,
  label: 'YouTube Description',
});

// "blog" category
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.blogPostBuilder, {
  category: PROJECT_CATEGORY.blog,
  label: 'Blog Post',
  title: 'Blog',
});

// "email" category
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.emailSubjectLine, {
  category: PROJECT_CATEGORY.email,
  label: 'Email Subject Line',
});

PROJECT_TYPE_PROPS.add(PROJECT_TYPE.emailPromotional, {
  category: PROJECT_CATEGORY.email,
  label: 'Promotional Email',
});

PROJECT_TYPE_PROPS.add(PROJECT_TYPE.emailColdOutreach, {
  category: PROJECT_CATEGORY.email,
  label: 'Cold Email',
});

// "sms" category
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.sms, {
  category: PROJECT_CATEGORY.sms,
  label: 'Promotional SMS',
});

// Shopify app-specific projects
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.shopifyProduct, {
  category: PROJECT_CATEGORY.productListing,
  label: 'Improve Your Existing Shopify Product Page',
});

PROJECT_TYPE_PROPS.add(PROJECT_TYPE.shopifyProductsDescription, {
  category: PROJECT_CATEGORY.productListing,
  label: 'Shopify Product Descriptions',
});

PROJECT_TYPE_PROPS.add(PROJECT_TYPE.shopifyLandingPage, {
  label: 'Shopify Landing Page',
});

// deprecated
PROJECT_TYPE_PROPS.add(PROJECT_TYPE.blogPost, {
  label: 'Blog Post (legacy)',
});

const getProjectTypeProp = (type, propName, defaultValue = null) => {
  const props = PROJECT_TYPE_PROPS.get(type);
  return (props && props[propName]) || defaultValue;
};

export const getProjectTypeCategory = (projectType) => getProjectTypeProp(projectType, 'category');

export const getProjectTypeLabel = (projectType) =>
  getProjectTypeProp(projectType, 'label', projectType);

export const getProjectTypeTitle = (projectType) =>
  getProjectTypeProp(projectType, 'title', 'Document');
