import gql from 'graphql-tag';
import { get } from 'lodash';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_AUDIENCE_TARGET } from './schema';

const QUERY = gql`
  query QueryAudiencesTargeting($projectId: Int!) {
    project(id: $projectId) {
      continuousOptimizationSegment {
        id
        status
        keywords
        audienceTarget {
          ...AudienceTarget
        }
      }
    }
  }
  ${FRAGMENT_AUDIENCE_TARGET}
`;

export const queryAudienceSegments = async (projectId) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
    },
  });

  const project = getSingleProp(result.data);
  return get(project, 'continuousOptimizationSegment', []);
};
