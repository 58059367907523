export const PERFORMANCE_EXTRACTION_STATUS = {
  created: 'created',
  running: 'running',
  done: 'done',
  doneWithErrors: 'done_with_errors',
  partialDone: 'partial_done',
  disconnected: 'disconnected',
  nullableResults: 'nullable_results',
};

export const VISIBLE_RESOURCES_STATUS = [
  PERFORMANCE_EXTRACTION_STATUS.created,
  PERFORMANCE_EXTRACTION_STATUS.done,
  PERFORMANCE_EXTRACTION_STATUS.running,
];

export const isDisconnectedStatus = (status) =>
  status === PERFORMANCE_EXTRACTION_STATUS.disconnected;
