import { createContainer } from 'react-sweet-state';

import { FreestyleToolsStore, ACTIONS } from './FreestyleToolsStore';

export const FreestyleToolsContainer = createContainer(FreestyleToolsStore, {
  onInit:
    () =>
    ({ setState, dispatch }, { state, currentCustomerId, currentWorkspaceId }) => {
      dispatch(ACTIONS.init(currentCustomerId, currentWorkspaceId));

      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState, dispatch }, { currentCustomerId, state, currentWorkspaceId }) => {
      dispatch(ACTIONS.init(currentCustomerId, currentWorkspaceId));

      if (state) {
        setState(state);
      }
    },
});
