import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_AD_ACCOUNT } from './schema';

const MUTATION = gql`
  mutation UpdateAccountName(
    $customerId: ID!
    $accountId: ID!
    $newAccountName: String!
    $dataSource: PerformanceCenterDataSource!
  ) {
    performanceCenterUpdateAccountName(
      customerId: $customerId
      accountId: $accountId
      dataSource: $dataSource
      newAccountName: $newAccountName
    ) {
      ...UserAdAccount
    }
  }
  ${FRAGMENT_AD_ACCOUNT}
`;

export const updateAccountName = ({ customerId, accountId, dataSource, newAccountName }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, accountId, dataSource, newAccountName },
  }).then(({ data }) => getSingleProp(data));
};
