import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation SuggestBlogPostBuilderSection(
    $projectId: ID!
    $sectionId: ID!
    $assetType: AssetTypeEnum!
    $recomposeMode: RecomposeModeEnum
    $continueMode: ContinueModeEnum
    $generationLength: String!
    $keywords: [String!]
    $instruction: Instruction
    $markedSegment: MarkedSegment
    $debugAcPrompt: String
    $freestyleToolAllInstructionsData: JSON
    $url: String
    $urlExtractions: [UrlExtractionInput!]
    $isParagraphMode: Boolean
  ) {
    suggestBlogPostBuilderSection(
      projectId: $projectId
      sectionId: $sectionId
      assetType: $assetType
      recomposeMode: $recomposeMode
      continueMode: $continueMode
      generationLength: $generationLength
      keywords: $keywords
      instruction: $instruction
      markedSegment: $markedSegment
      debugAcPrompt: $debugAcPrompt
      freestyleToolAllInstructionsData: $freestyleToolAllInstructionsData
      url: $url
      urlExtractions: $urlExtractions
      isParagraphMode: $isParagraphMode
    ) {
      copyText
      keywordsUsage
    }
  }
`;

export const suggestBlogPostBuilderSection = async (
  {
    projectId,
    sectionId,
    assetType,
    recomposeMode,
    continueMode,
    generationLength,
    keywords,
    instruction,
    markedSegment,
    debugAcPrompt,
    freestyleToolAllInstructionsData,
    url,
    urlExtractions,
    isParagraphMode,
  },
  options = null
) => {
  const variables = {
    projectId,
    sectionId,
    assetType,
    recomposeMode,
    continueMode,
    generationLength,
    keywords,
    instruction,
    markedSegment,
    debugAcPrompt,
    freestyleToolAllInstructionsData: freestyleToolAllInstructionsData
      ? JSON.stringify(freestyleToolAllInstructionsData)
      : null,
    url,
    urlExtractions,
    isParagraphMode,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  const parsedRes = getSingleProp(response.data);
  return {
    ...parsedRes,
    keywordsUsage: parsedRes.keywordsUsage ? JSON.parse(parsedRes.keywordsUsage) : null,
  };
};
