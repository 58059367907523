import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_BRAND_BRIEF } from './schema';

const MUTATION = gql`
  mutation UpdateBrandBrief(
    $id: ID!
    $briefType: BrandBriefTypeEnum!
    $name: String!
    $title: String!
    $body: String!
  ) {
    updateBrandBrief(id: $id, briefType: $briefType, name: $name, title: $title, body: $body) {
      ...BrandBrief
    }
  }
  ${FRAGMENT_BRAND_BRIEF}
`;

export const updateBrandBrief = ({ id, briefType, name, title, body }) => {
  const variables = {
    id,
    briefType,
    name,
    title,
    body,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
