import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { CollapsingTooltip } from 'src/components/CollapsingTooltip';
import { useStyles } from './useStyles';

export const ProductDescriptionNode = ({ element, children, ...attributes }) => {
  const styles = useStyles();

  return (
    <CollapsingTooltip
      title={element.article}
      arrow
      {...attributes}
    >
      <Typography
        className={styles.root}
        component="span"
      >
        {children}
      </Typography>
    </CollapsingTooltip>
  );
};

ProductDescriptionNode.propTypes = {
  attributes: PropTypes.shape({}),
  element: PropTypes.shape().isRequired,
  children: PropTypes.node,
  content: PropTypes.string,
};
