import { useState, useCallback, useEffect, useRef } from 'react';

import * as events from 'src/lib/events';
import { useCurrentCustomerId } from 'src/store';
import { testCustomToneOfVoice } from 'src/graphql/toneOfVoice';
import { normalizeTraitButNot } from 'src/data/toneOfVoice';
import { useErrorHandler } from '../useErrorHandler';
import { useTrackingEvent } from '../useTrackingEvent';

const createTovInput = ({ toneOfVoice, trait, butNot }) => {
  const selectedTrait = toneOfVoice.traits.find((item) => item.trait === trait.trait);

  return {
    draftTov: {
      traits: [
        butNot
          ? {
              trait: '',
              butNotPriority: 1,
              butNot: normalizeTraitButNot(selectedTrait.butNot),
            }
          : { trait: selectedTrait.trait, traitPriority: 1 },
      ],
    },
  };
};

export const useApplyAnalyzedToneOfVoice = ({
  generationTool,
  localEditorRef,
  onApplyingAnalyzedToneOfVoice,
}) => {
  const handleError = useErrorHandler();
  const [applyingAnalyzedToneOfVoice, setApplyingAnalyzedToneOfVoice] = useState(null);
  const [analyzedToneOfVoiceAppliedMessage, setAnalyzedToneOfVoiceAppliedMessage] = useState('');

  const trackEvent = useTrackingEvent();

  const currentCustomerId = useCurrentCustomerId();

  // Ref to store the timeout ID
  const timeoutRef = useRef(null);

  // Effect to clean up the timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Effect to clear the message on user click
  useEffect(() => {
    if (!setAnalyzedToneOfVoiceAppliedMessage) {
      return;
    }
    const handleClick = () => {
      setAnalyzedToneOfVoiceAppliedMessage('');
    };

    if (!analyzedToneOfVoiceAppliedMessage) {
      return;
    }
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [analyzedToneOfVoiceAppliedMessage, setAnalyzedToneOfVoiceAppliedMessage]);

  const handleApplyAnalyzedToneOfVoice = useCallback(
    async ({ toneOfVoice, trait, butNot }) => {
      if (onApplyingAnalyzedToneOfVoice) {
        onApplyingAnalyzedToneOfVoice();
      }
      setApplyingAnalyzedToneOfVoice(trait.trait);

      const text = localEditorRef.current?.getText();

      const tovInput = createTovInput({ toneOfVoice, trait, butNot });

      try {
        const result = await testCustomToneOfVoice({
          customerId: currentCustomerId,
          text,
          generationTool,
          tovInput,
        });

        trackEvent(events.TONE_OF_VOICE.analyzedToneOfVoiceApplied, {
          page: events.EVENT_PAGE.editor,
          action: butNot ? 'less' : 'more',
          trait: butNot ? normalizeTraitButNot(trait.butNot) : trait.trait,
          text,
        });

        localEditorRef.current?.replaceText(result?.modifiedCopyText);

        const message = `Updated to be ${
          butNot ? `less ${normalizeTraitButNot(trait.butNot)}` : `more ${trait.trait}`
        }`;

        setAnalyzedToneOfVoiceAppliedMessage(message);

        timeoutRef.current = setTimeout(() => {
          setAnalyzedToneOfVoiceAppliedMessage('');
        }, 5000);
      } catch (error) {
        handleError(error);
      } finally {
        setApplyingAnalyzedToneOfVoice(null);
      }
    },
    [
      currentCustomerId,
      onApplyingAnalyzedToneOfVoice,
      generationTool,
      localEditorRef,
      trackEvent,
      handleError,
    ]
  );

  return {
    analyzedToneOfVoiceAppliedMessage,
    handleApplyAnalyzedToneOfVoice,
    applyingAnalyzedToneOfVoice,
  };
};
