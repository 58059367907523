import { UniqMap } from 'src/lib/UniqMap';
import { BRIEF_TYPE } from 'src/data/brief/const';
import { ASSET_TYPE } from 'src/data/asset/const';
import { alignChannelWithBackend, CHANNEL } from 'src/data/channel';
import { EMAILS_CHANNELS_AND_RESOURCES } from 'src/data/performance';
import { AI_SUGGESTION_MODE, GENERATION_MODE_TYPE } from 'src/data/generation';
import { filterVisibleDDTemplates } from 'src/data/utils';
import { GENERATION_TOOL } from './const';
import { getToolGenerationModes } from './toolGenerationModes';
import { getDefaultCharsByTool } from './maxCharsMapDefaults';
import { isDataDrivenGenerationTool } from './utils';

const GENERATION_TOOL_PROPS = new UniqMap();

/**
 * "basic" tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicContentImprover, {
  title: 'Content Improver',
  copy: 'Spruce up your content. Make it more readable and engaging.',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.canvasContentImprover,
  basicHasMultipleGenerationModes: true,
  supportsCustomerPersona: false,
  excludedFromSuggestedPersonas: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicEli5, {
  title: 'Explain it to a 5th Grader',
  copy: 'Good writing is immediately understood. Make things simple with this tool so even a child can understand.',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.canvasEli5,
  generatingLoaderText: "using 'Explain it to a 5th Grader'",
  disableTOVModes: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicSentenceExpander, {
  title: 'Sentence Expander',
  copy: 'Articulate your idea with this awesome tool.',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.canvasSentenceExpander,
  generatingLoaderText: "using 'Sentence Expander'",
  disableTOVModes: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicAida, {
  title: 'AIDA',
  copy: "Attention, interest, desire, action. It's the classic copywriting formula, studied and used by almost every copywriter on the planet.",
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasAida,
  generatingLoaderText: "using 'AIDA'",
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicPas, {
  title: 'PAS',
  copy:
    'This formula gives your writing a simple chronology:\n' +
    'P = Introduce the problem.\n' +
    'A = Agitate the problem.\n' +
    'S = Solve the problem with your product or service.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasPas,
  generatingLoaderText: "using 'PAS'",
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicBab, {
  title: 'BAB',
  copy: 'A three-step copywriting formula that presents the problem situation (before), the solution situation (after), and the solution itself (bridge).',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasBab,
  generatingLoaderText: "using 'BAB'",
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicPerfectHeadline, {
  title: 'Perfect Product Headline',
  copy:
    'Do you waste endless hours looking through headlines?\n' +
    'Meet the Anyword Headline Generator — AI that writes perfect headlines for you.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasPerfectHeadline,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicPersuasiveBulletPoint, {
  title: 'Powerful Bullet Points',
  copy: "It's easy to make your product stand out with these powerful bullet points.",
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasPersuasiveBulletPoint,
  disableTOVModes: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicFeatureToBenefitsPractical, {
  title: 'Features to Benefits',
  copy: 'The features of your product may not be enough to convince a customer to buy it. If you want to increase your sales, you need to start promoting the benefits of your product.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasFeatureToBenefitsPractical,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  generatingLoaderText: "using 'Features to Benefits'",
  disableTOVModes: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicValueProposition, {
  title: 'Value Proposition',
  copy: 'What makes your product so great? How does your product help customers? Which customers would your product be perfect for?',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasValueProposition,
  disableTOVModes: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicProductDescriptionList, {
  title: 'Product Description',
  description: 'List',
  copy: 'Describe and explain your product in list form.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasProductDescriptionList,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 5,
  maxWordsPerKeyword: 4,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicProductDescriptionRelaxed, {
  title: 'Product Description',
  description: 'Paragraph',
  copy: 'Make your product description better with a click of a button.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasProductDescriptionRelaxed,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 5,
  maxWordsPerKeyword: 4,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicBlogIdeas, {
  title: 'Blog Ideas',
  copy: 'Keep your blogs posts fresh and different every time.',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.canvasBlogIdeas,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicVideoIdeas, {
  title: 'Video Topic Ideas',
  copy: "Have you been searching for ideas on how to make your videos more exciting? Well this might be the answer. It's guaranteed to make you smile.",
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.canvasVideoIdeas,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicTalkingPointsToParagraph, {
  title: 'Talking Points to Paragraph',
  copy: "Turn talking points into an engaging paragraph that's perfect for marketing materials.",
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.editorParagraph,
  generatingLoaderText: "using 'Talking Points to Paragraph'",
  disableTOVModes: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicProductReview, {
  title: 'Product Review',
  copy: "Need to write a review for a product? Just give us the key points-we'll take care of the rest.",
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.productReview,
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.summarizer, {
  title: 'Summarizer',
  copy: 'When you have a lot to say, but not enough time to say it, try using Summarizer!',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.freeTextBody,
  basicHasMultipleGenerationModes: true,
  defaultNumberOfVariations: 1,
  disableTOVModes: true,
});

/**
 * "basic DD" tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicFacebookAd, {
  title: 'Facebook Ad',
  copy: 'Generate exceptionally effective copy for your Facebook Ads.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasFacebookAd,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicGoogleAd, {
  title: 'Google Ad',
  copy: 'Create high-converting descriptions and headlines for your Google Search Ads.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasGoogleAd,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicTwitterAd, {
  title: 'X Ad',
  copy: 'Write scroll-stopping copy for your X ads.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasTwitterAd,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicLinkedinTextAd, {
  title: 'LinkedIn Ad',
  copy: 'Generate LinkedIn Text Ads that stand out and convert.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasLinkedinTextAd,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicPinterestAd, {
  title: 'Pinterest Ad',
  copy: 'Step up your Pinterest game with stellar descriptions and titles.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasPinterestAd,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicLandingPage, {
  title: 'Landing Page Copy',
  copy: 'Transform a product description into an entire landing page with this powerful tool.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasLandingPage,
  defaultNumberOfVariations: 1,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicLandingPageSeo, {
  title: 'SEO Meta Description',
  copy: 'Generate click-worthy meta descriptions to increase your visibility on Google.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasLandingPageSeo,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicInstagramSocial, {
  title: 'Instagram Caption',
  copy: 'Generate catchy captions for your Instagram posts.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.canvasInstagramSocial,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicFacebookSocial, {
  title: 'Facebook Post',
  copy: 'Create engaging copy for your Facebook posts to maximize organic reach.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.canvasFacebookSocial,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicTwitterSocial, {
  title: 'X Post',
  copy: 'Generate witty tweets to keep your followers engaged.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.canvasTwitterSocial,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicLinkedinSocial, {
  title: 'LinkedIn Post',
  copy: 'Cut through the noise by generating compelling copy for your LinkedIn posts.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.canvasLinkedinSocial,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicYoutubeSocial, {
  title: 'YouTube Description',
  copy: 'Write smart YouTube titles and descriptions to help viewers find your videos.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.canvasYoutubeSocial,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicPinterestPinSocial, {
  title: 'Pinterest Pin',
  copy: 'Step up your Pinterest game with stellar descriptions and titles.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.canvasPinterestPinSocial,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicColdEmail, {
  title: 'Cold Email',
  copy: 'Create powerful, convincing emails to attract potential customers to your product or service.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasColdEmail,
  defaultNumberOfVariations: 1,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicPromotionalEmail, {
  title: 'Promotional Email',
  copy: 'Write an awesome email about a new product or an exciting promotion.',
  briefType: BRIEF_TYPE.unifiedPromotionalEmail,
  defaultAssetType: ASSET_TYPE.canvasPromotionalEmail,
  defaultNumberOfVariations: 1,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicEmailSubjectLine, {
  title: 'Email Subject Line',
  copy: 'Write enticing subject lines for all types of emails.',
  briefType: BRIEF_TYPE.email,
  defaultAssetType: ASSET_TYPE.canvasEmailSubjectLine,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicSms, {
  title: 'Promotional SMS',
  copy: 'Generate text messages that actually sell.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.canvasSms,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.generalPrompt, {
  title: 'General Prompt',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.generalProductDescription,
  supportsPromptInstructions: true,
  supportsSuggestKeywords: true,
  supportsCustomerPersona: true,
});

/**
 * "product" data-driven tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.facebookPrimaryTextProduct, {
  title: 'Facebook Ad',
  description: 'Primary Text',
  copy: 'Easily craft highly engaging copy for your Facebook Ads’ primary text.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.facebookPrimaryText,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  supportsCustomModel: true,
  channel: CHANNEL.facebook,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.facebookHeadlineProduct, {
  title: 'Facebook Ad',
  description: 'Headline',
  copy: 'Generate exceptionally effective headlines for your Facebook Ads.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.facebookHeadline,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  supportsCustomModel: true,
  channel: CHANNEL.facebook,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.googleDescriptionProduct, {
  title: 'Google Search Ad',
  description: 'Description',
  copy: 'Create high-converting descriptions for your Google Search Ads.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.googleDescription,
  maxSuggestKeywords: 2,
  channel: CHANNEL.google,
  maxCampaignKeywords: 3,
  supportsCampaignKeywords: true,
  disableTOVModes: true,
  maxWordsPerKeyword: 5,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBenchmark: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.googleHeadlineProduct, {
  title: 'Google Search Ad',
  description: 'Headline',
  copy: 'Make your Google Ads stand out with engaging headlines.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.googleHeadline,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 5,
  channel: CHANNEL.google,
  maxCampaignKeywords: 3,
  supportsCampaignKeywords: true,
  disableTOVModes: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBenchmark: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.outbrainHeadlineProduct, {
  title: 'Outbrain Ad',
  description: 'Headline',
  copy: 'Generate irresistible Outbrain headlines that drive traffic.',
  article: 'an',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.outbrainHeadline,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.taboolaHeadlineProduct, {
  title: 'Taboola Ad',
  description: 'Headline',
  copy: 'Generate irresistible Taboola headlines that drive traffic.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.taboolaHeadline,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.twitterCopyProduct, {
  title: 'X Ad',
  description: 'Copy',
  copy: 'Write scroll-stopping copy for your X Ads.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.twitterCopy,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.twitterTitleProduct, {
  title: 'X Ad',
  description: 'Headline',
  copy: 'Create scroll-stopping X Ad headlines.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.twitterTitle,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestDescriptionProduct, {
  title: 'Pinterest Ad',
  description: 'Description',
  copy: 'Take it one step further with dazzling Pinterest descriptions.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.pinterestDescription,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestTitleProduct, {
  title: 'Pinterest Ad',
  description: 'Title',
  copy: 'Step up your Pinterest game with stellar titles.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.pinterestTitle,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.linkedinHeadlineSingleImage, {
  title: 'LinkedIn Single Image Ad',
  description: 'Headline',
  copy: 'Stand out with a compelling headline for your LinkedIn Single Image Ad.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.linkedinSingleImageHeadline,
  supportsCustomModel: true,
  channel: CHANNEL.linkedin,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBenchmark: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.linkedinIntroSingleImage, {
  title: 'LinkedIn Single Image Ad',
  description: 'Copy',
  copy: 'Write an engrossing description for your LinkedIn Single Image Ad.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.linkedinSingleImageIntro,
  supportsCustomModel: true,
  channel: CHANNEL.linkedin,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
  supportsMaxChars: true,
  supportsBenchmark: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.articleBlogHeadline, {
  title: 'Blog Headline',
  copy: "Give your content the world's best attention span",
  article: 'an',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.freeTextHeadline,
  disableTOVModes: true,
  excludedFromSuggestedPersonas: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.productLaunchMultiPrompt, {
  title: 'Product Launch Campaign',
  copy: 'Stage a successful product launch with impactful ads, engaging posts, and a conversion-driven email.',
  multiPrompt: true,
  briefType: BRIEF_TYPE.unifiedProduct,
  supportsCustomerPersona: true,
  disableTOVModes: true,
  defaultAssetType: ASSET_TYPE.canvasFreestyle,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsBackgroundUrlBriefExtraction: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  defaultNumberOfVariations: 1,
  numberOfVariations: [1],
  promptFields: {
    titles: {
      product_details: 'Product details',
      benefit: 'Feature or benefit to focus on',
      hashtags: 'Hashtags',
      special_offer: 'Special offer',
    },
    placeholders: {
      product_details:
        'Enter your product name, as well as a detailed description of its features and benefits. ',
      benefit:
        'Describe a unique feature or benefit you want to focus on as part of this campaign.',
      hashtags: 'Enter up to 3 hashtags you would like to include in this campaign.',
      special_offer:
        'Enter the details of the offer or discount you want to include in this campaign. You can also include a promo code and availability dates.',
    },
    order: ['product_details', 'benefit', 'hashtags', 'special_offer'],
  },
  promptTools: {
    'Facebook Post': GENERATION_TOOL.facebookPost,
    'LinkedIn Post': GENERATION_TOOL.linkedinPost,
    'Instagram Caption': GENERATION_TOOL.instagramCaption,
    'Facebook Ad': GENERATION_TOOL.facebookMixtureAd,
    'LinkedIn Ad': GENERATION_TOOL.linkedinMixtureAd,
    'Product Launch Email': GENERATION_TOOL.emailMixture,
  },
  longDesc:
    'Stage a successful product launch with impactful ads, engaging posts, and a conversion-driven email.',
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingPageProductAnnouncementMultiPrompt, {
  title: 'Product Landing Page',
  copy: "Turn your visitors into customers with a landing page that highlights your product's features and benefits.",
  multiPrompt: true,
  supportsBackgroundUrlBriefExtraction: true,
  briefType: BRIEF_TYPE.unifiedProduct,
  supportsCustomerPersona: true,
  disableTOVModes: true,
  defaultAssetType: ASSET_TYPE.canvasFreestyle,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  defaultNumberOfVariations: 1,
  numberOfVariations: [1],
  promptFields: {
    titles: {
      product_details: 'Product details',
      benefit: 'Feature or benefit to highlight',
      call_to_action: 'Desired action',
    },
    placeholders: {
      product_details:
        'Enter your product name, as well as a detailed description of its features and benefits.',
      benefit: 'Describe a unique feature or benefit you want to highlight on your landing page.',
      call_to_action:
        'Enter the action you want your customer to take. For example, make an appointment, try a free demo, or download an article.',
    },
    order: ['product_details', 'benefit', 'call_to_action'],
  },
  promptTools: {
    Headline: GENERATION_TOOL.landingHeadline,
    Subheader: GENERATION_TOOL.landingSubheader,
    'Descriptive Paragraph': GENERATION_TOOL.freestyle,
    'Features Section Title': GENERATION_TOOL.landingSubheader,
    'Feature Descriptions': GENERATION_TOOL.landingFeatures,
    'Benefits Section Title': GENERATION_TOOL.landingSubheader,
    'Benefit Descriptions': GENERATION_TOOL.landingBenefits,
    CTA: GENERATION_TOOL.landingCTA,
  },
  longDesc:
    "Turn your visitors into customers with a landing page that highlights your product's features and benefits.",
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailDripMultiPrompt, {
  title: 'Welcome Email Drip Campaign',
  copy: 'Share information about your product with a series of welcome emails designed to convert new subscribers into paying customers.',
  multiPrompt: true,
  supportsBackgroundUrlBriefExtraction: true,
  briefType: BRIEF_TYPE.unifiedProduct,
  supportsCustomerPersona: true,
  disableTOVModes: true,
  defaultAssetType: ASSET_TYPE.canvasFreestyle,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  defaultNumberOfVariations: 1,
  numberOfVariations: [1],
  promptFields: {
    titles: {
      brand_name: 'Brand name',
      product_details: 'Product details',
      feature: 'Feature to highlight',
      offer: 'Offer',
      customer_testimonial: 'Customer testimonial',
    },
    placeholders: {
      brand_name: 'Enter your brand name.',
      product_details:
        'Enter your product name, as well as a detailed description of its features and benefits.',
      feature: 'Describe a unique product feature you want to highlight as part of this campaign.',
      offer: 'Enter the details of the offer or discount you want to include in this campaign.',
      customer_testimonial:
        "Enter an explanation of how your product helped a customer. Include the customer's name and occupation.",
    },
    order: ['brand_name', 'product_details', 'feature', 'offer', 'customer_testimonial'],
  },
  promptTools: {
    'Email 1: Welcome Introduction': GENERATION_TOOL.emailMixture,
    'Email 2: Feature Spotlight': GENERATION_TOOL.emailMixture,
    'Email 3: Customer Testimonial': GENERATION_TOOL.emailMixture,
    'Email 4: Introductory Offer': GENERATION_TOOL.emailMixture,
  },
  longDesc:
    'Share information about your product with a series of welcome emails designed to convert new subscribers into paying customers.',
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.googleRSA, {
  title: 'Google Responsive Search Ad',
  titleForTooltip: 'Google Search Ad',
  mixtureSubAssetTypeSubtitles: { google_headline: 'Headline', google_description: 'Description' },
  mixtureSubGenerationTools: [
    GENERATION_TOOL.googleHeadlineProduct,
    GENERATION_TOOL.googleDescriptionProduct,
  ],
  mixtureSubGenerationToolsModeOverride: {
    [GENERATION_TOOL.googleHeadlineProduct]: AI_SUGGESTION_MODE.auto,
  },
  copy: 'Create a batch of captivating headlines and descriptions effortlessly with our RSA template.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.googleRSA,
  defaultNumberOfVariations: 1,
  numberOfVariations: [1],
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 5,
  channel: CHANNEL.google,
  maxCampaignKeywords: 3,
  supportsCampaignKeywords: true,
  disableTOVModes: true,
  supportsCallToAction: false,
  isMixtureTool: true,
  supportsBenchmark: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.facebookMixtureAd, {
  title: 'Facebook Ad',
  titleForTooltip: 'Facebook Ad',
  mixtureSubAssetTypeSubtitles: {
    facebook_headline: 'Headline',
    facebook_primary_text: 'Primary Text',
  },
  mixtureSubGenerationTools: [
    GENERATION_TOOL.facebookPrimaryTextProduct,
    GENERATION_TOOL.facebookHeadlineProduct,
  ],
  copy: 'Easily craft highly engaging copy for your Facebook Ads.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.facebookMixtureAd,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomModel: true,
  channel: CHANNEL.facebook,
  maxCampaignKeywords: 3,
  supportsCallToAction: false,
  isMixtureTool: true,
  supportsPromptInstructions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBenchmark: true,
  supportsBackgroundUrlBriefExtraction: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.twitterMixtureAd, {
  title: 'X Ad',
  titleForTooltip: 'X Ad',
  mixtureSubAssetTypeSubtitles: {
    twitter_title: 'Headline',
    twitter_copy: 'Copy',
  },
  mixtureSubGenerationTools: [
    GENERATION_TOOL.twitterCopyProduct,
    GENERATION_TOOL.twitterTitleProduct,
  ],
  copy: 'Write scroll-stopping X Ads.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.twitterMixtureAd,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  maxCampaignKeywords: 3,
  supportsCallToAction: false,
  isMixtureTool: true,
  supportsPromptInstructions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBackgroundUrlBriefExtraction: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestMixtureAd, {
  title: 'Pinterest Ad',
  titleForTooltip: 'Pinterest Ad',
  mixtureSubAssetTypeSubtitles: {
    pinterest_title: 'Title',
    pinterest_description: 'Description',
  },
  mixtureSubGenerationTools: [
    GENERATION_TOOL.pinterestDescriptionProduct,
    GENERATION_TOOL.pinterestTitleProduct,
  ],
  copy: 'Take it one step further with dazzling Pinterest Ads.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.pinterestMixtureAd,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  maxCampaignKeywords: 3,
  supportsCallToAction: false,
  isMixtureTool: true,
  supportsPromptInstructions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBackgroundUrlBriefExtraction: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.linkedinMixtureAd, {
  title: 'LinkedIn Ad',
  titleForTooltip: 'LinkedIn Ad',
  mixtureSubAssetTypeSubtitles: {
    linkedin_single_image_headline: 'Headline',
    linkedin_single_image_intro: 'Copy',
  },
  mixtureSubGenerationTools: [
    GENERATION_TOOL.linkedinIntroSingleImage,
    GENERATION_TOOL.linkedinHeadlineSingleImage,
  ],
  copy: 'Stand out with engrossing LinkedIn Single Image Ads.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.linkedinMixtureAd,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomModel: true,
  channel: CHANNEL.linkedin,
  maxCampaignKeywords: 3,
  supportsCallToAction: false,
  isMixtureTool: true,
  supportsPromptInstructions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBenchmark: true,
  supportsBackgroundUrlBriefExtraction: true,
});

/**
 * "landing" data-driven tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingHeadline, {
  title: 'Landing Page Headline',
  copy: 'Generate the perfect headline for your landing page.',
  article: 'a',
  briefTitle: 'Landing Page Headline',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingBuilderHeadline,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsMaxChars: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingSubheader, {
  title: 'Landing Page Subheader',
  copy: 'Write compelling subheaders that hook readers in.',
  article: 'a',
  briefTitle: 'Landing Page Subheader',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingBuilderSubHeadline,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsMaxChars: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingCTA, {
  title: 'Landing Page Call To Action',
  copy: 'Create irresistible call to actions that actually convert.',
  article: 'a',
  briefTitle: 'Call to Action',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingBuilderCta,
  collapseAdvancedOptions: true,
  supportsMaxChars: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingBenefits, {
  title: 'Landing Page Benefits',
  copy: "Tell readers what they'll gain by using your product.",
  article: 'a',
  briefTitle: 'List of Product Benefits',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.benefits,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsMaxChars: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingFeatures, {
  title: 'Landing Page Features',
  copy: "Build out a list of your product's key features.",
  article: 'a',
  briefTitle: 'List of Product Features',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.features,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsMaxChars: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingSEOMetadata, {
  title: 'SEO Meta Description',
  copy: 'Generate click-worthy meta descriptions to increase your visibility on Google.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingSEOMetadata,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsMaxChars: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});

/**
 * "product listing" data-driven tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.amazonProductListing, {
  title: 'Amazon Product Listing',
  copy: 'Write a persuasive paragraph for your Amazon listings.',
  article: 'an',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.amazonProductDescription,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.walmartProductListing, {
  title: 'Walmart Product Listing',
  copy: 'Write a persuasive paragraph for your Walmart listings.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.walmartProductDescription,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.generalProductListing, {
  title: 'General Product Listing',
  copy: 'Write a persuasive paragraph for your General listings.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.generalProductDescription,
  basicHasMultipleGenerationModes: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsPromptInstructions: true,
  supportsBackgroundUrlBriefExtraction: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicPersonalBio, {
  title: 'Personal Bio',
  copy: 'Generate a Personal Biography that stands out.',
  article: 'a',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.personalBio,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  excludedFromSuggestedPersonas: true,
  disableTOVModes: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicCompanyBio, {
  title: 'Company Bio',
  copy: 'Generate an "about us" section for your company.',
  article: 'a',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.companyBio,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  excludedFromSuggestedPersonas: true,
  disableTOVModes: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicEngagingQuestions, {
  title: 'Engaging Questions',
  copy: 'Create engaging questions for any topic.',
  article: 'an',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.engagingQuestions,
  supportsCustomerPersona: true,
  excludedFromSuggestedPersonas: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.basicQuoraAnswers, {
  title: 'Quora Answers',
  copy: 'Generate knowledgable responses to quora questions.',
  article: 'a',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.quoraAnswers,
  supportsCustomerPersona: true,
  excludedFromSuggestedPersonas: true,
});

/**
 * deprecated "product listing" data-driven tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.amazonProductDescription, {
  title: 'Amazon Product Listing',
  description: 'Paragraph Description',
  copy: 'Write a persuasive paragraph for your Amazon listings.',
  article: 'an',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.amazonProductDescription,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.amazonFeatureList, {
  title: 'Amazon Product Listing',
  description: 'Feature List',
  copy: 'Write a persuasive feature list for your Amazon listings.',
  article: 'an',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.amazonFeatureList,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.walmartProductDescription, {
  title: 'Walmart Product Listing',
  description: 'Paragraph Description',
  copy: 'Write a persuasive paragraph for your Walmart listings.',
  article: 'a',
  defaultAssetType: ASSET_TYPE.walmartProductDescription,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  briefType: BRIEF_TYPE.unifiedProduct,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.walmartFeatureList, {
  title: 'Walmart Product Listing',
  description: 'Feature List',
  copy: 'Write a persuasive paragraph for your Walmart listings.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.walmartFeatureList,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});

/**
 * "Social" data-driven tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.instagramCaption, {
  title: 'Instagram Caption',
  copy: 'Generate catchy captions for your Instagram posts.',
  article: 'an',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.instagramCaption,
  maxHashtags: 10,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  supportsPromptInstructions: true,
  hideBriefStonly: true,
  supportsBackgroundUrlBriefExtraction: true,
  collapseAdvancedOptions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsMaxChars: true,
  supportsBenchmark: true,
  supportsCustomModel: true,
  channel: CHANNEL.instagram,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.facebookPost, {
  title: 'Facebook Post',
  copy: 'Create engaging copy for your Facebook posts to maximize organic reach.',
  article: 'a',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.facebookPost,
  maxHashtags: 10,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  supportsPromptInstructions: true,
  hideBriefStonly: true,
  supportsBackgroundUrlBriefExtraction: true,
  collapseAdvancedOptions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsMaxChars: true,
  supportsBenchmark: true,
  supportsCustomModel: true,
  channel: CHANNEL.facebookPages,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.tweet, {
  title: 'X Post',
  copy: 'Generate witty tweets to keep your followers engaged.',
  article: 'a',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.tweet,
  maxHashtags: 10,
  supportsCustomerPersona: true,
  supportsPromptInstructions: true,
  hideBriefStonly: true,
  supportsBackgroundUrlBriefExtraction: true,
  collapseAdvancedOptions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsMaxChars: true,
  supportsCustomModel: true,
  supportsBenchmark: true,
  channel: CHANNEL.twitterSocial,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.linkedinPost, {
  title: 'LinkedIn Post',
  copy: 'Cut through the noise by generating compelling copy for your LinkedIn posts.',
  article: 'a',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.linkedinPost,
  maxHashtags: 10,
  supportsCustomerPersona: true,
  supportsPromptInstructions: true,
  hideBriefStonly: true,
  supportsBackgroundUrlBriefExtraction: true,
  collapseAdvancedOptions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsMaxChars: true,
  supportsBenchmark: true,
  supportsCustomModel: true,
  channel: CHANNEL.linkedinSocial,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestPostDescription, {
  title: 'Pinterest Pin',
  description: 'Description',
  copy: 'Take it one step further with dazzling Pinterest descriptions.',
  article: 'a',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.pinterestPost,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  maxHashtags: 10,
  supportsPromptInstructions: true,
  hideBriefStonly: true,
  supportsBackgroundUrlBriefExtraction: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
  supportsMaxChars: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestPostTitle, {
  title: 'Pinterest Pin',
  description: 'Title',
  copy: 'Step up your Pinterest game with stellar titles.',
  article: 'a',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.pinterestPostTitle,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  maxHashtags: 10,
  supportsPromptInstructions: true,
  hideBriefStonly: true,
  supportsBackgroundUrlBriefExtraction: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 4,
  supportsMaxChars: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.youtubeDescription, {
  title: 'Youtube Video Description',
  copy: "Generate enticing YouTube descriptions that grab viewers' attention.",
  article: 'a',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.youtubeDescription,
  maxHashtags: 10,
  supportsCustomerPersona: true,
  supportsPromptInstructions: true,
  collapseAdvancedOptions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.youtubeTitle, {
  title: 'Youtube Video Title',
  copy: 'Write smart YouTube titles to help viewers find your videos.',
  article: 'a',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.youtubeTitle,
  maxHashtags: 10,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsPromptInstructions: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
});
/**
 * "content" data-driven tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.facebookPrimaryTextContent, {
  title: 'Facebook Article Promotion',
  description: 'Primary Text',
  article: 'a',
  copy: 'Generate exceptionally effective copy for your promoted content ads on Facebook.',
  briefType: BRIEF_TYPE.unifiedContent,
  defaultAssetType: ASSET_TYPE.facebookPrimaryText,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.facebookHeadlineContent, {
  title: 'Facebook Article Promotion',
  description: 'Headline',
  copy: 'Generate ad headlines that promote your content.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedContent,
  defaultAssetType: ASSET_TYPE.facebookHeadline,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.outbrainHeadlineContent, {
  title: 'Outbrain Article Promotion',
  description: 'Headline',
  copy: 'Drive more traffic to your content with irresistible Outbrain headlines.',
  article: 'an',
  briefType: BRIEF_TYPE.unifiedContent,
  defaultAssetType: ASSET_TYPE.outbrainHeadline,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.taboolaHeadlineContent, {
  title: 'Taboola Article Promotion',
  description: 'Headline',
  copy: 'Drive more traffic to your content with irresistible Taboola headlines.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedContent,
  defaultAssetType: ASSET_TYPE.taboolaHeadline,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.twitterCopyContent, {
  title: 'X Article Promotion',
  description: 'Copy',
  article: 'a',
  copy: 'Write scroll-stopping copy for your promoted content ads on X.',
  briefType: BRIEF_TYPE.unifiedContent,
  defaultAssetType: ASSET_TYPE.twitterCopy,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.linkedinIntroContent, {
  title: 'LinkedIn Content Text Ad',
  description: 'Copy',
  briefType: BRIEF_TYPE.unifiedContent,
  defaultAssetType: ASSET_TYPE.linkedinIntro,
  collapseAdvancedOptions: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.linkedinHeadlineContent, {
  title: 'LinkedIn Content Text Ad',
  description: 'Headline',
  briefType: BRIEF_TYPE.unifiedContent,
  defaultAssetType: ASSET_TYPE.linkedinTitle,
  collapseAdvancedOptions: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestDescriptionContent, {
  title: 'Pinterest Article Promotion',
  description: 'Description',
  article: 'a',
  copy: 'Write a punchy Pinterest Ad that gets people to your content.',
  briefType: BRIEF_TYPE.unifiedContent,
  defaultAssetType: ASSET_TYPE.pinterestDescription,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestTitleContent, {
  title: 'Pinterest Article Promotion',
  description: 'Title',
  copy: 'Create stellar titles that will make your content shine.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedContent,
  defaultAssetType: ASSET_TYPE.pinterestTitle,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
});

/**
 * "email" and "sms" data-driven tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailSubject, {
  title: 'Email Subject Line',
  copy: 'Write enticing subject lines for all types of emails.',
  description: 'Subject Line',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedPromotionalEmail,
  defaultAssetType: ASSET_TYPE.emailSubject,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomModel: true,
  channel: CHANNEL.emailChannel,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 3,
  supportsCustomerPersona: true,
  supportsPromptInstructions: true,
  supportsMaxChars: true,
  collapseAdvancedOptions: true,
  supportsBackgroundUrlBriefExtraction: true,
  supportsBenchmark: true,
  variationLabelWithoutDescription: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailBody, {
  title: 'General Email',
  description: 'Body',
  copy: 'Create captivating email bodies suitable for any purpose, ensuring engagement, clarity, and effective communication.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedPromotionalEmail,
  defaultAssetType: ASSET_TYPE.emailBody,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomModel: true,
  channel: CHANNEL.emailChannel,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 3,
  supportsCustomerPersona: true,
  supportsPromptInstructions: true,
  supportsMaxChars: true,
  collapseAdvancedOptions: true,
  supportsBackgroundUrlBriefExtraction: true,
  supportsBenchmark: true,
  variationLabelWithoutDescription: true,
  noScore: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailMixture, {
  title: 'Email',
  titleForTooltip: 'Email',
  mixtureSubAssetTypeSubtitles: {
    email_subject: 'Subject Line',
    email_body: 'Body',
    email_promotional_body: 'Body',
    email_cold_outreach_body: 'Body',
  },
  mixtureSubGenerationTools: [GENERATION_TOOL.emailSubject, GENERATION_TOOL.emailBody],
  classifiedMixtureSubGenerationTools: [
    GENERATION_TOOL.emailPromotionalBody,
    GENERATION_TOOL.emailColdOutreachBody,
  ],
  copy: 'Create captivating emails suitable for any purpose, ensuring engagement, clarity, and effective communication.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedPromotionalEmail,
  defaultAssetType: ASSET_TYPE.emailMixture,
  isMixtureTool: true,
  supportsPromptInstructions: true,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBackgroundUrlBriefExtraction: true,
  supportsCustomModel: true,
  channel: CHANNEL.emailChannel,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 3,
  supportsBenchmark: true,
  variationLabelWithoutDescription: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.smsPromotional, {
  title: 'Promotional SMS',
  copy: 'Generate text messages that actually sell.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.sms,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBackgroundUrlBriefExtraction: true,
  supportsPromptInstructions: true,
});

/**
 * Rewrite tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailBodyRewrite, {
  title: 'Email Body',
  copy: 'Say goodbye to dull emails. Rewrite your email copy to make it even more persuasive.',
  briefType: BRIEF_TYPE.email,
  defaultAssetType: ASSET_TYPE.emailBody,
  generatingLoaderText: 'Email Body',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  supportsBenchmark: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailSubjectLineRewrite, {
  title: 'Email Subject Line',
  copy: 'Boost open rates by sprucing up your existing email subject line.',
  briefType: BRIEF_TYPE.email,
  defaultAssetType: ASSET_TYPE.emailSubjectLine,
  generatingLoaderText: 'Email subject line',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.facebookHeadlineProductRewrite, {
  title: 'Facebook Ad',
  description: 'Headline',
  copy: 'Rewrite your existing Facebook Ad copy to make it more effective.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.facebookHeadline,
  disableFormula: true,
  generatingLoaderText: 'Facebook ad - headline',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.facebookPrimaryTextProductRewrite, {
  title: 'Facebook Ad',
  description: 'Primary Text',
  copy: 'Rewrite your existing Facebook Ad copy to make it more effective.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.facebookPrimaryText,
  generatingLoaderText: 'Facebook ad - primary text',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.googleHeadlineProductRewrite, {
  title: 'Google Search Ad',
  description: 'Headline',
  copy: 'Make your Google Ads Headlines pop with a copy refresh.',
  article: 'an',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.googleHeadline,
  supportsCampaignKeywords: true,
  maxWordsPerKeyword: 5,
  maxCampaignKeywords: 3,
  generatingLoaderText: 'Google search ad - headline',
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.googleDescriptionProductRewrite, {
  title: 'Google Search Ad',
  description: 'Description',
  copy: 'Make your Google Ads Descriptions pop with a copy refresh.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.googleDescription,
  maxCampaignKeywords: 3,
  supportsCampaignKeywords: true,
  maxWordsPerKeyword: 5,
  generatingLoaderText: 'Google search ad - description',
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.outbrainHeadlineProductRewrite, {
  title: 'Outbrain Ad',
  description: 'Headline',
  copy: 'Draw in more traffic by refreshing your Outbrain headlines.',
  article: 'an',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.outbrainHeadline,
  generatingLoaderText: 'Outbrain headline',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.taboolaHeadlineProductRewrite, {
  title: 'Taboola Ad',
  description: 'Headline',
  copy: 'Boost your conversion rate by improving your Taboola headlines.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.taboolaHeadline,
  generatingLoaderText: 'Taboola - headline',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.twitterCopyProductRewrite, {
  title: 'X Ad',
  description: 'Copy',
  copy: 'Spruce up your X ads and make them stand out in the feed.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.twitterCopy,
  generatingLoaderText: 'X - copy',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestDescriptionProductRewrite, {
  title: 'Pinterest Ad',
  description: 'Description',
  copy: 'Transform you existing Pinterest ads into high-converting ones.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.pinterestDescription,
  generatingLoaderText: 'Pinterest ad - description',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingHeadlineRewrite, {
  title: 'Landing Page Headline',
  copy: 'Revamp your landing page headline to make it even more powerful and catchy.',
  article: 'a',
  briefTitle: 'Landing Page Headline',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingBuilderHeadline,
  generatingLoaderText: 'Landing page headline',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingSubheaderRewrite, {
  title: 'Landing Page Subheader',
  copy: "Rewrite your landing page subheaders to help capture readers' attention.",
  article: 'a',
  briefTitle: 'Landing Page Subheader',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingBuilderSubHeadline,
  generatingLoaderText: 'Landing page subheader',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.landingSEOMetadataRewrite, {
  title: 'SEO Meta Description',
  copy: "Battling Google's algorithm? Rewrite your SEO meta describes to boost visibility.",
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingSEOMetadata,
  generatingLoaderText: 'SEO meta description',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailColdOutreachBodyRewrite, {
  title: 'Cold Email',
  copy: 'Say goodbye to dull emails. Rewrite your email copy to make it even more persuasive.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.emailColdOutreachBody,
  generatingLoaderText: 'Cold email',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailPromotionalBodyRewrite, {
  title: 'Promotional Email Promotional',
  copy: 'Say goodbye to dull emails. Rewrite your email copy to make it even more persuasive.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.emailPromotionalBody,
  generatingLoaderText: 'Promotional email',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  supportsBenchmark: true,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.smsPromotionalRewrite, {
  title: 'Promotional SMS',
  copy: 'Trying to increase sales? Rewrite your Promotion SMS to draw in customers.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.sms,
  generatingLoaderText: 'Promotional SMS',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.linkedinHeadlineSingleImageRewrite, {
  title: 'LinkedIn Single Image Ad',
  description: 'Headline',
  copy: 'Get more clicks with an improved headline for your LinkedIn Single Image Ad.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.linkedinSingleImageHeadline,
  generatingLoaderText: 'LinkedIn single image ad - headline',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.linkedinIntroSingleImageRewrite, {
  title: 'LinkedIn Single Image Ad',
  description: 'Copy',
  copy: 'Get better engagement with an improved description for your LinkedIn Single Image Ad.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.linkedinSingleImageIntro,
  generatingLoaderText: 'LinkedIn single image ad - copy',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.instagramCaptionRewrite, {
  title: 'Instagram Caption',
  copy: 'Boost your likes by kicking your Instagram caption up a notch.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.instagramCaption,
  generatingLoaderText: 'Instagram caption',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.facebookPostRewrite, {
  title: 'Facebook Post',
  copy: 'Bring your Facebook post text to the next level.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.facebookPost,
  generatingLoaderText: 'Facebook post',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestPinTitleRewrite, {
  title: 'Pinterest Pin',
  description: 'Title',
  copy: 'Spruce up your Pinterest pin with a new and improved description.',
  generatingLoaderText: 'Pinterest pin - title',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.pinterestPostTitle,
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.pinterestPinDescriptionRewrite, {
  title: 'Pinterest Pin',
  description: 'Description',
  copy: 'Give your Pinterest pin a revised title that really pops.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.pinterestPost,
  generatingLoaderText: 'Pinterest pin - description',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.tweetRewrite, {
  title: 'X Post',
  copy: 'Make the most out of your tweets with a copy tune-up.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.tweet,
  generatingLoaderText: 'X post',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.linkedinPostRewrite, {
  title: 'LinkedIn Post',
  copy: 'Bring your LinkedIn post text to the next level.',
  briefType: BRIEF_TYPE.socialManual,
  defaultAssetType: ASSET_TYPE.linkedinPost,
  generatingLoaderText: 'LinkedIn post',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailSubjectRewrite, {
  title: 'Email Subject Line',
  copy: 'Boost open rates by sprucing up your existing email subject line.',
  briefType: BRIEF_TYPE.email,
  defaultAssetType: ASSET_TYPE.emailSubject,
  generatingLoaderText: 'Email subject line',
  supportsAutoSuggestKeywords: true,
  supportsSuggestKeywords: true,
  supportsBenchmark: true,
});

/**
 * "Teads" data-driven tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.teadsSingleVideoHeadline, {
  title: 'Teads Ad',
  description: 'Headline',
  copy: 'Generate effective headlines for ads.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.teadsSingleVideoHeadline,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.teadsSingleVideoDescription, {
  title: 'Teads Ad',
  description: 'Description',
  copy: 'Generate effective descriptions for ads.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.teadsSingleVideoDescription,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsCallToAction: false,
});
/**
 * Deprecated email tools
 */

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailSubjectLine, {
  title: 'General Email',
  description: 'Subject Line',
  copy: 'Write enticing subject lines for all types of emails.',
  article: 'a',
  briefType: BRIEF_TYPE.email,
  defaultAssetType: ASSET_TYPE.emailSubjectLine,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomModel: true,
  channel: CHANNEL.emailChannel,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 3,
  supportsCustomerPersona: true,
  excludedFromSuggestedPersonas: true,
  collapseAdvancedOptions: true,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailPromotionalSubjectLine, {
  title: 'Promotional Email',
  description: 'Subject Line',
  copy: 'Write one of these brilliant email subject lines for an exciting promotion.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedPromotionalEmail,
  defaultAssetType: ASSET_TYPE.emailPromotionalSubjectLine,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomModel: true,
  channel: CHANNEL.emailChannel,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailPromotionalBody, {
  title: 'Promotional Email',
  description: 'Body',
  copy: 'Have something amazing for your customers? Let them know! Write an awesome email about a new product or an exciting promotion.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedPromotionalEmail,
  defaultAssetType: ASSET_TYPE.emailPromotionalBody,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomModel: true,
  channel: CHANNEL.emailChannel,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBenchmark: true,
  variationLabelWithoutDescription: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailColdOutreachSubjectLine, {
  title: 'Cold Email',
  description: 'Subject Line',
  copy: 'Generate subject lines that get people to open emails.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.emailColdOutreachSubjectLine,
  supportsCustomModel: true,
  channel: CHANNEL.emailChannel,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBenchmark: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.emailColdOutreachBody, {
  title: 'Cold Email',
  description: 'Body',
  copy: 'Create powerful, convincing emails to attract potential customers to your product or service.',
  article: 'a',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.emailColdOutreachBody,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  supportsCustomModel: true,
  channel: CHANNEL.emailChannel,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
  collapseAdvancedOptions: true,
  supportsBenchmark: true,
  variationLabelWithoutDescription: true,
});

/**
 * deprecated "Teads" data-driven tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.teadsSingleImageHeadline, {
  title: 'Teads Single Image',
  description: 'Headline',
  copy: 'Generate effective descriptions and headlines for Single Image ads.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.teadsSingleImageHeadline,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.teadsSingleImageDescription, {
  title: 'Teads Single Image',
  description: 'Description',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.teadsSingleImageDescription,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.teadsCarouselHeadline, {
  title: 'Teads Carousel',
  copy: 'Generate effective headlines for Carousel ads.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.teadsCarouselHeadline,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.teadsSocialLayout1Headline, {
  title: 'Teads Social Layout I',
  description: 'Headline',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.teadsSocialLayout1Headline,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.teadsSocialLayout1PrimaryText, {
  title: 'Teads Social Layout I',
  description: 'Primary Text',
  copy: 'Generate effective primary texts, headlines and descriptions for Social Layout I ads.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.teadsSocialLayout1PrimaryText,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.teadsSocialLayout1Description, {
  title: 'Teads Social Layout I',
  description: 'Description',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.teadsSocialLayout1Description,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.teadsSocialLayout2PrimaryText, {
  title: 'Teads Social Layout II',
  description: 'Primary Text',
  copy: 'Generate effective primary texts for Social Layout II ads.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.teadsSocialLayout2PrimaryText,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxWordsPerKeyword: 4,
  supportsCustomerPersona: true,
});

/**
 * "Create your own" freestyle tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.freestyle, {
  title: 'General Prompt',
  description: '',
  copy: 'Generate any copy you want!\nTell the AI what to write and how.',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.canvasFreestyle,
  defaultNumberOfVariations: 1,
  supportsBackgroundUrlBriefExtraction: true,
  supportsCustomerPersona: true,
  supportsPromptInstructions: true,
  supportsSuggestKeywords: true,
  supportsMaxChars: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.createYourOwn, {
  title: 'General Prompt',
  description: '',
  copy: 'Generate any copy you want!\nTell the AI what to write and how.',
  briefType: BRIEF_TYPE.canvas,
  defaultAssetType: ASSET_TYPE.canvasFreestyle,
  defaultNumberOfVariations: 1,
  supportsBackgroundUrlBriefExtraction: true,
});

/**
 * LPCO tools
 */
GENERATION_TOOL_PROPS.add(GENERATION_TOOL.lpcoLandingHeadline, {
  title: '',
  description: '',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingHeadline,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.lpcoLandingCTA, {
  title: 'Create A New Use Case',
  description: '',
  copy: 'Generate content for any purpose and any use case in a breeze.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingCTA,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.lpcoLandingProductDescription, {
  title: '',
  description: '',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingProductDescription,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.lpcoLandingProductName, {
  title: '',
  description: '',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingProductName,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.lpcoLandingCopy, {
  title: '',
  description: '',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingCopy,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.wtmDefault, {
  title: 'Website automation',
  copy: 'Optimize on-site copy to display the right message to the right audience.',
  briefType: BRIEF_TYPE.unifiedProduct,
  defaultAssetType: ASSET_TYPE.landingProductDescription,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.blogDescription, {
  title: 'Blog Wizard',
  copy: 'Go from a blank page to a brilliant blog post in minutes.',
  channel: CHANNEL.blogUrls,
  supportsCustomModel: true,
  briefType: BRIEF_TYPE.blogPostBuilder,
  defaultAssetType: ASSET_TYPE.blogPostContent,
});

GENERATION_TOOL_PROPS.add(GENERATION_TOOL.customMultiPrompt, {
  title: 'Saved template',
  copy: 'Custom tool description',
  multiPrompt: true,
  supportsBackgroundUrlBriefExtraction: true,
  briefType: BRIEF_TYPE.unifiedProduct,
  supportsCustomerPersona: true,
  disableTOVModes: true,
  defaultAssetType: ASSET_TYPE.canvasFreestyle,
  supportsSuggestKeywords: true,
  supportsAutoSuggestKeywords: true,
  maxSuggestKeywords: 3,
  maxWordsPerKeyword: 4,
  defaultNumberOfVariations: 1,
  promptFields: {
    titles: {},
    placeholders: {},
  },
  numberOfVariations: [1],
  promptTools: {},
  longDesc: 'Custom tool long description',
});

const getToolProp = (type, propName, defaultValue = null) => {
  const props = GENERATION_TOOL_PROPS.get(type);
  if (props && props[propName] != null) {
    return props[propName];
  }
  return defaultValue;
};
export const getToolTitle = (tool) => getToolProp(tool, 'title', '');
export const getToolTitleForTooltip = (tool) =>
  getToolProp(tool, 'titleForTooltip', getToolTitle(tool));

export const getToolTitleArticle = (tool) => getToolProp(tool, 'article', '');

export const getToolBriefTitle = (tool) => getToolProp(tool, 'briefTitle', getToolTitle(tool));

export const getToolDescription = (tool) => getToolProp(tool, 'description', '');

export const getToolMixtureSubAssetTypeSubtitles = (tool, subAssetType) => {
  return getToolProp(tool, 'mixtureSubAssetTypeSubtitles', '')[subAssetType] || '';
};

export const getToolMixtureSubGenerationTools = (tool) => {
  return getToolProp(tool, 'mixtureSubGenerationTools', []);
};

export const getToolClassifiedMixtureSubGenerationTools = (tool) => {
  return getToolProp(tool, 'classifiedMixtureSubGenerationTools', []);
};

export const getToolMixtureSubGenerationToolsModeOverride = (tool) => {
  return getToolProp(tool, 'mixtureSubGenerationToolsModeOverride', []);
};

export const getToolGeneratingLoaderText = (tool) => getToolProp(tool, 'generatingLoaderText', '');

export const getToolCopy = (tool) => getToolProp(tool, 'copy', '');
export const getToolLongDesc = (tool) => getToolProp(tool, 'longDesc', '');

export const doesToolSupportSuggestKeywords = (tool) =>
  getToolProp(tool, 'supportsSuggestKeywords', false);

export const doesToolSupportPromptInstruction = (tool) =>
  getToolProp(tool, 'supportsPromptInstructions', false);

export const doesToolSupportMixtureModes = (tool) => {
  if (tool === GENERATION_TOOL.googleRSA) {
    return false;
  }
  return isMixtureTool(tool);
};

export const doesToolSupportCampaignKeywords = (tool) =>
  getToolProp(tool, 'supportsCampaignKeywords', false);

export const doesToolSupportHashtags = (tool) => getToolProp(tool, 'supportsHashtags', false);

export const doesToolHasMultipleGenerationModes = (tool) =>
  getToolProp(tool, 'basicHasMultipleGenerationModes', false);

export const shouldDisableTOVModes = (tool) => getToolProp(tool, 'disableTOVModes', false);

export const getToolMaxSuggestKeywords = (tool) => getToolProp(tool, 'maxSuggestKeywords', 1);

export const getToolMaxCampaignKeywords = (tool) => getToolProp(tool, 'maxCampaignKeywords', 1);

export const getToolMaxHashtags = (tool) => getToolProp(tool, 'maxHashtags', 1);

export const getToolMaxWordsPerKeyword = (tool) => getToolProp(tool, 'maxWordsPerKeyword', 4);

export const getToolDefaultNumberOfVariations = (tool) =>
  getToolProp(tool, 'defaultNumberOfVariations', null);

export const getToolNumberOfVariations = (tool) => getToolProp(tool, 'numberOfVariations', null);

export const getToolBriefType = (tool) => getToolProp(tool, 'briefType', null);

export const getToolDefaultAssetType = (tool) => getToolProp(tool, 'defaultAssetType', null);
export const isToolVariationLabelWithoutDescription = (tool) =>
  getToolProp(tool, 'variationLabelWithoutDescription', false);

export const isToolSupportCustomerPersona = (tool) =>
  getToolProp(tool, 'supportsCustomerPersona', false);

export const doesToolSupportMaxChars = (tool) => getToolProp(tool, 'supportsMaxChars', false);

export const isToolSupportCustomModel = (tool) => getToolProp(tool, 'supportsCustomModel', false);

export const isToolSupportBenchmark = (tool) => getToolProp(tool, 'supportsBenchmark', false);

export const doesToolSupportManualInstructions = (tool) =>
  getToolProp(tool, 'supportsManualInstructions', false);

export const doesToolSupportCallToAction = (tool) =>
  getToolProp(tool, 'supportsCallToAction', false);

export const doesToolSupportBackgroundUrlBriefExtraction = (tool) =>
  getToolProp(tool, 'supportsBackgroundUrlBriefExtraction', false);

export const shouldToolHideBriefStonly = (tool) => getToolProp(tool, 'hideBriefStonly', false);

export const doesToolSupportCollapseAdvancedOptions = (tool) =>
  getToolProp(tool, 'collapseAdvancedOptions', false);

export const isSummarizerGenerationTool = (tool) => tool === GENERATION_TOOL.summarizer;

export const doesToolSupportTalkingPointAutoSuggestions = (tool) =>
  getToolProp(tool, 'supportsAutoSuggestKeywords', false);

export const isToolExcludedFromSuggestedPersonas = (tool) =>
  getToolProp(tool, 'excludedFromSuggestedPersonas', false);

export const isMixtureTool = (tool) => getToolProp(tool, 'isMixtureTool', false);
export const isMultiPromptTool = (tool) => getToolProp(tool, 'multiPrompt', false);
export const getMultiPromptFields = (tool) => getToolProp(tool, 'promptFields', null);
export const getMultiPromptSubtools = (tool) => getToolProp(tool, 'promptTools', {});

export const isNoScoreTool = (tool) => getToolProp(tool, 'noScore', false);

export const getToolChannel = (tool) => getToolProp(tool, 'channel', null);
export const getBackendResourcesFromTool = (tool) => {
  const toolChannel = getToolChannel(tool);
  if (toolChannel === CHANNEL.emailChannel) {
    return EMAILS_CHANNELS_AND_RESOURCES.map((channel) => alignChannelWithBackend(channel));
  }

  return [alignChannelWithBackend(getToolChannel(tool))];
};

export const doesToolSupportFormulas = (tool) =>
  (doesToolHasMultipleGenerationModes(tool) || isDataDrivenGenerationTool(tool)) &&
  !isMultiPromptTool(tool) &&
  tool !== GENERATION_TOOL.googleRSA;

/**
 * Does tool has description text in it's title already
 * For example: { title: 'Email Subject Line', description: 'Subject Line' }
 * @param {GENERATION_TOOL} tool - generation tool
 * @returns {boolean} - true if tool has description in title
 */
export const toolHasDescriptionInTitle = (tool) => tool === GENERATION_TOOL.emailSubject;

/**
 * Get full generation tool title
 * @param {GENERATION_TOOL} tool - generation tool
 * @param {object} [options] - options
 * @param {string} [options.description] - tool description override
 * @returns {string} - tool title
 */
export const getToolLabel = (tool, options) => {
  const prepareDescription = () => {
    if (options?.description != null) {
      return options?.description;
    }
    if (toolHasDescriptionInTitle(tool)) {
      return '';
    }
    return getToolDescription(tool);
  };

  const title = getToolTitle(tool);
  const description = prepareDescription();
  return `${title}${description ? ` - ${description}` : ''}`;
};

export const initMultiPromptSubGenerationToolProps = (generationTool, customFormulas) => {
  const subGenerationTools = getToolMixtureSubGenerationTools(generationTool);
  const subGenerationToolsProps = {};

  subGenerationTools.forEach((subGenerationTool) => {
    const availableTemplates = getToolGenerationModes(subGenerationTool);
    const visibleTemplates = filterVisibleDDTemplates(availableTemplates);
    const customFormula = customFormulas?.find(
      (customFormula) => customFormula.generationTool === subGenerationTool
    );
    const selectedModeId = customFormula?.id || visibleTemplates[0];
    const selectedModeType = customFormula
      ? GENERATION_MODE_TYPE.customFormula
      : GENERATION_MODE_TYPE.common;
    subGenerationToolsProps[subGenerationTool] = {
      isCustomFormulasVisible: availableTemplates.includes(AI_SUGGESTION_MODE.customFormula),
      maxChars:
        doesToolSupportMaxChars(subGenerationTool) && getDefaultCharsByTool(subGenerationTool),
      selectedModeId,
      selectedModeType,
      visibleDDTemplates: visibleTemplates,
      errors: {},
      generationTool: subGenerationTool,
      subGenerationTool: subGenerationTool,
    };
  });
  return subGenerationToolsProps;
};
