import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 381,
    },
    dialogTitle: {
      paddingBottom: theme.spacing(0.5),
    },
    title: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightBold,
      marginTop: theme.spacing(3),
    },
    dialogContent: {
      paddingTop: theme.spacing(1),
    },
    content: {
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    buttonClose: {
      position: 'absolute',
      top: '20px',
      right: '20px',

      '& svg': {
        fontSize: '13px',
      },
    },
    dialogActions: {
      display: 'flex',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(5),
      justifyContent: 'center',
    },
    buttonCancel: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    flex1: {
      flex: 1,
    },
    buttonSubmit: {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  { name: 'ConfirmationDialog' }
);
