import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { CUSTOM_DATASET_FRAGMENT } from './schema';

//deprecated - change with the new one
const QUERY = gql`
  query CustomerCustomDataset(
    $customerId: ID!
    $brandVoiceIds: [ID!]
    $customDatasetId: ID
    $dataSource: PerformanceCenterDataSource
  ) {
    getCustomDatasets(
      customerId: $customerId
      brandVoiceIds: $brandVoiceIds
      customDatasetId: $customDatasetId
      dataSource: $dataSource
    ) {
      ...CustomDataset
    }
  }
  ${CUSTOM_DATASET_FRAGMENT}
`;

export const queryCustomModels = ({ customerId, brandVoiceIds, customModelId, dataSource }) =>
  executeGqlQuery({
    query: QUERY,
    variables: { customerId, brandVoiceIds, dataSource, customDatasetId: customModelId },
  }).then(({ data }) => getSingleProp(data));
