import { createContainer } from 'react-sweet-state';

import { SystemStore, PRIVATE_ACTIONS } from './SystemStore';

export const SystemStoreContainer = createContainer(SystemStore, {
  onInit:
    () =>
    ({ setState, dispatch }, { state }) => {
      if (state) {
        setState(state);
      }

      dispatch(PRIVATE_ACTIONS.init());
    },
  onUpdate:
    () =>
    ({ setState, dispatch }, { currentUser, state }) => {
      if (state) {
        setState(state);
      }
    },
});
