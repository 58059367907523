import { CHANNEL, alignChannelFromBackend } from './channel';
import { GENERATION_TOOL } from './generationTool';
import { EMAIL_CSV_RESOURCE } from './performance';

export const CUSTOM_MODEL_STATUS = {
  created: 'created',
  training_in_progress: 'training_in_progress',
  recreating: 'recreating',
  error: 'error',
  active: 'active', // after progress
};

const PROCESSING_ERROR_TYPE = {
  notEnoughDatasetError: 'not_enough_dataset_error',
  notEnoughRelevantDataError: 'not_enough_relevant_data_error',
  assetTypeNotSupportedError: 'asset_type_not_supported_error',
  notEnoughEnglishDatasetError: 'not_enough_english_dataset_error',
  systemError: 'system_error',
};

const PROCESSING_ERROR_TYPE_PROPS = {
  [PROCESSING_ERROR_TYPE.notEnoughDatasetError]: {
    message: 'Not enough dataset examples to train the model',
  },
  [PROCESSING_ERROR_TYPE.notEnoughRelevantDataError]: {
    message: 'Custom model training failed. Not enough training assets were available',
  },
  [PROCESSING_ERROR_TYPE.assetTypeNotSupportedError]: {
    message: 'Custom model training failed. Not enough training assets were available',
  },
  [PROCESSING_ERROR_TYPE.notEnoughEnglishDatasetError]: {
    message: 'Custom model training failed. Not enough training assets were available',
  },
  [PROCESSING_ERROR_TYPE.systemError]: {
    message: 'something went wrong',
  },
};

export const getProcessingErrorTypeMessage = (type) =>
  PROCESSING_ERROR_TYPE_PROPS?.[type]?.message ||
  'We were unable to create a custom model. Please try again later.';

export const getCustomModelTexts = (customModelId, customModels) => {
  const selectedCustomModel = customModels?.find((customModel) => customModel.id === customModelId);

  return {
    customModelTitle: selectedCustomModel?.accountName,
    customModelLabel: selectedCustomModel?.brandVoiceName,
  };
};

export const filterActiveCustomModels = (customModels) => {
  return customModels.filter((customModel) => customModel.status === CUSTOM_MODEL_STATUS.active);
};

export const getGenerationToolFromCustomModel = (customModel) => {
  const channel = alignChannelFromBackend(customModel?.dataSource);
  switch (channel) {
    case CHANNEL.facebook:
      return GENERATION_TOOL.facebookMixtureAd;
    case CHANNEL.linkedin:
      return GENERATION_TOOL.linkedinMixtureAd;
    case CHANNEL.linkedinSocial:
      return GENERATION_TOOL.linkedinPost;
    case CHANNEL.facebookPages:
      return GENERATION_TOOL.facebookPost;
    case CHANNEL.instagram:
      return GENERATION_TOOL.instagramCaption;
    case CHANNEL.hubspot:
    case CHANNEL.salesforce:
    case EMAIL_CSV_RESOURCE.emailColdCsvResource:
    case EMAIL_CSV_RESOURCE.emailPromotionCsvResource:
      return GENERATION_TOOL.emailMixture;
    case EMAIL_CSV_RESOURCE.emailSubjectLineCsvResource:
      return GENERATION_TOOL.emailSubject;
    case CHANNEL.blogUrls:
      return GENERATION_TOOL.blogDescription;
    default:
      return null;
  }
};
