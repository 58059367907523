import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './useStyles';

export const ResourcesUsageHeader = ({ limitData }) => {
  const styles = useStyles();
  const { currentCount, limit } = limitData;

  return (
    <div className={styles.rowsLimitContainer}>
      <span data-testid="FeatureLimitedProgressBar_limitCountLabel">
        <span className={styles.semiBoldLabel}>
          {currentCount}/{limit}
        </span>{' '}
        resources used{' '}
      </span>
    </div>
  );
};

ResourcesUsageHeader.propTypes = {
  limitData: PropTypes.shape({
    currentCount: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
};
