import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    unused: {},
    selected: {},

    root: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(0.5),
      alignItems: 'center',
      cursor: 'pointer',
      width: 200,
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(-0.5),

      '&:not($unused)': {
        '&$selected': {
          backgroundColor: COLORS.neutral.silver,
        },
        '&:hover': {
          backgroundColor: '#F7F9FD',
        },
      },
    },

    counter: {
      height: 20,
      width: 20,
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(13),
      fontWeight: theme.typography.fontWeightMedium,
      borderRadius: 4,
      textAlign: 'center',
      padding: theme.spacing(0.5),
    },

    label: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.gray.black,
      maxWidth: 190,

      '&:not($unused)': {
        '&$selected': {
          color: COLORS.primary.default,
        },
      },
    },
  }),
  { name: 'Keyword' }
);
