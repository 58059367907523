export const DOCUMENT_SHARE_MODE = {
  public: 'public_access',
  restricted: 'restricted_access',
};

const DOCUMENT_SHARE_MODE_LABELS = {
  [DOCUMENT_SHARE_MODE.public]: {
    title: 'Public',
    description: 'Anyone with a link can view',
  },
  [DOCUMENT_SHARE_MODE.restricted]: {
    title: 'Private',
    description: 'Only team members can edit/view',
  },
};

export const getDocumentShareModeTitle = (mode) => {
  return DOCUMENT_SHARE_MODE_LABELS[mode]?.title || mode;
};

export const getDocumentShareModeDescription = (mode) => {
  return DOCUMENT_SHARE_MODE_LABELS[mode]?.description || mode;
};
