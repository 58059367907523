import { useMemo } from 'react';

import { createStateSelectorHook } from 'src/lib/sweetState';
import { MultiPromptTemplatesStore } from 'src/store';
import { usePrepareMultiPromptTemplates } from 'src/services';

const useMultiPromptTemplatesState = createStateSelectorHook(MultiPromptTemplatesStore, [
  'multiPromptTemplates',
]);

export const useMultiPromptTemplates = (includeDraft) => {
  const { multiPromptTemplates } = useMultiPromptTemplatesState();
  const preparedMultiPromptTemplates = usePrepareMultiPromptTemplates(multiPromptTemplates);

  return useMemo(() => {
    return includeDraft
      ? preparedMultiPromptTemplates
      : preparedMultiPromptTemplates.filter((t) => t.status !== 'draft' && t.status !== 'released');
  }, [preparedMultiPromptTemplates, includeDraft]);
};
