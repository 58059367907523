import { STORAGE_KEY_OAUTH_DATA } from 'src/data/storage';
import { getQueryParams } from 'src/lib/browser';
import { useEffectOnce } from 'src/lib/hooks';
import * as storage from 'src/lib/storage';

const TwitterAuthCallback = () => {
  useEffectOnce(() => {
    const queryParams = getQueryParams(window.location.search);
    storage.writeItem(STORAGE_KEY_OAUTH_DATA, JSON.stringify(queryParams));

    window.close();
  });

  return null;
};

export default TwitterAuthCallback;
