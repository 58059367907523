import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation performanceCenterDisconnectAccount(
    $accountId: ID!
    $customerId: ID!
    $dataSource: PerformanceCenterDataSource!
  ) {
    performanceCenterDisconnectAccount(
      accountId: $accountId
      customerId: $customerId
      dataSource: $dataSource
    ) {
      id
    }
  }
`;

export const performanceCenterDisconnectAccount = (variables) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
