import { isString } from 'lodash';

import * as env from 'src/lib/env';
import { isSentryTestException } from './utils';

const exceptionsFromEvent = (event) => event?.exception?.values || [];

const framesFromException = (exception) => exception?.stacktrace?.frames || [];

// In some cases we have "sentry" wrapper module in the stacktrace - we don't need it
const withoutFirstSentryFrame = (frames) => {
  if (
    frames[0]?.function === 'sentryWrapped' ||
    frames[0]?.filename?.includes('node_modules/@sentry')
  ) {
    return frames.slice(1);
  }
  return frames;
};

/*
  event - Event in node_modules/@sentry/types/dist/event.d.ts
  error = {
    type?: string;
    value?: string;
    path?: string;
  }
 */
const isErrorInPath = (event, error) => {
  const filterException = (exception) => {
    if (error.type !== undefined && exception.type !== error.type) {
      return false;
    }

    if (error.value !== undefined && !`${exception.value}`.includes(error.value)) {
      return false;
    }

    if (error.path !== undefined) {
      const stackFrames = framesFromException(exception);
      return stackFrames.some((frame) => `${frame.filename}`.includes(error.path));
    }

    return true;
  };

  return exceptionsFromEvent(event).some(filterException);
};

const exceptionIncludesValue = (event, value) =>
  exceptionsFromEvent(event).some((ex) => ex.value != null && ex.value.includes(value));

const filterHintError = (hint, message) => {
  const error = hint.originalException;
  let errorMessage = '';
  if (typeof error === 'string') {
    errorMessage = error;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  return errorMessage.includes(message);
};

const isGoogleTagErrorEvent = (event) =>
  isErrorInPath(event, {
    type: 'TypeError',
    value: 'Illegal invocation',
    path: 'gtag',
  }) ||
  isErrorInPath(event, {
    type: 'fetch_generic_error',
    path: 'gtag',
  }) ||
  isErrorInPath(event, {
    type: 'TypeError',
    value: 'localStorage',
    path: '/ping.min.js',
  }) ||
  // ALM-3106+ALM-5051: Filter out "stonly"-related events
  isErrorInPath(event, {
    path: '.stonly.js',
  }) ||
  // ALM-3726: Do not react to "stonly"-related module load errors
  isErrorInPath(event, {
    type: 'ChunkLoadError',
    value: 'stonly.com',
  }) ||
  // ALM-3779: GTM related event
  isErrorInPath(event, {
    path: '/scripts/eid.es5.js',
  }) ||
  // ALM-3780: Do not react to "grammarly SDK" related errors
  isErrorInPath(event, {
    value: 'Cannot convert undefined or null to object',
    path: 'grammarly-editor-sdk',
  });

const isThirdPartyLibraryErrorEvent = (event, hint) => {
  if (!hint) {
    return false;
  }
  return (
    filterHintError(hint, 'privateSpecialRepair is not defined') ||
    filterHintError(hint, '$ is not defined') ||
    filterHintError(hint, 'chrome is not defined') ||
    filterHintError(hint, 'GTS is not defined') ||
    filterHintError(hint, "Unexpected token '<'") ||
    filterHintError(hint, "Unexpected token 'var'") ||
    // see https://stackoverflow.com/questions/72488297/referenceerror-getvaluesofautofillinputs-cant-find-variable-paymentautofillco
    filterHintError(hint, "Can't find variable: PaymentAutofillConfig")
  );
};

// Ignore errors if stacktrace starts with <anonymous> module
const fromIrrelevantModulesEvent = (event) => {
  const exceptions = exceptionsFromEvent(event);

  // Always allow "test" exception
  if (isSentryTestException(exceptions[0])) {
    return false;
  }

  const frames = framesFromException(exceptions[0]);
  const cleanedFrames = withoutFirstSentryFrame(frames);
  const firstFilename = cleanedFrames?.[0]?.filename;
  const firstAbsPath = cleanedFrames?.[0]?.abs_path;

  return (
    firstFilename === '<anonymous>' ||
    firstFilename?.startsWith('chrome-extension://') ||
    firstAbsPath?.includes('clarity.ms') ||
    firstAbsPath?.includes('taboola.com') ||
    firstAbsPath?.includes('clearbit.com') ||
    firstAbsPath?.includes('stonly.com')
  );
};

export const shouldSentryEventBeSend = (event, hint) => {
  if (!event) {
    return false;
  }

  if (fromIrrelevantModulesEvent(event)) {
    return false;
  }

  if (isGoogleTagErrorEvent(event, hint)) {
    return false;
  }

  if (isThirdPartyLibraryErrorEvent(event, hint)) {
    return false;
  }

  // By default, all events sent to Sentry
  return true;
};

export const prepareIgnoreErrors = () => {
  // Sentry community's compiled a list of common ignore rules
  //   https://docs.sentry.io/platforms/node/configuration/filtering/
  const result = [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // See https://github.com/getsentry/sentry-javascript/issues/2546#issuecomment-1727939255
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
  ];

  // From time to time we get this CORS-related errors thrown from one of our 3rd party integrations:
  //   gtm,   
  // We don't care about these to push them to Sentry.
  result.push('Blocked a frame with origin');

  return result;
};

export const prepareDenyUrls = () => {
  // Sentry community's compiled a list of common ignore rules
  //   https://docs.sentry.io/platforms/node/configuration/filtering/
  return [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /cdn\.nmgassets\.com/i, // namogoo scripts
  ];
};

const KNOWN_NON_CRITICAL_ERROR_MESSAGES = [
  'Non-Error promise rejection captured with',
  // We often get these from browser extensions, like "Grammarly"
  'ResizeObserver loop',
  // This might be triggered by Apollo, when user closes the tab before the page is fully loaded
  'AbortError: The user aborted a request.',
];
const includesKnownNonCriticalErrorMessage = (event) =>
  KNOWN_NON_CRITICAL_ERROR_MESSAGES.some((message) => exceptionIncludesValue(event, message));

const includesKnownNonCriticalGTMError = (event) =>
  isErrorInPath(event, {
    type: 'QuotaExceededError',
    path: 'googletagmanager.com',
  });

const includesKnownNonCriticalSlateError = (event) =>
  isErrorInPath(event, {
    value: 'Cannot resolve a DOM point from Slate point',
  }) ||
  isErrorInPath(event, {
    value: 'Cannot resolve a Slate point from DOM point',
  }) ||
  isErrorInPath(event, {
    value: 'Cannot resolve a Slate node from DOM node',
  }) ||
  isErrorInPath(event, {
    value: "Failed to execute 'setEnd' on 'Range'",
    path: 'slate-react',
  }) ||
  isErrorInPath(event, {
    value: 'Unable to find DocumentOrShadowRoot',
    path: 'slate-react',
  });

export const transformEventBeforeSend = (event, hint) => {
  if (includesKnownNonCriticalErrorMessage(event)) {
    /* eslint-disable no-param-reassign */
    event.level = 'info';
    event.extra = {
      ...event.extra,
      original_level: event.level,
    };
    /* eslint-enable no-param-reassign */

    return event;
  }

  if (includesKnownNonCriticalGTMError(event)) {
    /* eslint-disable no-param-reassign */
    event.level = 'info';
    event.tags = {
      ...event.tags,
      original_level: event.level,
      transaction: 'GTM',
    };
    /* eslint-enable no-param-reassign */

    return event;
  }

  if (includesKnownNonCriticalSlateError(event)) {
    /* eslint-disable no-param-reassign */
    event.level = 'info';
    event.tags = {
      ...event.tags,
      original_level: event.level,
      transaction: 'Slate',
    };
    /* eslint-enable no-param-reassign */

    return event;
  }

  //add the syntheticException when it's a critical error for more information
  /* eslint-disable no-param-reassign */
  event.extra = {
    ...event.extra,
    original_exception: hint?.syntheticException,
  };
  /* eslint-enable no-param-reassign */

  return event;
};

const mapTagName = (element) => {
  if (!element) {
    return '?';
  }

  const { tagName } = element;

  if (element.dataset) {
    const datasetData =
      element.dataset.sentryId || element.dataset.testid || element.dataset.onboarding;
    if (datasetData) {
      return `${tagName}[${datasetData}]`;
    }
  }

  if (element.id) {
    return `${tagName}#${element.id}`;
  }

  if (element.className) {
    return `${tagName}.${element.className}`;
  }

  return tagName;
};

export const createSentryPath = (path) => path.map(mapTagName).reverse().splice(2).join(' > ');

export const getGraphQLBreadcrumbData = (breadcrumb, hint) => {
  const breadcrumbData = breadcrumb?.data;
  const input = hint?.input;
  if (!breadcrumbData || !input) {
    return null;
  }

  const [url, inputData] = input;
  if (isString(url) && url.startsWith(env.getBackendUrl())) {
    try {
      const body = JSON.parse(inputData?.body);
      if (body.operationName) {
        return {
          operationName: body.operationName,
        };
      }
    } catch (e) {
      // do nothing
    }
  }

  return null;
};
