import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, FormControlLabel, Typography } from '@material-ui/core';

import { IconYoutubeWithWhiteTriangle } from 'src/assets/icons/YoutubeWithWhiteTriangle';
import { Checkbox } from 'src/components/Checkbox';
import WorkspacesVideoThumbnail from 'src/assets/img/worspaces_video_thumbnail.jpg';
import useStyles from './useStyles';
import { useHandleExplainerClick } from './useHandleExplainerClick';

export const TooltipContentWorkspaces = forwardRef(
  (
    {
      className,
      onPause,
      onContinue,
      onNextClick,
      dontShowAgain,
      onDontShowAgainChange,
    },
    ref
  ) => {
    const styles = useStyles();
    const onExplainerVideoClick = useHandleExplainerClick(onPause, onContinue);

    return (
      <div className={cx(styles.root, className)} ref={ref}>
        <div
          className={styles.videoThumbnailBlock}
          onClick={onExplainerVideoClick}
        >
          <img src={WorkspacesVideoThumbnail} alt="explainer video" />
          <IconYoutubeWithWhiteTriangle className={styles.iconYoutube} />
        </div>

        <Typography className={styles.title}>
          NEW: Product workspaces
        </Typography>
        <Typography className={styles.description}>
          Keep your content organized by creating a workspace for each product
          you are promoting.
        </Typography>

        <div className={styles.footer}>
          <FormControlLabel
            className={styles.checkboxLabel}
            classes={{ root: cx(styles.checkboxLabel), label: styles.label }}
            control={
              <Checkbox
                className={styles.checkbox}
                checked={dontShowAgain}
                onChange={onDontShowAgainChange}
              />
            }
            label="Don't show again"
          />

          <Button
            className={styles.button}
            onClick={onNextClick}
            variant="contained"
            color="primary"
          >
            Got it
          </Button>
        </div>
      </div>
    );
  }
);

TooltipContentWorkspaces.propTypes = {
  className: PropTypes.string,
  onPause: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  dontShowAgain: PropTypes.bool,
  onDontShowAgainChange: PropTypes.func.isRequired,
};

TooltipContentWorkspaces.defaultProps = {
  className: null,
  dontShowAgain: false,
};
