import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip, Typography } from '@material-ui/core';

import { formatByObject } from 'src/utils/format';
import { CheckmarkIcon } from 'src/assets/icons/Checkmark';
import { IconTimes } from 'src/assets/icons/Times';
import IconClose from 'src/assets/icons/Close';
import useStyles from './useStyles';

export const PlanFeature = ({
  className,
  feature,
  textFormatObject,
  removed,
  notFeature,
  plan,
  tier,
}) => {
  const { calcFunction, text, isHeader, tooltip = '', asWellAs } = feature;
  const styles = useStyles();
  const hideIcon = Boolean(isHeader) || asWellAs;
  let finalText = text;
  if (calcFunction && typeof calcFunction === 'function') {
    finalText = calcFunction(plan, tier) + ' ' + text;
  }
  const textFormatted = formatByObject(finalText, textFormatObject);
  const displayTimes = Boolean(notFeature);

  return (
    <Tooltip
      classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
      title={tooltip}
      interactive={!tooltip}
      arrow
    >
      <div
        className={cx(styles.root, className, {
          [styles.extraMarginTop]: asWellAs,
          [styles.dashed]: !!tooltip,
        })}
      >
        {!hideIcon &&
          (removed ? (
            <IconClose
              className={styles.iconCross}
              color="#DA5667"
            />
          ) : displayTimes ? (
            <IconTimes className={styles.iconTimes} />
          ) : (
            <CheckmarkIcon className={styles.iconCheck} />
          ))}

        <Typography
          className={cx(styles.text, { [styles.header]: isHeader })}
          dangerouslySetInnerHTML={{
            __html: textFormatted,
          }}
        />
      </div>
    </Tooltip>
  );
};

PlanFeature.propTypes = {
  className: PropTypes.string,
  feature: PropTypes.shape({
    text: PropTypes.string.isRequired,
    isHeader: PropTypes.bool,
    tooltip: PropTypes.string,
    asWellAs: PropTypes.bool,
    calcFunction: PropTypes.func,
  }).isRequired,
  textFormatObject: PropTypes.shape(),
  removed: PropTypes.bool,
  notFeature: PropTypes.bool,
  plan: PropTypes.string,
  tier: PropTypes.string,
};

PlanFeature.defaultProps = {
  className: null,
  textFormatObject: null,
  removed: false,
  notFeature: false,
};
