import gql from 'graphql-tag';
import { uniqueId } from 'lodash';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_DETECTED_BRAND_TERM } from './schema';

const MUTATION = gql`
  mutation DetectBrandTerms($brandVoiceIds: [ID!]!, $texts: [String!]!) {
    detectBrandTerms(brandVoiceIds: $brandVoiceIds, texts: $texts) {
      ...DetectedBrandTerm
    }
  }
  ${FRAGMENT_DETECTED_BRAND_TERM}
`;

export const detectBrandTerms = async ({ brandVoiceIds, text }, options) => {
  const variables = { brandVoiceIds, texts: [text] };

  const result = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  return prepareDetectedTerms(getSingleProp(result.data)?.[0]);
};

const prepareDetectedTerms = (detectedTerms) => {
  if (!detectedTerms) {
    return [];
  }

  return detectedTerms.map((item) => ({
    ...item,
    // BE is not providing any ID, so we use a generated one - to match between detected terms on Insights panel and decorations in editors
    id: item.id || uniqueId('detected_term_'),
  }));
};
