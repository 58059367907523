import { useCallback } from 'react';

import { alignChannelWithBackend, getChannelPerformanceLabel } from 'src/data/channel';
import { queryIntegrationAuthorizationRequestInfo } from 'src/graphql/performance';
import { ChannelAuthError } from 'src/lib/errors';
import { usePerformanceActions } from 'src/store';
import { authorizeIntegration } from 'src/utils/lib/oauth';

export const useAuthorizeIntegration = () => {
  const performanceActions = usePerformanceActions();

  return useCallback(
    async (channel) => {
      try {
        const result = await queryIntegrationAuthorizationRequestInfo(
          alignChannelWithBackend(channel)
        );

        let authData;

        if (result.type === 'link') {
          authData = await authorizeIntegration(result.url, channel);
        }

        if (authData) {
          return performanceActions.integrationAuthorize(channel, {
            authorizationCode: authData.code,
          });
        } else {
          throw new ChannelAuthError(
            `Error getting ${getChannelPerformanceLabel(channel)} authorization code`
          );
        }
      } catch (error) {
        throw new ChannelAuthError(
          `Error getting ${getChannelPerformanceLabel(channel)} authorization code`
        );
      }
    },
    [performanceActions]
  );
};
