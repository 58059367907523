import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    icon: {
      fontSize: theme.typography.pxToRem(12),
    },
    toolbarButton: {},
  }),
  { name: 'EditorActionButton' }
);
