import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const Loader = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {Array(7)
        .fill()
        .map((_, i) => (
          <Skeleton
            key={`AgeScoreLoaderSkeletonBar-${i}`}
            className={styles.bar}
            height={Math.random() * 50 + 50 + '%'}
          />
        ))}
    </div>
  );
};

Loader.propTypes = {
  classes: PropTypes.shape(),
};

Loader.defaultProps = {
  classes: null,
};
