import { createHook } from 'react-sweet-state';

import { NotificationStore } from 'src/store';

const useSelectorState = createHook(NotificationStore, {
  selector: (state, sectionId) => state.notifications?.length > 0,
});

export const useHaveNotificationsVisible = () => {
  const [notificationsPresent] = useSelectorState();
  return notificationsPresent;
};
