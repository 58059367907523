import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateCustomerName($id: ID!, $name: String) {
    updateCustomer(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const updateCustomerName = (id, name) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { id, name },
  }).then(({ data }) => getSingleProp(data));
};
