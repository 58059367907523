import { makeStyles } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    submenuButtonWrapper: {
      position: 'relative',

      '&:hover': {
        '& $createButton': {
          visibility: 'visible',
        },
      },
    },
    submenuButton: {},
    iconExpand: {
      color: COLORS.neutral.mediumGray,
      fontSize: theme.typography.pxToRem(16),
    },
    collapseButton: {
      paddingLeft: theme.spacing(3),
      '&:hover': {
        '& $collapseButtonActionMenu': {
          display: 'block',
        },
        '& $collapseItemText': {
          maxWidth: 160,
        },
      },
    },
    collapseInnerItem: {
      paddingLeft: theme.spacing(3.5),
    },
    collapseWrapper: {},
    innerCollapse: {},
    innerCollapseContent: {
      position: 'relative',
      padding: theme.spacing(0.5, 0),
    },

    verticalLine: {
      top: 0,
      bottom: 0,
      borderLeft: `2px solid ${COLORS.neutral.darkGray}`,
      position: 'absolute',
      marginLeft: theme.spacing(4),
    },
    items: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column nowrap',
      padding: theme.spacing(0.75, 0),
    },
    item: {
      '& + &': {
        marginTop: theme.spacing(0.75),
      },
    },
    createButton: {
      position: 'absolute',
      top: 11,
      right: 28,
      cursor: 'pointer',
      padding: 0,
      borderRadius: 2,
      marginRight: theme.spacing(0.25),
      minWidth: 'unset',
      height: theme.typography.pxToRem(16),
      width: theme.typography.pxToRem(16),
      color: COLORS.neutral.mediumLightGray,
      visibility: 'hidden',

      '&:hover': {
        backgroundColor: COLORS.neutral.mediumGray,
      },
    },
    createIcon: {
      fontSize: theme.typography.pxToRem(14),
    },
    innerCollapseStartIcon: {
      marginRight: theme.spacing(0.5),
    },
    innerCollapseEndIcon: {
      '& > svg': {
        fontSize: '18px !important',
      },
      height: theme.typography.pxToRem(16),
    },
    expandCollapseIcon: {
      '&&': {
        fontSize: theme.typography.pxToRem(18),
      },
    },
    collapseButtonContent: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: 2,
      width: '100%',
      alignItems: 'center',
    },
    collapseButtonActionMenu: {
      display: 'none',
    },
    collapseItemText: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: 180,
    },
    iconContextMenu: {
      fontSize: theme.typography.pxToRem(16) + '!important',
    },
  }),
  { name: 'SidebarSubmenu' }
);
