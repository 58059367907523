export const DEVICE_TYPE = {
  mobile: 'mobile',
  desktop: 'desktop',
};

export const isMobileDevice = (userAgent = navigator.userAgent) => /Mobile/i.test(userAgent);

export const getDeviceType = () => (isMobileDevice() ? DEVICE_TYPE.mobile : DEVICE_TYPE.desktop);

//note: navigator.platform may be the least-bad option even though it's deprecated
export const IS_MAC_OS = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
