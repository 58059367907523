import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_SUGGEST_REQUEST } from './schema';

const MUTATION = gql`
  mutation SuggestVariationsForLpco(
    $projectId: ID!
    $generationTool: GenerationToolEnum!
    $brief: BriefInput!
    $scoringMethod: ScoringMethodInput
    $suggestionMode: String!
    $keywords: String
    $language: String!
    $languageTargetFormality: LanguageTargetFormalityEnum
    $numberOfVariations: Int!
    $tovId: ID
    $suggestionAnnotationId: ID
    $enterpriseModelId: ID
    $debugAcPrompt: String
    $landingPageAssetId: ID
    $maxChars: Int
  ) {
    suggestVariationsForLpco(
      projectId: $projectId
      generationTool: $generationTool
      brief: $brief
      scoringMethod: $scoringMethod
      suggestionMode: $suggestionMode
      keywords: $keywords
      language: $language
      languageTargetFormality: $languageTargetFormality
      numberOfVariations: $numberOfVariations
      tovId: $tovId
      suggestionAnnotationId: $suggestionAnnotationId
      enterpriseModelId: $enterpriseModelId
      debugAcPrompt: $debugAcPrompt
      landingPageAssetId: $landingPageAssetId
      maxChars: $maxChars
    ) {
      ...SuggestionRequest
    }
  }
  ${FRAGMENT_SUGGEST_REQUEST}
`;

export const suggestVariationsForLpco = async ({
  projectId,
  generationTool,
  brief,
  scoringMethod,
  suggestionMode,
  keywords,
  language,
  numberOfVariations,
  tovId,
  suggestionAnnotationId,
  enterpriseModelId,
  debugAcPrompt,
  landingPageAssetId,
  languageTargetFormality,
  maxChars,
}) => {
  const variables = {
    projectId,
    generationTool,
    brief,
    scoringMethod,
    suggestionMode,
    keywords,
    language,
    numberOfVariations,
    tovId,
    suggestionAnnotationId,
    enterpriseModelId,
    debugAcPrompt,
    landingPageAssetId,
    languageTargetFormality,
    maxChars,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
