import { useCallback } from 'react';

import { usePopups } from '../services';
import { INVITE_ACCEPT_POPUP } from '../types';
import { useCurrentUser, useRefreshCurrentUser } from 'src/store';
import { useInviteToken, useInviteHandlers } from 'src/services/index';
import { useAccountsOnboarding } from 'src/services/useAccountsOnboarding';
import * as ls from 'src/lib/localData';

export const useInviteAcceptPopup = () => {
  const popups = usePopups();
  const currentUser = useCurrentUser();
  const { token, removeTokenFromUrl } = useInviteToken();
  const { handleTokenValidation, handleInvitationAccept, handleInvitationDecline } =
    useInviteHandlers(token);
  const { refreshCurrentUser } = useRefreshCurrentUser();
  const { startAccountOnboarding } = useAccountsOnboarding();

  const showInviteAcceptModalIfNeeded = useCallback(async () => {
    if (!token || !currentUser) return;

    const { email, errorType, invitedBy } = await handleTokenValidation();

    if (errorType || email !== currentUser.email) {
      removeTokenFromUrl();
      return;
    }

    const closePopup = () => {
      popups.closePopup(INVITE_ACCEPT_POPUP);
      removeTokenFromUrl();
    };

    popups.openPopup(INVITE_ACCEPT_POPUP, {
      onAccept: async () => {
        const { customerId } = await handleInvitationAccept();

        if (customerId) {
          // set new customerId, so it will used for selecting new Customer in CustomerStore.init() function
          // CustomerStore.init() function is called after user refreshing
          ls.currentCustomerId.write(customerId);

          const refreshedUser = await refreshCurrentUser();

          if (!refreshedUser) return;

          startAccountOnboarding();
        }

        closePopup();
      },
      onDecline: () => {
        handleInvitationDecline();
        closePopup();
      },
      invitedBy,
    });
  }, [
    currentUser,
    popups,
    token,
    removeTokenFromUrl,
    handleInvitationAccept,
    handleInvitationDecline,
    handleTokenValidation,
    startAccountOnboarding,
    refreshCurrentUser,
  ]);

  return showInviteAcceptModalIfNeeded;
};
