export const USER_INVITE_ERROR_CODES = {
  not_found: 'not_found',
  invite_already_accepted: 'invite_already_accepted',
  invite_discarded: 'invite_discarded',
  all_seats_taken: 'all_seats_taken',
};

export const USER_AUTHENTICATE_ERROR_CODES = {
  user_has_no_customer: 'user_has_no_customer',
};

export const UPDATE_CUSTOMER_ERROR_CODES = {
  customer_name_taken: 'customer_name_taken',
};

export const GRAPHQL_ERROR_CODES = {
  ...USER_INVITE_ERROR_CODES,
  ...USER_AUTHENTICATE_ERROR_CODES,
  ...UPDATE_CUSTOMER_ERROR_CODES,

  // custom error codes
  undefined: 'undefined',
  networkError: 'networkError',
};
