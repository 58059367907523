import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_FREESTYLE_TOOL } from './schema';

const QUERY = gql`
  query QueryCustomerSuggestionFreestyleTools($customerId: ID!, $workspaceId: ID) {
    customerSuggestionFreestyleTools(customerId: $customerId, workspaceId: $workspaceId) {
      ...SuggestionFreestyleTool
    }
  }
  ${FRAGMENT_FREESTYLE_TOOL}
`;

export const querySuggestionFreestyleTools = ({ customerId, workspaceId }) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      customerId,
      workspaceId,
    },
  }).then(({ data }) => {
    const result = getSingleProp(data).map((freestyleTool) => {
      freestyleTool.allInstructionsData = JSON.parse(freestyleTool.allInstructionsData || null);
      return freestyleTool;
    });
    return result;
  });
