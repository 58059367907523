import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 'none',
    },
    buttonClose: {
      zIndex: 10,
    },
  }),
  { name: 'CustomNotificationPopup' }
);
