import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

const SIZE = 20;

export const useStyles = makeStyles(
  () => ({
    root: {
      width: SIZE,
      height: SIZE,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      textTransform: 'capitalize',
      color: COLORS.gray.black,
      backgroundColor: COLORS.primary.light,
    },
  }),
  { name: 'WorkspaceNameIcon' }
);
