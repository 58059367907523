import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query CustomerResourcesExist(
    $customerId: ID!
    $workspaceId: ID
    $inputs: [ResourceDetailsInput!]!
  ) {
    customerResourcesExist(customerId: $customerId, workspaceId: $workspaceId, inputs: $inputs) {
      content
      exist
      type
      id
    }
  }
`;

export const checkCustomerResourcesExistence = ({ customerId, workspaceId, inputs }) => {
  return executeGqlQuery({
    query: QUERY,
    variables: { customerId, workspaceId, inputs },
  }).then(({ data }) => getSingleProp(data));
};
