/**
 * Send message to parent window, possibly container of an iframe that is currently wrapping our Application
 *
 * @param message
 * @param targetOrigin
 */
export const sendMessageToParent = (message, targetOrigin = '*') => {
  if (!window.parent) {
    return;
  }

  window.parent.postMessage(message, targetOrigin);
};

/**
 * Subscribe for browser messages from other windows
 *
 * @param listener
 * @returns {(function(): void)|*} Unsubscribe callback
 */
export const subscribeToMessages = (listener) => {
  window.addEventListener('message', listener);
  return () => {
    window.removeEventListener('message', listener);
  };
};
