import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      columnGap: theme.spacing(1),
    },
    light: {},
    inviteButton: {
      '$light &': {
        backgroundColor: 'transparent',
        borderColor: COLORS.primary.light,
        borderWidth: 1,
        color: COLORS.primary.light,

        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: COLORS.primary.light,
          borderWidth: 1,
          color: COLORS.primary.light,
        },
      },
    },
  }),
  { name: 'TopNavigationBarItems' }
);
