import { useCallback } from 'react';

import { useUserActions } from './useUserActions';
import { captureMessage } from 'src/lib/sentry';

export const useRefreshCurrentUser = () => {
  const userActions = useUserActions();

  const refreshCurrentUser = useCallback(async () => {
    try {
      const user = await userActions.refreshCurrentUser();

      return user;
    } catch (error) {
      captureMessage("Couldn't refresh user", {
        level: 'warning',
        extra: {
          error: error.toString(),
          source: 'useRefreshCurrentUser',
        },
      });
    }

    return false;
  }, [userActions]);

  return {
    refreshCurrentUser,
  };
};
