import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from '@material-ui/core';

import { AVATAR_TYPE } from 'src/data/persona';
import { IconEmptyAvatar } from './components/EmptyAvatar';
import { PersonaAvatarOne } from './components/PersonaAvatarOne';
import { PersonaAvatarTwo } from './components/PersonaAvatarTwo';
import { PersonaAvatarThree } from './components/PersonaAvatarThree';
import { PersonaAvatarFour } from './components/PersonaAvatarFour';
import { PersonaAvatarFive } from './components/PersonaAvatarFive';
import { PersonaAvatarSix } from './components/PersonaAvatarSix';
import { PersonaAvatarSeven } from './components/PersonaAvatarSeven';
import { PersonaAvatarEight } from './components/PersonaAvatarEight';
import { PersonaAvatarNine } from './components/PersonaAvatarNine';
import { PersonaAvatarTen } from './components/PersonaAvatarTen';
import { useStyles } from './useStyles';

export const getAvatarComponent = (avatarType) => {
  switch (avatarType) {
    case AVATAR_TYPE.avatarOne:
      return PersonaAvatarOne;
    case AVATAR_TYPE.avatarTwo:
      return PersonaAvatarTwo;
    case AVATAR_TYPE.avatarThree:
      return PersonaAvatarThree;
    case AVATAR_TYPE.avatarFour:
      return PersonaAvatarFour;
    case AVATAR_TYPE.avatarFive:
      return PersonaAvatarFive;
    case AVATAR_TYPE.avatarSix:
      return PersonaAvatarSix;
    case AVATAR_TYPE.avatarSeven:
      return PersonaAvatarSeven;
    case AVATAR_TYPE.avatarEight:
      return PersonaAvatarEight;
    case AVATAR_TYPE.avatarNine:
      return PersonaAvatarNine;
    case AVATAR_TYPE.avatarTen:
      return PersonaAvatarTen;
    default:
      return IconEmptyAvatar;
  }
};

export const PersonaAvatar = ({ className, avatarType, opaqueBackground, hasBadge }) => {
  const Component = getAvatarComponent(avatarType);
  const styles = useStyles();

  return (
    <Badge
      variant="dot"
      overlap="circular"
      invisible={!hasBadge}
      color="primary"
      classes={{
        dot: styles.dot,
      }}
    >
      <Component
        className={className}
        opaque={opaqueBackground}
      />
    </Badge>
  );
};

PersonaAvatar.propTypes = {
  className: PropTypes.string,
  avatarType: PropTypes.oneOf(Object.values(AVATAR_TYPE)),
  opaqueBackground: PropTypes.bool,
  hasBadge: PropTypes.bool,
};

PersonaAvatar.defaultProps = {
  className: null,
  avatarType: null,
  opaqueBackground: false,
  hasBadge: false,
};
