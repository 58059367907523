import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomButton, CUSTOM_BUTTON_VARIANT } from 'src/components/CustomButton';
import { useStyles } from './useStyles';

export const ButtonText = forwardRef(({ className, classes, children, ...props }, ref) => {
  const styles = useStyles({ classes });

  return (
    <CustomButton
      className={cx(styles.root, className)}
      variant={CUSTOM_BUTTON_VARIANT.text}
      ref={ref}
      {...props}
    >
      {children}
    </CustomButton>
  );
});

ButtonText.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  children: PropTypes.node,
};
ButtonText.defaultProps = {
  className: '',
  classes: null,
  children: null,
};
