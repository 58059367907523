import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const InfoIcon = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 46 46">
    <circle cx="23" cy="23" r="9" fill="white" />
    <circle cx="23" cy="23" r="23" fill="#84B8FF" />
    <circle cx="23" cy="23" r="16" fill="#AFD1FE" />
    <circle cx="23" cy="23" r="9" fill="white" />
    <path
      d="M23.6182 28H21.957V20.6035H23.6182V28ZM21.8545 18.6826C21.8545 18.4274 21.9342 18.2155 22.0938 18.0469C22.2578 17.8783 22.4902 17.7939 22.791 17.7939C23.0918 17.7939 23.3242 17.8783 23.4883 18.0469C23.6523 18.2155 23.7344 18.4274 23.7344 18.6826C23.7344 18.9333 23.6523 19.1429 23.4883 19.3115C23.3242 19.4756 23.0918 19.5576 22.791 19.5576C22.4902 19.5576 22.2578 19.4756 22.0938 19.3115C21.9342 19.1429 21.8545 18.9333 21.8545 18.6826Z"
      fill="#458DED"
    />
  </SvgIcon>
));
