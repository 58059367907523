import { useMemo } from 'react';

import { useCurrentCustomer } from 'src/store';
import { getCustomerPlan, isSelfServeBusinessPlan } from 'src/data/subscription';
import { ROUTES } from 'src/routes';
import { useBookDemoUrl } from 'src/services/useBookDemoUrl';

export const useGetUpgradeRowsLimitButtonAttributes = (upgradeUrl = '') => {
  const currentCustomer = useCurrentCustomer();
  const bookDemoURL = useBookDemoUrl();

  return useMemo(() => {
    const subscriptionPlan = getCustomerPlan(currentCustomer);
    const isBusinessPlan = isSelfServeBusinessPlan(subscriptionPlan);
    const upgradeRoute = isBusinessPlan && bookDemoURL ? bookDemoURL : upgradeUrl || ROUTES.UPGRADE;
    const upgradeButtonText = isBusinessPlan ? 'Contact sales' : 'Upgrade to Business';
    const shortUpgradeButtonText = isBusinessPlan ? 'Contact sales' : 'Upgrade';
    return {
      upgradeRoute,
      upgradeButtonText,
      shortUpgradeButtonText,
      isBusinessPlan,
    };
  }, [currentCustomer, bookDemoURL, upgradeUrl]);
};
