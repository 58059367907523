import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconBlockQuote = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.965 1.96484C4.32691 1.98296 3.69867 2.12673 3.11621 2.38792C2.53375 2.64912 2.00852 3.02261 1.57058 3.48703C1.13263 3.95145 0.790571 4.49767 0.56397 5.09443C0.337368 5.6912 0.230675 6.3268 0.249997 6.96484V7.58484C0.249997 8.07336 0.39486 8.55091 0.666267 8.9571C0.937674 9.36329 1.32344 9.67988 1.77477 9.86683C2.2261 10.0538 2.72274 10.1027 3.20187 10.0074C3.681 9.91208 4.12112 9.67683 4.46655 9.3314C4.81199 8.98596 5.04723 8.54585 5.14254 8.06672C5.23784 7.58758 5.18893 7.09095 5.00198 6.63962C4.81503 6.18828 4.49844 5.80252 4.09226 5.53111C3.68607 5.25971 3.20852 5.11484 2.72 5.11484C2.53281 5.115 2.34636 5.13851 2.165 5.18484C2.14133 5.19158 2.11616 5.19087 2.09291 5.18282C2.06965 5.17477 2.04944 5.15977 2.035 5.13984C2.02164 5.11894 2.01454 5.09465 2.01454 5.06984C2.01454 5.04504 2.02164 5.02075 2.035 4.99984C2.32248 4.47027 2.74496 4.02619 3.25956 3.71268C3.77416 3.39918 4.36255 3.22742 4.965 3.21484C5.13076 3.21484 5.28973 3.149 5.40694 3.03179C5.52415 2.91458 5.59 2.7556 5.59 2.58984C5.59 2.42408 5.52415 2.26511 5.40694 2.1479C5.28973 2.03069 5.13076 1.96484 4.965 1.96484Z"
        fill="#626266"
      />
      <path
        d="M11.125 3.21484C11.2908 3.21484 11.4497 3.149 11.5669 3.03179C11.6842 2.91458 11.75 2.7556 11.75 2.58984C11.75 2.42408 11.6842 2.26511 11.5669 2.1479C11.4497 2.03069 11.2908 1.96484 11.125 1.96484C10.4869 1.98296 9.85867 2.12673 9.27621 2.38792C8.69376 2.64912 8.16853 3.02261 7.73058 3.48703C7.29264 3.95145 6.95058 4.49767 6.72397 5.09443C6.49737 5.6912 6.39068 6.3268 6.41 6.96484V7.58484C6.40901 8.07478 6.55345 8.55398 6.82503 8.96176C7.09661 9.36954 7.4831 9.68754 7.93555 9.87548C8.388 10.0634 8.88605 10.1129 9.36662 10.0175C9.84719 9.92219 10.2886 9.68636 10.6351 9.33993C10.9815 8.99349 11.2173 8.55203 11.3127 8.07146C11.408 7.59089 11.3586 7.09284 11.1706 6.64039C10.9827 6.18794 10.6647 5.80145 10.2569 5.52987C9.84914 5.2583 9.36993 5.11385 8.88 5.11484C8.69283 5.11531 8.50643 5.13882 8.325 5.18484C8.30133 5.19158 8.27617 5.19087 8.25291 5.18282C8.22966 5.17477 8.20944 5.15977 8.195 5.13984C8.18164 5.11894 8.17455 5.09465 8.17455 5.06984C8.17455 5.04504 8.18164 5.02075 8.195 4.99984C8.48248 4.47027 8.90497 4.02619 9.41957 3.71268C9.93417 3.39918 10.5226 3.22742 11.125 3.21484Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconBlockQuote.displayName = 'IconBlockQuote';
