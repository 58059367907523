import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},

    divider: {
      color: COLORS.gray.gray70,
      background: COLORS.gray.gray70,
      margin: theme.spacing(0, -3),
    },

    stonlyHintIcon: {
      '&&': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    border: {
      border: '1px solid #E9E9E9',
    },
  }),
  { name: 'ReadabilityScore' }
);
