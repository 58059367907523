import { createContainer } from 'react-sweet-state';

import { ACTIONS, MultiPromptTemplatesStore } from './MultiPromptTemplatesStore';

export const MultiPromptTemplatesContainer = createContainer(MultiPromptTemplatesStore, {
  onInit:
    () =>
    ({ setState, dispatch }, { state, currentCustomerId, currentWorkspaceId }) => {
      dispatch(ACTIONS.init(currentCustomerId, currentWorkspaceId));
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState, dispatch }, { state, currentCustomerId, currentWorkspaceId }) => {
      dispatch(ACTIONS.update(currentCustomerId, currentWorkspaceId));
      if (state) {
        setState(state);
      }
    },
});
