import { Editor, Element as SlateElement, Node, Range, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import {
  editorIsFocusedAndHasValidSelection,
  getSlateSelectionFragment,
  SLATE_EDITOR_ACTION,
  SLATE_NODE_TYPES,
  SLATE_TEXT_FORMATS,
  slateEditorHasContent,
} from 'src/lib/slate';
import { TEXT_ALIGN_TYPES } from './const';
import {
  getEditorActionNodeType,
  getEditorActionTextFormat,
  htmlToSlate,
  slateToHtml,
  slateToText,
} from './lib';
import { RESOURCE_CONTENT_TYPE } from 'src/data/performance';

const MAX_FILE_NAME_LENGTH = 15;

export const PROMPT_NODE_ID = {
  image: 'promptImage',
  document: 'promptDocument',
};

export const applyPlugins = (editor, plugins) => {
  return plugins.reduce((e, p) => p(e), editor);
};

export const isEditorActionActive = (editor, action) => {
  const format = getEditorActionTextFormat(action);
  const nodeType = getEditorActionNodeType(action);
  switch (nodeType) {
    case SLATE_NODE_TYPES.BLOCK:
      return isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
    case SLATE_NODE_TYPES.MARK:
      return isMarkActive(editor, format);
    case SLATE_NODE_TYPES.INLINE:
      return isLinkActive(editor);
    default:
      return false;
  }
};

export const isEditorActionDisabled = (editor, action) => {
  if (action === SLATE_EDITOR_ACTION.insertLink) {
    return (
      !editorIsFocusedAndHasValidSelection(editor) ||
      Editor.string(editor, editor.selection).trim().length === 0
    );
  }
  return false;
};

export const getCurrentEditorTextFormat = (editor) => {
  const { selection } = editor;
  if (!selection) {
    return null;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) => !Editor.isEditor(node) && SlateElement.isElement(node),
    })
  );

  return match?.[0]?.type;
};

export const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) =>
        !Editor.isEditor(node) && SlateElement.isElement(node) && node[blockType] === format,
    })
  );

  return !!match;
};

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) &&
      SlateElement.isElement(node) &&
      node.type === SLATE_TEXT_FORMATS.LINK,
  });
  return !!link;
};

export const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) &&
      SlateElement.isElement(node) &&
      node.type === SLATE_TEXT_FORMATS.LINK,
  });
};

export const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: SLATE_TEXT_FORMATS.LINK,
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

export const insertBankMessage = (editor, bankMessage, isTextSelected = false) => {
  removeTagInLine(editor);

  const newNode = {
    ...bankMessage,
    type: SLATE_TEXT_FORMATS.BANK_MESSAGE,
    bankMessageId: bankMessage.id,
    bankMessageBody: bankMessage.body,
    children: [
      {
        type: SLATE_TEXT_FORMATS.BANK_MESSAGE,
        bankMessageId: bankMessage.id,
        text: bankMessage.name,
      },
    ],
  };
  insertPromptTag(
    editor,
    newNode,
    !(isTextSelected && getPromptAncestorNode(editor)?.path),
    isTextSelected && getPromptAncestorNode(editor)?.path
      ? null
      : `refer to this ${bankMessage.subtitle.toLowerCase().trim()}:`
  );
};

export const insertProductFromUrl = (editor, title, article) => {
  const newNode = {
    type: SLATE_TEXT_FORMATS.PRODUCT_DESCRIPTION,
    title: title,
    article: article,
    children: [
      {
        type: SLATE_TEXT_FORMATS.PRODUCT_DESCRIPTION,
        title: title,
        article: article,
        text: title,
      },
    ],
  };

  insertPromptTag(editor, newNode);
};

export const insertResourceUrl = (editor, url, isTextSelected = false) => {
  removeTagInLine(editor);

  const newNode = {
    type: SLATE_TEXT_FORMATS.RESOURCE_URL,
    resourceUrl: url,
    children: [
      {
        type: SLATE_TEXT_FORMATS.RESOURCE_URL,
        text: url,
      },
    ],
  };

  insertPromptTag(
    editor,
    newNode,
    !(isTextSelected && getPromptAncestorNode(editor)?.path),
    isTextSelected && getPromptAncestorNode(editor)?.path ? null : 'refer to this link: '
  );
};

const selectLine = (editor) => {
  Transforms.move(editor, {
    distance: 1,
    unit: 'line',
    reverse: true,
  });

  const startSelection = JSON.parse(JSON.stringify(editor.selection));

  Transforms.move(editor, {
    distance: 1,
    unit: 'line',
    reverse: false,
  });

  const endSelection = JSON.parse(JSON.stringify(editor.selection));

  Transforms.setSelection(editor, { anchor: startSelection.anchor, focus: endSelection.focus });
};

export const removeNodeFromPromptById = (editor, id, label, focus = true) => {
  const nodeInnerText = getTagInnerText(label);
  const promptNodes = document.querySelectorAll(`#${id}`);
  const promptNode = Array.from(promptNodes).find((node) => node.innerText === nodeInnerText);
  if (promptNode) {
    try {
      const range = document.createRange();
      range.selectNodeContents(promptNode);

      const slateRange = ReactEditor.toSlateRange(editor, range, {
        exactMatch: false,
      });

      Transforms.select(editor, slateRange);

      const ancestorNode = getPromptAncestorNode(editor);
      if (ancestorNode?.path) {
        Transforms.removeNodes(editor, { at: ancestorNode.path });
      }

      if (focus) {
        focusEditor({ editor });
      }
    } catch {
      return null;
    }
  }
  return promptNode;
};

const removeTagInLine = (editor) => {
  const currentSelection = JSON.parse(JSON.stringify(editor.selection));

  if (currentSelection?.anchor || currentSelection?.focus) {
    // set selection to line, to check if there is a prompt tag in the line
    selectLine(editor);

    const childNode = getPromptChildNode(editor);

    if (childNode?.path) {
      Transforms.select(editor, {
        anchor: { path: childNode.path, offset: 0 },
        focus: { path: childNode.path, offset: 0 },
      });

      Transforms.removeNodes(editor, { at: childNode?.path });
    } else {
      // if no prompt tag is found, set the selection back to the original selection
      Transforms.setSelection(editor, currentSelection);
    }
  }
};

export const getTagInnerText = (fileText) => {
  return fileText?.length > MAX_FILE_NAME_LENGTH
    ? `${fileText?.substring(0, MAX_FILE_NAME_LENGTH)}\u2026`
    : fileText;
};

export const insertFileTextToPrompt = (editor, isHtmlEmpty, file, type, id, focus = true) => {
  const isDocument = type === RESOURCE_CONTENT_TYPE.document;

  const fragment = editor.getFragment();
  const slateText = slateToText(fragment);
  const ancestorNode = getPromptAncestorNode(editor);
  if (ancestorNode?.path) {
    Transforms.removeNodes(editor, { at: ancestorNode.path });
  }
  const referText = isDocument ? 'refer to this PDF: ' : 'refer to this image: ';

  if (focus) {
    focusEditor({ editor });
  }

  const fileTextNode = {
    type: SLATE_TEXT_FORMATS.PARAGRAPH,
    children: [
      {
        type: SLATE_TEXT_FORMATS.PARAGRAPH,
        text: isHtmlEmpty || !slateText || ancestorNode?.path ? referText : '\n' + referText,
      },
    ],
  };
  Transforms.insertFragment(editor, [fileTextNode]);
  if (focus) {
    focusEditor({ editor });
  }
  const fileText = file?.label || file?.name;
  const text = getTagInnerText(fileText);

  const nodeType = isDocument
    ? SLATE_TEXT_FORMATS.PROMPT_DOCUMENT
    : SLATE_TEXT_FORMATS.PROMPT_IMAGE;

  const newNode = {
    type: nodeType,
    children: [
      {
        type: nodeType,
        text: text,
        id: file.id,
      },
    ],
  };
  if (focus) {
    focusEditor({ editor });
  }
  // need to wait for the editor to update before inserting the new node
  setTimeout(() => {
    insertPromptTag(editor, newNode, false, null, focus);
  }, 0);
};

const insertPromptTag = (editor, promptTagNode, addNewLine, tagName, focus = true) => {
  const ancestorNode = getPromptAncestorNode(editor);
  if (ancestorNode?.path) {
    Transforms.removeNodes(editor, { at: ancestorNode.path });
  }

  if (tagName) {
    const tagNode = {
      type: SLATE_TEXT_FORMATS.PARAGRAPH,
      children: [
        {
          type: SLATE_TEXT_FORMATS.PARAGRAPH,
          text: tagName + ' ',
        },
      ],
    };
    Transforms.insertFragment(editor, [tagNode]);
  }

  Transforms.insertNodes(editor, promptTagNode);

  // insert separation between nodes/text
  Transforms.insertFragment(editor, [
    { type: SLATE_NODE_TYPES.INLINE, children: [{ text: addNewLine ? ' \n' : ' ' }] },
  ]);

  setTimeout(() => {
    if (focus) {
      focusEditor({ editor });
    }
  }, 0);
};

const getPromptChildNode = (editor, type) => {
  return findChildByType(editor, type ? type : SLATE_TEXT_FORMATS.PROMPT_TAG);
};

export const getPromptAncestorNode = (editor) => {
  // If ancestor is a prompt tag, bank message, or product description it should be removed.
  let ancestorNode;

  for (const nodeFormat of [
    SLATE_TEXT_FORMATS.PROMPT_TAG,
    SLATE_TEXT_FORMATS.BANK_MESSAGE,
    SLATE_TEXT_FORMATS.PRODUCT_DESCRIPTION,
    SLATE_TEXT_FORMATS.RESOURCE_URL,
    SLATE_TEXT_FORMATS.PROMPT_IMAGE,
    SLATE_TEXT_FORMATS.PROMPT_DOCUMENT,
  ]) {
    ancestorNode = findAncestorByType(editor, nodeFormat);
    if (ancestorNode?.node !== null) {
      break;
    }
  }

  return ancestorNode;
};

export const insertLink = (editor, url, options) => {
  if (editor.selection) {
    wrapLink(editor, url);

    if (options?.withSpacePostfix) {
      Transforms.insertFragment(editor, [
        { type: SLATE_NODE_TYPES.INLINE, children: [{ text: ' ' }] },
      ]);
    }
  }
};

export const focusEditor = ({ editor, atEnd = false, ignoreSelection = false }) => {
  const shouldApplySelection = ignoreSelection || !editor?.selection;
  if (slateEditorHasContent(editor) && shouldApplySelection) {
    if (atEnd) {
      Transforms.select(editor, Editor.end(editor, []));
    } else {
      Transforms.select(editor, Editor.start(editor, []));
    }
  }

  ReactEditor.focus(editor);
};

export const insertNodesToEditorEnd = (editor, nodes) => {
  Transforms.insertNodes(editor, nodes, {
    at: [editor.children.length - 1],
  });
};

export const insertTextAtEditorSelection = (editor, text) => {
  const newNode = {
    type: SLATE_TEXT_FORMATS.PARAGRAPH,
    text: text,
  };

  insertNodesToEditorAtSelection(editor, [newNode]);
};

export const insertNodesToEditorAtSelection = (editor, nodes) => {
  const selectedNodes = getSlateSelectionFragment(editor);

  if (selectedNodes?.[0]?.type === SLATE_TEXT_FORMATS.DD_VARIATION) {
    // if paste target is a DD variation, paste is as a plain text
    const text = slateToText(nodes);
    editor.insertText(text);
    return;
  }

  if (!nodes.some((node) => node.type === SLATE_TEXT_FORMATS.DD_VARIATION)) {
    // if pasted nodes doesn't have a DD variation, just insert them in the selected cursor
    Transforms.insertNodes(editor, nodes);
    return;
  }

  // we should calculate the score again for a variation node that got pasted
  // cause even if its a part of a variation, the score of the original variation got pasted as well
  const newNodes = nodes.map((node) =>
    node.type === SLATE_TEXT_FORMATS.DD_VARIATION
      ? { ...node, variation: { ...node.variation, shouldBeScored: true } }
      : node
  );

  const path = editor.selection && editor.selection.anchor.path.slice(0, -1);
  Transforms.insertNodes(editor, newNodes, { at: path });
};

export const hasSlateContentChangeOperation = (operations) =>
  operations.some((op) => 'set_selection' !== op.type);

const cleanupHtmlContent = (htmlContent) => {
  return slateToHtml(htmlToSlate(htmlContent)).trim();
};

export const pasteFromClipboard = (editor, event) => {
  event.preventDefault();

  const htmlContent = event.clipboardData.getData('text/html');

  if (htmlContent) {
    insertNodesToEditorAtSelection(editor, htmlToSlate(cleanupHtmlContent(htmlContent)));
  } else {
    const textContent = event.clipboardData.getData('text/plain');
    editor.insertText(textContent);
  }
};

export const overrideContent = (editor, htmlString) => {
  Transforms.deselect(editor);
  // add fake history since content override adds to it
  const fakeHistory = {
    operations: [
      {
        type: 'insert_text',
        path: [0, 0],
        offset: 0,
        text: '',
        isFake: true,
      },
    ],
    selectionBefore: null,
  };
  editor.history.undos.push({ ...fakeHistory });

  // Remove existing nodes
  const children = [...editor.children];
  children.forEach((node) => editor.apply({ type: 'remove_node', path: [0], node }));

  // Add new nodes
  const nodes = htmlToSlate(htmlString);
  nodes.forEach((node, i) => editor.apply({ type: 'insert_node', path: [i], node: node }));

  // remove all isFake items from operations
  editor.history.undos.forEach((undo) => {
    undo.operations = undo.operations.filter((op) => !op.isFake);
  });
};

export const removePrefixBeforeCaret = (editor) => {
  const { selection } = editor;

  const { character, range } = getCharacterBeforeCursor(editor);
  if (selection && ['/', '@'].includes(character) && Range.isCollapsed(selection)) {
    Transforms.delete(editor, { at: range });
  }
};

export const getCharacterBeforeCursor = (editor) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const beforePoint = Editor.before(editor, selection, { unit: 'character' });

    if (beforePoint) {
      const range = { anchor: beforePoint, focus: selection.anchor };
      const [character] = Editor.string(editor, range);

      return { character, range };
    }
  }

  return { character: null, range: null };
};

export const getCharacterAfterCursor = (editor) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const afterPoint = Editor.after(editor, selection, { unit: 'character' });

    if (afterPoint) {
      const range = { anchor: afterPoint, focus: selection.anchor };
      const [character] = Editor.string(editor, range);

      return { character, range };
    }
  }

  return { character: null, range: null };
};

export const unwrapCurrentNode = (editor, node, path) => {
  if (Node.isNode(node) && !Editor.isEditor(node)) {
    Transforms.unwrapNodes(editor, { at: path });
  }
};

export const findChildByType = (editor, type) => {
  try {
    const { selection } = editor;

    if (!selection) {
      return null;
    }

    // Getting the path to the node where the cursor is
    const [node, path] = Editor.node(editor, selection);

    // If we couldn't find a node, return null
    if (!path) {
      return null;
    }

    // First, go over children
    for (const child of Node.children(editor, path, { reverse: true })) {
      const [node] = child;

      if (node.type === type) {
        return { node, path: child[1] };
      }
    }

    // No children of this type, check if current node is the type return it
    if (node.type === type) {
      return path;
    }
    return { node: null, path: null };
  } catch {
    return null;
  }
};

export const findAncestorByType = (editor, type) => {
  const { selection } = editor;

  if (!selection) {
    return null;
  }

  // Getting the path to the node where the cursor is
  const [node, path] = Editor.node(editor, selection);

  // If we couldn't find a node, return null
  if (!path) {
    return null;
  }

  // First, go over ancestors
  for (const ancestorPath of Node.ancestors(editor, path, { reverse: true })) {
    const [node] = ancestorPath;

    if (node.type === type) {
      return { node, path: ancestorPath[1] };
    }
  }

  // No ancestors of this type, check if current node is the type return it
  if (node.type === type) {
    return path;
  }

  return { node: null, path: null };
};
