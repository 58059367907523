import React, { forwardRef, memo, useMemo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import { generateSvgId } from 'src/lib/svg';

const PlatformYoutube = forwardRef((props, ref) => {
  const svgId1 = useMemo(() => generateSvgId(), []);
  const svgId2 = useMemo(() => generateSvgId(), []);

  return (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 16 16"
    >
      <defs>
        <polygon
          id={svgId2}
          points="0.00545454545 0.00363636364 16 0.00363636364 16 11.2727273 0.00545454545 11.2727273"
        />
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(0, 2)"
      >
        <mask
          id={svgId1}
          fill="white"
        >
          <use xlinkHref={`#${svgId2}`} />
        </mask>
        <path
          d="M15.6656364,1.76027273 C15.4816364,1.06736364 14.9395455,0.521727273 14.2510909,0.336545455 C13.0033636,0 8,0 8,0 C8,0 2.99663636,0 1.74890909,0.336545455 C1.06054545,0.521727273 0.518363636,1.06736364 0.334363636,1.76027273 C0,3.01609091 0,5.63636364 0,5.63636364 C0,5.63636364 0,8.25654545 0.334363636,9.51254545 C0.518363636,10.2053636 1.06054545,10.751 1.74890909,10.9362727 C2.99663636,11.2727273 8,11.2727273 8,11.2727273 C8,11.2727273 13.0033636,11.2727273 14.2510909,10.9362727 C14.9395455,10.751 15.4816364,10.2053636 15.6656364,9.51254545 C16,8.25654545 16,5.63636364 16,5.63636364 C16,5.63636364 16,3.01609091 15.6656364,1.76027273"
          id="Fill-1"
          fill="currentColor"
          mask={`url(#${svgId1})`}
        />
        <polyline
          fill="#FFFFFE"
          points="6.36363636 8.01536364 10.5454545 5.63645455 6.36363636 3.25736364 6.36363636 8.01536364"
        />
      </g>
    </SvgIcon>
  );
});

export default memo(PlatformYoutube);
