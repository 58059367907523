import { useMemo } from 'react';

import { SCORING_METHOD_TYPE } from 'src/data/scoring';
import { contentTypeFromBriefType } from 'src/data/brief';
import { useContentTaxonomies } from 'src/store/common';
import { DEFAULT_TAXONOMY_NAME } from 'src/data/common';

export const useScoringMethodName = (briefType, scoringMethod) => {
  const contentType = contentTypeFromBriefType(briefType);
  const taxonomies = useContentTaxonomies(contentType);

  return useMemo(() => {
    if (!scoringMethod) {
      return '';
    }

    switch (scoringMethod.type) {
      case SCORING_METHOD_TYPE.contentTaxonomy: {
        const taxonomy = taxonomies.find((n) => n.id === scoringMethod.id);
        return taxonomy?.name || DEFAULT_TAXONOMY_NAME;
      }

      // TODO: get "custom model" name from `scoringMethod` data or from "custom models" store
      case SCORING_METHOD_TYPE.customModel:
      case SCORING_METHOD_TYPE.autoClassifierScoringModel:
        return scoringMethod.name;

      default:
        return '<unknown scoring method type>';
    }
  }, [scoringMethod, taxonomies]);
};
