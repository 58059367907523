import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      right: 0,
      bottom: 0,
      top: 0,
      left: 0,
      zIndex: theme.zIndex.modal,
    },
    panel: {
      width: 560,
      height: 200,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: COLORS.neutral.white,
      borderRadius: theme.shape.borderRadiusLarge,
      border: theme.shape.borderGray70,
      boxShadow: theme.shape.boxShadowLarge,
    },
  }),
  { name: 'SuspenseFallbackPopup' }
);
