import { useCallback } from 'react';

import { useHistoryPushOrInfinityOpen } from 'src/services';
import { QUOTA_ENDED_POPUP } from '../types';
import { usePopups } from '../services';

const showQuotaEnded = (popups, isFullBlog) =>
  new Promise((resolve) => {
    popups.openPopup(QUOTA_ENDED_POPUP, {
      onClose: (result) => {
        popups.closePopup(QUOTA_ENDED_POPUP);
        resolve(result);
      },
      isFullBlog,
    });
  });

export const useQuotaEndedPopup = () => {
  const popups = usePopups();
  const historyPushOrInfinityOpen = useHistoryPushOrInfinityOpen();

  return useCallback(
    async ({ isFullBlog } = {}) => {
      const result = await showQuotaEnded(popups, isFullBlog);

      if (result?.changeRoute) {
        historyPushOrInfinityOpen(result?.changeRoute);
      }
    },
    [popups, historyPushOrInfinityOpen]
  );
};
