import { ONBOARDING_STEP } from 'src/data/onboarding';
import { SPOTLIGHT_TYPE } from '../components/Spotlight';
import { BoostAnalyticsOnboardingHint } from '../components/BoostAnalyticsOnboardingHint';
import { buildTarget } from './utils';

export const STEPS_CIP_DATA = [
  {
    id: ONBOARDING_STEP.cipBoost,
    target: buildTarget(['[data-onboarding="BoostAnalyticsCopy"]']),
    contentClass: BoostAnalyticsOnboardingHint,
    content: {
      dontShowAgain: true,
    },
    options: {
      placement: 'right-start',
      showOverlay: true,
      spotlights: [{ type: SPOTLIGHT_TYPE.button, padding: 0 }],
    },
  },
];
