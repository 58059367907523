import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
    },
    header: {
      marginBottom: theme.spacing(0.5),
    },
    item: {},
    submenu: {},
    menuItem: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }),
  { name: 'SidebarItems' }
);
