import { useCallback } from 'react';

import { AFTER_CANCEL_SUBSCRIPTION_POPUP, usePopups } from 'src/services/popup';

export const useAfterCancelSubscriptionPopup = () => {
  const popups = usePopups();

  return useCallback(() => {
    popups.openPopup(AFTER_CANCEL_SUBSCRIPTION_POPUP, {
      onClose: () => {
        popups.closePopup(AFTER_CANCEL_SUBSCRIPTION_POPUP);
      },
    });
  }, [popups]);
};
