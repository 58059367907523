import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_TALKING_POINTS_VIEW } from './schema';

const MUTATION = gql`
  mutation ExtractCustomerTalkingPointsView(
    $customerId: ID!
    $input: ResourceDetailsInput!
    $workspaceId: ID
  ) {
    extractCustomerTalkingPointsView(
      customerId: $customerId
      input: $input
      workspaceId: $workspaceId
    ) {
      ...TalkingPointsView
    }
  }
  ${FRAGMENT_TALKING_POINTS_VIEW}
`;

export const extractTalkingPointsView = ({ customerId, workspaceId, input }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, workspaceId, input },
  }).then(({ data }) => getSingleProp(data));
};
