import { useCallback } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { useHistory } from 'src/lib/hooks';
import { useCustomerActions, useBrandVoicesActions, useNotificationActions } from 'src/store';
import {
  RESTRICT_ACTION_TYPE,
  useRestrictActionPopup,
  useErrorHandler,
  useBrandVoices,
} from 'src/services';
import { CONFIRM_POPUP, usePopups } from 'src/services/popup';
import { CONFIRM_POPUP_MODE } from 'src/components/ConfirmPopup';
import { ROUTES } from 'src/routes';

export const useDeleteBrandVoice = () => {
  const popups = usePopups();
  const history = useHistory();
  const location = useLocation();
  const customerActions = useCustomerActions();
  const brandVoicesActions = useBrandVoicesActions();
  const { brandVoices } = useBrandVoices();
  const notificationActions = useNotificationActions();
  const handleError = useErrorHandler();
  const openRestrictActionPopup = useRestrictActionPopup();

  return useCallback(
    (brandVoiceId, brandVoiceName) =>
      new Promise((resolve) => {
        const onConfirm = () => {
          popups.closePopup(CONFIRM_POPUP);

          brandVoicesActions
            .deleteBrandVoice(brandVoiceId)
            .then(() => {
              notificationActions.displaySuccess(`Brand voice '${brandVoiceName}' deleted`);
              if (location.pathname.startsWith(`${ROUTES.BRAND_VOICE}/${brandVoiceId}`)) {
                history.push(ROUTES.ROOT);
              }
              resolve(true);
              customerActions.refreshCurrentCustomerFeatureLimit();
            })
            .catch((error) => {
              handleError(error);
              resolve(false);
            });
        };

        const onCancel = () => {
          popups.closePopup(CONFIRM_POPUP);
          resolve(false);
        };

        if (brandVoices.filter((item) => item.id !== brandVoiceId).length === 0) {
          openRestrictActionPopup({ actionType: RESTRICT_ACTION_TYPE.deleteLastBrandVoice });
          return;
        }

        popups.openPopup(CONFIRM_POPUP, {
          title: `Are you sure you want to delete this brand voice?`,
          content:
            'This will permanently remove all the assets in this brand voice, including: tone of voices, brand terms, target audiences, messaging bank assets, custom models, saved prompts and formulas.',
          confirm: 'Delete brand voice',
          cancel: 'Cancel',
          mode: CONFIRM_POPUP_MODE.warning,
          onConfirm,
          onCancel,
        });
      }),
    [
      popups,
      openRestrictActionPopup,
      brandVoicesActions,
      notificationActions,
      location,
      history,
      customerActions,
      handleError,
      brandVoices,
    ]
  );
};
