import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 666,
    },
    dialogTitle: {
      display: 'flex',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(3.25),
      right: theme.spacing(2),
    },
    closeIcon: {
      fontSize: theme.typography.pxToRem(16),
      color: COLORS.gray.gray30,
    },
    popupTitle: {
      display: 'flex',
      alignItems: 'center',
      color: COLORS.gray.black,
    },
    description: {
      color: COLORS.gray.black,
      marginBottom: theme.spacing(2),
    },
    buttonsWrapper: {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      border: theme.shape.borderGray60,
    },
    channelIcon: {
      color: COLORS.gray.gray30,
      fontSize: theme.typography.pxToRem(24),
      '& rect': {
        fill: 'transparent',
      },
    },
    buttonLabel: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      columnGap: theme.spacing(1),
    },
    channelButton: {
      ...theme.typography.regular14,
      color: COLORS.gray.black,
      padding: theme.spacing(2, 3),
      width: '100%',
      height: 56,
      borderRadius: 0,
      '& + &': {
        borderTop: theme.shape.borderGray60,
      },
    },
    csvTemplateContainer: {
      marginTop: theme.spacing(1),
      backgroundColor: COLORS.gray.gray90,
      display: 'flex',
      gap: theme.spacing(1),
      padding: theme.spacing(1.5, 2),
      borderRadius: theme.shape.borderRadiusSmall,
      color: COLORS.gray.gray30,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      alignItems: 'center',
    },
    uploadIcon: {
      fontSize: theme.typography.pxToRem(16),
    },
  }),
  { name: 'ConnectToChannelPopup' }
);
