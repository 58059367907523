import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';

import { CustomButton, CUSTOM_BUTTON_COLOR, CUSTOM_BUTTON_SIZE } from 'src/components/CustomButton';
import { useStyles } from './useStyles';

export const BusinessBookDemoButton = ({
  className,
  classes,
  onClick,
  vertical,
  hideLabel,
  buttonLabel,
  label,
  endIcon,
  fullWidth,
}) => {
  const styles = useStyles({ classes });

  return (
    <div className={cx(className, styles.root, { [styles.vertical]: vertical })}>
      {!hideLabel && (
        <Typography className={styles.label}>
          {label || 'This is an Enterprise Plan feature'}
        </Typography>
      )}

      <CustomButton
        className={styles.button}
        onClick={onClick}
        color={CUSTOM_BUTTON_COLOR.upsell}
        size={vertical ? CUSTOM_BUTTON_SIZE.medium : CUSTOM_BUTTON_SIZE.large}
        endIcon={endIcon}
        fullWidth={fullWidth}
      >
        {buttonLabel || 'Book a demo'}
      </CustomButton>
    </div>
  );
};

BusinessBookDemoButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  onClick: PropTypes.func,
  vertical: PropTypes.bool,
  hideLabel: PropTypes.bool,
  buttonLabel: PropTypes.string,
  label: PropTypes.string,
  endIcon: PropTypes.element,
  fullWidth: PropTypes.bool,
};
BusinessBookDemoButton.defaultProps = {
  className: null,
  classes: null,
  onClick: null,
  vertical: false,
  hideLabel: false,
  buttonLabel: null,
  label: null,
  endIcon: null,
  fullWidth: false,
};
