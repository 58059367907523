import { useEffect } from 'react';

import { useUiActions } from 'src/store';

export const useMaintainPopupVisibleCount = (state) => {
  const uiActions = useUiActions();

  const popupCount = state?.stack.length;
  useEffect(() => {
    uiActions.setGlobalPopupVisibleCount(popupCount);
  }, [popupCount, uiActions]);
};
