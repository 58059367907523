import { createStateSelectorHook } from 'src/lib/sweetState';
import { ConfirmationDialogStore } from 'src/store';

const useState = createStateSelectorHook(ConfirmationDialogStore, [
  'open',
  'title',
  'message',
  'buttonCancel',
  'buttonCancel',
  'buttonOK',
  'hideCloseIcon',
]);

export const useViewState = () => {
  const state = useState();
  return {
    ...state,
  };
};
