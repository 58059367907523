import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

import { CUSTOMER_PERSONA_FRAGMENT } from './schema';

const QUERY = gql`
  query CustomerPersonas($brandVoiceId: ID!, $projectId: ID) {
    customerPersonas(brandVoiceId: $brandVoiceId, projectId: $projectId) {
      edges {
        node {
          ...CustomerPersona
        }
      }
    }
  }
  ${CUSTOMER_PERSONA_FRAGMENT}
`;

export const queryCustomerPersonas = (brandVoiceId, projectId = null) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      brandVoiceId,
      projectId,
    },
  }).then(({ data }) => getSingleProp(data));
};
