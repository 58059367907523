import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent } from '@material-ui/core';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import customModel from './assets/customModel.svg';
import benchmark from './assets/benchmark.svg';
import talkingPoints from './assets/talkingPoints.svg';
import brandVoice from './assets/brandVoice.svg';
import rightBlock from './assets/rightBlock.svg';
import { useStyles } from './useStyles';

const ITEMS = [
  {
    icon: customModel,
    title: 'Custom Model',
    description: "Create tailored content\nbased on your channel's\nhistory",
  },
  {
    icon: benchmark,
    title: 'Benchmark',
    description:
      'Rank new vs. old content with\nadvanced analytics trained on\nyour marketing channels',
  },
  {
    icon: talkingPoints,

    title: 'Talking Points',
    description: 'Enhance new content with\nchannel-specific high-\nperforming talking points',
  },
  {
    icon: brandVoice,
    title: 'Brand Voice',
    description: 'Fine-tune your messaging for\nevery audience and channel',
  },
];

export const WelcomeRetrialPopup = ({ open, onClose, onSubmit }) => {
  const styles = useStyles();

  return (
    <Dialog
      classes={{ root: styles.root, paper: styles.paper }}
      open={open}
      onClose={onClose}
    >
      <DialogContent className={styles.dialogContent}>
        <CustomTypography className={styles.title}>Welcome Back to Anyword!</CustomTypography>
        <CustomTypography
          className={styles.description}
          variant={TYPOGRAPHY_VARIANT.paragraph14}
          color="primary"
        >
          Your additional 7-day free trial includes Anyword's
          <br /> starter plan and 2,500 words. <b>Here's what's new:</b>
        </CustomTypography>
        <div className={styles.marketingText}>
          <CustomTypography>
            Seamlessly integrate Anyword with your key marketing channels like Google, LinkedIn,
            HubSpot, and Meta and get access to these new features:
          </CustomTypography>
        </div>
        <div className={styles.textsWithIcons}>
          {ITEMS.map((item) => (
            <div
              key={item.title}
              className={styles.textWithIcon}
            >
              <div className={styles.itemIconWrapper}>
                <img
                  src={item.icon}
                  alt={item.title}
                />
              </div>
              <div className={styles.itemTexts}>
                <CustomTypography
                  className={styles.itemTitle}
                  variant={TYPOGRAPHY_VARIANT.medium12}
                >
                  {item.title}
                </CustomTypography>
                <CustomTypography
                  className={styles.itemDescription}
                  variant={TYPOGRAPHY_VARIANT.regular12}
                >
                  {item.description}
                </CustomTypography>
              </div>
            </div>
          ))}
        </div>
        <img
          className={styles.rightBlockImg}
          src={rightBlock}
          alt=""
        />
        <Button
          className={styles.submitButton}
          onClick={onSubmit}
          color="primary"
          variant="contained"
        >
          See it in action
        </Button>
      </DialogContent>
    </Dialog>
  );
};

WelcomeRetrialPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

WelcomeRetrialPopup.defaultProps = {};
