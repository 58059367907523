import { fill, maxBy, uniq } from 'lodash';

export const HIGHLIGHT_MARK = {
  user: 'user',
  recompose: 'recompose',
  positive: 'positive',
  warning: 'warning',
  error: 'error',
  accept: 'accept',
};

export const PERSISTENT_HIGHLIGHT_MARKS = [HIGHLIGHT_MARK.user, HIGHLIGHT_MARK.recompose];

// Former is higher priority
export const HIGHLIGHT_MARKS_PRIORITY = [
  HIGHLIGHT_MARK.error,
  HIGHLIGHT_MARK.warning,
  HIGHLIGHT_MARK.positive,
  HIGHLIGHT_MARK.user,
  HIGHLIGHT_MARK.recompose,
];

export const onlyPersistentHighlightMarks = (marks) =>
  marks.filter((mark) => PERSISTENT_HIGHLIGHT_MARKS.includes(mark.type));

export const moreImportantHighlightMark = (mark1, mark2) => {
  const index1 = HIGHLIGHT_MARKS_PRIORITY.indexOf(mark1);
  const index2 = HIGHLIGHT_MARKS_PRIORITY.indexOf(mark2);
  return index1 == null || index1 > index2;
};

const fillMarkedArrayFromMarks = (marks) => {
  if (!marks?.length) {
    return [];
  }

  const length = maxBy(marks, 'end')?.end + 1;
  const result = fill(Array(length), null);

  marks.forEach((mark) => {
    let counter = mark.start;
    while (counter < mark.end) {
      if (!result[counter] || moreImportantHighlightMark(result[counter], mark.type)) {
        result[counter] = mark.type;
      }
      counter++;
    }
  });

  return result;
};

const filterByParagraph = (array, paragraphIndex) =>
  array.filter((item) => item?.paragraph === paragraphIndex);

const collectMarksFromMarkedArray = (markedArray, paragraphIndex) => {
  const result = [];

  markedArray.reduce(
    (currentMark, currentType, currentIndex, array) => {
      // Last array item
      if (currentIndex === array.length - 1 && currentMark.type) {
        result.push({
          ...currentMark,
          paragraph: paragraphIndex,
          end: currentIndex,
        });
        return null;
      }

      if (currentType !== currentMark.type) {
        if (currentMark.type) {
          result.push({
            ...currentMark,
            paragraph: paragraphIndex,
            end: currentIndex,
          });
        }
        return {
          paragraph: paragraphIndex,
          type: currentType,
          start: currentIndex,
        };
      }

      return currentMark;
    },
    { type: markedArray[0], start: 0 }
  );

  return result;
};

export const normalizeMarkup = (inputMarkup) => {
  const paragraphs = uniq(inputMarkup.map(({ paragraph }) => paragraph));

  const outputMarks = [];
  paragraphs.forEach((currentParagraph) => {
    const paragraphMarks = filterByParagraph(inputMarkup, currentParagraph);

    const markedArray = fillMarkedArrayFromMarks(paragraphMarks);
    const collectedMarks = collectMarksFromMarkedArray(markedArray, currentParagraph);
    outputMarks.push(...collectedMarks);
  });

  return outputMarks;
};
