import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { ASSET_TYPE } from 'src/data/asset';
import { isApplicationModeInfinity } from 'src/data/system';
import { CustomAlert } from 'src/components/CustomAlert';
import { TEXT_EDITOR_TYPE } from '../../lib';
import { TextEditor } from '../TextEditor';
import { CopyInputActions } from '../CopyInputActions';
import { CopyInputInfinityActions } from '../CopyInputInfinityActions';
import { useStyles } from './useStyles';
import { useHandlers } from './useHandlers';

const propTypes = {
  className: PropTypes.string,
  assetType: PropTypes.oneOf(Object.values(ASSET_TYPE)).isRequired,
  editorContent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.string]),
  onEditorContentChange: PropTypes.func,
  disabled: PropTypes.bool,
  textEditorType: PropTypes.oneOf(Object.values(TEXT_EDITOR_TYPE)),
  readOnly: PropTypes.bool,
  detectedBrandTerms: PropTypes.array,
  requireChangesConfirmation: PropTypes.bool,
  editorRef: PropTypes.shape(),
  onCancelChanges: PropTypes.func,
  onSaveChanges: PropTypes.func,
  applicationMode: PropTypes.string,
  onEditorCopy: PropTypes.func,
  onEditorReferenceChange: PropTypes.func,
  onTextCopy: PropTypes.func,
  resetCounter: PropTypes.number,
  applyingAnalyzedToneOfVoice: PropTypes.bool,
  successMessage: PropTypes.string,
  onStartEditing: PropTypes.func,
  editing: PropTypes.bool,
};

const defaultProps = {
  className: null,
  assetType: null,
  editorContent: null,
  onEditorContentChange: null,
  disabled: false,
  textEditorType: null,
  readOnly: false,
  detectedBrandTerms: null,
  requireChangesConfirmation: false,
  editorRef: null,
  onCancelChanges: null,
  onSaveChanges: null,
  applicationMode: null,
  onEditorCopy: null,
  onEditorReferenceChange: null,
  onTextCopy: null,
  resetCounter: 0,
  applyingAnalyzedToneOfVoice: false,
  successMessage: null,
  onStartEditing: null,
  editing: false,
};

export const CopyInput = ({
  className,
  assetType,
  editorContent,
  onEditorContentChange,
  disabled,
  textEditorType,
  readOnly,
  detectedBrandTerms,
  requireChangesConfirmation,
  editorRef,
  onCancelChanges,
  onSaveChanges,
  applicationMode,
  onEditorCopy,
  onEditorReferenceChange,
  onTextCopy,
  resetCounter,
  applyingAnalyzedToneOfVoice,
  successMessage,
  onStartEditing,
}) => {
  const styles = useStyles();

  const [editMode, setEditMode] = useState(false);
  const [editor, setEditor] = useState();

  const { handleCancelClick, handleSaveClick, handleEditorContentChange, handleEditClick } =
    useHandlers({
      editor,
      setEditMode,
      onCancelChanges,
      onEditorContentChange,
      onSaveChanges,
      onStartEditing,
    });

  useEffect(() => {
    if (onEditorReferenceChange) {
      onEditorReferenceChange(editor);
    }
  }, [editor, onEditorReferenceChange]);

  useEffect(() => {
    if (applyingAnalyzedToneOfVoice && !editMode) {
      setEditMode(true);
    }
  }, [applyingAnalyzedToneOfVoice, editMode]);

  const isAppModeInfinity = isApplicationModeInfinity(applicationMode);

  return (
    <div className={cx(styles.root, className)}>
      <TextEditor
        classes={{ editor: styles.editor }}
        assetType={assetType}
        content={editorContent}
        onChange={handleEditorContentChange}
        disabled={disabled}
        editorType={textEditorType}
        readOnly={readOnly || !editMode || applyingAnalyzedToneOfVoice}
        detectedBrandTerms={detectedBrandTerms}
        editorRef={editorRef}
        setEditor={setEditor}
        onCopy={() => onTextCopy?.(editor)}
        onEdit={handleEditClick}
        editMode={editMode}
        isAppModeInfinity={isAppModeInfinity}
        loading={applyingAnalyzedToneOfVoice}
      />

      {successMessage && (
        <CustomAlert
          className={styles.alert}
          classes={{
            message: styles.alertMessage,
            textTitle: styles.alertTitle,
            icon: styles.alertIcon,
          }}
          severity="successBold"
          title={successMessage}
          hideCloseButton
        />
      )}

      {isAppModeInfinity ? (
        <CopyInputInfinityActions
          className={styles.editingActions}
          readOnly={readOnly}
          onSave={handleSaveClick}
          onCopy={onEditorCopy}
          disabled={disabled}
        />
      ) : (
        requireChangesConfirmation &&
        !readOnly &&
        editMode && (
          <CopyInputActions
            className={styles.editingActions}
            editMode={editMode}
            onSave={handleSaveClick}
            onCancel={handleCancelClick}
            disabled={disabled || applyingAnalyzedToneOfVoice}
          />
        )
      )}
    </div>
  );
};

CopyInput.displayName = 'CopyInput';
CopyInput.propTypes = propTypes;
CopyInput.defaultProps = defaultProps;
