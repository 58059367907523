import { useChannelAccounts } from './useChannelAccounts';
import { EMAILS_CHANNELS_AND_RESOURCES, flatConnectedAccounts } from 'src/data/performance';

export const useEmailConnectedAccounts = () => {
  const { connectedAccounts } = useChannelAccounts();

  return flatConnectedAccounts(
    EMAILS_CHANNELS_AND_RESOURCES.map((channel) => {
      return connectedAccounts[channel];
    }).filter((a) => a),
    true
  );
};
