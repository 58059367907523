import { isTeadsProduction } from 'src/lib/url';
import { whiteLabels } from './whiteLabels';

export const isTeadsUI = () => {
  const whiteLabel = window.alm?.whiteLabel?.read() || null;
  if (whiteLabel === whiteLabels.teads) {
    return true;
  }
  if (whiteLabel === whiteLabels.anyword) {
    return false;
  }
  const host = window.location.href;
  return isTeadsProduction(host);
};
