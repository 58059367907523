import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconUnknown = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        d="M7.49805 15.5H3.49805C3.23283 15.5 2.97848 15.3946 2.79094 15.2071C2.6034 15.0196 2.49805 14.7652 2.49805 14.5V3.5C2.49805 3.23478 2.6034 2.98043 2.79094 2.79289C2.97848 2.60536 3.23283 2.5 3.49805 2.5H13.498C13.7633 2.5 14.0176 2.60536 14.2052 2.79289C14.3927 2.98043 14.498 3.23478 14.498 3.5V8M7.49805 5.5H11.498M5.49805 8.5H11.498M5.49805 11.5H10.498"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1333 16.6954L9 17L9.30459 14.8644L14.7906 9.3784C14.9109 9.25832 15.0535 9.16311 15.2106 9.09818C15.3676 9.03326 15.5359 8.99989 15.7058 9C15.8757 9.00011 16.0439 9.03368 16.2009 9.0988C16.3578 9.16392 16.5004 9.25932 16.6205 9.37954C16.7405 9.49977 16.8357 9.64247 16.9007 9.79949C16.9656 9.95651 16.999 10.1248 16.9989 10.2947C16.9987 10.4646 16.9652 10.6328 16.9 10.7898C16.8349 10.9467 16.7395 11.0893 16.6193 11.2094L11.1333 16.6954Z"
        fill="#FFB700"
        stroke="#1E1E1E"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
