import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { trim } from 'lodash';

import { detectBrandTerms } from 'src/graphql/brandTerms';
import { useActualizeDetectedBrandTermsData } from 'src/services';

const DEFAULT_DEBOUNCE_TIMEOUT = 1000;
const EMPTY_STATE = [];

/**
 * Hook options
 * @typedef HookOptions
 * @type {object}
 * @property {boolean} [disabled] - should requests be disabled
 * @property {number} [debounceTimeout] - debounce timeout
 * @property {boolean} [disableStateChanges] - should hook stop updating internal state, and only use callbacks
 */

/**
 * Execute brand terms detection on provided text, debounced.
 *
 * @param {string} key - Provided key to reset state
 * @param {string[]} brandVoiceIds - Brand voice IDs to detect terms for
 * @param {string} text - Text to detect terms in
 * @param {Function} [onItemsChange] - Callback to call when items change
 * @param {Function} [onLoadingChange] - Callback to call when loading state changes
 * @param {HookOptions} [hookOptions] - Hook options
 * @returns {{loading: boolean, items: []|*}}
 */
export const useTextDetectedBrandTermsDebounced = (
  { key, brandVoiceIds, text, onItemsChange, onLoadingChange },
  hookOptions
) => {
  const disableRequests = hookOptions?.disabled || false;
  const debounceTimeout = hookOptions?.debounceTimeout || DEFAULT_DEBOUNCE_TIMEOUT;
  const disableStateChanges = hookOptions?.disableStateChanges || false;

  const [detectedBrandTerms, setDetectedBrandTerms] = useState(EMPTY_STATE);
  const [loading, setLoading] = useState(false);

  const refWriteResult = useRef();
  refWriteResult.current = (result) => {
    if (!disableStateChanges) {
      setDetectedBrandTerms(result);
    }
    if (onItemsChange) {
      onItemsChange(result);
    }
  };

  const refWriteLoading = useRef();
  refWriteLoading.current = (result) => {
    if (!disableStateChanges) {
      setLoading(result);
    }
    if (onLoadingChange) {
      onLoadingChange(result);
    }
  };

  const fetchDetectedBrandTerms = useDebouncedCallback(async (requestParams, requestOptions) => {
    try {
      if (brandVoiceIds.length) {
        const result = await detectBrandTerms(requestParams, requestOptions);
        refWriteResult.current(result);
      }
    } catch (error) {
      // we don't care much about errors here
    } finally {
      refWriteLoading.current(false);
    }
  }, debounceTimeout);

  // Reset state when "key" changes
  useEffect(() => {
    if (key) {
      refWriteResult.current(EMPTY_STATE);
      refWriteLoading.current(false);
    }
  }, [key]);

  // Main effect to fetch detected brand terms
  useEffect(() => {
    // Clean and do not query, if host component doesn't want it now
    if (disableRequests) {
      refWriteResult.current(EMPTY_STATE);
      return undefined;
    }

    // Return early, if no "text" to annotate was provided
    if (trim(text).length === 0) {
      refWriteResult.current(EMPTY_STATE);
      return undefined;
    }

    // Mark as "loading" even when we wait for "debounced" request to fire
    refWriteLoading.current(true);
    // Schedule debounced request
    const abortController = new AbortController();
    fetchDetectedBrandTerms(
      { brandVoiceIds, text },
      {
        abortSignal: abortController.signal,
      }
    );

    // Clean up possible pending requests when we want to schedule new request or component unmounts
    return () => {
      abortController.abort();
      refWriteLoading.current(false);
    };
  }, [disableRequests, fetchDetectedBrandTerms, text, brandVoiceIds]);

  // Actualize "brand terms" data if user decides to edit one in-place
  const actualizedItems = useActualizeDetectedBrandTermsData(detectedBrandTerms);

  return useMemo(
    () => ({
      items: actualizedItems,
      loading,
    }),
    [actualizedItems, loading]
  );
};
