import { ROUTES } from 'src/routes';
import { promisedWait } from 'src/lib/common';
import { captureException } from 'src/lib/sentry';
import { CHANNEL, alignChannelFromBackend, getChannelLabel } from 'src/data/channel';
import { PERFORMANCE_EXTRACTION_STATUS } from 'src/data/performance';
import { connectFacebook } from 'src/services/facebook';
import connectToLinkedin from 'src/services/connectToLinkedin';
import connectToLinkedinSocial from 'src/services/connectToLinkedinSocial';
import connectToGoogle from 'src/services/connectToGoogle';
import { connectToHubspot } from 'src/services/connectToHubspot';
import { waitForJob } from 'src/graphql/job';
import {
  performanceCenterRequest,
  queryPerformanceCenterRequestStatus,
} from 'src/graphql/performance';
import { AbortError, AsyncRequestError, ExtractionError } from 'src/lib/errors';
import { connectToSalesforce } from 'src/services/connectToSalesforce';

const rejectUnknownChannel = (error = 'Unknown social channel') => {
  return Promise.reject(new Error(error));
};

export const connectToChannel = (channel, customerId) => {
  switch (channel) {
    case CHANNEL.facebookPages:
    case CHANNEL.facebook:
    case CHANNEL.instagram:
      return connectFacebook(customerId);
    case CHANNEL.linkedin:
      return connectToLinkedin();
    case CHANNEL.linkedinSocial:
      return connectToLinkedinSocial();
    case CHANNEL.google:
      return connectToGoogle();
    case CHANNEL.hubspot:
      return connectToHubspot();
    case CHANNEL.salesforce:
      return connectToSalesforce();

    default:
      return rejectUnknownChannel();
  }
};

export const CREATION_POLLING_INTERVAL = 3000;

class CopiesExtractionError extends Error {
  constructor(code, message) {
    super(message);
    this.name = 'CopiesExtractionError';
    this.code = code;
    this.message = message;
  }
}

export const isCopiesExtractionError = (error) => error instanceof CopiesExtractionError;

const unwrapErrors = (json) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    captureException(error, {
      extra: {
        source: 'store/performance/utils/waitForCopiesExtraction.js -> unwrapErrors',
        value: json,
      },
    });
    return [];
  }
};

const stringifyErrors = (errors) => errors?.map((e) => e.detail).join('\n');

const isAuthError = (errorCode) => errorCode === ADS_EXTRACTION_ERROR.unauthorizedAccount;

const checkKnownCopiesExtractionError = (errors, errorCode, newMessage = null) => {
  const error = errors?.find((e) => e?.code === errorCode);
  if (error) {
    const message = error?.message || newMessage;
    if (isAuthError(error.code)) {
      return new CopiesExtractionError(error.code, message);
    } else {
      return new Error(message);
    }
  }
  return null;
};

const ADS_EXTRACTION_ERROR = {
  unauthorizedAccount: 'pc_unauthorized_account',
  authorizationFailed: 'pc_authorization_failed',
  genericError: 'pc_generic_error',
  pcAlreadyConnected: 'pc_already_connected',
};

const KNOWN_ERRORS = [
  {
    code: ADS_EXTRACTION_ERROR.unauthorizedAccount,
    message: 'Failed to fetch data, authentication issue please re-authorize.',
  },
  {
    code: ADS_EXTRACTION_ERROR.authorizationFailed,
    message: 'Authorization failed. Please try again.',
  },
  {
    code: ADS_EXTRACTION_ERROR.genericError,
    message: 'Failed to connect account.',
  },
  {
    code: ADS_EXTRACTION_ERROR.pcAlreadyConnected,
    message: 'Account already connected.',
  },
];

export const searchForKnownCopiesExtractionError = (errors) => {
  for (let i = 0; i < KNOWN_ERRORS.length; i += 1) {
    const knownErrorData = KNOWN_ERRORS[i];
    const error = checkKnownCopiesExtractionError(
      errors,
      knownErrorData.code,
      knownErrorData.message
    );
    if (error) {
      return error;
    }
  }

  return null;
};

export const waitForCopiesExtraction = async ({
  channel,
  customerId,
  accountId,
  notifications,
  options = null,
  setAvgCompletionPercent,
  textVariationsResultsCount = 50000,
  setChannelWarning,
  mergeAccountStatus,
}) => {
  if (options?.abortSignal?.aborted) {
    throw new AbortError();
  }

  // Query only the status of the request and related fields: errors, avgCompletionPercent
  const statusResponse = await queryPerformanceCenterRequestStatus({
    accountId,
    customerId,
    dataSource: channel,
  });

  // When the request is done, query the actual results
  const queryActualRequestResults = () =>
    performanceCenterRequest({
      accountId,
      customerId,
      dataSource: channel,
      textVariationsResultsCount,
    });

  if (!statusResponse) {
    mergeAccountStatus(
      alignChannelFromBackend(channel),
      accountId,
      PERFORMANCE_EXTRACTION_STATUS.nullableResults
    );
    return;
  }

  if (statusResponse.status) {
    mergeAccountStatus(alignChannelFromBackend(channel), accountId, statusResponse.status);
  }

  if (statusResponse.avgCompletionPercent || statusResponse.avgCompletionPercent === 0) {
    setAvgCompletionPercent(
      alignChannelFromBackend(channel),
      accountId,
      Math.min(99, statusResponse.avgCompletionPercent)
    );
  }

  // If it was partially done - query text variations
  if (statusResponse.status === PERFORMANCE_EXTRACTION_STATUS.partialDone) {
    const errors = unwrapErrors(statusResponse.errors);
    const errorsText = stringifyErrors(errors);
    if (!window.location.pathname.includes(ROUTES.PROJECTS) && errorsText) {
      notifications.displayWarning(errorsText);
    }
    setChannelWarning(channel, errorsText);
    return queryActualRequestResults();
  }

  // If it was successful - query text variations
  if (statusResponse.status === PERFORMANCE_EXTRACTION_STATUS.done) {
    setAvgCompletionPercent(alignChannelFromBackend(channel), accountId, 100);
    return queryActualRequestResults();
  }

  // If creation failed - throw returned error upwards
  if (statusResponse.status === PERFORMANCE_EXTRACTION_STATUS.doneWithErrors) {
    const errors = unwrapErrors(statusResponse.errors);

    const knownError = searchForKnownCopiesExtractionError(errors);
    if (knownError) {
      throw knownError;
    }

    const errorsText =
      stringifyErrors(errors) ||
      `${getChannelLabel(
        alignChannelFromBackend(channel)
      )} connection request failed with an unknown error`;
    throw new AsyncRequestError(errorsText);
  }

  // Wait sometime and check again
  await promisedWait(CREATION_POLLING_INTERVAL);

  return waitForCopiesExtraction({
    channel,
    customerId,
    accountId,
    notifications,
    options,
    setAvgCompletionPercent,
    setChannelWarning,
    mergeAccountStatus,
    textVariationsResultsCount,
  });
};

export const waitForExtraction = ({ id, options = null }) => {
  return waitForJob({
    id,
    options: {
      abortSignal: options?.abortSignal,
      pollingInterval: CREATION_POLLING_INTERVAL,
    },
  }).catch((error) => {
    throw new ExtractionError(error.message || 'Failed to extract');
  });
};
