import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query AvailableTonesOfVoice(
    $projectType: String!
    $assetType: String!
    $generationType: String!
    $contentType: String
  ) {
    availableTonesOfVoice(
      projectType: $projectType
      assetType: $assetType
      generationType: $generationType
      contentType: $contentType
    ) {
      id
      title
      hint
      keywordsEnabled
    }
  }
`;

export const queryAvailableTonesOfVoice = ({
  projectType,
  contentType,
  assetType,
  generationType,
}) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      projectType,
      contentType,
      assetType,
      generationType,
    },
  }).then(({ data }) => getSingleProp(data));
