import { capitalize } from 'lodash';

import {
  DEFAULT_SUGGEST_TOV_MODE,
  DRAFT_TOV_ID,
  PRESET_TONE_OF_VOICE,
  PRESET_TONE_OF_VOICE_PROPS,
} from './const';
import { isContentImproverTool, isRewriteGenerationTool } from '../generationTool';

export const isPresetTov = (tov) => Object.values(PRESET_TONE_OF_VOICE).includes(tov);

export const getToneOfVoiceTitle = (tov) => PRESET_TONE_OF_VOICE_PROPS[tov]?.title || '';
export const getToneOfVoiceDescription = (tov) =>
  PRESET_TONE_OF_VOICE_PROPS[tov]?.description || '';
export const getToneOfVoiceLabel = (tov) => PRESET_TONE_OF_VOICE_PROPS[tov]?.label || '';

export const getTovTexts = (tov, toneOfVoices) => {
  if (isPresetTov(tov)) {
    return {
      tovTitle: getToneOfVoiceTitle(tov),
      tovDescription: getToneOfVoiceDescription(tov),
      tovLabel: getToneOfVoiceLabel(tov),
    };
  } else {
    const toneOfVoice = toneOfVoices.find((toneOfVoice) => toneOfVoice.id === tov);
    return {
      tovTitle: toneOfVoice?.name,
      tovDescription: toneOfVoice?.description,
      tovLabel: toneOfVoice?.brandVoiceName,
    };
  }
};

export const getToneOfVoiceForGeneration = (selectedTov, draftTov) => {
  if (draftTov && selectedTov === DRAFT_TOV_ID) {
    return {
      draftTov: {
        personality: draftTov.personality,
        traits: draftTov.traits,
        description: draftTov.description,
      },
    };
  }
  if (isPresetTov(selectedTov)) {
    return { defaultPreset: selectedTov };
  } else {
    return { customId: selectedTov };
  }
};

export const getToneOfVoiceForInfinity = (selectedTov, toneOfVoices) => {
  if (!selectedTov) {
    return;
  } else {
    const { tovTitle } = getTovTexts(selectedTov, toneOfVoices);
    return tovTitle;
  }
};

export const getToneOfVoiceFromGeneration = ({
  tovInput,
  generationCustomModelId,
  draftToneOfVoice,
}) => {
  if (generationCustomModelId) {
    return {
      selectedCustomModelId: generationCustomModelId,
      selectedTov: null,
      draftToneOfVoice: null,
    };
  }
  if (draftToneOfVoice) {
    return {
      selectedCustomModelId: null,
      selectedTov: DRAFT_TOV_ID,
      draftToneOfVoice: { ...draftToneOfVoice, name: '' },
    };
  }
  if (tovInput?.draftTov) {
    return {
      selectedCustomModelId: null,
      selectedTov: DRAFT_TOV_ID,
      draftToneOfVoice: { ...tovInput.draftTov, name: '' },
    };
  }
  return {
    selectedCustomModelId: null,
    selectedTov: tovInput?.customId || tovInput?.defaultPreset || DEFAULT_SUGGEST_TOV_MODE,
    draftToneOfVoice: null,
  };
};

export const getToneOfVoiceForTrackingEvent = (tovInput, toneOfVoices) => {
  if (tovInput?.draftTov) {
    return {
      tovDescription: formatToneOfVoiceDescription(tovInput?.draftTov),
      tov: 'draft_tov',
    };
  }
  const customToneOfVoice = toneOfVoices.find(
    (toneOfVoice) => toneOfVoice.id === tovInput?.customId
  );
  if (customToneOfVoice) {
    return {
      tov: 'custom_tov',
      tovDescription: formatToneOfVoiceDescription(customToneOfVoice),
    };
  }
  return {
    tov: tovInput?.defaultPreset || DEFAULT_SUGGEST_TOV_MODE,
  };
};

export const formatToneOfVoiceTrait = (data) => {
  if (!data.butNot) {
    return capitalize(data.trait);
  }
  return `${capitalize(data.trait)} but not ${data.butNot}`;
};

export const formatToneOfVoiceDescription = (tov, multiline) => {
  const result = [];
  if (tov.traits?.length) {
    result.push(`${tov.traits.map((trait) => `${formatToneOfVoiceTrait(trait)}`).join(', ')}`);
  }
  if (tov.personality) {
    result.push(`Personality: ${capitalize(tov.personality)}`);
  }
  if (tov.description) {
    result.push(`Custom instructions: ${tov.description}`);
  }
  return result.join(multiline ? '\n' : ', ');
};

export const isEmptyTovData = (data) =>
  (!data.traits || data.traits?.length === 0) && !data.personality && !data.description;

export const getWorkspaceDefaultToneOfVoice = (workspace, generationTool) => {
  const workspaceDefaultToneOfVoice = (workspace?.defaultTovAnalyzerForTool || [])?.find(
    (item) => item.generationTool === generationTool
  );
  return workspaceDefaultToneOfVoice
    ? {
        id: workspaceDefaultToneOfVoice.customTovId,
        name: workspaceDefaultToneOfVoice.customTovName,
        ...workspaceDefaultToneOfVoice,
      }
    : null;
};

export const getBrandVoicesAssetsTonesOfVoice = (toneOfVoices) => {
  return toneOfVoices.filter((voice) => voice.traits?.length > 0);
};

export const getPresetTonesOfVoice = (generationTool) => {
  const presetTonesOfVoice = Object.values(PRESET_TONE_OF_VOICE);
  if (isRewriteGenerationTool(generationTool) || isContentImproverTool(generationTool)) {
    return presetTonesOfVoice.filter(
      (toneOfVoice) => toneOfVoice !== PRESET_TONE_OF_VOICE.engaging
    );
  }
  return presetTonesOfVoice;
};
