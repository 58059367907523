import React from 'react';

import image from 'src/assets/img/channel_revcontent.png';
import ImageIcon from 'src/components/ImageIcon';

const ChannelRevcontent = (props) => (
  <ImageIcon
    image={image}
    {...props}
  />
);

export default ChannelRevcontent;
