import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION } from './schema';

const MUTATION = gql`
  mutation CreateBlogPostBuilderAllOutlineSections(
    $projectId: ID!
    $sections: [BlogPostBuilderOutlineSectionInput!]!
  ) {
    createBlogPostBuilderAllOutlineSections(projectId: $projectId, sections: $sections) {
      ...BlogPostBuilderOutlineSection
    }
  }
  ${FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION}
`;

export const createBlogPostBuilderAllOutlineSections = ({ projectId, sections }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { projectId, sections },
  }).then(({ data }) => getSingleProp(data));
};
