import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { CUSTOM_DATASET_FRAGMENT } from '../customModel/schema';

const MUTATION = gql`
  mutation PerformanceCenterRecreateCustomModel(
    $customModelId: ID!
    $accountId: ID
    $dataSource: PerformanceCenterDataSource
  ) {
    performanceCenterRecreateCustomModel(
      customModelId: $customModelId
      accountId: $accountId
      dataSource: $dataSource
    ) {
      ...CustomDataset
    }
  }
  ${CUSTOM_DATASET_FRAGMENT}
`;

export const performanceCenterRecreateCustomModel = (variables) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
