import { GENERATION_TOOL_GROUP } from 'src/data/generationTool';

export const SIDEBAR_TAB_TYPE = {
  all: 'all',
  mostPopular: 'most_popular',
  toolGroup: 'tool_group',
  blog: 'blog',
  header: 'header',
};

export const DOCUMENT_TABS = [
  // Common tabs
  { type: SIDEBAR_TAB_TYPE.all, label: 'All' },
  { type: SIDEBAR_TAB_TYPE.mostPopular, label: 'Most popular' },
  { type: SIDEBAR_TAB_TYPE.header, label: 'Use Cases' },
  { type: SIDEBAR_TAB_TYPE.toolGroup, toolGroup: GENERATION_TOOL_GROUP.campaigns },
  { type: SIDEBAR_TAB_TYPE.toolGroup, toolGroup: GENERATION_TOOL_GROUP.social },
  { type: SIDEBAR_TAB_TYPE.toolGroup, toolGroup: GENERATION_TOOL_GROUP.ads },
  { type: SIDEBAR_TAB_TYPE.toolGroup, toolGroup: GENERATION_TOOL_GROUP.landingPage },
  { type: SIDEBAR_TAB_TYPE.toolGroup, toolGroup: GENERATION_TOOL_GROUP.email },
  // "generation tool group" tabs
  { type: SIDEBAR_TAB_TYPE.header, label: 'General tools' },
  { type: SIDEBAR_TAB_TYPE.toolGroup, toolGroup: GENERATION_TOOL_GROUP.articleBlog },
  { type: SIDEBAR_TAB_TYPE.toolGroup, toolGroup: GENERATION_TOOL_GROUP.anyCopy },
  { type: SIDEBAR_TAB_TYPE.toolGroup, toolGroup: GENERATION_TOOL_GROUP.boost },
  { type: SIDEBAR_TAB_TYPE.header, label: 'Saved templates' },
  {
    type: SIDEBAR_TAB_TYPE.toolGroup,
    toolGroup: GENERATION_TOOL_GROUP.customMultiPrompt,
    label: 'Your saved templates',
  },
];

export const ALL_SIDEBAR_TABS = [...DOCUMENT_TABS];
