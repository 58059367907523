import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
    },
    cell: {
      padding: theme.spacing(1, 2),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    emailChannelLabel: {
      color: COLORS.gray.gray40,
    },
    featureLimitedLabel: {
      paddingLeft: theme.spacing(0.5),
      color: COLORS.error.active,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },
    accountId: {
      maxWidth: 119,
    },
    buttonCell: {
      textAlign: 'center',
    },
    actionButton: {
      width: 106,
    },
  }),
  { name: 'AccountsTable' }
);
