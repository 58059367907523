import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const FavoriteStar = memo(
  forwardRef(({ full, light, ...restProps }, ref) => {
    const fill = full ? (light ? COLORS.warning.light : '#F9BE12') : 'none';

    return (
      <SvgIcon
        {...restProps}
        ref={ref}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.35042 1.11772C7.58555 0.532188 8.41445 0.532188 8.64958 1.11772L10.2932 5.21062C10.3933 5.45996 10.6273 5.62998 10.8954 5.64816L15.2959 5.94654C15.9254 5.98922 16.1816 6.77755 15.6973 7.18211L12.3127 10.01C12.1065 10.1823 12.0171 10.4574 12.0826 10.718L13.1587 14.9953C13.3126 15.6072 12.642 16.0944 12.1076 15.7589L8.3722 13.4138C8.14463 13.2709 7.85537 13.2709 7.6278 13.4138L3.89236 15.7589C3.35797 16.0944 2.68738 15.6072 2.84132 14.9953L3.91737 10.718C3.98292 10.4574 3.89353 10.1823 3.68734 10.01L0.302655 7.18211C-0.181556 6.77755 0.0745884 5.98922 0.704119 5.94654L5.1046 5.64816C5.37268 5.62998 5.60669 5.45996 5.70682 5.21062L7.35042 1.11772Z"
          strokeWidth="0.8"
          fill={fill}
          stroke={full ? 'none' : '#6F80A2'}
        />
      </SvgIcon>
    );
  })
);
