import React, { forwardRef } from 'react';
import { SvgIcon } from '@material-ui/core';

import { useSvgId } from 'src/lib/hooks';

export const AlertIcon = forwardRef((props, ref) => {
  const svgId = useSvgId();

  return (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 16 16"
      data-fill-none
    >
      <g clipPath={`url(#${svgId})`}>
        <path
          d="M7.35953 2.57347L1.71286 12.0001C1.59644 12.2018 1.53484 12.4303 1.53418 12.6631C1.53353 12.896 1.59385 13.1249 1.70914 13.3272C1.82443 13.5294 1.99068 13.698 2.19133 13.816C2.39199 13.9341 2.62006 13.9976 2.85286 14.0001H14.1462C14.379 13.9976 14.6071 13.9341 14.8077 13.816C15.0084 13.698 15.1746 13.5294 15.2899 13.3272C15.4052 13.1249 15.4655 12.896 15.4649 12.6631C15.4642 12.4303 15.4026 12.2018 15.2862 12.0001L9.63953 2.57347C9.52068 2.37754 9.35334 2.21555 9.15366 2.10313C8.95397 1.9907 8.72868 1.93164 8.49953 1.93164C8.27037 1.93164 8.04508 1.9907 7.8454 2.10313C7.64571 2.21555 7.47837 2.37754 7.35953 2.57347V2.57347Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 11.3335H8.50667"
          stroke="currentColor"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 6V8.66667"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={svgId}>
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
