import { Typography } from '@material-ui/core';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import useStyles from './useStyles';

export const UserAvatar = ({ className, name }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)}>
      <Typography>{name?.charAt(0)?.toUpperCase()}</Typography>
    </div>
  );
};

UserAvatar.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};

UserAvatar.defaultProps = {
  className: null,
  name: '',
};
