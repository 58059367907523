import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, Typography } from '@material-ui/core';

import { CustomDialog } from 'src/components/CustomDialog';
import { useStyles } from './useStyles';

export const NoWorkspacesPopup = ({ open, onClose }) => {
  const styles = useStyles();

  return (
    <CustomDialog
      classes={{ paper: styles.root }}
      open={open}
      title="You are currently unable to view any workspaces"
      submitLabel="Close"
      onSubmit={onClose}
      data-testid="NoWorkspacesPopup"
      fullWidth
    >
      <DialogContent className={styles.dialogContent}>
        <Typography className={styles.description}>
          Reach out to your administrator and ask them to include you in the required workspaces.
        </Typography>
      </DialogContent>
    </CustomDialog>
  );
};

NoWorkspacesPopup.propTypes = {
  open: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
};
NoWorkspacesPopup.defaultProps = {};
