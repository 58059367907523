import { UniqMap } from 'src/lib/UniqMap';
import { GENERATION_TOOL } from 'src/data/generationTool/const';

const DEFAULT_CHARS_PER_TOOL = new UniqMap();

/**
 * "product" data-driven tools
 */

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.facebookPrimaryTextProduct, 125);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.facebookHeadlineProduct, 40);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.googleHeadlineProduct, 30);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.googleDescriptionProduct, 90);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.outbrainHeadlineProduct, 100);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.taboolaHeadlineProduct, 100);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.twitterCopyProduct, 280);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.twitterTitleProduct, 70);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.linkedinIntroSingleImage, 150);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.linkedinHeadlineSingleImage, 70);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.pinterestDescriptionProduct, 500);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.pinterestTitleProduct, 100);

/**
 * "landing" data-driven tools
 */

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.landingHeadline, 50);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.landingSubheader, 150);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.landingCTA, 20);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.landingBenefits, 1000);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.landingFeatures, 1000);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.landingSEOMetadata, 160);

/**
 * "social" data-driven tools
 */

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.instagramCaption, 400);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.facebookPost, 400);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.tweet, 280);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.linkedinPost, 1600);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.pinterestPostDescription, 500);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.pinterestPostTitle, 60);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.emailSubject, 160);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.emailBody, 1000);

DEFAULT_CHARS_PER_TOOL.add(GENERATION_TOOL.freestyle, 9999);

export const getDefaultCharsByTool = (generationTool) => {
  if (!generationTool) {
    return null;
  }
  return DEFAULT_CHARS_PER_TOOL.get(generationTool);
};
