import { getApplicationHost } from 'src/data/system';
import { formatRoute } from 'src/lib/routing';
import { preparePlatformUrl } from 'src/lib/url';
import { ROUTE_PARAMS, ROUTES } from 'src/routes';

const formatExternalPlatformLink = (route, options = {}) => {
  const mergedOptions = { ...options };

  const host = getApplicationHost();
  if (host) {
    mergedOptions[ROUTE_PARAMS.host] = host;
  }

  const path = formatRoute(route, mergedOptions);
  return preparePlatformUrl(path);
};

export const formatUpgradeLink = () => formatExternalPlatformLink(ROUTES.UPGRADE);

export const formatAbsoluteRootLink = () => formatExternalPlatformLink(ROUTES.ROOT);
