import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 732,
      width: 732,
      position: 'relative',
      margin: 0,
      overflow: 'hidden',
    },
    dialogContent: {
      padding: theme.spacing(2, 4, 3),
    },
    content: {
      whiteSpace: 'pre-wrap',
    },
    dialogActions: {
      justifyContent: 'flex-end !important',
    },
  }),
  { name: 'PopupInformation' }
);
