export const URL_TERMS = 'https://anyword.com/anyword-terms-of-service/';
export const URL_PRIVACY_POLICY = 'https://anyword.com/privacy-policy';
export const URL_PRICING = 'https://anyword.com/pricing';
export const SUPPORT_URL =
  'https://support.anyword.com/hc/en-us/requests/new?utm_source=anyword_platform';
export const URL_VISIT_APPSUMO = 'https://appsumo.com/account/products';
export const URL_BRAND_VOCABULARY_GUIDELINES = 'https://anyword.com/brand-vocabulary';

export const DEBOUNCED_CALL_STATUS = {
  started: 'started',
  success: 'success',
  error: 'error',
};

export const YOUTUBE_VIDEO_ID = {
  dataDrivenDocuments: '4Af3ziZtxIY',
};

export const WISTIA_VIDEO_ID = {
  toneOfVoice: '1qarcexcp8',
  targetAudiences: 'l9xovpdw0i',
  extensionOnboarding: 'f9hjt4zd5m',
  homeOnboardingIntegrations: 'f7aq3xxcrz',
  homeOnboardingTargetAudience: 'utfr8tquek',
  homeOnboardingToneOfVoice: '00hhj8whxz',
};

export const COMPONENT_VIEW_TYPE = {
  generationPanel: 'generationPanel',
  insightsPanel: 'insightsPanel',
  blogBuilder: 'blogBuilder',
  wtmProject: 'wtmProject',
};

export const GENERATION_PANEL_MENU_TYPE = {
  toneOfVoice: 'toneOfVoice',
  talkingPoints: 'talkingPoints',
  formulas: 'formulas',
};
