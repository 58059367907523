import has from 'lodash/has';

const createReducer = (reducerObject, initialState = {}) => (
  state = initialState,
  action
) =>
  has(reducerObject, action.type)
    ? reducerObject[action.type](state, action)
    : state;

export default createReducer;
