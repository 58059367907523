import React, { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const CheckboxChecked = forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    viewBox="0 0 16 16"
  >
    <path
      d="M2.66667 0.5H13.3333C14.53 0.5 15.5 1.47005 15.5 2.66667V13.3333C15.5 14.53 14.53 15.5 13.3333 15.5H2.66667C1.47005 15.5 0.5 14.53 0.5 13.3333V2.66667C0.5 1.47005 1.47005 0.5 2.66667 0.5Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M6.13979 7.59497C5.8261 7.2723 5.31023 7.26509 4.98758 7.57878C4.66492 7.89246 4.65766 8.4083 4.97135 8.73096L6.13979 7.59497ZM7.77366 10.4445L7.18944 11.0124L7.87021 11.7126L8.43714 10.9175L7.77366 10.4445ZM11.9227 6.02859C12.184 5.66217 12.0987 5.15335 11.7323 4.89211C11.3659 4.63087 10.8571 4.71614 10.5958 5.08255L11.9227 6.02859ZM4.97135 8.73096L7.18944 11.0124L8.35788 9.87649L6.13979 7.59497L4.97135 8.73096ZM8.43714 10.9175L11.9227 6.02859L10.5958 5.08255L7.11024 9.97146L8.43714 10.9175Z"
      fill="white"
    />
  </SvgIcon>
));
