import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},

    contentWrapper: {},
    title: {
      height: 26,
      width: '60%',
    },
    annotations: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    annotation: {
      flex: 1,
    },
  }),
  { name: 'Loader' }
);
