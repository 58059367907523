/**
 * Get the operation name from a GraphQL mutation or query
 * @param {DocumentNode} gqlMutation - GQL mutation or query
 * @returns {string|null} - operation name or null if not found
 */
export const getGqlOperationName = (gqlMutation) => {
  // gqlMutation.definitions is an array, so we find the first definition
  // of type 'OperationDefinition' which represents our mutation or query
  const operationDefinition = gqlMutation?.definitions?.find(
    (definition) => definition?.kind === 'OperationDefinition'
  );

  // Return the operation name or null if not found
  return operationDefinition?.name?.value || null;
};
