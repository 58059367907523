import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';

import useStyles from './useStyles';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  children: PropTypes.node,
};

const defaultProps = {
  className: null,
  classes: null,
  children: null,
};

const Panel = ({ className, classes, children, ...restProps }) => {
  const styles = useStyles({ classes });

  return (
    <Paper className={cx(styles.root, className)} elevation={1} {...restProps}>
      {children}
    </Paper>
  );
};

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;

export default Panel;
