import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CSV_FILE } from './schema';

const MUTATION = gql`
  mutation UploadCsv($csv: Upload!, $customerId: ID!) {
    uploadCsv(csv: $csv, customerId: $customerId) {
      ...CSVFile
    }
  }
  ${FRAGMENT_CSV_FILE}
`;

export const uploadCsvFile = async ({ csv, customerId }) => {
  const variables = {
    csv,
    customerId,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });

  return getSingleProp(response.data);
};
