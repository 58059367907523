import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => {
    return {
      root: {
        zIndex: (props) => props.zIndex || theme.zIndex.onboarding,
      },
      backdrop: {
        zIndex: (props) => props.zIndex || theme.zIndex.onboarding + 1,
      },
      beacon: {
        zIndex: (props) => props.zIndex || theme.zIndex.onboarding + 2,
      },
      tooltip: {
        zIndex: (props) => props.zIndex || theme.zIndex.onboarding + 3,
      },
    };
  },
  { name: 'Runner' }
);
