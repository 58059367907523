import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    feature: {
      '& + &': {
        marginTop: theme.spacing(1.5),
      },
    },
  }),
  { name: 'SubscriptionPlanFeatures' }
);
