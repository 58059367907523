import { useCallback } from 'react';

import {
  useCustomFeatureLimitsData,
  useCustomerActions,
  useNotificationActions,
  usePerformanceActions,
} from 'src/store';
import { useCanCustomerCreateResource } from 'src/services';
import { getCreatedResourceMessage } from 'src/data/performance';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { useUpgradePopup } from './popup';

export const useExtractResource = ({ actionsOrigin, originObject }) => {
  const customerActions = useCustomerActions();
  const performanceActions = usePerformanceActions();
  const notificationActions = useNotificationActions();
  const limitData = useCustomFeatureLimitsData(FEATURE_LIMITS.resourcesPerCustomer);
  const availableResources = limitData?.limit - limitData?.currentCount;
  const openUpgradePopup = useUpgradePopup({ actionsOrigin, originObject });
  const canCustomerCreateResource = useCanCustomerCreateResource();

  return useCallback(
    async ({ name, url, tags, file, contentType }) => {
      performanceActions.setIsCreatingResource(true);
      if ((url || file) && !(await canCustomerCreateResource([url], file))) {
        performanceActions.setIsCreatingResource(false);
        openUpgradePopup(FEATURE_LIMITS.resourcesPerCustomer);
        return;
      }
      const createdResource = await performanceActions.extractResource({
        name,
        url,
        tags,
        file,
        contentType,
      });
      customerActions.refreshCurrentCustomerFeatureLimit();
      performanceActions.queryWorkspaceResources();
      if (createdResource) {
        const message = getCreatedResourceMessage(createdResource, availableResources);
        notificationActions.displaySuccess(message, null, { timeout: 5000 });
      }
      return createdResource;
    },
    [
      canCustomerCreateResource,
      openUpgradePopup,
      customerActions,
      performanceActions,
      notificationActions,
      availableResources,
    ]
  );
};
