import React from 'react';

import { SuspenseFallbackPopup } from 'src/components/SuspenseFallbackPopup';
import ErrorBoundary from 'src/services/ErrorBoundary';
import { lazyWithRetry } from 'src/utils/lazy';

const AsyncView = lazyWithRetry(() => import(/* webpackChunkName: "SharePopup" */ './Container'));

export default (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<SuspenseFallbackPopup />}>
      <AsyncView {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
