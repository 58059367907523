import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      letterSpacing: 'normal',
      whiteSpace: 'pre-wrap',
    },
    optional: {
      color: COLORS.gray.gray40,
      fontWeight: theme.typography.fontWeightRegular,
    },
    hint: {
      marginLeft: theme.spacing(0.5),
      color: COLORS.neutral.mediumGray,
      fontSize: theme.typography.pxToRem(14),
    },
  }),
  { name: 'FieldLabel' }
);
