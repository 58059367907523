import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconContentImprover = memo(
  forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
      <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
      <path
        d="M19.7181 9.00085C19.8891 8.66395 20.3703 8.66395 20.5412 9.00085L23.7842 15.3911C23.94 15.6981 23.7169 16.0615 23.3726 16.0615H16.8867C16.5424 16.0615 16.3193 15.6981 16.4751 15.3911L19.7181 9.00085Z"
        stroke="white"
        strokeWidth="1.07692"
        strokeLinejoin="round"
      />
      <path
        d="M20.125 16.5996L20.125 22.9996"
        stroke="white"
        strokeWidth="1.07692"
        strokeLinecap="round"
      />
      <path
        d="M15.5273 11H7.00162"
        stroke="white"
        strokeWidth="1.07692"
        strokeLinecap="round"
      />
      <path
        d="M12.6836 16H6.99939"
        stroke="white"
        strokeWidth="1.07692"
        strokeLinecap="round"
      />
      <path
        d="M15.5273 21H7.00162"
        stroke="white"
        strokeWidth="1.07692"
        strokeLinecap="round"
      />
    </SvgIcon>
  ))
);
