import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: theme.shape.borderLightGray,
      boxShadow: '0px 7px 10px rgba(55, 73, 107, 0.0818127)',
    },
    tooltipText: {},
    ctaText: {
      color: theme.palette.secondary.main,
    },
    extraPadding: {},
    tooltipBottom: {
      marginTop: theme.spacing(3.5),
    },
  }),
  { name: 'BlockedScoreTooltip' }
);
