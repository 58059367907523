import { useCallback, useState } from 'react';

export const useReferenceElement = () => {
  const [referenceElement, setReferenceElement] = useState(null);

  const clearReferenceElement = useCallback(() => {
    setReferenceElement(null);
  }, []);

  return {
    referenceElement,
    setReferenceElement,
    clearReferenceElement,
  };
};
