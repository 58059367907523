import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconSentenceExpander = memo(
  forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
      <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
      <path
        d="M23.5 7C23.5 6.72386 23.2761 6.5 23 6.5L18.5 6.5C18.2239 6.5 18 6.72386 18 7C18 7.27614 18.2239 7.5 18.5 7.5L22.5 7.5V11.5C22.5 11.7761 22.7239 12 23 12C23.2761 12 23.5 11.7761 23.5 11.5V7ZM6.5 23C6.5 23.2761 6.72386 23.5 7 23.5H11.5C11.7761 23.5 12 23.2761 12 23C12 22.7239 11.7761 22.5 11.5 22.5H7.5L7.5 18.5C7.5 18.2239 7.27614 18 7 18C6.72386 18 6.5 18.2239 6.5 18.5L6.5 23ZM22.6464 6.64645L6.64645 22.6464L7.35355 23.3536L23.3536 7.35355L22.6464 6.64645Z"
        fill="white"
      />
    </SvgIcon>
  ))
);
