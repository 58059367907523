import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';

import IconSettings from 'src/assets/icons/Settings';
import { usePopoverAnchor } from 'src/lib/hooks';
import { useStyles } from './useStyles';

export const ActionsMenu = ({ disabled, onRemoveClick }) => {
  const styles = useStyles();
  const [menuAnchor, openMenu, closeMenu] = usePopoverAnchor();

  return (
    <div>
      <IconButton
        onClick={(e) => {
          if (!menuAnchor) {
            openMenu(e.currentTarget);
          } else {
            closeMenu();
          }
        }}
        className={styles.iconButton}
      >
        <IconSettings data-testid="adAccountsSelectionPopup_settingsIcon" />
      </IconButton>

      <Menu
        classes={{ paper: styles.paper }}
        className={cx({ [styles.activeAction]: !disabled })}
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-testid="adAccountsSelectionPopup_actionsMenu"
      >
        <MenuItem
          onClick={() => {
            if (!disabled) {
              closeMenu();
              onRemoveClick?.();
            }
          }}
          disabled={disabled}
          data-testid="adAccountsSelectionPopup_removeIntegrationItem"
        >
          <Typography className={styles.removeIntegrationLabel}>Remove integration</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

ActionsMenu.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onRemoveClick: PropTypes.func,
};
ActionsMenu.defaultProps = {
  className: null,
  disabled: false,
  onRemoveClick: null,
};
