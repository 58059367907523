import { useCallback } from 'react';

import * as events from 'src/lib/events';
import { useSystemActions } from 'src/store';
import { useTrackingEvent } from './useTrackingEvent';

export const useTrackInfinityEvent = () => {
  const trackEvent = useTrackingEvent();
  const systemActions = useSystemActions();

  return useCallback(
    (name, attributes = null, options = null) => {
      const mergedAttributes = {
        // system attributes
        installationId: systemActions.getExtensionInstallationId(),
        page: events.EVENT_PAGE.infinity,
        // event attributes
        ...attributes,
      };

      trackEvent(name, mergedAttributes, options);
    },
    [trackEvent, systemActions]
  );
};
