import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2.25, 6.25, 0),
    },
    steps: {
      textAlign: 'center',
      color: COLORS.neutral.mediumGray,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
    title: {
      marginTop: theme.spacing(0.5),
      display: 'block',
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  { name: 'PopupHeader' }
);
