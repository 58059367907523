import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconProductReview = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 30 30"
      data-fill-none
    >
      <rect
        x="3"
        y="3"
        width="24"
        height="24"
        rx="4"
        fill="#28354D"
      />
      <path
        d="M15.9272 8.96875H8V22.9579C8 22.9579 15.4724 22.9579 21.212 22.9579V18.5539"
        stroke="white"
        strokeWidth="0.897436"
      />
      <path
        d="M13 17.6328H9.47327"
        stroke="white"
        strokeWidth="0.897436"
        strokeLinejoin="round"
      />
      <path
        d="M16 19.4297H9.24043"
        stroke="white"
        strokeWidth="0.897436"
        strokeLinejoin="round"
      />
      <path
        d="M16 21.2266H9.24043"
        stroke="white"
        strokeWidth="0.897436"
        strokeLinejoin="round"
      />
      <path
        d="M18.625 16H16.3985C16.2928 16 16.1914 15.958 16.1167 15.8833C16.042 15.8086 16 15.7072 16 15.6015V13.375C15.9998 13.3233 16.0098 13.272 16.0295 13.2241C16.0492 13.1763 16.0781 13.1328 16.1146 13.0961L22.0918 7.11888C22.1288 7.08123 22.173 7.05133 22.2218 7.03092C22.2705 7.01051 22.3228 7 22.3757 7C22.4285 7 22.4808 7.01051 22.5296 7.03092C22.5783 7.05133 22.6225 7.08123 22.6596 7.11888L24.8811 9.3404C24.9188 9.37748 24.9487 9.42168 24.9691 9.47042C24.9895 9.51916 25 9.57148 25 9.62432C25 9.67716 24.9895 9.72948 24.9691 9.77822C24.9487 9.82696 24.9188 9.87116 24.8811 9.90824L18.9039 15.8854C18.8672 15.9219 18.8237 15.9508 18.7759 15.9705C18.728 15.9902 18.6767 16.0002 18.625 16V16Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9102 8.63281L23.3647 11.0874"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
