export const SCORE_SIZE = {
  small: 'small',
  tableSmall: 'table_small',
  medium: 'medium',
  large: 'large',
  extraLarge: 'extra_large',
  xxl: 'xxl',
  xxxl: 'xxxl',
  xxxxl: 'xxxxl',
};

export const LEVEL = {
  low: 'low',
  medium: 'medium',
  high: 'high',
};
