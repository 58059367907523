import gql from 'graphql-tag';

import { FRAGMENT_INTEGRATION_ACCOUNT } from 'src/graphql/performance';
import { FRAGMENT_TALKING_POINTS_VIEW } from 'src/graphql/talkingPointsViews';

export const WORKSPACE_MEMBER_FRAGMENT = gql`
  fragment WorkspaceMember on CustomerMember {
    id
    email
    name
    role
  }
`;

export const WORKSPACE_FRAGMENT = gql`
  fragment Workspace on Workspace {
    id
    name
    creator {
      id
      name
      email
    }
    workspaceMembers {
      ...WorkspaceMember
    }
    workspaceMembersCount
    accessType
    defaultForUserIds
    defaultTovAnalyzerForTool {
      generationTool
      customTovId
      customTovName
    }
  }
  ${WORKSPACE_MEMBER_FRAGMENT}
`;

export const FRAGMENT_WORKSPACE_ASSOCIATED_TALKING_POINTS_VIEWS = gql`
  fragment WorkspaceAssociatedTalkingPointsViews on Workspace {
    associatedTalkingPointsViews {
      ...TalkingPointsView
    }
  }
  ${FRAGMENT_TALKING_POINTS_VIEW}
`;

export const WORKSPACE_ASSOCIATIONS_FRAGMENT = gql`
  fragment WorkspaceAssociations on Workspace {
    associatedBrandVoices {
      id
    }
    associatedAccounts {
      ...PerformanceCenterIntegrationAccount
    }
    associatedFreestyleTools {
      id
    }
    associatedMultiPromptTemplates {
      id
    }
    associatedResources {
      id
    }
    associatedTalkingPointsViews {
      id
    }
    associatedProjects {
      id
    }
    defaultBrandVoice {
      id
    }
  }
  ${FRAGMENT_INTEGRATION_ACCOUNT}
`;
