import { useCallback } from 'react';

import { usePopups, EXTRACTION_LOADER_POPUP } from 'src/services/popup';

export const useAccountExtractionLoaderPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({
      account,
      customModelAccount,
      isTrainingModel,
      isTalkingPointsView,
      onlyExtractingTalkingPoints,
    }) => {
      popups.openPopup(EXTRACTION_LOADER_POPUP, {
        account,
        customModelAccount,
        isTrainingModel,
        isTalkingPointsView,
        onlyExtractingTalkingPoints,
        onClose: () => {
          popups.closePopup(EXTRACTION_LOADER_POPUP);
        },
      });
    },
    [popups]
  );
};
