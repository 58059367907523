import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_PERFORMANCE_CENTER_INTEGRATION_REQUEST_ONLY_STATUS } from './schema';

const QUERY = gql`
  query PerformanceCenterRequestStatus(
    $accountId: ID!
    $customerId: ID!
    $dataSource: PerformanceCenterDataSource!
  ) {
    performanceCenterRequest(
      accountId: $accountId
      customerId: $customerId
      dataSource: $dataSource
    ) {
      ...PerformanceCenterIntegrationRequestStatus
    }
  }
  ${FRAGMENT_PERFORMANCE_CENTER_INTEGRATION_REQUEST_ONLY_STATUS}
`;

export const queryPerformanceCenterRequestStatus = ({ customerId, accountId, dataSource }) => {
  return executeGqlQuery({
    query: QUERY,
    variables: { customerId, accountId, dataSource },
  }).then(({ data }) => getSingleProp(data));
};
