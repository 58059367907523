import { createContainer } from 'react-sweet-state';

import { CustomerMemberStore, ACTIONS } from './CustomerMemberStore';

export const CustomerMemberStoreContainer = createContainer(
  CustomerMemberStore,
  {
    onInit: () => ({ dispatch }, { currentCustomerId }) => {
      if (!currentCustomerId) return;
      dispatch(ACTIONS.init());
    },
    onUpdate: () => ({ dispatch }, { currentCustomerId }) => {
      if (!currentCustomerId) return;

      dispatch(ACTIONS.init());
    },
  }
);
