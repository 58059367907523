import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query HubspotAuthorizationLink {
    hubspotAuthorizationLink {
      url
    }
  }
`;

export const queryHubspotAuthorizationLink = () => {
  return executeGqlQuery({
    query: QUERY,
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => result?.url);
};
