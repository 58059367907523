import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation MarkNotificationRead($notificationId: ID!) {
    markNotificationRead(notificationId: $notificationId) {
      id
    }
  }
`;

export const markNotificationRead = (id) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: {
      notificationId: id,
    },
  }).then(({ data }) => getSingleProp(data));
