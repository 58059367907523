import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconExclamationCircled = React.memo(
  React.forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 16 16"
      data-fill-none
    >
      <path
        d="M8.00091 0.00149868C5.85948 0.0349114 3.81702 0.908882 2.31425 2.43483C1.56625 3.18273 0.976057 4.07322 0.578679 5.05349C0.181301 6.03377 -0.0151714 7.08386 0.000913535 8.1415C3.51369e-05 9.1745 0.202962 10.1975 0.598069 11.152C0.993177 12.1064 1.5727 12.9735 2.30345 13.7037C3.0342 14.4338 3.90182 15.0126 4.8566 15.4069C5.81139 15.8012 6.83458 16.0033 7.86758 16.0015H8.00091C10.141 15.9821 12.1857 15.1138 13.6859 13.5875C15.186 12.0611 16.0187 10.0016 16.0009 7.8615C16.0029 6.81691 15.7963 5.78244 15.3932 4.81875C14.9901 3.85507 14.3986 2.98157 13.6535 2.24948C12.9084 1.51739 12.0246 0.941445 11.0539 0.555426C10.0833 0.169408 9.04531 -0.0189158 8.00091 0.00149868ZM7.00091 11.0282C6.99546 10.8941 7.0174 10.7604 7.06538 10.6351C7.11336 10.5099 7.18637 10.3957 7.27997 10.2996C7.37356 10.2035 7.48577 10.1275 7.60972 10.0763C7.73368 10.025 7.86679 9.99958 8.00091 10.0015C8.26349 10.003 8.51537 10.1057 8.70412 10.2883C8.89287 10.4708 9.00395 10.7191 9.01425 10.9815C9.01964 11.1135 8.99828 11.2453 8.95145 11.3688C8.90462 11.4924 8.83329 11.6052 8.74175 11.7005C8.6502 11.7957 8.54034 11.8715 8.41875 11.9233C8.29717 11.975 8.16638 12.0016 8.03425 12.0015C7.76932 12.0052 7.51334 11.9057 7.32048 11.7241C7.12762 11.5424 7.01301 11.2928 7.00091 11.0282ZM7.33425 8.3615V4.3615C7.33425 4.18469 7.40449 4.01512 7.52951 3.89009C7.65453 3.76507 7.8241 3.69483 8.00091 3.69483C8.17772 3.69483 8.34729 3.76507 8.47232 3.89009C8.59734 4.01512 8.66758 4.18469 8.66758 4.3615V8.3615C8.66758 8.53831 8.59734 8.70788 8.47232 8.8329C8.34729 8.95793 8.17772 9.02817 8.00091 9.02817C7.8241 9.02817 7.65453 8.95793 7.52951 8.8329C7.40449 8.70788 7.33425 8.53831 7.33425 8.3615Z"
        fill="currentColor"
      />
    </SvgIcon>
  ))
);
