import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IntegrationsResourcesIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
        data-fill-none
      >
        <path
          d="M3.87044 3.17163H9.78211C9.78211 3.17163 10.6154 3.17163 10.6154 4.00496V9.91663C10.6154 9.91663 10.6154 10.75 9.78211 10.75H3.87044C3.87044 10.75 3.03711 10.75 3.03711 9.91663V4.00496C3.03711 4.00496 3.03711 3.17163 3.87044 3.17163Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M14.2132 3.17163H20.1249C20.1249 3.17163 20.9582 3.17163 20.9582 4.00496V9.91663C20.9582 9.91663 20.9582 10.75 20.1249 10.75H14.2132C14.2132 10.75 13.3799 10.75 13.3799 9.91663V4.00496C13.3799 4.00496 13.3799 3.17163 14.2132 3.17163Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M3.86995 13.4192H9.78162C9.78162 13.4192 10.615 13.4192 10.615 14.2525V20.1642C10.615 20.1642 10.615 20.9975 9.78162 20.9975H3.86995C3.86995 20.9975 3.03662 20.9975 3.03662 20.1642V14.2525C3.03662 14.2525 3.03662 13.4192 3.86995 13.4192Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          d="M14.2181 13.4192H20.1298C20.1298 13.4192 20.9631 13.4192 20.9631 14.2525V20.1642C20.9631 20.1642 20.9631 20.9975 20.1298 20.9975H14.2181C14.2181 20.9975 13.3848 20.9975 13.3848 20.1642V14.2525C13.3848 14.2525 13.3848 13.4192 14.2181 13.4192Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
