export const STONLY_ID = {
  // IDs used across the app
  readabilityScore: 'StonlyReadabilityScore',
  toneOfVoice: 'StonlySelectorToneOfVoice',
  multiPromptBuilder: 'StonlyMultiPromptBuilder',
  workspaceButton: 'StonlyWorkspaceButton',
  templatesSidebarItem: 'StonlyTemplatesSidebarItem',
  brandVoicesSidebarItem: 'StonlyBrandVoicesSidebarItem',
  templateBuilderSidebarItem: 'StonlyTemplateBuilderSidebarItem',
  createDocumentButton: 'StonlyCreateDocumentButton',
  researchTab: 'StonlyResearchTab',
  favoriteTalkingPoint: 'StonlySelectorFavoriteTalkingPoint',
  brief: 'StonlySelectorBrief',
  targetAudience: 'StonlySelectorTargetAudience',
  prompt: 'StonlySelectorPrompt',
  talkingPoints: 'StonlySelectorTalkingPoints',

  // IDs currently unused, but might be present on Stonly dashboard
  predictiveScore: 'StonlySelectorPredictiveScore',
  urlReference: 'StonlySelectorURLReference',
  aiInstructions: 'StonlySelectorAIInstructions',
  blogWizard: 'StonlySelectorBlogWizard',
  formulas: 'StonlySelectorFormulas',
  suggestions: 'StonlySelectorSuggestions',
  improve: 'StonlySelectorImprove',
  documentsSidebarItem: 'StonlyDocumentsSidebarItem',
  savedPromptsSidebarItem: 'StonlySavedPromptsSidebarItem',
};

export const openStonlyBrandTermsGuide = () => {
  window.StonlyWidget?.('openGuide', { guideId: '3J263oA3xA' });
};
