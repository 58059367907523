import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { IconPlus } from 'src/assets/icons/Plus';
import { AudiencesRadarChart } from 'src/components/AudiencesRadarChart';
import { CustomButton } from 'src/components/CustomButton';
import { CustomTypography } from 'src/components/CustomTypography';
import { AUDIENCE_CHART_ITEMS_COUNT } from 'src/data/demographics';
import { InsightBox } from '../InsightBox';
import { useDebouncedLoading } from './useDebouncedLoading';
import { useStyles } from './useStyles';

export const ChartAudience = ({
  className,
  classes,
  data,
  loading,
  disabled,
  empty,
  onAddPersona,
  onSavePersona,
  onReplacePersona,
  onDeletePersona,
  onEditPersona,
  brandVoices,
}) => {
  const styles = useStyles({ classes });

  const debouncedLoading = useDebouncedLoading(loading);

  const topPersona = data?.[0]?.persona;
  const title = empty
    ? 'Doesn’t resonate with your audiences'
    : topPersona
    ? `Resonates most with ${topPersona.name}`
    : 'Find out how this text resonates with your audiences';

  const hasPersona = data?.length > 0;
  const hasEmptyPersonas = data?.length < AUDIENCE_CHART_ITEMS_COUNT;
  const canAddPersona = hasEmptyPersonas && !loading && !debouncedLoading;

  return (
    <InsightBox className={cx(styles.root, className)}>
      <div className={styles.header}>
        <CustomTypography
          className={styles.title}
          variant="medium12"
        >
          {title}
        </CustomTypography>
      </div>

      <AudiencesRadarChart
        className={styles.chart}
        audiencesData={!empty ? data : []}
        loading={debouncedLoading}
        disabled={disabled}
        onSavePersona={onSavePersona}
        onReplacePersona={onReplacePersona}
        onDeletePersona={onDeletePersona}
        onAddPersona={onAddPersona}
        onEditPersona={onEditPersona}
        brandVoices={brandVoices}
      />

      {onAddPersona && canAddPersona && !hasPersona && (
        <div className={styles.wrapperAddCenter}>
          <CustomButton
            className={styles.buttonAddCenter}
            onClick={onAddPersona}
            startIcon={<IconPlus />}
            disabled={disabled}
            variant="contained"
            color="primary"
          >
            Add target audience
          </CustomButton>
        </div>
      )}
    </InsightBox>
  );
};

ChartAudience.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      persona: PropTypes.shape({}),
    })
  ),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  empty: PropTypes.bool,
  onAddPersona: PropTypes.func,
  onSavePersona: PropTypes.func,
  onReplacePersona: PropTypes.func,
  onDeletePersona: PropTypes.func,
  onEditPersona: PropTypes.func,
  brandVoices: PropTypes.arrayOf(PropTypes.shape({})),
};

ChartAudience.defaultProps = {
  className: null,
  classes: null,
  data: [],
  loading: false,
  disabled: false,
  empty: false,
  onAddPersona: null,
  onSavePersona: null,
  onReplacePersona: null,
  onDeletePersona: null,
  onEditPersona: null,
  brandVoices: [],
};
