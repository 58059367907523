import Cookies from 'js-cookie';

import * as storage from 'src/lib/storage';
import { randomString } from 'src/utils/random';

const COOKIE_MUNCHKIN = '_mkto_trk';
const ANONYMOUS_SESSION_ID_STORAGE_KEY = 'postMaloneAnonymousSessionId';

let _sessionId;
export const getAnonymousSessionId = () => {
  if (_sessionId) {
    return _sessionId;
  }

  const sessionIdFromStorage = storage.readItem(ANONYMOUS_SESSION_ID_STORAGE_KEY);
  if (sessionIdFromStorage) {
    return sessionIdFromStorage;
  }

  // Read from marketo cookie or generate random one
  _sessionId = Cookies.get(COOKIE_MUNCHKIN) || randomString(10);

  // We want to store anonymousSessionId to local storage, so it can be used for Conversion tracking
  storage.writeItem(ANONYMOUS_SESSION_ID_STORAGE_KEY, _sessionId);

  return _sessionId;
};

let applicationSessionId;
export const getApplicationSessionId = () => {
  if (!applicationSessionId) {
    applicationSessionId = window.crypto.randomUUID ? window.crypto.randomUUID() : randomString(32);
  }

  return applicationSessionId;
};
