export const KEYWORDS_DESCRIPRTION = `Specify keywords you would like Anyword to include in the copy it generates for your audience segment.\n\nFor instance, if you set up an audience segment that corresponds to a search ad campaign with the keyword "writing blog posts" you should add this keyword as input for generating copy for this audience segment.  Don't worry, you can always change these later.`;
export const FIRST_STEP_DESCRIPTION =
  'An audience segment will view its own specific set of copy variations when coming to the website. Anyword will optimize copy for each audience segment separately.\n\nYou define an audience segment by specifying its UTM or referrer source.';

export const STEPS = {
  first: 'first_step',
  keywords: 'keywords_step',
};

export const KEYWORDS_HINT = `An audience segment will view its own specific set of copy variations when coming to the website. Anyword will optimize copy for each audience segment separately.\nYou define an audience segment by specifying its UTM or referrer source.`;

export const MAX_KEYWORDS_PER_SEGMENT = 30;

export const MAX_KEYWORDS_PER_SEGMENT_ERROR_MSG = `Only ${MAX_KEYWORDS_PER_SEGMENT} keywords are supported`;
