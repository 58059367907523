import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_BRAND_VOICE } from './schema';

const MUTATION = gql`
  mutation UpdateBrandVoice($id: ID!, $name: String!) {
    updateBrandVoice(id: $id, name: $name) {
      ...BrandVoice
    }
  }
  ${FRAGMENT_BRAND_VOICE}
`;

export const updateBrandVoice = ({ id, name }) => {
  const variables = {
    id,
    name,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
