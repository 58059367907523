import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CircularProgress } from '@material-ui/core';

import { TINYMCE_API_KEY } from 'src/lib/tinymce';
import { useStyles } from './useStyles';
import { useTinymce } from './useTinymce';

const EDITOR_OPTIONS = {
  branding: false,
  height: 160,
  menubar: false,
  forced_root_block: '', // Remove default <p> tag
  plugins: [
    'advlist autolink lists charmap anchor',
    'searchreplace fullscreen',
    'insertdatetime code help',
  ],
  toolbar: 'formatselect | bold italic backcolor | removeformat | code',
  content_style: 'body { font-family: Helvetica,Arial,sans-serif; font-size:14px }',
};

export const RichTextEditor = ({ className, value, disabled, onChange, onEdit, ...restProps }) => {
  const styles = useStyles();

  const [editor, setEditor] = useState(null);
  const initHandler = useCallback((event, initEditor) => {
    setEditor(initEditor);
  }, []);

  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.setMode(disabled ? 'readonly' : 'design');
  }, [editor, disabled]);

  const { loading } = useTinymce();

  const overrideEditorPaste = (e) => {
    // Prevent default paste behavior
    e.preventDefault();
    // Check for clipboard data in various places for cross-browser compatibility.
    // Get that data as text.
    const content = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
    const isHTMLContent = /<\/?[a-z][\s\S]*>/i.test(content);
    // Let TinyMCE do the heavy lifting for inserting that content into the editor.
    editor.execCommand('mceInsertContent', false, (!isHTMLContent && content) || '');
  };

  return (
    <div
      className={cx(styles.root, className)}
      data-testid="RichTextEditor"
      {...restProps}
    >
      {loading ? (
        <CircularProgress
          className={styles.loader}
          size={16}
        />
      ) : (
        <div className={styles.wrapper}>
          <Editor
            apiKey={TINYMCE_API_KEY}
            onInit={initHandler}
            value={value}
            init={EDITOR_OPTIONS}
            onEditorChange={onChange}
            onPaste={overrideEditorPaste}
            disabled={disabled}
            data-testid="RichTextEditor__Editor"
            onClick={onEdit}
          />
        </div>
      )}
    </div>
  );
};

RichTextEditor.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
};

RichTextEditor.defaultProps = {
  className: null,
  disabled: false,
  onChange: () => null,
  onEdit: null,
};
