import { CHANNEL } from 'src/data/channel';

export const FILTER_ASSET_TYPE = {
  allAssets: 'all_assets',
  title: 'title',
  text: 'text',
};

const FILTER_ASSET_TYPE_PROPS = {
  [FILTER_ASSET_TYPE.allAssets]: {
    label: {
      [CHANNEL.google]: 'All asset types',
      [CHANNEL.facebook]: 'All asset types',
      [CHANNEL.linkedin]: 'All asset types',
      [CHANNEL.hubspot]: 'All asset types',
      [CHANNEL.salesforce]: 'All asset types',
      [CHANNEL.marketo]: 'All asset types',
      [CHANNEL.braze]: 'All asset types',
      default: 'All asset types',
    },
  },
  [FILTER_ASSET_TYPE.title]: {
    label: {
      [CHANNEL.google]: 'Headline',
      [CHANNEL.facebook]: 'Headline',
      [CHANNEL.linkedin]: 'Headline',
      [CHANNEL.hubspot]: 'Subject',
      [CHANNEL.salesforce]: 'Subject',
      [CHANNEL.marketo]: 'Subject',
      [CHANNEL.braze]: 'Subject',
      default: 'Headline',
    },
  },
  [FILTER_ASSET_TYPE.text]: {
    label: {
      [CHANNEL.google]: 'Description',
      [CHANNEL.facebook]: 'Primary text',
      [CHANNEL.linkedin]: 'Copy',
      [CHANNEL.hubspot]: 'Body',
      [CHANNEL.salesforce]: 'Body',
      [CHANNEL.marketo]: 'Body',
      [CHANNEL.braze]: 'Body',
      default: 'Description',
    },
  },
};

export const getFilterAssetType = (filterAssetType, channel) => {
  const labels = FILTER_ASSET_TYPE_PROPS?.[filterAssetType]?.label;
  return labels?.[channel || 'default'] || '';
};
