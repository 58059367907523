import { useCallback } from 'react';

import * as events from 'src/lib/events';
import { useTrackingEvent } from 'src/services/useTrackingEvent';

export const useSidebarEvents = () => {
  const trackingEvent = useTrackingEvent();

  const trackNavigationItemClicked = useCallback(
    ({ label, ...restProps }) => {
      trackingEvent(events.SYSTEM.navigationItemClicked, {
        label,
        ...restProps,
      });
    },
    [trackingEvent]
  );

  return { trackNavigationItemClicked };
};
