import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BackArrowIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 25 24" data-fill-none>
    <path
      d="M8.6875 7.99998L5.3946 11.2929C5.00408 11.6834 5.00408 12.3166 5.3946 12.7071L8.6875 16M5.6875 12L19.6875 12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </SvgIcon>
);
