import { useCallback } from 'react';

import { usePopups, TONE_OF_VOICE_POPUP } from 'src/services/popup';
import { useTrackingEvent } from 'src/services/useTrackingEvent';
import * as events from 'src/lib/events';

export const useToneOfVoicePopup = () => {
  const popups = usePopups();
  const trackEvent = useTrackingEvent();

  return useCallback(
    ({
      brandVoice,
      toneOfVoice,
      onCreateCustomModel,
      page,
      shouldActiveDraftMode,
      shouldActivateManualStep,
      shouldActivateLastStep,
      shouldOnlyTrain,
    }) => {
      return new Promise((resolve) => {
        trackEvent(events.TONE_OF_VOICE.toneOfVoicePopupShown, {
          page,
        });
        popups.openPopup(TONE_OF_VOICE_POPUP, {
          brandVoice,
          toneOfVoice,
          onCreateCustomModel,
          page,
          shouldActiveDraftMode,
          shouldActivateManualStep,
          shouldActivateLastStep,
          shouldOnlyTrain,
          onClose: (result) => {
            popups.closePopup(TONE_OF_VOICE_POPUP);
            resolve(result);
          },
        });
      });
    },
    [popups, trackEvent]
  );
};
