import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ArrowRight } from 'src/assets/icons/ArrowRight';
import { TermText } from '../TermText';
import { useStyles } from './useStyles';

export const ReplacementTerms = ({ className, detectedBrandTerm }) => {
  const styles = useStyles();

  const oldText = detectedBrandTerm.termInText;
  const newText = detectedBrandTerm.replacement;

  return (
    <div className={cx(styles.root, className)}>
      <TermText
        className={styles.termText}
        text={oldText}
        isOriginal
      />

      <ArrowRight className={styles.iconArrow} />

      <TermText
        className={styles.termText}
        text={newText}
        isSuggestion
      />
    </div>
  );
};

ReplacementTerms.propTypes = {
  className: PropTypes.string,
  detectedBrandTerm: PropTypes.shape().isRequired,
};

ReplacementTerms.defaultProps = {
  className: '',
};
