import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconAlignRight = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.6875C8 9.27329 8.33579 8.9375 8.75 8.9375H12.4406C12.8548 8.9375 13.1906 9.27329 13.1906 9.6875C13.1906 10.1017 12.8548 10.4375 12.4406 10.4375H8.75C8.33579 10.4375 8 10.1017 8 9.6875Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6.19922C4 5.78501 4.33579 5.44922 4.75 5.44922H12.2322C12.6464 5.44922 12.9822 5.78501 12.9822 6.19922C12.9822 6.61343 12.6464 6.94922 12.2322 6.94922H4.75C4.33579 6.94922 4 6.61343 4 6.19922Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.71094C0 2.29672 0.335786 1.96094 0.75 1.96094H12.125C12.5392 1.96094 12.875 2.29672 12.875 2.71094C12.875 3.12515 12.5392 3.46094 12.125 3.46094H0.75C0.335786 3.46094 0 3.12515 0 2.71094Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconAlignRight.displayName = 'IconAlignRight';
