import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconAmazon = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7173 8.54111C10.7173 9.24654 10.7339 9.83288 10.3789 10.4599C10.0915 10.97 9.63396 11.2832 9.12722 11.2832C8.43209 11.2832 8.0267 10.7542 8.0267 9.97087C8.0267 8.42946 9.4095 8.14889 10.7179 8.14889L10.7173 8.54111ZM12.5416 12.9529C12.4219 13.0611 12.2484 13.068 12.115 12.9953C11.5126 12.496 11.4061 12.2647 11.0763 11.7894C10.0834 12.8012 9.3803 13.1047 8.09369 13.1047C6.5706 13.1047 5.38477 12.165 5.38477 10.2847C5.38477 8.81596 6.18124 7.81736 7.3144 7.32779C8.29639 6.89606 9.66774 6.81761 10.7167 6.70081V6.46604C10.7167 6.03488 10.7511 5.52585 10.4974 5.15252C10.277 4.82099 9.85383 4.683 9.48108 4.683C8.79111 4.683 8.17729 5.03686 8.0267 5.7692C7.99578 5.93239 7.87725 6.09386 7.71235 6.10245L5.95965 5.91178C5.81135 5.87971 5.64587 5.75947 5.68938 5.53387C6.09249 3.40612 8.0141 2.76367 9.73531 2.76367C10.6154 2.76367 11.7663 2.99843 12.4597 3.66493C13.3403 4.48717 13.2567 5.58425 13.2567 6.77868V9.59754C13.2567 10.4455 13.6095 10.8172 13.9393 11.2747C14.0561 11.439 14.0807 11.6354 13.9335 11.7551C13.5631 12.0648 12.9063 12.6351 12.5462 12.9569L12.5416 12.9529Z"
        fill="#1E1E1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.581 14.484C13.0076 15.3268 11.2972 15.7328 9.74093 15.7328C7.4351 15.7328 5.202 14.9352 3.39605 13.6108C3.23744 13.4951 3.12063 13.699 3.25233 13.849C4.92716 15.7534 7.13793 16.8986 9.59434 16.8986C11.347 16.8986 13.3815 16.2035 14.7855 14.898C15.0179 14.6821 14.8187 14.3569 14.581 14.484ZM14.9647 15.7529C14.9126 15.8828 15.0242 15.9361 15.1422 15.837C15.9089 15.1957 16.107 13.8524 15.9501 13.6589C15.7944 13.4665 14.4545 13.3004 13.6368 13.8748C13.5109 13.9629 13.5326 14.0849 13.6723 14.0677C14.1333 14.0128 15.1582 13.8891 15.3403 14.1238C15.5229 14.3586 15.137 15.3228 14.9647 15.7529Z"
        fill="#FF9900"
      />
    </SvgIcon>
  ))
);
