import { useEffect } from 'react';

import { useCustomModelsActions } from 'src/store';
import { useErrorHandler } from 'src/services';

export const usePreloadCustomModels = (brandVoice) => {
  const handleError = useErrorHandler();
  const customModelsActions = useCustomModelsActions();
  const brandVoiceId = brandVoice?.id;

  useEffect(() => {
    const fetchModels = async () => {
      try {
        await customModelsActions.queryCustomModels([brandVoiceId]);
      } catch (error) {
        handleError(error);
      }
    };

    if (brandVoiceId) {
      fetchModels();
    }

    return () => {
      customModelsActions.clearCustomModels();
    };
  }, [brandVoiceId, handleError, customModelsActions]);
};
