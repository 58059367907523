import { STEPS_WORKSPACES_DATA } from './steps.workspaces';
import { STEPS_DOCUMENT_DATA } from './steps.document';
import { STEPS_ACCOUNTS_DATA } from './steps.accounts';
import { STEPS_WTM_DATA } from './steps.wtm';
import { STEPS_CIP_DATA } from './steps.cip';

export const STEPS_DATA = [
  ...STEPS_WORKSPACES_DATA,
  ...STEPS_ACCOUNTS_DATA,
  ...STEPS_DOCUMENT_DATA,
  ...STEPS_WTM_DATA,
  ...STEPS_CIP_DATA,
];
