import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    text: {
      height: 20,
    },
  }),
  { name: 'Loader' }
);
