import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOMER_RESOURCE } from './schema';

const MUTATION = gql`
  mutation DeleteResource($id: ID!) {
    deleteResource(id: $id) {
      ...PerformanceCenterCustomerResource
    }
  }
  ${FRAGMENT_CUSTOMER_RESOURCE}
`;

export const deleteResource = (id) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
};
