import { useCallback, useMemo } from 'react';

import * as gtm from 'src/lib/gtm';
import * as events from 'src/lib/events';
import { getCustomerPlan, getCustomerTier, SUBSCRIPTION_PLAN } from 'src/data/subscription';
import { PAGES, useTrackingEvent } from 'src/services/useTrackingEvent';
import { isCommonEmail } from 'src/lib/validation/validators';
import {
  useCurrentCustomer,
  useCurrentUser,
  useIsUserAllowedToBookDemo,
  useNotificationActions,
} from 'src/store';
import { getUserCompanySize } from '../data/user';
import { useBusinessBookDemoPopup } from './popup';
import { BOOK_DEMO_ORIGIN } from 'src/data/common';

const URL = 'https://anyword.chilipiper.com/book/cip-request-a-demo-page';
const MAP_ORIGIN_TO_PAGE = {
  [BOOK_DEMO_ORIGIN.settingsPage]: PAGES.accountSettings,
  [BOOK_DEMO_ORIGIN.analyticsPage]: PAGES.copyAnalytics,
  [BOOK_DEMO_ORIGIN.wtmPage]: PAGES.wtm,
  [BOOK_DEMO_ORIGIN.editorPage]: PAGES.editor,
  [BOOK_DEMO_ORIGIN.cipPageChannel]: PAGES.resourcesIntegrations,
  [BOOK_DEMO_ORIGIN.cipPageConnectDialog]: PAGES.resourcesIntegrations,
  [BOOK_DEMO_ORIGIN.talkingPointsPage]: PAGES.talkingPoints,
};
const useEvents = (origin) => {
  const currentUser = useCurrentUser();
  const currentCustomer = useCurrentCustomer();
  const companySize = getUserCompanySize(currentUser);
  const trackingEvent = useTrackingEvent();

  const trackBookDemoRequest = useCallback(
    (email, userAllowedToBookDemo) => {
      const plan = SUBSCRIPTION_PLAN.business;

      const dimensions = { email, origin, companySize, page: MAP_ORIGIN_TO_PAGE[origin] };

      const event = userAllowedToBookDemo
        ? events.BUSINESS_DEMO.businessDemoBooked
        : events.BUSINESS_DEMO.businessDemoRequested;

      trackingEvent(event, {
        ...dimensions,
      });

      gtm.pushDataLayerEvent(gtm.EVENTS.customerUpgradeAceRequested, {
        email,
        customerId: currentCustomer.id,
        currentPlan: getCustomerPlan(currentCustomer),
        currentTier: getCustomerTier(currentCustomer),
        requestedPlan: plan,
        companySize,
        origin,
      });

      events.trackMarketoEvent(
        events.MARKETO_EVENT.clickedUpgradeButton,
        events.convertSubscriptionPlanForMarketo(plan),
        {
          CTA: events.MARKETO_SUBSCRIPTION_ACTION.bookDemo,
          origin,
        }
      );

      events.trackHubspotEvent(events.HUBSPOT_EVENT.clickedUpgradeButton, {
        cta: events.HUBSPOT_SUBSCRIPTION_ACTION.bookDemo,
        origin,
        plan: events.convertSubscriptionPlanForHubspot(plan),
        email: email,
      });
    },
    [companySize, currentCustomer, origin, trackingEvent]
  );

  return useMemo(() => ({ trackBookDemoRequest }), [trackBookDemoRequest]);
};

export const useBusinessBookDemo = (origin) => {
  const currentUser = useCurrentUser();
  const events = useEvents(origin);
  const notificationActions = useNotificationActions();
  const openBusinessBookDemoPopup = useBusinessBookDemoPopup();
  const userAllowedToBookDemo = useIsUserAllowedToBookDemo();

  const handleBusinessBookDemoClick = useCallback(() => {
    const onSubmit = (email) => {
      events.trackBookDemoRequest(email, userAllowedToBookDemo);
      if (userAllowedToBookDemo) {
        window.open(`${URL}?email=${email}`, '_blank');
      } else {
        notificationActions.displaySuccess(
          'Demo request received! Our team will contact you soon.'
        );
      }
    };

    if (!isCommonEmail(currentUser.email)) {
      onSubmit(currentUser.email);
      return;
    }

    return openBusinessBookDemoPopup(onSubmit);
  }, [events, currentUser, openBusinessBookDemoPopup, notificationActions, userAllowedToBookDemo]);

  return useMemo(
    () => ({
      handleBusinessBookDemoClick,
      userAllowedToBookDemo,
    }),
    [handleBusinessBookDemoClick, userAllowedToBookDemo]
  );
};
