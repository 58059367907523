import { useCallback } from 'react';

import { GRAPHQL_ERROR_CODES } from 'src/data/graphqlErrorCodes';
import { useGraphQLErrorHandler } from 'src/services';
import { useCustomerActions, useNotificationActions } from 'src/store';
import { useRename } from './useRename';

export const useRenameCustomer = ({ initialName, onSubmit }) => {
  const customerActions = useCustomerActions();
  const notificationActions = useNotificationActions();
  const handleGraphQLError = useGraphQLErrorHandler();

  const handleSubmit = useCallback(
    async (name) => {
      await customerActions.renameCurrentCustomer(name);
      notificationActions.displaySuccess('Name saved');

      if (onSubmit) onSubmit();
    },
    [customerActions, onSubmit, notificationActions]
  );

  const handleError = useCallback(
    (error) => {
      handleGraphQLError({
        error,
        errorCodeMappingList: [
          {
            code: GRAPHQL_ERROR_CODES.customer_name_taken,
            message: 'This company name has already been claimed by another account',
          },
        ],
      });
    },
    [handleGraphQLError]
  );

  return useRename({
    initialName,
    onSubmit: handleSubmit,
    onError: handleError,
  });
};
