import { authorizeInGoogle } from 'src/utils/lib/oauth';
import { authorizeGoogle, queryGoogleAuthorizationLink } from 'src/graphql/google';
import { ChannelAuthError } from 'src/lib/errors';

export default async function connectToGoogle() {
  // Query auth link from BE
  const authUrl = await queryGoogleAuthorizationLink();

  // Ask user to allow our app to use their data
  const authData = await authorizeInGoogle(authUrl);

  if (authData?.code) return authorizeGoogle(authData.code);

  throw new ChannelAuthError('Error getting Google authorization code');
}
