import { createStateSelectorHook } from 'src/lib/sweetState';
import { CustomModelsStore } from 'src/store/customModels/CustomModelsStore';

const useCustomModelState = createStateSelectorHook(CustomModelsStore, [
  'runningCustomModels',
  'isCustomModelsLoading',
  'customModels',
]);

export const useCustomModels = () => {
  const customModelsState = useCustomModelState();

  return {
    ...customModelsState,
  };
};
