import {
  FEATURE_LIMITS,
  getFeatureLimitsData,
  getFeatureLimitsLabel,
  getFeatureFullSubtitle,
  getFeatureFullDescription,
  getFeatureLimitedAccount,
} from 'src/data/featureLimits';
import { getCustomerPlan } from 'src/data/subscription';
import { useCustomerMaxMembers } from 'src/store/customerMember';
import { useCurrentCustomer } from './useCurrentCustomer';

export const useCustomFeatureLimitsData = (featureLimit) => {
  const currentCustomer = useCurrentCustomer();
  const plan = getCustomerPlan(currentCustomer);

  // Special case - user limits
  const { usersCount, maxMembers } = useCustomerMaxMembers();

  if (featureLimit === FEATURE_LIMITS.membersPerCustomer) {
    return { currentCount: usersCount, limit: maxMembers, label: 'Members' };
  }

  const featureLimitsData = getFeatureLimitsData(currentCustomer, featureLimit);
  const limitedAccountData = getFeatureLimitedAccount(currentCustomer);
  const label = getFeatureLimitsLabel(featureLimit);
  const fullSubtitle = getFeatureFullSubtitle({
    featureLimit,
    limit: featureLimitsData?.limit,
    label,
    plan,
  });
  const fullDescription = getFeatureFullDescription(featureLimit, plan);

  return {
    ...featureLimitsData,
    limitedAccountData,
    label,
    fullSubtitle,
    fullDescription,
    plan,
  };
};
