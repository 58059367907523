import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { validateCopyText } from 'src/graphql/generation';
import { trim } from 'lodash';

const DEFAULT_DEBOUNCE_TIMEOUT = 1000;

export const useTextSensitivityDebounced = ({ projectId, assetType, text }, hookOptions) => {
  const disableRequests = hookOptions?.disabled || false;
  const debounceTimeout = hookOptions?.debounceTimeout || DEFAULT_DEBOUNCE_TIMEOUT;

  const [sensitivity, setSensitivity] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSensitivity = useDebouncedCallback(async (requestParams, requestOptions) => {
    try {
      const result = await validateCopyText(requestParams, requestOptions);
      setSensitivity(result);
    } catch (error) {
      // we don't care much about errors here
    } finally {
      setLoading(false);
    }
  }, debounceTimeout);

  useEffect(() => {
    // Cleat and do not query, if host component doesn't want it now
    if (disableRequests) {
      setSensitivity(null);
      return;
    }

    // Return early, if no "text" to annotate was provided
    if (trim(text).length === 0) {
      setSensitivity(null);
      return;
    }

    // Mark as "loading" even when we wait for "debounced" request to fire
    setLoading(true);

    // Schedule debounced request
    const abortController = new AbortController();
    fetchSensitivity(
      { projectId, text, assetType },
      {
        abortSignal: abortController.signal,
      }
    );

    // Clean up possible pending requests when we want to schedule new request or component unmounts
    return () => {
      abortController.abort();
      setLoading(false);
    };
  }, [disableRequests, fetchSensitivity, projectId, text, assetType]);

  return useMemo(
    () => ({
      sensitivity,
      loading,
    }),
    [sensitivity, loading]
  );
};
