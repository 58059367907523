import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconHeadingTwo = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.42859 0.107422C1.8428 0.107422 2.17859 0.443208 2.17859 0.857422V4.82171H5.82145V0.857422C5.82145 0.443208 6.15723 0.107422 6.57145 0.107422C6.98566 0.107422 7.32145 0.443208 7.32145 0.857422V11.1431C7.32145 11.5573 6.98566 11.8931 6.57145 11.8931C6.15723 11.8931 5.82145 11.5573 5.82145 11.1431V6.32171H2.17859V11.1431C2.17859 11.5573 1.8428 11.8931 1.42859 11.8931C1.01438 11.8931 0.678589 11.5573 0.678589 11.1431V0.857422C0.678589 0.443208 1.01438 0.107422 1.42859 0.107422Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.27831 2.46256C8.62889 1.22289 9.74037 0.25 11 0.25C12.6663 0.25 13.75 1.8699 13.75 3.5V3.59187C13.75 4.67438 13.2923 5.74287 12.4496 6.35725M12.4495 6.35729L10.4342 7.82686C10.4342 7.82686 10.4342 7.82687 10.4342 7.82687C10.0478 8.10865 9.75 8.68469 9.75 9.38037V10.25H13C13.4142 10.25 13.75 10.5858 13.75 11C13.75 11.4142 13.4142 11.75 13 11.75H9C8.58579 11.75 8.25 11.4142 8.25 11V9.38037C8.25 8.29782 8.70778 7.22937 9.55039 6.61489L9.55041 6.61488L11.5658 5.14525L11.5659 5.14521C11.9522 4.86358 12.25 4.2876 12.25 3.59187V3.5C12.25 2.36867 11.5429 1.75 11 1.75C10.518 1.75 9.92031 2.16845 9.7217 2.87076C9.60898 3.26934 9.19448 3.50108 8.7959 3.38836C8.39732 3.27564 8.16559 2.86114 8.27831 2.46256"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconHeadingTwo.displayName = 'IconHeadingTwo';
