import { useMemo } from 'react';

const calculateTalkingPointProps = (numberOfInstances, copies) => {
  let impressions = 0;
  const textType = [];
  copies.forEach((copy) => {
    impressions += copy.lifetimeImpressions;

    if (!textType.includes(copy.textType)) {
      textType.push(copy.textType);
    }
  });
  return { impressions, numberOfInstances, textType };
};

export const usePreparedTalkingPoints = ({ talkingPoints, copies = [] }) => {
  const preparedTalkingPoints = useMemo(() => {
    if (!talkingPoints) {
      return [];
    }

    if (copies.length === 0) {
      return talkingPoints;
    }

    const mappedTalkingPoints = talkingPoints.map((talkingPoint) => {
      const talkingPointCopies = copies.filter((copy) => {
        return talkingPoint.copyTexts.includes(copy.text);
      });
      return {
        ...calculateTalkingPointProps(talkingPoint.copyTexts.length, talkingPointCopies),
        ...talkingPoint,
      };
    });

    return mappedTalkingPoints;
  }, [talkingPoints, copies]);

  return {
    preparedTalkingPoints,
  };
};
