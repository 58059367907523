import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { COMPARISON_SET_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation ExtractPerformanceCenterBenchmarks(
    $projectId: ID!
    $text: String!
    $assetType: AssetTypeEnum!
    $adAccountId: ID!
    $userGoal: String!
    $variationId: ID
    $comparisonSetName: String
  ) {
    extractPerformanceCenterBenchmarks(
      projectId: $projectId
      text: $text
      assetType: $assetType
      adAccountId: $adAccountId
      userGoal: $userGoal
      variationId: $variationId
      comparisonSetName: $comparisonSetName
    ) {
      position
      comparisonSet {
        ...ComparisonSet
      }
    }
  }
  ${COMPARISON_SET_FRAGMENT}
`;

export const extractPerformanceCenterBenchmarks = (
  { projectId, text, assetType, adAccountId, userGoal, variationId, comparisonSetName },
  options
) => {
  const variables = {
    projectId,
    text,
    assetType,
    adAccountId,
    userGoal,
    variationId,
    comparisonSetName,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
