import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    paper: {
      maxWidth: 575,
      position: 'relative',
    },
    header: {
      padding: theme.spacing(2.5),
      borderBottom: 0,
    },
    headerTitle: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(32),
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.neutral.secondary,
    },
    headerButtonClose: {
      position: 'absolute',
      top: '10px',
      right: '10px',

      '& svg': {
        fontSize: theme.typography.pxToRem(18),
      },
    },
    description: {
      marginTop: theme.spacing(3.25),
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(32),
      fontWeight: theme.typography.fontWeightMedium,
      whiteSpace: 'pre-wrap',
    },
    img: {
      width: 136,
      height: 131,
    },
    dialogContent: {
      padding: theme.spacing(4, 4, 0),
      borderRadius: theme.shape.borderRadius,
    },
    dialogActions: {
      padding: theme.spacing(3.25, 4, 6),
      margin: 0,
      justifyContent: 'space-between',
    },
    button: {
      padding: theme.spacing(1.5, 3),
    },
    input: {
      width: 359,
    },

    error: {
      position: 'absolute',
      bottom: theme.spacing(3),
      left: theme.spacing(4),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),

      '&&': {
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
  }),
  { name: 'BusinessBookDemoPopup' }
);
