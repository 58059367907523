import { useEffect } from 'react';

import { captureMessage } from 'src/lib/sentry';
import { usePerformanceActions } from 'src/store';
import { useChannelAccounts } from './useChannelAccounts';

export const useGetAccountLastExtraction = (channel, accountId) => {
  const performanceActions = usePerformanceActions();

  const { disconnectedAccounts } = useChannelAccounts();

  const isAccountDisconnected = disconnectedAccounts[channel]?.find(
    (account) => account.id === accountId
  );

  const shouldGetAccountLastExtraction = accountId && channel && !isAccountDisconnected;

  useEffect(() => {
    if (!shouldGetAccountLastExtraction) {
      return;
    }

    performanceActions.getLastExtraction(channel, accountId).catch((error) => {
      captureMessage(`Error when getting ${channel} last extraction`, {
        level: 'warning',
        extra: {
          error: error.toString(),
          source: 'useGetAccountLastExtraction',
        },
      });
    });
  }, [shouldGetAccountLastExtraction, channel, accountId, performanceActions]);
};
