import React, { forwardRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { DiamondIcon } from 'src/assets/icons/Diamond';
import { usePopoverAnchor } from 'src/lib/hooks';
import { CustomMenu } from 'src/components/CustomMenu';
import { DDUpgradePanel } from 'src/components/DDUpgradePanel';
import { ItemDefault } from '../ItemDefault';
import { useStyles } from './useStyles';

const SUBMENU_PROPS = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const ItemLocked = memo(
  forwardRef(({ className, item, onClick, disabled }, ref) => {
    const styles = useStyles();

    const [anchorEl, openMenu, closeMenu] = usePopoverAnchor(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = useCallback(
      (event) => {
        openMenu(event.currentTarget);
      },
      [openMenu]
    );

    const handleSubmenuClose = useCallback(() => {
      closeMenu();
    }, [closeMenu]);

    return (
      <>
        <ItemDefault
          className={className}
          ref={ref}
          item={item}
          onClick={handleClick}
          disabled={disabled}
          endIcon={<DiamondIcon />}
        />

        <CustomMenu
          classes={{
            paper: styles.submenu_paper,
          }}
          anchorEl={anchorEl}
          open={isOpen}
          onClose={handleSubmenuClose}
          disabled={disabled}
          {...SUBMENU_PROPS}
        >
          <DDUpgradePanel onClose={handleSubmenuClose} />
        </CustomMenu>
      </>
    );
  })
);

ItemLocked.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ItemLocked.defaultProps = {
  className: '',
  disabled: false,
};
