import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    button: {
      flexShrink: '0',
    },
    menu_paper: {
      width: '242px',
      marginTop: theme.spacing(1),
      padding: 0,
    },
  }),
  { name: 'UserProfile' }
);
