import gql from 'graphql-tag';

import { CUSTOMER_PERSONA_FRAGMENT } from './customerPersona';

export const FRAGMENT_CONTENT_VALIDATION = gql`
  fragment ContentValidation on ContentValidation {
    label
    reason
    reasonDetails
  }
`;

export const FRAGMENT_AB_TEST = gql`
  fragment AbTest on AbTest {
    name
    value
  }
`;

export const FRAGMENT_TARGET_AUDIENCE_INSIGHTS = gql`
  fragment TargetAudienceInsights on TargetAudienceInsights {
    score
    persona {
      ...CustomerPersona
    }
  }
  ${CUSTOMER_PERSONA_FRAGMENT}
`;

export const FRAGMENT_TARGET_AUDIENCE_INSIGHTS_DEMOGRAPHICS = gql`
  fragment TargetAudienceInsightsDemographics on TargetAudienceInsightsDemographics {
    audienceScores {
      ...TargetAudienceInsights
    }
    ageScoreHistogram
    genderScoreHistogram
  }
  ${FRAGMENT_TARGET_AUDIENCE_INSIGHTS}
`;
