import React, { useEffect } from 'react';
import { useHistory } from 'src/lib/hooks/useHistory';
import { Helmet } from 'react-helmet-async';

import { ROUTES, ROUTE_PARAMS } from 'src/routes';
import { useNotificationActions, useUserActions } from 'src/store';
import { useErrorHandler } from 'src/services/useErrorHandler';
import { useIsDisposableEmail } from 'src/services/useIsDisposableEmail';
import { getQueryParams } from 'src/lib/browser';
import { ONBOARDING_STAGE } from 'src/data/onboarding';
import { formatRoute } from 'src/lib/routing';
import VerifyEmailCallback from './VerifyEmailCallback';

const propTypes = {};
const defaultProps = {};

const Container = () => {
  const history = useHistory();
  const queryString = window.location.search;

  const notificationActions = useNotificationActions();
  const handleError = useErrorHandler();
  const userActions = useUserActions();
  const validateDisposableEmail = useIsDisposableEmail();

  useEffect(() => {
    const queryParams = getQueryParams(queryString);

    // If no 'token' found, redirect to 'sign-in'
    if (!queryParams.token) {
      notificationActions.displayMessage('No confirmation token found');
      history.replace(ROUTES.SIGN_IN);
      return undefined;
    }

    const isDisposableEmail = validateDisposableEmail();

    if (isDisposableEmail) return undefined;

    // Request email confirmation from BE
    userActions
      .confirmEmail(queryParams.token)
      .then(async () => {
        const checkoutPlanRoute = userActions.getOnboardingStageValue(
          ONBOARDING_STAGE.checkoutPlanRoute
        );
        if (checkoutPlanRoute && checkoutPlanRoute !== ROUTES.ROOT) {
          history.replace(checkoutPlanRoute);
        } else {
          history.replace(formatRoute(ROUTES.ROOT, { [ROUTE_PARAMS.userCreated]: true }));
        }
      })
      .catch((error) => {
        handleError(error);

        // Navigate to 'sign-in' on error
        history.replace(ROUTES.SIGN_IN);
      });

    return undefined;
  }, [
    queryString,
    notificationActions,
    handleError,
    history,
    userActions,
    validateDisposableEmail,
  ]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Verification</title>
      </Helmet>

      <VerifyEmailCallback />
    </React.Fragment>
  );
};

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
