import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    paper: {
      minWidth: 400,
      maxWidth: 600,
      borderRadius: theme.shape.borderRadiusLarge,
    },
    dialogContent: {
      padding: theme.spacing(2, 4, 3),
    },
    content: {
      whiteSpace: 'pre-wrap',
      textAlign: 'start',
    },
  }),
  { name: 'ConfirmPopup' }
);
