import { useCallback } from 'react';

import { usePopups, EXPLAINER_VIDEO_POPUP } from 'src/services/popup';

export const useExplainerVideoPopup = (videoId, isWistia, options) => {
  const popups = usePopups();

  return useCallback(
    (closeCallback = null) => {
      popups.openPopup(EXPLAINER_VIDEO_POPUP, {
        videoId,
        isWistia,
        aspectRatio: options?.aspectRatio || 16 / 9,
        onClose: () => {
          popups.closePopup(EXPLAINER_VIDEO_POPUP);
          if (closeCallback && typeof closeCallback === 'function') {
            closeCallback();
          }
        },
      });
    },
    [popups, videoId, isWistia, options?.aspectRatio]
  );
};
