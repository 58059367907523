import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconStrikethrough = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.62188 1.36323C5.22384 0.838233 6.03092 0.505859 6.86066 0.505859H10.4453C10.8595 0.505859 11.1953 0.841646 11.1953 1.25586C11.1953 1.67007 10.8595 2.00586 10.4453 2.00586H6.86066C6.42201 2.00586 5.95891 2.18748 5.6078 2.49369C5.25851 2.79833 5.07031 3.17865 5.07031 3.55273C5.07031 4.15251 5.32258 4.61275 5.7903 5.05124C6.28557 5.51555 6.96474 5.90237 7.75051 6.34437C7.76449 6.35223 7.7785 6.36011 7.79255 6.36801C8.52788 6.78154 9.36032 7.24969 10.0012 7.85055C10.682 8.48873 11.1953 9.32048 11.1953 10.4434C11.1953 11.2514 10.8743 12.0264 10.3029 12.5978C9.7315 13.1692 8.95652 13.4902 8.14844 13.4902H4.32031C3.9061 13.4902 3.57031 13.1544 3.57031 12.7402C3.57031 12.326 3.9061 11.9902 4.32031 11.9902H8.14844C8.55869 11.9902 8.95215 11.8273 9.24224 11.5372C9.53234 11.2471 9.69531 10.8536 9.69531 10.4434C9.69531 9.84358 9.44304 9.38334 8.97533 8.94486C8.48005 8.48054 7.80088 8.09372 7.01512 7.65173C7.00114 7.64387 6.98712 7.63598 6.97307 7.62808C6.23775 7.21455 5.4053 6.7464 4.76439 6.14554C4.08367 5.50737 3.57031 4.67561 3.57031 3.55273C3.57031 2.65792 4.0181 1.88981 4.62188 1.36323Z"
        fill="#444444"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.507812 7.38086C0.507812 6.96665 0.843599 6.63086 1.25781 6.63086H12.7422C13.1564 6.63086 13.4922 6.96665 13.4922 7.38086C13.4922 7.79507 13.1564 8.13086 12.7422 8.13086H1.25781C0.843599 8.13086 0.507812 7.79507 0.507812 7.38086Z"
        fill="#444444"
      />
    </SvgIcon>
  ))
);

IconStrikethrough.displayName = 'IconStrikethrough';
