import { createContainer } from 'react-sweet-state';

import { MainStore, PRIVATE_ACTIONS } from './MainStore';

export const MainStoreContainer = createContainer(MainStore, {
  onInit:
    () =>
    ({ setState, dispatch }, { state }) => {
      dispatch(PRIVATE_ACTIONS.initStore());

      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
