import { useCallback, useMemo } from 'react';

import * as events from 'src/lib/events';
import { PAGES, useTrackingEvent } from 'src/services/useTrackingEvent';

export default function useEvents() {
  const trackingEvent = useTrackingEvent();

  const trackPurchaseClick = useCallback(() => {
    trackingEvent(events.PURCHASE.navbarPurchaseClick, { page: PAGES.accountSettings });
  }, [trackingEvent]);

  return useMemo(
    () => ({
      trackPurchaseClick,
    }),
    [trackPurchaseClick]
  );
}
