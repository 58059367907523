import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    rowsLimitContainer: {
      alignItems: 'center',
    },
    semiBoldLabel: {
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    upgradeButton: {
      color: COLORS.success.active,
      fontWeight: theme.typography.fontWeightMedium,
      cursor: 'pointer',
    },
    icon: {
      color: COLORS.gray.gray50,
      marginLeft: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightSemiBold,
    },
    linearProgress: {
      marginTop: theme.spacing(0.5),
      flex: 1,
      height: 6,
      backgroundColor: COLORS.gray.gray70,
      borderRadius: theme.spacing(1),
    },
    bar: {
      backgroundColor: `${COLORS.success.active} !important`,
    },
    limitBar: {
      backgroundColor: `${COLORS.error.active} !important`,
    },
    informationIcon: {
      fontSize: theme.typography.pxToRem(14),
      color: COLORS.gray.gray50,
      margin: theme.spacing(0, 0.5, 0.25, 0.5),

      '&:hover': {
        color: alpha(COLORS.gray.gray50, 0.8),
      },
    },
  }),
  { name: 'FeatureLimitedProgressBarComponents' }
);
