import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';

import ImageIcon from 'src/components/ImageIcon';
import TextLink from 'src/components/TextLink';
import ImageEmpty from 'src/assets/img/empty.svg';
import { useStyles } from './useStyles';

export const PerformanceEmptyState = ({ className, copiesExist, onResetFiltersClick }) => {
  const styles = useStyles();

  return (
    <div className={cx(className, styles.root)}>
      <ImageIcon
        className={styles.image}
        image={ImageEmpty}
      />
      <Typography className={styles.title}>We couldn&apos;t find any results</Typography>
      <Typography className={styles.description}>
        {copiesExist
          ? 'Please try again with different filters or search terms'
          : 'There is no data available for the selected accounts at the moment.'}
      </Typography>
      {copiesExist && onResetFiltersClick && (
        <TextLink
          className={styles.buttonReset}
          onClick={onResetFiltersClick}
          component="button"
        >
          Reset Filters
        </TextLink>
      )}
    </div>
  );
};

PerformanceEmptyState.propTypes = {
  className: PropTypes.string,
  copiesExist: PropTypes.bool,
  onResetFiltersClick: PropTypes.func,
};
PerformanceEmptyState.defaultProps = {
  className: null,
  copiesExist: false,
  onResetFiltersClick: null,
};
