import { useCallback, useEffect, useMemo, useState } from 'react';
import mapValues from 'lodash/mapValues';

import { validate } from 'src/lib/validation';
import { useFreshRef } from './useFreshRef';

const mapErrors = (errors, fieldName) =>
  mapValues(errors, (value) => (value ? value[fieldName] : null));

export const isValidErrors = (fieldErrors) =>
  !Object.values(fieldErrors).some((e) => e && e.length > 0);

export const useValidatedForm = ({ initialFields = {}, rules = {}, options }) => {
  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState({});

  const rulesRef = useFreshRef(rules);
  const fieldsRef = useFreshRef(fields);

  const enableReinitialize = options?.enableReinitialize;
  const autoValidate = options?.autoValidate;

  useEffect(() => {
    if (enableReinitialize) {
      setFields(initialFields);
    }
  }, [initialFields, enableReinitialize]);

  /**
   * Validate only one field
   */
  const validateSingleField = useCallback(
    (field, value) => {
      // Run validations for one field
      const fieldRules = rulesRef.current[field];
      const fieldError = validate(fieldRules, value);

      setErrors((prev) => {
        // Do not cause re-render if both of them are nulls
        if (prev[field] === fieldError) {
          return prev;
        }
        // Rewrite errors for current field
        return { ...prev, [field]: fieldError };
      });
    },
    [rulesRef]
  );

  /**
   * Validate all fields
   *
   * @returns {Object} Validation rules errors
   */
  const validateWithRules = useCallback(() => {
    // Run validations for all possible fields
    const fieldErrors = {};
    Object.entries(rulesRef.current).forEach(([fieldName, fieldRules]) => {
      const fieldValue = fieldsRef.current[fieldName];
      fieldErrors[fieldName] = validate(fieldRules, fieldValue);
    });

    // Rewrite all errors
    setErrors(fieldErrors);

    // Respond, either form valid or not
    return mapErrors(fieldErrors, 'rule');
  }, [rulesRef, fieldsRef]);

  /**
   * Validate all fields
   *
   * @returns {Boolean} Are fields valid
   */
  const validateFields = useCallback(() => {
    const fieldErrors = validateWithRules();
    return isValidErrors(fieldErrors);
  }, [validateWithRules]);

  /**
   * Set value for a field
   */
  const update = useCallback(
    (field, value) => {
      // Update field value
      setFields((prev) => {
        // Do not cause re-render if both of them are equal
        if (prev[field] === value) {
          return prev;
        }

        // Run validations for current field, or clear error if "autoValidate" is disabled
        if (autoValidate) {
          validateSingleField(field, value);
        } else {
          setErrors((prev) => {
            return { ...prev, [field]: null };
          });
        }

        // Rewrite value for current field
        return {
          ...prev,
          [field]: value,
        };
      });
    },
    [validateSingleField, autoValidate]
  );

  const errorMessages = useMemo(() => mapErrors(errors, 'message'), [errors]);

  const errorRules = useMemo(() => mapErrors(errors, 'rule'), [errors]);

  return {
    fields,
    errors: errorMessages,
    errorRules,
    validate: validateFields,
    validateWithRules,
    update,
  };
};
