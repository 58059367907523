import { makeStyles, alpha } from '@material-ui/core/styles';

const dotSize = (size) => size / 3;
const dotPosition = (size) => (size - size / 3) / 2;

const pulseSize = (size) => size * 1.2;
const pulsePosition = (size) => (size - size * 1.2) / 2;

export default makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      width: ({ size }) => size,
      height: ({ size }) => size,
      pointerEvents: 'none',

      opacity: 1,
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
      }),

      '&[data-popper-reference-hidden]': {
        opacity: 0,
      },
    },
    dot: {
      position: 'absolute',
      width: ({ size }) => dotSize(size),
      height: ({ size }) => dotSize(size),
      top: ({ size }) => dotPosition(size),
      left: ({ size }) => dotPosition(size),

      backgroundColor: ({ color }) => color || theme.palette.primary.main,
      borderRadius: '50%',
    },
    pulse: {
      position: 'absolute',
      width: ({ size }) => pulseSize(size),
      height: ({ size }) => pulseSize(size),
      top: ({ size }) => pulsePosition(size),
      left: ({ size }) => pulsePosition(size),

      backgroundColor: ({ color }) => alpha(color || theme.palette.primary.main, 0.5),
      borderRadius: '50%',
      animation: '$onboarding 1s ease-in-out infinite',
    },
    '@keyframes onboarding': {
      '0%': {
        transform: 'scale(0.5)',
      },
      '100%': {
        transform: 'scale(1)',
      },
    },
  }),
  { name: 'Beacon' }
);
