import React from 'react';

// DO NOT REMOVE! Without the following imports getWorkspacesByTier and getSeatsByTier is trying to be imported from const, resulting in an error
import { getWorkspacesByTier, getSeatsLabelByTier } from 'src/data/subscription/subscriptionTier';

import { CheckmarkIcon } from 'src/assets/icons/Checkmark';
import {
  SUBSCRIPTION_PLAN,
  DEFAULT_SUBSCRIPTION_PLAN,
  DETAILS_TABLE_ICONS,
  SUBSCRIPTION_PLAN_DEPRECATED,
  SUBSCRIPTION_DATA_DRIVEN_SEAT_TIER,
  SUBSCRIPTION_SEAT_TIER,
  BILLING_CYCLES,
} from './const';

const checkMarks = (length) =>
  Array.from({ length }).map((_, index) => <CheckmarkIcon key={index} />);

export const clampSubscriptionPlan = (plan, defaultValue = DEFAULT_SUBSCRIPTION_PLAN) => {
  const values = Object.values(SUBSCRIPTION_PLAN);
  if (values.includes(plan)) {
    return plan;
  }
  return defaultValue;
};

export const isSubscriptionPlanDeprecated = (plan) =>
  Object.values(SUBSCRIPTION_PLAN_DEPRECATED).includes(plan);

export const isStarterPlan = (plan) => plan === SUBSCRIPTION_PLAN.starter;
export const isBasicPlan = (plan) => plan === SUBSCRIPTION_PLAN.basic;
export const isDDTeamsPlan = (plan) => plan === SUBSCRIPTION_PLAN.dd_teams;
export const isPocPlan = (plan) => plan === SUBSCRIPTION_PLAN.poc;

export const shouldUpgradeToBusiness = (plan) =>
  isStarterPlan(plan) || isBasicPlan(plan) || isDDTeamsPlan(plan);
export const isBusinessSelfServePlan = (plan) => plan === SUBSCRIPTION_PLAN.business_self_serve;
export const isEnterpriseOrBusinessPlan = (plan) =>
  [SUBSCRIPTION_PLAN.enterprise, SUBSCRIPTION_PLAN.business, SUBSCRIPTION_PLAN.poc].includes(plan);
export const isSelfServicePlan = (plan) => {
  return ![
    SUBSCRIPTION_PLAN.enterprise,
    SUBSCRIPTION_PLAN.business,
    SUBSCRIPTION_PLAN.poc,
  ].includes(plan);
};
export const isDataDrivenPlan = (plan) => isDDTeamsPlan(plan);

export const isStarterOrBasicPlan = (plan) => isStarterPlan(plan) || isBasicPlan(plan);

export const isSelfServeBusinessPlan = (plan) => plan === SUBSCRIPTION_PLAN.business_self_serve;

export const isPlanWithTiers = (plan) =>
  plan === SUBSCRIPTION_PLAN.dataDriven || plan === SUBSCRIPTION_PLAN.proAgency;

const calculateSeatPrice = (billingCycle) =>
  billingCycle === BILLING_CYCLES.annually ? '49' : '59';
const PLAN_PROPS = {
  [SUBSCRIPTION_PLAN.basic]: {
    label: 'Free',
    featureGroups: [
      {
        features: [
          { text: '5 one-click boost performance' },
          { text: '100+ performance-driven templates' },
          { text: 'Blog Wizard' },
          { text: 'Predictive performance scores & analytics' },
          { text: 'High-performing talking point suggestions' },
          { text: '3 tones of voice' },
          { text: '5 target audiences' },
          { text: '10 messaging bank assets' },
          { text: '10 custom copywriting formulas' },
          { text: '25 brand terms' },
        ],
      },
    ],
  },

  [SUBSCRIPTION_PLAN.poc]: {
    label: 'Proof of Concept',
  },

  [SUBSCRIPTION_PLAN.enterprise]: {
    label: 'Enterprise',
  },

  [SUBSCRIPTION_PLAN.starter]: {
    classNamePrefix: 'PricingStarter',
    label: 'Starter',
    description:
      'For solo marketers & freelancers who want on-brand content backed by Anyword’s performance data.',
    realtimeScoringAndAnalytics: DETAILS_TABLE_ICONS.minus,
    scoreOptimization: DETAILS_TABLE_ICONS.minus,
    seats: 1,
    price: 49,
    priceAnnually: 468,
    priceAnnuallyPerMonth: 39,
    percentOfAnnualDiscount: 20,
    blogPosts: DETAILS_TABLE_ICONS.check,
    aiWritingTools: DETAILS_TABLE_ICONS.check,
    social: DETAILS_TABLE_ICONS.check,
    ads: DETAILS_TABLE_ICONS.check,
    landingPages: DETAILS_TABLE_ICONS.check,
    subjectLines: DETAILS_TABLE_ICONS.check,
    productListings: DETAILS_TABLE_ICONS.check,
    customAiModels: DETAILS_TABLE_ICONS.minus,
    importAdsFromSocial: DETAILS_TABLE_ICONS.minus,
    copyVariationsInformed: DETAILS_TABLE_ICONS.minus,
    apiAccess: DETAILS_TABLE_ICONS.minus,
    support: 'Chat and ticket\nsupport',
    policyComplianceDetection: DETAILS_TABLE_ICONS.minus,
    features: [
      { text: '5 one-click Boost Performance' },
      { text: '100+ performance-driven templates' },
      { text: 'Blog Wizard' },
      { text: 'Predictive performance scores & analytics' },
      { text: 'High-performing talking point suggestions' },
      { text: '3 tones of voice' },
      { text: '5 target audiences' },
      { text: '10 messaging bank assets' },
      { text: '10 custom copywriting formulas' },
      { text: '25 brand terms' },
      { text: '1 Workspace' },
    ],
    featuresTitle: 'Starter plan includes:',
    featureGroups: [
      {
        features: [
          {
            text: 'Includes 1 seat',
          },
          {
            text: '1 Brand Voice',
            tooltip: () =>
              'Create 1 brand voice and add in your tone of voice, target audiences, brand terms, messaging bank and more.',
          },
          {
            text: '20 Resources',
            tooltip: () => '',
          },
          {
            text: 'Unlimited copy generation',
            tooltip: () =>
              'You have unlimited words to generate as much content as you need with Anyword’s Data-Driven Editor and Blog Wizard.',
          },
          {
            text: '100+ marketing templates & prompts',
            tooltip: () =>
              'Choose from our ready-made marketing prompts for emails, social media, ads, landing pages, etc.',
          },
          {
            text: 'Predictive performance scores & analytics',
            tooltip: () =>
              'Anyword generates, scores, and ranks content based on predicted performance, allowing you to quickly choose the best-performing variations before you publish.',
          },
          {
            text: 'Blog Wizard with plagiarism checker',
            tooltip: () => 'Create an SEO-optimized, plagiarism-free blog in minutes.',
          },
          {
            text: 'Create target audiences in 1-click',
            tooltip: () =>
              'Simply upload your website URL or sample text and Anyword will create your target audience down to their demographics and pain points.',
          },
          {
            text: 'Anyword’s Google Chrome Extension',
            tooltip: () =>
              'Use Anyword’s marketing templates, predictive performance analytics and brand voice features directly in ChatGPT, Google Docs, Notion\nand more.',
          },
        ],
      },
    ],
  },
  [SUBSCRIPTION_PLAN.dd_teams]: {
    classNamePrefix: 'PricingDD',
    label: 'Data-Driven',
    description:
      'For marketing teams who want on-brand content backed by Anyword’s\nperformance data.',
    realtimeScoringAndAnalytics: DETAILS_TABLE_ICONS.check,
    creditsPerMonth: 'Unlimited',
    scoreOptimization: DETAILS_TABLE_ICONS.check,
    seats: 'Multiple',
    blogPosts: DETAILS_TABLE_ICONS.check,
    aiWritingTools: DETAILS_TABLE_ICONS.check,
    social: DETAILS_TABLE_ICONS.check,
    ads: DETAILS_TABLE_ICONS.check,
    customAiModels: DETAILS_TABLE_ICONS.minus,
    importAdsFromSocial: DETAILS_TABLE_ICONS.minus,
    copyVariationsInformed: DETAILS_TABLE_ICONS.minus,
    apiAccess: DETAILS_TABLE_ICONS.minus,
    landingPages: 'Write from scratch',
    subjectLines: DETAILS_TABLE_ICONS.check,
    productListings: DETAILS_TABLE_ICONS.check,
    support: 'Chat and ticket\nsupport',
    policyComplianceDetection: DETAILS_TABLE_ICONS.minus,
    tiers: SUBSCRIPTION_DATA_DRIVEN_SEAT_TIER,
    featuresTitle: 'Data-Driven plan includes:',
    features: [
      { text: 'Unlimited one-click Boost Performance' },
      { text: '100+ performance-driven templates' },
      { text: 'Blog Wizard' },
      { text: 'Predictive performance scores & analytics' },
      { text: 'High-performing talking point suggestions' },
      { text: '3 tones of voice' },
      { text: '5 target audiences' },
      { text: '10 messaging bank assets' },
      { text: '10 custom copywriting formulas' },
      { text: '25 brand terms' },
      { text: 'Workspaces', calcFunction: getWorkspacesByTier },
    ],
    featureGroups: [
      {
        features: [
          {
            text: 'Includes ',
            calcFunction: getSeatsLabelByTier,
            tooltip: (billingCycle) =>
              `3 seats are included, and you can purchase up to 10 additional seats for $${calculateSeatPrice(
                billingCycle
              )}/seat.`,
          },
          {
            text: '1 Brand Voice',
            tooltip: () =>
              'Create 1 brand voice and add in your tone of voice, target audiences, brand terms, messaging bank and more.',
          },
          {
            text: '50 Resources',
            tooltip: () => '',
          },
          {
            text: 'Unlimited 1-Click Boost Performance & \nContent Improver',
            tooltip: () =>
              'In 1 click, Anyword will provide improved\ncontent variations for better performance and higher scores.',
          },
          {
            text: 'Unlimited copy generation',
            tooltip: () =>
              'You have unlimited words to generate as much content as you need with Anyword’s Data-Driven Editor and Blog Wizard.',
          },
          {
            text: '100+ marketing templates & prompts',
            tooltip: () =>
              'Choose from our ready-made marketing prompts for emails, social media, ads, landing pages, etc.',
          },
          {
            text: 'Predictive performance scores & analytics',
            tooltip: () =>
              'Anyword generates, scores, and ranks content based on predicted performance, allowing you\nto quickly choose the best-performing variations before you publish.',
          },
          {
            text: 'Blog Wizard with plagiarism checker',
            tooltip: () => 'Create an SEO-optimized, plagiarism-free blog\nin minutes.',
          },
          {
            text: 'Create target audiences in 1-click',
            tooltip: () =>
              'Simply upload your website URL or sample text and Anyword will create your target audience down to their demographics and pain points.',
          },
          {
            text: 'Anyword’s Google Chrome Extension',
            tooltip: () =>
              'Use Anyword’s marketing templates, predictive performance analytics and brand voice features directly in ChatGPT, Google Docs, Notion\nand more.',
          },
        ],
      },
    ],
  },

  [SUBSCRIPTION_PLAN.business_self_serve]: {
    classNamePrefix: 'PricingBusiness',
    label: 'Business',
    description:
      'For marketing teams who want better performance across channels with AI trained on your best messaging & past campaigns.',
    realtimeScoringAndAnalytics: DETAILS_TABLE_ICONS.check,
    creditsPerMonth: 'Unlimited',
    scoreOptimization: DETAILS_TABLE_ICONS.check,
    seats: 'Multiple',
    blogPosts: DETAILS_TABLE_ICONS.check,
    aiWritingTools: DETAILS_TABLE_ICONS.check,
    social: DETAILS_TABLE_ICONS.check,
    ads: DETAILS_TABLE_ICONS.check,
    customAiModels: DETAILS_TABLE_ICONS.minus,
    importAdsFromSocial: DETAILS_TABLE_ICONS.minus,
    copyVariationsInformed: DETAILS_TABLE_ICONS.minus,
    apiAccess: DETAILS_TABLE_ICONS.minus,
    landingPages: 'Write from scratch',
    subjectLines: DETAILS_TABLE_ICONS.check,
    productListings: DETAILS_TABLE_ICONS.check,
    support: 'Chat and ticket\nsupport',
    policyComplianceDetection: DETAILS_TABLE_ICONS.minus,
    tiers: SUBSCRIPTION_SEAT_TIER,
    featuresTitle: 'Everything In Data-Driven, plus:',
    features: [
      { text: 'Connect your marketing channels with unlimited rows (copy assets)' },
      { text: 'Unlimited access to Copy Intelligence with past performance copy analytics' },
      { text: 'Rank your generated content against your existing content' },
      { text: 'Predictive performance scores & analytics' },
      { text: 'Automated website messaging A/B testing' },
      { text: 'Extended brand voice capabilities' },
      { text: 'High-performing talking points' },
      { text: 'Unlimited 1-Click Boost Performance & Content Improver' },
      { text: '100+ marketing templates' },
      { text: 'Predictive performance scores & analytics' },
      { text: 'Blog Wizard with plagiarism checker' },
      { text: 'Use Anyword on ChatGPT, Google Docs, Notion & more with Google Chrome extension' },
      { text: 'Dedicated onboarding & account setup' },
    ],
    featureGroups: [
      {
        features: [
          {
            text: 'Seats',
            calcFunction: getSeatsLabelByTier,
            tooltip: (billingCycle) =>
              `3 seats are included, and you can purchase up to 20 additional seats for $${calculateSeatPrice(
                billingCycle
              )}/seat.`,
          },
          {
            text: '5 Brand Voices',
            tooltip: () =>
              'Create up to 5 brand voices and add in your tone of voice, target audiences, brand terms, messaging bank and more.',
          },
          {
            text: '200 Resources',
            tooltip: () => '',
          },
          {
            text: 'Connect your marketing channels with \n(data integration: 5000 rows)',
            tooltip: () =>
              'Connect your Hubspot, Google, Meta, website, and various other accounts to acquire copy insights and enable more personalized content creation. Each row (copy asset) represents a distinct copy asset that you employ and analyze. The number of rows depends on the number of integrations you have and the level of data granularity for each integration.',
          },
          {
            text: 'Use Copy Intelligence to analyze copy across your marketing channels',
            tooltip: () => 'Learn how your existing copy performs in each channel & improve it.',
          },
          {
            text: 'Rank new copy and benchmark it against content you published in the past',
            tooltip: () =>
              'Connect your channels to train Anyword on your past campaigns and messaging. Then rank & benchmark new content against your published content.',
          },
          {
            text: 'Identify high-performing talking points & incorporate them into new copy generations',
            tooltip: () =>
              'Anyword analyzes your copy across channels and shares your best performing talking points.\nUse these talking points in any content you generate.',
          },
          {
            text: 'Automated website messaging A/B testing',
          },
          {
            text: 'Onboarding & account setup',
            tooltip: () =>
              'Work with an Anyword expert to set up your account, train Anyword on your brand & develop an AI action plan for success.',
          },
          {
            text: 'Roles & permissions management',
            tooltip: () =>
              "Easily manage roles, permissions, and access across your company's workspaces.",
          },
        ],
      },
    ],
  },

  [SUBSCRIPTION_PLAN.business]: {
    label: 'Enterprise',
    description:
      'For teams who want custom-built AI tailored to their brand, with enterprise-grade security, privacy, and control.',
    featuresTitle: 'Everything In Business, plus:',
    featureGroups: [
      {
        features: [
          {
            text: 'Connect your marketing channels (data integration: unlimited rows)',
            tooltip: () =>
              'Connect your Hubspot, Google, Meta, website, and various other accounts to acquire copy insights and enable more personalized content creation. Each row (copy asset) represents a distinct copy asset that you employ and analyze. The number of rows depends on the number of integrations you have and the level of data granularity for each integration.',
          },
          {
            text: 'Enterprise-grade security & SSO',
            tooltip: () =>
              'A comprehensive set of enterprise-grade security features, including SOC2 and ISO compliance and single sign-on to authenticate all users’ logins.',
          },
          {
            text: 'Private language model',
            tooltip: () =>
              'Build & fine-tune a secure, dedicated language model that serves only your organization to\nensure you maintain control over sensitive\ndata and information.',
          },
          {
            text: 'Custom marketing channel integrations',
            tooltip: () => 'Connect any of your existing marketing tech\nstack to Anyword.',
          },
          {
            text: 'Custom-built AI models',
            tooltip: () =>
              'Develop a custom AI model tailored to your brand with Anyword’s data science experts.',
          },
          {
            text: 'Full API access',
            tooltip: () =>
              "Use Anyword's API to build performance-focused applications and processes.",
          },
          {
            text: 'Security & legal review',
            tooltip: () => '',
          },
          {
            text: 'Unlimited workspaces',
            tooltip: () => '',
          },
          {
            text: 'Priority support',
            tooltip: () =>
              'Get faster, prioritized technical and customer support from our dedicated Support specialists.',
          },
          {
            text: 'Dedicated customer success manager',
            tooltip: () =>
              'Dedicated Anyword expert to help you set up your account, train Anyword on your brand, develop an AI action plan, and develop best practices for your team through dedicated consultation hours.',
          },
        ],
      },
    ],
  },

  // Obsolete plans
  [SUBSCRIPTION_PLAN.dataDriven100]: {
    label: 'Data-Driven',
  },

  [SUBSCRIPTION_PLAN.dataDriven]: {
    label: 'Data-Driven',
  },

  [SUBSCRIPTION_PLAN.tiered]: {
    label: 'Starter',
  },

  [SUBSCRIPTION_PLAN.acquisition]: {
    label: 'Acquire',
  },

  [SUBSCRIPTION_PLAN.conversion]: {
    label: 'Convert',
  },

  [SUBSCRIPTION_PLAN.engagement]: {
    label: 'Engage',
  },
};

export const PLAN_DETAILS_TABLE_ROWS = [
  {
    key: 'creditsPerMonth',
    label: 'Word credits per month',
  },
  {
    key: 'realtimeScoringAndAnalytics',
    label: 'Real-time scoring and analytics',
    subLabel: 'Get real-time scoring and analytics for every copy edit you make',
  },
  {
    key: 'scoreOptimization',
    label: 'Score optimization',
    subLabel: 'Have Anyword rewrite any existing copy to improve its predictive performance score',
  },
  {
    key: 'seats',
    label: 'Seats',
  },
  {
    key: 'blogPosts',
    label: 'Blog posts',
  },
  {
    key: 'aiWritingTools',
    label: 'AI writing tools',
  },
  {
    key: 'social',
    label: 'Social',
    subLabel: 'Channel-specific data-driven copywriting optimized for performance',
  },
  {
    key: 'ads',
    label: 'Ads',
    subLabel: 'Channel-specific data-driven copywriting optimized for performance',
  },
  {
    key: 'landingPages',
    label: 'Landing pages',
  },
  {
    key: 'subjectLines',
    label: 'Subject lines, cold emails,\nProspecting emails',
  },
  {
    key: 'productListings',
    label: 'Product Listings',
    subLabel: 'Amazon, Shopify, Walmart',
  },
  {
    key: 'customAiModels',
    label: 'Custom AI models',
  },
  {
    key: 'policyComplianceDetection',
    label: 'Ad policy compliance\ndetection',
  },
  {
    key: 'importAdsFromSocial',
    label: 'Import your ads from\nFacebook & Google',
  },
  {
    key: 'copyVariationsInformed',
    label: 'Copy variations informed\nby customer reviews',
  },
  {
    key: 'apiAccess',
    label: 'API access',
  },
  {
    key: 'support',
    label: 'Support',
  },
];

export const PLAN_DETAILS_TABLE = [
  {
    type: 'header',
    text: 'Plans Include',
  },
  {
    text: 'Rows (copy assets)',
    data: ['50', '50', '5,000', 'Custom'],
  },
  {
    text: 'Words Generated',
    data: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
  },
  {
    text: 'Seats included',
    data: ['1', '3', '3', 'Custom'],
  },
  {
    text: 'Price per additional seat',
    data: [
      '-',
      '$59/mo or $49/mo yearly, max 10 seats',
      '$59/mo or $49/mo yearly, max 20 seats',
      'Custom',
    ],
  },
  {
    text: 'Workspaces',
    data: [1, 5, 10, 'Custom'],
  },
  {
    text: 'Resources',
    data: ['20', '50', '200', 'Custom'],
  },
  {
    type: 'header',
    text: 'Copy Generation Tools',
  },
  {
    text: 'Data-Driven Editor',
    data: checkMarks(4),
  },
  {
    text: '100+ performance-driven templates',
    data: checkMarks(4),
  },
  {
    text: 'Saved prompts & templates',
    data: ['Unlimited', 'Unlimited', 'Unlimited', 'Unlimited'],
  },
  {
    text: 'Blog Wizard',
    data: checkMarks(4),
  },
  {
    text: 'Plagiarism Checker',
    data: ['25/mo', '25/mo', 'Unlimited', 'Unlimited'],
  },
  {
    text: '30+ Languages',
    data: checkMarks(4),
  },
  {
    text: 'Google Chrome extension',
    data: checkMarks(4),
  },
  {
    type: 'header',
    text: 'Copy Intelligence & Brand Voice',
  },
  {
    text: 'Custom-built AI models',
    data: ['-', '-', '-', 'Unlimited'],
  },
  {
    text: 'Rows (copy assets)',
    data: ['50', '50', '5,000', 'Unlimited'],
  },
  {
    text: 'Brand Voices',
    data: ['1', '1', '5', 'Custom'],
  },
  {
    text: '1-click Boost Performance',
    data: ['5/mo', 'Unlimited', 'Unlimited', 'Unlimited'],
  },
  {
    text: 'Predictive performance scores & insights',
    data: checkMarks(4),
  },
  {
    text: 'High-performing talking points',
    data: checkMarks(4),
  },
  {
    text: 'Automated website messaging',
    data: ['-', '-', ...checkMarks(2)],
  },
  {
    text: 'Rank new copy and benchmark it\nagainst content you published in the past',
    data: ['-', '-', ...checkMarks(2)],
  },
  {
    text: 'Out of the box integrations',
    data: ['-', '-', ...checkMarks(2)],
  },
  {
    text: 'Custom integrations',
    data: ['-', '-', '-', checkMarks(1)],
  },
  {
    text: 'Copy analytics',
    data: ['-', '-', ...checkMarks(2)],
  },
  {
    type: 'header',
    text: 'Admin, Support & Security',
  },
  {
    text: 'Private language model',
    data: ['-', '-', '-', checkMarks(1)],
  },
  {
    text: 'API',
    data: ['-', '-', '-', checkMarks(1)],
  },
  {
    text: 'Dedicated customer success',
    data: ['-', '-', '-', checkMarks(1)],
  },
  {
    text: 'Onboarding & setup',
    data: ['-', '-', ...checkMarks(2)],
  },
  {
    text: 'SSO',
    data: ['-', '-', '-', checkMarks(1)],
  },
  {
    text: 'Reliability with 99.99% uptime',
    data: checkMarks(4),
  },
  {
    text: 'Knowledge base & email support',
    data: checkMarks(4),
  },
  {
    text: 'Roles & permissions management',
    data: ['-', '-', ...checkMarks(2)],
  },
  {
    text: 'Security review',
    data: ['-', '-', '-', checkMarks(1)],
  },
  {
    text: 'Legal review',
    data: ['-', '-', '-', checkMarks(1)],
  },
];

// functions

export const getPlanProperty = (plan, property, defaultValue = undefined) => {
  const props = PLAN_PROPS[plan];
  return (props && props[property]) || defaultValue;
};

export const getPlanFeatureGroups = (plan) => getPlanProperty(plan, 'featureGroups', []);

export const getSubscriptionPlanLabel = (plan) => getPlanProperty(plan, 'label', plan || '');

export const getSubscriptionPlanSubtitle = (plan) => getPlanProperty(plan, 'subtitle', '');

export const getSubscriptionPlanDescription = (plan) => getPlanProperty(plan, 'description', '');

export const getSubscriptionPlanWordsLimit = (plan) => getPlanProperty(plan, 'wordsLimit', 0);

export const getSubscriptionPlanFeatures = (plan) => getPlanProperty(plan, 'features', []);
export const getSubscriptionPlanFeaturesTitle = (plan) =>
  getPlanProperty(plan, 'featuresTitle', []);

export const getSubscriptionPlanTiers = (plan, defaultValue) =>
  getPlanProperty(plan, 'tiers', defaultValue);

export const getSubscriptionDetails = (plan, key) => getPlanProperty(plan, key, '');
