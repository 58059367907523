import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useCurrentCustomerPlan } from 'src/store';
import { useGetUpgradeRowsLimitButtonAttributes } from 'src/services';
import { isEnterpriseOrBusinessPlan } from 'src/data/subscription';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { UsageProgress } from 'src/components/UsageProgress';
import { InlineUpgradeButton } from 'src/components/InlineUpgradeButton';
import { useHandlers } from './useHandlers';
import { useStyles } from './useStyles';

export const FeatureLimitedProgressBar = ({
  feature,
  className,
  upgradeUrl,
  limitData = {},
  upgradeActionOrigin,
  headerLabel,
}) => {
  const styles = useStyles();
  const currentPlan = useCurrentCustomerPlan();
  const { currentCount, limit } = limitData;
  const isFeatureLimited = currentCount >= limit;
  const { shortUpgradeButtonText, isBusinessPlan } =
    useGetUpgradeRowsLimitButtonAttributes(upgradeUrl);

  const { handleUpgradeClick } = useHandlers({ upgradeUrl, upgradeActionOrigin, feature });

  return (
    <div className={cx(styles.root, className, { [styles.businessPlanWidth]: isBusinessPlan })}>
      <div className={styles.topContainer}>
        {headerLabel}
        {!isEnterpriseOrBusinessPlan(currentPlan) && (
          <InlineUpgradeButton
            className={styles.upgradeButton}
            text={shortUpgradeButtonText}
            onClick={handleUpgradeClick}
            dataTestId="integrationsAndResourcesFeatureLimitedProgressBarUpgradeButton"
          />
        )}
      </div>
      <UsageProgress
        classes={{
          bar: isFeatureLimited ? styles.limitBar : styles.bar,
          linearProgress: styles.linearProgress,
        }}
        value={currentCount}
        limit={limit}
      />
    </div>
  );
};

FeatureLimitedProgressBar.displayName = 'FeatureLimitedProgressBar';
FeatureLimitedProgressBar.propTypes = {
  className: PropTypes.string,
  upgradeUrl: PropTypes.string,
  upgradeActionOrigin: PropTypes.string,
  limitData: PropTypes.shape({
    currentCount: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  headerLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  feature: PropTypes.oneOf(Object.values(FEATURE_LIMITS)).isRequired,
};

FeatureLimitedProgressBar.defaultProps = {
  className: null,
  upgradeUrl: '',
  upgradeActionOrigin: '',
  headerLabel: '',
};
