import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';

export const CustomTag = ({ className, tag, backgroundColor }) => {
  const styles = useStyles({ backgroundColor });

  if (!tag) {
    return null;
  }

  return (
    <CustomTypography
      className={cx(className, styles.root)}
      variant={TYPOGRAPHY_VARIANT.medium12}
    >
      {tag}
    </CustomTypography>
  );
};

CustomTag.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};

CustomTag.defaultProps = {
  className: null,
  tag: null,
  backgroundColor: null,
};
