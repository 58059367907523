import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { UnauthenticatedNavBar } from 'src/views/UnauthenticatedNavBar';

export const PublicRoute = ({ children, displayNavigation }) => (
  <>
    {displayNavigation && <UnauthenticatedNavBar />}

    {children}
  </>
);

PublicRoute.propTypes = {
  children: PropTypes.node,
  displayNavigation: PropTypes.bool,
};
PublicRoute.defaultProps = {
  children: null,
  displayNavigation: false,
};
