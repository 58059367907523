import React from 'react';
import PropTypes from 'prop-types';

import { InsightBox } from '../InsightBox';
import { KeywordsGroup } from './components/KeywordsGroup';
import { useStyles } from './useStyles';

export const SEOTracker = ({
  markedKeywordsMap,
  targetKeywordsData,
  relatedKeywordsData,
  onToggleMarkedKeyword,
  onExpand,
}) => {
  const styles = useStyles();

  return (
    <InsightBox
      title="SEO keyword tracker"
      subtitle={`${targetKeywordsData.length} primary keywords • ${relatedKeywordsData.length} secondary keywords`}
      onExpand={onExpand}
      collapsableContent={
        (targetKeywordsData.length > 0 || relatedKeywordsData.length > 0) && (
          <div className={styles.keywordsGroups}>
            <KeywordsGroup
              classes={{ counter: styles.targetCounter }}
              title="Primary Keywords"
              keywords={targetKeywordsData}
              onToggleMarkedKeyword={onToggleMarkedKeyword}
              markedKeywordsMap={markedKeywordsMap}
            />
            <KeywordsGroup
              classes={{ counter: styles.relatedCounter }}
              title="Secondary Keywords"
              keywords={relatedKeywordsData}
              onToggleMarkedKeyword={onToggleMarkedKeyword}
              markedKeywordsMap={markedKeywordsMap}
            />
          </div>
        )
      }
    />
  );
};

SEOTracker.propTypes = {
  markedKeywordsMap: PropTypes.objectOf(PropTypes.bool),
  targetKeywordsData: PropTypes.arrayOf(PropTypes.shape({})),
  relatedKeywordsData: PropTypes.arrayOf(PropTypes.shape({})),
  onToggleMarkedKeyword: PropTypes.func,
  onExpand: PropTypes.func,
};

SEOTracker.defaultProps = {
  markedKeywordsMap: {},
  targetKeywordsData: [],
  relatedKeywordsData: [],
  onToggleMarkedKeyword: null,
  onExpand: null,
};
