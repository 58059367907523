import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Range as SlateRange } from 'slate';
import { useReadOnly, useSelected, useSlateStatic } from 'slate-react';

import { COLORS } from 'src/data/ui';
import { SLATE_TEXT_FORMATS } from 'src/lib/slate';
import { Link } from './components/Link';
import { BankMessageNode } from './views/BankMessageNode';
import { ImageNode } from './views/ImageNode';
import { DocumentNode } from './views/DocumentNode';
import { PromptTagNode } from './views/PromptTagNode';
import { VOID_ELEMENTS } from '../../const';
import { ProductDescriptionNode } from './components/ProductDescriptionNode';
import { ResourceUrlNode } from './components/ResourceUrlNode';

export const CustomElement = ({ attributes, children, element }) => {
  const style = { textAlign: element.align || '' };
  const editor = useSlateStatic();
  const selected = useSelected();
  const readOnly = useReadOnly();

  switch (element.type) {
    case SLATE_TEXT_FORMATS.BLOCK_QUOTE:
      return (
        <blockquote
          style={{
            ...style,
            borderLeft: `solid 3px ${COLORS.primary.light}`,
            paddingLeft: 10,
          }}
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case SLATE_TEXT_FORMATS.BULLETED_LIST:
      return (
        <ul
          style={style}
          {...attributes}
        >
          {children}
        </ul>
      );
    case SLATE_TEXT_FORMATS.NUMBERED_LIST:
      return (
        <ol
          style={style}
          {...attributes}
        >
          {children}
        </ol>
      );
    case SLATE_TEXT_FORMATS.HEADING_ONE:
      return (
        <h1
          style={style}
          {...attributes}
        >
          {children}
        </h1>
      );
    case SLATE_TEXT_FORMATS.HEADING_TWO:
      return (
        <h2
          style={style}
          {...attributes}
        >
          {children}
        </h2>
      );
    case SLATE_TEXT_FORMATS.HEADING_THREE:
      return (
        <h3
          style={style}
          {...attributes}
        >
          {children}
        </h3>
      );
    case SLATE_TEXT_FORMATS.LIST_ITEM:
      return (
        <li
          style={style}
          {...attributes}
          contentEditable={!readOnly}
          suppressContentEditableWarning
        >
          {children}
        </li>
      );
    case VOID_ELEMENTS.DIVIDER:
      return (
        <div
          style={{
            ...style,
            height: 2,
            width: 50,
            backgroundColor: '#7F91AD',
            margin: '20px 0',
          }}
          {...attributes}
        >
          {children}
        </div>
      );

    case SLATE_TEXT_FORMATS.DD_VARIATION: {
      const variation = element.variation || {};
      const props = {
        'data-variation': '',
        'data-variation-id': variation.id,
        'data-instance-id': variation.instanceId,
      };
      // Used in `useEditorVariationsBoundaries`
      if (variation.isScoreLocked) {
        props['data-score-locked'] = '';
      }
      return (
        <div
          style={{ ...style, margin: '8px 0px' }}
          {...props}
          {...attributes}
        >
          {children}
        </div>
      );
    }

    case SLATE_TEXT_FORMATS.LINK:
      return (
        <Link
          style={style}
          attributes={attributes}
          element={element}
        >
          {children}
        </Link>
      );

    case SLATE_TEXT_FORMATS.BANK_MESSAGE:
      return (
        <BankMessageNode
          element={element}
          {...attributes}
        >
          {children}
        </BankMessageNode>
      );

    case SLATE_TEXT_FORMATS.PROMPT_IMAGE:
      return (
        <ImageNode
          element={element}
          {...attributes}
        >
          {children}
        </ImageNode>
      );

    case SLATE_TEXT_FORMATS.PROMPT_DOCUMENT:
      return (
        <DocumentNode
          element={element}
          {...attributes}
        >
          {children}
        </DocumentNode>
      );

    case SLATE_TEXT_FORMATS.PRODUCT_DESCRIPTION:
      return (
        <ProductDescriptionNode
          element={element}
          {...attributes}
        >
          {children}
        </ProductDescriptionNode>
      );

    case SLATE_TEXT_FORMATS.RESOURCE_URL:
      return <ResourceUrlNode element={element}>{children}</ResourceUrlNode>;

    case SLATE_TEXT_FORMATS.PROMPT_TAG:
      return (
        <PromptTagNode
          element={element}
          {...attributes}
        >
          {children}
        </PromptTagNode>
      );

    default: {
      const selection = editor.selection;
      const isSelectionCollapsed = selection ? SlateRange.isCollapsed(selection) : false;
      const childrenNodes = element?.children || [];
      const isEmpty =
        childrenNodes.length === 0 || (childrenNodes.length === 1 && childrenNodes[0]?.text === '');

      return (
        <p
          className={cx(attributes.className, {
            'focused-empty-paragraph': selected && isSelectionCollapsed && isEmpty,
          })}
          style={{ ...style, margin: 0 }}
          {...attributes}
        >
          {children}
        </p>
      );
    }
  }
};

CustomElement.propTypes = {
  attributes: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  element: PropTypes.shape().isRequired,
};
