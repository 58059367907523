import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const DetectedBrandTermsContext = createContext({});
const DetectedBrandTermsContextProvider = DetectedBrandTermsContext.Provider;
const useDetectedBrandTermsContextValue = () => useContext(DetectedBrandTermsContext);

export const DetectedBrandTermsProvider = ({
  onApplyBrandTerm,
  appliedDetectedTermIds,
  children,
}) => {
  const value = useMemo(
    () => ({ onApplyBrandTerm, appliedDetectedTermIds }),
    [onApplyBrandTerm, appliedDetectedTermIds]
  );

  return (
    <DetectedBrandTermsContextProvider value={value}>{children}</DetectedBrandTermsContextProvider>
  );
};
DetectedBrandTermsProvider.displayName = 'DetectedBrandTermsProvider';
DetectedBrandTermsProvider.propTypes = {
  onApplyBrandTerm: PropTypes.func,
  appliedDetectedTermIds: PropTypes.instanceOf(Set),
  children: PropTypes.node,
};

/**
 * "apply detected brand term" handler
 * @returns {Function|null}
 */
export const useDetectedBrandTermsApplyHandler = () => {
  const contentValue = useDetectedBrandTermsContextValue();
  return contentValue?.onApplyBrandTerm;
};

/**
 * Set of detected term ids that were already applied in this session
 * @returns {Set|null}
 */
export const useDetectedBrandTermsAppliedIds = () => {
  const contentValue = useDetectedBrandTermsContextValue();
  return contentValue?.appliedDetectedTermIds;
};
