import { useCallback } from 'react';

import { MANAGE_WEBSITE_AUTOMATION_POPUP, usePopups } from 'src/services/popup';

export const useManageWebsiteAutomationPopup = () => {
  const popups = usePopups();

  return useCallback(
    (channel) => {
      return new Promise((resolve) => {
        popups.openPopup(MANAGE_WEBSITE_AUTOMATION_POPUP, {
          channel,
          onClose: async () => {
            resolve();
            popups.closePopup(MANAGE_WEBSITE_AUTOMATION_POPUP);
          },
        });
      });
    },
    [popups]
  );
};
