import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useStyles from './useStyles';

export const ImageMagnifier = ({ imgBase64, imageUrl, staticWidth, staticHeight, className }) => {
  const src = useMemo(
    () => (imgBase64 ? `data:image/jpeg;base64,${imgBase64}` : imageUrl),
    [imgBase64, imageUrl]
  );

  const styles = useStyles({
    staticHeight,
    staticWidth,
  });

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.innerContainer}>
        <img
          src={src}
          alt=""
          className={styles.staticImg}
        />
      </div>
    </div>
  );
};

ImageMagnifier.propTypes = {
  imgBase64: PropTypes.string,
  imageUrl: PropTypes.string,
  staticWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  staticHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

ImageMagnifier.defaultProps = {
  staticWidth: null,
  staticHeight: null,
  className: '',
  imgBase64: null,
  imageUrl: null,
};
