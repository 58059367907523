import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { getPolarHorizontalLabelAnchor, polarToCartesian } from '../../utils';
import { CREATE_PERSONA_ID, LABEL_HEIGHT, LABEL_SPACING, LABEL_WIDTH } from '../../const';
import { ButtonAddPersona } from '../ButtonAddPersona';
import { LabelSkeleton } from '../LabelSkeleton';
import { LabelPersona } from '../LabelPersona';
import { useStyles } from './useStyles';

export const CustomizedDataLabels = ({
  className,
  loading,
  disabled,
  onSavePersona,
  onReplacePersona,
  onEditPersona,
  onDeletePersona,
  onAddPersona,
  formattedGraphicalItems,
  brandVoices,
}) => {
  const styles = useStyles();
  const { points, radiusAxis } = formattedGraphicalItems?.[0]?.props || {};

  const labels = useMemo(() => {
    const result = [];

    points.forEach(({ angle, payload }) => {
      if (!payload?.persona && !loading) {
        return;
      }

      const coords = polarToCartesian(
        radiusAxis.cx,
        radiusAxis.cy,
        radiusAxis.outerRadius + LABEL_SPACING,
        angle
      );

      const textHorizontalAnchor = getPolarHorizontalLabelAnchor(angle);
      const calcX = alignXCoordinate(coords.x, textHorizontalAnchor);
      const calcY = alignYCoordinate(coords.y, textHorizontalAnchor);

      return result.push({
        key: payload.id,
        x: calcX,
        y: calcY,
        align: textHorizontalAnchor,
        persona: payload.persona,
        loading,
      });
    });

    return result;
  }, [loading, points, radiusAxis.cx, radiusAxis.cy, radiusAxis.outerRadius]);

  return (
    <g className={cx(styles.root, className)}>
      {labels.map((label) => (
        <foreignObject
          className={cx(styles.foreignObject, {
            [styles.createButtonForeignObject]: label.persona?.id === CREATE_PERSONA_ID,
          })}
          key={label.key}
          x={label.x}
          y={label.y}
        >
          {loading ? (
            <LabelSkeleton align={label.align} />
          ) : label.persona?.id === CREATE_PERSONA_ID ? (
            <ButtonAddPersona
              align={label.align}
              onClick={onAddPersona}
            />
          ) : (
            <LabelPersona
              persona={label.persona}
              align={label.align}
              onSave={onSavePersona}
              onEdit={onEditPersona}
              onReplace={onReplacePersona}
              onDelete={onDeletePersona}
              disabled={disabled}
              brandVoices={brandVoices}
            />
          )}
        </foreignObject>
      ))}
    </g>
  );
};

CustomizedDataLabels.displayName = 'CustomizedDataLabels';

CustomizedDataLabels.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSavePersona: PropTypes.func,
  onReplacePersona: PropTypes.func,
  onDeletePersona: PropTypes.func,
  onAddPersona: PropTypes.func,
  onEditPersona: PropTypes.func,
  formattedGraphicalItems: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape(),
    })
  ),
  brandVoices: PropTypes.arrayOf(PropTypes.shape({})),
};

CustomizedDataLabels.defaultProps = {
  className: null,
  loading: false,
  disabled: false,
  onSavePersona: null,
  onReplacePersona: null,
  onDeletePersona: null,
  formattedGraphicalItems: [],
  brandVoices: [],
};

const alignXCoordinate = (x, textHorizontalAnchor) => {
  if (textHorizontalAnchor === 'center') {
    return x - LABEL_WIDTH / 2;
  } else if (textHorizontalAnchor === 'end') {
    return x - LABEL_WIDTH;
  }
  return x;
};

const alignYCoordinate = (y, textHorizontalAnchor) => {
  if (textHorizontalAnchor === 'center') {
    return y - LABEL_HEIGHT + LABEL_SPACING / 2;
  }
  return y - LABEL_HEIGHT / 2;
};
