import React, { Suspense } from 'react';

import { SuspenseFallback } from 'src/components/SuspenseFallback';
import ErrorBoundary from 'src/services/ErrorBoundary';
import { lazyWithRetry } from 'src/utils/lazy';

const AsyncPage = lazyWithRetry(() => import(/* webpackChunkName: "SignInPage" */ './Container'));

export default (props) => (
  <ErrorBoundary>
    <Suspense fallback={<SuspenseFallback />}>
      <AsyncPage {...props} />
    </Suspense>
  </ErrorBoundary>
);
