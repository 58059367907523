import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ProductDescriptionList = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
    <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
    <path
      d="M23.1088 7C19.226 7 8 7 8 7V22.9368C8 22.9368 16.2695 22.9368 22.6214 22.9368V7"
      stroke="white"
      strokeWidth="0.897436"
    />
    <path
      d="M12.9648 18.9531H20.6737"
      stroke="white"
      strokeWidth="0.897436"
      strokeLinejoin="round"
    />
    <ellipse cx="10.9537" cy="18.9541" rx="1.0045" ry="0.996053" fill="white" />
    <path
      d="M12.9648 14.9697H20.6737"
      stroke="white"
      strokeWidth="0.897436"
      strokeLinejoin="round"
    />
    <ellipse cx="10.9537" cy="14.9697" rx="1.0045" ry="0.996053" fill="white" />
    <path
      d="M12.9648 10.9834H20.6737"
      stroke="white"
      strokeWidth="0.897436"
      strokeLinejoin="round"
    />
    <ellipse cx="10.9537" cy="10.9843" rx="1.0045" ry="0.996053" fill="white" />
  </SvgIcon>
));

export default React.memo(ProductDescriptionList);
