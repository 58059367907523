import { useEffect } from 'react';

import { isAbortError } from 'src/lib/errors';
import { useBrandVoicesActions, useCurrentWorkspaceId, useNotificationActions } from 'src/store';

/**
 * This hook is used to maintain the "brand voice" store state.
 */
export const useMaintainBrandVoicesStoreState = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const brandVoicesActions = useBrandVoicesActions();
  const notifications = useNotificationActions();

  useEffect(() => {
    if (!currentWorkspaceId) {
      return;
    }

    // Create an abort controller, in case user selects another workspace before the fetch completes
    const abortController = new AbortController();

    // Fetch brand voices for the current workspace
    brandVoicesActions
      .queryWorkspaceBrandVoices(
        { workspaceId: currentWorkspaceId },
        {
          abortSignal: abortController.signal,
        }
      )
      .catch((error) => {
        if (isAbortError(error)) {
          return;
        }

        // Display notification to user
        notifications.displayWarning(
          'Network error',
          'Error fetching brand voices. Please try reloading the page.'
        );
      });

    // Clear the store when the workspace changes
    return () => {
      abortController.abort();
      brandVoicesActions.clearStore();
    };
  }, [currentWorkspaceId, brandVoicesActions, notifications]);
};
