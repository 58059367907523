import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { CUSTOMER_MEMBER_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation RemoveCustomerMember($customerId: ID!, $email: String!) {
    removeCustomerMember(customerId: $customerId, email: $email) {
      ...CustomerMember
    }
  }
  ${CUSTOMER_MEMBER_FRAGMENT}
`;

export const removeCustomerUser = (customerId, email) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, email },
  }).then(({ data }) => getSingleProp(data));
