import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { getContentSensitivityInfo } from 'src/data/generation';
import { useStyles } from './useStyles';

export const ContentSensitivityInfo = ({
  className,
  classes,
  assetType,
  sensitivity,
  loading,
  horizontalLayout,
  smallerFont,
  policyTitle,
}) => {
  const styles = useStyles({ classes });

  const { title, reasonTitle, reasonDesc, reasonDetails, learnMoreLink, labelDesc } =
    getContentSensitivityInfo(policyTitle, sensitivity, assetType);

  return (
    <div
      className={cx(styles.root, className, {
        [styles.horizontalLayout]: horizontalLayout,
        [styles.smallerFont]: smallerFont,
      })}
      data-sensitivity={labelDesc}
    >
      <div className={styles.header}>
        <Typography className={styles.title}>{title || 'Content safety'}</Typography>

        {loading ? (
          <Skeleton
            variant="rect"
            width="70px"
            height="18px"
          />
        ) : (
          <Typography className={styles.badge}>{labelDesc}</Typography>
        )}
      </div>

      <div className={styles.reason}>
        {loading ? (
          <Skeleton
            variant="text"
            width="100%"
          />
        ) : (
          <Fragment>
            <Typography className={styles.reasonTitle}>
              {reasonTitle}
              {reasonDesc}
            </Typography>

            {reasonDetails && (
              <Typography className={styles.reasonDescription}>
                {reasonDetails} {learnMoreLink}
              </Typography>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

ContentSensitivityInfo.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  assetType: PropTypes.string,
  sensitivity: PropTypes.shape({
    label: PropTypes.string,
    reason: PropTypes.string,
    reasonDetails: PropTypes.string,
  }),
  loading: PropTypes.bool,
  horizontalLayout: PropTypes.bool,
  smallerFont: PropTypes.bool,
  policyTitle: PropTypes.bool,
};
ContentSensitivityInfo.defaultProps = {
  className: null,
  classes: null,
  assetType: null,
  sensitivity: null,
  loading: false,
  horizontalLayout: false,
  smallerFont: false,
  policyTitle: false,
};
