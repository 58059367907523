import { useCallback } from 'react';

import {
  ACCOUNTS_DATA_EXTRACTION_POPUP,
  AD_ACCOUNTS_SELECTION_POPUP,
  usePopups,
} from 'src/services/popup';

export const useAccountsDataExtractionPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ account }) => {
      popups.closePopup(AD_ACCOUNTS_SELECTION_POPUP);
      popups.openPopup(ACCOUNTS_DATA_EXTRACTION_POPUP, {
        onClose: () => {
          popups.closePopup(ACCOUNTS_DATA_EXTRACTION_POPUP);
        },
        account,
      });
    },
    [popups]
  );
};
