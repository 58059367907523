import { useCallback, useContext } from 'react';
import { ReactEditor, useSlate } from 'slate-react';

import { SLATE_EDITOR_ACTION } from 'src/lib/slate';
import {
  toggleBlock,
  toggleMark,
  toggleTextAlignmentBlock,
  getEditorActionTextFormat,
  slateToText,
  textToSlate,
} from '../../lib';
import { focusEditor } from '../../utils';
import { CustomSlateContext } from '../CustomSlate';

export const useHandleAction = ({ onEditorAction, onClearFormatting }) => {
  const editor = useSlate();
  const slateContext = useContext(CustomSlateContext);

  return useCallback(
    (event, action) => {
      event?.preventDefault();

      // Map editor action to text format
      const format = getEditorActionTextFormat(action);

      // Report executed action to parent component
      if (onEditorAction) {
        onEditorAction({ action, format });
      }

      // Process action
      switch (action) {
        case SLATE_EDITOR_ACTION.toggleBold:
        case SLATE_EDITOR_ACTION.toggleItalic:
        case SLATE_EDITOR_ACTION.toggleUnderline:
        case SLATE_EDITOR_ACTION.toggleStrikethrough: {
          toggleMark(editor, format);
          break;
        }

        case SLATE_EDITOR_ACTION.toggleParagraph:
        case SLATE_EDITOR_ACTION.toggleH1:
        case SLATE_EDITOR_ACTION.toggleH2:
        case SLATE_EDITOR_ACTION.toggleH3:
        case SLATE_EDITOR_ACTION.toggleBulletedList:
        case SLATE_EDITOR_ACTION.toggleNumberedList:
        case SLATE_EDITOR_ACTION.toggleBlockQuote: {
          toggleBlock(editor, format);
          break;
        }

        case SLATE_EDITOR_ACTION.alignTextLeft:
        case SLATE_EDITOR_ACTION.alignTextCenter:
        case SLATE_EDITOR_ACTION.alignTextRight: {
          toggleTextAlignmentBlock(editor, format);
          break;
        }

        case SLATE_EDITOR_ACTION.insertLink: {
          slateContext.openLinkInsertPopup();
          break;
        }

        case SLATE_EDITOR_ACTION.undo: {
          editor.undo();
          break;
        }

        case SLATE_EDITOR_ACTION.redo: {
          editor.redo();
          break;
        }

        case SLATE_EDITOR_ACTION.clearFormat: {
          const fragment = editor.getFragment();
          const text = slateToText(fragment);
          const nodes = textToSlate(text);
          editor.insertFragment(nodes);

          if (onClearFormatting) {
            onClearFormatting(text);
          }
          break;
        }

        default:
          break;
      }

      // Return focus to Editor after action was executed
      if (!ReactEditor.isFocused(editor)) {
        focusEditor({ editor });
      }
    },
    [editor, onClearFormatting, onEditorAction, slateContext]
  );
};
