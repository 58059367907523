import { createStore } from 'react-sweet-state';
import uniqueId from 'lodash/uniqueId';

import { NOTIFICATION_LEVEL, NOTIFICATION_TIMEOUTS } from 'src/data/notifications';
import { parseError } from 'src/lib/common';
import { message } from 'src/lib/log';

export const NAME = 'global-notification';

const createNotification = ({ level, title, description, options }) => ({
  id: uniqueId(),
  level,
  title,
  description,
  options,
});

const INITIAL_STATE = {
  notifications: [],

  upgradeAvailable: false,
};

/**
 * Private available actions
 */
const PRIVATE_ACTIONS = {
  addNotification:
    (notification) =>
    ({ getState, setState }) => {
      const { notifications } = getState();
      const newList = [...notifications, notification];
      setState({ notifications: newList });
    },

  removeNotification:
    (id) =>
    ({ getState, setState }) => {
      const { notifications } = getState();
      const newList = notifications.filter((n) => n.id !== id);
      setState({ notifications: newList });
    },
};

/**
 * Public available actions
 */
const ACTIONS = {
  displayMessage:
    (title, description, options) =>
    ({ dispatch }) => {
      const item = createNotification({
        level: NOTIFICATION_LEVEL.generic,
        title,
        description,
        options,
      });

      message('Info Message', item);

      dispatch(PRIVATE_ACTIONS.addNotification(item));
    },

  displaySuccess:
    (title, description, options) =>
    ({ dispatch }) => {
      if (!options?.timeout) {
        options = {
          ...options,
          timeout: NOTIFICATION_TIMEOUTS.success,
        };
      }
      const item = createNotification({
        level: NOTIFICATION_LEVEL.success,
        title,
        description,
        options,
      });

      message('Success Message', item);

      dispatch(PRIVATE_ACTIONS.addNotification(item));
    },

  displayWarning:
    (title, description, options) =>
    ({ dispatch }) => {
      const item = createNotification({
        level: NOTIFICATION_LEVEL.warning,
        title,
        description,
        options,
      });

      message('Warning Message', item);

      dispatch(PRIVATE_ACTIONS.addNotification(item));
    },

  displayError:
    (error, options) =>
    ({ dispatch }) => {
      const { title, description } = parseError(error);

      const item = createNotification({
        level: NOTIFICATION_LEVEL.error,
        title,
        description,
        options,
      });

      message('Error Message', item);

      dispatch(PRIVATE_ACTIONS.addNotification(item));
    },

  displayNotification:
    (notification) =>
    ({ dispatch }) => {
      message('Custom notification', notification);

      dispatch(PRIVATE_ACTIONS.addNotification(notification));
    },

  displayUpgradeAvailable:
    () =>
    ({ setState }) => {
      setState({ upgradeAvailable: true });
    },

  hide:
    (id) =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.removeNotification(id));
    },
};

const Store = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});

export default Store;
