import { useEffect } from 'react';

import { captureMessage } from 'src/lib/sentry';
import {
  useCurrentCustomerId,
  useCurrentWorkspaceId,
  useMultiPromptTemplateActions,
} from 'src/store';

export const usePreloadMultiPromptTemplates = () => {
  const currentCustomerId = useCurrentCustomerId();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const multiPromptTemplateActions = useMultiPromptTemplateActions();

  useEffect(() => {
    if (!currentCustomerId || !currentWorkspaceId) {
      return;
    }

    const fetchData = async () => {
      try {
        await multiPromptTemplateActions.queryWorkspaceMultiPromptTemplates({
          customerId: currentCustomerId,
          workspaceId: currentWorkspaceId,
        });
      } catch (error) {
        captureMessage('Error while getting multi prompt templates', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'usePreloadMultiPromptTemplates',
          },
        });
      }
    };

    fetchData();
  }, [multiPromptTemplateActions, currentCustomerId, currentWorkspaceId]);
};
