import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChannelFacebookPages = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 32 32"
    >
      <path
        d="M32 16.0978C32 24.1328 26.1488 30.7927 18.5 32V20.751H22.2281L22.9375 16.0978H18.5V13.0782C18.5 11.8048 19.12 10.5642 21.1075 10.5642H23.125V6.60261C23.125 6.60261 21.2938 6.2882 19.5431 6.2882C15.8888 6.2882 13.5 8.51673 13.5 12.5512V16.0978H9.4375V20.751H13.5V32C5.85125 30.7927 0 24.1328 0 16.0978C0 7.20753 7.16375 0 16 0C24.8363 0 32 7.20753 32 16.0978Z"
        fill="#1877F2"
      />
      <path
        d="M22.2281 20.625L22.9375 16H18.5V12.9987C18.5 11.7334 19.1199 10.5 21.1074 10.5H23.125V6.5625C23.125 6.5625 21.294 6.25 19.5434 6.25C15.8887 6.25 13.5 8.465 13.5 12.475V16H9.4375V20.625H13.5V31.8056C14.3146 31.9334 15.1495 32 16 32C16.8505 32 17.6854 31.9334 18.5 31.8056V20.625H22.2281Z"
        fill="white"
      />
    </SvgIcon>
  ))
);
