import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_INTEGRATION_ACCOUNT } from './schema';

const MUTATION = gql`
  mutation UnauthorizeChannel($channel: String!, $customerId: ID!) {
    unauthorizeChannel(channel: $channel, customerId: $customerId) {
      ...PerformanceCenterIntegrationAccount
    }
  }
  ${FRAGMENT_INTEGRATION_ACCOUNT}
`;

export const unauthorizeChannel = (channel, customerId) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { channel, customerId },
  }).then(({ data }) => getSingleProp(data));
};
