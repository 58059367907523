import React from 'react';

import {
  doesToolHasMultipleGenerationModes,
  getFormulaTitle,
  getToolDefaultGenerationMode,
  isBasicGenerationTool,
  getToolMixtureSubGenerationTools,
  getToolDescription,
  isContentImproverTool,
} from 'src/data/generationTool';
import {
  getDefaultSuggestionModeLabel,
  getGuidanceDataFromGenerationResult,
  getRecomposeModeLabel,
  getSuggestionModeLabel,
  isGenerationGuidanceType,
  prepareGuidanceModeFullTitle,
  isSuggestGenerationResult,
  AI_SUGGESTION_MODE,
  CASING_LABELS,
  CASING_TYPE,
} from 'src/data/generation';
import { CAPITALIZATION_LABEL } from 'src/data/project';
import { BaseItem } from '../BaseItem';

const getSuggestTitle = (generationData) => {
  const { suggestionAnnotationType, tovTitle, generationTool, suggestionMode, customFormulaName } =
    generationData;

  // If "guidance" mode was used
  if (isGenerationGuidanceType(suggestionAnnotationType)) {
    const guidanceData = getGuidanceDataFromGenerationResult(generationData);
    return prepareGuidanceModeFullTitle(guidanceData);
  }

  // if "custom formula" mode was used
  if (suggestionMode === AI_SUGGESTION_MODE.customFormula) {
    return customFormulaName || 'Custom formula';
  }

  // If "tone of voice" was used
  if (tovTitle) {
    return `${tovTitle} style`;
  }

  // If we have access to "generation tool", i.e. it's a new or properly migrated result
  if (generationTool) {
    const defaultMode = getToolDefaultGenerationMode(generationTool);
    const suggestionModeLabel = getSuggestionModeLabel(suggestionMode, generationTool);

    return suggestionMode === defaultMode
      ? getDefaultSuggestionModeLabel(generationTool)
      : suggestionModeLabel;
  }

  return getDefaultSuggestionModeLabel(generationTool);
};

const getRecomposeTitle = (generationData) => {
  const { recomposeMode, tovTitle, generationTool } = generationData;
  if (tovTitle) {
    return `${tovTitle} style`;
  }

  return getRecomposeModeLabel(recomposeMode, generationTool);
};

const parseSubGenerationToolsProps = (requestData) => {
  try {
    return JSON.parse(requestData.subGenerationToolsProps);
  } catch {
    return {};
  }
};

export const FrameworkItem = ({
  generationTool,
  requestData,
  vertical,
  multiline,
  baseItemClasses,
  casing,
  mixtureWrapperStyle,
}) => {
  const isBasic = generationTool && isBasicGenerationTool(generationTool);
  const toolHasMultipleGenerationModes = doesToolHasMultipleGenerationModes(generationTool);

  if ((isBasic && !toolHasMultipleGenerationModes) || isContentImproverTool(generationTool)) {
    return null;
  }

  const isSuggestion = isSuggestGenerationResult(requestData);
  const subGenerationToolsProps = getToolMixtureSubGenerationTools(requestData.generationTool);
  const maxChars = requestData.maxChars;
  const template = isSuggestion ? getSuggestTitle(requestData) : getRecomposeTitle(requestData);
  const baseItemLabel = getFormulaTitle(generationTool);
  const subGenerationToolsPropsParsed = parseSubGenerationToolsProps(requestData);

  return (
    <>
      {!subGenerationToolsProps || subGenerationToolsProps.length === 0 ? (
        <>
          <BaseItem
            label="Max Chars"
            vertical={vertical}
            classes={baseItemClasses}
            hideTitleColon={multiline}
          >
            {maxChars}
          </BaseItem>
          <BaseItem
            label={CAPITALIZATION_LABEL}
            vertical={vertical}
            classes={baseItemClasses}
            hideTitleColon={multiline}
          >
            {casing ? CASING_LABELS[casing] : CASING_LABELS[CASING_TYPE.default]}
          </BaseItem>
          <BaseItem
            label={baseItemLabel}
            vertical={vertical}
            classes={baseItemClasses}
            hideTitleColon={multiline}
          >
            {template}
          </BaseItem>
        </>
      ) : (
        subGenerationToolsProps.map((subGenerationTool, index) => {
          return (
            <div
              className={mixtureWrapperStyle}
              key={index}
            >
              <BaseItem
                label={`${getToolDescription(subGenerationTool)} Max Chars`}
                vertical={vertical}
                classes={baseItemClasses}
                hideTitleColon={multiline}
              >
                {subGenerationToolsPropsParsed?.[subGenerationTool].maxChars}
              </BaseItem>
              <BaseItem
                label={`${getToolDescription(subGenerationTool)} ${CAPITALIZATION_LABEL}`}
                vertical={vertical}
                classes={baseItemClasses}
                hideTitleColon={multiline}
              >
                {subGenerationToolsPropsParsed?.[subGenerationTool].casing
                  ? CASING_LABELS[subGenerationToolsPropsParsed?.[subGenerationTool].casing]
                  : CASING_LABELS[CASING_TYPE.default]}
              </BaseItem>
              <BaseItem
                label={`${getToolDescription(subGenerationTool)} ${baseItemLabel}`}
                vertical={vertical}
                classes={baseItemClasses}
                hideTitleColon={multiline}
              >
                {getSuggestTitle({
                  ...requestData,
                  generationTool: subGenerationTool,
                  suggestionMode: subGenerationToolsPropsParsed?.[subGenerationTool].suggestionMode,
                })}
              </BaseItem>
            </div>
          );
        })
      )}
    </>
  );
};
