import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { PROJECT_FROM_LIST_FRAGMENT } from './schema';

const PROJECTS_PAGE_SIZE = 12;

const QUERY = gql`
  query Projects(
    $customerId: ID!
    $cursor: String
    $filter: JSON
    $workspaceId: ID
    $projectFolderId: ID
    $sortOrder: SortOrderEnum
    $sortField: ProjectSortFieldEnum
  ) {
    projects(
      customerId: $customerId
      first: ${PROJECTS_PAGE_SIZE}
      after: $cursor,
      filter: $filter
      workspaceId: $workspaceId
      projectFolderId: $projectFolderId
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      edges {
        node {
          ...ProjectFromList
          savedVariationsCount
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PROJECT_FROM_LIST_FRAGMENT}
`;

export const queryProjects = ({
  customerId,
  workspaceId,
  cursor,
  filter,
  projectFolderId,
  sortOrder,
  sortField,
}) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      customerId,
      cursor,
      filter: JSON.stringify(filter),
      workspaceId,
      projectFolderId,
      sortOrder,
      sortField,
    },
  }).then(({ data }) => getSingleProp(data));
};
