import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { trim } from 'lodash';

import { extractCustomToneOfVoiceInsights } from 'src/graphql/toneOfVoice';

const DEFAULT_DEBOUNCE_TIMEOUT = 1000;
const EMPTY_STATE = [];

/**
 * Hook options
 * @typedef HookOptions
 * @type {object}
 * @property {boolean} [disabled] - should requests be disabled
 * @property {number} [debounceTimeout] - debounce timeout
 */

/**
 * Execute tones of voice extraction on provided text, debounced.
 *
 * @param {string} projectId - Project ID to extract tone of voice for
 * @param {string} text - Text to extract tone of voice in
 * @param {string} customTovId - Custom tone of voice ID
 * @param {HookOptions} [hookOptions] - Hook options
 * @returns {{loading: boolean, items: []|*}}
 */
export const useTextExtractToneOfVoiceDebounced = (
  { projectId, text, customTovId, displayBlogEmptyState = false },
  hookOptions = {}
) => {
  const disableRequests = hookOptions.disabled || false;
  const debounceTimeout = hookOptions.debounceTimeout || DEFAULT_DEBOUNCE_TIMEOUT;

  const [extractedTonesOfVoice, setExtractedTonesOfVoice] = useState(EMPTY_STATE);
  const [loading, setLoading] = useState(false);

  const fetchExtractedToneOfVoice = useDebouncedCallback(async (requestParams, requestOptions) => {
    try {
      const result = await extractCustomToneOfVoiceInsights(requestParams, requestOptions);
      setExtractedTonesOfVoice(result);
    } catch (error) {
      // we don't care much about errors here
    } finally {
      setLoading(false);
    }
  }, debounceTimeout);

  useEffect(() => {
    // Clean and do not query, if host component doesn't want it now
    if (disableRequests) {
      setExtractedTonesOfVoice(EMPTY_STATE);
      return;
    }

    // Return early, if no "text" to annotate was provided
    if (trim(text).length === 0 || !customTovId) {
      setExtractedTonesOfVoice(EMPTY_STATE);
      return;
    }

    // Mark as "loading" even when we wait for "debounced" request to fire
    setLoading(true);
    // Schedule debounced request
    const abortController = new AbortController();
    fetchExtractedToneOfVoice(
      { projectId, text, customTovId },
      {
        abortSignal: abortController.signal,
      }
    );

    // Clean up possible pending requests when we want to schedule new request or component unmounts
    return () => {
      abortController.abort();
      setLoading(false);
    };
  }, [disableRequests, fetchExtractedToneOfVoice, projectId, text, customTovId]);

  return useMemo(
    () => ({
      items: extractedTonesOfVoice,
      loading,
      displayBlogEmptyState,
    }),
    [extractedTonesOfVoice, loading, displayBlogEmptyState]
  );
};
