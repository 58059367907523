import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

//TODO: check size of this component in a bundle file, maybe it is better to store as a svg file, not a component
export const ToolTypeCIP = memo(
  forwardRef(({ ...restProps }, ref) => {
    return (
      <SvgIcon
        {...restProps}
        ref={ref}
        viewBox="0 0 142 132"
        data-fill-none
      >
        <g clipPath="url(#clip0_19700_6770)">
          <path
            d="M94.3904 2.64726C94.3904 2.64726 72.2341 -4.01313 50.3552 9.45546C28.4764 22.924 17.3213 48.0475 7.74984 68.2011C-1.82159 88.3548 3.48447 108.102 20.0633 116.394C36.6421 124.687 48.0476 110.664 71.3955 108.838C94.7434 107.012 101 109.98 120.453 96.0282C139.907 82.0762 143.608 51.2376 128.474 29.3165C113.34 7.39545 94.3904 2.64726 94.3904 2.64726Z"
            fill="#3D7FFE"
          />
          <path
            opacity="0.7"
            d="M94.3904 2.64726C94.3904 2.64726 72.2341 -4.01313 50.3552 9.45546C28.4764 22.924 17.3213 48.0475 7.74984 68.2011C-1.82159 88.3548 3.48447 108.102 20.0633 116.394C36.6421 124.687 48.0476 110.664 71.3955 108.838C94.7434 107.012 101 109.98 120.453 96.0282C139.907 82.0762 143.608 51.2376 128.474 29.3165C113.34 7.39545 94.3904 2.64726 94.3904 2.64726Z"
            fill="white"
          />
          <path
            d="M44.0299 14.4347L40.9741 9.45557H14.0547V14.4347V16.2268V99.0614H124.852V14.4347H44.0299Z"
            fill="#123068"
          />
          <path
            d="M124.129 16.7163H14.8828V97.2568H124.129V16.7163Z"
            fill="white"
          />
          <path
            d="M124.264 15.6294H14.4414V20.0112H124.264V15.6294Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeMiterlimit="10"
          />
          <path
            d="M124.129 16.7163H14.8828V97.2568H124.129V16.7163Z"
            stroke="#123068"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g opacity="0.17">
            <path
              d="M71.7931 24.2363H64.1493C64.0607 24.2363 63.9729 24.2184 63.8911 24.1837C63.8093 24.149 63.735 24.0982 63.6725 24.0341C63.61 23.97 63.5605 23.8939 63.5269 23.8103C63.4933 23.7266 63.4762 23.637 63.4766 23.5465C63.4766 23.3644 63.5474 23.1898 63.6736 23.061C63.7997 22.9322 63.9708 22.8599 64.1493 22.8599H71.7931C71.9715 22.8599 72.1426 22.9322 72.2688 23.061C72.3949 23.1898 72.4658 23.3644 72.4658 23.5465C72.4662 23.637 72.4491 23.7266 72.4155 23.8103C72.3818 23.8939 72.3324 23.97 72.2699 24.0341C72.2074 24.0982 72.1331 24.149 72.0513 24.1837C71.9694 24.2184 71.8817 24.2363 71.7931 24.2363Z"
              fill="#3D7FFE"
            />
            <path
              d="M87.0783 24.2363H79.4344C79.3458 24.2363 79.2581 24.2184 79.1763 24.1837C79.0945 24.149 79.0202 24.0982 78.9577 24.0341C78.8952 23.97 78.8457 23.8939 78.8121 23.8103C78.7784 23.7266 78.7613 23.637 78.7617 23.5465C78.7617 23.4564 78.7791 23.3671 78.8129 23.2838C78.8467 23.2005 78.8963 23.1248 78.9588 23.061C79.0212 22.9972 79.0954 22.9466 79.177 22.9121C79.2586 22.8776 79.3461 22.8599 79.4344 22.8599H87.0783C87.1669 22.8595 87.2547 22.8769 87.3366 22.9113C87.4186 22.9456 87.4931 22.9961 87.5559 23.0599C87.6187 23.1237 87.6685 23.1995 87.7025 23.283C87.7365 23.3666 87.754 23.4561 87.754 23.5465C87.754 23.7295 87.6828 23.9049 87.5561 24.0343C87.4294 24.1636 87.2575 24.2363 87.0783 24.2363Z"
              fill="#3D7FFE"
            />
            <path
              d="M102.36 24.2363H94.7187C94.5395 24.2363 94.3676 24.1636 94.2409 24.0343C94.1142 23.9049 94.043 23.7295 94.043 23.5465C94.043 23.4561 94.0605 23.3666 94.0945 23.283C94.1285 23.1995 94.1783 23.1237 94.2411 23.0599C94.3038 22.9961 94.3784 22.9456 94.4603 22.9113C94.5423 22.8769 94.6301 22.8595 94.7187 22.8599H102.36C102.448 22.8595 102.536 22.8769 102.618 22.9113C102.7 22.9456 102.774 22.9961 102.837 23.0599C102.9 23.1237 102.95 23.1995 102.984 23.283C103.018 23.3666 103.035 23.4561 103.035 23.5465C103.035 23.7295 102.964 23.9049 102.837 24.0343C102.711 24.1636 102.539 24.2363 102.36 24.2363Z"
              fill="#3D7FFE"
            />
            <path
              d="M117.652 24.2363H110.008C109.829 24.2363 109.657 24.1636 109.53 24.0343C109.403 23.9049 109.332 23.7295 109.332 23.5465C109.332 23.4561 109.35 23.3666 109.384 23.283C109.418 23.1995 109.467 23.1237 109.53 23.0599C109.593 22.9961 109.667 22.9456 109.749 22.9113C109.831 22.8769 109.919 22.8595 110.008 22.8599H117.652C117.74 22.8599 117.827 22.8776 117.909 22.9121C117.991 22.9466 118.065 22.9972 118.127 23.061C118.19 23.1248 118.239 23.2005 118.273 23.2838C118.307 23.3671 118.324 23.4564 118.324 23.5465C118.325 23.637 118.308 23.7266 118.274 23.8103C118.24 23.8939 118.191 23.97 118.128 24.0341C118.066 24.0982 117.992 24.149 117.91 24.1837C117.828 24.2184 117.74 24.2363 117.652 24.2363Z"
              fill="#3D7FFE"
            />
          </g>
          <path
            d="M82.9886 27.7305H17.9102V66.7383H82.9886V27.7305Z"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M18.2344 70.7568H36.9003"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M18.2344 74.7754H36.9003"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M18.2344 78.7969H36.9003"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M18.2344 82.8149H36.9003"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M115.411 27.7305H86.4609V51.3729H115.411V27.7305Z"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M115.411 44.3923L111.009 39.7889L105.453 42.6249L95.0309 33.6396L86.4609 43.1545V51.373H115.411V44.3923Z"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M105.8 35.7675C107.271 35.7675 108.464 34.5502 108.464 33.0486C108.464 31.5469 107.271 30.3296 105.8 30.3296C104.329 30.3296 103.137 31.5469 103.137 33.0486C103.137 34.5502 104.329 35.7675 105.8 35.7675Z"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M115.411 56.3369H86.4609V79.9793H115.411V56.3369Z"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M115.411 72.9988L111.009 68.3923L105.453 71.2313L95.0309 62.2461L86.4609 71.7609V79.9763H115.411V72.9988Z"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M108.571 61.7056C108.807 60.219 107.817 58.8191 106.36 58.5788C104.904 58.3386 103.533 59.3489 103.297 60.8356C103.062 62.3222 104.052 63.7221 105.508 63.9624C106.964 64.2026 108.336 63.1923 108.571 61.7056Z"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M18.1346 120.668C18.1346 120.668 10.1439 106.344 11.987 102.701C13.83 99.0583 18.5449 105.614 18.5449 105.614C18.5449 105.614 14.4454 92.2593 16.7018 86.4365C18.9581 80.6137 22.8464 86.4365 23.0516 90.5657C23.2567 94.695 22.2341 101.734 22.2341 101.734C22.2341 101.734 23.8721 87.1663 27.7664 91.5357C31.6607 95.9051 22.8464 110.476 22.8464 110.476C22.8464 110.476 28.789 107.089 28.5778 111.449C28.3667 115.809 21.4136 119.692 21.4136 119.692"
            fill="#3D7FFE"
          />
          <path
            d="M18.2042 117.999C18.2042 117.999 10.7986 110.956 14.4185 107.8C18.0383 104.644 19.9146 111.187 19.9146 111.187C19.9146 111.187 15.9267 91.0553 22.3761 90.3163C28.8254 89.5773 24.0713 107.8 24.0713 107.8C24.0713 107.8 29.5705 100.028 29.1994 106.344C28.8284 112.659 20.6687 117.999 20.6687 117.999"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M21.8382 116.718L16.8398 111.19"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M20.793 117.143L22.1444 100.986"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M20.5859 115.973L25.0625 109.916"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M27.06 114.845H13.2383L14.6198 131.488H25.6784L27.06 114.845Z"
            fill="#123068"
          />
          <path
            d="M98.716 109.099L102.559 119.174H94.4868L91.1445 109.099H98.716Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M93.9474 102.994H81.1875V114.944H93.9474V102.994Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M91.7755 105.675H79.0156V117.626H91.7755V105.675Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M88.463 108.01H75.7031V119.96H88.463V108.01Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M82.799 110.076H70.0391V122.026H82.799V110.076Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M98.7359 109.099H69.6445V131.488H98.7359V109.099Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M98.7308 109.099H77.2109V131.488H98.7308V109.099Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M77.2119 109.1L74.0566 119.175H65.9844L69.6404 109.1H77.2119Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M94.1429 113.318H80.8672V127.221H94.1429V113.318Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M94.1429 123.535L91.6573 120.893L89.4763 122.183L85.6514 118.433L80.9064 122.3L80.8672 122.386V127.221H94.1429V123.535Z"
            fill="#C5D9FF"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M91.0054 117.085C91.0054 117.405 90.9124 117.718 90.738 117.984C90.5637 118.251 90.3159 118.458 90.0259 118.581C89.736 118.704 89.417 118.736 89.1092 118.673C88.8014 118.611 88.5187 118.456 88.2968 118.23C88.0749 118.003 87.9237 117.715 87.8625 117.401C87.8013 117.086 87.8327 116.761 87.9528 116.465C88.0729 116.169 88.2763 115.916 88.5372 115.738C88.7981 115.56 89.1049 115.465 89.4187 115.465C89.8395 115.465 90.2431 115.635 90.5407 115.939C90.8382 116.243 91.0054 116.655 91.0054 117.085Z"
            fill="#C5D9FF"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M127.733 102.994H114.973V114.944H127.733V102.994Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M125.561 105.675H112.801V117.626H125.561V105.675Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M122.248 108.01H109.488V119.96H122.248V108.01Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M116.58 110.076H103.82V122.026H116.58V110.076Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M132.521 109.099H103.43V131.488H132.521V109.099Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M132.516 109.099H110.996V131.488H132.516V109.099Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M120.906 111.224V111.399C120.766 111.393 120.628 111.425 120.504 111.492C120.375 111.574 120.271 111.691 120.203 111.83C120.081 112.085 119.98 112.35 119.901 112.622L118.139 117.82H117.955L116.514 113.696L115.084 117.82H114.918L113.063 112.465C112.924 112.067 112.836 111.83 112.797 111.756C112.742 111.643 112.653 111.549 112.544 111.489C112.408 111.424 112.259 111.393 112.109 111.399V111.224H114.447V111.399H114.336C114.2 111.39 114.066 111.432 113.959 111.516C113.917 111.549 113.884 111.59 113.861 111.638C113.838 111.685 113.826 111.737 113.826 111.79C113.864 112.053 113.933 112.311 114.031 112.557L115.277 116.175L116.323 113.096L116.139 112.554L115.989 112.12C115.93 111.973 115.857 111.833 115.771 111.701C115.731 111.642 115.679 111.591 115.621 111.55C115.551 111.496 115.472 111.454 115.388 111.427C115.297 111.404 115.202 111.393 115.108 111.396V111.221H117.569V111.396H117.4C117.263 111.385 117.126 111.426 117.017 111.513C116.977 111.553 116.945 111.601 116.924 111.654C116.904 111.707 116.894 111.764 116.897 111.821C116.926 112.112 116.997 112.398 117.108 112.668L118.314 116.172L119.521 112.619C119.626 112.358 119.696 112.083 119.726 111.803C119.725 111.728 119.701 111.656 119.657 111.596C119.615 111.536 119.556 111.489 119.488 111.464C119.339 111.415 119.183 111.392 119.026 111.396V111.221L120.906 111.224Z"
            fill="#123068"
          />
          <path
            d="M121.406 112.444H128.981"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M121.406 114.236H128.981"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M121.406 116.028H128.981"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M121.406 117.823H128.981"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M116.58 87.6958H103.82V99.6463H116.58V87.6958Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M132.521 86.7197H103.43V109.109H132.521V86.7197Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M132.516 86.7197H110.996V109.109H132.516V86.7197Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M127.924 90.938H114.648V104.841H127.924V90.938Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M127.924 101.155L125.439 98.513L123.258 99.8032L119.433 96.0527L114.688 99.9203L114.648 100.01V104.841H127.924V101.155Z"
            fill="#C5D9FF"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M124.787 94.7042C124.787 95.0245 124.694 95.3376 124.519 95.604C124.345 95.8704 124.097 96.0779 123.807 96.2005C123.517 96.3231 123.198 96.3552 122.89 96.2927C122.583 96.2302 122.3 96.0759 122.078 95.8494C121.856 95.6229 121.705 95.3343 121.644 95.0201C121.583 94.706 121.614 94.3803 121.734 94.0843C121.854 93.7884 122.058 93.5354 122.318 93.3574C122.579 93.1795 122.886 93.0845 123.2 93.0845C123.621 93.0845 124.024 93.2551 124.322 93.5589C124.619 93.8626 124.787 94.2746 124.787 94.7042Z"
            fill="#C5D9FF"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M79.7988 46.2494L87.4185 58.4924L77.2227 58.4062C77.2227 58.4062 77.4791 48.2601 77.7355 47.1239C77.9919 45.9876 79.5424 45.9014 79.7988 46.2494Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M96.442 58.1936L35.1172 54.1016L32.9115 88.5463L94.2362 92.6383L96.442 58.1936Z"
            fill="#3D7FFE"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M94.606 86.8474L33.2812 82.7554L32.9105 88.5443L94.2353 92.6363L94.606 86.8474Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M92.9094 88.9396L34.7031 85.0557L34.611 86.4937L92.8174 90.3776L92.9094 88.9396Z"
            fill="#123068"
          />
          <path
            d="M36.1385 85.8944C36.1466 85.7608 36.1157 85.6277 36.0497 85.512C35.9838 85.3963 35.8857 85.3031 35.7679 85.2443C35.6501 85.1854 35.5179 85.1635 35.3879 85.1813C35.258 85.1991 35.1361 85.2558 35.0376 85.3442C34.9392 85.4327 34.8686 85.549 34.8349 85.6783C34.8011 85.8077 34.8056 85.9443 34.8479 86.071C34.8902 86.1978 34.9683 86.3089 35.0723 86.3903C35.1764 86.4718 35.3018 86.5199 35.4327 86.5287C35.5198 86.5345 35.6073 86.5228 35.69 86.494C35.7727 86.4653 35.8491 86.4201 35.9146 86.3612C35.9802 86.3023 36.0338 86.2307 36.0722 86.1506C36.1106 86.0705 36.1331 85.9834 36.1385 85.8944Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.8351 31.6785C58.8351 31.6785 57.5652 35.3058 57.6497 35.9894C57.7341 36.673 58.072 36.9409 57.6497 37.372C57.2273 37.8031 55.8729 38.8408 56.0418 39.4443C56.2108 40.0479 57.0584 40.7407 57.0584 40.7407C57.0584 40.7407 57.9875 44.5405 58.9196 44.9716C59.8517 45.4027 61.2876 45.0578 61.2876 45.0578L62.3011 47.8291L69.4081 46.0154L68.9857 41.609C68.9857 41.609 71.0973 37.4644 70.5936 34.961C70.0898 32.4575 65.6857 30.0342 62.8109 30.0342C59.9362 30.0342 59.0885 30.9887 58.8351 31.6785Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.8828 41.7871C58.8828 41.7871 59.9899 41.6393 60.3277 40.9526"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.9813 37.3598C58.9813 37.6677 58.8817 37.9018 58.7641 37.9018C58.6464 37.9018 58.5469 37.6585 58.5469 37.3598C58.5469 37.0611 58.6434 36.8179 58.7641 36.8179C58.8847 36.8179 58.9813 37.0611 58.9813 37.3598Z"
            fill="#123068"
          />
          <path
            d="M58.4609 36.131C58.4609 36.131 59.0884 35.3428 59.4715 36.3281"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M60.5233 33.0608C60.5233 33.0608 57.733 33.2333 57.564 30.5543C57.3951 27.8754 61.202 26.4097 62.6409 26.4097C64.0797 26.4097 63.0632 27.8785 63.0632 27.8785C63.0632 27.8785 66.6166 26.8408 67.5457 27.7922C68.4748 28.7437 67.7177 29.5197 67.7177 29.5197C67.7177 29.5197 72.3691 31.5058 72.3691 34.9607C72.3691 38.4156 69.9197 41.7874 69.4944 42.474C69.0691 43.1607 68.0555 43.7704 66.1943 43.8566C64.3331 43.9428 63.2321 42.9914 62.8098 41.7012L62.3875 40.4079C62.3875 40.4079 63.8233 38.8529 63.1476 37.5565C62.4719 36.2601 62.0466 37.0392 61.8777 37.5565C61.7087 38.0738 62.0466 38.5942 61.7087 38.5942C61.3709 38.5942 61.6243 38.1631 60.6952 37.2116C59.7661 36.2601 59.0029 35.8291 59.3408 34.8807C59.6204 34.2041 60.0214 33.5869 60.5233 33.0608Z"
            fill="#123068"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M60.2731 47.4101L59.2656 49.6856L72.11 46.7049L70.6228 44.8081L60.2731 47.4101Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M50.2913 72.6167C50.2913 72.6167 47.694 76.5828 46.6956 78.652C45.6971 80.7213 42.8042 84.7581 42.1316 85.1492C41.4589 85.5403 39.3413 85.3463 39.1512 85.4941C38.9612 85.6419 37.3232 87.5048 36.8919 88.0437C36.4605 88.5826 36.9884 88.7827 37.3715 88.8289C37.7546 88.8751 38.189 88.6349 38.6686 88.1915C38.9949 87.9104 39.3985 87.7393 39.8239 87.7019C40.017 87.6526 38.5268 89.7619 38.5751 90.1068C38.6468 90.3119 38.7759 90.4909 38.9464 90.6218C39.117 90.7526 39.3214 90.8294 39.5343 90.8427C39.8722 90.7934 40.7409 90.156 40.7409 90.156C40.7409 90.156 43.1542 90.4978 43.863 89.9097C44.5719 89.3216 45.5945 85.9837 45.7876 85.5433C45.9806 85.103 51.4074 80.0254 53.4737 78.3564C55.54 76.6875 56.7919 75.1663 56.7919 73.8915C56.7919 72.6167 50.2913 72.6167 50.2913 72.6167Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M65.4314 125.25C65.4314 125.25 61.6064 126.174 59.3772 126.174C57.148 126.174 56.1797 126.057 56.1797 126.814C56.1797 127.572 59.7211 128.97 61.7753 129.789C63.8296 130.608 67.8295 131.772 68.1734 131.655C68.5173 131.538 69.202 127.282 69.202 127.282L65.4314 125.25Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M56.4071 126.38C56.3329 126.426 56.2725 126.491 56.2326 126.569C56.1926 126.648 56.1747 126.736 56.1809 126.824C56.1809 127.581 59.7223 128.979 61.7765 129.798C63.8308 130.617 67.8307 131.781 68.1746 131.664C68.253 131.637 68.3495 131.393 68.4521 131.033C64.4522 130.22 58.0571 127.184 56.4071 126.38Z"
            fill="#123068"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.577 75.4007C58.577 75.4007 58.5197 79.9488 58.9209 83.8563C59.3221 87.7639 61.1471 104.903 61.3342 107.585C61.5212 110.267 65.4457 125.251 65.4457 125.251L69.2163 127.292C69.2163 127.292 71.1017 110.618 71.1017 107.819V103.154C71.1017 103.154 74.4198 89.9132 75.4424 86.7724C76.465 83.6315 76.9839 77.3838 76.8693 73.8857C73.8585 75.0877 70.6751 75.7773 67.4456 75.9273C62.1185 76.1582 58.577 75.4007 58.577 75.4007Z"
            fill="#123068"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M79.4544 51.2346L77.9129 63.8287C77.9129 63.8287 77.3096 73.3743 77.1437 74.2365C76.9778 75.0987 76.1995 75.5483 72.6883 76.7707C69.1771 77.9932 59.666 77.1218 58.8937 76.3335C58.1215 75.5452 57.9526 67.8502 57.9526 67.8502C57.9526 67.8502 56.924 72.312 56.1427 72.9248C55.3614 73.5375 52.4595 73.2727 51.09 71.961C49.7205 70.6492 51.3464 55.6071 51.2589 54.2092C51.1714 52.8112 59.666 49.3994 59.666 49.3994C59.666 49.3994 71.4515 46.6281 72.1122 46.7051C72.7728 46.7821 79.2854 45.3748 79.7983 46.2493C80.3111 47.1238 80.6549 47.6504 80.6549 47.6504"
            fill="white"
          />
          <path
            d="M79.4544 51.2346L77.9129 63.8287C77.9129 63.8287 77.3096 73.3743 77.1437 74.2365C76.9778 75.0987 76.1995 75.5483 72.6883 76.7707C69.1771 77.9932 59.666 77.1218 58.8937 76.3335C58.1215 75.5452 57.9526 67.8502 57.9526 67.8502C57.9526 67.8502 56.924 72.312 56.1427 72.9248C55.3614 73.5375 52.4595 73.2727 51.09 71.961C49.7205 70.6492 51.3464 55.6071 51.2589 54.2092C51.1714 52.8112 59.666 49.3994 59.666 49.3994C59.666 49.3994 71.4515 46.6281 72.1122 46.7051C72.7728 46.7821 79.2854 45.3748 79.7983 46.2493C80.3111 47.1238 80.6549 47.6504 80.6549 47.6504"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M57.9543 67.8498L55.6406 56.395"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M70.4678 52.1147L65.1406 68.4224"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M69.0138 62.7383L65.1406 72.6226"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M50.4823 68.2998C50.3551 68.4867 50.2887 68.7097 50.2922 68.9372C50.2922 69.3283 49.523 72.4691 49.8579 73.1065C50.1927 73.7439 51.5894 74.5784 53.8487 74.6769C56.1081 74.7754 56.9738 74.4306 57.1669 73.8424C57.36 73.2543 57.8909 70.3598 57.8426 70.0149C57.7943 69.6701 57.5048 69.8671 57.5048 69.8671C56.0346 70.1746 54.5118 70.0908 53.0825 69.6239C50.8201 68.8387 50.4823 68.2998 50.4823 68.2998Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M58.8672 78.7383C58.8672 78.7383 58.9818 88.5333 59.2081 94.3038C59.4343 100.074 60.3513 105.081 60.6952 106.257C61.0391 107.434 68.8036 117.567 70.5743 119.723C72.345 121.878 75.7718 125.145 75.7718 125.145L79.642 122.99C79.642 122.99 74.6315 115.689 73.0298 112.302C71.428 108.915 68.6317 103.323 68.6317 103.323L70.4024 87.1723L73.3163 85.5403"
            fill="#123068"
          />
          <path
            d="M58.8672 78.7383C58.8672 78.7383 58.9818 88.5333 59.2081 94.3038C59.4343 100.074 60.3513 105.081 60.6952 106.257C61.0391 107.434 68.8036 117.567 70.5743 119.723C72.345 121.878 75.7718 125.145 75.7718 125.145L79.642 122.99C79.642 122.99 74.6315 115.689 73.0298 112.302C71.428 108.915 68.6317 103.323 68.6317 103.323L70.4024 87.1723L73.3163 85.5403"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M75.8352 124.982C75.8352 124.982 73.9982 127.754 73.2983 128.416C72.5985 129.078 69.8746 130.744 69.4945 131.135C69.1144 131.526 71.2079 131.846 72.7282 131.846C74.2485 131.846 76.152 131.523 77.1052 130.922C78.0584 130.322 81.6059 127.751 82.0493 127.298C82.4927 126.845 79.6361 122.962 79.6361 122.962L75.8352 124.982Z"
            fill="white"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M82.0493 127.313C82.2031 127.159 81.9709 126.608 81.5998 125.946C80.2122 127.436 77.1414 130.608 75.8352 131.002C74.4054 131.436 70.7584 131.147 69.6001 131.039C69.5625 131.068 69.5272 131.1 69.4945 131.134C69.1144 131.522 71.2079 131.846 72.7282 131.846C74.2485 131.846 76.152 131.522 77.1052 130.922C78.0584 130.321 81.6059 127.766 82.0493 127.313Z"
            fill="#123068"
            stroke="#123068"
            strokeWidth="0.5"
            strokeMiterlimit="10"
          />
          <path
            d="M138.996 131.489H8.65234"
            stroke="#123068"
            strokeMiterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_19700_6770">
            <rect
              width="136"
              height="131"
              fill="white"
              transform="translate(3 1)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
