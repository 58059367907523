import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconEngagingQuestions = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 30 30"
        data-fill-none
      >
        <rect
          x="3"
          y="3"
          width="24"
          height="24"
          rx="4"
          fill="#28354D"
        />
        <path
          d="M19.3373 9.34085C20.068 9.34085 20.6625 9.93321 20.6625 10.6612C20.6625 10.8483 20.8135 11 20.9999 11C21.1862 11 21.3373 10.8483 21.3373 10.6612C21.3373 9.93082 21.9318 9.33661 22.6625 9.33661C22.8489 9.33661 22.9999 9.18488 22.9999 8.99787C22.9999 8.81076 22.8489 8.65912 22.6625 8.65912C21.9318 8.65912 21.3373 8.06676 21.3373 7.33873C21.3373 7.15163 21.1862 6.99998 20.9999 6.99998C20.8135 6.99998 20.6625 7.15163 20.6625 7.33873C20.6625 8.06914 20.068 8.66336 19.3373 8.66336C19.1509 8.66336 18.9999 8.815 18.9999 9.0021C18.9999 9.1892 19.1509 9.34085 19.3373 9.34085Z"
          fill="white"
        />
        <path
          d="M19.253 22.7556C19.8011 22.7556 20.247 23.1999 20.247 23.7459C20.247 23.8862 20.3602 24 20.5 24C20.6398 24 20.753 23.8862 20.753 23.7459C20.753 23.1981 21.1989 22.7525 21.747 22.7525C21.8867 22.7525 22 22.6387 22 22.4984C22 22.3581 21.8867 22.2444 21.747 22.2444C21.1989 22.2444 20.753 21.8001 20.753 21.2541C20.753 21.1137 20.6398 21 20.5 21C20.3602 21 20.247 21.1137 20.247 21.2541C20.247 21.8019 19.8011 22.2475 19.253 22.2475C19.1133 22.2475 19 22.3613 19 22.5016C19 22.6419 19.1133 22.7556 19.253 22.7556Z"
          fill="white"
        />
        <path
          d="M10.3498 15.6598C9.75238 15.6598 9.19135 15.4158 8.76984 14.9727C8.34849 14.5298 8.11651 13.9399 8.11651 13.3117C8.11651 13.1395 7.98464 13 7.82211 13C7.65951 13 7.52772 13.1395 7.52772 13.3117C7.52772 13.9399 7.29565 14.5298 6.8743 14.9727C6.4528 15.4158 5.89177 15.6598 5.29439 15.6598C5.13179 15.6598 5 15.7994 5 15.9716C5 16.1437 5.13179 16.2833 5.29439 16.2833C6.52579 16.2833 7.52772 17.3398 7.52772 18.6383C7.52772 18.8105 7.65951 18.95 7.82211 18.95C7.98464 18.95 8.11651 18.8105 8.11651 18.6383C8.11651 17.3398 9.11836 16.2833 10.3498 16.2833C10.5124 16.2833 10.6441 16.1437 10.6441 15.9716C10.6441 15.7994 10.5124 15.6598 10.3498 15.6598Z"
          fill="white"
        />
        <path
          d="M13.9109 16.9553V16.7935C13.9139 16.2385 13.9599 15.7961 14.049 15.4662C14.1411 15.1364 14.2747 14.87 14.4499 14.667C14.6251 14.464 14.8359 14.2801 15.0824 14.1151C15.2665 13.9883 15.4313 13.8566 15.5768 13.7203C15.7223 13.5839 15.8382 13.4332 15.9243 13.2683C16.0104 13.1002 16.0535 12.9131 16.0535 12.7069C16.0535 12.4881 16.0045 12.2962 15.9065 12.1313C15.8085 11.9664 15.6763 11.8395 15.51 11.7507C15.3467 11.6619 15.1656 11.6175 14.9666 11.6175C14.7736 11.6175 14.5909 11.6635 14.4187 11.7555C14.2465 11.8443 14.1054 11.9775 13.9955 12.1551C13.8857 12.3295 13.8263 12.5468 13.8174 12.8069H12C12.0148 12.1725 12.1574 11.6492 12.4276 11.2369C12.6978 10.8214 13.0557 10.5122 13.5011 10.3092C13.9465 10.1031 14.438 10 14.9755 10C15.5664 10 16.0891 10.1047 16.5434 10.314C16.9978 10.5201 17.3541 10.8199 17.6125 11.2131C17.8708 11.6064 18 12.0806 18 12.6356C18 13.0067 17.9421 13.3365 17.8263 13.6251C17.7134 13.9106 17.5546 14.1643 17.3497 14.3863C17.1448 14.6051 16.9027 14.8034 16.6236 14.981C16.389 15.13 16.196 15.2854 16.0445 15.4472C15.8961 15.6089 15.7847 15.7961 15.7105 16.0086C15.6392 16.2211 15.6021 16.4827 15.5991 16.7935V16.9553H13.9109ZM14.7929 20C14.4959 20 14.242 19.889 14.0312 19.667C13.8233 19.4418 13.7209 19.1722 13.7238 18.8582C13.7209 18.5474 13.8233 18.281 14.0312 18.059C14.242 17.837 14.4959 17.726 14.7929 17.726C15.075 17.726 15.3229 17.837 15.5367 18.059C15.7506 18.281 15.8589 18.5474 15.8619 18.8582C15.8589 19.0676 15.807 19.2594 15.706 19.4339C15.608 19.6051 15.4788 19.7431 15.3185 19.8478C15.1581 19.9493 14.9829 20 14.7929 20Z"
          fill="white"
        />
      </SvgIcon>
    );
  })
);
