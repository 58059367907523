import { useCallback } from 'react';

import { usePopups, IMPORT_TONE_OF_VOICE_POPUP } from 'src/services/popup';

export const useImportToneOfVoicePopup = () => {
  const popups = usePopups();

  return useCallback(
    (brandVoice) => {
      return new Promise((resolve) => {
        popups.openPopup(IMPORT_TONE_OF_VOICE_POPUP, {
          brandVoice,
          onClose: (result) => {
            popups.closePopup(IMPORT_TONE_OF_VOICE_POPUP);
            resolve(result);
          },
        });
      });
    },
    [popups]
  );
};
