import { INDUSTRY_UNDEFINED_ID } from 'src/data/industry';

export const SCORING_METHOD_TYPE = {
  contentTaxonomy: 'ContentTaxonomy',
  customModel: 'CustomModel',
  autoClassifierScoringModel: 'AutoClassifierScoringModel',
};

export const createScoringMethod = ({ id, type, name }) => ({
  id,
  type,
  name,
});

export const createTaxonomyScoringMethod = (id) =>
  createScoringMethod({ id, type: SCORING_METHOD_TYPE.contentTaxonomy });

export const serializeScoringMethod = (method) => {
  if (!method) {
    return '';
  }
  return `${method.type}_${method.id}`;
};

export const DEFAULT_SCORING_METHOD = createTaxonomyScoringMethod(INDUSTRY_UNDEFINED_ID);

export const mapScoringMethodForMutation = (scoringMethod) =>
  scoringMethod
    ? {
        id: scoringMethod.id,
        type: scoringMethod.type,
      }
    : undefined;

export const isSameScoringMethod = (method1, method2) =>
  serializeScoringMethod(method1) === serializeScoringMethod(method2);
