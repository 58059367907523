import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MUIButton from '@material-ui/core/Button';

import { CustomCircularProgress } from 'src/components/CustomCircularProgress';
import { CUSTOM_BUTTON_VARIANT, CUSTOM_BUTTON_COLOR, CUSTOM_BUTTON_SIZE } from './const';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  variant: PropTypes.oneOf(Object.values(CUSTOM_BUTTON_VARIANT)),
  color: PropTypes.oneOf(Object.values(CUSTOM_BUTTON_COLOR)),
  size: PropTypes.oneOf(Object.values(CUSTOM_BUTTON_SIZE)),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  className: null,
  classes: null,
  variant: CUSTOM_BUTTON_VARIANT.contained,
  color: CUSTOM_BUTTON_COLOR.primary,
  size: CUSTOM_BUTTON_SIZE.medium,
  startIcon: null,
  endIcon: null,
  loading: false,
  disabled: false,
  children: null,
};

export const CustomButton = forwardRef(
  (
    {
      className,
      classes,
      variant,
      color,
      size,
      startIcon,
      endIcon,
      loading,
      disabled,
      children,
      ...props
    },
    ref
  ) => {
    const styles = useStyles({ classes, variant, color, size });
    const icons = prepareIcons({ startIcon, endIcon, loading });

    return (
      <MUIButton
        className={cx(styles.root, className, {
          [styles.loading]: loading,
          [styles.disabled]: disabled,
        })}
        ref={ref}
        startIcon={icons.startIcon}
        endIcon={icons.endIcon}
        disabled={disabled}
        variant="text"
        color="default"
        size="medium"
        data-variant={variant}
        data-color={color}
        data-size={size}
        {...props}
      >
        {children}
      </MUIButton>
    );
  }
);

CustomButton.propTypes = propTypes;
CustomButton.defaultProps = defaultProps;

const renderLoadingIcon = () => (
  <CustomCircularProgress
    size={16}
    color="inherit"
  />
);

const prepareIcon = ({ icon, loading }) => {
  if (!icon) {
    return null;
  }

  return loading ? renderLoadingIcon() : icon;
};

const prepareIcons = ({ startIcon, endIcon, loading }) => {
  const result = {
    startIcon: prepareIcon({ icon: startIcon, loading }),
    endIcon: prepareIcon({ icon: endIcon, loading }),
  };

  if (loading && !startIcon && !endIcon) {
    result.startIcon = renderLoadingIcon();
  }

  return result;
};
