import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { CUSTOMER_MEMBER_FRAGMENT } from './schema';

const QUERY = gql`
  query CustomerTeamMembers($id: ID!) {
    customer(id: $id) {
      id
      teamMembers {
        ...CustomerMember
      }
    }
  }
  ${CUSTOMER_MEMBER_FRAGMENT}
`;

export const queryMembers = (id) =>
  executeGqlQuery({
    query: QUERY,
    variables: { id },
  })
    .then(({ data }) => getSingleProp(data))
    .then((customer) => customer?.teamMembers || []);
