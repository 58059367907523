// We use module.exports syntax here because Node doesn't support ES6 module syntax,
// Node support is needed here because this file is also used in tailwind.config.js

module.exports.COLOR_PRIMARY = '#3D7FFE';
module.exports.COLOR_SECONDARY = '#41C493';
module.exports.COLOR_TEXT_PRIMARY = '#28354D';
module.exports.COLOR_TEXT_SECONDARY = '#828486';
module.exports.COLOR_TEXT_SECONDARY_BLUISH = '#7F91AD';
module.exports.COLOR_TEXT_DISABLED = '#BDBDBD';
module.exports.COLOR_DIVIDER = '#E6EAF6';
module.exports.COLOR_DIVIDER_GRAY = '#D2D6E0';
module.exports.COLOR_DIVIDER_LIGHT_GRAY = '#EFF1F5';
module.exports.COLOR_DIVIDER_LIGHT_BLUE = '#DEE6F7';

module.exports.COLOR_DARK_GRAY = '#28354D';
module.exports.COLOR_DARK_HOVER = '#3F4C66';
module.exports.COLOR_DARK_HOVER_SECONDARY = '#333F56';
module.exports.COLOR_DARK_SELECTED = '#1C2536';

module.exports.COLOR_ICON_INACTIVE = '#8E969D';
module.exports.COLOR_ICON_DISABLED = '#DADFE9';

module.exports.COLOR_BG_PRIMARY = '#84B8FF';
module.exports.COLOR_BG_SECONDARY = '#AFD1FE';

module.exports.COLOR_GREEN_ACTIVE = '#31A266';
module.exports.COLOR_FAVORITE = '#FBBC04';

module.exports.COLOR_M_GREY = '#6F80A2';

module.exports.COLORS = {
  primary: {
    active: '#0C5CEF',
    default: '#3D7FFE',
    medium: '#91B6FF',
    light: '#C9DBFF',
    silver: '#ECF2FF',
  },
  neutral: {
    black: '#0A1329',
    blackBlue: '#1C2536',
    blackBlueLighter: '#2F393E',
    secondary: '#28354D',
    darkGray: '#3F4C66',
    gray: module.exports.COLOR_DIVIDER_GRAY,
    mediumGray: '#6F80A2',
    mediumLightGray: '#BAC1CF',
    lightGray: '#CBD3E1',
    silver: '#F7F9FD',
    white: '#FFFFFF',
  },
  gray: {
    white: '#FFFFFF',
    black: '#1E1E1E',
    gray30: '#626266',
    gray40: '#8C8C8C',
    gray50: '#B4B4B4',
    gray60: '#E2E2E2',
    gray70: '#E9E9E9',
    gray80: '#F0F0F0',
    gray90: '#F6F6F6',
    background: '#FAFAFA',
  },
  success: {
    active: '#3AA97F',
    default: '#41C493',
    light: '#C5EFDF',
    badge: '#DFFDF2',
    extraLight: '#E3FFEE',
  },
  warning: {
    active: '#E48900',
    default: '#FFB700',
    light: '#FFEDBF',
    extraLight: '#FEF4D9',
  },
  error: {
    active: '#EC5248',
    default: '#FF5A4F',
    light: '#FFC8C3',
    extraLight: '#FEF2F2',
  },
  text: {
    primary: '#28354D',
    secondary: '#828486',
  },
};
