import React, { forwardRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ListItemIcon, MenuItem, Tooltip } from '@material-ui/core';

import { TruncatedSpan } from 'src/components/TruncatedSpan';
import { useStyles } from './useStyles';

export const ItemDefault = memo(
  forwardRef(({ className, classes, item, onClick, endIcon, disabled, ...restProps }, ref) => {
    const styles = useStyles({ classes });

    const handleMenuItemClick = useCallback(
      (event) => {
        event.stopPropagation();

        // Call "on click" handler of parent menu
        if (onClick) {
          onClick(event, item);
        }

        // Call "on click" handler of the current item
        if (item.onClick) {
          item.onClick(event);
        }
      },
      [onClick, item]
    );

    return (
      <Tooltip
        classes={{ tooltip: styles.tooltip }}
        title={item.tooltip || ''}
        arrow
      >
        <div className={styles.itemWrapper}>
          <MenuItem
            className={cx(
              styles.root,
              className,
              { [styles.delete]: item.id === 'delete' },
              { [styles.emptyState]: item.emptyState },
              { [styles.title]: item.title },
              { [styles.createButton]: item.create }
            )}
            ref={ref}
            id={item.id}
            onClick={item.emptyState || item.title ? undefined : handleMenuItemClick}
            disabled={disabled || item.disabled}
            data-testid={`ContextMenu__Item__${item.id}`}
            {...restProps}
          >
            <div className={styles.header}>
              {item.icon && (
                <ListItemIcon className={styles.listItemIcon}>{item.icon}</ListItemIcon>
              )}

              <TruncatedSpan className={styles.text}>{item.label}</TruncatedSpan>

              {item.endIcon ? item.endIcon : endIcon}
            </div>
            {item.subtitle && <p className={styles.subtitle}>{item.subtitle}</p>}
          </MenuItem>
        </div>
      </Tooltip>
    );
  })
);

ItemDefault.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.shape({}),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    subtitle: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    emptyState: PropTypes.bool,
    create: PropTypes.bool,
    title: PropTypes.bool,
    tooltip: PropTypes.string,
    endIcon: PropTypes.node,
    body: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  endIcon: PropTypes.node,
};

ItemDefault.defaultProps = {
  className: '',
  classes: null,
  disabled: false,
  endIcon: null,
};
