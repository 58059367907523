import React, { useState, useMemo, useCallback } from 'react';

import { useUserActions, useOnboardingActions } from 'src/store';
import { useErrorHandler } from 'src/services/useErrorHandler';
import { Runner } from './components/Runner';
import { STEPS_DATA } from './steps';
import { useViewState } from './useViewState';

const getStep = (stepId) => STEPS_DATA.find(({ id }) => id === stepId);

export const OnboardingRoot = () => {
  const { isActive, currentStepId, currentStepIndex, stepsCount, isLastStepActive } =
    useViewState();
  const handleError = useErrorHandler();
  const userActions = useUserActions();
  const onboardingActions = useOnboardingActions();

  const activeStep = useMemo(() => {
    if (!isActive || !currentStepId) {
      return null;
    }

    return getStep(currentStepId);
  }, [isActive, currentStepId]);

  const [dontShowAgain, setDontShowAgain] = useState(false);
  const handleChangeDontShowAgain = () => {
    setDontShowAgain((value) => !value);
  };

  /**
   * Handle 'next' clicks
   */
  const handleNextStep = useCallback(() => {
    const stepId = onboardingActions.getCurrentStepId();
    const stage = onboardingActions.getCurrentStage();

    // Mark step as finished and continue with next step
    onboardingActions.finishStep(stepId);
    const step = getStep(stepId);

    if (onboardingActions.isLastStep(stepId)) {
      onboardingActions.stopOnboarding();

      if (!step.content?.dontShowAgain || dontShowAgain) {
        userActions.finishOnboardingStage(stage).catch((error) => {
          handleError(error);
        });
      }
    }
  }, [userActions, onboardingActions, handleError, dontShowAgain]);

  const onPause = useCallback(() => {
    onboardingActions.onPause();
  }, [onboardingActions]);

  const onContinue = useCallback(() => {
    onboardingActions.onContinue();
  }, [onboardingActions]);

  if (!isActive || !activeStep) {
    return null;
  }

  return (
    <Runner
      rootElement={window.document.body}
      step={activeStep}
      onPause={onPause}
      onContinue={onContinue}
      onNextStep={handleNextStep}
      isLastStep={isLastStepActive}
      stepsCount={stepsCount}
      currentStepIndex={currentStepIndex}
      dontShowAgain={dontShowAgain}
      onDontShowAgainChange={handleChangeDontShowAgain}
    />
  );
};
