import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconYoutubeWithWhiteTriangle = memo(
  forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 50 51">
      <path d="M20 18.7002L32.0833 25.5002L20 32.3002V18.7002Z" fill="white" />
      <path
        d="M45.951 15.2928C45.7035 14.3919 45.2212 13.5764 44.5556 12.9328C43.8711 12.2694 43.0322 11.7949 42.118 11.554C38.6967 10.6342 24.9897 10.6342 24.9897 10.6342C19.2754 10.5679 13.5625 10.8596 7.88321 11.5076C6.96903 11.7663 6.13169 12.2514 5.44568 12.9198C4.77162 13.5813 4.28338 14.3971 4.02833 15.2909C3.41566 18.6575 3.11798 22.0759 3.13931 25.4999C3.11745 28.9209 3.4144 32.3381 4.02833 35.7089C4.27792 36.599 4.76433 37.4111 5.44021 38.067C6.11609 38.723 6.95775 39.1968 7.88321 39.4477C11.3501 40.3656 24.9897 40.3656 24.9897 40.3656C30.7113 40.432 36.4315 40.1403 42.118 39.4923C43.0322 39.2514 43.8711 38.7768 44.5556 38.1135C45.2296 37.4575 45.7087 36.6418 45.9492 35.7535C46.5779 32.3882 46.8836 28.9684 46.8619 25.5427C46.9092 22.1024 46.6039 18.6665 45.951 15.2909V15.2928ZM20.632 31.8624V19.1393L32.0363 25.5018L20.632 31.8624Z"
        fill="currentColor"
      />
    </SvgIcon>
  ))
);
