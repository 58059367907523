import { createContainer } from 'react-sweet-state';

import Store, { PRIVATE_ACTIONS } from './Store';

const Container = createContainer(Store, {
  onInit: () => ({ setState, dispatch }, { state }) => {
    dispatch(PRIVATE_ACTIONS.init());

    if (state) {
      setState(state);
    }
  },
  onUpdate: () => ({ setState }, { state }) => {
    if (state) {
      setState(state);
    }
  },
});

export default Container;
