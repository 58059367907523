import React from 'react';
import PropTypes from 'prop-types';

import { TEXT_ANNOTATION_TYPE, getTextAnnotationTypeLabel } from 'src/data/annotation';
import { InsightBox } from '../InsightBox';
import { useStyles } from './useStyles';
import Typography from '@material-ui/core/Typography';
import { Loader } from './components/Loader';

export const ScoreAdditionalFactors = ({ campaignKeywords, headlineType, loading }) => {
  const styles = useStyles();

  const haveAdditionalFactors = Boolean(headlineType) || campaignKeywords?.length > 0;
  if (!haveAdditionalFactors) {
    return null;
  }

  return (
    <InsightBox className={styles.root}>
      <div className={styles.header}>
        <Typography className={styles.title}>Additional factors that impact your score</Typography>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.infoContainer}>
            {headlineType && (
              <div className={styles.infoItem}>
                <Typography className={styles.infoTitle}>
                  {getTextAnnotationTypeLabel(TEXT_ANNOTATION_TYPE.headlineType)}
                </Typography>
                <Typography className={styles.infoContent}>{headlineType}</Typography>
              </div>
            )}

            {campaignKeywords?.length > 0 && (
              <div className={styles.infoItem}>
                <Typography className={styles.infoTitle}>
                  {getTextAnnotationTypeLabel(TEXT_ANNOTATION_TYPE.campaignKeywords)}
                </Typography>

                <div className={styles.keywordsContainer}>
                  {campaignKeywords.map((keyword) => (
                    <Typography
                      key={keyword}
                      className={styles.infoContent}
                    >
                      {keyword}
                    </Typography>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </InsightBox>
  );
};

ScoreAdditionalFactors.propTypes = {
  loading: PropTypes.bool,
  campaignKeywords: PropTypes.arrayOf(PropTypes.string),
  headlineType: PropTypes.string,
};

ScoreAdditionalFactors.defaultProps = {
  loading: false,
  campaignKeywords: null,
  headlineType: '',
};
