import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    iconButton: {
      color: COLORS.gray.gray40,
      padding: theme.spacing(0),
      borderRadius: theme.shape.borderRadiusMedium,
      fontWeight: theme.typography.fontWeightMedium,
      '& svg': {
        fontSize: theme.typography.pxToRem(28),
      },
    },
    paper: {
      marginRight: theme.spacing(1),
    },
    activeAction: {
      marginTop: theme.spacing(5),
    },
    removeIntegrationLabel: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.error.active,
    },
  }),
  { name: 'ActionsMenu' }
);
