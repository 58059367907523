import { useCurrentCustomer } from 'src/store';
import {
  getCustomerPlan,
  isSelfServeBusinessPlan,
  isEnterpriseOrBusinessPlan,
} from 'src/data/subscription';

export const useIsCustomerOnBusinessOrEnterprisePlan = () => {
  const currentCustomer = useCurrentCustomer();

  const plan = getCustomerPlan(currentCustomer);
  return isSelfServeBusinessPlan(plan) || isEnterpriseOrBusinessPlan(plan);
};
