import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    root: {
      '& div': {
        borderRadius: 8,
      },

      '&$silentLooped': {
        '& .w-bottom-bar': {
          display: 'none !important', // couldn't find a way to hide "captions" button in "silentLooped" mode, so just hiding the whole bar
        },
      },
    },
    silentLooped: {},
  }),
  { name: 'WistiaEmbed' }
);
