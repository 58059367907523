import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    rightMargin: {},

    root: {
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: theme.spacing(3),
      gap: theme.spacing(2),
      borderRadius: theme.shape.borderRadiusSemiLarge,
      justifyContent: 'space-between',
      position: 'relative',
      '&$loader': {
        gap: theme.spacing(1),
      },
    },
    loader: {},

    topRightSlot: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
    },

    titlesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),

      '&$rightMargin': {
        marginRight: theme.spacing(8),
      },
    },

    title: {
      display: 'flex',
      color: COLORS.neutral.blackBlue,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
    },

    subtitle: {
      color: COLORS.gray.gray30,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
    },

    collapseWrapperInner: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
    },

    expandButton: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      justifyContent: 'left',
      width: 'fit-content',
      marginTop: ({ expanded }) => theme.spacing(expanded ? 2 : 0),
    },

    collapseWrapper: {},
  }),
  { name: 'InsightBox' }
);
