import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    skeleton: {
      width: '100%',
      height: 16,
      transform: 'none',
    },
  }),
  { name: 'ItemLoading' }
);
