import { useCallback } from 'react';

import { usePopups, BRAND_VOCABULARY_POPUP } from 'src/services/popup';

export const useBrandVocabularyPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ brandVoiceIds, onClose }) => {
      popups.openPopup(BRAND_VOCABULARY_POPUP, {
        onClose: (result) => {
          if (onClose) {
            onClose(result);
          }
          popups.closePopup(BRAND_VOCABULARY_POPUP);
        },
        brandVoiceIds,
      });
    },
    [popups]
  );
};
