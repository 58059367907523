import { useCallback } from 'react';

import { SCORING_METHOD_POPUP } from '../types';
import { usePopups } from '../services';

export const useScoringMethodPopup = () => {
  const popups = usePopups();

  const selectScoringMethod = useCallback(
    ({
      initialValue,
      contentType,
      generationTool,
      assetType,
      onSubmit,
      onApplyAll,
      ...restProps
    }) => {
      popups.openPopup(SCORING_METHOD_POPUP, {
        initialValue,
        contentType,
        generationTool,
        assetType,
        ...restProps,

        onSubmit: (result) => {
          popups.closePopup(SCORING_METHOD_POPUP);
          onSubmit(result);
        },
        onClose: () => {
          popups.closePopup(SCORING_METHOD_POPUP);
        },
        onApplyAll,
      });
    },
    [popups]
  );

  return {
    selectScoringMethod,
  };
};
