import { promisedWait } from 'src/lib/common';
import { AbortError, ExtractionError } from 'src/lib/errors';
import { queryCustomModels } from 'src/graphql/customModel';
import { CUSTOM_MODEL_STATUS, getProcessingErrorTypeMessage } from 'src/data/customModel';

const CREATION_POLLING_INTERVAL = 5000;

export const waitForCustomModelCreation = async ({
  customerId,
  brandVoiceId,
  dataSource,
  customModel,
  options,
  mergeCustomModelInCache,
  deleteCustomModel,
}) => {
  if (options?.abortSignal?.aborted) {
    throw new AbortError();
  }
  const result = await queryCustomModels({
    customerId,
    brandVoiceIds: [brandVoiceId],
    customModelId: customModel.id,
    dataSource,
  });
  mergeCustomModelInCache(result[0]);
  if (result?.[0]?.status === CUSTOM_MODEL_STATUS.active) {
    return result[0];
  }
  if (result?.[0]?.status === CUSTOM_MODEL_STATUS.error) {
    deleteCustomModel(result[0].id);
    throw new ExtractionError(getProcessingErrorTypeMessage(result[0].processingError));
  }

  if (!result) {
    return;
  }

  // Wait sometime and check again
  await promisedWait(CREATION_POLLING_INTERVAL);

  return waitForCustomModelCreation({
    customerId,
    brandVoiceId,
    dataSource,
    customModel,
    options,
    mergeCustomModelInCache,
    deleteCustomModel,
  });
};
