import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    title: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.gray.black,
      marginBottom: theme.spacing(2),
    },
    manualTov: {
      display: 'inline-block',

      '& + &': {
        marginLeft: theme.spacing(1),
      },
    },
    mixtureWrapper: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: 'Details' }
);
