import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query ContentTaxonomies {
    contentTaxonomies {
      edges {
        node {
          id
          name
          default
        }
      }
    }
  }
`;

export const queryContentTaxonomies = () =>
  executeGqlQuery({
    query: QUERY,
  }).then(({ data }) => {
    const contentTaxonomies = getSingleProp(data);
    return unwrapEdges(contentTaxonomies.edges);
  });
