import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_MULTI_PROMPT_TEMPLATE } from './schema';

const MUTATION = gql`
  mutation DeleteCustomerMultiPromptTemplate($customerId: ID!, $id: ID!) {
    deleteCustomerMultiPromptTemplate(customerId: $customerId, id: $id) {
      ...CustomerMultiPromptTemplate
    }
  }
  ${FRAGMENT_MULTI_PROMPT_TEMPLATE}
`;

export const deleteCustomerMultiPromptTemplate = (customerId, id) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, id },
  }).then(({ data }) => getSingleProp(data));
