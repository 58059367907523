import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_TONE_OF_VOICE } from './schema';

const QUERY = gql`
  query CustomToneOfVoiceList($brandVoiceId: ID!) {
    customToneOfVoiceList(brandVoiceId: $brandVoiceId) {
      ...CustomToneOfVoice
    }
  }
  ${FRAGMENT_CUSTOM_TONE_OF_VOICE}
`;

export const queryCustomToneOfVoiceList = (brandVoiceId) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      brandVoiceId,
    },
  }).then(({ data }) => getSingleProp(data));
};
