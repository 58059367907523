import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_FORMULA } from './schema';

const MUTATION = gql`
  mutation ExtractCustomFormula($brandVoiceId: ID!, $text: String!) {
    extractCustomFormula(brandVoiceId: $brandVoiceId, text: $text) {
      ...CustomFormula
    }
  }
  ${FRAGMENT_CUSTOM_FORMULA}
`;

export const extractCustomFormula = (variables) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
