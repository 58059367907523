import { useCallback, useEffect, useState } from 'react';

export const useOnHover = (onHover) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseOver = useCallback((event) => {
    const { currentTarget, target, relatedTarget } = event;

    if (
      currentTarget === target ||
      currentTarget === relatedTarget ||
      currentTarget.contains(target)
    ) {
      setHovered(true);
    }
  }, []);

  const handleMouseOut = useCallback((event) => {
    const { currentTarget, relatedTarget } = event;

    if (currentTarget !== relatedTarget && !currentTarget.contains(relatedTarget)) {
      setHovered(false);
    }
  }, []);

  useEffect(() => {
    if (onHover) {
      onHover(hovered);
    }
  }, [onHover, hovered]);

  return {
    hovered,
    handleMouseOver,
    handleMouseOut,
  };
};
