import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MenuItem } from '@material-ui/core';

import { SLATE_EDITOR_ACTIONS } from 'src/lib/slate';
import { getEditorActionLabel } from '../../lib';
import { EditorActionIcon } from '../EditorActionIcon';
import { useStyles } from './useStyles';

export const EditorActionMenuItem = ({ className, action, onAction, active, disabled }) => {
  const styles = useStyles();
  const label = getEditorActionLabel(action);

  const handleMouseDown = useCallback(
    (event) => {
      onAction(event, action);
    },
    [onAction, action]
  );

  return (
    <MenuItem
      className={cx(styles.root, className, { [styles.active]: active })}
      onMouseDown={handleMouseDown}
      disabled={disabled}
      button
    >
      <EditorActionIcon
        className={styles.icon}
        action={action}
      />

      {label}
    </MenuItem>
  );
};

EditorActionMenuItem.propTypes = {
  className: PropTypes.string,
  action: PropTypes.oneOf(SLATE_EDITOR_ACTIONS).isRequired,
  onAction: PropTypes.func.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};

EditorActionMenuItem.defaultProps = {
  className: '',
  active: false,
  disabled: false,
};
