import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(0.5),
    },
  }),
  { name: 'ErrorMessage' }
);
