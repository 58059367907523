import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { CUSTOM_DATASET_FRAGMENT } from '../customModel/schema';
import { executeGqlMutation } from '../client';

const MUTATION = gql`
  mutation updateCustomModel($customModelId: ID!, $isDefault: Boolean) {
    updateCustomModel(customModelId: $customModelId, isDefault: $isDefault) {
      ...CustomDataset
    }
  }
  ${CUSTOM_DATASET_FRAGMENT}
`;

export const updateCustomModel = (customModelId, isDefault) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { customModelId, isDefault },
  }).then(({ data }) => getSingleProp(data));
};
