import { defineDefaultEnumValue, defineEnumClampFunction } from 'src/lib/enum';

export const GENERATION_TOOL_BASIC = {
  basicContentImprover: 'basic_content_improver',
  basicEli5: 'basic_eli5',
  basicSentenceExpander: 'basic_sentence_expander',
  basicAida: 'basic_aida',
  basicPas: 'basic_pas',
  basicPerfectHeadline: 'basic_perfect_headline',
  basicProductDescriptionList: 'basic_product_description_list',
  basicProductDescriptionRelaxed: 'basic_product_description_relaxed',
  basicBlogIdeas: 'basic_blog_ideas',
  basicTalkingPointsToParagraph: 'basic_talking_points_to_paragraph',
  basicProductReview: 'basic_product_review',
  summarizer: 'summarizer',

  generalProductListing: 'dd_general_product_listing',
  generalPrompt: 'general_prompt',
};

// HINT: when adding new tools here, consider adding them to `REWRITE_TO_SUGGEST_TOOL_MAP` as well
export const GENERATION_TOOL_REWRITE = {
  facebookHeadlineProductRewrite: 'dd_facebook_headline_product_rewrite',
  facebookPrimaryTextProductRewrite: 'dd_facebook_primary_text_product_rewrite',
  googleHeadlineProductRewrite: 'dd_google_headline_product_rewrite',
  googleDescriptionProductRewrite: 'dd_google_description_product_rewrite',
  outbrainHeadlineProductRewrite: 'dd_outbrain_headline_product_rewrite',
  taboolaHeadlineProductRewrite: 'dd_taboola_headline_product_rewrite',
  twitterCopyProductRewrite: 'dd_twitter_copy_product_rewrite',
  pinterestDescriptionProductRewrite: 'dd_pinterest_description_product_rewrite',
  landingHeadlineRewrite: 'dd_landing_page_headline_rewrite',
  landingSubheaderRewrite: 'dd_landing_page_subheader_rewrite',
  landingSEOMetadataRewrite: 'dd_landing_page_seo_meta_data_rewrite',
  smsPromotionalRewrite: 'dd_sms_promotional_rewrite',
  linkedinHeadlineSingleImageRewrite: 'dd_linkedin_headline_single_image_rewrite',
  linkedinIntroSingleImageRewrite: 'dd_linkedin_intro_single_image_rewrite',
  instagramCaptionRewrite: 'dd_instagram_caption_rewrite',
  facebookPostRewrite: 'dd_facebook_post_rewrite',
  linkedinPostRewrite: 'dd_linkedin_post_rewrite',
  tweetRewrite: 'dd_tweet_rewrite',
  pinterestPinTitleRewrite: 'dd_pinterest_pin_title_rewrite',
  pinterestPinDescriptionRewrite: 'dd_pinterest_pin_description_rewrite',
  emailSubjectRewrite: 'dd_email_subject_rewrite',
  emailSubjectLineRewrite: 'dd_email_subject_line_rewrite',
  emailBodyRewrite: 'dd_email_body_rewrite',
  emailColdOutreachBodyRewrite: 'dd_email_cold_outreach_body_rewrite',
  emailPromotionalBodyRewrite: 'dd_email_promotional_body_rewrite',
};

export const GENERATION_TOOL_CUSTOM_MULTI_PROMPT = {
  customMultiPrompt: 'dd_custom_multi_prompt',
};

export const GENERATION_TOOL_DD = {
  linkedinMixtureAd: 'dd_linkedin_mixture_ad',
  twitterMixtureAd: 'dd_twitter_mixture_ad',
  pinterestMixtureAd: 'dd_pinterest_mixture_ad',
  facebookMixtureAd: 'dd_facebook_mixture_ad',
  facebookPrimaryTextProduct: 'dd_facebook_primary_text_product',
  facebookHeadlineProduct: 'dd_facebook_headline_product',
  googleRSA: 'dd_google_rsa',
  googleDescriptionProduct: 'dd_google_description_product',
  googleHeadlineProduct: 'dd_google_headline_product',
  outbrainHeadlineProduct: 'dd_outbrain_headline_product',
  taboolaHeadlineProduct: 'dd_taboola_headline_product',
  twitterCopyProduct: 'dd_twitter_copy_product',
  twitterTitleProduct: 'dd_twitter_title_product',
  pinterestDescriptionProduct: 'dd_pinterest_description_product',
  pinterestTitleProduct: 'dd_pinterest_title_product',

  landingHeadline: 'dd_landing_page_headline',
  landingSubheader: 'dd_landing_page_subheader',
  landingCTA: 'dd_landing_page_call_to_action',
  landingBenefits: 'dd_landing_page_benefits',
  landingFeatures: 'dd_landing_page_features',
  landingSEOMetadata: 'dd_landing_page_seo_meta_data',

  amazonProductListing: 'dd_amazon_product_listing',
  walmartProductListing: 'dd_walmart_product_listing',

  instagramCaption: 'dd_instagram_caption',
  facebookPost: 'dd_facebook_post',
  tweet: 'dd_tweet',
  linkedinPost: 'dd_linkedin_post',
  youtubeTitle: 'dd_youtube_title',
  youtubeDescription: 'dd_youtube_description',
  pinterestPostDescription: 'dd_pinterest_post_description',
  pinterestPostTitle: 'dd_pinterest_post_title',

  emailSubject: 'dd_email_subject',
  emailBody: 'dd_email_body',
  emailSubjectLine: 'dd_email_subject_line',
  emailPromotionalSubjectLine: 'dd_email_promotional_subject_line',
  emailPromotionalBody: 'dd_email_promotional_body',
  emailColdOutreachSubjectLine: 'dd_email_cold_outreach_subject_line',
  emailColdOutreachBody: 'dd_email_cold_outreach_body',
  emailMixture: 'dd_email_mixture',

  smsPromotional: 'dd_sms_promotional',

  linkedinHeadlineSingleImage: 'dd_linkedin_headline_single_image',
  linkedinIntroSingleImage: 'dd_linkedin_intro_single_image',

  articleBlogHeadline: 'dd_article_blog_headline',
  productLaunchMultiPrompt: 'dd_product_launch_multi_prompt',
  landingPageProductAnnouncementMultiPrompt: 'dd_landing_page_product_announcement_multi_prompt',
  emailDripMultiPrompt: 'dd_email_drip_multi_prompt',
};

export const GENERATION_TOOL_DD_TEADS = {
  teadsSingleVideoHeadline: 'dd_teads_single_video_headline',
  teadsSingleVideoDescription: 'dd_teads_single_video_description',
};

export const GENERATION_TOOL_FREESTYLE = {
  createYourOwn: 'freestyle_create_your_own',
  freestyle: 'freestyle',
};

export const GENERATION_TOOL_LPCO = {
  lpcoLandingHeadline: 'lpco_landing_page_headline',
  lpcoLandingCTA: 'lpco_landing_page_call_to_action',
  lpcoLandingProductDescription: 'lpco_landing_page_product_description',
  lpcoLandingProductName: 'lpco_landing_page_product_name',
  lpcoLandingCopy: 'lpco_landing_page_copy',
  // this is a purely FE value and won't be accepted by BE as "generation tool" param
  wtmDefault: 'wtm_default',
};

export const GENERATION_TOOL_DEPRECATED = {
  basicLandingPageSeo: 'basic_landing_page_seo',
  basicInstagramSocial: 'basic_instagram_social',
  basicFacebookSocial: 'basic_facebook_social',
  basicTwitterSocial: 'basic_twitter_social',
  basicLinkedinSocial: 'basic_linkedin_social',
  basicYoutubeSocial: 'basic_youtube_social',
  basicEmailSubjectLine: 'basic_email_subject_line',
  basicSms: 'basic_sms',
  basicFacebookAd: 'basic_facebook_ad',
  basicGoogleAd: 'basic_google_ad',
  basicTwitterAd: 'basic_twitter_ad',
  basicLinkedinTextAd: 'basic_linkedin_text_ad',
  basicPinterestAd: 'basic_pinterest_ad',
  basicLandingPage: 'basic_landing_page_builder',
  basicPinterestPinSocial: 'basic_pinterest_pin_social',
  basicColdEmail: 'basic_cold_email',
  basicPromotionalEmail: 'basic_promotional_email',
  linkedinIntroContent: 'dd_linkedin_intro_content',
  linkedinHeadlineContent: 'dd_linkedin_headline_content',
  amazonProductDescription: 'dd_amazon_product_description',
  amazonFeatureList: 'dd_amazon_feature_list',
  walmartProductDescription: 'dd_walmart_product_description',
  walmartFeatureList: 'dd_walmart_feature_list',
  teadsSingleImageHeadline: 'dd_teads_single_image_headline',
  teadsSingleImageDescription: 'dd_teads_single_image_description',
  teadsCarouselHeadline: 'dd_teads_carousel_headline',
  teadsSocialLayout1Headline: 'dd_teads_social_layout_1_headline',
  teadsSocialLayout1PrimaryText: 'dd_teads_social_layout_1_primary_text',
  teadsSocialLayout1Description: 'dd_teads_social_layout_1_description',
  teadsSocialLayout2PrimaryText: 'dd_teads_social_layout_2_primary_text',
  facebookPrimaryTextContent: 'dd_facebook_primary_text_content',
  facebookHeadlineContent: 'dd_facebook_headline_content',
  outbrainHeadlineContent: 'dd_outbrain_headline_content',
  taboolaHeadlineContent: 'dd_taboola_headline_content',
  twitterCopyContent: 'dd_twitter_copy_content',
  pinterestDescriptionContent: 'dd_pinterest_description_content',
  pinterestTitleContent: 'dd_pinterest_title_content',
  basicQuoraAnswers: 'basic_quora_answers',
  basicVideoIdeas: 'basic_video_ideas',
  basicBab: 'basic_bab',
  basicEngagingQuestions: 'basic_engaging_questions',
  basicPersonalBio: 'basic_personal_bio',
  basicCompanyBio: 'basic_company_bio',
  basicFeatureToBenefitsPractical: 'basic_feature_to_benefits_practical',
  basicPersuasiveBulletPoint: 'basic_persuasive_bullet_point',
  basicValueProposition: 'basic_value_proposition',
};

export const GENERATION_TOOL_BLOG = {
  // this is a purely FE value and won't be accepted by BE as "generation tool" param
  blogDescription: 'blog_description',
};

/**
 * Generation tool
 * @enum {string}
 * @property {GENERATION_TOOL} DEFAULT - default generation tool
 * @property {function(GENERATION_TOOL): GENERATION_TOOL} clamp - function that clamps the value to the enum
 */
export const GENERATION_TOOL = {
  ...GENERATION_TOOL_BASIC,
  ...GENERATION_TOOL_DD,
  ...GENERATION_TOOL_DD_TEADS,
  ...GENERATION_TOOL_FREESTYLE,
  ...GENERATION_TOOL_LPCO,
  ...GENERATION_TOOL_REWRITE,
  ...GENERATION_TOOL_CUSTOM_MULTI_PROMPT,
  ...GENERATION_TOOL_DEPRECATED,
  ...GENERATION_TOOL_BLOG,
};
defineDefaultEnumValue(GENERATION_TOOL, GENERATION_TOOL.facebookMixtureAd);
defineEnumClampFunction(GENERATION_TOOL, GENERATION_TOOL.facebookMixtureAd);

export const GENERATION_TOOL_GROUP = {
  customMultiPrompt: 'custom_multi_prompt',
  boost: 'boost',
  ads: 'ads',
  landingPage: 'landing_page',
  campaigns: 'campaigns',
  social: 'social',
  email: 'email',
  ecommerce: 'ecommerce',
  generalWriting: 'general_writing',
  anyCopy: 'any_copy',
  articleBlog: 'article_blog',
};

export const GENERATION_TOOL_GROUPS_SORTED_ALL = [
  GENERATION_TOOL_GROUP.boost,
  GENERATION_TOOL_GROUP.ads,
  GENERATION_TOOL_GROUP.landingPage,
  GENERATION_TOOL_GROUP.campaigns,
  GENERATION_TOOL_GROUP.social,
  GENERATION_TOOL_GROUP.email,
  GENERATION_TOOL_GROUP.ecommerce,
  GENERATION_TOOL_GROUP.generalWriting,
  GENERATION_TOOL_GROUP.anyCopy,
  GENERATION_TOOL_GROUP.articleBlog,
  GENERATION_TOOL_GROUP.customMultiPrompt,
];

export const GENERATION_TOOL_GROUPS_SORTED = [
  GENERATION_TOOL_GROUP.customMultiPrompt,
  GENERATION_TOOL_GROUP.boost,
  GENERATION_TOOL_GROUP.anyCopy,
  GENERATION_TOOL_GROUP.social,
  GENERATION_TOOL_GROUP.ads,
  GENERATION_TOOL_GROUP.landingPage,
  GENERATION_TOOL_GROUP.email,
  GENERATION_TOOL_GROUP.ecommerce,
  GENERATION_TOOL_GROUP.generalWriting,
  GENERATION_TOOL_GROUP.articleBlog,
];

export const GENERATION_BLOG_GROUP = {
  blog: 'blog',
};

export const GENERATION_WTM_GROUP = {
  wtm: 'wtm',
};

export const MOST_POPULAR_GENERATION_TOOLS = [
  GENERATION_TOOL.instagramCaption,
  GENERATION_TOOL.freestyle,
  GENERATION_TOOL.basicContentImprover,
  GENERATION_TOOL.facebookMixtureAd,
  GENERATION_TOOL.productLaunchMultiPrompt,
];

export const GENERATION_TOOL_BADGE_TYPE = {
  new: 'new',
  improved: 'improved',
};

export const GENERATION_TOOL_CARD_NEW_DOCUMENT = 'new';

export const BLANK_SAVED_PROMPT_ID = 'blank_saved_prompt';
