import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import * as storage from '../../lib/storage';

const MUTATION = gql`
  mutation SignUp(
    $email: String!
    $password: String!
    $signUpDevice: String!
    $referralToken: String
    $impactClickId: String
  ) {
    signUp(
      email: $email
      password: $password
      signUpDevice: $signUpDevice
      referralToken: $referralToken
      impactClickId: $impactClickId
    ) {
      token
    }
  }
`;

export const register = (email, password, signUpDevice, referralToken) => {
  const impactClickId = storage.readItem('impactClickId');
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { email, password, signUpDevice, referralToken, impactClickId },
  }).then(({ data }) => getSingleProp(data));
};
