import { useCallback } from 'react';

import { useConfirmPopup } from 'src/services/popup';
import { CONFIRM_POPUP_MODE } from 'src/components/ConfirmPopup';

export const useConfirmClosePopup = () => {
  const confirmPopup = useConfirmPopup();

  return useCallback(() => {
    return confirmPopup({
      mode: CONFIRM_POPUP_MODE.warning,
      title: 'Unsaved Changes',
      content: 'Are you sure you want to exit?',
      buttons: {
        confirm: 'Exit',
        cancel: 'Cancel',
      },
      withReject: true,
    })
      .then(() => true)
      .catch(() => false);
  }, [confirmPopup]);
};
