import { useCallback, useEffect } from 'react';

import { useFreshRef } from './useFreshRef';

export const useEventListener = ({ eventName, callback, options, target = document }) => {
  const callbackRef = useFreshRef(callback);

  const enabled = !options?.disabled;
  const capture = options?.capture;

  const handleEvent = useCallback(
    (event) => {
      if (callbackRef.current) {
        callbackRef.current(event);
      }
    },
    [callbackRef]
  );

  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    target.addEventListener(eventName, handleEvent, capture);

    return () => target.removeEventListener(eventName, handleEvent, capture);
  }, [enabled, eventName, handleEvent, capture, target]);
};
