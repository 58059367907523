import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';

export const ErrorMessage = ({ className, error, ...restProps }) => {
  const styles = useStyles();

  return error ? (
    <CustomTypography
      className={cx(styles.root, className)}
      variant={TYPOGRAPHY_VARIANT.regular12}
      color="error"
      {...restProps}
    >
      {error}
    </CustomTypography>
  ) : null;
};

ErrorMessage.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
};

ErrorMessage.defaultProps = {
  className: null,
  error: null,
};
