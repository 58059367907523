import { useMemo } from 'react';
import { createStateSelectorHook } from 'src/lib/sweetState';

import { BrandVoicesStore } from 'src/store';

const useBrandVoiceState = createStateSelectorHook(BrandVoicesStore, [
  'brandVoices',
  'defaultBrandVoice',
]);

export const useBrandVoices = () => {
  const { brandVoices, defaultBrandVoice } = useBrandVoiceState();

  return useMemo(
    () => ({
      brandVoices,
      defaultBrandVoice,
    }),
    [brandVoices, defaultBrandVoice]
  );
};
