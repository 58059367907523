import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import useStyles from './useStyles';

const propTypes = {
  classes: PropTypes.shape(),
  customer: PropTypes.shape().isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const defaultProps = {
  classes: null,
  selected: false,
};

const CustomerMenuItem = ({ classes, customer, selected, onClick }) => {
  const styles = useStyles({ classes });

  const handleClick = React.useCallback(() => {
    onClick(customer);
  }, [customer, onClick]);

  return (
    <MenuItem
      className={cx(styles.root, {
        [styles.selected]: selected,
      })}
      onClick={handleClick}
      data-testid="CustomerMenuItem"
    >
      <ListItemText
        classes={{
          primary: cx(styles.text, {
            [styles.selected]: selected,
          }),
        }}
        primary={customer.name}
      />
    </MenuItem>
  );
};

CustomerMenuItem.propTypes = propTypes;
CustomerMenuItem.defaultProps = defaultProps;

export default CustomerMenuItem;
