import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Table,
  TableBody,
  TableRow,
  TableContainer,
  TableHead,
  TableCell,
} from '@material-ui/core';

import { SORT_DIRECTION } from 'src/data/common';
import { ACCOUNT_FIELD } from 'src/data/performance';
import { CHANNEL, getChannelPerformanceLabel } from 'src/data/channel';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { CustomTableSortLabel } from 'src/components/CustomTableSortLabel';
import { AccountRow } from './components/AccountRow';
import { Loader } from './components/Loader';
import { IconInformation } from 'src/assets/icons/IconInformation';
import { useStyles } from './useStyles';

export const ACCOUNT_TABLE_COLUMNS = [
  { field: ACCOUNT_FIELD.name, title: 'Name', style: { width: 'auto' } },
  { field: ACCOUNT_FIELD.id, title: 'Account ID', style: { width: 151 } },
  { field: ACCOUNT_FIELD.connectedState, title: '', style: { width: 160 } },
];

const prepareAccountsTableColumns = (hideIdField) => {
  if (hideIdField) {
    return ACCOUNT_TABLE_COLUMNS.filter((column) => column.field !== ACCOUNT_FIELD.id);
  }
  return ACCOUNT_TABLE_COLUMNS;
};
const getColumnTitle = (column) => {
  return column.title;
};

const alignAccounts = ({ accounts, connectedAccounts, isTrainingModel, isTalkingPointsView }) => {
  if (isTrainingModel || isTalkingPointsView) {
    return [...connectedAccounts, ...accounts];
  } else {
    return accounts;
  }
};

export const AccountsTable = ({
  accounts,
  connectedAccounts,
  onAccountClick,
  onSortChange,
  sortField,
  sortDirection,
  limitedAccountData,
  item,
  channel,
  loadingAccounts,
  anyAccountConnected,
  isTrainingModel,
  isTalkingPointsView,
}) => {
  const styles = useStyles();
  const isEmptyState = accounts.length === 0 && !loadingAccounts;
  const createSortHandler = (field) => () => {
    onSortChange(field);
  };

  const alignedAccounts = alignAccounts({
    accounts,
    connectedAccounts,
    isTrainingModel,
    isTalkingPointsView,
  });

  const isBrazeChannel = channel === CHANNEL.braze;

  const columns = prepareAccountsTableColumns(isBrazeChannel);

  return (
    <TableContainer className={styles.root}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.field}
                className={cx(styles.title)}
                style={column.style}
              >
                <CustomTableSortLabel
                  active={sortField === column.field}
                  direction={sortField === column.field ? sortDirection : SORT_DIRECTION.asc}
                  onClick={createSortHandler(column.field)}
                >
                  {getColumnTitle(column)}
                </CustomTableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingAccounts ? (
            <Loader />
          ) : (
            alignedAccounts?.map((account) => (
              <AccountRow
                key={account.id}
                account={account}
                onAccountClick={onAccountClick}
                featureLimitedLabel={
                  limitedAccountData?.accountId === account.id
                    ? `Limit reached, only ${limitedAccountData?.rowsCount}  rows imported`
                    : null
                }
                isConnected={connectedAccounts?.includes(account)}
                hideIdField={isBrazeChannel}
              />
            ))
          )}
          {isEmptyState && !isTrainingModel && !isTalkingPointsView && anyAccountConnected && (
            <TableRow>
              <TableCell colSpan={3}>
                <CustomTypography
                  className={styles.alreadyConnected}
                  variant={TYPOGRAPHY_VARIANT.regular14}
                >
                  There is nothing to show, all ad accounts are already connected.
                </CustomTypography>
              </TableCell>
            </TableRow>
          )}
          {isEmptyState && !anyAccountConnected && (
            <TableRow>
              <TableCell
                className={styles.noAccountsCell}
                colSpan={3}
              >
                <CustomTypography
                  className={styles.textEmpty}
                  variant={TYPOGRAPHY_VARIANT.regular14}
                >
                  <IconInformation /> Looks like there are no {item}s in your{' '}
                  {getChannelPerformanceLabel(channel)}
                </CustomTypography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AccountsTable.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  connectedAccounts: PropTypes.arrayOf(PropTypes.shape({})),
  connectedAccountIds: PropTypes.arrayOf(PropTypes.string),
  onAccountClick: PropTypes.func,
  onSortChange: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  item: PropTypes.string,
  channel: PropTypes.string,
  loadingAccounts: PropTypes.bool,
  limitedAccountData: PropTypes.shape(),
  anyAccountConnected: PropTypes.bool,
  isTrainingModel: PropTypes.bool,
  isTalkingPointsView: PropTypes.bool,
  hideIdField: PropTypes.bool,
};

AccountsTable.defaultProps = {
  className: null,
  accounts: [],
  connectedAccountIds: null,
  onAccountClick: null,
  onSortChange: null,
  sortField: null,
  sortDirection: null,
  item: null,
  channel: null,
  loadingAccounts: false,
  limitedAccountData: null,
  anyAccountConnected: false,
  isTrainingModel: false,
  isTalkingPointsView: false,
  hideIdField: false,
};
