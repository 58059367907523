import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query ChannelsAuthState {
    me {
      id
      googleData {
        authState
      }
      facebookData {
        authState
      }
      linkedinData {
        authState
      }
      linkedinSocialData {
        authState
      }
      hubspotData {
        authState
      }
      salesforceData {
        authState
      }
      twitterSocialData {
        authState
      }
    }
  }
`;

export const queryChannelsAuthState = () => {
  return executeGqlQuery({
    query: QUERY,
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => {
      return {
        googleAuthState: result.googleData.authState,
        facebookAuthState: result.facebookData.authState,
        linkedinAuthState: result.linkedinData.authState,
        linkedinSocialAuthState: result.linkedinSocialData.authState,
        hubspotAuthState: result.hubspotData.authState,
        salesforceData: result.salesforceData.authState,
        twitterSocialData: result.twitterSocialData.authState,
      };
    });
};
