import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_TEXT_ANNOTATIONS } from './schema';

const MUTATION = gql`
  mutation ExtractBriefAnnotations(
    $customerId: ID!
    $generationTool: GenerationToolEnum!
    $brief: BriefInput!
    $promptImageId: ID
    $promptDocumentId: ID
    $maxWords: Int
    $freestyleToolAllInstructionsData: JSON
    $urlExtractions: [UrlExtractionInput!]
  ) {
    extractBriefAnnotations(
      customerId: $customerId
      generationTool: $generationTool
      brief: $brief
      promptImageId: $promptImageId
      promptDocumentId: $promptDocumentId
      maxWords: $maxWords
      freestyleToolAllInstructionsData: $freestyleToolAllInstructionsData
      urlExtractions: $urlExtractions
    ) {
      ...TextAnnotations
    }
  }
  ${FRAGMENT_TEXT_ANNOTATIONS}
`;

export const extractBriefAnnotations = async (
  {
    customerId,
    generationTool,
    brief,
    promptImageId,
    promptDocumentId,
    maxWords,
    freestyleToolAllInstructionsData,
    urlExtractions,
  },
  options = null
) => {
  const variables = {
    customerId,
    generationTool,
    brief,
    promptImageId,
    promptDocumentId,
    maxWords,
    freestyleToolAllInstructionsData: freestyleToolAllInstructionsData
      ? JSON.stringify(freestyleToolAllInstructionsData)
      : null,
    urlExtractions,
  };

  const result = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  return getSingleProp(result.data) || [];
};
