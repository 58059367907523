import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { detectedBrandTermHasReplacement } from 'src/data/brandTerm';
import { ReplacementTerms } from './components/ReplacementTerms';
import { TermHeader } from './components/TermHeader';
import { useStyles } from './useStyles';

export const DetectedBrandTerm = ({
  className,
  classes,
  headerClasses,
  detectedBrandTerm,
  onApplyClick,
  onHover,
  onClick,
  hoverable,
  disabled,
}) => {
  const styles = useStyles({ classes });

  const hasReplacements = detectedBrandTermHasReplacement(detectedBrandTerm);

  const handleApplyClick = (event) => {
    event.stopPropagation();
    onApplyClick(detectedBrandTerm);
  };

  const handleClick = () => {
    if (!onClick || disabled) {
      return;
    }
    onClick(detectedBrandTerm);
  };

  const handleMouseEnter = () => {
    if (!onHover || disabled) {
      return;
    }
    onHover(detectedBrandTerm);
  };

  const handleMouseLeave = () => {
    if (!onHover || disabled) {
      return;
    }
    onHover(null);
  };

  return (
    <div
      className={cx(styles.root, className, {
        [styles.hoverable]: hoverable,
        [styles.disabled]: disabled,
        [styles.hasReplacements]: hasReplacements,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      data-id={detectedBrandTerm.id}
    >
      <TermHeader
        className={styles.header}
        classes={headerClasses}
        detectedBrandTerm={detectedBrandTerm}
        onApplyClick={hasReplacements ? handleApplyClick : null}
        disabled={disabled}
      />

      {hasReplacements && (
        <ReplacementTerms
          className={styles.replacementTerms}
          detectedBrandTerm={detectedBrandTerm}
        />
      )}
    </div>
  );
};

DetectedBrandTerm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  headerClasses: PropTypes.shape({}),
  detectedBrandTerm: PropTypes.shape({
    id: PropTypes.string,
    brandTerm: PropTypes.shape(),
    termInText: PropTypes.string,
  }).isRequired,
  onApplyClick: PropTypes.func.isRequired,
  onHover: PropTypes.func,
  onClick: PropTypes.func,
  hoverable: PropTypes.bool,
  disabled: PropTypes.bool,
};

DetectedBrandTerm.defaultProps = {
  className: '',
  classes: null,
  headerClasses: null,
  onHover: null,
  onClick: null,
  hoverable: false,
  disabled: false,
};
