import { isEmpty, mapValues } from 'lodash';

import { PERFORMANCE_EXTRACTION_STATUS } from 'src/data/performance';
import { PerformanceStore } from 'src/store';
import { createStateSelectorHook } from 'src/lib/sweetState';

const usePerformanceState = createStateSelectorHook(PerformanceStore, [
  'accounts',
  'customerAccounts',
]);

const CHANNEL_ACCOUNT_STATUS = {
  connectedAccounts: [PERFORMANCE_EXTRACTION_STATUS.running, PERFORMANCE_EXTRACTION_STATUS.done],
  disconnectedAccounts: [
    PERFORMANCE_EXTRACTION_STATUS.disconnected,
    PERFORMANCE_EXTRACTION_STATUS.doneWithErrors,
    PERFORMANCE_EXTRACTION_STATUS.nullableResults,
  ],
  runningAccounts: [PERFORMANCE_EXTRACTION_STATUS.running],
};

export const useChannelAccounts = ({ shouldGetAllAccounts = false } = {}) => {
  const { accounts, customerAccounts } = usePerformanceState();

  const alignedAccounts = shouldGetAllAccounts ? customerAccounts : accounts;

  if (isEmpty(alignedAccounts)) {
    return mapValues(CHANNEL_ACCOUNT_STATUS, () => ({}));
  }

  return Object.keys(CHANNEL_ACCOUNT_STATUS).reduce((statusAcc, status) => {
    const channels = Object.keys(alignedAccounts || {});
    const accountsPerStatus = { ...statusAcc };

    channels.forEach((channel) => {
      if (!accountsPerStatus[status]) {
        accountsPerStatus[status] = {};
      }

      accountsPerStatus[status][channel] = (alignedAccounts[channel] || []).filter((account) =>
        CHANNEL_ACCOUNT_STATUS[status].includes(account.status)
      );
    });

    return accountsPerStatus;
  }, {});
};
