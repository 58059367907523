import { createContainer } from 'react-sweet-state';

import { CustomModelsStore } from './CustomModelsStore';

export const CustomModelsStoreContainer = createContainer(CustomModelsStore, {
  onInit:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
