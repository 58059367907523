import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import IconNotificationSuccess from 'src/assets/icons/NotificationSuccess';
import { IconExclamationCircled } from 'src/assets/icons/IconExclamationCircled';
import IconClose from 'src/assets/icons/Close';
import { ALERT_VARIANT } from './const';
import { useStyles } from './useStyles';
import { IconCheckBold } from 'src/assets/icons/IconCheckBold';

const ICON_MAPPING = {
  info: <IconExclamationCircled fontSize="inherit" />,
  success: <IconNotificationSuccess fontSize="inherit" />,
  warning: <IconExclamationCircled fontSize="inherit" />,
  error: <IconExclamationCircled fontSize="inherit" />,
  successBold: <IconCheckBold fontSize="inherit" />,
};

const getVariant = (severity) =>
  ['info', 'success'].includes(severity) ? ALERT_VARIANT.filled : ALERT_VARIANT.standard;

export const CustomAlert = ({
  className,
  classes,
  title,
  description,
  variant,
  severity,
  onClose,
  hideCloseButton,
  hideIcon,
  ...props
}) => {
  const styles = useStyles({ classes });
  const localVariant = variant || getVariant(severity);

  const localClasses = useMemo(
    () => ({
      message: styles.message,
      filledInfo: styles.filledInfo,
      filledSuccess: styles.filledSuccess,
      standardWarning: styles.standardWarning,
      standardError: styles.standardError,
      icon: styles.icon,
    }),
    [styles]
  );

  return (
    <Alert
      className={cx(styles.root, className, {
        [styles.hasClose]: Boolean(onClose) && !hideCloseButton,
        [styles.onlyDescription]: description && !title,
        [styles.elevated]: localVariant !== ALERT_VARIANT.outlined,
      })}
      classes={localClasses}
      iconMapping={hideIcon ? undefined : ICON_MAPPING}
      severity={severity}
      variant={localVariant}
      data-testid="CustomAlert"
      icon={hideIcon ? false : undefined}
      {...props}
    >
      {title && <Typography className={styles.textTitle}>{title}</Typography>}
      {description && <Typography className={styles.textDescription}>{description}</Typography>}

      {onClose && !hideCloseButton && (
        <IconButton
          className={styles.buttonClose}
          onClick={onClose}
          data-testid="CustomAlert__CloseButton"
        >
          <IconClose />
        </IconButton>
      )}
    </Alert>
  );
};

CustomAlert.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  variant: PropTypes.oneOf(Object.values(ALERT_VARIANT)),
  severity: PropTypes.string,
  classes: PropTypes.shape({}),
  onClose: PropTypes.func,
  hideCloseButton: PropTypes.bool,
  hideIcon: PropTypes.bool,
};

CustomAlert.defaultProps = {
  className: null,
  title: '',
  description: '',
  variant: null,
  severity: 'info',
  onClose: null,
  hideCloseButton: false,
  hideIcon: false,
};
