import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      overflow: 'hidden',
    },
    firstLetter: {
      flexShrink: 0,
    },
    name: {
      color: COLORS.neutral.silver,
      textAlign: 'start',
    },
  }),
  { name: 'WorkspaceLabel' }
);
