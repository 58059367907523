import { cloneDeep } from 'lodash';
import { createStore } from 'react-sweet-state';

import {
  getBareboneProject,
  isBareboneProject,
  prepareLoadedProject,
  unwrapProjects,
  PROJECT_STATUS,
  PROJECT_TYPE,
} from 'src/data/project';
import {
  deleteProject,
  queryProject,
  queryProjectBlogData,
  queryProjectCommonData,
  queryProjectOptimizationData,
  queryProjects,
  queryAssociatedProjects,
  updateProject,
  updateProjectFavorite,
  updateProjectBrandVoicesForBrandTerms,
} from 'src/graphql/project';
import { DEFAULT_SUGGEST_TOV_MODE, DRAFT_TOV_ID } from 'src/data/toneOfVoice';
import { isAbortError } from 'src/lib/errors';

const NAME = 'global-project';

const getAdditionalProjectDataFunction = (projectType) => {
  switch (projectType) {
    case PROJECT_TYPE.continuousOptimization:
    case PROJECT_TYPE.wtmHomePage:
      return queryProjectOptimizationData;

    case PROJECT_TYPE.blogPostBuilder:
      return queryProjectBlogData;

    case PROJECT_TYPE.document:
    default:
      return queryProjectCommonData;
  }
};

const queryAdditionalProjectData = (project, options) => {
  const queryFunction = getAdditionalProjectDataFunction(project.type);
  if (!queryFunction) {
    return Promise.resolve(project);
  }

  return queryFunction(project.id, options).then((data) => ({
    ...project,
    ...data,
  }));
};

const isProjectReady = (project) => {
  // "Blog builder" projects can have only two statuses - "draft" and "editing"
  //   both of which mean "ready" in this case
  if (
    project.type === PROJECT_TYPE.blogPostBuilder ||
    project.type === PROJECT_TYPE.document ||
    // TODO: Remove once no migrated to document_editor
    project.type === PROJECT_TYPE.canvas
  ) {
    return true;
  }

  // Both "done" and "legacy" statuses should be treated as project is ready to display on FE
  return [PROJECT_STATUS.done, PROJECT_STATUS.legacy].includes(project.status);
};

export const SUGGESTED_TITLE_LENGTH_LIMIT = 83;

const INITIAL_STATE = {
  projects: [],
  loadingProjects: false,
  loadedProjects: false,
  loadingMoreProjects: false,
  projectsCursor: null,
  hasMoreProjects: false,
  processingIds: [],

  currentProject: null,
  queryCurrentProjectAbortController: null,
  loadingCurrentProject: false,
  projectSuggestedTitle: '',
  isCurrentProjectEmpty: true,

  toneOfVoiceMenuOpened: false,
  selectedTov: DEFAULT_SUGGEST_TOV_MODE,
  selectedCustomModelId: null,
  draftToneOfVoice: null,
  recomposeTovStash: null,
  toneOfVoiceError: null,

  searching: false,
  searchResult: [],
};

/**
 * Private actions
 */
export const PRIVATE_ACTIONS = {
  addProjectToCache:
    (project) =>
    ({ getState, setState }) => {
      const { projects: list } = getState();
      const newList = [project, ...list];
      setState({ projects: newList });
    },

  mergeProjectInCache:
    (project) =>
    ({ getState, setState }) => {
      const { projects: list, currentProject } = getState();
      const projectIndex = list.findIndex((p) => p.id === project.id);
      if (projectIndex >= 0) {
        const cacheProject = list[projectIndex];
        list[projectIndex] = {
          ...cacheProject,
          ...project,
        };
      }
      setState({ projects: [...list] });

      if (currentProject?.id === project.id) {
        setState({ currentProject: { ...currentProject, ...project } });
      }
    },

  deleteProjectFromCache:
    (project) =>
    ({ getState, setState }) => {
      const { projects: list } = getState();
      const newList = list.filter((p) => p.id !== project.id);
      setState({ projects: newList });
    },

  markProjectProcessing:
    (id, processing) =>
    ({ getState, setState }) => {
      const { processingIds } = getState();
      const newList = processing ? [...processingIds, id] : processingIds.filter((n) => n !== id);
      setState({ processingIds: newList });
    },

  clearToneOfVoiceError:
    () =>
    ({ setState }) => {
      setState({ toneOfVoiceError: null });
    },
};

/**
 * Public available actions
 */
export const ACTIONS = {
  queryProjects:
    ({
      customerId,
      workspaceId,
      onlyForCurrentUser,
      favoritesOnly,
      projectTypes,
      shouldQueryAssociatedProjects,
      projectFolderId,
      sortOrder,
      sortField,
    }) =>
    async ({ setState, dispatch }) => {
      if (!customerId) {
        dispatch(ACTIONS.clearStore());
        throw new Error('Unknown customer');
      }

      const filter = {
        currentUser: onlyForCurrentUser,
        favoriteOnly: favoritesOnly,
        types: projectTypes,
      };

      setState({
        loadingProjects: true,
        loadedProjects: false,
      });
      try {
        // For WTM projects we should use another query
        const query = shouldQueryAssociatedProjects ? queryAssociatedProjects : queryProjects;

        const result = await query({
          customerId,
          workspaceId,
          cursor: null,
          filter,
          projectFolderId,
          sortOrder,
          sortField,
        });

        const { edges, pageInfo } = result;
        const projects = unwrapProjects(edges);

        setState({
          projects,
          hasMoreProjects: pageInfo.hasNextPage,
          projectsCursor: pageInfo.endCursor,
        });

        return projects;
      } finally {
        setState({
          loadingProjects: false,
          loadedProjects: true,
        });
      }
    },

  queryMoreProjects:
    ({
      workspaceId,
      onlyForCurrentUser,
      favoritesOnly,
      projectTypes,
      shouldQueryAssociatedProjects,
      projectFolderId,
      sortField,
      sortOrder,
    }) =>
    ({ getState, setState, dispatch }, { currentCustomerId }) => {
      if (!currentCustomerId) {
        dispatch(ACTIONS.clearStore());
        return Promise.reject(new Error('Unknown customer'));
      }

      const { projectsCursor } = getState();

      setState({ loadingMoreProjects: true });

      const filter = {
        currentUser: onlyForCurrentUser,
        favoriteOnly: favoritesOnly,
        types: projectTypes,
      };

      // For WTM projects we should use another query
      const query = shouldQueryAssociatedProjects ? queryAssociatedProjects : queryProjects;

      return query({
        customerId: currentCustomerId,
        workspaceId,
        cursor: projectsCursor,
        filter,
        projectFolderId,
        sortField,
        sortOrder,
      })
        .then((result) => {
          const { edges, pageInfo } = result;

          const { projects } = getState();
          const moreProjects = unwrapProjects(edges);

          setState({
            projects: [...projects, ...moreProjects],
            hasMoreProjects: pageInfo.hasNextPage,
            projectsCursor: pageInfo.endCursor,
          });

          return moreProjects;
        })
        .finally(() => {
          setState({ loadingMoreProjects: false });
        });
    },

  queryCurrentProject:
    (id) =>
    ({ getState, setState, dispatch }) => {
      // If current project is "barebone" - don't show loading animations,
      // we should switch to created project seamlessly
      const { currentProject } = getState();
      const currentIsBarebone = isBareboneProject(currentProject);
      if (!currentIsBarebone) {
        setState({ loadingCurrentProject: true, currentProject: null });
      }

      // We need an abort controller to cancel pending requests, in case user would want to exit "project" page mid-flight
      const abortController = new AbortController();
      setState({ queryCurrentProjectAbortController: abortController });

      return queryProject(id, { abortSignal: abortController.signal })
        .then((project) =>
          queryAdditionalProjectData(project, { abortSignal: abortController.signal })
        )
        .then((project) => {
          if (!isProjectReady(project)) {
            throw new Error('Project is not ready');
          }

          const prepared = prepareLoadedProject(project);
          setState({
            currentProject: prepared,
          });
          return prepared;
        })
        .catch((error) => {
          if (isAbortError(error)) {
            return;
          }
          throw error;
        })
        .finally(() => {
          setState({
            loadingCurrentProject: false,
            queryCurrentProjectAbortController: null,
          });
          dispatch(ACTIONS.resetCurrentProjectEmptyFlag());
        });
    },

  toggleProjectFavorite:
    (id) =>
    async ({ getState, dispatch }) => {
      const { projects } = getState();
      const project = projects.find((p) => p.id === id);
      if (!project) {
        return;
      }

      dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, true));
      try {
        const result = await updateProjectFavorite(id, !project.favorite);
        dispatch(
          PRIVATE_ACTIONS.mergeProjectInCache({
            ...project,
            favorite: !project.favorite,
          })
        );
        return result;
      } finally {
        dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, false));
      }
    },

  initBareboneCurrentProject:
    (projectType) =>
    ({ setState }) => {
      const bareboneProject = getBareboneProject(projectType);
      setState({ currentProject: bareboneProject });
      return bareboneProject;
    },

  getCurrentProject:
    () =>
    ({ getState }) => {
      return getState().currentProject;
    },

  hasMoreProjects:
    () =>
    ({ getState }) => {
      return getState().hasMoreProjects;
    },

  clearCurrentProject:
    () =>
    ({ getState, setState }) => {
      const { queryCurrentProjectAbortController } = getState();
      if (queryCurrentProjectAbortController) {
        queryCurrentProjectAbortController.abort();
      }

      setState({ currentProject: null });
    },

  renameProject:
    ({ id, name }) =>
    async ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, true));
      try {
        const newProject = await updateProject({ id, name });
        const prepared = prepareLoadedProject(newProject);

        dispatch(PRIVATE_ACTIONS.mergeProjectInCache(prepared));
        return prepared;
      } finally {
        dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, false));
      }
    },

  moveProject:
    ({ id, projectFolderId }) =>
    async ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, true));
      try {
        const newProject = await updateProject({ id, projectFolderId, updateProjectFolder: true });
        const prepared = prepareLoadedProject(newProject);

        dispatch(PRIVATE_ACTIONS.mergeProjectInCache(prepared));
        return prepared;
      } finally {
        dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, false));
      }
    },

  updateProjectBrandVoicesForBrandTerms:
    ({ id, brandVoiceIds }) =>
    async ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, true));
      try {
        const newProject = await updateProjectBrandVoicesForBrandTerms({
          id,
          brandVoiceIdsForBrandTerms: brandVoiceIds,
        });
        const prepared = prepareLoadedProject(newProject);

        dispatch(PRIVATE_ACTIONS.mergeProjectInCache(prepared));
        return prepared;
      } finally {
        dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, false));
      }
    },

  updateProject:
    ({ id, name, projectFolderId }) =>
    async ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, true));
      try {
        const newProject = await updateProject({
          id,
          name,
          projectFolderId,
          updateProjectFolder: true,
        });
        const prepared = prepareLoadedProject(newProject);

        dispatch(PRIVATE_ACTIONS.mergeProjectInCache(prepared));
        return prepared;
      } finally {
        dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, false));
      }
    },

  deleteProject:
    (id, ifEmpty = false) =>
    async ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, true));
      try {
        const deletedProject = await deleteProject(id, ifEmpty);
        const prepared = prepareLoadedProject(deletedProject);

        dispatch(PRIVATE_ACTIONS.deleteProjectFromCache(prepared));
        return prepared;
      } finally {
        dispatch(PRIVATE_ACTIONS.markProjectProcessing(id, false));
      }
    },

  storeProject:
    (project) =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.mergeProjectInCache(project));
    },

  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  getProjectSuggestedTitle:
    () =>
    ({ getState }) =>
      getState().projectSuggestedTitle,

  setProjectSuggestedTitle:
    (suggestedTitle) =>
    ({ setState }) => {
      setState({ projectSuggestedTitle: suggestedTitle });
    },

  isCurrentProjectEmpty:
    () =>
    ({ getState }) =>
      getState().isCurrentProjectEmpty,

  markCurrentProjectAsNonEmpty:
    () =>
    ({ setState }) => {
      setState({ isCurrentProjectEmpty: false });
    },

  resetCurrentProjectEmptyFlag:
    () =>
    ({ setState }) => {
      setState({ isCurrentProjectEmpty: true });
    },

  getSelectedTov:
    () =>
    ({ getState }) => {
      return getState().selectedTov;
    },

  updateToneOfVoice:
    (selectedTov) =>
    ({ setState }) => {
      setState({
        selectedTov,
        selectedCustomModelId: null,
      });
    },

  clearTovInputs:
    (emptyDefaultValue) =>
    ({ getState, setState, dispatch }) => {
      const { recomposeTovStash } = getState();
      setState({
        selectedCustomModelId: null,
        selectedTov: emptyDefaultValue ? recomposeTovStash : DEFAULT_SUGGEST_TOV_MODE,
      });
      dispatch(PRIVATE_ACTIONS.clearToneOfVoiceError());
    },

  setSelectedCustomModelId:
    (selectedCustomModelId) =>
    ({ setState, dispatch }) => {
      setState({
        selectedCustomModelId,
        selectedTov: null,
      });
      dispatch(PRIVATE_ACTIONS.clearToneOfVoiceError());
    },

  getSelectedCustomModelId:
    () =>
    ({ getState }) => {
      return getState().selectedCustomModelId;
    },

  clearSelectedCustomModelId:
    () =>
    ({ setState }) => {
      setState({
        selectedCustomModelId: null,
      });
    },

  isLoadingProjects:
    () =>
    ({ getState }) => {
      const { loadingProjects, loadingMoreProjects } = getState();
      return loadingProjects || loadingMoreProjects;
    },

  setDraftToneOfVoice:
    (draftToneOfVoice) =>
    ({ setState, dispatch }) => {
      setState({
        selectedTov: DRAFT_TOV_ID,
        selectedCustomModelId: null,
        draftToneOfVoice: { ...draftToneOfVoice, name: '' },
      });
      dispatch(PRIVATE_ACTIONS.clearToneOfVoiceError());
    },

  getDraftToneOfVoice:
    () =>
    ({ getState }) => {
      return getState().draftToneOfVoice;
    },

  clearDraftToneOfVoice:
    () =>
    ({ setState }) => {
      setState({
        draftToneOfVoice: null,
      });
    },

  selectDraftToneOfVoice:
    () =>
    ({ setState, dispatch }) => {
      setState({
        selectedTov: DRAFT_TOV_ID,
        selectedCustomModelId: null,
      });
      dispatch(PRIVATE_ACTIONS.clearToneOfVoiceError());
    },

  openToneOfVoiceMenu:
    () =>
    ({ setState, dispatch }) => {
      setState({
        toneOfVoiceMenuOpened: true,
      });
      dispatch(PRIVATE_ACTIONS.clearToneOfVoiceError());
    },
  closeToneOfVoiceMenu:
    () =>
    ({ getState, setState }) => {
      const { selectedTov, selectedCustomModelId } = getState();
      if (!selectedTov && !selectedCustomModelId) {
        setState({ toneOfVoiceError: 'Select tone of voice' });
      }
      setState({
        toneOfVoiceMenuOpened: false,
      });
    },

  storeRecomposeTovInStash:
    () =>
    ({ getState, setState }) => {
      const { selectedTov } = getState();

      setState({ recomposeTovStash: selectedTov });
    },
};

export const ProjectStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});
