import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(5),
    },
    indicator: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    icon: {
      width: 24,
      height: 24,
    },
    text: {
      width: 25,
    },
  }),
  { name: 'Loader' }
);
