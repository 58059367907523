import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { splitStrByMultipleTokens } from 'src/lib/string';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import CustomTextField from 'src/components/CustomTextField';
import { CustomChip } from 'src/components/CustomChip';

const useAutocompleteStyles = makeStyles(
  (theme) => ({
    root: {
      '& $inputRoot': {
        padding: theme.spacing(1),
      },

      '& $inputRoot $input': {
        padding: theme.spacing(1, 0.5),
      },
    },
    inputRoot: {
      fontSize: 'inherit',
    },
    input: {
      '&::placeholder': {
        fontStyle: ({ isItalic }) => (isItalic ? 'italic' : 'none'),
      },
    },
  }),
  { name: 'ChipInput' }
);

// These two arrays represent the keywords delimiters and need to be updated together:
// the delimiters strings
const CUSTOM_DELIMITERS = [',', ';'];
// the keycode to check for when submitting
const DELIMITER_KEY_CODES = [186, 188];

export const ChipInput = ({
  className,
  value,
  onChange,
  onEnterPress,
  disabled,
  inputDisabled,
  maxChips,
  AutocompleteProps,
  InputProps,
  chipClasses,
  isItalic,
  renderTags,
  inputProps,
  onInputChange,
  ...restProps
}) => {
  const otherAutocompleteProps = omit(AutocompleteProps, ['classes']);

  const autocompleteStyles = useAutocompleteStyles({
    classes: AutocompleteProps ? AutocompleteProps.classes : null,
    isItalic,
  });

  const inputVariant = inputProps?.variant || 'outlined';

  const alignedValue = value || [];

  const handleChange = useCallback(
    (event, newValue) => {
      if (!onChange) {
        return;
      }
      const separatedValues = newValue.reduce(
        (acc, v) => [...acc, ...splitStrByMultipleTokens(v, CUSTOM_DELIMITERS)],
        []
      );
      onChange(separatedValues);
    },
    [onChange]
  );

  const handleEnterPress = useCallback(
    (event) => {
      if (!onEnterPress) {
        return;
      }

      onEnterPress(event);
    },
    [onEnterPress]
  );

  return (
    <Autocomplete
      className={className}
      classes={autocompleteStyles}
      options={[]}
      value={alignedValue}
      onChange={handleChange}
      renderTags={
        renderTags
          ? renderTags
          : (tags, getTagProps) =>
              tags.map((option, index) => (
                <CustomChip
                  key={option}
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
      }
      renderInput={(params) => {
        return (
          <CustomTextField
            {...params}
            variant={inputVariant}
            onChange={(e) => onInputChange && onInputChange(e.target.value)}
            dataTestId={`chip${inputProps?.dataTestId?.replace(/\s+/g, '')}`}
            InputProps={{
              autoFocus: InputProps?.autoFocus,
              ...params.InputProps,
              onKeyDown: (e) => {
                if (onEnterPress && e.keyCode === 13 && e.target.value) {
                  handleEnterPress(e.target.value);
                }
                const isKeyCodeAllowed = DELIMITER_KEY_CODES.includes(e.keyCode);
                if (isKeyCodeAllowed && e.target.value) {
                  e.preventDefault();

                  handleChange(e, [...alignedValue, e.target.value]);
                }
              },

              onBlur: (e) => {
                if (!onChange) {
                  return;
                }

                if (
                  !e.target.value.trim() ||
                  (maxChips !== null && maxChips <= alignedValue.length)
                ) {
                  onChange(alignedValue);
                  return;
                }

                onChange([...alignedValue, e.target.value]);
              },
              disabled: inputDisabled || disabled,
            }}
            disabled={inputDisabled || disabled}
            {...restProps}
          />
        );
      }}
      disabled={disabled}
      multiple
      freeSolo
      disableClearable
      clearOnBlur
      autoComplete
      {...otherAutocompleteProps}
      data-testid="ChipInput"
    />
  );
};

ChipInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onEnterPress: PropTypes.func,
  disabled: PropTypes.bool,
  inputDisabled: PropTypes.bool,
  maxChips: PropTypes.number,
  AutocompleteProps: PropTypes.shape(),
  InputProps: PropTypes.shape(),
  chipClasses: PropTypes.shape(),
  isItalic: PropTypes.bool,
  renderTags: PropTypes.func,
  onInputChange: PropTypes.func,
  inputProps: PropTypes.shape(),
};

ChipInput.defaultProps = {
  className: null,
  value: null,
  onChange: null,
  onEnterPress: null,
  disabled: false,
  inputDisabled: false,
  maxChips: null,
  AutocompleteProps: null,
  isItalic: false,
  renderTags: null,
  chipClasses: null,
  onInputChange: null,
  InputProps: null,
  inputProps: null,
};
