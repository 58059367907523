import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'contents',
    },
    tooltip: {},
    text: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,

      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
  }),
  { name: 'TranslatedTooltipWrapper' }
);
