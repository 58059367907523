import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const RocketIcon = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 14 14"
      data-fill-none
    >
      <path
        d="M5.61569 4.63857C4.02593 3.55389 1.43821 3.7886 0.838856 5.16724C0.679089 5.53473 0.922035 5.92265 1.26529 6.12943L3.83686 7.67858"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.36178 8.38477C10.4465 9.97464 10.2127 12.5626 8.83315 13.1617C8.4656 13.3213 8.0777 13.0784 7.87092 12.7352L6.32178 10.1636"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.83667 7.67113L6.33079 10.1652C8.32608 8.96995 10.5943 7.81231 11.6296 6.73936C13.2288 5.14019 13.0243 3.11888 12.7764 2.09324C12.6718 1.66024 12.3417 1.33015 11.9087 1.2255C10.883 0.97762 8.86173 0.773133 7.26255 2.37231C6.18961 3.4076 5.02255 5.68525 3.83667 7.67113Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.88199 11.2629C4.03273 12.1121 2.44895 11.7004 2.44895 11.7004C2.44895 11.7004 2.10067 10.0533 2.8867 9.26758"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
