import { BRIEF_TYPE, getBrandBriefTypeLabel, mapContentForBriefInput } from 'src/data/brief';
import { customerTrialEnded } from 'src/data/customer';
import { getSuggestedAnnotationsOriginLabel } from 'src/data/performance';
import { getUserName } from 'src/data/user';

export const mapBriefForInfinity = (brief) => {
  if (!brief) {
    return null;
  }

  const content = mapContentForBriefInput(BRIEF_TYPE.DEFAULT, brief);
  return {
    name: content.title,
    typeName: 'Product Description',
    body: content.body,
  };
};

export const mapBrandBriefForInfinity = (brief) => {
  if (!brief) {
    return null;
  }

  return {
    id: brief.id,
    name: brief.name,
    typeName: getBrandBriefTypeLabel(brief.briefType),
    body: brief.body,
  };
};

export const mapBrandBriefsForInfinity = (briefs) => {
  if (!briefs || briefs.length === 0) {
    return [];
  }

  return briefs.map(mapBrandBriefForInfinity);
};

export const mapResourcesForInfinity = (resources) => {
  if (!resources || resources.length === 0) {
    return [];
  }

  return resources.map((resource) => ({
    id: resource.id,
    url: resource.url,
    typeName: getSuggestedAnnotationsOriginLabel(resource.websiteType),
  }));
};

export const mapWorkspaceForInfinity = (workspace) => ({
  id: workspace.id,
  name: workspace.name,
});

export const mapWorkspacesForInfinity = (workspaces) => {
  if (!workspaces || workspaces.length === 0) {
    return [];
  }

  return workspaces.map(mapWorkspaceForInfinity);
};

export const mapUserForInfinity = (user) => ({
  id: user.id,
  name: getUserName(user),
  isConfirmed: user.isConfirmed,
});

export const mapCustomerForInfinity = (customer) => ({
  id: customer.id,
  name: customer.name,
  featureLimits: customer.featureLimits,
  trialEnded: customerTrialEnded(customer),
});
