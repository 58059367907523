import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
      zIndex: theme.zIndex.snackbar,
      pointerEvents: 'none',

      '&$floating': {
        position: 'absolute',
        width: '100vw',
        paddingTop: theme.spacing(2),

        '& $wrapper': {
          padding: 0,
          margin: 0,
        },
      },
    },
    floating: {},

    wrapper: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    notification: {
      width: '100%',
      pointerEvents: 'auto',
    },
  }),
  { name: 'GeneralNotifications' }
);
