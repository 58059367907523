import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ExtractResourceTitle($customerId: ID!, $input: ResourceDetailsInput!) {
    extractResourceTitle(customerId: $customerId, input: $input) {
      id
      status
    }
  }
`;

export const extractResourceTitle = ({ customerId, input }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, input },
  }).then(({ data }) => getSingleProp(data));
};
