import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MenuItem } from '@material-ui/core';

import { useStyles } from './useStyles';

export const ItemCustom = forwardRef(({ className, item, disabled }, ref) => {
  const styles = useStyles();

  return (
    <MenuItem
      className={cx(styles.root, className)}
      ref={ref}
      disabled={disabled || item.disabled}
    >
      {item.component}
    </MenuItem>
  );
});

ItemCustom.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    component: PropTypes.node,
    disabled: PropTypes.bool,
  }).isRequired,
  disabled: PropTypes.bool,
};

ItemCustom.defaultProps = {
  className: '',
  disabled: false,
};
