import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Collapse, Typography } from '@material-ui/core';

import { getToolBriefType } from 'src/data/generationTool';
import { BRIEF_TYPE, getBriefFormTitle } from 'src/data/brief';
import { ToggledHeader } from 'src/components/ToggledHeader';
import { BaseItem } from '../BaseItem';
import { useStyles } from './useStyles';

const BriefContent = ({ briefType, requestData, vertical, multiline, classes, generationTool }) => {
  const { briefTitle, briefBody, emailPromotion, freestyleToolAllInstructions } = requestData;

  switch (briefType) {
    case BRIEF_TYPE.unifiedContent:
      return (
        <>
          {briefTitle && (
            <BaseItem
              label="Title"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefTitle}
            </BaseItem>
          )}
          {briefBody && (
            <BaseItem
              label="Body"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefBody}
            </BaseItem>
          )}
        </>
      );

    case BRIEF_TYPE.unifiedProduct:
      return (
        <>
          {freestyleToolAllInstructions && !briefTitle && (
            <BaseItem
              label={getBriefFormTitle(generationTool) || 'Brief'}
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {freestyleToolAllInstructions}
            </BaseItem>
          )}
          {briefTitle && (
            <BaseItem
              label="Product name"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefTitle}
            </BaseItem>
          )}
          {briefBody && freestyleToolAllInstructions && (
            <BaseItem
              label="Product description"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefBody}
            </BaseItem>
          )}
        </>
      );

    case BRIEF_TYPE.unifiedPromotionalEmail:
      return (
        <>
          {freestyleToolAllInstructions && (
            <BaseItem
              label={getBriefFormTitle(generationTool) || 'Brief'}
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {freestyleToolAllInstructions}
            </BaseItem>
          )}
          {briefTitle && !freestyleToolAllInstructions && (
            <BaseItem
              label="Product name"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefTitle}
            </BaseItem>
          )}
          {briefBody && !freestyleToolAllInstructions && (
            <BaseItem
              label="Product description"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefBody}
            </BaseItem>
          )}
          {emailPromotion && !freestyleToolAllInstructions && (
            <BaseItem
              label="Why are you sending this email?"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {emailPromotion}
            </BaseItem>
          )}
        </>
      );

    case BRIEF_TYPE.email:
      return (
        <>
          {freestyleToolAllInstructions && (
            <BaseItem
              label={getBriefFormTitle(generationTool) || 'Brief'}
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {freestyleToolAllInstructions}
            </BaseItem>
          )}
          {briefBody && !freestyleToolAllInstructions && (
            <BaseItem
              label="Brief"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefBody}
            </BaseItem>
          )}
        </>
      );

    case BRIEF_TYPE.socialManual:
      return (
        <>
          {freestyleToolAllInstructions && (
            <BaseItem
              label={getBriefFormTitle(generationTool) || 'Brief'}
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {freestyleToolAllInstructions}
            </BaseItem>
          )}
        </>
      );

    case BRIEF_TYPE.shopifyProductBrief:
      return (
        <>
          {briefTitle && (
            <BaseItem
              label="Product name"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefTitle}
            </BaseItem>
          )}
          {briefBody && (
            <BaseItem
              label="Product description"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefBody}
            </BaseItem>
          )}
        </>
      );

    case BRIEF_TYPE.canvas: {
      return (
        <>
          {briefBody && (
            <BaseItem
              label="Brief"
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {briefBody}
            </BaseItem>
          )}
          {freestyleToolAllInstructions && (
            <BaseItem
              label={'Prompt'}
              vertical={vertical}
              classes={classes}
              hideTitleColon={multiline}
            >
              {freestyleToolAllInstructions}
            </BaseItem>
          )}
        </>
      );
    }

    default:
      return null;
  }
};

export const BriefItem = ({
  generationTool,
  requestData,
  collapsible,
  vertical,
  baseItemClasses,
  multiline,
}) => {
  const styles = useStyles();

  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const briefType = getToolBriefType(generationTool);

  const content = (
    <div className={cx(styles.content, { [styles.vertical]: vertical })}>
      <BriefContent
        briefType={briefType}
        requestData={requestData}
        vertical={vertical}
        classes={baseItemClasses}
        multiline={multiline}
        generationTool={generationTool}
      />
    </div>
  );

  if (!collapsible) {
    return content;
  }

  return (
    <div className={cx(styles.root, { [styles.expanded]: expanded })}>
      <ToggledHeader
        className={styles.toggle}
        classes={{ button: styles.toggle__button }}
        title={<Typography className={styles.toggleTitle}>Brief</Typography>}
        expanded={expanded}
        onClick={toggleExpanded}
        arrowFirst
      />

      <Collapse in={expanded}>{content}</Collapse>
    </div>
  );
};

BriefItem.propTypes = {
  generationTool: PropTypes.string.isRequired,
  requestData: PropTypes.shape({
    briefTitle: PropTypes.string,
    briefBody: PropTypes.string,
    emailPromotion: PropTypes.string,
  }).isRequired,
  collapsible: PropTypes.bool,
};

BriefItem.defaultProps = {
  collapsible: false,
};
