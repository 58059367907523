import { useCallback } from 'react';

import {
  useCustomerActions,
  useCustomModelsActions,
  useIsCurrentUserAdmin,
  usePerformanceActions,
  useWorkspaceActions,
  useTalkingPointsViewsActions,
} from 'src/store';
import {
  useCipEvents,
  useConfirmUnauthorizeFromChannel,
  useRestrictActionPopup,
  useErrorHandler,
  usePromptWorkspaceAccessLevel,
} from 'src/services';
import {
  AD_ACCOUNTS_SELECTION_POPUP,
  useAccountExtractionLoaderPopup,
  useAccountsDataExtractionPopup,
  usePopups,
  useUpgradePopup,
} from 'src/services/popup';
import { captureMessage } from 'src/lib/sentry';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { UPGRADE_DIALOG_ACTIONS_ORIGIN } from 'src/data/common';
import { WORKSPACE_ACCESS_LEVEL } from 'src/data/workspace';
import { PERFORMANCE_EXTRACTION_STATUS } from 'src/data/performance';

export const useHandlers = ({
  channel,
  onConnectSuccess,
  isTrainingModel,
  brandVoiceId,
  isFeatureLimited,
  customModel,
  onClose,
  isTalkingPointsView,
}) => {
  const popups = usePopups();
  const performanceActions = usePerformanceActions();
  const customerActions = useCustomerActions();
  const workspaceActions = useWorkspaceActions();
  const customModelsActions = useCustomModelsActions();
  const talkingPointsViewsActions = useTalkingPointsViewsActions();
  const handleError = useErrorHandler();
  const cipEvents = useCipEvents();
  const confirmUnauthorizeFromChannel = useConfirmUnauthorizeFromChannel();
  const isCurrentUserAdmin = useIsCurrentUserAdmin();
  const openRestrictActionPopup = useRestrictActionPopup();
  const openAccountExtractionLoaderPopup = useAccountExtractionLoaderPopup();
  const openAccountsDataExtractionPopup = useAccountsDataExtractionPopup();
  const promptWorkspaceAccessLevel = usePromptWorkspaceAccessLevel();

  const openUpgradePopup = useUpgradePopup({
    actionsOrigin: UPGRADE_DIALOG_ACTIONS_ORIGIN.cipPageConnectDialog,
  });

  const refreshData = useCallback(() => {
    customerActions.refreshCurrentCustomerFeatureLimit();
    customerActions.refreshCurrentCustomerRowsAllocation();
  }, [customerActions]);

  const handleSortChange = useCallback(
    (field) => {
      performanceActions.sortAccounts(field, channel);
    },
    [performanceActions, channel]
  );

  const handleDisconnect = useCallback(async () => {
    if (!isCurrentUserAdmin) {
      openRestrictActionPopup();
      return;
    }
    const confirmed = await confirmUnauthorizeFromChannel(channel);
    if (!confirmed) {
      return;
    }
    cipEvents.trackDisconnectChannel(channel);

    performanceActions.disconnectFromChannel(channel);
    performanceActions.cancelWaitRequests(channel);

    refreshData();
    popups.closePopup(AD_ACCOUNTS_SELECTION_POPUP);
  }, [
    confirmUnauthorizeFromChannel,
    cipEvents,
    performanceActions,
    popups,
    isCurrentUserAdmin,
    channel,
    openRestrictActionPopup,
    refreshData,
  ]);

  const handleConnect = useCallback(
    async ({ account, isConnected, workspaceAccessLevel }) => {
      cipEvents.trackConnectAdAccounts(account.channel, account.id, 'connect');
      const shouldRecreate = customModel?.accountId === account.id;

      if (isTrainingModel && isConnected) {
        try {
          let cm = customModel;
          if (customModel?.id && shouldRecreate) {
            await customModelsActions.reCreateCustomModel({ customModel });
          } else {
            cm = await customModelsActions.createCustomModel({
              brandVoiceId,
              accountId: account.id,
              channel: account.channel,
              onCreateSuccess: () =>
                openAccountExtractionLoaderPopup({
                  customModelAccount: account,
                  isTrainingModel,
                }),
            });
          }
          refreshData();
          onConnectSuccess?.({
            customModelId: cm.id,
            brandVoiceId,
          });
        } catch (error) {
          handleError(error);
          captureMessage(`failed to ${customModel?.id ? 're' : ''}create custom model`, {
            level: 'warning',
            extra: {
              error: error.toString(),
              source: 'AdAccountsSelectionPopup -> useHandlers -> handleConnect',
            },
          });
        }
        return;
      }

      if (!isCurrentUserAdmin && !isTalkingPointsView) {
        openRestrictActionPopup();
        return;
      }

      // If account is already connected, just call for TP extraction
      if (isTalkingPointsView && isConnected) {
        performanceActions.resetAvgCompletionPercent(account.channel, account.id);

        openAccountExtractionLoaderPopup({
          account,
          isTalkingPointsView,
          onlyExtractingTalkingPoints: true,
        });

        try {
          await performanceActions.displayExtractingProgressIndicator(
            account.channel,
            account.id,
            talkingPointsViewsActions.extractTalkingPointsView(account.id, account.channel)
          );
        } catch (error) {
          handleError(error);
        }
        return;
      }

      // Otherwise, connect account (and extract TP if needed)

      openAccountExtractionLoaderPopup({
        account,
        isTrainingModel,
        isTalkingPointsView,
      });

      performanceActions
        .connectPerformanceCenterAccount({
          account,
          workspaceId:
            workspaceAccessLevel === WORKSPACE_ACCESS_LEVEL.onlyCurrentWorkspace
              ? workspaceActions.getCurrentWorkspaceId()
              : null,
          customModelBrandVoiceId: brandVoiceId,
          isTalkingPointsView,
        })
        .then((result) => {
          const customModelId = result?.customDatasetId;
          if (customModelId) {
            onConnectSuccess?.({
              customModelId,
              brandVoiceId,
            });
          } else {
            if (result?.status === PERFORMANCE_EXTRACTION_STATUS.done) {
              openAccountsDataExtractionPopup({ account });
              onConnectSuccess?.({
                brandVoiceId,
              });
            }
          }
          refreshData();
        })
        .catch((error) => {
          handleError(error);
          captureMessage(`failed to connect performance center ${channel}`, {
            level: 'warning',
            extra: {
              error: error.toString(),
              source: 'AdAccountsSelectionPopup -> useHandlers -> handleConnect',
            },
          });
        });
    },
    [
      cipEvents,
      customModel,
      isTrainingModel,
      isCurrentUserAdmin,
      isTalkingPointsView,
      openAccountExtractionLoaderPopup,
      customModelsActions,
      performanceActions,
      workspaceActions,
      brandVoiceId,
      refreshData,
      onConnectSuccess,
      handleError,
      openRestrictActionPopup,
      talkingPointsViewsActions,
      openAccountsDataExtractionPopup,
      channel,
    ]
  );

  const handleAccountClick = useCallback(
    async (account, isConnected) => {
      const currentWorkspaces = workspaceActions.getCurrentWorkspaces();
      if (!account) {
        return;
      }

      if (isFeatureLimited && !isConnected) {
        openUpgradePopup(FEATURE_LIMITS.resourcesRowsPerCustomer);
        return;
      }

      // Request workspace access level for this account - only if not connected and customer has more than 1 workspace
      let workspaceAccessLevel;
      if (!isConnected && currentWorkspaces.length > 1) {
        workspaceAccessLevel = await promptWorkspaceAccessLevel(account);
        if (!workspaceAccessLevel) {
          return;
        }
      }

      // Start "connection" flow
      handleConnect({
        account,
        isConnected,
        workspaceAccessLevel,
      });

      onClose();
    },
    [
      isFeatureLimited,
      workspaceActions,
      promptWorkspaceAccessLevel,
      handleConnect,
      onClose,
      openUpgradePopup,
    ]
  );

  return { handleSortChange, handleDisconnect, handleAccountClick };
};
