import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

const ChannelTwitter = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 40 40"
      data-fill-none
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.1753 0.449116C38.3356 0.598773 38.465 0.778651 38.5559 0.978466C38.6469 1.17828 38.6977 1.39412 38.7054 1.61363C38.7131 1.83315 38.6777 2.05205 38.601 2.25781C38.5244 2.46357 38.408 2.65216 38.2586 2.8128L24.6978 17.3977L39.6654 37.3235C39.8515 37.5718 39.965 37.8671 39.9931 38.1764C40.0212 38.4857 39.9629 38.7967 39.8246 39.0746C39.6863 39.3525 39.4736 39.5863 39.2102 39.7497C38.9469 39.9131 38.6433 39.9997 38.3336 39.9998H29.2031C28.9449 40 28.6902 39.9399 28.4591 39.8245C28.228 39.709 28.0268 39.5413 27.8713 39.3345L17.3373 25.3146L4.17985 39.4682C3.87837 39.7925 3.46078 39.9835 3.01894 39.999C2.57711 40.0145 2.14723 39.8533 1.82387 39.551C1.5005 39.2486 1.31015 38.8298 1.29468 38.3867C1.2792 37.9436 1.43988 37.5124 1.74136 37.1881L15.3022 22.6032L0.334608 2.6774C0.148574 2.42928 0.0351082 2.1341 0.00692422 1.82495C-0.0212598 1.5158 0.0369517 1.20488 0.175036 0.927041C0.31312 0.6492 0.525621 0.415412 0.788728 0.251874C1.05183 0.0883352 1.35515 0.00150747 1.66469 0.00111991H10.7969C11.0548 0.00124825 11.3092 0.0614025 11.54 0.176849C11.7708 0.292296 11.9717 0.459884 12.127 0.666428L22.661 14.6864L35.8168 0.532697C35.966 0.371915 36.1454 0.242205 36.3446 0.15098C36.5439 0.059756 36.7591 0.00880644 36.978 0.00104416C37.1968 -0.00671813 37.4151 0.0288591 37.6203 0.105742C37.8254 0.182625 38.0151 0.299306 38.1753 0.449116ZM21.2576 18.3756C21.2106 18.3211 21.1672 18.2636 21.1276 18.2035L9.96521 3.3427H5.00657L30.0331 36.6582H34.9918L21.2576 18.3756Z"
        fill="black"
      />
    </SvgIcon>
  ))
);

export default ChannelTwitter;
