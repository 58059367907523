import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query GetPerformanceCenterResultDocument(
    $performanceCenterTextVariationsResultId: ID!
    $customerId: ID!
  ) {
    getPerformanceCenterResultDocument(
      performanceCenterTextVariationsResultId: $performanceCenterTextVariationsResultId
      customerId: $customerId
    ) {
      id
    }
  }
`;

export const getPerformanceCenterResultDocument = (
  performanceCenterTextVariationsResultId,
  customerId
) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      performanceCenterTextVariationsResultId,
      customerId,
    },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => {
      return result?.id;
    });
};
