import { createStore } from 'react-sweet-state';
import { cloneDeep, orderBy } from 'lodash';
import { DateTime } from 'luxon';

import { captureMessage } from 'src/lib/sentry';
import {
  customerTrialEnded,
  isCustomerOnFreemium,
  isCustomerQuotaEnded,
  isCustomerSubscriptionCanceled,
} from 'src/data/customer';
import {
  queryCustomer,
  queryCustomerBillingConfig,
  queryGeneratedWords,
  updateCustomerName,
  queryCustomerFeatureLimit,
  queryCustomerRowsAllocation,
} from 'src/graphql/customer';
import * as ls from 'src/lib/localData';
import * as log from 'src/lib/log';
import pubsub, { PUBSUB } from 'src/lib/pubsub';
import { isUserAdminForCustomer } from 'src/data/user';
import { isApplicationModeInfinity } from 'src/data/system';
import { featureHasUsages, getFeatureLimitsData } from 'src/data/featureLimits';

const NAME = 'customer';

const persistCustomerSelection = (customerId) => {
  try {
    ls.currentCustomerId.write(customerId);
  } catch (error) {
    captureMessage("Couldn't persist customer selection", {
      level: 'warning',
      extra: {
        error: error.toString(),
        source: 'CustomerStore -> persistCustomerSelection',
      },
    });
  }
};

const sortCustomers = (customers) => orderBy(customers, ['name'], ['asc']);

const prepareCustomers = (customers) => {
  return sortCustomers(customers);
};

const inferCurrentCustomerId = (customers) => {
  if (!customers) {
    return null;
  }

  // In Extension We don't care about preserving "selected customer" per tab - always use last selected per browser
  const shouldSkipSession = isApplicationModeInfinity();

  // Check if saved in LS customer is in 'active' list
  const lsCustomerId = ls.currentCustomerId.read({ skipSession: shouldSkipSession });
  const lsCustomer = lsCustomerId ? customers.find((c) => String(c.id) === lsCustomerId) : null;

  // First customer from list
  const firstCustomer = customers[0];

  // If customer from LS is valid - use it, or first one from the list, in other case
  const currentCustomer = lsCustomer || firstCustomer;

  return currentCustomer ? currentCustomer.id : null;
};

const notifyGqlClient = (customerId) => {
  pubsub.publish(PUBSUB.CUSTOMER_ID_CHANGED, {
    customerId,
  });
};

const INITIAL_STATE = {
  currentCustomers: [],
  currentCustomerId: null,
  currentCustomer: null,

  generatedWords: [],
  loadingGeneratedWords: false,
};

/**
 * Private actions
 */
export const PRIVATE_ACTIONS = {
  mergeCurrentCustomerData:
    (data) =>
    ({ getState, setState }) => {
      const { currentCustomer, currentCustomers } = getState();

      // update customer data in store
      const newList = currentCustomers.map((n) =>
        String(n.id) === String(data.id) ? { ...n, ...data } : n
      );

      setState({ currentCustomers: prepareCustomers(newList) });

      // Update current customer data
      if (currentCustomer && currentCustomer.id === data.id) {
        setState({ currentCustomer: { ...currentCustomer, ...data } });
      }
    },

  removeFromCurrentCustomers:
    (customerId) =>
    ({ getState, setState }) => {
      const { currentCustomers } = getState();
      const newList = currentCustomers.filter((n) => String(n.id) !== String(customerId));
      setState({ currentCustomers: prepareCustomers(newList) });
    },

  renameCurrentCustomerInState:
    (name) =>
    ({ getState, setState }) => {
      const { currentCustomer, currentCustomerId, currentCustomers } = getState();

      const newCurrentCustomers = currentCustomers.map((customer) => {
        if (customer.id === currentCustomerId) {
          return {
            ...customer,
            name,
          };
        }

        return customer;
      });

      setState({
        currentCustomer: { ...currentCustomer, name },
        currentCustomers: newCurrentCustomers,
      });
    },

  clearState:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },
};

/**
 * Publicly available actions
 */
export const ACTIONS = {
  init:
    (user) =>
    ({ setState, dispatch }) => {
      // Clear internal state if:
      // * there is no user - this is logged-out state
      // * user is not confirmed - most of BE calls fail in this state
      if (!user || !user.isConfirmed) {
        dispatch(PRIVATE_ACTIONS.clearState());
        return;
      }

      // Store this users customer list
      const customers = prepareCustomers(user.customers || []);
      setState({ currentCustomers: customers });

      // Preselect current customer
      const currentCustomerId = inferCurrentCustomerId(customers);
      if (currentCustomerId) {
        dispatch(ACTIONS.selectCurrentCustomer(currentCustomerId)).catch((error) => {
          captureMessage("Couldn't select customer", {
            level: 'warning',
            extra: {
              error: error.toString(),
              source: 'CustomerStore -> init',
            },
          });
        });
      } else {
        setState({ currentCustomer: null });
      }
    },

  selectCurrentCustomer:
    (customerId) =>
    async ({ getState, setState, dispatch }) => {
      // Don't start another request for the same customer ID
      if (getState().currentCustomerId === customerId) {
        return;
      }
      setState({ currentCustomerId: customerId });

      // Fetch customer data
      const customer = await dispatch(ACTIONS.queryCustomer(customerId));
      if (!customer) {
        return;
      }

      // Persist customer data in store
      setState({ currentCustomer: customer });
      persistCustomerSelection(customer.id);
      notifyGqlClient(customer.id);

      // Fetch additional customer data
      dispatch(ACTIONS.refreshCurrentCustomerAdditionalData());
    },

  getIsCurrentUserAdmin:
    (customerId = null) =>
    ({ dispatch }, { currentUser }) => {
      const alignedCustomerId = customerId || dispatch(ACTIONS.getCurrentCustomerId());
      return isUserAdminForCustomer(currentUser, alignedCustomerId);
    },

  queryCustomer:
    (customerId) =>
    async ({ dispatch }) => {
      const isCurrentUserAdmin = dispatch(ACTIONS.getIsCurrentUserAdmin(customerId));

      try {
        // Load main "customer" data
        const customer = await queryCustomer({
          id: customerId,
          includePaymentMethod: isCurrentUserAdmin,
        });
        if (!customer) {
          captureMessage('"queryCustomer" GQL query returned empty data', {
            level: 'warning',
            extra: {
              source: 'CustomerStore -> queryCustomer',
            },
          });
          throw new Error('Customer invalid');
        }

        // Update customer data in store
        dispatch(PRIVATE_ACTIONS.mergeCurrentCustomerData(customer));

        return customer;
      } catch (error) {
        // if we can't access customer anymore - user don't have access to it
        if (error && error.message.includes('Customer invalid')) {
          dispatch(PRIVATE_ACTIONS.removeFromCurrentCustomers(customerId));
          return null;
        }

        throw error;
      }
    },

  refreshCurrentCustomer:
    () =>
    async ({ getState, dispatch }) => {
      const { currentCustomerId } = getState();
      if (!currentCustomerId) {
        throw new Error('No customer selected');
      }

      try {
        // Refetch customer data
        await dispatch(ACTIONS.queryCustomer(currentCustomerId));
        // Refetch additional customer data
        dispatch(ACTIONS.refreshCurrentCustomerAdditionalData());
      } catch (error) {
        captureMessage("Couldn't refresh customer", {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'CustomerStore -> refreshCurrentCustomer',
          },
        });
      }
    },

  /**
   * Load additional customer data, no need to wait for these queries to finish
   */
  refreshCurrentCustomerAdditionalData:
    () =>
    ({ dispatch }) => {
      dispatch(ACTIONS.refreshCurrentCustomerFeatureLimit());
      dispatch(ACTIONS.refreshCurrentCustomerRowsAllocation());
    },

  refreshCurrentCustomerQuota:
    () =>
    async ({ getState, dispatch }) => {
      const { currentCustomer } = getState();
      if (!currentCustomer) {
        log.error(new Error('No customer selected'));
        return;
      }

      try {
        const data = await queryCustomerBillingConfig(currentCustomer.id);
        dispatch(PRIVATE_ACTIONS.mergeCurrentCustomerData(data));

        return data;
      } catch (e) {
        log.error(e);
      }
    },

  refreshCurrentCustomerFeatureLimit:
    () =>
    async ({ getState, dispatch }) => {
      const { currentCustomer } = getState();
      if (!currentCustomer) {
        log.error(new Error('No customer selected'));
        return;
      }

      try {
        const data = await queryCustomerFeatureLimit(currentCustomer.id);
        dispatch(PRIVATE_ACTIONS.mergeCurrentCustomerData(data));

        return data;
      } catch (e) {
        log.error(e);
      }
    },

  refreshCurrentCustomerRowsAllocation:
    () =>
    async ({ getState, dispatch }) => {
      const { currentCustomer } = getState();
      if (!currentCustomer) {
        log.error(new Error('No customer selected'));
        return;
      }

      try {
        const data = await queryCustomerRowsAllocation(currentCustomer.id);
        dispatch(PRIVATE_ACTIONS.mergeCurrentCustomerData(data));
      } catch (e) {
        log.error(e);
      }
    },

  featureLimitExceeded:
    (feature) =>
    ({ getState }) => {
      const { currentCustomer } = getState();
      if (!currentCustomer) {
        return false;
      }

      const featureData = getFeatureLimitsData(currentCustomer, feature);
      return !featureHasUsages(featureData);
    },

  getCurrentCustomer:
    () =>
    ({ getState }) => {
      return getState().currentCustomer;
    },
  getCurrentCustomerId:
    () =>
    ({ getState }) => {
      const { currentCustomer } = getState();
      return currentCustomer ? currentCustomer.id : null;
    },

  isCurrentQuotaEnded:
    () =>
    ({ getState }) => {
      const { currentCustomer } = getState();

      return (
        isCustomerQuotaEnded(currentCustomer) ||
        customerTrialEnded(currentCustomer) ||
        (isCustomerSubscriptionCanceled(currentCustomer) && !isCustomerOnFreemium(currentCustomer))
      );
    },

  queryGeneratedWords:
    () =>
    ({ getState, setState }) => {
      const { currentCustomer } = getState();

      const today = DateTime.local();
      const weekAgo = today.minus({ days: 7 });

      setState({ loadingGeneratedWords: true });
      return queryGeneratedWords({
        customerId: currentCustomer.id,
        startDate: weekAgo.toISODate(),
        endDate: today.toISODate(),
      })
        .then((result) => {
          setState({ generatedWords: result || [] });
        })
        .finally(() => {
          setState({ loadingGeneratedWords: false });
        });
    },

  renameCurrentCustomer:
    (name) =>
    async ({ getState, dispatch }) => {
      const { currentCustomer } = getState();
      const member = await updateCustomerName(currentCustomer.id, name);

      dispatch(PRIVATE_ACTIONS.renameCurrentCustomerInState(name));

      return member;
    },
};

export const CustomerStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});
