import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)': {
        borderColor: COLORS.primary.light,
      },
      '& .tox-tinymce': {
        minHeight: 100,
        border: theme.shape.borderPrimaryLight,
        borderRadius: theme.shape.borderRadius,
      },
      '& .tox .tox-tbtn svg': {
        fill: theme.palette.text.primary,
      },
      '& .tox .tox-tbtn.tox-tbtn--disabled svg': {
        fill: theme.palette.text.disabled,
      },
      '& .tox .tox-statusbar__resize-handle svg': {
        fill: theme.palette.dividerGray,
      },
      '& .tox .tox-statusbar': {
        borderTop: 0,
      },
      '& .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary': {
        background: 'none',
        borderBottom: theme.shape.borderPrimaryLight,
      },
    },
    toolbar: {},
    loader: {
      color: theme.palette.bubble.commonText,
      marginTop: theme.spacing(1.5),
      animationDuration: '800ms',
    },
    wrapper: {
      position: 'relative',
    },
    score: {
      position: 'absolute',
      top: 50,
      right: 10,
      zIndex: 1000,
    },
  }),
  { name: 'RichTextEditor' }
);
