import { useMemo } from 'react';
import { map } from 'lodash';

import { createStateSelectorHook } from 'src/lib/sweetState';
import { flatConnectedAccounts } from 'src/data/performance';
import { PerformanceStore } from './PerformanceStore';

const usePerformanceState = createStateSelectorHook(PerformanceStore, [
  'globalBenchmarkConfigs',
  'loadingGlobalBenchmark',
  'accounts',
]);

export const useGlobalBenchmarkConfigs = () => {
  const { globalBenchmarkConfigs, loadingGlobalBenchmark, accounts } = usePerformanceState();

  const flattenConnectedAccounts = flatConnectedAccounts(accounts, true);

  const workspaceAccountIds = useMemo(
    () => map(flattenConnectedAccounts, 'id'),
    [flattenConnectedAccounts]
  );

  const filteredGlobalBenchmarkConfig = useMemo(() => {
    return globalBenchmarkConfigs?.filter((c) => workspaceAccountIds.includes(c.account?.id));
  }, [globalBenchmarkConfigs, workspaceAccountIds]);

  return {
    globalBenchmarkConfigs: filteredGlobalBenchmarkConfig,
    loadingGlobalBenchmark,
  };
};
