import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 557,
    },
    dialogContent: {
      margin: '0',
      padding: theme.spacing(2, 4, 3),
    },
    success: {
      padding: theme.spacing(2),
      backgroundColor: COLORS.success.extraLight,
      color: COLORS.success.active,
      marginBottom: theme.spacing(1.25),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      display: 'flex',
      alignItems: theme.spacing(1),
      columnGap: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    successIcon: {
      fontSize: theme.typography.pxToRem(16),
    },
  }),
  { name: 'AfterCancelSubscriptionPopup' }
);
