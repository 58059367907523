import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { BRIEF_FRAGMENT } from './schema';

const QUERY = gql`
  query QueryBrief($id: ID!) {
    brief(id: $id) {
      ...Brief
    }
  }
  ${BRIEF_FRAGMENT}
`;

export const queryBrief = (id) =>
  executeGqlQuery({
    query: QUERY,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
