import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useStyles } from './useStyles';

export const BaseItem = ({ classes, label, vertical, hideTitleColon, children, grayTitle }) => {
  const styles = useStyles({ classes });

  return (
    <span
      className={cx(styles.root, {
        [styles.vertical]: vertical,
        [styles.displaySeparator]: !vertical,
      })}
    >
      <span className={cx(styles.title, { [styles.grayish]: grayTitle })}>
        {label}
        {!hideTitleColon && ':'}
      </span>
      <span className={styles.body}>{children}</span>
    </span>
  );
};

BaseItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  vertical: PropTypes.bool,
};

BaseItem.defaultProps = {
  vertical: false,
};
