import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';

import { getChannelPerformanceShortLabel } from 'src/data/channel';
import { ChannelIcon } from 'src/components/ChannelIcon';
import { IconCup } from 'src/assets/icons/IconCup';
import { getIsEmailChannel } from 'src/data/performance';
import { IconEmail } from 'src/assets/icons/IconEmail';
import { useStyles } from './useStyles';

export const Connect = ({ onConnectClick, channel }) => {
  const styles = useStyles();
  const isEmailChannel = getIsEmailChannel(channel);
  return (
    <>
      <div className={styles.iconCupContainer}>
        <IconCup className={styles.iconCup} />
      </div>
      <Typography className={styles.connectLabel}>
        See how this text compares with
        <br />
        text you published in the past.
      </Typography>
      <Button
        color="primary"
        variant="text"
        className={styles.connectButton}
        classes={{ startIcon: styles.startIcon }}
        onClick={onConnectClick}
        startIcon={
          isEmailChannel ? (
            <IconEmail className={styles.iconEmail} />
          ) : (
            <ChannelIcon
              className={styles.iconChannel}
              channel={channel}
            />
          )
        }
      >
        {isEmailChannel
          ? 'Connect your email'
          : `Connect to ${getChannelPerformanceShortLabel(channel)}`}
      </Button>
    </>
  );
};

Connect.propTypes = {
  onConnectClick: PropTypes.func.isRequired,
  channel: PropTypes.string.isRequired,
};
