import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import TextLink from 'src/components/TextLink';
import { useStyles } from './useStyles';

const DefaultOptions = ({ className, classes, options, onSelect, disabled }) => {
  const styles = useStyles({ classes });

  return (
    <div
      className={cx(styles.root, className)}
      data-testid="DefaultOptions"
    >
      <CustomTypography
        className={styles.title}
        varaint={TYPOGRAPHY_VARIANT.regular14}
      >
        Here are a few suggestions
      </CustomTypography>
      <div className={styles.options}>
        {options.map((option) => (
          <TextLink
            className={styles.option}
            key={option.id}
            onClick={() => onSelect(option)}
            disabled={disabled}
            data-testid={`DefaultOptions__${option.name}Link`}
          >
            {option.name}
          </TextLink>
        ))}
      </div>
    </div>
  );
};

DefaultOptions.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DefaultOptions.defaultProps = {
  className: null,
  classes: null,
  disabled: false,
};

export default React.memo(DefaultOptions);
