import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconCompanyBio = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 30 30"
        data-fill-none
      >
        <rect
          x="3"
          y="3"
          width="24"
          height="24"
          rx="4"
          fill="#28354D"
        />
        <path
          d="M12.5 18.5H7.5V8.5H8.94118H17.5V23.5H13V23V19V18.5H12.5Z"
          fill="#28354D"
          stroke="white"
        />
        <rect
          x="9"
          y="10"
          width="3"
          height="1"
          fill="white"
        />
        <rect
          x="9"
          y="14"
          width="7"
          height="1"
          fill="white"
        />
        <rect
          x="9"
          y="12"
          width="7"
          height="1"
          fill="white"
        />
        <rect
          x="9"
          y="16"
          width="7"
          height="1"
          fill="white"
        />
        <rect
          x="21"
          y="10"
          width="1"
          height="3"
          fill="white"
        />
        <path
          d="M17.5 13.5H22.5V23.5H17.5V13.5Z"
          fill="#28354D"
          stroke="white"
        />
        <rect
          x="19"
          y="15"
          width="2"
          height="1"
          fill="white"
        />
        <rect
          x="19"
          y="17"
          width="2"
          height="1"
          fill="white"
        />
        <rect
          x="19"
          y="19"
          width="2"
          height="1"
          fill="white"
        />
        <rect
          x="19"
          y="21"
          width="2"
          height="1"
          fill="white"
        />
        <rect
          x="8.25"
          y="18.25"
          width="0.5"
          height="5.5"
          stroke="white"
          strokeWidth="0.5"
        />
        <rect
          x="5"
          y="23"
          width="20"
          height="1"
          fill="white"
        />
      </SvgIcon>
    );
  })
);
