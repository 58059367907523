import gql from 'graphql-tag';

export const FRAGMENT_JOB = gql`
  fragment Job on Job {
    id
    status
    result
    processingErrors {
      code
      errorProperties
      message
    }
  }
`;
