import isEmpty from 'validator/lib/isEmpty';
import isLength from 'validator/lib/isLength';
import isEmail from 'validator/lib/isEmail';
import trim from 'lodash/trim';

const MAX_NAME_LENGTH = 60;

export const SOCIAL_AUTH_STATE = {
  authorized: 'authorized',
  unauthorized: 'unauthorized',
  tokenIrrelevant: 'tokenIrrelevant',
};

export const validateEmail = (email) => {
  const messages = [];

  if (isEmpty(trim(email))) {
    messages.push('Please enter an email address');
  } else if (!isEmail(trim(email))) {
    messages.push('Email is invalid');
  }

  return {
    valid: messages.length === 0,
    messages,
  };
};

export const validateName = (name) => {
  const messages = [];

  if (name.match(/[^A-Za-z0-9&.,\-\s]/gi)) {
    messages.push('Only English characters are allowed');
  } else if (!isLength(name, { max: MAX_NAME_LENGTH })) {
    messages.push(`Names are limited to ${MAX_NAME_LENGTH} characters.`);
  }

  return {
    valid: messages.length === 0,
    messages,
  };
};

export const isUserFeatureEnabled = (user, featureFlag) => {
  if (!user) return false;

  const userFeatures = user.featureFlags || [];
  return userFeatures.includes(featureFlag);
};

export const MARKETING_USER_POSITION = {
  other: 'Other',
  contentMarketing: 'Content Marketing',
  demandGenAcquisition: 'Demand Gen/Acquisition',
  socialMediaManager: 'Social Media Manager',
  productMarketing: 'Product Marketing',
  marketingOps: 'Marketing Ops',
  vpMarketing: 'VP Marketing',
  cmo: 'CMO',
  founderCeo: 'Founder/CEO',
};

export const USER_SEGMENT = {
  demandGen: 'Demand Gen',
  decisionMakers: 'Decision Makers',
  other: 'Other',
  productMarketing: 'Product Marketing',
  content: 'Content',
  marketingOps: 'Marketing Ops',
};

// Currently, almost all the "enum values" equal "visible labels"
export const getUserMarketingPositionLabel = (position) => {
  return position;
};

export const USER_ROLE = {
  admin: 'admin',
  member: 'member',
};

const USER_ROLE_LABEL = {
  [USER_ROLE.admin]: 'Admin',
  [USER_ROLE.member]: 'Member',
};

export const getUserRoleLabel = (role) => {
  return USER_ROLE_LABEL[role] || role;
};

export const getUserRoleForCustomer = (user, customerId) => {
  const userCustomer = user?.customers?.find((customer) => customer.id === customerId);
  return userCustomer?.role;
};

export const isAdminRole = (role) => role === USER_ROLE.admin;

export const getUserName = (user) => {
  if (user?.name) {
    return user?.name;
  }

  if (user?.email) {
    return user?.email?.split('@')[0];
  }

  return '';
};

export const getUserCompanySize = (user) => user?.companyInformation?.company_size;

export const getUserCountry = (user) => user?.country;

export const isUserAdminForCustomer = (user, customerId) => {
  const roleForCustomer = getUserRoleForCustomer(user, customerId);
  return isAdminRole(roleForCustomer);
};
