import { useCallback } from 'react';

import { BLOG_CUSTOM_MODEL_POPUP } from '../types';
import { usePopups } from '../services';

export const useBlogCustomModelPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ brandVoiceId, onConnectSuccess, blogCustomModel }) => {
      return new Promise((resolve) => {
        popups.openPopup(BLOG_CUSTOM_MODEL_POPUP, {
          onSubmit: (result) => {
            popups.closePopup(BLOG_CUSTOM_MODEL_POPUP);
            resolve(result);
          },
          onClose: () => {
            popups.closePopup(BLOG_CUSTOM_MODEL_POPUP);
            resolve();
          },
          brandVoiceId,
          onConnectSuccess,
          blogCustomModel,
        });
      });
    },
    [popups]
  );
};
