import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';

import { formatDateMed } from 'src/lib/format';
import { useCustomerSubscriptionInfo } from 'src/store';
import IconClose from 'src/assets/icons/Close';
import { SubscriptionPlanFeatures } from 'src/components/SubscriptionPlanFeatures';
import { useDowngradePlanFeatures } from 'src/services/usePlanFeatures';
import useStyles from './useStyles';

export const DowngradePlanConfirmationPopup = ({
  plan,
  tier,
  open,
  onClose,
  onConfirm,
  isFromMonthlyToAnnually,
}) => {
  const styles = useStyles();

  const { currentPlan, currentTier, endOfBillingPeriod } = useCustomerSubscriptionInfo();
  const { features, textFormatObject } = useDowngradePlanFeatures(plan, tier);

  const periodEnd = endOfBillingPeriod ? formatDateMed(endOfBillingPeriod) : ' - ';

  return (
    <Dialog
      open={open}
      classes={{ paper: styles.paper }}
    >
      <DialogTitle
        className={styles.dialogTitle}
        disableTypography
      >
        <Typography className={styles.textTitle}>Downgrade Plan?</Typography>

        <IconButton
          className={styles.buttonClose}
          onClick={onClose}
        >
          <IconClose />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        {isFromMonthlyToAnnually ? (
          <Typography className={styles.textContent}>
            If you downgrade, <b>you will lose access to all data-driven features.</b>
          </Typography>
        ) : (
          <Typography className={styles.textContent}>
            If you downgrade, your subscription will remain active until {periodEnd}, the end of
            your current billing cycle. After that,{' '}
            <b>you will lose access to all data-driven features.</b>
          </Typography>
        )}

        <SubscriptionPlanFeatures
          className={styles.features}
          features={features}
          textFormatObject={textFormatObject}
          currentPlan={currentPlan}
          currentTier={currentTier}
          removed
        />
      </DialogContent>

      <DialogActions className={styles.dialogActions}>
        <Button
          className={styles.buttonCancel}
          onClick={onClose}
          variant="text"
          color="default"
        >
          Cancel
        </Button>

        <Button
          className={styles.buttonConfirm}
          onClick={onConfirm}
          variant="outlined"
        >
          Downgrade Plan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DowngradePlanConfirmationPopup.propTypes = {
  plan: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isFromMonthlyToAnnually: PropTypes.bool.isRequired,
};
DowngradePlanConfirmationPopup.defaultProps = {
  open: false,
};
