import { useCallback } from 'react';

import { FEATURE_LIMITS, featureHasUsages, getFeatureLimitsData } from 'src/data/featureLimits';
import { checkCustomerResourcesExistence } from 'src/graphql/performance';
import { RESOURCE_CONTENT_TYPE } from 'src/data/performance';
import { captureMessage } from 'src/lib/sentry';
import { useCurrentCustomer, useCurrentWorkspaceId } from 'src/store';

export const useCanCustomerCreateResource = () => {
  const currentCustomer = useCurrentCustomer();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const featureLimitsData = getFeatureLimitsData(
    currentCustomer,
    FEATURE_LIMITS.resourcesPerCustomer
  );

  return useCallback(
    async (urls, file) => {
      //Enterprise plan doesn't limited
      if (!featureLimitsData?.limit) {
        return true;
      }

      if (urls?.some((url) => url) || file) {
        const inputs = file
          ? { file, type: RESOURCE_CONTENT_TYPE.document }
          : urls.map((url) => ({
              content: url,
              type: RESOURCE_CONTENT_TYPE.url,
            }));

        try {
          const existenceResult = await checkCustomerResourcesExistence({
            customerId: currentCustomer.id,
            workspaceId: currentWorkspaceId,
            inputs,
          });
          const nonExistingUrls = existenceResult?.filter((input) => !input.exist);
          if (nonExistingUrls?.length === 0) {
            return true;
          }

          if (featureLimitsData) {
            return (
              featureLimitsData.currentCount + nonExistingUrls.length <= featureLimitsData.limit
            );
          }
        } catch (error) {
          captureMessage('Error when checking resource existence', {
            level: 'warning',
            extra: {
              error: error.toString(),
              source: 'useCanCustomerCreateResource',
              urls,
              currentCustomerId: currentCustomer.id,
            },
          });

          return false;
        }
      }
      return featureHasUsages(featureLimitsData);
    },
    [featureLimitsData, currentCustomer, currentWorkspaceId]
  );
};
