import { useEffect, useState } from 'react';

import useCurrentUser from 'src/store/user/useCurrentUser';
import useZendeskWidgetStatus from 'src/store/ui/useZendeskWidgetStatus';

const ZENDESK_KEY = '98d96898-d1ff-440e-bfe6-e4c5e5f82608';

const showWidget = (user) => {
  if (!window.zE) {
    return;
  }

  window.zE('webWidget', 'prefill', {
    name: {
      value: user.name,
    },
    email: {
      value: user.email,
    },
  });
  window.zE('webWidget', 'show');
};

const hideWidget = () => {
  if (!window.zE) {
    return;
  }

  window.zE('webWidget', 'hide');
};

const ZendeskSupportWidget = () => {
  const { zendeskWidgetVisible } = useZendeskWidgetStatus();

  const [loaded, setLoaded] = useState(false);
  const currentUser = useCurrentUser();

  // Mount "zendesk" script
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;
    script.onload = () => {
      setLoaded(true);
      hideWidget();
    };
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (!loaded || !currentUser || !zendeskWidgetVisible) {
      return undefined;
    }

    showWidget(currentUser);

    return () => {
      hideWidget();
    };
  }, [loaded, currentUser, zendeskWidgetVisible]);

  return null;
};

export default ZendeskSupportWidget;
