import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_SUGGEST_REQUEST } from './schema';

const MUTATION = gql`
  mutation SuggestVariations(
    $projectId: ID!
    $assetType: String!
    $suggestionMode: String!
    $keywords: String
    $link: String
    $inputText: String
    $debugAcPrompt: String
    $generateMore: Boolean
    $numberOfVariations: Int
    $language: String
    $suggestionAnnotationId: ID
    $landingPageAssetId: ID
    $forceUsingKeywords: Boolean
    $activeSectionIndex: Int
    $sections: [BlogPostBuilderOutlineSectionInput]
    $rephrase: JSON
    $customerPersonaId: ID
    $tovInput: TovInput
  ) {
    suggestVariations(
      projectId: $projectId
      assetType: $assetType
      suggestionMode: $suggestionMode
      keywords: $keywords
      link: $link
      inputText: $inputText
      debugAcPrompt: $debugAcPrompt
      generateMore: $generateMore
      numberOfVariations: $numberOfVariations
      language: $language
      suggestionAnnotationId: $suggestionAnnotationId
      landingPageAssetId: $landingPageAssetId
      forceUsingKeywords: $forceUsingKeywords
      activeSectionIndex: $activeSectionIndex
      sections: $sections
      rephrase: $rephrase
      customerPersonaId: $customerPersonaId
      tovInput: $tovInput
    ) {
      ...SuggestionRequest
    }
  }
  ${FRAGMENT_SUGGEST_REQUEST}
`;

export const suggestVariations = (projectId, assetType, params) => {
  const variables = {
    projectId,
    assetType,
    suggestionMode: params.suggestionMode,
    keywords: params.keywords,
    link: params.link,
    inputText: params.inputText,
    debugAcPrompt: params.debugAcPrompt,
    generateMore: params.generateMore,
    numberOfVariations: params.numberOfVariations,
    language: params.language,
    suggestionAnnotationId: params.guidanceId,
    landingPageAssetId: params.landingPageAssetId,
    forceUsingKeywords: params.forceUsingKeywords,
    rephrase: params.rephrase,
    activeSectionIndex: params.activeSectionIndex,
    sections: params.sections,
    customerPersonaId: params.customerPersona?.id,
    tovInput: params.tovInput,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then((response) => {
    return getSingleProp(response.data);
  });
};
