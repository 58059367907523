import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Customized,
} from 'recharts';

import { AVATAR_TYPE } from 'src/data/persona';
import { COLORS } from 'src/data/ui';
import { CustomizedDataLabels } from './components/CustomizedDataLabels';
import { CustomizedPolarGrid } from './components/CustomizedPolarGrid';
import { prepareChartData } from './utils';
import { useStyles } from './useStyles';

export const AudiencesRadarChart = memo(
  ({
    className,
    audiencesData,
    loading,
    disabled,
    onSavePersona,
    onReplacePersona,
    onDeletePersona,
    onEditPersona,
    onAddPersona,
    brandVoices,
  }) => {
    const styles = useStyles();

    const data = useMemo(() => prepareChartData(audiencesData, loading), [audiencesData, loading]);

    return (
      <ResponsiveContainer
        className={cx(styles.root, className, {
          [styles.loading]: loading,
        })}
      >
        <RadarChart
          data={data}
          cy="58%"
          outerRadius="75%"
        >
          <Customized component={<CustomizedPolarGrid />} />
          <PolarGrid
            gridType="polygon"
            stroke={COLORS.gray.gray80}
            radialLines
          />

          <PolarAngleAxis
            dataKey="id"
            tick={false}
            tickLine={false}
          />
          <PolarRadiusAxis
            angle={90}
            stroke={COLORS.gray.gray80}
            domain={[0, 100]}
            ticks={[0, 50, 75, 100]}
            tick={false}
          />

          <Customized
            component={
              <CustomizedDataLabels
                loading={loading}
                disabled={disabled}
                onSavePersona={onSavePersona}
                onReplacePersona={onReplacePersona}
                onEditPersona={onEditPersona}
                onDeletePersona={onDeletePersona}
                onAddPersona={onAddPersona}
                brandVoices={brandVoices}
              />
            }
          />

          <Radar
            dataKey="score"
            fillOpacity={0.6}
            isAnimationActive={false}
            strokeLinejoin="round"
            connectNulls
          />
        </RadarChart>
      </ResponsiveContainer>
    );
  }
);

AudiencesRadarChart.displayName = 'AudiencesRadarChart';

AudiencesRadarChart.propTypes = {
  className: PropTypes.string,
  audiencesData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      avatarType: PropTypes.oneOf(Object.values(AVATAR_TYPE)),
      score: PropTypes.number,
    })
  ),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onSavePersona: PropTypes.func,
  onReplacePersona: PropTypes.func,
  onDeletePersona: PropTypes.func,
  onAddPersona: PropTypes.func,
  onEditPersona: PropTypes.func,
  brandVoices: PropTypes.arrayOf(PropTypes.shape({})),
};

AudiencesRadarChart.defaultProps = {
  className: null,
  audiencesData: [],
  loading: false,
  disabled: false,
  onSavePersona: null,
  onReplacePersona: null,
  onDeletePersona: null,
  onAddPersona: null,
  onEditPersona: null,
  brandVoices: [],
};
