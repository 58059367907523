import { BRIEF_DEBUG_DATA_VISIBLE } from 'src/lib/featureFlags';

export const prepareACDebugContext = (name, params) => {
  if (!BRIEF_DEBUG_DATA_VISIBLE) {
    return null;
  }

  const title = `"${name}" request [${JSON.stringify(params)}]`;
  return { traceAuthorComplete: true, traceAuthorCompleteTitle: title };
};
