import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const CustomTextField = ({ inputProps, disallowLogRocket, dataTestId, ...restProps }) => {
  const localInputProps = useMemo(
    () => ({
      ...inputProps,
      'data-testid': dataTestId,
      'data-private': disallowLogRocket ? '' : null,
    }),
    [inputProps, disallowLogRocket, dataTestId]
  );

  return (
    <TextField
      inputProps={localInputProps}
      {...restProps}
    />
  );
};

CustomTextField.propTypes = {
  inputProps: PropTypes.shape(),
  disallowLogRocket: PropTypes.bool,
  dataTestId: PropTypes.string,
};
CustomTextField.defaultProps = {
  inputProps: null,
  disallowLogRocket: false,
};

export default React.memo(CustomTextField);
