import gql from 'graphql-tag';

export const FRAGMENT_ANNOTATION = gql`
  fragment Annotation on Annotation {
    mainOfferings
    emotions
    features
    benefits
    ctas
    pains
  }
`;

export const FRAGMENT_TEXT_ANNOTATION = gql`
  fragment TextAnnotation on TextAnnotation {
    name
    score
  }
`;

export const FRAGMENT_TEXT_ANNOTATIONS = gql`
  fragment TextAnnotations on TextAnnotations {
    mainOfferings {
      ...TextAnnotation
    }
    emotions {
      ...TextAnnotation
    }
    features {
      ...TextAnnotation
    }
    benefits {
      ...TextAnnotation
    }
    ctas {
      ...TextAnnotation
    }
    pains {
      ...TextAnnotation
    }
    keywords {
      ...TextAnnotation
    }
  }
  ${FRAGMENT_TEXT_ANNOTATION}
`;
