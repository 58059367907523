import { getHostname } from 'src/lib/url';

const DISPOSABLE_EMAIL_DOMAINS = [
  'temp-mail.org',
  'tempail.com',
  '10minutemail.com',
  '10minutemail.net',
  'yopmail.com',
  'cryptogmail.com',
  'anon.ws',
  'smailpro.com',
  'fakemail.net',
  'getnada.com',
  'fakemail.online',
  'temp-inbox.com',
  'mohmal.com',
  'tempmail.net',
  'tempmail.email',
  'tmailweb.com',
  'fakemailgenerator.com',
  'tempmaili.com',
  '10minemail.com',
  'tempmail.plus',
  'disposablemail.com',
  'gmailnator.com',
  'tempmail.ninja',
  'placebomail10.com',
  'pimmel.top',
  'deref-mail.com',
  'tijdelijke-email.nl',
  'dizymail.com',
  'minuteinbox.com',
  'harakirimail.com',
  'tempemail.in',
  'temp-mail.app',
  'temp-mails.com',
];

export const getDocumentReferrer = () => document.referrer;

const getIsReferrerDisposableEmail = () => {
  const referrer = getDocumentReferrer();
  const hostname = getHostname(referrer);
  const isReferrerDisposableEmail = DISPOSABLE_EMAIL_DOMAINS.includes(hostname);

  return isReferrerDisposableEmail;
};

export const checkIfEmailDisposable = () => {
  const isReferrerDisposableEmail = getIsReferrerDisposableEmail();

  return isReferrerDisposableEmail;
};
