import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 608,
      backgroundColor: theme.palette.common.white,
      margin: 0,
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightMedium,
    },
    dialogContent: {
      padding: theme.spacing(2, 4, 3),
      borderRadius: theme.shape.borderRadius,
      overflow: 'auto',
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
    },
  }),
  { name: 'NoWorkspacesPopup' }
);
