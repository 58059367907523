import { useEffect } from 'react';

import { useUiActions } from 'src/store';

export const useHideZendeskWidget = (hideZendeskWidget) => {
  const uiActions = useUiActions();

  useEffect(() => {
    if (!hideZendeskWidget) {
      return;
    }

    uiActions.hideZendeskWidget();

    return () => {
      uiActions.displayZendeskWidget();
    };
  }, [uiActions, hideZendeskWidget]);
};
