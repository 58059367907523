import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { DialogContent } from '@material-ui/core';

import { CustomDialog } from 'src/components/CustomDialog';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { CUSTOM_BUTTON_COLOR } from 'src/components/CustomButton';
import { MODE } from './const';
import { useStyles } from './useStyles';

const ConfirmPopup = ({
  className,
  open,
  mode,
  title,
  content,
  confirm,
  cancel,
  onConfirm,
  onCancel,
  classes,
}) => {
  const styles = useStyles({ classes });

  // HINT: we don't use 'onKeyDown' cause it clashes with handler in `Modal`
  const handleKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        onConfirm();
      }
    },
    [onConfirm]
  );

  const handleClose = useCallback(
    (event, reason) => {
      if (reason === 'backdropClick') {
        return;
      }
      onCancel();
    },
    [onCancel]
  );

  return (
    <CustomDialog
      className={cx(styles.paper, className)}
      submitProps={{
        color: mode === MODE.warning ? CUSTOM_BUTTON_COLOR.danger : CUSTOM_BUTTON_COLOR.primary,
      }}
      open={open}
      onClose={handleClose}
      onKeyUp={handleKeyUp}
      data-testid="ConfirmPopup"
      cancelLabel={cancel || 'No'}
      submitLabel={confirm || 'Yes'}
      title={title}
      onCancel={onCancel}
      onSubmit={onConfirm}
    >
      {content && (
        <DialogContent className={styles.dialogContent}>
          <CustomTypography
            className={styles.content}
            variant={TYPOGRAPHY_VARIANT.paragraph16}
          >
            {content}
          </CustomTypography>
        </DialogContent>
      )}
    </CustomDialog>
  );
};

ConfirmPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(Object.values(MODE)),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  confirm: PropTypes.string,
  cancel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
};

ConfirmPopup.defaultProps = {
  className: null,
  mode: MODE.confirmation,
  content: null,
  confirm: null,
  cancel: null,
  classes: null,
};

export default ConfirmPopup;
