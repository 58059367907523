import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const ArrowRight = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        d="M3.125 10H16.875"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 4.375L16.875 10L11.25 15.625"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
