import { useContext } from 'react';

import { useOnUnmount } from 'src/lib/hooks';
import * as log from 'src/lib/log';
import { PopupContext } from '../context';

export const usePopups = () => {
  const popupService = useContext(PopupContext);

  useOnUnmount(() => {
    if (!popupService) {
      log.warning('usePopups is not wrapped by PopupContext');
    }
  });

  return popupService;
};
