import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const Loader = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.indicator}>
        <Skeleton
          className={styles.icon}
          variant="rect"
          animation="wave"
        />
        <Skeleton
          className={styles.text}
          variant="text"
          animation="wave"
        />
      </div>

      <div className={styles.indicator}>
        <Skeleton
          className={styles.icon}
          variant="rect"
          animation="wave"
        />
        <Skeleton
          className={styles.text}
          variant="text"
          animation="wave"
        />
      </div>
    </div>
  );
};

Loader.propTypes = {
  classes: PropTypes.shape(),
};

Loader.defaultProps = {
  classes: null,
};
