import { useCallback } from 'react';
import { resourceChannelToContentType } from 'src/data/performance';

import { usePopups, CREATE_TALKING_POINTS_VIEW_POPUP } from 'src/services/popup';

export const useCreateTalkingPointsViewPopup = () => {
  const popups = usePopups();

  return useCallback(
    (channel) => {
      return new Promise((resolve) => {
        popups.openPopup(CREATE_TALKING_POINTS_VIEW_POPUP, {
          onClose: (result) => {
            popups.closePopup(CREATE_TALKING_POINTS_VIEW_POPUP);
            resolve(result);
          },
          contentType: resourceChannelToContentType(channel),
        });
      });
    },
    [popups]
  );
};
