import { useEffect } from 'react';

import {
  PerformanceStore,
  useBrandVoicesAssets,
  useCurrentCustomer,
  useCurrentUser,
} from 'src/store';
import { getCustomerPlan } from 'src/data/subscription';
import { createStateSelectorHook } from 'src/lib/sweetState';
import { useChannelAccounts } from './useChannelAccounts';
import { flatConnectedAccounts } from 'src/data/performance';

const usePerformanceState = createStateSelectorHook(PerformanceStore, ['accountsFetched']);

export const useStonlyWidgetUserIdentification = () => {
  const customer = useCurrentCustomer();
  const user = useCurrentUser();
  const userId = user?.id;
  const customerId = customer?.id;
  const plan = getCustomerPlan(customer);
  const { accountsFetched } = usePerformanceState();
  const { connectedAccounts } = useChannelAccounts();
  const { targetAudiences, loadingBrandVoiceAssets } = useBrandVoicesAssets();

  useEffect(() => {
    const flattenConnectedAccounts = flatConnectedAccounts(connectedAccounts);
    if (customerId && userId && plan && accountsFetched && !loadingBrandVoiceAssets) {
      window.StonlyWidget?.('identify', `${customerId}_${userId}_${plan}`, {
        plan,
        integrations_count: flattenConnectedAccounts.length,
        target_audiences_count: targetAudiences.length,
      });
    }
  }, [
    customerId,
    userId,
    plan,
    connectedAccounts,
    accountsFetched,
    targetAudiences,
    loadingBrandVoiceAssets,
  ]);
};
