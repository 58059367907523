import gql from 'graphql-tag';

import { unwrapEdges } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_TONE_OF_VOICE } from 'src/graphql/toneOfVoice/schema';
import { FRAGMENT_BRAND_BRIEF } from 'src/graphql/brief/schema';
import { FRAGMENT_CUSTOM_FORMULA } from 'src/graphql/formula/schema';
import { FRAGMENT_BRAND_TERM } from 'src/graphql/brandTerms/schema';
import { CUSTOMER_PERSONA_FRAGMENT } from 'src/graphql/customerPersona';
import { CUSTOM_DATASET_FRAGMENT } from 'src/graphql/customModel/schema';

const QUERY = gql`
  query BrandVoicesAssets($customerId: ID!, $id: ID) {
    workspaces(customerId: $customerId, id: $id) {
      edges {
        node {
          associatedBrandVoices {
            id
            name
            brandTerms {
              ...BrandTerm
            }
            brandBriefs {
              ...BrandBrief
            }
            targetAudiences {
              ...CustomerPersona
            }
            toneOfVoices {
              ...CustomToneOfVoice
            }
            customFormulas {
              ...CustomFormula
            }
            customModels {
              ...CustomDataset
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_BRAND_TERM}
  ${FRAGMENT_BRAND_BRIEF}
  ${CUSTOMER_PERSONA_FRAGMENT}
  ${FRAGMENT_CUSTOM_TONE_OF_VOICE}
  ${FRAGMENT_CUSTOM_FORMULA}
  ${CUSTOM_DATASET_FRAGMENT}
`;

/**
 * Query brand voices assets for the current customer and workspace
 * @param {string} customerId - ID of the customer
 * @param {string} workspaceId - ID of the workspace
 * @returns {Promise<object[]>} - array of brand voices with their assets
 */
export const queryBrandVoicesAssets = async (customerId, workspaceId) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: { customerId, id: workspaceId },
  });
  const { workspaces } = result.data;
  const nodes = unwrapEdges(workspaces?.edges);
  return nodes?.[0]?.associatedBrandVoices;
};
