import { useEffect } from 'react';

import { useCustomFormulasActions } from 'src/store';
import { useErrorHandler } from 'src/services';

export const usePreloadCustomFormulas = (brandVoice) => {
  const handleError = useErrorHandler();
  const customFormulasActions = useCustomFormulasActions();

  useEffect(() => {
    const fetchFormulas = async () => {
      try {
        await customFormulasActions.queryCustomFormulas(brandVoice.id);
      } catch (error) {
        handleError(error);
      }
    };

    if (brandVoice?.id) {
      fetchFormulas();
    }
  }, [brandVoice, handleError, customFormulasActions]);
};
