import { pluralize } from 'src/utils/format';

import { useCustomerMembers } from './useCustomerMembers';
import { useCurrentCustomer } from '../customer/useCurrentCustomer';

const UNLIMITED_MEMBERS_VALUE = -1;

export const useCustomerMaxMembers = () => {
  const currentCustomer = useCurrentCustomer();
  const { members } = useCustomerMembers();
  const usersCount = members?.length;
  const { maxMembers } = currentCustomer || {};
  const isUsersUnlimited = maxMembers === UNLIMITED_MEMBERS_VALUE;
  const spendAllInvites = !isUsersUnlimited && usersCount >= maxMembers;
  const usersLabel = pluralize(maxMembers, 'user', 'users');
  const textInvites = isUsersUnlimited
    ? `${usersCount} ${usersLabel}`
    : `${usersCount}/${maxMembers} ${usersLabel}`;

  return {
    isUsersUnlimited,
    spendAllInvites,
    usersCount,
    maxMembers,
    textInvites,
  };
};
