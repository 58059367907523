import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 310,
      padding: theme.spacing(3, 2, 2, 2),
      gap: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      border: theme.shape.border,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 0px 23px -3px rgba(23, 43, 77, 0.21)',
    },

    title: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(19),
      color: COLORS.neutral.secondary,
    },

    closeButton: {
      position: 'absolute',
      padding: 0,
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
    },

    closeIcon: {
      width: 8,
      height: 8,

      '& path': {
        fill: COLORS.neutral.darkGray,
      },
    },

    showToggle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.neutral.secondary,

      '& span': {
        margin: theme.spacing(-0.12),
      },
    },

    arrow: {
      width: 0,
      height: 0,
      borderLeft: '16px solid transparent',
      borderRight: '16px solid transparent',
      borderTop: '16px solid white',
      position: 'absolute',
      bottom: theme.spacing(-2),
      left: theme.spacing(4.5),
    },
  }),
  { name: 'HiddenAudiencesHint' }
);
