import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';

import { formatNumber } from 'src/utils/format';
import { getNextBillingPeriodStart, getSubscriptionTierSeatsLimit } from 'src/data/subscription';
import { formatDateMed } from 'src/lib/format';
import IconClose from 'src/assets/icons/Close';
import { useCurrentCustomer } from 'src/store';
import useStyles from './useStyles';

export const DowngradeTierConfirmationPopup = ({
  open,
  onClose,
  plan,
  tier,
  onConfirm,
  isFromMonthlyToAnnually,
}) => {
  const styles = useStyles();
  const customer = useCurrentCustomer();

  const nextPeriodStart = getNextBillingPeriodStart(customer);
  const periodStart = nextPeriodStart ? formatDateMed(nextPeriodStart) : ' - ';
  const newSeatsLimit = getSubscriptionTierSeatsLimit(tier, plan);

  return (
    <Dialog
      open={open}
      classes={{ paper: styles.paper }}
    >
      <DialogTitle
        className={styles.dialogTitle}
        disableTypography
      >
        <Typography className={styles.textTitle}>Downgrade tier?</Typography>
        <IconButton
          className={styles.buttonClose}
          onClick={onClose}
        >
          <IconClose />
        </IconButton>
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        <Typography className={styles.textContent}>
          {isFromMonthlyToAnnually ? (
            <>Are you sure? You will have only {formatNumber(newSeatsLimit)} seats.</>
          ) : (
            <>
              Are you sure? Starting {periodStart}
              <br />
              you will have only {formatNumber(newSeatsLimit)} seats.
            </>
          )}
        </Typography>
      </DialogContent>

      <DialogActions className={styles.dialogActions}>
        <Button
          className={styles.buttonCancel}
          onClick={onClose}
          variant="text"
          color="default"
        >
          Cancel
        </Button>

        <Button
          className={styles.buttonConfirm}
          onClick={onConfirm}
          variant="outlined"
        >
          Downgrade Plan
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DowngradeTierConfirmationPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  plan: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isFromMonthlyToAnnually: PropTypes.bool.isRequired,
};
DowngradeTierConfirmationPopup.defaultProps = {
  open: false,
};
