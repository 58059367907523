import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/data/ui';
import { EDITOR_TOOLBAR_GROUP_SPACING } from '../../const';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'stretch',
    },
    group: {
      flexShrink: 0,

      '& + &': {
        position: 'relative',
        marginLeft: EDITOR_TOOLBAR_GROUP_SPACING,

        '&:before': {
          content: '""',
          position: 'absolute',
          top: 6,
          bottom: 6,
          left: `-${EDITOR_TOOLBAR_GROUP_SPACING / 2}px`,
          borderLeft: theme.shape.borderGray60,
        },
      },
    },
    toolbarButton: {
      '&:hover': {
        backgroundColor: COLORS.primary.silver,
      },
    },
  }),
  { name: 'FixedToolbar' }
);
