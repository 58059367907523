import gql from 'graphql-tag';

import { getSingleProp, throwIfResultHasErrors } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_BRAND_BRIEF } from './schema';

const QUERY = gql`
  query BrandBriefs($brandVoiceId: ID!) {
    brandBriefs(brandVoiceId: $brandVoiceId) {
      ...BrandBrief
    }
  }
  ${FRAGMENT_BRAND_BRIEF}
`;

export const queryBrandBriefs = async (brandVoiceId) => {
  const variables = { brandVoiceId };

  const result = await executeGqlQuery({
    query: QUERY,
    variables,
  });
  throwIfResultHasErrors(result);

  return getSingleProp(result.data);
};
