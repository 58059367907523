import { useCallback } from 'react';

import { usePopups } from '../services';
import { RENAME_CUSTOMER_POPUP } from '../types';
import { useInviteUsersPopup } from './useInviteUsersPopup';

export const useRenameCustomerPopup = () => {
  const popups = usePopups();
  const showInviteUsersPopup = useInviteUsersPopup();

  return useCallback(() => {
    popups.openPopup(RENAME_CUSTOMER_POPUP, {
      onSubmit: () => {
        popups.closePopup(RENAME_CUSTOMER_POPUP);
        showInviteUsersPopup();
      },
      onClose: () => {
        popups.closePopup(RENAME_CUSTOMER_POPUP);
        showInviteUsersPopup();
      },
    });
  }, [popups, showInviteUsersPopup]);
};
