import React from 'react';
import cx from 'classnames';

import { IconFolderOpenedOutlined } from 'src/assets/icons/IconFolderOpenedOutlined';
import { SidebarLink } from '../../components/SidebarLink';
import { createRootProjectFolderSidebarItem } from '../../lib';
import { useActiveLocationPath, useProjectFolderItems } from '../../service';
import { useHandlers } from './useHandlers';
import { useViewState } from './useViewState';
import { useStyles } from './useStyles';

const ROOT_FOLDER_ITEM = createRootProjectFolderSidebarItem();

export const FolderItems = ({ className }) => {
  const styles = useStyles();

  const activePath = useActiveLocationPath();

  const { projectFolders } = useViewState();
  const { handleLinkClick } = useHandlers();

  const items = useProjectFolderItems(projectFolders);

  return (
    <div className={cx(styles.root, className)}>
      <SidebarLink
        className={styles.item}
        classes={{ iconStart: styles.item_iconStart }}
        item={ROOT_FOLDER_ITEM}
        active={ROOT_FOLDER_ITEM.path === activePath}
        onClick={handleLinkClick}
      />

      {items.map((item) => (
        <SidebarLink
          className={styles.item}
          classes={{ iconStart: styles.item_iconStart }}
          key={item.id}
          item={item}
          active={item.path === activePath}
          onClick={handleLinkClick}
          activeIcon={IconFolderOpenedOutlined}
        />
      ))}
    </div>
  );
};
