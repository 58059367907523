import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PowerfulBullets = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
    <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
    <rect
      x="11.7266"
      y="7.81543"
      width="11.7996"
      height="1.81532"
      fill="white"
    />
    <path
      d="M7.68316 7.16176C7.82017 6.7744 8.36798 6.7744 8.50499 7.16176C8.58488 7.38763 8.83209 7.50667 9.05848 7.42831C9.44676 7.29391 9.78832 7.72221 9.57089 8.07085C9.44411 8.27413 9.50517 8.54162 9.70759 8.66977C10.0548 8.88954 9.93285 9.42362 9.52471 9.471C9.28674 9.49863 9.11567 9.71314 9.14169 9.9513C9.18632 10.3597 8.69275 10.5974 8.40124 10.3079C8.23126 10.139 7.95689 10.139 7.78691 10.3079C7.4954 10.5974 7.00184 10.3597 7.04646 9.9513C7.07248 9.71314 6.90141 9.49863 6.66344 9.471C6.2553 9.42362 6.1334 8.88954 6.48056 8.66977C6.68298 8.54162 6.74404 8.27413 6.61726 8.07085C6.39983 7.72221 6.74139 7.29391 7.12967 7.42831C7.35606 7.50667 7.60327 7.38763 7.68316 7.16176Z"
      fill="white"
    />
    <rect
      x="11.7266"
      y="14.1689"
      width="11.7996"
      height="1.81532"
      fill="white"
    />
    <path
      d="M7.68316 13.5153C7.82017 13.1279 8.36798 13.1279 8.50499 13.5153C8.58488 13.7411 8.83209 13.8602 9.05848 13.7818C9.44676 13.6474 9.78832 14.0757 9.57089 14.4244C9.44411 14.6276 9.50517 14.8951 9.70759 15.0233C10.0548 15.2431 9.93285 15.7771 9.52471 15.8245C9.28674 15.8521 9.11567 16.0667 9.14169 16.3048C9.18632 16.7133 8.69275 16.951 8.40124 16.6614C8.23126 16.4926 7.95689 16.4926 7.78691 16.6614C7.4954 16.951 7.00184 16.7133 7.04646 16.3048C7.07248 16.0667 6.90141 15.8521 6.66344 15.8245C6.2553 15.7771 6.1334 15.2431 6.48056 15.0233C6.68298 14.8951 6.74404 14.6276 6.61726 14.4244C6.39983 14.0757 6.74139 13.6474 7.12967 13.7818C7.35606 13.8602 7.60327 13.7411 7.68316 13.5153Z"
      fill="white"
    />
    <rect
      x="11.7266"
      y="20.5225"
      width="11.7996"
      height="1.81532"
      fill="white"
    />
    <path
      d="M7.68316 19.8688C7.82017 19.4814 8.36798 19.4814 8.50499 19.8688C8.58488 20.0947 8.83209 20.2137 9.05848 20.1353C9.44676 20.0009 9.78832 20.4292 9.57089 20.7779C9.44411 20.9812 9.50517 21.2487 9.70759 21.3768C10.0548 21.5966 9.93285 22.1307 9.52471 22.178C9.28674 22.2057 9.11567 22.4202 9.14169 22.6583C9.18632 23.0668 8.69275 23.3045 8.40124 23.0149C8.23126 22.8461 7.95689 22.8461 7.78691 23.0149C7.4954 23.3045 7.00184 23.0668 7.04646 22.6583C7.07248 22.4202 6.90141 22.2057 6.66344 22.178C6.2553 22.1307 6.1334 21.5966 6.48056 21.3768C6.68298 21.2487 6.74404 20.9812 6.61726 20.7779C6.39983 20.4292 6.74139 20.0009 7.12967 20.1353C7.35606 20.2137 7.60327 20.0947 7.68316 19.8688Z"
      fill="white"
    />
  </SvgIcon>
));

export default React.memo(PowerfulBullets);
