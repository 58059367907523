import { useEffect } from 'react';

import { messageDisplayFullscreen } from 'src/data/infinity';
import { createStateSelectorHook } from 'src/lib/sweetState';
import { sendMessageToParent } from 'src/lib/messages';
import { UiStore } from 'src/store';

/**
 * Notify Extension that Platform displays fullscreen popup
 */
const useUiState = createStateSelectorHook(UiStore, [
  'globalPopupVisibleCount',
  'localPopupVisibleCount',
]);
export const useReactToFullscreenPopups = () => {
  const { globalPopupVisibleCount, localPopupVisibleCount } = useUiState();

  useEffect(() => {
    const fullscreenPopupVisible = globalPopupVisibleCount > 0 || localPopupVisibleCount > 0;
    const message = messageDisplayFullscreen(fullscreenPopupVisible);
    sendMessageToParent(message);
  }, [globalPopupVisibleCount, localPopupVisibleCount]);
};
