import { useCallback } from 'react';

import { CUSTOM_MODEL_POPUP } from '../types';
import { usePopups } from '../services';

export const useCustomModelPopup = (generationTool) => {
  const popups = usePopups();

  return useCallback(() => {
    return new Promise((resolve) => {
      popups.openPopup(CUSTOM_MODEL_POPUP, {
        onClose: () => {
          popups.closePopup(CUSTOM_MODEL_POPUP);
          resolve(false);
        },
        onSubmit: async () => {
          popups.closePopup(CUSTOM_MODEL_POPUP);
          resolve(true);
        },
        generationTool,
      });
    });
  }, [popups, generationTool]);
};
