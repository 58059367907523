import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tab, Tabs } from '@material-ui/core';

import {
  AUDIENCE_TARGET_TYPE,
  getAudienceTargetLabel,
} from 'src/data/audiencesTargeting';
import useStyles from './useStyles';

const AVAILABLE_TYPES = [
  AUDIENCE_TARGET_TYPE.REFERRER_SOURCE,
  AUDIENCE_TARGET_TYPE.UTM,
];
const DEFAULT_TYPE = AUDIENCE_TARGET_TYPE.REFERRER_SOURCE;

export const TargetTypeTabs = ({ className, value, onChange, disabled }) => {
  const styles = useStyles();

  const handleTypeChange = useCallback(
    (event, newType) => {
      onChange(newType);
    },
    [onChange]
  );

  return (
    <Tabs
      className={cx(styles.root, className)}
      classes={{ indicator: styles.indicator }}
      value={value || DEFAULT_TYPE}
      onChange={handleTypeChange}
      disabled={disabled}
      indicatorColor="primary"
    >
      {AVAILABLE_TYPES.map((type) => (
        <Tab
          className={styles.tab}
          classes={{
            selected: styles.selected,
          }}
          key={type}
          label={getAudienceTargetLabel(type)}
          value={type}
        />
      ))}
    </Tabs>
  );
};

TargetTypeTabs.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOf(Object.values(AUDIENCE_TARGET_TYPE)),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TargetTypeTabs.defaultProps = {
  className: null,
  value: null,
  disabled: false,
};
