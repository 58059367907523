import { makeStyles } from '@material-ui/core/styles';

import { CONTENT_SENSITIVITY } from 'src/data/generation';
import { COLORS } from 'src/data/ui';

const BG_COLOR_UNSAFE = '#DB0E00';
const BG_COLOR_SENSITIVE = '#FFC04E';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      marginTop: theme.spacing(2),
    },
    boxGroupTitle: {},

    content: {
      gap: 0,
    },

    badge: {
      marginBottom: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(0.75),

      [`&[data-sensitivity="${CONTENT_SENSITIVITY.unsafe}"]`]: {
        color: BG_COLOR_UNSAFE,
      },
      [`&[data-sensitivity="${CONTENT_SENSITIVITY.sensitive}"]`]: {
        color: BG_COLOR_SENSITIVE,
      },
      [`&[data-sensitivity="${CONTENT_SENSITIVITY.safe}"]`]: {
        color: COLORS.success.active,
      },
    },
    information: {
      fontSize: theme.typography.pxToRem(12),
    },
    description: {
      color: COLORS.gray.gray30,
    },
  }),
  { name: 'ChannelPolicy' }
);
