import { formatNumber } from 'src/utils/format';
import {
  DEFAULT_SUBSCRIPTION_STARTER_TIER,
  SUBSCRIPTION_PRO_TIER,
  SUBSCRIPTION_TIER,
  SUBSCRIPTION_PLAN,
} from './const';
import { FEATURE_LIMITS } from '../featureLimits';

export const clampSubscriptionTier = (tier, defaultValue = DEFAULT_SUBSCRIPTION_STARTER_TIER) => {
  const values = Object.values(SUBSCRIPTION_TIER);
  if (values.includes(tier)) {
    return tier;
  }
  return defaultValue;
};

const TIER_PROPS = {
  /* "tiered" plan tiers */
  [SUBSCRIPTION_TIER.tier_migrate]: {
    price: 0,
    wordsLimit: 6 * 1000,
  },
  [SUBSCRIPTION_TIER.tier_0]: {
    price: 0,
    wordsLimit: 5 * 1000,
  },
  [SUBSCRIPTION_TIER.tier_19]: {
    price: 19,
    wordsLimit: 15 * 1000,
    availableToUpgrade: true,
  },
  [SUBSCRIPTION_TIER.tier_39]: {
    price: 39,
    wordsLimit: 35 * 1000,
    availableToUpgrade: true,
  },
  [SUBSCRIPTION_TIER.tier_99]: {
    price: 99,
    wordsLimit: 100 * 1000,
    availableToUpgrade: true,
  },
  [SUBSCRIPTION_TIER.tier_149]: {
    price: 149,
    wordsLimit: 165 * 1000,
    availableToUpgrade: true,
  },
  [SUBSCRIPTION_TIER.tier_199]: {
    price: 199,
    wordsLimit: 240 * 1000,
    availableToUpgrade: true,
  },

  /* "pro" plans tiers */
  [SUBSCRIPTION_TIER.pro_beta_agency_tier_2]: {
    price: 750,
    priceAnnuallyPerMonth: 500,
    priceAnnually: 6000,
  },
  [SUBSCRIPTION_TIER.pro_beta_ecommerce_tier_1]: {
    price: 399,
    priceAnnuallyPerMonth: 266,
    priceAnnually: 3192,
    views: 1000000,
  },
  [SUBSCRIPTION_TIER.pro_beta_ecommerce_tier_2]: {
    price: 750,
    priceAnnuallyPerMonth: 500,
    priceAnnually: 6000,
    views: 5000000,
  },
  [SUBSCRIPTION_TIER.pro_beta_publisher_tier_1]: {
    price: 499,
    priceAnnuallyPerMonth: 333,
    priceAnnually: 3992,
  },
  [SUBSCRIPTION_TIER.pro_beta_publisher_tier_2]: {
    price: 750,
    priceAnnuallyPerMonth: 500,
    priceAnnually: 6000,
  },

  /* DEPRECATED "basic_tiered" plan tiers */
  [SUBSCRIPTION_TIER.basic_tier_15k]: {
    price: 19,
    priceAnnually: 190,
    priceAnnuallyPerMonth: 16,
    wordsLimit: 15 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
  },
  [SUBSCRIPTION_TIER.basic_tier_30k]: {
    price: 29,
    priceAnnually: 290,
    priceAnnuallyPerMonth: 25,
    wordsLimit: 30 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
  },
  [SUBSCRIPTION_TIER.basic_tier_50k]: {
    price: 39,
    priceAnnually: 390,
    priceAnnuallyPerMonth: 33,
    wordsLimit: 50 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
  },

  /* "basic_tiered" plan tiers */
  [SUBSCRIPTION_TIER.basic_tier_20k]: {
    price: 29,
    priceAnnually: 290,
    priceAnnuallyPerMonth: 24,
    wordsLimit: 20 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.basicTiered,
  },
  [SUBSCRIPTION_TIER.basic_tier_35k]: {
    price: 49,
    priceAnnually: 490,
    priceAnnuallyPerMonth: 41,
    wordsLimit: 35 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.basicTiered,
  },
  [SUBSCRIPTION_TIER.basic_tier_65k]: {
    price: 59,
    priceAnnually: 590,
    priceAnnuallyPerMonth: 49,
    wordsLimit: 65 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.basicTiered,
  },
  [SUBSCRIPTION_TIER.basic_tier_100k]: {
    price: 79,
    priceAnnually: 790,
    priceAnnuallyPerMonth: 66,
    wordsLimit: 100 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.basicTiered,
  },
  [SUBSCRIPTION_TIER.basic_tier_200k]: {
    price: 149,
    priceAnnually: 1490,
    priceAnnuallyPerMonth: 124,
    wordsLimit: 200 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.basicTiered,
  },
  [SUBSCRIPTION_TIER.basic_tier_300k]: {
    price: 199,
    priceAnnually: 1990,
    priceAnnuallyPerMonth: 166,
    wordsLimit: 300 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.basicTiered,
  },

  /* "data_driven" plan tiers */
  [SUBSCRIPTION_TIER.data_driven_30k]: {
    price: 99,
    priceAnnually: 990,
    priceAnnuallyPerMonth: 83,
    wordsLimit: 30 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.dataDriven,
  },
  [SUBSCRIPTION_TIER.data_driven_60k]: {
    price: 149,
    priceAnnually: 1490,
    priceAnnuallyPerMonth: 124,
    wordsLimit: 60 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.dataDriven,
  },
  [SUBSCRIPTION_TIER.data_driven_100k]: {
    price: 179,
    priceAnnually: 1790,
    priceAnnuallyPerMonth: 149,
    wordsLimit: 100 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.dataDriven,
  },
  [SUBSCRIPTION_TIER.data_driven_200k]: {
    price: 249,
    priceAnnually: 2490,
    priceAnnuallyPerMonth: 208,
    wordsLimit: 200 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.dataDriven,
  },
  [SUBSCRIPTION_TIER.data_driven_300k]: {
    price: 299,
    priceAnnually: 2990,
    priceAnnuallyPerMonth: 249,
    wordsLimit: 300 * 1000,
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.dataDriven,
  },
  [SUBSCRIPTION_TIER.pro_beta_agency_tier_1]: {
    price: 399,
    priceAnnuallyPerMonth: 333,
    priceAnnually: 3990,
    wordsLimit: 'Unlimited',
    availableToUpgrade: true,
    percentOfAnnualDiscount: 17,
    plan: SUBSCRIPTION_PLAN.proAgency,
  },
  [SUBSCRIPTION_TIER.seats_3]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 499,
      priceAnnuallyPerMonth: 4188 / 12,
      priceAnnually: 4188,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 3,
    },
    [SUBSCRIPTION_PLAN.dd_teams]: {
      price: 99,
      priceAnnuallyPerMonth: 948 / 12,
      priceAnnually: 948,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 20,
      plan: SUBSCRIPTION_PLAN.dd_teams,
      seats: 3,
    },
  },
  [SUBSCRIPTION_TIER.seats_4]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 558,
      priceAnnuallyPerMonth: 4776 / 12,
      priceAnnually: 4776,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 4,
    },
    [SUBSCRIPTION_PLAN.dd_teams]: {
      price: 158,
      priceAnnuallyPerMonth: 1536 / 12,
      priceAnnually: 1536,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 20,
      plan: SUBSCRIPTION_PLAN.dd_teams,
      seats: 4,
    },
  },
  [SUBSCRIPTION_TIER.seats_5]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 617,
      priceAnnuallyPerMonth: 5364 / 12,
      priceAnnually: 5364,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 5,
    },
    [SUBSCRIPTION_PLAN.dd_teams]: {
      price: 217,
      priceAnnuallyPerMonth: 2124 / 12,
      priceAnnually: 2124,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 20,
      plan: SUBSCRIPTION_PLAN.dd_teams,
      seats: 5,
    },
  },
  [SUBSCRIPTION_TIER.seats_6]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 676,
      priceAnnuallyPerMonth: 5952 / 12,
      priceAnnually: 5952,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 6,
    },
    [SUBSCRIPTION_PLAN.dd_teams]: {
      price: 276,
      priceAnnuallyPerMonth: 2712 / 12,
      priceAnnually: 2712,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 20,
      plan: SUBSCRIPTION_PLAN.dd_teams,
      seats: 6,
    },
  },
  [SUBSCRIPTION_TIER.seats_7]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 735,
      priceAnnuallyPerMonth: 6540 / 12,
      priceAnnually: 6540,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 7,
    },
    [SUBSCRIPTION_PLAN.dd_teams]: {
      price: 335,
      priceAnnuallyPerMonth: 3300 / 12,
      priceAnnually: 3300,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 20,
      plan: SUBSCRIPTION_PLAN.dd_teams,
      seats: 7,
    },
  },
  [SUBSCRIPTION_TIER.seats_8]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 794,
      priceAnnuallyPerMonth: 7128 / 12,
      priceAnnually: 7128,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 8,
    },
    [SUBSCRIPTION_PLAN.dd_teams]: {
      price: 394,
      priceAnnuallyPerMonth: 3888 / 12,
      priceAnnually: 3888,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 20,
      plan: SUBSCRIPTION_PLAN.dd_teams,
      seats: 8,
    },
  },
  [SUBSCRIPTION_TIER.seats_9]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 853,
      priceAnnuallyPerMonth: 7716 / 12,
      priceAnnually: 7716,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 9,
    },
    [SUBSCRIPTION_PLAN.dd_teams]: {
      price: 453,
      priceAnnuallyPerMonth: 4476 / 12,
      priceAnnually: 4476,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 20,
      plan: SUBSCRIPTION_PLAN.dd_teams,
      seats: 9,
    },
  },
  [SUBSCRIPTION_TIER.seats_10]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 912,
      priceAnnuallyPerMonth: 8304 / 12,
      priceAnnually: 8304,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 10,
    },
    [SUBSCRIPTION_PLAN.dd_teams]: {
      price: 512,
      priceAnnuallyPerMonth: 5064 / 12,
      priceAnnually: 5064,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 20,
      plan: SUBSCRIPTION_PLAN.dd_teams,
      seats: 10,
    },
  },
  [SUBSCRIPTION_TIER.seats_11]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 971,
      priceAnnuallyPerMonth: 8892 / 12,
      priceAnnually: 8892,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 11,
    },
  },
  [SUBSCRIPTION_TIER.seats_12]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 1030,
      priceAnnuallyPerMonth: 9480 / 12,
      priceAnnually: 9480,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 12,
    },
  },
  [SUBSCRIPTION_TIER.seats_13]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 1089,
      priceAnnuallyPerMonth: 10068 / 12,
      priceAnnually: 10068,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 13,
    },
  },
  [SUBSCRIPTION_TIER.seats_14]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 1148,
      priceAnnuallyPerMonth: 10656 / 12,
      priceAnnually: 10656,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 14,
    },
  },
  [SUBSCRIPTION_TIER.seats_15]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 1207,
      priceAnnuallyPerMonth: 11244 / 12,
      priceAnnually: 11244,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 15,
    },
  },
  [SUBSCRIPTION_TIER.seats_16]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 1266,
      priceAnnuallyPerMonth: 11832 / 12,
      priceAnnually: 11832,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 16,
    },
  },
  [SUBSCRIPTION_TIER.seats_17]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 1325,
      priceAnnuallyPerMonth: 12420 / 12,
      priceAnnually: 12420,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 17,
    },
  },
  [SUBSCRIPTION_TIER.seats_18]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 1384,
      priceAnnuallyPerMonth: 13008 / 12,
      priceAnnually: 13008,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 18,
    },
  },
  [SUBSCRIPTION_TIER.seats_19]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 1443,
      priceAnnuallyPerMonth: 13596 / 12,
      priceAnnually: 13596,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 19,
    },
  },
  [SUBSCRIPTION_TIER.seats_20]: {
    byPlan: true,
    [SUBSCRIPTION_PLAN.business_self_serve]: {
      price: 1502,
      priceAnnuallyPerMonth: 14184 / 12,
      priceAnnually: 14184,
      wordsLimit: 'Unlimited',
      availableToUpgrade: true,
      percentOfAnnualDiscount: 30,
      plan: SUBSCRIPTION_PLAN.business_self_serve,
      seats: 20,
    },
  },
};

const BASE_WORKSPACE_COUNT = {
  [SUBSCRIPTION_PLAN.dd_teams]: 5,
  [SUBSCRIPTION_PLAN.business_self_serve]: 10,
};

const SEAT_TIER_HIERARCHY = [
  SUBSCRIPTION_TIER.seats_3,
  SUBSCRIPTION_TIER.seats_4,
  SUBSCRIPTION_TIER.seats_5,
  SUBSCRIPTION_TIER.seats_6,
  SUBSCRIPTION_TIER.seats_7,
  SUBSCRIPTION_TIER.seats_8,
  SUBSCRIPTION_TIER.seats_9,
  SUBSCRIPTION_TIER.seats_10,
  SUBSCRIPTION_TIER.seats_11,
  SUBSCRIPTION_TIER.seats_12,
  SUBSCRIPTION_TIER.seats_13,
  SUBSCRIPTION_TIER.seats_14,
  SUBSCRIPTION_TIER.seats_15,
  SUBSCRIPTION_TIER.seats_16,
  SUBSCRIPTION_TIER.seats_17,
  SUBSCRIPTION_TIER.seats_18,
  SUBSCRIPTION_TIER.seats_19,
  SUBSCRIPTION_TIER.seats_20,
];

export const MAX_SEAT_TIER = {
  [SUBSCRIPTION_PLAN.dd_teams]: SUBSCRIPTION_TIER.seats_10,
  [SUBSCRIPTION_PLAN.business_self_serve]: SUBSCRIPTION_TIER.seats_20,
};
export const MIN_SEAT_TIER = SUBSCRIPTION_TIER.seats_3;

// Check only prices with "annual" discount - they're always lower then monthly ones
export const MIN_PRO_TIER_PRICE = Object.values(SUBSCRIPTION_PRO_TIER)
  .map((tier) => TIER_PROPS[tier].priceAnnuallyPerMonth)
  .reduce((acc, price) => (price && price < acc ? price : acc), Number.MAX_VALUE);

export const MIN_ECOMMERCE_TIER_PRICE = Object.values(SUBSCRIPTION_PRO_TIER)
  .filter((tier) => tier.includes('ecommerce'))
  .map((tier) => TIER_PROPS[tier].price)
  .reduce((acc, price) => (price && price < acc ? price : acc), Number.MAX_VALUE);

export const getNextSeatTier = (tier, plan) => {
  const tierIndex = SEAT_TIER_HIERARCHY.indexOf(tier);
  if (tierIndex !== -1 && tier !== MAX_SEAT_TIER[plan]) {
    return SEAT_TIER_HIERARCHY[tierIndex + 1];
  }
};

export const getPlanAndTierForNextLimit = (plan, tier = null, feature) => {
  const isBoostPerformanceLimit = feature === FEATURE_LIMITS.boostPerformancesPerCustomer;
  const isWorkspaceLimit = feature === FEATURE_LIMITS.workspacesPerCustomer;
  const isLiveScoringLimit = feature === FEATURE_LIMITS.liveScoring;

  switch (plan) {
    case SUBSCRIPTION_PLAN.basic:
      if (isWorkspaceLimit || isLiveScoringLimit || isBoostPerformanceLimit) {
        return { plan: SUBSCRIPTION_PLAN.dd_teams, tier: MIN_SEAT_TIER };
      }
      return { plan: SUBSCRIPTION_PLAN.business_self_serve, tier: MIN_SEAT_TIER };
    case SUBSCRIPTION_PLAN.starter:
      if (isWorkspaceLimit || isLiveScoringLimit || isBoostPerformanceLimit) {
        return { plan: SUBSCRIPTION_PLAN.dd_teams, tier: MIN_SEAT_TIER };
      }
      return { plan: SUBSCRIPTION_PLAN.business_self_serve, tier: MIN_SEAT_TIER };
    case SUBSCRIPTION_PLAN.dd_teams:
      if (tier === MAX_SEAT_TIER[plan]) {
        // in max tier for the plan
        if (isWorkspaceLimit) {
          // workspace limit hit
          return { plan: SUBSCRIPTION_PLAN.business_self_serve, tier: tier };
        }
      }
      if (!isWorkspaceLimit) {
        // brand voice limit hit
        return { plan: SUBSCRIPTION_PLAN.business_self_serve, tier: tier };
      }
      // next tier
      return { plan: SUBSCRIPTION_PLAN.dd_teams, tier: getNextSeatTier(tier) };
    case SUBSCRIPTION_PLAN.business_self_serve:
      if (tier === MAX_SEAT_TIER[plan]) {
        // member or workspace limits hit
        return { plan: SUBSCRIPTION_PLAN.business_self_serve };
      }
      if (!isWorkspaceLimit) {
        // brand voice limit hit
        return { plan: SUBSCRIPTION_PLAN.business_self_serve };
      }
      // next tier
      return { plan: SUBSCRIPTION_PLAN.business_self_serve, tier: getNextSeatTier(tier) };
    default:
      return { plan: SUBSCRIPTION_PLAN.dd_teams, tier: MIN_SEAT_TIER };
  }
};

export const getWorkspacesByTier = (plan, tier) => {
  if (
    TIER_PROPS[tier] &&
    TIER_PROPS[tier][plan] &&
    TIER_PROPS[tier][plan].seats &&
    BASE_WORKSPACE_COUNT[plan]
  ) {
    return (
      BASE_WORKSPACE_COUNT[plan] +
      TIER_PROPS[tier][plan].seats -
      TIER_PROPS[MIN_SEAT_TIER][plan].seats
    );
  }
  return 1;
};

export const getSeatsLabelByTier = (plan, tier = SUBSCRIPTION_TIER.seats_3) => {
  return `Includes ${TIER_PROPS?.[tier]?.[plan]?.seats || 3} seats`;
};

export const lessOrEqualSeatTier = (tierOne, tierTwo) => {
  const tierIndexOne = SEAT_TIER_HIERARCHY.indexOf(tierOne);
  const tierIndexTwo = SEAT_TIER_HIERARCHY.indexOf(tierTwo);
  if (tierIndexOne !== -1 && tierIndexTwo !== -1) {
    return tierIndexOne <= tierIndexTwo;
  }
};

export const getTierProperty = (tier, property, defaultValue = undefined, plan = undefined) => {
  let props = TIER_PROPS[tier];
  if (props && props.byPlan) {
    if (!plan || !props[plan]) {
      return defaultValue;
    }
    props = props[plan];
  }
  return (props && props[property]) || defaultValue;
};

export const getSubscriptionTierPrice = (tier, plan) => getTierProperty(tier, 'price', 0, plan);

export const getSubscriptionTierWordsLimit = (tier) => getTierProperty(tier, 'wordsLimit', 0);

export const getSubscriptionTierWordsLimitFormatted = (tier) => {
  const subscriptionLimit = getSubscriptionTierWordsLimit(tier);

  return Number.isFinite(subscriptionLimit) ? formatNumber(subscriptionLimit) : subscriptionLimit;
};

export const getPlanByTier = (tier, defaultPlan, plan) => {
  return getTierProperty(tier, 'plan', defaultPlan, plan);
};

export const isSubscriptionTierAvailableToUpgrade = (tier) =>
  getTierProperty(tier, 'availableToUpgrade', false);

export const getSubscriptionTierViewsLimit = (tier) => {
  return getTierProperty(tier, 'views', 0);
};

export const getSubscriptionTierSeatsLimit = (tier, plan) =>
  getTierProperty(tier, 'seats', 1, plan);

export const getSubscriptionTierTextFormatObject = (tier) => {
  if (!tier) return null;

  const wordsLimit = getSubscriptionTierWordsLimitFormatted(tier);
  const seatsLimit = getSubscriptionTierSeatsLimit(tier);

  return { wordsLimit, seatsLimit };
};
