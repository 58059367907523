import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useStyles from './useStyles';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  children: PropTypes.node,
  component: PropTypes.elementType,
};

const defaultProps = {
  className: null,
  classes: null,
  children: null,
  component: null,
};

const TextLink = React.forwardRef(
  ({ className, classes, children, component, ...restProps }, ref) => {
    const styles = useStyles({ classes });

    const Component = component || 'a';

    return (
      <Component
        className={cx(styles.root, className)}
        ref={ref}
        {...restProps}
      >
        {children}
      </Component>
    );
  }
);

TextLink.propTypes = propTypes;
TextLink.defaultProps = defaultProps;

export default TextLink;
