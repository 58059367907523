import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_MULTI_PROMPT_TEMPLATE } from './schema';

const MUTATION = gql`
  mutation UpdateCustomerMultiPromptTemplate(
    $customerId: ID!
    $id: ID!
    $name: String!
    $description: String!
    $info: String
    $fields: JSON!
    $blocks: JSON!
    $status: String
    $csvId: ID
    $primaryKey: String
    $selectedHeaders: [String!]
  ) {
    updateCustomerMultiPromptTemplate(
      customerId: $customerId
      id: $id
      description: $description
      info: $info
      name: $name
      fields: $fields
      blocks: $blocks
      status: $status
      csvId: $csvId
      primaryKey: $primaryKey
      selectedHeaders: $selectedHeaders
    ) {
      ...CustomerMultiPromptTemplate
    }
  }
  ${FRAGMENT_MULTI_PROMPT_TEMPLATE}
`;

export const updateCustomerMultiPromptTemplate = async ({
  customerId,
  id,
  name,
  description,
  info,
  fields,
  blocks,
  status,
  csvId,
  primaryKey,
  selectedHeaders,
}) => {
  const variables = {
    customerId,
    id,
    name,
    description,
    info,
    fields,
    blocks,
    status,
    csvId,
    primaryKey,
    selectedHeaders,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
