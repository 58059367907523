import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ColoredDiamondIcon = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 32 32"
      data-fill-none
    >
      <path
        d="M26 10.6667L28.6667 8"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 21.3334L28.6667 24"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6667 16H31.3333"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00001 10.6667L3.33334 8"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.00001 21.3334L3.33334 24"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33332 16H0.666656"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5813 24.36C22.5604 24.4187 22.5288 24.4729 22.488 24.52L16.488 31.12C16.4248 31.1875 16.3484 31.2412 16.2635 31.2779C16.1787 31.3147 16.0871 31.3335 15.9947 31.3333V26.32L22.5813 24.36Z"
        fill="#41C493"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6667 6.1333V24.0666C22.6653 24.1696 22.6377 24.2705 22.5867 24.36L16 26.32V7.8133L22.6 5.83997C22.6424 5.93202 22.6652 6.03194 22.6667 6.1333Z"
        fill="#41C493"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5947 5.83996L16 7.81329V0.666626C16.1501 0.668522 16.2956 0.720094 16.4133 0.813293L22.4133 5.61329C22.4883 5.67607 22.5499 5.75312 22.5947 5.83996Z"
        fill="#41C493"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.40799 24.36C9.42887 24.4187 9.46052 24.4729 9.50132 24.52L15.5013 31.12C15.5645 31.1875 15.6409 31.2412 15.7259 31.2779C15.8107 31.3147 15.9023 31.3335 15.9947 31.3333V26.32L9.40799 24.36Z"
        fill="#C5EFDF"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33334 6.1333V24.0666C9.33474 24.1696 9.36226 24.2705 9.41334 24.36L16 26.32V7.8133L9.39468 5.83997C9.35408 5.93242 9.33318 6.03231 9.33334 6.1333Z"
        fill="#C5EFDF"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39465 5.83996L16 7.81329V0.666626C15.8499 0.668522 15.7044 0.720094 15.5867 0.813293L9.58665 5.61329C9.50791 5.67501 9.44259 5.75215 9.39465 5.83996Z"
        fill="#C5EFDF"
        stroke="#111827"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
