import { useCallback } from 'react';
import { useSystemActions } from '../store';

export const DEFAULT_APPLICATION_HOST = 'go.anyword';

export const useGetSystemEventAttributes = () => {
  const systemActions = useSystemActions();

  return useCallback(() => {
    const result = {};

    result.applicationHost = systemActions.getApplicationHost() || DEFAULT_APPLICATION_HOST;

    return result;
  }, [systemActions]);
};
