import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, IconButton } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';
import useStyles from './useStyles';
import { ImageMagnifier } from '../ImageMagnifier';

export const PopupVariantScreenshotPreview = ({
  // assetId,
  variant,
  onEdit,
  onClose,
  open,
}) => {
  const styles = useStyles();

  const stopPropagation = (ev) => ev.stopPropagation();

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      open={open}
      onClick={onClose}
      maxWidth="lg"
      fullWidth
      data-testid="PopupVariantScreenshotPreview"
    >
      <DialogContent
        onClick={stopPropagation}
        className={styles.dialogContent}
      >
        <IconButton
          className={styles.buttonClose}
          onClick={onClose}
        >
          <IconClose />
        </IconButton>
        <ImageMagnifier
          imgBase64={variant.screenshotBase64}
          imageUrl={variant.screenshotUrl}
          staticHeight={300}
          className={styles.magnifierContainer}
        />
        {onEdit && (
          <Button
            className={styles.buttonEdit}
            onClick={onEdit}
            variant="contained"
            color="primary"
            data-testid="PopupVariantScreenshotPreview_editButton"
          >
            Edit
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

PopupVariantScreenshotPreview.propTypes = {
  variant: PropTypes.shape({
    screenshotBase64: PropTypes.string,
    screenshotUrl: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  open: PropTypes.bool,
};

PopupVariantScreenshotPreview.defaultProps = {
  open: false,
  onEdit: null,
};
