import { groupBy, lowerCase } from 'lodash';
import { useMemo } from 'react';
import { getResourceTagLabel } from 'src/data/performance';

export const useFilteredResources = ({
  resources,
  resourcesSearchText,
  resourcesTypeFilter,
  resourcesTagFilter,
  shouldBeCategorized,
}) => {
  const filteredResources = useMemo(() => {
    if (!resources) return [];

    const preparedSearchText = lowerCase(resourcesSearchText);

    return resources.filter((resource) => {
      const matchesSearchText =
        !resourcesSearchText ||
        lowerCase(resource.name).includes(preparedSearchText) ||
        lowerCase(resource.content).includes(preparedSearchText);
      const matchesFilterType =
        !resourcesTypeFilter.length || resourcesTypeFilter.includes(resource.contentType);
      const matchesFilterTag =
        !resourcesTagFilter.length ||
        (resource.tags || []).some((tag) => resourcesTagFilter.includes(getResourceTagLabel(tag)));

      return matchesSearchText && matchesFilterType && matchesFilterTag;
    });
  }, [resources, resourcesSearchText, resourcesTypeFilter, resourcesTagFilter]);

  if (shouldBeCategorized) {
    return groupBy(filteredResources, 'contentType');
  }

  return filteredResources;
};
