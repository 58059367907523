import { makeStyles } from '@material-ui/core/styles';

import { transformFromRotateDirection } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      transform: ({ direction }) => transformFromRotateDirection(direction),
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.short,
      }),
    },
  }),
  { name: 'ArrowIcon' }
);
