import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { trim } from 'lodash';

import { prepareAnnotations } from 'src/data/annotation';
import { isAssetDisabledAnnotations } from 'src/data/asset';
import { getToolBriefType, getToolDefaultAssetType } from 'src/data/generationTool';
import { extractTextAnnotations } from 'src/graphql/annotation';

const DEFAULT_DEBOUNCE_TIMEOUT = 500;

export const useTextAnnotationsDebounced = (
  { customerId, generationTool, text, variationId, productName },
  hookOptions
) => {
  const disableRequests = hookOptions?.disabled || false;
  const debounceTimeout = hookOptions?.debounceTimeout || DEFAULT_DEBOUNCE_TIMEOUT;

  const [annotations, setAnnotations] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Debounced "fetch" function
   */
  const fetchAnnotations = useDebouncedCallback(async (requestParams, requestOptions) => {
    try {
      const result = await extractTextAnnotations(requestParams, requestOptions);
      setAnnotations(result);
    } catch (error) {
      // we don't care much about errors here
    } finally {
      setLoading(false);
    }
  }, debounceTimeout);

  /**
   * React to "assetType" and "text" changes
   */
  useEffect(() => {
    // Cleat and do not query, if host component doesn't want it now
    if (disableRequests) {
      setAnnotations(null);
      return undefined;
    }

    // Return early, if no "text" to annotate was provided
    if (trim(text).length === 0) {
      setAnnotations(null);
      return undefined;
    }

    // Do not fetch annotations, if they're disabled for this asset
    const assetType = getToolDefaultAssetType(generationTool);
    if (isAssetDisabledAnnotations(assetType)) {
      setAnnotations(null);
      return undefined;
    }

    // Mark as "loading" even when we wait for "debounced" request to fire
    setLoading(true);

    // Schedule debounced request
    const abortController = new AbortController();
    fetchAnnotations(
      { customerId, generationTool, text, variationId, productName },
      {
        abortSignal: abortController.signal,
      }
    );

    // Clean up possible pending requests when we want to schedule new request or component unmounts
    return () => {
      abortController.abort();
      setLoading(false);
    };
  }, [
    disableRequests,
    fetchAnnotations,
    customerId,
    generationTool,
    text,
    variationId,
    productName,
  ]);

  // Reformat annotations for displaying purposes
  const preparedAnnotations = useMemo(() => {
    const briefType = getToolBriefType(generationTool);
    return prepareAnnotations(annotations, loading, {
      briefType,
    });
  }, [generationTool, annotations, loading]);

  return useMemo(
    () => ({
      annotations: preparedAnnotations,
      loading,
    }),
    [preparedAnnotations, loading]
  );
};
