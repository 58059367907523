import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import { COLORS } from 'src/data/ui';
import { useSvgId } from 'src/lib/hooks';

export const IconCheckCircle = memo(
  forwardRef((props, ref) => {
    const svgId = useSvgId();

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
        data-fill-none
      >
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M14.6667 7.501V8.11433C14.6658 9.55194 14.2003 10.9508 13.3395 12.1022C12.4788 13.2536 11.2688 14.096 9.89022 14.5036C8.5116 14.9112 7.03815 14.8623 5.68963 14.3641C4.3411 13.8658 3.18975 12.9451 2.40729 11.739C1.62482 10.533 1.25317 9.10637 1.34776 7.67187C1.44235 6.23737 1.99812 4.87188 2.93217 3.77905C3.86621 2.68621 5.1285 1.92459 6.53077 1.60776C7.93304 1.29094 9.40016 1.43589 10.7133 2.021"
            stroke={COLORS.gray.gray40}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.6667 1.44775L8 8.12109L6 6.12109"
            stroke={COLORS.gray.gray40}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.114258)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
