import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

const TAG_COLORS = {
  yellow: '#FFEDBF',
  purple: '#FAE8FF',
};

export const useStyles = makeStyles(
  (theme) => ({
    root: (props) => ({
      textTransform: 'uppercase',
      borderRadius: theme.shape.borderRadiusSmall,
      padding: theme.spacing(0.625, 1.25, 0.5),
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.gray.black,
      minWidth: 'max-content',
      backgroundColor: props.backgroundColor ? `${TAG_COLORS[props.backgroundColor]}` : null,
    }),
  }),
  { name: 'CustomTag' }
);
