import { getAssetChannel } from '../asset';
import { CHANNEL, getChannelLabel } from '../channel';
import { getGoogleContentValidationDetailsPerReason } from '../googleSensitiveInfoConverter';

export const CONTENT_SENSITIVITY = {
  safe: 'safe',
  unsafe: 'unsafe',
  sensitive: 'sensitive',
  blocked: 'blocked',
};

// As of 2021/07/08 in prod AC returns "safe", "sensitive" and "unsafe"
// values in a "reason" field, however, this is going to change to return
// more speicif reasons. To avoid downtime because of a difference in deployments
// between the platform and AC we'll hardcode some general purpose messages and
// deploy the FE first
export const normalizeContentSensitivityReason = (reason, channel) => {
  switch (reason.toLowerCase()) {
    case 'safe':
      return channel
        ? `This text complies with ${getChannelLabel(channel)}’s content policy`
        : 'This text complies with our AI content policy';
    case 'sensitive':
      return 'This text may be sensitive.';
    case 'unsafe':
      return 'This text may be unsafe';
    default:
      return reason;
  }
};

export function getContentSensitivityLabelDescription(sensitivity) {
  switch (sensitivity.toLowerCase()) {
    case 'red':
      return CONTENT_SENSITIVITY.unsafe;
    case 'yellow':
      return CONTENT_SENSITIVITY.sensitive;
    default:
      return CONTENT_SENSITIVITY.safe;
  }
}

export const CONTENT_SENSITIVITY_PROPS = {
  [CONTENT_SENSITIVITY.safe]: {
    label: 'Safe',
  },
  [CONTENT_SENSITIVITY.unsafe]: {
    label: 'This text may be unsafe',
  },
  [CONTENT_SENSITIVITY.sensitive]: {
    label: 'This text may be sensitive',
  },
  [CONTENT_SENSITIVITY.blocked]: {
    label: 'Blocked',
  },
};

export const getContentSensitivityLabel = (contentSensitivity) => {
  return CONTENT_SENSITIVITY_PROPS[contentSensitivity]?.label || contentSensitivity;
};

// see https://docs.google.com/spreadsheets/d/1CcHV33CFfh_SIXplGOXSju_Q7suXU6m-uKt_c7RlzQI/edit?usp=sharing
export const isGPT3Violation = (reason) => {
  return /The content of this variation may be (sensitive|unsafe)/i.test(reason);
};

const getTitle = (policyTitle) => {
  if (policyTitle) {
    return '';
  }
  return 'Content safety';
};

export const getContentSensitivityInfo = (policyTitle, sensitivity, assetType) => {
  const label = sensitivity?.label || '';
  const reason = sensitivity?.reason;
  const channel = getAssetChannel(assetType);
  const labelDesc = getContentSensitivityLabelDescription(label || '');
  const normalReason = normalizeContentSensitivityReason(reason || '', channel);

  const isFBViolation =
    labelDesc !== CONTENT_SENSITIVITY.safe &&
    !isGPT3Violation(normalReason) &&
    channel === CHANNEL.facebook;

  const isGoogleViolation =
    labelDesc !== CONTENT_SENSITIVITY.safe &&
    !isGPT3Violation(normalReason) &&
    channel === CHANNEL.google;

  const title = getTitle(policyTitle);
  let reasonDetails = sensitivity?.reasonDetails;
  let reasonTitle = ''; // default when neither isGoogleViolation nor isFBViolation
  let learnMoreLink = '';
  let reasonDesc = normalReason;

  if (isGoogleViolation) {
    const googleDetails = getGoogleContentValidationDetailsPerReason(sensitivity);
    reasonTitle = googleDetails.reasonTitle;
    reasonDesc = googleDetails.reasonDescription;
    reasonDetails = googleDetails.reasonDetails;
    learnMoreLink = googleDetails.learnMoreLink;
  } else if (isFBViolation) {
    reasonTitle = 'Possible Facebook policy violation:\n';
  }

  return { title, reasonTitle, reasonDesc, reasonDetails, learnMoreLink, labelDesc };
};
