import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { CircularProgress } from '@material-ui/core';

import { useCurrentCustomerId, useCurrentWorkspaceId } from 'src/store';
import { getChannelPerformanceLabel } from 'src/data/channel';
import {
  RESOURCE_CHANNELS,
  PERFORMANCE_EXTRACTION_STATUS,
  flatConnectedAccounts,
  getResourceDocumentLabel,
  isEmailCsvResourceDocument,
} from 'src/data/performance';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useViewState } from './useViewState';
import { useStyles } from './useStyles';
import { useSubscribeForPubsub } from './useSubscribeForPubsub';
import {
  useChannelAccounts,
  useGetAccountsLastExtraction,
  useIsApplicationModeInfinity,
  useErrorHandler,
} from 'src/services';

export const DataExtractionIndicator = () => {
  const styles = useStyles();

  const currentCustomerId = useCurrentCustomerId();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const handleError = useErrorHandler();
  const isApplicationModeInfinity = useIsApplicationModeInfinity();

  const { avgCompletionPercent, runningCustomModels, runningTalkingPointsViewAccountIds } =
    useViewState();

  const { connectedAccounts } = useChannelAccounts();

  const accountsInProgress = flatConnectedAccounts(connectedAccounts, true).filter(
    (account) =>
      account.status === PERFORMANCE_EXTRACTION_STATUS.running &&
      !RESOURCE_CHANNELS.includes(account.channel)
  );

  const getChannelLabel = (channel) =>
    getChannelPerformanceLabel(channel) || getResourceDocumentLabel(channel);

  const getTitle = () => {
    const channelLabel =
      accountsInProgress.length === 1
        ? getChannelLabel(accountsInProgress[0].channel)
        : `${accountsInProgress.length} channels`;

    const lastAccountInProgress = accountsInProgress[accountsInProgress.length - 1];
    const progress = lastAccountInProgress
      ? avgCompletionPercent[lastAccountInProgress.channel]?.[lastAccountInProgress.id]
      : null;

    const isCreatingTalkingPointsView = runningTalkingPointsViewAccountIds.includes(
      lastAccountInProgress?.id
    );

    if (!accountsInProgress.length && runningCustomModels.length) {
      return 'Training your model';
    }

    if (progress < 20) {
      return isEmailCsvResourceDocument(accountsInProgress[0].channel)
        ? 'Uploading...'
        : `Connecting ${channelLabel} with Anyword...`;
    }
    if (progress < 40) {
      return 'Downloading data';
    }
    if (progress < 60) {
      return isCreatingTalkingPointsView ? 'Scoring talking points' : 'Labeling copy';
    }
    if (progress < 80) {
      return isCreatingTalkingPointsView ? 'Scoring talking points' : 'Setting up account';
    }
    return isCreatingTalkingPointsView ? 'Scoring talking points' : 'Training your model';
  };

  const accountsInProgressRef = useRef([]);
  const lastAccountInProgressRef = useRef(null);
  const [isInsightsPanelOpen, setIsInsightsPanelOpen] = useState(false);

  useEffect(() => {
    if (
      !(accountsInProgress.length || accountsInProgressRef.current.length) ||
      !currentCustomerId
    ) {
      return;
    }
    lastAccountInProgressRef.current = accountsInProgressRef.current.find(
      (accountInProgress) => !accountsInProgress.includes(accountInProgress)
    );

    const isLastAccountExtractionDone =
      lastAccountInProgressRef.current?.status === PERFORMANCE_EXTRACTION_STATUS.done;

    if (lastAccountInProgressRef.current && isLastAccountExtractionDone) {
      accountsInProgressRef.current = accountsInProgressRef.current.filter(
        (prevAccountInProgress) => prevAccountInProgress !== lastAccountInProgressRef.current
      );
    }
    if (accountsInProgress.length) {
      // we don't want to override accountsInProgressRef if accountsInProgress is empty
      accountsInProgressRef.current = accountsInProgress;
    }
  }, [
    currentWorkspaceId,
    currentCustomerId,
    accountsInProgressRef,
    accountsInProgress,
    handleError,
    isApplicationModeInfinity,
  ]);

  useSubscribeForPubsub(setIsInsightsPanelOpen);
  useGetAccountsLastExtraction();

  if (!currentCustomerId || (!accountsInProgress.length && !runningCustomModels.length)) {
    return null;
  }

  return (
    <div
      className={cx(styles.root, {
        [styles.isInsightsPanelOpen]: isInsightsPanelOpen,
      })}
    >
      <CircularProgress
        classes={{ indeterminate: styles.loader }}
        size={16}
      />
      <CustomTypography
        className={styles.title}
        variant={TYPOGRAPHY_VARIANT.regular14}
      >
        {getTitle()}
      </CustomTypography>
    </div>
  );
};
