import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from './Tooltip';

const getTooltipClass = (step) => {
  if (step.contentClass) {
    return step.contentClass;
  }
  return Tooltip;
};

export const TooltipFactory = forwardRef(({ step, ...restProps }, ref) => {
  const TooltipClass = getTooltipClass(step);

  return React.createElement(TooltipClass, {
    step,
    ref,
    ...restProps,
  });
});

TooltipFactory.propTypes = {
  step: PropTypes.shape().isRequired,
};
