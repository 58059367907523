import React from 'react';

export const useOnScreen = (ref, rootMargin = '0px') => {
  // State and setter for storing whether element is visible
  const [isOnScreen, setIsOnScreen] = React.useState(false);

  React.useEffect(() => {
    const node = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsOnScreen(entry.isIntersecting);
      },
      { rootMargin }
    );

    if (node) {
      observer.observe(node);
    }

    return () => {
      observer.unobserve(node);
    };
  }, [ref, rootMargin]);

  return isOnScreen;
};
