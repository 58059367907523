import VALIDATORS from './validators';

const checkRule = (ruleName, ruleValue, value) => {
  const validator = VALIDATORS[ruleName];
  if (validator) {
    const error = validator(ruleValue, value);
    if (error) {
      if (typeof error === 'string') {
        return {
          rule: ruleName,
          message: error,
        };
      }

      if (typeof error === 'object') {
        return error;
      }
    }
  }

  return null;
};

export default function validate(rules, value) {
  if (!rules) {
    return null;
  }

  const errors = Object.entries(rules)
    // Skip rules with falsy value
    .filter(([, ruleValue]) => Boolean(ruleValue))
    // Apply rules
    .map(([ruleName, ruleValue]) => checkRule(ruleName, ruleValue, value))
    // Filter out rules without errors
    .filter((error) => Boolean(error));

  return errors[0] || null;
}

export function filterMessage(error) {
  if (error) {
    return error.message;
  }

  return null;
}
