import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_MULTI_PROMPT_TEMPLATE } from '../schema';

const MUTATION = gql`
  mutation UpdateExpertMultiPromptTemplate(
    $id: ID!
    $templateGroup: TemplateGroupEnum!
    $smallImage: String
    $bigImage: String
    $iconImage: String
  ) {
    updateExpertMultiPromptTemplate(
      id: $id
      templateGroup: $templateGroup
      smallImage: $smallImage
      bigImage: $bigImage
      iconImage: $iconImage
    ) {
      ...CustomerMultiPromptTemplate
    }
  }
  ${FRAGMENT_MULTI_PROMPT_TEMPLATE}
`;

export const updateExpertMultiPromptTemplate = async ({
  id,
  templateGroup,
  smallImage,
  bigImage,
  iconImage,
}) => {
  const variables = {
    id,
    templateGroup,
    smallImage,
    bigImage,
    iconImage,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
