import { useMemo } from 'react';

import { useBrandVoicesAssets, useCurrentWorkspace } from 'src/store';
import {
  getBrandVoicesAssetsTonesOfVoice,
  getWorkspaceDefaultToneOfVoice,
} from 'src/data/toneOfVoice';

export const useAnalyzerTonesOfVoice = (generationTool) => {
  const currentWorkspace = useCurrentWorkspace();

  const { toneOfVoices } = useBrandVoicesAssets();

  return useMemo(() => {
    const workspaceDefaultToneOfVoice = getWorkspaceDefaultToneOfVoice(
      currentWorkspace,
      generationTool
    );
    const brandVoicesAssetsTonesOfVoice = getBrandVoicesAssetsTonesOfVoice(toneOfVoices);

    const selectedToneOfVoice = brandVoicesAssetsTonesOfVoice.find(
      (toneOfVoice) => toneOfVoice.id === workspaceDefaultToneOfVoice?.id
    );

    return {
      analyzerToneOfVoice: selectedToneOfVoice || brandVoicesAssetsTonesOfVoice[0],
      analyzerTonesOfVoice: brandVoicesAssetsTonesOfVoice,
    };
  }, [currentWorkspace, toneOfVoices, generationTool]);
};
