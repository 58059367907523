import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const propTypes = {
  open: PropTypes.bool.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export const LogoutPopup = ({
  open,

  onApprove,
  onReject,
}) => (
  <Dialog
    open={open}
    onClose={onReject}
    data-testid="LogoutPopup"
  >
    <DialogTitle disableTypography>
      <Typography variant="h5">Logout</Typography>
    </DialogTitle>

    <DialogContent>
      <Typography variant="body1">Are you sure you want to log out?</Typography>
    </DialogContent>

    <DialogActions>
      <Button
        onClick={onReject}
        aria-label="Reject"
        data-testid="logoutPopupRejectButton"
      >
        Cancel
      </Button>

      <Button
        onClick={onApprove}
        variant="contained"
        color="primary"
        autoFocus
        aria-label="Approve"
        data-testid="logoutPopupApproveButton"
      >
        Log out
      </Button>
    </DialogActions>
  </Dialog>
);

LogoutPopup.propTypes = propTypes;
