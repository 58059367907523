import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },

    title: {
      color: COLORS.neutral.black,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },

    keywordsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    },

    counter: {},
  }),
  { name: 'KeywordsGroup' }
);
