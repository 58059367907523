import { useCallback } from 'react';

import { usePopups, BRAND_TERM_POPUP } from 'src/services/popup';

export const useBrandTermPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ brandTerm, brandVoice }) => {
      popups.openPopup(BRAND_TERM_POPUP, {
        brandTerm,
        brandVoice,
        onClose: () => {
          popups.closePopup(BRAND_TERM_POPUP);
        },
      });
    },
    [popups]
  );
};
