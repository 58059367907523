import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: theme.spacing(2),
    },

    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },

    title: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },

    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
      overflow: 'hidden',
    },

    infoItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.25),
    },

    infoTitle: {
      color: COLORS.gray.gray30,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },

    infoContent: {
      color: COLORS.gray.gray30,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },

    keywordsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: theme.spacing(0, 1.5),
      '& > *:not(:last-child)': {
        position: 'relative',

        '&:after': {
          content: '"∙"',
          position: 'absolute',
          left: '100%',
          top: '50%',
          display: 'inline-block',
          transform: 'translateY(-40%)',
          marginLeft: theme.spacing(0.5),
          lineHeight: 0,
        },
      },
    },
  }),
  { name: 'ScoreMethodsAndFactors' }
);
