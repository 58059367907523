export const CUSTOM_MENU_ITEM_TYPE = {
  default: 'default',
  divider: 'divider',
  subheader: 'subheader',
  submenu: 'submenu',
  placeholder: 'placeholder',
  locked: 'locked',
  loading: 'loading',
  custom: 'custom',
};
