import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { useStyles } from './useStyles';

export const Keyword = ({ classes, count, label, isSelected, isUsed, onClick }) => {
  const styles = useStyles({ classes });

  return (
    <div
      className={cx(styles.root, {
        [styles.selected]: isSelected,
        [styles.unused]: !isUsed,
      })}
      onClick={onClick}
    >
      <Typography className={styles.counter}>{count}</Typography>
      <TruncatedTypography
        className={cx(styles.label, {
          [styles.selected]: isSelected,
          [styles.unused]: !isUsed,
        })}
      >
        {label}
      </TruncatedTypography>
    </div>
  );
};

Keyword.propTypes = {
  classes: PropTypes.shape({}),
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isUsed: PropTypes.bool,
};

Keyword.defaultProps = {
  classes: null,
  isSelected: false,
  isUsed: false,
};
