import { useEffect } from 'react';

import {
  usePopups,
  GENERIC_NOTIFICATION_POPUP,
  CUSTOM_NOTIFICATION_POPUP,
} from 'src/services/popup';

export const PopupNotification = ({ notification, onClickNotification, onCloseNotification }) => {
  const popups = usePopups();

  useEffect(() => {
    const popupType = notification.htmlContent
      ? CUSTOM_NOTIFICATION_POPUP
      : GENERIC_NOTIFICATION_POPUP;

    popups.openPopup(popupType, {
      notification,

      onButtonClick: async (linkFromCustomNotificationEvent) => {
        onClickNotification(notification);
        popups.closePopup(popupType);

        if (linkFromCustomNotificationEvent) {
          window.location = linkFromCustomNotificationEvent;
        } else if (notification.link) {
          window.location.pathname = notification.link;
        }
      },
      onClose: () => {
        onCloseNotification(notification);
        popups.closePopup(popupType);
      },
    });
  }, [popups, notification, onClickNotification, onCloseNotification]);

  return null;
};
