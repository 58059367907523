import { useCallback } from 'react';

import { useUserActions, useCustomerActions } from 'src/store';
import * as gtm from 'src/lib/gtm';

export const useTrackGtmEvent = () => {
  const userActions = useUserActions();
  const customerActions = useCustomerActions();

  return useCallback(
    (name, attributes) => {
      const currentUser = userActions.getCurrentUser();
      const currentCustomer = customerActions.getCurrentCustomer();

      gtm.pushDataLayerEvent(name, {
        customerId: currentCustomer?.id,
        email: currentUser?.email,
        ...attributes,
      });
    },
    [userActions, customerActions]
  );
};
