import React, { forwardRef } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconCup = forwardRef((props, ref) => {
  return (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        d="M5.23438 19.1342C5.23438 17.5989 7.36779 16.3542 10 16.3542C12.6322 16.3542 14.7656 17.5989 14.7656 19.1342H5.23438Z"
        fill="white"
      />
      <path
        d="M9.9998 17.9428C11.6381 17.9202 13.2612 18.2592 14.7535 18.9356C14.5788 17.4924 12.5176 16.3542 9.9998 16.3542C7.48197 16.3542 5.42083 17.4924 5.24609 18.9356C6.73838 18.2592 8.36151 17.9202 9.9998 17.9428Z"
        fill="white"
      />
      <path
        d="M15.2422 8.03824C14.8856 11.6061 10.6053 14.2852 7.09461 11.8317C6.42024 11.3423 5.85904 10.7136 5.44913 9.98825C5.03922 9.26285 4.79019 8.45775 4.71895 7.6276L4.08353 1.30282C4.07797 1.24743 4.08411 1.19149 4.10156 1.13862C4.11901 1.08576 4.14737 1.03715 4.18482 0.99596C4.22227 0.954768 4.26796 0.921908 4.31893 0.899515C4.36989 0.877121 4.425 0.865693 4.48067 0.865972H15.521C15.5767 0.865693 15.6318 0.877121 15.6828 0.899515C15.7337 0.921908 15.7794 0.954768 15.8169 0.99596C15.8543 1.03715 15.8827 1.08576 15.9001 1.13862C15.9176 1.19149 15.9237 1.24743 15.9182 1.30282L15.2422 8.03824Z"
        fill="white"
      />
      <path
        d="M10.0002 0.865972H4.48165C4.42598 0.865693 4.37087 0.877121 4.3199 0.899515C4.26894 0.921908 4.22325 0.954768 4.1858 0.99596C4.14835 1.03715 4.11998 1.08576 4.10253 1.13862C4.08508 1.19149 4.07894 1.24743 4.08451 1.30282L4.71993 7.6276C4.79088 8.45756 5.03955 9.26254 5.44904 9.98792C5.85853 10.7133 6.41927 11.3421 7.09321 11.8317C7.9403 12.4419 8.95629 12.7733 10.0002 12.78V0.865972Z"
        fill="white"
      />
      <path
        d="M2.94115 7.81822C2.33202 7.59303 1.80652 7.18658 1.43544 6.65361C1.06437 6.12064 0.865533 5.48675 0.865723 4.83732V4.04305C0.865723 3.8324 0.949405 3.63037 1.09836 3.48142C1.24731 3.33246 1.44934 3.24878 1.65999 3.24878H4.28109"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0587 7.81822C17.6678 7.59303 18.1933 7.18658 18.5644 6.65361C18.9355 6.12064 19.1343 5.48675 19.1341 4.83732V4.04305C19.1341 3.8324 19.0504 3.63037 18.9015 3.48142C18.7525 3.33246 18.5505 3.24878 18.3398 3.24878H15.7188"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4373 4.00815L10.9963 5.21142H12.0837C12.1386 5.20903 12.1929 5.22373 12.2392 5.25348C12.2854 5.28323 12.3213 5.32658 12.3418 5.37756C12.3624 5.42854 12.3667 5.48464 12.354 5.53814C12.3414 5.59164 12.3125 5.63991 12.2713 5.67631L11.3274 6.60608L11.8505 7.80718C11.873 7.86217 11.8778 7.92282 11.8642 7.98067C11.8506 8.03852 11.8193 8.09068 11.7746 8.12989C11.73 8.1691 11.6742 8.19339 11.6151 8.19939C11.556 8.20538 11.4964 8.19277 11.4448 8.16332L10.1812 7.45267L8.91758 8.16332C8.866 8.19314 8.80636 8.20603 8.74707 8.20019C8.68777 8.19435 8.6318 8.17006 8.58702 8.13075C8.54225 8.09143 8.51093 8.03907 8.49746 7.98103C8.484 7.92299 8.48907 7.86219 8.51196 7.80718L9.03503 6.60608L8.09112 5.67739C8.04953 5.64112 8.02026 5.59282 8.00737 5.53917C7.99447 5.48551 7.99858 5.42918 8.01914 5.37797C8.03969 5.32677 8.07567 5.28322 8.12208 5.25338C8.16849 5.22354 8.22304 5.20887 8.27816 5.21142H9.36562L9.92511 4.00706C9.94947 3.9601 9.98628 3.92075 10.0315 3.89333C10.0767 3.8659 10.1286 3.85145 10.1815 3.85156C10.2344 3.85168 10.2863 3.86634 10.3314 3.89396C10.3765 3.92158 10.4132 3.96109 10.4373 4.00815Z"
        fill="white"
        stroke="#626266"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.78V16.3542"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.23438 19.1342C5.23438 17.5989 7.36779 16.3542 10 16.3542C12.6322 16.3542 14.7656 17.5989 14.7656 19.1342H5.23438Z"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2422 8.03824C14.8856 11.6061 10.6053 14.2852 7.09461 11.8317C6.42024 11.3423 5.85904 10.7136 5.44913 9.98825C5.03922 9.26285 4.79019 8.45775 4.71895 7.6276L4.08353 1.30282C4.07797 1.24743 4.08411 1.19149 4.10156 1.13862C4.11901 1.08576 4.14737 1.03715 4.18482 0.99596C4.22227 0.954768 4.26796 0.921908 4.31893 0.899515C4.36989 0.877121 4.425 0.865693 4.48067 0.865972H15.521C15.5767 0.865693 15.6318 0.877121 15.6828 0.899515C15.7337 0.921908 15.7794 0.954768 15.8169 0.99596C15.8543 1.03715 15.8827 1.08576 15.9001 1.13862C15.9176 1.19149 15.9237 1.24743 15.9182 1.30282L15.2422 8.03824Z"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
});
