import { createContainer } from 'react-sweet-state';

import { CustomerStore, ACTIONS } from './CustomerStore';

export const CustomerStoreContainer = createContainer(CustomerStore, {
  onInit: () => ({ setState, dispatch }, { currentUser, state }) => {
    dispatch(ACTIONS.init(currentUser));

    if (state) {
      setState(state);
    }
  },
  onUpdate: () => ({ setState, dispatch }, { currentUser, state }) => {
    dispatch(ACTIONS.init(currentUser));

    if (state) {
      setState(state);
    }
  },
});
