import { useCallback } from 'react';

import { useConfirmPopup } from 'src/services/popup';
import { CONFIRM_POPUP_MODE } from 'src/components/ConfirmPopup';
import { CHANNEL, getChannelPerformanceLabel } from 'src/data/channel';
import { isMetaChannel } from 'src/data/performance';
import { Typography } from '@material-ui/core';

export const useConfirmUnauthorizeFromChannel = () => {
  const confirmPopup = useConfirmPopup();

  const alignChannelLabel = (channel) =>
    isMetaChannel(channel)
      ? `${getChannelPerformanceLabel(CHANNEL.facebook)}, ${getChannelPerformanceLabel(
          CHANNEL.facebookPages
        )} and ${getChannelPerformanceLabel(CHANNEL.instagram)}`
      : getChannelPerformanceLabel(channel);

  return useCallback(
    (channel) => {
      return confirmPopup({
        mode: CONFIRM_POPUP_MODE.warning,
        title: `Disconnect Integration?`,
        content: (
          <Typography>
            You will no longer be able to access your <b>{alignChannelLabel(channel)}</b> data in
            this workspace.
            <br />
            Please note that disconnecting this data will also disconnect other members.
          </Typography>
        ),
        buttons: {
          confirm: 'Disconnect',
        },
        withReject: true,
      })
        .then(() => true)
        .catch(() => false);
    },
    [confirmPopup]
  );
};
