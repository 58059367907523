import React, { memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconTableSort = memo((props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
  >
    <path
      d="M8 2.41016L8 12.4102"
      stroke="#626266"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 5L8 2L5 5"
      stroke="#626266"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
));
