import { makeStyles } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    root: {
      padding: theme.spacing(0),
      backgroundColor: 'transparent',
      borderRadius: theme.shape.borderRadiusSmall,

      '& fieldset': {
        border: 'none',
      },
    },
    select_select: {
      ...theme.typography.medium14,
      width: 88,
      marginLeft: theme.spacing(1),
      borderRadius: theme.shape.borderRadiusSmall,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px !important`,
      color: COLORS.gray.gray30,
      '&:hover': {
        backgroundColor: COLORS.primary.silver,
      },

      '&:focus, &[aria-expanded]': {
        backgroundColor: COLORS.primary.silver,
        borderRadius: theme.shape.borderRadiusSmall,
      },
    },
    select_icon: {
      color: COLORS.gray.gray30,
      fontSize: theme.typography.pxToRem(14),
      top: 'calc(50% - 6px)',
      right: theme.spacing(0),
    },
  }),
  { name: 'TextStyleDropdown' }
);
