import { almNamespace } from 'src/utils/almNamespace';
import * as storage from 'src/lib/storage';
import { whiteLabels } from './whiteLabels';

const WHITE_LABEL_KEY = 'ALM_WHITE_LABEL';

const readWhiteLabel = () => {
  try {
    return JSON.parse(storage.readItem(WHITE_LABEL_KEY) || null);
  } catch (e) {
    return null;
  }
};

const writeWhiteLabel = (whiteLabel) => {
  try {
    storage.writeItem(WHITE_LABEL_KEY, JSON.stringify(whiteLabel));
  } catch (e) {
    //
  }
};

const createNamespaceObject = () => {
  let active = null;

  return {
    init() {
      active = readWhiteLabel();
    },
    read() {
      return active;
    },
    print() {
      // eslint-disable-next-line no-console
      console.log('whiteLabel', active);
    },

    alm: {
      activate: () => {
        active = whiteLabels.anyword;
        writeWhiteLabel(whiteLabels.anyword);
      },
      deactivate: () => {
        active = null;
        writeWhiteLabel(null);
      },
    },

    teads: {
      activate: () => {
        active = whiteLabels.teads;
        writeWhiteLabel(whiteLabels.teads);
      },
      deactivate: () => {
        active = null;
        writeWhiteLabel(null);
      },
    },
  };
};

almNamespace.add('whiteLabel', createNamespaceObject());

// init whiteLabel for testing
try {
  window.alm.whiteLabel.init();
} catch (e) {
  //
}
