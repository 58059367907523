import { useEffect } from 'react';

import { useGenerationToolActions } from 'src/store';

export const useQueryGenerationToolSettings = () => {
  const generationToolActions = useGenerationToolActions();

  useEffect(() => {
    generationToolActions.queryGenerationToolSettings();
  }, [generationToolActions]);
};
