import { COMPANY_SIZE } from 'src/data/company';

export const SUBSCRIPTION_PLAN_ACTIVE = {
  business: 'business',
  starter: 'starter',
  dd_teams: 'dd_teams',
  business_self_serve: 'business_self_serve',
  poc: 'poc',
};

export const SUBSCRIPTION_PLAN_DEPRECATED = {
  tiered: 'tiered',
  premium: 'premium',
  continuous: 'continuous',
  dataDriven100: 'data_driven_beta_100',
  acquisition: 'acquisition',
  conversion: 'conversion',
  engagement: 'engagement',
  shopifyFreemium: 'shopify_freemium',
  basic: 'basic',
  basicTiered: 'basic_tiered',
  proAgency: 'pro_beta_agency',
  proEcommerce: 'pro_beta_ecommerce',
  proPublisher: 'pro_beta_publisher',
  enterprise: 'enterprise',
  dataDriven: 'data_driven_beta_30',
  professional: 'professional',
  website: 'website',
};

export const SUBSCRIPTION_PLAN = {
  ...SUBSCRIPTION_PLAN_ACTIVE,
  ...SUBSCRIPTION_PLAN_DEPRECATED,
};

export const DEFAULT_SUBSCRIPTION_PLAN = SUBSCRIPTION_PLAN.starter;

export const BILLING_METHOD_TYPE = {
  stripe: 'stripe',
  storyDistro: 'story_distro',
  appsumo: 'appsumo',
};
export const SUBSCRIPTION_STATUS = {
  noSubscription: 'no_subscription',
  active: 'active',
  canceled: 'canceled',
  incomplete: 'incomplete',
  incompleteExpired: 'incomplete_expired',
  pastDue: 'past_due',
  unpaid: 'unpaid',
};

export const SUBSCRIPTION_TIERED_TIER = {
  tier_migrate: '0_dollars_for_6000_words',
  tier_0: '0_dollars_for_5000_words',
  tier_19: '19_dollars_for_15000_words',
  tier_39: '39_dollars_for_35000_words',
  tier_99: '99_dollars_for_100000_words',
  tier_149: '149_dollars_for_165000_words',
  tier_199: '199_dollars_for_240000_words',
};

export const SUBSCRIPTION_PRO_TIER = {
  pro_beta_agency_tier_1: 'pro_beta_agency_tier_1',
  pro_beta_agency_tier_2: 'pro_beta_agency_tier_2',
  pro_beta_ecommerce_tier_1: 'pro_beta_ecommerce_tier_1',
  pro_beta_ecommerce_tier_2: 'pro_beta_ecommerce_tier_2',
  pro_beta_publisher_tier_1: 'pro_beta_publisher_tier_1',
  pro_beta_publisher_tier_2: 'pro_beta_publisher_tier_2',
};

export const SUBSCRIPTION_BASIC_TIER = {
  basic_tier_20k: 'basic_tier_20k',
  basic_tier_35k: 'basic_tier_35k',
  basic_tier_65k: 'basic_tier_65k',
  basic_tier_100k: 'basic_tier_100k',
  basic_tier_200k: 'basic_tier_200k',
  basic_tier_300k: 'basic_tier_300k',
};

export const SUBSCRIPTION_BASIC_TIER_DEPRECATED = {
  basic_tier_15k: 'basic_tier_15k',
  basic_tier_30k: 'basic_tier_30k',
  basic_tier_50k: 'basic_tier_50k',
};

export const DEFAULT_DDU_TIER = SUBSCRIPTION_PRO_TIER.pro_beta_agency_tier_1;

export const SUBSCRIPTION_DATA_DRIVEN_TIER = {
  data_driven_30k: 'data_driven_30k',
  data_driven_60k: 'data_driven_60k',
  data_driven_100k: 'data_driven_100k',
  data_driven_200k: 'data_driven_200k',
  data_driven_300k: 'data_driven_300k',
  pro_beta_agency_tier_1: DEFAULT_DDU_TIER,
};

export const SUBSCRIPTION_DATA_DRIVEN_SEAT_TIER = {
  seats_3: 'seats_3',
  seats_4: 'seats_4',
  seats_5: 'seats_5',
  seats_6: 'seats_6',
  seats_7: 'seats_7',
  seats_8: 'seats_8',
  seats_9: 'seats_9',
  seats_10: 'seats_10',
};

export const SUBSCRIPTION_SEAT_TIER = {
  ...SUBSCRIPTION_DATA_DRIVEN_SEAT_TIER,
  seats_11: 'seats_11',
  seats_12: 'seats_12',
  seats_13: 'seats_13',
  seats_14: 'seats_14',
  seats_15: 'seats_15',
  seats_16: 'seats_16',
  seats_17: 'seats_17',
  seats_18: 'seats_18',
  seats_19: 'seats_19',
  seats_20: 'seats_20',
};

export const SUBSCRIPTION_TIER = {
  ...SUBSCRIPTION_TIERED_TIER,
  ...SUBSCRIPTION_PRO_TIER,
  ...SUBSCRIPTION_BASIC_TIER,
  ...SUBSCRIPTION_BASIC_TIER_DEPRECATED,
  ...SUBSCRIPTION_DATA_DRIVEN_TIER,
  ...SUBSCRIPTION_SEAT_TIER,
};

export const BILLING_CYCLES = {
  monthly: 'monthly',
  annually: 'annual',
};

export const ANNUAL_BILLING_CYCLE_DISCOUNT_PERCENT = 0.2;
export const OVERAGE_PRICE = {
  [SUBSCRIPTION_PLAN.dataDriven]: 20,
};
export const OVERAGE_WORDS_LIMIT = {
  [SUBSCRIPTION_PLAN.dataDriven]: 10000,
};
export const getOverageWordsLimitForPlan = (plan) => OVERAGE_WORDS_LIMIT[plan];
export const getOveragePriceForPlan = (plan) => OVERAGE_PRICE[plan];

export const DEFAULT_SUBSCRIPTION_STARTER_TIER = SUBSCRIPTION_TIER.tier_19;

export const DEFAULT_DD_TEAMS_TIER = SUBSCRIPTION_SEAT_TIER.seats_3;

export const PLAN_CATEGORIES = {
  mostBusinesses: 'most_businesses',
  smallBusinesses: 'small_businesses',
};

export const DETAILS_TABLE_ICONS = {
  check: '[check]',
  minus: '—',
};

export const getPlanCategoryByCompanySize = (companySize) => {
  switch (companySize) {
    case COMPANY_SIZE.size_1:
    case COMPANY_SIZE.size_2_10:
    case COMPANY_SIZE.size_11_50:
      return PLAN_CATEGORIES.smallBusinesses;
    default:
      return PLAN_CATEGORIES.mostBusinesses;
  }
};
