import { readItem } from 'src/lib/storage';
import { almNamespace } from 'src/utils/almNamespace';
import { developmentMode } from './localData';

/**
 * Read environment variable
 * Note: Environment variables overrides may be passed through "window.env" object
 *
 * @param {string} varName - variable name
 * @param {string} defaultValue - default value
 * @returns {string} - variable value
 */
const readVariable = (varName, defaultValue) => {
  if (window.env && window.env[varName]) {
    return window.env[varName];
  }
  if (window.Cypress && window.Cypress.env(varName)) {
    return window.Cypress.env(varName);
  }
  return defaultValue;
};

export function getAppEnv() {
  return readVariable('REACT_APP_ENV', process.env.REACT_APP_ENV);
}

export function getBackendUrl() {
  let reactAppBackendURL = getBackendRootUrl();
  if (process.env.REACT_APP_ENV === 'development' || process.env.NODE_ENV === 'development') {
    //get override from local storage
    const overrideURL = readItem('BE_LOCAL_OVERRIDE');
    if (overrideURL) {
      console.log('Backend url was override & set to:', overrideURL);
      reactAppBackendURL = overrideURL;
    }
  }
  return reactAppBackendURL + '/content_creator_platform';
}

export function getBackendRootUrl() {
  return readVariable('REACT_APP_BACKEND', process.env.REACT_APP_BACKEND);
}

export function getInfinityExtensionID() {
  return readVariable(
    'REACT_APP_INFINITY_EXTENSION_ID',
    process.env.REACT_APP_INFINITY_EXTENSION_ID
  );
}

export function getFacebookAppID() {
  return readVariable('REACT_APP_FACEBOOK_APP_ID', process.env.REACT_APP_FACEBOOK_APP_ID);
}

export function getSentryDSN() {
  return readVariable('REACT_APP_SENTRY_DSN', process.env.REACT_APP_SENTRY_DSN);
}

export function getUnsplashID() {
  return readVariable('REACT_APP_UNSPLASH_ID', process.env.REACT_APP_UNSPLASH_ID);
}

export function getMixpanelToken() {
  return readVariable('REACT_APP_MIXPANEL_TOKEN', process.env.REACT_APP_MIXPANEL_TOKEN);
}

export function getStripeApiKey() {
  return readVariable('REACT_APP_STRIPE_API_KEY', process.env.REACT_APP_STRIPE_API_KEY);
}

export function getChurnkeyAppId() {
  return readVariable('REACT_APP_CHURNKEY_APP_ID', process.env.REACT_APP_CHURNKEY_APP_ID);
}

export function getChurnkeyMode() {
  return readVariable('REACT_APP_CHURNKEY_MODE', process.env.REACT_APP_CHURNKEY_MODE);
}

export function getWebsiteOrigin() {
  return readVariable('REACT_APP_WEBSITE_ORIGIN', process.env.REACT_APP_WEBSITE_ORIGIN);
}

export function getLogRocketAppId() {
  return readVariable('REACT_APP_LOG_ROCKET_APP_ID', process.env.REACT_APP_LOG_ROCKET_APP_ID);
}

/**
 * Check if the app is running in development mode
 * @returns {boolean}
 */
export const inDevelopmentMode = () => {
  try {
    if (developmentMode.read()) {
      return true;
    }
  } catch (e) {
    // do  nothing
  }

  return getAppEnv() === 'development';
};

/**
 * Enable development mode
 */
export const enableDevelopmentMode = () => {
  try {
    developmentMode.write(true);
  } catch (e) {
    // do nothing
  }
};

/**
 * Disable development mode
 */
export const disableDevelopmentMode = () => {
  try {
    developmentMode.remove();
  } catch (e) {
    // do nothing
  }
};

almNamespace.add('developmentMode', {
  enableDevelopmentMode,
  disableDevelopmentMode,
});
