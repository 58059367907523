import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query VerifySSOEmail($email: String!) {
    verifySsoEmail(email: $email) {
      valid
    }
  }
`;

export const verifySSOEmail = (email) =>
  executeGqlQuery({
    query: QUERY,
    variables: { email },
  }).then(({ data }) => getSingleProp(data));
