import { get } from 'lodash';

export const INSIGHTS_INTERVAL_DAYS = {
  today: 'today',
  yesterday: 'yesterday',
  oneWeeks: 'one_weeks',
  allTime: 'all_time',
};

export const DEFAULT_INSIGHTS_INTERVAL_DAYS = INSIGHTS_INTERVAL_DAYS.allTime;

const INSIGHTS_INTERVAL_DAYS_PROPS = {
  [INSIGHTS_INTERVAL_DAYS.today]: {
    label: 'Published today',
    value: 0,
  },
  [INSIGHTS_INTERVAL_DAYS.yesterday]: {
    label: 'Published yesterday',
    value: 1,
  },
  [INSIGHTS_INTERVAL_DAYS.oneWeeks]: {
    label: 'Published in the last 7 days',
    value: 7,
  },
  [INSIGHTS_INTERVAL_DAYS.allTime]: {
    label: 'Published all time',
    value: null,
  },
};

export const getInsightsIntervalDaysLabel = (insightsIntervalDays) =>
  get(INSIGHTS_INTERVAL_DAYS_PROPS, [insightsIntervalDays, 'label']) || '';

export const getInsightsIntervalDaysValue = (insightsIntervalDays) =>
  get(INSIGHTS_INTERVAL_DAYS_PROPS, [insightsIntervalDays, 'value']);

export const getInsightsIntervalDaysByValue = (value) =>
  Object.values(INSIGHTS_INTERVAL_DAYS).find(
    (insightsIntervalDays) => value === getInsightsIntervalDaysValue(insightsIntervalDays)
  );
