import { getAssetTypeMaxCopyLength } from 'src/data/asset';
import {
  GENERATION_TOOL_GROUP,
  getGroupByTool,
  getToolDefaultAssetType,
  getToolLabel,
} from 'src/data/generationTool';
import { sendMessageToExtension } from 'src/lib/extensions';
import { sendMessageToParent } from 'src/lib/messages';
import * as env from 'src/lib/env';
import {
  messageSubmitInstructions,
  externalMessageLogin,
  externalMessageLogout,
  externalMessageWorkspaceData,
} from './messages';
import { formatGenerationPrompt } from './prompt';

/**
 * Send generated from params prompt to extension, asking it to replace current "prompt input" value
 * @param {object} params - Generation params
 * @param {string} visibility - Prompt visibility
 */
export const sendFullPromptToInfinityExtension = ({ params, visibility }) => {
  const text = formatGenerationPrompt(params);
  const message = messageSubmitInstructions({
    type: 'prompt',
    params: prepareGenerationParams(params),
    visibility,

    // 2023-07-17: deprecated, remove after Extension 0.0.26 is not in use anymore
    text,
    name: getToolLabel(params?.generationTool),
    generationTool: params?.generationTool,
    generationToolGroup: getGroupByTool(params?.generationTool),
  });
  sendMessageToParent(message);
};

export const syncAuthTokenToExternalExtension = (authToken = null) => {
  const extensionId = env.getInfinityExtensionID();
  const message = authToken ? externalMessageLogin(authToken) : externalMessageLogout();
  return sendMessageToExtension(extensionId, message);
};

export const activateWorkspaceInExternalExtension = (customerId, workspaceId) => {
  const extensionId = env.getInfinityExtensionID();
  const message = externalMessageWorkspaceData({ customerId, workspaceId });
  return sendMessageToExtension(extensionId, message);
};

function prepareGenerationParams(params) {
  const result = { ...params };

  result.generationToolLabel = getToolLabel(result.generationTool);
  result.generationToolGroup = getGroupByTool(result.generationTool);
  result.assetType = getToolDefaultAssetType(result.generationTool);

  // TODO: fix for ALM-5989, consider removing limit for all tools without the "strict" limit
  if (result.generationToolGroup !== GENERATION_TOOL_GROUP.social) {
    result.maxAssetTypeLength = getAssetTypeMaxCopyLength(result.assetType);
  }

  return result;
}
