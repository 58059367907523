import gql from 'graphql-tag';

import { FRAGMENT_AB_TEST } from 'src/graphql/schema';

export const CUSTOMER_FRAGMENT = gql`
  fragment Customer on Customer {
    id
    name
    lifecycle
  }
`;

export const CUSTOMER_QUERY_QUOTA_FRAGMENT = gql`
  fragment CustomerQueryQuota on CustomerQueryQuota {
    planWordsUsed
    planWordsLimit
    overageWordsUsed
    overageWordsLimit
  }
`;
export const CUSTOMER_BILLING_PERIOD_FRAGMENT = gql`
  fragment CustomerBillingPeriod on CustomerBillingPeriod {
    start
    end
  }
`;
export const CUSTOMER_BILLING_METHOD_FRAGMENT = gql`
  fragment CustomerBillingMethod on CustomerBillingMethod {
    type
    subscriptionStatus
  }
`;
export const CUSTOMER_PAYMENT_METHOD_FRAGMENT = gql`
  fragment CustomerPaymentMethod on CustomerPaymentMethod {
    id: stripeId
    card {
      brand
      last4
      expYear
      expMonth
    }
    billingDetails {
      name
      email
      address {
        city
        state
        country
        line1
        postalCode
      }
    }
  }
`;

export const FRAGMENT_FEATURE_LIMIT = gql`
  fragment FeatureLimit on FeatureLimit {
    name
    limit
    currentCount
    adminUpdated
  }
`;

export const FRAGMENT_ROWS_ALLOCATION = gql`
  fragment RowsAllocationPerChannel on RowsAllocationPerChannel {
    channel
    accountId
    rowsCount
    rowsAllocated
    name
  }
`;

export const CUSTOMER_BILLING_CONFIGURATION_FRAGMENT = gql`
  fragment CustomerBillingConfiguration on CustomerBillingConfiguration {
    plan
    wordsTier
    queryQuota {
      ...CustomerQueryQuota
    }
    currentPeriod {
      ...CustomerBillingPeriod
    }
    method {
      ...CustomerBillingMethod
    }
    planChangeRequest {
      plan
      wordsTier
      periodStartsOn
    }
    appliedPromotionCode {
      durationInMonths
      percentOff
      startAt
      endAt
    }
    cancelAtPeriodEnd
    billingCyclePeriod
    billingPeriod {
      ...CustomerBillingPeriod
    }
    fullPlanCostUsd
    subscriptionConfig {
      stripeId
      pauseStartAt
      pauseEndAt
    }
  }
  ${CUSTOMER_QUERY_QUOTA_FRAGMENT}
  ${CUSTOMER_BILLING_PERIOD_FRAGMENT}
  ${CUSTOMER_BILLING_METHOD_FRAGMENT}
`;

export const FRAGMENT_CUSTOMER_SETTINGS = gql`
  fragment CustomerSetting on CustomerSetting {
    blacklistWords
  }
`;

export const CUSTOMER_FULL_FRAGMENT = gql`
  fragment CustomerFull on Customer {
    ...Customer
    maxMembers
    segment
    isKeywee
    featureFlags
    featureAccess
    inRetrial
    registeredBeforeBasicTierPlan
    planMigrationStatus {
      acceptStatus
      gracePeriodEndsOn
      oldPlan
      oldTier
    }
    contentTaxonomy {
      id
      name
    }
    billingConfiguration {
      ...CustomerBillingConfiguration
    }
    paymentMethod @include(if: $includePaymentMethod) {
      ...CustomerPaymentMethod
    }
    settings {
      ...CustomerSetting
    }
    abTests {
      ...AbTest
    }
    churnkeyHash
    stripeCustomerId
    privacySettings {
      trainingDataEnabled
      externalLlmEnabled
      externalLanguageTranslationEnabled
      externalPlagiarismDetectionEnabled
      externalWebSearchEnabled
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${CUSTOMER_BILLING_CONFIGURATION_FRAGMENT}
  ${CUSTOMER_PAYMENT_METHOD_FRAGMENT}
  ${FRAGMENT_CUSTOMER_SETTINGS}
  ${FRAGMENT_AB_TEST}
`;

export const CUSTOMER_MEMBER_FRAGMENT = gql`
  fragment CustomerMember on CustomerMember {
    id
    email
    name
    role
    pending
    defaultWorkspaceId
  }
`;
