import { useEffect } from 'react';

import { useBriefsActions } from 'src/store';
import { useErrorHandler } from 'src/services';

export const usePreloadBrandBriefs = (brandVoice) => {
  const handleError = useErrorHandler();
  const briefsActions = useBriefsActions();

  useEffect(() => {
    const fetchBriefs = async () => {
      try {
        await briefsActions.queryBrandBriefs(brandVoice.id);
      } catch (error) {
        handleError(error);
      }
    };

    if (brandVoice?.id) {
      fetchBriefs();
    }
  }, [brandVoice, handleError, briefsActions]);
};
