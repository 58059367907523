import { NOTIFICATION_LEVEL } from 'src/data/notifications';

export const levelToSeverity = (level) => {
  switch (level) {
    case NOTIFICATION_LEVEL.success:
      return 'success';
    case NOTIFICATION_LEVEL.warning:
      return 'warning';
    case NOTIFICATION_LEVEL.error:
      return 'error';
    case NOTIFICATION_LEVEL.generic:
    default:
      return 'info';
  }
};
