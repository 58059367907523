import { createStateSelectorHook } from 'src/lib/sweetState';
import { OnboardingStore } from 'src/store';

const useOnboardingState = createStateSelectorHook(OnboardingStore, [
  'isActive',
  'currentStepId',
  'currentStepIndex',
  'stepsCount',
  'isLastStepActive',
]);

export const useViewState = () => {
  const onboardingState = useOnboardingState();

  return {
    ...onboardingState,
  };
};
