import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query StripeInvoicePage($stripeInvoiceId: ID!) {
    stripeInvoicePage(stripeInvoiceId: $stripeInvoiceId) {
      url
    }
  }
`;

export function queryStripeInvoicePage(stripeInvoiceId) {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      stripeInvoiceId,
    },
  }).then(({ data }) => getSingleProp(data));
}
