import React from 'react';
import PropTypes from 'prop-types';
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

const generateClassName = createGenerateClassName({
  disableGlobal: process.env.REACT_APP_ENV !== 'development',
});

const propTypes = {
  theme: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

const ThemeAndStylesProvider = ({ theme, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider generateClassName={generateClassName}>
        <CssBaseline />

        {children}
      </StylesProvider>
    </ThemeProvider>
  );
};

ThemeAndStylesProvider.propTypes = propTypes;
ThemeAndStylesProvider.defaultProps = defaultProps;

export default ThemeAndStylesProvider;
