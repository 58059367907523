import React from 'react';

import {
  DEFAULT_SUGGEST_TOV_MODE,
  MANUAL_TONE_OF_VOICE_PROPS,
  getToneOfVoiceTitle,
  formatToneOfVoiceDescription,
  isPresetTov,
} from 'src/data/toneOfVoice';
import { CustomTag } from 'src/components/CustomTag';
import { BaseItem } from '../BaseItem';
import { useStyles } from './useStyles';

export const TovItem = ({ tov, customModelName, vertical, multiline, classes }) => {
  const styles = useStyles();

  if (!tov.customName && !tov.defaultPreset && !tov.manualAttributes && !tov.draftTov) {
    return (
      <BaseItem
        label="Tone of voice"
        vertical={vertical}
        classes={{ ...classes, body: styles.customModelBodyItem }}
        hideTitleColon={multiline}
      >
        {customModelName || DEFAULT_SUGGEST_TOV_MODE}{' '}
        {customModelName && (
          <CustomTag
            className={styles.customModelTag}
            tag="Custom model"
          />
        )}
      </BaseItem>
    );
  }

  return (
    <BaseItem
      label="Tone of voice"
      vertical={vertical}
      classes={{ ...classes, body: styles.body }}
      hideTitleColon={multiline}
    >
      {tov.draftTov && formatToneOfVoiceDescription(tov.draftTov, true)}
      {tov.customName && <>{tov.customName}</>}
      {isPresetTov(tov.defaultPreset) && <>{getToneOfVoiceTitle(tov.defaultPreset)}</>}
      {tov.manualAttributes && (
        <>
          {tov.manualAttributes
            ?.map((manualTov) => MANUAL_TONE_OF_VOICE_PROPS[manualTov]?.title)
            .join(', ')}
        </>
      )}
    </BaseItem>
  );
};
