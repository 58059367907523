import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconEmail = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 5.66602C2.05228 5.66602 2.5 6.11373 2.5 6.66602V17.3327C2.5 17.7797 2.67497 18.2054 2.9815 18.5168C3.2875 18.8276 3.69915 18.9993 4.125 18.9993H19.875C20.3008 18.9993 20.7125 18.8276 21.0185 18.5168C21.325 18.2054 21.5 17.7797 21.5 17.3327V6.66602C21.5 6.11373 21.9477 5.66602 22.5 5.66602C23.0523 5.66602 23.5 6.11373 23.5 6.66602V17.3327C23.5 18.3001 23.1218 19.231 22.4438 19.9198C21.7652 20.6091 20.8415 20.9993 19.875 20.9993H4.125C3.15846 20.9993 2.23475 20.6091 1.55619 19.9198C0.878154 19.231 0.5 18.3001 0.5 17.3327V6.66602C0.5 6.11373 0.947715 5.66602 1.5 5.66602Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.55619 4.07953C2.23476 3.3902 3.15846 3 4.125 3H19.875C20.8415 3 21.7652 3.3902 22.4438 4.07953C23.1218 4.76833 23.5 5.69924 23.5 6.66667C23.5 7.00909 23.3248 7.3277 23.0356 7.51111L13.9273 13.2887C13.3507 13.6547 12.6826 13.8498 12 13.8498C11.3174 13.8498 10.6497 13.6549 10.0731 13.2889L0.964359 7.51111C0.675204 7.3277 0.5 7.00909 0.5 6.66667C0.5 5.69924 0.878154 4.76833 1.55619 4.07953ZM4.125 5C3.69915 5 3.2875 5.17171 2.9815 5.48257C2.7931 5.67395 2.6544 5.90849 2.57548 6.16464L11.1444 11.6C11.4021 11.7636 11.6988 11.8498 12 11.8498C12.3012 11.8498 12.5975 11.7639 12.8552 11.6002L21.4245 6.16464C21.3456 5.9085 21.2069 5.67395 21.0185 5.48257C20.7125 5.17171 20.3008 5 19.875 5H4.125Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);
