import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

import { CUSTOMER_PERSONA_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation CreateCustomerPersona(
    $brandVoiceId: ID!
    $name: String!
    $minAge: Int!
    $maxAge: Int!
    $gender: GenderEnum!
    $painPoints: [String!]!
    $avatarType: String
  ) {
    createCustomerPersona(
      brandVoiceId: $brandVoiceId
      name: $name
      minAge: $minAge
      maxAge: $maxAge
      gender: $gender
      painPoints: $painPoints
      avatarType: $avatarType
    ) {
      ...CustomerPersona
    }
  }
  ${CUSTOMER_PERSONA_FRAGMENT}
`;

export const createCustomerPersona = ({
  brandVoiceId,
  name,
  minAge,
  maxAge,
  gender,
  painPoints,
  avatarType,
}) => {
  const variables = {
    brandVoiceId,
    name,
    minAge,
    maxAge,
    gender,
    painPoints,
    avatarType,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
