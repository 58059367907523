import React from 'react';
import PropTypes from 'prop-types';
import { TableSortLabel } from '@material-ui/core';

import { IconTableSort } from 'src/assets/icons/IconTableSort';
import { useStyles } from './useStyles';

export const CustomTableSortLabel = ({ classes, ...restProps }) => {
  const styles = useStyles({ classes });

  return (
    <TableSortLabel
      classes={styles}
      IconComponent={IconTableSort}
      {...restProps}
    />
  );
};

CustomTableSortLabel.propTypes = {
  classes: PropTypes.shape({}),
};
CustomTableSortLabel.defaultProps = {
  classes: null,
};
