import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_CO_CAMPAIGN } from 'src/graphql/continuousOptimization';

const QUERY = gql`
  query ProjectOptimizationData($projectId: Int!) {
    project(id: $projectId) {
      id

      # "last used asset type" detection
      generationRequests(first: 1) {
        edges {
          node {
            ... on RecomposeRequest {
              assetType
              language
              briefTitle
              briefBody
              emailPromotion
            }
            ... on SuggestionRequest {
              assetType
              language
              briefTitle
              briefBody
            }
          }
        }
      }

      continuousOptimizationCampaign {
        ...ContinuousOptimizationCampaign
      }
    }
  }
  ${FRAGMENT_CO_CAMPAIGN}
`;

export const queryProjectOptimizationData = (projectId, options) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
    },
    options: {
      abortSignal: options?.abortSignal,
    },
  }).then(({ data }) => getSingleProp(data));
