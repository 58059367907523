import { DEFAULT_SPOTLIGHT_PADDING } from './const';

export function expandPadding(rawPadding) {
  if (rawPadding && typeof rawPadding === 'object') {
    return {
      top: DEFAULT_SPOTLIGHT_PADDING,
      bottom: DEFAULT_SPOTLIGHT_PADDING,
      left: DEFAULT_SPOTLIGHT_PADDING,
      right: DEFAULT_SPOTLIGHT_PADDING,
      ...rawPadding,
    };
  }

  return {
    top: rawPadding != null ? rawPadding : DEFAULT_SPOTLIGHT_PADDING,
    bottom: rawPadding != null ? rawPadding : DEFAULT_SPOTLIGHT_PADDING,
    left: rawPadding != null ? rawPadding : DEFAULT_SPOTLIGHT_PADDING,
    right: rawPadding != null ? rawPadding : DEFAULT_SPOTLIGHT_PADDING,
  };
}
