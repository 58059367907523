import { BILLING_CYCLES } from './const';

const BILLING_CYCLE_LABEL = {
  [BILLING_CYCLES.monthly]: 'Monthly',
  [BILLING_CYCLES.annually]: 'Yearly',
};

export const getBillingCycleLabel = (cycle) => BILLING_CYCLE_LABEL[cycle] || cycle;

export const clampBillingCycle = (cycle, defaultValue = BILLING_CYCLES.monthly) => {
  const values = Object.values(BILLING_CYCLES);
  if (values.includes(cycle)) {
    return cycle;
  }
  return defaultValue;
};
