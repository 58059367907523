import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography, useTheme } from '@material-ui/core';

import { TYPOGRAPHY_VARIANT } from './const';
import { useStyles } from './useStyles';

export const CustomTypography = forwardRef(({ className, variant, ...restProps }, ref) => {
  const styles = useStyles({ variant });
  const theme = useTheme();

  if (process.env.NODE_ENV !== 'production') {
    if (!variant) {
      console.error(`CustomTypography: required "variant" property is not defined`);
    }

    if (theme.typography[variant] === undefined) {
      console.error(
        `CustomTypography: unknown variant "${variant}", cannot find any options for it in "theme.typography"`
      );
    }
  }

  return (
    <Typography
      className={cx(styles.root, className)}
      ref={ref}
      {...restProps}
    />
  );
});

CustomTypography.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(TYPOGRAPHY_VARIANT)),
};

CustomTypography.defaultProps = {
  className: null,
  variant: TYPOGRAPHY_VARIANT.regular14,
};
