import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { MenuItem } from '@material-ui/core';

export const ItemPlaceholder = forwardRef(({ className, item }, ref) => (
  <MenuItem
    className={cx(className, 'text-xs leading-6 font-normal')}
    ref={ref}
    disabled
  >
    {item.label}
  </MenuItem>
));

ItemPlaceholder.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
};

ItemPlaceholder.defaultProps = {
  className: '',
};
