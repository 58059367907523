import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation DeleteProjectFolder($id: ID!, $userApproved: Boolean!) {
    deleteProjectFolder(id: $id, userApproved: $userApproved) {
      id
    }
  }
`;

export const deleteProjectFolder = ({ id, userApproved }) => {
  const variables = {
    id,
    userApproved,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
