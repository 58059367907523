import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Contact = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 24 24" data-fill-none>
    <path
      d="M18.6353 7.31332C18.6078 7.28251 18.576 7.25585 18.5409 7.23417C18.3453 7.08316 18.1064 7.00095 17.8605 7H6.13953C5.8936 7.00095 5.65468 7.08316 5.45907 7.23417C5.42403 7.25585 5.39223 7.28251 5.36465 7.31332C5.24966 7.42027 5.15787 7.55025 5.0951 7.69503C5.03233 7.83981 4.99994 7.99622 5 8.15435V15.8456C5 16.1518 5.12006 16.4454 5.33376 16.6619C5.54747 16.8784 5.83731 17 6.13953 17H17.8605C18.1627 17 18.4525 16.8784 18.6662 16.6619C18.8799 16.4454 19 16.1518 19 15.8456V8.15435C19.0001 7.99622 18.9677 7.83981 18.9049 7.69503C18.8421 7.55025 18.7503 7.42027 18.6353 7.31332ZM16.9847 7.98945L12 11.5943L7.01535 7.98945H16.9847ZM17.8605 16.0106H6.13953C6.09636 16.0106 6.05495 15.9932 6.02442 15.9623C5.9939 15.9313 5.97674 15.8894 5.97674 15.8456V8.45449L11.7167 12.6036C11.7993 12.6637 11.8983 12.696 12 12.696C12.1017 12.696 12.2007 12.6637 12.2833 12.6036L18.0233 8.45449V15.8456C18.0233 15.8894 18.0061 15.9313 17.9756 15.9623C17.945 15.9932 17.9036 16.0106 17.8605 16.0106Z"
      fill="currentColor"
    />
  </SvgIcon>
));

export default React.memo(Contact);
