import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      border: `1px solid ${theme.palette.dividerLightBlue}`,
      borderRadius: theme.typography.borderRadiusMedium,
      padding: 0,

      '&$dark': {
        backgroundColor: theme.palette.darkSelected,

        '& $input_input': {
          color: theme.palette.common.white,
        },
      },
    },
    dark: {},

    input_input: {
      height: '40px',
      width: '100%',
      padding: theme.spacing(1.5),
      boxSizing: 'border-box',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      borderRadius: theme.typography.borderRadiusMedium,

      '&::placeholder': {
        color: theme.palette.text.secondary,
        fontSize: 'inherit',
      },
    },
    input_fieldset: {
      border: 'none',
    },
    inputAdornment: {
      marginLeft: 0,
    },
    iconClose: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,
    },
    iconSearch: {
      fontSize: theme.typography.pxToRem(20),
      color: COLORS.neutral.mediumGray,
      marginLeft: 10,
    },
  }),
  { name: 'SearchMenuItem' }
);
