import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ExtractBlogDescriptionFromUrl($url: String!, $projectId: ID!) {
    extractBlogDescriptionFromUrl(url: $url, projectId: $projectId) {
      id
    }
  }
`;

export const extractBlogDescriptionFromURL = ({ url, projectId }) => {
  const variables = {
    url,
    projectId,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
