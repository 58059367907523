import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CO_ASSET } from './schema';

const MUTATION = gql`
  mutation CreateCOAsset($projectId: ID!, $cssSelector: String!, $segmentId: ID) {
    createContinuousOptimizationLandingPageAsset(
      projectId: $projectId
      cssSelector: $cssSelector
      continuousOptimizationSegmentId: $segmentId
    ) {
      ...LandingPageAsset
    }
  }
  ${FRAGMENT_CO_ASSET}
`;

export function createCOAsset({ projectId, cssSelector, segmentId }) {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { projectId: String(projectId), cssSelector, segmentId },
  }).then(({ data }) => getSingleProp(data));
}
