import { Editor as SlateEditor, Range as SlateRange, Transforms as SlateTransforms } from 'slate';
import { ReactEditor } from 'slate-react';

export const withPreviousSelection = (editor) => {
  editor.updatePreviousSelection = (selection) => {
    // Do not update selection if editor is read-only
    if (ReactEditor.isReadOnly(editor)) {
      return;
    }

    // Delete previous selection reference
    if (editor.previousSelectionRef) {
      editor.previousSelectionRef.unref();
    }

    // Remember current selection
    if (selection) {
      editor.previousSelectionRef = SlateEditor.rangeRef(editor, selection, {
        affinity: 'outward',
      });
    }
  };

  editor.restoreSelectionFromPrevious = () => {
    const hasExpandedSelection = editor.selection && SlateRange.isExpanded(editor.selection);

    // Restore selection from previous blur event
    const { previousSelectionRef } = editor;
    if (previousSelectionRef) {
      if (previousSelectionRef?.current) {
        const previousSelection = previousSelectionRef.current;
        if (!hasExpandedSelection) {
          SlateTransforms.select(editor, previousSelection);
        }
      }
    }
  };

  editor.clearPreviousSelection = () => {
    if (editor.previousSelectionRef) {
      editor.previousSelectionRef.unref();
    }
  };

  editor.getPreviousSelection = () => {
    return editor.previousSelectionRef?.current;
  };

  editor.getAvailableSelection = () => {
    return ReactEditor.isFocused(editor) ? editor.selection : editor.getPreviousSelection();
  };

  return editor;
};
