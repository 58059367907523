import { useCallback } from 'react';

import { usePopups } from '../services';
import { GENERATION_TOOL_POPUP } from '../types';

export const useGenerationToolPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ onSubmit, onCancel, selectedTabType, selectedToolGroup, withBlog }) => {
      popups.openPopup(GENERATION_TOOL_POPUP, {
        onSubmit: (result) => {
          popups.closePopup(GENERATION_TOOL_POPUP);
          onSubmit(result);
        },
        onClose: () => {
          popups.closePopup(GENERATION_TOOL_POPUP);
          if (onCancel) onCancel();
        },
        selectedTabType,
        selectedToolGroup,
        withBlog,
      });
    },
    [popups]
  );
};
