import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const UPDATE_TIMEOUT = 300;

export const useDebouncedLoading = (loading) => {
  const [debouncedValue, setDebouncedValue] = useState(false);

  const updateToFalse = useDebouncedCallback(() => {
    setDebouncedValue(false);
  }, UPDATE_TIMEOUT);

  useEffect(() => {
    // Set loading state immediately
    if (loading) {
      updateToFalse.cancel();
      setDebouncedValue(true);
      return;
    }

    updateToFalse();
  }, [updateToFalse, loading]);

  return debouncedValue;
};
