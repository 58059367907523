import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOMER_RESOURCE } from './schema';

const MUTATION = gql`
  mutation UpdateResource(
    $id: ID!
    $customerId: ID!
    $input: PerformanceCenterCustomerResourceUpdateInputType!
  ) {
    updateResource(id: $id, customerId: $customerId, input: $input) {
      ...PerformanceCenterCustomerResource
    }
  }
  ${FRAGMENT_CUSTOMER_RESOURCE}
`;

export const updateResource = ({ id, customerId, input }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { id, customerId, input },
  }).then(({ data }) => getSingleProp(data));
};
