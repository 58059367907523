import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(2.5, 4),
      borderBottom: `1px solid ${COLORS.gray.gray80}`,
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightBold,
      color: COLORS.gray.black,
    },
    buttonClose: {
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      position: 'absolute',
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
    },
  }),
  { name: 'PopupHeader' }
);
