import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'start',
      gap: theme.spacing(1),
    },
    label: {
      color: COLORS.gray.gray30,
    },
    toneOfVoiceNameButton: {
      textAlign: 'left',
      display: 'inline-block',
      color: COLORS.gray.gray30,
      textDecoration: 'underline',
      ...theme.typography.paragraph14,
      '&:hover': {
        color: COLORS.gray.black,
      },
    },
    icon: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: theme.spacing(0.25),
    },
  }),
  { name: 'SuccessMessage' }
);
