import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { CUSTOMER_FRAGMENT } from 'src/graphql/customer';
import { BRIEF_FRAGMENT } from 'src/graphql/brief';
import { PROJECT_FRAGMENT } from './schema';

const QUERY = gql`
  query Project($projectId: Int!) {
    project(id: $projectId) {
      ...Project

      customer {
        ...Customer
      }

      brief {
        ...Brief
      }
    }
  }
  ${PROJECT_FRAGMENT}
  ${BRIEF_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
`;

export const queryProject = (projectId, options) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
    },
    options: {
      abortSignal: options?.abortSignal,
    },
  }).then(({ data }) => getSingleProp(data));
