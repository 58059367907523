import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(1),
    },
    rect: {
      width: 260,
      height: 14,
    },
    rectSmall: {
      width: 180,
      height: 14,
    },
  }),
  { name: 'Loader' }
);
