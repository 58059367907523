import { useCallback, useState } from 'react';

import { validate } from 'src/lib/validation';
import { RULES } from 'src/lib/validation';
import { useErrorHandler } from 'src/services';

export const useRename = ({ initialName = '', onSubmit, onError }) => {
  const [name, setName] = useState(initialName);
  const [error, setError] = useState();
  const [renaming, setRenaming] = useState(false);
  const handleError = useErrorHandler();

  const handleNameChange = useCallback((e) => {
    setName(e.target.value);
    setError(null);
  }, []);

  const handleRenameClick = useCallback(async () => {
    const error = validate({ [RULES.required]: true }, name);

    if (error) {
      setError(error.message);
      return;
    }

    setRenaming(true);

    try {
      await onSubmit(name);
    } catch (e) {
      if (onError) {
        onError(e);
      } else {
        handleError(e);
      }
    } finally {
      setRenaming(false);
    }
  }, [name, handleError, onSubmit, onError]);

  return {
    handleNameChange,
    handleRenameClick,
    name,
    error,
    renaming,
  };
};
