import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { WORKSPACE_FRAGMENT } from './schema';

const QUERY = gql`
  query Workspaces($customerId: ID!) {
    workspaces(customerId: $customerId) {
      edges {
        node {
          ...Workspace
        }
      }
    }
  }
  ${WORKSPACE_FRAGMENT}
`;

export const queryWorkspaces = (customerId, options = null) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      customerId,
    },
    options: {
      abortSignal: options?.abortSignal,
    },
  }).then(({ data }) => getSingleProp(data));
};
