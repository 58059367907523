import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  className: '',
  name: '',
};

export const WorkspaceNameIcon = ({ className, name }) => {
  const styles = useStyles();

  if (!name) {
    return null;
  }

  return (
    <CustomTypography
      className={cx(styles.root, className)}
      variant={TYPOGRAPHY_VARIANT.medium12}
    >
      {name.charAt(0)}
    </CustomTypography>
  );
};

WorkspaceNameIcon.displayName = 'WorkspaceNameIcon';
WorkspaceNameIcon.propTypes = propTypes;
WorkspaceNameIcon.defaultProps = defaultProps;
