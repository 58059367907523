import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(8, 5, 3),
      position: 'relative',
    },
    sorry: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: theme.spacing(0.5),
      padding: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.common.white,
      backgroundColor: COLORS.error.default,
      '& svg': {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    emptyStateTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },
    emptyStateDescription: {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
    },
  }),
  { name: 'ResourceEmptyState' }
);
