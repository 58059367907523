import { ChannelAuthError } from 'src/lib/errors';
import { openPopup, subscribeToMessages } from 'src/utils/popup';
import { randomString } from 'src/utils/random';

export const AUTHORIZATION_STATUS = {
  authorized: 'authorized',
  unauthorized: 'unauthorized',
};

const POPUP_OPTIONS = {
  name: 'linkedin-login-popup',
  width: 500,
  height: 700,
};

export const loginToLinkedin = (url) =>
  new Promise((resolve, reject) => {
    // Session id
    const state = randomString(32);
    const urlWithState = `${url}&state=${state}`;

    const resultHandler = (authData) => {
      popup.close();

      if (!authData) {
        reject();
        return;
      }

      if (authData.state !== state) {
        reject(new ChannelAuthError('Error in LinkedIn login: Invalid state'));
        return;
      }

      if (authData.code) {
        resolve(authData.code);
      } else if (authData.error) {
        reject(new ChannelAuthError(authData.error_description));
      } else {
        reject(new ChannelAuthError('Error in LinkedIn login'));
      }
    };

    // Listen for message from popup
    const unsubscribeFromMessages = subscribeToMessages((data) => {
      unsubscribeFromMessages();
      resultHandler(data);
    }, POPUP_OPTIONS);

    // Open linkedin auth popup
    const popup = openPopup(urlWithState, POPUP_OPTIONS);

    if (!popup) {
      reject(new ChannelAuthError('Could not open LinkedIn login popup'));
      unsubscribeFromMessages();
      return;
    }
    popup.focus();

    // Run a timer, to check if popup was closed by user, and hasn't sent a message
    const timer = setInterval(() => {
      if (!popup.closed) {
        return;
      }

      clearInterval(timer);
      unsubscribeFromMessages();
      resultHandler(null);
    }, 100);
  });

export const formatPostUrl = (id) => `https://www.linkedin.com/feed/update/urn:li:share:${id}`;

export const formatBusinessManagerUrl = (accountId, campaignId) =>
  `https://www.linkedin.com/campaignmanager/account/${accountId}/creatives?campaignIds=%5B${campaignId}%5D`;
