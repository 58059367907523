import { useCallback, useEffect, useMemo, useState } from 'react';
import { flatten, intersection, without } from 'lodash';

import { useResizeObserver } from 'src/lib/hooks';
import {
  EDITOR_TOOLBAR_BUTTON_SPACING,
  EDITOR_TOOLBAR_BUTTON_WIDTH,
  EDITOR_TOOLBAR_GROUP_SPACING,
} from '../../const';
import { ALWAYS_VISIBLE_ACTIONS, isTextStyleAction } from '../../lib';

const calculateButtonsInGroupWidth = (buttonsCount) =>
  buttonsCount * EDITOR_TOOLBAR_BUTTON_WIDTH + (buttonsCount - 1) * EDITOR_TOOLBAR_BUTTON_SPACING;

const getVisibleButtonGroupsCount = ({
  buttonGroups,
  alwaysVisibleActionsCount,
  toolbarWrapperWidth,
}) => {
  // Calculate how much free space is available for "visible" buttons
  const undoRedoGroupWidth =
    calculateButtonsInGroupWidth(alwaysVisibleActionsCount) + EDITOR_TOOLBAR_GROUP_SPACING;
  const showMoreGroupWidth = calculateButtonsInGroupWidth(1) + EDITOR_TOOLBAR_GROUP_SPACING;

  // Calculate how much space we would need for the whole toolbar
  const maxRequiredWidth = buttonGroups.reduce((acc, group, index) => {
    const groupHasLeftSpacing = index > 0;
    const groupWidth =
      calculateButtonsInGroupWidth(group.length) +
      (groupHasLeftSpacing ? EDITOR_TOOLBAR_GROUP_SPACING : 0);
    return acc + groupWidth;
  }, 0);

  if (maxRequiredWidth <= toolbarWrapperWidth) {
    return buttonGroups.length;
  }

  // Calculate how much free space is available for "visible" buttons
  let availableWidthForButtons = toolbarWrapperWidth - undoRedoGroupWidth - showMoreGroupWidth;

  let visibleGroupsCounter = 0;
  for (let i = 0; i < buttonGroups.length; i++) {
    const groupHasLeftSpacing = i > 0;
    const groupWidth =
      calculateButtonsInGroupWidth(buttonGroups[i].length) +
      (groupHasLeftSpacing ? EDITOR_TOOLBAR_GROUP_SPACING : 0);

    if (availableWidthForButtons >= groupWidth) {
      availableWidthForButtons -= groupWidth;
      visibleGroupsCounter += 1;
    } else {
      // If there is no space for the next group, stop the loop
      break;
    }
  }

  return visibleGroupsCounter;
};

const groupsToButtons = (groups, availableActions) => {
  const buttons = flatten(groups);
  return intersection(buttons, availableActions);
};

/**
 * Take all actions except "always visible" actions, and possibly "text style dropdown" actions
 */
const filterActions = (allActions, displayTextStyleDropdown) => {
  const actions = without(allActions, ...ALWAYS_VISIBLE_ACTIONS);
  if (displayTextStyleDropdown) {
    return actions.filter((action) => !isTextStyleAction(action));
  }
  return actions;
};

/**
 * Filter groups to contain only visible actions
 */
const filterGroups = (groups, availableActions) => {
  return groups
    .map((groupActions) => intersection(groupActions, availableActions))
    .filter((groupActions) => groupActions.length > 0);
};

export const useResponsiveFormatButtons = ({
  groupedActions,
  wrapperElement,
  displayTextStyleDropdown,
}) => {
  // All actions flattened to a single array
  const allActions = useMemo(() => flatten(groupedActions), [groupedActions]);
  // All actions except "always visible" actions, and possibly "text style dropdown" actions
  const hideableActions = useMemo(
    () => filterActions(allActions, displayTextStyleDropdown),
    [allActions, displayTextStyleDropdown]
  );

  const [visibleGroups, setVisibleGroups] = useState(() =>
    filterGroups(groupedActions, hideableActions)
  );
  const [hiddenActions, setHiddenActions] = useState([]);

  const updateButtonsLayout = useCallback(
    (element) => {
      // If container element is not available yet, take some default width
      const availableWidth = element?.clientWidth || 1000;

      // Determine how many "non-hideable" buttons should be displayed
      const alwaysVisibleActionsCount = intersection(allActions, ALWAYS_VISIBLE_ACTIONS).length;

      const visibleButtonGroupsCount = getVisibleButtonGroupsCount({
        buttonGroups: groupedActions,
        toolbarWrapperWidth: availableWidth,
        alwaysVisibleActionsCount,
      });

      const visibleButtonGroups = groupedActions.slice(0, visibleButtonGroupsCount);
      setVisibleGroups(filterGroups(visibleButtonGroups, hideableActions));

      const hiddenButtonGroups = groupedActions.slice(visibleButtonGroupsCount);
      setHiddenActions(groupsToButtons(hiddenButtonGroups, hideableActions));
    },
    [allActions, groupedActions, hideableActions]
  );

  // Refresh button layout is case wrapper or available buttons changed
  useEffect(() => {
    updateButtonsLayout(wrapperElement);
  }, [wrapperElement, updateButtonsLayout]);

  // Refresh button layout is case wrapper changed its size
  useResizeObserver(wrapperElement, (element) => {
    updateButtonsLayout(element);
  });

  return { allActions, visibleGroups, hiddenActions };
};
