import { useEffect } from 'react';

import * as events from 'src/lib/events';
import { useHistory } from 'src/lib/hooks/useHistory';
import { ROUTES } from 'src/routes';
import { useUserActions, useCurrentUser } from 'src/store/user';
import { useTrackingEvent } from './useTrackingEvent';

export const UserGuard = () => {
  const user = useCurrentUser();
  const history = useHistory();
  const trackEvent = useTrackingEvent();
  const userActions = useUserActions();

  useEffect(() => {
    // if user loaded and has no customers -> logout
    if (user && !user.customers?.length) {
      trackEvent(events.SYSTEM.logout, {
        reason: events.LOGOUT_REASON.userGuard,
      });

      userActions.logout(true);
      // Redirect to SignIn page and ask to not go back from it
      history.push(ROUTES.SIGN_IN, { state: { disableFrom: true } });
    }
  }, [user, history, userActions, trackEvent]);

  return null;
};
