import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export default makeStyles(
  () => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    linearProgress: {
      flex: 1,
      height: 8,
      backgroundColor: COLORS.gray.gray70,
    },
    bar: {},
  }),
  { name: 'UsageProgress' }
);
