import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { COLORS } from 'src/data/ui';
import { getPolarAxisTicks, polarToCartesian } from '../../utils';

const POLYGON_COLORS = ['#F6F6F6', '#FCFCFC'];

export const CustomizedPolarGrid = ({ className, formattedGraphicalItems }) => {
  const { radiusAxis, angleAxis } = formattedGraphicalItems?.[0]?.props || {};

  const polygons = useMemo(() => {
    const radiusTicks = getPolarAxisTicks(radiusAxis).sort((a, b) => b - a);
    const angleTicks = getPolarAxisTicks(angleAxis);

    return radiusTicks.map((radius, index) => ({
      key: index,
      d: getPolygonPath(radius, radiusAxis.cx, radiusAxis.cy, angleTicks),
      fill: POLYGON_COLORS[index] || COLORS.neutral.white,
    }));
  }, [radiusAxis, angleAxis]);

  return (
    <g className={className}>
      {polygons.map((shape) => (
        <path
          key={shape.key}
          d={shape.d}
          fill={shape.fill}
        ></path>
      ))}
    </g>
  );
};

CustomizedPolarGrid.displayName = 'CustomizedDataLabels';

CustomizedPolarGrid.propTypes = {
  className: PropTypes.string,
  formattedGraphicalItems: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.shape(),
    })
  ),
};

CustomizedPolarGrid.defaultProps = {
  className: null,
  formattedGraphicalItems: [],
};

const getPolygonPath = (radius, cx, cy, polarAngles) => {
  let path = '';
  polarAngles.forEach(function (angle, i) {
    const point = polarToCartesian(cx, cy, radius, angle);
    if (i) {
      path += 'L '.concat(point.x, ',').concat(point.y);
    } else {
      path += 'M '.concat(point.x, ',').concat(point.y);
    }
  });
  path += 'Z';
  return path;
};
