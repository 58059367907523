import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  () => ({
    button: {
      flexShrink: 0,
    },
  }),
  { name: 'CommonItems' }
);
