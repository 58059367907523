import { useEffect, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { getAssetTypeMaxCopyLength, shouldAssetCheckCopyLength } from 'src/data/asset';
import { serializeSlateMarkedValue } from 'src/lib/slate';

const CALC_INTERVAL = 300;

export const useContentCounterData = (assetType, content) => {
  const [charCount, setCharCount] = useState(0);

  const calcCharCount = useThrottledCallback((value) => {
    if (!value) {
      return;
    }

    const { text } = serializeSlateMarkedValue(value);
    setCharCount(text.length);
  }, CALC_INTERVAL);

  useEffect(() => {
    calcCharCount(content);
  }, [calcCharCount, content]);

  const maxCount = getAssetTypeMaxCopyLength(assetType);
  const isStrict = shouldAssetCheckCopyLength(assetType);

  return {
    charCount,
    maxCount,
    isStrict,
    isEmpty: charCount === 0,
  };
};
