import { getUserCompanySize, getUserCountry } from 'src/data/user';
import { COMPANY_SIZE } from 'src/data/company';
import useCurrentUser from './useCurrentUser';

const ALLOWED_COUNTRIES = [
  'United States',
  'U.S. Virgin Islands',
  'Canada',
  'United Kingdom',
  'Israel',
  'Singapore',
  'Hong Kong',
  'Switzerland',
  'Sweden',
  'Norway',
  'New Zealand',
  'Australia',
  'Netherlands',
  'Luxembourg',
  'Ireland',
  'Germany',
  'France',
  'Finland',
  'Denmark',
  'Belgium',
  'Austria',
  'Spain',
  'Italy',
];

const DISALLOWED_COMPANY_SIZES = [
  COMPANY_SIZE.size_1,
  COMPANY_SIZE.size_2_10,
  COMPANY_SIZE.size_11_50,
];

export const useIsUserAllowedToBookDemo = () => {
  const currentUser = useCurrentUser();

  const companySize = getUserCompanySize(currentUser);
  if (DISALLOWED_COMPANY_SIZES.includes(companySize)) {
    return false;
  }

  const country = getUserCountry(currentUser);
  if (country) {
    return ALLOWED_COUNTRIES.includes(country);
  }
  return true;
};
