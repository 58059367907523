import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom-v5-compat';
import { Button } from '@material-ui/core';

import { ROUTES } from 'src/routes';

import { useCurrentCustomer } from 'src/store';
import { getCustomerPlan, isSelfServicePlan } from 'src/data/subscription';
import useEvents from './useEvents';
import useStyles from './useStyles';

export const ViewSubscriptionStatus = ({ className }) => {
  const styles = useStyles();
  const customer = useCurrentCustomer();
  const events = useEvents();
  const plan = getCustomerPlan(customer);
  const upgradeVisible = isSelfServicePlan(plan);

  const handleRequestUpgrade = useCallback(() => {
    events.trackPurchaseClick();
  }, [events]);

  return upgradeVisible ? (
    <Button
      className={cx(styles.root, className)}
      onClick={handleRequestUpgrade}
      component={Link}
      to={ROUTES.UPGRADE}
      variant="contained"
      color="primary"
      data-testid="viewSubscriptionStatusUpgradeButton"
    >
      Upgrade
    </Button>
  ) : null;
};

ViewSubscriptionStatus.propTypes = {
  className: PropTypes.string,
};
ViewSubscriptionStatus.defaultProps = {
  className: null,
};
