import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      boxShadow: theme.shape.boxShadow,
    },
  }),
  { name: 'Panel' }
);
