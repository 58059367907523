import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      width: 242,
      padding: theme.spacing(2, 3),
      backgroundColor: theme.palette.background.paper,
      border: theme.shape.border,
      borderRadius: 5,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',

      '&:after': {
        content: '""',
        position: 'absolute',
        top: -18,
        right: 5,
        borderWidth: 10,
        borderStyle: 'solid',
        borderColor: `transparent transparent ${COLORS.neutral.white} transparent`,
      },
    },
    title: {
      marginBottom: theme.spacing(0.75),
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(19),
      fontWeight: theme.typography.fontWeightMedium,
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(21),
      color: COLORS.neutral.mediumGray,
    },
    footer: {
      display: 'flex',
      marginTop: theme.spacing(1.5),
    },
    button: {
      height: 30,
    },
  }),
  { name: 'TooltipContentAccounts' }
);
