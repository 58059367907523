import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query AuthorCompleteTrace($id: ID!) {
    authorCompleteTrace(id: $id) {
      id
      acMachineName
      spans {
        id
        name
        data
        response
      }
    }
  }
`;

export const queryAuthorCompleteTrace = (id) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      id,
    },
  }).then(({ data }) => getSingleProp(data));
};
