import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation HubspotTrackEvent($eventName: HubspotEventsEnum!, $attributes: JSON) {
    hubspotTrackEvent(eventName: $eventName, attributes: $attributes) {
      id
    }
  }
`;

export function trackHubspotEvent(eventName, attributes) {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: {
      eventName,
      attributes: JSON.stringify(attributes),
    },
  }).then(({ data }) => getSingleProp(data));
}
