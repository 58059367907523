import { makeStyles, alpha } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      minHeight: 68,
      padding: theme.spacing(3),

      '&:hover': {
        backgroundColor: COLORS.neutral.mediumGray,
      },

      '&$expanded': {
        backgroundColor: COLORS.neutral.blackBlue,
      },
    },
    expanded: {},
    skeleton: {
      pointerEvents: 'none',
    },

    label: {
      width: '100%',
      overflow: 'hidden',
    },
    iconExpand: {
      color: alpha(COLORS.neutral.white, 0.3),
      marginRight: theme.spacing(-0.5),
      fontSize: theme.typography.pxToRem(20),
    },
  }),
  { name: 'WorkspaceButton' }
);
