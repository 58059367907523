import React from 'react';

import { useFreshRef } from './useFreshRef';

export const useKeyHandlers = (handlers, options) => {
  const handlersRef = useFreshRef(handlers);

  const enabled = !(options && options.disabled);
  const capture = options && options.capture;

  React.useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    /**
     * Element's 'keydown' event handler
     */
    const handleKeyDown = (event) => {
      const handler = handlersRef.current[event.key];
      if (handler) {
        event.preventDefault();
        event.stopPropagation();

        handler(event);
      }
    };

    window.addEventListener('keydown', handleKeyDown, capture);
    return () => {
      window.removeEventListener('keydown', handleKeyDown, capture);
    };
  }, [enabled, handlersRef, capture]);
};
