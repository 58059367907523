import React, { Fragment } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { IconButton, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom-v5-compat';

import { formatDDTeamsCheckoutLink } from 'src/lib/routing';
import { CustomButton, CUSTOM_BUTTON_COLOR, CUSTOM_BUTTON_SIZE } from 'src/components/CustomButton';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import ImageHand from 'src/assets/img/dd-banner.png';
import IconClose from 'src/assets/icons/Close';
import * as events from 'src/lib/events';
import { SUBSCRIPTION_PLAN } from 'src/data/subscription';
import { UPGRADE_ORIGIN } from 'src/data/common';
import { useStyles } from './useStyles';

const UPGRADE_URL = formatDDTeamsCheckoutLink();

export const DDUpgradePanel = ({ className, onUpgrade, onClose, inlineDocument }) => {
  const styles = useStyles();

  const handleUpgradeClicked = () => {
    events.trackHubspotEvent(events.HUBSPOT_EVENT.clickedUpgradeButton, {
      cta: events.HUBSPOT_SUBSCRIPTION_ACTION.buyNow,
      origin: UPGRADE_ORIGIN.scoringPopup,
      plan: events.convertSubscriptionPlanForHubspot(SUBSCRIPTION_PLAN.dataDriven),
    });
    onUpgrade();
  };

  return (
    <div className={cx(className, styles.root)}>
      <Typography className={styles.title}>
        {inlineDocument ? (
          <b>Let Anyword optimize your copy and boost your score</b>
        ) : (
          <Fragment>
            <b>Your perfect copy. Step-by-step.</b>
            <br />
            Get real-time scoring and analytics for every edit you make.
          </Fragment>
        )}
      </Typography>

      <IconButton
        className={styles.buttonClose}
        onClick={onClose}
      >
        <IconClose />
      </IconButton>

      <img
        className={styles.bannerImage}
        src={ImageHand}
        alt=""
      />

      <CustomButton
        className={styles.actionBtn}
        component={Link}
        to={UPGRADE_URL}
        onClick={handleUpgradeClicked}
        target="_blank"
        color={CUSTOM_BUTTON_COLOR.upsell}
        size={CUSTOM_BUTTON_SIZE.small}
      >
        <CustomTypography variant={TYPOGRAPHY_VARIANT.medium12}>
          Upgrade to Data-Driven plan
        </CustomTypography>
      </CustomButton>
    </div>
  );
};

DDUpgradePanel.propTypes = {
  className: PropTypes.string,
  onUpgrade: PropTypes.func,
  onClose: PropTypes.func,
  inlineDocument: PropTypes.bool,
};
DDUpgradePanel.defaultProps = {
  className: null,
  onUpgrade: null,
  onClose: null,
  inlineDocument: false,
};
