import { IconFolderOutlined } from 'src/assets/icons/IconFolderOutlined';
import { IconFolderRoot } from 'src/assets/icons/IconFolderRoot';
import { formatProjectsFolderRoute } from 'src/lib/routing';
import { ITEM_TYPE } from './const';

export const createRootProjectFolderSidebarItem = () => ({
  type: ITEM_TYPE.link,
  id: 'root_project_folder',
  label: 'All documents',
  path: formatProjectsFolderRoute(),
  icon: IconFolderRoot,
});

export const createProjectFolderSidebarItem = (folder) => ({
  type: ITEM_TYPE.link,
  id: folder.id,
  label: folder.name,
  path: formatProjectsFolderRoute(folder.id),
  icon: IconFolderOutlined,
});
