import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';

import isEmail from 'validator/lib/isEmail';
import { isCommonEmail } from 'src/lib/validation/validators';
import { ToolTypeCIP } from 'src/assets/icons/ToolTypeCIP';
import { CustomButton, CUSTOM_BUTTON_SIZE, CUSTOM_BUTTON_COLOR } from 'src/components/CustomButton';
import { PopupHeader } from 'src/components/PopupHeader';
import CustomInput from '../CustomInput';
import { useStyles } from './useStyles';

export const BusinessBookDemoPopup = ({ open, onSubmit, onClose }) => {
  const styles = useStyles();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validateEmail = useCallback((email) => {
    if (email.length === 0) {
      setError('Input needed');
      return false;
    }
    if (!isEmail(email)) {
      setError('Please enter a valid email address');
      return false;
    }
    if (isCommonEmail(email)) {
      setError('Please provide your company email');
      return false;
    }
    return true;
  }, []);

  const handleEmailChange = useCallback((event) => {
    setError('');
    setEmail(event.target.value);
  }, []);

  const clearState = useCallback(() => {
    setEmail('');
    setError('');
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (!validateEmail(email)) {
        return;
      }

      onSubmit(email);
      clearState();
    },
    [validateEmail, email, onSubmit, clearState]
  );

  const handleClose = useCallback(() => {
    onClose();
    clearState();
  }, [onClose, clearState]);

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      data-testid="BusinessBookDemoPopup"
    >
      <PopupHeader
        className={styles.header}
        classes={{ title: styles.headerTitle, buttonClose: styles.headerButtonClose }}
        title="Book a Demo"
        onClose={handleClose}
      />
      <form onSubmit={handleSubmit}>
        <DialogContent className={styles.dialogContent}>
          <ToolTypeCIP className={styles.img} />
          <Typography className={styles.description}>
            Enter your work email to book a demo
            <br />
            with our team
          </Typography>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <CustomInput
            className={styles.input}
            placeholder="Enter your work email"
            value={email}
            onChange={handleEmailChange}
            error={!!error}
          />
          <CustomButton
            className={styles.button}
            color={CUSTOM_BUTTON_COLOR.upsell}
            size={CUSTOM_BUTTON_SIZE.large}
            type="submit"
          >
            Book a demo
          </CustomButton>
        </DialogActions>
        {error && <Typography className={styles.error}>{error}</Typography>}
      </form>
    </Dialog>
  );
};

BusinessBookDemoPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

BusinessBookDemoPopup.defaultProps = {};
