import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(0.75),
      color: COLORS.gray.black,
    },
    card: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      borderRadius: theme.shape.borderRadius,
      border: theme.shape.borderGray70,
      padding: theme.spacing(2, 3),
      gap: theme.spacing(2),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      maxWidth: `calc(100% / 3 - ${theme.spacing(0.5)}px)`,
    },
    title: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.gray.gray30,
      fontWeight: theme.typography.fontWeightMedium,
    },
    value: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(32),
      color: COLORS.gray.black,
      fontWeight: theme.typography.fontWeightMedium,
    },
    accountContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
    },
    accountName: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.gray.black,
      fontWeight: theme.typography.fontWeightRegular,
    },
    channel: {
      color: COLORS.gray.gray40,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },
  }),
  { name: 'BlockCustomModelExtraction' }
);
