import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Information from 'src/components/Information';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  hint: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isQuestion: PropTypes.bool,
  isOptional: PropTypes.bool,
  titleBold: PropTypes.bool,
};

const defaultProps = {
  className: null,
  classes: null,
  label: null,
  hint: null,
  isQuestion: false,
  isOptional: false,
  titleBold: false,
};

export const FieldLabel = ({
  className,
  classes,
  label,
  hint,
  isQuestion,
  isOptional,
  titleBold,
  ...restProps
}) => {
  const styles = useStyles({ classes });

  if (!label) {
    return null;
  }

  return (
    <CustomTypography
      className={cx(styles.root, className)}
      variant={titleBold ? TYPOGRAPHY_VARIANT.medium14 : TYPOGRAPHY_VARIANT.regular14}
      {...restProps}
    >
      <span>{label}</span>
      {isOptional && <span className={styles.optional}> (optional)</span>}
      {hint && (
        <Information
          className={styles.hint}
          content={hint}
          isQuestion={isQuestion}
        />
      )}
    </CustomTypography>
  );
};

FieldLabel.propTypes = propTypes;
FieldLabel.defaultProps = defaultProps;
