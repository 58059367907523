import gql from 'graphql-tag';

import { PROJECT_TYPE } from 'src/data/project';
import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { BRIEF_FROM_LIST_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation ExtractBrief(
    $customerId: ID!
    $url: String!
    $briefType: String!
    $projectType: String!
    $generationTool: GenerationToolEnum
    $suggestionMode: String
  ) {
    extractBrief(
      customerId: $customerId
      url: $url
      briefType: $briefType
      projectType: $projectType
      generationTool: $generationTool
      suggestionMode: $suggestionMode
    ) {
      ...BriefFromList
    }
  }
  ${BRIEF_FROM_LIST_FRAGMENT}
`;

export const extractBrief = (props) => {
  const {
    customerId,
    url,
    briefType,
    generationTool = null,
    suggestionMode = null,
    projectType = PROJECT_TYPE.document,
  } = props;
  const variables = { customerId, url, briefType, generationTool, suggestionMode, projectType };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
