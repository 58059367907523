import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

import { CUSTOMER_PERSONA_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation UpdateCustomerPersonas(
    $personas: [CustomerPersonaInput!]!
    $projectId: ID
    $approve: Boolean
    $brandVoiceId: ID
  ) {
    updateCustomerPersonas(
      personas: $personas
      projectId: $projectId
      approve: $approve
      brandVoiceId: $brandVoiceId
    ) {
      ...CustomerPersona
    }
  }
  ${CUSTOMER_PERSONA_FRAGMENT}
`;

export const updateCustomerPersonas = ({ personas, projectId, approve, brandVoiceId }) => {
  const variables = {
    personas,
    projectId,
    approve,
    brandVoiceId,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
