import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomTypography } from 'src/components/CustomTypography';
import { GENDER, getHistogramCategoryValue } from 'src/data/demographics';
import { formatPercentage } from 'src/utils/format';
import { IconMale } from './assets/IconMale';
import { IconFemale } from './assets/IconFemale';
import { useStyles } from './useStyles';

export const DiagramGender = memo(({ className, data }) => {
  const styles = useStyles();

  const femaleValue = getHistogramCategoryValue(data, GENDER.female);
  const maleValue = getHistogramCategoryValue(data, GENDER.male);

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.indicator}>
        <IconFemale className={styles.icon} />
        <CustomTypography
          className={styles.text}
          variant="semiBold14"
        >
          {formatPercentage(femaleValue, '0')}
        </CustomTypography>
      </div>

      <div className={styles.indicator}>
        <IconMale className={styles.icon} />
        <CustomTypography
          className={styles.text}
          variant="semiBold14"
        >
          {formatPercentage(maleValue, '0')}
        </CustomTypography>
      </div>
    </div>
  );
});

DiagramGender.displayName = 'DiagramGender';

DiagramGender.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

DiagramGender.defaultProps = {
  className: null,
  data: [],
};
