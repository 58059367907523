import React, { forwardRef, memo, useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';
import { generateSvgId } from 'src/lib/svg';

export const IconSearch = memo(
  forwardRef((props, ref) => {
    const svgId1 = useMemo(() => generateSvgId(), []);
    const svgId2 = useMemo(() => generateSvgId(), []);
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
        data-fill-none
      >
        <g clipPath={`url(#${svgId1})`}>
          <g clipPath={`url(#${svgId2})`}>
            <path
              d="M1.56621 8.82945C2.14077 10.1813 3.22883 11.2496 4.59103 11.7992C5.95323 12.3489 7.47799 12.3349 8.82987 11.7603C10.1818 11.1858 11.25 10.0977 11.7997 8.73551C12.3493 7.37331 12.3353 5.84855 11.7608 4.49667C11.4763 3.82728 11.0627 3.22049 10.5437 2.71093C10.0247 2.20137 9.41043 1.79903 8.73593 1.52687C8.06144 1.25471 7.33995 1.11807 6.61265 1.12475C5.88534 1.13142 5.16648 1.28128 4.4971 1.56578C3.82771 1.85027 3.22092 2.26383 2.71136 2.78283C2.2018 3.30183 1.79945 3.91611 1.5273 4.59061C1.25514 5.2651 1.1185 5.98659 1.12517 6.71389C1.13185 7.4412 1.28171 8.16006 1.56621 8.82945Z"
              stroke="#B4B4B4"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5795 10.5791L14.875 14.8752"
              stroke="#B4B4B4"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id={svgId1}>
            <rect
              width="16"
              height="16"
              fill="white"
            />
          </clipPath>
          <clipPath id={svgId2}>
            <rect
              width="16"
              height="16"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
