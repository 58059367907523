export const COMPANY_SIZE = {
  size_1: '1',
  size_2_10: '2-10',
  size_11_50: '11-50',
  size_51_100: '51-100',
  size_101_500: '101-500',
  size_501_1000: '501-1000',
  size_1000_more: '1000+',
};

const COMPANY_SIZE_LABEL = {
  [COMPANY_SIZE.size_1]: 'Just Me',
  [COMPANY_SIZE.size_2_10]: '2–10',
  [COMPANY_SIZE.size_11_50]: '11–50',
  [COMPANY_SIZE.size_51_100]: '51–100',
  [COMPANY_SIZE.size_101_500]: '101–500',
  [COMPANY_SIZE.size_501_1000]: '501–1000',
  [COMPANY_SIZE.size_1000_more]: '1000+',
};

export function getCompanySizeLabel(size) {
  return COMPANY_SIZE_LABEL[size] || '';
}
