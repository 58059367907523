import { Menu, withStyles } from '@material-ui/core';

export const CustomMenu = withStyles({
  paper: {
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.11)',
    borderRadius: 6,
    minWidth: 83,
  },
  list: {},
})(Menu);
