import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1, 0, 1, 1),
      height: 38,
      color: theme.palette.common.white,

      '&$selected': {
        backgroundColor: theme.palette.darkHoverSecondary,
        fontWeight: theme.typography.fontWeightMedium,
      },

      '&:hover': {
        backgroundColor: theme.palette.darkHoverSecondary,

        '& $menuButton': {
          display: 'block',
        },
      },
    },
    selected: {},
    labelName: {
      flex: 1,
    },
    menuButton: {
      display: 'none',

      '& > button ': {
        color: theme.palette.common.white,
        padding: theme.spacing(1),
      },
    },
  }),
  { name: 'WorkspaceMenuItem' }
);
