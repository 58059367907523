import { loginToLinkedinSocial } from 'src/utils/lib/linkedinSocial';
import { authorizeLinkedinSocial } from 'src/graphql/linkedinSocial';
import { ChannelAuthError } from 'src/lib/errors';
import { queryLinkedinSocialAuthorizationLink } from 'src/graphql/linkedinSocial';

export default async function connectToLinkedinSocial() {
  // Query auth link from BE
  const authUrl = await queryLinkedinSocialAuthorizationLink();

  // Ask user to allow our app to use their data
  const token = await loginToLinkedinSocial(authUrl);
  if (!token) {
    throw new ChannelAuthError('Error getting LinkedIn Page authorization token');
  }

  // Save received token to BE
  return authorizeLinkedinSocial(token);
}
