import { makeStyles } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      background: '#5394EC',
      borderRadius: theme.shape.borderRadius,
      color: COLORS.neutral.white,
      overflow: 'hidden',
    },

    alertMessage: {
      flexGrow: 1,
    },
    alertActions: {
      flexShrink: 0,
      margin: 0,
    },
    title: {
      color: COLORS.neutral.white,
    },
    infoIcon: {
      fontSize: theme.typography.pxToRem(46),
    },
    buttonClose: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(1.25),
    },
    buttonCta: {
      flexShrink: 0,
      margin: theme.spacing(3, 0),
      padding: theme.spacing(0.5, 2),
      background: COLORS.neutral.white,
      color: '#458DED',

      '&:hover': {
        background: '#AFD1FE',
        color: '#fff',
      },
    },
  }),
  { name: 'PanelNotification' }
);
