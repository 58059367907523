import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { USER_FRAGMENT } from '../user/schema';

const MUTATION = gql`
  mutation IntegrationAuthorize(
    $customerId: ID!
    $dataSource: PerformanceCenterDataSource!
    $credentials: CredentialsInput!
  ) {
    integrationAuthorize(
      customerId: $customerId
      dataSource: $dataSource
      credentials: $credentials
    ) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;

export const integrationAuthorize = ({ customerId, dataSource, credentials }) => {
  const variables = {
    customerId,
    dataSource,
    credentials,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
