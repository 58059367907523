import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_FORMULA } from './schema';

const QUERY = gql`
  query CustomerCustomFormulas($brandVoiceId: ID!, $generationTool: GenerationToolEnum) {
    customerCustomFormulas(brandVoiceId: $brandVoiceId, generationTool: $generationTool) {
      ...CustomFormula
    }
  }
  ${FRAGMENT_CUSTOM_FORMULA}
`;

export const queryCustomFormulas = (brandVoiceId, generationTool) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      brandVoiceId,
      generationTool,
    },
  }).then(({ data }) => getSingleProp(data));
};
