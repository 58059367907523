/**
 * Generation modes
 * @enum {string}
 */
export const AI_GENERATION_MODE = {
  suggest: 'suggest',
  recompose: 'recompose',
  advanced: 'advanced',
};

/**
 * Suggestions generation mode
 * @enum {string}
 */
export const AI_SUGGESTION_MODE = {
  standard: 'standard',
  creative: 'creative',
  auto: 'auto',
  quotes: 'quotes',
  paragraph: 'paragraph',
  list: 'list',
  personalized: 'personalized',
  customFormula: 'custom_formula',
  guided: 'guided', // mode for usage with "generation guidance" templates
  BAB: 'bab',
  FAB: 'fab',
  PPP: 'ppp',
  PAS: 'pas',
  AIDA: 'aida',
  featureList: 'feature_list',
  sms160: 'sms_160',
  sms320: 'sms_320',
  sms480: 'sms_480',

  rewrite: 'rewrite', // this is service mode, only to display button for landing assets
  engaging: 'engaging', // conflict with AI_SUGGESTION_MODE.standard
  emotional_benefits: 'emotional_benefits',
  default: 'default',

  // modes for summarize generation tool
  topic: 'topic',
  tldr: 'tldr',
  summarize: 'summarize',

  hookBodyCta: 'hook_body_cta',
  shortPost: 'short_post',
  storyTelling: 'story_telling',
  qa: 'qa',
  fluent: 'fluent',
  bulletList: 'list',
  clickbait: 'clickbait',
  summary: 'summary',
  quoteUrl: 'quotes',
  question: 'question',
  hook: 'hook',
  callToAction: 'callToAction', // not using cta as it is meant for something else
  fourPs: 'pppp',
  oneTwoThreeFourFormula: 'one_two_three_four',
  attention: 'attention',

  //deprecated
  amazonFeatureList: 'amazon_feature_list',
  amazonDescription: 'amazon_description',

  combination: 'combination',
  keywordDriven: 'keyword_driven',
  benefit: 'benefit',
  feature: 'feature',
  CTA: 'cta',
  brand: 'brand',
  productCategory: 'product_category',
  pain: 'pain',

  regenerate: 'regenerate', //used in blog draft, for regenerating an outline headline
};

/**
 * Recompose generation mode
 * @enum {string}
 */
export const AI_RECOMPOSE_MODE = {
  moreEngaging: 'strict',
  default: 'relaxed',
  polished: 'polished',
  expand: 'expand', // currently only used in the "blog"
  shorten: 'shorten', // currently only used in the "blog"
  refine: 'refine', // currently only used in the "blog"
  manual: 'manual', // currently only used in the "blog"
  instruction_based: 'instruction_based', // currently only used in the "blog"
  tov: 'tov',
};

export const GENERATION_SOURCE_TYPE = {
  improveScore: 'improve_score',
  improveListScore: 'improve_list_score',
  changeTov: 'change_tov',
  changeAnnotations: 'change_annotations',
};

export const GENERATION_MODE_TYPE = {
  common: 'common',
  toneOfVoice: 'toneOfVoice',
  guidance: 'guidance',
  personalized: 'personalized',
  customFormula: 'customFormula',
};

export const RECOMPOSE_DEMOGRAPHIC_SEX_MODE = {
  masculine: 'masculine',
  feminine: 'feminine',
};

export const RECOMPOSE_DEMOGRAPHIC_AGE_MODE = {
  younger: 'younger',
  older: 'older',
};

export const GENERATION_GUIDANCE_TYPE = {
  customerAvatar: 'customer_avatar',
  pain: 'pain',
  benefit: 'benefit',
  feature: 'feature',
  ageRange: 'age_range',
  gender: 'gender',
  keyword: 'keyword',
  campaignKeyword: 'campaign_keyword',
  hashtag: 'hashtag',
  hubspot: 'hubspotEmails',
  google: 'google',
  facebook: 'facebook',
  linkedin: 'linkedin',
  linkedinSocial: 'linkedin_social',
  amazon: 'reviewsAmazon',
  trustpilot: 'reviewsTrustpilot',
  landing_page: 'landingPage',
  home_page: 'homePage',
  competitors: 'competitors',
  thoughtLeadership: 'thoughtLeadership',
  productPage: 'productPage',
  other: 'other',
  facebookPages: 'facebook_pages',
  instagram: 'instagram',
  genericResource: 'genericResource',

  // currently only used in the "blog builder"
  writeAbout: 'write_about',
  example: 'example',
  conclusion: 'conclusion',
  explain: 'explain',
  list: 'list',
};

export const CASING_TYPE = {
  sentence: 'sentence',
  title: 'title',
  upper: 'upper',
  default: 'default',
};

export const CASING_LABELS = {
  [CASING_TYPE.sentence]: 'Sentence case',
  [CASING_TYPE.upper]: 'UPPERCASE',
  [CASING_TYPE.title]: 'Title Case',
  [CASING_TYPE.default]: 'Let AI decide',
};

export const PERSONALIZED_NAME_MAX_LENGTH = 20;
export const PERSONALIZED_ITEMS_MIN_COUNT = 3;
export const PERSONALIZED_ITEMS_MAX_COUNT = 5;
export const PERSONALIZED_ITEM_MAX_LENGTH = 500;
export const PERSONALIZED_DESCRIPTION_MAX_LENGTH = 100;

export const MAX_WORDS_FOR_SCORE = 200;

export const DEFAULT_NUMBER_OF_VARIATIONS = 5;
export const BASIC_DEFAULT_NUMBER_OF_VARIATIONS = 2;
export const CANVAS_DEFAULT_NUMBER_OF_VARIATIONS = 3;
export const CANVAS_DEFAULT_LP_NUMBER_OF_VARIATIONS = 1;

export const AI_TEXT_SIZE = {
  short: 'short',
  medium: 'medium',
  long: 'long',
};

/**
 * Generation languages
 * @enum {string}
 */
export const LANGUAGE = {
  bg: 'bg',
  cs: 'cs',
  da: 'da',
  de: 'de',
  de_in: 'de_in',
  el: 'el',
  en_us: 'en-us',
  en_gb: 'en-gb',
  es: 'es',
  es_in: 'es_in',
  et: 'et',
  fi: 'fi',
  fr: 'fr',
  fr_in: 'fr_in',
  hu: 'hu',
  id: 'id',
  it: 'it',
  it_in: 'it_in',
  ja: 'ja',
  lt: 'lt',
  lv: 'lv',
  nb: 'nb',
  nl: 'nl',
  nl_in: 'nl_in',
  pl: 'pl',
  pl_in: 'pl_in',
  pt_pt: 'pt-pt',
  pt_pt_in: 'pt-pt_in',
  pt_br: 'pt-br',
  pt_br_in: 'pt-br_in',
  ro: 'ro',
  ru: 'ru',
  ru_in: 'ru_in',
  sk: 'sk',
  sl: 'sl',
  sv: 'sv',
  th: 'th',
  uk: 'uk',
  vi: 'vi',
  zh: 'zh',
};

export const HIDDEN_LANGUAGE = {
  he: 'he',
};

export const INFORMAL_LANGUAGE = {
  [LANGUAGE.de_in]: LANGUAGE.de,
  [LANGUAGE.nl_in]: LANGUAGE.nl,
  [LANGUAGE.fr_in]: LANGUAGE.fr,
  [LANGUAGE.it_in]: LANGUAGE.it,
  [LANGUAGE.pl_in]: LANGUAGE.pl,
  [LANGUAGE.ru_in]: LANGUAGE.ru,
  [LANGUAGE.es_in]: LANGUAGE.es,
  [LANGUAGE.pt_pt_in]: LANGUAGE.pt_pt,
  [LANGUAGE.pt_br_in]: LANGUAGE.pt_br,
};

export const EN_LANGUAGES = [LANGUAGE.en_gb, LANGUAGE.en_us];

// Filtering the informal languages
export const SUPPORTED_LANGUAGES_COUNT = Object.values(LANGUAGE).filter(
  (l) => !Object.keys(INFORMAL_LANGUAGE).includes(l)
).length;

// Define `DEFAULT` prop on `LANGUAGE` as "English US"
Object.defineProperty(LANGUAGE, 'DEFAULT', {
  value: LANGUAGE.en_us,
  enumerable: false,
});

export const DEFAULT_RECOMPOSE_TOV_MODE = 'Engaging';
export const SUGGEST_TOV_MODES = {
  conversational: 'Conversational',
  hardSell: 'Hard Sell',
  playful: 'Playful',
  fifthGrader: '5th Grader',
  confident: 'Confident',
};

export const REWERITE_TOOL_MODES = [AI_RECOMPOSE_MODE.default, AI_RECOMPOSE_MODE.moreEngaging];
