import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(5),
    },
    indicator: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    icon: {
      fontSize: theme.typography.pxToRem(24),
      color: COLORS.gray.black,
    },
    text: {
      color: COLORS.gray.black,
    },
  }),
  { name: 'DiagramGender' }
);
