import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_MODEL_BLOG_URL } from '../customModel/schema';

const MUTATION = gql`
  mutation performanceCenterAddCustomModelBlogUrl($url: String!, $customerId: ID!) {
    performanceCenterAddCustomModelBlogUrl(url: $url, customerId: $customerId) {
      ...CustomModelBlogUrl
    }
  }
  ${FRAGMENT_CUSTOM_MODEL_BLOG_URL}
`;

export const performanceCenterAddCustomModelBlogUrl = ({ customerId, url }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, url },
  }).then(({ data }) => getSingleProp(data));
};
