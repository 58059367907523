import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'stretch',
    },
    button: {},
    icon: {
      fontSize: theme.typography.pxToRem(12),
    },
    popper: {
      zIndex: theme.zIndex.modal,
    },
    paper: {
      boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.11)',
    },
    menuList: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'ToolbarShowMoreActions' }
);
