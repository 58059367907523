import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    title: {
      marginBottom: theme.spacing(0.5),
      color: COLORS.gray.black,
      textAlign: 'left',
    },
    wrapper: {},
    item: {
      display: 'flex',
      columnGap: theme.spacing(0.5),
      '& + &': {
        marginTop: theme.spacing(0.5),
      },
    },
    itemText: {
      color: COLORS.gray.black,
      padding: theme.spacing(0.375, 0.75),
      backgroundColor: COLORS.gray.gray90,
      borderRadius: theme.shape.borderRadiusMinimal,
      maxWidth: '100%',
    },
    empty: {
      color: COLORS.gray.gray40,
    },
  }),
  { name: 'PersonaPainPoints' }
);
