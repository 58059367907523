import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    subtitle: {
      color: COLORS.gray.gray30,
    },
    change: {
      marginTop: theme.spacing(1),
      cursor: 'pointer',
      color: COLORS.primary.default,
    },
  }),
  { name: 'EmptyResults' }
);
