import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconX = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5655 4.01213C15.6146 4.05783 15.6542 4.11275 15.682 4.17377C15.7099 4.23478 15.7254 4.30068 15.7278 4.36771C15.7302 4.43474 15.7193 4.50157 15.6958 4.5644C15.6723 4.62723 15.6367 4.68481 15.591 4.73386L11.4382 9.18725L16.0218 15.2714C16.0788 15.3472 16.1135 15.4374 16.1221 15.5318C16.1307 15.6263 16.1129 15.7212 16.0705 15.8061C16.0282 15.891 15.963 15.9623 15.8824 16.0122C15.8017 16.0621 15.7088 16.0886 15.6139 16.0886H12.8179C12.7388 16.0886 12.6608 16.0703 12.59 16.035C12.5193 15.9998 12.4577 15.9486 12.4101 15.8854L9.18423 11.6046L5.155 15.9263C5.06268 16.0253 4.9348 16.0836 4.79949 16.0883C4.66419 16.0931 4.53255 16.0439 4.43352 15.9515C4.3345 15.8592 4.27621 15.7313 4.27147 15.596C4.26673 15.4607 4.31594 15.3291 4.40826 15.2301L8.56101 10.7767L3.97747 4.69252C3.9205 4.61676 3.88575 4.52663 3.87712 4.43223C3.86849 4.33784 3.88632 4.2429 3.9286 4.15806C3.97089 4.07323 4.03596 4.00184 4.11653 3.95191C4.1971 3.90197 4.28999 3.87546 4.38478 3.87534H7.18135C7.26033 3.87538 7.33823 3.89375 7.40891 3.929C7.47958 3.96425 7.54112 4.01542 7.58866 4.07849L10.8145 8.35935L14.8432 4.03765C14.8889 3.98856 14.9438 3.94896 15.0049 3.9211C15.0659 3.89325 15.1318 3.87769 15.1988 3.87532C15.2658 3.87295 15.3327 3.88381 15.3955 3.90729C15.4583 3.93076 15.5164 3.96639 15.5655 4.01213ZM10.3847 9.48584C10.3703 9.4692 10.357 9.45164 10.3449 9.43327L6.92665 4.89566H5.40816L13.0721 15.0683H14.5906L10.3847 9.48584Z"
        fill="black"
      />
    </SvgIcon>
  ))
);
