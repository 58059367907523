import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  CUSTOM_BUTTON_SIZE,
  CUSTOM_BUTTON_VARIANT,
  CustomButton,
} from 'src/components/CustomButton';
import { useStyles } from './useStyles';

export const CopyInputActions = ({ className, disabled, onSave, onCancel }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)}>
      <CustomButton
        className={cx(styles.button, styles.buttonCancel)}
        onClick={onCancel}
        disabled={disabled}
        variant={CUSTOM_BUTTON_VARIANT.text}
        size={CUSTOM_BUTTON_SIZE.small}
      >
        Cancel
      </CustomButton>

      <CustomButton
        className={styles.button}
        onClick={onSave}
        disabled={disabled}
        variant={CUSTOM_BUTTON_VARIANT.contained}
        size={CUSTOM_BUTTON_SIZE.small}
      >
        Save
      </CustomButton>
    </div>
  );
};

CopyInputActions.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

CopyInputActions.defaultProps = {
  className: null,
  disabled: false,
  onSave: null,
  onCancel: null,
};
