import React from 'react';

import { isFreestyleGenerationTool } from 'src/data/generationTool';
import { getGenerationResultDescription } from 'src/data/generation';
import { BaseItem } from '../BaseItem';

export const GenerationToolItem = ({ generationData, vertical, classes, multiline }) => {
  const { generationTool, freestyleToolTitle } = generationData;
  const { title, description } = getGenerationResultDescription(generationData);
  const toolLabel = isFreestyleGenerationTool(generationTool)
    ? freestyleToolTitle
    : `${title}${description ? ` (${description})` : ''}`;

  return (
    <BaseItem
      label="Template"
      vertical={vertical}
      classes={classes}
      hideTitleColon={multiline}
    >
      {toolLabel}
    </BaseItem>
  );
};
