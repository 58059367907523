import { useCallback } from 'react';

import { usePopups } from '../services';
import { EDIT_PAYMENT_METHOD } from '../types';

export const useEditPaymentMethodPopup = () => {
  const popups = usePopups();

  return useCallback(() => {
    return new Promise((resolve) => {
      popups.openPopup(EDIT_PAYMENT_METHOD, {
        onConfirm: () => {
          popups.closePopup(EDIT_PAYMENT_METHOD);
          resolve();
        },
        onCancel: () => {
          popups.closePopup(EDIT_PAYMENT_METHOD);
        },
      });
    });
  }, [popups]);
};
