import React, { forwardRef, memo, useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const StarIcon = memo(
  forwardRef((props, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon {...props} ref={ref} viewBox="0 0 16 16">
        <rect width="16" height="16" rx="8" fill="#E0F5ED" />
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M8.00024 4.00098L9.23624 6.50498L12.0002 6.90898L10.0002 8.85698L10.4722 11.609L8.00024 10.309L5.52824 11.609L6.00024 8.85698L4.00024 6.90898L6.76424 6.50498L8.00024 4.00098Z"
            fill="#41C493"
            stroke="#41C493"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="9.6"
              height="9.6"
              fill="white"
              transform="translate(3.19995 3.2002)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
