import { useCallback } from 'react';

import { CONNECT_TO_CHANNEL_POPUP, usePopups } from 'src/services/popup';

export const useConnectToChannelPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({
      isTrainingModel,
      brandVoiceId,
      onConnectSuccess,
      displayIntegrations,
      displayResourcesTemplates,
      displayEmailIntegrations,
      displayBlogURLs,
      displayTalkingPointsFromResource,
      displayWTM,
      title,
      subTitle,
      isTalkingPointsView,
    }) => {
      return new Promise((resolve) => {
        popups.openPopup(CONNECT_TO_CHANNEL_POPUP, {
          onClose: async () => {
            popups.closePopup(CONNECT_TO_CHANNEL_POPUP);
            resolve();
          },
          onSubmit: async (channel) => {
            popups.closePopup(CONNECT_TO_CHANNEL_POPUP);
            resolve(channel);
          },
          isTrainingModel,
          brandVoiceId,
          onConnectSuccess,
          displayIntegrations,
          displayResourcesTemplates,
          displayEmailIntegrations,
          displayBlogURLs,
          displayTalkingPointsFromResource,
          displayWTM,
          title,
          subTitle,
          isTalkingPointsView,
        });
      });
    },
    [popups]
  );
};
