import { useCallback } from 'react';

import { useCustomModelsActions, useNotificationActions } from 'src/store';
import { useErrorHandler, useRefreshBrandVoiceToneOfVoices } from 'src/services';
import { CUSTOM_MODEL_SUCCESS_POPUP } from '../types';
import { usePopups } from '../services';

export const useCustomModelSuccessPopup = () => {
  const popups = usePopups();
  const notifications = useNotificationActions();
  const customModelsActions = useCustomModelsActions();
  const handleError = useErrorHandler();
  const refreshBrandVoiceToneOfVoices = useRefreshBrandVoiceToneOfVoices();

  return useCallback(
    ({ customModel }) => {
      return popups.openPopup(CUSTOM_MODEL_SUCCESS_POPUP, {
        onClose: async (shouldSetAsDefault) => {
          if (shouldSetAsDefault) {
            try {
              await customModelsActions.updateDefaultCustomModel(customModel, true);
              refreshBrandVoiceToneOfVoices(customModel.brandVoice?.id);
              notifications.displaySuccess('Custom model updated');
            } catch (error) {
              handleError(error);
            }
          }
          popups.closePopup(CUSTOM_MODEL_SUCCESS_POPUP);
        },
        customModel,
      });
    },
    [popups, customModelsActions, refreshBrandVoiceToneOfVoices, notifications, handleError]
  );
};
