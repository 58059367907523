import { useEffect, useRef } from 'react';

export const useEffectOnce = (effect) => {
  const effectRef = useRef(effect);
  const executedRef = useRef(false);

  useEffect(() => {
    if (executedRef.current) {
      return;
    }

    executedRef.current = true;
    return effectRef.current();
  }, []);
};

export default useEffectOnce;
