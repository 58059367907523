import React from 'react';
import PropTypes from 'prop-types';
import { createPopper } from '@popperjs/core';

import useStyles from './useStyles';

const DEFAULT_SIZE = 30;

const createModifiers = () => {
  return [
    {
      name: 'offset',
      options: {
        offset: ({ popper }) => {
          return [0, -popper.width];
        },
      },
    },
  ];
};

const propTypes = {
  classes: PropTypes.shape(),
  targetElement: PropTypes.instanceOf(Element),
  size: PropTypes.number,
  color: PropTypes.string,
  placement: PropTypes.string,
};

const defaultProps = {
  classes: null,
  targetElement: null,
  size: DEFAULT_SIZE,
  color: null,
  placement: 'right',
};

const Beacon = ({ classes, targetElement, size, color, placement }) => {
  const styles = useStyles({ classes, size, color });
  const beaconRef = React.useRef(null);

  React.useEffect(() => {
    if (!targetElement || !beaconRef.current) {
      return undefined;
    }

    const beaconElement = beaconRef.current;

    const popperInstance = createPopper(targetElement, beaconElement, {
      placement,
      modifiers: createModifiers(),
    });

    const interval = setInterval(() => {
      popperInstance.update();
    }, 100);

    return () => {
      clearInterval(interval);
      popperInstance.destroy();
    };
  }, [targetElement, placement]);

  if (!targetElement) {
    return null;
  }

  return (
    <div className={styles.root} ref={beaconRef}>
      <div className={styles.pulse} />
      <div className={styles.dot} />
    </div>
  );
};

Beacon.propTypes = propTypes;
Beacon.defaultProps = defaultProps;

export default Beacon;
