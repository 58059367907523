import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const BrandVoiceIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
        data-fill-none
      >
        <path
          d="M8.5385 15.0769H6.46156C5.5435 15.0769 4.66304 14.7122 4.01387 14.0631C3.3647 13.4139 3 12.5334 3 11.6154C3 10.6973 3.3647 9.81684 4.01387 9.16768C4.66304 8.51851 5.5435 8.15381 6.46156 8.15381H8.5385V15.0769Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.53857 15.077C12.6012 15.0773 16.5729 16.2798 19.9534 18.533L21.0002 19.2309V4L19.9534 4.69785C16.5729 6.95104 12.6012 8.15353 8.53857 8.15387V15.077Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.53858 15.0775C8.53799 15.9839 8.72181 16.881 9.07886 17.7141C9.43592 18.5472 9.95874 19.299 10.6155 19.9237"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
