import React, { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useSvgId } from 'src/lib/hooks';

export const InviteUserIcon = forwardRef((props, ref) => {
  const svgId = useSvgId();

  return (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 14 15"
    >
      <g clipPath={`url(#${svgId})`}>
        <path
          d="M8.16016 7.5C10.0932 7.5 11.6602 5.933 11.6602 4C11.6602 2.067 10.0932 0.5 8.16016 0.5C6.22716 0.5 4.66016 2.067 4.66016 4C4.66016 5.933 6.22716 7.5 8.16016 7.5Z"
          fill="currentColor"
        />
        <path
          d="M9.91732 8.66699H6.41732C5.33473 8.66823 4.29684 9.09883 3.53133 9.86434C2.76582 10.6298 2.33522 11.6677 2.33398 12.7503V13.917C2.33398 14.0717 2.39544 14.2201 2.50484 14.3295C2.61424 14.4389 2.76261 14.5003 2.91732 14.5003H13.4173C13.572 14.5003 13.7204 14.4389 13.8298 14.3295C13.9392 14.2201 14.0007 14.0717 14.0007 13.917V12.7503C13.9994 11.6677 13.5688 10.6298 12.8033 9.86434C12.0378 9.09883 10.9999 8.66823 9.91732 8.66699Z"
          fill="currentColor"
        />
        <path
          d="M2.33138 4.58301C2.17667 4.58301 2.0283 4.64447 1.9189 4.75386C1.80951 4.86326 1.74805 5.01163 1.74805 5.16634V6.33301H0.58138C0.426671 6.33301 0.278298 6.39447 0.168901 6.50386C0.0595051 6.61326 -0.00195312 6.76163 -0.00195312 6.91634C-0.00195312 7.07105 0.0595051 7.21942 0.168901 7.32882C0.278298 7.43822 0.426671 7.49968 0.58138 7.49968H1.74805V8.66634C1.74805 8.82105 1.80951 8.96942 1.9189 9.07882C2.0283 9.18822 2.17667 9.24968 2.33138 9.24968C2.48609 9.24968 2.63446 9.18822 2.74386 9.07882C2.85326 8.96942 2.91471 8.82105 2.91471 8.66634V7.49968H4.08138C4.23609 7.49968 4.38446 7.43822 4.49386 7.32882C4.60326 7.21942 4.66471 7.07105 4.66471 6.91634C4.66471 6.76163 4.60326 6.61326 4.49386 6.50386C4.38446 6.39447 4.23609 6.33301 4.08138 6.33301H2.91471V5.16634C2.91471 5.01163 2.85326 4.86326 2.74386 4.75386C2.63446 4.64447 2.48609 4.58301 2.33138 4.58301Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={svgId}>
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
