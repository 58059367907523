import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& > span': {
        paddingRight: theme.spacing(1.5),
      },
    },
    menu_paper: {
      width: 248,
      marginTop: theme.spacing(0.75),
      border: 'none',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
  }),
  { name: 'CreateContentItem' }
);
