import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconEditorLink = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89994 2.18069C6.89994 2.1807 6.89995 2.18068 6.89994 2.18069L5.73445 3.34627C5.44157 3.63917 4.9667 3.63919 4.67379 3.34631C4.38089 3.05343 4.38087 2.57855 4.67375 2.28565L5.83926 1.12005C7.2311 -0.271783 9.48767 -0.271779 10.8795 1.12001C12.2714 2.51184 12.2714 4.76845 10.8796 6.16028C10.8796 6.16028 10.8796 6.16028 10.8796 6.16028L9.71403 7.32587C9.42115 7.61877 8.94627 7.61878 8.65337 7.32589C8.36048 7.033 8.36047 6.55813 8.65335 6.26523L9.8189 5.09964C10.6249 4.2936 10.6249 2.98676 9.81892 2.18071C9.81892 2.1807 9.81893 2.18071 9.81892 2.18071M6.89994 2.18069C7.70597 1.37469 9.0128 1.37467 9.81892 2.18071L6.89994 2.18069Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.34656 4.67278C3.63946 4.96566 3.63948 5.44053 3.3466 5.73344L2.18108 6.89903C1.37504 7.70508 1.37503 9.01198 2.18106 9.81801C2.98711 10.624 4.29396 10.624 5.1 9.81801L6.26548 8.65241C6.55836 8.3595 7.03324 8.35948 7.32614 8.65236C7.61905 8.94524 7.61907 9.42011 7.32619 9.71302L6.16068 10.8786C4.76886 12.2705 2.51224 12.2705 1.12041 10.8787C-0.271415 9.48686 -0.271415 7.23024 1.12039 5.8384C1.12038 5.83841 1.12039 5.8384 1.12039 5.8384L2.2859 4.67281C2.57878 4.37991 3.05366 4.37989 3.34656 4.67278Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.12215 3.87787C8.41504 4.17077 8.41504 4.64564 8.12215 4.93853L4.93847 8.12221C4.64558 8.4151 4.17071 8.4151 3.87781 8.12221C3.58492 7.82931 3.58492 7.35444 3.87781 7.06155L7.06149 3.87787C7.35438 3.58498 7.82925 3.58498 8.12215 3.87787Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconEditorLink.displayName = 'IconEditorLink';
