import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_BOOKMARK } from 'src/graphql/user/schema';

const MUTATION = gql`
  mutation CreateUserBookmarks($bookmarks: [BookmarkInput!]!) {
    createBookmarks(bookmarks: $bookmarks) {
      ...Bookmark
    }
  }
  ${FRAGMENT_BOOKMARK}
`;

const mapBookmark = (item) => ({
  ...item,
  metadata: item.metadata ? JSON.stringify(item.metadata) : null,
});

export const createUserBookmarks = (bookmarks) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: {
      bookmarks: bookmarks.map(mapBookmark),
    },
  }).then(({ data }) => getSingleProp(data));
