/* eslint-disable no-param-reassign */
import {
  Editor,
  Node as SlateNode,
  Transforms,
  Element as SlateElement,
  Text,
  Point,
  Range,
  Path,
} from 'slate';

import { SLATE_TEXT_FORMATS } from 'src/lib/slate';

const findVariationNodeEntry = (editor, options) =>
  Array.from(Editor.levels(editor, options)).find(
    ([n]) => n.type === SLATE_TEXT_FORMATS.DD_VARIATION
  );

const editorHasVariation = (editor, variationId) =>
  editor.children.some(
    (node) =>
      node.type === SLATE_TEXT_FORMATS.DD_VARIATION &&
      node.variation &&
      node.variation.id === variationId
  );

/**
 * Apply "variation" wrapping with passed variation data to the current editor block
 * We should also check if variation not exist in editor already - we don't need duplicated "variation" blocks
 */
const applyVariationWrappingToEditor = (editor, variationNode) => {
  const variationExists = editorHasVariation(editor, variationNode.variation.id);
  if (variationExists) {
    return;
  }

  Transforms.wrapNodes(editor, {
    ...variationNode,
    children: [],
  });
};

export const withVariationBlocks = (editor) => {
  const { normalizeNode, insertBreak, deleteBackward, deleteForward, deleteFragment } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // HANDLE EMPTY DD VARIATIONS AND TURN THEM TO EMPTY PARAGRAPHS
    if (node.type === SLATE_TEXT_FORMATS.DD_VARIATION) {
      if (!SlateNode.string(node).trim()) {
        Transforms.unwrapNodes(editor, { at: path });
      }
      if (SlateElement.isElement(node) && Text.isText(SlateNode.child(node, 0))) {
        Transforms.setNodes(
          editor,
          { type: SLATE_TEXT_FORMATS.PARAGRAPH },
          {
            at: path,
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === SLATE_TEXT_FORMATS.DD_VARIATION,
          }
        );
        Transforms.wrapNodes(editor, { ...node, children: [] }, { at: path });
      }
    }

    normalizeNode(entry);
  };

  editor.insertBreak = () => {
    Editor.withoutNormalizing(editor, () => {
      const nodeEntry = findVariationNodeEntry(editor);
      if (Range.isCollapsed(editor.selection) && nodeEntry) {
        const [node, path] = nodeEntry;

        const endOfVariationBlockPoint = {
          ...Editor.point(editor, path, {
            edge: 'end',
          }),
          offset: SlateNode.string(node).length,
        };

        const [{ path: selectionStartPath }, selectionEndPoint] = Editor.edges(
          editor,
          editor.selection
        );

        const selectionStartNode = SlateNode.get(editor, selectionStartPath.slice(0, -1));

        const isEmptyNewLine = !SlateNode.string(selectionStartNode);

        const isEndOfVariationBlock =
          Point.equals(selectionEndPoint, endOfVariationBlockPoint) ||
          !Editor.string(editor, {
            anchor: selectionEndPoint,
            focus: endOfVariationBlockPoint,
          }).trim().length;

        if (isEndOfVariationBlock && isEmptyNewLine) {
          const nextPath = Path.next(path);
          Transforms.insertNodes(
            editor,
            [
              {
                type: SLATE_TEXT_FORMATS.PARAGRAPH,
                children: [{ text: '' }],
              },
            ],
            { at: nextPath }
          );
          Transforms.select(editor, nextPath);
        }
      }

      insertBreak();
    });
  };

  editor.deleteBackward = (unit) => {
    Editor.withoutNormalizing(editor, () => {
      // See if we are in a variation before applying "delete" action
      const variationEntry = findVariationNodeEntry(editor);

      // Perform normal "delete" action
      deleteBackward(unit);

      // If we were in a variation, but we don't see variation anymore,
      // then we should wrap current block back in a previous variation
      const variationAfterDeleteEntry = findVariationNodeEntry(editor);
      if (variationEntry && !variationAfterDeleteEntry) {
        const [node] = variationEntry;
        applyVariationWrappingToEditor(editor, node);
      }
    });
  };

  editor.deleteForward = (unit) => {
    Editor.withoutNormalizing(editor, () => {
      // See if there is a variation right after selection, before applying "delete" action
      const afterSelection = Editor.after(editor, editor.selection);
      const variationEntry = findVariationNodeEntry(editor, {
        at: afterSelection,
      });

      // Perform normal "delete" action
      deleteForward(unit);

      // If we were in a variation, but we are not in a variation anymore,
      // then we should wrap current block back in a previous variation
      const variationAfterDeleteEntry = findVariationNodeEntry(editor);
      if (variationEntry && !variationAfterDeleteEntry) {
        const [node] = variationEntry;
        applyVariationWrappingToEditor(editor, node);
      }
    });
  };

  editor.deleteFragment = (direction) => {
    Editor.withoutNormalizing(editor, () => {
      // Remember if there is a variation right after selection, before applying "delete" action
      const afterSelection = Editor.after(editor, editor.selection);
      const variationEntry = findVariationNodeEntry(editor, {
        at: afterSelection,
      });

      // Perform normal "delete" action
      deleteFragment(direction);

      // If we were in a variation, but we are not in a variation anymore,
      // then we should wrap current block back in a previous variation
      const variationAfterDeleteEntry = findVariationNodeEntry(editor);
      if (variationEntry && !variationAfterDeleteEntry) {
        const [node] = variationEntry;
        applyVariationWrappingToEditor(editor, node);
      }
    });
  };

  return editor;
};
