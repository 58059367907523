import { makeStyles } from '@material-ui/core/styles';

import { COLORS, TEXT_DETAILS_PANEL_WIDTH } from 'src/data/ui';
import { NAVIGATION_BAR_HEIGHT } from 'src/data/common';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      marginBottom: theme.spacing(12.5),
      background: COLORS.gray.gray90,
    },
    detailsHeader: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
      padding: theme.spacing(3),
      backgroundColor: COLORS.neutral.white,
      '&$noPaddingBottom': {
        paddingBottom: theme.spacing(0),
      },  
    },
    noPaddingBottom: {},
    blurred: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      inset: 0,
      backdropFilter: 'blur(5px)',

      position: 'fixed',
      height: `calc(100% - ${NAVIGATION_BAR_HEIGHT}px)`,
      width: TEXT_DETAILS_PANEL_WIDTH,
      marginLeft: 'auto',
      marginTop: 'auto',

      '& $blockedMessage': {
        backgroundColor: COLORS.neutral.white,
      },
    },
    blockedMessage: {
      marginTop: theme.spacing(4.5),
    },
    tab: {
      padding: `${theme.spacing(2)}px !important`,
    },
    detailsBody: {
      background: COLORS.gray.gray80,
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },

    boxGroupTitle: {
      color: COLORS.gray.gray30,
      ...theme.typography.semiBold12,
    },

    groupContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      '& + &': {
        marginTop: theme.spacing(2),
      },
    },

    channelPolicyContent: {
      position: 'relative',
    },
  }),
  { name: 'TextDetailsPanel' }
);
