import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(2),
      borderBottomLeftRadius: 12,
      borderBottomRightRadius: 12,
      backgroundColor: COLORS.gray.gray90,
      borderTop: 0,
    },
    upgradeText: {
      color: COLORS.gray.gray40,
      '& span': {
        cursor: 'pointer',
        color: COLORS.primary.default,
      },
    },
  }),
  { name: 'Upgrade' }
);
