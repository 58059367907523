import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';

import { ROUTE_PARAMS } from 'src/routes';

export const useInviteToken = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get(ROUTE_PARAMS.token);

  const removeTokenFromUrl = useCallback(() => {
    searchParams.delete(ROUTE_PARAMS.token);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return {
    token,
    removeTokenFromUrl,
  };
};
