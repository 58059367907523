import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_BLOG_POST_BUILDER_BRIEF } from './schema';

const MUTATION = gql`
  mutation UpdateBlogPostBuilderKeywords(
    $id: ID!
    $keywords: [String!]!
    $targetKeywords: [String!]!
    $supportingKeywords: JSON
  ) {
    updateBlogPostBuilderKeywords(
      id: $id
      keywords: $keywords
      targetKeywords: $targetKeywords
      supportingKeywords: $supportingKeywords
    ) {
      ...BlogPostBuilderBrief
    }
  }
  ${FRAGMENT_BLOG_POST_BUILDER_BRIEF}
`;

export const updateBlogPostBuilderKeywords = ({
  id,
  keywords,
  targetKeywords,
  supportingKeywords,
}) => {
  const variables = {
    id,
    keywords,
    targetKeywords,
    supportingKeywords: supportingKeywords ? JSON.stringify(supportingKeywords) : null,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then((response) => {
    const parsedRes = getSingleProp(response.data);
    return {
      ...parsedRes,
      supportingKeywords: parsedRes.supportingKeywords
        ? JSON.parse(parsedRes.supportingKeywords)
        : null,
    };
  });
};
