import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CircularProgress } from '@material-ui/core';

import { useStyles } from './useStyles';

const DEFAULT_DELAY = 300;

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  delay: PropTypes.number,
};

const defaultProps = {
  className: null,
  classes: null,
  delay: DEFAULT_DELAY,
};

export const SuspenseFallback = ({ className, classes, delay }) => {
  const styles = useStyles({ classes });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!delay) {
      return;
    }

    setVisible(false);
    const timer = setTimeout(() => setVisible(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  if (!visible) {
    return null;
  }

  return (
    <div className={cx(styles.root, className)}>
      <CircularProgress />
    </div>
  );
};

SuspenseFallback.displayName = 'SuspenseFallback';
SuspenseFallback.propTypes = propTypes;
SuspenseFallback.defaultProps = defaultProps;
