import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { slateToText } from './serialize';

export const getSelectionEdgePoints = (editor) => {
  return Editor.edges(editor, editor.selection);
};

export const getPlainTextSelection = (editor) => {
  if (!editor.selection) return '';

  const fragment = Editor.fragment(editor, editor.selection);
  return slateToText(fragment);
};

/**
 *
 * Completes the selection to the whole context of the selected text.
 */
export const selectCompleteContext = (editor) => {
  const [start, end] = getSelectionEdgePoints(editor);

  Transforms.select(editor, {
    anchor: Editor.point(editor, [start.path[0]], {
      edge: 'start',
    }),
    focus: Editor.point(editor, [end.path[0]], { edge: 'end' }),
  });
};

export const selectCompleteEditor = (editor) => {
  ReactEditor.focus(editor);
  Transforms.select(editor, []);
};

export const selectFocusedLeaf = (editor) => {
  const path = editor.selection?.anchor?.path;
  if (!path) return;

  const pathClone = [...path];

  pathClone.pop();
  const focusedNode = pathClone?.reduce((node, pathPosition) => {
    if (!node) return editor.children[pathPosition];

    return node.children[pathPosition];
  }, null);

  Transforms.select(editor, {
    anchor: { path: editor.selection.anchor.path, offset: 0 },
    focus: { path: editor.selection.focus.path, offset: focusedNode.children[0].text.length },
  });
};

export const selectWholeNode = (editor, node) => {
  const path = ReactEditor.findPath(editor, node);
  try {
    if (Editor.hasPath(editor, path)) {
      // Clear existing selections
      Transforms.deselect(editor);

      // Select the new range
      Transforms.select(editor, Editor.range(editor, path));

      // Focus the editor
      ReactEditor.focus(editor);
    }
  } catch (e) {
    //nothing
  }
};

export const isEntireVariationSelected = (editor) => {
  const context = getPlainTextSelectionContext(editor);
  const selection = getPlainTextSelection(editor);
  return context === selection;
};

export const getPlainTextSelectionContext = (editor) => {
  if (!editor.selection) return '';

  const [start, end] = getSelectionEdgePoints(editor);

  const fragment = Editor.fragment(editor, {
    anchor: Editor.point(editor, [start.path[0]], {
      edge: 'start',
    }),
    focus: Editor.point(editor, [end.path[0]], { edge: 'end' }),
  });
  return slateToText(fragment);
};
