import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: ({ align }) => align,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: theme.spacing(0.5),
      padding: theme.spacing(0, 1),
    },
    avatar: {
      width: 24,
      height: 24,
      cursor: 'pointer',
    },
    button: {
      ...theme.typography.medium12,
      lineHeight: theme.typography.pxToRem(12),
      cursor: 'pointer',
    },
  }),
  { name: 'ButtonAddPersona' }
);
