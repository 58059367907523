import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconAlignCenter = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 9.71094C0.5 9.29672 0.835786 8.96094 1.25 8.96094H12.625C13.0392 8.96094 13.375 9.29672 13.375 9.71094C13.375 10.1252 13.0392 10.4609 12.625 10.4609H1.25C0.835786 10.4609 0.5 10.1252 0.5 9.71094Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6.19922C3.5 5.78501 3.76168 5.44922 4.08449 5.44922H9.91551C10.2383 5.44922 10.5 5.78501 10.5 6.19922C10.5 6.61343 10.2383 6.94922 9.91551 6.94922H4.08449C3.76168 6.94922 3.5 6.61343 3.5 6.19922Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 2.71094C0.5 2.29672 0.835786 1.96094 1.25 1.96094H12.625C13.0392 1.96094 13.375 2.29672 13.375 2.71094C13.375 3.12515 13.0392 3.46094 12.625 3.46094H1.25C0.835786 3.46094 0.5 3.12515 0.5 2.71094Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconAlignCenter.displayName = 'IconAlignCenter';
