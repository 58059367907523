import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ChannelTaboola = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 370 370"
  >
    <g
      transform="translate(-547, -420)"
      fill="#004a7a"
    >
      <path
        d="M649.044,509.872c-25.054,0-28.632,24.34-28.632,42.95c0,18.612,2.861,42.948,27.916,43.666
			c25.051,0,28.631-24.34,28.631-42.95C677.676,534.212,674.098,509.872,649.044,509.872 M648.328,643.73
			c-65.14-0.719-94.488-43.665-94.488-91.625c0-47.958,30.064-90.908,95.204-90.193c65.138,0.716,94.487,43.665,94.487,91.625
			S713.465,644.444,648.328,643.73"
      />
      <path
        d="M813.682,510.59c-25.055,0-28.635,24.335-28.635,42.948c0,18.609,2.864,42.95,27.916,43.664
		c25.057,0,28.634-24.335,28.634-42.948C842.313,535.642,838.732,511.304,813.682,510.59 M812.963,645.16
		c-65.138-0.716-94.485-43.662-94.485-91.622s30.063-90.909,95.204-90.193c65.137,0.716,94.487,43.665,94.487,91.625
		C908.169,602.213,878.105,645.16,812.963,645.16"
      />
      <path
        d="M578.892,643.012c47.243,20.761,95.92,30.066,148.892,30.783c55.834,0,98.066-8.591,151.752-28.635v57.982
			c-47.961,23.621-100.214,35.075-151.752,34.357c-56.55,0-97.352-12.168-148.892-36.506V643.012z"
      />
    </g>
  </SvgIcon>
);

export default ChannelTaboola;
