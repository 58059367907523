import React from 'react';
import { DialogContent } from '@material-ui/core';

import { CustomDialog } from 'src/components/CustomDialog';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { GENERATION_TOOL } from 'src/data/generationTool';
import customModelIllustration from './assets/customModelIllustration.svg';
import { useStyles } from './useStyles';

export const CustomModelPopup = ({ open, onClose, onSubmit, generationTool }) => {
  const styles = useStyles();

  const handleSubmit = () => {
    onSubmit?.(true);
  };

  return (
    <CustomDialog
      className={styles.root}
      data-testid="customModelPopup"
      onClose={onClose}
      open={open}
      title="Train a custom model on your voice"
      submitLabel="Train model"
      onSubmit={handleSubmit}
    >
      <DialogContent className={styles.dialogContent}>
        <img
          className={styles.illustration}
          src={customModelIllustration}
          alt=""
        />
        <CustomTypography
          className={styles.title}
          variant={TYPOGRAPHY_VARIANT.h2}
        >
          Get your tone just right
        </CustomTypography>
        <CustomTypography
          className={styles.description}
          variant={TYPOGRAPHY_VARIANT.paragraph16}
        >
          {generationTool === GENERATION_TOOL.blogDescription ? (
            'Easily train AI on a set of existing blogs or articles.'
          ) : (
            <>
              Easily train AI to generate content that sounds like you.
              <br />
              Use an existing integration or connect a new marketing channel to Anyword.
            </>
          )}
        </CustomTypography>
      </DialogContent>
    </CustomDialog>
  );
};
