import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
    },
    header: {
      display: 'flex',
      columnGap: theme.spacing(0.5),
      alignItems: 'center',
    },
    position: {
      fontSize: theme.typography.pxToRem(22),
      lineHeight: theme.typography.pxToRem(32),
      fontWeight: theme.typography.fontWeightBold,
    },
    comparisonSetName: {
      maxWidth: 146,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    settingsButton: {
      color: COLORS.gray.gray40,
      padding: theme.spacing(0.5),
      marginLeft: 'auto',
      '&:hover, &:active': {
        backgroundColor: 'unset',
      },
    },
    settingsIcon: {
      fontSize: theme.typography.pxToRem(16),
    },
    footer: {
      display: 'flex',
      columnGap: theme.spacing(0.5),
      alignItems: 'center',
    },
    dataSource: {
      maxWidth: 230,
      color: COLORS.gray.gray30,
    },
    userGoal: {
      color: COLORS.gray.gray30,
    },
    bullet: {
      color: COLORS.gray.gray40,
    },
    tooltip: {
      maxWidth: 353,
    },
    tooltipContent: {
      padding: theme.spacing(1, 0),
    },
  }),
  { name: 'ConnectedBenchmark' }
);
