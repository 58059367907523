import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { WORKSPACE_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation UpdateWorkspace(
    $id: ID!
    $name: String!
    $memberEmails: [String!]
    $allowAll: Boolean
    $brandVoiceIds: [ID!]
    $promptIds: [ID!]
    $templateIds: [ID!]
    $integrations: [WorkspaceIntegrationInput!]
    $resourceIds: [ID!]
    $talkingPointsViewIds: [ID!]
    $projectIds: [ID!]
    $defaultBrandVoiceId: ID!
  ) {
    updateWorkspace(
      id: $id
      name: $name
      memberEmails: $memberEmails
      allowAll: $allowAll
      brandVoiceIds: $brandVoiceIds
      promptIds: $promptIds
      templateIds: $templateIds
      integrations: $integrations
      resourceIds: $resourceIds
      talkingPointsViewIds: $talkingPointsViewIds
      projectIds: $projectIds
      defaultBrandVoiceId: $defaultBrandVoiceId
    ) {
      ...Workspace
    }
  }
  ${WORKSPACE_FRAGMENT}
`;

export const updateWorkspace = ({
  id,
  name,
  memberEmails,
  allowAll,
  brandVoiceIds,
  integrations,
  promptIds,
  templateIds,
  resourceIds,
  talkingPointsViewIds,
  projectIds,
  defaultBrandVoiceId,
}) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: {
      id,
      name,
      memberEmails,
      allowAll,
      brandVoiceIds,
      integrations,
      promptIds,
      templateIds,
      resourceIds,
      talkingPointsViewIds,
      projectIds,
      defaultBrandVoiceId,
    },
  }).then(({ data }) => getSingleProp(data));
};
