import { useCallback } from 'react';

import * as events from 'src/lib/events';
import { useNotificationActions, useProjectActions, useProjectFolderActions } from 'src/store';
import { useErrorHandler, useTrackingEvent } from 'src/services';
import { PROJECT_SETTINGS_POPUP, usePopups } from 'src/services/popup';
import { isDocumentProject } from 'src/data/project';

export const useMoveProject = () => {
  const popups = usePopups();
  const notificationActions = useNotificationActions();
  const trackEvent = useTrackingEvent();
  const handleError = useErrorHandler();
  const projectActions = useProjectActions();
  const projectFolderActions = useProjectFolderActions();

  return useCallback(
    (project) => {
      popups.openPopup(PROJECT_SETTINGS_POPUP, {
        name: project.name,
        projectFolderId: project.projectFolder?.id,
        isDocument: isDocumentProject(project.type),
        displayName: false,
        displayMoveToFolder: true,
        onConfirm: async ({ projectFolderId, projectFolder }) => {
          popups.closePopup(PROJECT_SETTINGS_POPUP);

          try {
            await projectActions.moveProject({ id: project.id, projectFolderId });
            projectFolderActions.decreaseFolderProjectCount(project.projectFolder?.id);
            projectFolderActions.increaseFolderProjectCount(projectFolderId);

            trackEvent(events.PROJECT.projectMovedToFolder, {
              page: events.EVENT_PAGE.documents,
            });

            const targetFolderName = projectFolder?.name || 'All documents';
            notificationActions.displaySuccess(
              `‘${project.name}’ has been moved to ‘${targetFolderName}’`
            );
          } catch (error) {
            handleError(error);
          }
        },
        onCancel: () => {
          popups.closePopup(PROJECT_SETTINGS_POPUP);
        },
      });
    },
    [popups, projectActions, projectFolderActions, trackEvent, notificationActions, handleError]
  );
};
