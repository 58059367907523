import { createContainer } from 'react-sweet-state';

import { GenerationToolStore } from './GenerationToolStore';

export const GenerationToolStoreContainer = createContainer(GenerationToolStore, {
  onInit:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
