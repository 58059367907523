export const isEnglishInput = (input, avoidEmoji = false) => {
  const CHARACTERS_THRESHOLD = 1500;
  const ENGLISH_PERCENTAGE_THRESHOLD = 0.9;

  const regex = new RegExp(/^[~`!@#$%^&*()_+=[\]{}|; \n'‘’:",\u00a0.\\/<>?a-zA-Z0-9-]+$/);

  const emogiRegex = new RegExp(
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
  );

  const noSpacesCharCount = input.replace(/\s/g, '').length;
  const englishOnlyCharsCount = input.replace(/\W/g, '').length;

  // for long content, only 90% of it has to be English
  if (noSpacesCharCount > CHARACTERS_THRESHOLD) {
    return englishOnlyCharsCount / noSpacesCharCount > ENGLISH_PERCENTAGE_THRESHOLD;
  } else {
    return !input.length || input.match(regex) || (!avoidEmoji && input.match(emogiRegex));
  }
};
