import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import { queryGenerationToolSettings } from 'src/graphql/generationTool';
import { captureMessage } from 'src/lib/sentry';

const STORE_NAME = 'generation-tools-store';

const INITIAL_STATE = {
  generationToolSettings: [],
  newTools: [],
  improvedTools: [],
  loadingGenerationToolSettings: false,
};

const mapGenerationToolSettings = (generationToolSettings) => {
  const newTools = generationToolSettings
    .filter(({ badgeNew }) => badgeNew)
    .map(({ useCase }) => useCase);
  const improvedTools = generationToolSettings
    .filter(({ badgeImproved }) => badgeImproved)
    .map(({ useCase }) => useCase);
  return { newTools, improvedTools };
};

/**
 * Private actions
 */
const PRIVATE_ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },
};

/**
 * Public actions
 */
const ACTIONS = {
  init:
    () =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.clearStore());
    },

  queryGenerationToolSettings:
    () =>
    async ({ setState }) => {
      setState({ loadingGenerationToolSettings: true });
      try {
        const generationToolSettings = await queryGenerationToolSettings();

        const { newTools, improvedTools } = mapGenerationToolSettings(generationToolSettings);
        setState({ newTools, improvedTools });
      } catch (error) {
        captureMessage('Error while getting generation tools settings', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'GenerationToolStore -> queryGenerationToolSettings',
          },
        });
      } finally {
        setState({ loadingGenerationToolSettings: false });
      }
    },
};

export const GenerationToolStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: STORE_NAME,
});
