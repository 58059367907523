import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    handCursor: {
      cursor: 'pointer',
    },
  }),
  { name: 'CollapsingTooltip' }
);
