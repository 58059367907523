import { useMemo } from 'react';

import * as events from 'src/lib/events';
import { createStateSelectorHook } from 'src/lib/sweetState';
import { UiStore, useUiActions } from 'src/store';
import { useTrackingEvent } from '../useTrackingEvent';

const useUiState = createStateSelectorHook(UiStore, ['textDetailsPanelTab']);

export const useTabProps = () => {
  const uiActions = useUiActions();
  const { textDetailsPanelTab } = useUiState();
  const trackEvent = useTrackingEvent();

  return useMemo(() => {
    const handleSelectTab = (event, newTab) => {
      trackEvent(events.DOCUMENT.insightsPanelTabChanged, {
        page: events.EVENT_PAGE.editor,
        tab: newTab,
      });
      uiActions.setTextDetailsPanelTab(newTab);
    };

    return {
      onSelectTab: handleSelectTab,
      selectedTab: textDetailsPanelTab,
    };
  }, [textDetailsPanelTab, uiActions, trackEvent]);
};
