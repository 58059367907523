import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    header: {
      position: 'relative',
      marginBottom: theme.spacing(0.75),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    label: {
      padding: 0,
      color: theme.palette.text.primary,
    },
    counter: {
      marginLeft: theme.spacing(1),
      borderLeft: theme.shape.borderPrimaryLight,
      paddingLeft: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightRegular,
      color: COLORS.neutral.mediumGray,
    },
    subtitle: {
      marginTop: theme.spacing(0.75),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      color: COLORS.neutral.mediumGray,
      letterSpacing: 'normal',
    },
    inputWrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
    },
    input: {
      width: '100%',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      '& > div > div': {
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
    },

    error: {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.error.main,
      marginTop: '5px',
    },
  }),
  { name: 'FieldChipInput' }
);
