import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},

    resultsFoundTitle: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
    },
    checkAgain: {},
    checkAgainIcon: {},
    disabled: {},
  }),
  { name: 'ResultsFoundTitle' }
);
