import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import { COLORS } from 'src/data/ui';
import { useSvgId } from 'src/lib/hooks';

export const IconTarget = memo(
  forwardRef((props, ref) => {
    const svgId = useSvgId();

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
        data-fill-none
      >
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M7.99998 14.7811C11.6819 14.7811 14.6666 11.7963 14.6666 8.11442C14.6666 4.43252 11.6819 1.44775 7.99998 1.44775C4.31808 1.44775 1.33331 4.43252 1.33331 8.11442C1.33331 11.7963 4.31808 14.7811 7.99998 14.7811Z"
            stroke={COLORS.gray.gray40}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 12.1143C10.2091 12.1143 12 10.3234 12 8.11426C12 5.90512 10.2091 4.11426 8 4.11426C5.79086 4.11426 4 5.90512 4 8.11426C4 10.3234 5.79086 12.1143 8 12.1143Z"
            stroke={COLORS.gray.gray40}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.00002 9.44743C8.7364 9.44743 9.33335 8.85047 9.33335 8.11409C9.33335 7.37772 8.7364 6.78076 8.00002 6.78076C7.26364 6.78076 6.66669 7.37772 6.66669 8.11409C6.66669 8.85047 7.26364 9.44743 8.00002 9.44743Z"
            stroke={COLORS.gray.gray40}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.114258)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
