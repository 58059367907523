import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  () => ({
    root: {
      '&$hasUpgradeAvailable': {
        marginTop: '60px',
      },
    },
    hasUpgradeAvailable: {},

    notification: {},
  }),
  { name: 'NotificationsService' }
);
