import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomTypography } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';
import { getChannelPerformanceLabel } from 'src/data/channel';
import { getResourceDocumentLabel } from 'src/data/performance';

export const PerformanceRowsLimitTooltip = ({ className, connectedAccounts }) => {
  const styles = useStyles();

  return (
    <div className={cx(className, styles.root)}>
      {connectedAccounts?.length > 0 && (
        <>
          <CustomTypography
            className={styles.header}
            variant="semiBold14"
          >
            Data source
          </CustomTypography>
          <CustomTypography variant="regular14">
            {connectedAccounts.map((account) => (
              <span
                key={account?.id}
                className={styles.account}
              >
                &bull; {account?.name} (
                {getChannelPerformanceLabel(account?.channel) ||
                  getResourceDocumentLabel(account?.channel)}
                )
              </span>
            ))}
          </CustomTypography>
          <br />
        </>
      )}
      <CustomTypography
        className={styles.header}
        variant="semiBold14"
      >
        What are rows?
      </CustomTypography>
      <CustomTypography variant="regular14">
        Power your generations with insights from your best performing copy with Anyword’s Copy
        Intelligence Platform. Rows represent the number of copy assets you integrate with Anyword.
        The number of rows is affected by the amount of integrations you have and the granularity of
        the data related to them.
      </CustomTypography>
    </div>
  );
};

PerformanceRowsLimitTooltip.displayName = 'PerformanceRowsLimitTooltip';
PerformanceRowsLimitTooltip.propTypes = {
  className: PropTypes.string,
  connectedAccounts: PropTypes.array,
};
PerformanceRowsLimitTooltip.defaultProps = {
  className: '',
  connectedAccounts: [],
};
