import { useReducer } from 'react';
import { without, uniqueId } from 'lodash';

import createReducer from 'src/utils/createReducer';

const OPEN_POPUP = 'OPEN_POPUP';
const CLOSE_POPUP = 'CLOSE_POPUP';
const CLOSE_ALL_POPUPS = 'CLOSE_ALL_POPUPS';

const INITIAL_STATE = {
  stack: [],
};

export const openPopup = (popupName, popupProps) => ({
  type: OPEN_POPUP,
  popupName,
  popupProps,
});

export const closePopup = (popupName) => ({ type: CLOSE_POPUP, popupName });

export const closeAllPopups = () => ({ type: CLOSE_ALL_POPUPS });

const popupReducer = createReducer(
  {
    [OPEN_POPUP]: (state, { popupName, popupProps }) => {
      return {
        ...state,
        stack: [popupName, ...state.stack],
        [popupName]: {
          open: true,
          key: uniqueId(popupName),
          popupProps,
        },
      };
    },
    [CLOSE_POPUP]: (state, { popupName }) => {
      return {
        ...state,
        stack: without(state.stack, popupName),
        [popupName]: {
          ...state[popupName],
          open: false,
        },
      };
    },
    [CLOSE_ALL_POPUPS]: () => {
      return {
        stack: [],
      };
    },
  },
  INITIAL_STATE
);

export const usePopupReducer = () => useReducer(popupReducer, INITIAL_STATE);
