import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconRefresh = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
        data-fill-none
      >
        <path
          d="M0.5 2V5H3.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 10V7H8.5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.245 4.50004C9.99142 3.78343 9.56043 3.14274 8.99227 2.63775C8.4241 2.13275 7.73727 1.77992 6.99586 1.61217C6.25445 1.44441 5.48262 1.46721 4.7524 1.67842C4.02219 1.88964 3.35737 2.28239 2.82 2.82004L0.5 5.00004M11.5 7.00004L9.18 9.18004C8.64263 9.71768 7.97781 10.1104 7.2476 10.3217C6.51738 10.5329 5.74555 10.5557 5.00414 10.3879C4.26273 10.2202 3.5759 9.86732 3.00773 9.36233C2.43957 8.85734 2.00858 8.21664 1.755 7.50004"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
