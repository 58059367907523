import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { pickChannelIcon } from './utils';
import useStyles from './useStyles';

export const ChannelIcon = ({ className, channel }) => {
  const styles = useStyles();

  const IconClass = useMemo(() => pickChannelIcon(channel), [channel]);
  if (!IconClass) {
    return null;
  }

  return <IconClass className={cx(styles.root, className)} />;
};

ChannelIcon.propTypes = {
  className: PropTypes.string,
  channel: PropTypes.string.isRequired,
};

ChannelIcon.defaultProps = {
  className: null,
};
