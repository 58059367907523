import { useMemo } from 'react';
import { uniqBy } from 'lodash';
import { getResourceTagLabel } from 'src/data/performance';

export const useResourcesUniqueTags = ({ resources }) => {
  return useMemo(() => {
    return uniqBy(
      resources.flatMap((resource) => (resource.tags || []).map((tag) => getResourceTagLabel(tag))),
      (label) => label.toLowerCase()
    );
  }, [resources]);
};
