import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      overflow: 'visible',
      width: 730,
      maxWidth: 750,
      minWidth: 100,
      height: 670,
      minHeight: 40,
      maxHeight: 816,
    },
    popupHeader: {
      marginBottom: theme.spacing(1.5),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
      padding: theme.spacing(0, 6.25),
      color: COLORS.neutral.mediumGray,
      marginBottom: theme.spacing(2),
      whiteSpace: 'pre-wrap',
    },
    targetTabs: {
      margin: theme.spacing(0, 6.25),
      marginBottom: theme.spacing(3.5),
    },
    segmentForm: {
      margin: theme.spacing(0, 6.25),
      border: theme.shape.borderPrimaryLight,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shape.boxShadowBriefContent,
      padding: theme.spacing(3, 4),
    },
    keywords: {
      margin: theme.spacing(3.25, 6.25, 0),
    },
    input: {},
    dialogActions: {
      borderTop: theme.shape.borderPrimaryLight,
      margin: theme.spacing('auto', 0, 0, 0),
      padding: theme.spacing(2, 6.25),
      justifyContent: 'space-between',
    },
    buttonSubmit: {
      height: 'auto',
      padding: theme.spacing(1, 2.5),
      fontSize: theme.typography.pxToRem(16),
    },
    buttonCancel: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
      fontSize: theme.typography.pxToRem(16),
    },
    buttonBack: {
      fontSize: theme.typography.pxToRem(16),
    },
    icon: {
      // overriding ".MuiButton-iconSizeMedium > *:first-child" style
      '&&': {
        fontSize: theme.typography.pxToRem(24),
      },
    },
  }),
  { name: 'PopupAudienceTarget' }
);
