import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { useHistory } from 'src/lib/hooks/useHistory';
import { ROUTE_PARAMS } from 'src/routes';

export const useSupportCreateNewRouteCallback = (callback) => {
  const location = useLocation();
  const history = useHistory();

  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (!callbackRef.current) {
      return;
    }

    if (location.search.includes(ROUTE_PARAMS.createNew)) {
      callbackRef.current();
    }
  }, [location, history]);
};
