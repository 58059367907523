import React, { forwardRef } from 'react';
import cx from 'classnames';
import { Button, Typography } from '@material-ui/core';

import useStyles from './useStyles';

export const TooltipContentAccounts = forwardRef(({ className, onNextClick }, ref) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)} ref={ref}>
      <Typography className={styles.title}>Multiple accounts</Typography>
      <Typography className={styles.description}>
        Switch between all of your accounts here.
      </Typography>

      <div className={styles.footer}>
        <Button className={styles.button} onClick={onNextClick} variant="contained" color="primary">
          Close
        </Button>
      </div>
    </div>
  );
});
