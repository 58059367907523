import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&:hover': {
        '& $icon': {
          opacity: '1',
        },
      },
      '&$active': {
        // && is a workaround, see internals of `TableSortLabel`
        '&& $icon': {
          opacity: '1',
        },
      },
    },
    icon: {
      fontSize: theme.typography.pxToRem(16),
    },
    active: {},
  }),
  { name: 'CustomTableSortLabel' }
);
