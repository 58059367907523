import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Range as SlateRange } from 'slate';
import { ReactEditor, useSlateStatic, useSlateSelection } from 'slate-react';
import { ClickAwayListener, FormControl, OutlinedInput, Popper } from '@material-ui/core';

import { SLATE_TEXT_FORMATS } from 'src/lib/slate';
import { insertLink } from '../../../../utils';
import { useStyles } from './useStyles';

const POPPER_MODIFIERS = {
  offset: {
    offset: '0, 4',
  },
};

export const LinkInsertPopup = ({ onClose }) => {
  const [value, setValue] = useState('');
  const styles = useStyles();
  const editor = useSlateStatic();
  const selection = useSlateSelection();

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const currentSelection = editor.selection || editor.getPreviousSelection();

    // Resets when the selection has a length of 0
    if (!currentSelection || SlateRange.isCollapsed(currentSelection)) {
      setAnchorEl(null);
      onClose();
      return;
    }

    if (!ReactEditor.hasRange(editor, currentSelection)) {
      setAnchorEl(null);
      onClose();
      return;
    }

    const editableElement = ReactEditor.toDOMNode(editor, editor);

    const getBoundingClientRect = () => {
      const editableRect = editableElement.getBoundingClientRect();
      const range = ReactEditor.toDOMRange(editor, currentSelection);
      const rangeRect = range.getBoundingClientRect();

      return new DOMRect(
        rangeRect.x - editableRect.x,
        rangeRect.y - editableRect.y,
        rangeRect.width,
        rangeRect.height
      );
    };

    setAnchorEl({
      clientWidth: getBoundingClientRect().width,
      clientHeight: getBoundingClientRect().height,
      getBoundingClientRect,
    });

    // Find the current URL value
    const selectionPath = selection.anchor.path;
    let currentChild = editor.children[selectionPath[0]];
    let linkChild;

    for (let i = 1; i < selectionPath.length; i++) {
      if (currentChild.type === SLATE_TEXT_FORMATS.LINK) {
        linkChild = currentChild;
        break;
      }
      currentChild = currentChild.children[selectionPath[i]];
    }

    setValue(linkChild?.url);
  }, [editor, selection, onClose]);

  const handleEnterPress = (event) => {
    if (event.key === 'Enter' && !!value.trim()) {
      editor.restoreSelectionFromPrevious();
      insertLink(editor, value);
      onClose();
    }
  };

  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  if (!anchorEl) {
    return null;
  }

  return (
    <ClickAwayListener
      onClickAway={onClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <Popper
        className={styles.root}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={POPPER_MODIFIERS}
        disablePortal
        transition
        open
      >
        <FormControl className={styles.inputForm}>
          <OutlinedInput
            value={value}
            onChange={handleChange}
            className={styles.input}
            placeholder="Insert a URL"
            onKeyDown={handleEnterPress}
            autoFocus
          />
        </FormControl>
      </Popper>
    </ClickAwayListener>
  );
};

LinkInsertPopup.propTypes = {
  onClose: PropTypes.func,
};
