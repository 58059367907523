import { createContainer } from 'react-sweet-state';

import { ProjectFolderStore } from './ProjectFolderStore';

export const ProjectFolderStoreContainer = createContainer(ProjectFolderStore, {
  onInit:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
