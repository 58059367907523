import React, { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const PersonaAvatarTen = memo(
  forwardRef(({ opaque, ...props }, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 72 72"
        data-fill-none
      >
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M72.003 35.9894C71.9961 45.5273 68.2007 54.672 61.4514 61.4127C54.7022 68.1533 45.5516 71.9381 36.0119 71.9346C26.4722 71.9312 17.3244 68.1398 10.58 61.3943C3.83562 54.6487 0.046875 45.5013 0.046875 35.9634C0.046875 26.4255 3.83562 17.2781 10.58 10.5325C17.3244 3.78698 26.4722 -0.00435622 36.0119 -0.00781014C45.5516 -0.0112641 54.7022 3.77345 61.4514 10.5141C68.2007 17.2547 71.9961 26.3994 72.003 35.9373V35.9894Z"
            fill="#8CB2FF"
          />
          <path
            d="M55.17 66.3843V66.4666C49.4099 70.0927 42.7388 72.0098 35.932 71.9953C29.1252 71.9807 22.4624 70.035 16.7179 66.3843C15.0941 48.6908 17.5407 29.4834 21.2436 21.8049C24.7819 14.45 35.0868 14.7324 35.948 14.7681C36.812 14.7324 47.117 14.45 50.6525 21.8049C54.3362 29.4834 56.7938 48.6908 55.17 66.3843Z"
            fill="black"
          />
          <path
            d="M54.7716 40.0317C54.6986 40.4047 54.5759 40.7663 54.4068 41.1067C53.8575 42.204 53.2045 43.2463 52.4566 44.2193C52.0836 44.6909 50.4077 45.2175 50.4077 45.2175L50.3912 45.2805C50.3144 45.5356 50.0264 46.5201 49.7467 47.3784C49.7275 47.4415 49.7055 47.5073 49.6808 47.5731C49.6561 47.639 49.6342 47.7048 49.604 47.787C49.1213 49.0238 47.4509 52.364 47.2863 52.6931V52.7205L46.927 57.3825V57.4757C46.9407 58.0708 47.0888 64.9568 47.418 66.86C47.5395 67.5174 47.7793 68.1474 48.1256 68.7193C48.3224 69.0446 48.5424 69.3553 48.7839 69.6489C40.9877 72.5959 32.4127 72.7596 24.5096 70.1124C24.5837 69.8053 24.666 69.4515 24.7565 69.0676C25.2612 66.8929 25.9661 63.7584 26.1279 62.4503V62.3982C26.1581 62.1678 26.18 61.8854 26.202 61.5755V61.5399C26.3748 58.8359 26.3117 53.4856 26.3117 53.4856L25.6836 51.9307L23.2891 46.1389L22.9133 45.2312C22.0904 45.1955 20.7053 43.86 20.6203 43.7668C20.6203 43.7668 19.0075 41.1396 18.4945 39.0198C18.3217 38.2986 18.2779 37.6486 18.4671 37.155C18.6108 36.7835 18.8156 36.4387 19.0733 36.1349C19.5351 35.5811 20.175 35.205 20.8836 35.0708L21.7915 35.2217L22.6335 28.6181L24.5096 23.83L35.3384 17.7969L42.4534 18.0245L50.0484 24.277L50.2321 27.7378L51.6612 35.3341L53.3453 35.0599L53.5894 35.3506L54.6125 36.5572L54.7743 36.7464C54.7769 36.8565 54.786 36.9664 54.8017 37.0755C54.8932 38.0593 54.8831 39.05 54.7716 40.0317Z"
            fill="#DEE6F7"
          />
          <path
            d="M51.6592 35.34C51.9814 35.1444 52.3554 35.0514 52.7317 35.0735C53.108 35.0955 53.4686 35.2315 53.7657 35.4634C54.3476 35.9472 54.7368 36.6233 54.8629 37.3693C55.0741 38.5106 54.959 39.6883 54.531 40.7671C54.1118 41.8399 53.543 42.8481 52.8414 43.7617C52.3312 44.4528 51.6839 45.174 50.8281 45.2316"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.3633 40.3723C51.3879 39.9353 51.5758 39.5236 51.8898 39.2188C52.2038 38.9139 52.6209 38.7383 53.0584 38.7266"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.7907 35.2222C21.4684 35.0266 21.0941 34.9339 20.7177 34.9564C20.3414 34.979 19.9808 35.1157 19.6842 35.3484C19.1024 35.8321 18.7131 36.5083 18.587 37.2543C18.3728 38.3952 18.483 39.5734 18.9052 40.6548C19.3332 41.7279 19.9093 42.7359 20.6168 43.6494C21.1269 44.3405 21.7742 45.0617 22.6328 45.1193"
            stroke="#080808"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.0857 40.2473C22.0611 39.8103 21.8732 39.3986 21.5592 39.0938C21.2452 38.7889 20.8281 38.6133 20.3906 38.6016"
            stroke="#080808"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.1967 53.1094C46.891 56.4669 46.8113 59.8412 46.9581 63.2094C47.0541 65.2936 47.279 67.4682 48.4228 69.2124"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.3318 53.4609C26.4717 58.9593 26.4662 64.6113 24.5078 69.7532"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.685 35.2578C51.685 48.879 44.9869 59.9196 36.7227 59.9196C28.8808 59.9196 22.4598 49.9732 21.8125 37.3118"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.332 53.4609C26.332 53.4609 28.0408 64.1561 36.4806 64.1561C44.9204 64.1561 46.887 53.9244 46.887 53.9244C46.887 53.9244 42.0788 60.4539 36.7384 60.1989C31.3981 59.9438 26.332 53.4609 26.332 53.4609Z"
            fill="black"
          />
          <path
            d="M31.8551 40.399C31.3779 39.8504 30.6181 39.6639 29.8994 39.6721C29.1837 39.721 28.4725 39.8209 27.771 39.9711C27.0656 40.1194 26.3328 40.0662 25.6562 39.8175"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M30.2799 40.0342C30.3477 40.26 30.3674 40.4975 30.3376 40.7314C30.3078 40.9653 30.2293 41.1903 30.1071 41.3919C30.022 41.5493 29.8869 41.6738 29.7231 41.7458C29.6133 41.7785 29.4968 41.7809 29.3858 41.7529C29.2747 41.7249 29.1733 41.6675 29.0922 41.5867C28.929 41.4231 28.8199 41.2135 28.7795 40.986C28.7085 40.6795 28.7295 40.359 28.8399 40.0644C28.8399 40.0644 29.9864 39.6063 30.2799 40.0342Z"
            fill="black"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.1133 41.8672C29.0038 42.1411 29.9522 42.1667 30.8561 41.9412"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.1618 42.7109L31.9961 44.129"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.6558 38.5312C36.7271 40.5277 35.8329 42.4171 34.9854 44.2271C34.7824 44.6659 34.574 45.1485 34.6892 45.6174C34.7605 45.8653 34.8925 46.0915 35.0732 46.2756C35.3641 46.5899 35.7204 46.8366 36.1171 46.9982C36.5138 47.1598 36.9411 47.2323 37.3689 47.2107"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.6797 50.7289C35.7415 51.3005 36.9489 51.5447 38.1493 51.4304C39.3498 51.3162 40.4895 50.8487 41.4244 50.0871C41.4804 50.0461 41.5272 49.9938 41.5615 49.9335C41.6657 49.7305 41.4875 49.5028 41.3256 49.3438L41.9099 50.5095"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M37.3984 53.5605C37.8245 53.6969 38.2779 53.725 38.7175 53.6421C39.1572 53.5593 39.5692 53.3681 39.9164 53.0859"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.9698 33.6316C33.9698 33.9827 33.9397 34.4161 33.6188 34.5615C33.5148 34.602 33.404 34.6225 33.2924 34.6218C31.0981 34.7672 28.816 35.2417 27.0469 36.5692C28.7666 34.2817 31.046 33.3052 33.9698 33.6316Z"
            fill="black"
          />
          <path
            d="M41.6328 40.399C42.1101 39.8504 42.8698 39.6639 43.5885 39.6721C44.3042 39.721 45.0155 39.8209 45.7169 39.9711C46.4223 40.1194 47.1551 40.0662 47.8317 39.8175"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.2118 40.0342C43.144 40.26 43.1243 40.4975 43.1541 40.7314C43.1839 40.9653 43.2624 41.1903 43.3846 41.3919C43.4697 41.5493 43.6048 41.6738 43.7686 41.7458C43.8784 41.7785 43.9949 41.7809 44.1059 41.7529C44.217 41.7249 44.3184 41.6675 44.3995 41.5867C44.5617 41.4224 44.6707 41.2131 44.7122 40.986C44.7832 40.6795 44.7622 40.359 44.6518 40.0644C44.6518 40.0644 43.5136 39.6063 43.2118 40.0342Z"
            fill="black"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M45.3757 41.8672C44.4852 42.1411 43.5368 42.1667 42.6328 41.9412"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.332 42.7109L41.4923 44.129"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.5234 33.6316C39.5234 33.9827 39.5536 34.4161 39.8745 34.5615C39.9785 34.602 40.0893 34.6225 40.2009 34.6218C42.4089 34.7672 44.6773 35.2417 46.4464 36.5692C44.7266 34.2817 42.4473 33.3052 39.5234 33.6316Z"
            fill="black"
          />
          <path
            d="M21.2383 32.81L21.8911 38.5305C21.8911 38.5305 25.1825 36.2489 26.3318 35.6703C26.2855 32.9737 26.7182 30.2903 27.6099 27.7449C27.6099 27.7449 27.3357 31.1152 27.6099 33.3557C27.6099 33.3557 32.9695 31.7597 36.3651 31.9626C36.4936 31.9656 36.6218 31.9757 36.7491 31.9928L37.3581 25.5099L37.6323 31.9928C37.6323 31.9928 40.4986 31.916 41.4723 32.0202L41.615 29.2203L42.4186 32.0202C42.4186 32.0202 45.6717 33.3557 46.8703 34.3155L47.2515 33.0376L47.4353 35.5057C47.4353 35.5057 50.4333 36.6191 51.3494 37.4747C51.3494 37.4747 54.7258 22.2328 44.7418 18.314C34.7578 14.3952 26.9571 18.3497 24.4941 21.2511C22.031 24.1525 21.2383 32.81 21.2383 32.81Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="72"
              height="72"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);

PersonaAvatarTen.propTypes = {
  opaque: PropTypes.bool,
};
PersonaAvatarTen.defaultProps = {
  opaque: false,
};
