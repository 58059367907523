import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import { sortCustomToneOfVoice } from 'src/data/toneOfVoice';
import {
  queryCustomToneOfVoiceList,
  extractCustomToneOfVoice,
  updateCustomToneOfVoice,
  deleteCustomToneOfVoice,
  createCustomToneOfVoice,
} from 'src/graphql/toneOfVoice';

const INITIAL_STATE = {
  toneOfVoice: [],
  loadingToneOfVoice: false,
  extractingToneOfVoice: false,
  savingToneOfVoice: false,
  deletingToneOfVoice: false,
};

const PRIVATE_ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  removeToneOfVoiceFromCache:
    (itemId) =>
    ({ getState, setState }) => {
      const { toneOfVoice } = getState();
      const filteredList = toneOfVoice.filter((t) => t.id !== itemId);
      setState({ toneOfVoice: filteredList });
    },
};

/**
 * Public actions
 */
const ACTIONS = {
  queryCustomToneOfVoiceList:
    (brandVoiceId, isRefreshingDefaults) =>
    async ({ setState }) => {
      if (!isRefreshingDefaults) {
        setState({ loadingToneOfVoice: true });
      }
      try {
        const result = await queryCustomToneOfVoiceList(brandVoiceId);
        setState({
          toneOfVoice: sortCustomToneOfVoice(result),
        });
      } finally {
        setState({ loadingToneOfVoice: false });
      }
    },

  mergeCustomToneOfVoice:
    (item) =>
    ({ getState, setState }) => {
      const { toneOfVoice } = getState();
      let list = toneOfVoice;
      if (item.isDefault) {
        list = list.map((i) => ({ ...i, isDefault: false }));
      }
      const itemExists = list.some((t) => t.id === item.id);
      const newList = itemExists ? list.map((t) => (t.id === item.id ? item : t)) : [...list, item];
      setState({ toneOfVoice: sortCustomToneOfVoice(newList) });
    },

  getCustomToneOfVoice:
    (id) =>
    ({ getState }) => {
      const { toneOfVoice } = getState();
      return toneOfVoice.find((item) => item.id === id);
    },

  getFirstToneOfVoice:
    () =>
    ({ getState }) => {
      const { toneOfVoice } = getState();
      return toneOfVoice?.[0];
    },

  extractCustomToneOfVoice:
    ({ brandVoiceId, url, text, resourceId, inputType }) =>
    async ({ setState }) => {
      setState({ extractingToneOfVoice: true });
      try {
        const createdToneOfVoice = await extractCustomToneOfVoice({
          brandVoiceId,
          url,
          text,
          resourceId,
          inputType,
        });
        return createdToneOfVoice;
      } finally {
        setState({ extractingToneOfVoice: false });
      }
    },

  createCustomToneOfVoice:
    (item, brandVoiceId) =>
    async ({ setState, dispatch }) => {
      setState({ savingToneOfVoice: true });
      try {
        const createdToneOfVoice = await createCustomToneOfVoice({
          brandVoiceId,
          ...item,
        });
        dispatch(ACTIONS.mergeCustomToneOfVoice(createdToneOfVoice));
        return createdToneOfVoice;
      } finally {
        setState({ savingToneOfVoice: false });
      }
    },

  updateCustomToneOfVoice:
    (item) =>
    async ({ setState, dispatch }) => {
      setState({ savingToneOfVoice: true });
      try {
        const updatedToneOfVoice = await updateCustomToneOfVoice(item);
        dispatch(ACTIONS.mergeCustomToneOfVoice(updatedToneOfVoice));
        return updatedToneOfVoice;
      } finally {
        setState({ savingToneOfVoice: false });
      }
    },

  deleteCustomToneOfVoice:
    (id) =>
    async ({ setState, dispatch }) => {
      setState({ deletingToneOfVoice: true });
      try {
        await deleteCustomToneOfVoice(id);
        dispatch(PRIVATE_ACTIONS.removeToneOfVoiceFromCache(id));
      } finally {
        setState({ deletingToneOfVoice: false });
      }
    },

  isSavingToneOfVoice:
    () =>
    ({ getState }) => {
      return getState().savingToneOfVoice;
    },
  isExtractingToneOfVoice:
    () =>
    ({ getState }) => {
      return getState().extractingToneOfVoice;
    },
};

export const ToneOfVoiceStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'tone-of-voice-store',
});
