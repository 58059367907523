import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ValueProp = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
    <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
    <circle cx="15" cy="15" r="8.55128" stroke="white" strokeWidth="0.897436" />
    <path
      d="M15 10.5L16.3879 13.4656L19.5 13.9377L17.243 16.2541L17.7757 19.5L15 17.9656L12.2103 19.5L12.743 16.2541L10.5 13.9377L13.6121 13.4656L15 10.5Z"
      fill="white"
    />
  </SvgIcon>
));

export default React.memo(ValueProp);
