import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Checkbox, FormControlLabel, Button, Typography } from '@material-ui/core';

import { useEvents } from './useEvents';
import useStyles from './useStyles';

export const AssetActionsOnboardingHint = forwardRef(
  ({ className, onNextClick, dontShowAgain, onDontShowAgainChange }, ref) => {
    const styles = useStyles();
    const events = useEvents();

    const handleClose = () => {
      events.trackCloseClick();
      events.trackDontShowAgainChecked(dontShowAgain);
      onNextClick();
    };

    return (
      <div
        className={cx(styles.root, className)}
        ref={ref}
      >
        <Typography className={styles.title}>
          Start by generating or adding copy to your campaign
        </Typography>

        <div className={styles.actionButtons}>
          <FormControlLabel
            className={styles.showToggle}
            control={<Checkbox color="primary" />}
            label="Don't show again"
            checked={dontShowAgain}
            onChange={onDontShowAgainChange}
          />

          <Button
            className={styles.closeButton}
            color="primary"
            variant="contained"
            onClick={handleClose}
            data-testid="closeButton"
          >
            Close
          </Button>
        </div>
      </div>
    );
  }
);

AssetActionsOnboardingHint.propTypes = {
  className: PropTypes.string,
  onNextClick: PropTypes.func.isRequired,
  dontShowAgain: PropTypes.bool,
  onDontShowAgainChange: PropTypes.func.isRequired,
};

AssetActionsOnboardingHint.defaultProps = {
  className: null,
  dontShowAgain: false,
};
