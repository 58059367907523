// eslint-disable-next-line import/prefer-default-export
export const getDomainUserId = () => {
  const { snowplowKW } = window;

  // check if snow snowplow is active
  if (typeof snowplowKW === 'undefined') {
    return null;
  }

  let domainUserId = null;
  snowplowKW(function () {
    const { cf } = this;
    domainUserId = cf.getDomainUserId();
  });

  return domainUserId;
};
