import React from 'react';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const Loader = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Skeleton
        width="30%"
        className={styles.text}
        animation="wave"
      />
      <Skeleton
        width="85%"
        className={styles.text}
        animation="wave"
      />
      <Skeleton
        width="65%"
        className={styles.text}
        animation="wave"
      />
      <Skeleton
        width="55%"
        className={styles.text}
        animation="wave"
      />
    </div>
  );
};
