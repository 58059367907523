import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { getAssetChannel } from 'src/data/asset';
import { getChannelLabel } from 'src/data/channel';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import {
  CONTENT_SENSITIVITY,
  getContentSensitivityLabel,
  getContentSensitivityInfo,
} from 'src/data/generation';
import { IconInformation } from 'src/assets/icons/IconInformation';
import { InsightBox } from '../InsightBox';
import { useStyles } from './useStyles';

export const ChannelPolicy = ({ classes, assetType, sensitivity, loading, policyTitle }) => {
  const styles = useStyles({ classes });

  const { reasonTitle, reasonDesc, labelDesc, learnMoreLink } = getContentSensitivityInfo(
    policyTitle,
    sensitivity,
    assetType
  );

  const subtitle = useMemo(
    () =>
      !loading && (
        <>
          {reasonTitle}
          {reasonDesc}
        </>
      ),
    [loading, reasonDesc, reasonTitle]
  );

  const channelLabel = getChannelLabel(getAssetChannel(assetType));
  const title = channelLabel ? `Channel policy: ${channelLabel}` : 'Content policy';

  return (
    <div className={styles.root}>
      <Typography className={styles.boxGroupTitle}>{title}</Typography>
      <InsightBox
        className={styles.content}
        loading={loading}
      >
        <CustomTypography
          variant={TYPOGRAPHY_VARIANT.medium14}
          className={styles.badge}
          data-sensitivity={labelDesc}
        >
          {labelDesc === CONTENT_SENSITIVITY.unsafe && (
            <IconInformation className={styles.information} />
          )}
          {getContentSensitivityLabel(labelDesc)}
        </CustomTypography>

        <CustomTypography
          variant={TYPOGRAPHY_VARIANT.paragraph14}
          className={styles.description}
        >
          {sensitivity?.reasonDetails || subtitle}{' '}
          {learnMoreLink && (
            <a
              href={learnMoreLink}
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          )}
        </CustomTypography>
      </InsightBox>
    </div>
  );
};

ChannelPolicy.propTypes = {
  classes: PropTypes.shape({}),
  assetType: PropTypes.string,
  sensitivity: PropTypes.shape({
    label: PropTypes.string,
    reason: PropTypes.string,
    reasonDetails: PropTypes.string,
  }),
  loading: PropTypes.bool,
  horizontalLayout: PropTypes.bool,
  policyTitle: PropTypes.bool,
};

ChannelPolicy.defaultProps = {
  classes: null,
  assetType: null,
  sensitivity: null,
  loading: false,
  horizontalLayout: false,
  policyTitle: false,
};
