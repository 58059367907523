import { useCallback, useMemo } from 'react';
import * as events from 'src/lib/events';
import { PAGES, useTrackingEvent } from 'src/services/useTrackingEvent';

export const useEvents = () => {
  const trackingEvent = useTrackingEvent();

  const trackCloseClick = useCallback(() => {
    trackingEvent(events.PROJECT.clickAssetActionsHintClose, { page: PAGES.onboarding });
  }, [trackingEvent]);

  const trackDontShowAgainChecked = useCallback(
    (checked) => {
      trackingEvent(events.PROJECT.checkedAssetActionsHintDontShow, {
        checked,
        page: PAGES.onboarding,
      });
    },
    [trackingEvent]
  );

  return useMemo(
    () => ({
      trackCloseClick,
      trackDontShowAgainChecked,
    }),
    [trackCloseClick, trackDontShowAgainChecked]
  );
};
