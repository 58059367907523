import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  () => ({
    dot: {
      color: COLORS.primary.active,
      border: '2px solid ' + COLORS.neutral.silver,
      height: 14,
      minWidth: 14,
      borderRadius: 7,
    },
  }),
  { name: 'PersonaAvatar' }
);
