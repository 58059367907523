import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      fontSize: theme.typography.pxToRem(14),
      backgroundColor: COLORS.primary.silver,
      borderRadius: theme.shape.borderRadiusSmall,
      padding: theme.spacing(0, 0.25),
      color: COLORS.primary.active,
    },
  }),
  { name: 'ProductDescriptionNode' }
);
