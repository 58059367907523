import { createContainer } from 'react-sweet-state';

import { ToneOfVoiceStore } from './ToneOfVoiceStore';

export const ToneOfVoiceStoreContainer = createContainer(ToneOfVoiceStore, {
  onInit:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
