import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { CUSTOM_DATASET_FRAGMENT } from '../customModel/schema';

const MUTATION = gql`
  mutation performanceCenterCreateCustomModel(
    $customerId: ID!
    $brandVoiceId: ID!
    $accountId: ID!
    $dataSource: PerformanceCenterDataSource!
  ) {
    performanceCenterCreateCustomModel(
      customerId: $customerId
      brandVoiceId: $brandVoiceId
      accountId: $accountId
      dataSource: $dataSource
    ) {
      ...CustomDataset
    }
  }
  ${CUSTOM_DATASET_FRAGMENT}
`;

export const performanceCenterCreateCustomModel = ({
  customerId,
  brandVoiceId,
  accountId,
  dataSource,
}) => {
  const variables = { customerId, brandVoiceId, accountId, dataSource };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
