import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateProjectBrandVoicesForBrandTerms($id: Int!, $brandVoiceIdsForBrandTerms: [ID!]) {
    updateProject(id: $id, brandVoiceIdsForBrandTerms: $brandVoiceIdsForBrandTerms) {
      id
    }
  }
`;

export const updateProjectBrandVoicesForBrandTerms = ({ id, brandVoiceIdsForBrandTerms }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { id, brandVoiceIdsForBrandTerms },
  }).then(({ data }) => getSingleProp(data));
