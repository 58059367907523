import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { VARIATION_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation ArchiveVariations($ids: [ID!]!) {
    archiveVariations(ids: $ids) {
      variations {
        ...Variation
      }
    }
  }
  ${VARIATION_FRAGMENT}
`;

export const archiveVariations = ({ ids }) => {
  const variables = { ids };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
