import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconHeadingOne = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.42859 11.1431V0.857422V11.1431ZM6.57145 0.857422V11.1431V0.857422ZM1.42859 5.57171H6.57145H1.42859Z"
        fill="#626266"
      />
      <path
        d="M1.42859 11.1431V0.857422M6.57145 0.857422V11.1431M1.42859 5.57171H6.57145"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11V1V1.83334C12 2.7538 11.403 3.5 10.6667 3.5H10"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);

IconHeadingOne.displayName = 'IconHeadingOne';
