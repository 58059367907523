import { makeStyles } from '@material-ui/core/styles';

const HEIGHT = 11;
const GENDER_WIDTH = 124;

const getGenderWidth = (value) => (value / 100) * GENDER_WIDTH;

export const useStyles = makeStyles(
  (theme) => ({
    GenderBar: {
      margin: '0 9px',
      fontSize: theme.typography.pxToRem(9),
      color: '#666',
      display: 'flex',
    },
    Box: {
      display: 'flex',
      height: HEIGHT,
      width: '100%',
      backgroundColor: '#eee',
    },
    BoxElement: {
      flex: 1,
      position: 'relative',
    },
    BoxMale: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    BoxFemale: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    Separator: {
      height: HEIGHT,
      width: 1,
      background: '#fff',
    },
    Label: {
      position: 'absolute',
      top: 0,
      marginTop: -13,
    },
    LabelMale: {
      left: 4,
    },
    LabelFemale: {
      right: 4,
    },
    Value: {
      height: HEIGHT,
      backgroundColor: '#638BD9',
    },
    ValueMale: {
      width: ({ male }) => getGenderWidth(male),
    },
    ValueFemale: {
      width: ({ female }) => getGenderWidth(female),
    },
  }),
  { name: 'HistogramGenderSimple' }
);
