import { makeStyles } from '@material-ui/core/styles';

import { LABEL_HEIGHT, LABEL_WIDTH } from '../../const';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: LABEL_WIDTH,
      height: LABEL_HEIGHT,
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: ({ align }) => align,
      gap: theme.spacing(0.5),

      padding: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadiusSmall,
    },
  }),
  { name: 'LabelSkeleton' }
);
