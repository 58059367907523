import React, { forwardRef } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconFolderOutlined = forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    viewBox="0 0 20 20"
    data-fill-none
  >
    <path
      d="M19.1666 5.83333C19.1666 4.91284 18.4204 4.16667 17.4999 4.16667H7.49992L6.32211 2.98776C6.00944 2.67526 5.58538 2.49983 5.14329 2.5H2.49992C1.57943 2.5 0.833252 3.24617 0.833252 4.16667V15.8333C0.833252 16.7538 1.57943 17.5 2.49992 17.5H17.4999C18.4204 17.5 19.1666 16.7538 19.1666 15.8333V5.83333Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
));
