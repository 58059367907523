import React, { forwardRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { useStyles } from './useStyles';
import { IconCheck } from 'src/assets/icons/IconCheck';
import IconPlus from 'src/assets/icons/Plus';

export const CustomChip = forwardRef(
  (
    {
      className,
      label,
      variant,
      disabled,
      selectable,
      size,
      deleteIcon,
      icon,
      selected,
      onDelete,
      onClick,
      style, // tag positioner custom style
    },
    ref
  ) => {
    const styles = useStyles();
    const isSmallSize = size === 'small';
    return (
      <Chip
        ref={ref}
        style={style}
        className={cx(styles.root, className, styles[variant], styles[size], {
          [styles.selected]: selected,
          [styles.disabled]: disabled,
          [styles.selectable]: selectable,
        })}
        label={label}
        variant={variant}
        disabled={disabled}
        deleteIcon={
          deleteIcon ||
          (onDelete ? (
            <IconPlus
              className={cx(styles.closeIcon, styles.icon, {
                [styles.iconSmall]: isSmallSize,
              })}
            />
          ) : null)
        }
        icon={
          icon ||
          (selected ? (
            <IconCheck
              className={cx(styles.checkIcon, styles.icon, {
                [styles.iconSmall]: isSmallSize,
              })}
            />
          ) : selectable ? (
            <IconPlus
              className={cx(styles.plusIcon, styles.icon, {
                [styles.iconSmall]: isSmallSize,
              })}
            />
          ) : null)
        }
        color={variant === 'default' ? variant : 'primary'}
        onClick={onClick}
        onDelete={onDelete}
      />
    );
  }
);

CustomChip.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  variant: PropTypes.oneOf(['default', 'outlined']),
  disabled: PropTypes.bool,
  selectable: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  deleteIcon: PropTypes.node,
  icon: PropTypes.node,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  style: PropTypes.object,
};

CustomChip.defaultProps = {
  className: null,
  variant: 'default',
  disabled: false,
  selectable: false,
  size: 'medium',
  deleteIcon: null,
  icon: null,
  selected: false,
};
