import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(1),
      padding: theme.spacing(2, 2, 1, 2),
    },
    title: {
      color: COLORS.gray.black,
    },
  }),
  { name: 'HistogramAgeScore' }
);
