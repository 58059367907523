import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation SuggestBlogPostBuilderIntroSection(
    $projectId: ID!
    $assetType: AssetTypeEnum!
    $continueMode: ContinueModeEnum
    $recomposeMode: RecomposeModeEnum
    $generationLength: String!
    $keywords: [String!]
    $instruction: Instruction
    $markedSegment: MarkedSegment
    $debugAcPrompt: String
    $freestyleToolAllInstructionsData: JSON
    $url: String
    $urlExtractions: [UrlExtractionInput!]
    $isParagraphMode: Boolean
  ) {
    suggestBlogPostBuilderIntroSection(
      projectId: $projectId
      assetType: $assetType
      continueMode: $continueMode
      recomposeMode: $recomposeMode
      generationLength: $generationLength
      keywords: $keywords
      instruction: $instruction
      markedSegment: $markedSegment
      debugAcPrompt: $debugAcPrompt
      freestyleToolAllInstructionsData: $freestyleToolAllInstructionsData
      url: $url
      urlExtractions: $urlExtractions
      isParagraphMode: $isParagraphMode
    ) {
      copyText
      keywordsUsage
    }
  }
`;

export const suggestBlogPostBuilderIntroSection = (
  {
    projectId,
    assetType,
    recomposeMode,
    continueMode,
    generationLength,
    keywords,
    instruction,
    markedSegment,
    debugAcPrompt,
    freestyleToolAllInstructionsData,
    url,
    urlExtractions,
    isParagraphMode,
  },
  options = null
) => {
  const variables = {
    projectId,
    assetType,
    recomposeMode,
    continueMode,
    generationLength,
    keywords,
    instruction,
    markedSegment,
    debugAcPrompt,
    freestyleToolAllInstructionsData: freestyleToolAllInstructionsData
      ? JSON.stringify(freestyleToolAllInstructionsData)
      : null,
    url,
    urlExtractions,
    isParagraphMode,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  }).then((response) => {
    const parsedRes = getSingleProp(response.data);
    return {
      ...parsedRes,
      keywordsUsage: parsedRes.keywordsUsage ? JSON.parse(parsedRes.keywordsUsage) : null,
    };
  });
};
