import gql from 'graphql-tag';

import { executeGqlMutation } from 'src/graphql/client';
import { getSingleProp } from 'src/lib/graphql';

const MUTATION = gql`
  mutation FavoriteProject($projectId: ID!, $favorite: Boolean!) {
    favoriteProject(projectId: $projectId, favorite: $favorite) {
      id
      favorite
    }
  }
`;

export const updateProjectFavorite = (projectId, favorite) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { projectId, favorite },
  }).then(({ data }) => getSingleProp(data));
};
