import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CO_ASSET } from './schema';

const MUTATION = gql`
  mutation UpdateLandingPageAsset($id: ID!, $type: String, $status: String) {
    updateLandingPageAsset(id: $id, type: $type, status: $status) {
      ...LandingPageAsset
    }
  }
  ${FRAGMENT_CO_ASSET}
`;

/*
  type Status = draft | active | disabled | archived
 */
export function updateLandingPageAsset(id, { status, type }) {
  const variables = { id };

  if (type) {
    variables.type = type;
  }

  if (status) {
    variables.status = status;
  }

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
}
