import { get } from 'lodash';

export const getEventTitle = (event) => {
  if (event.exception) {
    return get(event.exception, 'values[0].value', '');
  }
  return event.message || '';
};

export class SentryTestException extends Error {
  constructor(message = '') {
    super(message);
    this.name = 'SentryTestException';
    this.message = message;
  }
}
export const isSentryTestException = (error) => error?.type === 'SentryTestException';
