import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 920,

      // for 13" macbook
      '@media screen and (max-height: 800px)': {
        maxHeight: 'calc(100% - 20px)',
      },
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    popupTitle: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'flex-start',
      columnGap: theme.spacing(1),
      color: COLORS.gray.black,
    },
    channelIcon: {
      width: 24,
      height: 24,
    },
    dialogContent: {
      padding: theme.spacing(3, 4, 3),
    },
    customModelSubtitle: {
      marginBottom: theme.spacing(1),
    },
    buttonCancel: {
      marginLeft: 'auto !important',
    },
    processingMessage: {
      marginLeft: theme.spacing(0.5),
      marginRight: 'auto',
      color: COLORS.gray.black,
      ...theme.typography.regular14,
    },
  }),
  { name: 'AdAccountsSelectionPopup' }
);
