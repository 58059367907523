import { useCallback } from 'react';

import { useHistory } from 'src/lib/hooks/useHistory';
import { checkIfEmailDisposable, getDocumentReferrer } from 'src/services/disposableEmails';
import { useNotificationActions } from 'src/store';
import { ROUTES } from 'src/routes';
import * as events from 'src/lib/events';
import { getQueryParams } from 'src/lib/browser';
import { PAGES } from './useTrackingEvent';

export const useIsDisposableEmail = () => {
  const history = useHistory();
  const notificationActions = useNotificationActions();
  const isDisposableEmail = checkIfEmailDisposable();
  const validate = useCallback(() => {
    if (!isDisposableEmail) return false;

    notificationActions.displayError({
      message:
        'Disposable email address. Please provide a genuine email address to start your free trial.',
    });

    const queryParams = getQueryParams(window.location.search);

    events.trackUserEventNoAuth(events.AUTH.disposableEmailBlocked, {
      confirmationToken: queryParams.token,
      referrer: getDocumentReferrer(),
      blockedBy: 'FE',
      page: PAGES.login,
    });

    history.replace(ROUTES.SIGN_UP);

    return true;
  }, [history, notificationActions, isDisposableEmail]);

  return validate;
};
