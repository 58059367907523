import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: '720px',
      backgroundColor: theme.palette.common.white,
      margin: 0,
    },
    buttonClose: {
      position: 'absolute',
      top: '7px',
      right: '7px',
      padding: theme.spacing(2),

      '& svg': {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    title: {
      marginTop: theme.spacing(2.5),
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightMedium,
      whiteSpace: 'pre-wrap',
    },
    dialogContent: {
      padding: theme.spacing(0, 4.25, 3),
    },
    displayVariation: {
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    checkbox: {
      margin: 'auto',
      '& svg': {
        fontSize: theme.typography.pxToRem(12),
      },
      '& rect': {
        stroke: theme.palette.text.primary,
      },
    },
    tocCheckboxLabel: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },
    dialogActions: {
      borderTop: `1px solid ${COLORS.primary.light}`,
      margin: 0,
      justifyContent: 'flex-end',
      padding: theme.spacing(2, 4.25, 2.25),
    },
    checkboxWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
    },
    buttonAction: {
      padding: theme.spacing(1, 2.75),
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  { name: 'ConfirmAddToCampaignPopup' }
);
