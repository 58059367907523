import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ExtractBlogInstructionsFromPdf($sectionId: ID!, $documentId: ID!, $projectId: ID) {
    extractBlogInstructionsFromPdf(
      sectionId: $sectionId
      documentId: $documentId
      projectId: $projectId
    ) {
      instructions
      status
      extractionErrors
    }
  }
`;

export const extractBlogInstructionsBasedOnPdf = (sectionId, documentId, projectId) => {
  const variables = { sectionId, documentId, projectId };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
