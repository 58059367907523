import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    root: {
      borderRadius: '8px',
      margin: 'auto',
    },
    innerContainer: ({ staticHeight, staticWidth }) => ({
      position: 'relative',
      maxHeight: staticHeight || '100%',
      maxWidth: staticWidth || '100%',
    }),
    staticImg: ({ staticHeight, staticWidth }) => ({
      maxHeight: staticHeight || '100%',
      maxWidth: staticWidth || '100%',
    }),
  }),
  { name: 'ImageMagnifier' }
);
