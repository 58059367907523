import React, { createContext, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Slate } from 'slate-react';

import { TranslatedTooltipWrapper } from 'src/components/TranslatedTooltipWrapper';
import { DEFAULT_SLATE_VALUE } from 'src/lib/slate';
import { htmlToSlate } from '../../lib';
import { calculateSlateOutput } from './util';

export const CustomSlateContext = createContext();

export const CustomSlate = ({ editor, value, onChange, convertToHtml, children }) => {
  const [isLinkInsertOpen, setIsLinkInsertOpen] = useState(false);
  const openLinkInsertPopup = useCallback(() => setIsLinkInsertOpen(true), []);
  const closeLinkInsertPopup = useCallback(() => setIsLinkInsertOpen(false), []);

  const [isLinkOpen, setIsLinkOpen] = useState(false);
  const openLinkPopup = useCallback(() => setIsLinkOpen(true), []);
  const closeLinkPopup = useCallback(() => setIsLinkOpen(false), []);

  const contextValue = useMemo(
    () => ({
      isLinkInsertOpen,
      openLinkInsertPopup,
      closeLinkInsertPopup,
      isLinkOpen,
      openLinkPopup,
      closeLinkPopup,
    }),
    [
      isLinkInsertOpen,
      openLinkInsertPopup,
      closeLinkInsertPopup,
      isLinkOpen,
      openLinkPopup,
      closeLinkPopup,
    ]
  );

  const editorValue = useMemo(() => {
    if (!convertToHtml) {
      return value || DEFAULT_SLATE_VALUE;
    }

    return value && value.length ? htmlToSlate(value) : DEFAULT_SLATE_VALUE;
  }, [value, convertToHtml]);

  const handleChange = useCallback(
    (slateValue) => {
      if (!onChange) {
        return;
      }

      const outputValue = convertToHtml ? calculateSlateOutput(editor, slateValue) : slateValue;
      onChange(outputValue);
    },
    [onChange, convertToHtml, editor]
  );

  return (
    <CustomSlateContext.Provider value={contextValue}>
      <TranslatedTooltipWrapper>
        <Slate
          editor={editor}
          value={editorValue}
          onChange={handleChange}
        >
          {children}
        </Slate>
      </TranslatedTooltipWrapper>
    </CustomSlateContext.Provider>
  );
};

CustomSlate.propTypes = {
  editor: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func,
  convertToHtml: PropTypes.bool,
};

CustomSlate.defaultProps = {
  value: null,
  onChange: null,
  convertToHtml: false,
};
