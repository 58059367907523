import React, { forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const CheckboxDefault = forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 1H2.66667C1.74619 1 1 1.74619 1 2.66667V13.3333C1 14.2538 1.74619 15 2.66667 15H13.3333C14.2538 15 15 14.2538 15 13.3333V2.66667C15 1.74619 14.2538 1 13.3333 1ZM2.66667 0H13.3333C14.8061 0 16 1.19391 16 2.66667V13.3333C16 14.8061 14.8061 16 13.3333 16H2.66667C1.19391 16 0 14.8061 0 13.3333V2.66667C0 1.19391 1.19391 0 2.66667 0Z"
      fill="currentColor"
    />
  </SvgIcon>
));
