import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: 310,
      padding: theme.spacing(2.5, 2),
      backgroundColor: theme.palette.background.paper,
      border: theme.shape.border,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',

      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: -24,
        right: 145,
        borderWidth: 12,
        borderStyle: 'solid',
        borderColor: `${COLORS.neutral.white} transparent transparent transparent`,
      },
    },

    title: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(16),
      color: COLORS.neutral.secondary,
      marginBottom: theme.spacing(1),
    },
    actionButtons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    closeButton: {
      borderRadius: 4,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      width: 80,
      minWidth: 0,
      height: 32,
    },

    showToggle: {
      fontSize: theme.typography.pxToRem(14),
      color: COLORS.neutral.secondary,

      '& span': {
        margin: theme.spacing(-0.12),
      },
    },
  }),
  { name: 'AssetActionsOnboardingHint' }
);
