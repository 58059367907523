import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '@material-ui/core/Button';
import IconExpandMore from '@material-ui/icons/ExpandMore';

import Information from 'src/components/Information';
import useStyles from './useStyles';

export const ToggledHeader = ({
  className,
  classes,
  title,
  expanded,
  onClick,
  disabled,
  children,
  hint,
  hideArrow,
  arrowFirst,
  ...restProps
}) => {
  const styles = useStyles({ classes });

  return (
    <div
      className={cx(styles.root, className, {
        [styles.expanded]: expanded,
      })}
      data-testid="ToggledHeader"
      {...restProps}
    >
      <div className={styles.header}>
        <Button
          className={styles.button}
          variant="text"
          onClick={onClick}
          disabled={disabled}
          data-testid="ToggledHeader__Button"
        >
          {!hideArrow && arrowFirst && (
            <IconExpandMore className={cx(styles.icon, styles.firstArrow)} />
          )}

          {title}

          {!hideArrow && !arrowFirst && <IconExpandMore className={styles.icon} />}
        </Button>

        {hint && (
          <Information
            className={styles.hint}
            content={hint}
          />
        )}
      </div>

      {children}
    </div>
  );
};

ToggledHeader.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  title: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  arrowFirst: PropTypes.bool,
  hideArrow: PropTypes.bool,
  hint: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

ToggledHeader.defaultProps = {
  className: null,
  classes: null,
  expanded: false,
  arrowFirst: false,
  hideArrow: false,
  hint: '',
  disabled: false,
  children: null,
};
