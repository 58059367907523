import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
  fragment User on User {
    id
    name
    email
    country
  }
`;

export const USER_INVITE_FRAGMENT = gql`
  fragment UserInvite on UserInvite {
    email
    name
    status
    registered
    invitedBy
    orphaned
  }
`;

export const NORMALIZATION_STATUS_QUERY = gql`
  query NormalizationStatus {
    me {
      id
      normalization {
        id
        status
        isNotificationVisible
      }
    }
  }
`;

export const HIDE_NORMALIZATION_NOTIFICATION_MUTATION = gql`
  mutation HideNormalizationNotification {
    hideNormalizationNotification {
      id
      status
      isNotificationVisible
    }
  }
`;

export const FRAGMENT_ONBOARDING_STAGE = gql`
  fragment UserOnboardingStage on UserOnboardingStage {
    name
    status
    value
  }
`;

export const FRAGMENT_BOOKMARK = gql`
  fragment Bookmark on Bookmark {
    id
    type
    name
    link
    metadata
  }
`;
