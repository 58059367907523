let maskId = 0;
export function generateMaskId() {
  maskId += 1;
  return `mask_${maskId}`;
}

let patternId = 0;
export function generatePatternId() {
  patternId += 1;
  return `pattern_${patternId}`;
}

let imageId = 0;
export function generateImageId() {
  imageId += 1;
  return `image_${imageId}`;
}

let svgId = 0;
export function generateSvgId() {
  svgId += 1;
  return `svg_${svgId}`;
}
