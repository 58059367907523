import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      color: COLORS.gray.gray30,
    },
    options: {
      display: 'flex',
      flexFlow: 'row wrap',
      marginTop: theme.spacing(0.5),
    },
    option: {
      ...theme.typography.regular14,

      '& + &': {
        position: 'relative',
        marginLeft: theme.spacing(1),
        paddingLeft: theme.spacing(1),

        '&:before': {
          content: '""',
          width: 1,
          height: 11,
          backgroundColor: COLORS.primary.default,
          position: 'absolute',
          top: 4,
          left: 0,
        },
      },
    },
  }),
  { name: 'DefaultOptions' }
);
