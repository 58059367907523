import { useCallback } from 'react';

import { useInformationPopup } from 'src/services/popup';

export const RESTRICT_ACTION_TYPE = {
  memberRestrictAccess: 'memberRestrictAccess',
  deleteLastWorkspace: 'deleteLastWorkspace',
  deleteLastBrandVoice: 'deleteLastBrandVoice',
  nonAssociatedBrandVoices: 'nonAssociatedBrandVoices',
  noAssociatedIntegrations: 'noAssociatedIntegrations',
  brandVocabularyWriteAccess: 'brandVocabularyWriteAccess',
};

export const DEFAULT_RESTRICT_TYPE = RESTRICT_ACTION_TYPE.memberRestrictAccess;

export const RESTRICT_ACTION_TYPE_PROPS = {
  [RESTRICT_ACTION_TYPE.memberRestrictAccess]: {
    title: 'Member restrict access',
    content: 'Only Admins can edit Workspaces, Integrations and Resources.',
  },
  [RESTRICT_ACTION_TYPE.deleteLastWorkspace]: {
    title: 'Cannot delete workspace',
    content:
      "It looks like you're trying to delete your last workspace." +
      '\n' +
      'You must have at least one workspace in order to use Anyword.',
  },
  [RESTRICT_ACTION_TYPE.deleteLastBrandVoice]: {
    title: 'Cannot delete brand voice',
    content:
      "It looks like you're trying to delete your last brand voice." +
      '\n' +
      'You must have at least one brand voice in order to use Anyword.',
  },
  [RESTRICT_ACTION_TYPE.nonAssociatedBrandVoices]: {
    title: 'No brand voice selected',
    content:
      'To create a workspace, you need to select at least one brand voice that will be set as default.\nDon’t worry, you can always change this setting later.',
    submitLabel: 'Select brand voice',
  },
  [RESTRICT_ACTION_TYPE.noAssociatedIntegrations]: {
    title: 'No integrations selected',
    content: 'We noticed that you haven’t added any integrations to this workspace.',
    submitLabel: 'I want to add integrations now',
    additionalActionLabel: 'Create the workspace. I will add integrations later.',
  },
  [RESTRICT_ACTION_TYPE.brandVocabularyWriteAccess]: {
    title: 'Member restrict access',
    content: 'Only admins can make changes to the brand vocabulary.',
  },
};

export const useRestrictActionPopup = () => {
  const infoPopup = useInformationPopup();

  return useCallback(
    (
      { actionType = DEFAULT_RESTRICT_TYPE } = {
        actionType: DEFAULT_RESTRICT_TYPE,
      }
    ) =>
      infoPopup({
        title: RESTRICT_ACTION_TYPE_PROPS[actionType].title,
        content: RESTRICT_ACTION_TYPE_PROPS[actionType].content,
        buttons: {
          submitLabel: RESTRICT_ACTION_TYPE_PROPS[actionType].submitLabel || 'Close',
          additionalActionLabel: RESTRICT_ACTION_TYPE_PROPS[actionType].additionalActionLabel,
        },
        dataTestId: 'restrictAction',
      }),
    [infoPopup]
  );
};
