import React, { useRef } from 'react';
import cx from 'classnames';
import { Button } from '@material-ui/core';

import { BOOK_DEMO_ORIGIN } from 'src/data/common';
import * as events from 'src/lib/events';
import { useInviteUsersPopup, useUpgradePopover } from 'src/services/popup';
import { useTrackingEvent } from 'src/services';
import { useCustomerMaxMembers } from 'src/store';
import { useStyles } from './useStyles';

export const InviteUsersButton = ({ className, source, label, upgrade, ...restProps }) => {
  const styles = useStyles();

  const trackingEvent = useTrackingEvent();
  const showInviteUsersPopup = useInviteUsersPopup();
  const buttonRef = useRef();
  const { spendAllInvites } = useCustomerMaxMembers();
  const openUpgradePopover = useUpgradePopover();

  const handleClick = () => {
    if (spendAllInvites) {
      openUpgradePopover({
        referenceElement: buttonRef?.current,
        origin: BOOK_DEMO_ORIGIN.addUser,
      });
      return;
    }

    trackingEvent(events.SETTINGS.clickedInvite, {
      source,
      page: events.EVENT_PAGE.accountSettings,
    });
    showInviteUsersPopup();
  };

  return (
    <Button
      className={cx(styles.root, className)}
      data-testid="inviteUsersButton"
      onClick={handleClick}
      variant="outlined"
      color="primary"
      {...restProps}
      ref={buttonRef}
    >
      {label ? label : 'Invite'}
    </Button>
  );
};
