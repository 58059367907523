import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query QueryCODraftAssets($projectId: Int!, $segmentId: ID) {
    project(id: $projectId) {
      id
      draftAssets(segmentId: $segmentId) {
        edges {
          node {
            id
            type
            cssSelector
            status
            variants {
              id
              isOrigin
              isDisabled
              screenshotBase64
              screenshotUrl
              savedVariation {
                id
                text
              }
            }
          }
        }
      }
    }
  }
`;

export async function queryCODraftAssets({ projectId, segmentId }) {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
      segmentId,
    },
  });

  const project = getSingleProp(result.data);
  return unwrapEdges(project.draftAssets.edges);
}
