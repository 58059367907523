import React from 'react';
import cx from 'classnames';
import { Typography } from '@material-ui/core';

import { AlertIcon } from 'src/assets/icons/Alert';
import { useStyles } from './useStyles';
import { RESOURCE_DOCUMENTS } from 'src/data/performance';

export const ResourceEmptyState = ({ className, channel }) => {
  const styles = useStyles();

  return (
    <div className={cx(className, styles.root)}>
      <Typography className={styles.sorry}>
        <AlertIcon className={styles.icon} />
        Sorry, we couldn't find any talking points in the provided{' '}
        {RESOURCE_DOCUMENTS.includes(channel) ? 'file' : 'link'}.
      </Typography>
      <Typography className={styles.emptyStateTitle}>
        Maximize your campaign's potential with talking points overview.
      </Typography>

      <Typography className={styles.emptyStateDescription}>
        Gain insights on talking points performance and optimize ad copy for better results.
      </Typography>
    </div>
  );
};
