import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { LayoutContainerCentered } from 'src/components/LayoutContainerCentered';
import { isPopupTypeNotification, isImportantNotification } from 'src/data/notifications';
import { PanelNotification } from '../../components/PanelNotification';
import {
  useCurrentNotification,
  useNotificationHandlers,
  useMaintainAppNotificationsHeight,
} from '../../services';
import { useStyles } from './useStyles';

export const GeneralNotifications = ({ onlyImportant, floating }) => {
  const styles = useStyles();

  const currentNotification = useCurrentNotification();
  const { handleCtaClick, handleCloseClick } = useNotificationHandlers();

  const [notificationElement, setNotificationElement] = useState(null);
  useMaintainAppNotificationsHeight(notificationElement, { floating });

  const isPopup = isPopupTypeNotification(currentNotification);
  const isImportant = isImportantNotification(currentNotification);

  // Do not display anything if:
  //   - we don't have anything
  //   - current notification is a "popup" one - these should be rendered by another view
  //   - view is configured to display only "urgent" notifications and the current is not on of those
  if (!currentNotification || isPopup || (onlyImportant && !isImportant)) {
    return null;
  }

  return (
    <div className={cx(styles.root, { [styles.floating]: floating })}>
      <LayoutContainerCentered className={styles.wrapper}>
        <PanelNotification
          className={styles.notification}
          ref={setNotificationElement}
          notification={currentNotification}
          onCtaClick={handleCtaClick}
          onClose={handleCloseClick}
          floating={floating}
        />
      </LayoutContainerCentered>
    </div>
  );
};

GeneralNotifications.propTypes = {
  className: PropTypes.string,
};

GeneralNotifications.defaultProps = {
  className: '',
};
