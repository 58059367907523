import { useRef, useEffect } from 'react';

export const useFreshRef = (value) => {
  // Create initial 'ref'
  const ref = useRef(value);

  // Refresh 'ref' value on every render
  useEffect(() => {
    ref.current = value;
  });

  return ref;
};
