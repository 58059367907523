import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { getIsEmailChannel } from 'src/data/performance';
import { getEmailChannelsFromAssetType, BENCHMARK_SOURCE } from 'src/data/benchmark';
import Information, { INFORMATION_SIZE } from 'src/components/Information';
import { Loader } from './components/Loader';
import { ConnectedBenchmark } from './components/ConnectedBenchmark';
import { Connect } from './components/Connect';
import { Upgrade } from './components/Upgrade';
import { EmptyResults } from './components/EmptyResults';
import { useStyles } from './useStyles';

const MIN_CHANNEL_ACCOUNTS = 2;

export const Benchmark = ({
  classes,
  onBenchmarkConnectClick,
  channel,
  benchmarkConfig,
  connectedAccounts,
  loadingStatus,
  loadingBenchmark,
  source,
  shouldUpgrade,
  variationId,
  projectId,
  featureLimitsData,
  openPopup,
  onBenchmarkUpgradeClick,
  openUpgradePopup,
  assetType,
  loadingGlobalBenchmark,
  globalBenchmarkConfigs,
}) => {
  const styles = useStyles({ classes });

  const isEmailChannel = getIsEmailChannel(channel);

  const { limit, currentCount, limitedAccountData } = featureLimitsData;
  const isFeatureLimited = limit && (!!limitedAccountData?.accountId || limit <= currentCount);

  const emailChannels = useMemo(() => getEmailChannelsFromAssetType(assetType), [assetType]);

  const alignedConnectedAccounts = useMemo(() => {
    if (isEmailChannel) {
      return emailChannels.flatMap((key) => connectedAccounts?.[key] || []);
    }
    return connectedAccounts?.[channel] || [];
  }, [isEmailChannel, connectedAccounts, emailChannels, channel]);

  const filteredAccounts = useMemo(
    () =>
      alignedConnectedAccounts.filter((account) => {
        const comparisonSets = globalBenchmarkConfigs?.find(
          (config) => config.account.id === account.id && config.assetType === assetType
        )?.comparisonSets;

        return comparisonSets?.length > 0;
      }),
    [alignedConnectedAccounts, globalBenchmarkConfigs, assetType]
  );

  const isChannelConnected = filteredAccounts?.length > 0;

  const handleConnectClick = useCallback(() => {
    if (!isChannelConnected && isFeatureLimited) {
      openUpgradePopup(FEATURE_LIMITS.resourcesRowsPerCustomer);
      onBenchmarkUpgradeClick();
      return;
    }
    onBenchmarkConnectClick?.(channel);
  }, [
    onBenchmarkUpgradeClick,
    channel,
    onBenchmarkConnectClick,
    isChannelConnected,
    openUpgradePopup,
    isFeatureLimited,
  ]);

  const isAnyEmailChannelLoading = emailChannels.some((channel) =>
    Object.values(loadingStatus?.[channel] || {}).some((status) => status === true)
  );

  const isChannelLoading = Object.values(loadingStatus?.[channel] || {}).some(
    (status) => status === true
  );

  const isLoadingChannelCopies = isEmailChannel
    ? isAnyEmailChannelLoading && alignedConnectedAccounts.length < MIN_CHANNEL_ACCOUNTS
    : isChannelLoading;

  const hasPosition = benchmarkConfig?.position > 0;

  const connectedAndHasBenchmark =
    isChannelConnected && hasPosition && !benchmarkConfig?.shouldUpgrade && !isLoadingChannelCopies;

  const shouldShowUpgradeSection = shouldUpgrade && connectedAndHasBenchmark;

  const emptyResults =
    (!(hasPosition || isLoadingChannelCopies) &&
      isChannelConnected &&
      !benchmarkConfig?.shouldUpgrade) ||
    benchmarkConfig?.shouldUpgrade;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <CustomTypography className={styles.boxGroupTitle}>Benchmarking</CustomTypography>
        <Information
          className={styles.informationIcon}
          content={'See how this text compares with\ntext you published in the past.'}
          size={INFORMATION_SIZE.small}
          displayInvertedIcon
        />
      </div>
      <div>
        {loadingBenchmark || loadingGlobalBenchmark ? (
          <Loader />
        ) : (
          <div
            className={cx(styles.container, {
              [styles.shouldUpgrade]: shouldShowUpgradeSection,
              [styles.isInfinity]: source === BENCHMARK_SOURCE.infinity,
            })}
          >
            {!(isChannelConnected || isLoadingChannelCopies) && (
              <Connect
                channel={channel}
                onConnectClick={handleConnectClick}
              />
            )}

            {isLoadingChannelCopies && (
              <>
                <CustomTypography
                  className={styles.subtitle}
                  variant={TYPOGRAPHY_VARIANT.regular14}
                >
                  Gathering and categorizing data from your account...
                </CustomTypography>
              </>
            )}

            {connectedAndHasBenchmark && (
              <ConnectedBenchmark
                onSettingsClick={() => openPopup({ channel, assetType })}
                benchmarkConfig={benchmarkConfig}
              />
            )}

            {emptyResults && (
              <EmptyResults
                comparisonSetName={benchmarkConfig?.comparisonSet?.name}
                onSettingsClick={() => openPopup({ channel, assetType })}
              />
            )}
          </div>
        )}
        {shouldShowUpgradeSection && (
          <Upgrade
            onBenchmarkUpgradeClick={onBenchmarkUpgradeClick}
            projectId={projectId}
            variationId={variationId}
          />
        )}
      </div>
    </div>
  );
};

Benchmark.propTypes = {
  classes: PropTypes.shape({}),
  loading: PropTypes.bool,
  channel: PropTypes.string.isRequired,
  onBenchmarkConnectClick: PropTypes.func,
  benchmarkConfig: PropTypes.shape({
    account: PropTypes.shape({
      name: PropTypes.string,
      channel: PropTypes.string,
    }),
    comparisonSet: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      examples: PropTypes.array,
      userGoal: PropTypes.string,
    }),
    position: PropTypes.number,
    shouldUpgrade: PropTypes.bool,
  }),
  connectedAccounts: PropTypes.shape({}),
  loadingStatus: PropTypes.shape({}),
  loadingBenchmark: PropTypes.bool,
  source: PropTypes.string,
  resourcesCSVDocumentsAccounts: PropTypes.arrayOf(PropTypes.shape({})),
  shouldUpgrade: PropTypes.bool,
  variationId: PropTypes.string,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  featureLimitsData: PropTypes.shape({
    limit: PropTypes.number,
    currentCount: PropTypes.number,
    limitedAccountData: PropTypes.shape({
      accountId: PropTypes.string,
    }),
  }),
  openPopup: PropTypes.func.isRequired,
  onBenchmarkUpgradeClick: PropTypes.func.isRequired,
  openUpgradePopup: PropTypes.func.isRequired,
  assetType: PropTypes.string,
  loadingGlobalBenchmark: PropTypes.bool,
  globalBenchmarkConfigs: PropTypes.arrayOf(PropTypes.shape({})),
};

Benchmark.defaultValues = {
  loading: null,
  connectedAccounts: null,
  onBenchmarkConnectClick: null,
  benchmarkConfig: null,
  loadingStatus: {},
  loadingBenchmark: false,
  source: null,
  resourcesCSVDocumentsAccounts: null,
  shouldUpgrade: false,
  variationId: null,
  projectId: null,
  featureLimitsData: null,
  assetType: null,
  loadingGlobalBenchmark: false,
  globalBenchmarkConfigs: [],
};
