import React from 'react';

import { SuspenseFallbackPopup } from 'src/components/SuspenseFallbackPopup';
import ErrorBoundary from 'src/services/ErrorBoundary';
import { lazyWithRetry } from 'src/utils/lazy';

const AsyncView = lazyWithRetry(() =>
  import(/* webpackChunkName: "ProjectFolderPopup" */ './Container')
);

export const ProjectFolderPopup = (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<SuspenseFallbackPopup />}>
      <AsyncView {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
