import { GENERATION_TOOL, getToolDefaultGenerationMode } from '../generationTool';
import {
  AI_RECOMPOSE_MODE,
  AI_SUGGESTION_MODE,
  GENERATION_GUIDANCE_TYPE,
  CASING_TYPE,
  CASING_LABELS,
} from './const';
import { getRecomposeModeDescription, getRecomposeModeLabel } from './recomposeMode';
import {
  getSuggestionModeDefaultLabel,
  getSuggestionModeDefaultDescription,
  DEFAULT_SUGGESTION_MODE_LABEL,
} from './suggestionMode';

const AI_RECOMPOSE_MODES = Object.values(AI_RECOMPOSE_MODE);

export const isRecomposeMode = (mode) => AI_RECOMPOSE_MODES.includes(mode);

export const getDefaultSuggestionModeLabel = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.summarizer:
    case GENERATION_TOOL.smsPromotional: {
      const defaultGenerationMode = getToolDefaultGenerationMode(generationTool);
      return getSuggestionModeLabel(defaultGenerationMode);
    }
    case GENERATION_TOOL.landingBenefits:
    case GENERATION_TOOL.landingFeatures:
      return getSuggestionModeLabel(AI_SUGGESTION_MODE.list);
    default:
      return DEFAULT_SUGGESTION_MODE_LABEL;
  }
};

const getDefaultSuggestionModeDescription = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.summarizer:
    case GENERATION_TOOL.smsPromotional: {
      const defaultGenerationMode = getToolDefaultGenerationMode(generationTool);
      return getSuggestionModeDescription(defaultGenerationMode);
    }
    case GENERATION_TOOL.landingBenefits:
    case GENERATION_TOOL.landingFeatures:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.list);
    default:
      return '';
  }
};

export const getDefaultGenerationModeLabel = (mode, generationTool) => {
  if (isRecomposeMode(mode)) {
    return getRecomposeModeLabel(mode, generationTool);
  } else {
    return getDefaultSuggestionModeLabel(generationTool);
  }
};

export const getDefaultGenerationModeDescription = (mode, generationTool) => {
  if (isRecomposeMode(mode)) {
    return getRecomposeModeDescription(mode, generationTool);
  } else {
    return getDefaultSuggestionModeDescription(generationTool);
  }
};

export const getGenerationModeLabel = (mode, generationTool) => {
  if (isRecomposeMode(mode)) {
    return getRecomposeModeLabel(mode, generationTool);
  } else {
    return getSuggestionModeLabel(mode, generationTool);
  }
};

export const getGenerationModeDescription = (mode, generationTool) => {
  if (isRecomposeMode(mode)) {
    return getRecomposeModeDescription(mode, generationTool);
  } else {
    return getSuggestionModeDescription(mode, generationTool);
  }
};

export const getSuggestionModeLabel = (mode, generationTool) => {
  switch (mode) {
    case AI_SUGGESTION_MODE.creative:
      return getCreativeSuggestionModeLabel(generationTool);
    case AI_SUGGESTION_MODE.brand:
      return getBrandSuggestionModeLabel(generationTool);
    case AI_SUGGESTION_MODE.pain:
      return getPainSuggestionModeLabel(generationTool);
    case AI_SUGGESTION_MODE.feature:
      return getFeatureSuggestionModeLabel(generationTool);
    default:
      return getSuggestionModeDefaultLabel(mode);
  }
};

const getSuggestionModeDescription = (mode, generationTool) => {
  switch (mode) {
    case AI_SUGGESTION_MODE.creative:
      return getCreativeSuggestionModeDescription(generationTool);
    case AI_SUGGESTION_MODE.benefit:
      return getBenefitSuggestionModeDescription(generationTool);
    case AI_SUGGESTION_MODE.feature:
      return getFeatureSuggestionModeDescription(generationTool);
    case AI_SUGGESTION_MODE.pain:
      return getPainSuggestionModeDescription(generationTool);
    case AI_SUGGESTION_MODE.brand:
      return getBrandSuggestionModeDescription(generationTool);
    case AI_SUGGESTION_MODE.list:
      return getListSuggestionModeDescription(generationTool);
    case AI_SUGGESTION_MODE.storyTelling:
      return getStoryTellingSuggestionModeDescription(generationTool);
    default:
      return getSuggestionModeDefaultDescription(mode);
  }
};

const getCreativeSuggestionModeLabel = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.twitterCopyProduct:
    case GENERATION_TOOL.facebookPrimaryTextProduct:
    case GENERATION_TOOL.linkedinIntroSingleImage:
      return 'Hook-Value-CTA';

    default:
      return getSuggestionModeDefaultLabel(AI_SUGGESTION_MODE.creative);
  }
};

const getBrandSuggestionModeLabel = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.googleHeadlineProduct:
      return 'Brand';
    default:
      return getSuggestionModeDefaultLabel(AI_SUGGESTION_MODE.brand);
  }
};

const getPainSuggestionModeLabel = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.landingHeadline:
    case GENERATION_TOOL.emailSubject:
      return 'PAS';
    default:
      return getSuggestionModeDefaultLabel(AI_SUGGESTION_MODE.pain);
  }
};

const getFeatureSuggestionModeLabel = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.landingHeadline:
      return 'FAB';
    default:
      return getSuggestionModeDefaultLabel(AI_SUGGESTION_MODE.feature);
  }
};

const getCreativeSuggestionModeDescription = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.facebookPrimaryTextProduct:
    case GENERATION_TOOL.linkedinIntroSingleImage:
    case GENERATION_TOOL.twitterCopyProduct:
      return 'Short and sweet';
    default:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.creative);
  }
};

const getBenefitSuggestionModeDescription = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.googleHeadlineProduct:
    case GENERATION_TOOL.outbrainHeadlineProduct:
    case GENERATION_TOOL.taboolaHeadlineProduct:
      return `Focus on a benefit of your product\nE.g. "World's Most Comfortable Shoes"`;
    case GENERATION_TOOL.facebookHeadlineProduct:
      return 'Include a benefit of your product or service\nE.g. "Keeps you warm"';

    default:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.benefit);
  }
};

const getFeatureSuggestionModeDescription = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.googleHeadlineProduct:
      return 'Include a feature of your product\nE.g. "Eco-Friendly Comfort Shoes"';
    case GENERATION_TOOL.facebookHeadlineProduct:
      return 'Include a feature of your product or service\nE.g. "Water-Repellent For Rainy Days';
    case GENERATION_TOOL.landingHeadline:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.FAB);
    default:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.feature);
  }
};

const getPainSuggestionModeDescription = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.landingHeadline:
    case GENERATION_TOOL.emailSubject:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.PAS);
    default:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.pain);
  }
};

const getBrandSuggestionModeDescription = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.googleHeadlineProduct:
      return 'Mention the Brand/Product name\nE.g. "Allbirds: Eco-Friendly Comfort"';
    case GENERATION_TOOL.outbrainHeadlineProduct:
    case GENERATION_TOOL.taboolaHeadlineProduct:
      return 'Make sure to include the product or brand name in the copy\nE.g. "Comfortably Sustainable - Allbirds Shoes & Clothing"';
    case GENERATION_TOOL.facebookHeadlineProduct:
      return 'Include your brand or product name\nE.g. "Allbirds New Collection"';

    default:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.brand);
  }
};

const getListSuggestionModeDescription = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.facebookPost:
    case GENERATION_TOOL.tweet:
    case GENERATION_TOOL.instagramCaption:
      return 'Format any message to be a short list of bullet points with emojis.';
    case GENERATION_TOOL.linkedinPost:
      return 'Format any message to be a list of bullet points with emojis.';
    default:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.list);
  }
};

const getStoryTellingSuggestionModeDescription = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.instagramCaption:
      return "Get people to engage with the brand's personal message.";
    default:
      return getSuggestionModeDefaultDescription(AI_SUGGESTION_MODE.storyTelling);
  }
};

export const isRegularTalkingPointType = (type) =>
  ![GENERATION_GUIDANCE_TYPE.campaignKeyword, GENERATION_GUIDANCE_TYPE.hashtag].includes(type);

export const getCasingValue = (value, text) => {
  if (value === CASING_TYPE.default) {
    return null;
  } else {
    const key = Object.keys(CASING_LABELS).find((key) => CASING_LABELS[key] === text);
    return CASING_TYPE[key];
  }
};
