import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { FavoriteStar } from 'src/assets/icons/FavoriteStar';
import { useStyles } from './useStyles';

export const AnnotationRating = forwardRef(
  ({ className, classes, rating, light, ...restProps }, ref) => {
    const styles = useStyles({ classes });

    return (
      <div
        className={cx(styles.root, className)}
        ref={ref}
        {...restProps}
      >
        {[...Array(rating)].map((_, id) => (
          <FavoriteStar
            className={styles.icon}
            key={id}
            full
            light={light}
          />
        ))}
      </div>
    );
  }
);

AnnotationRating.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  rating: PropTypes.number.isRequired,
  size: PropTypes.string,
  light: PropTypes.bool,
};

AnnotationRating.defaultProps = {
  className: '',
  classes: null,
  size: '',
  light: false,
};
