import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_BLOG_POST_BUILDER_BRIEF } from './schema';

const MUTATION = gql`
  mutation UpdateBlogPostBuilderDescription(
    $id: ID!
    $description: String!
    $customerPersonaId: ID
    $tovInput: TovInput
    $blogLength: Int
    $urlExtractions: [UrlExtractionInput!]
    $language: String
    $generationCustomModelId: ID
    $freestyleToolAllInstructionsData: JSON
  ) {
    updateBlogPostBuilderDescription(
      id: $id
      description: $description
      customerPersonaId: $customerPersonaId
      tovInput: $tovInput
      blogLength: $blogLength
      urlExtractions: $urlExtractions
      language: $language
      generationCustomModelId: $generationCustomModelId
      freestyleToolAllInstructionsData: $freestyleToolAllInstructionsData
    ) {
      ...BlogPostBuilderBrief
    }
  }
  ${FRAGMENT_BLOG_POST_BUILDER_BRIEF}
`;

export const updateBlogPostBuilderDescription = ({
  id,
  description,
  customerPersona,
  tovInput,
  blogLength,
  urlExtractions,
  language,
  generationCustomModelId,
  freestyleToolAllInstructionsData,
}) => {
  const variables = {
    id,
    description,
    customerPersonaId: customerPersona?.id,
    tovInput,
    blogLength,
    urlExtractions,
    language,
    generationCustomModelId,
    freestyleToolAllInstructionsData: JSON.stringify(freestyleToolAllInstructionsData),
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then((response) => getSingleProp(response.data));
};
