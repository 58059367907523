import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import cx from 'classnames';
import { isRewriteGenerationTool, isContentImproverTool } from 'src/data/generationTool';
import { DEFAULT_SUGGEST_IMPROVEMENT } from '../../const';
import { Details } from './Details';
import { BaseItem } from './BaseItem';
import { AI_SUGGESTION_MODE, getRecomposeModeLabel } from 'src/data/generation';
import { useStyles } from './useStyles';

const getInstructionsForDisplay = (generationData) => {
  const instructions = generationData.generationGuidance?.genGuideFreeform;
  const avoidWords = generationData.generationGuidance?.avoidWords;
  const generationTool = generationData.generationTool;
  const isRewrite =
    isRewriteGenerationTool(generationTool) || isContentImproverTool(generationTool);
  const originalVariationId = generationData.originalVariationId;

  if (instructions) {
    return instructions;
  } else if (avoidWords) {
    return 'Remove talking points to increase clarity: ' + avoidWords.join(', ');
  } else if (isRewrite) {
    if (originalVariationId) {
      return DEFAULT_SUGGEST_IMPROVEMENT;
    }
    return getRecomposeModeLabel(generationData.recomposeMode, generationTool);
  }
  return DEFAULT_SUGGEST_IMPROVEMENT;
};

const getInstructionLabel = (isDisplayOnlyInstructions) => {
  if (isDisplayOnlyInstructions) {
    return 'Copy was improved based on your prompt';
  }
  return 'Formula';
};

export const GenerationResultDetails = ({
  className,
  generationData,
  displayGenerationTool,
  collapsibleBrief,
  vertical,
  grayish,
  multiline,
  baseItemClasses,
}) => {
  const styles = useStyles();
  const { recomposeMode, suggestionMode } = generationData;

  const isRecompose = !!recomposeMode;
  const isDisplayInstructions =
    (isRecompose || generationData.originalVariationId) &&
    suggestionMode !== AI_SUGGESTION_MODE.personalized;
  const isDisplayOnlyInstructions =
    generationData.originalVariationId && suggestionMode !== AI_SUGGESTION_MODE.personalized;

  return (
    <div
      className={cx(styles.root, className, {
        [styles.grayish]: grayish,
        [styles.vertical]: vertical,
      })}
    >
      {!isDisplayOnlyInstructions && (
        <Details
          className={styles.text}
          generationData={generationData}
          displayGenerationTool={displayGenerationTool}
          collapsibleBrief={collapsibleBrief}
          vertical={vertical}
          multiline={multiline}
          baseItemClasses={baseItemClasses}
          isRecompose={isRecompose}
        />
      )}

      {isDisplayInstructions && (
        <Typography className={cx(styles.text, 'mt-1.5')}>
          <BaseItem
            label={getInstructionLabel(isDisplayOnlyInstructions)}
            vertical={vertical}
            classes={baseItemClasses}
            hideTitleColon={multiline}
          >
            {getInstructionsForDisplay(generationData)}
          </BaseItem>
        </Typography>
      )}
    </div>
  );
};

GenerationResultDetails.propTypes = {
  className: PropTypes.string,
  generationData: PropTypes.shape({
    generationTool: PropTypes.string,
  }).isRequired,
  displayGenerationTool: PropTypes.bool,
  collapsibleBrief: PropTypes.bool,
  vertical: PropTypes.bool,
  grayish: PropTypes.bool,
};

GenerationResultDetails.defaultProps = {
  className: '',
  displayGenerationTool: false,
  collapsibleBrief: false,
  vertical: false,
  grayish: false,
};
