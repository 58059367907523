import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

const ICON_WIDTH = 32;
const DIALOG_TITLE_COLUMN_GAP = 16;

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 800,

      // for 13" macbook
      '@media screen and (max-height: 800px)': {
        maxHeight: 'calc(100% - 20px)',
      },
    },
    dialogTitle: {
      padding: theme.spacing(2.5, 4),
      borderBottom: `1px solid ${COLORS.gray.gray80}`,
      display: 'flex',
      alignItems: 'center',
      columnGap: DIALOG_TITLE_COLUMN_GAP,
    },
    channelIcon: {
      width: ICON_WIDTH,
      height: ICON_WIDTH,
    },
    titleWrapper: {
      maxWidth: `calc(100% - ${ICON_WIDTH + DIALOG_TITLE_COLUMN_GAP}px)`,
    },
    title: {
      color: COLORS.gray.black,
    },
    label: {
      color: COLORS.gray.gray50,
      marginTop: theme.spacing(0.5),
    },
    dialogContent: {
      padding: theme.spacing(0),
    },
    talkingPoints: {
      width: '100%',
    },
    dialogActions: {
      padding: theme.spacing(2.5, 4),
      display: 'flex',
      justifyContent: 'flex-end',
      margin: 0,
    },
    buttonClose: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  { name: 'AdAccountsSelectionPopup' }
);
