import { useMemo } from 'react';

import { useCurrentCustomer } from 'src/store';
import { customerInTrial, customerTrialEnded } from 'src/data/customer';
import { getCustomerPlan, isStarterOrBasicPlan } from 'src/data/subscription';

export const useIsCustomerStarterOrTrialPlan = () => {
  const customer = useCurrentCustomer();
  const plan = getCustomerPlan(customer);

  return useMemo(
    () => customerInTrial(customer) || customerTrialEnded(customer) || isStarterOrBasicPlan(plan),
    [customer, plan]
  );
};
