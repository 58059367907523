import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_MULTI_PROMPT_BLOCKS } from './schema';

const MUTATION = gql`
  mutation CreateCsvMultiPromptBlocks(
    $csvId: ID!
    $description: String!
    $selectedHeaders: [String!]!
    $toolList: JSON!
  ) {
    createCsvMultiPromptBlocks(
      csvId: $csvId
      description: $description
      selectedHeaders: $selectedHeaders
      toolList: $toolList
    ) {
      ...MultiPromptBlocks
    }
  }
  ${FRAGMENT_MULTI_PROMPT_BLOCKS}
`;

export const createCsvMultiPromptBlocks = async ({
  csvId,
  description,
  selectedHeaders,
  toolList,
}) => {
  const variables = {
    csvId,
    description,
    selectedHeaders,
    toolList,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
