import gql from 'graphql-tag';

import { getSingleProp, throwIfResultHasErrors } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { USER_INVITE_FRAGMENT } from 'src/graphql/user/schema';

const QUERY = gql`
  query InviteDetails($token: String!) {
    inviteDetails(token: $token) {
      ...UserInvite
    }
  }
  ${USER_INVITE_FRAGMENT}
`;

export const queryInviteDetails = async (token) => {
  const params = {
    query: QUERY,
    variables: { token },
  };

  const result = await executeGqlQuery(params);
  throwIfResultHasErrors(result);

  return getSingleProp(result.data);
};
