import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    iconCupContainer: {
      marginRight: theme.spacing(1),
      padding: theme.spacing(1.25),
      border: theme.shape.borderGray70,
      borderRadius: theme.shape.borderRadiusSmall,
    },
    iconCup: {
      width: 20,
      height: 20,
    },
    connectButton: {
      marginLeft: 'auto',
      color: COLORS.primary.default,
      backgroundColor: COLORS.gray.gray90,
      ...theme.typography.medium14,
      padding: theme.spacing(0.5, 1.5),
      height: 32,
    },
    connectLabel: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      color: COLORS.gray.black,
    },
    startIcon: {
      marginRight: theme.spacing(0.5),
    },
    iconChannel: {
      fontSize: theme.typography.pxToRem(16),
    },
    iconEmail: {
      color: COLORS.gray.black,
    },
  }),
  { name: 'Connect' }
);
