import { createStore } from 'react-sweet-state';
import { cloneDeep, without } from 'lodash';
import { produce } from 'immer';

import { BRAND_TERM_STATUS, sortBrandTerms } from 'src/data/brandTerm';
import {
  queryBrandTerms,
  createBrandTerm,
  updateBrandTerm,
  deleteBrandTerm,
  activateBrandTermsBatch,
  discardBrandTermsBatch,
} from 'src/graphql/brandTerms';
import { captureMessage } from 'src/lib/sentry';

const INITIAL_STATE = {
  brandTerms: [],
  loadingBrandTerms: false,
  savingBrandTerm: false,
  deletingBrandTerm: false,
  loadedBrandTerms: false,
  processingIds: new Set(),
  appliedDetectedTermIds: new Set(),

  brandRulesEnabled: true,
  brandVoiceIdsForBrandTerms: [],
};

/**
 * Private actions
 */
const PRIVATE_ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  mergeItemInCache:
    (savedItem) =>
    ({ getState, setState }) => {
      const { brandTerms: list } = getState();
      const toolExists = list.some((t) => t.id === savedItem.id);
      const newList = toolExists
        ? list.map((t) => (t.id === savedItem.id ? savedItem : t))
        : sortBrandTerms([...list, savedItem]);
      setState({ brandTerms: newList });
    },

  removeItemsFromCache:
    (ids) =>
    ({ getState, setState }) => {
      const { brandTerms } = getState();
      const filteredList = brandTerms.filter((t) => !ids.includes(t.id));
      setState({ brandTerms: filteredList });
    },

  markProcessing:
    (ids, processing) =>
    ({ getState, setState }) => {
      const currentIds = [...getState().processingIds];
      const newValue = processing ? [...currentIds, ...ids] : without(currentIds, ...ids);
      setState({ processingIds: new Set(newValue) });
    },

  mapItemsInCache:
    (mapper) =>
    ({ getState, setState }) => {
      const { brandTerms } = getState();
      const mappedList = brandTerms.map(mapper);
      setState({ brandTerms: mappedList });
    },
};

/**
 * Public actions
 */
export const ACTIONS = {
  init:
    () =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.clearStore());
    },

  queryBrandTerms:
    (brandVoiceId) =>
    async ({ setState }) => {
      setState({ loadingBrandTerms: true });
      try {
        const result = await queryBrandTerms(brandVoiceId);

        const sortedItems = sortBrandTerms(result);
        setState({
          brandTerms: sortedItems,
          loadedBrandTerms: true,
        });
      } catch (error) {
        captureMessage('Error while getting brand terms', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'BrandTermsStore -> queryBrandTerms',
          },
        });
      } finally {
        setState({ loadingBrandTerms: false });
      }
    },

  getBrandTerms:
    () =>
    ({ getState }) => {
      return getState().brandTerms;
    },

  getBrandTerm:
    (itemId) =>
    ({ getState }) => {
      const { brandTerms } = getState();
      return brandTerms.find((t) => t.id === itemId);
    },

  createBrandTerm:
    (item, brandVoiceId) =>
    async ({ setState, dispatch }) => {
      setState({ savingBrandTerm: true });
      try {
        const createdTool = await createBrandTerm({
          brandVoiceId,
          ...item,
        });
        dispatch(PRIVATE_ACTIONS.mergeItemInCache(createdTool));
        return createdTool;
      } finally {
        setState({ savingBrandTerm: false });
      }
    },

  updateBrandTerm:
    (item) =>
    async ({ setState, dispatch }) => {
      setState({ savingBrandTerm: true });
      try {
        const updatedTool = await updateBrandTerm(item);
        dispatch(PRIVATE_ACTIONS.mergeItemInCache(updatedTool));
        return updatedTool;
      } finally {
        setState({ savingBrandTerm: false });
      }
    },

  deleteBrandTerm:
    (id) =>
    async ({ setState, dispatch }) => {
      setState({ deletingBrandTerm: true });
      try {
        await deleteBrandTerm(id);
        dispatch(PRIVATE_ACTIONS.removeItemsFromCache([id]));
      } finally {
        setState({ deletingBrandTerm: false });
      }
    },

  isSavingBrandTerm:
    () =>
    ({ getState }) => {
      return getState().savingBrandTerm;
    },

  getBrandRulesEnabled:
    () =>
    ({ getState }) => {
      return getState().brandRulesEnabled;
    },

  setBrandRulesEnabled:
    (value) =>
    ({ setState }) => {
      setState({ brandRulesEnabled: value });
    },

  toggleBrandRulesActivity:
    () =>
    ({ getState, setState }) => {
      const { brandRulesEnabled } = getState();
      setState({ brandRulesEnabled: !brandRulesEnabled });
    },

  setBrandVoiceIdsForBrandTerms:
    (value) =>
    ({ setState }) => {
      const newValue = value || [];

      setState({
        brandVoiceIdsForBrandTerms: newValue,
        brandRulesEnabled: newValue.length > 0,
      });
    },

  getBrandVoiceIdsForBrandTerms:
    () =>
    ({ getState }) => {
      return getState().brandVoiceIdsForBrandTerms;
    },

  activateBrandTerms:
    (ids) =>
    async ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.markProcessing(ids, true));
      try {
        await activateBrandTermsBatch({ ids });
        dispatch(
          PRIVATE_ACTIONS.mapItemsInCache((term) =>
            ids.includes(term.id) ? { ...term, status: BRAND_TERM_STATUS.active } : term
          )
        );
      } finally {
        dispatch(PRIVATE_ACTIONS.markProcessing(ids, false));
      }
    },

  discardBrandTerms:
    (ids) =>
    async ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.markProcessing(ids, true));
      try {
        await discardBrandTermsBatch({ ids });
        dispatch(PRIVATE_ACTIONS.removeItemsFromCache(ids));
      } finally {
        dispatch(PRIVATE_ACTIONS.markProcessing(ids, false));
      }
    },

  markDetectedTermAsApplied:
    (detectedTermId) =>
    ({ getState, setState }) => {
      const newValue = produce(getState().appliedDetectedTermIds, (draft) => {
        draft.add(detectedTermId);
      });
      setState({ appliedDetectedTermIds: newValue });
    },

  wasDetectedTermApplied:
    (detectedTermId) =>
    ({ getState }) => {
      const { appliedDetectedTermIds } = getState();
      return appliedDetectedTermIds.has(detectedTermId);
    },
};

export const BrandTermsStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'brand-terms-store',
});
