import { PROJECT_TYPE } from './const';
import { BRIEF_TYPE, isManualBriefType } from '../brief';

const PROJECT_BRIEF_TYPES_ASSOCIATION = {
  [PROJECT_TYPE.continuousOptimization]: BRIEF_TYPE.unifiedProduct,
  [PROJECT_TYPE.wtmHomePage]: BRIEF_TYPE.unifiedProduct,
  [PROJECT_TYPE.blogPostBuilder]: BRIEF_TYPE.blogPostBuilder,
  [PROJECT_TYPE.document]: BRIEF_TYPE.canvas,
};

export const getBriefTypeByProjectType = (projectType) =>
  PROJECT_BRIEF_TYPES_ASSOCIATION[projectType] || [];

export const projectTypeSupportsManualBrief = (projectType) => {
  const briefType = getBriefTypeByProjectType(projectType);
  return isManualBriefType(briefType);
};
