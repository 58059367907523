import { useCallback } from 'react';

import { useNotificationActions, usePerformanceActions } from 'src/store';
import { useErrorHandler } from 'src/services/useErrorHandler';
import { usePopups } from '../services';
import { RENAME_CHANNEL_ACCOUNT_POPUP } from '../types';

export const useRenameChannelAccountPopup = () => {
  const popups = usePopups();
  const performanceActions = usePerformanceActions();
  const notificationActions = useNotificationActions();
  const handleError = useErrorHandler();

  return useCallback(
    (account) => {
      popups.openPopup(RENAME_CHANNEL_ACCOUNT_POPUP, {
        onSubmit: async (newName) => {
          try {
            await performanceActions.renameChannelAccount(account, newName);
            notificationActions.displaySuccess('Name saved');

            popups.closePopup(RENAME_CHANNEL_ACCOUNT_POPUP);
          } catch (error) {
            handleError(error);
          }
        },
        onClose: () => {
          popups.closePopup(RENAME_CHANNEL_ACCOUNT_POPUP);
        },
        account,
      });
    },
    [popups, performanceActions, notificationActions, handleError]
  );
};
