import React, { forwardRef, memo, useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const IconTaboola = memo(
  forwardRef((props, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M9.91096 10.6671C8.34776 10.6574 8.13 12.1779 8.12327 13.3228C8.11654 14.4677 8.31633 16.0129 9.87878 16.0219C11.4412 16.0309 11.6597 14.4909 11.6665 13.3438C11.6732 12.1966 11.4734 10.6753 9.91096 10.6671ZM9.86381 19.0219C5.7998 18.9987 3.98144 16.2995 4.00014 13.2989C4.01885 10.2982 5.86565 7.64247 9.92967 7.66716C13.9937 7.69186 15.8113 10.367 15.7933 13.3677C15.7754 16.3684 13.9256 19.0458 9.86381 19.0219Z"
            fill="#0071B7"
          />
          <path
            d="M20.15 10.6661C18.5875 10.6571 18.3705 12.1769 18.3638 13.3233C18.3571 14.4697 18.5561 16.012 20.1193 16.0217C21.6825 16.0314 21.8988 14.4899 21.9055 13.3435C21.9122 12.1971 21.7124 10.6743 20.15 10.6661ZM20.1013 19.0224C16.0388 18.9977 14.2205 16.2986 14.2384 13.2986C14.2564 10.2987 16.1047 7.64149 20.1672 7.66619C24.2297 7.69088 26.0488 10.3675 26.0316 13.3675C26.0144 16.3674 24.1646 19.0448 20.1013 19.0224Z"
            fill="#0071B7"
          />
          <path
            d="M5.54348 19.0225C8.47232 20.3312 11.5127 20.9127 14.8224 20.9314C18.3012 20.9516 20.9397 20.2946 24.2801 19.0225L24.2607 22.627C21.2675 24.1094 18.0296 24.9093 14.7984 24.8906C11.2814 24.8697 8.73722 24.1468 5.51953 22.627L5.54348 19.0225Z"
            fill="#0071B7"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="22"
              height="22"
              fill="white"
              transform="translate(4 4)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
