import isEmpty from 'validator/lib/isEmpty';
import isLength from 'validator/lib/isLength';
import isURL from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import trim from 'lodash/trim';

import { pluralize } from 'src/utils/format';
import RULES from './rules';

const chars = (count) => pluralize(count, 'character', 'characters');

const validatorRequired = (rule, value) => {
  const ruleMessage = (rule && rule.message) || 'Input needed';

  if (value != null && !isEmpty(trim(value))) {
    return null;
  }
  return ruleMessage;
};

const validatorMaxLength = (rule, value) => {
  const ruleValue = (rule && rule.value) || rule;
  const ruleMessage =
    (rule && rule.message) ||
    `This value is too long. The maximum length is ${ruleValue} ${chars(ruleValue)}`;

  if (isLength(trim(value), { max: ruleValue })) {
    return null;
  }
  return ruleMessage;
};

const validatorOneComma = (rule, value) => {
  const ruleMessage = (rule && rule.message) || 'More than one comma is not allowed in the input';

  const regex = new RegExp(/(,).+(,)/);

  if (value.match(regex)) {
    return ruleMessage;
  }
  return null;
};

const validatorMinLength = (rule, value) => {
  const ruleValue = (rule && rule.value) || rule;
  const ruleMessage =
    (rule && rule.message) ||
    `This value is too short. The minimum length is ${ruleValue} ${chars(ruleValue)}`;

  if (isLength(trim(value), { min: ruleValue })) {
    return null;
  }
  return ruleMessage;
};

const validatorIsUrl = (rule, value) => {
  const ruleMessage =
    (rule && rule.message) ||
    'Specified URL seems to have an incorrect value. Please, re-check the URL';

  if (isURL(value)) {
    return null;
  }
  return ruleMessage;
};

const validatorIsEmail = (rule, value) => {
  const ruleMessage = (rule && rule.message) || 'Please enter a valid email address';

  if (isEmail(value)) {
    return null;
  }
  return ruleMessage;
};

const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 128;
const validatorPassword = (rule, value) => {
  if (!value || isEmpty(trim(value))) {
    return {
      rule: RULES.required,
      message: 'Please enter password',
    };
  }
  if (!isLength(trim(value), { min: MIN_PASSWORD_LENGTH })) {
    return {
      rule: RULES.min_length,
      message: `Password should be at least ${MIN_PASSWORD_LENGTH} characters long`,
    };
  }
  if (!isLength(trim(value), { max: MAX_PASSWORD_LENGTH })) {
    return {
      rule: RULES.max_length,
      message: `Please, use a maximum password length of ${MAX_PASSWORD_LENGTH} or less characters`,
    };
  }
  return null;
};

export default {
  [RULES.required]: validatorRequired,
  [RULES.max_length]: validatorMaxLength,
  [RULES.min_length]: validatorMinLength,
  [RULES.is_url]: validatorIsUrl,
  [RULES.is_email]: validatorIsEmail,
  [RULES.password]: validatorPassword,
  [RULES.one_comma]: validatorOneComma,
};

const COMMON_EMAIL_DOMAINS = [
  'icloud.com',
  'gmail.com',
  'me.com',
  'mac.com',
  'hotmail.com',
  'verizon.com',
  'yahoo.com',
  'live.com',
  'outlook.com',
  'aol.com',
];

export const isCommonEmail = (email) => COMMON_EMAIL_DOMAINS.some((d) => email.endsWith(d));
