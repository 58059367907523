import { clamp } from 'lodash';
import { SCORE_SIZE, LEVEL } from './const';

export const getCircleSize = (sizeName) => {
  switch (sizeName) {
    case SCORE_SIZE.small:
      return '22px';

    case SCORE_SIZE.tableSmall:
      return '24px';

    case SCORE_SIZE.large:
      return '35px';

    case SCORE_SIZE.extraLarge:
      return '40px';

    case SCORE_SIZE.xxl:
      return '48px';

    case SCORE_SIZE.xxxl:
      return '56px';

    case SCORE_SIZE.xxxxl:
      return '87px';

    case SCORE_SIZE.medium:
    default:
      return '32px';
  }
};

const SCORE_THRESHOLDS = [
  { level: LEVEL.low, to: 40 },
  { level: LEVEL.medium, from: 40, to: 65 },
  { level: LEVEL.high, from: 65 },
];

export const getScoreLevel = (score) => {
  for (let i = 0; i < SCORE_THRESHOLDS.length; i += 1) {
    const from = SCORE_THRESHOLDS[i].from || 0;
    const to = SCORE_THRESHOLDS[i].to || Number.MAX_SAFE_INTEGER;
    if (score >= from && score < to) {
      return SCORE_THRESHOLDS[i].level;
    }
  }

  return '';
};

export const getScoreProps = (score, locked, grayScaleLoader) => {
  const preparedScore = score != null && !locked ? Math.round(clamp(score, 0, 100)) : 0;
  const noScoreText = grayScaleLoader ? '--' : '';
  const textScore = score != null ? Math.round(clamp(score, 0, 100)) : noScoreText;
  const scoreLevel = !locked ? getScoreLevel(preparedScore) : '';

  let title;
  switch (scoreLevel) {
    case LEVEL.low:
      title = 'Below average';
      break;
    case LEVEL.high:
      title = 'Higher than average';
      break;
    case LEVEL.medium:
    default:
      title = 'Average';
  }

  return { preparedScore, textScore, scoreLevel, title };
};
