import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ConfirmRegistration($confirmationToken: String!, $confirmationDevice: String) {
    confirmRegistration(
      confirmationToken: $confirmationToken
      confirmationDevice: $confirmationDevice
    ) {
      token
    }
  }
`;

export const confirmRegistration = (confirmationToken, confirmationDevice) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { confirmationToken, confirmationDevice },
  }).then(({ data }) => getSingleProp(data));
