import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { SLATE_EDITOR_ACTIONS } from 'src/lib/slate';
import { getEditorActionLabel } from '../../lib';
import { ToolbarButton } from '../ToolbarButton';
import { EditorActionIcon } from '../EditorActionIcon';
import { useStyles } from './useStyles';

export const EditorActionButton = ({
  className,
  action,
  onAction,
  selected,
  disabled,
  classes,
}) => {
  const styles = useStyles({ classes });
  const label = getEditorActionLabel(action);

  const handleMouseDown = useCallback(
    (event) => {
      onAction(event, action);
    },
    [onAction, action]
  );

  return (
    <ToolbarButton
      className={className}
      classes={{ root: styles.toolbarButton }}
      onMouseDown={handleMouseDown}
      tooltip={label}
      selected={selected}
      disabled={disabled}
    >
      <EditorActionIcon
        className={styles.icon}
        action={action}
      />
    </ToolbarButton>
  );
};

EditorActionButton.propTypes = {
  className: PropTypes.string,
  action: PropTypes.oneOf(SLATE_EDITOR_ACTIONS).isRequired,
  onAction: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.shape(),
};

EditorActionButton.defaultProps = {
  className: '',
  selected: false,
  disabled: false,
  classes: {},
};
