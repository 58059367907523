export class ExtractionError extends Error {
  constructor(message = '', code = null) {
    super(message);
    this.name = 'ExtractionError';
    this.message = message;
    this.code = code;
  }
}

export const isExtractionError = (error) => error instanceof ExtractionError;
