import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    wrapper: {
      '&$empty $root': {
        color: theme.palette.text.hint,
      },
    },
    empty: {},

    root: {
      display: 'flex',
      flexFlow: 'column nowrap',

      padding: theme.spacing(0, 4, 0, 3),
      fontSize: theme.typography.pxToRem(14),

      '& fieldset': {
        borderColor: COLORS.primary.light,
      },
    },

    label: {
      marginBottom: theme.spacing(1.5),

      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.text.primary,
    },

    select_select: {
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    select_icon: {
      color: COLORS.neutral.secondary,
    },

    emptyOption: {
      display: 'none',
    },
    option: {},

    error: {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.error.main,
      marginTop: '5px',
    },
  }),
  { name: 'SelectItems' }
);
