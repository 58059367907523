import { isEmpty } from 'lodash';

import { ROUTE_PARAMS, ROUTES } from 'src/routes';
import { getNextSeatTier, MIN_SEAT_TIER, SUBSCRIPTION_PLAN } from 'src/data/subscription';

const formatParams = (params) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    searchParams.set(key, params[key]);
  });

  return searchParams.toString();
};

export const formatProjectCreateRoute = (projectType, params) => {
  const searchString = params && !isEmpty(params) ? `&${formatParams(params)}` : '';

  return `${ROUTES.PROJECTS}/${projectType}?${ROUTE_PARAMS.creation}${searchString}`;
};

export const formatRoute = (pathname, params) => {
  const searchString = params && !isEmpty(params) ? `?${formatParams(params)}` : '';
  return `${pathname}${searchString}`;
};

export const formatProjectRoute = (projectId, params) => {
  return formatRoute(`${ROUTES.PROJECTS}/${projectId}`, params);
};

export const formatProjectsFolderRoute = (folderId = null, params = null) => {
  if (!folderId) {
    return ROUTES.PROJECTS;
  }

  return formatRoute(ROUTES.PROJECTS, { ...params, [ROUTE_PARAMS.folder]: folderId });
};

const pickTier = (currentTier, sameTier) => {
  if (currentTier) {
    return sameTier ? currentTier : getNextSeatTier(currentTier);
  }

  return MIN_SEAT_TIER;
};

export const formatDDTeamsCheckoutLink = (currentTier, sameTier) => {
  return formatRoute(ROUTES.CHECKOUT, {
    plan: SUBSCRIPTION_PLAN.dd_teams,
    tier: pickTier(currentTier, sameTier),
  });
};

export const formatBusinessSelfServeCheckoutLink = (
  currentTier,
  sameTier,
  projectId,
  variationId
) => {
  const tier = pickTier(currentTier, sameTier);
  return projectId && variationId
    ? formatRoute(ROUTES.CHECKOUT, {
        plan: SUBSCRIPTION_PLAN.business_self_serve,
        tier,
        projectId,
        variationId,
      })
    : formatRoute(ROUTES.CHECKOUT, {
        plan: SUBSCRIPTION_PLAN.business_self_serve,
        tier,
      });
};

export const formatBrandVoiceChildRoute = (brandVoiceId, route) =>
  `${ROUTES.BRAND_VOICE}/${brandVoiceId}${route}`;
