import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    label: {
      ...theme.typography.regular14,
    },
    checkbox: {
      padding: 0,
    },
    icon: {
      fontSize: theme.typography.pxToRem(16),
    },
  }),
  { name: 'FieldCheckbox' }
);
