import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    select: {
      fontSize: theme.typography.pxToRem(13),
    },
    textWrapper: {
      marginTop: theme.spacing(2.5),
    },
    textLabel: {
      marginBottom: theme.spacing(1.5),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: COLORS.neutral.black,
    },
    text: {
      fontSize: theme.typography.pxToRem(13),
      color: COLORS.neutral.black,
    },
    textError: {
      marginTop: theme.spacing(0.75),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.error.main,
    },
  }),
  { name: 'TargetForm' }
);
