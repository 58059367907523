import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormControl, FormLabel, Typography } from '@material-ui/core';

import { SelectItems } from 'src/components/SelectItems';
import CustomInput from 'src/components/CustomInput';
import {
  TARGET_EVALUATION,
  AUDIENCE_TARGET_TYPE,
} from 'src/data/audiencesTargeting';
import { useEvaluationOptions } from './useEvaluationOptions';
import { useValuePlaceholder } from './useValuePlaceholder';
import { useStyles } from './useStyles';

export const TargetForm = ({ className, state, onChange }) => {
  const styles = useStyles();

  const handleEvaluationChange = useCallback(
    (targetEvaluation) => {
      onChange({ targetEvaluation });
    },
    [onChange]
  );

  const handleValueChange = useCallback(
    (event) => {
      onChange({ value: event.target.value });
    },
    [onChange]
  );

  const matchingOptions = useEvaluationOptions(state.targetType);
  const placeholder = useValuePlaceholder(state);

  return (
    <div className={cx(styles.root, className)}>
      <SelectItems
        className={styles.select}
        options={matchingOptions}
        value={state.targetEvaluation}
        onChange={handleEvaluationChange}
        label="Target Evaluation"
        placeholder="Select target evaluation"
        data-testid="FieldEvaluation"
      />

      <FormControl className={styles.textWrapper}>
        <FormLabel className={styles.textLabel}>
          {state.targetType === AUDIENCE_TARGET_TYPE.REFERRER_SOURCE
            ? 'URL'
            : 'Term'}
        </FormLabel>
        <CustomInput
          className={styles.text}
          value={state.value}
          onChange={handleValueChange}
          placeholder={placeholder}
          variant="outlined"
          data-testid="FieldValue"
        />
        {state.urlError && (
          <Typography className={styles.textError}>{state.urlError}</Typography>
        )}
      </FormControl>
    </div>
  );
};

TargetForm.propTypes = {
  className: PropTypes.string,
  state: PropTypes.shape({
    targetType: PropTypes.oneOf(Object.values(AUDIENCE_TARGET_TYPE)),
    targetEvaluation: PropTypes.oneOf(Object.values(TARGET_EVALUATION)),
    value: PropTypes.string,
    urlError: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

TargetForm.defaultProps = {
  className: '',
};
