import { useCallback, useEffect } from 'react';

import { useCustomerActions, useNotificationsCenterActions } from 'src/store';
import { useViewState } from './useViewState';
import { areCustomerNotifications } from './utils';

const NOTIFICATIONS_POOLING_INTERVAL = 60 * 1000; // 1 min
const CUSTOMER_POOLING_INTERVAL = 5 * 60 * 1000; // 5 min

export const BackendDataUpdater = () => {
  const customerActions = useCustomerActions();
  const notificationsCenterActions = useNotificationsCenterActions();
  const { currentCustomerId } = useViewState();

  // Update customer data each `CUSTOMER_POOLING_INTERVAL`
  const refreshCustomer = useCallback(() => {
    if (!customerActions.getCurrentCustomer()) {
      return;
    }
    customerActions.refreshCurrentCustomer();
  }, [customerActions]);

  useEffect(() => {
    refreshCustomer();

    const interval = setInterval(refreshCustomer, CUSTOMER_POOLING_INTERVAL);
    return () => clearInterval(interval);
  }, [refreshCustomer]);

  // Update notifications each `NOTIFICATIONS_POOLING_INTERVAL`
  const pullNotifications = useCallback(async () => {
    if (!currentCustomerId) {
      return;
    }

    const notifications = await notificationsCenterActions.queryNotifications(
      currentCustomerId
    );

    // If one of received notifications is about customer changes, we should refresh customer data right away
    if (areCustomerNotifications(notifications)) {
      refreshCustomer();
    }
  }, [currentCustomerId, notificationsCenterActions, refreshCustomer]);

  useEffect(() => {
    pullNotifications();

    const interval = setInterval(
      pullNotifications,
      NOTIFICATIONS_POOLING_INTERVAL
    );
    return () => clearInterval(interval);
  }, [pullNotifications]);

  return null;
};
