/* eslint-disable no-param-reassign */
import { VOID_ELEMENTS } from '../const';

export const withDividers = (editor) => {
  const { isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === VOID_ELEMENTS.DIVIDER ? true : isVoid(element);
  };

  return editor;
};
