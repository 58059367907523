import { useCallback } from 'react';

import { PROJECT_TYPE } from 'src/data/project';
import { GENERATION_TOOL } from 'src/data/generationTool';
import * as events from 'src/lib/events';
import { useCreateProject, useTrackingEvent } from 'src/services';
import { useGenerationToolPopup } from 'src/services/popup';
import { SIDEBAR_TAB_TYPE } from 'src/views/GenerationToolPopup/const';
import { useGenerationToolPopupSubmitHandler } from 'src/services/useGenerationToolPopupSubmitHandler';

export const useHandlers = ({ openNewProjectMenu, closeNewProjectMenu }) => {
  const openGenerationToolPopup = useGenerationToolPopup();
  const createProject = useCreateProject();
  const trackEvent = useTrackingEvent();

  const handleGenerationToolPopupSubmit = useGenerationToolPopupSubmitHandler();

  const handleNewProjectClick = useCallback(
    (event) => {
      openNewProjectMenu(event.currentTarget);
      trackEvent(events.DASHBOARD.clickedCreateProjectMenuButton, {
        page: events.EVENT_PAGE.homePage,
      });
    },
    [openNewProjectMenu, trackEvent]
  );

  const handleCreateDataDrivenProject = useCallback(() => {
    trackEvent(events.DASHBOARD.clickedCreateDataDrivenButton, {
      page: events.EVENT_PAGE.homePage,
    });

    openGenerationToolPopup({
      selectedTabType: SIDEBAR_TAB_TYPE.mostPopular,
      onSubmit: handleGenerationToolPopupSubmit,
      withBlog: false,
    });

    closeNewProjectMenu();
  }, [closeNewProjectMenu, openGenerationToolPopup, trackEvent, handleGenerationToolPopupSubmit]);

  const handleCreateBlogProject = useCallback(() => {
    trackEvent(events.DASHBOARD.clickedCreateBlogButton, {
      page: events.EVENT_PAGE.homePage,
    });

    createProject(PROJECT_TYPE.blogPostBuilder, {
      generationTool: GENERATION_TOOL.blogDescription,
    });

    closeNewProjectMenu();
  }, [closeNewProjectMenu, createProject, trackEvent]);

  return {
    handleNewProjectClick,
    handleCreateDataDrivenProject,
    handleCreateBlogProject,
  };
};
