import React, { forwardRef, memo, useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';

import { generateMaskId } from 'src/lib/svg';

export const IconQuoraAnswers = memo(
  forwardRef((props, ref) => {
    const maskId = useMemo(() => generateMaskId(), []);

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 30 30"
        data-fill-none
      >
        <g clipPath={`url(#${maskId})`}>
          <path
            d="M26.993 3H3.00703C3.00315 3 3 3.00315 3 3.00703V26.993C3 26.9969 3.00315 27 3.00703 27H26.993C26.9969 27 27 26.9969 27 26.993V3.00703C27 3.00315 26.9969 3 26.993 3Z"
            fill="#B92B27"
          />
          <path
            d="M22.3547 21.0938C22.467 21.2118 22.5427 21.3598 22.5728 21.52C22.6028 21.6801 22.5858 21.8456 22.5238 21.9963C22.4618 22.147 22.3576 22.2765 22.2236 22.3692C22.0896 22.4619 21.9316 22.5139 21.7688 22.5188L14.925 22.5141C13.1955 22.4863 11.5267 21.8726 10.1913 20.7733C8.85592 19.6739 7.93306 18.154 7.57365 16.4621C7.21423 14.7702 7.43952 13.0064 8.21268 11.4591C8.98584 9.91184 10.2611 8.6727 11.8299 7.94431C13.3988 7.21592 15.1683 7.0414 16.8492 7.44927C18.5301 7.85715 20.0229 8.82329 21.0834 10.1897C22.144 11.5561 22.7095 13.2419 22.6875 14.9715C22.6655 16.701 22.0574 18.3719 20.9625 19.7109L22.3547 21.0938ZM19.5938 14.8828C19.5938 13.6707 19.1122 12.5082 18.2551 11.6511C17.398 10.794 16.2356 10.3125 15.0234 10.3125C13.8113 10.3125 12.6488 10.794 11.7917 11.6511C10.9346 12.5082 10.4531 13.6707 10.4531 14.8828C10.4531 16.0949 10.9346 17.2574 11.7917 18.1145C12.6488 18.9716 13.8113 19.4531 15.0234 19.4531C16.2356 19.4531 17.398 18.9716 18.2551 18.1145C19.1122 17.2574 19.5938 16.0949 19.5938 14.8828Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id={maskId}>
            <rect
              x="3"
              y="3"
              width="24"
              height="24"
              rx="4"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
