import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_ROWS_ALLOCATION } from './schema';

const QUERY = gql`
  query CustomerRowsAllocation($id: ID!) {
    customer(id: $id) {
      id
      rowsAllocationPerChannel {
        ...RowsAllocationPerChannel
      }
    }
  }
  ${FRAGMENT_ROWS_ALLOCATION}
`;

/**
 * Get the CIP rows allocation for a customer
 * @param {string} customerId - ID of the customer
 * @param {object} [options] - additional options
 * @param {AbortSignal} [options.abortSignal] - AbortController signal
 * @return {Promise<[object]>} - Promise that resolves to the rows allocation data
 */
export const queryCustomerRowsAllocation = async (customerId, options = null) => {
  const variables = { id: customerId };

  const result = await executeGqlQuery({
    query: QUERY,
    variables,
    options: {
      abortSignal: options?.abortSignal,
    },
  });
  return getSingleProp(result.data);
};
