import { useCallback } from 'react';

import { GRAPHQL_ERROR_CODES } from 'src/data/graphqlErrorCodes';
import { acceptUserInvitation, discardUserInvitation, queryInviteDetails } from 'src/graphql/user';
import { useErrorHandler } from 'src/services';
import { getParsedGraphQLFirstError } from 'src/lib/graphql';

const getInvitationError = (errors) => {
  const errorType = getParsedGraphQLFirstError(errors)?.code;

  return { errorType };
};

export const useInviteHandlers = (token) => {
  const handleError = useErrorHandler();

  const handleTokenValidation = useCallback(async () => {
    if (!token) {
      return { errorType: GRAPHQL_ERROR_CODES.not_found };
    }

    try {
      const { email, name, registered, invitedBy, orphaned } = await queryInviteDetails(token);

      return { token, email, name, registered, invitedBy, orphaned };
    } catch (error) {
      return getInvitationError(error);
    }
  }, [token]);

  const handleInvitationAccept = useCallback(
    async ({ password, name } = {}) => {
      try {
        const { token: authToken, customerId } = await acceptUserInvitation({
          token,
          password,
          name,
        });

        return { authToken, customerId };
      } catch (error) {
        handleError(error);
        return getInvitationError(error);
      }
    },
    [token, handleError]
  );

  const handleInvitationDecline = useCallback(async () => {
    try {
      const { email, name } = await discardUserInvitation(token);

      return { email, name };
    } catch (error) {
      return getInvitationError(error);
    }
  }, [token]);

  return {
    handleTokenValidation,
    handleInvitationAccept,
    handleInvitationDecline,
  };
};
