import React from 'react';

import { SuspenseFallback } from 'src/components/SuspenseFallback';
import ErrorBoundary from 'src/services/ErrorBoundary';
import { lazyWithRetry } from 'src/utils/lazy';

const AsyncPage = lazyWithRetry(() => import(/* webpackChunkName: "SettingsPage" */ './Container'));

export default (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<SuspenseFallback />}>
      <AsyncPage {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
