import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconUser = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.49916 3.01728C6.97499 2.82019 7.48497 2.71875 8 2.71875C8.51503 2.71875 9.02501 2.82019 9.50084 3.01728C9.97666 3.21438 10.409 3.50326 10.7732 3.86744C11.1374 4.23162 11.4262 4.66396 11.6233 5.13979C11.8204 5.61561 11.9219 6.1256 11.9219 6.64062C11.9219 7.15565 11.8204 7.66564 11.6233 8.14146C11.4262 8.61729 11.1374 9.04963 10.7732 9.41381C10.409 9.77799 9.97666 10.0669 9.50084 10.264C9.02501 10.4611 8.51503 10.5625 8 10.5625C7.48497 10.5625 6.97499 10.4611 6.49916 10.264C6.02334 10.0669 5.591 9.77799 5.22682 9.41381C4.86264 9.04963 4.57375 8.61729 4.37666 8.14146C4.17957 7.66564 4.07812 7.15565 4.07812 6.64062C4.07812 6.1256 4.17957 5.61561 4.37666 5.13979C4.57375 4.66396 4.86264 4.23162 5.22682 3.86744C5.591 3.50326 6.02334 3.21438 6.49916 3.01728ZM8 4.21875C7.68195 4.21875 7.36702 4.28139 7.07319 4.4031C6.77935 4.52481 6.51237 4.70321 6.28748 4.9281C6.06258 5.15299 5.88419 5.41998 5.76248 5.71381C5.64077 6.00765 5.57812 6.32258 5.57812 6.64062C5.57812 6.95867 5.64077 7.2736 5.76248 7.56744C5.88419 7.86127 6.06258 8.12826 6.28748 8.35315C6.51237 8.57804 6.77935 8.75644 7.07319 8.87815C7.36702 8.99986 7.68195 9.0625 8 9.0625C8.31805 9.0625 8.63298 8.99986 8.92681 8.87815C9.22065 8.75644 9.48763 8.57804 9.71252 8.35315C9.93742 8.12826 10.1158 7.86127 10.2375 7.56744C10.3592 7.2736 10.4219 6.95867 10.4219 6.64062C10.4219 6.32258 10.3592 6.00765 10.2375 5.71381C10.1158 5.41998 9.93742 5.15299 9.71252 4.9281C9.48763 4.70321 9.22065 4.52481 8.92681 4.4031C8.63298 4.28139 8.31805 4.21875 8 4.21875Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45004 11.3843C6.25813 11.0481 7.12473 10.875 7.99997 10.875C8.87521 10.875 9.74181 11.0481 10.5499 11.3843C11.358 11.7205 12.0916 12.2132 12.7086 12.834C13.0005 13.1278 12.999 13.6027 12.7052 13.8947C12.4114 14.1866 11.9366 14.1851 11.6446 13.8913C11.167 13.4108 10.5992 13.0294 9.9737 12.7692C9.34821 12.509 8.67743 12.375 7.99997 12.375C7.3225 12.375 6.65172 12.509 6.02623 12.7692C5.40074 13.0294 4.83289 13.4108 4.35535 13.8913C4.06338 14.1851 3.58851 14.1866 3.2947 13.8947C3.00089 13.6027 2.9994 13.1278 3.29138 12.834C3.90832 12.2132 4.64195 11.7205 5.45004 11.3843Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.11194 1.0276C6.02757 0.648331 7.00893 0.453125 8 0.453125C8.99107 0.453125 9.97243 0.648331 10.8881 1.0276C11.8037 1.40686 12.6357 1.96276 13.3364 2.66355C14.0372 3.36435 14.5931 4.19631 14.9724 5.11194C15.3517 6.02757 15.5469 7.00893 15.5469 8C15.5469 8.99107 15.3517 9.97243 14.9724 10.8881C14.5931 11.8037 14.0372 12.6357 13.3364 13.3364C12.6357 14.0372 11.8037 14.5931 10.8881 14.9724C9.97243 15.3517 8.99107 15.5469 8 15.5469C7.00893 15.5469 6.02757 15.3517 5.11194 14.9724C4.19631 14.5931 3.36435 14.0372 2.66355 13.3364C1.96276 12.6357 1.40686 11.8037 1.0276 10.8881C0.648331 9.97243 0.453125 8.99107 0.453125 8C0.453125 7.00893 0.648331 6.02757 1.0276 5.11194C1.40686 4.19631 1.96276 3.36435 2.66355 2.66355C3.36435 1.96276 4.19631 1.40686 5.11194 1.0276ZM8 1.95312C7.20591 1.95312 6.4196 2.10953 5.68596 2.41342C4.95232 2.7173 4.28572 3.16271 3.72421 3.72421C3.16271 4.28572 2.7173 4.95232 2.41342 5.68596C2.10953 6.4196 1.95312 7.20591 1.95312 8C1.95312 8.79409 2.10953 9.5804 2.41342 10.314C2.7173 11.0477 3.16271 11.7143 3.72421 12.2758C4.28572 12.8373 4.95232 13.2827 5.68596 13.5866C6.4196 13.8905 7.20591 14.0469 8 14.0469C8.79409 14.0469 9.5804 13.8905 10.314 13.5866C11.0477 13.2827 11.7143 12.8373 12.2758 12.2758C12.8373 11.7143 13.2827 11.0477 13.5866 10.314C13.8905 9.5804 14.0469 8.79409 14.0469 8C14.0469 7.20591 13.8905 6.4196 13.5866 5.68596C13.2827 4.95232 12.8373 4.28572 12.2758 3.72421C11.7143 3.16271 11.0477 2.7173 10.314 2.41342C9.5804 2.10953 8.79409 1.95312 8 1.95312Z"
        fill="currentColor"
      />
    </SvgIcon>
  ))
);
