import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_BRAND_VOICE } from 'src/graphql/brandVoices';

const QUERY = gql`
  query CustomerBrandVoices($id: ID!) {
    customer(id: $id) {
      id
      brandVoices {
        ...BrandVoice
      }
    }
  }
  ${FRAGMENT_BRAND_VOICE}
`;

export const queryCustomerBrandVoices = (id) => {
  return executeGqlQuery({
    query: QUERY,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
};
