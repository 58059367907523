import { useEffect } from 'react';

import { useCurrentCustomer, useCurrentUser } from 'src/store';
import { FEATURE_LIMITS, getFeatureLimitsData } from 'src/data/featureLimits';
import { getCustomerPlan, isStarterPlan } from 'src/data/subscription';
import { ONBOARDING_STAGE, isOnboardingStageDone } from 'src/data/onboarding';

export const useG2ReviewBoostClaimedPopup = ({ isOpen, setIsOpen }) => {
  const currentUser = useCurrentUser();
  const currentCustomer = useCurrentCustomer();
  const featureLimitsData = getFeatureLimitsData(
    currentCustomer,
    FEATURE_LIMITS.boostPerformancesPerCustomer
  );
  const currentPlan = getCustomerPlan(currentCustomer);

  const seenG2ReviewBoostClaimedPopup = isOnboardingStageDone(
    currentUser?.onboardingStages,
    ONBOARDING_STAGE.g2ReviewBoostClaimed
  );

  useEffect(() => {
    if (
      isOpen ||
      !featureLimitsData?.adminUpdated ||
      !isStarterPlan(currentPlan) ||
      seenG2ReviewBoostClaimedPopup
    ) {
      return;
    }

    setIsOpen(true);
  }, [isOpen, featureLimitsData, currentPlan, seenG2ReviewBoostClaimedPopup, setIsOpen]);
};
