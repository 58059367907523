import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

import { CUSTOMER_ITEM_HEIGHT } from './const';

export default makeStyles(
  (theme) => ({
    root: {
      height: CUSTOMER_ITEM_HEIGHT,
      color: COLORS.neutral.secondary,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1.5),
      fontWeight: theme.typography.fontWeightMedium,

      '&$selected': {
        backgroundColor: COLORS.primary.light,
      },

      '&:hover': {
        backgroundColor: COLORS.primary.light,
      },
    },
    disabled: {},
    text: {
      fontSize: theme.typography.pxToRem(13),
      fontWeight: 'inherit',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: 'inherit',
    },
    selected: {},
  }),
  { name: 'CustomerMenuItem' }
);
