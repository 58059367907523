import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_CUSTOMER_RESOURCE } from './schema';

const QUERY = gql`
  query CustomerResource($customerId: ID!, $resourceId: ID) {
    customerResource(customerId: $customerId, resourceId: $resourceId) {
      ...PerformanceCenterCustomerResource
    }
  }
  ${FRAGMENT_CUSTOMER_RESOURCE}
`;

export const queryCustomerResource = (customerId, resourceId) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      customerId,
      resourceId,
    },
  }).then(({ data }) => getSingleProp(data));
};
