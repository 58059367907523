import { createStateSelectorHook } from 'src/lib/sweetState';
import { UserStore } from 'src/store';

const useUserState = createStateSelectorHook(UserStore, ['currentUser', 'currentUserLoading']);

export default function useViewState() {
  const userState = useUserState();

  return {
    ...userState,
  };
}
