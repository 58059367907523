import { JOB_STATUS } from 'src/data/job';
import { promisedWait } from 'src/lib/common';
import { AbortError, JobError } from 'src/lib/errors';
import { captureException } from 'src/lib/sentry';
import { queryJobStatus } from './queryJobStatus';

const DEFAULT_POLLING_INTERVAL = 3000;

/**
 * Job options
 * @typedef JobWaitingOptions
 * @type {object}
 * @property {AbortSignal} abortSignal - tool group
 * @property {number} [pollingInterval] - list of tools
 */

/**
 * Wait for async job on BE to finish
 * @param {Object} params - params
 * @param {ID} params.id - ID of the job
 * @param {JobWaitingOptions} [params.options] - waiting options
 * @returns {Promise<any>} - job result
 */
export const waitForJob = async ({ id, options }) => {
  if (options?.abortSignal?.aborted) {
    throw new AbortError();
  }

  // Query job status
  const response = await queryJobStatus(id);

  if (response.status === JOB_STATUS.completed) {
    return unwrapResult(response.result);
  }

  if (response.status === JOB_STATUS.failed) {
    const { message, code } = unwrapError(response);
    throw new JobError(message, code);
  }

  // Wait sometime and check again
  await promisedWait(options?.pollingInterval || DEFAULT_POLLING_INTERVAL);

  return waitForJob({
    id,
    options,
  });
};

const unwrapResult = (json) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    captureException(error, {
      extra: {
        source: 'src/graphql/waitForJob -> unwrapResult',
        value: json,
      },
    });
    return [];
  }
};

const unwrapError = (response) => {
  const code = response.processingErrors?.[0]?.code || 'general_error';
  const message = response.processingErrors?.[0]?.message;
  return {
    code,
    message,
  };
};
