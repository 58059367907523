import { useCallback, useState } from 'react';

export const useSearchTermState = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = useCallback((value) => {
    setSearchTerm(value);
  }, []);

  const resetSearchTerm = useCallback(() => {
    setSearchTerm('');
  }, []);

  return {
    searchTerm,
    resetSearchTerm,
    handleSearchChange,
  };
};
