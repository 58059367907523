import { useCallback } from 'react';

import {
  useCurrentCustomerId,
  useCustomerActions,
  useNotificationActions,
  useWorkspaceActions,
} from 'src/store';
import { WORKSPACE_POPUP, usePopups } from 'src/services/popup';
import { useErrorHandler } from 'src/services';
import { ROUTES } from 'src/routes';
import { useHistory } from 'src/lib/hooks/useHistory';

export const useCreateOrUpdateWorkspace = () => {
  const popups = usePopups();
  const currentCustomerId = useCurrentCustomerId();
  const customerActions = useCustomerActions();
  const workspaceStoreActions = useWorkspaceActions();
  const notificationActions = useNotificationActions();
  const handleError = useErrorHandler();
  const history = useHistory();

  return useCallback(
    (workspace) => {
      popups.openPopup(WORKSPACE_POPUP, {
        workspace,
        onConfirm: async (item) => {
          popups.closePopup(WORKSPACE_POPUP);

          try {
            if (workspace?.id) {
              await workspaceStoreActions.updateWorkspace({
                ...item,
                id: workspace.id,
              });
              notificationActions.displaySuccess(`Workspace '${item.name}' updated`);
            } else {
              await workspaceStoreActions.createWorkspace({
                ...item,
                customerId: currentCustomerId,
              });
              notificationActions.displaySuccess(`Workspace '${item.name}' created`);
              customerActions.refreshCurrentCustomerFeatureLimit();
              history.push(ROUTES.ROOT);
            }
          } catch (error) {
            handleError(error);
          }
        },
        onCancel: () => {
          popups.closePopup(WORKSPACE_POPUP);
        },
      });
    },
    [
      popups,
      workspaceStoreActions,
      currentCustomerId,
      notificationActions,
      handleError,
      customerActions,
      history,
    ]
  );
};
