import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import { YOUTUBE_VIDEO_ID } from 'src/const';
import { CustomButton, CUSTOM_BUTTON_VARIANT } from 'src/components/CustomButton';
import { YoutubeEmbed } from 'src/components/YoutubeEmbed';
import { useStyles } from './useStyles';

export const DataDrivenDocumentsPopup = ({ open, onComplete }) => {
  const styles = useStyles();

  return (
    <Dialog
      classes={{ paper: styles.paper }}
      open={open}
      maxWidth="lg"
      fullWidth
      data-testid="DataDrivenDocumentsPopup"
    >
      <DialogTitle className={styles.dialogTitle}>
        <Typography className={styles.title}>
          Introducing Our
          <br />
          Integrated Interface
        </Typography>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Typography className={styles.description}>
          You can now generate data-driven content for any marketing channel, <b>and</b> use our
          basic copywriting formats - all in one place.
        </Typography>
        <Typography className={styles.description}>
          To make the transition easier, we’ve turned all of your existing projects into documents,
          which you can find under the “Documents” tab.
        </Typography>

        <YoutubeEmbed
          className={styles.iframe}
          title="explainer video"
          videoId={YOUTUBE_VIDEO_ID.dataDrivenDocuments}
        />

        <CustomButton
          className={styles.gotIt}
          onClick={onComplete}
          variant={CUSTOM_BUTTON_VARIANT.outlined}
        >
          Got it!
        </CustomButton>
      </DialogContent>
    </Dialog>
  );
};

DataDrivenDocumentsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
};

DataDrivenDocumentsPopup.defaultProps = {};
