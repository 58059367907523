import { compact } from 'lodash';
import {
  Editor as SlateEditor,
  Element as SlateElement,
  Node as SlateNode,
  Range as SlateRange,
  Text as SlateText,
} from 'slate';

import { slateToText } from 'src/components/RichSlate';
import { SLATE_TEXT_FORMATS } from './const';

export const getEditorParagraphs = (editor) =>
  SlateEditor.nodes(editor, {
    at: [],
    match: (node) => SlateElement.isElement(node) && node.type === SLATE_TEXT_FORMATS.PARAGRAPH,
  });

/**
 * Get Slate top-level block for the passed location
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Location} at - Slate location
 * @returns {import('slate').BaseNode|undefined} - Slate block
 */
export const getSlateParentBlock = (editor, at) => {
  const entry = SlateEditor.above(editor, {
    at,
    match: (node) => !SlateEditor.isEditor(node) && SlateEditor.isBlock(editor, node),
    mode: 'highest',
  });
  return entry?.[0];
};

/**
 * Get text of the Slate top-level block for the passed location
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Location} at - Slate location
 * @returns {string} - Block text
 */
export const getSlateBlockText = (editor, at) => {
  const blockNode = getSlateParentBlock(editor, at);
  return blockNode ? SlateNode.string(blockNode) : '';
};

/**
 * Get Slate range before the passed location
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Location} location - Slate location
 * @returns {import('slate').BaseRange} - Slate range
 */
const getSlateRangeBefore = (editor, location) => {
  const startPoint = SlateEditor.start(editor, []);
  return SlateEditor.range(editor, startPoint, location);
};

/**
 * Get Slate range after the passed location
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Location} location - Slate location
 * @returns {import('slate').BaseRange} - Slate range
 */
const getSlateRangeAfter = (editor, location) => {
  const endPoint = SlateEditor.end(editor, []);
  return SlateEditor.range(editor, location, endPoint);
};

/**
 * Get text value before the passed location
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Location} location - Slate location
 * @returns {string|null} - Text value
 */
export const getSlateTextBefore = (editor, location) => {
  if (!editor || !location) {
    return null;
  }

  const beforeRange = getSlateRangeBefore(editor, location);
  return SlateEditor.string(editor, beforeRange);
};

/**
 * Get text value after the passed location
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Location} location - Slate location
 * @returns {string|null} - Text value
 */
export const getSlateTextAfter = (editor, location) => {
  if (!editor || !location) {
    return null;
  }

  const afterRange = getSlateRangeAfter(editor, location);
  return SlateEditor.string(editor, afterRange);
};

/**
 * Get Slate fragment before the passed location
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Location} location - Slate location
 * @returns {import('slate').Descendant[]|null} - Slate fragment
 */
export const getSlateFragmentBefore = (editor, location) => {
  if (!editor || !location) {
    return null;
  }

  const beforeRange = getSlateRangeBefore(editor, location);
  return SlateNode.fragment(editor, beforeRange);
};

/**
 * Get Slate fragment after the passed location
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Location} location - Slate location
 * @returns {import('slate').Descendant[]|null} - Slate fragment
 */
export const getSlateFragmentAfter = (editor, location) => {
  if (!editor || !location) {
    return null;
  }

  const afterRange = getSlateRangeAfter(editor, location);
  return SlateNode.fragment(editor, afterRange);
};

/**
 * Get text value of the passed range
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Range} range - Slate range
 * @returns {string} - Text value
 */
export const getSlateRangeText = (editor, range) => {
  if (!range || SlateRange.isCollapsed(range)) {
    return '';
  }

  return SlateEditor.string(editor, range);
};

/**
 * Get text value before the passed range
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Range} range - Slate range
 * @returns {string} - Text value
 */
export const getSlateTextBeforeRange = (editor, range) => {
  if (!range) {
    return '';
  }

  const rangeEdges = SlateRange.edges(range);
  return getSlateTextBefore(editor, rangeEdges[0]);
};

/**
 * Get text value after the passed range
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').Range} range - Slate range
 * @returns {string} - Text value
 */
export const getSlateTextAfterRange = (editor, range) => {
  if (!range) {
    return '';
  }

  const rangeEdges = SlateRange.edges(range);
  return getSlateTextAfter(editor, rangeEdges[1]);
};

/**
 * Get Slate fragment from the current selection
 * @param {import('slate').Editor} editor - Slate editor instance
 * @returns {import('slate').Descendant[]} - Slate fragment
 */
export const getSlateSelectionFragment = (editor) => {
  if (!editor?.selection) {
    return null;
  }

  if (SlateRange.isCollapsed(editor.selection)) {
    return [];
  }

  return SlateNode.fragment(editor, editor.selection);
};

/**
 * Get Slate fragment before the current selection
 * @param {import('slate').Editor} editor - Slate editor instance
 * @returns {import('slate').Descendant[]} - Slate fragment
 */
export const getSlateFragmentBeforeSelection = (editor) => {
  if (!editor?.selection) {
    return null;
  }

  const selectionEdges = SlateRange.edges(editor.selection);
  return getSlateFragmentBefore(editor, selectionEdges[0]);
};

/**
 * Get Slate fragment after the current selection
 * @param {import('slate').Editor} editor - Slate editor instance
 * @returns {import('slate').Descendant[]} - Slate fragment
 */
export const getSlateFragmentAfterSelection = (editor) => {
  if (!editor?.selection) {
    return null;
  }

  const selectionEdges = SlateRange.edges(editor.selection);
  return getSlateFragmentAfter(editor, selectionEdges[1]);
};

/**
 * Get Slate nodes that are part of the current selection
 * @param {import('slate').Editor} editor - Slate editor instance
 * @returns {import('slate').Node[]} - Slate nodes
 */
export const getSlateNodeEntriesAtSelection = (editor) => {
  if (!editor?.selection) {
    return null;
  }

  if (SlateRange.isCollapsed(editor.selection)) {
    return [];
  }

  return [
    ...SlateEditor.nodes(editor, {
      at: editor.selection,
      match: (node) => !SlateEditor.isEditor(node) && SlateEditor.isBlock(editor, node),
    }),
  ];
};

export const characterBefore = (editor, basePoint) => {
  const beforePoint = SlateEditor.before(editor, basePoint, { unit: 'character' });
  if (!beforePoint) {
    return null;
  }
  return SlateEditor.string(editor, { anchor: beforePoint, focus: basePoint });
};

export const characterAfter = (editor, basePoint) => {
  const afterPoint = SlateEditor.after(editor, basePoint, { unit: 'character' });
  if (!afterPoint) {
    return null;
  }
  return SlateEditor.string(editor, { anchor: basePoint, focus: afterPoint });
};

/**
 * Create a Slate range given the start and end text indexes in the editor
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {number} startIndex - Start index
 * @param {number} endIndex - End index
 * @returns {import('slate').BaseRange} - Slate range
 */
export const createSlateRange = (editor, startIndex, endIndex) => {
  const { children } = editor;

  let start = null;
  let end = null;

  let currentOffset = 0;
  const findPoint = (node, path = [], isRoot = false) => {
    if (SlateText.isText(node)) {
      const textLength = node.text.length;

      // Check if startIndex is in the current node
      if (startIndex >= currentOffset && startIndex <= currentOffset + textLength) {
        start = {
          path: path,
          offset: startIndex - currentOffset,
        };
      }

      // Check if endIndex is in the current node
      if (endIndex >= currentOffset && endIndex <= currentOffset + textLength) {
        end = {
          path: path,
          offset: endIndex - currentOffset,
        };
      }

      currentOffset += textLength;
    } else {
      // Recursively find in child nodes
      for (let i = 0; i < node.children.length; i++) {
        findPoint(node.children[i], path.concat(i));
        if (start?.path?.length > 0 && end?.path?.length > 0) {
          break;
        }

        // Account for newlines between paragraph nodes
        if (isRoot) {
          currentOffset += 1;
        }
      }
    }
  };

  findPoint({ children }, [], true);

  // Create a range from the points
  return {
    anchor: start || SlateEditor.start(editor, []),
    focus: end || SlateEditor.end(editor, []),
  };
};

/**
 * Get the range references intersection with the node
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {import('slate').RangeRef[]} rangeRefs - Range references
 * @param {import('slate').Path} nodePath - Node path
 * @returns {import('slate').BaseRange[]} - Intersections
 */
export const getRangeRefsIntersectionsWithNode = (editor, rangeRefs, nodePath) => {
  const nodeRange = SlateEditor.range(editor, nodePath);
  const intersections = rangeRefs.map((rangeRef) => {
    if (!rangeRef.current) {
      return null;
    }
    return SlateRange.intersection(rangeRef.current, nodeRange);
  });
  return compact(intersections);
};

/**
 * Get the plain text content of the Slate editor, including line breaks
 * @param {import('slate').Editor} editor - Slate editor instance
 * @returns {string} - Plain text content of the editor with line breaks
 */
export const getEditorTextContent = (editor) => {
  if (!editor) {
    return '';
  }

  // Utilize the existing slateToText utility
  return slateToText(editor.children);
};
