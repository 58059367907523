import { orderBy } from 'lodash';
import { createStore } from 'react-sweet-state';
import { getUserName } from 'src/data/user';

import {
  queryMembers,
  removeCustomerUser,
  updateCustomerMemberDefaultWorkspace,
  updateCustomerMemberRole,
  updateCustomerUser,
} from 'src/graphql/customer';
import { captureMessage } from 'src/lib/sentry';

const NAME = 'global-customer-member';

const INITIAL_STATE = {
  members: [],
  loadingMembers: false,
  processingUser: false,
};

/**
 * Private actions
 */
export const PRIVATE_ACTIONS = {
  updateUserName:
    (id, name) =>
    ({ getState, setState }) => {
      const { members } = getState();

      const newMembers = members.map((member) => {
        if (member.id === id) {
          return {
            ...member,
            name,
          };
        }

        return member;
      });

      setState({ members: newMembers });
    },
  updateMemberRoleInState:
    (email, role) =>
    ({ getState, setState }) => {
      const { members } = getState();

      const newMembers = members.map((member) => {
        if (member.email === email) {
          return {
            ...member,
            role,
          };
        }

        return member;
      });

      setState({ members: newMembers });
    },

  updateMemberDefaultWorkspaceIdInState:
    (memberUserId, defaultWorkspaceId) =>
    ({ getState, setState }) => {
      const { members } = getState();

      const newMembers = members.map((member) => {
        if (member.id === memberUserId) {
          return {
            ...member,
            defaultWorkspaceId,
          };
        }

        return member;
      });

      setState({ members: newMembers });
    },

  removeUserFromList:
    (email) =>
    ({ getState, setState }) => {
      const { members } = getState();
      const newList = members.filter((u) => u.email !== email);

      setState({ members: newList });
    },
};

/**
 * Public available actions
 */
export const ACTIONS = {
  init:
    () =>
    ({ dispatch }) => {
      dispatch(ACTIONS.queryMembers());
    },
  queryMembers:
    () =>
    async ({ setState }, { currentCustomerId }) => {
      setState({ loadingMembers: true });

      try {
        const members = await queryMembers(currentCustomerId);
        const preparedMembers = members.map((member) => ({
          ...member,
          name: getUserName(member).toLowerCase(),
        }));
        const membersSorted = orderBy(
          preparedMembers,
          ['pending', 'role', 'name'],
          ['asc', 'asc', 'asc']
        );

        setState({
          members: membersSorted,
        });
      } catch (error) {
        captureMessage('Error while getting customer team members', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'CustomerMembersStore -> queryMembers',
          },
        });
      } finally {
        setState({ loadingMembers: false });
      }
    },

  removeUser:
    (email) =>
    async ({ setState, dispatch }, { currentCustomerId }) => {
      setState({ processingUser: true });

      try {
        const member = await removeCustomerUser(currentCustomerId, email);

        dispatch(PRIVATE_ACTIONS.removeUserFromList(email));

        return member;
      } finally {
        setState({ processingUser: false });
      }
    },

  updateCustomerMemberRole:
    (email, role) =>
    async ({ setState, dispatch }, { currentCustomerId }) => {
      setState({ processingUser: true });

      try {
        const member = await updateCustomerMemberRole({
          customerId: currentCustomerId,
          email,
          role,
        });

        dispatch(PRIVATE_ACTIONS.updateMemberRoleInState(email, role));

        return member;
      } finally {
        setState({ processingUser: false });
      }
    },

  updateCustomerMemberDefaultWorkspace:
    (memberUserId, workspaceId) =>
    async ({ setState, dispatch }, { currentCustomerId }) => {
      setState({ processingUser: true });

      try {
        const member = await updateCustomerMemberDefaultWorkspace({
          customerId: currentCustomerId,
          memberUserId,
          defaultWorkspaceId: workspaceId,
        });

        dispatch(PRIVATE_ACTIONS.updateMemberDefaultWorkspaceIdInState(memberUserId, workspaceId));

        return member;
      } finally {
        setState({ processingUser: false });
      }
    },

  renameUser:
    (id, name) =>
    async ({ dispatch }) => {
      await updateCustomerUser(id, name);

      dispatch(PRIVATE_ACTIONS.updateUserName(id.toString(), name));
    },
  getMembers:
    () =>
    ({ getState }) => {
      return getState().members;
    },
};

export const CustomerMemberStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});
