import React, { useCallback } from 'react';

import pubsub, { PUBSUB } from 'src/lib/pubsub';
import { queryAuthorCompleteTrace } from 'src/graphql/queryAuthorCompleteTrace';
import { BRIEF_DEBUG_DATA_VISIBLE } from 'src/lib/featureFlags';

const parseData = (json) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

const mapSpan = (span) => ({
  ...span,
  data: parseData(span.data),
  response: parseData(span.response),
});

const AuthorCompleteTracer = () => {
  const handleTraceAcCall = useCallback((_, { traceId, title }) => {
    queryAuthorCompleteTrace(traceId)
      .then(({ spans, acMachineName }) => {
        if (!spans || spans.length === 0) {
          return;
        }

        const mapped = spans.map(mapSpan);

        /* eslint-disable no-console */
        const group = `AC calls for ${title}, AC: ${acMachineName}`;
        console.groupCollapsed(group);
        mapped.forEach((span) => {
          console.groupCollapsed(span.name);
          console.log('request', span.data);
          console.log('response', span.response);
          console.groupEnd(span.name);
        });
        console.groupEnd(group);
        /* eslint-enable no-console */
      })
      .catch(() => {
        /* do nothing */
      });
  }, []);

  React.useEffect(() => {
    if (!BRIEF_DEBUG_DATA_VISIBLE) {
      return undefined;
    }

    const token = pubsub.subscribe(PUBSUB.TRACE_AC_CALL, handleTraceAcCall);
    return () => pubsub.unsubscribe(token);
  }, [handleTraceAcCall]);

  return null;
};

export default AuthorCompleteTracer;
