import { authorizeInHubspot } from 'src/utils/lib/oauth';
import { authorizeHubspot, queryHubspotAuthorizationLink } from 'src/graphql/hubspot';
import { ChannelAuthError } from 'src/lib/errors';

export const connectToHubspot = async () => {
  // Query auth link from BE
  const authUrl = await queryHubspotAuthorizationLink();

  // Ask user to allow our app to use their data
  const authData = await authorizeInHubspot(authUrl);

  if (authData?.code) return authorizeHubspot(authData.code);

  throw new ChannelAuthError('Error getting Hubspot authorization code');
};
