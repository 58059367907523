import {
  getToolDefaultNumberOfVariations,
  getToolNumberOfVariations,
} from 'src/data/generationTool';
import {
  DEFAULT_NUMBER_OF_VARIATIONS,
  BASIC_DEFAULT_NUMBER_OF_VARIATIONS,
  CANVAS_DEFAULT_NUMBER_OF_VARIATIONS,
} from './const';

export const getNumberOfVariations = (cantChange) => [
  10,
  DEFAULT_NUMBER_OF_VARIATIONS,
  cantChange ? BASIC_DEFAULT_NUMBER_OF_VARIATIONS : 3,
];

export const getNumberOfVariationsForDocument = (cantChange, generationTool) => {
  const generationToolVariationCount = getToolNumberOfVariations(generationTool);
  if (generationToolVariationCount) {
    return cantChange ? [BASIC_DEFAULT_NUMBER_OF_VARIATIONS] : generationToolVariationCount;
  }
  return [10, DEFAULT_NUMBER_OF_VARIATIONS, cantChange ? BASIC_DEFAULT_NUMBER_OF_VARIATIONS : 3, 1];
};
export const getDocumentDefaultNumberOfVariations = (generationTool, cantChange) => {
  const defaultNumber = getToolDefaultNumberOfVariations(generationTool);
  if (defaultNumber) {
    return defaultNumber;
  }

  return cantChange ? BASIC_DEFAULT_NUMBER_OF_VARIATIONS : CANVAS_DEFAULT_NUMBER_OF_VARIATIONS;
};
