import gql from 'graphql-tag';

import { SAVED_VARIATION_FRAGMENT } from 'src/graphql/savedVariation';

export const FRAGMENT_CO_CONVERSION_GOAL = gql`
  fragment ContinuousOptimizationConversionGoal on ContinuousOptimizationLinkCallToAction {
    id
    url
  }
`;

export const FRAGMENT_CO_CSS_CTA = gql`
  fragment CssCallToAction on CssCallToAction {
    id
    cssSelector
    text
    htmlText
    screenshotBase64
    screenshotUrl
    href
    assetStatus
  }
`;

export const FRAGMENT_CO_CAMPAIGN = gql`
  fragment ContinuousOptimizationCampaign on ContinuousOptimizationCampaign {
    id
    status
    isScriptVerified
    trafficRate
    isTrafficRateSet
    addYourOwnCode
    ctaType
    cssCtaList {
      ...CssCallToAction
    }
    linkCallToActions {
      ...ContinuousOptimizationConversionGoal
    }
  }
  ${FRAGMENT_CO_CONVERSION_GOAL}
  ${FRAGMENT_CO_CSS_CTA}
`;

export const FRAGMENT_CO_CAMPAIGN_CALL_TO_ACTIONS = gql`
  fragment ContinuousOptimizationCampaignCallToAction on ContinuousOptimizationCampaign {
    id
    ctaType
    cssCtaList {
      ...CssCallToAction
    }
  }
  ${FRAGMENT_CO_CSS_CTA}
`;

export const FRAGMENT_CO_VARIANT_PERFORMANCE = gql`
  fragment VariantPerformance on VariantPerformance {
    audienceTargetId
    expectedConversionRate
    probabilityToBeatBaseline
    probabilityToBeBest
    improvementPercentage
    clicks
    views
  }
`;

export const FRAGMENT_CO_VARIANT = gql`
  fragment ContinuousOptimizationVariant on ContinuousOptimizationVariant {
    id
    isOrigin
    isDisabled
    savedVariation {
      ...SavedVariation
    }
    screenshotBase64
    screenshotUrl
    performance {
      ...VariantPerformance
    }
    isAssetInLearningPhase
  }
  ${FRAGMENT_CO_VARIANT_PERFORMANCE}
  ${SAVED_VARIATION_FRAGMENT}
`;

export const FRAGMENT_CO_ASSET = gql`
  fragment LandingPageAsset on LandingPageAsset {
    id
    type
    cssSelector
    status
    createdAt
    selectedAtNanoTimestamp
    variants {
      ...ContinuousOptimizationVariant
    }
    significantImproveScoreThreshold
    isInLearningPhase
    maxCharsTiers
    maxChars
  }
  ${FRAGMENT_CO_VARIANT}
`;

export const FRAGMENT_AUDIENCE_TARGET = gql`
  fragment AudienceTarget on AudienceTarget {
    id
    orderIndex
    targetType
    targetEvaluation
    value
  }
`;
