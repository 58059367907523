import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation AuthorizeHubspot($authorizationCode: String!) {
    authorizeHubspot(authorizationCode: $authorizationCode) {
      authState

      adAccounts {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const authorizeHubspot = (authorizationCode) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { authorizationCode },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => {
      const accounts = unwrapEdges(result?.accounts?.edges || []);
      const pages = unwrapEdges(result?.pages?.edges || []);

      return { accounts, authState: result?.authState, pages };
    });
