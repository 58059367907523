import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

export const useHistory = () => {
  const navigate = useNavigate();

  return useMemo(
    () => ({
      navigate,
      push: (path, options) => navigate(path, options),
      replace: (path, options) => navigate(path, { ...options, replace: true }),
      goBack: () => navigate(-1),
    }),
    [navigate]
  );
};
