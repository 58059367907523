import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: COLORS.neutral.white,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: theme.shape.borderRadiusSemiLarge,
      padding: theme.spacing(1.5, 2),
      height: 80,
      rowGap: theme.spacing(1.25),
    },
    wrapper: {
      display: 'flex',
      columnGap: theme.spacing(1),
      alignItems: 'center',
      maxHeight: 24,
      '& + &': {
        maxHeight: 12,
      },
    },
    skeleton: {
      borderRadius: theme.shape.borderRadiusMinimal,
    },
  }),
  { name: 'Loader' }
);
