import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DialogContent, Link } from '@material-ui/core';

import { useIsCurrentUserAdmin } from 'src/store';
import { ROUTES } from 'src/routes';
import { formatNumber } from 'src/utils/format';
import { formatRoute } from 'src/lib/routing';
import { CustomDialog } from 'src/components/CustomDialog';
import { useIsCustomerOnBusinessOrEnterprisePlan } from 'src/services';
import { getToolTitle } from 'src/data/generationTool';
import { getGenerationToolFromCustomModel } from 'src/data/customModel';
import { isBlogUrlsChannel, isEmailCsvResourceDocument } from 'src/data/performance';
import { BlockCustomModelExtraction } from 'src/components/BlockCustomModelExtraction';
import { FieldCheckbox } from 'src/components/FieldCheckbox';
import { useStyles } from './useStyles';
export const CustomModelSuccessPopup = ({ open, onClose, customModel }) => {
  const styles = useStyles();

  const [isDefault, setIsDefault] = useState(false);

  const isCurrentUserAdmin = useIsCurrentUserAdmin();

  const isCustomerOnBusinessOrEnterprisePlan = useIsCustomerOnBusinessOrEnterprisePlan();

  if (!customModel) {
    return null;
  }

  const displayUpgradeContainer =
    !isCustomerOnBusinessOrEnterprisePlan &&
    !isEmailCsvResourceDocument(customModel.dataSource) &&
    !isBlogUrlsChannel(customModel.dataSource);

  const toolTitle = getToolTitle(getGenerationToolFromCustomModel(customModel));

  return (
    <CustomDialog
      className={styles.root}
      open={open}
      fullWidth
      title="Custom model training complete!"
      data-testid="CustomModelSuccessPopup"
      onSubmit={() => onClose(isDefault)}
      submitLabel="Close"
    >
      <DialogContent className={styles.dialogContent}>
        <BlockCustomModelExtraction data={customModel} />

        {displayUpgradeContainer && (
          <div className={styles.upgradeContainer}>
            This custom model was trained on only {formatNumber(customModel.usedCopies)} of the{' '}
            {formatNumber(customModel.totalCopies)} assets that were available for training.
            <br />
            <Link href={formatRoute(ROUTES.UPGRADE)}>Upgrade to business plan</Link> to train larger
            and more accurate models.
          </div>
        )}
        {isCurrentUserAdmin && (
          <FieldCheckbox
            className={styles.default}
            checked={isDefault}
            onChange={(event) => setIsDefault(event.target.checked)}
            label={`Set as default for ${toolTitle}`}
          />
        )}
      </DialogContent>
    </CustomDialog>
  );
};

CustomModelSuccessPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  customModel: PropTypes.shape({
    usedCopies: PropTypes.number,
    totalCopies: PropTypes.number,
  }).isRequired,
};
CustomModelSuccessPopup.defaultProps = {
  open: false,
};
