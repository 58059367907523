import { useMemo } from 'react';

import { useCurrentCustomer } from 'src/store';
import {
  customerHasActiveSubscription,
  customerInTrial,
  getCustomerLifecycleLabel,
} from 'src/data/customer';
import { getCustomerPlan, getSubscriptionPlanLabel } from 'src/data/subscription';

export const useCurrentPlanLabel = () => {
  const customer = useCurrentCustomer();
  const hasActiveSubscription = customerHasActiveSubscription(customer);
  const plan = getCustomerPlan(customer);

  const trialLabel = useMemo(() => {
    if (!customer) {
      return '';
    }

    if (customerInTrial(customer)) {
      return 'Free Trial';
    }

    return getCustomerLifecycleLabel(customer.lifecycle);
  }, [customer]);
  const planLabel = getSubscriptionPlanLabel(plan);

  return hasActiveSubscription ? planLabel : trialLabel;
};
