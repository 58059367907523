import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Dialog, DialogActions, DialogTitle, IconButton } from '@material-ui/core';

import { CustomButton, CUSTOM_BUTTON_VARIANT } from 'src/components/CustomButton';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { IconCloseThin } from 'src/assets/icons/CloseThin';
import { useStyles } from './useStyles';

export const CustomDialog = ({
  classNameRoot,
  className,
  classes,
  onClose,
  title,
  loading,
  children,
  onCancel,
  cancelLabel,
  onSubmit,
  submitLabel,
  submitDisabled,
  cancelDisabled,
  submitProps,
  hideActions,
  disabled,
  displayCloseIcon,
  dialogActionsSlot,
  preContentSlot,
  ...props
}) => {
  const styles = useStyles({ classes });

  return (
    <Dialog
      {...props}
      classes={{
        root: classNameRoot,
        paper: cx(styles.root, className, {
          [styles.hasDialogActionsSlot]: Boolean(dialogActionsSlot),
        }),
      }}
      onClose={disabled ? null : onClose}
    >
      <DialogTitle
        className={cx(styles.dialogTitle, { [styles.noTitle]: !title })}
        disableTypography
      >
        <CustomTypography
          className={styles.title}
          variant={TYPOGRAPHY_VARIANT.semiBold16}
        >
          {title}
        </CustomTypography>
        {displayCloseIcon && (
          <IconButton
            className={styles.buttonClose}
            onClick={onClose}
            disabled={disabled}
            data-testid="buttonClose"
          >
            <IconCloseThin />
          </IconButton>
        )}
      </DialogTitle>
      {preContentSlot}

      <div className={styles.scrollable}>{children}</div>

      {!hideActions && (
        <DialogActions className={styles.dialogActions}>
          {dialogActionsSlot && <div className={styles.dialogActionsSlot}>{dialogActionsSlot}</div>}

          <div className={styles.actions}>
            {onCancel && (
              <CustomButton
                className={styles.buttonCancel}
                onClick={onCancel}
                disabled={cancelDisabled || disabled}
                variant={CUSTOM_BUTTON_VARIANT.text}
                data-testid="buttonCancel"
              >
                {cancelLabel || 'Cancel'}
              </CustomButton>
            )}

            {onSubmit && (
              <CustomButton
                className={styles.buttonSubmit}
                onClick={onSubmit}
                loading={loading}
                disabled={submitDisabled || disabled}
                data-testid="buttonSubmit"
                {...submitProps}
              >
                {submitLabel || 'Submit'}
              </CustomButton>
            )}
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  className: PropTypes.string,
  classNameRoot: PropTypes.string,
  classes: PropTypes.shape({}),
  onClose: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  submitDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  submitProps: PropTypes.shape({}),
  hideActions: PropTypes.bool,
  disabled: PropTypes.bool,
  displayCloseIcon: PropTypes.bool,
  dialogActionsSlot: PropTypes.node,
  preContentSlot: PropTypes.node,
};

CustomDialog.defaultProps = {
  className: null,
  classNameRoot: null,
  classes: null,
  onClose: null,
  title: null,
  loading: false,
  children: null,
  onCancel: null,
  cancelLabel: null,
  onSubmit: null,
  submitLabel: null,
  submitDisabled: false,
  cancelDisabled: false,
  submitProps: null,
  hideActions: false,
  disabled: false,
  displayCloseIcon: false,
  dialogActionsSlot: null,
  preContentSlot: null,
};
