import { makeStyles, alpha } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& .recharts-radar-polygon path': {
        stroke: COLORS.success.default,
        fill: alpha(COLORS.success.light, 0.5),
      },

      '&$loading': {
        '& .recharts-radar-polygon path': {
          fill: 'transparent',
          stroke: 'transparent',
        },
      },
    },
    loading: {},

    labelEmpty: {
      fill: '#9FA8BD',
      fontSize: theme.typography.pxToRem(12),
    },
  }),
  { name: 'AudiencesRadarChart' }
);
