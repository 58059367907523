import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation Authenticate($email: String!, $password: String!, $device: String) {
    authenticate(email: $email, password: $password, device: $device) {
      token
    }
  }
`;

export const authenticate = ({ email, password, device }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { email, password, device },
  }).then(({ data }) => getSingleProp(data));
