import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: 'auto',
      height: 'auto',
      padding: 0,
      lineHeight: 1,
      fontWeight: theme.typography.fontWeightRegular,

      '&:hover': {
        backgroundColor: 'unset',
      },
    },
  }),
  { name: 'ButtonText' }
);
