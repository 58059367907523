import { captureMessage } from 'src/lib/sentry';
import { RESOURCE_CONTENT_TYPE } from 'src/data/performance';
import { MAX_RESOURCE_PER_TYPE } from 'src/data/common';

const centerPosition = (width, height) => ({
  left:
    (window.screenX < 0 ? window.screen.width + window.screenX : window.screenX) +
    (window.outerWidth - width) / 2,
  top: window.screenY + (window.outerHeight - height) / 2.5,
});

const generatePopupFeatures = (width, height) => {
  const position = centerPosition(width, height);

  const features = [];
  features.push(`left=${position.left}`);
  features.push(`top=${position.top}`);
  features.push(`width=${width}`);
  features.push(`height=${height}`);
  features.push('scrollbars=1');
  features.push('toolbar=0');
  return features.join(',');
};

export const openPopup = (url, options) => {
  const { name, width, height } = options || {};

  // Prepare popup features and open it
  const features = generatePopupFeatures(width, height);
  return window.open(url, name, features);
};

export const shouldOpenResourcesConfirmPopup = (
  types,
  id,
  uploadingImages,
  uploadingDocuments,
  currentUrls
) => {
  // go over types and check if a file exists for one of the types
  for (const type of types) {
    if (type === RESOURCE_CONTENT_TYPE.image && uploadingImages.length === MAX_RESOURCE_PER_TYPE) {
      return !id || !uploadingImages.some((image) => image.id === id);
    }
    if (
      type === RESOURCE_CONTENT_TYPE.document &&
      uploadingDocuments.length === MAX_RESOURCE_PER_TYPE
    ) {
      return !id || !uploadingDocuments.some((doc) => doc.id === id);
    }
    if (type === RESOURCE_CONTENT_TYPE.url && currentUrls.length === MAX_RESOURCE_PER_TYPE) {
      return true;
    }
  }
  return false;
};

export const subscribeToMessages = (callback, options) => {
  function listener(event) {
    try {
      // If we can't read source window of the message, then it's not our message
      if (!event.source) {
        return;
      }

      // Work only with messages from our popup
      if (event.source.name !== options.name) {
        return;
      }

      callback(event.data);
    } catch (error) {
      captureMessage('Cannot subscribe to "window" messages', {
        level: 'warning',
        extra: {
          error: error.toString(),
          source: 'popup -> subscribeToMessages',
        },
      });
    }
  }

  function unsubscribe() {
    window.removeEventListener('message', listener);
  }

  window.addEventListener('message', listener);

  return unsubscribe;
};
