import { useCallback } from 'react';

import { useNotificationActions } from 'src/store';
import { getToolDefaultGenerationMode } from 'src/data/generationTool';

const MAIN_FEWER_RECEIVED_REASON = {
  numCharsLimit: 'num_chars_limit',
  numWordsLimit: 'num_words_limit',
};

const IMPROVE_SCORE_EMPTY_REQUEST_MESSAGE = {
  title: 'Couldn’t generate variations with better scores.',
};

const MESSAGE_EMPTY_GENERATION = {
  title: 'Couldn’t generate variations. We are sorry about that.\n',
  description: 'Please try again or change your inputs.',
};

const MESSAGE_EXTRACT_BRIEF_BLOCKING_GENERAL = {
  title: 'Oops! We couldn’t extract content from this webpage.',
  description: 'Make sure it is valid, password-free, and includes substantial content.',
};

const WITH_KEYWORDS_EMPTY_GENERATION_MESSAGE = {
  title: "We couldn't generate higher-scoring suggestions.",
  description: 'Let Anyword be more creative by changing or removing talking points.',
};

const MESSAGE_EMPTY_GENERATION_CHARACTERS_LIMIT = {
  title: "We couldn't generate results for the requested length.\n",
  description: 'Please try changing the character limit or use a different formula.',
};

const MESSAGE_EMPTY_GENERATION_AUTO_CHARACTERS_LIMIT = {
  title: "We couldn't generate results for the requested length.\n",
  description: 'Please try changing the character limit.',
};

const MESSAGE_EMPTY_GENERATION_WORDS_LIMIT = {
  title: "We couldn't generate results for the requested length.\n",
  description: 'Please try changing the word limit or use a different formula.',
};

const MESSAGE_EMPTY_GENERATION_AUTO_WORDS_LIMIT = {
  title: "We couldn't generate results for the requested length.\n",
  description: 'Please try changing the word limit.',
};

const getEmptyGenerationMessage = ({
  improvingScore,
  keywords,
  mainFewerReceivedReason,
  suggestionMode,
  generationTool,
}) => {
  // if there are keywords, there is a high chance they are the reason that we can't generate. Therefore we are
  //      updating user with a specific message that we couldn't generate because of the keywords. Relevant for improve score requests.
  if (keywords?.length > 0 && improvingScore) {
    return WITH_KEYWORDS_EMPTY_GENERATION_MESSAGE;
  }

  if (improvingScore) {
    return IMPROVE_SCORE_EMPTY_REQUEST_MESSAGE;
  }

  switch (mainFewerReceivedReason) {
    case MAIN_FEWER_RECEIVED_REASON.numCharsLimit:
      if (suggestionMode === getToolDefaultGenerationMode(generationTool)) {
        return MESSAGE_EMPTY_GENERATION_AUTO_CHARACTERS_LIMIT;
      }
      return MESSAGE_EMPTY_GENERATION_CHARACTERS_LIMIT;

    case MAIN_FEWER_RECEIVED_REASON.numWordsLimit:
      if (suggestionMode === getToolDefaultGenerationMode(generationTool)) {
        return MESSAGE_EMPTY_GENERATION_AUTO_WORDS_LIMIT;
      }
      return MESSAGE_EMPTY_GENERATION_WORDS_LIMIT;

    default:
      break;
  }

  return MESSAGE_EMPTY_GENERATION;
};

/**
 * Notify user, if results are empty or number of generated variations is lower than requested
 */
export const useNotifyAboutGenerationResult = (hookOptions) => {
  const notificationActions = useNotificationActions();
  const allowEmptyMessage = !hookOptions?.muteEmptyNotification;

  return useCallback(
    ({ result, improvingScore }) => {
      // Nothing to report if "result" is not available
      if (!result) {
        return;
      }

      const generatedCount = result?.variations?.length || 0;
      if (generatedCount === 0 && allowEmptyMessage) {
        if (result?.briefExtractionResult?.failedBriefs?.length > 0) {
          notificationActions.displayMessage(
            MESSAGE_EXTRACT_BRIEF_BLOCKING_GENERAL.title,
            MESSAGE_EXTRACT_BRIEF_BLOCKING_GENERAL.description
          );
          return;
        }
        const { keywords, mainFewerReceivedReason, suggestionMode, generationTool } = result;
        const message = getEmptyGenerationMessage({
          improvingScore,
          keywords,
          mainFewerReceivedReason,
          suggestionMode,
          generationTool,
        });
        notificationActions.displayMessage(message.title, message.description);
      }
    },
    [notificationActions, allowEmptyMessage]
  );
};
