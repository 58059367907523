import { countWords } from 'src/lib/string';

export const mapVariationToSuggestion = ({
  id,
  generationTool,
  copyText,
  improveReason,
  score,
}) => ({
  id,
  generationTool,
  copyText,
  improveReason,
  score,
});

export const deduceVariationCount = (originalText) => {
  const wordsCount = countWords(originalText);
  if (wordsCount > 50) {
    return 1;
  }
  if (wordsCount > 20) {
    return 2;
  }
  return 3;
};
