import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import {
  queryProjectFolders,
  createProjectFolder,
  updateProjectFolder,
  deleteProjectFolder,
} from 'src/graphql/projectFolder';

const INITIAL_STATE = {
  projectFolders: [],
  loadingProjectFolders: false,
  loadedProjectFolders: false,
  savingProjectFolder: false,
  deletingProjectFolder: false,
};

const PRIVATE_ACTIONS = {
  mergeFolderInCache:
    (data) =>
    ({ getState, setState }) => {
      const { projectFolders: list } = getState();
      const itemExists = list.some((t) => t.id === data.id);
      const newList = itemExists
        ? list.map((listItem) => (listItem.id === data.id ? { ...listItem, ...data } : listItem))
        : [...list, data];
      setState({ projectFolders: newList });
    },

  removeFolderFromCache:
    (itemId) =>
    ({ getState, setState }) => {
      const { projectFolders: list } = getState();
      const filteredList = list.filter((t) => t.id !== itemId);
      setState({ projectFolders: filteredList });
    },

  updateProjectFolder:
    (fields) =>
    async ({ setState, dispatch }) => {
      setState({ savingProjectFolder: true });
      try {
        const updatedFolder = await updateProjectFolder(fields);
        dispatch(PRIVATE_ACTIONS.mergeFolderInCache(updatedFolder));
        return updatedFolder;
      } finally {
        setState({ savingProjectFolder: false });
      }
    },

  updateFolderProjectCount:
    (projectFolderId, increment) =>
    ({ getState, dispatch }) => {
      if (!projectFolderId) {
        return;
      }
      const { projectFolders } = getState();
      const projectFolder = projectFolders.find(
        (projectFolder) => projectFolder.id === projectFolderId
      );

      const delta = increment ? 1 : -1;
      dispatch(
        PRIVATE_ACTIONS.mergeFolderInCache({
          id: projectFolderId,
          projectCount: projectFolder.projectCount + delta,
        })
      );
    },
};

const ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  queryProjectFolders:
    ({ workspaceId }, options) =>
    async ({ setState }) => {
      setState({ loadingProjectFolders: true });
      try {
        const results = await queryProjectFolders({ workspaceId }, options);
        setState({
          projectFolders: results,
          loadedProjectFolders: true,
        });
      } finally {
        setState({ loadingProjectFolders: false });
      }
    },

  createProjectFolder:
    ({ name }) =>
    async ({ setState, dispatch }, { currentWorkspaceId }) => {
      setState({ savingProjectFolder: true });
      try {
        const createdFolder = await createProjectFolder({
          workspaceId: currentWorkspaceId,
          name,
        });
        dispatch(PRIVATE_ACTIONS.mergeFolderInCache(createdFolder));
        return createdFolder;
      } finally {
        setState({ savingProjectFolder: false });
      }
    },

  // Helper "update" actions
  renameProjectFolder:
    ({ id, name }) =>
    async ({ dispatch }) => {
      return dispatch(PRIVATE_ACTIONS.updateProjectFolder({ id, name }));
    },
  moveProjectFolderToNavigationBar:
    ({ id }) =>
    async ({ dispatch }) => {
      return dispatch(PRIVATE_ACTIONS.updateProjectFolder({ id, onSidebar: true }));
    },
  removeProjectFolderFromNavigationBar:
    ({ id }) =>
    async ({ dispatch }) => {
      return dispatch(PRIVATE_ACTIONS.updateProjectFolder({ id, onSidebar: false }));
    },

  deleteProjectFolder:
    ({ id, userApproved = false }) =>
    async ({ setState, dispatch }) => {
      setState({ deletingProjectFolder: true });
      try {
        await deleteProjectFolder({ id, userApproved });
        dispatch(PRIVATE_ACTIONS.removeFolderFromCache(id));
      } finally {
        setState({ deletingProjectFolder: false });
      }
    },

  increaseFolderProjectCount:
    (projectFolderId) =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.updateFolderProjectCount(projectFolderId, true));
    },

  decreaseFolderProjectCount:
    (projectFolderId) =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.updateFolderProjectCount(projectFolderId));
    },

  getFolder:
    (id) =>
    ({ getState }) => {
      const { projectFolders } = getState();
      return projectFolders.find((folder) => folder.id === id);
    },
};

export const ProjectFolderStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'project-folder-store',
});
