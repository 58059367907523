import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'start',
      gap: theme.spacing(1),
    },

    icon: {
      fontSize: theme.typography.pxToRem(16),
      color: COLORS.gray.gray40,
    },

    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      width: '100%',
    },

    title: {
      color: COLORS.gray.gray30,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },

    annotations: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      gap: theme.spacing(0.5, 1.5),

      //dot delimiter between items
      '& > *:not(:last-child)': {
        position: 'relative',

        '&:after': {
          content: '"∙"',
          position: 'absolute',
          left: '100%',
          top: '50%',
          display: 'inline-block',
          transform: 'translateY(-40%)',
          marginLeft: theme.spacing(0.5),
          lineHeight: 0,
        },
      },
    },

    annotationText: {
      color: COLORS.gray.gray40,
      lineHeight: theme.typography.pxToRem(16),
    },
  }),
  { name: 'AnnotationsGroup' }
);
