import { ChannelAuthError } from 'src/lib/errors';
import * as env from 'src/lib/env';
import { openOAuthPopup } from 'src/utils/lib/oauth';

const AUTH_URL = `${env.getBackendUrl()}/authentication/google_oauth2`;

export const formatGoogleSignupUrl = (state, signUpDevice) =>
  AUTH_URL.concat('?state=', encodeURIComponent(state), '&signUpDevice=', signUpDevice);

export const authorizeInGoogle = async (authUrl) => {
  const authData = await openOAuthPopup(authUrl);

  if (authData?.code) {
    return authData;
  } else {
    return new ChannelAuthError('Error in Google Authorization: No auth code');
  }
};

export const loginToGoogle = (state, signUpDevice) => {
  const authUrl = formatGoogleSignupUrl(state, signUpDevice);

  return openOAuthPopup(authUrl);
};
