import { useCallback, useMemo, useState } from 'react';
import { orderBy, uniqBy } from 'lodash';

import { takeTopAudiences } from 'src/data/demographics';
import { FEATURE_LIMITS, featureHasUsages, getFeatureLimitsData } from 'src/data/featureLimits';
import * as events from 'src/lib/events';
import {
  useDeleteCustomerPersona,
  useEditSuggestedCustomerPersonaPopup,
  useSelectPersonaPopup,
  useUpgradePopup,
} from 'src/services/popup';
import { useTrackingEvent, useErrorHandler } from 'src/services';
import {
  useBrandVoicesActions,
  useCustomerActions,
  useCustomerPersonaActions,
  useNotificationActions,
} from 'src/store';

export const useInsightsAudienceChartProps = ({
  audienceData,
  onAfterPersonaUpdate,
  usedPersonaName,
  eventPage,
}) => {
  const notifications = useNotificationActions();
  const customerActions = useCustomerActions();
  const customerPersonaStoreActions = useCustomerPersonaActions();
  const brandVoicesActions = useBrandVoicesActions();
  const selectPersonaPopup = useSelectPersonaPopup();
  const openEditSuggestedCustomerPersonaPopup = useEditSuggestedCustomerPersonaPopup();

  const handleDeleteCustomerPersona = useDeleteCustomerPersona();
  const handleError = useErrorHandler();
  const openUpgradePopup = useUpgradePopup();
  const trackEvent = useTrackingEvent();

  const [hiddenPersonaIds, setHiddenPersonaIds] = useState([]);
  const [addedPersonaIds, setAddedPersonaIds] = useState([]);

  const handleUpdatePersona = useCallback(
    async ({ brandVoice, customerPersona }) => {
      if (!customerPersona) {
        return;
      }

      try {
        await customerPersonaStoreActions.updateCustomerPersona(
          { ...customerPersona, brandVoiceId: brandVoice?.id },
          true
        );
        notifications.displaySuccess(`Target audience '${customerPersona?.name}' updated`);

        onAfterPersonaUpdate();
        brandVoicesActions.queryBrandVoicesAssets();
      } catch (error) {
        handleError(error);
      }
    },
    [
      onAfterPersonaUpdate,
      brandVoicesActions,
      customerPersonaStoreActions,
      handleError,
      notifications,
    ]
  );

  const onEditPersona = useCallback(
    async (customerPersona) => {
      trackEvent(events.PERSONAS.clickedAddOnInsights, {
        page: eventPage,
      });

      openEditSuggestedCustomerPersonaPopup(
        customerPersona,
        handleUpdatePersona,
        customerPersona?.brandVoice
      );
    },
    [trackEvent, eventPage, handleUpdatePersona, openEditSuggestedCustomerPersonaPopup]
  );

  /**
   * Add persona to "My audiences"
   */
  const onAddPersona = useCallback(async () => {
    const currentCustomer = customerActions.getCurrentCustomer();
    const featureLimitsData = getFeatureLimitsData(
      currentCustomer,
      FEATURE_LIMITS.audiencePersonasPerCustomer
    );

    if (!featureHasUsages(featureLimitsData)) {
      openUpgradePopup(FEATURE_LIMITS.audiencePersonasPerCustomer);
      return;
    }

    trackEvent(events.PERSONAS.clickedAddOnInsights, {
      page: eventPage,
    });

    const newPersona = await selectPersonaPopup({
      displaySuggested: true,
    });

    if (!newPersona) {
      return;
    }

    if (hiddenPersonaIds.includes(newPersona.id)) {
      setHiddenPersonaIds(hiddenPersonaIds.filter((id) => id !== newPersona.id));
    }

    notifications.displaySuccess(`Target audience '${newPersona?.name}' updated`);
    onAfterPersonaUpdate();

    setAddedPersonaIds((prev) => [...prev, newPersona.id]);
    brandVoicesActions.queryBrandVoicesAssets();
  }, [
    customerActions,
    selectPersonaPopup,
    eventPage,
    onAfterPersonaUpdate,
    setAddedPersonaIds,
    hiddenPersonaIds,
    openUpgradePopup,
    trackEvent,
    brandVoicesActions,
    notifications,
  ]);

  /**
   * Save "suggested" persona to "My audiences"
   * @param {object} persona - suggested persona to save
   */
  const onSavePersona = useCallback(
    async (brandVoice, persona) => {
      const currentCustomer = customerActions.getCurrentCustomer();

      const featureLimitsData = getFeatureLimitsData(
        currentCustomer,
        FEATURE_LIMITS.audiencePersonasPerCustomer
      );

      if (!featureHasUsages(featureLimitsData)) {
        openUpgradePopup(FEATURE_LIMITS.audiencePersonasPerCustomer);
        return;
      }

      trackEvent(events.SUGGESTED_AUDIENCES.saved, {
        personaId: persona?.id,
        page: eventPage,
      });

      try {
        await customerPersonaStoreActions.saveSuggestedPersona(brandVoice, persona);
        notifications.displaySuccess(`Target audience '${persona?.name}' updated`);

        onAfterPersonaUpdate();
        customerActions.refreshCurrentCustomerFeatureLimit();
        brandVoicesActions.queryBrandVoicesAssets();
      } catch (error) {
        handleError(error);
      }
    },
    [
      customerActions,
      trackEvent,
      eventPage,
      openUpgradePopup,
      customerPersonaStoreActions,
      notifications,
      onAfterPersonaUpdate,
      handleError,
      brandVoicesActions,
    ]
  );

  /**
   * Remove persona from "insights"
   * @param {object} persona - persona to remove
   */
  const onReplacePersona = useCallback(
    async (persona) => {
      trackEvent(events.PERSONAS.clickedReplaceOnInsights, {
        personaId: persona?.id,
        page: eventPage,
      });

      // Add persona id to the list of personas to be removed

      setHiddenPersonaIds((prev) => [...prev, persona.id]);
    },
    [eventPage, trackEvent]
  );

  /**
   * Remove persona in "customer persona store"
   * @param {object} persona - persona to delete
   */
  const onDeletePersona = useCallback(
    async (persona) => {
      if (!persona?.isSuggested) {
        const deleted = await handleDeleteCustomerPersona(persona);
        if (deleted) {
          setHiddenPersonaIds((prev) => [...prev, persona.id]);
        }
      } else {
        setHiddenPersonaIds((prev) => [...prev, persona.id]);
      }
    },
    [handleDeleteCustomerPersona]
  );

  // Apply addition and deletion + limit to top X items
  const updatedData = useMemo(() => {
    const originalData = audienceData?.data || [];

    // We need only top X items for "insights" chart
    const topAudiences = takeTopAudiences(originalData, { usedPersonaName });

    // Apply additions
    const additions = originalData.filter((item) => {
      return addedPersonaIds.includes(item.persona?.id);
    });

    // Apply addition
    const result = [...additions, ...topAudiences];

    // Remove duplicates, which are possible after additions
    const deduplicated = uniqBy(result, 'persona.id');

    // Sort by "isSuggested" and "score", bringing "saved" items to the top
    const sortedByScore = orderBy(deduplicated, ['persona.isSuggested', 'score'], ['asc', 'desc']);

    // Apply slicing and deletion
    return sortedByScore.filter((item) => !hiddenPersonaIds.includes(item.persona?.id)).slice(0, 5);
  }, [audienceData?.data, addedPersonaIds, usedPersonaName, hiddenPersonaIds]);

  return {
    data: updatedData,
    loading: audienceData?.loading,
    onAddPersona,
    onEditPersona,
    onSavePersona,
    onReplacePersona,
    onDeletePersona,
  };
};
