import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconAlignLeft = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5625 9.6875C0.5625 9.27329 0.898286 8.9375 1.3125 8.9375H5.00306C5.41727 8.9375 5.75306 9.27329 5.75306 9.6875C5.75306 10.1017 5.41727 10.4375 5.00306 10.4375H1.3125C0.898286 10.4375 0.5625 10.1017 0.5625 9.6875Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5625 6.19922C0.5625 5.78501 0.898286 5.44922 1.3125 5.44922H8.79472C9.20893 5.44922 9.54472 5.78501 9.54472 6.19922C9.54472 6.61343 9.20893 6.94922 8.79472 6.94922H1.3125C0.898286 6.94922 0.5625 6.61343 0.5625 6.19922Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5625 2.71094C0.5625 2.29672 0.898286 1.96094 1.3125 1.96094H12.6875C13.1017 1.96094 13.4375 2.29672 13.4375 2.71094C13.4375 3.12515 13.1017 3.46094 12.6875 3.46094H1.3125C0.898286 3.46094 0.5625 3.12515 0.5625 2.71094Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconAlignLeft.displayName = 'IconAlignLeft';
