import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation CreateBlogOutlineWithInstructions($projectId: ID!) {
    createBlogOutlineWithInstructions(projectId: $projectId) {
      id
    }
  }
`;

export const createBlogOutlineWithInstructions = ({ projectId }) => {
  const variables = { projectId };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
