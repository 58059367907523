import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 498,
      height: 448,

      // for 13" macbook
      '@media screen and (max-height: 800px)': {
        maxHeight: 'calc(100% - 20px)',
      },
    },
    dialogContent: {
      overflowY: 'unset',
      '&:first-child': {
        padding: theme.spacing(2, 4, 4),
      },
    },
    description: {
      color: COLORS.gray.black,
      marginBottom: theme.spacing(3),
    },
    buttonApplyAll: {
      marginLeft: theme.spacing(0.5),
    },
    dialogActions: {
      justifyContent: 'flex-end !important',
    },
  }),
  {
    name: 'ScoringMethodPopup',
  }
);
