import Cookies from 'js-cookie';

const LANDING_UTM_COOKIE = 'landingPageUTMParams';

export const readLandingPageCookie = () => Cookies.getJSON(LANDING_UTM_COOKIE);

export const writeToLandingPageCookie = (newData) => {
  const cookieValue = readLandingPageCookie();
  const newValue = {
    ...cookieValue,
    ...newData,
  };
  Cookies.set(LANDING_UTM_COOKIE, newValue);
};
