import { useCallback } from 'react';

import { GENERATION_TOOL_POPUP, useGenerationToolPopup } from 'src/services/popup';
import { SIDEBAR_TAB_TYPE } from 'src/views/GenerationToolPopup/const';
import { useSidebarEvents } from '../../service';
import { useGenerationToolPopupSubmitHandler } from 'src/services/useGenerationToolPopupSubmitHandler';
import { useMainActions } from '../../store';

export const useHandlers = () => {
  const { trackNavigationItemClicked } = useSidebarEvents();
  const mainActions = useMainActions();
  const openGenerationToolPopup = useGenerationToolPopup();

  const handleGenerationToolPopupSubmit = useGenerationToolPopupSubmitHandler();

  const handleLinkClick = useCallback(
    (linkItem) => {
      trackNavigationItemClicked({ label: 'Custom Folder', folderName: linkItem.label });

      if (!linkItem.isChildItem && !linkItem.disableNavigation) {
        mainActions.collapseAll();
      }
      if (linkItem.popupKey === GENERATION_TOOL_POPUP) {
        openGenerationToolPopup({
          selectedTabType: SIDEBAR_TAB_TYPE.mostPopular,
          onSubmit: handleGenerationToolPopupSubmit,
          withBlog: true,
        });
      }
    },
    [
      mainActions,
      trackNavigationItemClicked,
      openGenerationToolPopup,
      handleGenerationToolPopupSubmit,
    ]
  );

  return {
    handleLinkClick,
  };
};
