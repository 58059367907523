import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ExtractBlogInstructionsFromText($sectionId: ID!, $text: String!) {
    extractBlogInstructionsFromText(sectionId: $sectionId, text: $text) {
      instructions
      status
      extractionErrors
    }
  }
`;

export const extractBlogInstructionsBasedOnText = (sectionId, text) => {
  const variables = { sectionId, text };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
