import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      margin: 0,
      overflow: 'hidden',

      // for 13" macbook
      '@media screen and (max-height: 800px)': {
        maxHeight: 'calc(100% - 20px)',
      },
    },
    hasDialogActionsSlot: {},

    dialogTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&$noTitle': {
        padding: 0,
        '& $buttonClose': {
          top: theme.spacing(2),
        },
      },
    },
    title: {},
    buttonClose: {
      position: 'absolute',
      top: theme.spacing(3.25),
      right: theme.spacing(2),
      '& svg': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    noTitle: {},
    scrollable: {
      overflowY: 'auto',
      height: 'calc(100% - 73px)', // 73px is the height of buttons part of the popup
    },
    dialogActions: {
      '$hasDialogActionsSlot &': {
        justifyContent: 'space-between',
      },
    },
    dialogActionsSlot: {},
    actions: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
    },
    buttonCancel: {
      minWidth: 80,
    },
    buttonSubmit: {
      minWidth: 80,
    },
  }),
  { name: 'CustomDialog' }
);
