import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';

import ContentTaxonomyAutocomplete from 'src/components/ContentTaxonomyAutocomplete';
import { FieldLabel } from 'src/components/FieldLabel';
import { CONTENT_TYPE, getContentTypeTaxonomyName } from 'src/data/contentType';
import { BRIEF_TYPE, BRIEF_FORM_FIELD_NAMES } from 'src/data/brief';
import { useViewState } from './useViewState';
import useStyles from './useStyles';

const FieldContentTaxonomy = ({
  className,
  classes,
  value,
  onChange,
  error,
  hint,
  disabled,
  contentType,
  briefType,
  name,
  hideLabel,
  ...restProps
}) => {
  const styles = useStyles({ classes });

  const { contentTaxonomies, defaultContentTaxonomies, loadingContentTaxonomies } =
    useViewState(contentType);
  const label = getContentTypeTaxonomyName(contentType);

  const inputClasses = useMemo(
    () => ({
      root: styles.input_root,
      input: styles.input_input,
    }),
    [styles]
  );

  const selectedOption = useMemo(
    () => contentTaxonomies.find((o) => o.id === value),
    [contentTaxonomies, value]
  );

  const handleChange = useCallback(
    (option) => {
      if (onChange) {
        onChange(option ? option.id : null);
      }
    },
    [onChange]
  );

  return (
    <FormControl
      className={cx(styles.root, className)}
      {...restProps}
      data-testid="ContentTaxonomy"
    >
      {!hideLabel && (
        <FieldLabel
          label={label}
          hint={hint}
          isQuestion
          titleBold
        />
      )}

      <ContentTaxonomyAutocomplete
        classes={inputClasses}
        options={contentTaxonomies}
        defaultOptions={defaultContentTaxonomies}
        selectedOption={selectedOption}
        onChange={handleChange}
        loading={loadingContentTaxonomies}
        disabled={disabled}
        name={name}
        error={!!error}
      />

      {error && typeof error === 'string' && (
        <Typography className={styles.error}>{error}</Typography>
      )}
    </FormControl>
  );
};

FieldContentTaxonomy.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  contentType: PropTypes.oneOf(Object.values(CONTENT_TYPE)),
  briefType: PropTypes.oneOf(Object.values(BRIEF_TYPE)),
  name: PropTypes.string,
  hideLabel: PropTypes.bool,
};

FieldContentTaxonomy.defaultProps = {
  className: null,
  classes: null,
  value: '',
  onChange: null,
  error: null,
  hint: null,
  disabled: false,
  contentType: CONTENT_TYPE.product,
  briefType: BRIEF_TYPE.unifiedProduct,
  name: BRIEF_FORM_FIELD_NAMES.contentTaxonomy,
  hideLabel: false,
};

export default React.memo(FieldContentTaxonomy);
