import { useCallback } from 'react';

import { isPopupTypeNotification } from 'src/data/notifications';
import { useCustomerActions, useNotificationsCenterActions } from 'src/store';
import { useTrackingEvents } from './useTrackingEvents';

export const useNotificationHandlers = () => {
  const notificationsCenterActions = useNotificationsCenterActions();
  const customerActions = useCustomerActions();
  const events = useTrackingEvents();

  const readNotification = useCallback(
    (notification) => {
      if (!notification) {
        return;
      }

      const currentCustomerId = customerActions.getCurrentCustomerId();
      notificationsCenterActions.markNotificationRead(currentCustomerId, notification.id);
    },
    [customerActions, notificationsCenterActions]
  );

  const handleCtaClick = useCallback(
    (notification) => {
      if (isPopupTypeNotification(notification)) {
        readNotification(notification);
      }

      events.trackCtaClick(notification);
    },
    [readNotification, events]
  );

  const handleCloseClick = useCallback(
    (notification) => {
      readNotification(notification);

      events.trackCloseClick(notification);
    },
    [readNotification, events]
  );

  return {
    handleCtaClick,
    handleCloseClick,
  };
};
