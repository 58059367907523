import { createStore } from 'react-sweet-state';

import { sortNotifications } from 'src/data/notifications';
import { queryNotifications, markNotificationRead } from 'src/graphql/notifications';
import { captureMessage } from 'src/lib/sentry';

const STORE_NAME = 'in-app-notifications';

const INITIAL_STATE = {
  notifications: [],
  currentNotification: null,
};

const PRIVATE_ACTIONS = {
  setCurrentNotification:
    () =>
    ({ getState, setState }) => {
      const { notifications } = getState();
      const currentNotification = notifications.shift();
      setState({
        notifications,
        currentNotification,
      });
    },
};

const ACTIONS = {
  queryNotifications:
    (customerId) =>
    async ({ setState, dispatch }) => {
      try {
        const notifications = await queryNotifications(customerId);
        const sorted = sortNotifications(notifications);
        setState({ notifications: sorted });
        dispatch(PRIVATE_ACTIONS.setCurrentNotification());

        return sorted;
      } catch (error) {
        return [];
      }
    },

  markNotificationRead:
    (customerId, notificationId) =>
    async ({ setState }) => {
      setState({ currentNotification: null });
      try {
        await markNotificationRead(notificationId);
      } catch (error) {
        captureMessage('Error while marking notification read', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'NotificationsStore -> markNotificationRead',
          },
        });
      }
    },
};

export const InAppNotificationsStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: STORE_NAME,
});
