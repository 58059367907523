import { orderBy, uniqueId } from 'lodash';

import { ASSET_TYPE } from 'src/data/asset';
import { serializeSlateMarkedValue, deserializeSlateMarkedValue } from 'src/lib/slate';
import { captureException } from 'src/lib/sentry';

export const VARIATION_STATUS = {
  draft: 'draft',
  saved: 'saved',
  discarded: 'discarded',
  archived: 'archived',
};

export const VARIATION_SOURCE = {
  suggest: 'suggest',
  recompose: 'recompose',
  custom: 'custom',
  defaultSuggest: 'default_suggest',
};

export const BLOG_VARIATION_INTRO_SECTION_ID = 'section_intro';

export const VARIATION_RATING = {
  thumbsUp: 'thumbs_up',
  thumbsDown: 'thumbs_down',
  none: 'none',
};

export const variationRatingTextToValue = (text) => {
  switch (text) {
    case VARIATION_RATING.thumbsUp:
      return 1;
    case VARIATION_RATING.thumbsDown:
      return -1;
    default:
      return 0;
  }
};

export function sortVariationsByScore(variations) {
  return orderBy(variations, ['assetType', 'score', 'id'], ['desc', 'desc', 'asc']);
}

export function sortVariations(variations) {
  return sortVariationsByScore(variations);
}

export function sortSavedVariations(variations) {
  return orderBy(variations, ['lastUpdateTime'], ['desc']);
}

export const variationHasRowsContent = (assetType) => assetType === ASSET_TYPE.blogPostOutline;

const OUTLINE_DELIMITER_LINES = '\n';
const OUTLINE_DELIMITER_PARTS = ':';
const mapOutlineRowFromText = (line, index) => {
  const parts = line.split(OUTLINE_DELIMITER_PARTS);

  const title = (parts.shift() || '').trim();
  const value = (parts.join(OUTLINE_DELIMITER_PARTS) || '').trim();

  return {
    id: index,
    title,
    value,
  };
};
const mapOutlineRowToText = (row) => {
  return `${row.title}: ${row.value}`;
};

export function parseOutlineVariationContent(text) {
  const textRows = text.split(OUTLINE_DELIMITER_LINES);
  return textRows.map(mapOutlineRowFromText);
}

export function serializeOutlineVariationContent(rows) {
  const textRows = rows.map(mapOutlineRowToText);
  return textRows.join(OUTLINE_DELIMITER_LINES);
}

export const variationHasEmptyContent = (assetType, copyText) => {
  const alignedText = copyText || '';
  if (variationHasRowsContent(assetType)) {
    const rows = parseOutlineVariationContent(alignedText);
    return rows.every((row) => row.value.trim().length === 0);
  }
  return alignedText.trim().length === 0;
};

export const parseVariationSlateContent = (assetType, rawContent) => {
  if (variationHasRowsContent(assetType)) {
    const textRows = parseOutlineVariationContent(rawContent.text);
    return textRows.map((row) => ({
      ...row,
      value: deserializeSlateMarkedValue({ text: row.value }),
    }));
  }
  return deserializeSlateMarkedValue(rawContent);
};

export const serializeVariationSlateContent = (assetType, data) => {
  if (variationHasRowsContent(assetType)) {
    const textRows = data.map((row) => ({
      ...row,
      value: serializeSlateMarkedValue(row.value).text,
    }));
    return { text: serializeOutlineVariationContent(textRows) };
  }
  return serializeSlateMarkedValue(data);
};

export const serializeVariationMarkup = (obj) => (obj ? JSON.stringify(obj) : null);

const histogramObjectToArray = (obj) => {
  const array = Object.entries(obj).map(([name, value]) => ({ name, value }));
  return orderBy(array, ['name'], ['asc']);
};

export const parseHistogramData = (variation) => {
  const histogramData = {};
  try {
    if (variation?.ageScoreHistogram) {
      const ages = JSON.parse(variation.ageScoreHistogram);
      histogramData.ages = histogramObjectToArray(ages);
    }
    if (variation?.genderScoreHistogram) {
      const genderData = JSON.parse(variation.genderScoreHistogram);
      histogramData.gender = genderData;
      histogramData.genders = histogramObjectToArray(genderData);
    }
  } catch (error) {
    captureException(error, {
      extra: {
        source: 'data/variation.js -> parseHistogramData',
      },
    });
  }
  return Object.keys(histogramData).length ? histogramData : null;
};

export const addLanguageToVariations = (variations, language) =>
  variations.map((variation) => ({ ...variation, language }));

export const loopOverVariations = (variations, callback) => {
  for (const variation of variations) {
    callback(variation);
    if (variation.variations) {
      loopOverVariations(variation.variations, callback);
    }
  }
};

export const addLanguageToGenerationResult = (result) => {
  const language = result.language;
  const resultClone = { ...result };

  loopOverVariations(resultClone.variations, (variation) => {
    variation.language = language;
  });

  return resultClone;
};

export const addLanguageToGenerationResults = (results) =>
  results.map(addLanguageToGenerationResult);

export const generateVariationInstanceId = (variationId) => `${variationId}_${uniqueId()}`;
export const getVariationIdFromInstanceId = (instanceId) => instanceId?.split('_')[0];

export const flattenAndMergeVariations = (variations, addonParams) => {
  const result = [];
  for (let variation of variations) {
    if (addonParams) {
      variation = { ...variation, ...addonParams };
    }
    result.push(variation);
    const extraParams = { ...addonParams };
    if (!variation.originalGenerationTool) {
      extraParams.originalGenerationTool = variation.generationTool;
    } else {
      extraParams.originalGenerationTool = variation.originalGenerationTool;
    }
    if (variation.customMultiPromptId) {
      extraParams.customMultiPromptId = variation.customMultiPromptId;
    }
    if (variation.multiPromptTool) {
      extraParams.multiPromptTool = variation.multiPromptTool;
    }
    if (variation.multiPromptStepIndex) {
      extraParams.multiPromptStepIndex = variation.multiPromptStepIndex;
    }
    if (variation.multiPromptStepName) {
      extraParams.multiPromptStepName = variation.multiPromptStepName;
    }
    if (variation.mixtureSubAssetType) {
      extraParams.mixtureSubAssetType = variation.mixtureSubAssetType;
    }

    if (variation?.variations) {
      result.push(...flattenAndMergeVariations(variation?.variations, extraParams));
    }
  }
  return result;
};
