// Get 'chrome' browser object from global and check if it supports messaging
const { chrome } = window;
const doesSupportMessages =
  chrome && chrome.runtime && typeof chrome.runtime.sendMessage === 'function';

/**
 * Send message to browser extension
 *
 * @param {string} extensionId - ID of the extension
 * @param {object} message - content of the message
 */
export const sendMessageToExtension = (extensionId, message) => {
  if (!doesSupportMessages) {
    return Promise.reject(new Error('Extension messages are not supported'));
  }

  return new Promise((resolve, reject) => {
    try {
      chrome.runtime.sendMessage(extensionId, message, (reply) => {
        if (chrome.runtime.lastError) {
          reject(chrome.runtime.lastError.message);
          return;
        }

        if (reply && reply.success) {
          resolve(reply.data);
          return;
        }

        reject(reply && reply.error);
      });
    } catch (error) {
      reject(error);
    }
  });
};
