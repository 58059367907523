import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      // override default 'button' styling
      display: 'inline',
      border: 'none',
      background: 'none',
      padding: '0',
      outline: 'none',
      cursor: 'pointer',
      // style as a link
      font: 'inherit',
      color: COLORS.primary.default,

      '&:enabled': {
        cursor: 'pointer',
      },

      '&:hover:enabled': {
        textDecoration: 'none',
      },

      '&:hover': {
        color: COLORS.primary.active,
      },

      '&:focus:enabled': {
        color: theme.palette.primary.dark,
      },
    },
    underline: {},
  }),
  { name: 'TextLink' }
);
