import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    header: {
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(0.5),
    },
    boxGroupTitle: {},
    container: {
      display: 'flex',
      background: COLORS.neutral.white,
      columnGap: theme.spacing(1),
      alignItems: 'center',
      borderRadius: theme.shape.borderRadiusSemiLarge,
      padding: theme.spacing(1.5, 2),
      minHeight: 80,
      color: COLORS.gray.black,
      '&$shouldUpgrade': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: theme.shape.borderRadiusSemiLarge,
        borderTopRightRadius: theme.shape.borderRadiusSemiLarge,
      },

      '&$isInfinity': {
        '& $subtitle': {
          fontSize: theme.typography.pxToRem(13),
          lineHeight: theme.typography.pxToRem(16),
        },
        '& $accountName': {
          fontSize: theme.typography.pxToRem(13),
          lineHeight: theme.typography.pxToRem(16),
        },
      },
    },
    informationIcon: {
      color: COLORS.gray.gray40,
    },
    shouldUpgrade: {},
    isInfinity: {},
    subtitle: {
      display: 'inline',
      columnGap: theme.spacing(0.25),
      color: COLORS.gray.gray30,
      '& span': {
        verticalAlign: 'middle',
      },
    },
    title: {
      ...theme.typography.regular14,
      color: COLORS.gray.gray30,
      minWidth: 'max-content',
      '& span': {
        color: COLORS.gray.black,
        fontSize: theme.typography.pxToRem(24),
        lineHeight: theme.typography.pxToRem(32),
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    loader: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'Benchmark' }
);
