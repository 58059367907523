import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    paper: {
      width: 542,
    },
    dialogTitle: {
      position: 'relative',
      padding: theme.spacing(7.5, 7.5, 1.5),
    },
    buttonClose: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
      padding: theme.spacing(1),
      '& svg': {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    textTitle: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
      fontWeight: theme.typography.fontWeightMedium,
    },

    dialogContent: {
      padding: theme.spacing(0, 7.5, 5),
    },
    textContent: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(15),
      lineHeight: theme.typography.pxToRem(22),
      color: theme.palette.text.secondary,
    },

    dialogActions: {
      margin: '0',
      padding: theme.spacing(0, 7.5, 7.75),
      alignItems: 'center',
    },
    buttonCancel: {
      width: '150px',
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.primary,
      border: 'none',
    },
    buttonConfirm: {
      width: '170px',
      marginLeft: theme.spacing(2),
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  { name: 'DowngradeTierConfirmationPopup' }
);
