import React, { forwardRef } from 'react';
import { SvgIcon } from '@material-ui/core';

export const DiamondIcon = forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 26 26" data-fill-none>
    <path
      d="M17.5222 6.78271H8.4788L3.95703 11.3045L13.0005 20.9132L22.044 11.3045L17.5222 6.78271Z"
      fill="#ECF2FF"
    />
    <path d="M3.95703 11.3045H8.4788V6.78271L3.95703 11.3045Z" fill="#F8FAFE" />
    <path
      d="M17.5219 6.78271V11.3045H8.47852L13.0002 20.9132L22.0437 11.3045L17.5219 6.78271Z"
      fill="#C9DBFF"
    />
    <path
      d="M17.5217 11.3044H22.0435L13 20.9131L17.5217 11.3044Z"
      fill="#91B6FF"
    />
    <path
      d="M25.6539 20.7703L25.5195 20.913L25.6581 20.7745L25.6539 20.7703Z"
      fill="#005ECE"
    />
  </SvgIcon>
));
