import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 608,
      width: 608,
      paddingTop: theme.spacing(6.5),
    },
    dialogContent: {
      padding: theme.spacing(2, 4, 1),
    },
    title: {
      fontFamily: theme.typography.fontRobotoSlab,
      fontSize: theme.typography.pxToRem(28),
      margin: theme.spacing(2, 0),
    },
    dialogActions: {
      border: 0,
      justifyContent: 'center !important',
      padding: theme.spacing(2, 4, 4.5),
    },
    button: {
      padding: theme.spacing(1.5, 4),
      height: 48,
      ...theme.typography.medium16,
    },
  }),
  { name: 'G2ReviewBoostClaimedPopup' }
);
