import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom-v5-compat';

import { AnywordLogo } from 'src/assets/icons/AnywordLogo';
import { IconChannelTeads } from 'src/assets/icons/ChannelTeads';
import { ROUTES } from 'src/routes';
import { isTeadsUI } from 'src/i18n';
import { isMobileDevice } from 'src/lib/userAgent';

import { APP_LOGO_VARIANT } from './const';
import { useStyles } from './useStyles';

export const AppLogo = ({ className, variant, onClick }) => {
  const styles = useStyles();

  const isMobile = isMobileDevice();

  return (
    <Link
      className={cx(styles.root, className)}
      to={isMobile ? ROUTES.MOBILE : ROUTES.ROOT}
      onClick={onClick}
      data-variant={variant || APP_LOGO_VARIANT.default}
      data-testid="AppLogo"
    >
      {isTeadsUI() ? (
        <IconChannelTeads className={cx(styles.logo, styles.logoTeads)} />
      ) : (
        <AnywordLogo className={cx(styles.logo, styles.logoAnyword)} />
      )}
    </Link>
  );
};

AppLogo.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(APP_LOGO_VARIANT)),
  onClick: PropTypes.func,
};

AppLogo.defaultProps = {
  className: null,
  variant: APP_LOGO_VARIANT.default,
  onClick: null,
};
