import React from 'react';

import { SuspenseFallbackPopup } from 'src/components/SuspenseFallbackPopup';
import ErrorBoundary from 'src/services/ErrorBoundary';
import { lazyWithRetry } from 'src/utils/lazy';

const AsyncView = lazyWithRetry(() =>
  import(
    // RenameCustomerPopup is a part of InviteUsers functionality,
    // it is only displayed after invitation is sent from InviteUsersPopup
    // thats why it is a part of InviteUsersPopup chunk
    /* webpackChunkName: "InviteUsersPopup" */ './RenameCustomerPopup'
  )
);

export const RenameCustomerPopup = (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<SuspenseFallbackPopup />}>
      <AsyncView {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
