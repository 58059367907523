import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { orderBy } from 'lodash';

import { SORT_DIRECTION } from 'src/data/common';
import { TALKING_POINT_FIELD } from 'src/data/performance';
import { useNotificationActions } from 'src/store';
import { formatNumber } from 'src/utils/format';
import { TextLink } from 'src/components/TextLink';
import { AnnotationRating } from 'src/components/AnnotationRating';
import { PerformanceEmptyState } from 'src/components/PerformanceEmptyState';
import { IconCopy } from 'src/assets/icons/IconCopy';
import { copyTextToClipboard } from 'src/utils/clipboard';
import { usePreparedTalkingPoints } from '../../services';
import { useStyles } from './useStyles';
import { ResourceEmptyState } from '../ResourceEmptyState';
import { Skeleton } from '@material-ui/lab';

export const TalkingPoints = ({
  className,
  talkingPoints,
  channel,
  dataSource,
  copies,
  onSeeMoreClick,
  isIntegrationChannel,
  onCopyTalkingPointClick,
  isLoading,
  title,
  label,
}) => {
  const styles = useStyles();

  const notifications = useNotificationActions();

  const { preparedTalkingPoints } = usePreparedTalkingPoints({
    talkingPoints,
    copies,
  });

  const sorted = useMemo(
    () =>
      orderBy(
        isIntegrationChannel ? preparedTalkingPoints : talkingPoints,
        [TALKING_POINT_FIELD.rating],
        [SORT_DIRECTION.desc]
      ),
    [preparedTalkingPoints, isIntegrationChannel, talkingPoints]
  );

  const handleCopyTalkingPoint = useCallback(
    async (text) => {
      await copyTextToClipboard(text);
      onCopyTalkingPointClick?.();
      notifications.displaySuccess('Copied to clipboard');
    },
    [notifications, onCopyTalkingPointClick]
  );

  if (!sorted.length) {
    return isIntegrationChannel ? (
      <PerformanceEmptyState />
    ) : (
      <ResourceEmptyState channel={channel} />
    );
  }

  const prepared = onSeeMoreClick ? sorted.slice(0, 5) : sorted;

  return (
    <div
      className={cx(styles.root, className)}
      data-testid="TalkingPoints"
    >
      <div className={styles.talkingPointsWrapper}>
        {prepared.map((tp, index) => (
          <div
            key={index}
            className={styles.talkingPoint}
          >
            <Typography className={styles.rank}>{index + 1}</Typography>
            <div className={styles.texts}>
              <Typography className={styles.title}>
                {tp[TALKING_POINT_FIELD.talkingPoint]}
              </Typography>
              {copies.length > 0 && (
                <>
                  {isIntegrationChannel && isLoading ? (
                    <div className={styles.skeletonsWrapper}>
                      <Skeleton
                        className={styles.skeleton}
                        animation="wave"
                        width={90}
                        height={16}
                      />
                      ∙
                      <Skeleton
                        className={styles.skeleton}
                        animation="wave"
                        width={70}
                        height={16}
                      />
                    </div>
                  ) : (
                    <Typography className={styles.description}>
                      {formatNumber(tp[TALKING_POINT_FIELD.impressions])} impression
                      {tp[TALKING_POINT_FIELD.impressions] > 1 ? 's' : ''} ∙{' '}
                      {tp[TALKING_POINT_FIELD.numberOfInstances]} instance
                      {tp[TALKING_POINT_FIELD.numberOfInstances] > 1 ? 's' : ''}
                    </Typography>
                  )}
                </>
              )}
            </div>
            <AnnotationRating
              className={styles.rating}
              rating={tp[TALKING_POINT_FIELD.rating]}
            />

            <Tooltip title="Copy talking point to clipboard">
              <IconButton
                className={styles.copyButton}
                classes={{ startIcon: styles.buttonStartIcon }}
                onClick={() => handleCopyTalkingPoint(tp[TALKING_POINT_FIELD.talkingPoint])}
              >
                <IconCopy className={styles.iconCopy} />
              </IconButton>
            </Tooltip>
          </div>
        ))}
      </div>
      {!!onSeeMoreClick && (
        <div className={styles.seeMoreWrapper}>
          <TextLink
            className={styles.seeMore}
            component="button"
            onClick={() =>
              onSeeMoreClick({ channel, dataSource, talkingPoints, copies, title, label })
            }
            rel="noopener"
          >
            See more
          </TextLink>
        </div>
      )}
    </div>
  );
};
