import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: 'white',
      width: '100%',
    },
    title: {},
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  }),
  { name: 'Loader' }
);
