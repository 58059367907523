import { createStateSelectorHook } from 'src/lib/sweetState';
import { MainStore } from '../../store';

const useMainState = createStateSelectorHook(MainStore, ['collapseItemId', 'menuItemId']);

export const useViewState = () => {
  const mainState = useMainState();

  return { ...mainState };
};
