/**
 * Asset types
 */
export const AD_ASSET_TYPE = {
  facebookMixtureAd: 'facebook_mixture_ad',
  pinterestMixtureAd: 'pinterest_mixture_ad',
  twitterMixtureAd: 'twitter_mixture_ad',
  linkedinMixtureAd: 'linkedin_mixture_ad',
  facebookHeadline: 'facebook_headline',
  facebookPrimaryText: 'facebook_primary_text',
  googleRSA: 'google_rsa',
  googleHeadline: 'google_headline',
  googleDescription: 'google_description',
  outbrainHeadline: 'outbrain_headline',
  taboolaHeadline: 'taboola_headline',

  linkedinTextAdHeadline: 'linkedin_text_ad_headline',
  linkedinTextAdDescription: 'linkedin_text_ad_description',
  linkedinTitle: 'linkedin_title',
  linkedinIntro: 'linkedin_intro_copy',
  linkedinSingleImageHeadline: 'linkedin_single_image_headline',
  linkedinSingleImageIntro: 'linkedin_single_image_intro',
  twitterTitle: 'twitter_title',
  twitterCopy: 'twitter_copy',
  youtubeTitle: 'youtube_title',
  youtubeDescription: 'youtube_description',
  pinterestTitle: 'pinterest_title',
  pinterestDescription: 'pinterest_description',

  teadsSingleImageHeadline: 'teads_single_image_headline',
  teadsSingleImageDescription: 'teads_single_image_description',
  teadsSingleVideoHeadline: 'teads_single_video_headline',
  teadsSingleVideoDescription: 'teads_single_video_description',
  teadsCarouselHeadline: 'teads_carousel_headline',
  teadsSocialLayout1Headline: 'teads_social_layout_1_headline',
  teadsSocialLayout1PrimaryText: 'teads_social_layout_1_primary_text',
  teadsSocialLayout1Description: 'teads_social_layout_1_description',
  teadsSocialLayout2PrimaryText: 'teads_social_layout_2_primary_text',
};

export const LANDING_ASSET_TYPE = {
  landingHeadline: 'landing_page_headline',
  landingCTA: 'landing_page_call_to_action',
  landingProductDescription: 'landing_page_product_description',
  landingProductName: 'landing_page_product_name',
  landingCopy: 'landing_page_copy',
  landingSEOMetadata: 'landing_page_seo_meta_data',
  landingBuilderHeadline: 'landing_page_builder_headline',
  landingBuilderSubHeadline: 'landing_page_builder_headline_supporting_copy',
  landingBuilderCta: 'landing_page_builder_cta',
  reinforcementStatement: 'landing_page_builder_reinforcement_statement',
  benefits: 'landing_page_builder_benefits',
  features: 'landing_page_builder_features',
};

export const PRODUCT_LISTING_ASSET_TYPE = {
  amazonProductDescription: 'amazon_product_description',
  amazonFeatureList: 'amazon_feature_list',
  walmartProductDescription: 'walmart_product_description',
  walmartFeatureList: 'walmart_feature_list',
  generalProductDescription: 'general_product_description',
  amazonProductListing: 'amazon_product_listing',
  walmartProductListing: 'walmart_product_listing',
  generalProductListing: 'general_product_listing',
};

export const SOCIAL_ASSET_TYPE = {
  facebookPost: 'facebook_post',
  instagramCaption: 'instagram_caption',
  tweet: 'tweet',
  linkedinPost: 'linkedin_post',
  pinterestPostTitle: 'pinterest_post_title',
  pinterestPost: 'pinterest_post',
};

export const EMAIL_ASSET_TYPE = {
  emailSubject: 'email_subject',
  emailBody: 'email_body',
  emailColdOutreachBody: 'email_cold_outreach_body',
  emailPromotionalBody: 'email_promotional_body',
  emailMixture: 'email_mixture',
};

export const BLOG_POST_ASSET_TYPE = {
  blogPostOutline: 'blog_post_outline',
  blogPostIntroParagraph: 'blog_post_intro_paragraph',
  blogPostTitle: 'blog_post_title',
  blogPostContent: 'blog_post_content',
  blogPostList: 'blog_post_list',
};

export const SMS_ASSET_TYPE = {
  sms: 'sms',
};

export const DEPRECATED_ASSET_TYPE = {
  verizonHeadline: 'verizon_headline',
  verizonDescription: 'verizon_description',
  subHeading: 'landing_page_builder_sub_heading',
  subHeadingSupportingCopy: 'landing_page_builder_sub_headline_supporting_copy',
  consumerBenefits: 'consumer_benefits',
  canvasFacebookAd: 'canvas_facebook_ad',
  canvasLandingPage: 'canvas_landing_page_builder',
  canvasInstagramSocial: 'canvas_instagram_social',
  canvasFacebookSocial: 'canvas_facebook_social',
  canvasTwitterSocial: 'canvas_twitter_social',
  canvasLinkedinSocial: 'canvas_linkedin_social',
  canvasPinterestPinSocial: 'canvas_pinterest_pin_social',
  canvasGoogleAd: 'canvas_google_ad',
  canvasYoutubeSocial: 'canvas_youtube_social',
  canvasColdEmail: 'canvas_cold_email',
  canvasLandingPageSeo: 'canvas_landing_page_seo',
  canvasWalmartProductDescription: 'canvas_walmart_product_description',
  canvasWalmartFeatureList: 'canvas_walmart_feature_list',
  canvasAmazonProductDescription: 'canvas_amazon_product_description',
  canvasAmazonFeatureList: 'canvas_amazon_feature_list',
  canvasTwitterAd: 'canvas_twitter_ad',
  canvasLinkedinTextAd: 'canvas_linkedin_text_ad',
  canvasPinterestAd: 'canvas_pinterest_ad',
  canvasPromotionalEmail: 'canvas_promotional_email',
  canvasEmailSubjectLine: 'canvas_email_subject_line',
  canvasSms: 'canvas_sms',
  emailSubjectLine: 'email_subject_line',
  emailColdOutreachSubjectLine: 'email_cold_outreach_subject_line',
  emailPromotionalSubjectLine: 'email_promotional_subject_line',
};

export const CANVAS_ASSET_TYPE = {
  canvasAida: 'canvas_aida',
  canvasPas: 'canvas_pas',
  canvasBab: 'canvas_bab',
  canvasFeatureToBenefitsPractical: 'canvas_feature_to_benefits_practical',
  canvasPerfectHeadline: 'canvas_perfect_headline',
  canvasPersuasiveBulletPoint: 'canvas_persuasive_bullet_point',
  canvasValueProposition: 'canvas_value_proposition',
  canvasProductDescriptionList: 'canvas_product_description_list',
  canvasProductDescriptionRelaxed: 'canvas_product_description_relaxed',
  canvasBlogIdeas: 'canvas_blog_ideas',
  canvasVideoIdeas: 'canvas_video_ideas',
  canvasContentImprover: 'canvas_content_improver',
  canvasEli5: 'canvas_eli5',
  canvasSentenceExpander: 'canvas_sentence_expander',
  canvasFreestyle: 'canvas_freestyle',
  productReview: 'product_review',
  freeTextHeadline: 'free_text_headline',
  freeTextTopic: 'free_text_topic',
  freeTextBody: 'free_text_body',
  personalBio: 'personal_bio',
  companyBio: 'company_bio',
  engagingQuestions: 'engaging_questions',
  quoraAnswers: 'quora_answers',
};

export const EDITOR_MARKED_ASSET_TYPE = {
  editorRephrase: 'editor_rephrase',
  editorParagraph: 'editor_paragraph',
};

export const ACTIVE_ASSET_TYPE = {
  ...CANVAS_ASSET_TYPE,
  ...AD_ASSET_TYPE,
  ...LANDING_ASSET_TYPE,
  ...SOCIAL_ASSET_TYPE,
  ...EMAIL_ASSET_TYPE,
  ...BLOG_POST_ASSET_TYPE,
  ...SMS_ASSET_TYPE,
  ...PRODUCT_LISTING_ASSET_TYPE,
  ...EDITOR_MARKED_ASSET_TYPE,
};

export const ASSET_TYPE = {
  ...ACTIVE_ASSET_TYPE,
  ...DEPRECATED_ASSET_TYPE,
};

export const DEFAULT_ASSET_TYPE = ASSET_TYPE.facebookPrimaryText;

export const CANVAS_ASSET_GROUP = {
  writingAssistance: 'Writing Assistance',
  copywritingFrameworks: 'Copywriting Frameworks',
  business: 'Business',
  ecommerce: 'Ecommerce',
  ideation: 'Ideation',
  ads: 'Ads',
  website: 'Website',
  social: 'Social',
  emails: 'Email',
  sms: 'SMS',
  yourTools: 'Your Tools',
};
