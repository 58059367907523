import { get } from 'lodash';

import { contentTypeFromBriefType } from 'src/data/brief';
import PROJECTS_MODES_AVAILABILITY from './PROJECTS_MODES_AVAILABILITY.json';

export const getAvailableSuggestionModes = (projectType, assetType, briefType) => {
  // Try to check modes by AssetType inside Project
  const byAssetType = get(PROJECTS_MODES_AVAILABILITY, [projectType, assetType]);
  if (byAssetType) {
    return byAssetType;
  }

  // Try to check modes by BriefType inside Project
  const byBriefType = get(PROJECTS_MODES_AVAILABILITY, [projectType, briefType, assetType]);
  if (byBriefType) {
    return byBriefType;
  }

  // Try to check modes by ContentType inside Project
  const contentType = contentTypeFromBriefType(briefType);
  const byContentType = get(PROJECTS_MODES_AVAILABILITY, [projectType, contentType, assetType]);

  if (byContentType) {
    return byContentType;
  }

  // The Table is not finished
  throw new Error(
    `Unexpected PROJECTS_MODES_AVAILABILITY: ${JSON.stringify({
      projectType,
      assetType,
      briefType,
      contentType,
    })}`
  );
};
