import { useCallback } from 'react';
import { useBrandVoicesActions, useCustomModelsActions, useToneOfVoiceActions } from 'src/store';

export const useRefreshBrandVoiceToneOfVoices = () => {
  const brandVoicesActions = useBrandVoicesActions();
  const customModelsActions = useCustomModelsActions();
  const toneOfVoiceStoreActions = useToneOfVoiceActions();

  return useCallback(
    async (brandVoiceId) => {
      const promises = [
        brandVoicesActions.queryBrandVoicesAssets(),
        toneOfVoiceStoreActions.queryCustomToneOfVoiceList(brandVoiceId, true),
        customModelsActions.queryCustomModels([brandVoiceId], true),
      ];

      await Promise.all(promises);
    },
    [brandVoicesActions, toneOfVoiceStoreActions, customModelsActions]
  );
};
