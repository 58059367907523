import { orderBy } from 'lodash';

export const NOTIFICATION_LEVEL = {
  generic: 'generic',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

export const NOTIFICATION_TYPE = {
  generic: 'generic',
  planMigration: 'plan_migration',
  growthCampaignDDFeatures: 'growth_campaign_dd_features',
  basicUsersAccessToDDFeatures: 'basic_users_access_to_dd_features',
  basicTieredAccessToDDFeatures: 'basic_tiered_users_access_to_dd_features',
  highSuggestionsRequestsErrorRate: 'high_suggestions_requests_error_rate',
  systemNotFunctioning: 'system_not_functioning',
  walkthroughWebinarBonusWords: 'walkthrough_webinar_bonus_words',
  // deprecated, will remove
  bonusCreditsAdded: 'bonus_credits_added',
  bonusCreditsDDFirstWeek: 'bonus_credits_dd_first_week',
  growthCampaignBonusWords: 'growth_campaign_bonus_words',
};

export const POPUP_NOTIFICATION_TYPES = [
  NOTIFICATION_TYPE.bonusCreditsAdded,
  NOTIFICATION_TYPE.bonusCreditsDDFirstWeek,
  NOTIFICATION_TYPE.growthCampaignBonusWords,
  NOTIFICATION_TYPE.growthCampaignDDFeatures,
  NOTIFICATION_TYPE.basicUsersAccessToDDFeatures,
  NOTIFICATION_TYPE.basicTieredAccessToDDFeatures,
  NOTIFICATION_TYPE.walkthroughWebinarBonusWords,
];

export const IMPORTANT_NOTIFICATION_TYPES = [
  NOTIFICATION_TYPE.highSuggestionsRequestsErrorRate,
  NOTIFICATION_TYPE.systemNotFunctioning,
];

export const isPopupTypeNotification = (notification) =>
  POPUP_NOTIFICATION_TYPES.includes(notification?.type);

export const isImportantNotification = (notification) =>
  IMPORTANT_NOTIFICATION_TYPES.includes(notification?.type);

export const sortNotifications = (notifications) =>
  orderBy(notifications, [(n) => isImportantNotification(n)], ['desc']);

export const NOTIFICATION_TIMEOUTS = {
  default: 15000,
  success: 3000,
};
