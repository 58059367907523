import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION } from './schema';

const MUTATION = gql`
  mutation UpdateBlogPostBuilderOutlineSection(
    $id: ID!
    $headline: String!
    $content: String
    $keywordsUsage: JSON
    $manualKeywords: [String!]
    $instructions: String
    $generationLength: BlogOutlineSectionGenerationLengthEnum
  ) {
    updateBlogPostBuilderOutlineSection(
      id: $id
      headline: $headline
      content: $content
      keywordsUsage: $keywordsUsage
      manualKeywords: $manualKeywords
      instructions: $instructions
      generationLength: $generationLength
    ) {
      ...BlogPostBuilderOutlineSection
    }
  }
  ${FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION}
`;

export const updateBlogPostBuilderOutlineSection = ({
  id,
  headline,
  content,
  keywordsUsage,
  manualKeywords,
  instructedWriting,
  generationLength,
}) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: {
      id,
      headline,
      content,
      keywordsUsage: keywordsUsage ? JSON.stringify(keywordsUsage) : null,
      manualKeywords,
      instructions: instructedWriting,
      generationLength,
    },
  }).then(({ data }) => getSingleProp(data));
};
