import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      gap: theme.spacing(0.5),
      paddingBottom: theme.spacing(1),
    },

    bar: {
      flex: 1,
      transformOrigin: 'bottom',
    },
  }),
  { name: 'Loader' }
);
