import { Editor as SlateEditor, Transforms } from 'slate';

import { textToSlate } from 'src/components/RichSlate'; // TODO: consider moving this to global scope
import { createSlateRange } from './queries';

/**
 * Apply text changes to the Slate editor
 * @param {import('slate').Editor} editor - Slate editor instance
 * @param {[{ startIndex: number, oldText: string, newText: string }]} changes - Text changes
 */
export const applyEditorTextChanges = (editor, changes) => {
  changes.forEach((change) => {
    if (!change?.oldText || !change?.newText) {
      return;
    }

    const endIndex =
      change.endIndex != null ? change.endIndex : change.startIndex + change.oldText.length;
    const range = createSlateRange(editor, change.startIndex, endIndex);
    if (!range) {
      return;
    }

    Transforms.insertText(editor, change.newText, {
      at: range,
    });
  });
};

export const replaceSlateEditorValue = (editor, newEditorValue) => {
  if (!editor) {
    return;
  }

  // Remove selection, in order to mitigate "can't find node" errors
  Transforms.deselect(editor);

  // Remove existing nodes
  const children = [...editor.children];
  children.forEach((node) => editor.apply({ type: 'remove_node', path: [0], node }));

  // Add new nodes
  newEditorValue.forEach((node, i) => editor.apply({ type: 'insert_node', path: [i], node: node }));

  // Place cursor at the end of the new content
  const point = SlateEditor.end(editor, []);
  Transforms.select(editor, point);
};

export const replaceSlateEditorText = (editor, newTextValue) => {
  if (!editor || newTextValue == null) {
    return;
  }

  const newValue = textToSlate(newTextValue);
  replaceSlateEditorValue(editor, newValue);
};
