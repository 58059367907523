import React, { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const PersonaAvatarFive = memo(
  forwardRef(({ opaque, ...props }, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 72 72"
        data-fill-none
      >
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M72.0002 36.0013C71.9997 43.1211 69.8878 50.0807 65.9318 56.0003C61.9757 61.9199 56.3531 66.5335 49.7749 69.2577C43.1966 71.982 35.9583 72.6945 28.9752 71.3051C21.992 69.9158 15.5777 66.4871 10.5432 61.4525C5.5088 56.4179 2.0804 50.0035 0.691547 43.0206C-0.697302 36.0376 0.0157801 28.7996 2.74062 22.2219C5.46547 15.6442 10.0797 10.0221 15.9998 6.06667C21.9199 2.11121 28.8801 2.0665e-08 36.0001 0C45.5471 0.00436146 54.7017 3.79884 61.4522 10.5495C68.2026 17.3002 71.9966 26.4548 72.0002 36.0013Z"
            fill="#8CB2FF"
          />
          <path
            d="M67.5468 36.0026C67.5468 42.2409 65.6969 48.3392 62.2309 53.5261C58.7649 58.7131 53.8386 62.7559 48.0749 65.1432C42.3111 67.5305 35.9689 68.1552 29.8502 66.9381C23.7314 65.7211 18.111 62.717 13.6997 58.3059C9.28831 53.8947 6.28413 48.2745 5.06704 42.156C3.84995 36.0376 4.4746 29.6956 6.86202 23.9321C9.24943 18.1686 13.2924 13.2425 18.4796 9.77666C23.6668 6.31082 29.7653 4.46094 36.0039 4.46094C44.368 4.46602 52.3882 7.79078 58.3025 13.7049C64.2169 19.619 67.5418 27.6388 67.5468 36.0026Z"
            fill={opaque ? '#8CB2FF' : 'white'}
          />
          <path
            d="M54.7756 40.0476C54.7026 40.4206 54.5799 40.7822 54.4108 41.1227C53.8619 42.223 53.2089 43.2682 52.4606 44.244C52.0876 44.7157 50.4117 45.2423 50.4117 45.2423L50.3952 45.3054C50.3184 45.5605 50.0304 46.5451 49.7507 47.4036C49.7315 47.4667 49.7095 47.5325 49.6848 47.5956C49.6601 47.6587 49.6382 47.73 49.608 47.8123C49.1253 49.0493 47.4549 52.3899 47.2903 52.7191C47.2903 52.7191 47.2903 52.7191 47.2903 52.7355L46.931 57.3982V57.4914C46.9447 58.0839 47.0928 64.9737 47.422 66.8771C47.5433 67.5347 47.7831 68.1648 48.1296 68.7367C48.3262 69.0622 48.5462 69.373 48.7879 69.6665C40.9918 72.6144 32.4165 72.7781 24.5135 70.13C24.5876 69.8201 24.6699 69.469 24.7604 69.0823C25.2651 66.9073 25.97 63.7723 26.1318 62.4641C26.133 62.4467 26.133 62.4293 26.1318 62.4119C26.162 62.1816 26.184 61.899 26.2059 61.5891C26.2071 61.5773 26.2071 61.5653 26.2059 61.5535C26.3787 58.8491 26.3156 53.498 26.3156 53.498L25.6793 51.9648L23.293 46.1529L22.9172 45.2451C22.0943 45.2094 20.7092 43.8737 20.6242 43.7804C20.6242 43.7804 19.0114 41.1529 18.4984 39.0327C18.3256 38.3114 18.2818 37.6449 18.471 37.1649C18.6153 36.7945 18.8201 36.4506 19.0772 36.1474C19.5381 35.5925 20.1784 35.216 20.8875 35.0832L21.5622 28.6185L22.9775 23.3881L35.3369 17.8203L42.4519 18.048L51.6844 24.4852L52.211 27.5845L52.9022 34.5621L53.3438 35.0804L53.5879 35.3739L54.611 36.5807L54.7728 36.77C54.7728 36.77 54.7728 36.8934 54.8003 37.0991C54.8927 38.0802 54.8844 39.0682 54.7756 40.0476Z"
            fill="#DEE6F7"
          />
          <path
            d="M36.0624 30.5391C36.3696 31.3153 36.3367 32.1847 36.2764 33.0075C36.0542 36.2193 35.5769 39.4365 34.4798 42.4618C34.3334 42.743 34.2972 43.0687 34.3783 43.3751C34.452 43.5276 34.5599 43.6609 34.6937 43.7646C35.6385 44.5948 36.8536 45.0521 38.1113 45.051"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.2109 48.3217C34.7226 48.7639 35.357 49.0394 36.0295 49.1116C36.6997 49.1793 37.3762 49.1479 38.0372 49.0184C38.768 48.9366 39.4657 48.6694 40.0642 48.2422"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.7266 52.0547C36.0694 52.1013 36.3931 52.2439 36.7332 52.307C36.9007 52.3517 37.0766 52.355 37.2457 52.3168C37.4148 52.2786 37.5721 52.1999 37.7042 52.0876"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.7031 39.3438C40.7833 39.643 40.926 39.9219 41.1217 40.162C41.3174 40.4022 41.5618 40.5981 41.8387 40.7371"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.3203 40.8278C31.6235 40.7353 31.9053 40.5834 32.1493 40.3809C32.3932 40.1785 32.5944 39.9295 32.7411 39.6484"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.6605 35.3557C51.9826 35.1597 52.357 35.0667 52.7335 35.0893C53.1099 35.1118 53.4705 35.2488 53.767 35.4818C54.3524 35.9684 54.7421 36.6504 54.8641 37.4018C55.0759 38.5431 54.9609 39.7212 54.5322 40.8C54.1046 41.8735 53.5284 42.8817 52.8207 43.7951C52.3105 44.4863 51.6632 45.2076 50.8047 45.2652"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.3633 40.3878C51.3879 39.9509 51.5758 39.5392 51.8898 39.2344C52.2038 38.9296 52.6209 38.7539 53.0584 38.7422"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.795 35.3554C21.4723 35.1596 21.0976 35.0667 20.7207 35.0893C20.3439 35.1118 19.9829 35.2487 19.6858 35.4816C19.1 35.9679 18.7102 36.6499 18.5886 37.4015C18.376 38.5427 18.4901 39.7207 18.9178 40.7998C19.3452 41.8674 19.9185 42.8707 20.6211 43.7811C21.1285 44.4723 21.7758 45.1937 22.6344 45.2513"
            stroke="#080808"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.0869 40.3878C22.0629 39.9512 21.8756 39.5395 21.562 39.2346C21.2485 38.9297 20.8317 38.7539 20.3945 38.7422"
            stroke="#080808"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.6987 19.2117C45.3315 21.7593 47.8099 23.65 50.6985 24.5518C50.4516 28.3588 50.8301 32.0807 51.4116 35.8492C51.8944 35.3007 52.7392 35.276 53.4331 35.0456C53.8007 32.5771 53.9131 29.859 54.6125 27.4728C54.6738 27.1937 54.7924 26.9303 54.9609 26.6994C55.153 26.5093 55.3655 26.341 55.5945 26.1975C56.3735 25.6133 56.7684 24.6396 56.9412 23.6824C57.3499 21.3894 56.464 18.7454 54.3986 17.6703C52.9257 16.905 51.0057 16.9571 49.8619 15.7503C49.168 15.018 48.9293 13.9813 48.4905 13.0679C47.3741 10.7146 44.9549 9.22807 42.4918 8.38879C40.227 7.63932 37.8331 7.35935 35.4564 7.56597C23.7306 8.45187 17.7704 20.4048 17.1889 21.9544C16.8515 22.8513 16.9366 23.8469 17.0463 24.8014C17.2712 26.7213 17.6524 28.7839 19.0815 30.0812C19.4106 30.3802 19.7974 30.6298 20.0113 31.0275C20.1486 31.3109 20.235 31.6164 20.2664 31.9298C20.6723 34.4669 21.3197 36.6967 21.7256 39.2338C22.4552 36.864 21.9039 34.5903 22.4963 32.1821C23.1903 29.3379 25.1213 26.8996 27.4801 25.1717C29.839 23.4437 32.6175 22.3604 35.4344 21.554C35.2507 22.0751 35.6347 22.6648 36.1394 22.887C36.6693 23.0706 37.2413 23.0954 37.7851 22.9583C39.6937 22.623 41.5022 21.8618 43.0761 20.7312C43.5643 20.3883 44.0909 19.7986 43.6987 19.2117Z"
            fill="black"
          />
          <path
            d="M47.1967 53.125C46.891 56.484 46.8113 59.8597 46.9581 63.2293C47.0541 65.311 47.279 67.486 48.42 69.2304"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.3318 53.4766C26.4717 58.9621 26.4662 64.6286 24.5078 69.7685"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.103 26.2203C40.8701 26.9197 40.721 27.6443 40.6587 28.3788C40.6246 28.57 40.6525 28.767 40.7382 28.9411C40.8669 29.1008 41.0441 29.2144 41.2429 29.2648L46.2871 31.1353C46.7219 31.3326 47.1947 31.4319 47.6722 31.426C47.7768 31.4253 47.8801 31.4029 47.9757 31.3603C48.0713 31.3178 48.157 31.2559 48.2276 31.1787C48.2981 31.1014 48.352 31.0104 48.3857 30.9114C48.4195 30.8124 48.4324 30.7075 48.4237 30.6032C48.4512 29.912 47.9492 29.3251 47.4555 28.8424C46.0457 27.471 44.3698 26.319 42.4635 25.861C41.9725 25.7513 41.2676 25.7321 41.103 26.2203Z"
            fill="black"
          />
          <path
            d="M32.7666 26.2203C32.9996 26.9197 33.1487 27.6443 33.211 28.3788C33.2451 28.57 33.2172 28.767 33.1315 28.9411C33.0027 29.1008 32.8256 29.2144 32.6268 29.2648L27.5826 31.1353C27.1519 31.3304 26.684 31.4296 26.2112 31.426C26.1066 31.4253 26.0033 31.4029 25.9077 31.3603C25.8121 31.3178 25.7264 31.2559 25.6558 31.1787C25.5852 31.1014 25.5314 31.0104 25.4977 30.9114C25.4639 30.8124 25.451 30.7075 25.4597 30.6032C25.4322 29.912 25.9342 29.3251 26.4279 28.8424C27.8377 27.471 29.5136 26.319 31.4199 25.861C31.8972 25.7513 32.6021 25.7321 32.7666 26.2203Z"
            fill="black"
          />
          <path
            d="M51.685 35.2734C51.685 48.8967 44.987 59.939 36.7227 59.939C28.8809 59.939 22.4598 49.9883 21.8125 37.3278"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.332 53.4766C26.332 53.4766 28.0408 64.1733 36.4806 64.1733C44.9204 64.1733 46.8871 53.9428 46.8871 53.9428C46.8871 53.9428 42.0788 60.4733 36.7247 60.2182C31.3707 59.9632 26.332 53.4766 26.332 53.4766Z"
            fill="black"
          />
          <path
            d="M28.7428 38.5681C29.2048 38.5681 29.5794 37.7588 29.5794 36.7606C29.5794 35.7624 29.2048 34.9531 28.7428 34.9531C28.2808 34.9531 27.9062 35.7624 27.9062 36.7606C27.9062 37.7588 28.2808 38.5681 28.7428 38.5681Z"
            fill="black"
          />
          <path
            d="M44.8834 38.5681C45.3455 38.5681 45.72 37.7588 45.72 36.7606C45.72 35.7624 45.3455 34.9531 44.8834 34.9531C44.4214 34.9531 44.0469 35.7624 44.0469 36.7606C44.0469 37.7588 44.4214 38.5681 44.8834 38.5681Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="72"
              height="72"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);

PersonaAvatarFive.propTypes = {
  opaque: PropTypes.bool,
};
PersonaAvatarFive.defaultProps = {
  opaque: false,
};
