import { createStateSelectorHook } from 'src/lib/sweetState';

import { PopupStore } from './store';

const usePopupState = createStateSelectorHook(PopupStore, [
  'error',
  'selectedTaxonomyId',
  'isLoading',
]);

export const useViewState = () => {
  const popupState = usePopupState();

  return {
    ...popupState,
  };
};
