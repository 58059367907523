import { useCallback } from 'react';

import { useCustomerPersonaActions, useNotificationActions } from 'src/store';
import { useErrorHandler } from 'src/services';
import { EVENT_PAGE, SUGGESTED_AUDIENCES } from 'src/lib/events';
import { useTrackingEvent } from './useTrackingEvent';

export const useAddCustomerPersonaToMyAudiences = () => {
  const customerPersonaStoreActions = useCustomerPersonaActions();
  const notificationActions = useNotificationActions();
  const handleError = useErrorHandler();
  const trackEvent = useTrackingEvent();

  return useCallback(
    async (brandVoice, customerPersona) => {
      const customerPersonaId = customerPersona ? customerPersona.id : null;
      if (customerPersona?.isSuggested) {
        trackEvent(SUGGESTED_AUDIENCES.saved, {
          page: EVENT_PAGE.editor,
        });
      }

      try {
        await customerPersonaStoreActions.saveSuggestedPersona(brandVoice, customerPersona);

        notificationActions.displaySuccess(
          `Target audience '${customerPersona?.name}' ${customerPersonaId ? 'updated' : 'created'}`
        );
      } catch (error) {
        handleError(error);
      }
    },
    [trackEvent, customerPersonaStoreActions, notificationActions, handleError]
  );
};
