import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { CUSTOMER_FRAGMENT } from 'src/graphql/customer';
import { executeGqlQuery } from 'src/graphql/client';
import { USER_FRAGMENT, FRAGMENT_ONBOARDING_STAGE, FRAGMENT_BOOKMARK } from './schema';
import { FRAGMENT_AB_TEST } from 'src/graphql/schema';

const QUERY = gql`
  query CurrentUser {
    me {
      ...User
      isConfirmed
      isKeywee
      isExpert
      hasExternalApiAccess
      createdAt
      featureFlags
      companyInformation
      segment

      accounts {
        edges {
          role
          node {
            ...Customer
          }
        }
      }

      onboardingStages {
        ...UserOnboardingStage
      }

      abTests {
        ...AbTest
      }

      bookmarks {
        ...Bookmark
      }
    }
  }
  ${USER_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
  ${FRAGMENT_ONBOARDING_STAGE}
  ${FRAGMENT_AB_TEST}
  ${FRAGMENT_BOOKMARK}
`;

export const queryCurrentUser = (options) => {
  return executeGqlQuery({
    query: QUERY,
    options,
  }).then(({ data }) => getSingleProp(data));
};
