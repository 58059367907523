import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query CustomerInvoices(
    $customerId: ID!
    $cursor: String
    $perPage: Int
    $status: InvoiceStatusEnum
  ) {
    customer(id: $customerId) {
      id
      invoices(first: $perPage, after: $cursor, status: $status) {
        edges {
          node {
            stripeId
            createdAt
            status
            amountDue
            amountPaid
            description
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

export function queryCustomerInvoices(customerId, cursor, perPage, status) {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      customerId,
      cursor,
      perPage,
      status,
    },
  }).then(({ data }) => getSingleProp(data));
}
