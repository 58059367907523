import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    paper: {
      width: 542,
    },
    dialogTitle: {
      position: 'relative',
      padding: theme.spacing(5, 5, 2),
    },
    buttonClose: {
      position: 'absolute',
      top: theme.spacing(3),
      right: theme.spacing(3),
      padding: theme.spacing(1),
      '& svg': {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    textTitle: {
      fontSize: theme.typography.pxToRem(24),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: theme.typography.fontWeightMedium,
    },

    dialogContent: {
      padding: theme.spacing(0, 5, 9),
    },
    textContent: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(21),

      '& > b': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },

    features: {
      marginTop: `${theme.spacing(2)}px !important`,
    },
    dialogActions: {
      padding: theme.spacing(0, 5, 5),
    },
    buttonCancel: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.primary,
    },
    buttonConfirm: {
      width: '170px',
      marginLeft: theme.spacing(2),
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  { name: 'DowngradePlanConfirmationPopup' }
);
