import { useCallback } from 'react';

import { useHistory } from 'src/lib/hooks/useHistory';
import { formatProjectCreateRoute } from 'src/lib/routing';
import { useCustomerActions } from 'src/store';
import { ROUTE_PARAMS } from 'src/routes';
import { useQuotaEndedPopup } from 'src/services/popup';
import mixpanel from 'src/lib/mixpanel';
import { getQueryParamValue } from 'src/lib/browser';

const prepareRouteParams = (options) => {
  const params = {};
  if (options?.generationTool) {
    params[ROUTE_PARAMS.generationTool] = options.generationTool;
  }
  if (options?.customMultiPromptId) {
    params[ROUTE_PARAMS.customMultiPromptId] = options.customMultiPromptId;
  }
  if (options?.customMultiPromptGroup) {
    params[ROUTE_PARAMS.customMultiPromptGroup] = options.customMultiPromptGroup;
  }
  if (options?.freestyleGenerationToolId) {
    params[ROUTE_PARAMS.freestyleGenerationToolId] = options.freestyleGenerationToolId;
  }
  if (options?.generateFrom) {
    params[ROUTE_PARAMS.generateFrom] = options.generateFrom;
  }

  const folder = getQueryParamValue(ROUTE_PARAMS.folder);
  if (folder) {
    params[ROUTE_PARAMS.folder] = folder;
  }

  return params;
};

export const useCreateProject = () => {
  const history = useHistory();
  const showQuotaEndedPopup = useQuotaEndedPopup();

  const customerActions = useCustomerActions();

  /**
   * Navigate user to creation of a new project for selected "project type" and, possibly, "asset type"
   * @param {PROJECT_TYPE} projectType - project type
   * @param {object} options - additional options
   */
  return useCallback(
    (projectType, options = null) => {
      if (customerActions.isCurrentQuotaEnded()) {
        showQuotaEndedPopup();
        return;
      }

      mixpanel.track(mixpanel.EVENTS.PROJECT_NEW_CLICK);

      // Navigate to the "create project" page
      const params = prepareRouteParams(options);
      history.push(formatProjectCreateRoute(projectType, params), {
        state: { textToImprove: options?.textToImprove, keywords: options?.keywords },
      });
    },
    [customerActions, showQuotaEndedPopup, history]
  );
};
