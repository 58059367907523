import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { normalizeTraitButNot } from 'src/data/toneOfVoice';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { CUSTOM_BUTTON_VARIANT, CustomButton } from 'src/components/CustomButton';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    classification: PropTypes.bool.isRequired,
    reason: PropTypes.string.isRequired,
    trait: PropTypes.shape({
      trait: PropTypes.string.isRequired,
      butNot: PropTypes.string,
    }),
  }).isRequired,
  analyzerToneOfVoice: PropTypes.shape({}).isRequired,
  onApplyToneOfVoice: PropTypes.func,
  applyingAnalyzedToneOfVoice: PropTypes.string,
};

const defaultProps = {
  className: null,
  onApplyToneOfVoice: null,
  applyingAnalyzedToneOfVoice: null,
};

export const ToneOfVoiceInsightsItem = ({
  className,
  item,
  analyzerToneOfVoice,
  onApplyToneOfVoice,
  applyingAnalyzedToneOfVoice,
}) => {
  const styles = useStyles();

  const handleClick = () => {
    onApplyToneOfVoice({
      toneOfVoice: analyzerToneOfVoice,
      trait: item.trait,
      butNot: item.trait.butNot,
    });
  };

  const isApplyingTrait = applyingAnalyzedToneOfVoice === item.trait.trait;

  return (
    <div className={cx(styles.root, className)}>
      <CustomTypography variant={TYPOGRAPHY_VARIANT.paragraph14}>{item.reason}</CustomTypography>
      {onApplyToneOfVoice && (
        <CustomButton
          className={styles.buttonApply}
          variant={CUSTOM_BUTTON_VARIANT.outlined}
          onClick={handleClick}
          disabled={!!applyingAnalyzedToneOfVoice}
          loading={isApplyingTrait}
        >
          Make {item.trait.butNot ? 'less' : 'more'}{' '}
          {item.trait.butNot ? normalizeTraitButNot(item.trait.butNot) : item.trait.trait}
        </CustomButton>
      )}
    </div>
  );
};

ToneOfVoiceInsightsItem.propTypes = propTypes;
ToneOfVoiceInsightsItem.defaultProps = defaultProps;
