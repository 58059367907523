import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { AppBar, Toolbar } from '@material-ui/core';
import FlexGrow from 'src/components/FlexGrow';

import { useCurrentUser } from 'src/store';
import { CommonItems } from './components/CommonItems';
import { useStyles } from './useStyles';

export const UnauthenticatedNavBar = ({ className }) => {
  const styles = useStyles();
  const currentUser = useCurrentUser();
  return (
    <AppBar
      className={cx(styles.muiAppBar, className)}
      position="absolute"
      elevation={0}
      data-testid="UnauthorizedNavBar"
    >
      <Toolbar
        className={cx(styles.toolbar)}
        disableGutters
      >
        <FlexGrow />

        <CommonItems user={currentUser} />
      </Toolbar>
    </AppBar>
  );
};

UnauthenticatedNavBar.propTypes = {
  className: PropTypes.string,
};

UnauthenticatedNavBar.defaultProps = {
  className: '',
};
