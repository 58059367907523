import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconDocument = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 32 32"
        data-fill-none
      >
        <path
          d="M27.7857 27.7856C27.7857 28.3539 27.5599 28.899 27.1581 29.3008C26.7563 29.7026 26.2111 29.9284 25.6429 29.9284H6.35714C5.78882 29.9284 5.24378 29.7026 4.84191 29.3008C4.44006 28.899 4.21429 28.3539 4.21429 27.7856V4.21415C4.21429 3.64582 4.44006 3.10078 4.84191 2.69892C5.24378 2.29705 5.78882 2.07129 6.35714 2.07129H20.2857L27.7857 9.57129V27.7856Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6429 10.6426H14.9286"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6429 17.0713H21.3571"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6429 23.5H21.3571"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
