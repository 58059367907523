import { createStore } from 'react-sweet-state';

import { captureMessage } from 'src/lib/sentry';
import { queryContentTaxonomies } from 'src/graphql/queryContentTaxonomies';
import { DEFAULT_TAXONOMY_NAME } from 'src/data/common';

export const NAME = 'common';

const INITIAL_STATE = {
  contentTaxonomies: [],
  defaultContentTaxonomies: [],
  loadingContentTaxonomies: false,
  loadedContentTaxonomies: false,

  authTokenGuardActive: true,
};

/**
 * Public available actions
 */
export const PRIVATE_ACTIONS = {
  initContentTaxonomies:
    () =>
    ({ getState, setState }, { notifications }) => {
      const { loadedContentTaxonomies } = getState();
      if (loadedContentTaxonomies) {
        return;
      }

      setState({ loadingContentTaxonomies: true });

      queryContentTaxonomies()
        .then((results) => {
          // Some "taxonomies" are marked as "default" ones,
          // and will be shown if nothing was found for user search input
          const defaultTaxonomies = results.filter(
            (o) => o.default && o.name !== DEFAULT_TAXONOMY_NAME
          );

          setState({
            contentTaxonomies: results,
            defaultContentTaxonomies: defaultTaxonomies,
            loadedContentTaxonomies: true,
          });
        })
        .catch((error) => {
          // Display notification to user
          notifications.displayWarning(
            'Network error',
            'Error fetching industry/topic options. Please try reloading the page.'
          );

          // Report "warning" to Sentry
          captureMessage('Error while loading context taxonomies', {
            level: 'warning',
            extra: {
              error: error.toString(),
            },
          });
        })
        .finally(() => {
          setState({ loadingContentTaxonomies: false });
        });
    },

  clearContentTaxonomies:
    () =>
    ({ setState }) => {
      setState({
        contentTaxonomies: [],
        defaultContentTaxonomies: [],
        loadingContentTaxonomies: false,
        loadedContentTaxonomies: false,
      });
    },
};

/**
 * Public available actions
 */
export const ACTIONS = {
  enableAuthTokeGuard:
    () =>
    ({ setState }) => {
      setState({ authTokenGuardActive: true });
    },
  disableAuthTokeGuard:
    () =>
    ({ setState }) => {
      setState({ authTokenGuardActive: false });
    },
};

export const CommonStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});
