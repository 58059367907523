import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { pluralize } from 'src/utils/format';
import { PROJECT_TYPE } from 'src/data/project';
import { formatTopThreeBrandVoicesWithCount } from 'src/data/brandTerm';
import { IconModified } from 'src/assets/icons/Modified';
import { TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { CustomIconButton } from 'src/components/CustomIconButton';
import { BrandTermsItem } from './components/BrandTermsItem';
import { EmptyTermsBanner } from './components/EmptyTermsBanner';
import { SuccessMessage } from './components/SuccessMessage';
import { InsightBox } from '../InsightBox';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  projectType: PropTypes.oneOf(Object.values(PROJECT_TYPE)).isRequired,
  availableBrandTermsCount: PropTypes.number,
  activeBrandVoices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  detectedBrandTerms: PropTypes.arrayOf(
    PropTypes.shape({
      brandTerm: PropTypes.shape({}).isRequired,
      termInText: PropTypes.string.isRequired,
    })
  ).isRequired,
  onApplyBrandTerm: PropTypes.func.isRequired,
  onBrandTermHover: PropTypes.func,
  onBrandTermClick: PropTypes.func,
  onSettingsClick: PropTypes.func,
  onEmptyTermsClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: null,
  classes: null,
  brandVoices: [],
  onBrandTermHover: null,
  onBrandTermClick: null,
  onSettingsClick: null,
  onEmptyTermsClick: null,
  loading: false,
  disabled: false,
};

export const BrandTerms = ({
  className,
  classes,
  projectType,
  availableBrandTermsCount,
  activeBrandVoices,
  detectedBrandTerms,
  onApplyBrandTerm,
  onBrandTermHover,
  onBrandTermClick,
  onSettingsClick,
  onEmptyTermsClick,
  loading,
  disabled,
}) => {
  const styles = useStyles({ classes });

  const isBlog = projectType === PROJECT_TYPE.blogPostBuilder;

  const activeBrandVoiceCount = activeBrandVoices.length;
  const hasDetectedTermsAndActiveBrandVoices =
    detectedBrandTerms.length > 0 && activeBrandVoiceCount > 0;
  const title = `Brand ${pluralize(activeBrandVoices.length || 1, 'vocabulary', 'vocabularies')}`;

  return (
    <div
      className={cx(styles.root, className, {
        [styles.isBlog]: isBlog,
      })}
      data-section="brand-terms"
    >
      <div className={styles.header}>
        <TruncatedTypography
          className={styles.boxGroupTitle}
          variant={TYPOGRAPHY_VARIANT.medium12}
        >
          {title}
          {activeBrandVoices.length > 0 &&
            `: ${formatTopThreeBrandVoicesWithCount(activeBrandVoices)}`}
        </TruncatedTypography>

        {onSettingsClick && (
          <CustomIconButton
            className={styles.buttonSettings}
            onClick={onSettingsClick}
          >
            <IconModified />
          </CustomIconButton>
        )}
      </div>
      <InsightBox
        className={styles.wrapper}
        loading={loading}
      >
        {hasDetectedTermsAndActiveBrandVoices ? (
          activeBrandVoices.map((brandVoice) => (
            <BrandTermsItem
              className={styles.brandTermsItem}
              key={brandVoice.id}
              brandVoice={brandVoice}
              detectedTerms={detectedBrandTerms}
              onApplyBrandTerm={onApplyBrandTerm}
              onBrandTermHover={onBrandTermHover}
              onBrandTermClick={onBrandTermClick}
              hoverable={isBlog}
              loading={loading}
              disabled={disabled}
            />
          ))
        ) : activeBrandVoiceCount > 0 && availableBrandTermsCount > 0 ? (
          <SuccessMessage
            activeBrandVoices={activeBrandVoices}
            onSettingsClick={onSettingsClick}
          />
        ) : (
          <EmptyTermsBanner
            availableBrandTermsCount={availableBrandTermsCount || 0}
            activeBrandVoiceCount={activeBrandVoiceCount || 0}
            onCtaClick={onEmptyTermsClick}
          />
        )}
      </InsightBox>
    </div>
  );
};

BrandTerms.propTypes = propTypes;
BrandTerms.defaultProps = defaultProps;
