export const DEFAULT_AC_PROMPT = `<PARAM> engine
gpt-3.5-turbo

<PARAM> temperature
0

<PARAM> top_p
0.9

<PARAM> presence_penalty
0.0

<PARAM> freq_penalty
0.0

<PARAM> response_length
100

<PARAM> stop

<PARAM> inloop_filter_functions

<PARAM> inloop_post_process_functions

<PARAM> preserve_newlines
True

<PARAM> oversampling_policy
no_over_sampling

<PARAM> text
Write something`;
