import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { IconEditPencil } from 'src/assets/icons/IconEditPencil';
import IconDelete from 'src/assets/icons/Delete';
import { ContextMenu } from 'src/components/ContextMenu';
import { SIDEBAR_ACTION } from '../../lib';
import { useStyles } from './useStyles';

export const BrandVoiceContextMenu = ({ className, brandVoice, onSidebarAction, ...restProps }) => {
  const styles = useStyles();

  const contextMenuItems = useMemo(
    () => [
      {
        id: 'edit',
        dataTestId: `editBrandVoice${brandVoice.id}MenuItem`,
        label: 'Change name',
        onClick: () => onSidebarAction(SIDEBAR_ACTION.editBrandVoice, brandVoice),
        icon: <IconEditPencil />,
      },

      {
        id: 'delete',
        dataTestId: `deleteBrandVoice${brandVoice.id}MenuItem`,
        label: 'Delete',
        onClick: () => onSidebarAction(SIDEBAR_ACTION.deleteBrandVoice, brandVoice),
        icon: <IconDelete />,
      },
    ],
    [brandVoice, onSidebarAction]
  );

  return (
    <ContextMenu
      className={cx(styles.collapseButtonActionMenu, className)}
      items={contextMenuItems}
      horizontal
      small
      {...restProps}
    />
  );
};

BrandVoiceContextMenu.propTypes = {
  className: PropTypes.string,
  brandVoice: PropTypes.shape({}).isRequired,
  onSidebarAction: PropTypes.func.isRequired,
};

BrandVoiceContextMenu.defaultProps = {
  className: '',
};
