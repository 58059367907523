import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
    },
    editingActions: {
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
    editor: {
      color: COLORS.neutral.blackBlue,
      lineHeight: theme.typography.pxToRem(24),
      fontSize: theme.typography.pxToRem(15),
    },
    alert: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      width: 'fit-content',
      height: 28,
      backgroundColor: COLORS.neutral.white,
      padding: theme.spacing(1.5),
      paddingLeft: theme.spacing(1),
      bottom: theme.spacing(1),
    },
    alertMessage: {
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(1),
      color: COLORS.gray.black,
    },
    alertIcon: {
      marginRight: theme.spacing(1),
      color: COLORS.success.default,
      '& svg': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    alertTitle: {
      ...theme.typography.medium12,
    },
  }),
  { name: 'CopyInput' }
);
