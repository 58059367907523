import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSlate } from 'slate-react';

import { CUSTOM_TOOLTIP_SIZE } from 'src/components/CustomTooltip';
import { SelectItems } from 'src/components/SelectItems';
import { SLATE_EDITOR_ACTION, SLATE_EDITOR_ACTIONS } from 'src/lib/slate';
import { onlyTextStyleOptions, prepareTextStyleOptions, textFormatToEditorAction } from '../../lib';
import { getCurrentEditorTextFormat } from '../../utils';
import { useStyles } from './useStyles';

export const TextStyleDropdown = ({ className, editorActions, onAction, disabled }) => {
  const styles = useStyles();
  const editor = useSlate();

  const currentTextFormat = getCurrentEditorTextFormat(editor);
  const options = useMemo(() => prepareTextStyleOptions(editorActions), [editorActions]);
  const value = clampSelectedValue(
    onlyTextStyleOptions(editorActions),
    textFormatToEditorAction(currentTextFormat),
    SLATE_EDITOR_ACTION.toggleParagraph
  );

  const handleChange = useCallback(
    (value) => {
      // Execute the action with a small timeout, so menu will be closed before the action is executed
      // and focus could be properly restored to the editor
      setTimeout(() => onAction(null, value), 100);
    },
    [onAction]
  );

  return (
    <SelectItems
      classes={{
        wrapper: cx(styles.wrapper, className),
        root: styles.root,
        select_select: styles.select_select,
        select_icon: styles.select_icon,
      }}
      value={value}
      onChange={handleChange}
      hint="Styles"
      disabled={disabled}
      options={options}
      TooltipProps={{
        size: CUSTOM_TOOLTIP_SIZE.small,
        arrow: true,
        placement: 'bottom',
      }}
    />
  );
};

TextStyleDropdown.propTypes = {
  className: PropTypes.string,
  editorActions: PropTypes.arrayOf(PropTypes.oneOf(SLATE_EDITOR_ACTIONS)).isRequired,
  onAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
TextStyleDropdown.defaultProps = {
  className: null,
  disabled: false,
};

const clampSelectedValue = (options, value, defaultValue) => {
  if (options.includes(value)) {
    return value;
  }
  return defaultValue;
};
