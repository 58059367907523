import { useMemo } from 'react';
import { camelCase, uniqueId } from 'lodash';

import {
  Performance,
  BrandVoiceIcon,
  IntegrationsResourcesIcon,
  SavedTemplatesIcon,
  TemplatesIcon,
} from 'src/assets/icons/navbar-links';
import IconChrome from 'src/assets/icons/IconChrome';
import { INFINITY_EXTENSION_STORE_URL } from 'src/data/infinity';
import { STONLY_ID } from 'src/lib/stonly';
import { ROUTES } from 'src/routes';
import { GENERATION_TOOL_POPUP } from 'src/services/popup';
import { ITEM_TYPE } from '../lib';

const createLinkItem = ({
  label,
  path,
  icon,
  isChildItem,
  popupKey,
  isEmptyItem,
  dataStonlyId,
  externalLink,
}) => ({
  type: ITEM_TYPE.link,
  id: `${camelCase(label)}LinkItem`,
  label,
  path,
  icon,
  popupKey,
  isEmptyItem,
  isChildItem,
  dataStonlyId,
  externalLink,
});

const createHeaderItem = ({ label }) => ({
  type: ITEM_TYPE.header,
  id: uniqueId('header'),
  label,
});

const createMenuItem = ({
  label,
  icon,
  items,
  displayCreateButton,
  isChildItem,
  dataStonlyId,
}) => ({
  type: ITEM_TYPE.menu,
  id: `${camelCase(label)}MenuItem`,
  label,
  icon,
  items,
  isChildItem,
  displayCreateButton,
  dataStonlyId,
});

const createBrandVoiceMenuItem = ({ id, label, items, displayActionsMenu }) => {
  return {
    id: `${camelCase(label)}${id}CollapsableItem`,
    label,
    type: ITEM_TYPE.collapse,
    items,
    displayActionsMenu,
    brandVoice: {
      id,
      name: label,
    },
  };
};

const createEmptyItem = () =>
  createLinkItem({ label: 'No brand voices are accessible', isEmptyItem: true });

export const useNavigationSidebarItems = (brandVoices) => {
  return useMemo(
    () => [
      createHeaderItem({ label: 'Assets & Tools' }),

      createMenuItem({
        label: 'Copy Intelligence',
        icon: Performance,
        items: [
          createLinkItem({
            label: 'Analytics',
            path: ROUTES.ANALYTICS,
            isChildItem: true,
          }),
          createLinkItem({
            label: 'Talking Points',
            path: ROUTES.TALKING_POINTS,
            isChildItem: true,
          }),
          createLinkItem({
            label: 'Website Automation',
            path: ROUTES.WEBSITE_AUTOMATION,
            isChildItem: true,
          }),
        ],
      }),

      createMenuItem({
        label: 'Brand Voice',
        displayCreateButton: true,
        icon: BrandVoiceIcon,
        dataStonlyId: STONLY_ID.brandVoicesSidebarItem,
        items:
          brandVoices?.length > 0
            ? brandVoices.map(({ id, name }) =>
                createBrandVoiceMenuItem({
                  id,
                  label: name,
                  displayActionsMenu: true,
                  items: [
                    createLinkItem({
                      label: 'Custom Models',
                      path: `${ROUTES.BRAND_VOICE}/${id}${ROUTES.CUSTOM_MODELS}`,
                      isChildItem: true,
                    }),
                    createLinkItem({
                      label: 'Tone of Voice',
                      path: `${ROUTES.BRAND_VOICE}/${id}${ROUTES.TONE_OF_VOICE}`,
                      isChildItem: true,
                    }),
                    createLinkItem({
                      label: 'Target Audiences',
                      path: `${ROUTES.BRAND_VOICE}/${id}${ROUTES.PERSONAS}`,
                      isChildItem: true,
                    }),
                    createLinkItem({
                      label: 'Messaging Bank',
                      path: `${ROUTES.BRAND_VOICE}/${id}${ROUTES.BRAND_BRIEFS}`,
                      isChildItem: true,
                    }),
                    createLinkItem({
                      label: 'Brand Vocabulary',
                      path: `${ROUTES.BRAND_VOICE}/${id}${ROUTES.BRAND_TERMS}`,
                      isChildItem: true,
                    }),
                    createLinkItem({
                      label: 'Formulas',
                      path: `${ROUTES.BRAND_VOICE}/${id}${ROUTES.CUSTOM_FORMULA}`,
                      isChildItem: true,
                    }),
                  ],
                })
              )
            : [createEmptyItem()],
      }),

      createLinkItem({
        label: 'Templates',
        popupKey: GENERATION_TOOL_POPUP,
        icon: TemplatesIcon,
        disableNavigation: true,
        dataStonlyId: STONLY_ID.templatesSidebarItem,
      }),

      createLinkItem({
        label: 'Template Builder',
        path: ROUTES.TEMPLATE_BUILDER,
        icon: SavedTemplatesIcon,
        dataStonlyId: STONLY_ID.templateBuilderSidebarItem,
      }),

      createLinkItem({
        label: 'Integrations & Resources',
        path: ROUTES.INTEGRATIONS_RESOURCES,
        icon: IntegrationsResourcesIcon,
      }),

      createLinkItem({
        label: 'Get Chrome Extension',
        path: INFINITY_EXTENSION_STORE_URL,
        icon: IconChrome,
        externalLink: true,
      }),
    ],
    [brandVoices]
  );
};
