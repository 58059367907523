import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import { PROMPT_TAG_SEARCH } from './const';

import { useStyles } from './useStyles';

export const PromptTagNode = ({ element, children, ...attributes }) => {
  const styles = useStyles();

  const childTextLower = element?.children?.[0]?.text?.toLowerCase();
  const [assetBackground, setAssetBackground] = useState(false);

  useEffect(() => {
    if (
      childTextLower?.includes(PROMPT_TAG_SEARCH.productDetails) ||
      childTextLower?.includes(PROMPT_TAG_SEARCH.productDescription)
    ) {
      setAssetBackground(true);
    }
  }, [childTextLower]);

  if (!childTextLower) {
    return null;
  }

  return (
    <Typography
      {...attributes}
      className={cx(styles.root, {
        [styles.assetBackground]: assetBackground,
      })}
      component="span"
    >
      {children}
    </Typography>
  );
};

PromptTagNode.propTypes = {
  attributes: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  element: PropTypes.shape().isRequired,
  children: PropTypes.node,
};
