import { useCallback } from 'react';

import { usePopups } from '../services';
import { BRAND_TERMS_IMPORT_POPUP } from '../types';

export const useBrandTermsImportPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ brandVoice, onClose }) => {
      popups.openPopup(BRAND_TERMS_IMPORT_POPUP, {
        onClose: (imported) => {
          popups.closePopup(BRAND_TERMS_IMPORT_POPUP);

          if (onClose) {
            onClose(imported);
          }
        },
        brandVoice,
      });
    },
    [popups]
  );
};
