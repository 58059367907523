import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { CollapsingTooltip } from 'src/components/CollapsingTooltip';
import { RichSlateBrandBriefsContext } from '../../../../contexts';
import { useStyles } from './useStyles';

const useBrandBrief = (id) => {
  const brandBriefs = useContext(RichSlateBrandBriefsContext);
  return useMemo(
    () => brandBriefs?.find((brief) => Number(brief.id) === Number(id)),
    [brandBriefs, id]
  );
};

export const BankMessageNode = ({ element, children, ...attributes }) => {
  const styles = useStyles();
  const bankMessageId = element.bankMessageId;

  const brandBrief = useBrandBrief(bankMessageId);

  return (
    <CollapsingTooltip
      title={element?.bankMessageBody || brandBrief?.body}
      arrow
      {...attributes}
    >
      <Typography
        className={styles.root}
        component="span"
      >
        {children}
      </Typography>
    </CollapsingTooltip>
  );
};

BankMessageNode.propTypes = {
  attributes: PropTypes.shape({}),
  element: PropTypes.shape().isRequired,
  children: PropTypes.node,
};
