import { useCallback } from 'react';

import { INFORMATION_POPUP } from '../types';
import { usePopups } from '../services';

const DEFAULT_BUTTONS = {
  confirm: 'Ok',
};

export const useInformationPopup = () => {
  const popups = usePopups();

  return useCallback(
    (options) => {
      const { title, content, buttons, ...restOptions } = options;
      const buttonOptions = { ...DEFAULT_BUTTONS, ...buttons };

      return new Promise((resolve) => {
        popups.openPopup(INFORMATION_POPUP, {
          title,
          content,
          submitLabel: buttonOptions.submitLabel,
          additionalActionLabel: buttonOptions.additionalActionLabel,
          ...restOptions,
          onClose: (reason) => {
            popups.closePopup(INFORMATION_POPUP);
            resolve(reason);
          },
        });
      });
    },
    [popups]
  );
};
