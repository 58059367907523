import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_FEATURE_LIMIT } from './schema';

const QUERY = gql`
  query CustomerFeatureLimit($id: ID!) {
    customer(id: $id) {
      id
      featureLimits {
        ...FeatureLimit
      }
    }
  }
  ${FRAGMENT_FEATURE_LIMIT}
`;

export const queryCustomerFeatureLimit = (id, options = null) => {
  return executeGqlQuery({
    query: QUERY,
    variables: { id },
    options: {
      abortSignal: options?.abortSignal,
    },
  }).then(({ data }) => getSingleProp(data));
};
