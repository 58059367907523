import { loginToLinkedin } from 'src/utils/lib/linkedin';
import { authorizeLinkedin, queryLinkedinAuthorizationLink } from 'src/graphql/linkedin';
import { ChannelAuthError } from 'src/lib/errors';

export default async function connectToLinkedin() {
  // Query auth link from BE
  const authUrl = await queryLinkedinAuthorizationLink();

  // Ask user to allow our app to use their data
  const token = await loginToLinkedin(authUrl);
  if (!token) {
    throw new ChannelAuthError('Error getting Linkedin authorization token');
  }

  // Save received token to BE
  return authorizeLinkedin(token);
}
