import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { SLATE_EDITOR_ACTIONS } from 'src/lib/slate';
import { TextStyleDropdown } from '../TextStyleDropdown';
import { ToolbarUndoRedoActions, ToolbarShowMoreActions, ToolbarFormatActions } from './components';
import { useResponsiveFormatButtons } from './useResponsiveFormatButtons';
import { useHandleAction } from './useHandleAction';
import { useStyles } from './useStyles';

export const FixedToolbar = ({
  className,
  classes,
  groupedActions,
  onEditorAction,
  onClearFormatting,
  displayTextStyleDropdown,
}) => {
  const styles = useStyles({ classes });
  const rootRef = useRef();

  const { visibleGroups, hiddenActions, allActions } = useResponsiveFormatButtons({
    groupedActions,
    wrapperElement: rootRef.current,
    displayTextStyleDropdown,
  });

  const handleEditorAction = useHandleAction({ onEditorAction, onClearFormatting });

  return (
    <div
      className={cx(styles.root, className)}
      ref={rootRef}
    >
      {displayTextStyleDropdown && (
        <TextStyleDropdown
          className={styles.group}
          editorActions={allActions}
          onAction={handleEditorAction}
        />
      )}

      {visibleGroups.map((groupActions, index) => (
        <ToolbarFormatActions
          className={styles.group}
          key={index}
          editorActions={groupActions}
          onAction={handleEditorAction}
          classes={{ toolbarButton: styles.toolbarButton }}
        />
      ))}

      {hiddenActions.length > 0 && (
        <ToolbarShowMoreActions
          className={styles.group}
          editorActions={hiddenActions}
          onAction={handleEditorAction}
        />
      )}

      <ToolbarUndoRedoActions
        className={styles.group}
        editorActions={allActions}
        onAction={handleEditorAction}
      />
    </div>
  );
};

FixedToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  groupedActions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOf(SLATE_EDITOR_ACTIONS))),
  onEditorAction: PropTypes.func,
  onClearFormatting: PropTypes.func,
  displayTextStyleDropdown: PropTypes.bool,
};

FixedToolbar.defaultProps = {
  className: '',
  classes: {},
  groupedActions: [],
  onEditorAction: null,
  onClearFormatting: null,
  displayTextStyleDropdown: false,
};
