const NAME = 'AbortError';

export class AbortError extends Error {
  constructor(message = 'Aborted') {
    super(message);
    this.name = NAME;
    this.message = message;
  }
}

const hasNetworkAbortError = (error) => error?.networkError?.name === NAME;
const looksLikeAbortError = (error) => error?.message?.includes('aborted');

export const isAbortError = (error) =>
  error instanceof AbortError ||
  error?.name === NAME ||
  hasNetworkAbortError(error) ||
  looksLikeAbortError(error);
