import React from 'react';
import cx from 'classnames';
import { IconButton } from '@material-ui/core';

import TextLink from 'src/components/TextLink';
import { CustomTypography } from 'src/components/CustomTypography';
import { CustomButton } from 'src/components/CustomButton';
import { IconCloseThin } from 'src/assets/icons/CloseThin';
import { useHandlers } from './useHandlers';
import { useStyles } from './useStyles';
import svg from './icon.svg';

const LINK =
  'https://anyword.zoom.us/webinar/register/WN_Lsq-UmJLRK6bg2smEL0A9g?utm_medium=email&_hsenc=p2ANqtz--7Fjth0tFHEhE1J3Az9ak4Tg5qjiHjgV3njn6n_-8AtCoSqHXoqT0ayEIxgPrKDTH1d9qgaK_XJW2T7QdZZ4b9YxHp6A&_hsmi=316131018&utm_content=316131018&utm_source=topnavbar#/registration';

export const WebinarBanner = ({ className }) => {
  const styles = useStyles();

  const { handleClose, handleClick } = useHandlers();

  return (
    <a
      className={cx(styles.root, className)}
      href={LINK}
      onClick={handleClick}
      target="_blank"
      rel="noreferrer"
    >
      <CustomTypography className={styles.liveWebinar}>
        <img
          src={svg}
          alt=""
        />
        Live Webinar
      </CustomTypography>
      <TextLink
        className={styles.title}
        href={LINK}
        onClick={handleClick}
        target="_blank"
        rel="noreferrer"
      >
        Search Influence x Anyword: How to Apply AI + Analytics to Your Content |{' '}
        <span className={styles.time}>Tue, July 30, 12:30 pm EST</span>
      </TextLink>
      <CustomButton
        href={LINK}
        className={styles.button}
        onClick={handleClick}
        target="_blank"
        rel="noreferrer"
      >
        Save your spot
      </CustomButton>
      <IconButton
        onClick={handleClose}
        className={styles.closeButton}
      >
        <IconCloseThin className={styles.closeIcon} />
      </IconButton>
    </a>
  );
};
