import { flatten } from 'lodash';
import { Text as SlateText } from 'slate';
import { jsx } from 'slate-hyperscript';

import { captureMessage } from 'src/lib/sentry';
import {
  DEFAULT_SCORING_METHOD,
  createScoringMethod,
  createTaxonomyScoringMethod,
} from 'src/data/scoring';
import { generateVariationInstanceId } from 'src/data/variation';
import { SLATE_TEXT_FORMATS, DEFAULT_SLATE_VALUE } from 'src/lib/slate';
import { VOID_ELEMENTS } from '../const';
import { createBulletedListNode, createListItemNode, createParagraph } from '../templateCreators';

const EMPTY_LIST_ITEM_NODE = createListItemNode({ text: '' });

const isDDVariationElement = (element) =>
  element.dataset && (element.dataset.variationId || element.dataset.savedVariationId);

const parseScoringMethod = (dataset) => {
  if (dataset.scoringMethod) {
    return createScoringMethod({
      type: dataset.scoringMethod,
      id: dataset.scoringMethodId,
      name: decodeURI(dataset.scoringMethodName),
    });
  }

  // Previous iteration of scoring required just "contentTaxonomyId"
  if (dataset.contentTaxonomyId) {
    return createTaxonomyScoringMethod(dataset.contentTaxonomyId);
  }

  return DEFAULT_SCORING_METHOD;
};

const parseSecondaryScoringMethodName = (dataset) => {
  if (dataset.secondaryScoringMethodName) {
    return decodeURI(dataset.secondaryScoringMethodName);
  }
};

const parseCampaignKeywords = (dataset) => {
  const { campaignKeywords } = dataset;
  if (campaignKeywords) {
    const decoded = decodeURI(campaignKeywords);
    try {
      return JSON.parse(decoded);
    } catch (error) {
      captureMessage('Error parsing campaign keywords', {
        level: 'warning',
        extra: {
          error: error.toString(),
          source: 'RichSlate/deserialize.js -> parseCampaignKeywords',
          value: decoded,
        },
      });
    }
  }
};

const parseBenchmarkConfig = (dataset) => {
  if (dataset.benchmarkConfigUserGoal) {
    return {
      comparisonSet: {
        userGoal: dataset.benchmarkConfigUserGoal,
        name: decodeURIComponent(dataset.benchmarkConfigComparisonSetName),
      },
      account: {
        id: dataset.benchmarkConfigAccountId,
        name: decodeURIComponent(dataset.benchmarkConfigAccountName),
        channel: decodeURIComponent(dataset.benchmarkConfigAccountChannel),
      },
    };
  }

  // Previous iteration of scoring required just "contentTaxonomyId"
  if (dataset.contentTaxonomyId) {
    return createTaxonomyScoringMethod(dataset.contentTaxonomyId);
  }

  return DEFAULT_SCORING_METHOD;
};

const parseDDVariation = (element, children) => {
  const { dataset } = element;
  const isSaved = Boolean(dataset.savedVariationId);
  const variationId = isSaved ? dataset.savedVariationId : dataset.variationId;

  return jsx(
    'element',
    {
      type: SLATE_TEXT_FORMATS.DD_VARIATION,
      variation: {
        id: variationId,
        instanceId: generateVariationInstanceId(variationId),
        score: dataset.variationScore,
        assetType: dataset.assetType,
        generationTool: dataset.generationTool,
        language: dataset.language,
        isSaved,
        isScoreLocked: dataset.scoreLocked != null,
        scoringMethod: parseScoringMethod(dataset),
        secondaryScoringMethod: dataset.secondaryScoringMethodName
          ? { name: parseSecondaryScoringMethodName(dataset) }
          : null,
        campaignKeywords: parseCampaignKeywords(dataset),
        benchmarkConfig: parseBenchmarkConfig(dataset),
      },
    },
    children
  );
};

// Parse text attributes for text nodes
const parseTextAttribute = (el) => {
  switch (el.nodeName.toLowerCase()) {
    case 'strong':
    case 'b':
      return { bold: true };

    case 'em':
    case 'i':
      return { italic: true };

    case 'u':
      return { underline: true };

    case 's':
      return { strikethrough: true };

    default:
      return null;
  }
};

const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent);
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = {
    ...markAttributes,
    ...parseTextAttribute(el),
  };

  const children = flatten(
    Array.from(el.childNodes).map((node) => deserialize(node, nodeAttributes))
  );

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  const commonAttributes = {};
  if (el.style.textAlign) {
    commonAttributes.align = el.style.textAlign;
  }

  switch (el.nodeName.toLowerCase()) {
    case 'body':
      return jsx('fragment', {}, children);

    case 'h1':
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.HEADING_ONE,
        },
        children
      );

    case 'h2':
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.HEADING_TWO,
        },
        children
      );

    case 'h3':
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.HEADING_THREE,
        },
        children
      );

    case 'h4':
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.HEADING_FOUR,
        },
        children
      );

    case 'h5':
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.HEADING_FIVE,
        },
        children
      );

    case 'h6':
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.HEADING_SIX,
        },
        children
      );

    case 'ol': {
      const items = skipTextNodes(children) || [EMPTY_LIST_ITEM_NODE];
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.NUMBERED_LIST,
        },
        items
      );
    }

    case 'ul': {
      const items = skipTextNodes(children) || [EMPTY_LIST_ITEM_NODE];
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.BULLETED_LIST,
        },
        items
      );
    }

    case 'li': {
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.LIST_ITEM,
        },
        children
      );
    }

    case 'br':
      return '\n';

    case 'blockquote':
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.BLOCK_QUOTE,
        },
        children
      );

    case 'div':
      if (el.dataset && el.dataset.divider) {
        return jsx(
          'element',
          {
            ...commonAttributes,
            type: VOID_ELEMENTS.DIVIDER,
          },
          [{ text: '' }]
        );
      }
      if (isDDVariationElement(el)) {
        return parseDDVariation(el, children);
      }
      return children;

    case 'p':
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.PARAGRAPH,
        },
        children
      );

    case 'a':
      return jsx(
        'element',
        {
          ...commonAttributes,
          type: SLATE_TEXT_FORMATS.LINK,
          url: el.getAttribute('href'),
        },
        children
      );

    case 'span':
      if (el.hasAttribute('data-bank-message')) {
        return jsx(
          'element',
          {
            ...commonAttributes,
            attributes: el.attributes,
            bankMessageId: el.getAttribute('data-bank-message-id'),
            bankMessageBody: el.getAttribute('data-bank-message-body'),
            tagName: el.getAttribute('data-bank-message-tag-name') || '',
            type: SLATE_TEXT_FORMATS.BANK_MESSAGE,
          },
          children
        );
      } else if (el.hasAttribute('data-prompt-image')) {
        return jsx(
          'element',
          {
            ...commonAttributes,
            attributes: el.attributes,
            id: el.getAttribute('data-prompt-image-id'),
            type: SLATE_TEXT_FORMATS.PROMPT_IMAGE,
          },
          children
        );
      } else if (el.hasAttribute('data-prompt-document')) {
        return jsx(
          'element',
          {
            ...commonAttributes,
            id: el.getAttribute('data-prompt-document-id'),
            attributes: el.attributes,
            type: SLATE_TEXT_FORMATS.PROMPT_DOCUMENT,
          },
          children
        );
      } else if (el.hasAttribute('data-prompt-tag')) {
        return jsx(
          'element',
          {
            ...commonAttributes,
            attributes: el.attributes,
            type: SLATE_TEXT_FORMATS.PROMPT_TAG,
          },
          children
        );
      } else if (el.hasAttribute('data-product-description')) {
        return jsx(
          'element',
          {
            ...commonAttributes,
            attributes: el.attributes,
            title: el.getAttribute('data-product-description-title'),
            article: el.getAttribute('data-product-description-article'),
            type: SLATE_TEXT_FORMATS.PRODUCT_DESCRIPTION,
          },
          children
        );
      } else if (el.hasAttribute('data-resource-url')) {
        return jsx(
          'element',
          {
            ...commonAttributes,
            attributes: el.attributes,
            resourceUrl: el.getAttribute('data-resource-url-text'),
            tagName: el.getAttribute('data-resource-url-tag-name') || '',
            type: SLATE_TEXT_FORMATS.RESOURCE_URL,
          },
          children
        );
      } else return children;

    default:
      return children;
  }
};

const skipTextNodes = (nodes) => nodes.filter((node) => !SlateText.isText(node));

export const htmlToSlate = (html = '<p></p>') => {
  const { body } = new DOMParser().parseFromString(html, 'text/html');
  const slateValue = deserialize(body);
  if (slateValue && slateValue.length > 0) {
    return slateValue;
  }
  return DEFAULT_SLATE_VALUE;
};

export const textToSlate = (text) => {
  if (!text) {
    return DEFAULT_SLATE_VALUE;
  }

  const lines = text.split('\n');
  const parsedLines = lines.map(parseTextLine);
  return wrapListItems(parsedLines);
};

const wrapListItems = (parsedLines) => {
  const result = [];
  let currentList = null;
  parsedLines.forEach((line) => {
    if (line.type === SLATE_TEXT_FORMATS.LIST_ITEM) {
      if (!currentList) {
        currentList = createBulletedListNode({ children: [] });
        result.push(currentList);
      }
      currentList.children.push(line);
    } else {
      currentList = null;
      result.push(line);
    }
  });
  return result;
};

const parseTextLine = (line) => {
  if (isListItemText(line)) {
    const text = parseListItemText(line);
    return createListItemNode({ text });
  }

  return createParagraph({ text: line });
};

const isListItemText = (text) => {
  return /^\s*([0-9]+\.|-)\s*(.*)/.test(text);
};

const parseListItemText = (line) => {
  const match = line.match(/^\s*([0-9]+\.|-)\s*(.*)/);
  return match ? match[2] : line;
};
