import { useMemo } from 'react';

import { createStateSelectorHook } from 'src/lib/sweetState';
import { BrandVoicesStore } from './BrandVoicesStore';

const useBrandVoicesState = createStateSelectorHook(BrandVoicesStore, [
  'brandVoicesAssets',
  'loadingBrandVoiceAssets',
  'defaultBrandVoice',
]);

export const useDefaultBrandVoiceAssets = () => {
  const { brandVoicesAssets, loadingBrandVoiceAssets, defaultBrandVoice } = useBrandVoicesState();

  return useMemo(() => {
    if (loadingBrandVoiceAssets) {
      return {
        loadingBrandVoiceAssets,
      };
    }
    const alignedDefaultBrandVoice =
      (brandVoicesAssets || []).find((brandVoice) => brandVoice.id === defaultBrandVoice?.id) ||
      brandVoicesAssets[0];

    const { customModels, toneOfVoices, customFormulas, targetAudiences, brandBriefs } =
      alignedDefaultBrandVoice || {};

    return {
      customModels,
      toneOfVoices,
      customFormulas,
      targetAudiences,
      brandBriefs,
      loadingBrandVoiceAssets,
      ...alignedDefaultBrandVoice,
    };
  }, [defaultBrandVoice, brandVoicesAssets, loadingBrandVoiceAssets]);
};
