import { CHANNEL, alignChannelFromBackend } from 'src/data/channel';
import { INTEGRATION_CHANNELS, PLATFORM_CHANNELS } from './const';
import { isDisconnectedStatus } from './extraction';
import { isEmailCsvResourceDocument } from './resources';

export const isIntegrationChannel = (channel) => INTEGRATION_CHANNELS.includes(channel);

export const isPlatformChannel = (channel) => PLATFORM_CHANNELS.includes(channel);

export const isMetaChannel = (channel) =>
  [CHANNEL.facebook, CHANNEL.facebookPages, CHANNEL.instagram].includes(channel);

export const flatConnectedAccounts = (connectedAccounts, withResources) =>
  withResources
    ? Object.values(connectedAccounts || {}).reduce((acc, accounts) => {
        return acc.concat(accounts);
      }, [])
    : Object.keys(connectedAccounts || {}).reduce((acc, channel) => {
        if (isIntegrationChannel(channel)) {
          return acc.concat(connectedAccounts[channel]);
        }
        return acc;
      }, []);

export const getAssociatedAccountIds = (workspace) => {
  const { associatedIntegrationIds } = workspace.associatedAccounts.reduce(
    (acc, account) => {
      if (
        (isIntegrationChannel(alignChannelFromBackend(account.accountType)) ||
          isEmailCsvResourceDocument(account.accountType)) &&
        !isDisconnectedStatus(account.status)
      ) {
        acc.associatedIntegrationIds.push(account.accountId);
      }
      return acc;
    },
    { associatedIntegrationIds: [] }
  );

  return { associatedIntegrationIds };
};

export const isBlogUrlsChannel = (channel) => channel === CHANNEL.blogUrls;

export const convertAccountsListToChannelMap = (accounts = []) => {
  const result = {};

  accounts.forEach((account) => {
    const channel = alignChannelFromBackend(account.accountType);

    const channelAccounts = result[channel] || [];

    const preparedAccount = {
      name: account.accountName,
      id: account.accountId,
      channel,
      ...account,
    };

    result[channel] = [...channelAccounts, preparedAccount];
  });

  return result;
};

export const shouldChanelConnectManually = (channel) =>
  [CHANNEL.marketo, CHANNEL.braze].includes(channel);
