import React from 'react';

import { useOnTabFocus } from 'src/lib/hooks';
import { captureMessage } from 'src/lib/sentry';
import { useCustomerActions } from 'src/store';
import * as ls from 'src/lib/localData';

const CustomerSelectionSaver = () => {
  const customerActions = useCustomerActions();

  const handleTabFocus = React.useCallback(() => {
    const currentCustomer = customerActions.getCurrentCustomer();
    if (!currentCustomer) {
      return;
    }

    // Save selected customer ID to browser storage
    try {
      ls.currentCustomerId.write(currentCustomer.id);
    } catch (error) {
      captureMessage("Couldn't persist customer selection", {
        level: 'warning',
        extra: {
          error: error.toString(),
          source: 'CustomerSelectionSaver',
        },
      });
    }
  }, [customerActions]);

  useOnTabFocus(handleTabFocus);

  return null;
};

export default CustomerSelectionSaver;
