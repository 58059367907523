import { useCallback } from 'react';

import {
  GENERATION_TOOL_POPUP,
  useBrandVoicePopup,
  useDeleteBrandVoice,
  useGenerationToolPopup,
} from 'src/services/popup';
import { SIDEBAR_TAB_TYPE } from 'src/views/GenerationToolPopup/const';
import { SIDEBAR_ACTION } from '../../lib';
import { useSidebarEvents } from '../../service';
import { useGenerationToolPopupSubmitHandler } from 'src/services/useGenerationToolPopupSubmitHandler';
import { useMainActions } from '../../store';

export const useHandlers = () => {
  const { trackNavigationItemClicked } = useSidebarEvents();
  const mainActions = useMainActions();
  const openGenerationToolPopup = useGenerationToolPopup();
  const openBrandVoicePopup = useBrandVoicePopup();
  const deleteBrandVoice = useDeleteBrandVoice();

  const handleGenerationToolPopupSubmit = useGenerationToolPopupSubmitHandler();

  const handleToggleMenu = useCallback(
    (menuItem) => {
      mainActions.toggleMenuItem(menuItem?.id);
    },
    [mainActions]
  );

  const handleToggleCollapse = useCallback(
    (collapseItem) => {
      mainActions.toggleCollapseItem(collapseItem?.id);
    },
    [mainActions]
  );

  const handleLinkClick = useCallback(
    (linkItem) => {
      trackNavigationItemClicked({ label: linkItem.label });

      if (!linkItem.isChildItem && !linkItem.disableNavigation) {
        mainActions.collapseAll();
      }
      if (linkItem.popupKey === GENERATION_TOOL_POPUP) {
        openGenerationToolPopup({
          selectedTabType: SIDEBAR_TAB_TYPE.mostPopular,
          onSubmit: handleGenerationToolPopupSubmit,
          withBlog: true,
        });
      }
    },
    [
      mainActions,
      trackNavigationItemClicked,
      openGenerationToolPopup,
      handleGenerationToolPopupSubmit,
    ]
  );

  const handleSidebarAction = useCallback(
    (type, data) => {
      switch (type) {
        case SIDEBAR_ACTION.createBrandVoice: {
          openBrandVoicePopup();
          break;
        }

        case SIDEBAR_ACTION.editBrandVoice: {
          openBrandVoicePopup(data);
          break;
        }

        case SIDEBAR_ACTION.deleteBrandVoice: {
          deleteBrandVoice(data.id, data.name);
          break;
        }

        default: {
          // do nothing
        }
      }
    },
    [openBrandVoicePopup, deleteBrandVoice]
  );

  return {
    handleToggleMenu,
    handleToggleCollapse,
    handleLinkClick,
    handleSidebarAction,
  };
};
