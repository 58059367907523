import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      wordBreak: 'break-word',

      padding: theme.spacing(0.75, 2),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.neutral.mediumGray,

      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:active, &[aria-selected="true"]': {
        backgroundColor: theme.palette.action.selected,
      },

      '& [data-highlight="true"]': {
        color: COLORS.neutral.secondary,
        fontWeight: 600,
      },
    },
  }),
  { name: 'ListOption' }
);
