import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useCurrentUser, useCurrentWorkspace, useCurrentWorkspaces } from 'src/store';
import { usePopoverAnchor } from 'src/lib/hooks';
import { WorkspaceButton } from '../../components/WorkspaceButton';
import { WorkspaceMenu } from '../../components/WorkspaceMenu';
import { useHandlers } from './useHandlers';
import { useViewState } from './useViewState';
import { useStyles } from './useStyles';

export const WorkspaceItem = ({ className, classes }) => {
  const styles = useStyles({ classes });

  const currentUser = useCurrentUser();
  const currentWorkspace = useCurrentWorkspace();
  const currentWorkspaces = useCurrentWorkspaces();
  const [menuAnchor, openMenu, closeMenu] = usePopoverAnchor();

  const { loadingWorkspaces, loadingCurrentWorkspace } = useViewState();
  const {
    handleMenuClose,
    handleWorkspaceClick,
    handleCreateOrUpdateWorkspace,
    handleDeleteWorkspace,
  } = useHandlers({ closeMenu });

  const loading = !currentUser || !currentWorkspace || loadingWorkspaces || loadingCurrentWorkspace;

  return (
    <>
      <WorkspaceButton
        className={cx(styles.button, className)}
        currentWorkspace={currentWorkspace}
        open={Boolean(menuAnchor)}
        onClick={(e) => (currentWorkspace ? openMenu(e.currentTarget) : null)}
        loading={loading}
      />

      <WorkspaceMenu
        classes={{ paper: styles.menu_paper }}
        open={Boolean(menuAnchor)}
        anchor={menuAnchor}
        onClose={handleMenuClose}
        workspaces={currentWorkspaces}
        selectedWorkspaceId={currentWorkspace && currentWorkspace.id}
        onWorkspaceClick={handleWorkspaceClick}
        onCreateOrUpdateClick={handleCreateOrUpdateWorkspace}
        onDeleteClick={handleDeleteWorkspace}
      />
    </>
  );
};

WorkspaceItem.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
};
WorkspaceItem.defaultProps = {
  className: '',
  classes: null,
};
