import React from 'react';

import { NavigationSidebarPanel } from './NavigationSidebarPanel';
import { MainStoreContainer } from './store';

const Container = ({ className }) => {
  return <NavigationSidebarPanel className={className} />;
};

const StoreWrapper = ({ className }) => {
  return (
    <MainStoreContainer>
      <Container className={className} />
    </MainStoreContainer>
  );
};

export default StoreWrapper;
