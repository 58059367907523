import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';

export const SidebarHeader = ({ className, item }) => {
  const styles = useStyles();
  return (
    <CustomTypography
      className={cx(styles.root, className)}
      variant={TYPOGRAPHY_VARIANT.semiBold12}
    >
      {item.label}
    </CustomTypography>
  );
};

SidebarHeader.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({}).isRequired,
};

SidebarHeader.defaultProps = {
  className: '',
};
