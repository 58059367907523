import gql from 'graphql-tag';

export const FRAGMENT_PROJECT_FOLDER = gql`
  fragment ProjectFolder on ProjectFolder {
    id
    name
    user {
      name
      email
    }
    projectCount
    projectUpdatedAt
    onSidebar
  }
`;

export const FRAGMENT_PROJECT_FOLDER_FOR_SEARCH = gql`
  fragment ProjectFolderForSearch on ProjectFolder {
    id
    name
    user {
      name
      email
    }
    __typename
  }
`;
