import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_RESOURCE } from './schema';

const MUTATION = gql`
  mutation CreateResourceAccount(
    $customerId: ID!
    $name: String!
    $dataSource: String!
    $file: Upload
  ) {
    createResourceAccount(
      customerId: $customerId
      name: $name
      file: $file
      dataSource: $dataSource
    ) {
      ...PerformanceCenterResource
    }
  }
  ${FRAGMENT_RESOURCE}
`;

export const createResourceAccount = ({ customerId, name, file, dataSource }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, name, file, dataSource },
  }).then(({ data }) => getSingleProp(data));
};
