import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent } from '@material-ui/core';

import { useCurrentCustomer } from 'src/store';
import { formatDateMedNonLocale } from 'src/lib/format';
import { CustomDialog } from 'src/components/CustomDialog';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import NotificationSuccess from 'src/assets/icons/NotificationSuccess';
import { useStyles } from './useStyles';

export const AfterCancelSubscriptionPopup = ({ open, onClose }) => {
  const styles = useStyles();

  const currentCustomer = useCurrentCustomer();

  return (
    <CustomDialog
      className={styles.root}
      open={open}
      onClose={onClose}
      title="Cancellation approved"
      maxWidth="lg"
      submitLabel="Close"
      onSubmit={onClose}
    >
      <DialogContent className={styles.dialogContent}>
        <CustomTypography className={styles.success}>
          <NotificationSuccess className={styles.successIcon} />
          Your subscription cancellation request has been approved.
        </CustomTypography>

        <CustomTypography
          className={styles.description}
          variant={TYPOGRAPHY_VARIANT.paragraph16}
        >
          You will still have access to the platform until the end of your billing cycle on{' '}
          {formatDateMedNonLocale(currentCustomer?.billingConfiguration?.billingPeriod?.end)}.
          <br />
          <br />
          After that date, your subscription will be canceled and you will no longer be able to
          access the platform.
        </CustomTypography>
      </DialogContent>
    </CustomDialog>
  );
};

AfterCancelSubscriptionPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
AfterCancelSubscriptionPopup.defaultProps = {};
