import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import pubsub, { PUBSUB } from 'src/lib/pubsub';
import diagramsImage from 'src/assets/img/diagrams-image.svg';
import useStyles from './useStyles';
import { useEvents } from './useEvents';

export const ScoreOnboardingHint = forwardRef(
  ({ className, onNextClick, dontShowAgain, onDontShowAgainChange }, ref) => {
    const styles = useStyles();
    const events = useEvents();

    const handleClose = () => {
      events.trackCloseClick();
      events.trackDontShowAgainChecked(dontShowAgain);
      pubsub.publish(PUBSUB.OPEN_DETAILS_PANEL);
      onNextClick();
    };

    return (
      <div
        className={cx(styles.root, className)}
        ref={ref}
      >
        <img
          src={diagramsImage}
          alt=""
        />

        <Typography className={styles.title}>Score details</Typography>
        <Typography className={styles.subTitle}>
          Click on a score to see demographic breakdowns, policy compliance, text analysis, and
          more.
        </Typography>

        <div className={styles.actionButtons}>
          <FormControlLabel
            className={styles.showToggle}
            control={<Checkbox color="primary" />}
            label="Don't show again"
            checked={dontShowAgain}
            onChange={onDontShowAgainChange}
          />

          <Button
            className={styles.closeButton}
            color="primary"
            variant="contained"
            data-testid="scoreHintCloseButton"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    );
  }
);

ScoreOnboardingHint.propTypes = {
  className: PropTypes.string,
  onNextClick: PropTypes.func.isRequired,
  dontShowAgain: PropTypes.bool,
  onDontShowAgainChange: PropTypes.func.isRequired,
};

ScoreOnboardingHint.defaultProps = {
  className: null,
  dontShowAgain: false,
};
