import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&$interactive:hover': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    interactive: {},

    textWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    text: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      color: COLORS.neutral.mediumGray,
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      color: COLORS.primary.default,
      textDecoration: 'none',
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    hint: {
      fontSize: theme.typography.pxToRem(10),
      marginLeft: theme.spacing(0.5),
    },
    tooltip: {
      maxWidth: 150,
    },
  }),
  { name: 'AnnotationListItem' }
);
