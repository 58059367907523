const GOOGLE_VIOLATION_REASONS = {
  exclamation_mark_headline: 'Exclamation marks in ad headline',
  multiple_exclamation_question_mark_description:
    'Use of an exclamation mark (“!”) or question mark (“?”) two or more times in ad description',
  capitalization: 'Excessive or gimmicky use of capitalization',
  phone_number: 'Phone number in ad text',
  unsupported_characters: 'Symbols, emojis or unsupported characters',
  generic_promotion: 'Overly generic or vague promotion',
  clickbait: 'Clickbait tactics or sensationalist text',
};

const GOOGLE_TITLES_ENUM = {
  google_policy_violation: 'Possible Google ad policy violation:\n',
};

const GOOGLE_REASON_DETAILS_ENUM = {
  google_editorial_policy:
    'The content of this variation may not meet Google’s professional and editorial standards. Your ad may be disapproved.',
  google_clickbait_policy:
    'The content of this variation may violate Google’s misrepresentation policy that forbids clickbait messaging such as ‘‘Click here to find out” or “You won’t believe what happened”. Your ad may be disapproved.',
};

const GOOGLE_REASON_DESCRIPTION_ENUM = {
  generic_promotion: 'Overly generic or vague promotion.',
  exclamation_mark_headline: 'Exclamation marks in ad headline.',
  multiple_exclamation_question_mark_description:
    'Use of an exclamation mark (“!”) or question mark (“?”) two or more times in ad description.',
  capitalization: 'Excessive or gimmicky use of capitalization',
  phone_number: 'Phone number in ad text',
  unsupported_characters:
    'Punctuation or symbols that are not used correctly or for their intended purpose; Emojis or unsupported characters.',
  clickbait: 'Clickbait tactics or sensationalist text',
};

const GOOGLE_LINKS_ENUM = {
  google_editorial_policy: 'https://support.google.com/adspolicy/answer/6021546',
  google_clickbait_policy: 'https://support.google.com/adspolicy/answer/6020955',
};

const getGooglePolicyLinkForDisplay = (reason) => {
  return !(reason && reason === GOOGLE_VIOLATION_REASONS.clickbait)
    ? GOOGLE_LINKS_ENUM.google_editorial_policy
    : GOOGLE_LINKS_ENUM.google_clickbait_policy;
};

const getReasonDetails = (reason) => {
  let reasonDetails = null;
  switch (reason) {
    case GOOGLE_VIOLATION_REASONS.clickbait:
      reasonDetails = GOOGLE_REASON_DETAILS_ENUM.google_clickbait_policy;
      break;
    default:
      reasonDetails = GOOGLE_REASON_DETAILS_ENUM.google_editorial_policy;
      break;
  }
  return reasonDetails;
};

const getReasonDescription = (reason) => {
  let reasonDescription = null;
  switch (reason) {
    case GOOGLE_VIOLATION_REASONS.exclamation_mark_headline:
      reasonDescription = GOOGLE_REASON_DESCRIPTION_ENUM.exclamation_mark_headline;
      break;
    case GOOGLE_VIOLATION_REASONS.generic_promotion:
      reasonDescription = GOOGLE_REASON_DESCRIPTION_ENUM.generic_promotion;
      break;
    case GOOGLE_VIOLATION_REASONS.multiple_exclamation_question_mark_description:
      reasonDescription =
        GOOGLE_REASON_DESCRIPTION_ENUM.multiple_exclamation_question_mark_description;
      break;
    case GOOGLE_VIOLATION_REASONS.capitalization:
      reasonDescription = GOOGLE_REASON_DESCRIPTION_ENUM.capitalization;
      break;
    case GOOGLE_VIOLATION_REASONS.phone_number:
      reasonDescription = GOOGLE_REASON_DESCRIPTION_ENUM.phone_number;
      break;
    case GOOGLE_VIOLATION_REASONS.unsupported_characters:
      reasonDescription = GOOGLE_REASON_DESCRIPTION_ENUM.unsupported_characters;
      break;
    case GOOGLE_VIOLATION_REASONS.clickbait:
      reasonDescription = GOOGLE_REASON_DESCRIPTION_ENUM.clickbait;
      break;
    default:
      break;
  }
  // in case of capitalization violation - the reason might include more info, but the prefix should stay the same
  if (!reasonDescription && reason && reason.startsWith(GOOGLE_VIOLATION_REASONS.capitalization)) {
    reasonDescription = GOOGLE_REASON_DESCRIPTION_ENUM.capitalization;
  }
  return reasonDescription;
};

export const getGoogleContentValidationDetailsPerReason = (sensitivity) => {
  const reason = sensitivity.reason || '';
  const reasonTitle = GOOGLE_TITLES_ENUM.google_policy_violation;
  // const isGoogleViolation = labelDesc !== CONTENT_SENSITIVITY.safe && !isGPT3Violation(normalReason);

  const reasonDetails = getReasonDetails(reason);
  const reasonDescription = getReasonDescription(reason);
  const learnMoreLink = getGooglePolicyLinkForDisplay(reason);

  return {
    reasonTitle,
    reasonDescription,
    reasonDetails,
    learnMoreLink,
  };
};
