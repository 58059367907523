import { useCallback, useMemo } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { useLocation } from 'react-router-dom-v5-compat';

import { ROUTES } from 'src/routes';
import { useHistory } from './useHistory';

export const useGoBack = () => {
  const history = useHistory();
  const location = useLocation();
  const lastLocation = useLastLocation();

  const cameFromSharedPage = useMemo(() => {
    return lastLocation?.pathname.startsWith(ROUTES.SHARED);
  }, [lastLocation]);

  const isLastLocationSameAsCurrentLocation = useMemo(() => {
    return location.pathname === lastLocation?.pathname;
  }, [location, lastLocation]);

  return useCallback(() => {
    if (
      lastLocation &&
      lastLocation.pathname !== ROUTES.VERIFY_EMAIL_CALLBACK &&
      !cameFromSharedPage &&
      !isLastLocationSameAsCurrentLocation
    ) {
      history.goBack();
    } else {
      history.push(ROUTES.DASHBOARD);
    }
  }, [history, lastLocation, cameFromSharedPage, isLastLocationSameAsCurrentLocation]);
};
