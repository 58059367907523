import { UniqMap } from 'src/lib/UniqMap';
import { GENERATION_TOOL, GENERATION_TOOL_GROUP } from './const';

export const TOOL_GROUPS = new UniqMap();

TOOL_GROUPS.add(GENERATION_TOOL_GROUP.ads, [
  GENERATION_TOOL.facebookMixtureAd,
  GENERATION_TOOL.facebookHeadlineProductRewrite,
  GENERATION_TOOL.facebookPrimaryTextProductRewrite,
  GENERATION_TOOL.googleRSA,
  GENERATION_TOOL.googleHeadlineProduct,
  GENERATION_TOOL.googleDescriptionProduct,
  GENERATION_TOOL.googleHeadlineProductRewrite,
  GENERATION_TOOL.googleDescriptionProductRewrite,
  GENERATION_TOOL.linkedinMixtureAd,
  GENERATION_TOOL.linkedinIntroSingleImageRewrite,
  GENERATION_TOOL.linkedinHeadlineSingleImageRewrite,
  GENERATION_TOOL.twitterMixtureAd,
  GENERATION_TOOL.twitterCopyProductRewrite,
  GENERATION_TOOL.outbrainHeadlineProduct,
  GENERATION_TOOL.outbrainHeadlineProductRewrite,
  GENERATION_TOOL.taboolaHeadlineProduct,
  GENERATION_TOOL.taboolaHeadlineProductRewrite,
  GENERATION_TOOL.pinterestMixtureAd,
  GENERATION_TOOL.pinterestDescriptionProductRewrite,
]);
TOOL_GROUPS.add(GENERATION_TOOL_GROUP.landingPage, [
  GENERATION_TOOL.landingPageProductAnnouncementMultiPrompt,
  GENERATION_TOOL.landingHeadline,
  GENERATION_TOOL.landingHeadlineRewrite,
  GENERATION_TOOL.landingSubheader,
  GENERATION_TOOL.landingSubheaderRewrite,
  GENERATION_TOOL.landingCTA,
  GENERATION_TOOL.landingBenefits,
  GENERATION_TOOL.landingFeatures,
  GENERATION_TOOL.landingSEOMetadata,
  GENERATION_TOOL.landingSEOMetadataRewrite,
]);
TOOL_GROUPS.add(GENERATION_TOOL_GROUP.campaigns, [
  GENERATION_TOOL.productLaunchMultiPrompt,
  GENERATION_TOOL.emailDripMultiPrompt,
]);
TOOL_GROUPS.add(GENERATION_TOOL_GROUP.social, [
  GENERATION_TOOL.instagramCaption,
  GENERATION_TOOL.instagramCaptionRewrite,
  GENERATION_TOOL.facebookPost,
  GENERATION_TOOL.facebookPostRewrite,
  GENERATION_TOOL.tweet,
  GENERATION_TOOL.tweetRewrite,
  GENERATION_TOOL.linkedinPost,
  GENERATION_TOOL.linkedinPostRewrite,
  GENERATION_TOOL.pinterestPostDescription,
  GENERATION_TOOL.pinterestPinDescriptionRewrite,
  GENERATION_TOOL.pinterestPostTitle,
  GENERATION_TOOL.pinterestPinTitleRewrite,
  GENERATION_TOOL.youtubeDescription,
  GENERATION_TOOL.youtubeTitle,
]);
TOOL_GROUPS.add(GENERATION_TOOL_GROUP.email, [
  GENERATION_TOOL.emailDripMultiPrompt,
  GENERATION_TOOL.emailMixture,
  GENERATION_TOOL.emailSubject,
  GENERATION_TOOL.emailSubjectRewrite,
  GENERATION_TOOL.emailPromotionalBodyRewrite,
  GENERATION_TOOL.emailColdOutreachBodyRewrite,
]);

TOOL_GROUPS.add(GENERATION_TOOL_GROUP.generalWriting, [
  GENERATION_TOOL.basicAida,
  GENERATION_TOOL.basicPas,
  GENERATION_TOOL.basicEli5,
  GENERATION_TOOL.basicSentenceExpander,
  GENERATION_TOOL.basicTalkingPointsToParagraph,
  GENERATION_TOOL.summarizer,
]);

TOOL_GROUPS.add(GENERATION_TOOL_GROUP.ecommerce, [
  GENERATION_TOOL.amazonProductListing,
  GENERATION_TOOL.walmartProductListing,
  GENERATION_TOOL.generalProductListing,
  GENERATION_TOOL.basicProductReview,
  GENERATION_TOOL.basicPerfectHeadline,
  GENERATION_TOOL.smsPromotional,
  GENERATION_TOOL.smsPromotionalRewrite,
]);

TOOL_GROUPS.add(GENERATION_TOOL_GROUP.articleBlog, [
  GENERATION_TOOL.articleBlogHeadline,
  GENERATION_TOOL.basicBlogIdeas,
  GENERATION_TOOL.blogDescription,
]);

TOOL_GROUPS.add(GENERATION_TOOL_GROUP.anyCopy, [GENERATION_TOOL.freestyle]);

TOOL_GROUPS.add(GENERATION_TOOL_GROUP.boost, [GENERATION_TOOL.basicContentImprover]);
TOOL_GROUPS.add(GENERATION_TOOL_GROUP.customMultiPrompt, [GENERATION_TOOL.customMultiPrompt]);

export const getToolsByGroup = (toolGroup) => TOOL_GROUPS.get(toolGroup) || [];

export const getGroupByTool = (tool) =>
  [...TOOL_GROUPS.map.keys()].find((group) => getToolsByGroup(group).includes(tool));

export const getTeadsTools = () => [
  GENERATION_TOOL.teadsSingleVideoHeadline,
  GENERATION_TOOL.teadsSingleVideoDescription,
];

export const getRewriteHeadlineTools = () => [
  GENERATION_TOOL.pinterestPinTitleRewrite,
  GENERATION_TOOL.linkedinHeadlineSingleImageRewrite,
  GENERATION_TOOL.emailSubjectRewrite,
  GENERATION_TOOL.landingSubheaderRewrite,
  GENERATION_TOOL.landingHeadlineRewrite,
  GENERATION_TOOL.taboolaHeadlineProductRewrite,
  GENERATION_TOOL.outbrainHeadlineProductRewrite,
];

export const isGenerationToolInSocialGroup = (generationTool) => {
  const toolGroup = getGroupByTool(generationTool);
  return toolGroup === GENERATION_TOOL_GROUP.social;
};

export const isGenerationToolInProductListingGroup = (generationTool) => {
  const toolGroup = getGroupByTool(generationTool);
  return toolGroup === GENERATION_TOOL_GROUP.ecommerce;
};

export const isGenerationToolInAdsGroup = (generationTool) => {
  const toolGroup = getGroupByTool(generationTool);
  return toolGroup === GENERATION_TOOL_GROUP.ads;
};

export const getActiveTemplatesArray = () => {
  const arr = TOOL_GROUPS.getAll().reduce((acc, item) => {
    return [...acc, ...item[1]];
  }, []);
  return arr;
};

export const isUsedTemplate = (template) => {
  const usedtemplatesArray = getActiveTemplatesArray();
  return usedtemplatesArray.includes(template, 0);
};
