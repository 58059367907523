import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Divider } from '@material-ui/core';

export const ItemDivider = forwardRef(({ className }, ref) => (
  <Divider
    className={cx(className, 'bg-primary-light')}
    ref={ref}
  />
));

ItemDivider.propTypes = {
  className: PropTypes.string,
};

ItemDivider.defaultProps = {
  className: '',
};
