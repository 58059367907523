import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'inline',
      position: 'relative',
    },
    popupContainer: {
      position: 'absolute',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1.2),
      padding: theme.spacing(1),
      left: 0,
      top: 'calc(100% + 4px)', // position right under the link text with 4px offset
      width: 283,
      height: 40,
      background: 'white',
      boxShadow: '0px 1px 27px rgba(31, 44, 75, 0.1)',
      border: `1px solid ${COLORS.primary.light}`,
      borderRadius: 6,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(19),
      color: COLORS.neutral.secondary,
      zIndex: theme.zIndex.modal,
      userSelect: 'none',
    },
    link: {
      color: COLORS.primary.default,
      textDecoration: 'underline',
    },
    ellipsized: {
      whiteSpace: 'nowrap',
      maxWidth: 150,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    unlinkIcon: {
      fontSize: theme.typography.pxToRem(14),
      marginLeft: 5,
      cursor: 'pointer',
    },
    editIcon: {
      fontSize: theme.typography.pxToRem(14),
      marginLeft: 'auto',
      cursor: 'pointer',
      color: COLORS.neutral.mediumGray,
    },
  }),
  { name: 'Link' }
);
