import gql from 'graphql-tag';

export const FRAGMENT_CUSTOM_MODEL_BLOG_URL = gql`
  fragment CustomModelBlogUrl on CustomModelBlogUrl {
    id
    url
    validationError
    status
  }
`;

export const CUSTOM_DATASET_FRAGMENT = gql`
  fragment CustomDataset on CustomDataset {
    id
    status
    accountId
    accountName
    dataPointsSize
    totalCopies
    usedCopies
    words
    dataSource
    createdAt
    processingError
    brandVoice {
      id
      name
    }
    blogUrls {
      ...CustomModelBlogUrl
    }
    isDefault
  }
  ${FRAGMENT_CUSTOM_MODEL_BLOG_URL}
`;
