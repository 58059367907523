import { makeStyles } from '@material-ui/core/styles';

import { CONTENT_SENSITIVITY } from 'src/data/generation';
import { COLORS } from 'src/data/ui';

const BG_COLOR_UNSAFE = '#F4736A';
const BG_COLOR_SENSITIVE = '#FFC04E';
const HORIZONTAL_TITLE_WIDTH = 115;

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexFlow: 'column nowrap',

      [`&[data-sensitivity="${CONTENT_SENSITIVITY.unsafe}"] $badge`]: {
        backgroundColor: BG_COLOR_UNSAFE,
      },
      [`&[data-sensitivity="${CONTENT_SENSITIVITY.sensitive}"] $badge`]: {
        backgroundColor: BG_COLOR_SENSITIVE,
      },

      '&$horizontalLayout': {
        '& $header': {},

        '& $title': {
          width: HORIZONTAL_TITLE_WIDTH,
          marginRight: theme.spacing(1),
        },

        '& $reason': {
          paddingLeft: HORIZONTAL_TITLE_WIDTH + theme.spacing(1),
        },
        '& $reasonDescription': {
          marginTop: theme.spacing(0.5),
        },
      },

      '&$smallerFont': {
        '& $title': {
          fontSize: theme.typography.pxToRem(11),
          lineHeight: theme.typography.pxToRem(14),
        },
        '& $badge': {
          fontSize: theme.typography.pxToRem(9),
          lineHeight: theme.typography.pxToRem(10),
        },
        '& $reasonTitle': {
          fontSize: theme.typography.pxToRem(11),
          lineHeight: theme.typography.pxToRem(14),
        },
        '& $reasonDescription': {
          fontSize: theme.typography.pxToRem(11),
          lineHeight: theme.typography.pxToRem(14),
        },
      },
    },
    horizontalLayout: {},
    smallerFont: {},

    loader: {
      alignSelf: 'center',
    },
    header: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    title: {
      marginRight: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.primary,
    },
    badge: {
      padding: theme.spacing(0.5, 1.25),
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(10),
      fontWeight: theme.typography.fontWeightMedium,
      textTransform: 'uppercase',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '4px',
    },
    reason: {
      display: 'flex',
      flexFlow: 'column nowrap',
      flex: 1,
      marginTop: theme.spacing(1),
    },
    reasonTitle: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.neutral.mediumGray,
      whiteSpace: 'pre-wrap',
    },
    reasonDescription: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.neutral.mediumGray,
      whiteSpace: 'pre-wrap',
      '& a': {
        color: '#648bd9',
      },
    },
  }),
  { name: 'ContentSensitivityInfo' }
);
