import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import { CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CustomMenu } from 'src/components/CustomMenu';
import { CustomMenuItem } from 'src/components/CustomMenuItem';
import { ItemDefault } from '../ItemDefault';
import { useStyles } from './useStyles';

const SUBMENU_PROPS = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const HOVER_MENU_TIMEOUT = 100;

export const ItemWithSubmenu = memo(
  forwardRef(({ className, classes, item, onClick, disabled, submenuProps }, ref) => {
    const styles = useStyles();

    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const loading = item?.loading || false;
    const localDisabled = disabled || item?.disabled;

    const menuItemRef = useRef(null);
    useImperativeHandle(ref, () => menuItemRef.current);

    const debouncedOpen = useDebouncedCallback(() => {
      setIsSubmenuOpen(true);
    }, HOVER_MENU_TIMEOUT);

    const debouncedClose = useDebouncedCallback(() => {
      setIsSubmenuOpen(false);
    }, HOVER_MENU_TIMEOUT);

    const handleClick = useCallback(() => {
      if (localDisabled) {
        return;
      }

      debouncedClose.cancel();
      setIsSubmenuOpen(true);
    }, [localDisabled, debouncedClose]);

    const handleMouseEnter = useCallback(() => {
      if (localDisabled) {
        return;
      }

      debouncedClose.cancel();
      debouncedOpen();
    }, [localDisabled, debouncedOpen, debouncedClose]);

    const handleMouseLeave = useCallback(() => {
      debouncedOpen.cancel();
      debouncedClose();
    }, [debouncedOpen, debouncedClose]);

    const handleSubmenuClose = useCallback(() => {
      debouncedOpen.cancel();
      setIsSubmenuOpen(false);
    }, [debouncedOpen]);

    const handleSubmenuItemClick = useCallback(
      (event) => {
        debouncedOpen.cancel();
        setIsSubmenuOpen(false);

        if (onClick) {
          onClick(event);
        }
      },
      [onClick, debouncedOpen]
    );

    const isSubMenuHasContent = item.submenuContent || item.items?.length > 0;

    return (
      <div
        className={cx(styles.wrapper, {
          [styles.open]: isSubmenuOpen,
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ItemDefault
          className={cx(styles.root, className)}
          classes={classes}
          ref={menuItemRef}
          item={item}
          onClick={handleClick}
          disabled={disabled}
          selected={isSubmenuOpen}
          endIcon={
            loading ? (
              <CircularProgress size={16} />
            ) : (
              <ExpandMoreIcon className={styles.iconSubmenu} />
            )
          }
        />

        {isSubMenuHasContent && !loading && (
          <CustomMenu
            className={styles.submenu}
            classes={{ paper: styles.submenu_paper }}
            anchorEl={menuItemRef?.current}
            open={isSubmenuOpen}
            onClose={handleSubmenuClose}
            disabled={disabled}
            autoFocus={false}
            disableAutoFocus
            disableEnforceFocus
            disablePortal
            {...SUBMENU_PROPS}
            {...submenuProps}
          >
            <div className={styles.submenu_items}>
              {item.submenuContent && item.submenuContent({ onClick: handleSubmenuItemClick })}

              {item.items?.length > 0 &&
                item.items.map((item) => (
                  <CustomMenuItem
                    key={item.id}
                    item={item}
                    onClick={handleSubmenuItemClick}
                  />
                ))}
            </div>
          </CustomMenu>
        )}
      </div>
    );
  })
);

ItemWithSubmenu.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    icon: PropTypes.shape({}),
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    submenuContent: PropTypes.func,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  submenuProps: PropTypes.shape(),
};

ItemWithSubmenu.defaultProps = {
  className: '',
  disabled: false,
  submenuProps: null,
  classes: null,
};
