import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  () => ({
    root: {
      minHeight: '100vh',
      width: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'PageLayout' }
);
