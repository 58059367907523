import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_PROJECT_FOLDER } from './schema';

const QUERY = gql`
  query ProjectFolders($workspaceId: ID) {
    projectFolders(workspaceId: $workspaceId) {
      ...ProjectFolder
    }
  }
  ${FRAGMENT_PROJECT_FOLDER}
`;

export const queryProjectFolders = async ({ workspaceId }, options) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: {
      workspaceId,
    },
    options: {
      abortSignal: options?.abortSignal,
    },
  });

  const data = getSingleProp(result.data);
  return data || [];
};
