import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { without } from 'lodash';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

import { useEmailConnectedAccounts, useRestrictActionPopup } from 'src/services';
import { CHANNEL, PLATFORM_CHANNEL, getChannelPerformanceLabel } from 'src/data/channel';
import { EMAIL_CSV_RESOURCES, getResourceDocumentLabel } from 'src/data/performance';
import {
  useAccountExtractionLoaderPopup,
  useAddFileResourcePopup,
  useUpgradePopup,
} from 'src/services/popup';
import { ChannelIcon } from 'src/components/ChannelIcon';
import { IconCloseThin } from 'src/assets/icons/CloseThin';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useBlogCustomModelPopup } from 'src/services/popup';
import TextLink from 'src/components/TextLink';
import { FEATURE_LIMITS, featureHasUsages } from 'src/data/featureLimits';
import {
  useCustomerTwitterSocialIntegrationEnabled,
  useCustomFeatureLimitsData,
  useIsCurrentUserAdmin,
} from 'src/store';
import { UploadIcon } from './assets/UploadIcon';
import { useStyles } from './useStyles';

const CSV_TEMPLATE = [
  [
    'Campaign (optional)',
    'Subject',
    'Email Body',
    'Sent',
    'Delivered',
    'Opened',
    'Clicked',
    'Date\n',
  ],
  [
    'Campaign name',
    'Paste the subject line of the email here',
    'Paste the content of your email here',
    '100000',
    '9000',
    '4640',
    '119',
    '2023-07-27 12:33:47\n',
  ],
  [
    'Campaign name',
    'Paste the subject line of the email here',
    'Paste the content of your email here',
    '100000',
    '9000',
    '4640',
    '119',
    '2023-07-27 12:33:47\n',
  ],
];

export const ConnectToChannelPopup = ({
  open,
  onClose,
  onSubmit,
  brandVoiceId,
  isTrainingModel,
  onConnectSuccess,
  displayIntegrations,
  displayResourcesTemplates,
  displayEmailIntegrations,
  displayBlogURLs,
  displayTalkingPointsFromResource,
  displayWTM,
  title,
  subTitle,
  isTalkingPointsView,
}) => {
  const styles = useStyles();
  const openAddFileResourcePopup = useAddFileResourcePopup();
  const openAccountExtractionLoaderPopup = useAccountExtractionLoaderPopup();
  const openBlogCustomModelPopup = useBlogCustomModelPopup();
  const emailConnectedAccounts = useEmailConnectedAccounts();
  const featureData = useCustomFeatureLimitsData(FEATURE_LIMITS.resourcesRowsPerCustomer);
  const isExceedPerformanceRowsLimit = !featureHasUsages(featureData);
  const openUpgradePopup = useUpgradePopup();
  const openRestrictActionPopup = useRestrictActionPopup();
  const isCurrentUserAdmin = useIsCurrentUserAdmin();

  const shouldIncludeTwitterSocial = useCustomerTwitterSocialIntegrationEnabled();

  const allChannels = [
    CHANNEL.google,
    CHANNEL.facebook,
    CHANNEL.linkedin,
    CHANNEL.instagram,
    CHANNEL.facebookPages,
    CHANNEL.linkedinSocial,
    ...(shouldIncludeTwitterSocial ? [CHANNEL.twitterSocial] : []), // Conditionally include CHANNEL.twitterSocial
    PLATFORM_CHANNEL.projectWtm,
    PLATFORM_CHANNEL.blogUrls,
  ];

  const templateCsvURL = useMemo(() => {
    const blob = new Blob(CSV_TEMPLATE, { type: 'text/csv' });
    // Creating an object for downloading url
    return window.URL.createObjectURL(blob);
  }, []);
  let channels = allChannels;
  channels = isTrainingModel
    ? without(channels, PLATFORM_CHANNEL.projectWtm, PLATFORM_CHANNEL.blogUrls, CHANNEL.google)
    : channels;
  channels = displayWTM ? channels : without(channels, PLATFORM_CHANNEL.projectWtm);
  channels = displayBlogURLs ? channels : without(channels, PLATFORM_CHANNEL.blogUrls);

  const handleCreateSuccess = useCallback(
    (account) => {
      openAccountExtractionLoaderPopup({
        account,
        isTrainingModel,
        isTalkingPointsView,
      });
    },
    [isTrainingModel, openAccountExtractionLoaderPopup, isTalkingPointsView]
  );

  const handleResourceClick = useCallback(
    async (type) => {
      if (emailConnectedAccounts.length && isTrainingModel) {
        onSubmit(type);
        onClose();
      } else {
        if (!isCurrentUserAdmin) {
          openRestrictActionPopup();
          return;
        }
        if (isExceedPerformanceRowsLimit) {
          openUpgradePopup(FEATURE_LIMITS.resourcesRowsPerCustomer);
          return;
        }

        openAddFileResourcePopup({
          type,
          brandVoiceId,
          onCreateSuccess: handleCreateSuccess,
          onConnectSuccess,
          isTalkingPointsView,
        });
      }

      onClose();
    },
    [
      isCurrentUserAdmin,
      openRestrictActionPopup,
      openAddFileResourcePopup,
      onClose,
      brandVoiceId,
      handleCreateSuccess,
      onConnectSuccess,
      onSubmit,
      emailConnectedAccounts,
      isTrainingModel,
      isExceedPerformanceRowsLimit,
      openUpgradePopup,
      isTalkingPointsView,
    ]
  );

  const handleBlogPostsArticlesClick = useCallback(async () => {
    const result = await openBlogCustomModelPopup({ brandVoiceId, onConnectSuccess });

    if (result) {
      onClose();
      openAccountExtractionLoaderPopup({ customModelAccount: result, isTrainingModel: true });
    }
  }, [
    openBlogCustomModelPopup,
    brandVoiceId,
    onClose,
    openAccountExtractionLoaderPopup,
    onConnectSuccess,
  ]);

  const handleTalkingPointsFromResourceClick = useCallback(
    (contentType) => {
      onSubmit(contentType);
    },
    [onSubmit]
  );

  const onDownloadCsvTemplate = useCallback(() => {
    if (displayResourcesTemplates) {
      //track event?
    }
  }, [displayResourcesTemplates]);

  return (
    <Dialog
      open={open}
      classes={{ paper: styles.root }}
      onClose={onClose}
      maxWidth="md"
      data-testid="ConnectToChannelPopup"
    >
      <DialogTitle
        className={styles.dialogTitle}
        disableTypography
      >
        <CustomTypography
          className={styles.popupTitle}
          variant={TYPOGRAPHY_VARIANT.bold16}
        >
          {title || 'Select Source'}
        </CustomTypography>
        <IconButton
          className={styles.closeButton}
          onClick={onClose}
        >
          <IconCloseThin className={styles.closeIcon} />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <CustomTypography
          className={styles.description}
          variant={TYPOGRAPHY_VARIANT.regular14}
        >
          {subTitle || 'Select an integration or resource'}
        </CustomTypography>
        <div className={styles.buttonsWrapper}>
          {displayIntegrations &&
            channels.map((channel) => (
              <Button
                className={styles.channelButton}
                data-testid={`channelButton_${channel}`}
                key={channel}
                classes={{ label: styles.buttonLabel }}
                onClick={() => {
                  onSubmit(channel);
                  onClose();
                }}
              >
                <ChannelIcon
                  channel={channel}
                  className={styles.channelIcon}
                />
                {getChannelPerformanceLabel(channel)}
              </Button>
            ))}

          {displayTalkingPointsFromResource && (
            <>
              <Button
                className={styles.channelButton}
                classes={{ label: styles.buttonLabel }}
                onClick={() => handleTalkingPointsFromResourceClick(CHANNEL.other)}
              >
                <ChannelIcon
                  className={styles.channelIcon}
                  channel={CHANNEL.other}
                />
                {getChannelPerformanceLabel(CHANNEL.other)}
              </Button>
              <Button
                className={styles.channelButton}
                classes={{ label: styles.buttonLabel }}
                onClick={() => handleTalkingPointsFromResourceClick(CHANNEL.generalDocument)}
              >
                <ChannelIcon
                  className={styles.channelIcon}
                  channel={CHANNEL.generalDocument}
                />
                PDF
              </Button>
            </>
          )}
          {displayBlogURLs && (
            <Button
              className={styles.channelButton}
              data-testid={`channelButton_${PLATFORM_CHANNEL.blogUrls}`}
              classes={{ label: styles.buttonLabel }}
              onClick={handleBlogPostsArticlesClick}
            >
              <ChannelIcon
                className={styles.channelIcon}
                channel={PLATFORM_CHANNEL.blogUrls}
              />
              {getChannelPerformanceLabel(PLATFORM_CHANNEL.blogUrls)}
            </Button>
          )}
          {displayEmailIntegrations && (
            <>
              <Button
                className={styles.channelButton}
                data-testid={`channelButton_${CHANNEL.hubspot}`}
                classes={{ label: styles.buttonLabel }}
                onClick={() => {
                  onSubmit(CHANNEL.hubspot);
                  onClose();
                }}
              >
                <ChannelIcon
                  className={styles.channelIcon}
                  channel={CHANNEL.hubspot}
                />
                {getChannelPerformanceLabel(CHANNEL.hubspot)} emails
              </Button>
              <Button
                className={styles.channelButton}
                data-testid={`channelButton_${CHANNEL.salesforce}`}
                classes={{ label: styles.buttonLabel }}
                onClick={() => {
                  onSubmit(CHANNEL.salesforce);
                  onClose();
                }}
              >
                <ChannelIcon
                  className={styles.channelIcon}
                  channel={CHANNEL.salesforce}
                />
                {getChannelPerformanceLabel(CHANNEL.salesforce)} emails
              </Button>
              <Button
                className={styles.channelButton}
                data-testid={`channelButton_${CHANNEL.marketo}`}
                classes={{ label: styles.buttonLabel }}
                onClick={() => {
                  onSubmit(CHANNEL.marketo);
                  onClose();
                }}
              >
                <ChannelIcon
                  className={styles.channelIcon}
                  channel={CHANNEL.marketo}
                />
                {getChannelPerformanceLabel(CHANNEL.marketo)} emails
              </Button>
              <Button
                className={styles.channelButton}
                data-testid={`channelButton_${CHANNEL.braze}`}
                classes={{ label: styles.buttonLabel }}
                onClick={() => {
                  onSubmit(CHANNEL.braze);
                  onClose();
                }}
              >
                <ChannelIcon
                  className={styles.channelIcon}
                  channel={CHANNEL.braze}
                />
                {getChannelPerformanceLabel(CHANNEL.braze)} emails
              </Button>
              {!displayResourcesTemplates && (
                <Button
                  className={styles.channelButton}
                  data-testid={`channelButton_${CHANNEL.emailChannel}`}
                  classes={{ label: styles.buttonLabel }}
                  onClick={() => {
                    onSubmit(CHANNEL.emailChannel);
                    onClose();
                  }}
                >
                  <ChannelIcon
                    className={styles.channelIcon}
                    channel={CHANNEL.emailChannel}
                  />
                  {getChannelPerformanceLabel(CHANNEL.emailChannel)}
                </Button>
              )}
            </>
          )}
          {displayResourcesTemplates &&
            EMAIL_CSV_RESOURCES.map((csvResource) => (
              <Button
                key={csvResource}
                className={styles.channelButton}
                classes={{ label: styles.buttonLabel }}
                onClick={() => {
                  handleResourceClick(csvResource);
                }}
              >
                <ChannelIcon
                  className={styles.channelIcon}
                  channel={csvResource}
                />
                {getResourceDocumentLabel(csvResource)} (CSV)
              </Button>
            ))}
        </div>
        {displayResourcesTemplates && (
          <div className={styles.csvTemplateContainer}>
            <UploadIcon className={styles.uploadIcon} />
            <div>
              Ensure your data import correctly by using{' '}
              <TextLink
                href={templateCsvURL}
                download="anyword_email_template"
                onClick={onDownloadCsvTemplate}
                data-testid={`downloadCsvTemplateLink`}
              >
                our CSV template
              </TextLink>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

ConnectToChannelPopup.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  displayIntegrations: PropTypes.bool,
  displayResourcesTemplates: PropTypes.bool,
  displayEmailIntegrations: PropTypes.bool,
  displayBlogURLs: PropTypes.bool,
  displayTalkingPointsFromResource: PropTypes.bool,
  displayWTM: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
ConnectToChannelPopup.defaultProps = {
  className: null,
  open: false,
  displayIntegrations: true,
  displayWTM: true,
  displayResourcesTemplates: false,
  displayEmailIntegrations: false,
  displayBlogURLs: false,
  displayTalkingPointsFromResource: false,
  title: 'Select Source',
  subTitle: 'Select an integration or resource',
};
