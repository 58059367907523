import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    paper: {
      position: 'relative',
      maxWidth: 860,
      '&$wistia': {
        maxWidth: 1205,
        width: '100%',
        overflow: 'hidden',
        boxShadow: 'unset',
        background: 'unset',

        '@media screen and (max-width: 1305px), (max-height: 640px)': {
          maxWidth: 860,
        },

        '& $buttonClose': {
          display: 'none',
        },

        '& $dialogContent': {
          overflow: 'hidden',
          background: 'unset',
        },
      },
    },
    wistia: {},
    wistiaEmbed: {
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
    },
    dialogContent: {
      background: '#fff',
      padding: '40px 0 0!important',
      borderRadius: theme.shape.borderRadius,
    },

    buttonClose: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      padding: theme.spacing(1),

      '& svg': {
        fontSize: theme.typography.pxToRem(22),
      },
    },

    iframe: {
      margin: theme.spacing(0, 5),
      borderRadius: theme.shape.borderRadius,
      border: 0,
    },
  }),
  { name: 'ExplainerVideoPopup' }
);
