import { isApplicationModeInfinity } from 'src/data/system';
import { useApplicationMode } from './useApplicationMode';
import { isInIframe } from 'src/lib/browser';

export const useIsApplicationModeInfinity = () => {
  const applicationMode = useApplicationMode();

  // We want to make sure we are embedded in an iframe
  if (!isInIframe()) {
    return false;
  }

  return isApplicationModeInfinity(applicationMode);
};
