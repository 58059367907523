export const addProtocolIfMissing = (url) => {
  if (url.match(/^http.*/)) {
    return url;
  }

  return `https://${url}`;
};

export const parseUrl = (rawUrl) => {
  const prepared = addProtocolIfMissing(rawUrl);
  return new URL(prepared);
};

export const getHostname = (url) => {
  if (!url) {
    return '';
  }
  return parseUrl(url).hostname;
};

export const isLocalhost = (rawUrl) => {
  const hostname = getHostname(rawUrl);
  return hostname === 'localhost' || hostname === '127.0.0.1';
};

export const isTesting = (rawUrl) => {
  // "staging" URLs also have "kcc" part, so let's check if this one is not from them first
  if (isStaging(rawUrl)) {
    return false;
  }

  const hostname = getHostname(rawUrl);
  return hostname.includes('kcc');
};

export const getSearchParamValue = (rawUrl, key) => {
  return parseUrl(rawUrl).searchParams.get(key);
};

export const isStaging = (rawUrl) => {
  const hostname = getHostname(rawUrl);
  return hostname.includes('staging.keywee.io');
};

export const isProduction = (rawUrl) => {
  const hostname = getHostname(rawUrl);
  return hostname.includes('anyword.com');
};

export const isTeadsProduction = (rawUrl) => {
  const hostname = getHostname(rawUrl);
  return hostname.includes('teads.anyword.com') || hostname.includes('teads-test.keywee.sh');
};

export const isNotProduction = (rawUrl) =>
  isLocalhost(rawUrl) || isStaging(rawUrl) || isTesting(rawUrl);

export const preparePlatformUrl = (path = '') =>
  `${window.location.protocol}//${window.location.host}${path}`;
