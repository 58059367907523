import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { BRIEF_FROM_LIST_FRAGMENT } from './schema';

const QUERY = gql`
  query BriefStatus($id: ID!) {
    brief(id: $id) {
      ...BriefFromList
    }
  }
  ${BRIEF_FROM_LIST_FRAGMENT}
`;

export const queryBriefStatus = (id) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      id,
    },
  }).then(({ data }) => getSingleProp(data));
