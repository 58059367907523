import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query ExternalSignupDetails($token: String!) {
    externalSignupDetails(token: $token) {
      email
    }
  }
`;

export const externalSignupDetails = ({ token }) =>
  executeGqlQuery({
    query: QUERY,
    variables: { token },
  }).then(({ data }) => getSingleProp(data));
