import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    root: {
      minWidth: 'auto',
    },
  }),
  { name: 'CommonNavBarButton' }
);
