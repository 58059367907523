import { lazy } from 'react';

const MAX_ATTEMPTS = 10;
const INTERVAL = 500;

const retry = (fn, attemptsLeft = MAX_ATTEMPTS, interval = INTERVAL) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            // Maximum retries exceeded
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, attemptsLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

export const lazyWithRetry = (
  componentImport,
  attemptsLeft = MAX_ATTEMPTS,
  interval = INTERVAL
) => lazy(() => retry(componentImport, attemptsLeft, interval));
