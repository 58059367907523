import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Rectangle,
} from 'recharts';

import { AGE_GROUP_APPEAL, getAgeHistogramAppeal } from 'src/data/demographics';
import { COLOR_PRIMARY, COLORS } from 'src/data/ui';
import { useStyles } from './useStyles';

const RoundedBar = (props) => (
  <Rectangle
    {...props}
    radius={[4, 4, 0, 0]}
  />
);

export const HistogramAge = memo(
  ({ className, data, forcedAppeal, hideGrid, xAxisProps, margin, displayHighlighting }) => {
    const styles = useStyles();

    const hasData = Array.isArray(data) && data.length > 0;
    const ageAppeal = forcedAppeal || getAgeHistogramAppeal(data || []);
    const highlightYoungest = [AGE_GROUP_APPEAL.youngest, AGE_GROUP_APPEAL.neutral].includes(
      ageAppeal
    );
    const highlightMiddle = [AGE_GROUP_APPEAL.middle, AGE_GROUP_APPEAL.neutral].includes(ageAppeal);
    const highlightOldest = [AGE_GROUP_APPEAL.oldest, AGE_GROUP_APPEAL.neutral].includes(ageAppeal);

    return (
      <ResponsiveContainer
        className={cx(styles.root, className, {
          [styles.highlightYoungest]: displayHighlighting && highlightYoungest,
          [styles.highlightMiddle]: displayHighlighting && highlightMiddle,
          [styles.highlightOldest]: displayHighlighting && highlightOldest,
        })}
      >
        <BarChart
          data={data}
          isAnimationActive={false}
          margin={margin}
          barCategoryGap={2}
        >
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={false}
            interval={0}
            padding={{ left: 0, right: 0 }}
            ticks={hasData ? undefined : ['']}
            height={16}
            {...xAxisProps}
          />

          <YAxis
            tickLine={false}
            axisLine={false}
            domain={[0, 100]}
            ticks={[0, 50, 100]}
            interval={0}
            allowDataOverflow
            hide
          />

          {!hideGrid && (
            <CartesianGrid
              horizontal
              vertical={false}
              stroke={COLORS.primary.light}
              strokeDasharray="5 5"
            />
          )}

          <Bar
            dataKey="value"
            fill={COLOR_PRIMARY}
            isAnimationActive={false}
            shape={<RoundedBar />}
          />
        </BarChart>
      </ResponsiveContainer>
    );
  }
);

HistogramAge.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  forcedAppeal: PropTypes.oneOf(Object.values(AGE_GROUP_APPEAL)),
  hideGrid: PropTypes.bool,
  xAxisProps: PropTypes.shape(),
  margin: PropTypes.shape(),
  displayHighlighting: PropTypes.bool,
};

HistogramAge.defaultProps = {
  className: null,
  data: [],
  forcedAppeal: null,
  yAxisProps: null,
  margin: undefined,
  hideGrid: false,
  displayHighlighting: false,
};
