import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { escapeRegExp } from 'lodash';
import { Menu, MenuItem, ListItemText, Typography, IconButton } from '@material-ui/core';

import IconLogout from 'src/assets/icons/Logout';
import IconSettings from 'src/assets/icons/Settings';
import IconContact from 'src/assets/icons/Contact';
import { SUPPORT_URL } from 'src/const';
import { SearchMenuItem } from 'src/components/SearchMenuItem';
import CustomerMenuItem from '../CustomerMenuItem';
import useStyles from './useStyles';
import { UserAvatar } from 'src/components/UserAvatar';
import { EditIcon } from 'src/assets/icons/EditIcon';

const moveSelectedUp = (customers, selectedId) => {
  if (!selectedId || customers.length <= 1) {
    return customers;
  }

  const selectedCustomer = customers.find((c) => String(c.id) === String(selectedId));
  if (!selectedCustomer) {
    return customers;
  }

  const filtered = customers.filter((c) => String(c.id) !== String(selectedId));
  return [selectedCustomer, ...filtered];
};

const filterCustomers = (customers, search) => {
  const regex = RegExp(escapeRegExp(search), 'i');
  return customers.filter((c) => regex.test(c.name));
};

export const ProfileMenu = ({
  classes,
  open,
  anchor,
  onClose,
  customers,
  selectedCustomerId,
  onCustomerClick,
  onSettingsClick,
  onLogoutClick,
  onEditClick,
  disabledSettings,
  name,
  email,
}) => {
  const styles = useStyles({ classes });

  const [search, setSearch] = React.useState('');
  React.useEffect(() => {
    if (open) {
      setSearch('');
    }
  }, [open]);

  const reorderedCustomers = React.useMemo(
    () => moveSelectedUp(customers, selectedCustomerId),
    [customers, selectedCustomerId]
  );

  const filteredCustomers = React.useMemo(() => {
    if (search) {
      return filterCustomers(reorderedCustomers, search);
    }
    return reorderedCustomers;
  }, [search, reorderedCustomers]);

  const customersPresent = customers.length > 1;
  const searchVisible = customers.length > 10;

  return (
    <Menu
      classes={{ paper: styles.paper }}
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
      data-testid="profileMenu"
    >
      <div className={styles.userInfo}>
        <UserAvatar name={name} />
        <div className="flex-1 min-w-0">
          <div className={styles.nameContainer}>
            <Typography className={styles.name}>{name}</Typography>
            <IconButton
              className={styles.editIcon}
              data-testid="profileMenuEditUserItem"
              onClick={onEditClick}
            >
              <EditIcon />
            </IconButton>
          </div>
          <Typography className={styles.email} data-testid="profileMenuUserEmail">{email}</Typography>
        </div>
      </div>
      {customersPresent && (
        <div
          className={styles.customerGroup}
          data-testid="profileMenuCustomItems"
        >
          <Typography className={styles.customerHeader}>Change account</Typography>
          {searchVisible && (
            <SearchMenuItem
              className={styles.searchItem}
              value={search}
              onChange={setSearch}
            />
          )}
          <div className={styles.customerItems}>
            {filteredCustomers.map((customer) => (
              <CustomerMenuItem
                classes={{ root: styles.customerItem }}
                key={customer.id}
                customer={customer}
                onClick={onCustomerClick}
                selected={String(customer.id) === String(selectedCustomerId)}
              />
            ))}
            {filteredCustomers.length === 0 && (
              <MenuItem
                className={cx(styles.menuItem, styles.noResults)}
                disabled
              >
                <ListItemText
                  classes={{
                    primary: styles.itemText,
                  }}
                  primary="No results"
                />
              </MenuItem>
            )}
          </div>
        </div>
      )}

      <div className={styles.settings}>
        <MenuItem
          className={styles.menuItem}
          onClick={onSettingsClick}
          disabled={disabledSettings}
          data-testid="profileMenuSettingsItem"
        >
          <IconSettings className={styles.itemIcon} />
          <ListItemText
            classes={{
              primary: styles.itemText,
            }}
            primary="Account settings"
          />
        </MenuItem>

        <MenuItem
          className={styles.menuItem}
          component="a"
          href={SUPPORT_URL}
          target="_blank"
          data-testid="profileMenuContactItem"
        >
          <IconContact className={styles.itemIcon} />
          <ListItemText
            classes={{
              primary: styles.itemText,
            }}
            primary="Contact us"
          />
        </MenuItem>

        <MenuItem
          className={styles.menuItem}
          onClick={onLogoutClick}
          data-testid="profileMenuLogoutItem"
        >
          <IconLogout className={styles.itemIcon} />
          <ListItemText
            classes={{
              primary: styles.itemText,
            }}
            primary="Log out"
          />
        </MenuItem>
      </div>
    </Menu>
  );
};

ProfileMenu.propTypes = {
  classes: PropTypes.shape(),
  anchor: PropTypes.instanceOf(Element),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})),
  selectedCustomerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onCustomerClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  disabledSettings: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
};

ProfileMenu.defaultProps = {
  classes: null,
  anchor: null,
  open: false,
  customers: [],
  selectedCustomerId: null,
  disabledSettings: false,
  name: '',
};
