import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import { sortCustomFormulas } from 'src/data/customFormulas';
import {
  queryCustomFormulas,
  createCustomFormula,
  updateCustomFormula,
  deleteCustomFormula,
  extractCustomFormula,
} from 'src/graphql/formula';

const INITIAL_STATE = {
  customFormulas: [],
  loadingCustomFormulas: false,
  extractingCustomFormula: false,
  savingCustomFormula: false,
  deletingCustomFormula: false,
};

const PRIVATE_ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  mergeCustomFormulaInCache:
    (item) =>
    ({ getState, setState }) => {
      const { customFormulas: list } = getState();
      const itemExists = list.some((t) => t.id === item.id);
      let newList = itemExists ? list.map((t) => (t.id === item.id ? item : t)) : [...list, item];

      if (item.isDefault) {
        newList = newList.map((t) => {
          if (t.generationTool === item.generationTool && t.id !== item.id) {
            return { ...t, isDefault: false };
          }
          return t;
        });
      }
      setState({ customFormulas: sortCustomFormulas(newList) });
    },

  removeCustomFormulaFromCache:
    (itemId) =>
    ({ getState, setState }) => {
      const { customFormulas } = getState();
      const filteredList = customFormulas.filter((t) => t.id !== itemId);
      setState({ customFormulas: filteredList });
    },
};

/**
 * Public actions
 */
const ACTIONS = {
  queryCustomFormulas:
    (brandVoiceId) =>
    async ({ setState }) => {
      setState({ loadingCustomFormulas: true });
      try {
        const result = await queryCustomFormulas(brandVoiceId);
        setState({ customFormulas: sortCustomFormulas(result) });
      } finally {
        setState({ loadingCustomFormulas: false });
      }
    },

  getCustomFormulas:
    () =>
    ({ getState }) => {
      return getState().customFormulas;
    },

  extractCustomFormula:
    ({ text, brandVoiceId }) =>
    async ({ setState }) => {
      setState({ extractingCustomFormula: true });
      try {
        const createdCustomFormula = await extractCustomFormula({ brandVoiceId, text });
        return createdCustomFormula;
      } finally {
        setState({ extractingCustomFormula: false });
      }
    },

  createCustomFormula:
    (item, brandVoiceId) =>
    async ({ setState, dispatch }) => {
      setState({ savingCustomFormula: true });
      try {
        const createdCustomFormula = await createCustomFormula({
          brandVoiceId,
          ...item,
        });
        dispatch(PRIVATE_ACTIONS.mergeCustomFormulaInCache(createdCustomFormula));
        return createdCustomFormula;
      } finally {
        setState({ savingCustomFormula: false });
      }
    },

  updateCustomFormula:
    (item) =>
    async ({ setState, dispatch }) => {
      setState({ savingCustomFormula: true });
      try {
        const updatedCustomFormula = await updateCustomFormula(item);
        dispatch(PRIVATE_ACTIONS.mergeCustomFormulaInCache(updatedCustomFormula));
        return updatedCustomFormula;
      } finally {
        setState({ savingCustomFormula: false });
      }
    },

  deleteCustomFormula:
    (id) =>
    async ({ setState, dispatch }) => {
      setState({ deletingCustomFormula: true });
      try {
        await deleteCustomFormula(id);
        dispatch(PRIVATE_ACTIONS.removeCustomFormulaFromCache(id));
      } finally {
        setState({ deletingCustomFormula: false });
      }
    },

  isSavingCustomFormula:
    () =>
    ({ getState }) => {
      return getState().savingCustomFormula;
    },

  isExtractingCustomFormula:
    () =>
    ({ getState }) => {
      return getState().extractingCustomFormula;
    },
};

export const CustomFormulasStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'formulas-store',
});
