import React, { useCallback, useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, Typography } from '@material-ui/core';

import { BackArrowIcon } from 'src/assets/icons/BackArrowIcon';
import { FieldChipInput } from 'src/components/FieldChipInput';
import { PopupHeader } from './components/PopupHeader';
import { TargetForm } from './components/TargetForm';
import { TargetTypeTabs } from './components/TargetTypeTabs';
import { usePopupState } from './usePopupState';
import { getDescription, getStepIndex } from './utils';
import { STEPS, KEYWORDS_HINT, MAX_KEYWORDS_PER_SEGMENT } from './const';
import { useStyles } from './useStyles';

const PLACEHOLDER = 'writing blog posts';

export const PopupAudienceTarget = ({
  open,
  segment,
  onSubmit,
  onClose,
  hideBackdrop,
}) => {
  const styles = useStyles();

  const {
    state,
    isValid,
    isUrlValid,
    handleChange,
    handleTypeChange,
  } = usePopupState(segment);

  const isFirstStep = useMemo(() => state.step === STEPS.first, [state]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (!isValid || !isUrlValid()) {
        return;
      }

      if (isFirstStep) {
        handleChange({ step: STEPS.keywords });
        return;
      }

      onSubmit(state);
    },
    [isValid, isUrlValid, onSubmit, state, handleChange, isFirstStep]
  );

  const handleCancelOrBack = useCallback(() => {
    if (isFirstStep) {
      onClose();
    } else {
      handleChange({ step: STEPS.first });
    }
  }, [isFirstStep, onClose, handleChange]);

  const stepIndex = getStepIndex(state.step);

  const description = getDescription(state.step);

  return (
    <Dialog
      classes={{ paper: styles.root }}
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop}
      maxWidth="sm"
      fullWidth
      data-testid="PopupAudienceTarget"
    >
      <PopupHeader className={styles.popupHeader} step={stepIndex} />

      <form className={styles.form} onSubmit={handleSubmit}>
        <Typography className={styles.description}>{description}</Typography>

        {isFirstStep ? (
          <Fragment>
            <TargetTypeTabs
              className={styles.targetTabs}
              value={state.targetType}
              onChange={handleTypeChange}
            />

            <TargetForm
              className={styles.segmentForm}
              state={state}
              onChange={handleChange}
            />
          </Fragment>
        ) : (
          <FieldChipInput
            className={styles.keywords}
            label="Keywords"
            hint={KEYWORDS_HINT}
            placeholder={state.keywords.length === 0 ? PLACEHOLDER : ''}
            value={state.keywords}
            AutocompleteProps={{ classes: { input: styles.input } }}
            onChange={(keywords) => {
              handleChange({ keywords });
            }}
            maxChips={MAX_KEYWORDS_PER_SEGMENT}
            error={state.keywordsError}
            data-testid="Keywords"
            isQuestion
            isOptional
            isItalic
          />
        )}

        <DialogActions className={styles.dialogActions}>
          {isFirstStep ? (
            <Button
              className={styles.buttonCancel}
              onClick={handleCancelOrBack}
              variant="text"
              color="primary"
            >
              Cancel
            </Button>
          ) : (
            <Button
              className={styles.buttonBack}
              onClick={handleCancelOrBack}
              startIcon={<BackArrowIcon className={styles.icon} />}
              variant="text"
              color="primary"
            >
              Back
            </Button>
          )}
          <Button
            className={styles.buttonSubmit}
            disabled={!isValid}
            type="submit"
            variant="contained"
            color="primary"
          >
            {isFirstStep ? 'Next' : 'Save'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

PopupAudienceTarget.propTypes = {
  open: PropTypes.bool.isRequired,
  segment: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  hideBackdrop: PropTypes.bool,
};

PopupAudienceTarget.defaultProps = {
  segment: null,
  hideBackdrop: null,
};
