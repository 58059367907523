import { useCallback } from 'react';

import { useConfirmPopup } from 'src/services/popup';

export const useOpenConfirmPopup = ({ mode, title, content, submitButtonText, withReject }) => {
  const confirmPopup = useConfirmPopup();

  return useCallback(() => {
    return confirmPopup({
      mode,
      title,
      content,
      buttons: {
        confirm: submitButtonText,
      },
      withReject: withReject,
    })
      .then(() => true)
      .catch(() => false);
  }, [confirmPopup, mode, title, content, submitButtonText, withReject]);
};
