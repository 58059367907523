import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_MULTI_PROMPT_INPUTS } from './schema';

const MUTATION = gql`
  mutation CreateMultiPromptInputs($customerId: ID!, $description: String!) {
    createMultiPromptInputs(customerId: $customerId, description: $description) {
      ...MultiPromptInputs
    }
  }
  ${FRAGMENT_MULTI_PROMPT_INPUTS}
`;

export const createMultiPromptInputs = async ({ customerId, description }) => {
  const variables = {
    customerId,
    description,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
