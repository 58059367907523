import { useCallback } from 'react';

import { useNotificationActions } from 'src/store';
import {
  isBackendError,
  isValidationError,
  isChannelAuthError,
  isAsyncRequestError,
  isExtractionError,
  isAbortError,
} from 'src/lib/errors';
import { captureException, captureMessage } from 'src/lib/sentry';

const shouldDisplayAsWarning = (error) => isValidationError(error);

const shouldDisplayAsIs = (error) =>
  error?.networkError ||
  error?.graphQLErrors ||
  isBackendError(error) ||
  isChannelAuthError(error) ||
  isAsyncRequestError(error) ||
  isExtractionError(error);

export const useErrorHandler = () => {
  const notificationActions = useNotificationActions();

  return useCallback(
    (error, extra) => {
      // Generally we should not have "AbortErrors" here, as they should be handled closer to the source
      if (isAbortError(error)) {
        captureMessage('"AbortError" was propagated to the generic error handler', {
          level: 'warning',
          extra: {
            error: error.toString(),
            stack: error.stack,
            source: 'useErrorHandler',
          },
        });
        return;
      }

      if (process.env.NODE_ENV === 'development') {
        console.warn('[useErrorHandler]', error.message);
      }

      if (shouldDisplayAsWarning(error) && error.message) {
        notificationActions.displayWarning(error.message);
      } else if (shouldDisplayAsIs(error) && error.message) {
        notificationActions.displayError(error);
      } else {
        notificationActions.displayError(
          new Error('Sorry, something went wrong. We’re working on it.')
        );

        captureException(error, {
          extra: {
            source: 'useErrorHandler',
            ...extra,
          },
        });
      }
    },
    [notificationActions]
  );
};
