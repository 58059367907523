import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { TruncatedTooltip } from 'src/components/TruncatedTooltip';
import { useStyles } from './useStyles';

export const TruncatedSpan = ({ className, children, title, ...restProps }) => {
  const styles = useStyles();

  return (
    <TruncatedTooltip
      title={title || children}
      {...restProps}
    >
      {(ref) => (
        <span
          className={cx(styles.root, className)}
          ref={ref}
        >
          {children}
        </span>
      )}
    </TruncatedTooltip>
  );
};

TruncatedSpan.propTypes = {
  className: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
};
TruncatedSpan.defaultProps = {
  className: '',
  title: null,
};
