import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Tooltip, Typography } from '@material-ui/core';

import { IconRefresh } from 'src/assets/icons/IconRefresh';
import { useStyles } from './useStyles';

export const ResultsFoundTitle = ({
  classes,
  className,
  numOfResults,
  toolTip,
  onCheckPlagiarism,
  disabled,
}) => {
  const styles = useStyles({ classes });
  const buttonRef = useRef();

  const handleClick = useCallback(() => {
    onCheckPlagiarism({ referenceElement: buttonRef.current });
  }, [onCheckPlagiarism]);

  return (
    <div className={className}>
      <Typography className={styles.resultsFoundTitle}>{numOfResults} Results Found</Typography>
      <Tooltip title={toolTip}>
        <span>
          <Button
            ref={buttonRef}
            className={styles.checkAgain}
            startIcon={<IconRefresh />}
            classes={{ startIcon: cx(styles.checkAgainIcon, { [styles.disabled]: disabled }) }}
            onClick={handleClick}
            disabled={disabled}
            variant="contained"
          >
            Check again
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

ResultsFoundTitle.propTypes = {
  className: PropTypes.shape(),
  classes: PropTypes.shape(),
  numOfResults: PropTypes.number,
  toolTip: PropTypes.string,
  onCheckPlagiarism: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ResultsFoundTitle.defaultProps = {
  className: null,
  classes: {},
  numOfResults: 0,
  toolTip: '',
};
