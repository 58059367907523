import { createStateSelectorHook } from 'src/lib/sweetState';
import { InAppNotificationsStore } from 'src/store';

const useNotificationsState = createStateSelectorHook(InAppNotificationsStore, [
  'currentNotification',
]);

export const useViewState = () => {
  const notificationsState = useNotificationsState();
  return {
    ...notificationsState,
  };
};
