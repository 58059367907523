import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_TONE_OF_VOICE } from './schema';

const MUTATION = gql`
  mutation ExtractCustomToneOfVoice(
    $brandVoiceId: ID!
    $url: String
    $text: String
    $resourceId: ID
    $inputType: TovExtractionInputTypeEnum
  ) {
    extractCustomToneOfVoice(
      brandVoiceId: $brandVoiceId
      url: $url
      text: $text
      resourceId: $resourceId
      inputType: $inputType
    ) {
      ...CustomToneOfVoice
    }
  }
  ${FRAGMENT_CUSTOM_TONE_OF_VOICE}
`;

export const extractCustomToneOfVoice = ({ brandVoiceId, url, text, resourceId, inputType }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { brandVoiceId, url, text, resourceId, inputType },
  }).then(({ data }) => getSingleProp(data));
};
