import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateUserCompanyInformation(
    $userRoles: [String!]
    $companySize: String
    $contactInformation: [String!]
  ) {
    updateUserCompanyInformation(
      userRoles: $userRoles
      companySize: $companySize
      contactInformation: $contactInformation
    ) {
      id
      companyInformation
      segment
    }
  }
`;

export const updateUserCompanyInformation = ({ userRoles, companySize, contactInformation }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { userRoles, companySize, contactInformation },
  }).then(({ data }) => getSingleProp(data));
