import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { USER_FRAGMENT } from '../user/schema';

const MUTATION = gql`
  mutation UpdateCustomerUser($id: ID!, $name: String!) {
    updateCustomerUser(id: $id, name: $name) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;

export const updateCustomerUser = (id, name) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { id, name },
  }).then(({ data }) => getSingleProp(data));
