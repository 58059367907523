import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { useStyles } from './useStyles';

export const ResourceUrlNode = ({ element, children }) => {
  const styles = useStyles();
  return (
    <Typography
      className={styles.root}
      component="span"
    >
      {children}
    </Typography>
  );
};

ResourceUrlNode.propTypes = {
  element: PropTypes.shape(),
  children: PropTypes.node,
  content: PropTypes.string,
};
