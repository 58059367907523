import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CO_CAMPAIGN } from './schema';

const MUTATION = gql`
  mutation VerifyCOCampaign($id: ID!) {
    verifyContinuousOptimizationCampaign(id: $id) {
      ...ContinuousOptimizationCampaign
    }
  }
  ${FRAGMENT_CO_CAMPAIGN}
`;

export function verifyCOCampaign(id) {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
}
