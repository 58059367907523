import { isNil, omitBy, pick, trim } from 'lodash';

import { getToolBriefType, GENERATION_TOOL } from 'src/data/generationTool';
import { isExtractingBriefType } from './briefType';
import { BRIEF_TYPE } from './const';

export const UNIFIED_FIELD = {
  title: 'title',
  article: 'article',
  emailPromotion: 'emailPromotion',
};

export const EMAIL_FIELD = {
  body: 'body',
};

export const BRIEF_FORM_FIELD_NAMES = {
  ...UNIFIED_FIELD,
  ...EMAIL_FIELD,
  url: 'url',
  contentTaxonomy: 'contentTaxonomy',
};

export const SOCIAL_MANUAL_FIELD = {
  body: 'body',
};

export const BLOG_POST_FIELD = {
  title: 'title',
  body: 'body',
};

export const PRODUCT_QUESTIONS = {
  productName: 'productName',
  productDescription: 'productDescription',
};

export const CANVAS_BRIEF_FIELD = {
  freeText: 'freeText',
};

const mapShopifyProductBriefContent = (brief) => ({
  id: brief.id || '',
  productName: brief.productName || '',
  body: brief.body || '',
  lastModifiedAt: brief.lastModifiedAt || '',
  productImageUrl: brief.productImageUrl || '',
  productStatus: brief.productStatus || '',
  url: brief.url || '',
});

const mapEmailPromotionalUrlContent = (brief) => {
  if (brief.type !== BRIEF_TYPE.unifiedPromotionalEmail) {
    return null;
  }
  return {
    emailPromotion: brief.emailPromotion,
  };
};

export const defaultBriefContent = () => ({});

export const mapContentForEditing = (brief) => {
  if (isExtractingBriefType(brief.type)) {
    return {
      url: brief.url,
      title: brief.computedTitle || '',
      article: brief.computedArticle || '',
      ...mapEmailPromotionalUrlContent(brief),
    };
  }

  switch (brief.type) {
    case BRIEF_TYPE.shopifyProductBrief:
      return mapShopifyProductBriefContent(brief);

    case BRIEF_TYPE.socialManual:
      return { [SOCIAL_MANUAL_FIELD.body]: brief.description };

    case BRIEF_TYPE.email:
      return pick(brief, Object.values(EMAIL_FIELD));

    case BRIEF_TYPE.blogPost:
      return pick(brief, Object.values(BLOG_POST_FIELD));

    case BRIEF_TYPE.canvas:
      return pick(brief, Object.values(CANVAS_BRIEF_FIELD));

    default:
      return null;
  }
};

const mapExtractedBriefByType = (brief) => {
  const { acTitleAiProvided = '', acArticleAiProvided = '' } = brief;
  return {
    title: acTitleAiProvided,
    article: acArticleAiProvided,
  };
};

export const mapExtractedContentForEditing = (brief) => {
  const { url, contentTaxonomy } = brief;
  return {
    url,
    contentTaxonomy,
    ...mapExtractedBriefByType(brief),
    ...mapEmailPromotionalUrlContent(brief),
  };
};

/**
 * Map any of currently used brief types to "BriefInputType", which is used in "document" generation mutations
 *
 * input BriefInput {
 *   title: String           # 1st usual field
 *   body: String            # 2nd usual field
 *   freeText: String        # 3rd "canvas" or single "canvas", "email promotional", single "social"
 * }
 */
export const mapContentForBriefInput = (briefType, briefContent) => {
  if (!briefContent) {
    return {};
  }

  switch (briefType) {
    case BRIEF_TYPE.unifiedContent:
    case BRIEF_TYPE.unifiedProduct:
      return {
        title: briefContent.title,
        body: briefContent.article,
        freeText: briefContent.body,
      };

    case BRIEF_TYPE.unifiedPromotionalEmail:
      return {
        title: briefContent.title,
        body: briefContent.article,
        freeText: briefContent.emailPromotion || briefContent.body,
      };

    case BRIEF_TYPE.email:
    case BRIEF_TYPE.socialManual:
      return {
        freeText: briefContent.body,
      };

    case BRIEF_TYPE.shopifyProductBrief:
      return {
        title: briefContent.productName,
        body: briefContent.body,
      };

    case BRIEF_TYPE.canvas: {
      return {
        freeText: briefContent.body || briefContent.freeText,
      };
    }

    default:
      return null;
  }
};

export const mapToolContentForBriefInput = (generationTool, briefContent) => {
  const briefType = getToolBriefType(generationTool);
  return mapContentForBriefInput(briefType, briefContent);
};

export const getRecomposeTextFromBrief = (generationTool, briefContent) => {
  const briefType = getToolBriefType(generationTool);
  if (!briefContent) {
    return '';
  }

  switch (briefType) {
    case BRIEF_TYPE.unifiedContent:
    case BRIEF_TYPE.unifiedProduct:
    case BRIEF_TYPE.unifiedPromotionalEmail:
      return briefContent.article;

    case BRIEF_TYPE.email:
    case BRIEF_TYPE.socialManual:
    case BRIEF_TYPE.shopifyProductBrief:
      return briefContent.body;

    case BRIEF_TYPE.canvas:
      return briefContent.freeText;

    default:
      return null;
  }
};

export const mapBriefInputToContent = (briefType, briefInput) => {
  if (!briefInput) {
    return {};
  }

  switch (briefType) {
    case BRIEF_TYPE.unifiedContent:
    case BRIEF_TYPE.unifiedProduct:
      return {
        title: briefInput.title,
        article: briefInput.body,
      };

    case BRIEF_TYPE.unifiedPromotionalEmail:
      return {
        title: briefInput.title,
        article: briefInput.body,
        emailPromotion: briefInput.freeText,
      };

    case BRIEF_TYPE.email:
    case BRIEF_TYPE.socialManual:
      return {
        body: briefInput.freeText,
      };

    case BRIEF_TYPE.shopifyProductBrief:
      return {
        productName: briefInput.title,
        body: briefInput.body,
      };

    case BRIEF_TYPE.canvas: {
      return {
        freeText: briefInput.freeText,
      };
    }

    default:
      return null;
  }
};

export const mapBriefFromGenerationResult = ({ generationTool, request }) => {
  const briefType = getToolBriefType(generationTool);
  const { briefTitle, briefBody, emailPromotion } = request;

  switch (briefType) {
    case BRIEF_TYPE.unifiedContent:
    case BRIEF_TYPE.unifiedProduct:
      return {
        title: briefTitle,
        article: briefBody,
      };

    case BRIEF_TYPE.unifiedPromotionalEmail:
      return {
        title: briefTitle,
        article: briefBody,
        emailPromotion,
      };

    case BRIEF_TYPE.email:
    case BRIEF_TYPE.socialManual:
      return {
        body: briefBody,
      };

    case BRIEF_TYPE.shopifyProductBrief:
      return {
        productName: briefTitle,
        body: briefBody,
      };

    case BRIEF_TYPE.canvas: {
      return {
        freeText: briefBody,
      };
    }

    default:
      return null;
  }
};

/**
 * In addition to trivial case of checking cache for passed brief types,
 * we also want to copy content between "unified product" and "unified email promo" brief types
 */
const BRIEF_SIMILAR_TYPES = {
  [BRIEF_TYPE.unifiedProduct]: [BRIEF_TYPE.unifiedPromotionalEmail],
  [BRIEF_TYPE.unifiedPromotionalEmail]: [BRIEF_TYPE.unifiedProduct],
};

export const shouldHaveUniqueCacheValue = (generationTool) =>
  [
    GENERATION_TOOL.basicPersonalBio,
    GENERATION_TOOL.basicCompanyBio,
    GENERATION_TOOL.basicEngagingQuestions,
    GENERATION_TOOL.basicQuoraAnswers,
  ].includes(generationTool);

export const generateBriefCacheKey = (generationTool) => {
  const briefType = getToolBriefType(generationTool);
  if (shouldHaveUniqueCacheValue(generationTool)) {
    return `tool_${generationTool}`;
  }
  return `brief_type_${briefType}`;
};

export const readBriefContentFromCache = (contentCache, generationTool) => {
  const briefType = getToolBriefType(generationTool);

  const cacheKey = generateBriefCacheKey(generationTool);
  // Check cache for this particular brief type
  const contentByDirectType = contentCache[cacheKey];
  if (contentByDirectType) {
    return contentByDirectType;
  }

  // Check if we have content for brief types which are similar to ours
  const similarTypes = BRIEF_SIMILAR_TYPES[briefType];
  if (similarTypes && similarTypes.length > 0) {
    for (let i = 0; i <= similarTypes.length - 1; i += 1) {
      const similarType = similarTypes[i];
      const similarContent = contentCache[similarType];
      if (similarContent) {
        const briefInput = mapContentForBriefInput(similarType, similarContent);
        const mappedContent = mapBriefInputToContent(briefType, briefInput);
        return omitBy(mappedContent, isNil); // only non-empty values matter
      }
    }
  }

  return null;
};

export const checkBriefHasContent = (briefType, briefContent) => {
  const data = mapContentForBriefInput(briefType, briefContent);
  return trim(data.title) !== '' || trim(data.body) !== '' || trim(data.freeText) !== '';
};

export const isEnoughContentForExtraction = (content) => {
  // over 5 words, continue generation
  return content && content.split(' ').length <= 5;
};
