import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 565,
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    illustration: {},
    title: {
      fontSize: theme.typography.pxToRem(20),
      marginTop: theme.spacing(6),
    },
    description: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'CustomModelPopup' }
);
