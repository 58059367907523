import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  () => ({
    root: {
      color: COLORS.success.active,
      cursor: 'pointer',
    },
  }),
  { name: 'InlineUpgradeButton' }
);
