import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconEli5 = memo(
  forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
      <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
      <path
        d="M6.02727 20V19.1346L7.56136 18.9423V11.4492L6 11.4766V10.6319L8.90455 10V18.9423L10.4318 19.1346V20H6.02727Z"
        fill="white"
      />
      <path
        d="M14.1886 18.9973V15.8448H11.4545V14.6223H14.1886V11.717H15.5318V14.6223H18.2591V15.8448H15.5318V18.9973H14.1886Z"
        fill="white"
      />
      <path
        d="M19.5955 20V19.1346L21.1295 18.9423V11.4492L19.5682 11.4766V10.6319L22.4727 10V18.9423L24 19.1346V20H19.5955Z"
        fill="white"
      />
    </SvgIcon>
  ))
);
