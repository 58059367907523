import { useEffect } from 'react';

import { captureMessage } from 'src/lib/sentry';
import { usePerformanceActions, isCopiesExtractionError, useCurrentCustomerId } from 'src/store';
import { useErrorHandler } from 'src/services';
import { flatConnectedAccounts } from 'src/data/performance';
import { useChannelAccounts } from './useChannelAccounts';

export const useGetAccountsLastExtraction = () => {
  const currentCustomerId = useCurrentCustomerId();
  const handleError = useErrorHandler();
  const performanceActions = usePerformanceActions();

  const { runningAccounts } = useChannelAccounts();

  const flattenRunningAccounts = flatConnectedAccounts(runningAccounts, true);

  const shouldGetAccountsLastExtraction = currentCustomerId && flattenRunningAccounts?.length > 0;

  useEffect(() => {
    if (!shouldGetAccountsLastExtraction) {
      return;
    }

    flattenRunningAccounts.forEach((account) => {
      performanceActions.getLastExtraction(account.channel, account.id).catch((error) => {
        if (isCopiesExtractionError(error)) {
          captureMessage(`Auth error getting ${account.channel} last extraction`, {
            level: 'warning',
            extra: {
              error: error.toString(),
              source: 'useGetAccountsLastExtraction',
            },
          });
        } else {
          handleError(error);
        }
      });
    });
  }, [shouldGetAccountsLastExtraction, performanceActions, flattenRunningAccounts, handleError]);
};
