import { Editor, Element as SlateElement, Transforms } from 'slate';

import { editorIsFocusedAndHasValidSelection, SLATE_TEXT_FORMATS } from 'src/lib/slate';
import { LIST_TYPES } from '../const';
import { isBlockActive, isMarkActive } from '../utils';

export const toggleBlock = (editor, format) => {
  const isList = LIST_TYPES.includes(format);
  const isSelectionValid = editorIsFocusedAndHasValidSelection(editor);
  const isActive = isBlockActive(editor, format, 'type');

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties = {
    type: isActive ? SLATE_TEXT_FORMATS.PARAGRAPH : isList ? SLATE_TEXT_FORMATS.LIST_ITEM : format,
  };
  Transforms.setNodes(editor, newProperties, {
    split: isSelectionValid,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleTextAlignmentBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format, 'align');

  Transforms.setNodes(editor, {
    align: isActive ? undefined : format,
  });
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
