import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CO_CONVERSION_GOAL } from './schema';

const MUTATION = gql`
  mutation CreateCOConversionGoal($campaignId: ID!, $url: String!) {
    createContinuousOptimizationLinkCallToAction(campaignId: $campaignId, url: $url) {
      ...ContinuousOptimizationConversionGoal
    }
  }
  ${FRAGMENT_CO_CONVERSION_GOAL}
`;

export function createCOConversionGoal(campaignId, url) {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { campaignId, url },
  }).then(({ data }) => getSingleProp(data));
}
