import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChannelMarketoIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
      >
        <path
          d="M1.75 18.205L5.519 17.206V5.188L1.768 4.582L1.75 18.205ZM22.25 3.334L16.134 0V24L22.25 19.114V3.334ZM13.289 3.548L8.364 2.086V20.915L13.289 18.758V3.548Z"
          fill="#5643AC"
        />
      </SvgIcon>
    );
  })
);
