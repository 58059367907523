import { GENERATION_TOOL } from 'src/data/generationTool';
import { IconContentImprover } from 'src/assets/icons/generationTools/IconContentImprover';
import { IconEli5 } from 'src/assets/icons/generationTools/IconEli5';
import { IconSentenceExpander } from 'src/assets/icons/generationTools/IconSentenceExpander';
import { IconAIDA } from 'src/assets/icons/generationTools/IconAIDA';
import BAB from 'src/assets/icons/generationTools/BAB';
import BlogIdeas from 'src/assets/icons/generationTools/BlogIdeas';
import F2B from 'src/assets/icons/generationTools/F2B';
import PAS from 'src/assets/icons/generationTools/PAS';
import PerfectHeadline from 'src/assets/icons/generationTools/PerfectHeadline';
import PowerfulBullets from 'src/assets/icons/generationTools/PowerfulBullets';
import ProductDescriptionList from 'src/assets/icons/generationTools/ProductDescriptionList';
import ProductDescriptionParagraph from 'src/assets/icons/generationTools/ProductDescriptionParagraph';
import ValueProp from 'src/assets/icons/generationTools/ValueProp';
import VideoTopicIdeas from 'src/assets/icons/generationTools/VideoTopicIdeas';
import { IconFacebook } from 'src/assets/icons/generationTools/IconFacebook';
import { IconX } from 'src/assets/icons/generationTools/IconX';
import { IconGeneralPrompt } from 'src/assets/icons/generationTools/IconGeneralPrompt';
import { IconGoogle } from 'src/assets/icons/generationTools/IconGoogle';
import { IconHomePage } from 'src/assets/icons/generationTools/IconHomePage';
import { IconLinkedin } from 'src/assets/icons/generationTools/IconLinkedin';
import { IconPinterest } from 'src/assets/icons/generationTools/IconPinterest';
import { IconInstagram } from 'src/assets/icons/generationTools/IconInstagram';
import { IconYoutube } from 'src/assets/icons/generationTools/IconYoutube';
import { IconLandingPage } from 'src/assets/icons/generationTools/IconLandingPage';
import { IconLandingPageSeo } from 'src/assets/icons/generationTools/IconLandingPageSeo';
import { IconAmazon } from 'src/assets/icons/generationTools/IconAmazon';
import { IconWalmart } from 'src/assets/icons/generationTools/IconWalmart';
import { IconEmailTool } from 'src/assets/icons/generationTools/IconEmailTool';
import { IconSms } from 'src/assets/icons/generationTools/IconSms';
import { IconOutbrain } from 'src/assets/icons/generationTools/IconOutbrain';
import { IconTaboola } from 'src/assets/icons/generationTools/IconTaboola';
import { IconTeads } from 'src/assets/icons/generationTools/IconTeads';
import { IconFreestyleTool } from 'src/assets/icons/generationTools/IconFreestyleTool';
import { IconTalkingPoints } from 'src/assets/icons/generationTools/IconTalkingPoints';
import { IconProductReview } from 'src/assets/icons/generationTools/IconProductReview';
import { IconSummarize } from 'src/assets/icons/generationTools/IconSummarize';
import { IconPersonalBio } from 'src/assets/icons/generationTools/IconPersonalBio';
import { IconCompanyBio } from 'src/assets/icons/generationTools/IconCompanyBio';
import { IconEngagingQuestions } from 'src/assets/icons/generationTools/IconEngagingQuestions';
import { IconQuoraAnswers } from 'src/assets/icons/generationTools/IconQuoraAnswers';
import { IconUnknown } from 'src/assets/icons/generationTools/IconUnknown';
import { IconWTMBlack } from 'src/assets/icons/IconWTMBlack';
import { IconCampaigns } from 'src/assets/icons/generationTools/IconCampaigns';
import { IconCustomMultiPrompt } from 'src/assets/icons/generationTools/IconCustomMultiPrompt';

const ICONS = {
  // "basic" and "basic DD"
  [GENERATION_TOOL.basicContentImprover]: IconContentImprover,
  [GENERATION_TOOL.basicEli5]: IconEli5,
  [GENERATION_TOOL.basicSentenceExpander]: IconSentenceExpander,
  [GENERATION_TOOL.basicAida]: IconAIDA,
  [GENERATION_TOOL.basicPas]: PAS,
  [GENERATION_TOOL.basicBab]: BAB,
  [GENERATION_TOOL.basicFeatureToBenefitsPractical]: F2B,
  [GENERATION_TOOL.basicPerfectHeadline]: PerfectHeadline,
  [GENERATION_TOOL.basicPersuasiveBulletPoint]: PowerfulBullets,
  [GENERATION_TOOL.basicValueProposition]: ValueProp,
  [GENERATION_TOOL.basicProductDescriptionList]: ProductDescriptionList,
  [GENERATION_TOOL.basicProductDescriptionRelaxed]: ProductDescriptionParagraph,
  [GENERATION_TOOL.basicBlogIdeas]: BlogIdeas,
  [GENERATION_TOOL.basicVideoIdeas]: VideoTopicIdeas,
  [GENERATION_TOOL.basicTalkingPointsToParagraph]: IconTalkingPoints,
  [GENERATION_TOOL.basicProductReview]: IconProductReview,
  [GENERATION_TOOL.summarizer]: IconSummarize,
  [GENERATION_TOOL.basicPersonalBio]: IconPersonalBio,
  [GENERATION_TOOL.basicCompanyBio]: IconCompanyBio,
  [GENERATION_TOOL.basicEngagingQuestions]: IconEngagingQuestions,
  [GENERATION_TOOL.basicQuoraAnswers]: IconQuoraAnswers,
  [GENERATION_TOOL.generalPrompt]: IconGeneralPrompt,

  // "data driven"
  [GENERATION_TOOL.facebookMixtureAd]: IconFacebook,
  [GENERATION_TOOL.facebookPrimaryTextProduct]: IconFacebook,
  [GENERATION_TOOL.facebookPrimaryTextProductRewrite]: IconFacebook,
  [GENERATION_TOOL.facebookHeadlineProduct]: IconFacebook,
  [GENERATION_TOOL.facebookHeadlineProductRewrite]: IconFacebook,
  [GENERATION_TOOL.googleRSA]: IconGoogle,
  [GENERATION_TOOL.googleDescriptionProduct]: IconGoogle,
  [GENERATION_TOOL.googleHeadlineProductRewrite]: IconGoogle,
  [GENERATION_TOOL.googleDescriptionProductRewrite]: IconGoogle,
  [GENERATION_TOOL.googleHeadlineProduct]: IconGoogle,
  [GENERATION_TOOL.outbrainHeadlineProduct]: IconOutbrain,
  [GENERATION_TOOL.outbrainHeadlineProductRewrite]: IconOutbrain,
  [GENERATION_TOOL.taboolaHeadlineProduct]: IconTaboola,
  [GENERATION_TOOL.taboolaHeadlineProductRewrite]: IconTaboola,
  [GENERATION_TOOL.twitterMixtureAd]: IconX,
  [GENERATION_TOOL.twitterCopyProduct]: IconX,
  [GENERATION_TOOL.twitterCopyProductRewrite]: IconX,
  [GENERATION_TOOL.twitterTitleProduct]: IconX,
  [GENERATION_TOOL.pinterestMixtureAd]: IconPinterest,
  [GENERATION_TOOL.pinterestDescriptionProduct]: IconPinterest,
  [GENERATION_TOOL.pinterestDescriptionProductRewrite]: IconPinterest,
  [GENERATION_TOOL.pinterestTitleProduct]: IconPinterest,
  [GENERATION_TOOL.landingHeadline]: IconLandingPage,
  [GENERATION_TOOL.landingHeadlineRewrite]: IconLandingPage,
  [GENERATION_TOOL.landingSubheader]: IconLandingPage,
  [GENERATION_TOOL.landingSubheaderRewrite]: IconLandingPage,
  [GENERATION_TOOL.landingCTA]: IconLandingPage,
  [GENERATION_TOOL.landingBenefits]: IconLandingPage,
  [GENERATION_TOOL.landingFeatures]: IconLandingPage,
  [GENERATION_TOOL.landingSEOMetadata]: IconLandingPageSeo,
  [GENERATION_TOOL.landingSEOMetadataRewrite]: IconLandingPageSeo,
  [GENERATION_TOOL.amazonProductListing]: IconAmazon,
  [GENERATION_TOOL.walmartProductListing]: IconWalmart,
  [GENERATION_TOOL.instagramCaption]: IconInstagram,
  [GENERATION_TOOL.facebookPost]: IconFacebook,
  [GENERATION_TOOL.tweet]: IconX,
  [GENERATION_TOOL.linkedinPost]: IconLinkedin,
  [GENERATION_TOOL.linkedinPostRewrite]: IconLinkedin,
  [GENERATION_TOOL.pinterestPostDescription]: IconPinterest,
  [GENERATION_TOOL.pinterestPostTitle]: IconPinterest,
  [GENERATION_TOOL.youtubeTitle]: IconYoutube,
  [GENERATION_TOOL.youtubeDescription]: IconYoutube,
  [GENERATION_TOOL.emailSubjectLine]: IconEmailTool,
  [GENERATION_TOOL.emailPromotionalSubjectLine]: IconEmailTool,
  [GENERATION_TOOL.emailPromotionalBody]: IconEmailTool,
  [GENERATION_TOOL.emailColdOutreachSubjectLine]: IconEmailTool,
  [GENERATION_TOOL.emailColdOutreachBody]: IconEmailTool,
  [GENERATION_TOOL.emailColdOutreachBodyRewrite]: IconEmailTool,
  [GENERATION_TOOL.emailPromotionalBodyRewrite]: IconEmailTool,
  [GENERATION_TOOL.emailBodyRewrite]: IconEmailTool,
  [GENERATION_TOOL.emailSubject]: IconEmailTool,
  [GENERATION_TOOL.emailBody]: IconEmailTool,
  [GENERATION_TOOL.emailMixture]: IconEmailTool,
  [GENERATION_TOOL.emailSubjectRewrite]: IconEmailTool,
  [GENERATION_TOOL.smsPromotional]: IconSms,
  [GENERATION_TOOL.smsPromotionalRewrite]: IconSms,
  [GENERATION_TOOL.facebookPrimaryTextContent]: IconFacebook,
  [GENERATION_TOOL.facebookHeadlineContent]: IconFacebook,
  [GENERATION_TOOL.outbrainHeadlineContent]: IconOutbrain,
  [GENERATION_TOOL.taboolaHeadlineContent]: IconTaboola,
  [GENERATION_TOOL.twitterCopyContent]: IconX,
  [GENERATION_TOOL.pinterestDescriptionContent]: IconPinterest,
  [GENERATION_TOOL.pinterestTitleContent]: IconPinterest,
  [GENERATION_TOOL.teadsSingleImageHeadline]: IconTeads,
  [GENERATION_TOOL.teadsSingleImageDescription]: IconTeads,
  [GENERATION_TOOL.teadsSingleVideoHeadline]: IconTeads,
  [GENERATION_TOOL.teadsSingleVideoDescription]: IconTeads,
  [GENERATION_TOOL.teadsCarouselHeadline]: IconTeads,
  [GENERATION_TOOL.teadsSocialLayout1Headline]: IconTeads,
  [GENERATION_TOOL.teadsSocialLayout1PrimaryText]: IconTeads,
  [GENERATION_TOOL.teadsSocialLayout1Description]: IconTeads,
  [GENERATION_TOOL.teadsSocialLayout2PrimaryText]: IconTeads,
  [GENERATION_TOOL.generalProductListing]: ProductDescriptionParagraph,
  [GENERATION_TOOL.linkedinHeadlineSingleImageRewrite]: IconLinkedin,
  [GENERATION_TOOL.linkedinIntroSingleImageRewrite]: IconLinkedin,
  [GENERATION_TOOL.instagramCaptionRewrite]: IconInstagram,
  [GENERATION_TOOL.facebookPostRewrite]: IconFacebook,
  [GENERATION_TOOL.pinterestPinTitleRewrite]: IconPinterest,
  [GENERATION_TOOL.pinterestPinDescriptionRewrite]: IconPinterest,
  [GENERATION_TOOL.tweetRewrite]: IconX,
  [GENERATION_TOOL.linkedinMixtureAd]: IconLinkedin,
  [GENERATION_TOOL.linkedinHeadlineSingleImage]: IconLinkedin,
  [GENERATION_TOOL.linkedinIntroSingleImage]: IconLinkedin,
  [GENERATION_TOOL.articleBlogHeadline]: PerfectHeadline,

  [GENERATION_TOOL.productLaunchMultiPrompt]: IconCampaigns,
  [GENERATION_TOOL.landingPageProductAnnouncementMultiPrompt]: IconLandingPage,
  [GENERATION_TOOL.emailDripMultiPrompt]: IconEmailTool,
  [GENERATION_TOOL.customMultiPrompt]: IconCustomMultiPrompt,

  // Freestyle
  [GENERATION_TOOL.freestyle]: IconFreestyleTool,
  [GENERATION_TOOL.createYourOwn]: IconFreestyleTool,

  //project blog
  [GENERATION_TOOL.blogDescription]: PerfectHeadline,

  // project wtm
  [GENERATION_TOOL.wtmDefault]: IconWTMBlack,
  [GENERATION_TOOL.lpcoLandingHeadline]: IconHomePage,
  [GENERATION_TOOL.lpcoLandingCTA]: IconHomePage,
  [GENERATION_TOOL.lpcoLandingProductDescription]: IconHomePage,
  [GENERATION_TOOL.lpcoLandingProductName]: IconHomePage,
  [GENERATION_TOOL.lpcoLandingCopy]: IconHomePage,
};

export const getGenerationToolIcon = (as) => ICONS[as] || IconUnknown;
