import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions, Typography, Button } from '@material-ui/core';

import { Checkbox } from 'src/components/Checkbox';
import { DisplayVariation } from 'src/components/DisplayVariation';
import { SCORE_SIZE } from 'src/components/Score';
import { useStyles } from './useStyles';

export const ConfirmAddToCampaignPopup = ({
  open,
  onConfirm,
  onCancel,
  variation,
  newVariation,
  score,
}) => {
  const styles = useStyles();

  const [marked, setMarked] = useState(false);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      onConfirm(marked);
    },
    [onConfirm, marked]
  );

  const handleChange = useCallback(
    (event, value) => {
      setMarked(value);
    },
    [setMarked]
  );

  return (
    <Dialog
      classes={{ paper: styles.root }}
      open={open}
      fullWidth
      data-testid="ConfirmAddToCampaignPopup"
    >
      <form onSubmit={handleSubmit}>
        <DialogContent className={styles.dialogContent}>
          <DisplayVariation
            className={styles.displayVariation}
            title="Original copy"
            copyText={variation.copyText}
            score={variation.score}
            scoreSize={SCORE_SIZE.medium}
          />
          <DisplayVariation
            className={styles.displayVariation}
            title="New copy"
            copyText={newVariation.copyText}
            score={newVariation.score}
            scoreSize={SCORE_SIZE.medium}
          />
          <Typography
            className={styles.title}
            variant="h4"
          >
            We recommend adding copy with a score higher than {score - 1}
          </Typography>
        </DialogContent>

        <DialogActions className={styles.dialogActions}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              className={styles.checkbox}
              id="dontShowThisAgain"
              checked={marked}
              onChange={handleChange}
              size="small"
            />
            <Typography
              className={styles.tocCheckboxLabel}
              component="label"
              htmlFor="dontShowThisAgain"
            >
              Don’t show this again
            </Typography>
          </div>
          <Button
            className={styles.buttonAction}
            onClick={onCancel}
            variant="text"
            color="primary"
            aria-label="Cancel"
          >
            Cancel
          </Button>
          <Button
            className={styles.buttonAction}
            type="submit"
            variant="contained"
            color="primary"
            aria-label="Confirm"
            data-testid="addToCampaignButton"
          >
            Add to campaign
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

ConfirmAddToCampaignPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  variation: PropTypes.shape({}).isRequired,
  newVariation: PropTypes.shape({}).isRequired,
  score: PropTypes.number,
};
ConfirmAddToCampaignPopup.defaultProps = {
  score: 0,
};
