import { useMemo } from 'react';
import { DateTime } from 'luxon';

import { countWords } from 'src/lib/string';

const WORDS_PER_MINUTE = 225;

export const useCalculateMetaFromText = (copyText) => {
  return useMemo(() => {
    const wordsCount = countWords(copyText);
    const readingMinutes = wordsCount / WORDS_PER_MINUTE;
    const dt = DateTime.fromSeconds(readingMinutes * 60);
    const readingTimeFormatted = dt.toFormat(`m 'min' s 'sec'`);

    return {
      readingTimeFormatted,
      wordsCount,
    };
  }, [copyText]);
};
