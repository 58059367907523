import React from 'react';
import PropTypes from 'prop-types';

import { flatConnectedAccounts } from 'src/data/performance';
import { PerformanceRowsLimitTooltip } from 'src/components/PerformanceRowsLimitTooltip';
import { Information } from 'src/components/Information';
import { formatNumber } from 'src/utils/format';
import { useStyles } from './useStyles';

export const RowsUsageHeader = ({ limitData, connectedAccounts }) => {
  const styles = useStyles();
  const { currentCount, limit } = limitData;
  const isFeatureLimited = currentCount >= limit;
  const flattenConnectedAccounts = flatConnectedAccounts(connectedAccounts, true);

  const usedAccountsCount = flattenConnectedAccounts?.length;

  return (
    <div className={styles.rowsLimitContainer}>
      <span data-testid="FeatureLimitedProgressBar_limitCountLabel">
        <span className={styles.semiBoldLabel}>
          {isFeatureLimited ? formatNumber(limit) : formatNumber(currentCount)}/
          {formatNumber(limit)}
        </span>{' '}
        rows (copy assets) used {currentCount ? `in ${usedAccountsCount} accounts` : ''}
      </span>
      <Information
        className={styles.informationIcon}
        content={<PerformanceRowsLimitTooltip connectedAccounts={flattenConnectedAccounts} />}
      />
    </div>
  );
};

RowsUsageHeader.propTypes = {
  limitData: PropTypes.shape({
    currentCount: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  connectedAccounts: PropTypes.shape({}),
  resourcesAccounts: PropTypes.array,
};
RowsUsageHeader.defaultProps = {
  connectedAccounts: {},
  resourcesAccounts: [],
};
