import { useCallback } from 'react';

import { useCustomerActions, useUserActions, useWorkspaceActions } from '../store';

export const useGetCommonEventAuthAttributes = () => {
  const userActions = useUserActions();
  const customerActions = useCustomerActions();
  const workspaceActions = useWorkspaceActions();

  return useCallback(() => {
    const userId = userActions.getCurrentUserId();
    const customerId = customerActions.getCurrentCustomerId();

    // Generally we should not have "workspace" selected when there is no customer, but in some cases we are not clearing the "workspace" store when "customer" gets changed
    // TODO: we should fix that, but for now we need to handle this case
    const workspaceId = customerId ? workspaceActions.getCurrentWorkspaceId() : null;

    return {
      userId,
      customerId,
      workspaceId,
    };
  }, [userActions, customerActions, workspaceActions]);
};
