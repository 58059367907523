import { SLATE_NODE_TYPES, SLATE_TEXT_FORMATS, SLATE_EDITOR_ACTION } from 'src/lib/slate';

export const ALWAYS_VISIBLE_ACTIONS = [
  SLATE_EDITOR_ACTION.undo,
  SLATE_EDITOR_ACTION.redo,
  SLATE_EDITOR_ACTION.clearFormat,
];

const EDITOR_ACTION_PROPS = {
  // Mark formatting actions
  [SLATE_EDITOR_ACTION.toggleBold]: {
    label: 'Bold',
    textFormat: SLATE_TEXT_FORMATS.BOLD,
    nodeType: SLATE_NODE_TYPES.MARK,
  },
  [SLATE_EDITOR_ACTION.toggleItalic]: {
    label: 'Italic',
    textFormat: SLATE_TEXT_FORMATS.ITALIC,
    nodeType: SLATE_NODE_TYPES.MARK,
  },
  [SLATE_EDITOR_ACTION.toggleUnderline]: {
    label: 'Underline',
    textFormat: SLATE_TEXT_FORMATS.UNDERLINE,
    nodeType: SLATE_NODE_TYPES.MARK,
  },
  [SLATE_EDITOR_ACTION.toggleStrikethrough]: {
    label: 'Strikethrough',
    textFormat: SLATE_TEXT_FORMATS.STRIKETHROUGH,
    nodeType: SLATE_NODE_TYPES.MARK,
  },

  // Block formatting actions
  [SLATE_EDITOR_ACTION.toggleParagraph]: {
    label: 'Normal text',
    textFormat: SLATE_TEXT_FORMATS.PARAGRAPH,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },
  [SLATE_EDITOR_ACTION.toggleH1]: {
    label: 'H1',
    textFormat: SLATE_TEXT_FORMATS.HEADING_ONE,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },
  [SLATE_EDITOR_ACTION.toggleH2]: {
    label: 'H2',
    textFormat: SLATE_TEXT_FORMATS.HEADING_TWO,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },
  [SLATE_EDITOR_ACTION.toggleH3]: {
    label: 'H3',
    textFormat: SLATE_TEXT_FORMATS.HEADING_THREE,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },
  [SLATE_EDITOR_ACTION.toggleBulletedList]: {
    label: 'Bulleted list',
    textFormat: SLATE_TEXT_FORMATS.BULLETED_LIST,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },
  [SLATE_EDITOR_ACTION.toggleNumberedList]: {
    label: 'Numbered list',
    textFormat: SLATE_TEXT_FORMATS.NUMBERED_LIST,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },
  [SLATE_EDITOR_ACTION.toggleBlockQuote]: {
    label: 'Quote',
    textFormat: SLATE_TEXT_FORMATS.BLOCK_QUOTE,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },
  [SLATE_EDITOR_ACTION.alignTextLeft]: {
    label: 'Left align',
    textFormat: SLATE_TEXT_FORMATS.ALIGN_LEFT,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },
  [SLATE_EDITOR_ACTION.alignTextCenter]: {
    label: 'Center align',
    textFormat: SLATE_TEXT_FORMATS.ALIGN_CENTER,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },
  [SLATE_EDITOR_ACTION.alignTextRight]: {
    label: 'Right align',
    textFormat: SLATE_TEXT_FORMATS.ALIGN_RIGHT,
    nodeType: SLATE_NODE_TYPES.BLOCK,
  },

  // Inline actions
  [SLATE_EDITOR_ACTION.insertLink]: {
    label: 'Link',
    textFormat: SLATE_TEXT_FORMATS.LINK,
    nodeType: SLATE_NODE_TYPES.INLINE,
  },

  [SLATE_EDITOR_ACTION.clearFormat]: {
    label: 'Clear formatting',
  },
};

export const getEditorActionLabel = (editorAction) =>
  EDITOR_ACTION_PROPS[editorAction]?.label || '';
export const getEditorActionTextFormat = (editorAction) =>
  EDITOR_ACTION_PROPS[editorAction]?.textFormat;
export const getEditorActionNodeType = (editorAction) =>
  EDITOR_ACTION_PROPS[editorAction]?.nodeType;

export const textFormatToEditorAction = (textFormat) =>
  Object.keys(EDITOR_ACTION_PROPS).find(
    (action) => EDITOR_ACTION_PROPS[action].textFormat === textFormat
  );

export const isTextStyleAction = (editorAction) =>
  [
    SLATE_EDITOR_ACTION.toggleParagraph,
    SLATE_EDITOR_ACTION.toggleH1,
    SLATE_EDITOR_ACTION.toggleH2,
    SLATE_EDITOR_ACTION.toggleH3,
  ].includes(editorAction);
