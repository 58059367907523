import { useCurrentCustomer, useCurrentUser, useCurrentWorkspace } from 'src/store';

/**
 * We have to have "user" loaded, otherwise we should just display loader and wait until data gets loaded
 * Also "customer" and "workspace" are required, but only for "confirmed" user, see CustomerStore -> init
 */
export const useIsLoading = () => {
  const currentUser = useCurrentUser();
  const currentCustomer = useCurrentCustomer();
  const currentWorkspace = useCurrentWorkspace();

  return !currentUser || (currentUser.isConfirmed && !currentCustomer && !currentWorkspace);
};
