import { get } from 'lodash';

import { isBasicPlan, getCustomerPlan } from 'src/data/subscription';
import { customerInTrial } from 'src/data/customer';

export const FEATURE_ACCESS = {
  liveScoring: 'live_scoring',
  refundFlaggedVariations: 'refund_flagged_variations',
  freemiumAllowed: 'freemium_allowed',
};

export const customerCanAccessFeature = (customer, feature) => {
  const featureAccess = get(customer, 'featureAccess', []);
  return featureAccess.includes(feature);
};

export const customerHasLiveScoring = (customer) =>
  customerCanAccessFeature(customer, FEATURE_ACCESS.liveScoring);

export const customerHasRefundFlaggedVariations = (customer) =>
  customerCanAccessFeature(customer, FEATURE_ACCESS.refundFlaggedVariations);

export const customerFreemiumAllowed = (customer) =>
  customerCanAccessFeature(customer, FEATURE_ACCESS.freemiumAllowed);

export const customerCanChangeGenerationLanguage = (customer) => {
  const isCustomerInTrial = customerInTrial(customer);
  const plan = getCustomerPlan(customer);
  return !isBasicPlan(plan) || isCustomerInTrial;
};
export const customerCanChangeNumberOfVariations = (customer) => {
  const isCustomerInTrial = customerInTrial(customer);
  const plan = getCustomerPlan(customer);
  return !isBasicPlan(plan) || isCustomerInTrial;
};
