import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { IconModified } from 'src/assets/icons/Modified';
import { TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { CustomIconButton } from 'src/components/CustomIconButton';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { ToneOfVoiceInsightsItem } from './components/ToneOfVoiceInsightsItem';
import { SuccessMessage } from './components/SuccessMessage';
import { EmptyBanner } from './components/EmptyBanner';
import { InsightBox } from '../InsightBox';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  toneOfVoiceInsights: PropTypes.arrayOf(
    PropTypes.shape({
      classification: PropTypes.bool.isRequired,
      reason: PropTypes.string.isRequired,
      trait: PropTypes.shape({
        trait: PropTypes.string.isRequired,
        butNot: PropTypes.string,
      }),
    })
  ),
  onSettingsClick: PropTypes.func,
  onOpenToneOfVoicePopup: PropTypes.func,
  analyzerToneOfVoice: PropTypes.shape({
    name: PropTypes.string.isRequired,
    traits: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  availableTovCount: PropTypes.number,
  displayBlogEmptyState: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onApplyToneOfVoice: PropTypes.func,
  applyingAnalyzedToneOfVoice: PropTypes.string,
};

const defaultProps = {
  className: null,
  classes: null,
  brandVoices: [],
  onSettingsClick: null,
  onOpenToneOfVoicePopup: null,
  toneOfVoiceInsights: [],
  analyzerToneOfVoice: null,
  availableTovCount: 0,
  displayBlogEmptyState: false,
  loading: false,
  disabled: false,
  onApplyToneOfVoice: null,
  applyingAnalyzedToneOfVoice: null,
};

export const ToneOfVoice = ({
  className,
  classes,
  toneOfVoiceInsights,
  onSettingsClick,
  onOpenToneOfVoicePopup,
  analyzerToneOfVoice,
  availableTovCount,
  displayBlogEmptyState,
  loading,
  disabled,
  onApplyToneOfVoice,
  applyingAnalyzedToneOfVoice,
}) => {
  const styles = useStyles({ classes });

  const toneOfVoiceInsightsExist = toneOfVoiceInsights?.length > 0;

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.header}>
        <TruncatedTypography
          className={styles.boxGroupTitle}
          variant={TYPOGRAPHY_VARIANT.medium12}
          title={analyzerToneOfVoice?.name}
        >
          Tone of voice
          {analyzerToneOfVoice && `: ${analyzerToneOfVoice.name}`}
        </TruncatedTypography>
        {availableTovCount > 0 && (
          <CustomIconButton
            className={styles.buttonSettings}
            onClick={onSettingsClick}
          >
            <IconModified />
          </CustomIconButton>
        )}
      </div>

      <InsightBox
        className={styles.wrapper}
        loading={loading}
      >
        {analyzerToneOfVoice &&
          (analyzerToneOfVoice?.traits || []).length > 0 &&
          !toneOfVoiceInsightsExist && (
            <SuccessMessage
              analyzerToneOfVoice={analyzerToneOfVoice}
              onSettingsClick={onSettingsClick}
            />
          )}
        {analyzerToneOfVoice && toneOfVoiceInsightsExist ? (
          toneOfVoiceInsights.map((item, index) => (
            <ToneOfVoiceInsightsItem
              key={`${item.reason}_${index}`}
              item={item}
              onApplyToneOfVoice={onApplyToneOfVoice}
              analyzerToneOfVoice={analyzerToneOfVoice}
              applyingAnalyzedToneOfVoice={applyingAnalyzedToneOfVoice}
            />
          ))
        ) : (
          <EmptyBanner
            onCtaClick={onOpenToneOfVoicePopup}
            displayBlogEmptyState={displayBlogEmptyState}
            analyzerToneOfVoice={analyzerToneOfVoice}
            onSettingsClick={onSettingsClick}
          />
        )}
      </InsightBox>
    </div>
  );
};

ToneOfVoice.propTypes = propTypes;
ToneOfVoice.defaultProps = defaultProps;
