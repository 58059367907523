import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    subtitle: {
      marginTop: theme.spacing(0.75),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      color: COLORS.neutral.mediumGray,
    },
    label: {
      paddingRight: 0,
    },
  }),
  { name: 'FieldHeader' }
);
