import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_TALKING_POINTS_VIEW } from './schema';

const QUERY = gql`
  query CustomerTalkingPointsView($customerId: ID!, $workspaceId: ID, $talkingPointsViewId: ID) {
    customerTalkingPointsViewList(
      customerId: $customerId
      workspaceId: $workspaceId
      talkingPointsViewId: $talkingPointsViewId
    ) {
      ...TalkingPointsView
    }
  }
  ${FRAGMENT_TALKING_POINTS_VIEW}
`;

export const queryTalkingPointsView = async ({ customerId, workspaceId, talkingPointsViewId }) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: { customerId, workspaceId, talkingPointsViewId },
  });

  const talkingPointsViews = getSingleProp(result.data);
  return talkingPointsViews.length > 0 ? talkingPointsViews[0] : null;
};
