import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconSend = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
        data-fill-none
      >
        <path
          d="M1.37499 2.83938C1.27741 2.66478 1.23823 2.46355 1.26318 2.26509C1.28813 2.06664 1.37589 1.88136 1.51365 1.73634C1.65141 1.59133 1.83193 1.49417 2.02885 1.45907C2.22576 1.42397 2.42874 1.45277 2.60812 1.54126L14.4919 7.57813C14.569 7.61735 14.6337 7.67714 14.6789 7.75088C14.7242 7.82462 14.7481 7.90944 14.7481 7.99594C14.7481 8.08245 14.7242 8.16727 14.6789 8.24101C14.6337 8.31475 14.569 8.37454 14.4919 8.41376L2.60812 14.4588C2.42874 14.5472 2.22576 14.576 2.02885 14.5409C1.83193 14.5058 1.65141 14.4087 1.51365 14.2637C1.37589 14.1187 1.28813 13.9334 1.26318 13.7349C1.23823 13.5365 1.27741 13.3352 1.37499 13.1606L4.53624 7.99563L1.37499 2.83938Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7482 7.99562L4.5332 7.99562"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
