import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';
import { getCircleSize } from 'src/components/Score';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      minHeight: 40,
      height: 'auto',
      padding: theme.spacing(1, 2),
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',

      '&:hover': {
        backgroundColor: COLORS.gray.gray90,
      },

      '&$delete': {
        color: COLORS.error.default,
        '& $listItemIcon > svg': {
          color: COLORS.error.default,
        },
      },
    },
    delete: {},
    header: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      fontSize: theme.typography.pxToRem(12),
    },
    listItemIcon: {
      marginRight: theme.spacing(1),
      minWidth: 0,

      '& > svg': {
        fontSize: theme.typography.pxToRem(16),
        color: COLORS.gray.gray30,
      },
    },
    text: {
      fontSize: theme.typography.pxToRem(14),
    },
    subtitle: {
      marginTop: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(12),
      color: COLORS.gray.gray50,
    },
    emptyState: {
      cursor: 'default',
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    title: {
      color: COLORS.gray.gray30,
      padding: theme.spacing(0, 1, 1, 1),
      cursor: 'default',
      backgroundColor: 'unset',
      '&:hover': {
        backgroundColor: 'unset',
      },
      '& span': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: getCircleSize(14),
      },
    },
    createButton: {
      color: COLORS.primary.active,
      backgroundColor: 'unset',
      borderRadius: 0,
      marginBottom: theme.spacing(1.5),
      borderBottom: `1px solid ${COLORS.gray.gray70}`,
      '& > div': {
        paddingBottom: theme.spacing(1),
      },
      '&:hover': {
        backgroundColor: 'unset',
        textDecoration: 'underline',
      },
    },
    itemWrapper: {
      '&:last-child': {
        '& > li': {
          border: 'none',
          marginBottom: 0,
        },
      },
    },
  }),
  { name: 'ItemDefault' }
);
