import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ChannelGoogle = React.forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    viewBox="0 0 24 24"
  >
    <g>
      <path
        d="M0.495255 17.0167L8.47527 3.18236L15.3901 7.17816L7.41107 21.0125L0.495255 17.0167Z"
        fill="#FBBC04"
        fillOpacity="1"
      />
      <path
        d="M23.4369 16.9684L15.452 3.12921C14.4212 1.17802 12.0059 0.432559 10.0563 1.46421C8.10678 2.49586 7.36292 4.9132 8.39273 6.86438C8.43793 6.94995 8.48608 7.03354 8.53718 7.11615L16.5221 20.9554C17.5991 22.881 20.0321 23.5684 21.9561 22.4906C23.8801 21.4127 24.567 18.9777 23.49 17.052C23.4723 17.0206 23.4546 16.9901 23.4369 16.9596V16.9684Z"
        fill="#4285F4"
      />
      <path
        d="M3.99247 22.9794C6.19744 22.9794 7.98493 21.1905 7.98493 18.9836C7.98493 16.7768 6.19744 14.9878 3.99247 14.9878C1.78749 14.9878 0 16.7768 0 18.9836C0 21.1905 1.78749 22.9794 3.99247 22.9794Z"
        fill="#34A853"
      />
    </g>
  </SvgIcon>
));

export default React.memo(ChannelGoogle);
