export const TEXT_ANNOTATION_TYPE = {
  mainOfferings: 'mainOfferings',
  emotions: 'emotions',
  features: 'features',
  pains: 'pains',
  benefits: 'benefits',
  ctas: 'ctas',
  campaignKeywords: 'campaignKeywords',
  headlineType: 'headlineType',
};

export const MAX_ANNOTATION_SELECTION_COUNT = 3;
