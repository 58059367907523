import { useCallback } from 'react';
import { lowerCase } from 'lodash';

import { CONFIRM_POPUP_MODE } from 'src/components/ConfirmPopup';
import { getProjectTypeTitle } from 'src/data/project';
import { useErrorHandler } from 'src/services';
import { useNotificationActions, useProjectActions, useProjectFolderActions } from 'src/store';
import { useConfirmPopup } from './popup';

export const useDeleteProject = () => {
  const confirmPopup = useConfirmPopup();
  const notifications = useNotificationActions();
  const projectActions = useProjectActions();
  const projectFolderActions = useProjectFolderActions();
  const handleError = useErrorHandler();

  return useCallback(
    async (project) => {
      const projectTitle = getProjectTypeTitle(project.type);
      const lowercaseTitle = lowerCase(projectTitle);

      const confirmed = await confirmPopup({
        mode: CONFIRM_POPUP_MODE.warning,
        title: `Are you sure you want to delete this ${lowercaseTitle}?`,
        content:
          'Deleting this project will permanently remove all associated data, including test variations, results, and settings. This action cannot be undone.',
        buttons: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      });
      if (!confirmed) {
        return false;
      }

      try {
        const deletedProject = await projectActions.deleteProject(project.id);
        projectFolderActions.decreaseFolderProjectCount(project.projectFolder?.id);
        notifications.displaySuccess(`${projectTitle} '${deletedProject.name}' deleted`);
        return true;
      } catch (error) {
        handleError(error);
        return false;
      }
    },
    [confirmPopup, handleError, notifications, projectActions, projectFolderActions]
  );
};
