import React from 'react';
import PropTypes from 'prop-types';

import { CustomDialog } from 'src/components/CustomDialog';
import { useEffectOnce } from 'src/lib/hooks';
import { useStyles } from './useStyles';

const CUSTOM_NOTIFICATION_EVENTS = {
  ctaClick: 'cb-cta-click-event',
};

export const CustomNotificationPopup = ({ open, notification, onClose, onButtonClick }) => {
  const styles = useStyles();

  useEffectOnce(() => {
    const handleCtaClick = ({ detail }) => {
      onButtonClick(detail?.link);
    };

    document.addEventListener(CUSTOM_NOTIFICATION_EVENTS.ctaClick, handleCtaClick);

    return () => {
      document.removeEventListener(CUSTOM_NOTIFICATION_EVENTS.ctaClick, handleCtaClick);
    };
  });

  return (
    <CustomDialog
      className={styles.root}
      classes={{
        buttonClose: styles.buttonClose,
      }}
      open={open}
      onClose={onClose}
      data-testid="CustomNotificationPopup"
      hideActions
      displayCloseIcon
    >
      <div
        dangerouslySetInnerHTML={{
          __html: notification.htmlContent,
        }}
      />
    </CustomDialog>
  );
};

CustomNotificationPopup.propTypes = {
  open: PropTypes.bool,
  notification: PropTypes.shape({
    htmlContent: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

CustomNotificationPopup.defaultProps = {
  open: false,
};
