import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const F2B = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
    <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
    <path
      d="M7.59622 10.128V19H6V10.128H7.59622ZM10.6822 13.9181V15.3439H7.15992V13.9181H10.6822ZM11.0547 10.128V11.5599H7.15992V10.128H11.0547Z"
      fill="white"
    />
    <path
      d="M17.1203 17.629V19H11.8209V17.8301L14.3269 14.7529C14.5788 14.432 14.7774 14.1496 14.9228 13.9059C15.0683 13.6581 15.1729 13.4367 15.2368 13.2417C15.3042 13.0427 15.3379 12.8538 15.3379 12.675C15.3379 12.4069 15.2988 12.1774 15.2208 11.9865C15.1428 11.7915 15.0275 11.6412 14.875 11.5355C14.726 11.4299 14.5415 11.3771 14.3216 11.3771C14.0875 11.3771 13.8853 11.4421 13.715 11.5721C13.5483 11.7021 13.4206 11.8829 13.332 12.1144C13.2468 12.346 13.2043 12.608 13.2043 12.9005H11.6666C11.6666 12.3724 11.7765 11.889 11.9965 11.4502C12.2164 11.0074 12.5268 10.6561 12.9276 10.3961C13.3284 10.132 13.8037 10 14.3535 10C14.8962 10 15.3538 10.1016 15.7263 10.3047C16.1023 10.5037 16.386 10.7921 16.5776 11.1699C16.7727 11.5437 16.8702 11.9905 16.8702 12.5105C16.8702 12.803 16.8294 13.0894 16.7479 13.3697C16.6663 13.6459 16.5492 13.9221 16.3967 14.1984C16.2477 14.4705 16.0668 14.7468 15.854 15.0271C15.6411 15.3074 15.4053 15.5978 15.1463 15.8984L13.8002 17.629H17.1203Z"
      fill="white"
    />
    <path
      d="M21.2705 15.1063H19.2805L19.2699 13.851H20.9406C21.235 13.851 21.4762 13.8084 21.6642 13.7231C21.8522 13.6337 21.9923 13.5058 22.0845 13.3392C22.1803 13.1686 22.2282 12.9614 22.2282 12.7177C22.2282 12.4414 22.1821 12.218 22.0899 12.0474C22.0012 11.8768 21.8611 11.7529 21.6695 11.6757C21.4815 11.5985 21.2385 11.5599 20.9406 11.5599H19.8339V19H18.2377V10.128H20.9406C21.3911 10.128 21.7937 10.1767 22.1484 10.2742C22.5067 10.3717 22.8099 10.52 23.0582 10.719C23.3065 10.9181 23.4963 11.1699 23.6276 11.4746C23.7588 11.7752 23.8244 12.1327 23.8244 12.5471C23.8244 12.9127 23.7517 13.2498 23.6063 13.5586C23.4644 13.8673 23.2391 14.1192 22.9305 14.3142C22.6255 14.5091 22.2264 14.6168 21.7334 14.6371L21.2705 15.1063ZM21.2013 19H18.8442L19.4667 17.5741H21.2013C21.4815 17.5741 21.7103 17.5234 21.8877 17.4218C22.065 17.3162 22.1963 17.174 22.2814 16.9953C22.3665 16.8165 22.4091 16.6114 22.4091 16.3798C22.4091 16.1198 22.3701 15.8944 22.292 15.7035C22.2176 15.5125 22.097 15.3663 21.9302 15.2647C21.7635 15.1591 21.5436 15.1063 21.2705 15.1063H19.7328L19.7434 13.851H21.6589L22.026 14.3446C22.4978 14.3365 22.8773 14.432 23.1646 14.631C23.4555 14.826 23.6666 15.0799 23.7978 15.3927C23.9326 15.7055 24 16.0406 24 16.3981C24 16.9668 23.8918 17.4462 23.6754 17.8362C23.4591 18.2221 23.1416 18.5125 22.723 18.7075C22.308 18.9025 21.8008 19 21.2013 19Z"
      fill="white"
    />
  </SvgIcon>
));

export default React.memo(F2B);
