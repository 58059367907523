import sanitizeHtml from 'sanitize-html';
import { Text } from 'slate';
import { trim } from 'lodash';

import { SLATE_TEXT_FORMATS } from 'src/lib/slate';
import { VOID_ELEMENTS } from '../const';
// TODO: move this constant to higher scope
import { PROMPT_TAG_SEARCH } from '../components/CustomElement/views/PromptTagNode/const';

const MAX_FILE_NAME_LENGTH = 15;

const sanitize = (dirty) =>
  sanitizeHtml(dirty, {
    allowedTags: [],
    allowedAttributes: {},
  });

const formatStyle = (stylesObj) => {
  const styles = Object.entries(stylesObj)
    .filter(([, value]) => value != null && value !== '')
    .map(([name, value]) => `${name}:${value}`);

  if (styles.length === 0) {
    return '';
  }

  return styles.join(';');
};

const formatAttributes = (attributes) => {
  if (!attributes) {
    return '';
  }

  const mapped = Object.entries(attributes).map(([key, value]) => `${key}="${value}"`);
  return mapped.join(' ');
};

const formatElement = (tagName, attributes, children) => {
  const attributesStr = formatAttributes(attributes);
  const elementStart = attributesStr.length > 0 ? `${tagName} ${attributesStr}` : tagName;
  return `<${elementStart}>${children}</${tagName}>`;
};

const serializeDDVariation = (variation, children) => {
  const attributes = {
    'data-variation': '',
    'data-variation-score': variation.score,
  };
  if (variation.isSaved) {
    attributes['data-saved-variation-id'] = variation.id;
  } else {
    attributes['data-variation-id'] = variation.id;
  }
  if (variation.assetType) {
    attributes['data-asset-type'] = variation.assetType;
  }
  if (variation.generationTool) {
    attributes['data-generation-tool'] = variation.generationTool;
  }
  if (variation.language) {
    attributes['data-language'] = variation.language;
  }
  if (variation.isScoreLocked) {
    attributes['data-score-locked'] = '';
  }
  if (variation.scoringMethod) {
    attributes['data-scoring-method'] = variation.scoringMethod.type;
    attributes['data-scoring-method-id'] = variation.scoringMethod.id;
    attributes['data-scoring-method-name'] = encodeURI(sanitize(variation.scoringMethod.name));
  }
  if (variation.campaignKeywords) {
    attributes['data-campaign-keywords'] = encodeURI(
      sanitize(JSON.stringify(variation.campaignKeywords))
    );
  }
  if (variation.secondaryScoringMethod) {
    attributes['data-secondary-scoring-method-name'] = encodeURI(
      sanitize(variation.secondaryScoringMethod.name)
    );
  }
  if (variation.benchmarkConfig) {
    attributes['data-benchmark-config-user-goal'] =
      variation.benchmarkConfig.comparisonSet?.userGoal;
    attributes['data-benchmark-config-account-id'] = variation.benchmarkConfig.account?.id;
    attributes['data-benchmark-config-account-name'] = encodeURIComponent(
      sanitize(variation.benchmarkConfig.account?.name)
    );
    attributes['data-benchmark-config-account-channel'] = encodeURIComponent(
      sanitize(variation.benchmarkConfig.account?.channel)
    );
    attributes['data-benchmark-config-comparison-set-name'] = encodeURIComponent(
      sanitize(variation.benchmarkConfig.comparisonSet?.name)
    );
  }
  return formatElement('div', attributes, children);
};

const serializeBankMessageNode = (node, children) => {
  const attributes = {
    'data-bank-message': true,
    'data-bank-message-id': node.bankMessageId,
    'data-bank-message-body': node.bankMessageBody,
    'data-bank-message-tag-name': node.tagName || '',
  };
  return formatElement('span', attributes, children);
};

const serializeImageNode = (node, children) => {
  const attributes = {
    'data-prompt-image': true,
    'data-prompt-image-id': node.children[0].id || node.id,
  };
  return formatElement('span', attributes, children);
};

const serializeDocumentNode = (node, children) => {
  const attributes = {
    'data-prompt-document': true,
    'data-prompt-document-id': node.children[0].id || node.id,
  };
  return formatElement('span', attributes, children);
};

const serializeProductDescriptionNode = (node, children) => {
  const attributes = {
    'data-product-description': true,
    'data-product-description-title': node.title,
    'data-product-description-article': node.article,
  };
  return formatElement('span', attributes, children);
};

const serializeResourceUrlNode = (node, children) => {
  const attributes = {
    'data-resource-url': true,
    'data-resource-url-text': node.resourceUrl,
    'data-resource-url-tag-name': node.tagName || '',
  };
  return formatElement('span', attributes, children);
};

const serializeTagNode = (node, children) => {
  const attributes = {
    'data-prompt-tag': true,
  };
  return formatElement('span', attributes, children);
};

export const serializeTextNode = (node, options) => {
  if (!node) {
    return '';
  }
  let string = !options?.skipTextNodeSanitization ? sanitize(node.text) : node.text;
  if (node.bold) {
    string = `<strong>${string}</strong>`;
  }
  if (node.italic) {
    string = `<em>${string}</em>`;
  }
  if (node.underline) {
    string = `<u>${string}</u>`;
  }
  if (node.strikethrough) {
    string = `<s>${string}</s>`;
  }
  return string;
};

const serializeNodeToHtml = (node, options) => {
  if (Text.isText(node)) {
    return serializeTextNode(node, options);
  }

  const children = node.children?.map((child) => serializeNodeToHtml(child, options)).join('');
  const stylesStr = formatStyle({
    'text-align': node.align,
  });
  const attributes = stylesStr.length > 0 ? { style: stylesStr } : null;
  const variation = node.variation || {};

  switch (node.type) {
    case SLATE_TEXT_FORMATS.HEADING_ONE:
      return formatElement('h1', attributes, children);
    case SLATE_TEXT_FORMATS.HEADING_TWO:
      return formatElement('h2', attributes, children);
    case SLATE_TEXT_FORMATS.HEADING_THREE:
      return formatElement('h3', attributes, children);
    case SLATE_TEXT_FORMATS.HEADING_FOUR:
      return formatElement('h4', attributes, children);
    case SLATE_TEXT_FORMATS.HEADING_FIVE:
      return formatElement('h5', attributes, children);
    case SLATE_TEXT_FORMATS.HEADING_SIX:
      return formatElement('h6', attributes, children);

    case SLATE_TEXT_FORMATS.BULLETED_LIST:
      return formatElement('ul', attributes, children);
    case SLATE_TEXT_FORMATS.NUMBERED_LIST:
      return formatElement('ol', attributes, children);
    case SLATE_TEXT_FORMATS.LIST_ITEM:
      return formatElement('li', attributes, children);

    case SLATE_TEXT_FORMATS.BLOCK_QUOTE:
      return formatElement('blockquote', attributes, children);

    case SLATE_TEXT_FORMATS.PARAGRAPH:
      return formatElement('p', attributes, children);

    case VOID_ELEMENTS.DIVIDER: {
      const localAttributes = {
        ...attributes,
        'data-divider': true,
      };
      return formatElement('div', localAttributes, children);
    }

    case SLATE_TEXT_FORMATS.LINK: {
      const localAttributes = {
        ...attributes,
        href: sanitize(node.url),
      };
      return formatElement('a', localAttributes, children);
    }

    case SLATE_TEXT_FORMATS.DD_VARIATION:
      return serializeDDVariation(variation, children);

    case SLATE_TEXT_FORMATS.BANK_MESSAGE:
      return serializeBankMessageNode(node, children);

    case SLATE_TEXT_FORMATS.PRODUCT_DESCRIPTION:
      return serializeProductDescriptionNode(node, children);

    case SLATE_TEXT_FORMATS.RESOURCE_URL:
      return serializeResourceUrlNode(node, children);

    case SLATE_TEXT_FORMATS.PROMPT_IMAGE:
      return serializeImageNode(node, children);

    case SLATE_TEXT_FORMATS.PROMPT_DOCUMENT:
      return serializeDocumentNode(node, children);

    case SLATE_TEXT_FORMATS.PROMPT_TAG:
      return serializeTagNode(node, children);

    default:
      return children;
  }
};

/**
 * Serializes Slate nodes to HTML
 * @param {import('slate').Descendant[]} nodes - Slate nodes
 * @param {object} [options] - Options
 * @returns {string} - HTML text
 */
export const slateToHtml = (nodes, options) => {
  return nodes?.map((node) => serializeNodeToHtml(node, options)).join('');
};

/**
 * Serializes Slate nodes to text
 * @param {import('slate').Descendant[]} nodes - Slate nodes
 * @param {{includeLinks: boolean, includeListItemPrefix: boolean}} [options] - Options
 * @returns {string} - Text
 */
export const slateToText = (nodes, options) => {
  if (!nodes) {
    return '';
  }
  return nodes.map((node, index) => serializeNodeToText(node, { index }, options)).join('\n');
};

const serializeNodeToText = (node, params, options) => {
  if (Text.isText(node)) {
    return node.text;
  }

  const childrenLines = node.children?.map((childNode, childIndex) =>
    serializeNodeToText(childNode, { index: childIndex, parent: node }, options)
  );

  switch (node.type) {
    case SLATE_TEXT_FORMATS.BULLETED_LIST:
    case SLATE_TEXT_FORMATS.NUMBERED_LIST: {
      return childrenLines.join('\n');
    }

    case SLATE_TEXT_FORMATS.LIST_ITEM: {
      const includeListItemPrefix = options?.includeListItemPrefix || false;
      const isNumberedList = params?.parent?.type === SLATE_TEXT_FORMATS.NUMBERED_LIST;
      const prefix = includeListItemPrefix ? (isNumberedList ? `${params.index + 1}. ` : '- ') : '';
      const content = childrenLines.join('');
      return `${prefix}${content}`;
    }

    case SLATE_TEXT_FORMATS.LINK: {
      const includeLinks = options?.includeLinks || false;
      const content = childrenLines.join('');
      return includeLinks && trim(node.url) ? `[${content}](${node.url})` : content;
    }

    case SLATE_TEXT_FORMATS.HEADING_ONE:
    case SLATE_TEXT_FORMATS.HEADING_TWO:
    case SLATE_TEXT_FORMATS.HEADING_THREE:
    case SLATE_TEXT_FORMATS.HEADING_FOUR:
    case SLATE_TEXT_FORMATS.HEADING_FIVE:
    case SLATE_TEXT_FORMATS.HEADING_SIX:
    case SLATE_TEXT_FORMATS.BLOCK_QUOTE:
    case SLATE_TEXT_FORMATS.PARAGRAPH:
    default: {
      return childrenLines.join('');
    }
  }
};

const promptTagPrefix = '<span data-prompt-tag="true">';
const promptClose = '</span>';

export const replacePromptTags = (value, lastPromptUrl, lastPromptAsset) => {
  if (!value) {
    return;
  }

  const tagStrings = value.split(promptTagPrefix).slice(1);
  const tagReplacements = [];
  tagStrings.forEach((tagString) => {
    const tagContent = tagString.split(promptClose)[0];
    if (
      (tagContent.toLowerCase().includes(PROMPT_TAG_SEARCH.url) ||
        tagContent.toLowerCase().includes(PROMPT_TAG_SEARCH.link)) &&
      lastPromptUrl
    ) {
      const newTag = serializeResourceUrlNode({ resourceUrl: lastPromptUrl }, lastPromptUrl);
      tagReplacements.push({
        replace: promptTagPrefix + tagContent + promptClose,
        replaceWith: newTag,
      });
    } else if (
      (tagContent.toLowerCase().includes(PROMPT_TAG_SEARCH.productDetails) ||
        tagContent.toLowerCase().includes(PROMPT_TAG_SEARCH.productDescription)) &&
      lastPromptAsset
    ) {
      let newTag;
      if (lastPromptAsset.id && lastPromptAsset.name) {
        newTag = serializeBankMessageNode(
          {
            bankMessageId: lastPromptAsset.id,
            bankMessageBody: lastPromptAsset.body,
          },
          lastPromptAsset.name
        );
      } else if (lastPromptAsset.title && lastPromptAsset.article) {
        newTag = serializeProductDescriptionNode(
          { title: lastPromptAsset.title, article: lastPromptAsset.article },
          lastPromptAsset.title
        );
      }

      if (newTag) {
        tagReplacements.push({
          replace: promptTagPrefix + tagContent + promptClose,
          replaceWith: newTag,
        });
      }
    }
  });
  let finalValue = value;
  tagReplacements.forEach((item) => {
    finalValue = finalValue.replace(item.replace, item.replaceWith);
  });
  return finalValue;
};

export const replaceFilePlaceholders = (text, fields, field, documents, images) => {
  let serialized = text;
  Object.entries(fields[field].placeholders).forEach(([placeholder, value]) => {
    if (fields[field].placeholders[placeholder].type === SLATE_TEXT_FORMATS.PROMPT_DOCUMENT) {
      const fileText = value.article;
      if (fileText) {
        const text =
          fileText.length > MAX_FILE_NAME_LENGTH
            ? `${fileText.substring(0, MAX_FILE_NAME_LENGTH)}\u2026`
            : fileText;
        serialized = serialized.replace(placeholder, `'${text}'`);
      }
    } else if (fields[field].placeholders[placeholder].type === SLATE_TEXT_FORMATS.PROMPT_IMAGE) {
      const fileText = value.article;
      if (fileText) {
        const text =
          fileText.length > MAX_FILE_NAME_LENGTH
            ? `${fileText.substring(0, MAX_FILE_NAME_LENGTH)}\u2026`
            : fileText;
        serialized = serialized.replace(placeholder, `'${text}'`);
      }
    } else if (fields[field].placeholders[placeholder].type === SLATE_TEXT_FORMATS.BANK_MESSAGE) {
      serialized = serialized.replace(
        placeholder,
        `'${fields[field].placeholders[placeholder]?.body}'`
      );
    } else if (fields[field].placeholders[placeholder].type === SLATE_TEXT_FORMATS.RESOURCE_URL) {
      serialized = serialized.replace(
        placeholder,
        `'${fields[field].placeholders[placeholder]?.url}'`
      );
    }
  });
  return serialized;
};
