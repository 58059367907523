import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useStyles from './useStyles';

const propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
};

const defaultProps = {
  className: null,
};

const ImageIcon = ({ className, image }) => {
  const styles = useStyles({ image });

  return <div className={cx(styles.root, className)} />;
};

ImageIcon.propTypes = propTypes;
ImageIcon.defaultProps = defaultProps;

export default ImageIcon;
