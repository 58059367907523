import { customerHasEmptyMigrationStatus, customerSubscriptionExpired } from 'src/data/customer';
import { isOnboardingStageDone, ONBOARDING_STAGE } from 'src/data/onboarding';
import { useCurrentUser, useCurrentCustomer } from 'src/store';
import { useMemo } from 'react';

export const useIsCustomerLocked = () => {
  const user = useCurrentUser();
  const customer = useCurrentCustomer();

  return useMemo(() => {
    if (!user || !customer) {
      return true;
    }

    const seenMigrationPopup = isOnboardingStageDone(
      user.onboardingStages,
      ONBOARDING_STAGE.migratedFromStarter
    );

    return (
      customerSubscriptionExpired(customer) ||
      (customerHasEmptyMigrationStatus(customer) && !seenMigrationPopup)
    );
  }, [user, customer]);
};
