import React, { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const PersonaAvatarEight = memo(
  forwardRef(({ opaque, ...props }, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 72 72"
        data-fill-none
      >
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M72 35.9945C72.0011 43.1146 69.8908 50.0752 65.936 55.996C61.9811 61.9167 56.3594 66.5318 49.7817 69.2576C43.204 71.9833 35.9657 72.6973 28.9821 71.3093C21.9986 69.9213 15.5835 66.4936 10.5481 61.4597C5.51259 56.4258 2.08295 50.0117 0.692817 43.0286C-0.697315 36.0455 0.0145014 28.807 2.73825 22.2285C5.46201 15.6499 10.0754 10.0268 15.9949 6.07014C21.9145 2.11351 28.8744 0.00108505 35.9945 0C45.5412 0.00217991 54.6964 3.7948 61.448 10.5443C68.1995 17.2938 71.9949 26.4478 72 35.9945Z"
            fill="#8CB2FF"
          />
          <path
            d="M54.7743 40.041C54.7013 40.4141 54.5786 40.7757 54.4095 41.1162C53.8607 42.2166 53.2076 43.2618 52.4593 44.2376C52.0863 44.7094 50.4104 45.236 50.4104 45.236L50.394 45.2991C50.3172 45.5542 50.0292 46.5389 49.7494 47.3974C49.7302 47.4605 49.7083 47.5263 49.6836 47.5921C49.6589 47.6579 49.6369 47.7238 49.6068 47.8061C49.124 49.0431 47.4536 52.3839 47.2891 52.713V52.724L46.9297 57.3869V57.4801C46.9297 58.0753 47.0916 64.9626 47.4207 66.8662C47.5423 67.5237 47.782 68.1538 48.1284 68.7258C48.3251 69.0512 48.5451 69.362 48.7867 69.6557C40.9905 72.6032 32.4154 72.767 24.5124 70.1192C24.5864 69.812 24.6687 69.4582 24.7592 69.0742C25.2639 66.8991 25.9688 63.764 26.1307 62.4557V62.4035C26.1608 62.1731 26.1828 61.8906 26.2047 61.5807V61.545C26.3775 58.8406 26.3144 53.4893 26.3144 53.4893L25.6781 51.9587L23.2891 46.1549L22.9133 45.247C22.0904 45.2113 20.719 43.8755 20.6203 43.7823C20.6203 43.7823 19.0075 41.1546 18.4945 39.0344C18.3217 38.313 18.2779 37.663 18.4671 37.1693C18.6108 36.7977 18.8156 36.4528 19.0733 36.1489C19.5351 35.595 20.175 35.2188 20.8836 35.0847L21.5611 28.6143L22.9764 23.3754L35.3357 17.8047L42.4507 18.0323L51.6831 24.4726L52.2097 27.572L51.6831 35.2712L53.3288 35.071L53.5729 35.3617L54.596 36.5686L54.7579 36.7578C54.7579 36.7578 54.7716 36.8813 54.7853 37.087C54.8818 38.0695 54.8781 39.0593 54.7743 40.041Z"
            fill="#DEE6F7"
          />
          <path
            d="M51.655 35.3554C51.9777 35.1596 52.3525 35.0667 52.7293 35.0893C53.1061 35.1118 53.4672 35.2487 53.7642 35.4816C54.3508 35.9673 54.7408 36.6497 54.8614 37.4016C55.0748 38.5428 54.9607 39.721 54.5322 40.8C54.1015 41.8721 53.5256 42.8799 52.8207 43.7952C52.3105 44.4864 51.6632 45.2078 50.8047 45.2654"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.3633 40.3801C51.3879 39.9432 51.5758 39.5314 51.8898 39.2266C52.2038 38.9218 52.6209 38.7461 53.0584 38.7344"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.7886 35.2385C21.4665 35.0429 21.0924 34.9499 20.7161 34.9719C20.3399 34.994 19.9792 35.13 19.6821 35.3619C19.0984 35.8442 18.7087 36.5212 18.585 37.2682C18.3684 38.4063 18.4777 39.5825 18.9004 40.6611C19.3267 41.7353 19.9029 42.7437 20.6119 43.6563C21.1221 44.3475 21.7694 45.0689 22.6252 45.1265"
            stroke="#080808"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.083 40.2629C22.059 39.8262 21.8717 39.4145 21.5581 39.1096C21.2446 38.8047 20.8278 38.6289 20.3906 38.6172"
            stroke="#080808"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.1976 53.125C46.8905 56.484 46.8108 59.8599 46.959 63.2297C47.055 65.3115 47.2799 67.4866 48.421 69.2311"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.3318 53.4766C26.4717 58.9623 26.4635 64.629 24.5078 69.7691"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.685 35.2734C51.685 48.8945 44.9869 59.94 36.7227 59.94C28.8781 59.94 22.4598 49.9889 21.8125 37.3278"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.332 53.4766C26.332 53.4766 28.0408 64.1737 36.4806 64.1737C44.9204 64.1737 46.8788 53.9346 46.8788 53.9346C46.8788 53.9346 42.076 60.4654 36.7302 60.2103C31.3844 59.9552 26.332 53.4766 26.332 53.4766Z"
            fill="black"
          />
          <path
            d="M31.3495 38.5267C30.4479 38.2817 29.5065 38.2193 28.5805 38.3432C27.6544 38.4671 26.7625 38.7747 25.957 39.248"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.0814 39.7031C31.798 40.2418 31.3978 40.7103 30.9102 41.0745"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.6714 35.9922C35.6988 37.2738 35.449 38.5463 34.9391 39.7225C34.4673 40.9074 33.8584 42.0347 33.3373 43.2004C33.236 43.3851 33.1859 43.5934 33.1919 43.8038C33.2301 44.0031 33.3254 44.187 33.4662 44.3332C33.8623 44.821 34.365 45.2117 34.9355 45.4751C35.5061 45.7385 36.1294 45.8677 36.7576 45.8528"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.543 49.5575C34.5606 49.9347 35.6465 50.0925 36.7295 50.0207C37.8124 49.9488 38.8679 49.6489 39.8269 49.1406"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.9297 53.0772C35.1975 53.2753 35.5034 53.4159 35.8282 53.49C36.1529 53.5641 36.4895 53.5702 36.8168 53.5078C37.3357 53.4334 37.8352 53.2582 38.2869 52.9922"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.6429 38.6625C28.5957 38.911 28.605 39.1669 28.6703 39.4113C28.7057 39.5317 28.7667 39.643 28.849 39.7377C28.9314 39.8323 29.0332 39.9081 29.1476 39.9599C29.2644 40.0095 29.3941 40.0203 29.5176 39.9907C29.641 39.9611 29.7517 39.8926 29.8333 39.7953C29.9065 39.6836 29.9542 39.5572 29.9732 39.425C30.0248 39.1716 30.0423 38.9123 30.0253 38.6543C30.0054 38.9328 29.9219 39.203 29.7812 39.4442C29.7438 39.5135 29.6913 39.5735 29.6276 39.6197C29.5825 39.6393 29.5341 39.6497 29.4851 39.6505C29.436 39.6512 29.3873 39.6423 29.3417 39.6242C29.2961 39.6061 29.2545 39.5792 29.2193 39.545C29.1841 39.5109 29.156 39.4701 29.1366 39.425C29.0708 39.233 29.0708 39.0245 29.1366 38.8325C29.1542 38.741 29.1907 38.6542 29.2436 38.5775C29.273 38.5414 29.311 38.5135 29.3542 38.4962C29.3974 38.4789 29.4443 38.4729 29.4904 38.4787C29.633 38.5171 29.6742 38.6981 29.666 38.8463C29.6543 39.0542 29.5932 39.2564 29.4877 39.436C29.3889 39.0959 29.4602 38.7173 28.6429 38.6625Z"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.5039 30.7031C33.7355 31.4908 33.8428 32.3098 33.8221 33.1306"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.1756 30.4406C31.4719 30.3779 31.781 30.4273 32.0429 30.5793C32.3049 30.7313 32.5011 30.9751 32.5937 31.2634C32.7797 31.8492 32.8876 32.457 32.9146 33.071C32.9318 33.187 32.9054 33.3052 32.8406 33.4029C32.7638 33.4713 32.6682 33.5153 32.5663 33.529C31.0248 33.8838 29.4815 34.2294 27.9363 34.5658C27.6274 34.6393 27.3103 34.6734 26.9928 34.6673C26.9081 34.6672 26.8245 34.6475 26.7487 34.6097C26.6596 34.5515 26.5893 34.4687 26.5465 34.3713C26.5036 34.2739 26.49 34.1661 26.5073 34.0611C26.5432 33.8551 26.6258 33.6599 26.7487 33.4906C27.7591 31.9293 29.3452 30.8302 31.1619 30.4323L31.1756 30.4406Z"
            fill="black"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.6133 38.5266C42.5144 38.2817 43.4554 38.2193 44.381 38.3432C45.3065 38.4671 46.198 38.7747 47.003 39.2479"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.8828 39.7031C41.1678 40.2415 41.5687 40.71 42.0568 41.0745"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M44.3177 38.6589C44.3677 38.9072 44.3583 39.1638 44.2903 39.4077C44.2562 39.5288 44.1958 39.6407 44.1133 39.7356C44.0307 39.8305 43.9282 39.9058 43.813 39.9563C43.6966 40.0057 43.5673 40.0164 43.4444 39.9868C43.3214 39.9572 43.2112 39.8888 43.1301 39.7917C43.0552 39.6809 43.0073 39.5541 42.9902 39.4214C42.9377 39.1681 42.9192 38.9089 42.9353 38.6507C42.9561 38.9294 43.0405 39.1997 43.1822 39.4406C43.2185 39.5098 43.2701 39.5699 43.333 39.6162C43.3784 39.6357 43.4272 39.646 43.4766 39.6466C43.5259 39.6472 43.575 39.6381 43.6208 39.6198C43.6667 39.6014 43.7085 39.5742 43.7438 39.5397C43.7791 39.5052 43.8073 39.4641 43.8267 39.4187V39.4187C43.8926 39.2267 43.8926 39.0182 43.8267 38.8262C43.8091 38.7347 43.7727 38.6479 43.7198 38.5711C43.6923 38.5336 43.6549 38.5044 43.6118 38.487C43.5687 38.4695 43.5215 38.4645 43.4757 38.4724C43.333 38.5108 43.2891 38.6918 43.2974 38.8399C43.3101 39.0481 43.3721 39.2502 43.4784 39.4297C43.5854 39.0923 43.5031 38.7138 44.3177 38.6589Z"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.4733 30.7031C39.2405 31.4905 39.1331 32.3097 39.1552 33.1306"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.789 30.4401C41.4928 30.3781 41.184 30.4278 40.9222 30.5797C40.6604 30.7316 40.4641 30.975 40.371 31.263C40.1872 31.8492 40.0802 32.4568 40.0528 33.0705C40.034 33.1866 40.0605 33.3054 40.1268 33.4024C40.203 33.4719 40.2988 33.516 40.4011 33.5286C41.9426 33.8833 43.4859 34.2289 45.0311 34.5654C45.3391 34.6388 45.6552 34.6729 45.9719 34.6669C46.1005 34.6706 46.2253 34.6234 46.3193 34.5356C46.4133 34.4478 46.4688 34.3264 46.4738 34.1978C46.4735 34.1554 46.4689 34.1132 46.4601 34.0717C46.4221 33.8657 46.3387 33.6708 46.216 33.5012C45.2066 31.94 43.6214 30.8408 41.8055 30.4429L41.789 30.4401Z"
            fill="black"
            stroke="black"
            strokeWidth="0.552381"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.6437 20.0884C43.6437 20.0884 38.7065 29.0713 30.6014 28.797L31.7424 26.7454C31.7424 26.7454 25.7603 32.0912 24.5095 32.5383L24.2627 31.4411C24.2627 31.4411 22.0108 37.5385 22.1314 40.7668L20.5488 31.9211C19.803 31.0708 19.3181 30.0234 19.1522 28.9046C18.9863 27.7857 19.1465 26.6427 19.6135 25.6126L18.6562 26.0679C18.6562 26.0679 20.494 14.9127 27.8229 13.6126C30.9482 13.1024 34.1357 13.1024 37.261 13.6126C37.261 13.6126 33.613 11.7419 32.5982 11.8791C32.5982 11.8791 40.9831 10.4062 44.8204 15.1705C44.8204 15.1705 52.6649 17.7762 54.3545 23.7035L52.9145 22.8313C52.9145 22.8313 54.4176 25.7963 53.7785 29.2167C53.1394 32.637 52.2233 33.1472 52.2233 33.1472C52.2233 33.1472 51.8201 39.8836 51.5212 39.0663C51.2222 38.2489 48.0624 30.8596 48.0624 30.8596C48.0624 30.8596 44.5543 28.8382 43.6437 20.0884Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="72"
              height="72"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);

PersonaAvatarEight.propTypes = {
  opaque: PropTypes.bool,
};
PersonaAvatarEight.defaultProps = {
  opaque: false,
};
