import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { VARIATION_RATING_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation DeleteVariationRatings($projectId: ID!, $generationTool: GenerationToolEnum!) {
    deleteVariationRatings(projectId: $projectId, generationTool: $generationTool) {
      ...VariationRating
    }
  }
  ${VARIATION_RATING_FRAGMENT}
`;

export const deleteVariationRatings = ({ projectId, generationTool }) => {
  const variables = { projectId, generationTool };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
