import { useEffect } from 'react';

import { isAbortError } from 'src/lib/errors';
import { useProjectFolderActions, useCurrentWorkspaceId, useNotificationActions } from 'src/store';

/**
 * This hook is used to maintain the "project folder" store state.
 */
export const useMaintainProjectFolderStoreState = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const projectFolderActions = useProjectFolderActions();
  const notifications = useNotificationActions();

  useEffect(() => {
    if (!currentWorkspaceId) {
      return;
    }

    // Create an abort controller, in case user selects another workspace before the fetch completes
    const abortController = new AbortController();

    // Fetch project folders for the current workspace
    projectFolderActions
      .queryProjectFolders(
        { workspaceId: currentWorkspaceId },
        { abortSignal: abortController.signal }
      )
      .catch((error) => {
        if (isAbortError(error)) {
          return;
        }

        // Display notification to user
        notifications.displayWarning(
          'Network error',
          'Error fetching project folders. Please try reloading the page.'
        );
      });

    // Clear the store when the workspace changes
    return () => {
      abortController.abort();
      projectFolderActions.clearStore();
    };
  }, [currentWorkspaceId, projectFolderActions, notifications]);
};
