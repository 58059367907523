import React from 'react';

export default (Wrapper, wrapperProps) => (Component) => {
  const wrapWith = (props) => (
    <Wrapper {...props} {...wrapperProps}>
      <Component {...props} />
    </Wrapper>
  );

  wrapWith.displayName = `WrapWith(${Wrapper.displayName || Wrapper.name})`;

  return wrapWith;
};
