const INTEGRATION_CHANNEL = [
  'facebook_pages',
  'linkedin',
  'facebook',
  'google',
  'hubspot',
  'instagram',
];
const RESOURCE_CHANNEL = [
  'landing_page',
  'home_page',
  'product_page',
  'competitor',
  'thought_leadership',
  'amazon_reviews',
  'trustpilot',
  'other',
];

export const getIntegrationFromChannel = (channel) => {
  if (channel) {
    for (const src of INTEGRATION_CHANNEL) {
      if (channel.startsWith(src)) {
        return src;
      }
    }
  }
  return null;
};

export const getResourceFromChannel = (channel) => {
  if (channel) {
    for (const src of RESOURCE_CHANNEL) {
      if (channel.startsWith(src)) {
        return src;
      }
    }
  }
  return null;
};
