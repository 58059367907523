import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BlogIdeas = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
    <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
    <path
      d="M15.9272 8.9707H8V22.9599C8 22.9599 15.4724 22.9599 21.212 22.9599V18.5558"
      stroke="white"
      strokeWidth="0.897436"
    />
    <path
      d="M10.6445 13.6328H14.1713"
      stroke="white"
      strokeWidth="0.897436"
      strokeLinejoin="round"
    />
    <path
      d="M10.6445 16.4316H17.4041"
      stroke="white"
      strokeWidth="0.897436"
      strokeLinejoin="round"
    />
    <path
      d="M10.6445 19.2285H17.4041"
      stroke="white"
      strokeWidth="0.897436"
      strokeLinejoin="round"
    />
    <path
      d="M19.9587 6.52475L19.9587 6.52474C18.7477 6.78377 17.7476 7.81052 17.4921 9.07825C17.2303 10.3897 17.7074 11.6792 18.715 12.4658C19.0231 12.7031 19.2509 13.0334 19.3832 13.4067H21.9196C22.0638 13.0286 22.3163 12.6906 22.647 12.4235C23.4297 11.7834 23.8849 10.8281 23.8849 9.79188C23.8849 8.77323 23.4474 7.82764 22.6842 7.18877L19.9587 6.52475ZM19.9587 6.52475L19.9612 6.5242M19.9587 6.52475L19.9612 6.5242M19.9612 6.5242C20.9344 6.31031 21.9281 6.55697 22.684 7.18857L19.9612 6.5242Z"
      stroke="white"
      strokeWidth="0.897436"
    />
    <path
      d="M19.332 15.5928H22.2146"
      stroke="white"
      strokeWidth="0.897436"
      strokeLinecap="round"
    />
    <path
      d="M19.6914 17.0332L21.8534 17.0332"
      stroke="white"
      strokeWidth="0.897436"
      strokeLinecap="round"
    />
  </SvgIcon>
));

export default React.memo(BlogIdeas);
