import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';

import { getBlockedReasonCopy, BLOCKED_REASON } from 'src/data/common';
import { useStyles } from './useStyles';

const HIGHLIGHTED_TEXT = 'Data-Driven';

export const BlockedScoreTooltip = ({
  className,
  blockedReason,
  open,
  onOpen,
  onClose,
  nonBlockedTitle,
  children,
  placement,
}) => {
  const styles = useStyles();

  const blockedReasonCopy = getBlockedReasonCopy(blockedReason);

  const addClassToHighlightedText = (str) => {
    const splitString = str.split(HIGHLIGHTED_TEXT);
    return [splitString[0], HIGHLIGHTED_TEXT, splitString[1]];
  };

  return (
    <Tooltip
      classes={{
        tooltip: cx(styles.root, className),
        tooltipPlacementBottom: styles.tooltipBottom,
      }}
      title={
        !blockedReason || blockedReason === BLOCKED_REASON.limitedScoring ? (
          nonBlockedTitle || ''
        ) : (
          <span className={styles.tooltipText}>
            {blockedReasonCopy.includes(HIGHLIGHTED_TEXT)
              ? addClassToHighlightedText(blockedReasonCopy).map((str, i) => (
                  <span
                    className={i === 1 ? styles.ctaText : null}
                    key={str}
                  >
                    {str}
                  </span>
                ))
              : blockedReasonCopy}
          </span>
        )
      }
      open={open != null ? open : undefined}
      onOpen={onOpen || undefined}
      onClose={onClose || undefined}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};

BlockedScoreTooltip.propTypes = {
  className: PropTypes.string,
  blockedReason: PropTypes.oneOf(Object.values(BLOCKED_REASON)),
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  nonBlockedTitle: PropTypes.node,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
};

BlockedScoreTooltip.defaultProps = {
  className: null,
  blockedReason: null,
  open: null,
  onOpen: null,
  onClose: null,
  nonBlockedTitle: null,
  placement: 'bottom',
};
