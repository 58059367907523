import {
  GENERATION_TOOL,
  isBasicGenerationTool,
  isDataDrivenGenerationTool,
  isSocialGenerationTool,
  isRewriteGenerationTool,
  doesToolSupportPromptInstruction,
} from 'src/data/generationTool';

export const getBriefFormTitle = (generationTool) => {
  if (isRewriteGenerationTool(generationTool)) {
    return null;
  }

  switch (generationTool) {
    case GENERATION_TOOL.summarizer:
    case GENERATION_TOOL.articleBlogHeadline:
    case GENERATION_TOOL.basicContentImprover:
      return null;

    default:
      break;
  }

  if (doesToolSupportPromptInstruction(generationTool)) {
    return 'Prompt';
  }

  if (
    isBasicGenerationTool(generationTool) ||
    (isDataDrivenGenerationTool(generationTool) && !isSocialGenerationTool(generationTool))
  ) {
    return 'Brief';
  }

  return null;
};
