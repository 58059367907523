import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core';

import {
  ACCOUNT_FIELD,
  getIsEmailChannel,
  getResourceDocumentLabel,
  isEmailCsvResourceDocument,
} from 'src/data/performance';
import { getChannelLabel } from 'src/data/channel';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { CustomButton, CUSTOM_BUTTON_SIZE } from 'src/components/CustomButton';
import { useStyles } from './useStyles';

const getEmailChannelLabel = (channel) =>
  getChannelLabel(channel) || getResourceDocumentLabel(channel);

export const AccountRow = ({
  account,
  onAccountClick,
  featureLimitedLabel,
  isConnected,
  hideIdField,
}) => {
  const styles = useStyles();

  const isEmailChannel = getIsEmailChannel(account.channel);

  const isEmailCsvResource = isEmailCsvResourceDocument(account.channel);

  return (
    <TableRow
      key={account.id}
      className={styles.root}
      tabIndex={-1}
    >
      <TableCell className={styles.cell}>
        <CustomTypography variant={TYPOGRAPHY_VARIANT.regular14}>
          {account[ACCOUNT_FIELD.name]?.replace('(.csv)', '')}
          {isEmailChannel && (
            <span className={styles.emailChannelLabel}>
              {' '}
              ({getEmailChannelLabel(account.channel)})
            </span>
          )}
          {featureLimitedLabel ? (
            <span className={styles.featureLimitedLabel}>{featureLimitedLabel}</span>
          ) : null}
        </CustomTypography>
      </TableCell>

      {!hideIdField && (
        <TableCell className={styles.cell}>
          <TruncatedTypography
            variant={TYPOGRAPHY_VARIANT.regular14}
            className={styles.accountId}
          >
            {!isEmailCsvResource && account[ACCOUNT_FIELD.id]}
          </TruncatedTypography>
        </TableCell>
      )}

      <TableCell className={cx(styles.cell, styles.buttonCell)}>
        <CustomButton
          className={styles.actionButton}
          onClick={() => onAccountClick(account, isConnected)}
          size={CUSTOM_BUTTON_SIZE.small}
        >
          Select
        </CustomButton>
      </TableCell>
    </TableRow>
  );
};

AccountRow.propTypes = {
  account: PropTypes.shape(),
  onAccountClick: PropTypes.func,
  featureLimitedLabel: PropTypes.string,
  isConnected: PropTypes.bool,
  hideIdField: PropTypes.bool,
};

AccountRow.defaultProps = {
  account: null,
  onAccountClick: null,
  featureLimitedLabel: null,
  isConnected: false,
  hideIdField: false,
};
