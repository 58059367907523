import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_TARGET_AUDIENCE_INSIGHTS } from 'src/graphql/schema';

const MUTATION = gql`
  mutation ExtractTargetAudienceInsights(
    $projectId: ID!
    $generationTool: GenerationToolEnum!
    $text: String
    $variationId: ID
    $brandVoiceId: ID
    $scoringMethod: ScoringMethodInput
  ) {
    extractTargetAudienceInsights(
      projectId: $projectId
      generationTool: $generationTool
      text: $text
      variationId: $variationId
      brandVoiceId: $brandVoiceId
      scoringMethod: $scoringMethod
    ) {
      ...TargetAudienceInsights
    }
  }
  ${FRAGMENT_TARGET_AUDIENCE_INSIGHTS}
`;

export const extractTargetAudienceInsights = async (
  { projectId, generationTool, text, variationId, scoringMethod },
  options
) => {
  const variables = {
    projectId,
    generationTool,
    text,
    variationId,
    scoringMethod,
  };

  const result = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  return getSingleProp(result.data);
};
