import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { ASSET_TYPE } from 'src/data/asset';
import { serializeVariationSlateContent } from 'src/data/variation';
import { countWords } from 'src/lib/string';
import useStyles from './useStyles';

export const TextAndWordCounter = memo(
  ({ className, classes, count, text, hint, commaSeparators, assetType, hideWords }) => {
    const styles = useStyles({ classes });

    const countFormatted = useMemo(() => {
      if (commaSeparators) return count.toLocaleString();

      return count;
    }, [count, commaSeparators]);

    const areThereWords = !!text;
    const wordCount = areThereWords
      ? typeof text === 'string'
        ? countWords(text)
        : countWords(serializeVariationSlateContent(assetType, text).text)
      : null;
    const showWords = !hideWords && areThereWords;

    return (
      <Tooltip title={hint || ''}>
        <Typography
          className={cx(styles.root, className)}
          variant="body2"
          component="span"
        >
          {countFormatted} characters{showWords ? ` | ${wordCount} words` : ``}
        </Typography>
      </Tooltip>
    );
  }
);

TextAndWordCounter.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  count: PropTypes.number,
  minCount: PropTypes.number,
  hint: PropTypes.string,
  strict: PropTypes.bool,
  suffix: PropTypes.string,
  commaSeparators: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.shape({}))]),
  assetType: PropTypes.oneOf(Object.values(ASSET_TYPE)),
  hideWords: PropTypes.bool,
};
TextAndWordCounter.defaultProps = {
  className: null,
  classes: null,
  count: 0,
  minCount: 0,
  hint: null,
  strict: false,
  suffix: '',
  commaSeparators: false,
  text: '',
  assetType: null,
  hideWords: false,
};
