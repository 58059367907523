import React, { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const PersonaAvatarNine = memo(
  forwardRef(({ opaque, ...props }, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 72 72"
        data-fill-none
      >
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M72 35.993C72.0024 42.6788 70.1414 49.2329 66.6258 54.9199C63.1102 60.607 58.0791 65.2019 52.0972 68.1892C51.0213 68.7294 49.9186 69.2143 48.7933 69.6422C41.6166 72.3704 33.7554 72.7328 26.358 70.6767C18.9606 68.6205 12.4144 64.2534 7.67547 58.2132C2.93652 52.1729 0.253074 44.776 0.0170252 37.1025C-0.219023 29.4291 2.00469 21.8812 6.36341 15.5612C10.7221 9.24115 16.9875 4.48003 24.2446 1.97317C31.5016 -0.53369 39.3702 -0.654973 46.701 1.62703C54.0319 3.90904 60.4411 8.4748 64.9926 14.6575C69.5441 20.8402 71.9994 28.316 72 35.993Z"
            fill="#8CB2FF"
          />
          <path
            d="M52.0973 68.1894C51.0215 68.7297 49.9187 69.2147 48.7934 69.6426C43.9711 71.477 38.8104 72.2517 33.662 71.9139C28.5136 71.5761 23.4983 70.1338 18.957 67.6849L22.2691 66.1797L46.9153 66.9392L52.0973 68.1894Z"
            fill="#DEE6F7"
          />
          <path
            d="M67.5383 35.996C67.5383 42.2315 65.689 48.327 62.2244 53.5116C58.7597 58.6962 53.8353 62.7372 48.0737 65.1234C42.3122 67.5096 35.9724 68.1339 29.8561 66.9175C23.7397 65.701 18.1214 62.6983 13.7117 58.2891C9.30208 53.88 6.29906 48.2623 5.08243 42.1467C3.86581 36.031 4.49022 29.6919 6.87672 23.931C9.26321 18.1702 13.3046 13.2463 18.4898 9.78205C23.675 6.31779 29.7712 4.46875 36.0074 4.46875C44.3699 4.46875 52.3899 7.79036 58.3031 13.7029C64.2163 19.6154 67.5383 27.6344 67.5383 35.996Z"
            fill="white"
          />
          <path
            d="M34.9932 14.5261C38.2176 14.3287 40.1752 14.2273 45.0036 17.5609C49.8319 20.8946 51.9129 24.9164 52.4969 28.1733C53.0809 31.4302 54.1256 43.3941 54.1256 43.3941C54.1256 43.3941 55.3758 48.581 55.3347 49.4638C55.1707 51.7697 54.1612 53.934 52.4997 55.5417C50.1088 57.7869 48.4308 59.4346 48.4308 59.4346L48.1347 60.3722L48.4857 63.2864L47.7618 64.4543L47.6247 65.093L48.4802 66.3815L48.206 67.5906L41.8998 69.9126L33.5729 70.672L27.678 69.866L23.9245 68.742C23.9245 68.742 22.2794 67.4206 22.2794 66.1732C22.2794 64.9258 22.8936 62.7545 23.0334 61.8745C23.1732 60.9945 23.1211 57.3346 22.7346 57.1646C22.348 56.9947 17.1221 52.9756 16.8013 49.8668C16.4805 46.7579 16.9329 44.5345 17.558 41.5957C18.1831 38.6568 19.1784 31.178 20.0256 28.7819C20.8729 26.3858 23.3707 19.236 29.4301 16.3739C35.4895 13.5117 34.9932 14.5261 34.9932 14.5261Z"
            fill="#43C493"
          />
          <path
            d="M28.9511 21.358C31.5476 19.5705 32.584 19.0332 35.4629 19.9434C38.3418 20.8536 46.7044 27.3728 48.0067 31.1479C49.3091 34.9229 49.2789 37.4067 49.4873 41.593C49.6957 45.7793 48.9581 50.9881 45.2238 54.8125C41.4894 58.6369 39.5948 60.0872 35.3533 59.8624C31.1117 59.6376 26.9578 54.7495 26.9578 54.7495C26.9578 54.7495 22.634 49.4118 21.9513 42.558C21.2686 35.7043 21.8992 33.0807 22.9959 29.3796C24.0926 25.6786 28.9511 21.358 28.9511 21.358Z"
            fill="#8CB2FF"
          />
          <path
            d="M47.6757 51.1592C44.9339 56.1707 40.7609 59.932 36.3493 59.932C28.5077 59.932 22.1138 50.6493 21.4805 38"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.6758 31.4375L32.5195 33.0168"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.6214 31.7503C31.6736 32.115 31.6624 32.486 31.5885 32.847C31.5745 32.9478 31.5328 33.0428 31.4679 33.1212C31.3633 33.212 31.2307 33.2643 31.0922 33.2693C28.7069 33.6202 26.1789 33.4776 24.0156 34.5387C26.1309 32.7211 28.8326 31.7307 31.6214 31.7503Z"
            fill="black"
          />
          <path
            d="M34.837 36.6875C35.1081 37.9 35.0484 39.1631 34.6643 40.3447C34.281 41.5185 33.8278 42.6683 33.3071 43.788C33.1062 44.154 33.0301 44.5756 33.0905 44.9888C33.2176 45.3597 33.48 45.6691 33.8253 45.8551C34.2833 46.1401 34.7972 46.3233 35.3323 46.3923C35.8673 46.4614 36.4109 46.4147 36.9263 46.2553"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.6328 49.572C34.64 50.1324 35.7747 50.4238 36.9273 50.4181C38.0799 50.4123 39.2116 50.1096 40.2132 49.5391"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.0625 48.7188C40.4065 49.0838 40.6104 49.5587 40.6383 50.0595"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.7773 52.7317C36.2697 52.9127 36.795 52.9866 37.3182 52.9483C37.8471 52.9095 38.3395 52.6643 38.6891 52.2656"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.988 58.1969C21.9636 56.8729 20.1774 55.2166 18.7045 53.2979C17.5804 51.8367 16.6207 50.1534 16.4946 48.3166C16.4096 47.0445 16.7249 45.7834 16.9936 44.5361C17.8929 40.3772 18.285 36.1251 19.1871 31.969C20.0891 27.8129 21.5669 23.6705 24.3252 20.4328C26.0409 18.4508 28.1762 16.8754 30.5763 15.8208C32.9764 14.7663 35.5811 14.259 38.2015 14.3357C44.1759 16.0436 49.6075 20.2628 51.8228 26.0693C53.5063 30.4859 53.2486 35.363 53.6818 40.0702C53.8956 42.4063 54.2852 44.723 54.8471 47.0007C55.1213 48.0973 55.4338 49.2322 55.2775 50.3563C55.1348 51.1626 54.8468 51.9364 54.4276 52.6399C52.6534 55.8882 49.9992 58.5706 46.7697 60.3792"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.3014 49.4064C46.7633 54.3548 43.421 58.8892 38.7215 61.0742C37.4 61.6883 35.9578 62.116 34.5046 62.0447C33.4008 61.9689 32.3251 61.6635 31.346 61.1482C29.0703 59.9968 27.2882 58.0339 25.9556 55.8599C24.6231 53.6859 23.7101 51.2898 22.8026 48.9074C21.2347 43.581 21.0255 37.9468 22.1939 32.5188C22.7228 30.0626 23.5209 27.6722 24.5738 25.3909C25.3161 23.7899 26.445 22.3986 27.8589 21.3424C29.2727 20.2862 30.9271 19.5982 32.6731 19.3404C33.7222 19.192 34.7874 19.1994 35.8344 19.3623H35.9304C39.1904 19.9106 42.3846 21.3115 44.6192 23.7487C46.7249 26.0379 47.8298 29.0316 48.4988 32.0938C48.6387 32.7299 48.7593 33.3686 48.8662 34.0129C49.7162 39.1258 49.8368 44.458 48.3014 49.4064Z"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.9164 57.4688C22.623 59.0917 23.3468 60.7805 22.9876 62.387C22.8314 63.0888 22.4749 63.7331 22.2885 64.4267C22.1208 65.0866 22.1078 65.7762 22.2505 66.442C22.3932 67.1077 22.6877 67.7315 23.111 68.2648"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.1551 59.6094L48.5691 62.7374C48.6222 63.0003 48.6175 63.2716 48.5554 63.5325C48.3854 64.0479 47.7767 64.3549 47.7164 64.9032C47.6451 65.5283 48.347 65.9998 48.421 66.6139C48.5115 67.3678 47.6945 67.8832 46.9898 68.1711C39.3429 71.2854 30.7062 71.4993 23.0977 68.2862"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.0234 57.9219C23.3168 60.1644 24.5232 62.2288 26.1573 63.7942C27.7914 65.3596 29.8231 66.4589 31.9426 67.243C34.5582 68.2135 37.3796 68.7344 40.1488 68.4054C42.918 68.0764 45.8874 67.0045 47.7162 64.899"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.3438 15.1406C38.0789 15.5518 42.1532 18.5839 45.6463 21.8052C46.9788 23.0334 48.2839 24.3246 49.2408 25.8626C50.0845 27.2979 50.7301 28.8407 51.16 30.4492C51.6623 32.1605 52.0642 33.8998 52.3637 35.658C52.7613 38.0075 52.9943 40.4447 54.0883 42.5638"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M27.8245 41.0356C28.2864 41.0356 28.6608 40.2266 28.6608 39.2287C28.6608 38.2308 28.2864 37.4219 27.8245 37.4219C27.3627 37.4219 26.9883 38.2308 26.9883 39.2287C26.9883 40.2266 27.3627 41.0356 27.8245 41.0356Z"
            fill="black"
          />
          <path
            d="M27.8194 37.4196C27.8194 37.4196 26.2072 37.6115 25.4258 37.1016C25.4258 37.1016 26.7967 38.4094 27.6357 38.4396C28.4747 38.4697 27.8194 37.4196 27.8194 37.4196Z"
            fill="black"
          />
          <path
            d="M37.2305 31.4375L37.3867 33.0168"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M38.2979 31.7503C38.2457 32.115 38.2569 32.486 38.3308 32.847C38.3448 32.9478 38.3866 33.0428 38.4514 33.1212C38.556 33.212 38.6886 33.2643 38.8271 33.2693C41.2124 33.6202 43.7404 33.4776 45.9037 34.5387C43.7884 32.7211 41.0867 31.7307 38.2979 31.7503Z"
            fill="black"
          />
          <path
            d="M42.0823 41.0356C42.5442 41.0356 42.9186 40.2266 42.9186 39.2287C42.9186 38.2308 42.5442 37.4219 42.0823 37.4219C41.6205 37.4219 41.2461 38.2308 41.2461 39.2287C41.2461 40.2266 41.6205 41.0356 42.0823 41.0356Z"
            fill="black"
          />
          <path
            d="M42.0834 37.4196C42.0834 37.4196 43.6956 37.6115 44.477 37.1016C44.477 37.1016 43.1061 38.4094 42.2671 38.4396C41.4281 38.4697 42.0834 37.4196 42.0834 37.4196Z"
            fill="black"
          />
          <path
            d="M33.3872 39.2969C33.3872 42.7927 30.7742 45.6277 27.5471 45.6277C24.32 45.6277 21.707 42.7927 21.707 39.2969H33.3872Z"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M37.375 39.2969C37.375 42.7927 39.9907 45.6277 43.2151 45.6277C46.4394 45.6277 49.0551 42.7927 49.0551 39.2969H37.375Z"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.1562 41.6023C33.1562 41.6023 35.5334 39.4335 37.6665 41.2459"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.5015 32.1068C41.8883 30.5716 35.615 23.3478 33.4682 20.4993C33.1673 20.1402 32.902 19.7527 32.6758 19.3424C32.8156 18.9915 33.7506 18.9833 33.7177 19.2465C34.4263 19.2116 35.1367 19.2511 35.8371 19.3644H35.9331C39.1931 19.9127 42.3873 21.3136 44.6219 23.7508C46.7276 26.0509 47.8325 29.0446 48.5015 32.1068Z"
            fill="white"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.7164 19.2465C33.6972 19.4164 33.6698 19.5946 33.6342 19.7783C33.5875 20.0223 33.53 20.2608 33.4669 20.4993C31.597 27.4463 22.9246 32.1233 22.1953 32.5071C22.7243 30.0553 23.5224 27.6695 24.5752 25.3929C25.3175 23.7919 26.4464 22.4007 27.8603 21.3444C29.2742 20.2882 30.9286 19.6002 32.6745 19.3424C32.8144 18.9915 33.7493 18.9833 33.7164 19.2465Z"
            fill="white"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.1953 67.5225C19.2858 67.5225 21.9974 66.2969 21.9974 66.2969"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.4453 67.3047L51.5024 68.0422"
            stroke="black"
            strokeWidth="0.548363"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="72"
              height="72"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);

PersonaAvatarNine.propTypes = {
  opaque: PropTypes.bool,
};
PersonaAvatarNine.defaultProps = {
  opaque: false,
};
