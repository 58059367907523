class AlmNamespace {
  constructor() {
    this.check();
  }

  check() {
    if (!window.alm) {
      window.alm = {};
    }
  }

  add(name, entity) {
    this.check();
    if (!window.alm[name]) {
      window.alm[name] = entity;
    }
  }
}

export const almNamespace = new AlmNamespace();
