import { uniqueId } from 'lodash';

import { contentTypeFromBriefType } from 'src/data/brief';
import { CONTENT_TYPE } from 'src/data/contentType';
import { GENERATION_GUIDANCE_TYPE } from 'src/data/generation/const';
import { getToolMaxWordsPerKeyword } from 'src/data/generationTool';
import { countWords } from 'src/lib/string';
import { TEXT_ANNOTATION_TYPE } from './const';
import { SUGGESTED_ANNOTATIONS_ORIGIN } from 'src/data/performance';

export const EMPTY_ANNOTATIONS = Object.values(TEXT_ANNOTATION_TYPE).map((type) => ({
  type,
  value: null,
}));

const notEmpty = ({ value }) => Array.isArray(value) && value.length > 0;
export const annotationsMapToArray = (annotationsObj) => {
  if (!annotationsObj) {
    return [];
  }

  // Display only supported types in correct order
  const array = Object.values(TEXT_ANNOTATION_TYPE).map((type) => ({
    type,
    value: annotationsObj[type],
  }));

  // Display only non-empty values
  return array.filter(notEmpty);
};

const withoutMainOffering = (entry) => entry.type !== TEXT_ANNOTATION_TYPE.mainOfferings;

export const filterAnnotations = (annotations, { briefType }) => {
  // For "content" briefs we should not display "main offering"
  if (contentTypeFromBriefType(briefType) === CONTENT_TYPE.content) {
    return annotations.filter(withoutMainOffering);
  }
  return annotations;
};

export const prepareAnnotations = (annotationMap, loading, { briefType }) => {
  const annotations = annotationsMapToArray(annotationMap || {});
  const arrayToFilter = loading ? EMPTY_ANNOTATIONS : annotations;
  return filterAnnotations(arrayToFilter, {
    briefType,
  });
};

const FIELD_TO_TYPE_MAP = {
  features: GENERATION_GUIDANCE_TYPE.feature,
  benefits: GENERATION_GUIDANCE_TYPE.benefit,
  pains: GENERATION_GUIDANCE_TYPE.pain,
  keywords: GENERATION_GUIDANCE_TYPE.keyword,
  homePage: GENERATION_GUIDANCE_TYPE.home_page,
  landingPage: GENERATION_GUIDANCE_TYPE.landing_page,
  competitors: GENERATION_GUIDANCE_TYPE.competitors,
  thoughtLeadership: GENERATION_GUIDANCE_TYPE.thoughtLeadership,
  productPage: GENERATION_GUIDANCE_TYPE.productPage,
  other: GENERATION_GUIDANCE_TYPE.other,
  reviewsAmazon: GENERATION_GUIDANCE_TYPE.amazon,
  reviewsTrustpilot: GENERATION_GUIDANCE_TYPE.trustpilot,
  hubspotEmails: GENERATION_GUIDANCE_TYPE.hubspot,
  google: GENERATION_GUIDANCE_TYPE.google,
  facebook: GENERATION_GUIDANCE_TYPE.facebook,
  linkedin: GENERATION_GUIDANCE_TYPE.linkedin,
  facebookPages: GENERATION_GUIDANCE_TYPE.facebookPages,
  instagramAccount: GENERATION_GUIDANCE_TYPE.instagram,
  linkedinSocial: GENERATION_GUIDANCE_TYPE.linkedinSocial,
  genericResource: GENERATION_GUIDANCE_TYPE.genericResource,
};

const createExtractedAnnotationMapper =
  (data) =>
  ({ name, score, rating, talkingPoint }) => ({
    id: uniqueId(data.type),
    type: data.type,
    content: name || talkingPoint,
    score: score || rating,
    accountId: data.accountId,
    accountName: data.name,
    accountUrl: data.accountUrl,
    dataSource: data.dataSource,
    origin: data.origin,
    talkingPointsViewId: data.talkingPointsViewId,
  });

export const convertExtractedAnnotations = (queryResult) => {
  return Object.entries(FIELD_TO_TYPE_MAP)
    .map(([field, annotationType]) => {
      const result = queryResult?.[field] || [];
      return result.map(
        createExtractedAnnotationMapper({
          type: annotationType,
          origin: SUGGESTED_ANNOTATIONS_ORIGIN.anyword,
        })
      );
    })
    .reduce((acc, items) => [...acc, ...items], []);
};

export const convertExtractedPerformanceCenterAnnotations = (tpView) => {
  return {
    ...tpView,
    talkingPoints: tpView.talkingPoints?.map(
      createExtractedAnnotationMapper({
        dataSource: tpView.dataSource,
        accountId: tpView?.id,
        accountName: tpView?.name,
        accountUrl: tpView?.accountUrl,
        talkingPointsViewId: tpView?.id,
      })
    ),
  };
};

export const filterAnnotationsByTool = ({ annotations, generationTool }) => {
  const toolMaxWordsPerKeyword = getToolMaxWordsPerKeyword(generationTool);
  return annotations.filter(({ name }) => countWords(name) <= toolMaxWordsPerKeyword);
};

export const isNotTalkingPointAnnotation = ({ annotations }) => {
  return annotations.filter(
    (annotation) =>
      ![
        GENERATION_GUIDANCE_TYPE.feature,
        GENERATION_GUIDANCE_TYPE.google,
        GENERATION_GUIDANCE_TYPE.facebook,
        GENERATION_GUIDANCE_TYPE.linkedin,
        GENERATION_GUIDANCE_TYPE.keyword,
        GENERATION_GUIDANCE_TYPE.amazon,
        GENERATION_GUIDANCE_TYPE.trustpilot,
        GENERATION_GUIDANCE_TYPE.home_page,
        GENERATION_GUIDANCE_TYPE.landing_page,
        GENERATION_GUIDANCE_TYPE.competitors,
        GENERATION_GUIDANCE_TYPE.thoughtLeadership,
        GENERATION_GUIDANCE_TYPE.productPage,
        GENERATION_GUIDANCE_TYPE.other,
        GENERATION_GUIDANCE_TYPE.hubspot,
        GENERATION_GUIDANCE_TYPE.facebookPages,
        GENERATION_GUIDANCE_TYPE.instagram,
        GENERATION_GUIDANCE_TYPE.linkedinSocial,
        GENERATION_GUIDANCE_TYPE.genericResource,
      ].includes(annotation.type)
  );
};
