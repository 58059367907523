import { useMemo } from 'react';

import { LANGUAGE, HIDDEN_LANGUAGE, getLanguageLabel } from 'src/data/generation';
import { useCustomerHebrewSupportFeatureEnabled } from 'src/store';

export const useAvailableLanguages = () => {
  const hebrewSupportEnabled = useCustomerHebrewSupportFeatureEnabled();

  return useMemo(() => {
    const result = Object.values(LANGUAGE);

    if (hebrewSupportEnabled) {
      result.push(HIDDEN_LANGUAGE.he);
    }

    const preparedLanguages = result.map((language) => ({
      value: language,
      label: getLanguageLabel(language),
    }));
    const sortedLanguages = preparedLanguages.sort((a, b) => a.label.localeCompare(b.label));

    return sortedLanguages;
  }, [hebrewSupportEnabled]);
};
