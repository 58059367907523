export const SLATE_NODE_TYPES = {
  BLOCK: 'block',
  INLINE: 'inline',
  MARK: 'mark',
};

export const SLATE_TEXT_FORMATS = {
  PARAGRAPH: 'paragraph',
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  STRIKETHROUGH: 'strikethrough',
  ALIGN_LEFT: 'left',
  ALIGN_CENTER: 'center',
  ALIGN_RIGHT: 'right',
  HEADING_ONE: 'heading-one',
  HEADING_TWO: 'heading-two',
  HEADING_THREE: 'heading-three',
  HEADING_FOUR: 'heading-four',
  HEADING_FIVE: 'heading-five',
  HEADING_SIX: 'heading-six',
  BLOCK_QUOTE: 'block-quote',
  BULLETED_LIST: 'bulleted-list',
  NUMBERED_LIST: 'numbered-list',
  LIST_ITEM: 'list-item',
  LINK: 'link',
  IMG: 'image',
  DD_VARIATION: 'dd-variation',
  PROMPT_TAG: 'prompt-tag',
  BANK_MESSAGE: 'bank-message',
  PRODUCT_DESCRIPTION: 'product-description',
  RESOURCE_URL: 'resource-url',
  PROMPT_IMAGE: 'prompt-image',
  PROMPT_DOCUMENT: 'prompt-document',
};

export const SLATE_EDITOR_ACTION = {
  // Inline formatting actions
  toggleBold: 'toggleBold',
  toggleItalic: 'toggleItalic',
  toggleUnderline: 'toggleUnderline',
  toggleStrikethrough: 'toggleStrikethrough',
  insertLink: 'insertLink',

  // Block formatting actions
  toggleParagraph: 'toggleParagraph',
  toggleH1: 'toggleH1',
  toggleH2: 'toggleH2',
  toggleH3: 'toggleH3',
  toggleBlockQuote: 'toggleBlockQuote',
  toggleBulletedList: 'toggleBulletedList',
  toggleNumberedList: 'toggleNumberedList',

  // Alignment actions
  alignTextLeft: 'alignTextLeft',
  alignTextCenter: 'alignTextCenter',
  alignTextRight: 'alignTextRight',

  // Other actions
  undo: 'undo',
  redo: 'redo',
  clearFormat: 'clearFormat',
};
export const SLATE_EDITOR_ACTIONS = Object.values(SLATE_EDITOR_ACTION);

export const DEFAULT_SLATE_VALUE = [
  {
    type: SLATE_TEXT_FORMATS.PARAGRAPH,
    children: [{ text: '' }],
  },
];
