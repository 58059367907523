import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
    },
    small: {},

    icon: {
      fontSize: theme.typography.pxToRem(10),

      '& + &': {
        marginLeft: theme.spacing(0.5),
      },
    },
  }),
  { name: 'AnnotationRating' }
);
