import Cookies from 'js-cookie';

import { getDomainUserId } from 'src/lib/snowplow';
import { readLandingPageCookie } from 'src/lib/cookies';

const FACEBOOK_FBC_COOKIE = '_fbc';
const FACEBOOK_FBP_COOKIE = '_fbp';

export const getTrackingPayload = () => {
  const landingCookieValue = readLandingPageCookie();

  const facebookCookieValues = {
    facebook_cookie_click_id_fbc: Cookies.getJSON(FACEBOOK_FBC_COOKIE),
    facebook_cookie_browser_id_fbp: Cookies.getJSON(FACEBOOK_FBP_COOKIE),
  };

  const snowplowCookieValues = {
    snowplow_cookie_domain_user_id: getDomainUserId(),
  };

  return {
    ...landingCookieValue,
    ...facebookCookieValues,
    ...snowplowCookieValues,
  };
};
