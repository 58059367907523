import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ExtractBlogInstructions(
    $sectionId: ID!
    $url: String!
    $headline: String
    $projectId: ID
  ) {
    extractBlogInstructions(
      sectionId: $sectionId
      url: $url
      headline: $headline
      projectId: $projectId
    ) {
      id
    }
  }
`;

export const extractBlogInstructionsBasedOnURL = (sectionId, url, headline, projectId) => {
  const variables = { sectionId, url, headline, projectId };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
