import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { CUSTOMER_PERSONA_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation ExtractCustomerPersona(
    $brandVoiceId: ID!
    $urls: [String!]
    $text: String
    $resourceId: ID
    $count: Int
  ) {
    extractCustomerPersona(
      brandVoiceId: $brandVoiceId
      urls: $urls
      text: $text
      resourceId: $resourceId
      count: $count
    ) {
      edges {
        node {
          ...CustomerPersona
        }
      }
    }
  }
  ${CUSTOMER_PERSONA_FRAGMENT}
`;

export const extractCustomerPersona = ({ brandVoiceId, urls, text, resourceId, count }) => {
  const variables = { brandVoiceId, urls, text, resourceId, count };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => {
      return unwrapEdges(result?.edges || []);
    });
};
