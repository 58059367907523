import React from 'react';
import cx from 'classnames';

import { AppLogo, APP_LOGO_VARIANT } from 'src/components/AppLogo';
import { useIsCustomerLocked } from 'src/services';
import { CreateContentItem } from './views/CreateContentItem';
import { FolderItems } from './views/FolderItems';
import { SidebarItems } from './views/SidebarItems';
import { WorkspaceItem } from './views/WorkspaceItem';
import { useStyles } from './useStyles';
import { SidebarLink } from './components/SidebarLink';
import { ITEM_TYPE } from './lib';
import { IconHome } from 'src/assets/icons/IconHome';
import { useActiveLocationPath, useSidebarEvents } from './service';
import { useHistory } from 'src/lib/hooks';

export const NavigationSidebarPanel = ({ className }) => {
  const styles = useStyles();

  const isCustomerLocked = useIsCustomerLocked();
  const { trackNavigationItemClicked } = useSidebarEvents();

  const activePath = useActiveLocationPath();
  const history = useHistory();

  const homeItem = {
    type: ITEM_TYPE.link,
    id: 'home',
    label: 'Home',
    path: '/dashboard',
    icon: IconHome,
  };

  return (
    <div
      className={cx(styles.root, className)}
      data-testid="navigationSidebarPanel"
    >
      <div className={styles.logoWrapper}>
        <AppLogo
          className={styles.logo}
          variant={APP_LOGO_VARIANT.light}
          onClick={() => {
            trackNavigationItemClicked({ label: 'App logo' });
          }}
        />
      </div>

      {!isCustomerLocked && (
        <>
          <div className={styles.createContentWrapper}>
            <CreateContentItem className={styles.createContentItem} />
          </div>

          <div className={styles.scrollable}>
            <SidebarLink
              className={styles.homeItem}
              item={homeItem}
              active={homeItem.path === activePath}
              onClick={() => {
                trackNavigationItemClicked({ label: homeItem.label });
                history.push(homeItem.path);
              }}
            />

            <FolderItems className={styles.folderItems} />

            <div className={styles.divider} />

            <SidebarItems className={styles.sidebarItems} />
          </div>

          <WorkspaceItem className={styles.workspaceItem} />
        </>
      )}
    </div>
  );
};
