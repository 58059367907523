import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import { archiveCustomModel, queryCustomModels } from 'src/graphql/customModel';
import { CHANNEL, alignChannelFromBackend, alignChannelWithBackend } from 'src/data/channel';
import {
  performanceCenterCreateCustomModel,
  performanceCenterRecreateCustomModel,
  performanceCenterCreateBlogUrlCustomModel,
  updateCustomModel,
} from 'src/graphql/performance';
import { waitForCustomModelCreation } from './utils';
import { CUSTOM_MODEL_STATUS } from 'src/data/customModel';
import { isAbortError } from 'src/lib/errors';

const INITIAL_STATE = {
  customModels: [],
  isCustomModelsLoading: false,

  runningCustomModels: [],
  creatingBlogCustomModel: false,
};

export const PRIVATE_ACTIONS = {
  init:
    () =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.clearStore());
    },

  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  mergeCustomModelInCache:
    (customModel) =>
    ({ getState, setState }) => {
      if (!customModel) {
        return;
      }
      const { customModels } = getState();

      const updatedCustomModels = customModels.map((cm) =>
        cm.id === customModel.id ? customModel : cm
      );

      setState({ customModels: updatedCustomModels });
    },

  storeCustomModelInCache:
    (customModel) =>
    ({ getState, setState }) => {
      if (!customModel) {
        return;
      }
      const { customModels } = getState();

      setState({ customModels: [...customModels, customModel] });
    },

  removeCustomModelFromCache:
    (customModelId) =>
    ({ getState, setState, dispatch }) => {
      if (!customModelId) {
        return;
      }
      const { customModels } = getState();

      dispatch(PRIVATE_ACTIONS.removeFromRunningCustomModels(customModelId));
      setState({
        customModels: customModels.filter((customModel) => customModel.id !== customModelId),
      });
    },

  removeFromRunningCustomModels:
    (customModelId) =>
    ({ getState, setState }) => {
      if (!customModelId) {
        return;
      }
      const { runningCustomModels } = getState();

      setState({
        runningCustomModels: runningCustomModels.filter(
          (runningCustomModel) => runningCustomModel.id !== customModelId
        ),
      });
    },
};

export const ACTIONS = {
  queryCustomModels:
    (brandVoiceIds, isRefreshingDefaults) =>
    async ({ setState, dispatch }, { currentCustomerId, notifications }) => {
      if (!isRefreshingDefaults) {
        setState({ isCustomModelsLoading: true });
      }

      try {
        const customModels = await queryCustomModels({
          customerId: currentCustomerId,
          brandVoiceIds,
        });

        setState({ customModels: customModels || [] });

        customModels?.forEach((customModel) => {
          const brandVoiceId = customModel.brandVoice?.id;
          const channel = alignChannelFromBackend(customModel.dataSource);
          if (
            [CUSTOM_MODEL_STATUS.training_in_progress, CUSTOM_MODEL_STATUS.created].includes(
              customModel.status
            )
          ) {
            dispatch(
              ACTIONS.pollCustomModel({
                customModel,
                brandVoiceId,
                channel,
              })
            );
          }
        });
        return customModels;
      } catch (error) {
        notifications.displayError(error);
      } finally {
        setState({ isCustomModelsLoading: false });
      }
    },

  createCustomModel:
    ({ brandVoiceId, accountId, channel, onCreateSuccess, onSuccessCallback }) =>
    async ({ dispatch }, { currentCustomerId }) => {
      const customModel = await performanceCenterCreateCustomModel({
        customerId: currentCustomerId,
        brandVoiceId,
        accountId,
        dataSource: alignChannelWithBackend(channel),
      });

      dispatch(ACTIONS.setRunningCustomModel(customModel));

      onCreateSuccess?.();

      dispatch(PRIVATE_ACTIONS.storeCustomModelInCache(customModel));

      return dispatch(
        ACTIONS.pollCustomModel({ customModel, brandVoiceId, channel, onSuccessCallback })
      );
    },

  reCreateCustomModel:
    ({ customModel, onSuccessCallback }) =>
    async ({ dispatch }) => {
      const newCustomModel = await performanceCenterRecreateCustomModel({
        customModelId: customModel.id,
        accountId: customModel.accountId,
        dataSource: customModel.dataSource,
      });
      dispatch(PRIVATE_ACTIONS.removeCustomModelFromCache(customModel.id));
      dispatch(PRIVATE_ACTIONS.storeCustomModelInCache(newCustomModel));
      await dispatch(
        ACTIONS.pollCustomModel({
          customModel: newCustomModel,
          brandVoiceId: newCustomModel.brandVoiceId,
          channel: alignChannelFromBackend(newCustomModel.dataSource),
          onSuccessCallback,
        })
      );
    },

  pollCustomModel:
    ({ customModel, brandVoiceId, channel, onSuccessCallback }) =>
    async ({ dispatch }, { currentCustomerId }) => {
      const deleteCustomModel = (id) => {
        dispatch(ACTIONS.deleteCustomModel(id));
      };
      const mergeCustomModelInCache = (cm) => {
        dispatch(PRIVATE_ACTIONS.mergeCustomModelInCache(cm));
      };
      try {
        const customModelResult = await waitForCustomModelCreation({
          customerId: currentCustomerId,
          brandVoiceId,
          dataSource: alignChannelWithBackend(channel),
          customModel,
          mergeCustomModelInCache,
          deleteCustomModel,
        });

        if (onSuccessCallback) {
          onSuccessCallback({ customModel: customModelResult });
        }
        dispatch(PRIVATE_ACTIONS.removeFromRunningCustomModels(customModelResult.id));
        return customModelResult;
      } catch (error) {
        if (!isAbortError(error)) {
          throw error;
        }
      }
    },

  deleteCustomModel:
    (customModelId) =>
    async ({ dispatch }) => {
      await archiveCustomModel(customModelId);
      dispatch(PRIVATE_ACTIONS.removeCustomModelFromCache(customModelId));
    },

  clearCustomModels:
    () =>
    async ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.clearStore());
    },

  setRunningCustomModel:
    (customModel) =>
    ({ getState, setState }) => {
      const { runningCustomModels } = getState();

      setState({
        runningCustomModels: [...runningCustomModels, customModel],
      });
    },

  removeCustomModelFromCache: PRIVATE_ACTIONS.removeCustomModelFromCache,

  createBlogPostCustomModel:
    ({ brandVoiceId, accountIds, name, onCreateSuccess }) =>
    async ({ setState, dispatch }, { currentCustomerId, notifications }) => {
      setState({ creatingBlogCustomModel: true });
      const channel = CHANNEL.blogUrls;
      try {
        const customModel = await performanceCenterCreateBlogUrlCustomModel({
          customerId: currentCustomerId,
          name,
          dataSource: channel,
          brandVoiceId,
          accountIds,
        });

        onCreateSuccess(customModel);

        dispatch(ACTIONS.setRunningCustomModel(customModel));

        dispatch(PRIVATE_ACTIONS.storeCustomModelInCache(customModel));

        return dispatch(ACTIONS.pollCustomModel({ customModel, brandVoiceId, channel }));
      } finally {
        setState({ creatingBlogCustomModel: false });
      }
    },

  getCustomModels:
    () =>
    ({ getState }) => {
      const { customModels } = getState();

      return customModels;
    },

  updateDefaultCustomModel: (customModel, isDefault) => () => {
    return updateCustomModel(customModel.id, isDefault);
  },
};

export const CustomModelsStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'custom-models-store',
});
