import { orderBy, uniqBy } from 'lodash';

import { DETECTED_BRAND_TERM_TYPE, getDetectedBrandTermType } from 'src/data/brandTerm';

export const prepareDetectedTerms = (detectedTerms, brandVoiceId) => {
  // Take only terms for the current brand voice
  const currentItems = detectedTerms?.filter(
    ({ brandTerm }) => brandTerm.brandVoice?.id === brandVoiceId
  );

  // Remove duplicates for "approved" terms
  const aggregated = uniqBy(currentItems, (detectedTerm) => {
    const detectedType = getDetectedBrandTermType(detectedTerm);

    if (detectedType !== DETECTED_BRAND_TERM_TYPE.approved) {
      return detectedTerm.id;
    }

    return `${detectedType}-${detectedTerm.brandTerm.id}`;
  });

  // Sort by detected type
  return orderBy(
    aggregated,
    [
      (detectedTerm) => {
        const detectedType = getDetectedBrandTermType(detectedTerm);
        return DETECTED_BRAND_TERM_TYPE_ORDER.indexOf(detectedType);
      },
    ],
    ['asc']
  );
};

const DETECTED_BRAND_TERM_TYPE_ORDER = [
  DETECTED_BRAND_TERM_TYPE.doNotUse,
  DETECTED_BRAND_TERM_TYPE.approvedMistake,
  DETECTED_BRAND_TERM_TYPE.approvedCaseSensitive,
  DETECTED_BRAND_TERM_TYPE.approvedHyphenationConsistency,
  DETECTED_BRAND_TERM_TYPE.useCarefully,
  DETECTED_BRAND_TERM_TYPE.approved,
];
