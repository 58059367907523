import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Checkbox, FormControlLabel, Button, Typography } from '@material-ui/core';

import useStyles from './useStyles';

export const BoostAnalyticsOnboardingHint = forwardRef(
  ({ className, onNextClick, dontShowAgain, onDontShowAgainChange }, ref) => {
    const styles = useStyles();

    const handleClose = () => {
      onNextClick();
    };

    return (
      <div
        className={cx(styles.root, className)}
        ref={ref}
      >
        <Typography className={styles.title}>🚀 Boost copy performance</Typography>

        <Typography className={styles.description}>
          Boost performance instantly by improving your copy with just one click
        </Typography>

        <div className={styles.actionButtons}>
          <FormControlLabel
            className={styles.showToggle}
            control={<Checkbox color="primary" />}
            label="Don't show again"
            checked={dontShowAgain}
            onChange={onDontShowAgainChange}
          />

          <Button
            className={styles.closeButton}
            color="primary"
            variant="contained"
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
      </div>
    );
  }
);

BoostAnalyticsOnboardingHint.propTypes = {
  className: PropTypes.string,
  onNextClick: PropTypes.func.isRequired,
  dontShowAgain: PropTypes.bool,
  onDontShowAgainChange: PropTypes.func.isRequired,
};

BoostAnalyticsOnboardingHint.defaultProps = {
  className: null,
  dontShowAgain: false,
};
