import { useEffect } from 'react';

export const useScrollIntoViewConditionally = ({ elementRef, condition, block }) => {
  useEffect(() => {
    if (condition && elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: block || 'center',
      });
    }
  }, [condition, elementRef, block]);
};
