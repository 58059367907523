import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Typography } from '@material-ui/core';
import cx from 'classnames';

import { CheckmarkIcon } from 'src/assets/icons/Checkmark';
import { IconRefresh } from 'src/assets/icons/IconRefresh';
import { useStyles } from './useStyles';

export const PlagiarismNotFound = ({
  classes,
  className,
  toolTip,
  onCheckPlagiarism,
  disabled,
}) => {
  const styles = useStyles({ classes });
  const buttonRef = useRef();

  const handleClick = useCallback(() => {
    onCheckPlagiarism({ referenceElement: buttonRef.current });
  }, [onCheckPlagiarism]);

  return (
    <div className={className}>
      <CheckmarkIcon className={styles.checkmark} />
      <Typography className={styles.notFound}>Plagiarism not found</Typography>

      <Tooltip title={toolTip}>
        <span>
          <Button
            ref={buttonRef}
            className={styles.checkAgain}
            startIcon={<IconRefresh />}
            classes={{ startIcon: cx(styles.checkAgainIcon, { [styles.disabled]: disabled }) }}
            onClick={handleClick}
            disabled={disabled}
            variant="contained"
          >
            Check again
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

PlagiarismNotFound.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  toolTip: PropTypes.string,
  onCheckPlagiarism: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

PlagiarismNotFound.defaultProps = {
  className: null,
  classes: {},
  toolTip: '',
};
