import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const BAB = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
    <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
    <path
      d="M16.1225 8.16992H7.71875V23C7.71875 23 15.6403 23 21.725 23V16.347"
      stroke="white"
      strokeWidth="1.10883"
    />
    <path
      d="M9.9375 15.2373H13.6762"
      stroke="white"
      strokeWidth="1.10883"
      strokeLinejoin="round"
    />
    <path
      d="M9.9375 17.4551H17.1034"
      stroke="white"
      strokeWidth="1.10883"
      strokeLinejoin="round"
    />
    <path
      d="M9.9375 19.6729H17.1034"
      stroke="white"
      strokeWidth="1.10883"
      strokeLinejoin="round"
    />
    <path
      d="M21.0644 10.5653H18.8627L18.8509 9.53001H20.7736C21.098 9.53001 21.373 9.48475 21.5985 9.39423C21.828 9.29994 22.0021 9.16605 22.1207 8.99256C22.2394 8.8153 22.2988 8.60221 22.2988 8.35329C22.2988 8.07797 22.2434 7.85357 22.1326 7.68008C22.0218 7.50659 21.8517 7.38024 21.6223 7.30104C21.3967 7.22184 21.1079 7.18224 20.7558 7.18224H19.3138V14.2878H17.8242V6.05078H20.7558C21.2306 6.05078 21.6539 6.09415 22.0258 6.1809C22.4016 6.26764 22.7201 6.40342 22.9812 6.58822C23.2463 6.76926 23.4461 6.99932 23.5806 7.27841C23.7191 7.5575 23.7883 7.8894 23.7883 8.27409C23.7883 8.61353 23.7033 8.92468 23.5331 9.20754C23.363 9.48663 23.1118 9.71481 22.7795 9.89207C22.4471 10.0693 22.0337 10.1749 21.5392 10.2089L21.0644 10.5653ZM20.9991 14.2878H18.3939L19.0645 13.162H20.9991C21.3354 13.162 21.6163 13.1092 21.8418 13.0036C22.0673 12.8942 22.2355 12.7452 22.3463 12.5566C22.461 12.3643 22.5184 12.1399 22.5184 11.8834C22.5184 11.6157 22.4689 11.3837 22.37 11.1876C22.2711 10.9877 22.1148 10.835 21.9012 10.7293C21.6875 10.62 21.4086 10.5653 21.0644 10.5653H19.3909L19.4028 9.53001H21.5807L21.919 9.92036C22.3937 9.93544 22.7834 10.0354 23.0881 10.2202C23.3967 10.405 23.6261 10.6445 23.7765 10.9387C23.9268 11.2328 24.002 11.5497 24.002 11.8891C24.002 12.4133 23.8813 12.8527 23.64 13.2072C23.4026 13.5618 23.0604 13.8314 22.6133 14.0162C22.1662 14.1973 21.6282 14.2878 20.9991 14.2878Z"
      fill="white"
    />
  </SvgIcon>
));

export default React.memo(BAB);
