import { ONBOARDING_STEP } from 'src/data/onboarding';
import { SPOTLIGHT_TYPE } from '../components/Spotlight';
import { TooltipContentAccounts } from '../components/TooltipContentAccounts';

export const STEPS_ACCOUNTS_DATA = [
  {
    id: ONBOARDING_STEP.accounts,
    target: '[data-onboarding="ProfileButton"]',
    contentClass: TooltipContentAccounts,
    content: {
      dontShowAgain: false,
    },
    options: {
      placement: 'bottom-end',
      showOverlay: true,
      spotlights: [{ type: SPOTLIGHT_TYPE.circle, padding: 0 }],
    },
  },
];
