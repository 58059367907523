import { useMemo, useRef } from 'react';

import { createStateSelectorHook } from 'src/lib/sweetState';
import { TEXT_EDITOR_TYPE } from 'src/components/TextDetailsPanel';
import {
  useCurrentCustomer,
  useCustomFeatureLimitsData,
  PerformanceStore,
  useIsProjectReadOnly,
  useCustomerTwitterSocialIntegrationEnabled,
} from 'src/store';
import { BOOK_DEMO_ORIGIN, UPGRADE_DIALOG_ACTIONS_ORIGIN } from 'src/data/common';
import { isHebrewLanguage } from 'src/data/generation';
import { getToolBriefType, isToolSupportBenchmark } from 'src/data/generationTool';
import { getChannelFromAssetType } from 'src/data/benchmark';
import { getCustomerPlan, shouldUpgradeToBusiness } from 'src/data/subscription';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { CHANNEL } from 'src/data/channel';
import { EMAILS_CHANNELS } from 'src/data/performance';
import { PRIVACY_SETTINGS_FEATURES } from 'src/data/privacy';
import * as events from 'src/lib/events';
import { injectFacebookSDK } from 'src/utils/facebook';
import {
  useConnectToChannelPopup,
  useUpgradePopover,
  useUpgradePopup,
  useBenchmarkPopup,
} from '../popup';
import { useBrandVoices } from '../useBrandVoices';
import { useChannelAccounts } from '../useChannelAccounts';
import { useConnectToChannel } from '../useConnectToChannel';
import { useCustomerPrivacySettings } from '../useCustomerPrivacySettings';
import { useScoringMethodName } from '../useScoringMethodName';
import { useTrackingEvent } from '../useTrackingEvent';
import { useBrandTermsProps } from './useBrandTermsProps';
import { useToneOfVoiceProps } from './useToneOfVoiceProps';
import { useApplyAnalyzedToneOfVoice } from './useApplyAnalyzedToneOfVoice';
import { useTabProps } from './useTabProps';

const usePerformanceState = createStateSelectorHook(PerformanceStore, [
  'loadingGlobalBenchmark',
  'loadingStatus',
  'globalBenchmarkConfigs',
]);

const scoreIsNotAppealing = (score) => score < 60;

export const usePrepareTextDetailsPanelProps = ({
  editorContent,
  textEditorType = TEXT_EDITOR_TYPE.richSlate,
  onEditorContentChange,
  onEditorReferenceChange,
  localEditorRef,
  onCancelChanges,
  onSaveChanges,
  saveChangesEventData,
  blockedReason,
  isScoreLocked,
  readOnly,
  onScoringMethodClick,
  onApplyDetectedBrandTerm,
  onDetectedBrandTermHover,
  onDetectedBrandTermClick,
  assetType,
  scoringMethod,
  generationTool,
  updating,
  onLockedUpgradeClick,
  onUndoClick,
  undoDisabled,
  detectedBrandTermsData,
  toneOfVoiceInsightsData,
  sensitivityData,
  scoreData,
  histogramsData,
  annotationsData,
  campaignKeywords,
  forceDisabled,
  scoringMethodName,
  secondaryScoringMethodName,
  textForReadabilityScore,
  markedKeywordsMap,
  applicationMode,
  onEditorCopy,
  editorReadOnly,
  targetKeywordsData,
  relatedKeywordsData,
  onToggleMarkedKeyword,
  onSeoTrackerExpand,
  plagiarismChecksUsed,
  plagiarismChecksLimit,
  hasPerformedPlagiarismCheck,
  potentialCopiedSentences,
  lastPlagiarismCheckTime,
  loadingPlagiarismReport,
  onCheckPlagiarism,
  textForPlagiarism,
  isOpen,
  onOpenPlagiarism,
  targetAudienceProps,
  onTextCopy,
  onBenchmarkApplyClick,
  benchmarkConfigProps,
  trackingEventAttributes,
  resetCounter,
  language,
}) => {
  const openBenchmarkPopup = useBenchmarkPopup();
  // Keep reference to the latest tracking event attributes, so we can use them in the event handlers
  const trackingEventAttributesRef = useRef(trackingEventAttributes);
  trackingEventAttributesRef.current = trackingEventAttributes;

  const currentCustomer = useCurrentCustomer();
  const { brandVoices } = useBrandVoices();
  const isProjectReadOnly = useIsProjectReadOnly();
  const noneAppeal = scoreIsNotAppealing(scoreData?.score);
  const briefType = getToolBriefType(generationTool);
  const disabled = forceDisabled || isProjectReadOnly || updating;
  const deducedScoringMethodName = useScoringMethodName(briefType, scoringMethod);
  const resourcesRowsPerCustomerFeatureLimitsData = useCustomFeatureLimitsData(
    FEATURE_LIMITS.resourcesRowsPerCustomer
  );
  const { connectedAccounts } = useChannelAccounts();
  const { loadingStatus, loadingGlobalBenchmark, globalBenchmarkConfigs } = usePerformanceState();
  const shouldIncludeTwitterSocial = useCustomerTwitterSocialIntegrationEnabled();

  const trackEvent = useTrackingEvent();
  const { connectToChannel } = useConnectToChannel({
    page: events.EVENT_PAGE.editor,
  });
  const { isFeatureRestricted } = useCustomerPrivacySettings();
  const openConnectToChannelPopup = useConnectToChannelPopup();
  const openUpgradePopover = useUpgradePopover();
  const {
    applyingAnalyzedToneOfVoice,
    analyzedToneOfVoiceAppliedMessage,
    handleApplyAnalyzedToneOfVoice,
  } = useApplyAnalyzedToneOfVoice({
    generationTool,
    localEditorRef,
    onApplyingAnalyzedToneOfVoice: toneOfVoiceInsightsData?.onApplyingAnalyzedToneOfVoice,
  });

  // Inject SDK for FB
  injectFacebookSDK();

  const brandPolicyLoading =
    toneOfVoiceInsightsData?.loading ||
    detectedBrandTermsData?.loading ||
    sensitivityData?.loading ||
    applyingAnalyzedToneOfVoice;

  const copyInputProps = useMemo(() => {
    const handleCopyInputSaveChanges = () => {
      onSaveChanges?.();

      if (saveChangesEventData?.name) {
        trackEvent(saveChangesEventData.name, {
          ...trackingEventAttributesRef.current,
          assetType,
          generationTool,
          ...saveChangesEventData.attributes,
        });
      }
    };

    return {
      assetType,
      generationTool,
      editorContent,
      onEditorContentChange,
      disabled,
      textEditorType,
      requireChangesConfirmation: true,
      editorRef: localEditorRef,
      onCancelChanges,
      onSaveChanges: handleCopyInputSaveChanges,
      detectedBrandTerms: detectedBrandTermsData?.items,
      applicationMode,
      onEditorCopy,
      readOnly: editorReadOnly,
      onEditorReferenceChange,
      onTextCopy,
      resetCounter,
      applyingAnalyzedToneOfVoice,
      successMessage: analyzedToneOfVoiceAppliedMessage,
    };
  }, [
    assetType,
    generationTool,
    editorContent,
    onEditorContentChange,
    disabled,
    textEditorType,
    localEditorRef,
    onCancelChanges,
    detectedBrandTermsData?.items,
    applicationMode,
    onEditorCopy,
    editorReadOnly,
    onEditorReferenceChange,
    onTextCopy,
    saveChangesEventData,
    trackEvent,
    onSaveChanges,
    resetCounter,
    applyingAnalyzedToneOfVoice,
    analyzedToneOfVoiceAppliedMessage,
  ]);

  const scoreDetailsProps = useMemo(
    () => ({
      score: scoreData?.score,
      basedOnValue: scoreData?.basedOn,
      loading: scoreData?.loading,
      blocked: !!blockedReason,
      locked: isScoreLocked,
      readOnly,
      displayScoringMethod: !isHebrewLanguage(language),
      scoringMethodName: scoringMethodName || deducedScoringMethodName,
      onScoringMethodClick,
      campaignKeywords,
    }),
    [
      blockedReason,
      campaignKeywords,
      deducedScoringMethodName,
      isScoreLocked,
      onScoringMethodClick,
      readOnly,
      scoreData?.basedOn,
      scoreData?.loading,
      scoreData?.score,
      scoringMethodName,
      language,
    ]
  );

  const scoreAdditionalFactorsProps = useMemo(
    () => ({
      campaignKeywords,
      loading: annotationsData?.loading,
      headlineType: secondaryScoringMethodName,
    }),
    [annotationsData?.loading, campaignKeywords, secondaryScoringMethodName]
  );

  const histogramAgeScoreProps = useMemo(() => {
    if (isHebrewLanguage(language)) {
      return null;
    }

    return {
      loading: histogramsData?.loading,
      score: scoreData?.score,
      data: histogramsData?.age,
      noneAppeal,
    };
  }, [histogramsData?.age, histogramsData?.loading, scoreData?.score, language, noneAppeal]);

  const histogramGenderScoreProps = useMemo(() => {
    if (isHebrewLanguage(language)) {
      return null;
    }

    return {
      loading: histogramsData?.loading,
      data: histogramsData?.gender,
    };
  }, [histogramsData?.gender, histogramsData?.loading, language]);

  const talkingPointsProps = useMemo(() => {
    if (isHebrewLanguage(language)) {
      return null;
    }

    return {
      annotations: annotationsData?.annotations,
      loading: annotationsData?.loading,
    };
  }, [annotationsData?.annotations, annotationsData?.loading, language]);

  const toneOfVoiceProps = useToneOfVoiceProps({
    toneOfVoiceInsightsData,
    generationTool,
    disabled,
    applyingAnalyzedToneOfVoice,
    handleApplyAnalyzedToneOfVoice,
    loading: brandPolicyLoading,
  });

  const brandTermsProps = useBrandTermsProps({
    detectedBrandTermsData,
    onApplyDetectedBrandTerm,
    onDetectedBrandTermHover,
    onDetectedBrandTermClick,
    disabled,
    loading: brandPolicyLoading,
  });

  const channelPolicyProps = useMemo(() => {
    if (isHebrewLanguage(language)) {
      return null;
    }

    return {
      assetType,
      sensitivity: sensitivityData?.sensitivity,
      loading: brandPolicyLoading,
      horizontalLayout: true,
      policyTitle: true,
    };
  }, [assetType, brandPolicyLoading, sensitivityData?.sensitivity, language]);

  const blockedProps = useMemo(() => {
    if (!blockedReason) {
      return null;
    }

    return {
      blockedReason,
      onUpgradeClick: onLockedUpgradeClick,
      onUndoClick,
      undoDisabled,
    };
  }, [blockedReason, onLockedUpgradeClick, onUndoClick, undoDisabled]);

  const readabilityScoreProps = useMemo(
    () => ({
      copyText: textForReadabilityScore,
    }),
    [textForReadabilityScore]
  );

  const seoTrackerProps = useMemo(
    () => ({
      targetKeywordsData,
      relatedKeywordsData,
      onToggleMarkedKeyword,
      markedKeywordsMap,
      onExpand: onSeoTrackerExpand,
    }),
    [
      targetKeywordsData,
      relatedKeywordsData,
      onToggleMarkedKeyword,
      markedKeywordsMap,
      onSeoTrackerExpand,
    ]
  );

  const plagiarismCheckerProps = useMemo(() => {
    const handleCheckPlagiarism = ({ referenceElement }) => {
      if (isFeatureRestricted(PRIVACY_SETTINGS_FEATURES.EXTERNAL_PLAGIARISM_DETECTION)) {
        return;
      }

      const reachedFeatureLimit =
        plagiarismChecksLimit != null ? plagiarismChecksUsed >= plagiarismChecksLimit : false;
      if (reachedFeatureLimit) {
        openUpgradePopover({
          referenceElement,
          origin: BOOK_DEMO_ORIGIN.checkPlagiarism,
          placement: 'top-end',
        });
      } else {
        onCheckPlagiarism(textForPlagiarism);
      }
    };

    return {
      copyText: textForPlagiarism,
      plagiarismChecksUsed,
      plagiarismChecksLimit,
      loading: loadingPlagiarismReport,
      lastPlagiarismCheckTime,
      hasPerformedPlagiarismCheck,
      onCheckPlagiarism: handleCheckPlagiarism,
      potentialCopiedSentences,
      isOpen,
      onOpenPlagiarism,
    };
  }, [
    plagiarismChecksUsed,
    plagiarismChecksLimit,
    lastPlagiarismCheckTime,
    hasPerformedPlagiarismCheck,
    onCheckPlagiarism,
    potentialCopiedSentences,
    loadingPlagiarismReport,
    textForPlagiarism,
    isOpen,
    onOpenPlagiarism,
    isFeatureRestricted,
    openUpgradePopover,
  ]);

  const audienceChartProps = useMemo(() => {
    if (isHebrewLanguage(language)) {
      return null;
    }

    return {
      ...targetAudienceProps,
      generationTool,
      brandVoices,
      empty: !scoreData?.loading && !(scoreData?.score > 0),
      loading: targetAudienceProps?.loading || scoreData?.loading,
    };
  }, [
    language,
    targetAudienceProps,
    generationTool,
    scoreData?.loading,
    scoreData?.score,
    brandVoices,
  ]);

  const openBenchmarkUpgradePopup = useUpgradePopup({
    actionsOrigin: UPGRADE_DIALOG_ACTIONS_ORIGIN.cipPageConnectDialog,
    projectId: benchmarkConfigProps?.projectId,
    variationId: benchmarkConfigProps?.variationId,
  });
  const benchmarkProps = useMemo(() => {
    if (!isToolSupportBenchmark(generationTool)) {
      return null;
    }
    let channel = getChannelFromAssetType(assetType);

    if (channel === CHANNEL.twitterSocial && !shouldIncludeTwitterSocial) {
      return null;
    }

    const plan = getCustomerPlan(currentCustomer);
    const shouldUpgrade = shouldUpgradeToBusiness(plan);

    const onBenchmarkConnectClick = async () => {
      trackEvent(events.DOCUMENT.variationBenchmarkConnectToChannelClicked, {
        ...trackingEventAttributesRef.current,
        channel,
        generationTool,
      });
      if (channel === CHANNEL.emailChannel) {
        const emailChannel = await openConnectToChannelPopup({
          displayIntegrations: false,
          displayResourcesTemplates: true,
          displayEmailIntegrations: true,
          title: 'Email',
          subTitle: 'Select one of the options to import email data',
        });
        if (!EMAILS_CHANNELS.includes(emailChannel)) {
          return;
        }
        channel = emailChannel;
      }
      connectToChannel({ channel });
    };

    const onBenchmarkUpgradeClick = () => {
      trackEvent(events.DOCUMENT.variationBenchmarkUpgradeClicked, {
        ...trackingEventAttributesRef.current,
        channel,
        generationTool,
      });
    };

    const onBenchmarkPopupOpenClick = () => {
      openBenchmarkPopup({
        assetType: benchmarkConfigProps.assetType,
        channel,
        loadingBenchmark: benchmarkConfigProps.loadingBenchmark,
        onBenchmarkApplyClick,
        benchmarkConfig: benchmarkConfigProps.benchmarkConfig,
      });
      trackEvent(events.DOCUMENT.benchmarkPopupOpen, {
        ...trackingEventAttributesRef.current,
        channel,
        generationTool,
      });
    };

    return {
      onBenchmarkConnectClick,
      onBenchmarkUpgradeClick,
      channel,
      assetType,
      shouldUpgrade,
      featureLimitsData: resourcesRowsPerCustomerFeatureLimitsData,
      openPopup: onBenchmarkPopupOpenClick,
      openUpgradePopup: openBenchmarkUpgradePopup,
      connectedAccounts,
      loadingGlobalBenchmark,
      globalBenchmarkConfigs,
      loadingStatus,
      ...benchmarkConfigProps,
    };
  }, [
    currentCustomer,
    onBenchmarkApplyClick,
    generationTool,
    assetType,
    benchmarkConfigProps,
    trackEvent,
    resourcesRowsPerCustomerFeatureLimitsData,
    connectToChannel,
    openConnectToChannelPopup,
    openBenchmarkUpgradePopup,
    connectedAccounts,
    loadingGlobalBenchmark,
    globalBenchmarkConfigs,
    loadingStatus,
    openBenchmarkPopup,
    shouldIncludeTwitterSocial,
  ]);

  const tabProps = useTabProps();

  return useMemo(
    () => ({
      tabProps,
      copyInputProps,
      scoreDetailsProps,
      histogramAgeScoreProps,
      histogramGenderScoreProps,
      talkingPointsProps,
      brandTermsProps,
      channelPolicyProps,
      blockedProps,
      scoreAdditionalFactorsProps,
      readabilityScoreProps,
      seoTrackerProps,
      plagiarismCheckerProps,
      audienceChartProps,
      benchmarkProps,
      toneOfVoiceProps,
    }),
    [
      tabProps,
      copyInputProps,
      scoreDetailsProps,
      histogramAgeScoreProps,
      histogramGenderScoreProps,
      talkingPointsProps,
      brandTermsProps,
      channelPolicyProps,
      blockedProps,
      scoreAdditionalFactorsProps,
      readabilityScoreProps,
      seoTrackerProps,
      plagiarismCheckerProps,
      audienceChartProps,
      benchmarkProps,
      toneOfVoiceProps,
    ]
  );
};
