import gql from 'graphql-tag';

export const FRAGMENT_FREESTYLE_TOOL = gql`
  fragment SuggestionFreestyleTool on CustomerSuggestionFreestyleTool {
    id
    name
    allInstructions
    allInstructionsData
    generationTool
    special
  }
`;
