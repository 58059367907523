import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_ANNOTATION } from './schema';

const MUTATION = gql`
  mutation AnnotateCopyText($assetType: AssetTypeEnum!, $text: String!) {
    annotateCopyText(assetType: $assetType, text: $text) {
      ...Annotation
    }
  }
  ${FRAGMENT_ANNOTATION}
`;

export const annotateCopyText = async ({ assetType, text }, options) => {
  const variables = { assetType, text };

  const result = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  return getSingleProp(result.data);
};
