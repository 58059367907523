import { ChannelAuthError } from 'src/lib/errors';

//                           ms     s    min  h    d
const TOKEN_VALID_DURATION = 1000 * 60 * 60 * 24 * 30;

const PERMISSIONS_ERROR_CODE = '100';

export const checkFacebookLogin = () =>
  new Promise((resolve, reject) => {
    if (!window.FB) {
      reject(new ChannelAuthError('Facebook SDK is not initialized'));
      return;
    }

    window.FB.getLoginStatus((res) => {
      if (res.status === 'connected' && res.authResponse) {
        resolve(res.authResponse);
      } else {
        resolve(null);
      }
    }, true);
  });

export const loginToFacebook = (scope) =>
  new Promise((resolve, reject) => {
    if (!window.FB) {
      reject(new ChannelAuthError('Facebook SDK is not initialized'));
      return;
    }
    if (!scope) {
      reject(new ChannelAuthError('Cannot login to Facebook with empty "scope"'));
      return;
    }

    const PARAMS = {
      scope,
    };

    window.FB.login((res) => {
      if (res.status === 'connected' && res.authResponse) {
        resolve(res.authResponse);
      } else if (res.status === 'unknown') {
        reject();
      } else {
        resolve(null);
      }
    }, PARAMS);
  });

export function isFacebookTokenValid(expiresAt) {
  if (expiresAt == null) {
    return false;
  }
  if (expiresAt === 0) {
    return true;
  }

  return new Date(expiresAt * 1000) - new Date() > TOKEN_VALID_DURATION;
}

export const isPermissionsError = (error) => {
  const graphqlError = (error.graphQLErrors && error.graphQLErrors[0]) || undefined;
  if (!graphqlError) {
    return false;
  }
  return graphqlError.code === PERMISSIONS_ERROR_CODE;
};
