import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconBold = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 1C2.25 0.585786 2.58579 0.25 3 0.25H5.58723C7.2105 0.25 8.52643 1.56593 8.52643 3.1892C8.52643 4.81247 7.2105 6.12839 5.58723 6.12839H3C2.58579 6.12839 2.25 5.79261 2.25 5.37839V1ZM3.75 1.75V4.62839H5.58723C6.38208 4.62839 7.02643 3.98404 7.02643 3.1892C7.02643 2.39435 6.38208 1.75 5.58723 1.75H3.75Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5.37891C2.25 4.96469 2.58579 4.62891 3 4.62891H5.98527C7.82837 4.62891 9.3225 6.12303 9.3225 7.96614C9.3225 9.80922 7.82838 11.3034 5.98527 11.3034H3C2.58579 11.3034 2.25 10.9676 2.25 10.5534V5.37891ZM3.75 6.12891V9.80337H5.98527C6.99994 9.80337 7.8225 8.9808 7.8225 7.96614C7.8225 6.95146 6.99995 6.12891 5.98527 6.12891H3.75Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconBold.displayName = 'IconBold';
