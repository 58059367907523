import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './useStyles';

const propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

const defaultProps = {
  title: null,
};

const ProgressPopup = ({ open, title }) => {
  const styles = useStyles();

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      {title && (
        <DialogTitle className={styles.dialogTitle} disableTypography>
          <Typography className={styles.title} variant="h5">
            {title}
          </Typography>
        </DialogTitle>
      )}

      <DialogContent className={styles.dialogContent}>
        <CircularProgress size={60} />
      </DialogContent>
    </Dialog>
  );
};

ProgressPopup.propTypes = propTypes;
ProgressPopup.defaultProps = defaultProps;

export default ProgressPopup;
