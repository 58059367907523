import { createStateSelectorHook } from 'src/lib/sweetState';
import { PerformanceStore } from './PerformanceStore';

const usePerformanceState = createStateSelectorHook(PerformanceStore, [
  'copies',
  'lastExtractionDate',
  'loadingStatus',
  'campaigns',
  'avgCompletionPercent',
  'campaignsCopyImpressions',
  'connectingToChannel',
  'authErrorMessage',
  'loadingAccounts',
  'errorMessage',
]);

export const usePerformanceChannelData = (channel, accountId) => {
  const {
    copies,
    lastExtractionDate,
    loadingStatus,
    campaigns,
    avgCompletionPercent,
    campaignsCopyImpressions,
    connectingToChannel,
    authErrorMessage,
    loadingAccounts,
    errorMessage,
  } = usePerformanceState();

  return {
    copies: copies[channel]?.[accountId] || [],
    lastExtractionDate: lastExtractionDate[channel]?.[accountId] || null,
    loadingStatus: loadingStatus[channel]?.[accountId] || false,
    campaigns: campaigns[channel]?.[accountId] || [],
    avgCompletionPercent: avgCompletionPercent[channel]?.[accountId] || null,
    campaignsCopyImpressions: campaignsCopyImpressions[channel]?.[accountId] || [],
    connectingToChannel: connectingToChannel[channel] || false,
    authErrorMessage: authErrorMessage[channel] || null,
    loadingAccounts,
    errorMessage: errorMessage[channel]?.[accountId] || null,
  };
};
