import { UniqMap } from 'src/lib/UniqMap';
import { NATIVE_CHANNEL, SOCIAL_CHANNEL, getChannelLabel } from 'src/data/channel';
import { PROJECT_TYPE } from 'src/data/project/const';
import {
  ASSET_TYPE,
  BLOG_POST_ASSET_TYPE,
  EMAIL_ASSET_TYPE,
  LANDING_ASSET_TYPE,
  SMS_ASSET_TYPE,
  CANVAS_ASSET_TYPE,
  DEPRECATED_ASSET_TYPE,
  PRODUCT_LISTING_ASSET_TYPE,
  EDITOR_MARKED_ASSET_TYPE,
} from './const';

/**
 * Asset props and utilities:
 *   channel - channel of the current asset type
 *   maxCopyLength - how long assets of this type might be
 *   strictCopyLengthCheck - should we enforce length check
 *   disableScores - should variation scores be hidden
 *   supportsSuggestKeywords - does asset type supports "suggest" keywords
 *   supportsSuggestLink  - does asset type supports "suggest" link
 *   supportsRunningAds - does asset type supports selection of "running" ads and tab
 *   disableAnnotations - disable annotations for specific asset
 */
const ASSET_PROPS_MAP = new UniqMap();

/* Common asset types props */
ASSET_PROPS_MAP.add(ASSET_TYPE.facebookPrimaryText, {
  label: 'Primary Text',
  channel: SOCIAL_CHANNEL.facebook,
  maxCopyLength: 125,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  supportsRunningAds: true,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.facebookHeadline, {
  label: 'Headline',
  channel: SOCIAL_CHANNEL.facebook,
  maxCopyLength: 40,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsRunningAds: true,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.facebookMixtureAd, {
  label: 'Facebook Ad',
  channel: SOCIAL_CHANNEL.facebook,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsRunningAds: true,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.twitterMixtureAd, {
  label: 'X Ad',
  channel: SOCIAL_CHANNEL.twitter,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsRunningAds: true,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.pinterestMixtureAd, {
  label: 'Pinterest Ad',
  channel: SOCIAL_CHANNEL.pinterest,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsRunningAds: true,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.linkedinMixtureAd, {
  label: 'LinkedIn Ad',
  channel: SOCIAL_CHANNEL.linkedin,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  supportsRunningAds: true,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.googleHeadline, {
  label: 'Headline',
  channel: SOCIAL_CHANNEL.google,
  maxCopyLength: 30,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
  maxWordsPerKeyword: 1,
  supportsRunningAds: true,
});

ASSET_PROPS_MAP.add(ASSET_TYPE.googleDescription, {
  label: 'Description',
  channel: SOCIAL_CHANNEL.google,
  maxCopyLength: 90,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  supportsRunningAds: true,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.googleRSA, {
  label: 'RSA',
  channel: SOCIAL_CHANNEL.google,
  maxCopyLength: 90,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  supportsRunningAds: true,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.linkedinTextAdHeadline, {
  label: 'Headline',
  channel: SOCIAL_CHANNEL.linkedin,
  maxCopyLength: 25,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.linkedinTextAdDescription, {
  label: 'Intro Text',
  channel: SOCIAL_CHANNEL.linkedin,
  maxCopyLength: 75,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.linkedinIntro, {
  label: 'Intro Text',
  channel: SOCIAL_CHANNEL.linkedin,
  maxCopyLength: 150,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.linkedinTitle, {
  label: 'Headline',
  channel: SOCIAL_CHANNEL.linkedin,
  maxCopyLength: 70,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.linkedinSingleImageHeadline, {
  label: 'Headline',
  channel: SOCIAL_CHANNEL.linkedin,
  maxCopyLength: 27,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.linkedinSingleImageIntro, {
  label: 'Copy',
  channel: SOCIAL_CHANNEL.linkedin,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.twitterTitle, {
  label: 'Title',
  channel: SOCIAL_CHANNEL.twitter,
  maxCopyLength: 70,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.twitterCopy, {
  label: 'Copy',
  channel: SOCIAL_CHANNEL.twitter,
  maxCopyLength: 280,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.pinterestTitle, {
  label: 'Title',
  channel: SOCIAL_CHANNEL.pinterest,
  maxCopyLength: 100,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.pinterestDescription, {
  label: 'Description',
  channel: SOCIAL_CHANNEL.pinterest,
  maxCopyLength: 500,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.youtubeTitle, {
  label: 'Title',
  channel: SOCIAL_CHANNEL.youtube,
  maxCopyLength: 70,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.youtubeDescription, {
  label: 'Description',
  channel: SOCIAL_CHANNEL.youtube,
  maxCopyLength: 750,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});

ASSET_PROPS_MAP.add(ASSET_TYPE.outbrainHeadline, {
  label: 'Headline',
  channel: NATIVE_CHANNEL.outbrain,
  maxCopyLength: 50,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.taboolaHeadline, {
  label: 'Headline',
  channel: NATIVE_CHANNEL.taboola,
  maxCopyLength: 50,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.verizonHeadline, {
  label: 'Headline',
  channel: NATIVE_CHANNEL.verizon,
  maxCopyLength: 50,
  strictCopyLengthCheck: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.verizonDescription, {
  label: 'Description',
  channel: NATIVE_CHANNEL.verizon,
  maxCopyLength: 255,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});

/* Common assets for "Teads" channel  */
ASSET_PROPS_MAP.add(ASSET_TYPE.teadsSingleImageHeadline, {
  label: 'Headline',
  channel: NATIVE_CHANNEL.teads,
  maxCopyLength: 50,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.teadsSingleImageDescription, {
  label: 'Description',
  channel: NATIVE_CHANNEL.teads,
  maxCopyLength: 140,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.teadsSingleVideoHeadline, {
  ...ASSET_PROPS_MAP.get(ASSET_TYPE.teadsSingleImageHeadline),
});
ASSET_PROPS_MAP.add(ASSET_TYPE.teadsSingleVideoDescription, {
  ...ASSET_PROPS_MAP.get(ASSET_TYPE.teadsSingleImageDescription),
});
ASSET_PROPS_MAP.add(ASSET_TYPE.teadsCarouselHeadline, {
  ...ASSET_PROPS_MAP.get(ASSET_TYPE.teadsSingleImageHeadline),
  label: 'Carousel',
});
ASSET_PROPS_MAP.add(ASSET_TYPE.teadsSocialLayout1Headline, {
  label: 'Headline',
  channel: NATIVE_CHANNEL.teads,
  maxCopyLength: 40,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.teadsSocialLayout1PrimaryText, {
  label: 'Primary Text',
  channel: NATIVE_CHANNEL.teads,
  maxCopyLength: 125,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.teadsSocialLayout1Description, {
  label: 'Description',
  channel: NATIVE_CHANNEL.teads,
  maxCopyLength: 25,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.teadsSocialLayout2PrimaryText, {
  ...ASSET_PROPS_MAP.get(ASSET_TYPE.teadsSocialLayout1PrimaryText),
  label: 'Primary Text',
});

/* Social asset types */
ASSET_PROPS_MAP.add(ASSET_TYPE.facebookPost, {
  label: 'Post',
  channel: SOCIAL_CHANNEL.facebook,
  maxCopyLength: 150,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.instagramCaption, {
  label: 'Caption',
  channel: SOCIAL_CHANNEL.instagram,
  maxCopyLength: 70,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.tweet, {
  label: 'X Post',
  channel: SOCIAL_CHANNEL.twitter,
  maxCopyLength: 280,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.linkedinPost, {
  label: 'Post',
  channel: SOCIAL_CHANNEL.linkedinSocial,
  maxCopyLength: 70,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.pinterestPostTitle, {
  label: 'Title',
  channel: SOCIAL_CHANNEL.pinterest,
  maxCopyLength: 100,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});
ASSET_PROPS_MAP.add(ASSET_TYPE.pinterestPost, {
  label: 'Description',
  channel: SOCIAL_CHANNEL.pinterest,
  maxCopyLength: 500,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});

/* Product listing asset types props */
ASSET_PROPS_MAP.add(PRODUCT_LISTING_ASSET_TYPE.amazonProductDescription, {
  label: 'Paragraph Description',
  channel: NATIVE_CHANNEL.amazon,
  maxCopyLength: 500,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  strictCopyLengthCheck: true,
});
ASSET_PROPS_MAP.add(PRODUCT_LISTING_ASSET_TYPE.amazonFeatureList, {
  label: 'Feature List',
  channel: NATIVE_CHANNEL.amazon,
  maxCopyLength: 850,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  strictCopyLengthCheck: true,
});
ASSET_PROPS_MAP.add(PRODUCT_LISTING_ASSET_TYPE.walmartProductDescription, {
  label: 'Paragraph Description',
  channel: NATIVE_CHANNEL.walmart,
  maxCopyLength: 500,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  strictCopyLengthCheck: true,
});
ASSET_PROPS_MAP.add(PRODUCT_LISTING_ASSET_TYPE.walmartFeatureList, {
  label: 'Feature List',
  channel: NATIVE_CHANNEL.walmart,
  maxCopyLength: 850,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  strictCopyLengthCheck: true,
});
ASSET_PROPS_MAP.add(PRODUCT_LISTING_ASSET_TYPE.generalProductDescription, {
  label: 'Paragraph Description',
});
ASSET_PROPS_MAP.add(PRODUCT_LISTING_ASSET_TYPE.generalProductListing, {
  label: 'Product Listing',
});
ASSET_PROPS_MAP.add(PRODUCT_LISTING_ASSET_TYPE.amazonProductListing, {
  label: 'Product Listing',
  channel: NATIVE_CHANNEL.amazon,
});
ASSET_PROPS_MAP.add(PRODUCT_LISTING_ASSET_TYPE.walmartProductListing, {
  label: 'Product Listing',
  channel: NATIVE_CHANNEL.walmart,
});

/**
 * LPCO asset types
 */
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.landingCTA, {
  label: 'Call to Action',
  maxCopyLength: 100,
  supportsSuggestKeywords: true,
  strictCopyLengthCheck: true,
});
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.landingCopy, {
  label: 'Copy',
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  maxCopyLength: 200,
});
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.landingProductName, {
  label: 'Product Name',
  maxCopyLength: 150,
  supportsSuggestKeywords: true,
  strictCopyLengthCheck: true,
});
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.landingSEOMetadata, {
  label: 'SEO Meta Description',
  maxCopyLength: 160,
  strictCopyLengthCheck: true,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});

/**
 * Shopify LPCO asset types
 */
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.landingHeadline, {
  label: 'Headline',
  maxCopyLength: 150,
  supportsSuggestKeywords: true,
  strictCopyLengthCheck: true,
});
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.landingProductDescription, {
  label: 'Paragraph Description',
  maxCopyLength: 850,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
  strictCopyLengthCheck: true,
});

/**
 * "Landing page builder" assets
 */
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.landingBuilderHeadline, {
  label: 'Headline',
  maxCopyLength: 150,
  maxSuggestKeywords: 2,
});
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.landingBuilderSubHeadline, {
  label: 'Subheader',
  maxCopyLength: 150,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.landingBuilderCta, {
  label: 'Call to Action',
  maxCopyLength: 150,
  maxSuggestKeywords: 2,
  disableAnnotations: true,
});
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.reinforcementStatement, {
  label: 'Reinforcement Statement',
});
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.benefits, {
  label: 'Benefits',
  maxCopyLength: 150,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});
ASSET_PROPS_MAP.add(LANDING_ASSET_TYPE.features, {
  label: 'Features',
  maxCopyLength: 150,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 2,
});

/* Email asset types props */
ASSET_PROPS_MAP.add(ASSET_TYPE.emailMixture, {
  label: 'Email',
  maxSuggestKeywords: 1,
  supportsSuggestKeywords: true,
});

ASSET_PROPS_MAP.add(EMAIL_ASSET_TYPE.emailSubject, {
  label: 'Email Subject Line',
  maxCopyLength: 40,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 1,
});

ASSET_PROPS_MAP.add(EMAIL_ASSET_TYPE.emailBody, {
  label: 'General Email Body',
  maxCopyLength: 600,
  maxSuggestKeywords: 1,
  disableScores: true,
});

ASSET_PROPS_MAP.add(EMAIL_ASSET_TYPE.emailColdOutreachBody, {
  label: 'Email Body',
});
ASSET_PROPS_MAP.add(EMAIL_ASSET_TYPE.emailPromotionalBody, {
  label: 'Email Body',
});

/* Blog post asset types props */
ASSET_PROPS_MAP.add(BLOG_POST_ASSET_TYPE.blogPostOutline, {
  label: 'Outline',
  disableScores: true,
  disableAnnotations: true,
});
ASSET_PROPS_MAP.add(BLOG_POST_ASSET_TYPE.blogPostTitle, {
  label: 'Title',
  maxCopyLength: 80,
  disableAnnotations: true,
});
ASSET_PROPS_MAP.add(BLOG_POST_ASSET_TYPE.blogPostIntroParagraph, {
  label: 'Intro Paragraph',
  maxCopyLength: 600,
  supportsSuggestKeywords: true,
  maxSuggestKeywords: 3,
  disableAnnotations: true,
});
ASSET_PROPS_MAP.add(BLOG_POST_ASSET_TYPE.blogPostContent, {
  label: 'Content',
});
ASSET_PROPS_MAP.add(BLOG_POST_ASSET_TYPE.blogPostList, {
  label: 'List',
});

/* SMS asset types props */
ASSET_PROPS_MAP.add(SMS_ASSET_TYPE.sms, {
  label: 'Promotional SMS',
  maxCopyLength: 480,
  strictCopyLengthCheck: true,
  supportsSuggestLink: true,
});

/* Canvas asset types props */
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasContentImprover, {
  label: 'Content Improver',
  maxCopyLength: 4800,
  strictCopyLengthCheck: true,
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasEli5, {
  label: 'Explain it to a 5th Grader',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasSentenceExpander, {
  label: 'Sentence Expander',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasAida, {
  label: 'AIDA',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasPas, {
  label: 'PAS',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasBab, {
  label: 'BAB',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasPerfectHeadline, {
  label: 'Perfect Headline',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasPersuasiveBulletPoint, {
  label: 'Powerful Bullet Points',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasFeatureToBenefitsPractical, {
  label: 'Features to Benefits',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasValueProposition, {
  label: 'Value Proposition',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasProductDescriptionList, {
  label: 'Product Description — List',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasProductDescriptionRelaxed, {
  label: 'Product Description — Paragraph',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasBlogIdeas, {
  label: 'Blog Post Topic Ideas',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasVideoIdeas, {
  label: 'Video Topic Ideas',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.canvasFreestyle, {
  label: 'Create A New Use Case',
  maxCopyLength: 3000,
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.productReview, {
  label: 'Product Review',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.freeTextHeadline, {
  label: 'Headline',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.freeTextTopic, {
  label: 'Keywords',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.freeTextBody, {
  label: 'Body',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.personalBio, {
  label: 'Personal Bio',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.companyBio, {
  label: 'Company Bio',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.engagingQuestions, {
  label: 'Engaging Questions',
});
ASSET_PROPS_MAP.add(CANVAS_ASSET_TYPE.quoraAnswers, {
  label: 'Quora Answers',
});

ASSET_PROPS_MAP.add(EDITOR_MARKED_ASSET_TYPE.editorParagraph, {
  label: 'Editor Paragraph',
});
ASSET_PROPS_MAP.add(EDITOR_MARKED_ASSET_TYPE.editorRephrase, {
  label: 'Editor Rephrase',
});

/**
 * Deprecated asset types props
 */
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.subHeading, {
  label: 'Sub Heading (deprecated)',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.subHeadingSupportingCopy, {
  label: 'Supporting Copy (deprecated)',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.consumerBenefits, {
  label: 'Product Description',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasFacebookAd, {
  label: 'Facebook Ad',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasGoogleAd, {
  label: 'Google Ad',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasTwitterAd, {
  label: 'X Ad',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasLinkedinTextAd, {
  label: 'Linkedin Text Ad',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasPinterestAd, {
  label: 'Pinterest Ad',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasLandingPage, {
  label: 'Landing Page Copy',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasLandingPageSeo, {
  label: 'SEO Meta Description',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasFacebookSocial, {
  label: 'Facebook Post',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasInstagramSocial, {
  label: 'Instagram Caption',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasLinkedinSocial, {
  label: 'LinkedIn Post',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasTwitterSocial, {
  label: 'X Post',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasYoutubeSocial, {
  label: 'YouTube Description',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasPinterestPinSocial, {
  label: 'Pinterest Pin',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasColdEmail, {
  label: 'Cold Email',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasPromotionalEmail, {
  label: 'Promotional Email',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasEmailSubjectLine, {
  label: 'Email Subject Line',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasSms, {
  label: 'Promotional SMS',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasAmazonProductDescription, {
  label: 'Amazon Product Listing - Paragraph',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasAmazonFeatureList, {
  label: 'Amazon Product Listing - Feature List',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasWalmartProductDescription, {
  label: 'Walmart Product Listing - Paragraph',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.canvasWalmartFeatureList, {
  label: 'Walmart Product Listing - Feature List',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.emailSubjectLine, {
  label: 'Subject Line',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.emailPromotionalSubjectLine, {
  label: 'Subject Line',
});
ASSET_PROPS_MAP.add(DEPRECATED_ASSET_TYPE.emailColdOutreachSubjectLine, {
  label: 'Subject Line',
});

const getAssetProp = (type, propName, defaultValue = null) => {
  const props = ASSET_PROPS_MAP.get(type);
  if (props && props[propName] != null) {
    return props[propName];
  }
  return defaultValue;
};

export const getAssetChannel = (assetType) => getAssetProp(assetType, 'channel', '');

export const getAssetLabel = (assetType) => getAssetProp(assetType, 'label', '');

export const getAssetTypeMaxSuggestKeywords = (assetType) =>
  getAssetProp(assetType, 'maxSuggestKeywords', 1);

export const getAssetTypeMaxWordsPerKeyword = (assetType) =>
  getAssetProp(assetType, 'maxWordsPerKeyword', 3);

export const isAssetScoresDisabled = (assetType) => getAssetProp(assetType, 'disableScores', false);

export const isAssetSupportSuggestKeywords = (assetType) =>
  getAssetProp(assetType, 'supportsSuggestKeywords', false);

export const isAssetSupportSuggestLink = (assetType) =>
  getAssetProp(assetType, 'supportsSuggestLink', false);

export const isAssetDisabledAnnotations = (assetType) =>
  getAssetProp(assetType, 'disableAnnotations', false);

export const getAssetTypeMaxCopyLength = (assetType) => getAssetProp(assetType, 'maxCopyLength', 0);

export const shouldAssetCheckCopyLength = (assetType) =>
  getAssetProp(assetType, 'strictCopyLengthCheck', false);

const createOption = (assetType) => ({
  value: assetType,
  label: getAssetLabel(assetType),
});

export const getAssetTypeForProject = (projectType) => {
  switch (projectType) {
    case PROJECT_TYPE.continuousOptimization:
    default:
      return [
        createOption(LANDING_ASSET_TYPE.landingHeadline),
        createOption(LANDING_ASSET_TYPE.landingCopy),
        createOption(LANDING_ASSET_TYPE.landingCTA),
        createOption(LANDING_ASSET_TYPE.landingProductName),
      ];
  }
};

export const getAssetChannelLabel = (assetType) => getChannelLabel(getAssetChannel(assetType));
