import { useCallback, useMemo } from 'react';

import { formatBrandVoiceChildRoute } from 'src/lib/routing';
import { createStateSelectorHook } from 'src/lib/sweetState';
import { ROUTES } from 'src/routes';
import {
  BrandTermsStore,
  useBrandVoiceIdsForBrandTerms,
  useBrandVoicesAssets,
  useCurrentProject,
} from 'src/store';
import { useBrandVoices } from '../useBrandVoices';
import { useHistoryPushOrInfinityOpen } from '../useHistoryPushOrInfinityOpen';
import { useSetupProjectVocabulary } from '../useSetupProjectVocabulary';

const useBrandTermsState = createStateSelectorHook(BrandTermsStore, ['appliedDetectedTermIds']);

export const useBrandTermsProps = ({
  detectedBrandTermsData,
  onApplyDetectedBrandTerm,
  onDetectedBrandTermHover,
  onDetectedBrandTermClick,
  disabled,
  loading,
}) => {
  const currentProject = useCurrentProject();
  const { brandVoices: availableBrandVoices, defaultBrandVoice } = useBrandVoices();
  const { brandTerms: availableBrandTerms } = useBrandVoicesAssets();
  const { brandVoiceIdsForBrandTerms, brandRulesEnabled } = useBrandVoiceIdsForBrandTerms();
  const historyPushOrInfinityOpen = useHistoryPushOrInfinityOpen();
  const setupProjectVocabulary = useSetupProjectVocabulary();

  // Filter out detected terms that have already been applied
  const { appliedDetectedTermIds } = useBrandTermsState();
  const notAppliedDetectedTerms = useMemo(
    () => detectedBrandTermsData?.items?.filter((term) => !appliedDetectedTermIds.has(term.id)),
    [appliedDetectedTermIds, detectedBrandTermsData?.items]
  );

  const availableBrandTermsCount = availableBrandTerms?.length || 0;
  const projectType = currentProject?.type;

  const activeBrandVoices = useMemo(() => {
    if (!brandRulesEnabled) {
      return [];
    }
    return availableBrandVoices?.filter((brandVoice) =>
      brandVoiceIdsForBrandTerms?.includes(brandVoice.id)
    );
  }, [availableBrandVoices, brandRulesEnabled, brandVoiceIdsForBrandTerms]);

  // Handle "empty terms" CTA click, either open "vocabulary" settings or navigate to "brand terms" page
  const handleEmptyTermsClick = useCallback(() => {
    if (availableBrandTermsCount > 0) {
      setupProjectVocabulary();
    } else {
      const route = formatBrandVoiceChildRoute(defaultBrandVoice.id, ROUTES.BRAND_TERMS);
      historyPushOrInfinityOpen(route);
    }
  }, [
    availableBrandTermsCount,
    defaultBrandVoice,
    historyPushOrInfinityOpen,
    setupProjectVocabulary,
  ]);

  return useMemo(
    () => ({
      projectType,
      availableBrandTermsCount,
      activeBrandVoices,
      detectedBrandTerms: notAppliedDetectedTerms,
      appliedDetectedTermIds,
      onApplyBrandTerm: onApplyDetectedBrandTerm,
      onBrandTermHover: onDetectedBrandTermHover,
      onBrandTermClick: onDetectedBrandTermClick,
      onSettingsClick: availableBrandTermsCount > 0 ? setupProjectVocabulary : null,
      onEmptyTermsClick: handleEmptyTermsClick,
      loading,
      disabled,
    }),
    [
      projectType,
      availableBrandTermsCount,
      activeBrandVoices,
      notAppliedDetectedTerms,
      appliedDetectedTermIds,
      onApplyDetectedBrandTerm,
      onDetectedBrandTermHover,
      onDetectedBrandTermClick,
      setupProjectVocabulary,
      handleEmptyTermsClick,
      loading,
      disabled,
    ]
  );
};
