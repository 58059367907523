import React from 'react';
import PropTypes from 'prop-types';

import { CustomTooltip } from 'src/components/CustomTooltip';
import { DetectedBrandTermTooltipContent } from '../DetectedBrandTermTooltipContent';
import { InsightsLeafDefault } from '../InsightsLeafDefault';
import { useDetectedBrandTermsAppliedIds } from '../../../../lib';
import { useStyles } from './useStyles';

export const InsightsLeafBrandTerm = ({ attributes, leaf, children }) => {
  const styles = useStyles();

  const appliedDetectedTermIds = useDetectedBrandTermsAppliedIds();

  const leafElement = (
    <InsightsLeafDefault
      attributes={attributes}
      leaf={leaf}
    >
      {children}
    </InsightsLeafDefault>
  );

  // If term was just applied, we might still have tooltip opened - this removes it
  if (!appliedDetectedTermIds || appliedDetectedTermIds.has(leaf.data.id)) {
    return leafElement;
  }

  return (
    <CustomTooltip
      classes={{ tooltip: styles.tooltip }}
      title={<DetectedBrandTermTooltipContent detectedBrandTerm={leaf.data} />}
      arrow={false}
      interactive
    >
      {leafElement}
    </CustomTooltip>
  );
};

InsightsLeafBrandTerm.propTypes = {
  attributes: PropTypes.shape({}),
  leaf: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.shape(),
    }),
  }),
  children: PropTypes.shape({}),
};

InsightsLeafBrandTerm.defaultProps = {
  attributes: null,
  leaf: null,
  children: null,
};
