import React, { useMemo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import { generateSvgId } from 'src/lib/svg';

const ProductDescriptionParagraph = React.forwardRef((props, ref) => {
  const svgId = useMemo(() => generateSvgId(), []);

  return (
    <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
      <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
      <path
        d="M16.3178 8.10352H7V22.9481C7 22.9481 14.7499 22.9481 20.7027 22.9481V19.1523"
        stroke="white"
        strokeWidth="0.897436"
      />
      <path
        d="M9.73828 12.9395H13.396"
        stroke="white"
        strokeWidth="0.897436"
        strokeLinejoin="round"
      />
      <path
        d="M9.73828 15.8408H16.7489"
        stroke="white"
        strokeWidth="0.897436"
        strokeLinejoin="round"
      />
      <path
        d="M9.73828 18.7432H16.7489"
        stroke="white"
        strokeWidth="0.897436"
        strokeLinejoin="round"
      />
      <g clipPath={`url(#${svgId})`}>
        <path
          d="M21.4865 10.7634C20.8891 10.7634 20.3281 10.5194 19.9066 10.0762C19.4852 9.63333 19.2532 9.04344 19.2532 8.41523C19.2532 8.24305 19.1214 8.10352 18.9588 8.10352C18.7962 8.10352 18.6644 8.24305 18.6644 8.41523C18.6644 9.04344 18.4324 9.63333 18.011 10.0762C17.5895 10.5194 17.0285 10.7634 16.4311 10.7634C16.2685 10.7634 16.1367 10.9029 16.1367 11.0751C16.1367 11.2472 16.2685 11.3868 16.4311 11.3868C17.6625 11.3868 18.6644 12.4433 18.6644 13.7418C18.6644 13.914 18.7962 14.0535 18.9588 14.0535C19.1214 14.0535 19.2532 13.914 19.2532 13.7418C19.2532 12.4433 20.2551 11.3868 21.4865 11.3868C21.6491 11.3868 21.7809 11.2472 21.7809 11.0751C21.7809 10.9029 21.6491 10.7634 21.4865 10.7634Z"
          fill="white"
        />
        <path
          d="M21.0014 8.32299C21.639 8.32299 22.1578 8.86808 22.1578 9.53802C22.1578 9.71011 22.2896 9.84973 22.4522 9.84973C22.6148 9.84973 22.7466 9.71011 22.7466 9.53802C22.7466 8.86589 23.2654 8.3191 23.903 8.3191C24.0656 8.3191 24.1974 8.17948 24.1974 8.00739C24.1974 7.83522 24.0656 7.69568 23.903 7.69568C23.2654 7.69568 22.7466 7.15059 22.7466 6.48066C22.7466 6.30848 22.6148 6.16895 22.4522 6.16895C22.2896 6.16895 22.1578 6.30848 22.1578 6.48066C22.1578 7.15278 21.639 7.69957 21.0014 7.69957C20.8388 7.69957 20.707 7.83911 20.707 8.01128C20.707 8.18346 20.8388 8.32299 21.0014 8.32299Z"
          fill="white"
        />
        <path
          d="M20.0202 15.6054C20.5208 15.6054 20.9282 16.0351 20.9282 16.5632C20.9282 16.6989 21.0317 16.809 21.1593 16.809C21.287 16.809 21.3905 16.6989 21.3905 16.5632C21.3905 16.0334 21.7978 15.6023 22.2984 15.6023C22.4261 15.6023 22.5296 15.4922 22.5296 15.3566C22.5296 15.2208 22.4261 15.1108 22.2984 15.1108C21.7978 15.1108 21.3905 14.6811 21.3905 14.153C21.3905 14.0172 21.287 13.9072 21.1593 13.9072C21.0317 13.9072 20.9282 14.0172 20.9282 14.153C20.9282 14.6828 20.5208 15.1139 20.0202 15.1139C19.8925 15.1139 19.7891 15.2239 19.7891 15.3596C19.7891 15.4954 19.8925 15.6054 20.0202 15.6054Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={svgId}>
          <rect
            width="11.8756"
            height="10.6397"
            fill="white"
            transform="translate(12.125 6.16895)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});

export default React.memo(ProductDescriptionParagraph);
