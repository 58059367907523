import { useEffectOnce } from 'src/lib/hooks';
import * as events from 'src/lib/events';
import { useTrackingEvent } from './useTrackingEvent';

export const useTrackApplicationLoaded = () => {
  const trackEvent = useTrackingEvent();

  useEffectOnce(() => {
    trackEvent(events.SYSTEM.applicationLoaded, null, { noAuth: true });
  });
};
