import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { useCustomFeatureLimitsData } from 'src/store';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { UPGRADE_DIALOG_ACTIONS_ORIGIN } from 'src/data/common';
import { FeatureLimitedProgressBar, RowsUsageHeader } from 'src/views/FeatureLimitedProgressBar';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useChannelAccounts, useUserChannelAccounts } from 'src/services';
import { CHANNEL } from 'src/data/channel';
import {
  EMAILS_CHANNELS_AND_RESOURCES,
  INTEGRATION_CHANNELS,
  SOCIAL_CHANNELS,
} from 'src/data/performance';
import { getChannelPerformanceLabel } from 'src/data/channel';
import { ChannelIcon } from 'src/components/ChannelIcon';
import { AccountsTable } from 'src/components/AccountsTable';
import { ActionsMenu } from './components/ActionsMenu';
import { useHandlers } from './useHandlers';
import { useViewState } from './useViewState';
import { useStyles } from './useStyles';

export const AdAccountsSelectionPopup = ({
  open,
  channel,
  onClose,
  isTrainingModel,
  brandVoiceId,
  customModel,
  onConnectSuccess,
  isTalkingPointsView,
}) => {
  const styles = useStyles();

  const { loadingAccounts, accountsSortField, accountsSortDirection, connectingToChannel } =
    useViewState(channel);
  const { limit, currentCount, limitedAccountData } = useCustomFeatureLimitsData(
    FEATURE_LIMITS.resourcesRowsPerCustomer
  );
  const limitData = { limit, currentCount };
  const isFeatureLimited = limit && (!!limitedAccountData?.accountId || limit <= currentCount);
  const item = SOCIAL_CHANNELS.includes(channel) ? 'page' : 'account';

  const { handleSortChange, handleDisconnect, handleAccountClick } = useHandlers({
    channel,
    onConnectSuccess,
    onClose,
    isTrainingModel,
    brandVoiceId,
    isFeatureLimited,
    customModel,
    isTalkingPointsView,
  });
  const { connectedAccounts } = useChannelAccounts();
  const { disconnectedAccounts } = useUserChannelAccounts();

  const channelConnectedAccounts = useMemo(() => {
    if (channel === CHANNEL.emailChannel) {
      const connectedFileResources = EMAILS_CHANNELS_AND_RESOURCES.reduce((acc, channel) => {
        const resources = connectedAccounts[channel] || [];
        return [...acc, ...resources];
      }, []);
      return [...(connectedAccounts?.[channel] || []), ...connectedFileResources];
    }

    return connectedAccounts?.[channel] || [];
  }, [connectedAccounts, channel]);

  const channelDisconnectedAccounts = useMemo(() => {
    const channelConnectedAccountIds = channelConnectedAccounts.map(
      (channelConnectedAccounts) => channelConnectedAccounts.id
    );

    return (disconnectedAccounts?.[channel] || []).filter(
      (disconnectedAccount) => !channelConnectedAccountIds.includes(disconnectedAccount.id)
    );
  }, [disconnectedAccounts, channel, channelConnectedAccounts]);

  return (
    <Dialog
      open={open}
      classes={{ paper: styles.root }}
      onClose={onClose}
      maxWidth="md"
      data-testid="AdAccountsSelectionPopup"
    >
      <DialogTitle
        className={styles.dialogTitle}
        disableTypography
      >
        <CustomTypography
          className={styles.popupTitle}
          variant={TYPOGRAPHY_VARIANT.h3}
        >
          {!isTrainingModel && (
            <ChannelIcon
              className={styles.channelIcon}
              channel={channel}
            />
          )}
          {isTrainingModel
            ? 'Train a custom model'
            : `Select ${getChannelPerformanceLabel(channel)} account to integrate with Anyword`}
        </CustomTypography>
        {!isTrainingModel && <ActionsMenu onRemoveClick={handleDisconnect} />}
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        {isTrainingModel && (
          <CustomTypography
            variant={TYPOGRAPHY_VARIANT.paragraph16}
            className={styles.customModelSubtitle}
          >
            Select the data you want train the model on
          </CustomTypography>
        )}
        <AccountsTable
          accounts={channelDisconnectedAccounts}
          connectedAccounts={channelConnectedAccounts}
          anyAccountConnected={channelConnectedAccounts.length > 0}
          onAccountClick={handleAccountClick}
          onSortChange={handleSortChange}
          sortField={accountsSortField}
          sortDirection={accountsSortDirection}
          item={item}
          channel={channel}
          limitedAccountData={limitedAccountData}
          loadingAccounts={loadingAccounts || connectingToChannel}
          isTrainingModel={isTrainingModel}
          isTalkingPointsView={isTalkingPointsView}
        />
      </DialogContent>
      <DialogActions>
        {isFeatureLimited && (
          <FeatureLimitedProgressBar
            feature={FEATURE_LIMITS.resourcesRowsPerCustomer}
            limitData={limitData}
            upgradeActionOrigin={UPGRADE_DIALOG_ACTIONS_ORIGIN.cipPageConnectDialog}
            headerLabel={
              <RowsUsageHeader
                connectedAccounts={connectedAccounts}
                limitData={limitData}
              />
            }
          />
        )}
        <Button
          className={styles.buttonCancel}
          onClick={onClose}
          variant="text"
          color="primary"
          aria-label="Cancel"
          data-testid="AddAccountSelectionPopup__CancelButton"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdAccountsSelectionPopup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  channel: PropTypes.oneOf(INTEGRATION_CHANNELS).isRequired,
  onClose: PropTypes.func.isRequired,
  isTrainingModel: PropTypes.bool,
  brandVoiceId: PropTypes.string,
  customModel: PropTypes.shape({}),
  onConnectSuccess: PropTypes.func,
  isTalkingPointsView: PropTypes.bool,
};
AdAccountsSelectionPopup.defaultProps = {
  className: null,
  open: false,
  isTrainingModel: false,
  brandVoiceId: null,
  onConnectSuccess: null,
  isTalkingPointsView: false,
};
