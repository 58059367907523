import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateProject(
    $id: Int!
    $name: String
    $projectFolderId: ID
    $updateProjectFolder: Boolean
  ) {
    updateProject(
      id: $id
      name: $name
      projectFolderId: $projectFolderId
      updateProjectFolder: $updateProjectFolder
    ) {
      id
      name
      projectFolder {
        id
      }
    }
  }
`;

export const updateProject = ({ id, name, projectFolderId, updateProjectFolder }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { id, name, projectFolderId, updateProjectFolder },
  }).then(({ data }) => getSingleProp(data));
