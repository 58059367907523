import { findKey } from 'lodash';
import { LANGUAGE, INFORMAL_LANGUAGE, HIDDEN_LANGUAGE } from './const';

const LANGUAGES = Object.values(LANGUAGE);
const HIDDEN_LANGUAGES = Object.values(HIDDEN_LANGUAGE);

export const LANGUAGE_LABELS = {
  [LANGUAGE.bg]: 'Bulgarian',
  [LANGUAGE.cs]: 'Czech',
  [LANGUAGE.da]: 'Danish',
  [LANGUAGE.de]: 'German (formal)',
  [LANGUAGE.de_in]: 'German (informal)',
  [LANGUAGE.el]: 'Greek',
  [LANGUAGE.en_us]: 'English (US)',
  [LANGUAGE.en_gb]: 'English (UK)',
  [LANGUAGE.es]: 'Spanish (formal)',
  [LANGUAGE.es_in]: 'Spanish (informal)',
  [LANGUAGE.et]: 'Estonian',
  [LANGUAGE.fi]: 'Finnish',
  [LANGUAGE.fr]: 'French (formal)',
  [LANGUAGE.fr_in]: 'French (informal)',
  [LANGUAGE.hu]: 'Hungarian',
  [LANGUAGE.id]: 'Indonesian',
  [LANGUAGE.it]: 'Italian (formal)',
  [LANGUAGE.it_in]: 'Italian (informal)',
  [HIDDEN_LANGUAGE.he]: 'Hebrew',
  [LANGUAGE.ja]: 'Japanese',
  [LANGUAGE.lt]: 'Lithuanian',
  [LANGUAGE.lv]: 'Latvian',
  [LANGUAGE.nb]: 'Norwegian (Bokmål)',
  [LANGUAGE.nl]: 'Dutch (formal)',
  [LANGUAGE.nl_in]: 'Dutch (informal)',
  [LANGUAGE.pl]: 'Polish (formal)',
  [LANGUAGE.pl_in]: 'Polish (informal)',
  [LANGUAGE.pt_pt]: 'Portuguese (formal)',
  [LANGUAGE.pt_pt_in]: 'Portuguese (informal)',
  [LANGUAGE.pt_br]: 'Portuguese (BR)(formal)',
  [LANGUAGE.pt_br_in]: 'Portuguese (BR)(informal)',
  [LANGUAGE.ro]: 'Romanian',
  [LANGUAGE.ru]: 'Russian (formal)',
  [LANGUAGE.ru_in]: 'Russian (informal)',
  [LANGUAGE.sk]: 'Slovak',
  [LANGUAGE.sl]: 'Slovenian',
  [LANGUAGE.sv]: 'Swedish',
  [LANGUAGE.th]: 'Thai',
  [LANGUAGE.uk]: 'Ukrainian',
  [LANGUAGE.vi]: 'Vietnamese',
  [LANGUAGE.zh]: 'Chinese',
};

export const LANGUAGE_LABELS_WITH_EMOJIS = {
  [LANGUAGE.bg]: '🇧🇬 Bulgarian',
  [LANGUAGE.cs]: '🇨🇿 Czech',
  [LANGUAGE.da]: '🇩🇰 Danish',
  [LANGUAGE.de]: '🇩🇪 German (formal)',
  [LANGUAGE.de_in]: '🇩🇪 German (informal)',
  [LANGUAGE.el]: '🇬🇷 Greek',
  [LANGUAGE.en_us]: '🇺🇸 English',
  [LANGUAGE.en_gb]: '🇬🇧 English UK',
  [LANGUAGE.es]: '🇪🇸 Spanish (formal)',
  [LANGUAGE.es_in]: '🇪🇸 Spanish (informal)',
  [LANGUAGE.et]: '🇪🇪 Estonian',
  [LANGUAGE.fi]: '🇫🇮 Finnish',
  [LANGUAGE.fr]: '🇫🇷 French (formal)',
  [LANGUAGE.fr_in]: '🇫🇷 French (informal)',
  [LANGUAGE.hu]: '🇭🇺 Hungarian',
  [LANGUAGE.id]: '🇮🇩 Indonesian',
  [LANGUAGE.it]: '🇮🇹 Italian (formal)',
  [LANGUAGE.it_in]: '🇮🇹 Italian (informal)',
  [HIDDEN_LANGUAGE.he]: '🇮🇱 Hebrew',
  [LANGUAGE.ja]: '🇯🇵 Japanese',
  [LANGUAGE.lt]: '🇱🇹 Lithuanian',
  [LANGUAGE.lv]: '🇱🇻 Latvian',
  [LANGUAGE.nb]: '🇳🇴 Norwegian (Bokmål)',
  [LANGUAGE.nl]: '🇳🇱 Dutch (formal)',
  [LANGUAGE.nl_in]: '🇳🇱 Dutch (informal)',
  [LANGUAGE.pl]: '🇵🇱 Polish (formal)',
  [LANGUAGE.pl_in]: '🇵🇱 Polish (informal)',
  [LANGUAGE.pt_pt]: '🇵🇹 Portuguese (formal)',
  [LANGUAGE.pt_pt_in]: '🇵🇹 Portuguese (informal)',
  [LANGUAGE.pt_br]: '🇧🇷 Portuguese (BR)(formal)',
  [LANGUAGE.pt_br_in]: '🇧🇷 Portuguese (BR)(informal)',
  [LANGUAGE.ro]: '🇷🇴 Romanian',
  [LANGUAGE.ru]: '🇷🇺 Russian (formal)',
  [LANGUAGE.ru_in]: '🇷🇺 Russian (informal)',
  [LANGUAGE.sk]: '🇸🇰 Slovak',
  [LANGUAGE.sl]: '🇸🇮 Slovenian',
  [LANGUAGE.sv]: '🇸🇪 Swedish',
  [LANGUAGE.th]: '🇹🇭 Thai',
  [LANGUAGE.uk]: '🇺🇦 Ukrainian',
  [LANGUAGE.vi]: '🇻🇳 Vietnamese',
  [LANGUAGE.zh]: '🇨🇳 Chinese',
};

const TARGET_FORMALITY = {
  formal: 'more', // backend value
  informal: 'less', // backend value
};

export const LANGUAGE_BACKEND_EN = 'en';
export const LANGUAGE_BACKEND_PT = 'pt';

export const LANGUAGE_TARGET_FORMALITY = {
  [LANGUAGE.de]: TARGET_FORMALITY.formal,
  [LANGUAGE.de_in]: TARGET_FORMALITY.informal,
  [LANGUAGE.nl]: TARGET_FORMALITY.formal,
  [LANGUAGE.nl_in]: TARGET_FORMALITY.informal,
  [LANGUAGE.fr]: TARGET_FORMALITY.formal,
  [LANGUAGE.fr_in]: TARGET_FORMALITY.informal,
  [LANGUAGE.it]: TARGET_FORMALITY.formal,
  [LANGUAGE.it_in]: TARGET_FORMALITY.informal,
  [LANGUAGE.pl]: TARGET_FORMALITY.formal,
  [LANGUAGE.pl_in]: TARGET_FORMALITY.informal,
  [LANGUAGE.ru]: TARGET_FORMALITY.formal,
  [LANGUAGE.ru_in]: TARGET_FORMALITY.informal,
  [LANGUAGE.es]: TARGET_FORMALITY.formal,
  [LANGUAGE.es_in]: TARGET_FORMALITY.informal,
  [LANGUAGE.pt_pt]: TARGET_FORMALITY.formal,
  [LANGUAGE.pt_pt_in]: TARGET_FORMALITY.informal,
  [LANGUAGE.pt_br]: TARGET_FORMALITY.formal,
  [LANGUAGE.pt_br_in]: TARGET_FORMALITY.informal,
};

export const getLanguageTargetFormality = (language) => {
  return LANGUAGE_TARGET_FORMALITY[language];
};

export const getLanguageFromTargetFormality = (language, languageTargetFormality) => {
  return languageTargetFormality === TARGET_FORMALITY.informal
    ? findKey(INFORMAL_LANGUAGE, (value) => language === value)
    : language;
};

export const normalizeLanguage = (nonAligned) => {
  return INFORMAL_LANGUAGE[nonAligned] || nonAligned;
};

const LANGUAGE_ALIASES = {
  [LANGUAGE_BACKEND_EN]: LANGUAGE.en_us,
  [LANGUAGE_BACKEND_PT]: LANGUAGE.pt_pt,
};
export const alignLanguage = (nonAligned, languageTargetFormality = null) => {
  if (LANGUAGES.includes(nonAligned)) {
    return languageTargetFormality
      ? getLanguageFromTargetFormality(nonAligned, languageTargetFormality)
      : nonAligned;
  }

  if (HIDDEN_LANGUAGES.includes(nonAligned)) {
    return nonAligned;
  }

  if (LANGUAGE_ALIASES[nonAligned]) {
    return LANGUAGE_ALIASES[nonAligned];
  }

  return null;
};

export const getLanguageLabel = (language) => LANGUAGE_LABELS[language];

export const getLanguageLabelWithEmoji = (language) => LANGUAGE_LABELS_WITH_EMOJIS[language];

export const isNonEnglish = (language) => {
  // Treat "empty" as English
  if (!language) {
    return false;
  }

  return !language.startsWith(LANGUAGE_BACKEND_EN);
};

export const isHebrewLanguage = (language) => language === HIDDEN_LANGUAGE.he;

export const isRtlLanguage = (language) => isHebrewLanguage(language);

export const languageSupportsScoring = (language) =>
  !isNonEnglish(language) || isHebrewLanguage(language);
