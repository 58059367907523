import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { DialogTitle, Typography } from '@material-ui/core';

import { useStyles } from './useStyles';

export const PopupHeader = ({ className, step }) => {
  const styles = useStyles();

  return (
    <DialogTitle className={cx(styles.root, className)} disableTypography>
      <Typography className={styles.steps}>Step {step} of 2</Typography>
      <Typography className={styles.title} component="h2">
        Add audience segment
      </Typography>
    </DialogTitle>
  );
};

PopupHeader.propTypes = {
  className: PropTypes.string,
  step: PropTypes.number,
};

PopupHeader.defaultProps = {
  className: null,
  step: 1,
};
