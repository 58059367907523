import React from 'react';
import PropTypes from 'prop-types';

import { CustomTypography } from 'src/components/CustomTypography';
import { preparePropTypesFromChildComponent } from '../../lib';
import { ChartAudience } from '../ChartAudience';
import { HistogramAgeScore } from '../HistogramAgeScore';
import { HistogramGenderScore } from '../HistogramGenderScore';
import { useStyles } from './useStyles';

export const Histograms = ({
  classes,
  audienceChartProps,
  histogramAgeScoreProps,
  histogramGenderScoreProps,
}) => {
  const styles = useStyles({ classes });

  return (
    <div className={styles.root}>
      <CustomTypography className={styles.boxGroupTitle}>Target audiences</CustomTypography>

      <div className={styles.histograms}>
        {audienceChartProps && (
          <ChartAudience
            className={styles.chartAudience}
            {...audienceChartProps}
          />
        )}

        <div className={styles.histogramsWrapper}>
          <>
            {histogramAgeScoreProps && (
              <HistogramAgeScore
                className={styles.histogramAge}
                {...histogramAgeScoreProps}
              />
            )}

            {histogramGenderScoreProps && (
              <HistogramGenderScore
                className={styles.histogramGender}
                {...histogramGenderScoreProps}
              />
            )}
          </>
        </div>
      </div>
    </div>
  );
};

Histograms.propTypes = {
  classes: PropTypes.shape(),
  audienceChartProps: preparePropTypesFromChildComponent(ChartAudience.propTypes),
  histogramAgeScoreProps: preparePropTypesFromChildComponent(HistogramAgeScore.propTypes),
  histogramGenderScoreProps: preparePropTypesFromChildComponent(HistogramGenderScore.propTypes),
  showBrandVoiceSettings: PropTypes.bool,
};

Histograms.defaultProps = {
  classes: null,
  audienceChartProps: null,
  histogramAgeScoreProps: null,
  histogramGenderScoreProps: null,
  showBrandVoiceSettings: false,
};
