import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    tooltip: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'TruncatedTooltip' }
);
