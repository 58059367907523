export const USER_LOGIN = 'user.login';
export const USER_LOGOUT = 'user.logout';
export const SIGN_IN_CLICK = 'signInButton.click';
export const SIGN_IN_GOOGLE_CLICK = 'signIn.continueWithGoogle.click';
export const SIGN_IN_FAILURE = 'signIn.failure';
export const SIGN_UP_CLICK = 'signUpButton.click';
export const SIGN_UP_GOOGLE_CLICK = 'signUp.continueWithGoogle.click';
export const SIGN_UP_FAILURE = 'signUp.failure';

export const PROJECT_NEW_CLICK = 'project.new.click';
export const PROJECT_EDIT_CLICK = 'project.edit.click';
export const PROJECT_DELETE_CLICK = 'project.delete.click';
export const PROJECT_DELETE_CONFIRM = 'project.delete.confirm.click';
export const PROJECT_DUPLICATE_CLICK = 'project.duplicate.click';

export const BRIEF_EDIT_CLICK = 'brief.edit.click';
export const BRIEF_TYPE_CLICK = 'brief.type.selection.click';
export const BRIEF_CLICK_EXTRACTION = 'brief.extraction.click';
export const BRIEF_EXTRACTION_ERROR = 'brief.extraction.error';
export const BRIEF_MODE_CLICK = 'brief.mode.click';
export const BRIEF_TEXT_EDIT = 'brief.text.edit';
export const BRIEF_CLICK_BACK = 'brief.back.click';
export const BRIEF_SAVE_CLICK = 'brief.save.click';
export const BRIEF_DISCARD_CLICK = 'brief.discard.click';
export const BRIEF_ERROR_LENGTH = 'brief.length.error';
export const BRIEF_FEATURE_ADD_CLICK = 'brief.add.feature.click';
export const BRIEF_FEATURE_REMOVE_CLICK = 'brief.remove.feature.click';
export const BRIEF_COPY_ADD_CLICK = 'brief.add.copy.click';
export const BRIEF_COPY_REMOVE_CLICK = 'brief.remove.copy.click';

export const ONBOARDING_PREFIX = 'walkthrough';
export const ONBOARDING_CLICK_TO_PLATFORM = `${ONBOARDING_PREFIX}.clickToPlatform`;
export const ONBOARDING_CLICK_TO_EXTERNAL = `${ONBOARDING_PREFIX}.clickToExternalSites`;
export const ONBOARDING_TAKE_TOUR_AGAIN = `${ONBOARDING_PREFIX}.takeTheTourAgain.click`;
export const ONBOARDING_FINISH_CREATE_PROJECT = `${ONBOARDING_PREFIX}.createProjectClick`;
export const ONBOARDING_FINISH_TOUR_CLICK = `${ONBOARDING_PREFIX}.finishTourClick`;
export const ONBOARDING_WELCOME = `${ONBOARDING_PREFIX}.welcome`;
export const ONBOARDING_START_CLICK = `${ONBOARDING_PREFIX}.startClick`;
export const ONBOARDING_SKIP_CLICK = `${ONBOARDING_PREFIX}.skipClick`;
export const ONBOARDING_SKIP_CONFIRM = `${ONBOARDING_PREFIX}.skipConfirm`;

export const SUGGESTION_TAB_CLICK = 'suggestionTab.Click';
export const ASSET_SELECT_CLICK = 'asset.select.click';

export const SUGGESTION_APPROVED = 'suggestion.approved';
export const SUGGESTION_REQUESTED = 'suggestion.requested';

export const SUGGEST_CLICK = 'suggest.click';
export const SUGGEST_MODE_SELECT = 'suggestion.mode.select';
export const SUGGEST_KEYWORD_EDIT = 'keyword.text.edit';
export const SUGGEST_PRESET_SELECT = 'keyword.preset.select';
export const SUGGEST_CLEAR_CLICK = 'keyword.clear.click';
export const SUGGEST_PROMOTION_EDIT = 'promotion.text.edit';
export const SUGGEST_PROMOTION_SELECT = 'promotion.preset.select';
export const SUGGEST_PROMOTION_CLEAR = 'promotion.clear.click';

export const RECOMPOSE_CLICK = 'recompose.click';
export const RECOMPOSE_TEXT_EDIT = 'recompose.text.edit';
export const RECOMPOSE_SAVE_CLICK = 'recompose.save.click';

export const VARIATION_SAVE_CLICK = 'variation.save.click';
export const VARIATION_SAVED_REMOVE_CLICK = 'variation.saved.remove.click';
export const VARIATION_TEXT_EDIT = 'variation.text.edit';
export const VARIATION_CLIPBOARD_COPY = 'variation.copyToClipboard';

// Any of these events would be fired only once for consequential series of same events
export const MERGED_EVENTS = [
  BRIEF_TEXT_EDIT,
  SUGGEST_KEYWORD_EDIT,
  SUGGEST_PROMOTION_EDIT,
  RECOMPOSE_TEXT_EDIT,
  VARIATION_TEXT_EDIT,
];
