import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconGeneralPrompt = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 16 16"
      data-fill-none
    >
      <path
        d="M13.9442 2.05604C13.7455 1.85818 13.5095 1.70164 13.25 1.59549C12.9904 1.48933 12.7124 1.43565 12.432 1.43755C12.1516 1.43945 11.8743 1.49689 11.6162 1.60655C11.3581 1.71621 11.1243 1.87592 10.9283 2.07645L2.47 10.5348L1.4375 14.5627L5.46542 13.5296L13.9237 5.07129C14.1243 4.87537 14.2841 4.64163 14.3938 4.38358C14.5035 4.12554 14.561 3.84831 14.5629 3.56792C14.5648 3.28753 14.5111 3.00955 14.4049 2.75005C14.2987 2.49054 14.1421 2.25466 13.9442 2.05604Z"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6869 2.31836L13.6817 5.31319"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.47058 10.5342L5.46833 13.5267"
        stroke="#626266"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
