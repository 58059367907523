import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    talkingPointsWrapper: {
      marginBottom: 'auto',
    },
    talkingPoint: {
      padding: theme.spacing(2, 5),
      height: 73,
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${COLORS.gray.gray90}`,
    },
    rank: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.gray.gray40,
    },
    texts: {
      marginLeft: theme.spacing(3),
      maxWidth: 333,
    },
    title: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.neutral.blackBlue,
    },
    description: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.gray.gray40,
    },
    skeletonsWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(0.25),
    },
    skeleton: {
      borderRadius: theme.shape.borderRadiusMinimal,
    },
    rating: {
      marginLeft: 'auto',
    },
    seeMoreWrapper: {
      marginTop: 'auto',
      padding: theme.spacing(2, 4, 2),
      textAlign: 'right',
    },
    seeMore: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),

      textDecoration: 'none',
    },
    copyButton: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadiusSmall,
    },
    iconCopy: {
      fontSize: theme.typography.pxToRem(16),
      color: COLORS.primary.default,
    },
  }),
  { name: 'TalkingPoints' }
);
