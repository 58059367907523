import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Divider } from '@material-ui/core';

import { ArrowBackChevron } from 'src/assets/icons/ArrowBackChevron';
import { AppLogo, APP_LOGO_VARIANT } from 'src/components/AppLogo';
import { TopNavigationBarItems } from 'src/views/TopNavigationBarItems';
import { useGoBack } from 'src/lib/hooks/useGoBack';
import { useStyles } from './useStyles';

export const NavigationBar = ({
  className,
  children,
  onBackClick,
  displayLogo,
  displayBackButton,
  displayUserItems,
  displayProjectName,
}) => {
  const styles = useStyles();
  const goBack = useGoBack();

  const onBackButtonClick = useCallback(() => {
    // Custom onBackClick handler can be passed, and if it returns 'false' we want to prevent goBack()
    if (onBackClick && !onBackClick()) return;

    goBack();
  }, [goBack, onBackClick]);

  return (
    <div className={cx(styles.root, className)}>
      {displayLogo && (
        <AppLogo
          className={styles.logo}
          variant={APP_LOGO_VARIANT.light}
        />
      )}

      {displayBackButton && (
        <Button
          className={styles.backBtn}
          classes={{ startIcon: styles.startIcon }}
          startIcon={<ArrowBackChevron className={styles.backIcon} />}
          onClick={onBackButtonClick}
          data-testid="navigationBarBackButton"
        >
          Back
        </Button>
      )}

      {children && (
        <>
          {displayProjectName && <Divider className={styles.divider} />}
          {children}
        </>
      )}

      {displayUserItems && <TopNavigationBarItems light />}
    </div>
  );
};

NavigationBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onBackClick: PropTypes.func,
  displayLogo: PropTypes.bool,
  displayBackButton: PropTypes.bool,
  displayUserItems: PropTypes.bool,
  displayProjectName: PropTypes.bool,
};

NavigationBar.defaultProps = {
  className: '',
  children: null,
  onBackClick: null,
  displayLogo: false,
  displayBackButton: false,
  displayUserItems: false,
  displayProjectName: true,
};
