/* eslint-disable no-param-reassign */
import { Range as SlateRange, Node as SlateNode, Transforms, Editor } from 'slate';

import { SLATE_TEXT_FORMATS } from 'src/lib/slate';
import { HEADING_TYPES, LIST_TYPES } from '../const';

export const withNewLine = (editor) => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    if (SlateRange.isCollapsed(editor.selection)) {
      const textNode = SlateNode.get(editor, editor.selection && editor.selection.anchor.path);
      const parentPath = editor.selection && editor.selection.anchor.path.slice(0, -1);
      const parentNode = SlateNode.get(editor, parentPath);
      if (HEADING_TYPES.includes(parentNode.type)) {
        const offsetToSplitAt = editor.selection.anchor.offset;
        const textAfterSplit = textNode.text.substring(offsetToSplitAt);

        const newPath = [...parentPath.slice(0, -1), parentPath[parentPath.length - 1] + 1];
        if (offsetToSplitAt < textNode.text.length) {
          Editor.deleteForward(editor, { unit: 'block' });
        }
        Transforms.insertNodes(
          editor,
          {
            type: SLATE_TEXT_FORMATS.PARAGRAPH,
            children: [{ text: textAfterSplit }],
          },
          { at: newPath }
        );
        Transforms.select(editor, {
          anchor: { path: [...newPath, 0], offset: 0 },
          focus: { path: [...newPath, 0], offset: 0 },
        });
      } else if (/^\n*$/.test(textNode.text) && SLATE_TEXT_FORMATS.LIST_ITEM === parentNode.type) {
        Editor.withoutNormalizing(editor, () => {
          const listPath = parentPath.slice(0, -1);
          const listNode = SlateNode.get(editor, listPath);
          let newPath;
          if (LIST_TYPES.includes(listNode.type) && listNode.children.length === 1) {
            Transforms.removeNodes(editor, { at: listPath });
            newPath = listPath;
          } else {
            Transforms.removeNodes(editor, { at: parentPath });
            newPath = [...parentPath.slice(0, -2), parentPath[parentPath.length - 2] + 1];
          }
          Transforms.insertNodes(
            editor,
            { type: SLATE_TEXT_FORMATS.PARAGRAPH, children: [{ text: '' }] },
            { at: newPath }
          );
          Transforms.select(editor, {
            anchor: { path: [...newPath, 0], offset: 0 },
            focus: { path: [...newPath, 0], offset: 0 },
          });
        });
      } else {
        insertBreak();
      }
    } else {
      insertBreak();
    }
  };

  return editor;
};
