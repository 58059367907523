import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

export const useScrollToTopOnLocationChange = (scrollContainerRef) => {
  const location = useLocation();

  useLayoutEffect(() => {
    if (
      scrollContainerRef.current &&
      typeof scrollContainerRef.current.scrollTo === 'function'
    ) {
      scrollContainerRef.current.scrollTo(0, 0);
    }
  }, [location.pathname, scrollContainerRef]);
};
