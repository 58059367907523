import { createContainer } from 'react-sweet-state';

import { BriefsStore } from './BriefsStore';

export const BriefsStoreContainer = createContainer(BriefsStore, {
  onInit:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
