import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const BlogWizardIcon = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 24 24"
      data-fill-none
    >
      <path
        d="M8.87158 18.875H3.87158C3.54006 18.875 3.22212 18.7433 2.9877 18.5089C2.75328 18.2745 2.62158 17.9565 2.62158 17.625V3.875C2.62158 3.54348 2.75328 3.22554 2.9877 2.99112C3.22212 2.7567 3.54006 2.625 3.87158 2.625H16.3716C16.7031 2.625 17.021 2.7567 17.2555 2.99112C17.4899 3.22554 17.6216 3.54348 17.6216 3.875V9.5M8.87158 6.375H13.8716M6.37158 10.125H13.8716M6.37158 13.875H12.6216M20.8557 14.3917L14.4999 20.75L11.3749 21.375L11.9999 18.25L18.3591 11.8917C18.5226 11.7278 18.7169 11.5978 18.9307 11.5091C19.1446 11.4204 19.3738 11.3748 19.6053 11.3748C19.8368 11.3748 20.0661 11.4204 20.2799 11.5091C20.4938 11.5978 20.688 11.7278 20.8516 11.8917L20.8591 11.8983C21.1892 12.2294 21.3743 12.6781 21.3737 13.1457C21.3731 13.6133 21.1868 14.0614 20.8557 14.3917Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
