import React from 'react';
import PropTypes from 'prop-types';

import { ASSET_TYPE } from 'src/data/asset';
import { RichTextEditor } from 'src/components/RichTextEditor';
import { TEXT_EDITOR_TYPE } from '../../lib';
import { TextEditorRichSlate } from '../TextEditorRichSlate';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  assetType: PropTypes.oneOf(Object.values(ASSET_TYPE)).isRequired,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.string]).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  editorType: PropTypes.oneOf(Object.values(TEXT_EDITOR_TYPE)),
  readOnly: PropTypes.bool,
  detectedBrandTerms: PropTypes.arrayOf(PropTypes.shape()),
  editorRef: PropTypes.shape(),
  setEditor: PropTypes.func,
  onCopy: PropTypes.func,
  onEdit: PropTypes.func,
  editMode: PropTypes.bool,
  isAppModeInfinity: PropTypes.bool,
  loading: PropTypes.bool,
};

const defaultProps = {
  className: null,
  classes: null,
  onChange: null,
  disabled: false,
  editorType: TEXT_EDITOR_TYPE.richSlate,
  readOnly: false,
  detectedBrandTerms: [],
  editorRef: null,
  setEditor: null,
  onCopy: null,
  onEdit: null,
  editMode: false,
  isAppModeInfinity: false,
  loading: false,
};

export const TextEditor = ({
  className,
  classes,
  assetType,
  content,
  onChange,
  disabled,
  editorType,
  readOnly,
  detectedBrandTerms,
  editorRef,
  setEditor,
  onCopy,
  onEdit,
  editMode,
  isAppModeInfinity,
  loading,
}) => (
  <>
    {editorType === TEXT_EDITOR_TYPE.richSlate && (
      <TextEditorRichSlate
        className={className}
        classes={classes}
        ref={editorRef}
        assetType={assetType}
        content={content}
        onChange={onChange}
        detectedBrandTerms={detectedBrandTerms}
        disabled={disabled}
        readOnly={readOnly}
        setEditor={setEditor}
        onCopy={onCopy}
        onEdit={editMode ? null : onEdit}
        editMode={editMode}
        isAppModeInfinity={isAppModeInfinity}
        loading={loading}
      />
    )}

    {editorType === TEXT_EDITOR_TYPE.tinymce && (
      <RichTextEditor
        className={className}
        value={content}
        onChange={onChange}
        disabled={disabled}
        onEdit={editMode ? null : onEdit}
      />
    )}
  </>
);

TextEditor.displayName = 'TextEditor';
TextEditor.propTypes = propTypes;
TextEditor.defaultProps = defaultProps;
