import { useMemo } from 'react';
import { uniqueId } from 'lodash';
import { COLORS } from 'src/data/ui';
import { CUSTOM_MENU_ITEM_TYPE } from 'src/components/CustomMenuItem';
import { getBrandBriefTypeLabel } from 'src/data/brief';
import { IMPORT_BRIEF_SOURCE } from '../views/ProjectDocumentView/const';

const LOADING_ITEMS_COUNT = 4;

const createLoadingItems = (length) =>
  Array.from({ length }).map((_, i) => ({
    id: i,
    type: CUSTOM_MENU_ITEM_TYPE.loading,
  }));

const createEmptyStateItem = (label) => ({
  id: uniqueId('emptyState'),
  type: CUSTOM_MENU_ITEM_TYPE.default,
  label,
  emptyState: true,
});

export const createGroupTitleItem = (label, onClick) => ({
  id: uniqueId('groupTitle'),
  type: CUSTOM_MENU_ITEM_TYPE.default,
  label: (
    <span>
      {label}
      <span
        style={{
          color: COLORS.gray.gray60,
          marginLeft: 4,
          marginRight: 4,
        }}
      >
        •
      </span>
      <span
        onClick={onClick}
        style={{
          color: COLORS.primary.default,
          alignItems: 'center',
          columnGap: 8,
          cursor: 'pointer',
        }}
      >
        Edit
      </span>
    </span>
  ),
  title: true,
});

export const createBriefsLinkItem = (brandVoice, onAddBrief) => ({
  id: uniqueId('add-brief'),
  type: CUSTOM_MENU_ITEM_TYPE.default,
  label: 'New',
  create: true,
  brandVoice,
  onClick: () => {
    onAddBrief(brandVoice);
  },
});

const mapBrandBriefToItem = (onSelect, brandBrief) => ({
  id: brandBrief.id,
  name: brandBrief.name,
  type: CUSTOM_MENU_ITEM_TYPE.default,
  label: brandBrief.name,
  itemType: brandBrief.briefType,
  subtitle: getBrandBriefTypeLabel(brandBrief.briefType),
  body: brandBrief.body,
  onClick: () => onSelect(IMPORT_BRIEF_SOURCE.brandBrief, brandBrief),
});

export const useBrandBriefsMenuItems = ({
  brandVoicesAssets,
  isLoading,
  onSelect,
  onAddBrief,
  onEditBrief,
}) => {
  return useMemo(() => {
    if (isLoading) {
      return createLoadingItems(LOADING_ITEMS_COUNT);
    }

    // Regular brand briefs menu items
    const items = [
      ...(brandVoicesAssets || []).reduce((acc, brandVoice) => {
        const messages = brandVoice.brandBriefs.map((brandBrief) => ({
          ...mapBrandBriefToItem(onSelect, brandBrief),
          brandVoiceName: brandVoice.name,
        }));
        return [...acc, ...messages];
      }, []),
    ];

    if (items.length === 0) {
      items.push(createEmptyStateItem('No messaging bank assets created yet'));
    }
    if (brandVoicesAssets.length === 0) {
      items.push(createEmptyStateItem('No brand voices are accessible'));
    }

    if (onEditBrief) {
      const title = createGroupTitleItem('Messaging bank', onEditBrief);
      items.unshift(title);
    }

    return items;
  }, [isLoading, onSelect, brandVoicesAssets, onEditBrief]);
};
