import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { USER_INVITE_FRAGMENT } from 'src/graphql/user/schema';

const MUTATION = gql`
  mutation DiscardUserInvitation($token: String!) {
    discardUserInvitation(token: $token) {
      ...UserInvite
    }
  }
  ${USER_INVITE_FRAGMENT}
`;

export const discardUserInvitation = (token) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { token },
  }).then(({ data }) => getSingleProp(data));
