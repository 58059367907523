import gql from 'graphql-tag';

import { FRAGMENT_CONTENT_VALIDATION } from 'src/graphql/schema';

export const SAVED_VARIATION_FRAGMENT = gql`
  fragment SavedVariation on SavedVariation {
    id
    assetType
    text
    htmlText
    score
    lastUpdateTime
    ageScoreHistogram
    genderScoreHistogram
    language
    contentSafety {
      ...ContentValidation
    }
    engagementScoreSupportSize
    isScoreLocked
  }
  ${FRAGMENT_CONTENT_VALIDATION}
`;
