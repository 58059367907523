import { useCallback } from 'react';

import { usePerformanceActions } from 'src/store';
import { useCipEvents, useErrorHandler } from 'src/services';
import { ADD_RESOURCE_POPUP } from '../types';
import { usePopups } from '../services';

export const useAddResourcePopup = () => {
  const popups = usePopups();
  const performanceActions = usePerformanceActions();
  const cipEvents = useCipEvents();
  const handleError = useErrorHandler();

  return useCallback(
    ({ resource, type, onHiddenError, pastedFiles, onStartUpload, onStopLoading } = {}) => {
      return new Promise((resolve) => {
        popups.openPopup(ADD_RESOURCE_POPUP, {
          onClose: () => {
            popups.closePopup(ADD_RESOURCE_POPUP);
            resolve();
          },
          onSubmit: async (result) => {
            try {
              cipEvents.trackAddResource();
              resolve({
                ...result,
              });
              performanceActions.queryWorkspaceResources();
              popups.closePopup(ADD_RESOURCE_POPUP);
            } catch (error) {
              popups.closePopup(ADD_RESOURCE_POPUP);
              handleError(error);
              resolve();
            }
          },
          type: type,
          handleError: onHiddenError,
          onStartUpload: onStartUpload,
          onStopLoading: onStopLoading,
          pastedFiles: pastedFiles,
          resource,
        });
      });
    },
    [popups, cipEvents, handleError, performanceActions]
  );
};
