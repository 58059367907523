export const ROUTES = {
  ROOT: '/',

  SIGN_UP: '/signup',
  SIGN_UP_DEPRECATED: '/sign-up',
  SIGN_IN: '/login',
  SIGN_IN_DEPRECATED: '/sign-in',
  RESET_PASSWORD: '/reset-password',
  ACCEPT_INVITE: '/accept-invite',
  ONBOARDING_START: '/onboarding-start',
  DASHBOARD: '/dashboard',
  PROJECTS: '/projects',
  PERSONAS: '/target-audiences',
  INTEGRATIONS_RESOURCES: '/integrations-resources',
  ANALYTICS: '/analytics',
  WEBSITE_AUTOMATION: '/website-automation',
  TALKING_POINTS: '/talking-points',
  CUSTOM_MODELS: '/custom-models',
  BRAND_TERMS: '/brand-terms',
  BRAND_BRIEFS: '/messaging-bank',
  CUSTOM_FORMULA: '/custom-formula',
  TEMPLATE_BUILDER: '/template-builder',
  SAVED_PROMPTS: '/prompts',
  TONE_OF_VOICE: '/tone-of-voice',
  SETTINGS: '/settings',
  NOT_VERIFIED: '/verification',
  MOBILE: '/mobile',
  LOCKED: '/locked',
  BRAND_VOICE: '/brand-voice',
  FILE_VIEWER: '/file-viewer',

  UPGRADE: '/upgrade',
  CHECKOUT: '/upgrade/checkout',

  EXTERNAL_SIGNUP_IFRAME: '/external-signup-iframe',
  EXTERNAL_SIGNUP: '/external-signup',

  LINKEDIN_AUTH_CALLBACK: '/linkedin/callback',
  GOOGLE_AUTH_CALLBACK: '/google/callback',
  TWITTER_AUTH_CALLBACK: '/twitter/callback',
  TWITTER_SOCIAL_AUTH_CALLBACK: '/twitter_social/callback',
  HUBSPOT_AUTH_CALLBACK: '/hubspot/callback',
  SALESFORCE_AUTH_CALLBACK: 'salesforce/callback',
  OAUTH_CALLBACK: '/oauth-callback',
  SAML_CALLBACK: '/saml/callback',
  VERIFY_EMAIL_CALLBACK: '/verify-email-callback',
  MAGIC_LINK_CALLBACK: '/magic-link-callback',
  SHARED: '/shared',
  EXTERNAL_INFINITY_EXTENSION: '/external-infinity-extension',
  EXTENSION_ONBOARDING: '/extension-onboarding',
  EXTENSION_UNINSTALLED: '/extension-uninstalled',
  MULTI_PROMPT_BUILDER: '/multi-prompt-builder',
};

export const ROUTE_PARTS = {
  new: 'new',
};

export const ROUTE_PARAMS = {
  generationTool: 'generation-tool',
  customMultiPromptId: 'custom-multi-prompt-id',
  customMultiPromptGroup: 'custom-multi-prompt-group',
  templateType: 'template-type',
  creation: 'creation',
  createNew: 'create-new',
  datasetId: 'dataset',
  freestyleGenerationToolId: 'custom-generation-tool-id',
  userCreated: 'userCreated',
  invited: 'invited',
  token: 'token',
  view: 'view',
  generateFrom: 'generate-from',
  templatesPopup: 'templates-popup',
  textToImprove: 'text-to-improve',
  keywords: 'keywords',
  createNewScoringModel: 'create-new-scoring-model',
  applicationMode: 'application-mode',
  host: 'host',
  installationId: 'installation-id',
  source: 'source',
  autoClose: 'auto-close',

  newPlan: 'new-plan',
  newTier: 'new-tier',
  planCategory: 'plan_category',
  billingCycle: 'billing_cycle',
  coupon: 'coupon',

  customerId: 'customer_id',
  redirectTo: 'redirect_to',
  channel: 'channel',
  accountId: 'account-id',
  table: 'table',
  scrollTo: 'scrollTo',

  integration: 'integration',
  autoOpen: 'auto-open',
  resources: 'resources',
  checkout: 'checkout',

  folder: 'folder',

  panel: 'panel',

  // deprecated
  assetType: 'asset-type',
  walkthroughSkipped: 'walkthroughSkipped',
};

const THE_LAST_EL_RG = /([a-z\d_-]+)(\/*|)$/i;

export const getTheLastPathElement = (pathname) => {
  const result = pathname.match(THE_LAST_EL_RG);
  return result ? result[0] : '';
};
