import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CO_CAMPAIGN } from './schema';

const MUTATION = gql`
  mutation UpdateCOCampaign(
    $id: ID!
    $trafficRate: Int
    $ctaType: String
    $conversionGoalId: ID
    $cssCtaIdList: [ID!]
  ) {
    updateContinuousOptimizationCampaign(
      id: $id
      trafficRate: $trafficRate
      ctaType: $ctaType
      conversionGoalLinkCallToActionId: $conversionGoalId
      cssCtaIdList: $cssCtaIdList
    ) {
      ...ContinuousOptimizationCampaign
    }
  }
  ${FRAGMENT_CO_CAMPAIGN}
`;

export const updateCOCampaign = ({ id, trafficRate, ctaType, conversionGoalId, cssCtaIdList }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { id, trafficRate, ctaType, conversionGoalId, cssCtaIdList },
  }).then(({ data }) => getSingleProp(data));
