import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query ProjectQuota($projectId: ID!) {
    projectQuota(projectId: $projectId) {
      exceedLimit
    }
  }
`;

export const queryProjectQuota = (projectId) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
    },
  }).then(({ data }) => getSingleProp(data));
