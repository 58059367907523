import { parse as parseQuery } from 'query-string';

const WRAPPER_STYLE = `
  position: absolute; 
  top: -10000px; 
  left: 0;
`;
export const calculateBlockHeight = (content, style) => {
  const div = document.createElement('div');
  div.setAttribute('style', style);
  div.innerHTML = content;

  const wrapper = document.createElement('div');
  wrapper.setAttribute('style', WRAPPER_STYLE);
  wrapper.appendChild(div);

  document.body.appendChild(wrapper);
  const height = div.offsetHeight;
  document.body.removeChild(wrapper);

  return height;
};

const CALCULATE_IMAGE_SIZE_TIMEOUT = 1000;
export const calculateImageSize = (imageUrl) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve({
        width: image.width,
        height: image.height,
      });
    };
    image.src = imageUrl;

    setTimeout(
      () => reject(new Error('Timeout in calculating image size')),
      CALCULATE_IMAGE_SIZE_TIMEOUT
    );
  });

export const getQueryParams = (queryString = '') =>
  parseQuery(queryString || window.location.search);

export const getQueryParamValue = (paramName, queryString = '') => {
  const params = getQueryParams(queryString);
  return params[paramName];
};

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true; // in case `top` is inaccessible, meaning we're in an iframe cross-origin
  }
};
