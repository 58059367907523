import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION } from './schema';

const MUTATION = gql`
  mutation DeleteBlogPostBuilderOutlineSection($id: ID!) {
    deleteBlogPostBuilderOutlineSection(id: $id) {
      ...BlogPostBuilderOutlineSection
    }
  }
  ${FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION}
`;

export const deleteBlogPostBuilderOutlineSection = (id) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
