import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { SLATE_EDITOR_ACTIONS } from 'src/lib/slate';
import { getActionIcon } from './utils';

export const EditorActionIcon = memo(({ className, action }) => {
  const Icon = getActionIcon(action);
  if (!Icon) {
    return null;
  }

  return <Icon className={className} />;
});

EditorActionIcon.propTypes = {
  className: PropTypes.string,
  action: PropTypes.oneOf(SLATE_EDITOR_ACTIONS).isRequired,
};

EditorActionIcon.defaultProps = {
  className: null,
};
