import { uniq } from 'lodash';

import { SLATE_EDITOR_ACTION } from 'src/lib/slate';
import { isTextStyleAction, getEditorActionLabel } from './editorAction';

const DEFAULT_TEXT_STYLE_ACTION = SLATE_EDITOR_ACTION.toggleParagraph;
const createOption = (editorAction) => ({
  value: editorAction,
  label: getEditorActionLabel(editorAction),
});

export const onlyTextStyleOptions = (editorActions) => editorActions.filter(isTextStyleAction);

/**
 * Prepare "text style" options for "select" component
 * @param {SLATE_EDITOR_ACTION[]} editorActions - list of available editor actions
 * @returns {{label: *, value: *}[]} - list of "select" options
 */
export const prepareTextStyleOptions = (editorActions) => {
  // Take only "text style" actions from the full list of available actions
  const availableActions = onlyTextStyleOptions(editorActions);
  // Add default "paragraph" action and remove duplicates (if we already had "paragraph" in there)
  const uniqActions = uniq([DEFAULT_TEXT_STYLE_ACTION, ...availableActions]);

  return uniqActions.map(createOption);
};
