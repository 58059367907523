import { Editor as SlateEditor } from 'slate';

import {
  getDetectedBrandTermType,
  getDetectedBrandTermTypeHighlightMark,
} from 'src/data/brandTerm';
import { createSlateRange } from './queries';

export const createDetectedBrandTermsRangeRefs = (editor, detectedBrandTerms) => {
  if (!detectedBrandTerms || detectedBrandTerms.length === 0) {
    return [];
  }

  const result = [];

  detectedBrandTerms.forEach((detectedBrandTerm) => {
    const { termStartPosition, termEndPosition } = detectedBrandTerm;

    const range = createSlateRange(editor, termStartPosition, termEndPosition);
    if (!range) {
      return;
    }

    const detectedBrandTermType = getDetectedBrandTermType(detectedBrandTerm);
    const rangeRef = SlateEditor.rangeRef(
      editor,
      {
        ...range,
        decoration: getDetectedBrandTermTypeHighlightMark(detectedBrandTermType),
        data: detectedBrandTerm,
      },
      { affinity: 'inward' }
    );
    result.push(rangeRef);
  });

  return result;
};
