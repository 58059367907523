import { useEffect } from 'react';

import { useToneOfVoiceActions } from 'src/store';
import { useErrorHandler } from 'src/services';

export const usePreloadToneOfVoice = (brandVoice) => {
  const handleError = useErrorHandler();
  const toneOfVoiceStoreActions = useToneOfVoiceActions();

  useEffect(() => {
    const fetchToneOfVoice = async () => {
      try {
        await toneOfVoiceStoreActions.queryCustomToneOfVoiceList(brandVoice.id);
      } catch (error) {
        handleError(error);
      }
    };

    if (brandVoice?.id) {
      fetchToneOfVoice();
    }
  }, [brandVoice, handleError, toneOfVoiceStoreActions]);
};
