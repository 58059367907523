import { useNotifyAboutWorkspaceChanges } from './useNotifyAboutWorkspaceChanges';
import { useReactToFullscreenPopups } from './useReactToFullscreenPopups';
import { useSetupExtensionCommunication } from './useSetupExtensionCommunication';
import { useSetupSystemStoreData } from './useSetupSystemStoreData';

export const SupportInfinityExtension = () => {
  useSetupSystemStoreData();
  useSetupExtensionCommunication();
  useReactToFullscreenPopups();
  useNotifyAboutWorkspaceChanges();

  return null;
};
