export class BackendError extends Error {
  constructor(message = '', code = null) {
    super(message);
    this.name = 'BackendError';
    this.message = message;
    this.code = code;
  }
}

export const isBackendError = (error) => error instanceof BackendError;
