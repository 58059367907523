import { EMAILS_CHANNELS_AND_RESOURCES } from 'src/data/performance/const';
import { CHANNEL } from 'src/data/channel';

export const GENERIC_RESOURCE = 'generic_resource';

export const EMAIL_CSV_RESOURCE = {
  emailSubjectLineCsvResource: 'email_subject_csv_resource',
  emailPromotionCsvResource: 'email_promotion_csv_resource',
  emailColdCsvResource: 'email_cold_csv_resource',
};

//TODO - CAN BE REMOVED?
export const RESOURCE_DOCUMENT = {
  ...EMAIL_CSV_RESOURCE,
  emailCsvResource: 'email_csv_resource',
  googleDocs: 'google_docs',
};

export const EMAIL_CSV_RESOURCES = Object.values(EMAIL_CSV_RESOURCE);

export const isEmailCsvResourceDocument = (resourceDocument) =>
  EMAIL_CSV_RESOURCES.includes(resourceDocument);

export const RESOURCE_DOCUMENT_PROPS = {
  [RESOURCE_DOCUMENT.emailCsvResource]: {
    label: 'Email performance report',
  },
  [RESOURCE_DOCUMENT.emailSubjectLineCsvResource]: {
    label: 'Email - subject line performance report',
  },
  [RESOURCE_DOCUMENT.emailPromotionCsvResource]: {
    label: 'Email - promotion performance report',
  },
  [RESOURCE_DOCUMENT.emailColdCsvResource]: {
    label: 'Email - cold performance report',
  },
  [RESOURCE_DOCUMENT.googleDocs]: {
    label: 'Google docs',
  },
};

export const RESOURCE_TAG = {
  general: 'general',
  amazon: 'amazon',
  trustpilot: 'trustpilot',
  homepage: 'home_page',
  productPage: 'product_page',
  landingPage: 'landing_page',
  competitors: 'competitors',
  thoughtLeadership: 'thought_leadership',
  blogPost: 'blog_post',
  generalDocument: 'general_document',
  generalImage: 'general_image',
};

export const RESOURCE_TAG_PROPS = {
  [RESOURCE_TAG.general]: {
    label: 'General',
  },
  [RESOURCE_TAG.amazon]: {
    label: 'Amazon reviews',
  },
  [RESOURCE_TAG.trustpilot]: {
    label: 'Trustpilot reviews',
  },
  [RESOURCE_TAG.homepage]: {
    label: 'Homepage',
  },
  [RESOURCE_TAG.productPage]: {
    label: 'Product information',
  },
  [RESOURCE_TAG.landingPage]: {
    label: 'Landing page',
  },
  [RESOURCE_TAG.competitors]: {
    label: 'Competitor',
  },
  [RESOURCE_TAG.thoughtLeadership]: {
    label: 'Thought leadership',
  },
  [RESOURCE_TAG.blogPost]: {
    label: 'Blog post',
  },
  [RESOURCE_TAG.generalDocument]: {
    label: 'General document',
  },
  [RESOURCE_TAG.generalImage]: {
    label: 'General image',
  },
};

export const getResourceTagLabel = (tag) => {
  return RESOURCE_TAG_PROPS[tag]?.label || tag;
};

export const RESOURCE_CONTENT_TYPE = {
  url: 'url',
  document: 'document',
  image: 'image',
};

export const RESOURCE_CONTENT_TYPE_DIRECT_UPLOAD = {
  document: 'documentDirect',
  img: 'imageDirect',
};

export const RESOURCE_FILE_UPLOAD_ERROR_CODE = {
  fileTooLarge: 'file-too-large',
  fileInvalidType: 'file-invalid-type',
  fileAlreadyExists: 'file-already-exists',
  fileExistsButNotAssociatedToWorkspace: 'file-exists-but-not-associated-to-workspace',
  imgResolutionExceeded: 'img-resolution-exceeded',
  tooManyFiles: 'too-many-files',
};

export const RESOURCE_CONTENT_TYPE_PROPS = {
  [RESOURCE_CONTENT_TYPE.url]: {
    label: 'Webpage',
    nameInputPlaceholder: 'Acme Homepage',
  },
  [RESOURCE_CONTENT_TYPE.document]: {
    label: 'PDF',
    nameInputPlaceholder: 'Enter name',
    fileDescription: 'Supports PDF up to 50MB',
    fileMaxSize: 50_000_000,
    acceptedFileType: {
      'text/pdf': ['.pdf'],
    },
    errors: {
      [RESOURCE_FILE_UPLOAD_ERROR_CODE.fileTooLarge]: {
        title: 'PDF is too large for upload. The maximum allowed size is 50MB.',
      },
      [RESOURCE_FILE_UPLOAD_ERROR_CODE.fileInvalidType]: {
        title: 'File type is not supported. Please upload a valid file.',
      },
      [RESOURCE_FILE_UPLOAD_ERROR_CODE.fileAlreadyExists]: {
        title: 'Upload failed. The PDF document already exists in this account.',
      },
      [RESOURCE_FILE_UPLOAD_ERROR_CODE.fileExistsButNotAssociatedToWorkspace]: {
        title: 'Upload failed. This PDF document already exists in another workspace.',
        description: 'Please go to the workspace settings to grant access and try again.',
      },
    },
  },
  [RESOURCE_CONTENT_TYPE.image]: {
    label: 'Image',
    nameInputPlaceholder: 'Enter name',
    fileDescription: 'Max image size 20MB (JPEG, JPG, PNG only)',
    fileMaxSize: 20_000_000,
    acceptedFileType: {
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
    },
    errors: {
      [RESOURCE_FILE_UPLOAD_ERROR_CODE.fileTooLarge]: {
        title: 'The uploaded image is too big. The maximum file size allowed is 20MB.',
      },
      [RESOURCE_FILE_UPLOAD_ERROR_CODE.fileInvalidType]: {
        title: 'Upload failed. Only JPG, JPEG, and PNG formats are supported.',
      },
      [RESOURCE_FILE_UPLOAD_ERROR_CODE.fileAlreadyExists]: {
        title: 'Upload failed. The image already exists in the workspace.',
      },
      [RESOURCE_FILE_UPLOAD_ERROR_CODE.resolutionExceeded]: {
        title: 'Image upload failed. The maximum resolution supported is 6000x6000 pixels.',
      },
      [RESOURCE_FILE_UPLOAD_ERROR_CODE.fileExistsButNotAssociatedToWorkspace]: {
        title: 'Upload failed. This image already exists in another workspace.',
        description: 'Please go to the workspace settings to grant access and try again.',
      },
    },
  },
};

export const getResourceContentTypeLabel = (contentType) => {
  return RESOURCE_CONTENT_TYPE_PROPS[contentType]?.label || contentType;
};

export const getResourceContentErrorMessage = (contentType, errorCode, options = null) => {
  const error = RESOURCE_CONTENT_TYPE_PROPS[contentType]?.errors?.[errorCode];
  if (!error) {
    return null;
  }

  if (options?.object) {
    return error;
  }

  return error.description ? `${error.title} ${error.description}` : error.title;
};

export const getResourceDocumentErrorMessage = (errorCode, options = null) =>
  getResourceContentErrorMessage(RESOURCE_CONTENT_TYPE.document, errorCode, options);
export const getResourceImageErrorMessage = (errorCode, options = null) =>
  getResourceContentErrorMessage(RESOURCE_CONTENT_TYPE.image, errorCode, options);

export const RESOURCE_STATUS = {
  active: 'active',
  processing: 'processing',
  archived: 'archived',
};

export const getResourceDocumentLabel = (document) => RESOURCE_DOCUMENT_PROPS[document]?.label;

export const getIsEmailChannel = (channel) =>
  EMAILS_CHANNELS_AND_RESOURCES.includes(channel) || channel === CHANNEL.emailChannel;
export const getIsResource = (channel) => EMAIL_CSV_RESOURCES.includes(channel);

export const getCreatedResourceMessage = (resource, availableResources) => {
  return `A resource was successfully added to Anyword${
    availableResources > -1 ? ` (${availableResources - 1} resources left)` : ''
  }: "${resource?.name || resource?.url}"`;
};

export const getContentTypeTag = (contentType) => {
  switch (contentType) {
    case RESOURCE_CONTENT_TYPE.document:
      return RESOURCE_TAG.generalDocument;
    case RESOURCE_CONTENT_TYPE.image:
      return RESOURCE_TAG.generalImage;
    default:
      return RESOURCE_TAG.general;
  }
};

export const resourceChannelToContentType = (channel) => {
  if (channel === CHANNEL.other) {
    return RESOURCE_CONTENT_TYPE.url;
  }
  if (channel === CHANNEL.generalDocument) {
    return RESOURCE_CONTENT_TYPE.document;
  }
  return RESOURCE_CONTENT_TYPE.url;
};

export const isUrlResource = (resource) => resource.contentType === RESOURCE_CONTENT_TYPE.url;
export const isImageResource = (resource) => resource.contentType === RESOURCE_CONTENT_TYPE.image;
export const isDocumentResource = (resource) =>
  resource.contentType === RESOURCE_CONTENT_TYPE.document;
