import { get } from 'lodash';

import { ASSET_TYPE } from 'src/data/asset/const';
import { GENERATION_GUIDANCE_TYPE } from './const';

const GENERATION_GUIDANCE_TYPE_PROPS = {
  [GENERATION_GUIDANCE_TYPE.customerAvatar]: {
    label: 'Persona',
    placeholder: 'e. g. College student',
    description: 'Describe a customer avatar',
    toggle: "Find my customer's avatar from customer reviews instead",
    csPlaceholder: 'e. g. Little league baseball coach',
  },
  [GENERATION_GUIDANCE_TYPE.feature]: {
    label: 'Product feature',
    shortLabel: 'Feature',
    placeholder: 'e. g. Shredded memory foam',
    description: 'Describe a product feature',
    toggle: '+ Select a product feature from customer reviews',
    csPlaceholder: 'https://www.trustpilot.com/review/anyword.com',
    csDescription: 'Select a product feature from customer reviews',
    csToggle: '+ Describe a product feature in your own words',
  },
  [GENERATION_GUIDANCE_TYPE.benefit]: {
    label: 'Product benefit',
    shortLabel: 'Benefit',
    placeholder: 'e. g. Wake up refreshed every morning',
    description: 'Describe a product benefit',
    toggle: '+ Select a product benefit from customer reviews',
    csPlaceholder: 'https://www.trustpilot.com/review/anyword.com',
    csDescription: 'Select a product benefit from customer reviews',
    csToggle: '+ Describe a product benefit in your own words',
  },
  [GENERATION_GUIDANCE_TYPE.pain]: {
    label: 'Pain',
    placeholder: 'e. g. Tossing and turning all night',
    description: 'Describe a customer pain point',
    toggle: '+ Select a pain point from customer reviews',
    csPlaceholder: 'https://www.trustpilot.com/review/anyword.com',
    csDescription: 'Select a pain point from customer reviews',
    csToggle: '+ Describe a customer pain point in your own words',
  },
  [GENERATION_GUIDANCE_TYPE.ageRange]: {
    label: 'Age group',
  },
  [GENERATION_GUIDANCE_TYPE.gender]: {
    label: 'Gender',
  },
  [GENERATION_GUIDANCE_TYPE.keyword]: {
    label: 'Keyword',
  },
  [GENERATION_GUIDANCE_TYPE.hashtag]: {
    label: 'Hashtag',
  },
};

export const getGenerationGuidanceTypesValues = (assetType) => {
  switch (assetType) {
    case ASSET_TYPE.emailColdOutreachSubjectLine:
      return [GENERATION_GUIDANCE_TYPE.pain];
    default:
      return Object.values(GENERATION_GUIDANCE_TYPE);
  }
};

export const getGenerationGuidanceTypeLabel = (type) =>
  get(GENERATION_GUIDANCE_TYPE_PROPS, `${type}.label`, '');

export const getGenerationGuidanceTypeShortLabel = (type) => {
  const shortLabel = get(GENERATION_GUIDANCE_TYPE_PROPS, `${type}.shortLabel`);
  if (shortLabel) {
    return shortLabel;
  }
  return getGenerationGuidanceTypeLabel(type);
};

export const getGenerationGuidanceTypePlaceholder = (type, isGuidanceModeCS) =>
  get(
    GENERATION_GUIDANCE_TYPE_PROPS,
    isGuidanceModeCS ? `${type}.csPlaceholder` : `${type}.placeholder`,
    ''
  );

export const getGenerationGuidanceTypeDescription = (type, isGuidanceModeCS) =>
  get(
    GENERATION_GUIDANCE_TYPE_PROPS,
    isGuidanceModeCS ? `${type}.csDescription` : `${type}.description`,
    ''
  );

export const getGenerationGuidanceTypeToggleText = (type, isGuidanceModeCS) =>
  get(GENERATION_GUIDANCE_TYPE_PROPS, isGuidanceModeCS ? `${type}.csToggle` : `${type}.toggle`, '');

export const guidanceTypeShouldUseDefaultSuggestionMode = (guidanceType) =>
  [
    GENERATION_GUIDANCE_TYPE.ageRange,
    GENERATION_GUIDANCE_TYPE.gender,
    GENERATION_GUIDANCE_TYPE.customerAvatar,
  ].includes(guidanceType);

export const isGenerationGuidanceType = (type) =>
  Object.values(GENERATION_GUIDANCE_TYPE).includes(type);

export const isProductGenerationGuidanceType = (type) =>
  [GENERATION_GUIDANCE_TYPE.benefit, GENERATION_GUIDANCE_TYPE.feature].includes(type);
