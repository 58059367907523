import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconPinterest = memo(
  forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
      <path
        d="M3 14.9999C3 19.9135 5.9548 24.1348 10.183 25.9907C10.1493 25.1528 10.177 24.1469 10.3919 23.2352C10.6226 22.2611 11.9359 16.6965 11.9359 16.6965C11.9359 16.6965 11.5526 15.9303 11.5526 14.7979C11.5526 13.0196 12.5833 11.6915 13.8669 11.6915C14.9584 11.6915 15.4858 12.5113 15.4858 13.4931C15.4858 14.5903 14.7859 16.2315 14.426 17.7516C14.1254 19.0245 15.0643 20.0628 16.32 20.0628C18.5937 20.0628 20.125 17.1426 20.125 13.6828C20.125 11.0528 18.3536 9.0843 15.1317 9.0843C11.4916 9.0843 9.22385 11.7989 9.22385 14.831C9.22385 15.8765 9.5321 16.6137 10.0149 17.1846C10.2369 17.4469 10.2678 17.5523 10.1874 17.8534C10.1298 18.0742 9.99766 18.6058 9.94291 18.8164C9.86304 19.1203 9.61676 19.229 9.34207 19.1168C7.66545 18.4323 6.88461 16.5963 6.88461 14.5323C6.88461 11.1236 9.75954 7.0362 15.461 7.0362C20.0426 7.0362 23.058 10.3515 23.058 13.9102C23.058 18.6176 20.4409 22.1343 16.5831 22.1343C15.2876 22.1343 14.0689 21.434 13.6515 20.6386C13.6515 20.6386 12.9548 23.4034 12.8072 23.9373C12.5528 24.8625 12.0548 25.7872 11.5995 26.5079C12.6787 26.8265 13.8188 27 15.0004 27C21.627 27 27 21.6274 27 14.9999C27 8.37257 21.627 3 15.0004 3C8.37317 3 3 8.37257 3 14.9999Z"
        fill="#CB1F27"
      />
    </SvgIcon>
  ))
);
