import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_AUDIENCE_TARGET } from './schema';

const MUTATION = gql`
  mutation CreateAudienceTargeting(
    $projectId: Int!
    $targetType: AudienceTargetType!
    $targetEvaluation: AudienceTargetEvaluation
    $value: String
    $keywords: [String!]
  ) {
    addAudienceTarget(
      projectId: $projectId
      targetType: $targetType
      targetEvaluation: $targetEvaluation
      value: $value
      keywords: $keywords
    ) {
      ...AudienceTarget
    }
  }
  ${FRAGMENT_AUDIENCE_TARGET}
`;

export const createAudienceTargeting = ({
  projectId,
  targetType,
  targetEvaluation,
  value,
  keywords,
}) => {
  const variables = {
    projectId,
    targetType,
    targetEvaluation,
    value,
    keywords,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
