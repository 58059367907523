import { NOTIFICATION_TYPE } from 'src/data/notifications';

const CUSTOMER_NOTIFICATIONS = [
  NOTIFICATION_TYPE.growthCampaignDDFeatures,
  NOTIFICATION_TYPE.growthCampaignBonusWords,
  NOTIFICATION_TYPE.basicUsersAccessToDDFeatures,
  NOTIFICATION_TYPE.basicTieredAccessToDDFeatures,
];
export const areCustomerNotifications = (notifications) =>
  notifications.some((n) => CUSTOMER_NOTIFICATIONS.includes(n.type));
