import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query Notifications($customerId: ID!, $isTabActive: Boolean) {
    notifications(customerId: $customerId, isTabActive: $isTabActive) {
      id
      title
      message
      createdAt
      link
      linkText
      type
      htmlContent
    }
  }
`;

export const queryNotifications = (customerId) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      customerId,
      isTabActive: document.visibilityState === 'visible',
    },
  }).then(({ data }) => getSingleProp(data));
