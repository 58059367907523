import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'absolute',
      top: theme.spacing(2.5),
      right: theme.spacing(2.5),
      padding: theme.spacing(2.5),
      color: theme.palette.action.active,

      '& svg': {
        fontSize: theme.typography.pxToRem(24),
      },
    },
  }),
  { name: 'PopupCloseButton' }
);
