import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ReportVariation($id: ID!, $reason: String!, $comment: String) {
    reportVariation(id: $id, reason: $reason, comment: $comment) {
      id
      refundedCount
    }
  }
`;

export const reportVariation = (id, reason, comment) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { id, reason, comment },
  }).then(({ data }) => getSingleProp(data));
