import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      fontSize: theme.typography.pxToRem(14),
    },
    active: {},

    label: {
      paddingLeft: 29,
    },
    emptyLabel: {
      justifyContent: 'center',
    },
  }),
  {
    name: 'SidebarSubmenuItem',
  }
);
