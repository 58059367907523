import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChannelHomepageIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
      >
        <rect
          width="24"
          height="24"
          rx="4"
          fill="#FAE8FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.50195 12.5459C6.91617 12.5459 7.25195 12.8817 7.25195 13.2959V18.0459H9.75195V14.7959C9.75195 14.3318 9.93633 13.8867 10.2645 13.5585C10.5927 13.2303 11.0378 13.0459 11.502 13.0459H12.502C12.9661 13.0459 13.4112 13.2303 13.7394 13.5585C14.0676 13.8867 14.252 14.3318 14.252 14.7959V18.0459H16.752V13.2959C16.752 12.8817 17.0877 12.5459 17.502 12.5459C17.9162 12.5459 18.252 12.8817 18.252 13.2959V18.7959C18.252 19.2101 17.9162 19.5459 17.502 19.5459H13.502C13.0877 19.5459 12.752 19.2101 12.752 18.7959V14.7959C12.752 14.7296 12.7256 14.666 12.6787 14.6191C12.6318 14.5722 12.5683 14.5459 12.502 14.5459H11.502C11.4356 14.5459 11.3721 14.5722 11.3252 14.6191C11.2783 14.666 11.252 14.7296 11.252 14.7959V18.7959C11.252 19.2101 10.9162 19.5459 10.502 19.5459H6.50195C6.08774 19.5459 5.75195 19.2101 5.75195 18.7959V13.2959C5.75195 12.8817 6.08774 12.5459 6.50195 12.5459Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.002 5.95996C11.9691 5.95996 11.9366 5.96643 11.9062 5.97901C11.8759 5.99158 11.8483 6.01001 11.8251 6.03324L5.03228 12.8261C4.73939 13.119 4.26452 13.119 3.97162 12.8261C3.67873 12.5332 3.67873 12.0583 3.97162 11.7654L10.7641 4.97292C10.7641 4.97298 10.7642 4.97286 10.7641 4.97292C10.9266 4.81038 11.1197 4.68126 11.332 4.59328C11.5444 4.50526 11.772 4.45996 12.002 4.45996C12.2319 4.45996 12.4595 4.50526 12.6719 4.59328C12.8842 4.68126 13.0771 4.81021 13.2396 4.97275C13.2396 4.97269 13.2397 4.97281 13.2396 4.97275L20.0323 11.7654C20.3252 12.0583 20.3252 12.5332 20.0323 12.8261C19.7394 13.119 19.2645 13.119 18.9716 12.8261L12.179 6.03341C12.1557 6.01018 12.128 5.99158 12.0977 5.97901C12.0673 5.96643 12.0348 5.95996 12.002 5.95996Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);
