import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Collapse, Tooltip } from '@material-ui/core';

import { ChevronIcon } from 'src/components/ChevronIcon';
import PlusIcon from 'src/assets/icons/Plus';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { DirectionalTriangleIcon } from 'src/components/DirectionalTriangleIcon';
import { ROTATE_DIRECTION } from 'src/data/ui';
import { ITEM_TYPE, SIDEBAR_ACTION } from '../../lib';
import { SidebarSubmenuItem } from '../SidebarSubmenuItem';
import { SidebarButton } from '../SidebarButton';
import { BrandVoiceContextMenu } from '../BrandVoiceContextMenu';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({}).isRequired,
  menuItemId: PropTypes.string,
  collapseItemId: PropTypes.string,
  activePath: PropTypes.string,
  onToggleMenu: PropTypes.func.isRequired,
  onToggleCollapse: PropTypes.func.isRequired,
  onSidebarAction: PropTypes.func.isRequired,
};

const defaultProps = {
  className: '',
  menuItemId: null,
  collapseItemId: null,
  activePath: '',
};

export const SidebarSubmenu = ({
  className,
  item,
  menuItemId,
  collapseItemId,
  activePath,
  onToggleMenu,
  onToggleCollapse,
  onSidebarAction,
}) => {
  const styles = useStyles();
  const isMenuExpand = menuItemId === item.id;

  const handleMenuItemClick = useCallback(() => {
    onToggleMenu(item);
  }, [onToggleMenu, item]);

  const handleCollapseItemClick = useCallback(
    (innerItem) => {
      onToggleCollapse(innerItem);
    },
    [onToggleCollapse]
  );

  const handleCreateBrandVoiceClick = useCallback(
    (e) => {
      e.stopPropagation();

      onSidebarAction(SIDEBAR_ACTION.createBrandVoice);
    },
    [onSidebarAction]
  );

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.submenuButtonWrapper}>
        <SidebarButton
          className={styles.submenuButton}
          item={item}
          onClick={handleMenuItemClick}
          endIcon={
            <ChevronIcon
              className={styles.iconExpand}
              direction={isMenuExpand ? ROTATE_DIRECTION.down : ROTATE_DIRECTION.right}
            />
          }
        />

        {item.displayCreateButton && (
          <Tooltip
            title="Create new Brand voice"
            arrow
            placement="top"
          >
            <Button
              className={styles.createButton}
              onClick={handleCreateBrandVoiceClick}
            >
              <PlusIcon className={styles.createIcon} />
            </Button>
          </Tooltip>
        )}
      </div>

      <Collapse
        className={styles.collapse}
        in={isMenuExpand}
      >
        <div className={styles.items}>
          {item.items?.map((menuItem) => {
            if (menuItem.disabled) {
              return null;
            }

            if (menuItem.type === ITEM_TYPE.collapse) {
              const isInnerExpand = collapseItemId === menuItem.id;
              return (
                <div
                  className={styles.collapseWrapper}
                  key={menuItem.id}
                >
                  <SidebarButton
                    className={styles.collapseButton}
                    classes={{
                      startIcon: styles.innerCollapseStartIcon,
                    }}
                    item={menuItem}
                    onClick={() => handleCollapseItemClick(menuItem)}
                    startIcon={
                      <DirectionalTriangleIcon
                        className={styles.expandCollapseIcon}
                        direction={isInnerExpand ? ROTATE_DIRECTION.down : ROTATE_DIRECTION.right}
                      />
                    }
                  >
                    {menuItem.displayActionsMenu && (
                      <div data-testid="navigationSidebarLinkUserBrandVoice" className={styles.collapseButtonContent}>
                        <TruncatedTypography className={styles.collapseItemText}>
                          {menuItem.label}
                        </TruncatedTypography>

                        <BrandVoiceContextMenu
                          className={styles.collapseButtonActionMenu}
                          brandVoice={menuItem.brandVoice}
                          onSidebarAction={onSidebarAction}
                          dataTestId={`collapseMenuItem${menuItem.id}`}
                        />
                      </div>
                    )}
                  </SidebarButton>

                  <Collapse
                    className={styles.innerCollapse}
                    in={isInnerExpand}
                  >
                    <div className={styles.innerCollapseContent}>
                      <div className={styles.verticalLine} />

                      {menuItem.items?.map((innerCollapseItem) => {
                        if (innerCollapseItem.disabled) {
                          return null;
                        }
                        return (
                          <SidebarSubmenuItem
                            className={cx(styles.item, styles.collapseInnerItem)}
                            classes={{
                              endIcon: styles.innerCollapseEndIcon,
                            }}
                            key={innerCollapseItem.id}
                            item={innerCollapseItem}
                            active={activePath.startsWith(innerCollapseItem.path)}
                          />
                        );
                      })}
                    </div>
                  </Collapse>
                </div>
              );
            }

            return (
              <SidebarSubmenuItem
                className={styles.item}
                key={menuItem.id}
                item={menuItem}
                active={activePath.startsWith(menuItem.path)}
              />
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};

SidebarSubmenu.displayName = 'SidebarSubmenu';
SidebarSubmenu.propTypes = propTypes;
SidebarSubmenu.defaultProps = defaultProps;
