import React from 'react';

import KeyTab from 'src/components/KeyTab';
import KeyEnter from 'src/components/KeyEnter';
import KeyArrows from 'src/components/KeyArrows';

const KEY_TOKENS = {
  '{TAB}': KeyTab,
  '{ENTER}': KeyEnter,
  '{ARROWS}': KeyArrows,
};
const TOKENS_REGEX = new RegExp(
  Object.keys(KEY_TOKENS)
    .map((key) => `(${key})`)
    .join('|'),
  'gi'
);

const TOKEN_STYLE = {
  fontSize: '12px',
  color: 'inherit',
  borderColor: 'inherit',
  margin: '0 1px',
};

const createToken = (Token) => <Token style={TOKEN_STYLE} />;

export default function(text) {
  const parts = text.split(TOKENS_REGEX);
  return parts.map((part) => {
    const token = KEY_TOKENS[part];
    if (token) {
      return createToken(token);
    }
    return part;
  });
}
