import React from 'react';
import { Button } from '@material-ui/core';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { IconCopy } from 'src/assets/icons/IconCopy';
import { useStyles } from './useStyles';

export const CopyInputInfinityActions = ({ className, readOnly, disabled, onCopy, onSave }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)}>
      {readOnly ? (
        <Button
          className={cx(styles.button, styles.grayButton)}
          onClick={onCopy}
          disabled={disabled}
          startIcon={<IconCopy className={styles.icon} />}
          variant="contained"
          color="default"
          size="small"
        >
          Copy
        </Button>
      ) : (
        <Button
          className={styles.button}
          onClick={onSave}
          disabled={disabled}
          variant="contained"
          color="primary"
          size="small"
        >
          Apply
        </Button>
      )}
    </div>
  );
};

CopyInputInfinityActions.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onCopy: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
};

CopyInputInfinityActions.defaultProps = {
  className: null,
  readOnly: false,
  disabled: false,
  onCopy: null,
  onEdit: null,
  onSave: null,
};
