import React from 'react';
import PropTypes from 'prop-types';

import { CustomDialog } from 'src/components/CustomDialog';
import { CustomTypography } from 'src/components/CustomTypography';
import { GenerationResultDetails } from '../GenerationResultDetails';
import { useStyles } from './useStyles';

export const GeneratedDetailsPopup = ({ open, generationData, onClose }) => {
  const styles = useStyles();

  const isMultiPrompt = generationData?.multiPromptResults?.length > 0;

  const generationDetailsGroup = isMultiPrompt
    ? generationData.multiPromptResults
    : [generationData];

  return (
    <CustomDialog
      className={styles.root}
      classes={{
        title: styles.title,
      }}
      open={open}
      onClose={onClose}
      data-testid="GeneratedDetailsPopup"
      title="Generation details"
      onCancel={onClose}
      cancelLabel="Close"
    >
      <div className={styles.detailsWrapper}>
        {generationData.multiPromptData?.order?.map((item) => {
          return (
            <div
              className={styles.baseItemRoot}
              key={item}
            >
              <CustomTypography className={styles.baseItemTitle}>
                {generationData.multiPromptData.titles[item]}
              </CustomTypography>
              <CustomTypography className={styles.baseItemBody}>
                {generationData.multiPromptData.data[item] || 'None'}
              </CustomTypography>
            </div>
          );
        })}
        {generationDetailsGroup.map((generationData) => {
          return (
            <GenerationResultDetails
              key={generationData.id}
              displayGenerationTool={isMultiPrompt}
              className={styles.details}
              generationData={generationData}
              multiline
              baseItemClasses={{
                root: styles.baseItemRoot,
                title: styles.baseItemTitle,
                body: styles.baseItemBody,
              }}
            />
          );
        })}
      </div>
    </CustomDialog>
  );
};

GeneratedDetailsPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

GeneratedDetailsPopup.defaultProps = {
  open: false,
};
