import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip, Typography } from '@material-ui/core';

import { IconSearchBorder } from 'src/assets/icons/IconSearchBorder';
import { MIN_WORDS_FOR_PLAGIARISM_CHECK } from 'src/data/plagiarism';
import { useStyles } from './useStyles';

export const FirstTimeButton = ({
  className,
  classes,
  wordsCountDisable,
  tooltip,
  onCheckPlagiarism,
  disabled,
}) => {
  const styles = useStyles({ classes });

  const buttonRef = useRef();

  const handleClick = useCallback(() => {
    onCheckPlagiarism({ referenceElement: buttonRef.current });
  }, [onCheckPlagiarism]);

  return (
    <div className={className}>
      <Tooltip title={tooltip}>
        <span>
          <Button
            ref={buttonRef}
            className={styles.searchButton}
            startIcon={<IconSearchBorder className={styles.searchIcon} />}
            onClick={handleClick}
            disabled={disabled}
            color="primary"
            variant="contained"
          >
            Check plagiarism
          </Button>
        </span>
      </Tooltip>

      {wordsCountDisable && (
        <Typography className={styles.subtitle}>
          Minimum {MIN_WORDS_FOR_PLAGIARISM_CHECK} words
        </Typography>
      )}
    </div>
  );
};

FirstTimeButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  wordsCountDisable: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
  onCheckPlagiarism: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

FirstTimeButton.defaultProps = {
  className: '',
  classes: {},
  tooltip: '',
};
