import gql from 'graphql-tag';

export const CUSTOMER_PERSONA_FRAGMENT = gql`
  fragment CustomerPersona on CustomerPersona {
    id
    name
    minAge
    maxAge
    gender
    painPoints
    avatarType
    isSuggested
    brandVoice {
      id
      name
    }
  }
`;
