import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query ProjectCommonData($projectId: Int!) {
    project(id: $projectId) {
      id

      # "last used asset type" detection
      generationRequests(first: 1) {
        edges {
          node {
            ... on RecomposeRequest {
              assetType
              generationTool
              language
              languageTargetFormality
              briefTitle
              briefBody
              emailPromotion
              sourceType
              generationGuidance {
                campaignKeywords
              }
            }
            ... on SuggestionRequest {
              assetType
              generationTool
              language
              languageTargetFormality
              briefTitle
              briefBody
              emailPromotion
              freestyleToolId
              freestyleToolAllInstructions
              freestyleToolAllInstructionsData
              generationGuidance {
                campaignKeywords
              }
            }
          }
        }
      }
    }
  }
`;

export const queryProjectCommonData = async (projectId, options) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
    },
    options: {
      abortSignal: options?.abortSignal,
    },
  });

  return getSingleProp(result.data);
};
