import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { ButtonText } from 'src/components/ButtonText';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  onCtaClick: PropTypes.func.isRequired,
  displayBlogEmptyState: PropTypes.bool,
  analyzerToneOfVoice: PropTypes.shape({
    traits: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
  }),
  onSettingsClick: PropTypes.func.isRequired,
};

const defaultProps = {
  className: null,
  displayBlogEmptyState: false,
  analyzerToneOfVoice: null,
};

export const EmptyBanner = ({
  className,
  onCtaClick,
  displayBlogEmptyState,
  analyzerToneOfVoice,
  onSettingsClick,
}) => {
  const styles = useStyles();

  if ((analyzerToneOfVoice?.traits || []).length > 0) {
    return null;
  }

  return (
    <div className={cx(styles.root, className)}>
      {displayBlogEmptyState ? (
        <CustomTypography
          className={styles.title}
          variant={TYPOGRAPHY_VARIANT.medium12}
        >
          Your blog post is too short to analyze the tone of voice
        </CustomTypography>
      ) : (
        <>
          <CustomTypography
            className={styles.title}
            variant={TYPOGRAPHY_VARIANT.medium12}
          >
            Tone of voice insights are not available
          </CustomTypography>
          <CustomTypography
            className={styles.description}
            variant={TYPOGRAPHY_VARIANT.paragraph12}
          >
            Add specific traits to your tone of voice to see if this text is in compliance
          </CustomTypography>
          <ButtonText
            className={styles.buttonCta}
            onClick={() => onCtaClick({ options: { shouldActivateManualStep: true } })}
          >
            New tone of voice
          </ButtonText>
        </>
      )}
    </div>
  );
};

EmptyBanner.propTypes = propTypes;
EmptyBanner.defaultProps = defaultProps;
