import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const CustomInput = React.forwardRef(
  ({ inputProps, disallowLogRocket, dataTestId, ...restProps }, ref) => {
    const localInputProps = useMemo(
      () => ({
        ...inputProps,
        'data-testid': dataTestId,
        'data-private': disallowLogRocket ? '' : null,
        dir: 'auto',
      }),
      [inputProps, disallowLogRocket, dataTestId]
    );

    return (
      <OutlinedInput
        ref={ref}
        inputProps={localInputProps}
        {...restProps}
      />
    );
  }
);

CustomInput.propTypes = {
  inputProps: PropTypes.shape(),
  disallowLogRocket: PropTypes.bool,
  dataTestId: PropTypes.string,
};
CustomInput.defaultProps = {
  inputProps: null,
  disallowLogRocket: false,
};

export default React.memo(CustomInput);
