import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation StartSegmentCampaign($campaignId: ID!, $segmentId: ID!) {
    startContinuousOptimizationCampaignSegment(campaignId: $campaignId, segmentId: $segmentId) {
      id
      status
    }
  }
`;

export const startSegmentCampaign = ({ campaignId, segmentId }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { campaignId, segmentId },
  }).then(({ data }) => getSingleProp(data));
