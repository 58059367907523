import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs } from '@material-ui/core';
import cx from 'classnames';

import { TEXT_DETAILS_PANEL_TAB, TEXT_DETAILS_PANEL_TAB_PROPS } from 'src/data/ui';
import { CopyInput } from './components/CopyInput';
import { BrandTerms } from './components/BrandTerms';
import { TalkingPoints } from './components/TalkingPoints';
import { ChannelPolicy } from './components/ChannelPolicy';
import { ReadabilityScore } from './components/ReadabilityScore';
import { BlockedMessage } from './components/BlockedMessage';
import { ScoreAdditionalFactors } from './components/ScoreAdditionalFactors';
import { ScoringMethod } from './components/ScoringMethod';
import { Histograms } from './components/Histograms';
import { SEOScore } from './components/SEOScore';
import { PlagiarismChecker } from './components/PlagiarismChecker';
import { Benchmark } from './components/Benchmark';
import { SEOTracker } from './components/SEOTracker';
import { ToneOfVoice } from './components/ToneOfVoice';
import { DetectedBrandTermsProvider, preparePropTypesFromChildComponent } from './lib';
import { useStyles } from './useStyles';

export const TextDetailsPanel = ({
  className,
  classes,
  tabProps,
  copyInputProps,
  scoreDetailsProps,
  scoreAdditionalFactorsProps,
  audienceChartProps,
  histogramAgeScoreProps,
  histogramGenderScoreProps,
  talkingPointsProps,
  brandTermsProps,
  channelPolicyProps,
  blockedProps,
  readabilityScoreProps,
  seoTrackerProps,
  plagiarismCheckerProps,
  benchmarkProps,
  seoScoreProps,
  toneOfVoiceProps,
}) => {
  const styles = useStyles({ classes });

  const showTabs = !!tabProps;

  return (
    <DetectedBrandTermsProvider
      onApplyBrandTerm={brandTermsProps?.onApplyBrandTerm}
      appliedDetectedTermIds={brandTermsProps?.appliedDetectedTermIds}
    >
      <div
        className={cx(styles.root, className)}
        data-testid="TextDetailsPanel"
        data-component="TextDetailsPanel"
      >
        {(scoreDetailsProps || copyInputProps) && (
          <div className={cx(styles.detailsHeader, { [styles.noPaddingBottom]: showTabs })}>
            {scoreDetailsProps && <ScoringMethod {...scoreDetailsProps} />}

            {copyInputProps && <CopyInput {...copyInputProps} />}

            {showTabs && (
              <Tabs
                value={tabProps.selectedTab}
                onChange={tabProps.onSelectTab}
                indicatorColor="primary"
              >
                {Object.values(TEXT_DETAILS_PANEL_TAB).map((tab) => (
                  <Tab
                    className={styles.tab}
                    key={tab}
                    label={TEXT_DETAILS_PANEL_TAB_PROPS[tab].label}
                    value={tab}
                  />
                ))}
              </Tabs>
            )}
          </div>
        )}

        <div className={styles.detailsBody}>
          {(tabProps?.selectedTab === TEXT_DETAILS_PANEL_TAB.performance || !showTabs) && (
            <>
              {benchmarkProps && (
                <Benchmark
                  classes={{ root: styles.groupContainer, boxGroupTitle: styles.boxGroupTitle }}
                  {...benchmarkProps}
                />
              )}

              {(audienceChartProps || histogramAgeScoreProps || histogramGenderScoreProps) && (
                <Histograms
                  classes={{ root: styles.groupContainer, boxGroupTitle: styles.boxGroupTitle }}
                  audienceChartProps={audienceChartProps}
                  histogramAgeScoreProps={histogramAgeScoreProps}
                  histogramGenderScoreProps={histogramGenderScoreProps}
                />
              )}

              {scoreAdditionalFactorsProps && (
                <ScoreAdditionalFactors {...scoreAdditionalFactorsProps} />
              )}

              {talkingPointsProps && (
                <TalkingPoints
                  classes={{ root: styles.groupContainer, boxGroupTitle: styles.boxGroupTitle }}
                  {...talkingPointsProps}
                />
              )}
            </>
          )}
          {(tabProps?.selectedTab === TEXT_DETAILS_PANEL_TAB.brandPolicy || !showTabs) && (
            <>
              {seoScoreProps && !seoScoreProps?.empty && (
                <div>
                  <SEOScore {...seoScoreProps} />
                </div>
              )}

              {seoScoreProps?.empty && readabilityScoreProps && (
                <ReadabilityScore {...readabilityScoreProps} />
              )}

              {plagiarismCheckerProps && <PlagiarismChecker {...plagiarismCheckerProps} />}

              {
                //backwards compatibility
                seoScoreProps?.empty && seoTrackerProps && <SEOTracker {...seoTrackerProps} />
              }

              {toneOfVoiceProps && (
                <ToneOfVoice
                  classes={{ root: styles.groupContainer, boxGroupTitle: styles.boxGroupTitle }}
                  {...toneOfVoiceProps}
                />
              )}

              {brandTermsProps && (
                <BrandTerms
                  classes={{ root: styles.groupContainer, boxGroupTitle: styles.boxGroupTitle }}
                  {...brandTermsProps}
                />
              )}

              {channelPolicyProps && (
                <ChannelPolicy
                  classes={{
                    root: styles.groupContainer,
                    boxGroupTitle: styles.boxGroupTitle,
                    content: styles.channelPolicyContent,
                  }}
                  {...channelPolicyProps}
                />
              )}
            </>
          )}
        </div>

        {blockedProps && tabProps?.selectedTab === TEXT_DETAILS_PANEL_TAB.brandPolicy && (
          <div className={styles.blurred}>
            <BlockedMessage
              className={styles.blockedMessage}
              {...blockedProps}
            />
          </div>
        )}
      </div>
    </DetectedBrandTermsProvider>
  );
};

TextDetailsPanel.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  copyInputProps: preparePropTypesFromChildComponent(CopyInput.propTypes),
  scoreDetailsProps: preparePropTypesFromChildComponent(ScoringMethod.propTypes),
  scoreAdditionalFactorsProps: preparePropTypesFromChildComponent(ScoreAdditionalFactors.propTypes),
  audienceChartProps: Histograms.propTypes.audienceChartProps,
  histogramAgeScoreProps: Histograms.propTypes.histogramAgeScoreProps,
  histogramGenderScoreProps: Histograms.propTypes.histogramGenderScoreProps,
  talkingPointsProps: preparePropTypesFromChildComponent(TalkingPoints.propTypes),
  brandTermsProps: preparePropTypesFromChildComponent(BrandTerms.propTypes),
  channelPolicyProps: preparePropTypesFromChildComponent(ChannelPolicy.propTypes),
  blockedProps: preparePropTypesFromChildComponent(BlockedMessage.propTypes),
  readabilityScoreProps: preparePropTypesFromChildComponent(ReadabilityScore.propTypes),
  seoTrackerProps: preparePropTypesFromChildComponent(SEOTracker.propTypes),
  plagiarismCheckerProps: preparePropTypesFromChildComponent(PlagiarismChecker.propTypes),
  benchmarkProps: preparePropTypesFromChildComponent(Benchmark.propTypes),
  toneOfVoiceProps: preparePropTypesFromChildComponent(ToneOfVoice.propTypes),
  seoScoreProps: PropTypes.shape({ empty: PropTypes.bool }),
  tabProps: PropTypes.shape({ selectedTab: PropTypes.string, onSelectTab: PropTypes.func }),
};

TextDetailsPanel.defaultProps = {
  className: null,
  classes: null,
  copyInputProps: null,
  scoreDetailsProps: null,
  scoreAdditionalFactorsProps: null,
  audienceChartProps: null,
  histogramAgeScoreProps: null,
  histogramGenderScoreProps: null,
  talkingPointsProps: null,
  brandTermsProps: null,
  channelPolicyProps: null,
  blockedProps: null,
  readabilityScoreProps: null,
  seoTrackerProps: null,
  plagiarismCheckerProps: null,
  benchmarkProps: null,
  seoScoreProps: null,
  tabProps: null,
};
