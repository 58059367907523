import React, { forwardRef, memo, useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const IconInstagram = memo(
  forwardRef((props, ref) => {
    const svgId1 = useMemo(() => generateSvgId(), []);
    const svgId2 = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
        <g clipPath={`url(#${svgId1})`}>
          <path
            d="M15 5.163C18.204 5.163 18.584 5.175 19.85 5.233C23.102 5.381 24.621 6.924 24.769 10.152C24.827 11.417 24.838 11.797 24.838 15.001C24.838 18.206 24.826 18.585 24.769 19.85C24.62 23.075 23.105 24.621 19.85 24.769C18.584 24.827 18.206 24.839 15 24.839C11.796 24.839 11.416 24.827 10.151 24.769C6.891 24.62 5.38 23.07 5.232 19.849C5.174 18.584 5.162 18.205 5.162 15C5.162 11.796 5.175 11.417 5.232 10.151C5.381 6.924 6.896 5.38 10.151 5.232C11.417 5.175 11.796 5.163 15 5.163V5.163ZM15 3C11.741 3 11.333 3.014 10.053 3.072C5.695 3.272 3.273 5.69 3.073 10.052C3.014 11.333 3 11.741 3 15C3 18.259 3.014 18.668 3.072 19.948C3.272 24.306 5.69 26.728 10.052 26.928C11.333 26.986 11.741 27 15 27C18.259 27 18.668 26.986 19.948 26.928C24.302 26.728 26.73 24.31 26.927 19.948C26.986 18.668 27 18.259 27 15C27 11.741 26.986 11.333 26.928 10.053C26.732 5.699 24.311 3.273 19.949 3.073C18.668 3.014 18.259 3 15 3V3ZM15 8.838C11.597 8.838 8.838 11.597 8.838 15C8.838 18.403 11.597 21.163 15 21.163C18.403 21.163 21.162 18.404 21.162 15C21.162 11.597 18.403 8.838 15 8.838ZM15 19C12.791 19 11 17.21 11 15C11 12.791 12.791 11 15 11C17.209 11 19 12.791 19 15C19 17.21 17.209 19 15 19ZM21.406 7.155C20.61 7.155 19.965 7.8 19.965 8.595C19.965 9.39 20.61 10.035 21.406 10.035C22.201 10.035 22.845 9.39 22.845 8.595C22.845 7.8 22.201 7.155 21.406 7.155Z"
            fill={`url(#${svgId2})`}
          />
        </g>
        <defs>
          <linearGradient
            id={svgId2}
            x1="20"
            y1="3"
            x2="11.5293"
            y2="25.5293"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5E5CCB" />
            <stop offset="0.18214" stopColor="#8D40B7" />
            <stop offset="0.431696" stopColor="#DA2E7C" />
            <stop offset="0.753527" stopColor="#F15544" />
            <stop offset="1" stopColor="#FECA66" />
          </linearGradient>
          <clipPath id={svgId1}>
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(3 3)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
