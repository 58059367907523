import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    tooltip: {
      ...theme.typography.paragraph14,
      padding: theme.spacing(1, 1.5),
    },
    fitContent: {
      width: 'fit-content',
      maxWidth: 'unset',
    },
    sizeSmall: {
      ...theme.typography.paragraph12,
      padding: theme.spacing(0.75, 1),
      maxHeight: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: 'CustomTooltip' }
);
