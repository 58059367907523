import React from 'react';
import PropTypes from 'prop-types';

import { showReportDialog, captureException, captureMessage } from 'src/lib/sentry';

const reportToSentry = (error, errorInfo) => {
  if (error.name === 'ChunkLoadError') {
    captureMessage('Error loading FE bundle chunk', {
      level: 'warning',
      extra: {
        ...errorInfo,
        error: error.toString(),
        source: 'services/ErrorBoundary.js',
      },
    });
  } else {
    captureException(error, {
      extra: {
        ...errorInfo,
        source: 'ErrorBoundary',
      },
      tags: {
        transaction: 'ErrorBoundary',
      },
    });
  }
};

class ErrorBoundary extends React.Component {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    reportToSentry(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      // render fallback UI
      return (
        <div className="snap">
          <p>Oh no, something went wrong.</p>
          <p>Our engineers have been notified. Please try again soon.</p>

          <button
            type="button"
            onClick={showReportDialog}
          >
            Report
          </button>
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
