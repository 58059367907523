import { queryProject, queryProjectStatus } from 'src/graphql/project';
import { PROJECT_STATUS, PROJECT_CREATION_ERROR, ProjectCreationError } from 'src/data/project';
import { SUPPORTED_LANGUAGES_COUNT } from 'src/data/generation';
import { captureException } from 'src/lib/sentry';

const CREATION_POLLING_INTERVAL = 1000;

const promisedWait = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });

const unwrapErrors = (json) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    captureException(error, {
      extra: {
        source: 'store/project/utils.js -> unwrapErrors',
        value: json,
      },
    });
    return [];
  }
};

const stringifyErrors = (errors) => errors.map((e) => e.detail).join('\n');

const checkKnownProjectCreationError = (errors, errorCode, newMessage = null) => {
  const error = errors.find((e) => e.code === errorCode);
  if (error) {
    const message = newMessage || error.detail;
    return new ProjectCreationError(error.code, message);
  }
  return null;
};

const KNOWN_ERRORS = [
  { code: PROJECT_CREATION_ERROR.unsupportedLangManualBrief },
  {
    code: PROJECT_CREATION_ERROR.nonEnglishContent,
    message: 'URL extraction supports English only.',
  },
  {
    code: PROJECT_CREATION_ERROR.clientDomainNotFound,
    message: 'Domain doesn’t exist. Please try a different URL',
  },
  {
    code: PROJECT_CREATION_ERROR.unsupportedLangUrlBrief,
    message: `Language not supported. Please use a webpage in one of our ${SUPPORTED_LANGUAGES_COUNT} supported languages.`,
  },
];
const searchForKnownProjectCreationError = (errors) => {
  for (let i = 0; i < KNOWN_ERRORS.length; i += 1) {
    const knownErrorData = KNOWN_ERRORS[i];
    const error = checkKnownProjectCreationError(
      errors,
      knownErrorData.code,
      knownErrorData.message
    );
    if (error) {
      return error;
    }
  }

  return null;
};

export const waitForProjectCreation = (projectId) =>
  queryProjectStatus(projectId).then((result) => {
    // If creation was successful - query project data
    // Both "brief scrapping" statuses should be treated as success here
    if (
      [
        PROJECT_STATUS.done,
        PROJECT_STATUS.briefApprovalWaits,
        PROJECT_STATUS.briefProcessingError,
      ].includes(result.status)
    ) {
      return queryProject(projectId);
    }

    // If creation failed - throw returned error upwards
    if (result.status === PROJECT_STATUS.doneWithErrors) {
      const errors = unwrapErrors(result.errors);
      const knownError = searchForKnownProjectCreationError(errors);
      if (knownError) {
        throw knownError;
      }

      const errorsText = stringifyErrors(errors);
      throw new Error(errorsText);
    }

    // Wait sometime and check again
    return promisedWait(CREATION_POLLING_INTERVAL).then(() => waitForProjectCreation(projectId));
  });

export const parseBriefCreationErrors = (brief) => {
  const errors = unwrapErrors(brief.errors);
  const knownError = searchForKnownProjectCreationError(errors);
  return knownError ? [knownError] : errors;
};
