import { createStore } from 'react-sweet-state';

const STORE_NAME = 'confirmation-dialog';

const INITIAL_STATE = {
  // Public - for UI
  open: false,

  title: 'Confirmation Dialog',
  message: 'Are you sure?',
  buttonCancel: 'Cancel',
  buttonOK: 'OK',
  hideCloseIcon: false,

  // Private - for actions
  resolve: null,
  reject: null,
};

/**
 * Private actions
 */
const PRIVATE_ACTIONS = {
  close: () => ({ setState }) => {
    setState({ ...INITIAL_STATE });
  },
};

/**
 * Public actions
 */
const ACTIONS = {
  open: (options = {}) => ({ setState }) => {
    setState({
      ...INITIAL_STATE,
      ...options,
      open: true,
    });
    return new Promise((resolve, reject) => {
      setState({
        resolve,
        reject,
      });
    });
  },

  onCancel: () => ({ getState, dispatch }) => {
    const { reject } = getState();
    reject();
    dispatch(PRIVATE_ACTIONS.close());
  },

  onOK: () => ({ getState, dispatch }) => {
    const { resolve } = getState();
    resolve();
    dispatch(PRIVATE_ACTIONS.close());
  },
};

export const ConfirmationDialogStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: STORE_NAME,
});
