import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query SavedVariation($id: ID!) {
    savedVariation(id: $id) {
      id
      text
      score
    }
  }
`;

export const querySavedVariationOriginalText = async (id) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: { id },
  });

  const data = getSingleProp(result.data);
  if (!data) {
    return null;
  }
  return {
    text: data.text,
    score: data.score,
  };
};
