import * as storage from 'src/lib/storage';
import { isNotProduction } from './url';

export const BRIEF_DEBUG_DATA_VISIBLE =
  storage.readItem('feature.briefDebugDataVisible') != null ||
  isNotProduction(window.location.href);

export const DEBUG_AC_PROMPT_ENABLED = storage.readItem('feature.debugAcPromptEnabled') != null;

// @see /post-malone/backend/app/models/flipper_feature.rb
export const BE_CUSTOMER_FEATURES = {
  googleAdsCustomModeImport: 'google_ads_custom_mode_import',
  twitterAdsCustomModeImport: 'twitter_ads_custom_mode_import',
  landingPageBuilderProject: 'landing_page_builder_project',
  getScoreInlineFunction: 'get_score_inline_function',
  hebrewSupport: 'hebrew_support',
  blogFromUrl: 'blog_from_url',
  websiteIntelligence: 'website_intelligence',
  infinityExtension: 'infinity_extension', // deprecated
  blogResearchGivenUrl: 'blog_research_given_url',
  blogResearchWebSearch: 'blog_research_web_search',
  blogSeoScore: 'blog_seo_score',
  personalizePrompt: 'personalize_prompt',
  twitterSocialIntegrationEnabled: 'twitter_social_integration_enabled',
};

export const BE_USER_FEATURES = {
  customTemplatesFromReviews: 'custom_templates_from_reviews',
};
