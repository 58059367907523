import { getEditorTextContent } from './queries';
import { applyEditorTextChanges, replaceSlateEditorText } from './transforms';

export const hasSlateAstOperations = (operations) =>
  operations.some((op) => 'set_selection' !== op.type);

export const slateEditorHasContent = (editor) =>
  Array.isArray(editor?.children) && editor.children.length > 0;

export const createEditorInstanceImperativeHandle = (editor) => {
  return {
    applyTextChanges: (changes) => {
      applyEditorTextChanges(editor, changes);
    },
    getText: () => {
      return getEditorTextContent(editor);
    },
    replaceText: (newText) => {
      replaceSlateEditorText(editor, newText);
    },
  };
};
