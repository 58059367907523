import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Skeleton } from '@material-ui/lab';

import { ArrowRight } from 'src/assets/icons/ArrowRight';
import { useStyles } from './useStyles';

export const DetectedBrandTermSkeleton = ({ className }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.row}>
        <Skeleton
          className={styles.rect}
          variant="rect"
        />
      </div>

      <div className={styles.row}>
        <Skeleton
          className={styles.rectSmall}
          variant="rect"
        />

        <ArrowRight className={styles.iconArrow} />

        <Skeleton
          className={styles.rectSmall}
          variant="rect"
        />
      </div>
    </div>
  );
};

DetectedBrandTermSkeleton.propTypes = {
  className: PropTypes.string,
};

DetectedBrandTermSkeleton.defaultProps = {
  className: '',
};
