import { useEffect } from 'react';
import { useFocused } from 'slate-react';

export const usePropagateFocusChange = (onFocusChange) => {
  const focused = useFocused();

  useEffect(() => {
    if (onFocusChange) {
      onFocusChange(focused);
    }
  }, [onFocusChange, focused]);
};
