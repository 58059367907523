import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Question = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 10 10" data-fill-none>
    <path
      d="M4.58264 6.27008C4.58781 5.96276 4.62267 5.72001 4.68724 5.54181C4.7518 5.36362 4.88351 5.16605 5.08236 4.94912L5.58983 4.42616C5.80676 4.18082 5.91523 3.9174 5.91523 3.63591C5.91523 3.36474 5.84421 3.15298 5.70217 3.00061C5.56013 2.84565 5.35353 2.76818 5.08236 2.76818C4.81894 2.76818 4.60718 2.83791 4.44706 2.97736C4.28694 3.11682 4.20689 3.30405 4.20689 3.53906H3.49023C3.4954 3.12069 3.64389 2.78367 3.93572 2.528C4.23013 2.26975 4.61234 2.14062 5.08236 2.14062C5.57046 2.14062 5.95009 2.27233 6.22126 2.53575C6.495 2.79659 6.63188 3.15556 6.63188 3.61266C6.63188 4.06461 6.42269 4.51009 6.00432 4.94912L5.58208 5.36749C5.39356 5.57668 5.29929 5.87754 5.29929 6.27008H4.58264ZM4.55165 7.49808C4.55165 7.38186 4.58652 7.28502 4.65624 7.20754C4.72856 7.12748 4.83444 7.08745 4.9739 7.08745C5.11335 7.08745 5.21924 7.12748 5.29155 7.20754C5.36386 7.28502 5.40001 7.38186 5.40001 7.49808C5.40001 7.61429 5.36386 7.71113 5.29155 7.78861C5.21924 7.8635 5.11335 7.90095 4.9739 7.90095C4.83444 7.90095 4.72856 7.8635 4.65624 7.78861C4.58652 7.71113 4.55165 7.61429 4.55165 7.49808Z"
      fill="currentColor"
    />
    <circle cx="5" cy="5" r="4.75" stroke="currentColor" strokeWidth="0.5" />
  </SvgIcon>
));

export default React.memo(Question);
