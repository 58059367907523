import { defineDefaultEnumValue } from 'src/lib/enum';

export const COMMON_BRIEF_TYPE = {
  unifiedProduct: 'UnifiedProduct',
  unifiedContent: 'UnifiedContent',
  multiPromptTemplate: 'multiPromptTemplate',
};

export const SPECIFIC_BRIEF_TYPE = {
  blogPostBuilder: 'BlogPostBuilder',
};

export const CANVAS_BRIEF_TYPE = {
  canvas: 'CanvasBrief',
};

export const DEPRECATED_BRIEF_TYPE = {
  blogPost: 'BlogPost',
  email: 'Email',
  unifiedPromotionalEmail: 'UnifiedPromotionalEmail',
  socialManual: 'SocialManual',
  shopifyProductBrief: 'ShopifyProduct',
};

/**
 * Brief type
 * @enum {string}
 * @property {string} DEFAULT - default brief type
 */
export const BRIEF_TYPE = {
  ...COMMON_BRIEF_TYPE,
  ...SPECIFIC_BRIEF_TYPE,
  ...CANVAS_BRIEF_TYPE,
  ...DEPRECATED_BRIEF_TYPE,
};
defineDefaultEnumValue(BRIEF_TYPE, BRIEF_TYPE.unifiedProduct);

export const TITLE_PLACEHOLDER = 'Mindful Me';
export const DESCRIPTION_PLACEHOLDER =
  'A mobile app that helps you relax and manage stress with guided meditations that fit into your schedule.';

export const CREATE_YOUR_OWN_TOOL_BRIEF_TITLE_HINT =
  'Use this field to instruct Anyword. Here are some examples:\n- Describe your product\n- Add talking points\n- Describe your target persona\n- Outline a structure\n- Specify a writing style\nMore details mean more precise results.';

export const DEFAULT_BRIEF_TYPE = BRIEF_TYPE.unifiedProduct;
