import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';

import { YoutubeEmbed } from 'src/components/YoutubeEmbed';
import { WISTIA_PLAYER_MODE, WistiaEmbed } from 'src/components/WistiaEmbed';
import { useStyles } from './useStyles';

export const ExplainerVideoPopup = ({ open, onClose, videoId, isWistia, aspectRatio }) => {
  const styles = useStyles();

  return (
    <Dialog
      classes={{ paper: cx(styles.paper, { [styles.wistia]: isWistia }) }}
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      data-testid="ExplainerVideoPopup"
    >
      <DialogContent className={styles.dialogContent}>
        <IconButton
          onClick={onClose}
          className={styles.buttonClose}
        >
          <IconClose />
        </IconButton>

        {isWistia ? (
          <WistiaEmbed
            className={styles.wistiaEmbed}
            videoId={videoId}
            mode={WISTIA_PLAYER_MODE.autoplay}
            aspectRatio={aspectRatio}
          />
        ) : (
          <YoutubeEmbed
            className={styles.iframe}
            title="explainer video"
            videoId={videoId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

ExplainerVideoPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  videoId: PropTypes.string,
  isWistia: PropTypes.bool,
  aspectRatio: PropTypes.number,
};

ExplainerVideoPopup.defaultProps = {
  videoId: null,
  isWistia: false,
  aspectRatio: null,
};
