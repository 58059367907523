import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const TrustpilotIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 20 20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.31557 0.192001C9.5209 0.0664399 9.7569 0 9.99765 0C10.2383 0 10.4743 0.0664399 10.6797 0.192001C10.8851 0.317561 11.0517 0.497367 11.1615 0.711611C11.1654 0.719307 11.1692 0.727085 11.1728 0.734939L13.7938 6.4242L18.7605 6.42729C18.7777 6.4273 18.7948 6.42801 18.8119 6.42943C19.0587 6.44994 19.2944 6.54073 19.4912 6.69107C19.688 6.8414 19.8376 7.04498 19.9223 7.27769C20.007 7.5104 20.0232 7.7625 19.9691 8.00416C19.9149 8.24581 19.7927 8.46684 19.6167 8.64118C19.5973 8.66043 19.5767 8.67843 19.5549 8.69493L13.473 13.3156L10.6247 15.4423L15.7889 13.8322L16.9961 18.3555C17.0605 18.5981 17.0537 18.8548 16.976 19.0935C16.8983 19.3322 16.7534 19.5433 16.5587 19.7015C16.3638 19.8598 16.1275 19.9583 15.878 19.9854C15.6285 20.0126 15.3765 19.967 15.1522 19.8543C15.1189 19.8375 15.0872 19.8178 15.0573 19.7953L9.97515 15.973L4.94239 19.7966C4.91189 19.8198 4.87931 19.8401 4.84508 19.8573C4.62086 19.9701 4.36885 20.0157 4.11935 19.9886C3.86985 19.9615 3.63349 19.8628 3.43869 19.7047C3.2439 19.5464 3.09897 19.3353 3.02134 19.0966C2.94369 18.8579 2.93666 18.6019 3.00107 18.3593C3.00316 18.3515 3.0054 18.3437 3.0078 18.3359L4.92519 12.1073L0.443725 8.69101C0.42225 8.67459 0.401858 8.65684 0.382678 8.63784C0.206786 8.46351 0.0845289 8.24248 0.0303802 8.00083C-0.0237683 7.75918 -0.00753934 7.50707 0.0771448 7.27436C0.16183 7.04164 0.311424 6.83807 0.508214 6.68774C0.705006 6.5374 0.940752 6.44661 1.18754 6.4261C1.20476 6.42467 1.22203 6.42395 1.2393 6.42395H6.20552L8.82232 0.735261C8.82599 0.727298 8.82982 0.719412 8.83382 0.711611C8.94349 0.497367 9.11024 0.317561 9.31557 0.192001Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);
