import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const TemplatesIcon = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        d="M17.3228 1.9043H3.44775C3.44775 1.9043 2.2915 1.9043 2.2915 3.06055V16.9355C2.2915 16.9355 2.2915 18.0918 3.44775 18.0918H17.3228C17.3228 18.0918 18.479 18.0918 18.479 16.9355V3.06055C18.479 3.06055 18.479 1.9043 17.3228 1.9043Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.479 6.5293H2.2915"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.9165 6.5293V18.0918"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
