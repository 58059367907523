import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconUndo = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.375 0.623047C1.78921 0.623047 2.125 0.958833 2.125 1.37305V4.37305H5.125C5.53921 4.37305 5.875 4.70883 5.875 5.12305C5.875 5.53726 5.53921 5.87305 5.125 5.87305H1.375C0.960786 5.87305 0.625 5.53726 0.625 5.12305V1.37305C0.625 0.958833 0.960786 0.623047 1.375 0.623047Z"
        fill="#444444"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1284 0.683371C7.28624 0.522951 8.4659 0.683957 9.5384 1.14878C10.6109 1.61361 11.535 2.36437 12.2095 3.31897C12.8841 4.27357 13.2833 5.39527 13.3633 6.56142C13.4434 7.72758 13.2013 8.8933 12.6635 9.93113C12.1257 10.969 11.3129 11.839 10.314 12.446C9.31505 13.053 8.16846 13.3737 6.99956 13.373C6.58535 13.3728 6.24976 13.0368 6.25 12.6226C6.25025 12.2084 6.58623 11.8728 7.00044 11.873C7.89431 11.8736 8.77111 11.6283 9.53499 11.1641C10.2989 10.6999 10.9204 10.0346 11.3317 9.241C11.7429 8.44736 11.9281 7.55593 11.8669 6.66416C11.8056 5.77239 11.5004 4.91462 10.9846 4.18464C10.4687 3.45465 9.76206 2.88054 8.94191 2.52508C8.12176 2.16963 7.21967 2.0465 6.33426 2.16918C5.44885 2.29185 4.61421 2.6556 3.9216 3.22065C3.22899 3.7857 2.70507 4.5303 2.40711 5.37305C2.26904 5.76357 1.84053 5.96823 1.45 5.83016C1.05948 5.69208 0.854825 5.26357 0.992896 4.87305C1.38253 3.771 2.06766 2.79729 2.97339 2.05837C3.87911 1.31946 4.97056 0.843791 6.1284 0.683371Z"
        fill="#444444"
      />
    </SvgIcon>
  ))
);

IconUndo.displayName = 'IconUndo';
