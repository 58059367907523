import React from 'react';
import PropTypes from 'prop-types';

import useStyles from './useStyles';

const propTypes = {
  classes: PropTypes.shape(),
};

const defaultProps = {
  classes: null,
};

const KeyArrows = ({ classes, ...restProps }) => {
  const styles = useStyles({ classes });

  return (
    <span className={styles.root} {...restProps}>
      ↑ ↓
    </span>
  );
};

KeyArrows.propTypes = propTypes;
KeyArrows.defaultProps = defaultProps;

export default KeyArrows;
