import { makeStyles } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
    },
    item: {},
    item_iconStart: {
      color: COLORS.neutral.white,
    },
  }),
  { name: 'FolderItems' }
);
