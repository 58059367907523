import React from 'react';

import { DialogContent, MenuItem } from '@material-ui/core';
import { RULES } from 'src/lib/validation';
import { useValidatedForm } from 'src/lib/hooks';
import { useStyles } from './useStyles';
import { CustomDialog } from 'src/components/CustomDialog';
import CustomInput from 'src/components/CustomInput';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useHandlers } from './useHandlers';
import { FieldSelect } from 'src/components/FieldSelect';
import { GENERATION_TOOL_GROUP, getToolGroupTitle } from 'src/data/generationTool';

export const TemplatesExpertEditPopup = ({ open, onClose, template }) => {
  const styles = useStyles();

  const [value, setValue] = React.useState(template.templateGroup);

  const {
    fields,
    errors,
    validate,
    update: updateForm,
  } = useValidatedForm({
    initialFields: {
      templateGroup: template.templateGroup,
      smallImage: template.smallImage,
      bigImage: template.bigImage,
      iconImage: template.iconImage,
    },
    rules: {
      templateGroup: { [RULES.required]: { message: 'Template group is required' } },
      smallImage: {},
      bigImage: {},
      iconImage: {},
    },
  });

  const { handleSubmit } = useHandlers({
    onClose,
    validate,
    fields,
    template,
  });

  return (
    <CustomDialog
      className={styles.root}
      onClose={onClose}
      data-testid="templatesExpertEditPopup"
      title="Templates Expert Edit"
      onSubmit={handleSubmit}
      onCancel={onClose}
      submitLabel="Save"
      cancelLabel="Cancel"
      open={open}
      fullWidth
    >
      <DialogContent className={styles.dialogContent}>
        <FieldSelect
          label="Template Group"
          dataTestId="templateGroupInput"
          className={styles.field}
          value={getToolGroupTitle(value, '')}
          error={errors.templateGroup}
          onChange={(e) => {
            setValue(e.target.value);
            updateForm('templateGroup', e.target.value);
          }}
        >
          {Object.values(GENERATION_TOOL_GROUP)
            .filter(
              (group) =>
                ![
                  GENERATION_TOOL_GROUP.anyCopy,
                  GENERATION_TOOL_GROUP.boost,
                  GENERATION_TOOL_GROUP.customMultiPrompt,
                ].includes(group)
            )
            .map((group) => (
              <MenuItem
                key={group}
                value={group}
              >
                {getToolGroupTitle(group, '')}
              </MenuItem>
            ))}
        </FieldSelect>
      </DialogContent>
      <DialogContent className={styles.dialogContent}>
        <CustomTypography variant={TYPOGRAPHY_VARIANT.medium14}>
          Small Template Image (SVG)
        </CustomTypography>
        <CustomInput
          dataTestId="smallImageInput"
          className={styles.field}
          value={fields.smallImage}
          onChange={(e) => updateForm('smallImage', e.target.value)}
          error={errors.smallImage}
          placeholder="Small template image"
          autoFocus
          multiline
          rows={5}
        />
        <ErrorMessage error={errors.smallImage} />
      </DialogContent>
      <DialogContent className={styles.dialogContent}>
        <CustomTypography variant={TYPOGRAPHY_VARIANT.medium14}>
          Big Template Image (SVG)
        </CustomTypography>
        <CustomInput
          dataTestId="bigImageInput"
          className={styles.field}
          value={fields.bigImage}
          onChange={(e) => updateForm('bigImage', e.target.value)}
          error={errors.bigImage}
          placeholder="Big template image"
          autoFocus
          multiline
          rows={5}
        />
        <ErrorMessage error={errors.bigImage} />
      </DialogContent>
      <DialogContent className={styles.dialogContent}>
        <CustomTypography variant={TYPOGRAPHY_VARIANT.medium14}>Icon Image (SVG)</CustomTypography>
        <CustomInput
          dataTestId="iconImageInput"
          className={styles.field}
          value={fields.iconImage}
          onChange={(e) => updateForm('iconImage', e.target.value)}
          error={errors.iconImage}
          placeholder="Icon image"
          autoFocus
          multiline
          rows={5}
        />
        <ErrorMessage error={errors.iconImage} />
      </DialogContent>
    </CustomDialog>
  );
};
