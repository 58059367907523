import { useCallback } from 'react';

import { usePopups } from '../services';
import { TALKING_POINTS_POPUP } from '../types';

export const useTalkingPointsPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ channel, dataSource, talkingPoints, onCopyTalkingPointClick, label, title, copies }) => {
      popups.openPopup(TALKING_POINTS_POPUP, {
        channel,
        dataSource,
        talkingPoints,
        onCopyTalkingPointClick,
        label,
        title,
        copies,
        onClose: () => {
          popups.closePopup(TALKING_POINTS_POPUP);
        },
      });
    },
    [popups]
  );
};
