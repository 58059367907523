import { useEffectOnce } from 'src/lib/hooks';
import { useCustomerActions } from 'src/store';

export const useRefreshCustomerRowsAllocationOnMount = () => {
  const customerActions = useCustomerActions();

  useEffectOnce(() => {
    customerActions.refreshCurrentCustomerRowsAllocation();
  }, [customerActions]);
};
