import { GENDER_TONE, AGE_GROUP_APPEAL } from 'src/data/demographics';
import {
  RECOMPOSE_DEMOGRAPHIC_SEX_MODE,
  RECOMPOSE_DEMOGRAPHIC_AGE_MODE,
} from './const';

const RECOMPOSE_DEMOGRAPHIC_MODE_PROPS = {
  [RECOMPOSE_DEMOGRAPHIC_SEX_MODE.masculine]: {
    title: 'Masculine',
    hint: 'Rewrite your text in a more masculine tone',
    tone: GENDER_TONE.masculine,
    agePreset: null,
  },
  [RECOMPOSE_DEMOGRAPHIC_SEX_MODE.feminine]: {
    title: 'Feminine',
    hint: 'Rewrite your text in a more feminine tone',
    tone: GENDER_TONE.feminine,
    agePreset: null,
  },
  [RECOMPOSE_DEMOGRAPHIC_AGE_MODE.older]: {
    title: 'Older age groups',
    hint: 'Rewrite your text to appeal to older age groups',
    tone: null,
    agePreset: AGE_GROUP_APPEAL.older,
  },
  [RECOMPOSE_DEMOGRAPHIC_AGE_MODE.younger]: {
    title: 'Younger age groups',
    hint: 'Rewrite your text to appeal to younger age groups',
    tone: null,
    agePreset: AGE_GROUP_APPEAL.younger,
  },
};

export const getRecomposeDemographicModeProps = (mode) =>
  RECOMPOSE_DEMOGRAPHIC_MODE_PROPS[mode];
