import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.25),
      borderRadius: theme.shape.borderRadiusMedium,
      overflow: 'hidden',
    },
    isOriginal: {},
    isSuggestion: {},

    text: {
      color: COLORS.gray.gray30,
      lineHeight: theme.typography.pxToRem(18),

      '$isOriginal &': {
        textDecorationLine: 'line-through',
      },
    },
  }),
  { name: 'TermText' }
);
