import React, { forwardRef, memo, useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const IconFacebook = memo(
  forwardRef((props, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M27 15.0733C27 21.0996 22.6116 26.0945 16.875 27V18.5633H19.6711L20.2031 15.0733H16.875V12.8086C16.875 11.8536 17.34 10.9231 18.8306 10.9231H20.3438V7.95195C20.3438 7.95195 18.9703 7.71615 17.6573 7.71615C14.9166 7.71615 13.125 9.38755 13.125 12.4134V15.0733H10.0781V18.5633H13.125V27C7.38844 26.0945 3 21.0996 3 15.0733C3 8.40565 8.37281 3 15 3C21.6272 3 27 8.40565 27 15.0733Z"
            fill="#1877F2"
          />
          <path
            d="M19.6711 18.4688L20.2031 15H16.875V12.749C16.875 11.8 17.3399 10.875 18.8306 10.875H20.3438V7.92188C20.3438 7.92188 18.9705 7.6875 17.6576 7.6875C14.9165 7.6875 13.125 9.34875 13.125 12.3563V15H10.0781V18.4688H13.125V26.8542C13.736 26.95 14.3621 27 15 27C15.6379 27 16.264 26.95 16.875 26.8542V18.4688H19.6711Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(3 3)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
