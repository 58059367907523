import { PerformanceStore } from 'src/store';
import { createStateSelectorHook } from 'src/lib/sweetState';
import { isDocumentResource, isImageResource, isUrlResource } from 'src/data/performance';

const usePerformanceState = createStateSelectorHook(PerformanceStore, [
  'resources',
  'loadingResources',
]);

export const useResources = () => {
  const { resources, loadingResources } = usePerformanceState();

  return { resources, loadingResources };
};

export const useUrlResources = () => {
  const { resources } = usePerformanceState();

  return (resources || []).filter(isUrlResource);
};

export const useImageResources = () => {
  const { resources } = usePerformanceState();

  return (resources || []).filter(isImageResource);
};

export const useDocumentResources = () => {
  const { resources } = usePerformanceState();

  return (resources || []).filter(isDocumentResource);
};
