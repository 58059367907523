import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';

import { HistogramAge } from 'src/components/HistogramAge';
import { HistogramGenderSimple } from 'src/components/HistogramGenderSimple';
import { useStyles } from './useStyles';

const AgeGenderHistogram = ({ className, histogramData, ...restProps }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)} {...restProps}>
      <div className={styles.header}>
        <Typography className={styles.title}>Score</Typography>
        <Typography className={styles.description}>
          Predicted Engagement by Age and Gender
        </Typography>
      </div>

      <div className={styles.wrapperAge}>
        <HistogramAge data={histogramData.ages} />
      </div>

      {histogramData.gender ? (
        <HistogramGenderSimple gender={histogramData.gender} />
      ) : null}
    </div>
  );
};

AgeGenderHistogram.propTypes = {
  className: PropTypes.string,
  histogramData: PropTypes.shape({
    ages: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })
    ),
    gender: PropTypes.shape({
      male: PropTypes.number,
      female: PropTypes.number,
    }),
  }),
};

AgeGenderHistogram.defaultProps = {
  className: null,
  histogramData: {
    ages: [],
    gender: {
      male: 0,
      female: 0,
    },
  },
};

export default React.memo(AgeGenderHistogram);
