import {
  AI_GENERATION_MODE,
  GENERATION_GUIDANCE_TYPE,
  GENERATION_MODE_TYPE,
  RECOMPOSE_DEMOGRAPHIC_AGE_MODE,
  RECOMPOSE_DEMOGRAPHIC_SEX_MODE,
} from './const';
import { getAgeGroupLabel, getGenderLabel } from '../demographics';
import { getRecomposeDemographicModeProps } from './recomposeDemographic';
import { getGenerationGuidanceTypeLabel } from './guidanceType';

export function clampGenerationMode(mode) {
  const values = Object.values(AI_GENERATION_MODE);
  if (values.includes(mode)) {
    return mode;
  }
  return AI_GENERATION_MODE.suggest;
}

export const isGuidanceGenerationMode = (generationModeType) =>
  generationModeType === GENERATION_MODE_TYPE.guidance;

export const prepareGuidanceModeTitle = (mode) => {
  if (mode.type === GENERATION_GUIDANCE_TYPE.ageRange) {
    return `${mode.minAge} - ${getAgeGroupLabel(mode.maxAge)}`;
  }
  if (mode.type === GENERATION_GUIDANCE_TYPE.gender) {
    return getGenderLabel(mode.content);
  }
  return mode.content;
};

export const prepareGuidanceModeFullTitle = (mode) => {
  const title = getGenerationGuidanceTypeLabel(mode.type);
  const description = prepareGuidanceModeTitle(mode);
  return `${title}: ${description}`;
};

export const getGuidanceDataFromGenerationResult = (result) => ({
  type: result.suggestionAnnotationType,
  minAge: result.suggestionAnnotationMinAge,
  maxAge: result.suggestionAnnotationMaxAge,
  content: result.suggestionAnnotationContent,
});

export const formatModeListId = (id, type) => `${type}_${id}`;

export const getToneOfVoiceModes = (modes) =>
  modes.map((tov) => ({
    id: formatModeListId(tov.id, GENERATION_MODE_TYPE.toneOfVoice),
    realId: tov.id,
    type: GENERATION_MODE_TYPE.toneOfVoice,
    title: tov.title,
    hint: tov.hint,
  }));

const getDemographicModes = (modes) =>
  Object.values(modes).map((mode) => {
    const { title, hint } = getRecomposeDemographicModeProps(mode);

    return {
      id: formatModeListId(mode, GENERATION_MODE_TYPE.common),
      realId: mode,
      type: GENERATION_MODE_TYPE.common,
      title,
      hint,
    };
  });

export const getDemographicSexModes = () => getDemographicModes(RECOMPOSE_DEMOGRAPHIC_SEX_MODE);

export const getDemographicAgeModes = () => getDemographicModes(RECOMPOSE_DEMOGRAPHIC_AGE_MODE);
