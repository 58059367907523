import React from 'react';
import PropTypes from 'prop-types';
import MuiRadio from '@material-ui/core/Radio';

import IconRadioDefault from 'src/assets/icons/RadioDefault';
import IconRadioChecked from 'src/assets/icons/RadioChecked';
import { useStyles } from './useStyles';

const Radio = ({ strokeColor, ...restProps }) => {
  const styles = useStyles();

  return (
    <MuiRadio
      classes={styles}
      icon={<IconRadioDefault strokeColor={strokeColor} />}
      checkedIcon={<IconRadioChecked />}
      color="primary"
      {...restProps}
    />
  );
};

Radio.propTypes = {
  strokeColor: PropTypes.string,
};
Radio.defaultProps = {
  strokeColor: null,
};

export default Radio;
