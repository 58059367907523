import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';

import { StarIcon } from 'src/assets/icons/StarIcon';
import TextLink from 'src/components/TextLink';
import { FormControlLabel } from '@material-ui/core';
import { Checkbox } from 'src/components/Checkbox';
import renderKeyTokens from './renderKeyTokens';
import useStyles from './useStyles';

const getNextButtonText = (content, isLastStep) => {
  if (content.next) {
    return content.next;
  }
  return isLastStep ? 'Finish' : 'Next >';
};

export const Tooltip = forwardRef(
  (
    {
      className,
      step,
      onSkipClick,
      onNextClick,
      isLastStep,
      counter,
      dontShowAgain,
      onDontShowAgainChange,
    },
    ref
  ) => {
    const styles = useStyles();

    const content = step.content || {};
    const options = step.options || {};

    return (
      <div
        className={cx(styles.root, className)}
        ref={ref}
        data-testid="Tooltip"
      >
        {options.showSkipButton && (
          <IconButton
            className={styles.buttonClose}
            onClick={onSkipClick}
            size="small"
            data-testid="Tooltip__SkipButton"
          >
            <IconClose />
          </IconButton>
        )}

        {content.title && (
          <div className={styles.titleWrapper}>
            <Typography
              className={styles.title}
              variant="body2"
            >
              {content.title}
            </Typography>
            {options.proFeature && <StarIcon className={styles.starIcon} />}
          </div>
        )}

        {content.description && (
          <Typography
            className={styles.description}
            variant="body2"
            component="div"
          >
            {content.description}
          </Typography>
        )}

        {options.proFeature && (
          <Typography
            className={styles.proFeatureDescWrapper}
            variant="body2"
            component="div"
          >
            This feature is available in the Data-Driven plan.
          </Typography>
        )}

        {content.cta && (
          <Typography
            className={styles.cta}
            variant="body2"
            component="div"
          >
            {renderKeyTokens(content.cta)}
          </Typography>
        )}

        <div className={styles.footer}>
          {step.content.dontShowAgain && (
            <div className={styles.checkbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    iconClassName={styles.checkboxIcon}
                    checked={dontShowAgain}
                    onChange={onDontShowAgainChange}
                    name="checkbox"
                    color="primary"
                    data-testid="Tooltip__DontShowAgainCheckbox"
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    className={styles.checkboxLabel}
                  >
                    Don’t show again
                  </Typography>
                }
              />
            </div>
          )}

          <TextLink
            className={styles.buttonNext}
            onClick={onNextClick}
            data-testid="Tooltip__NextLink"
          >
            {getNextButtonText(content, isLastStep)}
          </TextLink>

          {counter && (
            <Typography
              className={styles.counter}
              variant="body2"
            >
              {counter}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

Tooltip.propTypes = {
  className: PropTypes.string,
  step: PropTypes.shape().isRequired,
  onNextClick: PropTypes.func.isRequired,
  onSkipClick: PropTypes.func,
  isLastStep: PropTypes.bool,
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dontShowAgain: PropTypes.bool,
  onDontShowAgainChange: PropTypes.func.isRequired,
};

Tooltip.defaultProps = {
  className: null,
  onSkipClick: null,
  isLastStep: false,
  counter: '',
  dontShowAgain: false,
};
