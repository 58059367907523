import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& svg': {
        fontSize: theme.typography.pxToRem(16) + '!important',
      },
    },
  }),
  { name: 'BrandVoiceContextMenu' }
);
