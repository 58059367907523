import { ChannelAuthError } from 'src/lib/errors';
import { openOAuthPopup } from 'src/utils/lib/oauth';

export const authorizeInSalesforce = async (authUrl) => {
  const authData = await openOAuthPopup(authUrl, { width: 600, height: 800 });

  if (authData?.code) {
    return authData;
  } else {
    return new ChannelAuthError('Error in Salesforce Authorization: No auth code');
  }
};
