import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const Loader = ({ classes }) => {
  const styles = useStyles({ classes });

  return (
    <div className={styles.root}>
      <div className={styles.row}>
        <Skeleton
          className={styles.icon}
          variant="circle"
          animation="wave"
          height={56}
          width={65}
        />

        <div className={styles.contentWrapper}>
          <Skeleton
            variant="text"
            animation="wave"
            height={22}
            width="65%"
          />
          <Skeleton
            variant="text"
            animation="wave"
            height={22}
            width="65%"
          />
        </div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  classes: PropTypes.shape(),
};

Loader.defaultProps = {
  classes: null,
};
