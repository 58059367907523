import { useCallback } from 'react';

import { UPGRADE_POPUP } from '../types';
import { usePopups } from '../services';

export const useUpgradePopup = ({
  actionsOrigin,
  originObject,
  projectId,
  variationId,
  variant,
  brandVoiceId,
} = {}) => {
  const popups = usePopups();

  return useCallback(
    (feature) => {
      return new Promise((resolve) => {
        popups.openPopup(UPGRADE_POPUP, {
          onSubmit: () => {
            popups.closeAllPopups();
            resolve();
          },
          onClose: () => {
            popups.closePopup(UPGRADE_POPUP);
            resolve();
          },
          feature,
          actionsOrigin,
          originObject,
          projectId,
          variationId,
          variant,
          brandVoiceId,
        });
      });
    },
    [popups, actionsOrigin, projectId, variationId, variant, originObject, brandVoiceId]
  );
};
