import React from 'react';
import PropTypes from 'prop-types';

import { DetectedBrandTerm } from 'src/components/DetectedBrandTerm';
import { useDetectedBrandTermsApplyHandler } from '../../../../lib';
import { useStyles } from './useStyles';

const propTypes = {
  detectedBrandTerm: PropTypes.shape().isRequired,
};

export const DetectedBrandTermTooltipContent = ({ detectedBrandTerm }) => {
  const styles = useStyles();

  const handleApply = useDetectedBrandTermsApplyHandler();
  if (!handleApply) {
    return '';
  }

  return (
    <DetectedBrandTerm
      className={styles.root}
      classes={{ replacementTerms: styles.replacementTerms }}
      headerClasses={{ term: styles.term }}
      detectedBrandTerm={detectedBrandTerm}
      onApplyClick={handleApply}
    />
  );
};

DetectedBrandTermTooltipContent.displayName = 'DetectedBrandTermTooltipContent';
DetectedBrandTermTooltipContent.propTypes = propTypes;
