export const ROTATE_DIRECTION = {
  left: 'left',
  right: 'right',
  up: 'up',
  down: 'down',
  upLeft: 'upLeft',
  upRight: 'upRight',
  downLeft: 'downLeft',
  downRight: 'downRight',
};

export const transformFromRotateDirection = (direction) => {
  switch (direction) {
    case ROTATE_DIRECTION.up:
      return 'rotate(-90deg)';
    case ROTATE_DIRECTION.down:
      return 'rotate(90deg)';
    case ROTATE_DIRECTION.left:
      return 'rotate(180deg)';
    case ROTATE_DIRECTION.upRight:
      return 'rotate(-45deg)';
    case ROTATE_DIRECTION.upLeft:
      return 'rotate(-135deg)';
    case ROTATE_DIRECTION.downRight:
      return 'rotate(45deg)';
    case ROTATE_DIRECTION.downLeft:
      return 'rotate(135deg)';
    case ROTATE_DIRECTION.right:
    default:
      return 'rotate(0deg)';
  }
};
