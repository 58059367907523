import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChannelHubspotIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 32 32"
      >
        <g>
          <path
            d="M24.0294 10.8322V7.07419C24.526 6.84208 24.9465 6.47369 25.2419 6.01191C25.5373 5.55013 25.6954 5.01394 25.698 4.46576V4.37953C25.698 2.78123 24.4023 1.48555 22.8041 1.48555H22.7178C21.1195 1.48555 19.8238 2.78123 19.8238 4.37953V4.46576C19.8263 5.01394 19.9845 5.55013 20.2799 6.01191C20.5753 6.47369 20.9958 6.84208 21.4924 7.07419V10.8322C20.0651 11.0508 18.7208 11.6426 17.5958 12.5478L7.28919 4.52002C7.36268 4.25471 7.40102 3.9819 7.40535 3.70807C7.4066 3.0631 7.21655 2.43225 6.85923 1.89531C6.50191 1.35838 5.99338 0.939473 5.39795 0.691592C4.80252 0.44371 4.14695 0.377986 3.51417 0.502734C2.88138 0.627483 2.29981 0.937098 1.84301 1.39242C1.38621 1.84774 1.07471 2.4283 0.947912 3.06068C0.821112 3.69306 0.884709 4.34884 1.13066 4.94507C1.37661 5.54129 1.79386 6.05119 2.32964 6.41025C2.86541 6.76931 3.49564 6.9614 4.14061 6.96223C4.70483 6.95957 5.25849 6.80894 5.74629 6.52537L15.8954 14.4229C14.0295 17.2416 14.0794 20.9152 16.0211 23.6823L12.9342 26.77C12.6846 26.6903 12.4248 26.6479 12.1628 26.6443C10.6845 26.6456 9.48687 27.8444 9.48725 29.3228C9.48763 30.801 10.686 31.9992 12.1642 31.9996C13.6425 32 14.8414 30.8024 14.8427 29.324C14.8392 29.0621 14.7968 28.8022 14.7168 28.5527L17.7705 25.4979C20.4991 27.5984 24.2465 27.7794 27.1648 25.9515C30.083 24.1236 31.5557 20.6728 30.8565 17.3012C30.1573 13.9295 27.4338 11.3491 24.0294 10.8322ZM22.7636 23.1921C22.1995 23.2072 21.638 23.1092 21.1123 22.9038C20.5867 22.6984 20.1075 22.3898 19.7031 21.9961C19.2987 21.6025 18.9773 21.1318 18.7578 20.6118C18.5384 20.0919 18.4253 19.5333 18.4253 18.9689C18.4253 18.4045 18.5384 17.8459 18.7578 17.326C18.9773 16.806 19.2987 16.3354 19.7031 15.9417C20.1075 15.548 20.5867 15.2394 21.1123 15.034C21.638 14.8286 22.1995 14.7306 22.7636 14.7457C25.0371 14.8253 26.8393 16.6909 26.8406 18.9658C26.8416 21.2406 25.0413 23.108 22.7678 23.1899"
            fill="#FF7A59"
          />
        </g>
      </SvgIcon>
    );
  })
);
