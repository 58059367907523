import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    labelWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    labelTooltip: {
      marginLeft: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(16),
      paddingBottom: theme.spacing(0.3),
    },
    labelTooltipContent: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    placeholder: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(19),
      color: COLORS.neutral.mediumLightGray,
    },
    select: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(19),
      height: 48,
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      alignItems: 'center',

      '&:focus': {
        backgroundColor: COLORS.neutral.white,
      },
    },
    valueContainer: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    menuPaper: {
      marginTop: theme.spacing(0.5),
    },
    selectIcon: {
      color: COLORS.neutral.secondary,
      fontSize: theme.typography.pxToRem(20),
      right: 10,
      top: 14,
    },
    loadingSelectIcon: {
      top: 14,
      right: 16,
      color: `${COLORS.primary.default}!important`,
      width: '20px!important',
      height: '20px!important',
    },
    notchedOutline: {},
  }),
  { name: 'FieldSelect' }
);
