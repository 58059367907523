import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {
  CustomButton,
  CUSTOM_BUTTON_VARIANT,
  CUSTOM_BUTTON_COLOR,
} from 'src/components/CustomButton';
import { useStyles } from './useStyles';

export const CommonNavBarButton = React.forwardRef(
  ({ className, classes, selected, ...restProps }, ref) => {
    const styles = useStyles({ classes });

    return (
      <CustomButton
        className={cx(styles.root, className)}
        classes={styles}
        ref={ref}
        variant={selected ? CUSTOM_BUTTON_VARIANT.contained : CUSTOM_BUTTON_VARIANT.text}
        color={selected ? CUSTOM_BUTTON_COLOR.upsell : CUSTOM_BUTTON_COLOR.gray}
        aria-selected={selected}
        {...restProps}
      />
    );
  }
);

CommonNavBarButton.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  selected: PropTypes.bool,
};
CommonNavBarButton.defaultProps = {
  className: null,
  classes: null,
  selected: false,
};
