import { createContainer } from 'react-sweet-state';

import { BrandTermsStore } from './BrandTermsStore';

export const BrandTermsStoreContainer = createContainer(BrandTermsStore, {
  onInit:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
