import React from 'react';
import PropTypes from 'prop-types';
import { Button, DialogContent } from '@material-ui/core';

import { CustomDialog } from 'src/components/CustomDialog';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';
import { POPUP_INFORMATION_CLOSE_REASON } from './const';

export const PopupInformation = ({
  open,
  classes,
  title,
  content,
  onClose,
  submitLabel,
  additionalActionLabel,
  dataTestId,
}) => {
  const styles = useStyles({ classes });

  return (
    <CustomDialog
      className={styles.root}
      classes={{ dialogActions: styles.dialogActions }}
      open={open}
      onClose={() => onClose(POPUP_INFORMATION_CLOSE_REASON.close)}
      data-testid={`${dataTestId}Popup`}
      title={title}
      onSubmit={() => onClose(POPUP_INFORMATION_CLOSE_REASON.submit)}
      submitLabel={submitLabel}
      dialogActionsSlot={
        additionalActionLabel && (
          <Button
            onClick={() => onClose(POPUP_INFORMATION_CLOSE_REASON.additionalAction)}
            variant="text"
            color="primary"
          >
            {additionalActionLabel}
          </Button>
        )
      }
    >
      {content && (
        <DialogContent className={styles.dialogContent}>
          <CustomTypography
            className={styles.content}
            variant={TYPOGRAPHY_VARIANT.paragraph16}
          >
            {content}
          </CustomTypography>
        </DialogContent>
      )}
    </CustomDialog>
  );
};

PopupInformation.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  additionalActionLabel: PropTypes.string,
  dataTestId: PropTypes.string,
};

PopupInformation.defaultProps = {
  classes: null,
  content: null,
  submitLabel: 'Close',
  additionalActionLabel: null,
  dataTestId: 'information',
};
