import { makeStyles, alpha } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      width: 310,
      padding: theme.spacing(3, 3.5),
      backgroundColor: theme.palette.background.paper,
      border: theme.shape.border,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    },
    title: {
      margin: theme.spacing(2.5, 0, 1, 0),
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(19),
      fontWeight: theme.typography.fontWeightMedium,
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(21),
      color: alpha(theme.palette.text.primary, 0.75),
    },
    footer: {
      display: 'flex',
      flexFlow: 'row nowrap',
      marginTop: theme.spacing(3),
    },
    checkboxLabel: {
      width: '100%',
    },
    checkbox: {
      '& svg': {
        fontSize: theme.typography.pxToRem(18),
      },
    },
    label: {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(17),
    },
    button: {
      fontSize: theme.typography.pxToRem(13),
      minWidth: 80,
      height: 32,
    },
    videoThumbnailBlock: {
      position: 'relative',
      borderRadius: '7px',
      cursor: 'pointer',
      overflow: 'hidden',

      '& > img': {
        width: '100%',
        objectFit: 'contain',
      },
    },
    iconYoutube: {
      position: 'absolute',
      top: `calc(50% - 25px)`,
      left: `calc(50% - 25px)`,
      height: '50px',
      width: '50px',
      color: '#162A5A',
    },
  }),
  { name: 'TooltipContentWorkspaces' }
);
