import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import {
  APPLICATION_MODE,
  getApplicationHost,
  getApplicationMode,
  getExtensionInstallationId,
} from 'src/data/system';
import * as ls from 'src/lib/localData';

const INITIAL_STATE = {
  applicationMode: APPLICATION_MODE.default,
  applicationHost: null,
  extensionInstallationId: null,
};

export const PRIVATE_ACTIONS = {
  init:
    () =>
    ({ setState }) => {
      setState({
        ...cloneDeep(INITIAL_STATE),
        applicationMode: getApplicationMode(),
        applicationHost: getApplicationHost(),
        extensionInstallationId: getExtensionInstallationId(),
      });
    },
};

export const ACTIONS = {
  activateApplicationMode:
    (value) =>
    ({ setState }) => {
      setState({ applicationMode: value });

      // Preserve "mode" between page reloads
      ls.sessionApplicationMode.write(value);
    },

  getApplicationMode:
    () =>
    ({ getState }) => {
      return getState().applicationMode;
    },

  setApplicationHost:
    (value) =>
    ({ setState }) => {
      setState({ applicationHost: value });

      // Preserve "host" between page reloads
      ls.sessionApplicationHost.write(value);
    },

  getApplicationHost:
    () =>
    ({ getState }) => {
      return getState().applicationHost;
    },

  getExtensionInstallationId:
    () =>
    ({ getState }) => {
      return getState().extensionInstallationId;
    },
};

export const SystemStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'system',
});
