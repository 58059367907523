import React, { forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

import { DiamondIcon } from 'src/assets/icons/Diamond';
import { BLOCKED_REASON } from 'src/data/common';
import { SCORE_SIZE } from './const';
import { getCircleSize, getScoreProps } from './utils';
import useStyles from './useStyles';

const LOCKED_VALUE = 99;

const Score = forwardRef(
  (
    {
      className,
      classes,
      score,
      size,
      locked,
      loading,
      onClick,
      isTargetForHint,
      strokeWidth,
      generationToolIcon,
      blockedReason,
      grayScaleLoader,
      ...props
    },
    ref
  ) => {
    const styles = useStyles({ classes, strokeWidth });

    const { preparedScore, textScore, scoreLevel } = getScoreProps(score, locked, grayScaleLoader);
    const circleSize = getCircleSize(size);

    return (
      <div
        className={cx(styles.root, className, {
          [styles.clickable]: Boolean(onClick),
          [styles.locked]: locked,
        })}
        ref={ref}
        onClick={onClick}
        data-size={size}
        data-score={scoreLevel}
        data-testid="Score"
        data-onboarding={isTargetForHint ? 'Score' : ''}
        {...props}
      >
        {locked && blockedReason !== BLOCKED_REASON.maxWordsExceeded ? (
          <DiamondIcon className={styles.diamondIcon} />
        ) : loading ? (
          <CircularProgress
            className={cx(styles.circleLoading, { [styles.circleLoadingGray]: grayScaleLoader })}
            size={circleSize}
            value={100}
          />
        ) : (
          <Fragment>
            <CircularProgress
              classes={{
                root: styles.circleBgRoot,
                circle: styles.circleBgCircle,
              }}
              size={circleSize}
              variant="determinate"
              value={100}
            />

            <CircularProgress
              classes={{
                root: styles.circleRoot,
                circle: styles.circleCircle,
              }}
              size={circleSize}
              variant="determinate"
              value={preparedScore}
            />
          </Fragment>
        )}
        {locked ? '' : generationToolIcon}
        <span className={cx(styles.score, { [styles.scoreHidden]: grayScaleLoader && loading })}>
          {locked
            ? blockedReason === BLOCKED_REASON.maxWordsExceeded
              ? '--'
              : LOCKED_VALUE
            : textScore}
        </span>
      </div>
    );
  }
);

Score.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  score: PropTypes.number,
  size: PropTypes.oneOf(Object.values(SCORE_SIZE)),
  locked: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  isTargetForHint: PropTypes.bool,
  strokeWidth: PropTypes.number,
  blockedReason: PropTypes.oneOf(Object.values(BLOCKED_REASON)),
  generationToolIcon: PropTypes.node,
  grayScaleLoader: PropTypes.bool,
};

Score.defaultProps = {
  className: null,
  classes: null,
  score: null,
  size: SCORE_SIZE.medium,
  locked: false,
  loading: false,
  onClick: null,
  isTargetForHint: true,
  strokeWidth: null,
  blockedReason: null,
  generationToolIcon: null,
  grayScaleLoader: false,
};

export default Score;
