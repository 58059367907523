import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { CUSTOM_DATASET_FRAGMENT } from '../customModel/schema';

const MUTATION = gql`
  mutation performanceCenterCreateBlogUrlCustomModel(
    $customerId: ID!
    $name: String!
    $brandVoiceId: ID!
    $accountIds: [ID!]!
    $dataSource: PerformanceCenterDataSource!
  ) {
    performanceCenterCreateBlogUrlCustomModel(
      customerId: $customerId
      name: $name
      brandVoiceId: $brandVoiceId
      accountIds: $accountIds
      dataSource: $dataSource
    ) {
      ...CustomDataset
    }
  }
  ${CUSTOM_DATASET_FRAGMENT}
`;

export const performanceCenterCreateBlogUrlCustomModel = ({
  customerId,
  name,
  brandVoiceId,
  accountIds,
  dataSource,
}) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, name, brandVoiceId, accountIds, dataSource },
  }).then(({ data }) => getSingleProp(data));
};
