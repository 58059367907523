import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { countWords } from 'src/lib/string';
import { formatUpdatedDateTime } from 'src/lib/format';
import { MIN_WORDS_FOR_PLAGIARISM_CHECK } from 'src/data/plagiarism';
import { InsightBox } from '../InsightBox';
import { Loader } from './components/Loader';
import { ResultsFoundTitle } from './components/ResultsFoundTitle';
import { PlagiarismCheckResults } from './components/PlagiarismCheckResults';
import { FirstTimeButton } from './components/FirstTimeButton';
import { PlagiarismNotFound } from './components/PlagiarismNotFound';
import { useStyles } from './useStyles';

export const PlagiarismChecker = ({
  copyText,
  plagiarismChecksUsed,
  plagiarismChecksLimit,
  loading,
  lastPlagiarismCheckTime,
  hasPerformedPlagiarismCheck,
  potentialCopiedSentences,
  onCheckPlagiarism,
  isOpen,
  onOpenPlagiarism,
}) => {
  const styles = useStyles();
  const wordsCountDisable = countWords(copyText) < MIN_WORDS_FOR_PLAGIARISM_CHECK;
  const hasURLsPlagiarized = !isEmpty(potentialCopiedSentences);
  const hasMultipleURLsPlagiarized = potentialCopiedSentences?.length > 1 ?? false;
  const numOfResults = potentialCopiedSentences?.length;

  const toolTip = wordsCountDisable
    ? `Plagiarism check requires a minimum of ${MIN_WORDS_FOR_PLAGIARISM_CHECK} words.`
    : '';

  const formattedLastCheckTime = formatUpdatedDateTime(lastPlagiarismCheckTime);

  const showFirstTimeButton = !hasPerformedPlagiarismCheck && !loading;
  const showResultsNumber = hasPerformedPlagiarismCheck && !loading && hasURLsPlagiarized;
  const showNotFound = hasPerformedPlagiarismCheck && !loading && !hasURLsPlagiarized;
  const showFirstResult = hasPerformedPlagiarismCheck && !loading && hasURLsPlagiarized;

  const subtitle = useMemo(
    () =>
      loading ? (
        'Scanning...'
      ) : (
        <>
          <span>
            {hasPerformedPlagiarismCheck
              ? `Last checked: ${formattedLastCheckTime}`
              : `Scan your content to ensure it's original`}
          </span>
          <span>
            {plagiarismChecksLimit
              ? `${plagiarismChecksUsed}/${plagiarismChecksLimit} checks used`
              : ''}
          </span>
        </>
      ),
    [
      loading,
      hasPerformedPlagiarismCheck,
      formattedLastCheckTime,
      plagiarismChecksUsed,
      plagiarismChecksLimit,
    ]
  );

  useEffect(() => {
    if (isOpen) {
      onOpenPlagiarism();
    }
  }, [isOpen, onOpenPlagiarism]);

  return (
    <InsightBox
      classes={{ subtitle: styles.subtitle }}
      title="Content Originality"
      subtitle={subtitle}
      collapsableContent={
        hasPerformedPlagiarismCheck &&
        !loading &&
        hasMultipleURLsPlagiarized && (
          <PlagiarismCheckResults
            classes={{ resultBox: styles.resultBox }}
            plagiarismCheckResults={potentialCopiedSentences?.slice(1) || []}
          />
        )
      }
    >
      {showResultsNumber && (
        <ResultsFoundTitle
          classes={{
            checkAgain: styles.checkAgain,
            checkAgainIcon: styles.checkAgainIcon,
            disabled: styles.disabled,
          }}
          className={cx(styles.resultBox, styles.row, styles.firstBox)}
          numOfResults={numOfResults}
          toolTip={toolTip}
          onCheckPlagiarism={onCheckPlagiarism}
          disabled={wordsCountDisable}
        />
      )}
      {showFirstTimeButton && (
        <FirstTimeButton
          classes={{
            subtitle: styles.subtitle,
          }}
          className={styles.row}
          wordsCountDisable={wordsCountDisable}
          toolTip={toolTip}
          onCheckPlagiarism={onCheckPlagiarism}
          disabled={wordsCountDisable}
        />
      )}
      {showNotFound && (
        <PlagiarismNotFound
          className={cx(styles.resultBox, styles.success, styles.row, styles.firstBox)}
          classes={{
            checkAgain: styles.checkAgain,
            checkAgainIcon: styles.checkAgainIcon,
            disabled: styles.disabled,
          }}
          wordsCountDisable={wordsCountDisable}
          toolTip={toolTip}
          onCheckPlagiarism={onCheckPlagiarism}
          disabled={wordsCountDisable}
        />
      )}
      {loading && (
        <div className={styles.resultBox}>
          <Loader />
        </div>
      )}
      {showFirstResult && (
        <PlagiarismCheckResults
          classes={{ resultBox: styles.resultBox }}
          plagiarismCheckResults={
            potentialCopiedSentences?.length > 0 ? [potentialCopiedSentences[0]] : []
          }
        />
      )}
    </InsightBox>
  );
};

PlagiarismChecker.propTypes = {
  plagiarismChecksUsed: PropTypes.number,
  plagiarismChecksLimit: PropTypes.number,
  loading: PropTypes.bool,
  lastPlagiarismCheckTime: PropTypes.string,
  hasPerformedPlagiarismCheck: PropTypes.bool,
  potentialCopiedSentences: PropTypes.array,
  onCheckPlagiarism: PropTypes.func.isRequired,
  copyText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onOpenPlagiarism: PropTypes.func.isRequired,
};

PlagiarismChecker.defaultProps = {
  plagiarismChecksUsed: 0,
  plagiarismChecksLimit: 0,
  loading: false,
  lastPlagiarismCheckTime: '',
  hasPerformedPlagiarismCheck: false,
  potentialCopiedSentences: [],
  copyText: '',
};
