import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_BRAND_BRIEF } from './schema';

const MUTATION = gql`
  mutation BrandBriefUsed($id: ID!, $workspaceId: ID!) {
    brandBriefUsed(id: $id, workspaceId: $workspaceId) {
      ...BrandBrief
    }
  }
  ${FRAGMENT_BRAND_BRIEF}
`;

export const updateBrandBriefUsed = async (id, workspaceId) => {
  const variables = {
    id,
    workspaceId,
  };
  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
