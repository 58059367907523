import React from 'react';

import { isPopupTypeNotification } from 'src/data/notifications';
import { PopupNotification } from '../../components/PopupNotification';
import { useNotificationHandlers } from '../../services';
import { useViewState } from './useViewState';

export const PopupNotifications = () => {
  const { currentNotification } = useViewState();
  const { handleCtaClick, handleCloseClick } = useNotificationHandlers();

  const isPopupNotification = isPopupTypeNotification(currentNotification);

  if (!isPopupNotification) {
    return null;
  }

  return (
    <PopupNotification
      notification={currentNotification}
      onClickNotification={handleCtaClick}
      onCloseNotification={handleCloseClick}
    />
  );
};
