import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.text.secondary,
      borderBottom: theme.shape.borderGray,
    },
    indicator: {
      height: '2px',
    },
    tab: {
      padding: theme.spacing(0, 2),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.text.secondary,

      '&$selected': {
        color: theme.palette.primary.main,
      },
    },
    selected: {},
  }),
  { name: 'TargetTypeTabs' }
);
