import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },

    resultBox: {},
  }),
  { name: 'PlagiarismCheckResults' }
);
