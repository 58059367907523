import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import useStyles from './useStyles';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  children: PropTypes.node,
};

const defaultProps = {
  className: null,
  classes: null,
  children: null,
};

const PageLayout = ({ className, classes, children, ...props }) => {
  const styles = useStyles({ classes });

  return (
    <div className={cx(styles.root, className)} {...props}>
      {children}
    </div>
  );
};

PageLayout.propTypes = propTypes;
PageLayout.defaultProps = defaultProps;

export default PageLayout;
