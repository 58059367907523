import React from 'react';
import cx from 'classnames';

import { useBrandVoices } from 'src/services';
import { SidebarHeader } from '../../components/SidebarHeader';
import { SidebarSubmenu } from '../../components/SidebarSubmenu';
import { SidebarLink } from '../../components/SidebarLink';
import { ITEM_TYPE } from '../../lib';
import {
  useActiveLocationPath,
  useAutoExpandActiveMenuItem,
  useNavigationSidebarItems,
} from '../../service';
import { useViewState } from './useViewState';
import { useHandlers } from './useHandlers';
import { useStyles } from './useStyles';

export const SidebarItems = ({ className }) => {
  const styles = useStyles();

  const { menuItemId, collapseItemId } = useViewState();
  const { brandVoices } = useBrandVoices();
  const activePath = useActiveLocationPath();
  const items = useNavigationSidebarItems(brandVoices);
  useAutoExpandActiveMenuItem(items, activePath);

  const { handleToggleMenu, handleToggleCollapse, handleLinkClick, handleSidebarAction } =
    useHandlers();

  return (
    <div className={cx(styles.root, className)}>
      {items.map((item) => {
        if (item.disabled) {
          return null;
        }

        if (item.type === ITEM_TYPE.header) {
          return (
            <SidebarHeader
              className={styles.header}
              key={item.id}
              item={item}
            />
          );
        }

        if (item.type === ITEM_TYPE.menu) {
          return (
            <SidebarSubmenu
              className={cx(styles.item, styles.submenu)}
              key={item.id}
              item={item}
              menuItemId={menuItemId}
              collapseItemId={collapseItemId}
              onToggleCollapse={handleToggleCollapse}
              onToggleMenu={handleToggleMenu}
              activePath={activePath}
              onSidebarAction={handleSidebarAction}
            />
          );
        }

        return (
          <SidebarLink
            className={cx(styles.item, styles.link)}
            key={item.id}
            item={item}
            active={item.path === activePath}
            onClick={handleLinkClick}
          />
        );
      })}
    </div>
  );
};
