import { createStateSelectorHook } from 'src/lib/sweetState';
import { FreestyleToolsStore } from 'src/store';

const useFreestyleToolsState = createStateSelectorHook(FreestyleToolsStore, [
  'freestyleTools',
  'filteredFreestyleTools',
]);

export const useFreestyleTools = () => {
  const { freestyleTools } = useFreestyleToolsState();

  return freestyleTools;
};

export const useFilteredFreestyleTools = () => {
  const { filteredFreestyleTools } = useFreestyleToolsState();
  return filteredFreestyleTools;
};
