import React, { memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChannelProductPageIcon = memo(
  forwardRef(({ monochrome, ...restProps }, ref) => {
    return (
      <SvgIcon
        {...restProps}
        ref={ref}
        viewBox="0 0 24 24"
      >
        <rect
          width="24"
          height="24"
          rx="4"
          fill="#FFEDBF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.43168 8.67049C8.44142 8.67011 8.45116 8.66992 8.46091 8.66992H15.5379C15.5474 8.66992 15.5569 8.6701 15.5663 8.67046C15.9311 8.68428 16.2826 8.82836 16.5508 9.08209C16.8105 9.32792 16.9742 9.66048 17.0044 10.0205L18.0689 17.3731C18.069 17.3738 18.0691 17.3746 18.0692 17.3754C18.1001 17.5836 18.0857 17.7957 18.0271 17.9976C17.9683 18.2003 17.8668 18.3869 17.7309 18.5461C17.5949 18.7053 17.4277 18.8336 17.2409 18.9244C17.0542 19.0151 16.8512 19.0668 16.6446 19.0773C16.632 19.0779 16.6194 19.0782 16.6067 19.0782H7.39299C7.38036 19.0782 7.36773 19.0779 7.35512 19.0773C7.14856 19.0668 6.94553 19.0151 6.75882 18.9244C6.57204 18.8336 6.40479 18.7053 6.26888 18.5461C6.13289 18.3869 6.0314 18.2003 5.97262 17.9976C5.91405 17.7957 5.89961 17.5836 5.93054 17.3754L6.9953 10.0205C7.02553 9.6608 7.1889 9.32846 7.44831 9.08268C7.71608 8.82898 8.06718 8.68471 8.43168 8.67049ZM8.48825 10.1699C8.48705 10.183 8.48551 10.1961 8.48362 10.2091L7.41708 17.5764C7.41896 17.5771 7.42109 17.5777 7.42344 17.5782H16.5763C16.5786 17.5777 16.5808 17.5771 16.5827 17.5764L15.5161 10.2091C15.5142 10.1961 15.5127 10.183 15.5115 10.1699H8.48825Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0001 6.37402C11.4915 6.37402 11.0094 6.56855 10.6584 6.90603C10.3084 7.24256 10.1176 7.69256 10.1176 8.15527V8.6709H13.8826V8.15527C13.8826 7.69256 13.6917 7.24256 13.3417 6.90603C12.9907 6.56855 12.5086 6.37402 12.0001 6.37402ZM9.61876 5.82478C10.2552 5.21286 11.1122 4.87402 12.0001 4.87402C12.8879 4.87402 13.7449 5.21286 14.3813 5.82478C15.0187 6.43767 15.3826 7.27533 15.3826 8.15527V9.4209C15.3826 9.83511 15.0468 10.1709 14.6326 10.1709H9.36755C8.95334 10.1709 8.61755 9.83511 8.61755 9.4209V8.15527C8.61755 7.27533 8.98137 6.43767 9.61876 5.82478Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);

ChannelProductPageIcon.propTypes = {
  monochrome: PropTypes.bool,
};
ChannelProductPageIcon.defaultProps = {
  monochrome: false,
};
