import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { WorkspaceNameIcon } from '../WorkspaceNameIcon';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  className: '',
  name: '',
};

export const WorkspaceName = ({ className, name, boldName }) => {
  const styles = useStyles();

  return (
    <div className={cx(styles.root, className)}>
      <WorkspaceNameIcon
        className={styles.firstLetter}
        name={name}
      />

      <TruncatedTypography
        className={styles.name}
        variant={boldName ? TYPOGRAPHY_VARIANT.medium14 : TYPOGRAPHY_VARIANT.regular14}
      >
        {name}
      </TruncatedTypography>
    </div>
  );
};

WorkspaceName.displayName = 'WorkspaceLabel';
WorkspaceName.propTypes = propTypes;
WorkspaceName.defaultProps = defaultProps;
