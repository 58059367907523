import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  () => ({
    loaderWrapper: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: 'PrivateRoute' }
);
