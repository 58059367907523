import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { CUSTOMER_MEMBER_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation UpdateCustomerMemberDefaultWorkspace(
    $customerId: ID!
    $memberUserId: ID!
    $defaultWorkspaceId: ID
  ) {
    updateCustomerMemberDefaultWorkspace(
      customerId: $customerId
      memberUserId: $memberUserId
      defaultWorkspaceId: $defaultWorkspaceId
    ) {
      ...CustomerMember
    }
  }
  ${CUSTOMER_MEMBER_FRAGMENT}
`;

export const updateCustomerMemberDefaultWorkspace = ({
  customerId,
  memberUserId,
  defaultWorkspaceId,
}) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, memberUserId, defaultWorkspaceId },
  }).then(({ data }) => getSingleProp(data));
