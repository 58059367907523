import { GENERATION_TOOL_REWRITE, GENERATION_TOOL_DD, GENERATION_TOOL } from './const';
import { without } from 'lodash';

const REWRITE_TO_SUGGEST_TOOL_MAP = {
  [GENERATION_TOOL_REWRITE.facebookHeadlineProductRewrite]:
    GENERATION_TOOL_DD.facebookHeadlineProduct,
  [GENERATION_TOOL_REWRITE.facebookPrimaryTextProductRewrite]:
    GENERATION_TOOL_DD.facebookPrimaryTextProduct,
  [GENERATION_TOOL_REWRITE.googleHeadlineProductRewrite]: GENERATION_TOOL_DD.googleHeadlineProduct,
  [GENERATION_TOOL_REWRITE.googleDescriptionProductRewrite]:
    GENERATION_TOOL_DD.googleDescriptionProduct,
  [GENERATION_TOOL_REWRITE.outbrainHeadlineProductRewrite]:
    GENERATION_TOOL_DD.outbrainHeadlineProduct,
  [GENERATION_TOOL_REWRITE.taboolaHeadlineProductRewrite]:
    GENERATION_TOOL_DD.taboolaHeadlineProduct,
  [GENERATION_TOOL_REWRITE.twitterCopyProductRewrite]: GENERATION_TOOL_DD.twitterCopyProduct,
  [GENERATION_TOOL_REWRITE.pinterestDescriptionProductRewrite]:
    GENERATION_TOOL_DD.pinterestDescriptionProduct,
  [GENERATION_TOOL_REWRITE.landingHeadlineRewrite]: GENERATION_TOOL_DD.landingHeadline,
  [GENERATION_TOOL_REWRITE.landingSubheaderRewrite]: GENERATION_TOOL_DD.landingSubheader,
  [GENERATION_TOOL_REWRITE.landingSEOMetadataRewrite]: GENERATION_TOOL_DD.landingSEOMetadata,
  [GENERATION_TOOL_REWRITE.emailColdOutreachBodyRewrite]: GENERATION_TOOL_DD.emailColdOutreachBody,
  [GENERATION_TOOL_REWRITE.emailPromotionalBodyRewrite]: GENERATION_TOOL_DD.emailPromotionalBody,
  [GENERATION_TOOL_REWRITE.emailBodyRewrite]: GENERATION_TOOL_DD.emailBody,
  [GENERATION_TOOL_REWRITE.smsPromotionalRewrite]: GENERATION_TOOL_DD.smsPromotional,
  [GENERATION_TOOL_REWRITE.linkedinHeadlineSingleImageRewrite]:
    GENERATION_TOOL_DD.linkedinHeadlineSingleImage,
  [GENERATION_TOOL_REWRITE.linkedinIntroSingleImageRewrite]:
    GENERATION_TOOL_DD.linkedinIntroSingleImage,
  [GENERATION_TOOL_REWRITE.instagramCaptionRewrite]: GENERATION_TOOL_DD.instagramCaption,
  [GENERATION_TOOL_REWRITE.facebookPostRewrite]: GENERATION_TOOL_DD.facebookPost,
  [GENERATION_TOOL_REWRITE.tweetRewrite]: GENERATION_TOOL_DD.tweet,
  [GENERATION_TOOL_REWRITE.pinterestPinTitleRewrite]: GENERATION_TOOL_DD.pinterestPostTitle,
  [GENERATION_TOOL_REWRITE.pinterestPinDescriptionRewrite]:
    GENERATION_TOOL_DD.pinterestPostDescription,
  [GENERATION_TOOL_REWRITE.linkedinPostRewrite]: GENERATION_TOOL_DD.linkedinPost,
  [GENERATION_TOOL_REWRITE.emailSubjectRewrite]: GENERATION_TOOL_DD.emailSubject,
  [GENERATION_TOOL_REWRITE.emailSubjectLineRewrite]: GENERATION_TOOL_DD.emailSubjectLine,
};

export const getSuggestRelativeForRewriteTool = (rewriteTool) =>
  REWRITE_TO_SUGGEST_TOOL_MAP[rewriteTool];

export const convertRewriteToolToSuggestTool = (rewriteTool) => {
  const suggestTool = getSuggestRelativeForRewriteTool(rewriteTool);
  return suggestTool || rewriteTool;
};

export const getRewriteRelativeForSuggestTool = (suggestTool) => {
  const entry = Object.entries(REWRITE_TO_SUGGEST_TOOL_MAP).find(
    (entry) => entry[1] === suggestTool
  );
  return entry?.[0];
};

export const suggestToolHasRewriteRelative = (suggestTool) => {
  return getRewriteRelativeForSuggestTool(suggestTool) != null;
};

export const getSuggestToolsWithRewriteRelatives = () => {
  const rewriteTools = Object.values(GENERATION_TOOL_REWRITE);
  const suggestTools = rewriteTools.map(getSuggestRelativeForRewriteTool).filter(Boolean);
  return without(suggestTools, GENERATION_TOOL.emailBody, GENERATION_TOOL.emailSubjectLine);
};

export const getInfinityTools = () => getSuggestToolsWithRewriteRelatives();

export const clampInfinityGenerationTool = (tool, defaultValue = null) => {
  const infinityTools = getInfinityTools();
  return infinityTools.includes(tool) ? tool : defaultValue;
};
