import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconTriangle = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 16 16"
      data-fill-none
    >
      <path
        d="M6 11V5L10 8L6 11Z"
        fill="#CBD3E1"
      />
    </SvgIcon>
  ))
);
