import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { useStyles } from './useStyles';

const PAIN_POINTS_COUNT = 3;

const stringifyPainPointsTooltip = (painPoints) =>
  `\u2022 ${painPoints.slice(PAIN_POINTS_COUNT).join('\n\u2022 ')}`;

export const PersonaPainPoints = ({ className, painPoints = [] }) => {
  const styles = useStyles();

  const painPointsLength = painPoints.length;

  return (
    <div className={className}>
      <CustomTypography
        className={styles.title}
        variant={TYPOGRAPHY_VARIANT.semiBold12}
      >
        Pain points
      </CustomTypography>
      {painPointsLength ? (
        <div className={styles.wrapper}>
          {painPoints.slice(0, PAIN_POINTS_COUNT).map((painPoint, index) => (
            <div
              key={painPoint}
              className={styles.item}
            >
              <TruncatedTypography
                className={styles.itemText}
                variant={TYPOGRAPHY_VARIANT.regular12}
              >
                {painPoint}
              </TruncatedTypography>
              {painPointsLength > PAIN_POINTS_COUNT && index === PAIN_POINTS_COUNT - 1 && (
                <Tooltip
                  title={stringifyPainPointsTooltip(painPoints)}
                  arrow
                  placement="bottom"
                >
                  <CustomTypography
                    className={styles.itemText}
                    variant={TYPOGRAPHY_VARIANT.regular12}
                  >
                    +{painPointsLength - PAIN_POINTS_COUNT}
                  </CustomTypography>
                </Tooltip>
              )}
            </div>
          ))}
        </div>
      ) : (
        <CustomTypography
          variant={TYPOGRAPHY_VARIANT.regular12}
          className={styles.empty}
        >
          Empty
        </CustomTypography>
      )}
    </div>
  );
};

PersonaPainPoints.propTypes = {
  className: PropTypes.string,
  painPoints: PropTypes.arrayOf(PropTypes.string),
};

PersonaPainPoints.defaultProps = {
  className: null,
  painPoints: null,
};
