import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';
import { LABEL_HEIGHT, LABEL_WIDTH } from '../../const';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: LABEL_WIDTH,
      height: LABEL_HEIGHT,
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: ({ align }) => align,

      '&:hover': {
        '& $menu': {
          display: 'block',
        },
      },
    },
    content: {
      maxWidth: LABEL_WIDTH,
      minHeight: LABEL_HEIGHT,
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      gap: theme.spacing(0.5),

      padding: theme.spacing(0.5, 1),
      borderRadius: theme.shape.borderRadiusSmall,
      justifyContent: 'space-between',

      '&:hover': {
        background: COLORS.gray.gray90,
      },
      '&$open': {
        background: COLORS.gray.gray90,
        '& $menu': {
          display: 'block',
        },
      },
    },
    menuPaper: {
      marginTop: theme.spacing(0.5),
    },
    open: {},
    tooltip: {
      maxWidth: 'none',
      padding: 0,
      background: 'none',
      zIndex: 9999,
    },
    tooltipPopper: {
      zIndex: '1300 !important',
    },
    avatar: {
      fontSize: theme.typography.pxToRem(24),
    },
    texts: {
      display: 'flex',
      flexFlow: 'column nowrap',
      overflow: 'hidden',
    },
    textName: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.gray.black,

      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    textSuggested: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.gray.gray50,
    },
    actions: {
      marginLeft: 'auto',
      display: 'flex',
      columnGap: theme.spacing(0.25),
    },
    menu: {
      padding: theme.spacing(0.5),
      display: 'none',
      borderRadius: theme.shape.borderRadiusSmall,
      '& svg': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    icon: {
      fontSize: `${theme.typography.pxToRem(16)} !important`,
      color: COLORS.neutral.secondary,
    },
  }),
  { name: 'LabelPersona' }
);
