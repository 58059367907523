import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const VideoTopicIdeas = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
    <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
    <path
      d="M15.5827 9.07617H7.87109V23C7.87109 23 15.1403 23 20.7238 23V18.6165"
      stroke="white"
      strokeWidth="0.897436"
    />
    <path
      d="M20.0185 6.49834L20.0185 6.49833C18.7398 6.77014 17.6899 7.84469 17.4218 9.16734C17.1473 10.5333 17.6471 11.8799 18.7072 12.7022C19.024 12.9446 19.2556 13.2889 19.3821 13.6791H22.1113C22.2486 13.2864 22.5058 12.934 22.8502 12.6577C23.6733 11.9888 24.151 10.9912 24.151 9.91077C24.151 8.84899 23.692 7.86146 22.8892 7.1936L20.0185 6.49834ZM20.0185 6.49834L20.0205 6.4979M20.0185 6.49834L20.0205 6.4979M20.0205 6.4979C21.0459 6.27391 22.0933 6.53249 22.8892 7.19355L20.0205 6.4979Z"
      stroke="white"
      strokeWidth="0.734266"
    />
    <path
      d="M19.4727 15.8154H22.4245"
      stroke="white"
      strokeWidth="0.734266"
      strokeLinecap="round"
    />
    <path
      d="M19.8438 17.2822L22.0576 17.2822"
      stroke="white"
      strokeWidth="0.734266"
      strokeLinecap="round"
    />
    <path
      d="M15.8624 15.3942L11.5977 12.71V18.0784L15.8624 15.3942Z"
      fill="white"
    />
  </SvgIcon>
));

export default React.memo(VideoTopicIdeas);
