import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { intersection } from 'lodash';

import { SLATE_EDITOR_ACTIONS } from 'src/lib/slate';
import { ALWAYS_VISIBLE_ACTIONS } from '../../../lib';
import { EditorActionButton } from '../../EditorActionButton';

export const ToolbarUndoRedoActions = ({ className, editorActions, onAction }) => {
  const actions = useMemo(
    () => intersection(editorActions, ALWAYS_VISIBLE_ACTIONS),
    [editorActions]
  );

  return (
    <div className={className}>
      {actions.map((action) => (
        <EditorActionButton
          key={action}
          action={action}
          onAction={onAction}
        />
      ))}
    </div>
  );
};

ToolbarUndoRedoActions.propTypes = {
  className: PropTypes.string,
  editorActions: PropTypes.arrayOf(PropTypes.oneOf(SLATE_EDITOR_ACTIONS)).isRequired,
  onAction: PropTypes.func.isRequired,
};

ToolbarUndoRedoActions.defaultProps = {
  className: '',
};
