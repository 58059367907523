import gql from 'graphql-tag';

export const FRAGMENT_CUSTOM_FORMULA = gql`
  fragment CustomFormula on CustomFormula {
    id
    generationTool
    name
    body
    isDefault
    brandVoice {
      id
      name
    }
  }
`;
