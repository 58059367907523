import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import useStyles from './useStyles';

export const CollapsingTooltip = ({
  title,
  moreString,
  cutoffLength,
  children,
  classes,
  ...restProps
}) => {
  const styles = useStyles({ classes });

  // Cut off more than actual needed characters, to account for edge cases such as a single character being cut off
  const cutoffActual = cutoffLength - 8;

  const isToolTipCutOff = title?.length > cutoffLength;
  const [handCursor, setHandCursor] = React.useState(isToolTipCutOff);
  const moreLabel = '...\n\n' + moreString;

  const [tooltipLabel, setTooltipLabel] = React.useState(
    title.substring(0, cutoffActual) + (isToolTipCutOff ? moreLabel : '')
  );

  return (
    <Tooltip
      title={tooltipLabel}
      classes={{
        ...classes,
        tooltip: handCursor ? cx(styles.handCursor, classes?.tooltip) : classes?.tooltip,
      }}
      interactive={true}
      leaveDelay={120}
      onClose={() => {
        setHandCursor(isToolTipCutOff);
        setTooltipLabel(title.substring(0, cutoffActual) + (isToolTipCutOff ? moreLabel : ''));
      }}
      PopperProps={{
        onClick: (event) => {
          if (isToolTipCutOff) {
            setHandCursor(false);
            setTooltipLabel(title);
            event.stopPropagation();
          }
        },
      }}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

CollapsingTooltip.propTypes = {
  title: PropTypes.string,
  moreString: PropTypes.string,
  classes: PropTypes.shape(),
  children: PropTypes.node,
  cutoffLength: PropTypes.number,
};

CollapsingTooltip.defaultProps = {
  title: '',
  moreString: 'Show more',
  classes: null,
  children: null,
  cutoffLength: 118, // need to cut off if longer than cutoffLength characters
};
