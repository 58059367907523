import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconEditPencil = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4269 0.687566C12.8064 0.684997 13.1827 0.757643 13.5339 0.901307C13.8852 1.04497 14.2045 1.25681 14.4734 1.52458L14.4755 1.52665C14.7433 1.79548 14.9553 2.11475 15.099 2.466C15.2427 2.81724 15.3154 3.19349 15.3129 3.573C15.3103 3.95251 15.2325 4.32774 15.084 4.677C14.9362 5.02483 14.7211 5.34004 14.4512 5.60455L6.0053 14.0504C6.00329 14.0525 6.00128 14.0545 5.99925 14.0565C5.99614 14.0597 5.993 14.0627 5.98985 14.0658C5.89583 14.1578 5.77925 14.2234 5.65176 14.2561L1.62385 15.2892C1.3671 15.355 1.09466 15.2805 0.907222 15.0931C0.719785 14.9057 0.64519 14.6332 0.711005 14.3765L1.74351 10.3486C1.77688 10.2183 1.84464 10.0995 1.93969 10.0045L10.3952 1.54894C10.6598 1.27909 10.9751 1.06409 11.323 0.916274C11.6722 0.767868 12.0474 0.690135 12.4269 0.687566ZM5.46795 12.4664L12.6211 5.31332L10.6866 3.37889L3.53179 10.5337L5.46795 12.4664ZM2.87048 11.993L4.0085 13.129L2.47869 13.5214L2.87048 11.993ZM12.9661 2.28967C12.7982 2.22102 12.6184 2.1863 12.4371 2.18753C12.2557 2.18876 12.0764 2.2259 11.9095 2.29682C11.8673 2.31478 11.826 2.33482 11.7859 2.35683L13.6433 4.21423C13.6654 4.17399 13.6856 4.13258 13.7036 4.09016C13.7745 3.92334 13.8117 3.74411 13.8129 3.56284C13.8141 3.38158 13.7794 3.20186 13.7107 3.03409C13.6423 2.86677 13.5414 2.71464 13.4139 2.58645C13.2856 2.45899 13.1334 2.35813 12.9661 2.28967Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);
