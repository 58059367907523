import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query GoogleAuthorizationLink {
    googleAuthorizationLink {
      url
    }
  }
`;

export const queryGoogleAuthorizationLink = () =>
  executeGqlQuery({
    query: QUERY,
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => result?.url);
