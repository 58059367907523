import { useCallback, useState } from 'react';
import { useHistory } from 'src/lib/hooks/useHistory';

import * as events from 'src/lib/events';
import { useUserActions } from 'src/store';
import { ROUTES } from 'src/routes';
import { useSelectCurrentCustomer } from 'src/services';
import { useTrackingEvent } from 'src/services/useTrackingEvent';
import { ELEMENT_ID } from 'src/data/dom';

export const useHandlers = ({ openMenu, closeMenu }) => {
  const history = useHistory();
  const trackEvent = useTrackingEvent();
  const [dialogLogoutOpened, setDialogLogoutOpened] = useState(false);
  const userActions = useUserActions();
  const { handleSelectCustomer } = useSelectCurrentCustomer();

  const openLogoutDialog = useCallback(() => {
    setDialogLogoutOpened(true);
    closeMenu();
  }, [closeMenu]);
  const closeLogoutDialog = useCallback(() => {
    setDialogLogoutOpened(false);
  }, []);

  const handleCustomerClick = useCallback(
    (customer) => {
      closeMenu();

      handleSelectCustomer(customer.id);
    },
    [closeMenu, handleSelectCustomer]
  );

  const handleSettingsClick = useCallback(() => {
    closeMenu();

    history.push(ROUTES.SETTINGS);
  }, [closeMenu, history]);

  const handleEditClick = useCallback(() => {
    closeMenu();

    history.push(`${ROUTES.SETTINGS}#${ELEMENT_ID.accountDetails}`);
  }, [closeMenu, history]);

  const handleLogoutClick = useCallback(() => {
    closeMenu();

    openLogoutDialog();
  }, [closeMenu, openLogoutDialog]);

  const handleLogout = useCallback(() => {
    trackEvent(events.SYSTEM.logout, {
      reason: events.LOGOUT_REASON.userAction,
      page: events.EVENT_PAGE.accountSettings,
    });

    setDialogLogoutOpened(false);
    userActions.logout(false, true);
    // Redirect to SignIn page and ask to not go back from it
    history.push(ROUTES.SIGN_IN, { state: { disableFrom: true } });
  }, [trackEvent, userActions, history]);

  const handleProfileButtonClick = (e) => {
    openMenu(e.currentTarget);

    trackEvent(events.SETTINGS.clickedUserProfile, {
      page: events.EVENT_PAGE.accountSettings,
    });
  };

  return {
    dialogLogoutOpened,
    closeLogoutDialog,
    handleCustomerClick,
    handleSettingsClick,
    handleEditClick,
    handleLogoutClick,
    handleLogout,
    handleProfileButtonClick,
  };
};
