import { useEffect } from 'react';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

import { useFreshRef } from './useFreshRef';

export const useResizeObserver = (element, onResize) => {
  const refOnResize = useFreshRef(onResize);

  useEffect(() => {
    if (!element) {
      return undefined;
    }

    const resizeObserver = new ResizeObserverPolyfill(() => {
      if (element && refOnResize.current) {
        refOnResize.current(element);
      }
    });
    resizeObserver.observe(element);

    return () => {
      resizeObserver.disconnect();
    };
  }, [element, refOnResize]);
};
