import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconChannelWTM = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 33 33"
      data-fill-none
    >
      <path
        d="M15.5 29C22.9555 29 29 22.9555 29 15.5C29 8.04448 22.9555 2 15.5 2C8.04448 2 2 8.04448 2 15.5C2 22.9555 8.04448 29 15.5 29Z"
        fill="#C9DBFF"
      />
      <path
        d="M5.2005 18.8453C5.20012 16.5118 5.83795 14.2188 7.0505 12.1947C8.26306 10.1705 10.008 8.48584 12.1118 7.30829C14.2155 6.13074 16.6046 5.5014 19.0412 5.48291C21.4779 5.46443 23.8771 6.05744 26 7.20291C24.7375 5.63476 23.1269 4.35494 21.2846 3.45604C19.4423 2.55715 17.4144 2.06165 15.3476 2.00539C13.2808 1.94914 11.2268 2.33353 9.33416 3.13077C7.44152 3.92801 5.75758 5.11816 4.40416 6.61511C3.05074 8.11207 2.06167 9.87841 1.50852 11.7863C0.955374 13.6943 0.851966 15.6961 1.20578 17.647C1.5596 19.5979 2.36179 21.4491 3.55432 23.0667C4.74686 24.6843 6.29992 26.0278 8.10115 27C6.21627 24.6653 5.19583 21.7965 5.2005 18.8453Z"
        fill="#ECF2FF"
      />
      <path
        d="M15.1504 29.2604C12.5751 29.2599 10.0537 28.5143 7.88298 27.1114C5.71229 25.7084 3.98281 23.7066 2.89826 21.3417C1.8137 18.9768 1.41925 16.3473 1.76137 13.763C2.10349 11.1787 3.16792 8.74721 4.82929 6.75496C6.49066 4.76271 8.67975 3.29269 11.1387 2.51805C13.5977 1.74341 16.2241 1.69643 18.7085 2.38264C21.1929 3.06884 23.432 4.45966 25.1618 6.39124C26.8916 8.32283 28.0402 10.7147 28.4723 13.2852"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1506 29.2604C12.0416 29.2604 9.521 23.1363 9.521 15.5804C9.521 8.02447 12.0416 1.90039 15.1506 1.90039C18.2597 1.90039 20.7803 8.02447 20.7803 15.5804C20.7829 17.1062 20.676 18.6302 20.4605 20.1404"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.40967 11.0195H27.8931"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.40967 20.1406H20.5"
        stroke="#1E1E1E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="16"
        y="16"
        width="17"
        height="17"
        rx="8.5"
        fill="#E3FFEE"
      />
      <path
        d="M25.9141 21H29.198V24.325"
        stroke="#3AA97F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.1976 21L24.5063 27.175L20.7532 24.325L18.5 28.5"
        stroke="#3AA97F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
IconChannelWTM.displayName = 'IconChannelWTM';
