import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconDelete = React.memo(
  React.forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 4C1.25 3.58579 1.58579 3.25 2 3.25H14C14.4142 3.25 14.75 3.58579 14.75 4C14.75 4.41421 14.4142 4.75 14 4.75H2C1.58579 4.75 1.25 4.41421 1.25 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66732 2.08337C6.51261 2.08337 6.36424 2.14483 6.25484 2.25423C6.14544 2.36362 6.08398 2.512 6.08398 2.66671V3.25004H9.91732V2.66671C9.91732 2.512 9.85586 2.36362 9.74646 2.25423C9.63707 2.14483 9.48869 2.08337 9.33398 2.08337H6.66732ZM11.4173 3.25004V2.66671C11.4173 2.11417 11.1978 1.58427 10.8071 1.19357C10.4164 0.802867 9.88652 0.583374 9.33398 0.583374H6.66732C6.11478 0.583374 5.58488 0.802867 5.19418 1.19357C4.80348 1.58427 4.58398 2.11417 4.58398 2.66671V3.25004H3.33398C2.91977 3.25004 2.58398 3.58583 2.58398 4.00004V13.3334C2.58398 13.8859 2.80348 14.4158 3.19418 14.8065C3.58488 15.1972 4.11478 15.4167 4.66732 15.4167H11.334C11.8865 15.4167 12.4164 15.1972 12.8071 14.8065C13.1978 14.4158 13.4173 13.8859 13.4173 13.3334V4.00004C13.4173 3.58583 13.0815 3.25004 12.6673 3.25004H11.4173ZM4.08398 4.75004V13.3334C4.08398 13.4881 4.14544 13.6365 4.25484 13.7459C4.36424 13.8552 4.51261 13.9167 4.66732 13.9167H11.334C11.4887 13.9167 11.6371 13.8552 11.7465 13.7459C11.8559 13.6365 11.9173 13.4881 11.9173 13.3334V4.75004H4.08398Z"
        fill="currentColor"
      />
    </SvgIcon>
  ))
);

export default IconDelete;
