import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ExtractResource(
    $customerId: ID!
    $workspaceId: ID!
    $input: PerformanceCenterCustomerResourceInputType!
  ) {
    extractResource(customerId: $customerId, workspaceId: $workspaceId, input: $input) {
      id
      status
    }
  }
`;

export const extractResource = ({ customerId, workspaceId, input }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, workspaceId, input },
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
