import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { INTEGRATION_REQUEST_INFO_FRAGMENT } from './schema';

const QUERY = gql`
  query IntegrationAuthorizationRequestInfo($dataSource: PerformanceCenterDataSource!) {
    integrationAuthorizationRequestInfo(dataSource: $dataSource) {
      ...IntegrationAuthorizationRequestInfo
    }
  }
  ${INTEGRATION_REQUEST_INFO_FRAGMENT}
`;

export const queryIntegrationAuthorizationRequestInfo = (dataSource) => {
  return executeGqlQuery({
    variables: { dataSource },
    query: QUERY,
  }).then(({ data }) => getSingleProp(data));
};
