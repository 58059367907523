import React from 'react';

import { usePopups } from '../services';
import { CONFIRM_POPUP } from '../types';

const DEFAULT_BUTTONS = {
  confirm: 'Delete',
  cancel: 'Cancel',
};

export const useConfirmPopup = () => {
  const popups = usePopups();

  return React.useCallback(
    (options) => {
      const { mode, title, content, withReject, classes } = options;
      const buttons = { ...DEFAULT_BUTTONS, ...options.buttons };

      return new Promise((resolve, reject) => {
        popups.openPopup(CONFIRM_POPUP, {
          mode,
          title,
          content,
          confirm: buttons.confirm,
          cancel: buttons.cancel,
          classes,
          onConfirm: () => {
            popups.closePopup(CONFIRM_POPUP);
            resolve(true);
          },
          onCancel: () => {
            popups.closePopup(CONFIRM_POPUP);
            if (withReject) {
              reject();
            }
          },
        });
      });
    },
    [popups]
  );
};
