import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

import { getUserGoalLabel } from 'src/data/benchmark';
import { getResourceDocumentLabel } from 'src/data/performance';
import { alignChannelFromBackend, getChannelPerformanceLabel } from 'src/data/channel';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { ButtonText } from 'src/components/ButtonText';
import { IconSettings } from 'src/assets/icons/IconSettings';
import { useStyles } from './useStyles';

const TooltipContent = ({ className, name, description }) => {
  if (!name || !description) {
    return;
  }

  return (
    <div className={className}>
      <CustomTypography variant={TYPOGRAPHY_VARIANT.semiBold12}>
        <b>Benchmark set:</b>
      </CustomTypography>
      <CustomTypography variant={TYPOGRAPHY_VARIANT.paragraph14}>{name}</CustomTypography>
      <br />
      <CustomTypography variant={TYPOGRAPHY_VARIANT.semiBold12}>
        <b>Description:</b>
      </CustomTypography>
      <CustomTypography variant={TYPOGRAPHY_VARIANT.paragraph14}>{description}</CustomTypography>
    </div>
  );
};

TooltipContent.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
};

TooltipContent.defaultProps = {
  className: null,
  name: null,
  description: null,
};

const getChannelLabel = (channel) =>
  getChannelPerformanceLabel(alignChannelFromBackend(channel)) || getResourceDocumentLabel(channel);

export const ConnectedBenchmark = ({ onSettingsClick, benchmarkConfig }) => {
  const styles = useStyles();

  const dataSourceText = `${benchmarkConfig.account?.name} (${getChannelLabel(
    benchmarkConfig.account?.channel
  )})`;
  const benchmarkByText = getUserGoalLabel(benchmarkConfig.comparisonSet?.userGoal);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <CustomTypography
          className={styles.title}
          variant={TYPOGRAPHY_VARIANT.regular14}
        >
          Projected ranking
        </CustomTypography>
        <CustomTypography className={styles.position}>#{benchmarkConfig.position}</CustomTypography>
        <CustomTypography
          className={styles.title}
          variant={TYPOGRAPHY_VARIANT.regular14}
        >
          of {benchmarkConfig.comparisonSet?.examples?.length} in benchmark set
        </CustomTypography>
        <CustomTooltip
          classes={{ tooltip: styles.tooltip }}
          title={
            <TooltipContent
            className={styles.tooltipContent}
              name={benchmarkConfig.comparisonSet?.name}
              description={benchmarkConfig.comparisonSet?.description}
            />
          }
          arrow
        >
          <ButtonText onClick={onSettingsClick}>
            <CustomTypography
              className={styles.comparisonSetName}
              variant={TYPOGRAPHY_VARIANT.regular14}
            >
              {benchmarkConfig.comparisonSet?.name}
            </CustomTypography>
          </ButtonText>
        </CustomTooltip>
        <IconButton
          onClick={onSettingsClick}
          className={styles.settingsButton}
        >
          <IconSettings className={styles.settingsIcon} />
        </IconButton>
      </div>
      <div className={styles.footer}>
        <TruncatedTypography
          className={styles.dataSource}
          title={dataSourceText}
          variant={TYPOGRAPHY_VARIANT.regular12}
        >
          Data source: {dataSourceText}
        </TruncatedTypography>
        <span className={styles.bullet}>∙</span>
        <TruncatedTypography
          className={styles.userGoal}
          title={benchmarkByText}
          variant={TYPOGRAPHY_VARIANT.regular12}
        >
          Benchmark by: {benchmarkByText}
        </TruncatedTypography>
      </div>
    </div>
  );
};

ConnectedBenchmark.propTypes = {
  onSettingsClick: PropTypes.func.isRequired,
  benchmarkConfig: PropTypes.shape({
    account: PropTypes.shape({
      name: PropTypes.string,
      channel: PropTypes.string,
    }),
    comparisonSet: PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      examples: PropTypes.array,
      userGoal: PropTypes.string,
    }),
    position: PropTypes.number,
    loadingGlobalBenchmark: PropTypes.bool,
    shouldUpgrade: PropTypes.bool,
  }).isRequired,
};
