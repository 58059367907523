import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

import { CUSTOMER_PERSONA_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation UpdateCustomerPersona(
    $id: ID!
    $name: String!
    $minAge: Int!
    $maxAge: Int!
    $gender: GenderEnum!
    $painPoints: [String!]!
    $avatarType: String
    $projectId: ID
    $approve: Boolean
    $brandVoiceId: ID
  ) {
    updateCustomerPersona(
      id: $id
      name: $name
      minAge: $minAge
      maxAge: $maxAge
      gender: $gender
      painPoints: $painPoints
      avatarType: $avatarType
      projectId: $projectId
      approve: $approve
      brandVoiceId: $brandVoiceId
    ) {
      ...CustomerPersona
    }
  }
  ${CUSTOMER_PERSONA_FRAGMENT}
`;

export const updateCustomerPersona = ({
  id,
  name,
  minAge,
  maxAge,
  gender,
  painPoints,
  avatarType,
  projectId,
  approve,
  brandVoiceId,
}) => {
  // Common params, present in all project/brief types
  const variables = {
    id,
    name,
    minAge,
    maxAge,
    gender,
    painPoints,
    avatarType,
    projectId,
    approve,
    brandVoiceId,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
