import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ActivateBrandTermsBatch($ids: [ID!]!) {
    activateBrandTermsBatch(ids: $ids)
  }
`;

export const activateBrandTermsBatch = ({ ids }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { ids },
  }).then(({ data }) => getSingleProp(data));
};
