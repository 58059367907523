export const DEFAULT_BENCHMARK_ERROR_TEXT = 'Error detecting benchmark.';

export const USER_GOAL = {
  clickRate: 'click_rate',
  clicks: 'clicks',
  commentsCount: 'comments_count',
  ctr: 'ctr',
  impressions: 'impressions',
  likes: 'likes',
  openRate: 'open_rate',
  reach: 'reach',
  reactions: 'reactions',
  shares: 'shares',
};

export const USER_GOAL_PROPS = {
  [USER_GOAL.clickRate]: {
    label: 'Click rate',
    isPercentage: true,
    shouldMultipleMetricValue: true,
  },
  [USER_GOAL.clicks]: {
    label: 'Clicks',
  },
  [USER_GOAL.commentsCount]: {
    label: 'Comments count',
  },
  [USER_GOAL.ctr]: {
    label: 'CTR',
    isPercentage: true,
  },
  [USER_GOAL.impressions]: {
    label: 'Impressions',
  },
  [USER_GOAL.likes]: {
    label: 'Likes',
  },
  [USER_GOAL.openRate]: {
    label: 'Open rate',
    isPercentage: true,
    shouldMultipleMetricValue: true,
  },
  [USER_GOAL.reach]: {
    label: 'Reach',
  },
  [USER_GOAL.reactions]: {
    label: 'Reactions',
  },
  [USER_GOAL.shares]: {
    label: 'Shares',
  },
};

export const BENCHMARK_SOURCE = {
  sharedPage: 'sharedPage',
  infinity: 'infinity',
  boost: 'boost',
  editor: 'editor',
};

export const BENCHMARK_ERROR = {
  calculateBenchmarkEmpty: 'pc_calculate_benchmark_empty',
  notEnoughDatasetError: 'not_enough_dataset_error',
  systemError: 'system_error',
  configArchived: 'config_archived',
  notEnoughEnglishDatasetError: 'not_enough_english_dataset_error',
};

export const BENCHMARK_ERROR_PROPS = {
  [BENCHMARK_ERROR.calculateBenchmarkEmpty]: {
    message: 'We couldn’t find relevant content based\non this description',
  },
};
