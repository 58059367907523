import { SLATE_TEXT_FORMATS } from 'src/lib/slate';

export const createParagraph = ({ text = '', ...restProps } = {}) => ({
  type: SLATE_TEXT_FORMATS.PARAGRAPH,
  children: [createTextNode({ text, ...restProps })],
});

export const createBulletedListNode = ({ children } = {}) => ({
  type: SLATE_TEXT_FORMATS.BULLETED_LIST,
  children,
});

export const createListItemNode = ({ text = '' } = {}) => ({
  type: SLATE_TEXT_FORMATS.LIST_ITEM,
  children: [createTextNode({ text })],
});

export const createLinkNode = ({ url, text = '' } = {}) => ({
  type: SLATE_TEXT_FORMATS.LINK,
  url,
  children: [createTextNode({ text })],
});

export const createTextNode = ({ text = '', ...restProps } = {}) => ({ text, ...restProps });

export const createBlockQuote = ({ text } = {}) => ({
  type: SLATE_TEXT_FORMATS.BLOCK_QUOTE,
  children: [createTextNode({ text })],
});

export const convertStringWithLineBreaksToParagraphs = (str) => {
  return str.split('\n').map((text) => createParagraph({ text }));
};

export const createDivider = (isLong = false) => {
  return [createParagraph({ text: isLong ? '——————————' : '———', bold: isLong })];
};

export const createLineBreak = () => {
  return [createParagraph()];
};
