import React from 'react';

import { SUPPORT_URL } from 'src/const';
import { isTeadsUI } from 'src/i18n';
import { CommonNavBarButton } from 'src/components/CommonNavBarButton';
import useStyles from './useStyles';

export const CommonItems = () => {
  const styles = useStyles();

  if (isTeadsUI()) {
    return null;
  }

  return (
    <CommonNavBarButton
      className={styles.button}
      component="a"
      href={SUPPORT_URL}
      target="_blank"
      rel="noopener"
    >
      Contact us
    </CommonNavBarButton>
  );
};

CommonItems.propTypes = {};
CommonItems.defaultProps = {};
