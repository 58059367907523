import { useCallback } from 'react';

import { usePopups } from '../services';
import { INVITE_USERS_POPUP } from '../types';

export const useInviteUsersPopup = () => {
  const popups = usePopups();

  return useCallback(() => {
    popups.openPopup(INVITE_USERS_POPUP, {
      onSubmit: () => {
        popups.closePopup(INVITE_USERS_POPUP);
      },
      onClose: () => {
        popups.closePopup(INVITE_USERS_POPUP);
      },
    });
  }, [popups]);
};
