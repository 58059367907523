import { useMemo } from 'react';

import {
  getAudienceTargetEvaluationLabel,
  getAudienceTargetLabel,
  TARGET_EVALUATION,
} from 'src/data/audiencesTargeting';

const EVALUATION_OPTIONS = Object.values(TARGET_EVALUATION).map((key) => ({
  label: key,
  value: key,
}));

export const useEvaluationOptions = (targetType) =>
  useMemo(
    () =>
      EVALUATION_OPTIONS.map((option) => ({
        ...option,
        label: `${getAudienceTargetLabel(
          targetType
        )} ${getAudienceTargetEvaluationLabel(option.label)}`,
      })),
    [targetType]
  );
