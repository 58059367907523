/* eslint-disable no-param-reassign */
import { Editor, Element as SlateElement, Point, Range, Transforms } from 'slate';

import { SLATE_TEXT_FORMATS } from 'src/lib/slate';

export const withLists = (editor) => {
  const { deleteBackward } = editor;

  editor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const [match] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === SLATE_TEXT_FORMATS.LIST_ITEM,
      });
      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);
        if (Point.equals(selection.anchor, start) && block.type === SLATE_TEXT_FORMATS.LIST_ITEM) {
          Transforms.setNodes(editor, { type: SLATE_TEXT_FORMATS.PARAGRAPH });
          Transforms.unwrapNodes(editor, {
            match: (n) =>
              [SLATE_TEXT_FORMATS.BULLETED_LIST, SLATE_TEXT_FORMATS.NUMBERED_LIST].includes(n.type),
            split: true,
          });
          return;
        }
      }
    }

    deleteBackward(...args);
  };

  return editor;
};
