import { get } from 'lodash';
import isEmpty from 'validator/lib/isEmpty';
import isLength from 'validator/lib/isLength';

import { formatNumber } from 'src/utils/format';
import { countChars, countWords } from 'src/lib/string';
import { PROJECT_TYPE } from 'src/data/project/const';
import {
  GENERATION_TOOL,
  isSocialGenerationTool,
  isSummarizerGenerationTool,
} from 'src/data/generationTool';
import { BRIEF_FORM_FIELD_NAMES, PRODUCT_QUESTIONS } from './briefContent';
import { BRIEF_TYPE } from './const';

export const COPIES_MAX_WORD_COUNT = 150;
export const ARTICLE_TITLE_MAX_CHARS_COUNT = 240;
export const ARTICLE_BODY_MAX_CHARS_COUNT = 2400;
export const EMAIL_BRIEF_MAX_CHARS = 3000;
export const EMAIL_PROMOTION_MAX_CHARS = 80;
export const SOCIAL_MANUAL_MAX_CHARS_COUNT = 600;
export const PRODUCT_NAME_MAX_CHARS = 120;
export const PRODUCT_DESCRIPTION_MAX_CHARS = 1200;
export const FREESTYLE_TITLE_MAX_CHARS = 80;
export const FREESTYLE_DESCRIPTION_MAX_WORDS = 2400;
export const MANUAL_INSTRUCTIONS_MAX_CHARS = 60;

const FREETEXT_MAX_CHAR_COUNT = 4800;
const FREETEXT_MAX_WORD_COUNT = 1000;

export const EXTRACT_ANNOTATIONS_MAX_CHARS = 3000;

export const EMPTY_FIELD_ERROR = 'Input needed';

const getMaxCharsError = (chars) => `${formatNumber(chars)} characters maximum`;

const avoidLimitMaxWordCount = (url) => {
  return url && url.includes('walmart.com');
};

const validateFieldValue = ({ value, maxChars, useMaxLimit = true }) => {
  const messages = [];

  const alignedValue = value || '';
  if (isEmpty(alignedValue, { ignore_whitespace: true })) {
    messages.push(EMPTY_FIELD_ERROR);
  } else if (countChars(alignedValue) > maxChars && useMaxLimit) {
    messages.push(getMaxCharsError(maxChars));
  }

  return messages;
};

const validateBriefContent = ({ content, nameMaxChars, bodyMaxChars }) => {
  const url = get(content, 'url') || '';
  const title = get(content, 'title') || '';
  const body = get(content, 'article') || '';

  const useMaxWordsLimit = !avoidLimitMaxWordCount(url);
  const messagesTitle = validateFieldValue({
    value: title,
    maxChars: nameMaxChars,
    useMaxLimit: useMaxWordsLimit,
  });
  const messagesBody = validateFieldValue({
    value: body,
    maxChars: bodyMaxChars,
    useMaxLimit: useMaxWordsLimit,
  });

  const valid = messagesTitle.length === 0 && messagesBody.length === 0;

  return {
    valid,
    title: messagesTitle,
    article: messagesBody,
  };
};

export const validateProductBrief = (content) =>
  validateBriefContent({
    content,
    nameMaxChars: PRODUCT_NAME_MAX_CHARS,
    bodyMaxChars: PRODUCT_DESCRIPTION_MAX_CHARS,
  });
export const validateContentBrief = (content) =>
  validateBriefContent({
    content,
    nameMaxChars: ARTICLE_TITLE_MAX_CHARS_COUNT,
    bodyMaxChars: ARTICLE_BODY_MAX_CHARS_COUNT,
  });

export const DEFAULT_PRODUCT_QUESTIONS_VALIDATIONS = {
  [PRODUCT_QUESTIONS.productName]: {
    maxChars: 80,
  },
  [PRODUCT_QUESTIONS.productDescription]: {
    maxChars: 1200,
  },
};
export const PRODUCT_QUESTIONS_VALIDATIONS = {
  [PROJECT_TYPE.productDescription]: {
    [PRODUCT_QUESTIONS.productName]: {
      maxChars: 80,
    },
    [PRODUCT_QUESTIONS.productDescription]: {
      maxChars: 1200,
    },
  },
  [PROJECT_TYPE.amazonProductDescription]: {
    [PRODUCT_QUESTIONS.productName]: {
      maxChars: 80,
    },
    [PRODUCT_QUESTIONS.productDescription]: {
      maxChars: PRODUCT_DESCRIPTION_MAX_CHARS,
    },
  },
  [PROJECT_TYPE.walmartProductListing]: {
    [PRODUCT_QUESTIONS.productName]: {
      maxChars: 80,
    },
    [PRODUCT_QUESTIONS.productDescription]: {
      maxChars: PRODUCT_DESCRIPTION_MAX_CHARS,
    },
  },
  [PROJECT_TYPE.featuresToBenefits]: {
    [PRODUCT_QUESTIONS.productName]: {
      maxChars: 80,
    },
    [PRODUCT_QUESTIONS.productDescription]: {
      maxChars: 1200,
    },
  },
  [PROJECT_TYPE.landingPageBuilder]: {
    [PRODUCT_QUESTIONS.productName]: {
      maxChars: 80,
    },
    [PRODUCT_QUESTIONS.productDescription]: {
      maxChars: 1200,
    },
  },
};

export const getProductAllQuestionsValidations = (projectType) => {
  const projectValidations = PRODUCT_QUESTIONS_VALIDATIONS[projectType];
  return projectValidations || DEFAULT_PRODUCT_QUESTIONS_VALIDATIONS;
};

export const getProductQuestionValidations = (projectType, type) => {
  const validations = getProductAllQuestionsValidations(projectType);
  return validations[type];
};

export const getProductQuestionMaxChars = (projectType, type) => {
  const validations = getProductQuestionValidations(projectType, type);
  return (validations && validations.maxChars) || 0;
};

const createEmptyTextArray = (length) => Array.from({ length }).map(() => '');

export const validateCopies = (copies) => {
  const preparedCopies = Array.isArray(copies) ? copies : [];

  const messagesCopies = createEmptyTextArray(preparedCopies.length);
  const messagesGeneral = [];
  const events = [];

  // filter out empty copies
  const notEmptyCopies = preparedCopies.filter((content) => Boolean(content));

  // should include at minimum 1 copy
  if (notEmptyCopies.length < 1) {
    messagesGeneral.push('At least one variation is required');
    events.push(`less_than_1_copy`);
  }

  // first copy should not be longer than 150 words
  const firstFilledIndex = preparedCopies.findIndex(Boolean);
  const wordCount = countWords(preparedCopies[firstFilledIndex]);
  if (wordCount > COPIES_MAX_WORD_COUNT) {
    messagesCopies[firstFilledIndex] = `No more than ${COPIES_MAX_WORD_COUNT} words are accepted`;
    events.push('maximum');
  }

  // Consider valid if each copy is valid
  const valid = !messagesCopies.some(Boolean) && messagesGeneral.length === 0;

  return {
    valid,
    copies: messagesCopies,
    general: messagesGeneral,
    events,
  };
};

const validateBriefBody = ({ content, maxChars }) => {
  const body = get(content, 'body') || '';
  const messages = validateFieldValue({
    value: body,
    maxChars,
  });
  const valid = messages.length === 0;

  return {
    valid,
    body: messages,
  };
};

export const validateEmailBrief = (content) =>
  validateBriefBody({ content, maxChars: EMAIL_BRIEF_MAX_CHARS });

export const validateEmailPromotionField = (value = '', skip) => {
  const messages = skip
    ? []
    : validateFieldValue({
        value,
        maxChars: EMAIL_PROMOTION_MAX_CHARS,
      });

  const valid = messages.length === 0;

  return {
    valid,
    messages,
  };
};

export const validateEmailPromotionalBrief = (content, skip) => {
  const productValidation = validateProductBrief(content);
  const promoValidation = validateEmailPromotionField(content?.emailPromotion, skip);

  return {
    ...productValidation,
    emailPromotion: promoValidation.messages,
    valid: productValidation.valid && promoValidation.valid,
  };
};

export const validateSocialManualBrief = (content) =>
  validateBriefBody({ content, maxChars: SOCIAL_MANUAL_MAX_CHARS_COUNT });

export const BLOG_DESCRIPTION_MIN_WORDS_COUNT = 5;
export const BLOG_DESCRIPTION_MAX_CHAR_COUNT = 1000;
export const BLOG_KEYWORDS_MAX_COUNT = 20;
export const BLOG_MAX_WORDS_PER_KEYWORD = 8;
export const BLOG_TARGET_KEYWORDS_MAX_COUNT = 2;
export const BLOG_TARGET_MAX_WORDS_PER_KEYWORD = 8;
export const BLOG_MANUAL_MAX_WORDS_PER_KEYWORD = 7;

export const validateBlogPostBuilderBrief = (content) => {
  const description = get(content, 'description') || '';

  const messagesDescription = [];

  const descriptionWordCount = countWords(description);
  if (descriptionWordCount < BLOG_DESCRIPTION_MIN_WORDS_COUNT) {
    messagesDescription.push(
      `Your description should be at least ${BLOG_DESCRIPTION_MIN_WORDS_COUNT} words long`
    );
  } else if (!isLength(description, { max: BLOG_DESCRIPTION_MAX_CHAR_COUNT })) {
    messagesDescription.push(
      `Your description should be up to ${BLOG_DESCRIPTION_MAX_CHAR_COUNT} characters long`
    );
  }

  const valid = messagesDescription.length === 0;

  return {
    valid,
    messages: {
      description: messagesDescription,
    },
  };
};

export const validateBlogPostBuilderBriefKeywords = (
  content,
  isTargetKeywordsFlowEnabled = false
) => {
  const briefKeywords = get(content, 'keywords') || [];
  const briefTargetKeywords = get(content, 'targetKeywords') || [];
  const keywords = isTargetKeywordsFlowEnabled ? briefTargetKeywords : briefKeywords;

  const messagesKeywords = [];

  const maxKeywordsCount = isTargetKeywordsFlowEnabled
    ? BLOG_TARGET_KEYWORDS_MAX_COUNT
    : BLOG_KEYWORDS_MAX_COUNT;
  const maxWordsPerKeyword = isTargetKeywordsFlowEnabled
    ? BLOG_TARGET_MAX_WORDS_PER_KEYWORD
    : BLOG_MAX_WORDS_PER_KEYWORD;

  if (keywords.length > BLOG_KEYWORDS_MAX_COUNT) {
    messagesKeywords.push(`No more than ${maxKeywordsCount} keywords are accepted`);
  }

  const longKeyword = keywords.find((keyword) => countWords(keyword) > maxWordsPerKeyword);
  if (longKeyword) {
    messagesKeywords.push(`Keywords can be up to ${maxWordsPerKeyword} words.`);
  }

  const valid = messagesKeywords.length === 0;

  return {
    valid,
    messages: {
      keywords: messagesKeywords,
    },
  };
};

export const validateBriefEditContent = (briefType, briefContent, projectType) => {
  switch (briefType) {
    case BRIEF_TYPE.unifiedProduct:
    case BRIEF_TYPE.unifiedPromotionalEmail: {
      const errors = { title: null, article: null };
      const validations = validateProductBrief(briefContent);
      if (!validations.valid) {
        errors.title = validations.title.join();
        errors.article = validations.article.join();
      }

      if (projectType === PROJECT_TYPE.emailPromotional) {
        const { emailPromotion } = briefContent;
        const validationPromo = validateEmailPromotionField(emailPromotion);
        if (!validationPromo.valid) {
          errors.emailPromotion = validationPromo.messages.join();
        }
      }

      return errors;
    }

    case BRIEF_TYPE.email: {
      const errors = {
        body: null,
      };

      const validation = validateEmailBrief(briefContent || {});
      if (!validation.valid) {
        errors.body = validation.messages.join();
      }

      return errors;
    }

    case BRIEF_TYPE.unifiedContent: {
      const errors = { title: null, article: null };
      const validations = validateContentBrief(briefContent);
      if (!validations.valid) {
        errors.title = validations.title.join();
        errors.article = validations.article.join();
      }

      return errors;
    }

    case BRIEF_TYPE.socialManual: {
      const errors = { body: null };
      const { valid, messages } = validateSocialManualBrief({
        body: briefContent.body,
      });
      if (!valid) {
        errors.body = messages.join();
      }

      return errors;
    }

    default:
      return null;
  }
};

export const getFirstErrorFieldName = (errors) => {
  let firstErrorFieldName;

  const checkError = (error, name) => {
    if (firstErrorFieldName) return;
    if (error) {
      firstErrorFieldName = name;
    }
  };

  checkError(errors.url, BRIEF_FORM_FIELD_NAMES.url);
  checkError(errors.title, BRIEF_FORM_FIELD_NAMES.title);
  checkError(errors.article, BRIEF_FORM_FIELD_NAMES.article);
  checkError(errors.body, BRIEF_FORM_FIELD_NAMES.body);
  checkError(errors.emailPromotion, BRIEF_FORM_FIELD_NAMES.emailPromotion);
  checkError(errors.contentTaxonomy, BRIEF_FORM_FIELD_NAMES.contentTaxonomy);

  return firstErrorFieldName;
};

const isFieldEmpty = (filed) => {
  return !filed || !filed.trim();
};

export const getCanvasBriefMaxCharCount = (generationTool) => {
  if (isSocialGenerationTool(generationTool)) {
    return SOCIAL_MANUAL_MAX_CHARS_COUNT;
  }

  switch (generationTool) {
    case GENERATION_TOOL.basicEli5:
    case GENERATION_TOOL.basicTalkingPointsToParagraph:
      return 1200;

    case GENERATION_TOOL.basicSentenceExpander:
      return 160;

    case GENERATION_TOOL.basicVideoIdeas:
    case GENERATION_TOOL.basicBlogIdeas:
    case GENERATION_TOOL.basicPromotionalEmail:
      return 80;

    case GENERATION_TOOL.basicQuoraAnswers:
      return 350;

    case GENERATION_TOOL.basicEngagingQuestions:
      return 100;

    default:
      return FREETEXT_MAX_CHAR_COUNT;
  }
};

export const getCanvasBriefMaxWordCount = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.summarizer:
      return 5000;

    default:
      return FREETEXT_MAX_WORD_COUNT;
  }
};
export const validateCanvasBrief = (generationTool, content) => {
  const freeText = get(content, 'freeText') || '';
  const messagesFreeText = [];

  const maxCharCount = getCanvasBriefMaxCharCount(generationTool);
  const maxWordCount = getCanvasBriefMaxWordCount(generationTool);

  if (isFieldEmpty(freeText)) {
    messagesFreeText.push(EMPTY_FIELD_ERROR);
  } else if (isSummarizerGenerationTool(generationTool)) {
    if (countWords(freeText) > maxWordCount) {
      messagesFreeText.push(`No more than ${maxWordCount} words are accepted`);
    }
  } else if (countChars(freeText) > maxCharCount) {
    messagesFreeText.push(`No more than ${maxCharCount} characters are accepted`);
  }

  const valid = messagesFreeText.length === 0;

  return {
    valid,
    freeText: messagesFreeText,
  };
};

export const validateDocumentBrief = (briefContent, options) => {
  switch (options?.briefType) {
    case BRIEF_TYPE.unifiedProduct:
      return validateProductBrief(briefContent);

    case BRIEF_TYPE.unifiedContent:
      return validateContentBrief(briefContent);

    case BRIEF_TYPE.email:
      return validateEmailBrief(briefContent);

    case BRIEF_TYPE.unifiedPromotionalEmail:
      return validateEmailPromotionalBrief(briefContent, options?.skipEmailPromotionField);

    case BRIEF_TYPE.socialManual:
      return validateSocialManualBrief(briefContent);

    case BRIEF_TYPE.canvas:
      return validateCanvasBrief(options?.generationTool, briefContent);

    default:
      throw new Error('Unknown brief type to validate');
  }
};

export const validateFreestyleBrief = ({ allInstructions }) => {
  const errors = {
    allInstructions: null,
  };

  if (isFieldEmpty(allInstructions)) {
    errors.allInstructions = EMPTY_FIELD_ERROR;
  } else if (countWords(allInstructions) > FREESTYLE_DESCRIPTION_MAX_WORDS) {
    errors.allInstructions = `No more than ${FREESTYLE_DESCRIPTION_MAX_WORDS} words are accepted`;
  }

  return errors;
};
