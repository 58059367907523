import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: COLORS.neutral.white,

      display: 'flex',
      flexFlow: 'column nowrap',
    },
    row: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',

      '& + &': {
        marginTop: theme.spacing(0.5),
      },
    },
    rectSmall: {
      borderRadius: theme.shape.borderRadiusSmall,
      width: 285,
      height: 14,
    },
    rect: {
      borderRadius: theme.shape.borderRadiusSmall,
      width: 450,
      height: 14,
    },
    rectMedium: {
      borderRadius: theme.shape.borderRadiusSmall,
      width: 375,
      height: 14,
    },
  }),
  { name: 'EditorSkeleton' }
);
