/* global FB */

import * as env from 'src/lib/env';
import { captureException } from 'src/lib/sentry';

const SDK_SCRIPT_ID = 'facebook-jssdk';
const APP_ID = env.getFacebookAppID();

export const injectFacebookSDK = (page) => {
  // Mock Facebook SDK, only in 'test' environment
  try {
    if (process.env.REACT_APP_ENV === 'test') {
      import('./sdk.mock');
      return;
    }

    if (document.getElementById(SDK_SCRIPT_ID)) {
      return;
    }

    window.fbAsyncInit = () => {
      FB.init({
        appId: APP_ID,
        cookie: true,
        status: true,
        version: 'v8.0',
      });
    };

    const js = document.createElement('script');
    js.id = SDK_SCRIPT_ID;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';

    const firstScript = document.getElementsByTagName('script')[0];
    if (firstScript) {
      firstScript.parentNode.insertBefore(js, firstScript);
    } else {
      document.body.appendChild(js);
    }
  } catch (error) {
    captureException(error, {
      extra: {
        source: `${page} -> injectFacebookSDK`,
      },
    });
  }
};

export * from './facebook';
export * from './urls';
