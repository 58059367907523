import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip, Popper, MenuList, Grow, Paper, ClickAwayListener } from '@material-ui/core';
import { useSlate } from 'slate-react';

import { ThreeDotsIcon } from 'src/assets/icons/ThreeDotsIcon';
import { usePopoverAnchor } from 'src/lib/hooks';
import { SLATE_EDITOR_ACTIONS } from 'src/lib/slate';
import { isEditorActionActive, isEditorActionDisabled } from '../../../../utils';
import { ToolbarButton } from '../../../ToolbarButton';
import { EditorActionMenuItem } from '../../../EditorActionMenuItem';
import { useStyles } from './useStyles';

export const ToolbarShowMoreActions = ({ className, editorActions, onAction }) => {
  const styles = useStyles();
  const editor = useSlate();

  const ref = useRef();
  const [menuAnchor, openMenu, closeMenu] = usePopoverAnchor();
  const handleClick = (event) => {
    event.preventDefault(); // prevent from editor losing focus
    openMenu(event.currentTarget);
  };

  return (
    <div className={cx(styles.root, className)}>
      <Tooltip title={menuAnchor ? '' : 'More'}>
        <ToolbarButton
          className={styles.button}
          ref={ref}
          onMouseDown={handleClick}
          selected={Boolean(menuAnchor)}
        >
          <ThreeDotsIcon className={styles.icon} />
        </ToolbarButton>
      </Tooltip>

      <Popper
        className={styles.popper}
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        role={undefined}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top',
            }}
          >
            <Paper className={styles.paper}>
              <ClickAwayListener
                onClickAway={closeMenu}
                mouseEvent="onMouseDown"
              >
                <MenuList className={styles.menuList}>
                  {editorActions.map((action) => (
                    <EditorActionMenuItem
                      key={action}
                      action={action}
                      onAction={onAction}
                      active={isEditorActionActive(editor, action)}
                      disabled={isEditorActionDisabled(editor, action)}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

ToolbarShowMoreActions.propTypes = {
  className: PropTypes.string,
  editorActions: PropTypes.arrayOf(PropTypes.oneOf(SLATE_EDITOR_ACTIONS)).isRequired,
  onAction: PropTypes.func.isRequired,
};
