import { makeStyles } from '@material-ui/core/styles';

import { HIGHLIGHT_MARK } from 'src/data/markedText';
import { COLORS } from 'src/data/ui';

const FOOTER_HEIGHT = 22;
const INPUT_FOOTER_GAP = 18;

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '&$focused': {
        '& $outline': {
          borderColor: COLORS.primary.default,
        },
      },

      '&:hover $outline': {
        borderColor: COLORS.gray.gray50,
      },

      '&$focused:hover $outline': {
        borderColor: COLORS.primary.default,
      },

      '&$empty': {
        '& $footer': {
          pointerEvents: 'none',
          opacity: '0',
        },
      },

      '&$extraPaddingBottom': {
        '& $editorWrapper': {
          paddingBottom: FOOTER_HEIGHT + INPUT_FOOTER_GAP + theme.spacing(1.5),
        },
      },
    },
    focused: {},
    empty: {},
    readOnly: {},
    extraPaddingBottom: {},

    editorWrapper: {
      position: 'relative',
      width: '100%',
      padding: theme.spacing(2, 2, 4, 2),
      display: 'flex',
      flexFlow: 'column nowrap',

      backgroundColor: theme.palette.common.white,
      borderRadius: theme.shape.borderRadius,
    },

    outline: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      border: `1.5px solid ${COLORS.gray.gray60}`,
      borderRadius: theme.shape.borderRadiusSemiLarge,
      pointerEvents: 'none',
    },

    editor: {
      width: '100%',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.text.primary,
      wordBreak: 'break-word',
      whiteSpace: 'pre-line',

      [`& [data-decoration="${HIGHLIGHT_MARK.positive}"]`]: {
        borderBottom: `1.5px solid ${COLORS.success.default}`,
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.shortest,
        }),
        '&:hover': {
          backgroundColor: COLORS.success.light,
        },
      },

      [`& [data-decoration="${HIGHLIGHT_MARK.warning}"]`]: {
        borderBottom: `1.5px solid ${COLORS.warning.default}`,
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.shortest,
        }),
        '&:hover': {
          backgroundColor: COLORS.warning.light,
        },
      },

      [`& [data-decoration="${HIGHLIGHT_MARK.error}"]`]: {
        borderBottom: `1.5px solid ${COLORS.error.default}`,
        transition: theme.transitions.create('background-color', {
          duration: theme.transitions.duration.shortest,
        }),
        '&:hover': {
          backgroundColor: COLORS.error.light,
        },
      },
    },

    loadingEditor: {
      opacity: 0,
      height: 50,
    },

    loader: {
      position: 'absolute',
      left: theme.spacing(2),
      top: theme.spacing(1.5),
    },

    footer: {
      position: 'absolute',
      left: theme.spacing(2),
      right: theme.spacing(1.5),
      bottom: theme.spacing(1),
      height: FOOTER_HEIGHT,

      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    textCounter: {
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(10),
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
    },
    action: {},
    actionButton: {
      padding: theme.spacing(0, 1.5),
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(13),
      fontWeight: theme.typography.fontWeightMedium,
      height: 24,
    },
  }),
  { name: 'TextEditorRichSlate' }
);
