import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    wrapper: {},

    root: {},
    open: {},

    iconSubmenu: {
      fontSize: theme.typography.pxToRem(16),
      marginLeft: 'auto',

      transform: 'rotate(-90deg)',
    },
    submenu: {
      // We don't want for invisible Popover div of Submenu to capture events for clicks and hovers
      // "pointer events" handling should be restored in `submenu_paper`
      pointerEvents: 'none',
      marginTop: theme.spacing(-1),
    },
    submenu_paper: {
      pointerEvents: 'all',
      minWidth: 320,
      maxWidth: 360,
      maxHeight: 360,
      marginLeft: theme.spacing(-1),
    },
    submenu_items: {},
  }),
  { name: 'ItemWithSubmenu' }
);
