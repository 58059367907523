import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation BulkDeleteResource($ids: [ID!]!) {
    bulkDeleteResources(ids: $ids) {
      id
    }
  }
`;

export const bulkDeleteResources = (ids) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { ids },
  }).then(({ data }) => getSingleProp(data));
};
