import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  () => ({
    root: {
      minHeight: 40,
      height: 'auto',
      padding: 0,
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  }),
  { name: 'ItemCustom' }
);
