import { GENERATION_TOOL, isContentImproverTool } from '../generationTool';
import { AI_RECOMPOSE_MODE } from './const';

export const AI_RECOMPOSE_MODE_LABEL = {
  [AI_RECOMPOSE_MODE.default]: 'Boost performance scores - be creative',
  [AI_RECOMPOSE_MODE.moreEngaging]: 'Boost performance scores - keep similar to original',
  [AI_RECOMPOSE_MODE.tov]: 'Change tone of voice',
};

const AI_RECOMPOSE_MODE_DESCRIPTION = {
  [AI_RECOMPOSE_MODE.default]: 'Make more engaging and coherent',
  [AI_RECOMPOSE_MODE.moreEngaging]: 'Fix spelling & grammar, replace a word where necessary',
};

export function clampRecomposeMode(mode) {
  const values = Object.values(AI_RECOMPOSE_MODE);
  if (values.includes(mode)) {
    return mode;
  }
  return AI_RECOMPOSE_MODE.moreEngaging;
}

export function getRecomposeModeLabel(mode, generationTool = null) {
  if (generationTool === GENERATION_TOOL.generalProductListing) {
    return 'Paragraph';
  }
  if (isContentImproverTool(generationTool) && mode === AI_RECOMPOSE_MODE.polished) {
    return 'Keep similar to original';
  }

  if (mode === AI_RECOMPOSE_MODE.default && isContentImproverTool(generationTool)) {
    return 'Improve';
  }

  if (mode === AI_RECOMPOSE_MODE.moreEngaging && isContentImproverTool(generationTool)) {
    return 'Improve but keep similar to original';
  }

  return AI_RECOMPOSE_MODE_LABEL[mode] || '';
}

export const getRecomposeModeDescription = (mode, generationTool) => {
  if (generationTool === GENERATION_TOOL.generalProductListing) {
    return '';
  }
  if (isContentImproverTool(generationTool) && mode === AI_RECOMPOSE_MODE.polished) {
    return 'Fix spelling & grammar, replace a word where necessary';
  }
  if (isContentImproverTool(generationTool) && mode === AI_RECOMPOSE_MODE.default) {
    return 'Write in a more engaging way';
  }
  return AI_RECOMPOSE_MODE_DESCRIPTION[mode] || '';
};
