import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';
import { CUSTOMER_ITEM_HEIGHT } from '../CustomerMenuItem';

const MAX_CUSTOMER_ITEMS = 10;

export default makeStyles(
  (theme) => ({
    paper: {
      backgroundColor: COLORS.neutral.white,
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.07)',
      borderRadius: theme.shape.borderRadius,
    },
    userInfo: {
      display: 'flex',
      padding: '10px 6px 6px',
      borderBottom: '1px solid rgba(90, 112, 150, 0.2)',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
    nameContainer: {
      display: 'flex',
      alignItems: 'center',
      marginTop: -6,

      '&:hover $editIcon': {
        visibility: 'visible',
      },
    },
    editIcon: {
      visibility: 'hidden',
      '& svg': {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    name: {
      color: COLORS.neutral.secondary,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(19),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    email: {
      color: COLORS.neutral.mediumGray,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      marginTop: theme.spacing(-1),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    customerGroup: {
      padding: theme.spacing(0.75),
      borderBottom: '1px solid rgba(90, 112, 150, 0.2)',
    },
    searchItem: {
      marginTop: theme.spacing(1),
      backgroundColor: COLORS.neutral.white,
      color: COLORS.neutral.secondary,
    },
    customerHeader: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: '#9FA8BD',
      padding: theme.spacing(0, 1.5, 0),
    },
    customerItems: {
      marginTop: theme.spacing(1),
      maxHeight: CUSTOMER_ITEM_HEIGHT * MAX_CUSTOMER_ITEMS,
      overflowY: 'auto',
      outline: 'none',
    },
    customerItem: {
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    settings: {
      padding: theme.spacing(1),
    },
    itemIcon: {
      fontSize: theme.typography.pxToRem(24),
      marginRight: '12px',
    },
    itemText: {
      fontSize: theme.typography.pxToRem(13),
      color: 'inherit',
    },
    menuItem: {
      color: COLORS.neutral.secondary,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),

      '&:hover': {
        backgroundColor: COLORS.primary.light,
      },

      '& + &': {
        marginTop: theme.spacing(1),
      },
    },

    noResults: {
      padding: theme.spacing(1, 1.5),
    },
  }),
  { name: 'ProfileMenu' }
);
