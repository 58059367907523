import { orderBy } from 'lodash';

import { COLORS } from 'src/data/ui';
import { HIGHLIGHT_MARK } from 'src/data/markedText';

/**
 * Brand term approval type
 * @enum {string}
 */
export const APPROVAL_TYPE = {
  approved: 'approved',
  doNotUse: 'dont_use',
  useCarefully: 'use_carefully',
};

const APPROVAL_TYPE_PROPS = {
  [APPROVAL_TYPE.approved]: {
    title: 'Approved',
    description: 'Terms that are ok to use',
  },
  [APPROVAL_TYPE.doNotUse]: {
    title: 'Don’t use',
    description: 'Terms that will not be included in the generated text',
  },
  [APPROVAL_TYPE.useCarefully]: {
    title: 'Use carefully',
    description: 'Terms that will be generated but also flagged',
  },
};

export const getApprovalTypeTitle = (type) => APPROVAL_TYPE_PROPS[type]?.title;
export const getApprovalTypeDescription = (type) => APPROVAL_TYPE_PROPS[type]?.description;

/**
 * Brand term status
 * @enum {string}
 */
export const BRAND_TERM_STATUS = {
  active: 'active',
  inactive: 'inactive',
  discarded: 'discarded',
};

export const isBrandTermActive = (brandTerm) => brandTerm?.status === BRAND_TERM_STATUS.active;
export const isBrandTermInactive = (brandTerm) => brandTerm?.status === BRAND_TERM_STATUS.inactive;

/**
 * Detected brand term type
 * @enum {string}
 */
export const DETECTED_BRAND_TERM_TYPE = {
  approved: 'approved',
  approvedMistake: 'approved_mistake',
  approvedCaseSensitive: 'approved_case_sensitive',
  approvedHyphenationConsistency: 'approved_hyphenation_consistency',
  doNotUse: 'dont_use',
  useCarefully: 'use_carefully',
};

const DETECTED_BRAND_TERM_TYPE_PROPS = {
  [DETECTED_BRAND_TERM_TYPE.approved]: {
    title: 'Approved term',
    colorGroup: COLORS.success,
    highlightMark: HIGHLIGHT_MARK.positive,
  },
  [DETECTED_BRAND_TERM_TYPE.approvedMistake]: {
    title: 'Common mistake',
    colorGroup: COLORS.warning,
    highlightMark: HIGHLIGHT_MARK.warning,
  },
  [DETECTED_BRAND_TERM_TYPE.approvedCaseSensitive]: {
    title: 'Capitalization mistake',
    colorGroup: COLORS.warning,
    highlightMark: HIGHLIGHT_MARK.warning,
  },
  [DETECTED_BRAND_TERM_TYPE.approvedHyphenationConsistency]: {
    title: 'Hyphenation mistake',
    colorGroup: COLORS.warning,
    highlightMark: HIGHLIGHT_MARK.warning,
  },
  [DETECTED_BRAND_TERM_TYPE.doNotUse]: {
    title: 'Don’t use',
    colorGroup: COLORS.error,
    highlightMark: HIGHLIGHT_MARK.error,
  },
  [DETECTED_BRAND_TERM_TYPE.useCarefully]: {
    title: 'Use carefully',
    colorGroup: COLORS.warning,
    highlightMark: HIGHLIGHT_MARK.warning,
  },
};

export const getDetectedBrandTermTypeTitle = (type) => DETECTED_BRAND_TERM_TYPE_PROPS[type]?.title;
export const getDetectedBrandTermTypeColorGroup = (type) =>
  DETECTED_BRAND_TERM_TYPE_PROPS[type]?.colorGroup;
export const getDetectedBrandTermTypeHighlightMark = (type) =>
  DETECTED_BRAND_TERM_TYPE_PROPS[type]?.highlightMark;

/**
 * Detected brand term reason
 * @enum {string}
 */
export const DETECTED_BRAND_TERM_REASON = {
  approved: 'approved',
  mistake: 'mistake',
  caseSensitive: 'case_sensitive',
  hyphenationConsistency: 'hyphenation_consistency',
};

/**
 * Get detected brand term type based on the approval type and reason
 * @param {Object} detectedTerm - Detected brand term
 * @returns {DETECTED_BRAND_TERM_TYPE|null}
 */
export const getDetectedBrandTermType = (detectedTerm) => {
  const approvalType = detectedTerm?.brandTerm?.approvalType;
  if (!approvalType) {
    return null;
  }

  if (approvalType === APPROVAL_TYPE.approved) {
    const REASON_TO_DETECTED_TYPE = {
      [DETECTED_BRAND_TERM_REASON.approved]: DETECTED_BRAND_TERM_TYPE.approved,
      [DETECTED_BRAND_TERM_REASON.mistake]: DETECTED_BRAND_TERM_TYPE.approvedMistake,
      [DETECTED_BRAND_TERM_REASON.caseSensitive]: DETECTED_BRAND_TERM_TYPE.approvedCaseSensitive,
      [DETECTED_BRAND_TERM_REASON.hyphenationConsistency]:
        DETECTED_BRAND_TERM_TYPE.approvedHyphenationConsistency,
    };
    return REASON_TO_DETECTED_TYPE[detectedTerm.reason];
  }

  const APPROVAL_TO_DETECTED_TYPE = {
    [APPROVAL_TYPE.doNotUse]: DETECTED_BRAND_TERM_TYPE.doNotUse,
    [APPROVAL_TYPE.useCarefully]: DETECTED_BRAND_TERM_TYPE.useCarefully,
  };
  return APPROVAL_TO_DETECTED_TYPE[approvalType];
};

export const brandTermSupportsReplacement = (approvalType) =>
  [APPROVAL_TYPE.doNotUse, APPROVAL_TYPE.useCarefully].includes(approvalType);

export const sortBrandTerms = (brandTerms) =>
  orderBy(brandTerms, ['updatedAt', 'term', 'id'], ['desc', 'asc', 'asc']);

export const RELOAD_BRAND_RULES_TOKEN_FIELD = 'postMaloneBrandRulesReload';

/**
 * Brand term part of speech
 * @enum {string}
 */
export const PART_OF_SPEECH = {
  noun: 'noun',
  adjective: 'adj',
  verb: 'verb',
  adverb: 'adv',
};

export const UNKNOWN_PART_OF_SPEECH = 'unknown';

const PART_OF_SPEECH_PROPS = {
  [PART_OF_SPEECH.noun]: {
    title: 'Noun',
  },
  [PART_OF_SPEECH.adjective]: {
    title: 'Adjective',
  },
  [PART_OF_SPEECH.verb]: {
    title: 'Verb',
  },
  [PART_OF_SPEECH.adverb]: {
    title: 'Adverb',
  },
};

export const getPartOfSpeechTitle = (type) => {
  if (!isPartOfSpeechValid(type)) {
    return 'None';
  }
  return PART_OF_SPEECH_PROPS[type]?.title;
};

export const isPartOfSpeechValid = (type) => type && Object.values(PART_OF_SPEECH).includes(type);

export const createEditorTextChangeFromDetectedBrandTerm = (detectedTerm) => {
  if (!detectedBrandTermHasReplacement(detectedTerm)) {
    return null;
  }

  const { termInText, termStartPosition, termEndPosition, replacement } = detectedTerm;
  return {
    startIndex: termStartPosition,
    endIndex: termEndPosition,
    oldText: termInText,
    newText: replacement,
  };
};

export const detectedBrandTermIsCommonMistake = (detectedTerm) => {
  if (detectedTerm.brandTerm.approvalType !== APPROVAL_TYPE.approved) {
    return false;
  }

  return detectedTerm.replacement?.length > 0 || false;
};

export const detectedBrandTermHasReplacement = (detectedTerm) => {
  return detectedBrandTermIsCommonMistake(detectedTerm) || detectedTerm.replacement?.length > 0;
};

export const brandTermToEventAttributes = (term) => ({
  brandTermId: term.id,
  term: term.term,
  termType: term.approvalType,
  caseSensitive: term.caseSensitive,
  hyphenationConsistency: term.consistentHyphenation,
  termsToCorrect: term.commonMistakes?.join(', '),
  replaceWith: term.replaceWith,
  partOfSpeech: term.partOfSpeech,
});

export const formatBrandVoicesNames = (brandVoices) => {
  if (!Array.isArray(brandVoices) || brandVoices.length === 0) {
    return ''; // Return an empty string if the input is not a valid array or is empty
  }

  if (brandVoices.length === 1) {
    return `‘${brandVoices[0].name}’`; // If only one brand name, return it wrapped in single quotes
  }

  const allButLast = brandVoices
    .slice(0, -1)
    .map((voice) => `‘${voice.name}’`)
    .join(', ');
  const last = `‘${brandVoices[brandVoices.length - 1].name}’`;

  return `${allButLast} & ${last}`;
};

export const formatTopThreeBrandVoicesWithCount = (brandVoices) => {
  if (!Array.isArray(brandVoices) || brandVoices.length === 0) {
    return ''; // Return an empty string if the input is not a valid array or is empty
  }

  const firstThreeNames = brandVoices
    .slice(0, 3)
    .map((voice) => voice.name)
    .join(', ');
  const remainingCount = brandVoices.length - 3;

  return remainingCount > 0 ? `${firstThreeNames} +${remainingCount}` : firstThreeNames;
};
