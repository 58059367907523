import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CONTENT_VALIDATION } from './schema';

const MUTATION = gql`
  mutation ValidateCopyText($projectId: ID!, $text: String!, $assetType: AssetTypeEnum) {
    validateCopyText(projectId: $projectId, text: $text, assetType: $assetType) {
      ...ContentValidation
    }
  }
  ${FRAGMENT_CONTENT_VALIDATION}
`;

export const validateCopyText = async ({ projectId, text, assetType }, options) => {
  const variables = { projectId, text, assetType };

  const result = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  });
  return getSingleProp(result.data);
};
