import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    customModelBodyItem: {
      display: 'flex',
      alignItems: 'flex-end',
      columnGap: theme.spacing(1),
    },
    customModelTag: {
      backgroundColor: COLORS.warning.light,
      textTransform: 'unset',
      padding: theme.spacing(0.25, 0.75),
      ...theme.typography.medium12,
    },
    body: {
      whiteSpace: 'pre-wrap',
    },
  }),
  { name: 'TovItem' }
);
