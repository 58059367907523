import { useMemo } from 'react';

import {
  getCustomerBillingPeriodEnd,
  getCustomerPlan,
  getCustomerTier,
} from 'src/data/subscription';
import { useCurrentCustomer } from './useCurrentCustomer';

export const useCustomerSubscriptionInfo = () => {
  const currentCustomer = useCurrentCustomer();

  return useMemo(() => {
    const currentPlan = getCustomerPlan(currentCustomer);
    const currentTier = getCustomerTier(currentCustomer);
    const endOfBillingPeriod = getCustomerBillingPeriodEnd(currentCustomer);

    return {
      currentPlan,
      currentTier,
      endOfBillingPeriod,
    };
  }, [currentCustomer]);
};
