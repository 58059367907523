import { groupBy } from 'lodash';
import { DateTime } from 'luxon';

import { formatDateLocale, parseDateTime } from 'src/lib/format';
import { BRIEF_TYPE } from 'src/data/brief/const';
import { unwrapEdges } from 'src/lib/graphql';
import { GENERATION_GUIDANCE_TYPE } from 'src/data/generation';
import { getBriefTypeByProjectType } from './projectBriefTypesAssociation';
import {
  ACCESS_STATE_TYPE,
  BAREBONE_PROJECT_ID,
  DEFAULT_BLOG_NAME,
  DEFAULT_DOCUMENT_NAME,
  PROJECT_STATUS,
} from './const';
import { isContinuousOptimizationType } from './projectType';

/**
 * Project grouping and sorting
 */
const DATE_GROUPS = {
  today: { index: 1, name: 'Modified today' },
  yesterday: { index: 2, name: 'Modified yesterday' },
  last30Days: { index: 3, name: 'Modified in the last 30 days' },
  sometime: { index: 4, name: 'Modified some time ago' },
};

const generateDateGroup = (project) => {
  const editedDate = parseDateTime(project.editedAt);
  if (editedDate && editedDate.isValid) {
    const today = DateTime.local();
    const yesterday = today.minus({ days: 1 });
    const ago30days = today.minus({ days: 30 });

    if (editedDate.hasSame(today, 'day')) {
      return DATE_GROUPS.today.name;
    }
    if (editedDate.hasSame(yesterday, 'day')) {
      return DATE_GROUPS.yesterday.name;
    }
    if (editedDate.hasSame(ago30days, 'day') || editedDate >= ago30days) {
      return DATE_GROUPS.last30Days.name;
    }
  }

  return DATE_GROUPS.sometime.name;
};

export const sortProjects = (projects) =>
  [...projects].sort((a, b) => {
    const aDate = parseDateTime(a.editedAt);
    const bDate = parseDateTime(b.editedAt);
    return bDate - aDate;
  });

export const groupProjects = (projects) => {
  // Sort 'projects' by 'updatedAt' DESC
  const sorted = sortProjects(projects);

  // Generate 'dateGroup' based on 'updatedAt' value
  const withDateGroups = sorted.map((project) => ({
    ...project,
    dateGroup: generateDateGroup(project),
  }));

  // Group by 'dateGroup'
  const groupedObj = groupBy(withDateGroups, 'dateGroup');

  // Prepare result
  return Object.entries(groupedObj).map(([group, groupedProjects]) => ({
    name: group,
    projects: groupedProjects,
  }));
};

export const getBareboneProject = (projectType) => {
  const briefType = getBriefTypeByProjectType(projectType);

  return {
    id: BAREBONE_PROJECT_ID,
    type: projectType,
    name: DEFAULT_DOCUMENT_NAME,
    brief: {
      type: briefType,
    },
  };
};

export const isBareboneProjectId = (projectId) => projectId === BAREBONE_PROJECT_ID;

export const isBareboneProject = (project) => project && isBareboneProjectId(project.id);

export const generateProjectName = (brief) => {
  switch (brief.type) {
    case BRIEF_TYPE.canvas:
      return DEFAULT_DOCUMENT_NAME;

    case BRIEF_TYPE.blogPostBuilder:
      return DEFAULT_BLOG_NAME;

    default: {
      const content = brief.content || {};
      return content.title;
    }
  }
};

const prepareBrief = (brief) => {
  const result = { ...brief };

  const { lastModifiedAt } = result;
  if (lastModifiedAt) {
    result.lastModifiedAt = formatDateLocale(lastModifiedAt);
  }

  return result;
};

const prepareCampaignKeywords = (generationRequests) => {
  const generationRequestsNodes = generationRequests.edges.map((edge) => edge.node);
  const campaignKeywords = generationRequestsNodes[0]?.generationGuidance?.campaignKeywords || [];

  return campaignKeywords.map((keyword) => ({
    id: `${GENERATION_GUIDANCE_TYPE.campaignKeyword}${keyword}`,
    type: GENERATION_GUIDANCE_TYPE.campaignKeyword,
    content: keyword,
  }));
};

export const prepareLoadedProject = (project) => {
  const result = { ...project };

  const {
    variations,
    savedVariations,
    webArticleAssets,
    brief,
    customerPersonas,
    generationRequests,
  } = result;

  if (variations) {
    const draftEdges = variations.edges || [];
    result.variations = unwrapEdges(draftEdges);
  }
  if (savedVariations) {
    const savedEdges = savedVariations.edges || [];
    result.savedVariations = unwrapEdges(savedEdges);
  }
  if (webArticleAssets) {
    const assetsEdges = webArticleAssets.edges || [];
    result.webArticleAssets = unwrapEdges(assetsEdges);
  }
  if (brief) {
    result.brief = prepareBrief(brief);
  }
  if (customerPersonas) {
    result.customerPersonaIds = customerPersonas.map((c) => c.id);
  }
  if (generationRequests) {
    result.annotations = prepareCampaignKeywords(generationRequests);
  }

  return result;
};

export const unwrapProjects = (projectEdges) => {
  const nodes = unwrapEdges(projectEdges);
  return nodes.map((node) => prepareLoadedProject(node));
};

export const isProjectReadOnly = (project) => {
  if (!project) {
    return false;
  }

  return (
    project.status === PROJECT_STATUS.legacy ||
    (project.accessState !== ACCESS_STATE_TYPE.read_write &&
      !isContinuousOptimizationType(project.type))
  );
};
