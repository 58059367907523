import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FACEBOOK_USER_AD_ACCOUNT_FRAGMENT, FACEBOOK_USER_PAGE_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation AuthorizeFacebook($customerId: ID!, $token: String!) {
    authorizeFacebook(customerId: $customerId, token: $token) {
      authState

      adAccounts {
        edges {
          node {
            ...FacebookUserAdAccount
          }
        }
      }

      pages {
        edges {
          node {
            ...FacebookUserPage
          }
        }
      }
    }
  }
  ${FACEBOOK_USER_AD_ACCOUNT_FRAGMENT}
  ${FACEBOOK_USER_PAGE_FRAGMENT}
`;

export const authorizeFacebook = (customerId, token) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, token },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => {
      const adAccounts = unwrapEdges(result?.adAccounts?.edges || []);
      const pages = unwrapEdges(result?.pages?.edges || []);

      return { adAccounts, authState: result?.authState, pages };
    });
