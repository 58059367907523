import { createContainer } from 'react-sweet-state';

import { CustomerPersonaStore } from './CustomerPersonaStore';

export const CustomerPersonaStoreContainer = createContainer(CustomerPersonaStore, {
  onInit:
    () =>
    ({ setState, dispatch }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
