import { useEffect, useState } from 'react';
import './tinymce';

export const useTinymce = () => {
  // Loading state of the Editor (lazy loading)
  const [loading, setLoading] = useState(true);

  // Check loading window.tinymce
  useEffect(() => {
    if (window.tinymce) {
      setLoading(false);
      return;
    }
    const timeout = setInterval(() => {
      if (window.tinymce) {
        setLoading(false);
        clearTimeout(timeout);
      }
    }, 100);
    // eslint-disable-next-line consistent-return
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  return {
    loading,
  };
};
