import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const LabelSkeleton = ({ className, align }) => {
  const styles = useStyles({ align });

  return (
    <div className={cx(styles.root, className)}>
      <Skeleton
        variant="circle"
        width={24}
        height={24}
      />
      <Skeleton
        variant="text"
        width={75}
      />
    </div>
  );
};

LabelSkeleton.displayName = 'LabelSkeleton';

LabelSkeleton.propTypes = {
  className: PropTypes.string,
  align: PropTypes.string.isRequired,
};

LabelSkeleton.defaultProps = {
  className: null,
};
