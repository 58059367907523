import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import IconClose from '@material-ui/icons/Close';

import { CustomInput } from 'src/components/CustomInput';
import { IconSearch } from 'src/assets/icons/Search';
import { useStyles } from './useStyles';

export const SearchMenuItem = ({ className, classes, value, onChange, placeholder, dark }) => {
  const styles = useStyles({ classes });

  const handleChange = React.useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  // Do not allow bubbling of key downs - so user can enter anything into input, and MenuList selection won't bother us
  const handleKeyDown = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleClear = React.useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <CustomInput
      classes={{
        root: cx(styles.root, className, {
          [styles.dark]: dark,
        }),
        input: styles.input_input,
        notchedOutline: styles.input_fieldset,
      }}
      value={value || ''}
      placeholder={placeholder || 'Search'}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      data-testid="SearchMenuItem"
      endAdornment={
        <InputAdornment
          position="end"
          className={styles.inputAdornment}
        >
          {value && (
            <IconButton
              onClick={handleClear}
              data-testid="SearchMenuItem__ClearIcon"
            >
              <IconClose className={styles.iconClose} />
            </IconButton>
          )}
        </InputAdornment>
      }
      startAdornment={
        <InputAdornment position="start">
          <IconSearch className={styles.iconSearch} />
        </InputAdornment>
      }
      autoFocus
    />
  );
};

SearchMenuItem.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
  placeholder: PropTypes.string,
  dark: PropTypes.bool,
};
SearchMenuItem.defaultProps = {
  className: '',
  classes: null,
  value: '',
  placeholder: '',
  dark: false,
};
