import { useState } from 'react';
import { intersection } from 'lodash';

import { useEffectOnce } from './useEffectOnce';

const TRANSLATED_CLASS_NAMES = ['translated-ltr', 'translated-rtl'];

const isClassListContainTranslated = (classList) => {
  const classes = Array.from(classList);

  return intersection(classes, TRANSLATED_CLASS_NAMES).length > 0;
};

export const useIsTranslated = () => {
  const [isTranslated, setIsTranslated] = useState(false);

  useEffectOnce(() => {
    const html = document.querySelector('html');

    setIsTranslated(isClassListContainTranslated(html.classList));

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: false, subtree: false };

    const callback = (mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          setIsTranslated(isClassListContainTranslated(mutation.target.classList));
        }
      });
    };

    const observer = new MutationObserver(callback);
    observer.observe(html, config);

    return () => {
      observer.disconnect();
    };
  });

  return isTranslated;
};
