import { createStore } from 'react-sweet-state';

import { DEBUG_AC_PROMPT_ENABLED } from 'src/lib/featureFlags';
import { DEFAULT_AC_PROMPT } from './const';

export const NAME = 'global-debug';

const INITIAL_STATE = {
  acPromptActive: false,
  acPrompt: DEFAULT_AC_PROMPT,
};

/**
 * Public available actions
 */
export const ACTIONS = {
  resetAcPrompt:
    () =>
    ({ setState }) => {
      setState({
        acPromptActive: false,
        acPrompt: DEFAULT_AC_PROMPT,
      });
    },

  updateAcPrompt:
    (value) =>
    ({ setState }) => {
      setState({ acPrompt: value });
    },

  toggleAcPrompt:
    () =>
    ({ getState, setState }) => {
      const { acPromptActive: value } = getState();
      setState({ acPromptActive: !value });
    },

  isAcPromptActive:
    () =>
    ({ getState }) => {
      return getState().acPromptActive;
    },
  getAcPrompt:
    () =>
    ({ getState }) => {
      return getState().acPrompt;
    },
  getAcPromptIfActive:
    () =>
    ({ getState }) => {
      if (!DEBUG_AC_PROMPT_ENABLED) {
        return null;
      }

      const { acPromptActive, acPrompt } = getState();
      return acPromptActive ? acPrompt : null;
    },
};

const Store = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});

export default Store;
