import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { formatNumber } from 'src/utils/format';
import useStyles from './useStyles';

const checkValidity = (count, min, max) => count >= min && count <= max;

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  count: PropTypes.number,
  minCount: PropTypes.number,
  maxCount: PropTypes.number.isRequired,
  hint: PropTypes.string,
  strict: PropTypes.bool,
  suffix: PropTypes.string,
  commaSeparators: PropTypes.bool,
  error: PropTypes.bool,
};

const defaultProps = {
  className: null,
  classes: null,
  count: 0,
  minCount: 0,
  hint: null,
  strict: false,
  suffix: '',
  commaSeparators: false,
  error: false,
};

const TextCounter = ({
  className,
  classes,
  count,
  minCount,
  maxCount,
  hint,
  strict,
  suffix,
  commaSeparators,
  error,
}) => {
  const styles = useStyles({ classes });

  const valid = strict ? checkValidity(count, minCount, maxCount) : true;

  const maxCountFormatted = useMemo(() => {
    if (maxCount === null) return null;
    if (commaSeparators) return `/${maxCount.toLocaleString()}`;

    return `/${formatNumber(maxCount)}`;
  }, [maxCount, commaSeparators]);

  const countFormatted = useMemo(() => {
    if (commaSeparators) return count.toLocaleString();

    return formatNumber(count);
  }, [count, commaSeparators]);

  return (
    <Tooltip title={hint || ''}>
      <Typography
        className={cx(styles.root, className, {
          [styles.invalid]: !valid || error,
        })}
        variant="body2"
        component="span"
      >
        {countFormatted}
        {maxCountFormatted} {suffix}
      </Typography>
    </Tooltip>
  );
};

TextCounter.propTypes = propTypes;
TextCounter.defaultProps = defaultProps;

export default React.memo(TextCounter);
