import Graphemer from 'graphemer';
import { Node as SlateNode } from 'slate';

import { normalizeMarkup, onlyPersistentHighlightMarks } from 'src/data/markedText';

const plainLeaf = (t) => ({ text: t });
const markedLeaf = (t, m) => ({ text: t, mark: m.type });

const marksByParagraph = (markup, pIndex) => (markup || []).filter((m) => m.paragraph === pIndex);

const createParagraph = (children) => ({
  type: 'paragraph',
  children,
});

const splitByMarks = (textToSplit, marks) => {
  if (marks.length === 0) {
    return [plainLeaf(textToSplit)];
  }

  const splitter = new Graphemer();
  const graphemes = splitter.splitGraphemes(textToSplit);

  let markEnd = 0;
  const leafs = marks.reduce((acc, mark) => {
    if (markEnd < mark.start) {
      const preMarkText = graphemes.slice(markEnd, mark.start).join('');
      acc.push(plainLeaf(preMarkText));
    }

    if (mark.start !== mark.end) {
      const markedText = graphemes.slice(mark.start, mark.end).join('');
      acc.push(markedLeaf(markedText, mark));
    }

    markEnd = mark.end;

    return acc;
  }, []);

  if (markEnd < graphemes.length) {
    const postMarksText = graphemes.slice(markEnd).join('');
    leafs.push(plainLeaf(postMarksText));
  }

  // Make sure we have at least one leaf
  if (leafs.length === 0) {
    leafs.push(plainLeaf(''));
  }

  return leafs;
};

export const deserializeSlateMarkedValue = (value) => {
  const text = (value && value.text) || '';
  const normalizedMarks = normalizeMarkup((value && value.markup) || []);

  // Split text to paragraphs
  const paragraphs = text.split('\n');

  return paragraphs.map((paragraphText, paragraphIndex) => {
    const paragraphMarks = marksByParagraph(normalizedMarks, paragraphIndex);
    const leafs = splitByMarks(paragraphText, paragraphMarks);

    return createParagraph(leafs);
  });
};

export const serializeSlateMarkedValue = (value) => {
  const markup = [];
  const splitter = new Graphemer();

  const text = value
    .map((paragraph, index) => {
      // String content of each paragraph in the value's children.
      const paragraphText = SlateNode.string(paragraph);

      // Walk over leafs, and collect marks
      let position = 0;
      paragraph.children.forEach((leaf) => {
        const leafText = SlateNode.string(leaf);
        const leafLength = splitter.countGraphemes(leafText);

        if (leaf.mark) {
          const entry = {
            type: leaf.mark,
            paragraph: index,
            start: position,
            end: position + leafLength,
          };

          markup.push(entry);
        }

        position += leafLength;
      });

      return paragraphText;
    })
    // Join them all with line breaks denoting paragraphs.
    .join('\n');

  // We should save only "persistent" marks
  const persistentMarks = onlyPersistentHighlightMarks(markup);

  return {
    text,
    markup: persistentMarks,
  };
};
