import React from 'react';
import { DialogContent } from '@material-ui/core';

import {
  CustomButton,
  CUSTOM_BUTTON_VARIANT,
  CUSTOM_BUTTON_COLOR,
} from 'src/components/CustomButton';
import { CustomDialog } from 'src/components/CustomDialog';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import FieldContentTaxonomy from './views/FieldContentTaxonomy';
import { useHandlers } from './useHandlers';
import { useViewState } from './useViewState';
import { useStyles } from './useStyles';

export const ScoringMethodPopup = ({ open, onSubmit, onApplyAll }) => {
  const styles = useStyles();

  const { handleConfirm, handleClose, handleChange, handleApplyAll } = useHandlers({
    onSubmit,
    onApplyAll,
  });
  const { error, selectedTaxonomyId, isLoading } = useViewState();

  return (
    <CustomDialog
      open={open}
      className={styles.root}
      classes={{ dialogActions: styles.dialogActions }}
      onClose={handleClose}
      title="Select comparison data set"
      disabled={isLoading}
      loading={isLoading}
      submitLabel="Apply"
      onSubmit={handleConfirm}
      dialogActionsSlot={
        <>
          <CustomButton
            onClick={handleClose}
            variant={CUSTOM_BUTTON_VARIANT.text}
            disabled={isLoading}
          >
            Cancel
          </CustomButton>
          {onApplyAll ? (
            <CustomButton
              className={styles.buttonApplyAll}
              onClick={handleApplyAll}
              variant={CUSTOM_BUTTON_VARIANT.outlined}
              color={CUSTOM_BUTTON_COLOR.primary}
              loading={isLoading}
            >
              Apply all
            </CustomButton>
          ) : null}
        </>
      }
    >
      <DialogContent className={styles.dialogContent}>
        <CustomTypography
          className={styles.description}
          variant={TYPOGRAPHY_VARIANT.paragraph16}
        >
          Select the data set that matches best with your industry.
          <br />
          We will compare your content with content in this data set.
        </CustomTypography>
        <CustomTypography variant={TYPOGRAPHY_VARIANT.medium14}>Select data set</CustomTypography>
        <FieldContentTaxonomy
          value={selectedTaxonomyId}
          onChange={handleChange}
          error={error}
          hideLabel
        />
      </DialogContent>
    </CustomDialog>
  );
};
