import gql from 'graphql-tag';

import { BRIEF_FROM_LIST_FRAGMENT } from 'src/graphql/brief';

export const PROJECT_FROM_LIST_FRAGMENT = gql`
  fragment ProjectFromList on Project {
    id
    type
    name
    status
    editedAt
    generatedGenerationTools
    brief {
      ...BriefFromList
    }
    projectFolder {
      id
    }
    lastModifyingUser {
      name
      email
    }
    favorite
  }
  ${BRIEF_FROM_LIST_FRAGMENT}
`;

export const PROJECT_FRAGMENT = gql`
  fragment Project on Project {
    id
    type
    name
    status
    editedAt
    generatedGenerationTools
    editorContent
    workspaceId
    accessState
    projectFolder {
      id
    }
    customerPersonas {
      brandVoice {
        id
        name
      }
      id
      name
    }
    contentTaxonomy {
      id
      name
    }
    lastModifyingUser {
      name
      email
    }
    state
    brandVoiceIdsForBrandTerms
  }
`;

export const FRAGMENT_PROJECT_FOR_SEARCH = gql`
  fragment ProjectForSearch on Project {
    id
    name
    lastModifyingUser {
      name
      email
    }
    projectFolder {
      id
      name
    }
  }
`;

export const FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION = gql`
  fragment BlogPostBuilderOutlineSection on BlogPostBuilderOutlineSection {
    id
    headline
    content
    paragraphs # can be removed after all sections would be converted to HTML
    order
    keywordsUsage
    manualKeywords
    instructions
    lastPlagiarismCheckResult # currently not used for outlines, leaving here for future use
    generationLength
    textStatus
  }
`;

export const FRAGMENT_PROJECT_BLOG_CONTENT = gql`
  fragment BlogPostBuilderContent on BlogPostBuilderContent {
    id
    title
    titleScore
    introParagraph
    introManualKeywords
    introKeywordsUsage
    introInstructions
    introGenerationLength
    introTextStatus
    lastPlagiarismCheckResult {
      potentialCopiedSentences {
        url
        title
        snippet
        percentMatched
      }
      overallSimilarityPercent
      checksUsed
      lastCheckTime
    }
    outlineSections {
      ...BlogPostBuilderOutlineSection
    }
  }
  ${FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION}
`;
