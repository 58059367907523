import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    placeholder: {
      '& > [data-slate-placeholder]': {
        paddingRight: theme.spacing(4),
      },
    },
  }),
  { name: 'TextLeaf' }
);
