import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconCustomMultiPrompt = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        d="M7.73731 19.0545H4.06534C3.69577 19.0545 3.34134 18.9077 3.08001 18.6464C2.81869 18.385 2.67188 18.0306 2.67188 17.661V2.33292C2.67188 1.96335 2.81869 1.60892 3.08001 1.34759C3.34134 1.08626 3.69577 0.939453 4.06534 0.939453H13.9394C14.3088 0.939532 14.6629 1.08621 14.9242 1.34727L17.5922 4.0153C17.8532 4.27652 17.9999 4.6307 18 5.00001V19.0545"
        fill="#66E1FF"
      />
      <path
        d="M7.73731 19.0545H4.06534C3.69577 19.0545 3.34134 18.9077 3.08001 18.6464C2.81869 18.385 2.67188 18.0306 2.67188 17.661V2.33292C2.67188 1.96335 2.81869 1.60892 3.08001 1.34759C3.34134 1.08626 3.69577 0.939453 4.06534 0.939453H13.9394C14.3088 0.939532 14.6629 1.08621 14.9242 1.34727L17.5922 4.0153C17.8532 4.27652 17.9999 4.6307 18 5.00001V19.0545L7.73731 19.0545Z"
        stroke="#1E1E1E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="5.84912"
        y="4.97852"
        width="8.20047"
        height="3.60023"
        rx="0.650059"
        fill="#E9FBFF"
        stroke="#1E1E1E"
      />
      <rect
        x="5.84912"
        y="11.8789"
        width="3.60023"
        height="3.60023"
        rx="0.650059"
        fill="#E9FBFF"
        stroke="#1E1E1E"
      />
      <rect
        x="11.5994"
        y="11.8789"
        width="3.60023"
        height="3.60023"
        rx="0.650059"
        fill="#E9FBFF"
        stroke="#1E1E1E"
      />
    </SvgIcon>
  ))
);
