import 'src/lib/polyfills';

import React, { useMemo } from 'react';
import { compose } from 'recompose';
import { ApolloProvider } from '@apollo/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { CompatRouter } from 'react-router-dom-v5-compat';

import {
  CustomerSubscriptionPausedGuard,
  UserGuard,
  useImpactClickId,
  useMaintainPerformanceStoreState,
  useStonlyWidgetUserIdentification,
  useMaintainBrandVoicesStoreState,
  useMaintainProjectFolderStoreState,
  useMaintainTalkingPointsViewsStoreState,
  useTrackApplicationLoaded,
  useWebinarBanner,
} from 'src/services';
import wrapWith from 'src/utils/wrapWith';
import ErrorBoundary from 'src/services/ErrorBoundary';
import { gqlClient } from 'src/graphql/client';
import ThemeAndStylesProvider from './components/ThemeAndStylesProvider';
import { createAppTheme } from './theme';
import GlobalStoreContainers from './store/GlobalStoreContainers';
import NotificationsService from './services/notifications';
import { PopupService } from './services/popup';
import BuildRelevanceChecker from './services/BuildRelevanceChecker';
import CustomerSelectionSaver from './services/CustomerSelectionSaver';
import { UserAnalyticsUpdater } from './services/UserAnalyticsUpdater';
import AuthorCompleteTracer from './services/AuthorCompleteTracer';
import ZendeskSupportWidget from './services/ZendeskSupportWidget';
import { SupportInfinityExtension } from './services/infinity';
import { AppRouting } from './App.Routing';
import AuthTokenGuard from './services/AuthTokenGuard';
import { OnboardingRoot } from './views/OnboardingRoot';
import { PopupNotifications } from './views/NotificationsCenter';
import { LocationGuard } from './services/LocationGuard';
import { BackendDataUpdater } from './services/BackendDataUpdater';
import { ConfirmationDialog } from './views/ConfirmationDialog';
import { DataExtractionIndicator } from './views/DataExtractionIndicator';
import { G2ReviewBoostClaimedPopup } from './views/G2ReviewBoostClaimedPopup';

const AppThemeProvider = (props) => {
  const theme = useMemo(() => createAppTheme(), []);

  return (
    <ThemeAndStylesProvider
      theme={theme}
      {...props}
    />
  );
};

const App = () => {
  useMaintainBrandVoicesStoreState();
  useMaintainPerformanceStoreState();
  useMaintainTalkingPointsViewsStoreState();
  useMaintainProjectFolderStoreState();
  useImpactClickId();
  useStonlyWidgetUserIdentification();
  useTrackApplicationLoaded();
  useWebinarBanner();

  return (
    <>
      <Helmet
        titleTemplate="%s - Anyword AI Copywriter"
        defaultTitle="Anyword AI Copywriter"
      />

      <Router>
        <CompatRouter>
          <PopupService>
            <NotificationsService />

            <BuildRelevanceChecker />
            <CustomerSelectionSaver />
            <UserAnalyticsUpdater />
            <AuthorCompleteTracer />
            <ZendeskSupportWidget />
            <SupportInfinityExtension />

            <AuthTokenGuard />
            <BackendDataUpdater />
            <UserGuard />
            <CustomerSubscriptionPausedGuard />
            <LocationGuard />
            <OnboardingRoot />
            <DataExtractionIndicator />

            <LastLocationProvider>
              <PopupNotifications />
              <ConfirmationDialog />
              <G2ReviewBoostClaimedPopup />

              <AppRouting />
            </LastLocationProvider>
          </PopupService>
        </CompatRouter>
      </Router>
    </>
  );
};

export default compose(
  wrapWith(ErrorBoundary),
  wrapWith(HelmetProvider),
  wrapWith(ApolloProvider, { client: gqlClient }),
  wrapWith(GlobalStoreContainers),
  wrapWith(AppThemeProvider)
)(App);
