import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { VARIATION_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation UpdateVariation(
    $id: ID!
    $assetType: String!
    $copyText: String
    $copyTextMarkup: JSON
    $forceScoring: Boolean
    $scoringMethod: ScoringMethodInput
    $skipLock: Boolean
  ) {
    updateVariation(
      id: $id
      assetType: $assetType
      copyText: $copyText
      copyTextMarkup: $copyTextMarkup
      forceScoring: $forceScoring
      scoringMethod: $scoringMethod
      skipLock: $skipLock
    ) {
      ...Variation
      language
    }
  }
  ${VARIATION_FRAGMENT}
`;

export const updateVariation = (
  { id, assetType, copyText, copyTextMarkup, scoringMethod, skipLock },
  options
) => {
  const variables = {
    id,
    assetType,
    copyText,
    copyTextMarkup,
    scoringMethod,
    forceScoring: true,
    skipLock,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
