import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation CreateExternalSignup($email: String!, $trackingPayload: JSON!, $source: String!) {
    createExternalSignup(email: $email, trackingPayload: $trackingPayload, source: $source) {
      email
    }
  }
`;

export const createExternalSignup = ({ email, trackingPayload, source }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { email, trackingPayload, source },
  }).then(({ data }) => getSingleProp(data));
