import { get } from 'lodash';

import { AI_SUGGESTION_MODE, GENERATION_MODE_TYPE } from './const';

export const DEFAULT_SUGGESTION_MODE_LABEL = 'Don’t apply a formula';

export const WALMART_DEFAULT_SUGGESTION_MODE_DESCRIPTION =
  "Our default high engaging template. It's based on your description and features and benefits.";
export const DEFAULT_SUGGESTION_MODE_DESCRIPTION =
  'Let Anyword choose the best formula based on your brief.';

export function clampSuggestionMode(mode) {
  const values = Object.values(AI_SUGGESTION_MODE);
  if (values.includes(mode)) {
    return mode;
  }
  return AI_SUGGESTION_MODE.standard;
}

export const getAlignedSuggestionMode = (modeId, modeType) => {
  switch (modeType) {
    case GENERATION_MODE_TYPE.customFormula:
      return AI_SUGGESTION_MODE.customFormula;

    case GENERATION_MODE_TYPE.guidance: {
      return AI_SUGGESTION_MODE.guided;
    }

    case GENERATION_MODE_TYPE.common:
    default:
      return modeId;
  }
};

const SUGGESTION_MODE_PROPS = {
  [AI_SUGGESTION_MODE.standard]: {
    label: DEFAULT_SUGGESTION_MODE_LABEL,
    description: 'Higher engagement scores at the expense of creativity',
  },
  [AI_SUGGESTION_MODE.auto]: {
    label: DEFAULT_SUGGESTION_MODE_LABEL,
  },
  [AI_SUGGESTION_MODE.engaging]: {
    label: DEFAULT_SUGGESTION_MODE_LABEL,
  },
  [AI_SUGGESTION_MODE.default]: {
    label: DEFAULT_SUGGESTION_MODE_LABEL,
  },
  [AI_SUGGESTION_MODE.creative]: {
    label: DEFAULT_SUGGESTION_MODE_LABEL,
  },
  [AI_SUGGESTION_MODE.quotes]: {
    label: 'Quotes',
    description: 'Let Anyword scan your content and choose engaging quotes',
  },
  [AI_SUGGESTION_MODE.paragraph]: {
    label: 'Paragraph',
    description: 'A paragraph outlining your product features',
  },
  [AI_SUGGESTION_MODE.list]: {
    label: 'List',
    description: 'Hook, list of features or benefits with emojis, CTA',
  },
  [AI_SUGGESTION_MODE.PAS]: {
    label: 'PAS',
    description: 'Problem, Agitate, Solve',
  },
  [AI_SUGGESTION_MODE.BAB]: {
    label: 'BAB',
    description: 'Before-After-Bridge',
  },
  [AI_SUGGESTION_MODE.FAB]: {
    label: 'FAB',
    description: 'Features, Advantages, Benefits',
  },
  [AI_SUGGESTION_MODE.PPP]: {
    label: 'PPP',
    description: 'Generate copy that follows the Praise-Picture-Push framework',
  },
  [AI_SUGGESTION_MODE.AIDA]: {
    label: 'AIDA',
    description: 'Attention, Interest, Desire, Action',
  },
  [AI_SUGGESTION_MODE.featureList]: {
    label: 'Feature list',
    description: 'Turn product descriptions into feature lists',
  },
  [AI_SUGGESTION_MODE.sms160]: {
    label: 'Short',
    description: 'Generate up to 160 characters',
  },
  [AI_SUGGESTION_MODE.sms320]: {
    label: 'Medium',
    description: 'Generate up to 320 characters',
  },
  [AI_SUGGESTION_MODE.sms480]: {
    label: 'Long',
    description: 'Generate up to 480 characters',
  },
  [AI_SUGGESTION_MODE.rewrite]: {
    label: 'More engaging',
    description: 'Rewrite your text to make it more engaging',
  },
  [AI_SUGGESTION_MODE.emotional_benefits]: {
    label: 'Informal',
    description: 'Turn product descriptions into informal lists of benefits',
  },
  [AI_SUGGESTION_MODE.topic]: {
    label: 'Keywords',
    description: 'Generate engaging and accurate keywords',
  },
  [AI_SUGGESTION_MODE.tldr]: {
    label: 'TL;DR',
    description: 'Too Long; Didn’t Read',
  },
  [AI_SUGGESTION_MODE.summarize]: {
    label: 'Paragraph',
    description: 'Generate one accurate paragraph to rule them all',
  },
  [AI_SUGGESTION_MODE.keywordDriven]: {
    label: 'Keyword-driven',
    description: 'Incorporate one or more of the campaign keywords',
  },
  [AI_SUGGESTION_MODE.benefit]: {
    label: 'Benefit',
    description:
      'Focus on a benefit of your product or service.\nE.g. "Soft, Cozy, Sustainable" or "The Ultimate Rain Shoe"',
  },
  [AI_SUGGESTION_MODE.feature]: {
    label: 'Feature',
    description:
      'Focus on a feature of your product or service.\nE.g. "Water-Repellent For Rainy Days"',
  },
  [AI_SUGGESTION_MODE.brand]: {
    label: 'Brand/Product',
    description: 'Focus on your brand or product.\nE.g. "Allbirds New Collection"',
  },
  [AI_SUGGESTION_MODE.CTA]: {
    label: 'CTA',
    description: 'Call To Action',
  },
  [AI_SUGGESTION_MODE.productCategory]: {
    label: 'Product/Category',
    description: `Mention the product type or category.\nE.g. "The world's best Tennis shoes"`,
  },
  [AI_SUGGESTION_MODE.pain]: {
    label: 'Pain',
    description: 'Focus on a pain your product or service solves.\nE.g. "Goodbye To Back Pains"',
  },
  // deprecated
  [AI_SUGGESTION_MODE.amazonDescription]: {
    label: 'Amazon Description',
    description: 'Amazon product description',
  },
  [AI_SUGGESTION_MODE.amazonFeatureList]: {
    label: 'Feature List',
    description: 'Turn product descriptions into Amazon-style feature lists',
  },
  [AI_SUGGESTION_MODE.hookBodyCta]: {
    label: 'Hook, Interest, Action',
    description: 'Perfect for announcements, launches, or driving action of any kind',
  },
  [AI_SUGGESTION_MODE.shortPost]: {
    label: '1-sentence',
    description: 'A brief and easy-to-read statement',
  },
  [AI_SUGGESTION_MODE.storyTelling]: {
    label: 'Personal',
    description: 'Get people to engage with a first person personal message',
  },
  [AI_SUGGESTION_MODE.qa]: {
    label: 'Question',
    description: 'Inspire engagement by asking your audience a thought provoking question',
  },
  [AI_SUGGESTION_MODE.fluent]: {
    label: DEFAULT_SUGGESTION_MODE_LABEL,
  },
  [AI_SUGGESTION_MODE.summary]: {
    label: 'Summarize',
    description:
      'Works well with a shared link. Summarize the main points of content in an engaging way',
  },
  [AI_SUGGESTION_MODE.clickbait]: {
    label: 'Clickbait',
    description: 'Works well with a shared link. Convey a 1st person short opinion about content',
  },
  [AI_SUGGESTION_MODE.quoteUrl]: {
    label: 'Quote',
    description: 'Works well with a shared link. Pick an engaging quote from the shared link',
  },
  [AI_SUGGESTION_MODE.question]: {
    label: 'Question',
    description: 'Engage curiosity with a targeted question',
  },
  [AI_SUGGESTION_MODE.hook]: {
    label: 'Hook',
    description: 'Grab attention with an intriguing statement',
  },
  [AI_SUGGESTION_MODE.callToAction]: {
    label: 'Call to Action',
    description: 'Prompt immediate response with a specific directive',
  },
  [AI_SUGGESTION_MODE.fourPs]: {
    label: "The 4 P's",
    description: 'Picture, Promise, Prove, Push',
  },
  [AI_SUGGESTION_MODE.oneTwoThreeFourFormula]: {
    label: 'The 1 – 2 – 3 – 4 Formula',
    description: 'Introduce your product, detail benefits, prove credibility, and prompt action',
  },
  [AI_SUGGESTION_MODE.attention]: {
    label: 'AIDA',
    description: 'Attention, Interest, Desire, Action',
  },
};

export const getSuggestionModeDefaultLabel = (mode) =>
  get(SUGGESTION_MODE_PROPS, [mode, 'label']) || '';

export const getSuggestionModeDefaultDescription = (mode) =>
  get(SUGGESTION_MODE_PROPS, [mode, 'description']) || '';
