import { useCallback } from 'react';

import { UPGRADE_POPOVER } from '../types';
import { usePopups } from '../services';

export const useUpgradePopover = () => {
  const popups = usePopups();

  return useCallback(
    ({ referenceElement, origin, placement }) => {
      popups.openPopup(UPGRADE_POPOVER, {
        referenceElement,
        origin,
        placement,
        onClose: () => {
          popups.closePopup(UPGRADE_POPOVER);
        },
      });
    },
    [popups]
  );
};
