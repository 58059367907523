import { loginToFacebook } from 'src/utils/facebook';
import { authorizeFacebook, queryFacebookPermissions } from 'src/graphql/facebook';
import { ChannelAuthError } from 'src/lib/errors';

export default async function connectFacebook(customerId) {
  // Query required permissions from BE
  const requiredPermissions = await queryFacebookPermissions(customerId);
  const permissionsStr = requiredPermissions.join();

  // Ask user to allow our app to use their data
  const loginData = await loginToFacebook(permissionsStr);
  const token = loginData && loginData.accessToken;
  if (!token) {
    throw new ChannelAuthError('Error getting Facebook authorization token');
  }

  // Save received token to BE
  return authorizeFacebook(customerId, token);
}
