import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1),

      '& svg': {
        fontSize: theme.typography.pxToRem(16),
      },
      '&:hover': {
        backgroundColor: 'unset',
        '& svg': {
          color: COLORS.primary.default,
        },
      },
    },
  }),
  { name: 'Checkbox' }
);
