import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: 32,
      minHeight: 'auto',
      padding: theme.spacing(0.5, 2, 0.5, 1.5),
      color: COLORS.gray.black,

      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(18),
      justifyContent: 'flex-start',

      borderRadius: theme.spacing(0.5),
      '&$active': {
        color: theme.palette.primary.main,
      },
      '&:disabled': {
        color: theme.palette.text.secondaryBluish,
        opacity: 0.6,
      },
    },
    active: {},
    icon: {
      fontSize: theme.typography.pxToRem(14),
      marginRight: theme.spacing(1),
      height: theme.typography.pxToRem(12),
      width: theme.typography.pxToRem(12),
    },
  }),
  { name: 'EditorActionMenuItem' }
);
