import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useStyles } from './useStyles';

export const LayoutContainerCentered = forwardRef(({ className, children, ...props }, ref) => {
  const styles = useStyles();

  return (
    <div
      className={cx(styles.root, className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});

LayoutContainerCentered.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
LayoutContainerCentered.defaultProps = {
  className: null,
  children: null,
};
