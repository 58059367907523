import { get } from 'lodash';
import { isBusinessSelfServePlan, isStarterPlan } from './subscription';

export const FEATURE_LIMITS = {
  tonesOfVoicePerCustomer: 'custom_tones_of_voice_per_customer',
  audiencePersonasPerCustomer: 'audience_personas_per_customer',
  brandBriefPerCustomer: 'brand_brief_per_customer',
  customFormulasPerCustomer: 'custom_formulas_per_customer',
  brandRulesPerCustomer: 'brand_rules_per_customer',
  workspacesPerCustomer: 'workspaces_per_customer',
  membersPerCustomer: 'members_per_customer',
  boostPerformancesPerCustomer: 'boost_performances_per_customer',
  plagiarismChecksPerCustomer: 'plagiarism_checks_per_customer',
  resourcesRowsPerCustomer: 'resources_rows_per_customer',
  resourcesPerCustomer: 'resources_per_customer',
  brandVoicePerCustomer: 'brand_voice_per_customer',
  liveScoring: 'live_scoring',
};

export const getFeatureLimitsData = (customer, feature) => {
  const featureLimits = get(customer, 'featureLimits', []);
  return featureLimits.find((f) => f.name === feature);
};

export const getFeatureLimitedAccount = (customer) => {
  return customer?.rowsAllocationPerChannel?.find((r) => r.rowsCount < r.rowsAllocated);
};

const FEATURE_LIMITS_PROPS = {
  [FEATURE_LIMITS.tonesOfVoicePerCustomer]: {
    label: 'Tones of Voice',
  },
  [FEATURE_LIMITS.audiencePersonasPerCustomer]: {
    label: 'Target audiences',
  },
  [FEATURE_LIMITS.brandBriefPerCustomer]: {
    label: 'Messaging Bank assets',
  },
  [FEATURE_LIMITS.customFormulasPerCustomer]: {
    label: 'Custom Formulas',
  },
  [FEATURE_LIMITS.brandRulesPerCustomer]: {
    label: 'Brand Terms',
  },
  [FEATURE_LIMITS.workspacesPerCustomer]: {
    label: 'Workspaces',
  },
  [FEATURE_LIMITS.membersPerCustomer]: {
    label: 'Members',
  },
  [FEATURE_LIMITS.boostPerformancesPerCustomer]: {
    label: 'Performance Boosts',
    upgradeCta: 'Upgrade your plan to unlock unlimited boosts and other premium features.',
  },
  [FEATURE_LIMITS.plagiarismChecksPerCustomer]: {
    label: 'Plagiarism Checks',
  },
  [FEATURE_LIMITS.resourcesRowsPerCustomer]: {
    label: 'Rows',
    upgradeCta: 'Upgrade your plan and get more insights, better decisions, and personalized text.',
  },
  [FEATURE_LIMITS.brandVoicePerCustomer]: {
    label: 'Brand Voices',
  },
  [FEATURE_LIMITS.liveScoring]: {
    label: 'Live Scoring',
  },
  [FEATURE_LIMITS.resourcesPerCustomer]: {
    label: 'Resources',
  },
};

export const featureHasUsages = (featureData) => {
  if (!featureData || !featureData.limit) {
    return true;
  }
  return (featureData.currentCount || 0) < featureData.limit;
};

export const getFeatureLimitsLabel = (feature) => FEATURE_LIMITS_PROPS[feature]?.label;

export const getFeatureFullSubtitle = ({ featureLimit, limit, label, plan }) => {
  switch (featureLimit) {
    case FEATURE_LIMITS.boostPerformancesPerCustomer:
      return `You’ve reached your limit of ${limit} ${
        isStarterPlan(plan) ? 'monthly ' : ''
      }${label}`;

    case FEATURE_LIMITS.liveScoring:
      return 'Upgrade to get real-time score';
    default:
      return null;
  }
};

export const getFeatureFullDescription = (featureLimit, plan) => {
  switch (featureLimit) {
    case FEATURE_LIMITS.brandVoicePerCustomer:
      return `To add additional brand voices, upgrade to the ${
        isBusinessSelfServePlan(plan) ? 'Enterprise' : 'Business'
      } tier and take your copywriting to the next level.`;

    case FEATURE_LIMITS.resourcesPerCustomer:
      return `To add additional resources, upgrade to the ${
        isBusinessSelfServePlan(plan) ? 'Enterprise' : 'Business'
      } tier and take your copywriting to the next level.`;

    case FEATURE_LIMITS.liveScoring:
      return 'Get real-time scoring and analytics for every copy edit you make.';

    default:
      return FEATURE_LIMITS_PROPS[featureLimit]?.upgradeCta || '';
  }
};
