/**
 * Safari with privacy options will have localStorage but won't let us write to it.
 * Android WebView might not have localStorage at all.
 * If app is embedded in an iframe, then in Chrome Incognito accessing localStorage throws.
 */

import * as log from 'src/lib/log';

const memoryStorage = {};

export function readItem(key, options) {
  let value;

  try {
    const browserStorage = getBrowserStorage(options);
    if (browserStorage) {
      value = browserStorage.getItem(key);
    } else {
      value = memoryStorage[key];
    }
  } catch (e) {
    log.warning('Browser storage is not available, error reading');
  }

  return value !== null ? value : null;
}

export function writeItem(key, value, options) {
  try {
    const browserStorage = getBrowserStorage(options);
    if (browserStorage) {
      browserStorage.setItem(key, value);
    } else {
      memoryStorage[key] = value;
    }
  } catch (e) {
    log.warning('Browser storage is not available, error writing');
    memoryStorage[key] = value;
  }
}

export function removeItem(key, options) {
  try {
    const browserStorage = getBrowserStorage(options);
    if (browserStorage) {
      browserStorage.removeItem(key);
    } else {
      delete memoryStorage[key];
    }
  } catch (e) {
    log.warning('Browser storage is not available, error removing');
  }
}

const getBrowserStorage = (options) => {
  const { session } = options || {};
  if (session) {
    return checkStorageAvailable('sessionStorage') ? window.sessionStorage : null;
  }

  return checkStorageAvailable('localStorage') ? window.localStorage : null;
};

// see: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
const checkStorageAvailable = (type) => {
  let storage;
  try {
    storage = window[type];
    const x = '__anyword_storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};
