import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './useStyles';

export const TextLeaf = forwardRef(({ attributes, children, leaf, ...restProps }, ref) => {
  const styles = useStyles();
  let modifiedChildren = children;

  if (leaf.bold) {
    modifiedChildren = <strong>{modifiedChildren}</strong>;
  }

  if (leaf.italic) {
    modifiedChildren = <em>{modifiedChildren}</em>;
  }

  if (leaf.underline) {
    modifiedChildren = <u>{modifiedChildren}</u>;
  }

  if (leaf.strikethrough) {
    modifiedChildren = <s>{modifiedChildren}</s>;
  }

  if (leaf.placeholder) {
    modifiedChildren = <span className={styles.placeholder}>{modifiedChildren}</span>;
  }

  const modifiedAttributes = {
    ...attributes,
    style: {
      ...attributes.style,
      ...(leaf.backgroundColor && { backgroundColor: leaf.backgroundColor }),
    },
  };

  return (
    <span
      ref={ref}
      {...modifiedAttributes}
      {...(leaf.highlight && { 'data-highlight': true })}
      {...(leaf.decoration && { 'data-decoration': leaf.decoration })}
      {...(leaf.selection && { 'data-selection': true })}
      {...restProps}
    >
      {modifiedChildren}
    </span>
  );
});

TextLeaf.propTypes = {
  children: PropTypes.shape({}),
  leaf: PropTypes.shape({
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underline: PropTypes.bool,
    strikethrough: PropTypes.bool,
    backgroundColor: PropTypes.string,
    highlight: PropTypes.bool,
    decoration: PropTypes.string,
    selection: PropTypes.bool,
    placeholder: PropTypes.string,
  }),
  attributes: PropTypes.shape({
    style: PropTypes.shape({}),
  }),
};

TextLeaf.defaultProps = {
  children: null,
  leaf: null,
  attributes: null,
};
