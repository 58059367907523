import { useMemo } from 'react';
import { map } from 'lodash';

import { TalkingPointsViewsStore } from './TalkingPointsViewsStore';
import { createStateSelectorHook } from 'src/lib/sweetState';

const useTalkingPointsState = createStateSelectorHook(TalkingPointsViewsStore, [
  'talkingPointsViews',
]);

export const useWorkspaceAssociatedTalkingPointsViewsIds = () => {
  const { talkingPointsViews } = useTalkingPointsState();
  return useMemo(() => map(talkingPointsViews, 'id').join(','), [talkingPointsViews]);
};
