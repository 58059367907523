import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useStyles from './useStyles';
import { ViewSubscriptionStatus } from 'src/views/ViewSubscriptionStatus';
import { InviteUsersButton } from 'src/views/InviteUsersButton';
import { InviteUserIcon } from 'src/assets/icons/InviteUser';
import { UserProfile } from './UserProfile';
import { useIsCustomerLocked } from 'src/services';
import { TRACK_EVENT_SOURCE } from 'src/lib/events';

export const TopNavigationBarItems = ({ className, light }) => {
  const styles = useStyles();
  const isCustomerLocked = useIsCustomerLocked();

  return (
    <div className={cx(styles.root, className, { [styles.light]: light })}>
      <InviteUsersButton
        className={styles.inviteButton}
        startIcon={<InviteUserIcon />}
        source={TRACK_EVENT_SOURCE.navbar}
      />
      <ViewSubscriptionStatus />
      <UserProfile disabledSettings={isCustomerLocked} />
    </div>
  );
};

TopNavigationBarItems.propTypes = {
  className: PropTypes.string,
  light: PropTypes.bool,
};
TopNavigationBarItems.defaultProps = {
  className: null,
  light: false,
};
