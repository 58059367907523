import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconLinkedin = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        d="M6.61763 16.0008H4.02666V7.858H6.61763V16.0008ZM11.9968 10.2511C11.6942 10.2511 11.4041 10.3699 11.1901 10.5815C10.9761 10.793 10.8559 11.08 10.8559 11.3791V16.0008H8.13169V7.858H10.8559V8.69611C11.4945 8.16164 12.3012 7.86347 13.1377 7.85272C14.8277 7.85272 16.0006 9.09145 16.0006 11.4424V16.0008H13.1377V11.3791C13.1384 11.2305 13.1094 11.0833 13.0524 10.9458C12.9954 10.8084 12.9114 10.6834 12.8054 10.5781C12.6994 10.4728 12.5734 10.3892 12.4347 10.3322C12.2959 10.2752 12.1471 10.2458 11.9968 10.2458V10.2511ZM6.66561 5.3173C6.66561 5.57793 6.58744 5.83271 6.44099 6.04942C6.29454 6.26613 6.08638 6.43504 5.84285 6.53478C5.59931 6.63452 5.33133 6.66061 5.07279 6.60977C4.81425 6.55892 4.57677 6.43341 4.39037 6.24912C4.20397 6.06482 4.07704 5.83001 4.02561 5.57439C3.97418 5.31876 4.00058 5.0538 4.10145 4.813C4.20233 4.57221 4.37316 4.3664 4.59234 4.2216C4.81152 4.0768 5.0692 3.99951 5.3328 3.99951C5.68444 4.00229 6.02071 4.14236 6.26835 4.38919C6.516 4.63602 6.65496 4.96962 6.65495 5.3173H6.66561Z"
        fill="#0A66C2"
        stroke="#0A66C2"
        strokeWidth="0.160009"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  ))
);
