import { countWords } from 'src/lib/string';
import {
  AI_GENERATION_MODE,
  AI_RECOMPOSE_MODE,
  AI_SUGGESTION_MODE,
  GENERATION_GUIDANCE_TYPE,
  MAX_WORDS_FOR_SCORE,
  isHebrewLanguage,
  isNonEnglish,
} from 'src/data/generation';
import {
  ASSET_TYPE,
  getAssetTypeMaxCopyLength,
  isAssetSupportSuggestKeywords,
  isCanvasAssetType,
  isLandingAssetType,
  isSmsAssetType,
  LANDING_ASSET_TYPE,
} from './asset';
import { VARIATION_SOURCE } from './variation';
import { BRIEF_TYPE, isExtractingBriefType } from './brief';
import { PROJECT_TYPE } from './project';
import { TEXT_ANNOTATION_TYPE } from './annotation';
import {
  doesToolSupportSuggestKeywords,
  getToolDefaultAssetType,
  getToolBriefType,
  GENERATION_TOOL,
  isToolSupportCustomerPersona,
  generationToolAllowsScoresForNonEnglish,
  generationToolAllowsScores,
  isContentImproverTool,
} from './generationTool';
import { BLOCKED_REASON } from './common';

export function generationModeFromVariationSource(source) {
  switch (source) {
    case VARIATION_SOURCE.suggest:
    case VARIATION_SOURCE.defaultSuggest:
      return AI_GENERATION_MODE.suggest;

    case VARIATION_SOURCE.recompose:
      return AI_GENERATION_MODE.recompose;

    default:
      return null;
  }
}

export const areSuggestKeywordsDisabled = (suggestionMode, briefType, assetType, projectType) => {
  // in case of Landing page builder project type
  if (
    projectType === PROJECT_TYPE.landingPageBuilder &&
    assetType !== ASSET_TYPE.landingBuilderCta
  ) {
    return false;
  }

  if (isCanvasAssetType(assetType)) {
    return !isAssetSupportSuggestKeywords(assetType);
  }

  if (isLandingAssetType(assetType)) {
    if (briefType === BRIEF_TYPE.shopifyProductBrief) {
      return suggestionMode === AI_SUGGESTION_MODE.list;
    }
    return [AI_SUGGESTION_MODE.standard, AI_SUGGESTION_MODE.rewrite].includes(suggestionMode);
  }

  // Keywords are disabled in "quote" and "list" modes
  if ([AI_SUGGESTION_MODE.quotes, AI_SUGGESTION_MODE.list].includes(suggestionMode)) {
    return true;
  }

  // Allow to use "keywords" in both google asset types in all "suggestion modes"
  if ([ASSET_TYPE.googleHeadline, ASSET_TYPE.googleDescription].includes(assetType)) {
    return false;
  }

  return false;
};

// TODO: compare to suggestion modes on production
export const doesToolSupportSuggestKeywordsForMode = ({ generationTool, suggestionMode }) => {
  if (!doesToolSupportSuggestKeywords(generationTool)) {
    return false;
  }

  const assetType = getToolDefaultAssetType(generationTool);
  if (isLandingAssetType(assetType)) {
    return (
      [
        LANDING_ASSET_TYPE.landingBuilderSubHeadline,
        LANDING_ASSET_TYPE.features,
        LANDING_ASSET_TYPE.benefits,
        ASSET_TYPE.landingBuilderHeadline,
      ].includes(assetType) ||
      suggestionMode === AI_SUGGESTION_MODE.creative ||
      suggestionMode === AI_RECOMPOSE_MODE.default
    );
  }

  // Keywords are disabled in "quote" modes
  if (suggestionMode === AI_SUGGESTION_MODE.quotes) {
    return false;
  }

  return true;
};

export const doesToolSupportsPersonaForMode = ({ generationTool, suggestionMode }) => {
  if (!isToolSupportCustomerPersona(generationTool)) {
    return false;
  }

  if (generationTool === GENERATION_TOOL.facebookPrimaryTextContent) {
    return suggestionMode !== AI_SUGGESTION_MODE.quotes;
  }

  if (isContentImproverTool(generationTool)) {
    return suggestionMode === AI_RECOMPOSE_MODE.default;
  }

  if (suggestionMode === AI_SUGGESTION_MODE.quotes) {
    return false;
  }

  if (suggestionMode === AI_SUGGESTION_MODE.quoteUrl) {
    return false;
  }

  return true;
};

export const defaultRecomposeMode = () => AI_RECOMPOSE_MODE.default;

export function canGenerateMoreForSuggestionMode(suggestionMode) {
  return suggestionMode !== AI_SUGGESTION_MODE.quotes;
}

const getSmsAssetMaxCopyLength = (suggestionMode) => {
  switch (suggestionMode) {
    case AI_SUGGESTION_MODE.sms160:
      return 160;

    case AI_SUGGESTION_MODE.sms320:
      return 320;

    case AI_SUGGESTION_MODE.sms480:
    default:
      return 480;
  }
};
export const getAssetMaxCopyLength = ({ assetType, suggestionMode }) => {
  if (isSmsAssetType(assetType)) {
    return getSmsAssetMaxCopyLength(suggestionMode);
  }
  return getAssetTypeMaxCopyLength(assetType);
};

const ANNOTATION_TYPE_TO_GUIDANCE_TYPE = {
  [TEXT_ANNOTATION_TYPE.benefits]: GENERATION_GUIDANCE_TYPE.benefit,
  [TEXT_ANNOTATION_TYPE.pains]: GENERATION_GUIDANCE_TYPE.pain,
  [TEXT_ANNOTATION_TYPE.features]: GENERATION_GUIDANCE_TYPE.feature,
  [TEXT_ANNOTATION_TYPE.campaignKeywords]: GENERATION_GUIDANCE_TYPE.campaignKeyword,
};
export const guidanceTypeFromAnnotationType = (annotationType) => {
  return ANNOTATION_TYPE_TO_GUIDANCE_TYPE[annotationType] || null;
};
export const annotationTypeFromGuidanceType = (guidanceType) => {
  return Object.entries(ANNOTATION_TYPE_TO_GUIDANCE_TYPE).find(
    ([, value]) => value === guidanceType
  )?.[0];
};

export const generationToolSupportsExtraction = (tool) => {
  const briefType = getToolBriefType(tool);
  return isExtractingBriefType(briefType);
};

export const calculateBlockedReason = ({ language, text, generationTool }) => {
  if (isHebrewLanguage(language)) {
    return null;
  }
  if (!isScoreAvailableForGenerationToolAndLanguage({ language, generationTool })) {
    return BLOCKED_REASON.nonEnglish;
  }
  if (text && countWords(text) > MAX_WORDS_FOR_SCORE) {
    return BLOCKED_REASON.maxWordsExceeded;
  }
  return null;
};

export const alignSummarizerSuggestionModeForGeneration = ({ assetType, suggestionMode }) => {
  if (assetType === ASSET_TYPE.freeTextTopic) {
    return AI_SUGGESTION_MODE.topic;
  }
  return suggestionMode;
};

export const isScoreAvailableForGenerationToolAndLanguage = ({ language, generationTool }) => {
  if (!generationToolAllowsScores(generationTool)) {
    return false;
  }

  if (generationToolAllowsScoresForNonEnglish(generationTool)) {
    return true;
  }

  if (isHebrewLanguage(language)) {
    return true;
  }

  return !isNonEnglish(language);
};

export const filterVisibleDDTemplates = (modes) => {
  const serviceModes = [AI_SUGGESTION_MODE.guided, AI_SUGGESTION_MODE.customFormula];
  return modes.filter((mode) => !serviceModes.includes(mode));
};
