import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateBlogPostBuilderProjectStatus($projectId: ID!, $status: String!) {
    updateBlogPostBuilderProjectStatus(projectId: $projectId, status: $status) {
      id
      name
      status
    }
  }
`;

export const updateBlogPostBuilderProjectStatus = ({ projectId, status }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { projectId, status },
  }).then(({ data }) => getSingleProp(data));
};
