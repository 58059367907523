import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1),
    },
  }),
  { name: 'Radio' }
);
