import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ChannelVerizon = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 16 16"
  >
    <circle
      cx="8"
      cy="8"
      r="7.9"
      fill="black"
    />

    <g
      transform="translate(-0.5)"
      fill="#d32e2a"
    >
      <path d="M11.0102 2L6.76419 11.2641L5.13213 8H3.45508L6.12278 13.3354L6.20508 13.5H7.38946L7.4754 13.3125L12.6603 2H11.0102Z" />
    </g>
  </SvgIcon>
);

export default ChannelVerizon;
