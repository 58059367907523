import snakeCase from 'lodash/snakeCase';
import fromPairs from 'lodash/fromPairs';

const DEFAULT_ATTRIBUTES = {
  eventSource: 'platform',
  eventSourceVersion: process.env.RELEASE_VERSION || '',
};

export const getDistinctId = () => {
  return null;
};

// Do not convert names starting with $, these are overriding standard mixpanel attributes
const convertAttributesName = (name) => {
  if (name.startsWith('$')) {
    return name;
  }
  return snakeCase(name);
};

const convertAttributesNames = (attributes) => {
  const entries = Object.entries(attributes);
  const fixedEntries = entries.map(([name, value]) => {
    const newName = convertAttributesName(name);
    return [newName, value];
  });
  return fromPairs(fixedEntries);
};

export const prepareAttributes = (attributes) => {
  const result = {
    ...DEFAULT_ATTRIBUTES,
    ...attributes,
  };

  return convertAttributesNames(result);
};
