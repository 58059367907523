import { useCallback } from 'react';

import { useNotificationActions, useProjectFolderActions } from 'src/store';
import { useHistory } from 'src/lib/hooks';
import { useErrorHandler, useTrackingEvent, useQueryParamProjectFolderId } from 'src/services';
import { useDeleteProjectFolderPopup } from 'src/services/popup';
import { formatProjectsFolderRoute } from 'src/lib/routing';
import * as events from 'src/lib/events';

export const useDeleteProjectFolder = () => {
  const projectFolderActions = useProjectFolderActions();
  const openDeleteProjectFolderPopup = useDeleteProjectFolderPopup();
  const history = useHistory();
  const trackEvent = useTrackingEvent();
  const handleError = useErrorHandler();
  const notifications = useNotificationActions();
  const projectFolderId = useQueryParamProjectFolderId();

  return useCallback(
    async (projectFolder) => {
      // Should we require a confirmation dialog here?
      if (projectFolder?.projectCount > 0) {
        openDeleteProjectFolderPopup(projectFolder);
        return;
      }

      // Delete the project folder
      try {
        await projectFolderActions.deleteProjectFolder({
          id: projectFolder.id,
        });

        if (projectFolderId === projectFolder.id) {
          history.push(formatProjectsFolderRoute());
        }

        trackEvent(events.PROJECT.folderDeleted, {
          page: events.EVENT_PAGE.documents,
        });
        notifications.displaySuccess(`Folder '${projectFolder.name}' deleted`);
      } catch (error) {
        handleError(error);
      }
    },
    [
      handleError,
      notifications,
      openDeleteProjectFolderPopup,
      projectFolderActions,
      projectFolderId,
      history,
      trackEvent,
    ]
  );
};
