import PropTypes from 'prop-types';
import cx from 'classnames';

import { useStyles } from './useStyles';

export const InlineUpgradeButton = ({ className, text, onClick, upgradeUrl, dataTestId }) => {
  const styles = useStyles();

  const handleClick = () => {
    if (onClick) {
      onClick({ upgradeUrl });
    }
  };

  return (
    <span
      className={cx(styles.root, className)}
      onClick={handleClick}
      data-testid={`inlineUpgradeButton_${dataTestId}`}
    >
      {text || 'Upgrade'}
    </span>
  );
};

InlineUpgradeButton.displayName = 'InlineUpgradeButton';

InlineUpgradeButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  upgradeUrl: PropTypes.string,
  dataTestId: PropTypes.string,
};

InlineUpgradeButton.defaultProps = {
  className: null,
  text: null,
  upgradeUrl: null,
  dataTestId: '',
};
