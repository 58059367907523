import { useCallback } from 'react';

import { usePopups } from 'src/services/popup';
import { PROJECT_FOLDER } from '../types';

export const useProjectFolderPopup = () => {
  const popups = usePopups();

  return useCallback(
    (projectFolder) => {
      return new Promise((resolve) => {
        popups.openPopup(PROJECT_FOLDER, {
          projectFolder,
          onClose: (data) => {
            resolve(data);
            popups.closePopup(PROJECT_FOLDER);
          },
        });
      });
    },
    [popups]
  );
};
