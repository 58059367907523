import gql from 'graphql-tag';

import { FRAGMENT_CONTENT_VALIDATION } from 'src/graphql/schema';

export const VARIATION_FRAGMENT = gql`
  fragment Variation on Variation {
    id
    projectAiSuggestionRequestId
    projectAiRecomposeRequestId
    originalVariationId
    significantImproveOrigin
    assetType
    groupIdx
    mixtureSubAssetType
    generationTool
    source
    suggestionMode
    recomposeMode
    status
    copyText
    copyTextMarkup
    score
    isEnterpriseScoreUsed
    lastUpdateTime
    createdAt
    ageScoreHistogram
    genderScoreHistogram
    scoringMethod {
      id
      type
      name
    }
    secondaryScoringMethod {
      id
      type
      name
    }
    contentSafety {
      ...ContentValidation
    }
    engagementScoreSupportSize
    landingPageAsset {
      id
      significantImproveScoreThreshold
    }
    generationGuidance {
      keywords
      campaignKeywords
      features
      benefits
      pains
      avatar
      tov
    }
    isScoreLocked
    isEdited
    isRecompose
    improveReason
    classifiedGenerationTool
    benchmarkConfig {
      userCreated
      account {
        id
        name
        channel
      }
      assetType
      comparisonSet {
        name
        description
        userGoal
        examples
      }
    }
    chainId
    blockId
    multiPromptStepIndex
    multiPromptStepName
    multiPromptTool
    customMultiPromptId
    chainFields
    rating
    csvChainId
  }
  ${FRAGMENT_CONTENT_VALIDATION}
`;

export const VARIATION_RATING_FRAGMENT = gql`
  fragment VariationRating on VariationRating {
    id
    rating
    positiveRatingsCount
    negativeRatingsCount
  }
`;

export const VARIATION_RATINGS_FRAGMENT = gql`
  fragment VariationRatings on VariationRatings {
    ...VariationRating
  }
  ${VARIATION_RATING_FRAGMENT}
`;
