import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconResourceTypeUrl = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 24 24"
      data-fill-none
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46413 2.86328C3.13729 2.86328 2.7124 3.18634 2.7124 3.79185V21.149C2.7124 21.7545 3.13728 22.0776 3.46413 22.0776H9.15454C9.70682 22.0776 10.1545 22.5253 10.1545 23.0776C10.1545 23.6299 9.70682 24.0776 9.15454 24.0776H3.46413C1.85606 24.0776 0.712402 22.6738 0.712402 21.149V3.79185C0.712402 2.26713 1.85606 0.863281 3.46413 0.863281H20.9813C22.5894 0.863281 23.733 2.26716 23.733 3.79185V11.9883C23.733 12.5406 23.2853 12.9883 22.733 12.9883C22.1807 12.9883 21.733 12.5406 21.733 11.9883V3.79185C21.733 3.1863 21.3081 2.86328 20.9813 2.86328H3.46413Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.693848 7.69922C0.693848 7.14693 1.14156 6.69922 1.69385 6.69922H22.7328C23.2851 6.69922 23.7328 7.14693 23.7328 7.69922C23.7328 8.2515 23.2851 8.69922 22.7328 8.69922H1.69385C1.14156 8.69922 0.693848 8.2515 0.693848 7.69922Z"
        fill="currentColor"
      />
      <path
        d="M21.5584 20.8488L19.0618 18.0907C19.0331 18.0595 19.0175 18.0167 19.0187 17.9723C19.0243 17.9277 19.0456 17.8874 19.0779 17.8599L20.6921 16.6288C20.8666 16.4975 20.955 16.2659 20.9181 16.0369C20.8874 15.8104 20.7407 15.6237 20.5414 15.5575L13.6812 13.3203C13.5381 13.2753 13.384 13.3182 13.2777 13.4327C13.1859 13.5501 13.1554 13.711 13.1969 13.8589L15.2469 21.3756C15.3063 21.593 15.4736 21.754 15.6774 21.7899C15.8856 21.8305 16.0961 21.7332 16.2154 21.5413L17.3346 19.7657C17.3633 19.7311 17.4053 19.7136 17.4476 19.7183C17.4869 19.7167 17.5254 19.7315 17.5552 19.7598L20.068 22.5238C20.2778 22.7532 20.6168 22.7532 20.8266 22.5238L21.5853 21.6892C21.7902 21.4496 21.7782 21.072 21.5584 20.8488Z"
        fill="currentColor"
      />
    </SvgIcon>
  ))
);
