import { useCallback, useEffect, useMemo, useState } from 'react';

import { AUDIENCE_TARGET_TYPE } from 'src/data/audiencesTargeting';
import { checkValidState, checkValidUrl, validateKeywordsValue } from './utils';
import { MAX_KEYWORDS_PER_SEGMENT_ERROR_MSG, STEPS } from './const';

const DEFAULT_STATE = {
  targetType: AUDIENCE_TARGET_TYPE.REFERRER_SOURCE,
  targetEvaluation: null,
  value: '',
  keywords: [],
  step: STEPS.first,
  urlError: null,
  keywordsError: null,
};

export const usePopupState = (segment) => {
  const [state, setState] = useState({ ...DEFAULT_STATE });
  useEffect(() => {
    setState((prev) => ({ ...prev, ...segment, step: STEPS.first }));
  }, [segment]);

  const isValid = useMemo(() => checkValidState(state), [state]);

  const isUrlValid = useCallback(() => {
    if (!checkValidUrl(state)) {
      setState((prev) => ({ ...prev, urlError: 'Please enter a valid URL' }));
      return false;
    }
    return true;
  }, [state]);

  const handleChange = useCallback((part) => {
    if (part.keywords && !validateKeywordsValue(part.keywords)) {
      setState((prev) => ({
        ...prev,
        keywordsError: MAX_KEYWORDS_PER_SEGMENT_ERROR_MSG,
      }));
      return;
    }
    setState((prev) => ({ ...prev, ...part, urlError: '', keywordsError: '' }));
  }, []);

  const handleTypeChange = useCallback((targetType) => {
    setState((prev) => ({ ...prev, targetType, urlError: '' }));
  }, []);

  return {
    state,
    isValid,
    isUrlValid,
    handleChange,
    handleTypeChange,
  };
};
