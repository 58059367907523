import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateCustomerSettings($customerId: ID!, $blacklistWords: [String!]) {
    updateCustomerSettings(customerId: $customerId, blacklistWords: $blacklistWords) {
      blacklistWords
    }
  }
`;

export const updateBlacklistWords = (customerId, blacklistWords) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { customerId, blacklistWords },
  }).then(({ data }) => {
    const result = getSingleProp(data);
    return (result && result.blacklistWords) || [];
  });
