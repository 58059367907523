import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import { useSvgId } from 'src/lib/hooks';

export const IconEmptyAvatar = memo(
  forwardRef(({ opaque, ...props }, ref) => {
    const svgId = useSvgId();

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 42 42"
      >
        <g clipPath={`url(#${svgId})`}>
          <rect
            width="42"
            height="42"
            rx="21"
            fill="#8CB2FF"
          />
          <rect
            x="1.83398"
            y="15.0181"
            width="10.7273"
            height="10.7273"
            rx="5.36363"
            fill="#4285F4"
          />
          <rect
            x="-4.60156"
            y="25.7452"
            width="23.6"
            height="23.6"
            rx="11.8"
            fill="#4285F4"
          />
          <rect
            x="29.4355"
            y="15.0181"
            width="10.7273"
            height="10.7273"
            rx="5.36363"
            fill="#4285F4"
          />
          <rect
            x="23"
            y="25.7452"
            width="23.6"
            height="23.6"
            rx="11.8"
            fill="#4285F4"
          />
          <rect
            x="13.5"
            y="8.5"
            width="15"
            height="15"
            rx="7.5"
            fill="#4285F4"
          />
          <rect
            x="6.49805"
            y="24"
            width="29"
            height="29"
            rx="14.5"
            fill="#4285F4"
            stroke="#8CB2FF"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="42"
              height="42"
              rx="21"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);

IconEmptyAvatar.propTypes = {
  opaque: PropTypes.bool,
};
IconEmptyAvatar.defaultProps = {
  opaque: false,
};
