import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useSvgId } from 'src/lib/hooks';

export const ChannelCompetitorsIcon = memo(
  forwardRef((props, ref) => {
    const svgId = useSvgId();

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
      >
        <rect
          width="24"
          height="24"
          rx="4"
          fill="#FFC8C3"
        />
        <g clipPath={`url(#${svgId})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.023 6.35202C12.9863 5.98091 11.8674 5.90224 10.7889 6.12461C9.71044 6.34698 8.71395 6.86183 7.90857 7.61277C7.10319 8.36372 6.51997 9.32179 6.22278 10.3821C5.92559 11.4424 5.92589 12.564 6.22365 13.6242C6.5214 14.6843 7.10514 15.6421 7.91092 16.3926C8.71671 17.1431 9.71347 17.6574 10.7921 17.8792C11.1978 17.9626 11.4591 18.3592 11.3756 18.7649C11.2922 19.1706 10.8957 19.4319 10.4899 19.3485C9.14172 19.0712 7.89579 18.4283 6.88858 17.4902C5.88137 16.5521 5.15172 15.3549 4.77953 14.0298C4.40734 12.7046 4.40696 11.3026 4.77844 9.97726C5.14992 8.65191 5.87893 7.45434 6.88564 6.51568C7.89234 5.57702 9.13793 4.93348 10.486 4.65551C11.8341 4.37755 13.2326 4.47589 14.5285 4.93977C15.8244 5.40364 16.9677 6.21516 17.8331 7.28546C18.6986 8.35576 19.2528 9.64357 19.4352 11.0079C19.4901 11.4184 19.2017 11.7957 18.7912 11.8506C18.3806 11.9055 18.0033 11.6171 17.9484 11.2066C17.8025 10.1151 17.3591 9.08487 16.6667 8.22861C15.9744 7.37235 15.0597 6.72312 14.023 6.35202Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7956 4.82747C11.138 5.06066 11.2265 5.52722 10.9933 5.86956C10.2 7.0341 9.60001 9.29403 9.60001 11.9999C9.60001 14.7059 10.2001 16.9664 10.9933 18.1309C11.2265 18.4733 11.138 18.9398 10.7956 19.173C10.4533 19.4062 9.98673 19.3177 9.75354 18.9754C8.72155 17.4603 8.10001 14.8548 8.10001 11.9999C8.10001 9.14504 8.72157 6.54018 9.75354 5.02512C9.98673 4.68278 10.4533 4.59429 10.7956 4.82747Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.515 11.5508C4.515 11.1366 4.85079 10.8008 5.265 10.8008H12.45C12.8642 10.8008 13.2 11.1366 13.2 11.5508C13.2 11.965 12.8642 12.3008 12.45 12.3008H5.265C4.85079 12.3008 4.515 11.965 4.515 11.5508Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.8494 7.95068C5.8494 7.53647 6.18518 7.20068 6.5994 7.20068H17.4C17.8142 7.20068 18.15 7.53647 18.15 7.95068C18.15 8.3649 17.8142 8.70068 17.4 8.70068H6.5994C6.18518 8.70068 5.8494 8.3649 5.8494 7.95068Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.27879 15.1504C5.27879 14.7362 5.61458 14.4004 6.02879 14.4004H11.1C11.5142 14.4004 11.85 14.7362 11.85 15.1504C11.85 15.5646 11.5142 15.9004 11.1 15.9004H6.02879C5.61458 15.9004 5.27879 15.5646 5.27879 15.1504Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2401 4.80463C13.5951 4.59118 14.0559 4.70591 14.2693 5.06089C15.0313 6.32803 15.5159 7.74229 15.6913 9.21041C15.7404 9.6217 15.4468 9.99495 15.0356 10.0441C14.6243 10.0932 14.251 9.79964 14.2019 9.38835C14.0516 8.13075 13.6365 6.91929 12.9838 5.83386C12.7704 5.47888 12.8851 5.01808 13.2401 4.80463Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.956 11.0316C14.392 10.5955 14.9834 10.3506 15.6 10.3506C16.2166 10.3506 16.808 10.5955 17.244 11.0316C17.68 11.4676 17.925 12.059 17.925 12.6756C17.925 13.2922 17.68 13.8836 17.244 14.3196C16.808 14.7556 16.2166 15.0006 15.6 15.0006C14.9834 15.0006 14.392 14.7556 13.956 14.3196C13.5199 13.8836 13.275 13.2922 13.275 12.6756C13.275 12.059 13.5199 11.4676 13.956 11.0316ZM15.6 11.8506C15.3812 11.8506 15.1713 11.9375 15.0166 12.0922C14.8619 12.2469 14.775 12.4568 14.775 12.6756C14.775 12.8944 14.8619 13.1042 15.0166 13.2589C15.1713 13.4137 15.3812 13.5006 15.6 13.5006C15.8188 13.5006 16.0286 13.4137 16.1834 13.2589C16.3381 13.1042 16.425 12.8944 16.425 12.6756C16.425 12.4568 16.3381 12.2469 16.1834 12.0922C16.0286 11.9375 15.8188 11.8506 15.6 11.8506Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.6 13.9502C16.1121 13.9501 16.6193 14.0509 17.0925 14.2469C17.5657 14.4428 17.9957 14.7301 18.3579 15.0923C18.7201 15.4545 19.0073 15.8845 19.2033 16.3577C19.3993 16.8309 19.5001 17.338 19.5 17.8502C19.5 17.8501 19.5 17.8502 19.5 17.8502V18.7502C19.5 19.1644 19.1642 19.5002 18.75 19.5002H12.45C12.0358 19.5002 11.7 19.1644 11.7 18.7502V17.8502C11.7 16.8159 12.1109 15.8239 12.8423 15.0925C13.5736 14.3611 14.5657 13.9502 15.6 13.9502ZM16.5186 15.6328C16.2274 15.5122 15.9153 15.4501 15.6001 15.4502C14.9636 15.4502 14.353 15.7031 13.9029 16.1531C13.4529 16.6032 13.2 17.2137 13.2 17.8502V18.0002H18V17.8502C18.0001 17.535 17.938 17.2228 17.8174 16.9316C17.6969 16.6404 17.5201 16.3758 17.2972 16.153C17.0744 15.9301 16.8098 15.7533 16.5186 15.6328Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(4 4)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
