import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 496,
    },
    dialogActions: {
      justifyContent: 'center',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&:first-child': {
        padding: theme.spacing(5, 10),
      },
    },
    title: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    message: {
      marginTop: theme.spacing(3),
      textAlign: 'center',
    },
  }),
  { name: 'GenericNotificationPopup' }
);
