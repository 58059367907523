import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { CUSTOM_DATASET_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation ArchiveCustomModel($id: ID!) {
    archiveCustomDataset(id: $id) {
      ...CustomDataset
    }
  }
  ${CUSTOM_DATASET_FRAGMENT}
`;

export const archiveCustomModel = (id) => {
  const variables = {
    id,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
