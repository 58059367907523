import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_FREESTYLE_TOOL } from './schema';

const MUTATION = gql`
  mutation UpdateCustomerSuggestionFreestyleTool(
    $id: ID!
    $name: String
    $generationTool: GenerationToolEnum!
    $allInstructionsData: JSON
  ) {
    updateCustomerSuggestionFreestyleTool(
      id: $id
      name: $name
      generationTool: $generationTool
      allInstructionsData: $allInstructionsData
    ) {
      ...SuggestionFreestyleTool
    }
  }
  ${FRAGMENT_FREESTYLE_TOOL}
`;

export const updateSuggestionFreestyleTool = ({
  id,
  name,
  generationTool,
  allInstructionsData,
}) => {
  const variables = {
    id,
    name,
    generationTool,
    allInstructionsData: JSON.stringify(allInstructionsData),
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => {
    const result = getSingleProp(data);
    result.allInstructionsData = JSON.parse(result.allInstructionsData || null);
    return result;
  });
};
