import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom-v5-compat';
import cx from 'classnames';

import { SidebarButton } from '../SidebarButton';
import { useStyles } from './useStyles';

export const SidebarSubmenuItem = ({ className, classes, item, active }) => {
  const styles = useStyles();

  return (
    <SidebarButton
      className={cx(styles.root, className, { [styles.active]: active })}
      classes={{
        ...classes,
        label: item.isEmptyItem ? styles.emptyLabel : styles.label,
      }}
      item={item}
      active={active}
      component={NavLink}
      to={item.disableNavigation ? undefined : item.path}
      startIcon={undefined}
    />
  );
};

SidebarSubmenuItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape().isRequired,
  active: PropTypes.bool,
};

SidebarSubmenuItem.defaultProps = {
  className: '',
  active: false,
};
