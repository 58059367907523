import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_TALKING_POINTS_VIEW } from './schema';

const MUTATION = gql`
  mutation DeleteTalkingPointsView($id: ID!) {
    deleteCustomerTalkingPointsView(id: $id) {
      ...TalkingPointsView
    }
  }
  ${FRAGMENT_TALKING_POINTS_VIEW}
`;

export const deleteTalkingPointsView = (id) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
