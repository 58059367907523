import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_LINKEDIN_AD_ACCOUNT } from './schema';

const MUTATION = gql`
  mutation AuthorizeLinkedIn($token: String!) {
    authorizeLinkedin(authorizationCode: $token) {
      authState

      adAccounts {
        edges {
          node {
            ...LinkedinUserAdAccount
          }
        }
      }
    }
  }
  ${FRAGMENT_LINKEDIN_AD_ACCOUNT}
`;

export const authorizeLinkedin = (token) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { token },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => {
      const adAccounts = unwrapEdges(result?.adAccounts?.edges || []);

      return { adAccounts, authState: result?.authState };
    });
