import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_PROJECT_BLOG_CONTENT } from './schema';

const QUERY = gql`
  query ProjectBlogData($projectId: Int!) {
    project(id: $projectId) {
      id

      blogContent {
        ...BlogPostBuilderContent
      }
    }
  }
  ${FRAGMENT_PROJECT_BLOG_CONTENT}
`;

export const queryProjectBlogData = (projectId, options) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
    },
    options: {
      abortSignal: options?.abortSignal,
    },
  }).then(({ data }) => getSingleProp(data));
};
