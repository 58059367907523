import { useCallback } from 'react';

import { usePopups } from '../services';
import { ANALYZER_TONE_OF_VOICE_SELECTION_POPUP } from '../types';

export const useAnalyzerToneOfVoiceSelectionPopup = () => {
  const popups = usePopups();

  return useCallback(
    (generationTool) => {
      popups.openPopup(ANALYZER_TONE_OF_VOICE_SELECTION_POPUP, {
        onClose: () => {
          popups.closePopup(ANALYZER_TONE_OF_VOICE_SELECTION_POPUP);
        },
        generationTool,
      });
    },
    [popups]
  );
};
