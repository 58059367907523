import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Settings = React.memo(
  React.forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 24 24" data-fill-none>
      <path
        d="M9.88889 18.75H5.54321C5.21728 18.75 5 18.5385 5 18.2212V5.52885C5 5.21154 5.21728 5 5.54321 5H9.88889C10.2148 5 10.4321 5.21154 10.4321 5.52885C10.4321 5.84615 10.2148 6.0577 9.88889 6.0577H6.08642V17.6923H9.88889C10.2148 17.6923 10.4321 17.9038 10.4321 18.2212C10.4321 18.5385 10.2148 18.75 9.88889 18.75ZM14.7778 16.6346C14.6148 16.6346 14.5062 16.5817 14.3975 16.476C14.1803 16.2645 14.1803 15.9471 14.3975 15.7355L17.8198 12.4038H8.25926C7.93333 12.4038 7.71605 12.1923 7.71605 11.875C7.71605 11.5577 7.93333 11.3462 8.25926 11.3462H17.8198L14.3975 8.01442C14.1803 7.80288 14.1803 7.48558 14.3975 7.27404C14.6148 7.0625 14.9407 7.0625 15.158 7.27404L19.5038 11.5048C19.558 11.5577 19.6124 11.6106 19.6124 11.6635C19.6124 11.7163 19.6667 11.7692 19.6667 11.875C19.6667 11.9279 19.6667 12.0337 19.6124 12.0865C19.6124 12.1394 19.558 12.1923 19.5038 12.2452L15.158 16.476C15.0494 16.5817 14.9407 16.6346 14.7778 16.6346Z"
        fill="currentColor"
      />
    </SvgIcon>
  ))
);

export default Settings;
