import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 360,
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      padding: theme.spacing(2),
      border: theme.shape.borderGray70,
      boxShadow: theme.shape.boxShadowNormal,
      borderRadius: theme.shape.borderRadiusSemiLarge,
      backgroundColor: COLORS.neutral.white,
    },
    header: {
      display: 'flex',
      columnGap: theme.spacing(1),
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    avatar: {
      fontSize: theme.typography.pxToRem(48),
    },
    name: {
      maxWidth: 238,
      color: COLORS.gray.black,
    },
    description: {
      marginTop: theme.spacing(0.25),
      color: COLORS.gray.gray30,
      '& span': {
        color: COLORS.gray.gray70,
      },
    },
    actions: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'flex-end',
      marginLeft: 'auto',
      columnGap: theme.spacing(0.5),
    },
    iconButton: {
      padding: theme.spacing(0.5),
      color: COLORS.gray.gray40,
      '&:hover': {
        backgroundColor: COLORS.primary.silver,
      },
      '& svg': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    iconEditPersona: {
      fontSize: theme.typography.pxToRem(12),
    },

    customActions: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      borderTop: `1px solid ${COLORS.gray.gray80}`,
    },
    buttonSaveSuggestedPersona: {
      cursor: 'pointer',
      padding: theme.spacing(0.5),
      color: COLORS.gray.gray40,
      fontSize: theme.typography.pxToRem(22) + '!important',
      '&:hover': {
        backgroundColor: COLORS.primary.silver,
      },
      '& svg': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    painPoints: {
      marginTop: theme.spacing(2),
    },
  }),
  { name: 'PersonaDetailsPanel' }
);
