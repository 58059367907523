import React, { forwardRef, memo, useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const IconLandingPage = memo(
  forwardRef((props, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 20 20"
        data-fill-none
      >
        <circle
          cx="10"
          cy="10"
          r="10"
        />
        <g clipPath={`url(#&{svgId})`}>
          <path
            d="M16.125 10.4712V4.81731C16.125 4.56739 16.0328 4.32771 15.8687 4.151C15.7046 3.97428 15.4821 3.875 15.25 3.875H4.75C4.51794 3.875 4.29538 3.97428 4.13128 4.151C3.96719 4.32771 3.875 4.56739 3.875 4.81731V15.1827C3.875 15.4326 3.96719 15.6723 4.13128 15.849C4.29538 16.0257 4.51794 16.125 4.75 16.125H8.25"
            fill="white"
          />
          <path
            d="M4.31641 6.15723H16.1263"
            stroke="#1E1E1E"
            strokeWidth="0.65625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.125 6.5V14.7786C16.125 16.5829 16.125 16.0522 15.0598 16.0522C13.9946 16.0522 4.94022 16.0522 4.94022 16.0522C4.65771 16.0522 4.38676 15.918 4.187 15.6792C3.98723 15.4403 3.875 15.1163 3.875 14.7786V6.5H16.125Z"
            fill="#66E1FF"
          />
          <path
            d="M4.74758 16.125H5.25658L12.625 6.5H3.875V14.8417C3.875 15.1901 3.875 16.125 3.875 16.125C3.875 16.125 4.50949 16.125 4.74758 16.125Z"
            fill="#C2F3FF"
          />
          <path
            d="M7.51562 8.4209H12.952V13.8573H7.51562V8.4209Z"
            fill="white"
            stroke="#1E1E1E"
            strokeWidth="0.65625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.8125 13.5L9.99915 10.875L12.6241 13.5"
            fill="#78EB7B"
          />
          <path
            d="M7.8125 13.5L9.99915 10.875L12.6241 13.5"
            stroke="#1E1E1E"
            strokeWidth="0.65625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.125 10.4712V4.81731C16.125 4.56739 16.0328 4.32771 15.8687 4.151C15.7046 3.97428 15.4821 3.875 15.25 3.875H4.75C4.51794 3.875 4.29538 3.97428 4.13128 4.151C3.96719 4.32771 3.875 4.56739 3.875 4.81731V15.1827C3.875 15.4326 3.96719 15.6723 4.13128 15.849C4.29538 16.0257 4.51794 16.125 4.75 16.125H8.25"
            stroke="#1E1E1E"
            strokeWidth="0.65625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.125 9.52885V15.1827C16.125 15.4326 16.0328 15.6723 15.8687 15.849C15.7046 16.0257 15.4821 16.125 15.25 16.125H4.75C4.51794 16.125 4.29538 16.0257 4.13128 15.849C3.96719 15.6723 3.875 15.4326 3.875 15.1827V4.81731C3.875 4.56739 3.96719 4.32771 4.13128 4.151C4.29538 3.97428 4.51794 3.875 4.75 3.875H8.25"
            stroke="#1E1E1E"
            strokeWidth="0.65625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="14"
              height="14"
              transform="translate(3 3)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
