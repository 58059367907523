import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    vertical: {},
    grayish: {},

    text: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(19),
      color: COLORS.neutral.blackBlue,

      '& + &': {
        marginTop: theme.spacing(1),
      },

      '$vertical &': {
        display: 'flex',
        flexFlow: 'column nowrap',
      },

      '$grayish &': {
        color: COLORS.neutral.darkGray,
      },
    },
  }),
  { name: 'GenerationResultDetails' }
);
