import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconAIDA = memo(
  forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
      <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
      <path
        d="M16.763 8.20996H8V23.674C8 23.674 16.2602 23.674 22.605 23.674V16.7367"
        stroke="white"
        strokeWidth="1.15623"
      />
      <path
        d="M10.3164 15.5801H14.215"
        stroke="white"
        strokeWidth="1.15623"
        strokeLinejoin="round"
      />
      <path
        d="M10.3164 17.8926H17.7887"
        stroke="white"
        strokeWidth="1.15623"
        strokeLinejoin="round"
      />
      <path
        d="M10.3164 20.2051H17.7887"
        stroke="white"
        strokeWidth="1.15623"
        strokeLinejoin="round"
      />
      <path
        d="M19.6228 7.14443L16.8051 14.5891H15.1016L18.6512 6H19.7394L19.6228 7.14443ZM21.9806 14.5891L19.1565 7.14443L19.0334 6H20.1281L23.6907 14.5891H21.9806ZM21.8446 11.4036V12.5775H16.7145V11.4036H21.8446Z"
        fill="white"
      />
    </SvgIcon>
  ))
);
