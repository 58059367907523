import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useCurrentWorkspaceId, useTalkingPointsViewsActions } from 'src/store';
import { useNewestLastExtractionDate } from './useNewestLastExtractionDate';

export const useRefreshTalkingPointsViews = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const newestLastExtractionDate = useNewestLastExtractionDate();

  const talkingPointsViewsActions = useTalkingPointsViewsActions();

  // Debounce the talking points views refresh to avoid too many requests
  // (in case "last extraction date" is updated multiple times in a short period of time)
  const refreshTalkingPointsViews = useDebouncedCallback(() => {
    talkingPointsViewsActions.refreshWorkspaceTalkingPointsViewList(currentWorkspaceId);
  }, 3000);

  useEffect(() => {
    if (!currentWorkspaceId) {
      return;
    }
    // Refresh the talking points views
    refreshTalkingPointsViews();

    // Cancel pending requests when the workspace changes
    return () => {
      refreshTalkingPointsViews.cancel();
    };
  }, [currentWorkspaceId, refreshTalkingPointsViews, newestLastExtractionDate]);
};
