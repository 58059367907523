import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import useStyles from './useStyles';
import { formatNumber } from 'src/utils/format';
import { alignChannelFromBackend, getChannelPerformanceLabel } from 'src/data/channel';
import { getResourceDocumentLabel } from 'src/data/performance';
import { TruncatedTypography } from 'src/components/TruncatedTypography';

export const BlockCustomModelExtraction = ({ className, data }) => {
  const styles = useStyles();

  if (!data) {
    return null;
  }

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.card}>
        <Typography className={styles.title}>Training data</Typography>
        <div className={styles.accountContainer}>
          <TruncatedTypography className={styles.accountName}>
            {data.accountName}
          </TruncatedTypography>
          <Typography className={styles.channel}>
            {getChannelPerformanceLabel(alignChannelFromBackend(data.dataSource)) ||
              getResourceDocumentLabel(data.dataSource)}
          </Typography>
        </div>
      </div>
      <div className={styles.card}>
        <Typography className={styles.title}>Unique copy assets</Typography>
        <Typography className={styles.value}>
          {formatNumber(data.usedCopies || data.dataPointsSize)}
        </Typography>
      </div>
      <div className={styles.card}>
        <Typography className={styles.title}>Words</Typography>
        <Typography className={styles.value}>{formatNumber(data.words)}</Typography>
      </div>
    </div>
  );
};

BlockCustomModelExtraction.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    accountName: PropTypes.string,
    dataSource: PropTypes.string,
    usedCopies: PropTypes.number,
    words: PropTypes.number,
    dataPointsSize: PropTypes.number,
  }).isRequired,
};
BlockCustomModelExtraction.defaultProps = {
  className: null,
};
