import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      height: 290,
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      alignItems: 'stretch',
      padding: theme.spacing(3, 2),
    },
    header: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    title: {
      color: COLORS.gray.black,
    },
    buttonAddCorner: {
      ...theme.typography.medium12,
      height: 'auto',
      padding: 0,
    },
    wrapperAddCenter: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(10, 0, 3, 0),
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonAddCenter: {
      ...theme.typography.medium14,
      height: 32,
    },
    chart: {},
  }),
  { name: 'ChartAudience' }
);
