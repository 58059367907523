import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      border: `1px solid ${COLORS.success.light}`,
      backgroundColor: COLORS.neutral.white,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 4px 12px 0px rgba(0,0,0, 0.1)',
      padding: theme.spacing(3, 4, 1),
      rowGap: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    headerWrapper: {
      marginBottom: theme.spacing(0.5),
    },
    headerText: {
      fontFamily: theme.typography.fontRobotoSlab,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(29),
      fontWeight: theme.typography.fontWeightBold,
    },
    blockedScoreIcon: {
      width: 32,
      height: 32,
      marginRight: theme.spacing(1),
    },
    blockedReason: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      textAlign: 'left',
      width: 288,
      marginBottom: theme.spacing(1.5),
    },
    actionButton: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      padding: theme.spacing(1.5),
      width: 288,
    },
  }),
  { name: 'BlockedMessage' }
);
