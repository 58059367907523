import { useCallback } from 'react';

import { useUpgradePopup } from 'src/services/popup';
import { useHistory } from 'src/lib/hooks';

export const useHandlers = ({ upgradeUrl, upgradeActionOrigin, feature }) => {
  const openUpgradePopup = useUpgradePopup({ actionsOrigin: upgradeActionOrigin });
  const history = useHistory();

  const handleUpgradeClick = useCallback(() => {
    if (upgradeUrl) {
      history.push(upgradeUrl);
      return;
    }

    openUpgradePopup(feature);
  }, [upgradeUrl, openUpgradePopup, feature, history]);

  return {
    handleUpgradeClick,
  };
};
