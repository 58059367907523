import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      width: 40,
      height: 40,
      borderRadius: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(19),
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: COLORS.primary.light,
      color: COLORS.neutral.secondary,
    },
  }),
  { name: 'UserAvatar' }
);
