import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    row: {
      height: 56,
      '&:last-child > td': {
        borderBottom: 'none',
      },
    },
    cell: {
      padding: theme.spacing(1, 1.5),
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        '& $skeleton': {
          marginLeft: 'auto',
        },
      },
    },
    skeleton: {
      borderRadius: theme.shape.borderRadiusMinimal,
    },
  }),
  { name: 'Loader' }
);
