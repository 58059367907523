import { DateTime } from 'luxon';

export function parseDateTime(dateStr) {
  if (dateStr.includes('T')) {
    return DateTime.fromISO(dateStr);
  }
  return DateTime.fromSQL(dateStr);
}

export function formatUpdatedDate(dateStr) {
  const parsed = parseDateTime(dateStr);
  return parsed.toFormat('D');
}

export function formatUpdatedDateTime(dateStr) {
  const parsed = parseDateTime(dateStr);
  return parsed.toFormat('MMM d, hh:mm a');
}

export function formatUpdatedTimestamp(dateStr) {
  const parsed = parseDateTime(dateStr);
  return parsed.toFormat('D t ZZZZ');
}

export function formatDateMed(dateStr) {
  const parsed = parseDateTime(dateStr);
  return parsed.toLocaleString(DateTime.DATE_MED);
}

export function formatDate(dateStr, format) {
  const parsed = parseDateTime(dateStr);
  return parsed.toFormat(format);
}

export function formatDateLocale(dateStr, format) {
  const parsed = parseDateTime(dateStr);
  return parsed.toLocaleString(format);
}

export function formatDateMedNonLocale(dateStr) {
  if (!dateStr) {
    return '';
  }
  return formatDate(dateStr, 'MMM dd, yyyy');
}

export function formatTime(dateStr) {
  return formatDate(dateStr, 'HH:mm');
}

/**
 * calculates difference between given time and now
 * @param dateStr:string date from the past
 * @returns {{unit: string, count: number}} returns the biggest non-zero unit of the time diff (year max, single or plural) and it's counter (floor round)
 */
export function getDateTimeDiffNow(dateStr) {
  const parsed = parseDateTime(dateStr);

  // diffNow returns a negative value for date from the past, hence negate()
  const diffNow = parsed.diffNow(['year', 'month', 'day', 'hour', 'minute', 'second']).negate();

  let unit;

  if (diffNow.years) {
    unit = 'years';
  } else if (diffNow.months) {
    unit = 'months';
  } else if (diffNow.weeks) {
    unit = 'weeks';
  } else if (diffNow.days) {
    unit = 'days';
  } else if (diffNow.hours) {
    unit = 'hours';
  } else if (diffNow.minutes) {
    unit = 'minutes';
  } else {
    unit = 'seconds';
  }

  const count = Math.floor(diffNow[unit]);

  if (count === 1) {
    unit = unit.slice(0, -1); //remove plural s from the unit
  }

  return { unit, count };
}

export const formatDateTimeDiffNow = (dateStr) => {
  const { unit, count } = getDateTimeDiffNow(dateStr);
  return `${count} ${unit} ago`;
};
