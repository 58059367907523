export class UniqMap {
  constructor() {
    this.map = new Map();
  }

  add(key, value) {
    if (!key) {
      throw new Error('UniqMap cannot have empty keys');
    }
    if (this.map.has(key)) {
      throw new Error(`UniqMap already has this key: ${key}`);
    }

    this.map.set(key, value);
  }

  get(key) {
    return this.map.get(key);
  }

  getAll() {
    return [...this.map.entries()];
  }

  getKeys() {
    return [...this.map.keys()];
  }
}
