import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconDisk = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.94444 2.25C2.76026 2.25 2.58363 2.32316 2.4534 2.4534C2.32316 2.58363 2.25 2.76026 2.25 2.94444V13.0555C2.25 13.2397 2.32316 13.4163 2.4534 13.5465C2.58363 13.6768 2.76026 13.7499 2.94444 13.7499H13.0555C13.2397 13.7499 13.4163 13.6768 13.5465 13.5465C13.6768 13.4163 13.7499 13.2397 13.7499 13.0555V5.42175L10.5782 2.25H2.94444ZM1.39274 1.39274C1.80427 0.981199 2.36244 0.75 2.94444 0.75H10.8888C11.0877 0.75 11.2785 0.829018 11.4192 0.96967L15.0303 4.58076C15.1709 4.72141 15.2499 4.91218 15.2499 5.11109V13.0555C15.2499 13.6375 15.0187 14.1957 14.6072 14.6072C14.1957 15.0187 13.6375 15.2499 13.0555 15.2499H2.94444C2.36244 15.2499 1.80427 15.0187 1.39274 14.6072C0.981199 14.1957 0.75 13.6375 0.75 13.0555V2.94444C0.75 2.36244 0.981199 1.80427 1.39274 1.39274Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.63867 8.72229C3.63867 8.30808 3.97446 7.97229 4.38867 7.97229H11.6109C12.0251 7.97229 12.3609 8.30808 12.3609 8.72229V14.5C12.3609 14.9142 12.0251 15.25 11.6109 15.25C11.1966 15.25 10.8609 14.9142 10.8609 14.5V9.47229H5.13867V14.5C5.13867 14.9142 4.80289 15.25 4.38867 15.25C3.97446 15.25 3.63867 14.9142 3.63867 14.5V8.72229Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.38867 0.75C4.80289 0.75 5.13867 1.08579 5.13867 1.5V4.36109H10.1664C10.5806 4.36109 10.9164 4.69688 10.9164 5.11109C10.9164 5.5253 10.5806 5.86109 10.1664 5.86109H4.38867C3.97446 5.86109 3.63867 5.5253 3.63867 5.11109V1.5C3.63867 1.08579 3.97446 0.75 4.38867 0.75Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);
