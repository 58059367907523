import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { VARIATION_FRAGMENT } from './schema';

const MUTATION = gql`
  mutation OverrideVariation($id: ID!, $selectedVariationId: ID!) {
    overrideVariation(id: $id, selectedVariationId: $selectedVariationId) {
      ...Variation
      language
    }
  }
  ${VARIATION_FRAGMENT}
`;

export const overrideVariation = ({ id, selectedVariationId }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { id, selectedVariationId },
  }).then(({ data }) => getSingleProp(data));
};
