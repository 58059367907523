import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const AmazonIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 20 18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5452 7.35722C11.5452 8.25554 11.5687 9.0022 11.0664 9.80063C10.6597 10.4503 10.0123 10.8492 9.29531 10.8492C8.31176 10.8492 7.73815 10.1754 7.73815 9.17793C7.73815 7.21503 9.69473 6.85774 11.546 6.85774L11.5452 7.35722ZM14.1264 12.9754C13.9571 13.1132 13.7116 13.122 13.5228 13.0293C12.6705 12.3935 12.5198 12.0989 12.0532 11.4937C10.6483 12.7822 9.65341 13.1686 7.83295 13.1686C5.67788 13.1686 4 11.9721 4 9.57751C4 7.70721 5.12696 6.43556 6.7303 5.81213C8.11975 5.26234 10.0601 5.16245 11.5444 5.0137V4.71474C11.5444 4.16569 11.593 3.51746 11.2341 3.04205C10.9221 2.61987 10.3234 2.44414 9.796 2.44414C8.81974 2.44414 7.95123 2.89476 7.73815 3.82736C7.69441 4.03517 7.5267 4.24079 7.29337 4.25173L4.81342 4.00892C4.60358 3.96808 4.36944 3.81496 4.43101 3.52767C5.00138 0.818116 7.72033 0 10.1557 0C11.401 0 13.0294 0.298955 14.0105 1.1477C15.2566 2.19477 15.1383 3.59184 15.1383 5.11286V8.70252C15.1383 9.7824 15.6374 10.2556 16.104 10.8382C16.2693 11.0475 16.3042 11.2976 16.0959 11.45C15.5718 11.8445 14.6425 12.5707 14.1329 12.9805L14.1264 12.9754Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.11 14.9243C14.8837 15.9976 12.4637 16.5146 10.2616 16.5146C6.99902 16.5146 3.83933 15.4989 1.28403 13.8124C1.05961 13.6651 0.894334 13.9246 1.08068 14.1157C3.45044 16.5409 6.57854 17.9992 10.0542 17.9992C12.5341 17.9992 15.4127 17.114 17.3993 15.4515C17.7282 15.1766 17.4462 14.7625 17.11 14.9243ZM17.6528 16.5401C17.5791 16.7057 17.7371 16.7735 17.904 16.6473C18.9888 15.8307 19.2691 14.1201 19.0472 13.8736C18.8268 13.6286 16.931 13.4172 15.774 14.1485C15.5958 14.2608 15.6266 14.4161 15.8243 14.3942C16.4765 14.3242 17.9267 14.1667 18.1843 14.4657C18.4428 14.7646 17.8967 15.9925 17.6528 16.5401Z"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);
