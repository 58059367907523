export const defineDefaultEnumValue = (enumObject, defaultValue) => {
  Object.defineProperty(enumObject, 'DEFAULT', {
    value: defaultValue,
    enumerable: false,
  });
};

export const createEnumClampFunction = (enumObject, defaultValue = undefined) => {
  const values = Object.values(enumObject);
  return (value) => {
    if (values.includes(value)) {
      return value;
    }
    return defaultValue || values[0];
  };
};

export const defineEnumClampFunction = (enumObject, defaultValue = undefined) => {
  Object.defineProperty(enumObject, 'clamp', {
    value: createEnumClampFunction(enumObject, defaultValue),
    enumerable: false,
  });
};
