import { useEffect } from 'react';

import { useCustomerPersonaActions } from 'src/store';
import { useErrorHandler } from 'src/services';

export const usePreloadPersonas = (brandVoice) => {
  const handleError = useErrorHandler();
  const customerPersonaActions = useCustomerPersonaActions();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await customerPersonaActions.queryCustomerPersonas(brandVoice.id);
      } catch (error) {
        handleError(error);
      }
    };

    if (brandVoice?.id) {
      fetchData();
    }
  }, [brandVoice?.id, handleError, customerPersonaActions]);
};
