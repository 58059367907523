import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { PROJECT_FROM_LIST_FRAGMENT } from './schema';

const PROJECTS_PAGE_SIZE = 12;

const QUERY = gql`
  query AssociatedProjects(
    $customerId: ID!
    $workspaceId: ID!
    $filter: JSON!
    $cursor: String
  ) {
    associatedProjects(
      customerId: $customerId
      workspaceId: $workspaceId
      filter: $filter
      first: ${PROJECTS_PAGE_SIZE}
      after: $cursor
    ) {
      edges {
        node {
          ...ProjectFromList
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${PROJECT_FROM_LIST_FRAGMENT}
`;

export const queryAssociatedProjects = ({ customerId, workspaceId, cursor, filter }) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      customerId,
      workspaceId,
      filter: JSON.stringify(filter),
      cursor,
    },
  }).then(({ data }) => getSingleProp(data));
};
