import * as log from 'src/lib/log';

const URL_INDEX = '/FE_METADATA.json';
const BUILD_TAG = process.env.REACT_APP_REPOSITORY_TAG;

const fetchFEBuildTag = () => {
  // Add timestamp to URL, to overcome "read from disk cache" issue
  const url = `${URL_INDEX}?t=${Date.now()}`;

  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error requesting FE build metadata file');
      }

      return response.json();
    })
    .then((data) => data.TAG);
};

export function checkRemoteTag(onResult) {
  fetchFEBuildTag()
    .then((remoteTag) => {
      onResult(remoteTag === BUILD_TAG);
    })
    .catch((error) => {
      log.warning(`Failed to check FE relevance: ${error?.message}`);
    });
}
