import { ONBOARDING_STEP } from 'src/data/onboarding';
import { SPOTLIGHT_TYPE } from '../components/Spotlight';
import { AssetActionsOnboardingHint } from '../components/AssetActionsOnboardingHint';
import { buildTarget } from './utils';

export const STEPS_WTM_DATA = [
  {
    id: ONBOARDING_STEP.wtmAssetActions,
    target: buildTarget(['[data-onboarding="assetGenerateCopyCTA"]']),
    contentClass: AssetActionsOnboardingHint,
    content: {
      dontShowAgain: true,
    },
    options: {
      placement: 'top-start',
      showOverlay: true,
      spotlights: [
        {
          type: SPOTLIGHT_TYPE.button,
          padding: 0,
        },
        {
          target: buildTarget(['[data-onboarding="assetAddYourOwnCTA"]']),
          type: SPOTLIGHT_TYPE.button,
          padding: 0,
        },
      ],
    },
  },
];
