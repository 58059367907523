import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      minWidth: theme.narrowestContainerWidth,

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
  { name: 'OAuthCallback' }
);
