import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { AnnotationListItem } from '../../../AnnotationListItem';
import { Loader } from './components/Loader';
import { useStyles } from './useStyles';

export const AnnotationsGroup = ({ Icon, title, annotations, loading }) => {
  const styles = useStyles();

  if (loading) {
    return (
      <Loader
        classes={{
          root: styles.root,
          contentWrapper: styles.contentWrapper,
          annotations: styles.annotations,
        }}
      />
    );
  }

  if (!annotations || annotations.length === 0) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Icon className={styles.icon} />

      <div className={styles.contentWrapper}>
        <Typography className={styles.title}>{title}</Typography>

        <div className={styles.annotations}>
          {annotations.map((annotation) => (
            <AnnotationListItem
              classes={{ text: styles.annotationText }}
              key={annotation.id}
              annotation={annotation}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

AnnotationsGroup.propTypes = {
  Icon: PropTypes.elementType,
  title: PropTypes.string,
  loading: PropTypes.bool,
  annotations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      score: PropTypes.number,
    })
  ),
};

AnnotationsGroup.defaultProps = {
  annotations: [],
  Icon: null,
  title: null,
  loading: false,
};
