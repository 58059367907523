import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconTalkingPoints = memo(
  forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
      <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
      <path
        d="M15.5273 9H7.00162"
        stroke="white"
        strokeWidth="1.77692"
        strokeLinecap="round"
      />
      <path
        d="M24 13L7 13"
        stroke="white"
        strokeWidth="1.77692"
        strokeLinecap="round"
      />
      <path
        d="M24 21H7"
        stroke="white"
        strokeWidth="1.77692"
        strokeLinecap="round"
      />
      <path
        d="M24 17H7"
        stroke="white"
        strokeWidth="1.77692"
        strokeLinecap="round"
      />
    </SvgIcon>
  ))
);
