import { syncAuthTokenToExternalExtension } from 'src/data/infinity';
import { isApplicationModeInfinity } from 'src/data/system';
import * as storage from 'src/lib/storage';
import { getQueryParamValue } from 'src/lib/browser';

const TOKEN_FIELD = 'postMaloneAccessToken';

const QUERY_PARAMS = {
  authToken: 'auth_token',
};

let localAuthToken = null;

const Authentication = {
  /**
   * Save TOKEN in browser storage, or in memory if we are in Infinity and don't want to share "auth" state with other tabs.
   *
   * @param {string} token - authentication token
   */
  signIn(token) {
    if (isApplicationModeInfinity()) {
      localAuthToken = token;
    } else {
      storage.writeItem(TOKEN_FIELD, token);
      this.notifyExtension();
    }
  },

  /**
   * Remove TOKEN from browser storage, or from memory if we were logged in Infinity.
   */
  signOut() {
    if (isApplicationModeInfinity()) {
      localAuthToken = null;
    } else {
      storage.removeItem(TOKEN_FIELD);
      this.notifyExtension();
    }
  },

  getQueryToken() {
    return getQueryParamValue(QUERY_PARAMS.authToken);
  },

  isAuthenticated() {
    const token = this.getToken();
    return Boolean(token);
  },

  getToken() {
    // Token from memory is the only token that should be used in Infinity
    if (isApplicationModeInfinity()) {
      return localAuthToken;
    }

    // Token from query params has the second highest priority
    const queryToken = this.getQueryToken();
    if (queryToken) {
      return queryToken;
    }

    // Token from storage has the lowest priority
    return storage.readItem(TOKEN_FIELD);
  },

  /**
   * Notify extension about authentication status change
   * Do not react to errors
   */
  notifyExtension() {
    // "embedded" Platform should not send out "external" messages
    if (isApplicationModeInfinity()) {
      return;
    }

    if (this.isAuthenticated()) {
      const token = this.getToken();
      syncAuthTokenToExternalExtension(token).catch(() => {
        /* do nothing */
      });
    } else {
      syncAuthTokenToExternalExtension(null).catch(() => {
        /* do nothing */
      });
    }
  },

  subscribeToTokenChanges(listener) {
    if (!listener) {
      return null;
    }

    const handler = (event) => {
      // HINT: this fixes issue in Safari - we don't react to event, when it was fired for the same tab which changed value
      if (document.hasFocus()) {
        return;
      }

      if (event.key === TOKEN_FIELD) {
        listener(event.oldValue, event.newValue);
      }
    };
    window.addEventListener('storage', handler);
    return () => window.removeEventListener('storage', handler);
  },
};

export default Authentication;
