import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& + &': {
        '&$vertical': {
          marginTop: theme.spacing(1),
        },

        '&$displaySeparator': {
          '&:before': {
            content: '""',
            borderLeft: `1px solid ${COLORS.neutral.mediumGray}`,
            margin: theme.spacing(0, 1),
          },
        },
      },
    },
    vertical: {},
    displaySeparator: {},

    title: {
      marginRight: theme.spacing(0.5),
      fontWeight: theme.typography.fontWeightMedium,
    },
    grayish: {
      color: COLORS.neutral.secondary,
    },
    body: {},
  }),
  { name: 'BaseItem' }
);
