import { useCallback, useEffect } from 'react';

import { useCurrentWorkspaces, useWorkspacesFetched } from 'src/store';
import { usePopups } from '../services';
import { NO_WORKSPACES_POPUP } from '../types';

export const useNoWorkspacesPopup = () => {
  const popups = usePopups();
  const currentWorkspaces = useCurrentWorkspaces();
  const workspacesFetched = useWorkspacesFetched();

  const openPopup = useCallback(() => {
    popups.openPopup(NO_WORKSPACES_POPUP, {
      onClose: () => {
        popups.closePopup(NO_WORKSPACES_POPUP);
      },
    });
  }, [popups]);

  useEffect(() => {
    if (workspacesFetched && currentWorkspaces.length === 0) {
      openPopup();
    }
  }, [workspacesFetched, currentWorkspaces, openPopup]);
};
