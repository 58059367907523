import React from 'react';

export const usePopoverAnchor = (startingAnchor = null) => {
  const [anchor, setAnchor] = React.useState(startingAnchor);

  function open(a) {
    setAnchor(a);
  }

  function close() {
    setAnchor(null);
  }

  return [anchor, open, close];
};
