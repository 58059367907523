import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  () => ({
    root: {
      display: 'inline-block',
      width: '1em',
      height: '1em',
      backgroundImage: ({ image }) => `url(${image})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  }),
  { name: 'ImageIcon' }
);
