import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { ItemDefault } from './components/ItemDefault';
import { ItemDivider } from './components/ItemDivider';
import { ItemSubheader } from './components/ItemSubheader';
import { ItemWithSubmenu } from './components/ItemWithSubmenu';
import { ItemPlaceholder } from './components/ItemPlaceholder';
import { ItemLocked } from './components/ItemLocked';
import { ItemLoading } from './components/ItemLoading';
import { ItemCustom } from './components/ItemCustom';
import { CUSTOM_MENU_ITEM_TYPE } from './const';

const getItemClass = (type) => {
  switch (type) {
    case CUSTOM_MENU_ITEM_TYPE.divider:
      return ItemDivider;

    case CUSTOM_MENU_ITEM_TYPE.subheader:
      return ItemSubheader;

    case CUSTOM_MENU_ITEM_TYPE.submenu:
      return ItemWithSubmenu;

    case CUSTOM_MENU_ITEM_TYPE.placeholder:
      return ItemPlaceholder;

    case CUSTOM_MENU_ITEM_TYPE.locked:
      return ItemLocked;

    case CUSTOM_MENU_ITEM_TYPE.loading:
      return ItemLoading;

    case CUSTOM_MENU_ITEM_TYPE.custom:
      return ItemCustom;

    default:
      return ItemDefault;
  }
};

export const CustomMenuItem = forwardRef(({ item, ...restProps }, ref) => {
  const ItemClass = getItemClass(item.type);

  return (
    <ItemClass
      item={item}
      ref={ref}
      {...restProps}
    />
  );
});

CustomMenuItem.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(CUSTOM_MENU_ITEM_TYPE)),
  }).isRequired,
};
