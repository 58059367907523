import { useCallback } from 'react';

import { BUSINESS_BOOK_DEMO_POPUP } from '../types';
import { usePopups } from '../services';

export const useBusinessBookDemoPopup = () => {
  const popups = usePopups();

  return useCallback(
    (onSubmit) => {
      popups.openPopup(BUSINESS_BOOK_DEMO_POPUP, {
        onClose: () => {
          popups.closePopup(BUSINESS_BOOK_DEMO_POPUP);
        },
        onSubmit: (email) => {
          popups.closePopup(BUSINESS_BOOK_DEMO_POPUP);
          onSubmit(email);
        },
      });
    },
    [popups]
  );
};
