import React, { forwardRef, memo, useMemo } from 'react';
import { SvgIcon } from '@material-ui/core';
import { generateSvgId } from '../../../lib/svg';

export const IconGoogle = memo(
  forwardRef((props, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M3.56411 19.9179L11.7811 6C12.8249 6.60797 18.0881 9.48977 18.9381 10.0379L10.7211 23.9565C9.82248 25.1311 2.42561 21.7003 3.56411 19.9171V19.9179V19.9179Z"
            fill="#FBBC04"
          />
          <path
            d="M27.1772 19.9167L18.9602 5.9997C17.8115 4.10827 15.3374 3.42092 13.3053 4.53804C11.2732 5.65516 10.6545 8.06158 11.8032 10.0375L20.0202 23.9562C21.169 25.8468 23.6431 26.534 25.6752 25.417C27.6185 24.2998 28.326 21.808 27.1772 19.9183V19.9167V19.9167Z"
            fill="#3D7FFE"
          />
          <path
            d="M7.12474 25.9541C9.40277 25.9541 11.2495 24.1724 11.2495 21.9746C11.2495 19.7768 9.40277 17.9951 7.12474 17.9951C4.84671 17.9951 3 19.7768 3 21.9746C3 24.1724 4.84671 25.9541 7.12474 25.9541Z"
            fill="#34A853"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="24.75"
              height="22"
              fill="white"
              transform="translate(3 4)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
