import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    button: {},
    menu_paper: {
      border: 0,
      width: 248,
      marginBottom: theme.spacing(0.5),
    },
  }),
  { name: 'WorkspaceItem' }
);
