import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      zIndex: theme.zIndex.modal,
    },
    inputForm: {
      width: 283,
      height: 40,

      '& fieldset': {
        borderColor: COLORS.primary.light,
      },
    },
    input: {
      paddingRight: 0,
      boxShadow: '0px 1px 27px rgba(31, 44, 75, 0.1)',
      borderRadius: 6,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(19),
      color: COLORS.neutral.secondary,
    },
  }),
  { name: 'LinkInsertPopup' }
);
