import React, { useCallback, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { ClickAwayListener, Tooltip, Typography } from '@material-ui/core';

import IconDelete from 'src/assets/icons/Delete';
import { IconUser } from 'src/assets/icons/User';
import { IconTimes } from 'src/assets/icons/Times';
import { PersonaAvatar } from 'src/components/PersonaAvatar';
import { ContextMenu } from 'src/components/ContextMenu';
import { PersonaDetailsPanel } from 'src/components/PersonaDetailsPanel';
import { useMenuOpenedState } from 'src/lib/hooks';
import { useStyles } from './useStyles';

const MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  getContentAnchorEl: null,
};

export const LabelPersona = ({
  className,
  persona,
  brandVoices,
  align,
  onSave,
  onReplace,
  onDelete,
  onEdit,
}) => {
  const styles = useStyles({ align });

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleSavePersona = useCallback(
    (brandVoice) => {
      onSave?.(brandVoice, persona);
      setOpenTooltip(false);
    },
    [onSave, persona]
  );

  const handleEditPersona = useCallback(() => {
    onEdit?.(persona);
    setOpenTooltip(false);
  }, [onEdit, persona]);

  const handleDeletePersona = useCallback(() => {
    onDelete?.(persona);
    setOpenTooltip(false);
  }, [onDelete, persona]);

  const handleReplacePersona = useCallback(() => {
    onReplace?.(persona);
    setOpenTooltip(false);
  }, [onReplace, persona]);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const contextMenuItems = [
    {
      id: 'show',
      label: 'Show audience',
      onClick: () => handleTooltipOpen(),
      icon: <IconUser className={styles.icon} />,
    },

    {
      id: 'remove',
      label: 'Delete',
      onClick: handleReplacePersona,
      icon: <IconTimes className={styles.icon} />,
    },
  ];
  if (!persona.isSuggested) {
    contextMenuItems.push({
      id: 'delete',
      label: 'Permanently delete from saved audiences',
      onClick: handleDeletePersona,
      icon: <IconDelete className={styles.icon} />,
    });
  }

  const { menuOpened, handleMenuOpen, handleMenuClose } = useMenuOpenedState();

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={cx(styles.root, className)}>
        <Tooltip
          classes={{ tooltip: styles.tooltip, popper: styles.tooltipPopper }}
          open={openTooltip}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          title={
            <PersonaDetailsPanel
              persona={persona}
              onSave={handleSavePersona}
              onDelete={handleDeletePersona}
              onEdit={handleEditPersona}
              brandVoices={brandVoices}
            />
          }
          interactive
        >
          <div className={cx(styles.content, { [styles.open]: menuOpened || openTooltip })}>
            <PersonaAvatar
              className={styles.avatar}
              avatarType={persona?.avatarType}
            />

            <div className={styles.texts}>
              <Typography className={styles.textName}>{persona?.name}</Typography>

              {persona?.isSuggested && (
                <Typography className={styles.textSuggested}>Suggested</Typography>
              )}
            </div>
            <div className={styles.actions}>
              <ContextMenu
                className={styles.menu}
                classes={{ menuRoot: styles.menuPaper }}
                onOpen={() => {
                  handleMenuOpen();
                  setOpenTooltip(false);
                }}
                onClose={() => {
                  handleMenuClose();
                  setOpenTooltip(false);
                }}
                items={contextMenuItems}
                menuProps={MENU_PROPS}
              />
            </div>
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

LabelPersona.displayName = 'LabelPersona';

LabelPersona.propTypes = {
  className: PropTypes.string,
  persona: PropTypes.shape({
    avatarType: PropTypes.string,
    name: PropTypes.string,
    isSuggested: PropTypes.bool,
  }).isRequired,
  align: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onReplace: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  disabled: PropTypes.bool,
  brandVoices: PropTypes.arrayOf(PropTypes.shape({})),
};

LabelPersona.defaultProps = {
  className: null,
  onSave: null,
  onReplace: null,
  onDelete: null,
  onEdit: null,
  disabled: false,
  brandVoices: [],
};
