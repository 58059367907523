import React, { forwardRef } from 'react';
import { SvgIcon } from '@material-ui/core';

import { useSvgId } from 'src/lib/hooks';

export const LanternIcon = forwardRef((props, ref) => {
  const svgId = useSvgId();

  return (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 32 32"
      data-fill-none
    >
      <g clipPath={`url(#${svgId})`}>
        <path
          d="M26 10.6667L28.6667 8"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 21.334L28.6667 24.0007"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.6665 16H31.3332"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.00016 10.6667L3.3335 8"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.00016 21.334L3.3335 24.0007"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33317 16H0.666504"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5813 24.3594C22.5604 24.418 22.5288 24.4723 22.488 24.5194L16.488 31.1194C16.4248 31.1868 16.3484 31.2406 16.2634 31.2772C16.1786 31.314 16.087 31.3328 15.9946 31.3327V26.3194L22.5813 24.3594Z"
          fill="#41C493"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.6667 6.13318V24.0665C22.6653 24.1694 22.6377 24.2704 22.5867 24.3598L16 26.3198V7.81318L22.6 5.83984C22.6424 5.9319 22.6652 6.03182 22.6667 6.13318Z"
          fill="#41C493"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.5947 5.83935L16 7.81268V0.666016C16.1501 0.667912 16.2956 0.719484 16.4133 0.812682L22.4133 5.61268C22.4883 5.67546 22.5499 5.75251 22.5947 5.83935Z"
          fill="#41C493"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.4082 24.3594C9.42908 24.418 9.46074 24.4723 9.50154 24.5194L15.5015 31.1194C15.5647 31.1868 15.6411 31.2406 15.7261 31.2772C15.8109 31.314 15.9025 31.3328 15.9949 31.3327V26.3194L9.4082 24.3594Z"
          fill="#C5EFDF"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.3335 6.13318V24.0665C9.3349 24.1694 9.36242 24.2704 9.4135 24.3598L16.0002 26.3198V7.81318L9.39483 5.83984C9.35423 5.9323 9.33334 6.03219 9.3335 6.13318Z"
          fill="#C5EFDF"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.39453 5.83935L15.9999 7.81268V0.666016C15.8497 0.667912 15.7043 0.719484 15.5865 0.812682L9.58653 5.61268C9.50778 5.6744 9.44246 5.75154 9.39453 5.83935Z"
          fill="#C5EFDF"
          stroke="#111827"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id={svgId}>
          <rect
            width="32"
            height="32"
            fill="white"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
});
