import { useMultiPromptTemplateActions } from 'src/store';
import { GENERATION_TOOL } from 'src/data/generationTool';

export const usePrepareMultiPromptTemplates = (multiPromptTemplates) => {
  const multiPromptTemplateActions = useMultiPromptTemplateActions();

  return multiPromptTemplates.map((item) => ({
    ...item,
    title: item.title || item.name,
    tool: item.tool || GENERATION_TOOL.customMultiPrompt,
    customData: item.customData || GENERATION_TOOL.customMultiPrompt,
    subTools: item.subTools || multiPromptTemplateActions.getMultiPromptTemplateSubTools(item.id),
    multiPromptTag:
      item.multiPromptTag ||
      Object.keys(multiPromptTemplateActions.getMultiPromptTemplateBlocks(item.id))?.length > 1,
  }));
};
