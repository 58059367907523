import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { WORKSPACE_ASSOCIATIONS_FRAGMENT, WORKSPACE_FRAGMENT } from './schema';

const QUERY = gql`
  query Workspace($customerId: ID!, $id: ID) {
    workspaces(customerId: $customerId, id: $id) {
      edges {
        node {
          ...Workspace
          ...WorkspaceAssociations
        }
      }
    }
  }
  ${WORKSPACE_FRAGMENT}
  ${WORKSPACE_ASSOCIATIONS_FRAGMENT}
`;

export const queryWorkspace = ({ customerId, id }, options = null) => {
  return executeGqlQuery({
    query: QUERY,
    variables: { customerId, id },
    options: {
      abortSignal: options?.abortSignal,
    },
  }).then(({ data }) => getSingleProp(data));
};
