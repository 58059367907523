import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    replacementTerms: {
      marginRight: theme.spacing(2),
    },
    term: {
      marginRight: theme.spacing(5),
    },
  }),
  { name: 'DetectedBrandTermTooltipContent' }
);
