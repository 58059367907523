import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      alignItems: 'center',
      minWidth: theme.narrowestContainerWidth,
    },
    centerWrapper: {
      flex: '1',

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    panel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '320px',
      padding: '40px 20px',

      [theme.breakpoints.up('sm')]: {
        width: '480px',
        padding: '40px',
      },
    },
    logo: {
      color: COLORS.neutral.white,
      height: '38px',
      width: 'auto',
      marginBottom: '20px',
    },
    title: {
      marginTop: theme.spacing(4),
      fontSize: theme.typography.pxToRem(20),
    },
    loader: {
      marginTop: theme.spacing(6),
      color: theme.palette.primary.main,
    },
  }),
  { name: 'VerifyEmailCallback' }
);
