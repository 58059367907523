import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation MarketoTrackEvent(
    $eventName: MarketoEventsEnum!
    $primaryValue: String!
    $attributes: JSON
  ) {
    marketoTrackEvent(eventName: $eventName, primaryValue: $primaryValue, attributes: $attributes) {
      id
    }
  }
`;

export function trackMarketoEvent(eventName, primaryValue, attributes) {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: {
      eventName,
      primaryValue,
      attributes: JSON.stringify(attributes),
    },
  }).then(({ data }) => getSingleProp(data));
}
