import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const RadioDefault = React.forwardRef(({ strokeColor, ...restProps }, ref) => (
  <SvgIcon {...restProps} ref={ref} viewBox="0 0 26 26">
    <rect
      x="1"
      y="1"
      width="24"
      height="24"
      rx="12"
      stroke={strokeColor || '#D2D6E0'}
      fill="transparent"
    />
  </SvgIcon>
));

RadioDefault.propTypes = {
  strokeColor: PropTypes.string,
};
RadioDefault.defaultProps = {
  strokeColor: null,
};

export default React.memo(RadioDefault);
