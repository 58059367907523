import { makeStyles } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    wrapper: {},
    root: {
      justifyContent: 'flex-start',
      width: '100%',
      height: 38,
      padding: theme.spacing(0.5, 0.25, 0.5, 1),
      borderRadius: 4,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      color: COLORS.neutral.silver,

      '&$isSubmenu:not($menuOpenEndIcon)': {
        '& $endIcon': {
          display: 'none',
        },
      },

      '&:hover:not($isEmptySubmenuItem)': {
        backgroundColor: `${COLORS.neutral.darkGray}DD`,
        '&$isSubmenu': {
          '& $endIcon': {
            display: 'block !important',
          },
        },
      },

      '&$active': {
        backgroundColor: COLORS.neutral.darkGray,
        fontWeight: theme.typography.fontWeightMedium,

        '& $iconStart': {
          color: COLORS.neutral.lightGray,
        },
      },

      '&$outlined': {
        border: '1px solid #415A8A',
        width: '100%',

        '&:focus': {
          backgroundColor: COLORS.neutral.blackBlue,
        },
      },

      '&$narrow': {
        height: 38,
      },

      '&$$menuOpenEndIcon': {
        backgroundColor: `${COLORS.neutral.mediumGray}DD`,
      },
    },
    menuOpenEndIcon: {},
    active: {},
    outlined: {},
    narrow: {},
    label: {},
    isEmptySubmenuItem: {
      cursor: 'default',
      color: COLORS.neutral.mediumGray,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      height: 32,
    },
    startIcon: {
      color: COLORS.neutral.silver,
      fontSize: theme.typography.pxToRem(20),
      display: 'flex',
      justifyContent: 'center',
      marginRight: theme.spacing(1),
    },
    iconStart: {
      color: COLORS.neutral.mediumGray,

      // HINT: we need to overcome specificity of ".MuiButton-iconSizeMedium > *:first-child"
      '&&': {
        fontSize: theme.typography.pxToRem(20),
      },
    },
    isSubmenu: {},
    endIcon: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
      alignSelf: 'center',
      color: '#697385',
    },
    secondMenu: {
      position: 'fixed',
      top: 0,
      left: 0,
      marginLeft: 272,
    },
    secondMenuOverlay: {
      height: '100vh',
      width: '100%',
      backgroundColor: `${COLORS.gray.gray30}DD`,
      zIndex: 999999,
    },
    secondMenuWrapper: {
      height: '100vh',
      width: 300,
      padding: theme.spacing(2),
      backgroundColor: COLORS.neutral.darkGray,
      display: 'flex',
      flexDirection: 'column',
    },
    submenuCloseButton: {
      color: COLORS.neutral.silver,
      fontSize: theme.typography.pxToRem(16),
      cursor: 'pointer',
      alignSelf: 'flex-end',
    },
    submenuItemsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      overflowY: 'auto',
      marginTop: theme.spacing(2),
    },
    submenuItemButton: {
      width: '100%',
      borderRadius: 4,
      color: COLORS.neutral.silver,
      justifyContent: 'flex-start',
      padding: theme.spacing(0.5, 2),
      '&:hover': {
        backgroundColor: COLORS.neutral.mediumGray,
      },
    },
    submenuItemIconStart: {},
  }),
  {
    name: 'SidebarButton',
  }
);
