import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: theme.shape.borderRadius,
      maxHeight: 281,
    },
    title: {
      color: COLORS.gray.gray30,
      backgroundColor: COLORS.gray.gray90,
      padding: theme.spacing(2, 1.5),
      borderBottom: theme.shape.borderGray70,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
    },
    toggle: {
      width: 84,
      textAlign: 'center',
    },
    alreadyConnected: {
      textAlign: 'center',
      color: COLORS.gray.gray30,
    },
    emptyWrapper: {
      padding: theme.spacing(1.5, 2),
      backgroundColor: COLORS.warning.light,
    },
    noAccountsCell: {
      backgroundColor: COLORS.warning.light,
    },
    textEmpty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: theme.spacing(0.5),
      color: COLORS.gray.black,
      '& svg': {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  }),
  { name: 'AccountsTable' }
);
