import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

import {
  queryTalkingPointsViewList,
  extractTalkingPointsView,
  deleteTalkingPointsView,
} from 'src/graphql/talkingPointsViews';
import { captureMessage } from 'src/lib/sentry';
import { alignChannelWithBackend } from 'src/data/channel';
import { waitForTalkingPointViewCreation } from './utils';

const INITIAL_STATE = {
  talkingPointsViews: [],
  isLoadingTalkingPointsViews: false,
  isExtractingTalkingPointsView: false,
  loadedTalkingPointsViews: false,

  customerTalkingPointsViews: [],
  isLoadingCustomerTalkingPointsViews: false,
};

/**
 * Private actions
 */
const PRIVATE_ACTIONS = {
  mergeItemInCache:
    (savedView) =>
    ({ getState, setState }) => {
      const { talkingPointsViews: list } = getState();
      const toolExists = list.some((t) => t.id === savedView.id);
      const newList = toolExists
        ? list.map((t) => (t.id === savedView.id ? savedView : t))
        : [...list, savedView];

      setState({ talkingPointsViews: newList });
    },

  removeItemFromCache:
    (toolId) =>
    ({ getState, setState }) => {
      const { talkingPointsViews } = getState();
      const filteredList = talkingPointsViews.filter((t) => t.id !== toolId);
      setState({ talkingPointsViews: filteredList });
    },
};

/**
 * Public actions
 */
export const ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  queryWorkspaceTalkingPointsViewList:
    (customerId, workspaceId) =>
    async ({ setState }) => {
      setState({ isLoadingTalkingPointsViews: true });
      try {
        const result = await queryTalkingPointsViewList({
          customerId,
          workspaceId,
        });
        setState({
          talkingPointsViews: result || [],
          loadedTalkingPointsViews: true,
        });

        return result;
      } catch (error) {
        captureMessage('Error while getting talking points', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'TalkingPointsViewsStore -> queryWorkspaceTalkingPointsViewList',
          },
        });
      } finally {
        setState({ isLoadingTalkingPointsViews: false });
      }
    },

  queryCustomerTalkingPointsViewList:
    () =>
    async ({ setState }, { currentCustomerId }) => {
      setState({ isLoadingCustomerTalkingPointsViews: true });
      try {
        const result = await queryTalkingPointsViewList({
          customerId: currentCustomerId,
        });
        setState({
          customerTalkingPointsViews: result,
        });
      } catch (error) {
        captureMessage('Error while getting customer talking points', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'TalkingPointsViewsStore -> queryCustomerTalkingPointsViewList',
          },
        });
      } finally {
        setState({ isLoadingCustomerTalkingPointsViews: false });
      }
    },

  getTalkingPointsViews:
    () =>
    ({ getState }) => {
      return getState().talkingPointsViews;
    },

  waitForTalkingPointViewCreation:
    (id, options) =>
    ({ dispatch }, { currentCustomerId, currentWorkspaceId }) => {
      return waitForTalkingPointViewCreation({
        id,
        customerId: currentCustomerId,
        workspaceId: currentWorkspaceId,
        options,
        onResult: (talkingPointsView) => {
          dispatch(PRIVATE_ACTIONS.mergeItemInCache(talkingPointsView));
        },
      });
    },

  refreshWorkspaceTalkingPointsViewList:
    (workspaceId) =>
    async ({ setState }, { currentCustomerId }) => {
      try {
        const response = await queryTalkingPointsViewList({
          customerId: currentCustomerId,
          workspaceId,
        });

        setState({ talkingPointsViews: response || [] });
      } catch (error) {
        captureMessage('Error while getting talking points', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'TalkingPointsViewsStore -> refreshWorkspaceTalkingPointsViewList',
          },
        });
      }
    },

  extractTalkingPointsView:
    (resourceId, channel) =>
    async ({ setState, dispatch }, { currentCustomerId, currentWorkspaceId, notifications }) => {
      const dataSource = alignChannelWithBackend(channel);

      setState({ isExtractingTalkingPointsView: true });
      try {
        // Create talking points view (async process)
        const createdTalkingPointsView = await extractTalkingPointsView({
          input: {
            resourceId,
            dataSource,
          },
          customerId: currentCustomerId,
          workspaceId: currentWorkspaceId,
        });
        dispatch(PRIVATE_ACTIONS.mergeItemInCache(createdTalkingPointsView));

        // Wait for talking points view to be created
        const extractedTalkingPointsView = await waitForTalkingPointViewCreation({
          id: createdTalkingPointsView.id,
          customerId: currentCustomerId,
          workspaceId: currentWorkspaceId,
          onResult: (talkingPointsView) => {
            dispatch(PRIVATE_ACTIONS.mergeItemInCache(talkingPointsView));
          },
        });
        notifications.displaySuccess('New talking points view created.');

        return extractedTalkingPointsView;
      } finally {
        setState({ isExtractingTalkingPointsView: false });
      }
    },

  deleteTalkingPointsView:
    (id) =>
    async ({ dispatch }, { notifications }) => {
      await deleteTalkingPointsView(id);
      dispatch(PRIVATE_ACTIONS.removeItemFromCache(id));
      notifications.displaySuccess('Talking points view removed');
    },
};

export const TalkingPointsViewsStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'talking-points-views-store',
});
