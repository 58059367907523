import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    border: {
      border: theme.shape.borderGray80,
      padding: theme.spacing(1.5),
    },
    scoreWrapper: { display: 'flex', justifyContent: 'start', alignItems: 'center' },
    scoreView: { height: 87, width: 87 },
    score: {
      fontSize: theme.typography.pxToRem(24),
      height: 87,
      width: 87,
      marginTop: theme.spacing(3),
    },
    scoreDetails: {
      color: COLORS.gray.gray30,
      marginLeft: theme.spacing(2),
      lineHeight: theme.typography.pxToRem(22),
    },

    structureWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      margin: theme.spacing(1, 0),
    },

    structureRow: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
    },

    structureChip: {
      border: theme.shape.borderGray80,
      borderRadius: theme.shape.borderRadiusSmall,
      padding: theme.spacing(1.5),
      color: COLORS.gray.gray40,
      width: 146,
    },

    structureChipDetails: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: theme.spacing(0.5),
      alignItems: 'center',
    },

    structureChipBody: {
      color: COLORS.gray.black,
      display: 'flex',
      alignItems: 'center',
    },

    structureIconCheck: {
      color: COLORS.success.active,
      marginLeft: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(14),
    },

    structureIconArrowUp: {
      color: COLORS.error.default,
      marginLeft: theme.spacing(0.5),
      transform: 'rotate(270deg)',
      fontSize: theme.typography.pxToRem(14),
    },
    structureIconArrowDown: {
      color: COLORS.error.default,
      marginLeft: theme.spacing(0.5),
      transform: 'rotate(90deg)',
      fontSize: theme.typography.pxToRem(14),
    },

    keywordChipWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
    },

    keywordRange: {
      borderRadius: theme.shape.borderRadiusSmall,
      padding: theme.spacing(0.5, 0.5),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightBold,
    },

    keywordRangeGray: { backgroundColor: COLORS.gray.gray80, color: COLORS.gray.gray30 },
    keywordRangeGreen: { backgroundColor: '#DFF7E8', color: COLORS.success.active },
    keywordRangeOrange: { backgroundColor: COLORS.warning.light, color: COLORS.warning.active },
    keywordRangeRed: { backgroundColor: COLORS.error.extraLight, color: COLORS.error.active },

    keywordArrowUp: {
      color: COLORS.warning.default,
      marginLeft: theme.spacing(0.5),
      transform: 'rotate(270deg)',
      fontSize: theme.typography.pxToRem(16),
    },
    keywordArrowDown: {
      color: COLORS.warning.default,
      marginLeft: theme.spacing(0.5),
      transform: 'rotate(90deg)',
      fontSize: theme.typography.pxToRem(16),
    },
    competitorKeywords: { marginTop: theme.spacing(0.5) },
    noKeywords: { marginTop: theme.spacing(1), color: COLORS.gray.gray50 },
    singleChipSkeleton: {
      display: 'flex',
      gap: theme.spacing(0.5),
    },

    scoreSkeleton: {
      width: 87,
      height: 87,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    skeletonInner: { width: 67, height: 67, backgroundColor: COLORS.neutral.white },

    informationIcon: { fontSize: theme.typography.pxToRem(12) },

    flexLabel: { display: 'flex', gap: theme.spacing(0.5) },

    text: { height: 22 },

    hint: {
      fontSize: theme.typography.pxToRem(12),
      color: COLORS.gray.gray40,
    },
  }),
  { name: 'SEOScore' }
);
