import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom-v5-compat';
import { Button, Typography, Tooltip } from '@material-ui/core';

import { BLOCKED_REASON, getBlockedReasonCopy } from 'src/data/common';
import { ColoredDiamondIcon } from 'src/assets/icons/ColoredDiamond';

import { useStyles } from './useStyles';
import { ROUTES } from 'src/routes';
import { formatRoute } from 'src/lib/routing';

export const BlockedMessage = ({
  className,
  blockedReason,
  onUpgradeClick,
  onUndoClick,
  undoDisabled,
}) => {
  const styles = useStyles();

  const isLimited = blockedReason === BLOCKED_REASON.limitedScoring;

  return (
    <div
      className={cx(
        styles.root,
        {
          'border-0': blockedReason === BLOCKED_REASON.emptyText,
        },
        className
      )}
    >
      {isLimited && (
        <div className={styles.headerWrapper}>
          <ColoredDiamondIcon className={styles.blockedScoreIcon} />
          <span className={styles.headerText}>Upgrade to get real-time score</span>
        </div>
      )}

      <Typography className={styles.blockedReason}>
        {getBlockedReasonCopy(blockedReason)}
      </Typography>

      {isLimited && (
        <Button
          className={styles.actionButton}
          component={Link}
          to={formatRoute(ROUTES.UPGRADE)}
          onClick={onUpgradeClick}
          variant="contained"
          color="secondary"
          data-testid={`blockedMessagePopup_${blockedReason}_upgradeButton`}
        >
          Upgrade plan
        </Button>
      )}

      {onUndoClick && (
        <Tooltip title="Revert back to original copy with score">
          <Button
            className={styles.actionButton}
            component={Link}
            onClick={onUndoClick}
            disabled={undoDisabled}
            variant="text"
            color="primary"
            data-testid={`blockedMessagePopup_${blockedReason}_undoButton`}
          >
            Undo
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

BlockedMessage.propTypes = {
  className: PropTypes.string,
  blockedReason: PropTypes.oneOf(Object.values(BLOCKED_REASON)),
  onUpgradeClick: PropTypes.func,
  onUndoClick: PropTypes.func,
  undoDisabled: PropTypes.bool,
};

BlockedMessage.defaultProps = {
  className: null,
  blockedReason: null,
  onUpgradeClick: null,
  onUndoClick: null,
  undoDisabled: false,
};
