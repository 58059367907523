import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_JOB } from './schema';

const QUERY = gql`
  query JobStatus($id: ID!) {
    jobStatus(id: $id) {
      ...Job
    }
  }
  ${FRAGMENT_JOB}
`;

export const queryJobStatus = (id) => {
  return executeGqlQuery({
    query: QUERY,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
};
