/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import { Backdrop as MuiBackdrop } from '@material-ui/core';

import { useResetPageScrollOnMount } from 'src/lib/hooks';
import Spotlight from '../Spotlight';
import useInterceptTab from './useInterceptTab';
import useStyles from './useStyles';

const propTypes = {
  className: PropTypes.string,
  rootElement: PropTypes.instanceOf(Element),
  step: PropTypes.shape({
    options: PropTypes.shape(),
  }).isRequired,
};

const defaultProps = {
  className: null,
  rootElement: null,
};

const Backdrop = ({ className, rootElement, step }) => {
  const styles = useStyles();

  // We block vertical scrolling of 'body' with CSS, so we should reset vertical scroll
  useResetPageScrollOnMount();

  // Intercept changing focusing by pressing 'tab' key
  useInterceptTab();

  // Step options
  const stepTarget = get(step, 'target');
  const options = (step && step.options) || {};
  const spotlights = get(options, 'spotlights', []);

  return (
    <MuiBackdrop
      className={cx(styles.root, className)}
      open
    >
      {rootElement &&
        spotlights.map((spotlight, index) => (
          <Spotlight
            className={styles.spotlight}
            key={index}
            rootElement={rootElement}
            target={spotlight.target || stepTarget}
            options={spotlight}
          />
        ))}
    </MuiBackdrop>
  );
};

Backdrop.propTypes = propTypes;
Backdrop.defaultProps = defaultProps;

export default Backdrop;
