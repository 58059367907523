import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconHeadingThree = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.42859 0.25C1.8428 0.25 2.17859 0.585786 2.17859 1V4.96428H5.82145V1C5.82145 0.585786 6.15723 0.25 6.57145 0.25C6.98566 0.25 7.32145 0.585786 7.32145 1V11.2857C7.32145 11.6999 6.98566 12.0357 6.57145 12.0357C6.15723 12.0357 5.82145 11.6999 5.82145 11.2857V6.46429H2.17859V11.2857C2.17859 11.6999 1.8428 12.0357 1.42859 12.0357C1.01438 12.0357 0.678589 11.6999 0.678589 11.2857V1C0.678589 0.585786 1.01438 0.25 1.42859 0.25Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.02366 1.44701C9.43025 0.770445 10.133 0.25 11 0.25C12.4822 0.25 13.4167 1.68333 13.4167 3.08332V3.41669C13.4167 4.17612 13.1507 4.94238 12.678 5.49881C13.3561 6.12141 13.75 7.10052 13.75 8.08337V8.5C13.75 10.1301 12.6663 11.75 11 11.75C9.7404 11.75 8.6289 10.7772 8.27831 9.53747C8.16559 9.13889 8.39733 8.7244 8.79591 8.61168C9.19449 8.49896 9.60899 8.7307 9.7217 9.12928C9.92031 9.83157 10.518 10.25 11 10.25C11.5429 10.25 12.25 9.63136 12.25 8.5V8.08337C12.25 6.95201 11.5429 6.33337 11 6.33337H10.9333C10.5191 6.33337 10.1833 5.99759 10.1833 5.58337C10.1833 5.16916 10.5191 4.83337 10.9333 4.83337C11.3289 4.83337 11.9167 4.36384 11.9167 3.41669V3.08332C11.9167 2.18214 11.3588 1.75 11 1.75C10.7765 1.75 10.511 1.88418 10.3094 2.21966C10.096 2.5747 9.63522 2.68955 9.28018 2.47618C8.92515 2.26282 8.8103 1.80205 9.02366 1.44701Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconHeadingThree.displayName = 'IconHeadingThree';
