import { makeStyles } from '@material-ui/core/styles';

import { DETECTED_BRAND_TERM_TYPE, getDetectedBrandTermTypeColorGroup } from 'src/data/brandTerm';

export const useStyles = makeStyles(
  (theme) => {
    // Prepare color styles for each approval type
    const typeColors = Object.values(DETECTED_BRAND_TERM_TYPE).reduce((acc, type) => {
      const colorGroup = getDetectedBrandTermTypeColorGroup(type);
      if (colorGroup?.active) {
        acc[`&[data-type="${type}"]`] = {
          color: colorGroup?.active,
        };
      }
      return acc;
    }, {});

    return {
      root: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        gap: theme.spacing(1),
        ...typeColors,
      },
      icon: {
        fontSize: theme.typography.pxToRem(8),
      },
    };
  },
  { name: 'TermType' }
);
