import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

import { COLORS, COLOR_DARK_GRAY } from 'src/data/ui';
import Score from 'src/components/Score';
import { BlockedScoreTooltip } from 'src/components/BlockedScoreTooltip';
import { GenerationToolIcon } from 'src/components/GenerationToolIcon';
import { BLOCKED_REASON } from 'src/data/common';
import ScoreLoader from './ScoreLoader';
import { TooltipContent } from './TooltipContent';

const useStyles = makeStyles(
  () => ({
    tooltip: {
      backgroundColor: COLORS.neutral.white,
    },
    darkTooltip: {
      backgroundColor: COLOR_DARK_GRAY,
    },
    limitedTooltipText: {
      color: COLORS.neutral.white,
      '& span': {
        color: COLORS.success.default,
      },
    },
    icon: {
      width: 18,
      height: 18,
      position: 'absolute',
      left: 25,
      top: 17,
      backgroundColor: COLORS.neutral.white,
      borderRadius: '100%',
    },
  }),
  { name: 'ScoreView' }
);

const ScoreView = ({
  className,
  score,
  histogramData,
  contentSensitivity,
  updating,
  onHover,
  forceDisplayHistogram,
  loadingSensitivity,
  blockedReason,
  hideContent,
  generationTool,
  isLoading,
  grayScaleLoader,
  ...props
}) => {
  const styles = useStyles();
  // We need tooltip to be "controlled" in order to display it on demand in onboarding flow
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setTooltipOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  const isLimitedScoring = blockedReason === BLOCKED_REASON.limitedScoring;
  const generationToolIcon = generationTool && (
    <GenerationToolIcon
      className={styles.icon}
      tool={generationTool}
    />
  );

  return (
    <div
      className={className}
      data-testid="ScoreView"
    >
      {updating ? (
        <ScoreLoader />
      ) : (
        <BlockedScoreTooltip
          className={isLimitedScoring ? styles.darkTooltip : styles.tooltip}
          nonBlockedTitle={
            isLimitedScoring ? (
              <Typography className={styles.limitedTooltipText}>
                <span>Upgrade</span> your subscription to unlock
                <br />
                real-time scoring and analytics for every copy edit you make.
              </Typography>
            ) : score && !hideContent ? (
              <TooltipContent
                histogramData={histogramData}
                contentSensitivity={contentSensitivity}
                loadingSensitivity={loadingSensitivity}
              />
            ) : (
              ''
            )
          }
          open={forceDisplayHistogram || tooltipOpen}
          onOpen={handleOpen}
          onClose={handleClose}
        >
          <Score
            locked={!!blockedReason}
            score={score}
            onMouseOver={!blockedReason ? onHover : null}
            onFocus={onHover}
            generationToolIcon={generationToolIcon}
            {...props}
            loading={isLoading}
            grayScaleLoader={grayScaleLoader}
          />
        </BlockedScoreTooltip>
      )}
    </div>
  );
};

ScoreView.propTypes = {
  className: PropTypes.string,
  score: PropTypes.number,
  histogramData: PropTypes.shape(),
  contentSensitivity: PropTypes.shape(),
  updating: PropTypes.bool,
  onHover: PropTypes.func,
  forceDisplayHistogram: PropTypes.bool,
  loadingSensitivity: PropTypes.bool,
  blockedReason: PropTypes.oneOf(Object.values(BLOCKED_REASON)),
  hideContent: PropTypes.bool,
  generationTool: PropTypes.string,
  isLoading: PropTypes.bool,
  grayScaleLoader: PropTypes.bool,
};

ScoreView.defaultProps = {
  className: null,
  score: null,
  updating: false,
  histogramData: null,
  contentSensitivity: null,
  onHover: null,
  forceDisplayHistogram: false,
  loadingSensitivity: false,
  blockedReason: null,
  hideContent: false,
  generationTool: null,
  isLoading: false,
  grayScaleLoader: false,
};

export default ScoreView;
