import { createContainer } from 'react-sweet-state';

import Store from './Store';

const Container = createContainer(Store, {
  onInit: () => ({ setState }, { state }) => {
    if (state) {
      setState(state);
    }
  },
  onUpdate: () => ({ setState }, { state }) => {
    if (state) {
      setState(state);
    }
  },
});

export default Container;
