import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation AuthorizeBraze($apiKey: String!, $accountName: String!, $customerId: ID!) {
    authorizeBraze(apiKey: $apiKey, accountName: $accountName, customerId: $customerId) {
      authState
      adAccounts {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export const authorizeBraze = ({ apiKey, accountName, customerId }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { apiKey, accountName, customerId },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => unwrapEdges(result?.adAccounts?.edges || []));
};
