import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_INTEGRATION_ACCOUNT } from '../performance/schema';

const QUERY = gql`
  query CustomerAccounts($id: ID!) {
    customer(id: $id) {
      id
      accounts {
        ...PerformanceCenterIntegrationAccount
      }
    }
  }
  ${FRAGMENT_INTEGRATION_ACCOUNT}
`;

export const queryCustomerAccounts = (id) => {
  return executeGqlQuery({
    query: QUERY,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
};
