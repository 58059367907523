import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      cursor: 'pointer',
      marginLeft: theme.spacing(1),
      height: 16,
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.pxToRem(16),
      color: COLORS.primary.default,

      '&:hover': {
        color: COLORS.primary.active,
      },
    },
  }),
  { name: 'StonlyIcon' }
);
