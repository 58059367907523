import React, { forwardRef, useCallback, useImperativeHandle, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ASSET_TYPE } from 'src/data/asset';
import {
  CustomEditable,
  CustomSlate,
  hasSlateContentChangeOperation,
  useRichSlateEditor,
} from 'src/components/RichSlate';
import { TextAndWordCounter } from 'src/components/TextAndWordCounter';
import TextCounter from 'src/components/TextCounter';
import { createEditorInstanceImperativeHandle, useDecorateTextInsights } from 'src/lib/slate';
import { InsightsLeaf } from './components/InsightsLeaf';
import { useContentCounterData } from './useContentCounterData';
import { useStyles } from './useStyles';
import { EditorSkeleton } from './components/EditorSkeleton';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  assetType: PropTypes.oneOf(Object.values(ASSET_TYPE)).isRequired,
  content: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func,
  detectedBrandTerms: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  setEditor: PropTypes.func,
  onCopy: PropTypes.func,
  onEdit: PropTypes.func,
  editMode: PropTypes.bool,
  isAppModeInfinity: PropTypes.bool,
  loading: PropTypes.bool,
};

const defaultProps = {
  className: null,
  classes: null,
  onChange: null,
  detectedBrandTerms: [],
  disabled: false,
  readOnly: false,
  setEditor: null,
  onCopy: null,
  onEdit: null,
  editMode: false,
  isAppModeInfinity: false,
  loading: false,
};

export const TextEditorRichSlate = forwardRef(
  (
    {
      className,
      classes,
      assetType,
      content,
      onChange,
      detectedBrandTerms,
      disabled,
      readOnly,
      setEditor,
      onCopy,
      onEdit,
      editMode,
      isAppModeInfinity,
      loading,
    },
    ref
  ) => {
    const styles = useStyles({ classes });
    const editorInstance = useRichSlateEditor();
    const [focused, setFocused] = useState(false);

    const counter = useContentCounterData(assetType, content);
    const decorate = useDecorateTextInsights(editorInstance, detectedBrandTerms);

    useEffect(() => {
      if (setEditor) {
        setEditor(editorInstance);
      }
    }, [editorInstance, setEditor]);

    useImperativeHandle(ref, () => createEditorInstanceImperativeHandle(editorInstance), [
      editorInstance,
    ]);

    const handleChange = useCallback(
      (value) => {
        if (!hasSlateContentChangeOperation(editorInstance.operations)) {
          return;
        }

        if (onChange) {
          onChange(value, editorInstance.operations);
        }
      },
      [onChange, editorInstance]
    );

    const handleFocusChange = useCallback((value) => {
      setFocused(value);
    }, []);

    return (
      <div
        className={cx(styles.root, className, {
          [styles.focused]: focused,
          [styles.empty]: counter.isEmpty,
          [styles.readOnly]: readOnly,
          [styles.extraPaddingBottom]: editMode || isAppModeInfinity,
        })}
        data-testid="TextEditor"
      >
        <div
          className={styles.editorWrapper}
          onClick={onEdit}
        >
          <div className={styles.outline} />

          <CustomSlate
            editor={editorInstance}
            value={content}
            onChange={handleChange}
          >
            <CustomEditable
              className={cx(styles.editor, { [styles.loadingEditor]: loading })}
              onFocusChange={handleFocusChange}
              decorate={decorate}
              readOnly={disabled || readOnly}
              onCopy={onCopy}
              onCut={onCopy}
              renderLeaf={(props) => <InsightsLeaf {...props} />}
            />
            {loading && <EditorSkeleton className={styles.loader} />}
          </CustomSlate>

          <div className={styles.footer}>
            {editMode && counter.isStrict ? (
              <TextCounter
                className={styles.textCounter}
                count={counter.charCount}
                maxCount={counter.maxCount}
                strict={counter.isStrict}
              />
            ) : (
              <TextAndWordCounter
                className={styles.textCounter}
                count={counter.charCount}
                text={content}
                assetType={assetType}
                hideWords
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);

TextEditorRichSlate.displayName = 'TextEditorRichSlate';
TextEditorRichSlate.propTypes = propTypes;
TextEditorRichSlate.defaultProps = defaultProps;
