import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom-v5-compat';

import { useLocationBlocker } from 'src/lib/hooks';
import { ROUTES } from './routes';
import { PrivateRoute } from './views/PrivateRoute';
import { PublicRoute } from './views/PublicRoute';
import SignUpPage from './views/SignUpPage';
import SignInPage from './views/SignInPage';
import ResetPasswordPage from './views/ResetPasswordPage';
import AcceptInvitePage from './views/AcceptInvitePage';
import LinkedinAuthCallback from './views/LinkedinAuthCallback';
import GoogleAuthCallback from './views/GoogleAuthCallback';
import TwitterAuthCallback from './views/TwitterAuthCallback';
import HubspotAuthCallback from './views/HubspotAuthCallback';
import SalesforceAuthCallback from './views/SalesforceAuthCallback';
import OAuthCallback from './views/OAuthCallback';
import VerifyEmailCallback from './views/VerifyEmailCallback';
import { MagicLinkCallback } from './views/MagicLinkCallback';
import NotVerifiedPage from './views/NotVerifiedPage';
import { ProjectsPage } from './views/ProjectsPage';
import { PersonasPage } from './views/PersonasPage';
import ProjectPage from './views/ProjectPage';
import SettingsPage from './views/SettingsPage';
import MobileDevicePage from './views/MobileDevicePage';
import LockedOutPage from './views/LockedOutPage';
import { SubscriptionPage } from './views/SubscriptionPage';
import { CheckoutPage } from './views/CheckoutPage';
import RootPage from './views/RootPage';
import { ExternalSignupIframe } from './views/ExternalSignupIframe';
import { ExternalSignupPage } from './views/ExternalSignupPage';
import { DashboardPage } from './views/DashboardPage';
import { SharedPage } from './views/SharedPage';
import { TalkingPointsPage } from './views/TalkingPointsPage';
import { BrandTermsPage } from './views/BrandTermsPage';
import { BrandBriefsPage } from './views/BrandBriefsPage';
import { ExternalInfinityExtensionIframe } from './views/ExternalInfinityExtensionIframe';
import { TemplateBuilder } from './views/TemplateBuilder';
import { ToneOfVoicePage } from './views/ToneOfVoicePage';
import { CustomModelsPage } from './views/CustomModelsPage';
import { ChannelsResourcesPage } from './views/ChannelsResourcesPage';
import { CopyAnalyticsPage } from './views/CopyAnalyticsPage';
import { WebsiteAutomationPage } from './views/WebsiteAutomationPage';
import { MultiPromptBuilderPage } from './views/MultiPromptBuilderPage';
import { CustomFormulaPage } from './views/CustomFormulaPage';
import { ExtensionOnboardingPage } from './views/ExtensionOnboardingPage';
import { ExtensionUninstalledPage } from './views/ExtensionUninstalledPage';
import { isApplicationModeInfinity } from './data/system';
import FileViewerPage from './views/FileViewerPage';

// `react-router-dom-v5-compat`(it is official migration package) is used because `react-router-last-location`
// doesn't support react-router v6 so we need to use react-router v5
// After `react-router-last-location` will migrate to react-router v6, the following should be done:
//   1. uninstall `react-router-dom-v5-compat`, `react-router` and `react-router-dom` from package.json
//   2. install `react-router-dom`
//   3. remove `<CompatRouter>` from `App.js`
//   4. rewrite useLocationBlocker() using react-router v6
// Migration guide https://reactrouter.com/docs/en/v6/upgrading/v5

export const AppRouting = () => {
  useLocationBlocker();

  // Platform embedded in Extension really needs only one "view", and should not support redirections to anywhere else
  if (isApplicationModeInfinity()) {
    return <ExternalInfinityExtensionIframe />;
  }

  return (
    <Routes>
      <Route
        path={ROUTES.SIGN_UP}
        element={
          <PublicRoute>
            <SignUpPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.SIGN_UP_DEPRECATED}
        element={
          <Navigate
            to={ROUTES.SIGN_UP}
            replace
          />
        }
      />

      <Route
        path={ROUTES.SIGN_IN}
        element={
          <PublicRoute>
            <SignInPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.SIGN_IN_DEPRECATED}
        element={
          <Navigate
            to={ROUTES.SIGN_IN}
            replace
          />
        }
      />

      <Route
        path={ROUTES.RESET_PASSWORD}
        element={
          <PublicRoute displayNavigation>
            <ResetPasswordPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.ACCEPT_INVITE}
        element={
          <PublicRoute displayNavigation>
            <AcceptInvitePage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.LINKEDIN_AUTH_CALLBACK}
        element={
          <PublicRoute displayNavigation>
            <LinkedinAuthCallback />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.GOOGLE_AUTH_CALLBACK}
        element={
          <PublicRoute displayNavigation>
            <GoogleAuthCallback />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.TWITTER_AUTH_CALLBACK}
        element={
          <PublicRoute displayNavigation>
            <TwitterAuthCallback />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.TWITTER_SOCIAL_AUTH_CALLBACK}
        element={
          <PublicRoute displayNavigation>
            <TwitterAuthCallback />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.HUBSPOT_AUTH_CALLBACK}
        element={
          <PublicRoute displayNavigation>
            <HubspotAuthCallback />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.SALESFORCE_AUTH_CALLBACK}
        element={
          <PublicRoute displayNavigation>
            <SalesforceAuthCallback />
          </PublicRoute>
        }
      />

      {[ROUTES.OAUTH_CALLBACK, ROUTES.SAML_CALLBACK].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <PublicRoute displayNavigation>
              <OAuthCallback />
            </PublicRoute>
          }
        />
      ))}
      <Route
        path={ROUTES.VERIFY_EMAIL_CALLBACK}
        element={
          <PublicRoute displayNavigation>
            <VerifyEmailCallback />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.MAGIC_LINK_CALLBACK}
        element={
          <PublicRoute displayNavigation>
            <MagicLinkCallback />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.EXTERNAL_SIGNUP_IFRAME}
        element={
          <PublicRoute displayNavigation>
            <ExternalSignupIframe />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.EXTERNAL_SIGNUP}
        element={
          <PublicRoute displayNavigation>
            <ExternalSignupPage />
          </PublicRoute>
        }
      />

      <Route
        path={`${ROUTES.SHARED}/:shareDocumentId`}
        element={
          <PublicRoute>
            <SharedPage />
          </PublicRoute>
        }
      />
      <Route
        path={ROUTES.DASHBOARD}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <DashboardPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.NOT_VERIFIED}
        element={
          <PrivateRoute>
            <NotVerifiedPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.TALKING_POINTS}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <TalkingPointsPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.INTEGRATIONS_RESOURCES}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <ChannelsResourcesPage />
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.ANALYTICS}
        element={
          <PrivateRoute displayNotifications>
            <CopyAnalyticsPage />
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.WEBSITE_AUTOMATION}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <WebsiteAutomationPage />
          </PrivateRoute>
        }
      />

      <Route
        path={`${ROUTES.BRAND_VOICE}/:brandVoiceId${ROUTES.BRAND_TERMS}`}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <BrandTermsPage />
          </PrivateRoute>
        }
      />
      <Route
        path={`${ROUTES.BRAND_VOICE}/:brandVoiceId${ROUTES.BRAND_BRIEFS}`}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <BrandBriefsPage />
          </PrivateRoute>
        }
      />

      <Route
        path={`${ROUTES.BRAND_VOICE}/:brandVoiceId${ROUTES.TONE_OF_VOICE}`}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <ToneOfVoicePage />
          </PrivateRoute>
        }
      />

      <Route
        path={`${ROUTES.BRAND_VOICE}/:brandVoiceId${ROUTES.CUSTOM_MODELS}`}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <CustomModelsPage />
          </PrivateRoute>
        }
      />

      <Route
        path={`${ROUTES.BRAND_VOICE}/:brandVoiceId${ROUTES.PERSONAS}`}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <PersonasPage />
          </PrivateRoute>
        }
      />

      <Route
        path={ROUTES.PROJECTS}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
            exact
          >
            <ProjectsPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.TEMPLATE_BUILDER}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <TemplateBuilder />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.MULTI_PROMPT_BUILDER}
        element={
          <PrivateRoute displayNotifications>
            <MultiPromptBuilderPage />
          </PrivateRoute>
        }
      />
      <Route
        path={`${ROUTES.BRAND_VOICE}/:brandVoiceId${ROUTES.CUSTOM_FORMULA}`}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <CustomFormulaPage />
          </PrivateRoute>
        }
      />

      <Route
        path={`${ROUTES.PROJECTS}/:projectId`}
        element={
          <PrivateRoute
            displayNotifications
            onlyImportantNotifications
            floatingNotifications
          >
            <ProjectPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.SETTINGS}
        element={
          <PrivateRoute
            displayNavigation
            displayNotifications
          >
            <SettingsPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.MOBILE}
        element={
          <PrivateRoute hideZendeskWidget>
            <MobileDevicePage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.LOCKED}
        element={
          <PrivateRoute displayNavigation>
            <LockedOutPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.CHECKOUT}
        element={
          <PrivateRoute unAuthRedirectPath={ROUTES.SIGN_UP}>
            <CheckoutPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.UPGRADE}
        element={
          <PrivateRoute>
            <SubscriptionPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.EXTENSION_ONBOARDING}
        element={
          <PrivateRoute>
            <ExtensionOnboardingPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.EXTENSION_UNINSTALLED}
        element={
          <PrivateRoute>
            <ExtensionUninstalledPage />
          </PrivateRoute>
        }
      />

      <Route
        path={`${ROUTES.FILE_VIEWER}/:type`}
        element={
          <PrivateRoute>
            <FileViewerPage />
          </PrivateRoute>
        }
      />
      <Route
        path={ROUTES.ROOT}
        element={
          <PrivateRoute>
            <RootPage />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={ROUTES.ROOT}
            replace
          />
        }
      />
    </Routes>
  );
};
