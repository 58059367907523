import React from 'react';

import { CommonStoreContainer } from './common';
import { Container as DebugStoreContainer } from './debug';
import { WorkspaceStoreContainer, useCurrentWorkspaceId } from './workspace';
import { ProjectStoreContainer } from './project';
import { Container as VariationStoreContainer } from './variation';
import { UserStoreContainer, useCurrentUserId } from './user';
import { CustomerStoreContainer } from './customer';
import {
  Container as NotificationStoreContainer,
  useActions as useNotificationActions,
} from './notification';
import { UiStoreContainer } from './ui';
import useCurrentUser from './user/useCurrentUser';
import { useCurrentCustomerId } from './customer';
import { CustomerPersonaStoreContainer } from './customerPersona';
import { FreestyleToolsContainer } from './freestyleTools';
import { MultiPromptTemplatesContainer } from './multiPromptTemplates';
import { CustomerMemberStoreContainer } from './customerMember';
import { GenerationToolStoreContainer } from './generationTool';
import { PerformanceStoreContainer } from './performance';
import { BrandTermsStoreContainer } from './brandTerms';
import { BriefsStoreContainer } from './briefs';
import { SystemStoreContainer } from './system';
import { CustomFormulasStoreContainer } from './customFormulas';
import { ToneOfVoiceStoreContainer } from './toneOfVoice';
import { BrandVoicesStoreContainer } from './brandVoices';
import { CustomModelsStoreContainer } from './customModels';
import { TalkingPointsViewsStoreContainer } from './talkingPointsViews';
import { ProjectFolderStoreContainer } from './projectFolder';

const DataStoresContainers = ({ children }) => {
  const currentUser = useCurrentUser();
  const currentCustomerId = useCurrentCustomerId();
  const notifications = useNotificationActions();
  const currentWorkspaceId = useCurrentWorkspaceId();

  return (
    <CustomerMemberStoreContainer
      isGlobal
      currentCustomerId={currentCustomerId}
    >
      <CommonStoreContainer
        isGlobal
        currentUserConfirmed={currentUser?.isConfirmed}
        notifications={notifications}
      >
        <PerformanceStoreContainer
          isGlobal
          currentCustomerId={currentCustomerId}
          currentWorkspaceId={currentWorkspaceId}
          notifications={notifications}
        >
          <TalkingPointsViewsStoreContainer
            isGlobal
            currentCustomerId={currentCustomerId}
            currentWorkspaceId={currentWorkspaceId}
            notifications={notifications}
          >
            <BrandVoicesStoreContainer
              isGlobal
              currentCustomerId={currentCustomerId}
              currentWorkspaceId={currentWorkspaceId}
              notifications={notifications}
            >
              <BrandTermsStoreContainer
                isGlobal
                currentCustomerId={currentCustomerId}
                notifications={notifications}
              >
                <GenerationToolStoreContainer isGlobal>
                  <MultiPromptTemplatesContainer
                    isGlobal
                    currentCustomerId={currentCustomerId}
                    currentWorkspaceId={currentWorkspaceId}
                  >
                    <FreestyleToolsContainer
                      isGlobal
                      currentCustomerId={currentCustomerId}
                      currentWorkspaceId={currentWorkspaceId}
                    >
                      <CustomerPersonaStoreContainer
                        isGlobal
                        currentCustomerId={currentCustomerId}
                        notifications={notifications}
                      >
                        <ProjectStoreContainer
                          isGlobal
                          currentCustomerId={currentCustomerId}
                          currentWorkspaceId={currentWorkspaceId}
                        >
                          <BriefsStoreContainer
                            isGlobal
                            currentCustomerId={currentCustomerId}
                          >
                            <CustomFormulasStoreContainer isGlobal>
                              <CustomModelsStoreContainer
                                isGlobal
                                currentCustomerId={currentCustomerId}
                                notifications={notifications}
                              >
                                <ToneOfVoiceStoreContainer
                                  isGlobal
                                  currentCustomerId={currentCustomerId}
                                >
                                  <VariationStoreContainer isGlobal>
                                    <ProjectFolderStoreContainer
                                      currentWorkspaceId={currentWorkspaceId}
                                      isGlobal
                                    >
                                      {children}
                                    </ProjectFolderStoreContainer>
                                  </VariationStoreContainer>
                                </ToneOfVoiceStoreContainer>
                              </CustomModelsStoreContainer>
                            </CustomFormulasStoreContainer>
                          </BriefsStoreContainer>
                        </ProjectStoreContainer>
                      </CustomerPersonaStoreContainer>
                    </FreestyleToolsContainer>
                  </MultiPromptTemplatesContainer>
                </GenerationToolStoreContainer>
              </BrandTermsStoreContainer>
            </BrandVoicesStoreContainer>
          </TalkingPointsViewsStoreContainer>
        </PerformanceStoreContainer>
      </CommonStoreContainer>
    </CustomerMemberStoreContainer>
  );
};

const WorkspaceStoreContainerWrapper = ({ children }) => {
  const currentUserId = useCurrentUserId();
  const currentCustomerId = useCurrentCustomerId();
  const notifications = useNotificationActions();

  return (
    <WorkspaceStoreContainer
      isGlobal
      currentUserId={currentUserId}
      currentCustomerId={currentCustomerId}
      notifications={notifications}
    >
      <DataStoresContainers>{children}</DataStoresContainers>
    </WorkspaceStoreContainer>
  );
};

const CustomerStoreContainerWrapper = ({ children }) => {
  const currentUser = useCurrentUser();

  return (
    <CustomerStoreContainer
      isGlobal
      currentUser={currentUser}
    >
      <WorkspaceStoreContainerWrapper>{children}</WorkspaceStoreContainerWrapper>
    </CustomerStoreContainer>
  );
};

const GlobalStoreContainers = ({ children }) => {
  return (
    <NotificationStoreContainer isGlobal>
      <DebugStoreContainer isGlobal>
        <UiStoreContainer isGlobal>
          <SystemStoreContainer isGlobal>
            <UserStoreContainer isGlobal>
              <CustomerStoreContainerWrapper>{children}</CustomerStoreContainerWrapper>
            </UserStoreContainer>
          </SystemStoreContainer>
        </UiStoreContainer>
      </DebugStoreContainer>
    </NotificationStoreContainer>
  );
};

export default GlobalStoreContainers;
