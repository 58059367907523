import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconSms = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        d="M15.3244 5.16797H4.68598C4.40205 5.16797 4.17188 5.39814 4.17188 5.68207V12.6063V13.978C4.17188 14.262 4.40205 14.4921 4.68598 14.4921H7.44285V16.6431C7.44285 16.8526 7.68003 16.9741 7.85007 16.8517L11.1281 14.4921H15.3244C15.6084 14.4921 15.8385 14.262 15.8385 13.978V5.68207C15.8385 5.39814 15.6084 5.16797 15.3244 5.16797Z"
        stroke="#2F393E"
        strokeWidth="0.875"
        strokeLinejoin="round"
      />
      <path
        d="M7.78147 9.75011C7.78147 10.1336 7.47055 10.4446 7.08702 10.4446C6.70349 10.4446 6.39258 10.1336 6.39258 9.75011C6.39258 9.36658 6.70349 9.05566 7.08702 9.05566C7.47055 9.05566 7.78147 9.36658 7.78147 9.75011Z"
        fill="#2F393E"
      />
      <path
        d="M10.5608 9.75011C10.5608 10.1336 10.2499 10.4446 9.86632 10.4446C9.48279 10.4446 9.17188 10.1336 9.17188 9.75011C9.17188 9.36658 9.48279 9.05566 9.86632 9.05566C10.2499 9.05566 10.5608 9.36658 10.5608 9.75011Z"
        fill="#2F393E"
      />
      <path
        d="M13.3362 9.75011C13.3362 10.1336 13.0252 10.4446 12.6417 10.4446C12.2582 10.4446 11.9473 10.1336 11.9473 9.75011C11.9473 9.36658 12.2582 9.05566 12.6417 9.05566C13.0252 9.05566 13.3362 9.36658 13.3362 9.75011Z"
        fill="#2F393E"
      />
    </SvgIcon>
  ))
);
