import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_ONBOARDING_STAGE } from './schema';

const MUTATION = gql`
  mutation UpdateOnboardingStage($name: String!, $status: String, $value: String) {
    updateOnboardingStage(name: $name, status: $status, value: $value) {
      ...UserOnboardingStage
    }
  }
  ${FRAGMENT_ONBOARDING_STAGE}
`;

export const updateOnboardingStage = ({ name, status, value }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { name, status, value },
  }).then(({ data }) => getSingleProp(data));
