import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Skeleton } from '@material-ui/lab';

import ScoreView from 'src/components/ScoreView';
import { SCORE_SIZE } from 'src/components/Score';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { CustomChip } from 'src/components/CustomChip';
import { Information } from 'src/components/Information';
import { IconArrow } from 'src/assets/icons/IconArrow';
import { IconCheckBold } from 'src/assets/icons/IconCheckBold';
import { getFleschReadingEaseScore, getScoreDescription } from '../../lib';
import { InsightBox } from '../InsightBox';
import { useStyles } from './useStyles';

export const SEOScore = ({
  isLoading,
  score,
  wordCount,
  paragraphCount,
  headlineCount,
  userKeywords,
  competitorKeywords,
  onExpand,
  fullText,
  onToggleMarkedKeywords,
}) => {
  const styles = useStyles();

  const seoScoreDescription = useCallback(
    (score) => {
      const SCORE_THRESHOLDS = {
        medium: 40,
        high: 65,
      };
      if (isLoading) {
        return 'Calculating your SEO score...';
      }
      if (!score) {
        return "We couldn't calculate your SEO score";
      }
      if (score >= SCORE_THRESHOLDS.high) {
        return 'Engaging, relevant, and SEO-aligned, your content is perfectly suited for publishing.';
      } else if (score >= SCORE_THRESHOLDS.medium) {
        return 'Your blog can be improved. Follow our SEO suggestions to increase its visibility and appeal.';
      } else {
        return 'Your blog needs more engaging and keyword relevant content. Use our SEO suggestions for a noticeable boost.';
      }
    },
    [isLoading]
  );

  const keywordChipStyle = (keyword) => {
    if (!keyword.range) {
      return styles.keywordRangeGray;
    }

    if (keyword.count === 0) {
      return styles.keywordRangeRed;
    }

    if (keyword.count >= keyword.range?.[0] && keyword.count <= keyword.range?.[1]) {
      return styles.keywordRangeGreen;
    }

    return styles.keywordRangeOrange;
  };

  const keywordIconArrow = (keyword) => {
    if (keyword?.count < keyword.range?.[0]) {
      return <IconArrow className={styles.keywordArrowUp} />;
    }
    if (keyword?.count > keyword.range?.[1]) {
      return <IconArrow className={styles.keywordArrowDown} />;
    }
    return null;
  };

  const keywordComponent = (keyword) => {
    return (
      <CustomChip
        onClick={() => {
          if (keyword.cluster) {
            onToggleMarkedKeywords(keyword.cluster);
          } else {
            onToggleMarkedKeywords([keyword.keyword]);
          }
        }}
        label={
          <div className={styles.singleChipContainer}>
            {keyword.keyword}{' '}
            <span className={cx(styles.keywordRange, keywordChipStyle(keyword))}>
              {`${keyword.count} / ${
                keyword.range ? keyword.range?.[0] + '-' + keyword.range?.[1] : '-'
              }`}
            </span>
            {keywordIconArrow(keyword)}
          </div>
        }
      />
    );
  };

  const KeywordSkeleton = () => {
    return (
      <div className={styles.singleChipSkeleton}>
        <Skeleton
          variant="text"
          className={styles.text}
          width={80}
          animation="wave"
        />
        <Skeleton
          variant="text"
          className={styles.text}
          width={20}
          animation="wave"
        />
      </div>
    );
  };

  const structureComponent = useCallback(
    (item) => {
      const structureIcon = (item) => {
        if (item.count >= item.range?.[0] && item.count <= item.range?.[1]) {
          return <IconCheckBold className={styles.structureIconCheck} />;
        }

        if (item.count < item.range?.[0]) {
          return <IconArrow className={styles.keywordArrowUp} />;
        }

        if (item.count > item.range?.[1]) {
          return <IconArrow className={styles.keywordArrowDown} />;
        }

        return null;
      };

      return (
        <div className={styles.structureChip}>
          <CustomTypography variant={TYPOGRAPHY_VARIANT.regular12}>{item.label}</CustomTypography>
          {isLoading && (
            <Skeleton
              variant="text"
              className={styles.text}
              width={100 + '%'}
              animation="wave"
            />
          )}
          {!isLoading && (
            <div className={styles.structureChipDetails}>
              <CustomTypography
                variant={TYPOGRAPHY_VARIANT.medium14}
                className={styles.structureChipBody}
              >
                {item.count} {structureIcon(item)}
              </CustomTypography>
              {item.range && (
                <CustomTypography
                  variant={TYPOGRAPHY_VARIANT.regular12}
                >{`${item.range?.[0]} -${item.range?.[1]}`}</CustomTypography>
              )}
            </div>
          )}
        </div>
      );
    },
    [isLoading, styles]
  );

  const fleshReadingEaseScore = getFleschReadingEaseScore(fullText);
  const fleshReadingEaseScoreDescription = getScoreDescription(fleshReadingEaseScore);
  const fleshReadingEaseScoreDescriptionText = getScoreDescription(
    fleshReadingEaseScore,
    true
  )?.description;

  return (
    <InsightBox
      title="SEO Score"
      onExpand={onExpand}
    >
      <div className={styles.scoreWrapper}>
        <div className={styles.scoreView}>
          <ScoreView
            className={styles.score}
            score={score}
            size={SCORE_SIZE.xxxxxl}
            strokeWidth={4}
            isLoading={isLoading}
            grayScaleLoader={true}
          />
        </div>
        <CustomTypography
          className={styles.scoreDetails}
          variant={TYPOGRAPHY_VARIANT.regular14}
        >
          {seoScoreDescription(score)}
        </CustomTypography>
      </div>
      <div className={styles.structureWrapper}>
        <div className={styles.structureRow}>
          {structureComponent(wordCount)}
          {structureComponent(paragraphCount)}
        </div>
        <div className={styles.structureRow}>
          {structureComponent(headlineCount)}
          <div className={styles.structureChip}>
            <div className={styles.flexLabel}>
              <CustomTypography variant={TYPOGRAPHY_VARIANT.regular12}>
                Readability level
              </CustomTypography>
              <Information
                className={styles.hint}
                content={fleshReadingEaseScoreDescriptionText}
              />
            </div>
            <div className={styles.structureChipDetails}>
              {isLoading && (
                <Skeleton
                  variant="text"
                  className={styles.text}
                  width={100 + '%'}
                  animation="wave"
                />
              )}
              {!isLoading && (
                <CustomTypography
                  variant={TYPOGRAPHY_VARIANT.medium14}
                  className={styles.structureChipBody}
                >
                  {fleshReadingEaseScoreDescription?.education}
                </CustomTypography>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <CustomTypography variant={TYPOGRAPHY_VARIANT.medium14}>
          SEO Keywords Usage
        </CustomTypography>
      </div>
      <div>
        <div className={styles.flexLabel}>
          <CustomTypography variant={TYPOGRAPHY_VARIANT.medium12}>Your keywords</CustomTypography>
        </div>
        <div className={styles.keywordChipWrapper}>
          {isLoading && <KeywordSkeleton />}
          {!isLoading && userKeywords.map((keyword) => keywordComponent(keyword))}
        </div>
      </div>
      <div>
        <div className={styles.flexLabel}>
          <CustomTypography
            className={styles.competitorKeywords}
            variant={TYPOGRAPHY_VARIANT.medium12}
          >
            Suggestions based on competitors
          </CustomTypography>
        </div>
        {isLoading && <KeywordSkeleton />}
        {!isLoading && competitorKeywords.length === 0 && (
          <CustomTypography
            className={styles.noKeywords}
            variant={TYPOGRAPHY_VARIANT.regular12}
          >
            We couldn't find any suggestions based on your competitors
          </CustomTypography>
        )}
        <div className={styles.keywordChipWrapper}>
          {competitorKeywords.map((keyword) => keywordComponent(keyword))}
        </div>
      </div>
    </InsightBox>
  );
};

SEOScore.propTypes = {
  isLoading: PropTypes.bool,
  score: PropTypes.number,
  wordCount: PropTypes.shape({
    label: PropTypes.string,
    range: PropTypes.array,
    count: PropTypes.number,
  }),
  paragraphCount: PropTypes.shape({
    label: PropTypes.string,
    range: PropTypes.array,
    count: PropTypes.number,
  }),
  headlineCount: PropTypes.shape({
    label: PropTypes.string,
    range: PropTypes.array,
    count: PropTypes.number,
  }),
  userKeywords: PropTypes.arrayOf(
    PropTypes.shape({
      keyword: PropTypes.string,
      range: PropTypes.array,
      count: PropTypes.number,
    })
  ),
  competitorKeywords: PropTypes.arrayOf(
    PropTypes.shape({
      keyword: PropTypes.string,
      range: PropTypes.array,
      count: PropTypes.number,
    })
  ),
  onExpand: PropTypes.func,
  onToggleMarkedKeywords: PropTypes.func,
  fullText: PropTypes.string,
};
