import { ONBOARDING_STEP } from 'src/data/onboarding';
import { SPOTLIGHT_TYPE } from '../components/Spotlight';
import { ScoreOnboardingHint } from '../components/ScoreHint';
import { HiddenAudiencesHint } from '../components/HiddenAudiencesHint';

export const STEPS_DOCUMENT_DATA = [
  {
    id: ONBOARDING_STEP.documentVariationScore,
    target: '[data-active-view="true"] [data-onboarding="Score"]',
    contentClass: ScoreOnboardingHint,
    content: {
      dontShowAgain: true,
    },
    options: {
      placement: 'left-start',
      showOverlay: true,
      spotlights: [{ type: SPOTLIGHT_TYPE.circle, padding: 15 }],
      zIndex: 1200,
    },
  },
  {
    id: ONBOARDING_STEP.documentSuggestedPersonasNotify,
    target: '[data-onboarding="PersonaMoreButton"]',
    contentClass: HiddenAudiencesHint,
    content: {
      dontShowAgain: true,
    },
    options: {
      placement: 'top-start',
      showOverlay: true,
      spotlights: [{ type: SPOTLIGHT_TYPE.rounded, padding: 0 }],
      zIndex: 1200,
    },
  },
];
