import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;

export const logout = async (authToken) => {
  const result = await executeGqlMutation({
    mutation: MUTATION,
    options: { authToken },
  });
  return getSingleProp(result.data);
};
