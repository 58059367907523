export class AsyncRequestError extends Error {
  constructor(message = '', code = null) {
    super(message);
    this.name = 'AsyncRequestError';
    this.message = message;
    this.code = code;
  }
}

export const isAsyncRequestError = (error) => error instanceof AsyncRequestError;
