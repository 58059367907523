import { alpha, makeStyles } from '@material-ui/core';

import { COLORS, FONTS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      backgroundColor: COLORS.primary.active,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    liveWebinar: {
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(1),
      padding: theme.spacing(0.75, 1.5),
      backgroundColor: alpha(COLORS.neutral.white, 0.27),
      borderRadius: theme.shape.borderRadiusSmall,
      color: COLORS.neutral.white,
    },
    title: {
      ...theme.typography.h3,
      color: COLORS.neutral.white,
      margin: theme.spacing(0, 2),
      fontFamily: FONTS.DMSans,
      '&:hover': {
        color: COLORS.neutral.white,
      },
    },
    time: {
      color: '#FFF500',
    },
    button: {
      backgroundColor: COLORS.neutral.white,
      color: COLORS.primary.active,
      borderRadius: 60,
      padding: theme.spacing(1.375, 2),
      '&:hover': {
        color: COLORS.primary.active,
        backgroundColor: alpha(COLORS.neutral.white, 0.82),
      },
      '&:active': {
        backgroundColor: COLORS.neutral.white,
      },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(2),
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    closeIcon: {
      fontSize: theme.typography.pxToRem(12),
      color: COLORS.neutral.white,
    },
  }),
  {
    name: 'WebinarBanner',
  }
);
