import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation TestCustomToneOfVoice(
    $customerId: ID!
    $text: String!
    $generationTool: GenerationToolEnum!
    $tovInput: TovInput!
  ) {
    testCustomToneOfVoice(
      customerId: $customerId
      text: $text
      generationTool: $generationTool
      tovInput: $tovInput
    ) {
      originalCopyText
      modifiedCopyText
      tovParams {
        draftTov {
          traits {
            trait
            butNot
          }
          personality
          description
        }
      }
    }
  }
`;

export const testCustomToneOfVoice = ({ customerId, text, generationTool, tovInput }, options) => {
  const variables = {
    customerId,
    text,
    generationTool,
    tovInput,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      abortSignal: options?.abortSignal,
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
