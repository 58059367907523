import { useEffect } from 'react';
import { useLocation } from 'react-router-dom-v5-compat';

import { useHistory } from 'src/lib/hooks/useHistory';
import Authentication from 'src/utils/Authentication';
import { customerSubscriptionExpired, customerHasEmptyMigrationStatus } from 'src/data/customer';
import { isMobileDevice } from 'src/lib/userAgent';
import {
  useCurrentUser,
  useCurrentCustomer,
  useCurrentWorkspaces,
  useWorkspacesFetched,
} from 'src/store';
import { ROUTES } from 'src/routes';
import { isOnboardingStageDone, ONBOARDING_STAGE } from '../data/onboarding';
import { useIsApplicationModeInfinity } from './useIsApplicationModeInfinity';

const isSignedIn = (user) => Authentication.isAuthenticated() && Boolean(user);

const checkDesktopUserAgent = (history, user) => {
  // Do not apply redirection to non-authed users
  if (!isSignedIn(user)) {
    return false;
  }

  if (isMobileDevice()) {
    history.replace(`${ROUTES.MOBILE}${window.location.search}`);
    return true;
  }

  return false;
};

const checkUnconfirmedUser = (history, locationPathname, user) => {
  // Do not redirect from 'verification callback' page
  // User might be logged-in already, and will receive 'currentUser.isConfirmed = false' before actually confirming him
  if (locationPathname === ROUTES.VERIFY_EMAIL_CALLBACK) {
    return false;
  }

  // Redirect un-confirmed user to confirmation info page
  if (user && !user.isConfirmed) {
    history.replace(ROUTES.NOT_VERIFIED);
    return true;
  }

  return false;
};

// Allow "locked" customers to visit some specific route
const isAllowedRouteForLockedCustomer = (locationPathname) => {
  return [ROUTES.UPGRADE, ROUTES.CHECKOUT, ROUTES.SETTINGS].includes(locationPathname);
};

const checkCustomerTrial = (history, locationPathname, customer) => {
  // Can't check if customer data is not available yet
  if (!customer) {
    return false;
  }

  if (isAllowedRouteForLockedCustomer(locationPathname)) {
    return false;
  }

  if (customerSubscriptionExpired(customer)) {
    history.replace(ROUTES.LOCKED);
    return true;
  }

  return false;
};

const checkCustomerPlanMigration = (history, user, customer) => {
  // Can't check if data is not available yet
  if (!user || !customer) {
    return false;
  }

  const seenMigrationPopup = isOnboardingStageDone(
    user.onboardingStages,
    ONBOARDING_STAGE.migratedFromStarter
  );
  if (customerHasEmptyMigrationStatus(customer) && !seenMigrationPopup) {
    history.replace(ROUTES.LOCKED);
    return true;
  }

  return false;
};

const checkHasAnyWorkspace = (history, user, customer, currentWorkspaces, workspacesFetched) => {
  // Can't check if data is not available yet
  if (!user || !customer) {
    return false;
  }

  if (workspacesFetched && currentWorkspaces.length === 0) {
    history.replace(ROUTES.SETTINGS);
    return true;
  }

  return false;
};

export const LocationGuard = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const currentUser = useCurrentUser();
  const currentCustomer = useCurrentCustomer();
  const currentWorkspaces = useCurrentWorkspaces();
  const workspacesFetched = useWorkspacesFetched();
  const isInfinityAppMode = useIsApplicationModeInfinity();

  useEffect(() => {
    // We don't want to have any redirections in "infinity" app mode - Platform should always be opened on the "infinity" route
    if (isInfinityAppMode) {
      return;
    }

    if (checkUnconfirmedUser(history, pathname, currentUser)) {
      return;
    }

    if (checkDesktopUserAgent(history, currentUser, currentCustomer)) {
      return;
    }

    if (checkCustomerTrial(history, pathname, currentCustomer)) {
      return;
    }

    if (checkCustomerPlanMigration(history, currentUser, currentCustomer)) {
      return;
    }

    if (
      checkHasAnyWorkspace(
        history,
        currentUser,
        currentCustomer,
        currentWorkspaces,
        workspacesFetched
      )
    ) {
      return;
    }
  }, [
    isInfinityAppMode,
    currentCustomer,
    currentUser,
    history,
    pathname,
    currentWorkspaces,
    workspacesFetched,
  ]);

  return null;
};
