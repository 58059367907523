import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'inline',
      padding: '1px 3px',

      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '3px',

      fontSize: '9px',
      fontWeight: theme.typography.fontWeightMedium,
      color: '#777',
    },
  }),
  { name: 'KeyEnter' }
);
