import { useCallback } from 'react';

import { useProjectActions } from 'src/store';
import { useErrorHandler } from 'src/services';
import { PROJECT_SETTINGS_POPUP, usePopups } from 'src/services/popup';
import { isDocumentProject } from 'src/data/project';

export const useRenameProject = () => {
  const popups = usePopups();

  const handleError = useErrorHandler();
  const projectActions = useProjectActions();

  return useCallback(
    (project) => {
      popups.openPopup(PROJECT_SETTINGS_POPUP, {
        name: project.name,
        isDocument: isDocumentProject(project.type),
        displayName: true,
        displayMoveToFolder: false,
        onConfirm: ({ name }) => {
          popups.closePopup(PROJECT_SETTINGS_POPUP);

          projectActions.renameProject({ id: project.id, name }).catch((error) => {
            handleError(error);
          });
        },
        onCancel: () => {
          popups.closePopup(PROJECT_SETTINGS_POPUP);
        },
      });
    },
    [popups, projectActions, handleError]
  );
};
