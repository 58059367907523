import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom-v5-compat';
import { CircularProgress } from '@material-ui/core';

import Authentication from 'src/utils/Authentication';
import { ROUTES } from 'src/routes';
import { useHideZendeskWidget } from 'src/services';
import { PrivateRouteWrapper } from './components/PrivateRouteWrapper';
import { useIsLoading } from './services';
import { useViewState } from './useViewState';
import { useStyles } from './useStyles';

export const PrivateRoute = ({
  classes,
  children,
  displayNavigation,
  displayNotifications,
  onlyImportantNotifications,
  floatingNotifications,
  hideZendeskWidget,
  hideOverflow,
  unAuthRedirectPath,
}) => {
  const styles = useStyles();

  const { pathname, search } = useLocation();
  const isLoading = useIsLoading();
  useHideZendeskWidget(hideZendeskWidget);

  const { showWebinarBanner } = useViewState();

  // If we don't have proper auth token, then we should redirect to "login" page
  if (!Authentication.isAuthenticated()) {
    const location = pathname + search;

    return (
      <Navigate
        to={unAuthRedirectPath || ROUTES.SIGN_IN}
        state={{ from: location }}
        replace
      />
    );
  }

  if (isLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <CircularProgress size={48} />
      </div>
    );
  }

  return (
    <PrivateRouteWrapper
      classes={classes}
      displayNavigation={displayNavigation}
      displayNotifications={displayNotifications}
      onlyImportantNotifications={onlyImportantNotifications}
      floatingNotifications={floatingNotifications}
      hideOverflow={hideOverflow}
      showWebinarBanner={showWebinarBanner}
    >
      {children}
    </PrivateRouteWrapper>
  );
};

PrivateRoute.propTypes = {
  classes: PropTypes.shape({}),
  children: PropTypes.node,
  displayNavigation: PropTypes.bool,
  displayNotifications: PropTypes.bool,
  onlyImportantNotifications: PropTypes.bool,
  floatingNotifications: PropTypes.bool,
  hideZendeskWidget: PropTypes.bool,
  unAuthRedirectPath: PropTypes.string,
};

PrivateRoute.defaultProps = {
  classes: null,
  children: null,
  displayNavigation: false,
  displayNotifications: false,
  onlyImportantNotifications: false,
  floatingNotifications: false,
  hideZendeskWidget: false,
  unAuthRedirectPath: null,
};
