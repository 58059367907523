import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation RateVariation($id: ID!, $rating: Int!, $feedback: String) {
    rateVariation(id: $id, rating: $rating, feedback: $feedback)
  }
`;

export const rateVariation = ({ id, rating, feedback }) => {
  const variables = { id, rating, feedback };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
