import React, { forwardRef } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconHome = forwardRef((props, ref) => (
  <SvgIcon
    {...props}
    ref={ref}
    viewBox="0 0 20 20"
    data-fill-none
  >
    <g>
      <path
        d="M-0.798828 10L9.36099 1.53349C9.73183 1.22445 10.2705 1.22445 10.6414 1.53349L20.8012 10"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.50171 9V17.8393C2.50171 18.3915 2.94942 18.8392 3.50171 18.8392H16.5017C17.054 18.8392 17.5017 18.3915 17.5017 17.8392V9"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 18.3333V13.5C7 12.9477 7.44772 12.5 8 12.5H12C12.5523 12.5 13 12.9477 13 13.5V18.3333"
        stroke="white"
        strokeWidth="1.5"
      />
    </g>
  </SvgIcon>
));
