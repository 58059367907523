import React from 'react';

import { usePopoverAnchor } from 'src/lib/hooks';
import { useCurrentCustomer, useCurrentCustomers, useCurrentUser } from 'src/store';
import { ProfileButton } from './ProfileButton';
import { ProfileMenu } from './ProfileMenu';
import { LogoutPopup } from './LogoutPopup';
import useStyles from './useStyles';
import { useCurrentPlanLabel } from './useCurrentPlanLabel';
import { useHandlers } from './useHandlers';
import { getUserName } from 'src/data/user';

export const UserProfile = ({ classes, disabledSettings }) => {
  const styles = useStyles({ classes });
  const [menuAnchor, openMenu, closeMenu] = usePopoverAnchor();
  const currentCustomers = useCurrentCustomers();
  const currentCustomer = useCurrentCustomer();
  const user = useCurrentUser();
  const planLabel = useCurrentPlanLabel();
  const userName = getUserName(user);
  const {
    dialogLogoutOpened,
    closeLogoutDialog,
    handleCustomerClick,
    handleSettingsClick,
    handleEditClick,
    handleLogoutClick,
    handleLogout,
    handleProfileButtonClick,
  } = useHandlers({ openMenu, closeMenu });

  return (
    <>
      <ProfileButton
        open={!!menuAnchor}
        onClick={handleProfileButtonClick}
        name={userName}
        email={user?.email}
        planLabel={planLabel}
      />

      <ProfileMenu
        classes={{ paper: styles.menu_paper }}
        open={!!menuAnchor}
        anchor={menuAnchor}
        onClose={closeMenu}
        customers={currentCustomers}
        selectedCustomerId={currentCustomer && currentCustomer.id}
        onCustomerClick={handleCustomerClick}
        onSettingsClick={handleSettingsClick}
        onLogoutClick={handleLogoutClick}
        onEditClick={handleEditClick}
        disabledSettings={disabledSettings}
        name={userName}
        email={user?.email}
      />

      <LogoutPopup
        open={dialogLogoutOpened}
        onApprove={handleLogout}
        onReject={closeLogoutDialog}
      />
    </>
  );
};
