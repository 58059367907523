export const CONTENT_TYPE = {
  product: 'product',
  content: 'content',
};

const CONTENT_TYPE_LABEL = {
  [CONTENT_TYPE.product]: {
    label: 'Product',
    taxonomyName: 'Topic or industry',
    taxonomyHint: 'Select the best scoring model to predict your copy performance.',
  },
  [CONTENT_TYPE.content]: {
    label: 'Content',
    taxonomyName: 'Topic',
    taxonomyHint:
      'Select the topic most relevant to your content. This will help score your copy variations.',
  },
};

export const getContentTypeLabel = (contentType) => CONTENT_TYPE_LABEL[contentType].label || '';

export const getContentTypeTaxonomyName = (contentType) =>
  CONTENT_TYPE_LABEL[contentType].taxonomyName || '';

export const getContentTypeTaxonomyHint = (contentType) =>
  CONTENT_TYPE_LABEL[contentType].taxonomyHint || '';

export const NON_SELECTABLE_TAXONOMY_HINT =
  'This Dataset is the set of comparable copy assets that Anyword used to score your copy.';
