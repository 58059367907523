import { ChannelAuthError } from 'src/lib/errors';
import { openPopup, subscribeToMessages } from 'src/utils/popup';

const POPUP_OPTIONS = {
  name: 'oauth-popup',
  width: 600,
  height: 800,
};

export const openOAuthPopup = (authUrl, popupOptions = {}) =>
  new Promise((resolve, reject) => {
    const resultHandler = (authData) => {
      popup.close();

      if (!authData) {
        reject();
        return;
      }

      if (authData.errorMessage) {
        reject(new Error(authData.errorMessage));
        return;
      }

      resolve(authData);
    };

    // Listen for message from popup
    const unsubscribeFromMessages = subscribeToMessages(
      (data) => {
        unsubscribeFromMessages();
        resultHandler(data);
      },
      { ...POPUP_OPTIONS, ...popupOptions }
    );

    // Open Google auth popup
    const popup = openPopup(authUrl, { ...POPUP_OPTIONS, ...popupOptions });

    if (!popup) {
      reject(new Error('Cannot open pop-up. Redirecting…'));
      unsubscribeFromMessages();
      return;
    }
    popup.focus();

    // Run a timer, to check if popup was closed by user, and hasn't sent a message
    const timer = setInterval(() => {
      if (!popup.closed) {
        return;
      }

      clearInterval(timer);
      unsubscribeFromMessages();
      resultHandler(null);
    }, 100);
  });

export const oauthLogin = async (authUrl) => {
  const authData = await openOAuthPopup(authUrl);

  if (authData?.token) {
    return authData;
  } else {
    return new ChannelAuthError('Error in OAuth login');
  }
};
