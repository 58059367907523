import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const EditorIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
        data-fill-none
      >
        <path
          d="M12.8707 13.7801L9.77734 14.2226L10.219 11.1284L18.174 3.17345C18.5256 2.82181 19.0026 2.62427 19.4998 2.62427C19.9971 2.62427 20.474 2.82181 20.8257 3.17345C21.1773 3.52508 21.3749 4.00199 21.3749 4.49928C21.3749 4.99656 21.1773 5.47348 20.8257 5.82511L12.8707 13.7801Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M17.2891 4.05762L19.9407 6.70928"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M17.625 13.875V20.125C17.625 20.4565 17.4933 20.7745 17.2589 21.0089C17.0245 21.2433 16.7065 21.375 16.375 21.375H3.875C3.54348 21.375 3.22554 21.2433 2.99112 21.0089C2.7567 20.7745 2.625 20.4565 2.625 20.125V7.625C2.625 7.29348 2.7567 6.97554 2.99112 6.74112C3.22554 6.5067 3.54348 6.375 3.875 6.375H10.125"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </SvgIcon>
    );
  })
);
