import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import IconClose from 'src/assets/icons/Close';
import useStyles from './useStyles';

export const HiddenAudiencesHint = forwardRef(
  ({ className, onNextClick, dontShowAgain, onDontShowAgainChange }, ref) => {
    const styles = useStyles();

    const handleClose = () => {
      onNextClick();
    };

    return (
      <div
        className={cx(styles.root, className)}
        ref={ref}
      >
        <IconButton
          className={styles.closeButton}
          onClick={handleClose}
        >
          <IconClose className={styles.closeIcon} />
        </IconButton>

        <Typography className={styles.title}>
          Click here to see suggestions for target audiences based on your brief
        </Typography>

        <FormControlLabel
          className={styles.showToggle}
          control={<Checkbox color="primary" />}
          label="Don't show again"
          checked={dontShowAgain}
          onChange={onDontShowAgainChange}
        />

        <div className={styles.arrow} />
      </div>
    );
  }
);

HiddenAudiencesHint.propTypes = {
  className: PropTypes.string,
  onNextClick: PropTypes.func.isRequired,
  dontShowAgain: PropTypes.bool,
  onDontShowAgainChange: PropTypes.func.isRequired,
};

HiddenAudiencesHint.defaultProps = {
  className: null,
  dontShowAgain: false,
};
