import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Typography } from '@material-ui/core';

import { ButtonText } from 'src/components/ButtonText';
import { IconTarget } from 'src/assets/icons/IconTarget';
import { IconCheckCircle } from 'src/assets/icons/IconCheckCircle';
import { IconSend } from 'src/assets/icons/IconSend';
import { TEXT_ANNOTATION_TYPE, getTextAnnotationTypeLabel } from 'src/data/annotation';
import { getAnnotationGroupByType, addIdToAnnotationsItems } from '../../lib';
import { InsightBox } from '../InsightBox';
import { AnnotationsGroup } from './components/AnnotationsGroup';
import { useStyles } from './useStyles';

export const TalkingPoints = ({ classes, annotations, loading }) => {
  const styles = useStyles({ classes });
  const [expanded, setExpanded] = useState(false);

  const mappedAnnotations = useMemo(() => addIdToAnnotationsItems(annotations), [annotations]);
  const features = getAnnotationGroupByType(mappedAnnotations, TEXT_ANNOTATION_TYPE.features);
  const benefits = getAnnotationGroupByType(mappedAnnotations, TEXT_ANNOTATION_TYPE.benefits);
  const ctas = getAnnotationGroupByType(mappedAnnotations, TEXT_ANNOTATION_TYPE.ctas);

  const handleExpandToggle = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  const groups = useMemo(() => {
    const result = [];

    if (features?.length > 0) {
      result.push(
        <AnnotationsGroup
          title={getTextAnnotationTypeLabel(TEXT_ANNOTATION_TYPE.features)}
          key={TEXT_ANNOTATION_TYPE.features}
          Icon={IconTarget}
          annotations={features}
        />
      );
    }

    if (benefits.length > 0) {
      result.push(
        <AnnotationsGroup
          title={getTextAnnotationTypeLabel(TEXT_ANNOTATION_TYPE.benefits)}
          key={TEXT_ANNOTATION_TYPE.benefits}
          Icon={IconCheckCircle}
          annotations={benefits}
        />
      );
    }

    if (ctas.length > 0) {
      result.push(
        <AnnotationsGroup
          title={getTextAnnotationTypeLabel(TEXT_ANNOTATION_TYPE.ctas)}
          key={TEXT_ANNOTATION_TYPE.ctas}
          Icon={IconSend}
          annotations={ctas}
        />
      );
    }

    return result;
  }, [benefits, ctas, features]);

  const hasHiddenAnnotations = groups.length > 1;
  const annotationsAvailable = groups?.length > 0;

  if (!loading && !annotationsAvailable) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Typography className={styles.boxGroupTitle}>High performing talking points</Typography>

      <InsightBox className={styles.container}>
        <div>
          {loading ? (
            <AnnotationsGroup loading />
          ) : (
            <>
              {groups[0]}

              <Collapse
                in={expanded}
                classes={{ wrapperInner: styles.annotationsGroups }}
              >
                {groups.slice(1)}
              </Collapse>
            </>
          )}
        </div>

        {!loading && hasHiddenAnnotations && (
          <ButtonText
            className={styles.expandButton}
            onClick={handleExpandToggle}
          >
            Show {expanded ? 'less' : 'more'}
          </ButtonText>
        )}
      </InsightBox>
    </div>
  );
};

TalkingPoints.propTypes = {
  classes: PropTypes.shape({}),
  annotations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool,
};

TalkingPoints.defaultValues = {
  classes: null,
  loading: false,
};
