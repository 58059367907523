import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconHomePage = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 20 20"
        data-fill-none
      >
        <path
          d="M15.6866 7.0625V14.5C15.6866 14.7785 15.5824 15.0455 15.3969 15.2425C15.2114 15.4394 14.9598 15.55 14.6974 15.55H5.30071C5.03838 15.55 4.05919 15.4394 3.8737 15.2425C3.6882 15.0455 3.58398 14.6035 3.58398 14.325V7.0625H9.99908H15.6866Z"
          fill="#66E1FF"
        />
        <path
          d="M5.14145 15.8125H5.625L12.1875 7.0625H3.4375V15.0833C3.4375 15.4 4.3125 15.8125 4.3125 15.8125C4.3125 15.8125 4.91527 15.8125 5.14145 15.8125Z"
          fill="#C2F3FF"
        />
        <path
          d="M15.6914 10.5645V5.31445C15.6914 5.08239 15.5992 4.85983 15.4351 4.69573C15.271 4.53164 15.0485 4.43945 14.8164 4.43945H4.31641C4.08434 4.43945 3.86178 4.53164 3.69769 4.69573C3.53359 4.85983 3.44141 5.08239 3.44141 5.31445V14.9395C3.44141 15.1715 3.53359 15.3941 3.69769 15.5582C3.86178 15.7223 4.08434 15.8145 4.31641 15.8145H7.81641"
          stroke="#1E1E1E"
          strokeWidth="0.65625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.57031 14.0705L12.2058 11.764C12.4452 11.5548 12.7524 11.4395 13.0703 11.4395C13.3883 11.4395 13.6954 11.5548 13.9348 11.764L16.5703 14.0705"
          fill="white"
        />
        <path
          d="M9.57031 14.0705L12.2058 11.764C12.4452 11.5548 12.7524 11.4395 13.0703 11.4395C13.3883 11.4395 13.6954 11.5548 13.9348 11.764L16.5703 14.0705"
          stroke="#1E1E1E"
          strokeWidth="0.65625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4453 13.625L10.4453 16.6888C10.4453 16.9208 10.5375 17.1434 10.7016 17.3075C10.8657 17.4716 11.0882 17.5638 11.3203 17.5638H14.8203C15.0524 17.5638 15.2749 17.4716 15.439 17.3075C15.6031 17.1434 15.6953 16.9208 15.6953 16.6888V13.625"
          fill="white"
        />
        <path
          d="M10.4453 13.625L10.4453 16.6888C10.4453 16.9208 10.5375 17.1434 10.7016 17.3075C10.8657 17.4716 11.0882 17.5638 11.3203 17.5638H14.8203C15.0524 17.5638 15.2749 17.4716 15.439 17.3075C15.6031 17.1434 15.6953 16.9208 15.6953 16.6888V13.625"
          stroke="#1E1E1E"
          strokeWidth="0.65625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9453 17.5645H12.1953V15.8145C12.1953 15.5824 12.2875 15.3598 12.4516 15.1957C12.6157 15.0316 12.8382 14.9395 13.0703 14.9395C13.3024 14.9395 13.5249 15.0316 13.689 15.1957C13.8531 15.3598 13.9453 15.5824 13.9453 15.8145V17.5645Z"
          fill="#C2F3FF"
          stroke="#1E1E1E"
          strokeWidth="0.65625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.44141 7.06445H15.6914"
          stroke="#1E1E1E"
          strokeWidth="0.65625"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
