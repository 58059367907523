import { createStateSelectorHook } from 'src/lib/sweetState';
import { BrandTermsStore } from './BrandTermsStore';

const useBrandTermsState = createStateSelectorHook(BrandTermsStore, [
  'brandVoiceIdsForBrandTerms',
  'brandRulesEnabled',
]);

export const useBrandVoiceIdsForBrandTerms = () => {
  const brandTermsState = useBrandTermsState();

  return brandTermsState;
};
