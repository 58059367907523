import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomCheckbox } from 'src/components/CustomCheckbox';
import { CustomFormControlLabel } from 'src/components/CustomFormControlLabel';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: null,
  classes: null,
  checked: false,
  disabled: false,
};

export const FieldCheckbox = ({ className, classes, checked, onChange, label, disabled }) => {
  const styles = useStyles({ classes });

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.checked;
      onChange(event, newValue);
    },
    [onChange]
  );

  return (
    <CustomFormControlLabel
      className={cx(styles.root, className)}
      classes={{ label: styles.label }}
      control={
        <CustomCheckbox
          className={styles.checkbox}
          iconClassName={styles.icon}
          color="primary"
        />
      }
      label={label}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

FieldCheckbox.displayName = 'FieldCheckbox';
FieldCheckbox.propTypes = propTypes;
FieldCheckbox.defaultProps = defaultProps;
