export const ONBOARDING_STAGE = {
  welcomePopup: 'WelcomePopup',
  welcomeRetrialPopup: 'WelcomeRetrialPopup',
  commonProjectWalkthrough: 'CommonProjectWalkthrough', // deprecated
  continuousOptimizationWalkthrough: 'ContinuousOptimizationWalkthrough', // deprecated
  productDescriptionWalkthrough: 'ProductDescriptionWalkthrough', // deprecated
  continuousOptimizationTutorial: 'ContinuousOptimizationTutorial', // deprecated
  blogBuilderNewEditingUI: 'BlogBuilderNewEditingUI',
  shopifyWelcome: 'ShopifyWelcome', // deprecated
  briefEditing: 'BriefEditing', // deprecated
  briefEditingBlog: 'BriefEditingBlog', // deprecated
  briefEditingContinuousOptimization: 'BriefEditingContinuousOptimization', // deprecated
  shopifyProductWalkthrough: 'ShopifyProductWalkthrough', // deprecated
  workspaces: 'Workspaces',
  dataDrivenDocumentsPopup: 'DataDrivenDocumentsPopup',
  basicPlanIntro: 'BasicPlanIntro', // deprecated
  migratedFromStarter: 'MigratedFromStarter',
  confirmAddToCampaign: 'ConfirmAddToCampaign',
  documentVariations: 'DocumentVariations',
  wtmLimitation: 'WtmLimitation',
  accounts: 'Accounts',
  wtmAsset: 'WtmAsset',
  listVariations: 'ListVariations',
  documentSuggestedPersonasNotify: 'MoreSuggestedPersonasNotify',
  documentPromptWithUrl: 'DocumentPromptWithUrl',
  personaBadgeHint: 'personaBadgeHint',
  cip: 'Cip',
  wtmCipMigrationPopup: 'WtmCipMigrationPopup',
  checkoutPlanRoute: 'CheckoutPlanRoute',
  homepageIntegrations: 'HomepageIntegrations',
  homepageTargetAudience: 'HomepageTargetAudience',
  homepageAds: 'HomepageAds',
  homepageEmail: 'HomepageEmail',
  homepageContentImprover: 'HomepageContentImprover',
  homepageLongForm: 'HomepageLongForm',
  homepageSocialPosts: 'HomepageSocialPosts',
  homepageToneOfVoice: 'HomepageToneOfVoice',
  webinarBanner2: 'WebinarBanner2',
  g2ReviewBoostClaimed: 'G2ReviewBoostClaimed',
};

export const ONBOARDING_STAGE_STATUS = {
  pending: 'pending',
  finished: 'finished',
  skipped: 'skipped',
};

export const ONBOARDING_STEP = {
  workspaces: 'workspaces',
  accounts: 'accounts',

  // Document steps
  documentVariationScore: 'documentVariationScore',
  documentSuggestedPersonasNotify: 'documentSuggestedPersonasNotify',
  documentPromptWithUrl: 'documentPromptWithUrl',

  // List variations
  listVariationActions: 'listVariationActions',

  // Wtm steps
  wtmAssetActions: 'wtmAssetActions',

  // CIP
  cipBoost: 'cipBoost',
};

const ONBOARDING_STAGE_STEPS = {
  [ONBOARDING_STAGE.workspaces]: [ONBOARDING_STEP.workspaces],
  [ONBOARDING_STAGE.accounts]: [ONBOARDING_STEP.accounts],
  [ONBOARDING_STAGE.listVariations]: [ONBOARDING_STEP.listVariationActions],
  [ONBOARDING_STAGE.documentVariations]: [ONBOARDING_STEP.documentVariationScore],
  [ONBOARDING_STAGE.wtmAsset]: [ONBOARDING_STEP.wtmAssetActions],
  [ONBOARDING_STAGE.documentSuggestedPersonasNotify]: [
    ONBOARDING_STEP.documentSuggestedPersonasNotify,
  ],
  [ONBOARDING_STAGE.cip]: [ONBOARDING_STEP.cipBoost],
};

export const getOnboardingStepsForStage = (stage) => {
  return ONBOARDING_STAGE_STEPS[stage] || [];
};

const getOnboardingStage = (stages, stageName) => {
  if (!Array.isArray(stages)) {
    return null;
  }
  return stages.find((s) => s.name === stageName);
};

export const isOnboardingStagePending = (stages, stageName) => {
  const stage = getOnboardingStage(stages, stageName);
  return stage ? stage.status === ONBOARDING_STAGE_STATUS.pending : false;
};

export const isOnboardingStageFinished = (stages, stageName) => {
  const stage = getOnboardingStage(stages, stageName);
  return stage ? stage.status === ONBOARDING_STAGE_STATUS.finished : false;
};

export const isOnboardingStageSkipped = (stages, stageName) => {
  const stage = getOnboardingStage(stages, stageName);
  return stage ? stage.status === ONBOARDING_STAGE_STATUS.skipped : false;
};

export function isOnboardingStageDone(stages, stageName) {
  return (
    isOnboardingStageFinished(stages, stageName) || isOnboardingStageSkipped(stages, stageName)
  );
}

export const getOnboardingStageValue = (stages, stageName, defaultValue = null) => {
  const stage = getOnboardingStage(stages, stageName);
  return stage ? stage.value : defaultValue;
};

export const isActiveOnboardingStage = (activeStage, onboardingStage) => {
  return activeStage?.toLowerCase() === onboardingStage.toLowerCase();
};
