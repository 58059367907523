import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation DeleteWorkspace($id: ID!) {
    deleteWorkspace(id: $id) {
      id
    }
  }
`;

export const deleteWorkspace = (id) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { id },
  }).then(({ data }) => getSingleProp(data));
};
