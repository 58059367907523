import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Editable } from 'slate-react';

import { useIsTranslated } from 'src/lib/hooks';
import { LinkInsertPopup } from '../CustomElement/components/LinkInsertPopup';
import { CustomSlateContext } from '../CustomSlate';
import { CustomElement } from '../CustomElement';
import { TextLeaf } from '../TextLeaf';
import { usePropagateFocusChange } from './usePropagateFocusChange';
import { useStyles } from './useStyles';

export const CustomEditable = forwardRef(
  ({ className, readOnly, onFocusChange, ...restProps }, rootRef) => {
    const styles = useStyles();
    const isTranslated = useIsTranslated();
    const { isLinkInsertOpen, closeLinkInsertPopup } = useContext(CustomSlateContext);

    usePropagateFocusChange(onFocusChange);

    return (
      <>
        <Editable
          className={cx(styles.root, className)}
          ref={rootRef}
          renderElement={(props) => <CustomElement {...props} />}
          renderLeaf={(props) => <TextLeaf {...props} />}
          readOnly={isTranslated || readOnly}
          {...restProps}
        />

        {isLinkInsertOpen && <LinkInsertPopup onClose={closeLinkInsertPopup} />}
      </>
    );
  }
);

CustomEditable.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onFocusChange: PropTypes.func,
};

CustomEditable.defaultProps = {
  className: '',
  readOnly: false,
  onFocusChange: null,
};
