import gql from 'graphql-tag';

const FRAGMENT_TALKING_POINT = gql`
  fragment TalkingPoint on TalkingPoint {
    talkingPoint
    copyTexts
    rating
  }
`;

export const FRAGMENT_TALKING_POINTS_VIEW = gql`
  fragment TalkingPointsView on TalkingPointsView {
    id
    talkingPoints {
      ...TalkingPoint
    }
    accountId
    name
    accountUrl
    dataSource
    status
    createdAt
  }
  ${FRAGMENT_TALKING_POINT}
`;
