export function parseError(error) {
  if (!error) {
    return { title: '' };
  }

  if (error.networkError) {
    return {
      title: 'Network error',
      description: error.networkError.message,
    };
  }
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return {
      description: error.graphQLErrors.map((gqlError) => gqlError.message).join('\n'),
    };
  }

  return { title: error.message };
}

export const promisedWait = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });

/**
 * Parse JSON string, return defaultValue if failed
 * @param {string} jsonString - JSON string
 * @param {string} [defaultValue] - default value
 * @returns {any} - parsed JSON or defaultValue
 */
export const parseJson = (jsonString, defaultValue = undefined) => {
  if (!jsonString) {
    return defaultValue;
  }

  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return defaultValue;
  }
};
