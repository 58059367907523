import {
  getPlainTextSelection,
  getPlainTextSelectionContext,
  getSelectionEdgePoints,
  slateToHtml,
} from '../../lib';

export const calculateSlateOutput = (editor, slateValue) => {
  const plainText = getPlainTextSelection(editor);
  const plainTextContext = getPlainTextSelectionContext(editor);
  const [selectionStart] = getSelectionEdgePoints(editor);
  const calculatedValues = {
    htmlContent: slateToHtml(slateValue),
    plainText,
    plainTextContext,
    indexesWithinContext: plainText.trim().length
      ? {
          start: selectionStart.offset,
          end: selectionStart.offset + plainText.length,
        }
      : null,
  };
  return calculatedValues;
};
