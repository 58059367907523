import { useMemo } from 'react';

import {
  AUDIENCE_TARGET_TYPE,
  getAudienceTargetEvaluationLabel,
} from 'src/data/audiencesTargeting';

export const useValuePlaceholder = (state) => {
  const { targetType, targetEvaluation } = state;

  return useMemo(() => {
    const label = getAudienceTargetEvaluationLabel(targetEvaluation) || '';

    switch (targetType) {
      case AUDIENCE_TARGET_TYPE.REFERRER_SOURCE:
        return label ? `URL which ${label}` : 'Enter a URL';

      case AUDIENCE_TARGET_TYPE.UTM:
      default:
        return label ? `Term which ${label}` : 'Enter a term';
    }
  }, [targetType, targetEvaluation]);
};
