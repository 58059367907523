import { useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { trim } from 'lodash';

import { mapScoringMethodForMutation } from 'src/data/scoring';
import { extractTargetAudienceInsights } from 'src/graphql/extractTargetAudienceInsights';

const DEFAULT_DEBOUNCE_TIMEOUT = 500;

export const useTextAudienceDebounced = (
  { projectId, generationTool, text, variationId, scoringMethod },
  hookOptions
) => {
  const disableRequests = hookOptions?.disabled || false;
  const debounceTimeout = hookOptions?.debounceTimeout || DEFAULT_DEBOUNCE_TIMEOUT;
  const refreshKey = hookOptions?.refreshKey || 0;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  /**
   * Debounced "fetch" function
   */
  const fetchData = useDebouncedCallback(async (requestParams, requestOptions) => {
    try {
      const result = await extractTargetAudienceInsights(requestParams, requestOptions);
      setData(result);
    } catch (error) {
      // we don't care much about errors here
    } finally {
      setLoading(false);
    }
  }, debounceTimeout);

  /**
   * React to input parameters changes
   */
  useEffect(() => {
    // Clear and do not query, if host component doesn't want it now
    if (disableRequests) {
      setData(null);
      return undefined;
    }

    // Return early, if no "text" to analyze was provided
    if (trim(text).length === 0) {
      setData(null);
      return undefined;
    }

    // Mark as "loading" even when we wait for "debounced" request to fire
    setLoading(true);

    // Schedule debounced request
    const abortController = new AbortController();
    fetchData(
      {
        projectId,
        generationTool,
        text,
        variationId,
        scoringMethod: mapScoringMethodForMutation(scoringMethod),
      },
      {
        abortSignal: abortController.signal,
      }
    );

    // Clean up possible pending requests when we want to schedule new request or component unmounts
    return () => {
      abortController.abort();
      setLoading(false);
    };
  }, [
    disableRequests,
    fetchData,
    projectId,
    generationTool,
    text,
    variationId,
    scoringMethod,
    refreshKey,
  ]);

  return useMemo(
    () => ({
      data,
      loading,
    }),
    [data, loading]
  );
};
