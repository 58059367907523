export const AUDIENCE_TARGET_LIMIT = 10;

export const AUDIENCE_TARGET_TYPE = {
  DEFAULT: 'DEFAULT',
  REFERRER_SOURCE: 'REFERRER_SOURCE',
  UTM: 'UTM',
};

const TARGET_TYPE_TITLE = {
  [AUDIENCE_TARGET_TYPE.DEFAULT]: 'Everyone coming to the website',
  [AUDIENCE_TARGET_TYPE.REFERRER_SOURCE]: 'Referrer source',
  [AUDIENCE_TARGET_TYPE.UTM]: 'UTM',
};

export const TARGET_EVALUATION = {
  IS_EXACTLY: 'EXACT_MATCH',
  CONTAINS: 'CONTAINS',
};

const TARGET_EVALUATION_TITLE = {
  [TARGET_EVALUATION.IS_EXACTLY]: 'is exactly: ',
  [TARGET_EVALUATION.CONTAINS]: 'contains: ',
};

export const getAudienceTargetLabel = (value) => TARGET_TYPE_TITLE[value];

export const getAudienceTargetEvaluationLabel = (value) =>
  TARGET_EVALUATION_TITLE[value];

export const getAudienceTargetTitle = (targeting) => {
  const evaluationLabel = getAudienceTargetEvaluationLabel(
    targeting.targetEvaluation
  );
  return `${TARGET_TYPE_TITLE[targeting.targetType]} ${evaluationLabel || ''}`;
};

export const getAudiencesTargetingDescription = (targeting) => {
  if (
    targeting.targetType === AUDIENCE_TARGET_TYPE.DEFAULT ||
    !targeting.targetEvaluation ||
    !targeting.value
  ) {
    return '';
  }

  return targeting.value;
};

export const getAudiencesTargetingTitle = (targeting) => {
  const typeLabel = getAudienceTargetLabel(targeting.targetType);
  const description = getAudiencesTargetingDescription(targeting);
  if (!description) {
    return typeLabel;
  }

  return `${typeLabel} ${description}`;
};

export const isDefaultAudienceTarget = (target) =>
  target.targetType === AUDIENCE_TARGET_TYPE.DEFAULT;

export const withoutDefaultAudienceTarget = (targets) =>
  targets.filter((segment) => !isDefaultAudienceTarget(segment));
