import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { BRIEF_TYPE } from 'src/data/brief';
import { isContinuousOptimizationType } from 'src/data/project';
import { generateProjectName } from 'src/data/project/utils';

const MUTATION = gql`
  mutation CreateProject(
    $customerId: ID!
    $type: String!
    $name: String
    $briefType: String!
    $workspaceId: ID
    $briefId: ID
    $url: String
    $projectFolderId: ID
  ) {
    createProject(
      customerId: $customerId
      type: $type
      name: $name
      briefType: $briefType
      workspaceId: $workspaceId
      briefId: $briefId
      url: $url
      projectFolderId: $projectFolderId
    ) {
      id
    }
  }
`;

export const createProject = ({
  customerId,
  projectType,
  brief,
  briefId,
  workspaceId,
  url,
  projectFolderId,
}) => {
  // Common params, present in all project/brief types
  const variables = {
    customerId,
    type: projectType,
    briefType: brief.type,
    workspaceId,
    briefId,
    projectFolderId,
  };

  // Determine what "project name" will be
  variables.name = generateProjectName(brief);

  if (brief.type === BRIEF_TYPE.unifiedProduct) {
    if (isContinuousOptimizationType(projectType)) {
      variables.url = url;
    }
  }

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
