import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_TALKING_POINTS_VIEW } from './schema';

const QUERY = gql`
  query CustomerTalkingPointsViewList($customerId: ID!, $workspaceId: ID) {
    customerTalkingPointsViewList(customerId: $customerId, workspaceId: $workspaceId) {
      ...TalkingPointsView
    }
  }
  ${FRAGMENT_TALKING_POINTS_VIEW}
`;

export const queryTalkingPointsViewList = async ({ customerId, workspaceId }) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: { customerId, workspaceId },
  });
  return getSingleProp(result.data);
};
