const NAME = 'ChannelAuthError';

export class ChannelAuthError extends Error {
  constructor(message = '', code = null) {
    super(message);
    this.name = NAME;
    this.message = message;
  }
}

export const isChannelAuthError = (error) =>
  error instanceof ChannelAuthError || error?.name === NAME;
