import React, { memo, useCallback, useState, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { CustomMenu } from 'src/components/CustomMenu';
import { CustomMenuItem } from 'src/components/CustomMenuItem';
import { CustomButton } from 'src/components/CustomButton';
import { useStyles } from './useStyles';

const MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  getContentAnchorEl: null,
};

export const ContextMenu = memo(
  forwardRef(
    (
      {
        className,
        classes,
        items,
        dataTestId,
        renderButton,
        renderItems,
        onOpen,
        onClose,
        disabled,
        horizontal,
        small,
        menuProps,
        displayCloseButton,
        title,
      },
      ref
    ) => {
      const styles = useStyles({ classes });

      const [anchorEl, setAnchorEl] = useState(null);
      const [open, setOpen] = useState(false);

      const openMenu = useCallback(() => {
        setOpen(true);

        if (onOpen) {
          onOpen();
        }
      }, [onOpen]);

      const closeMenu = useCallback(() => {
        setOpen(false);

        if (onClose) {
          onClose();
        }
      }, [onClose]);

      const handleButtonClick = useCallback(
        (event) => {
          event?.stopPropagation();
          openMenu();
        },
        [openMenu]
      );

      const handleClose = useCallback(
        (event) => {
          event?.stopPropagation();
          closeMenu();
        },
        [closeMenu]
      );

      const handleMenuClick = useCallback((event) => {
        event?.stopPropagation();
      }, []);

      const handleMenuItemClick = useCallback(
        (event) => {
          event?.stopPropagation();
          closeMenu();
        },
        [closeMenu]
      );

      useImperativeHandle(ref, () => ({
        openMenu,
      }));

      return (
        <>
          {renderButton ? (
            renderButton({ ref: setAnchorEl, onClick: handleButtonClick, open })
          ) : (
            <IconButton
              className={cx(
                styles.root,
                {
                  [styles.horizontal]: horizontal,
                  [styles.small]: small,
                },
                className
              )}
              ref={setAnchorEl}
              onClick={handleButtonClick}
              disabled={disabled}
              data-testid={`${dataTestId ? dataTestId + '_' : undefined}ContextMenu_MoreButton`}
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
            >
              <MoreVertIcon />
            </IconButton>
          )}

          <CustomMenu
            classes={{ paper: styles.menuRoot }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl) && open}
            onClick={handleMenuClick}
            onClose={handleClose}
            disabled={disabled}
            {...MENU_PROPS}
            {...menuProps}
          >
            {displayCloseButton && (
              <CustomButton
                className={styles.buttonClose}
                onClick={handleClose}
                variant="text"
                color="primary"
              >
                Close
              </CustomButton>
            )}

            {title && <div className={styles.title}>{title}</div>}

            {renderItems
              ? renderItems(handleClose)
              : items.map((item) => (
                  <CustomMenuItem
                    key={item.id}
                    data-testid={'contextMenuItem_' + item.id}
                    item={item}
                    onClick={handleMenuItemClick}
                  />
                ))}
          </CustomMenu>
        </>
      );
    }
  )
);

ContextMenu.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    menuRoot: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.shape({}),
      label: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    })
  ),
  renderItems: PropTypes.func,
  renderButton: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  horizontal: PropTypes.bool,
  small: PropTypes.bool,
  menuProps: PropTypes.shape(),
  displayCloseButton: PropTypes.bool,
  title: PropTypes.string,
};

ContextMenu.defaultProps = {
  className: null,
  dataTestId: null,
  classes: {},
  items: [],
  renderButton: null,
  renderItems: null,
  onOpen: null,
  onClose: null,
  disabled: false,
  horizontal: false,
  small: false,
  menuProps: null,
  displayCloseButton: false,
  title: null,
};
