import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation AcceptUserInvitation($token: String!, $password: String, $name: String) {
    acceptUserInvitation(token: $token, password: $password, name: $name) {
      token
      customerId
    }
  }
`;

export const acceptUserInvitation = ({ token, password, name }) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { token, password, name },
  }).then(({ data }) => getSingleProp(data));
