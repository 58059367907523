import { createStateSelectorHook } from 'src/lib/sweetState';
import { CustomModelsStore, PerformanceStore } from 'src/store';

const usePerformanceState = createStateSelectorHook(PerformanceStore, [
  'avgCompletionPercent',
  'runningTalkingPointsViewAccountIds',
]);

const useCustomModelsState = createStateSelectorHook(CustomModelsStore, ['runningCustomModels']);

export const useViewState = () => {
  const performanceState = usePerformanceState();
  const customModelsState = useCustomModelsState();

  return {
    ...performanceState,
    ...customModelsState,
  };
};
