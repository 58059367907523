import { useCallback } from 'react';

import { usePopups } from '../services';
import { SHARE_POPUP } from '../types';

export const useSharePopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ projectId, shareDocumentId }) => {
      popups.openPopup(SHARE_POPUP, {
        projectId,
        shareDocumentId,
        onClose: () => {
          popups.closePopup(SHARE_POPUP);
        },
      });
    },
    [popups]
  );
};
