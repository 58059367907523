import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation PerformanceCenterRefreshAccount(
    $accountId: ID!
    $customerId: ID!
    $dataSource: PerformanceCenterDataSource!
  ) {
    performanceCenterRefreshAccount(
      accountId: $accountId
      customerId: $customerId
      dataSource: $dataSource
    ) {
      id
      status
    }
  }
`;

export const performanceCenterRefreshAccount = ({ accountId, customerId, dataSource }) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: {
      accountId,
      customerId,
      dataSource,
    },
  }).then(({ data }) => getSingleProp(data));
};
