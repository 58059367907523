import { EXTERNAL_MESSAGE, INCOMING_MESSAGE, OUTGOING_MESSAGE } from './const';

/**
 * Check if the message type is supported as "incoming" message from Extension
 * @param {INCOMING_MESSAGE} messageType - message type
 * @returns {boolean} - true if the message type is supported
 */
export const isSupportedIncomingMessage = (messageType) =>
  Object.values(INCOMING_MESSAGE).includes(messageType);

export const messagePlatformReady = () => ({
  type: OUTGOING_MESSAGE.platformReady,
});

export const messageFrameViewReady = () => ({
  type: OUTGOING_MESSAGE.frameViewReady,
});

export const messagePlatformInfo = (content) => ({
  type: OUTGOING_MESSAGE.platformInfo,
  content,
});

export const messageSubmitInstructions = (content) => ({
  type: OUTGOING_MESSAGE.submitInstructions,
  content,
});

export const messageDisplayFullscreen = (fullscreen) => ({
  type: OUTGOING_MESSAGE.displayFullscreen,
  content: {
    fullscreen,
  },
});

export const messageCloseFrame = () => ({
  type: OUTGOING_MESSAGE.closeFrame,
});

export const messageScoreTextResult = ({
  userFlow,
  text,
  score,
  generationTool,
  error = undefined,
}) => ({
  type: OUTGOING_MESSAGE.scoreTextResult,
  content: {
    userFlow,
    text,
    score,
    generationTool,
    error,
  },
});

export const messageDisplaySuggestionInsights = ({ generationTool, text }) => ({
  type: OUTGOING_MESSAGE.displaySuggestionInsights,
  content: {
    generationTool,
    text,
  },
});

export const messageDisplayVariationInsights = ({
  generationTool,
  assetType,
  text,
  additionalScoringParams,
}) => ({
  type: OUTGOING_MESSAGE.displayVariationInsights,
  content: {
    generationTool,
    assetType,
    text,
    additionalScoringParams,
  },
});

export const messageApplySuggestion = ({ assetType, text, keepFrame }) => ({
  type: OUTGOING_MESSAGE.applySuggestion,
  content: {
    assetType,
    text,
    keepFrame,
  },
});

export const messageClassifyTextResult = ({ generationTool }) => ({
  type: OUTGOING_MESSAGE.classifyTextResult,
  content: {
    generationTool,
  },
});

export const messageDictionaryBriefs = (content) => ({
  type: OUTGOING_MESSAGE.dictionaryBriefs,
  content,
});

export const messageDictionaryResources = (content) => ({
  type: OUTGOING_MESSAGE.dictionaryResources,
  content,
});

export const messageDictionaryWorkspaces = (content) => ({
  type: OUTGOING_MESSAGE.dictionaryWorkspaces,
  content,
});

export const messageImportedBrief = (content) => ({
  type: OUTGOING_MESSAGE.importedBrief,
  content,
});

export const messageSwitchVariationsGenerationVariant = ({ optionsVariant, temporary }) => ({
  type: OUTGOING_MESSAGE.switchVariationsGenerationVariant,
  content: {
    optionsVariant,
    temporary,
  },
});

export const externalMessageLogin = (token) => ({
  type: EXTERNAL_MESSAGE.login,
  content: { token },
});

export const externalMessageLogout = () => ({
  type: EXTERNAL_MESSAGE.logout,
});

export const externalMessageWorkspaceData = ({ customerId, workspaceId }) => ({
  type: EXTERNAL_MESSAGE.activateWorkspace,
  content: { customerId, workspaceId },
});
