import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_BRAND_TERM } from './schema';

const MUTATION = gql`
  mutation UpdateBrandTerm(
    $id: ID!
    $term: String
    $approvalType: BrandTermApprovalTypeEnum!
    $replaceWith: String
    $partOfSpeech: BrandTermPartOfSpeechEnum
    $caseSensitive: Boolean
    $capitalizeAtStart: Boolean
    $consistentHyphenation: Boolean
    $commonMistakes: [String!]
    $description: String
    $tags: [String!]
  ) {
    updateBrandTerm(
      id: $id
      term: $term
      approvalType: $approvalType
      replaceWith: $replaceWith
      partOfSpeech: $partOfSpeech
      caseSensitive: $caseSensitive
      capitalizeAtStart: $capitalizeAtStart
      consistentHyphenation: $consistentHyphenation
      commonMistakes: $commonMistakes
      description: $description
      tags: $tags
    ) {
      ...BrandTerm
    }
  }
  ${FRAGMENT_BRAND_TERM}
`;

export const updateBrandTerm = ({
  id,
  term,
  approvalType,
  replaceWith,
  partOfSpeech,
  caseSensitive,
  capitalizeAtStart,
  consistentHyphenation,
  commonMistakes,
  description,
  tags,
}) => {
  const variables = {
    id,
    term,
    approvalType,
    replaceWith,
    partOfSpeech,
    caseSensitive,
    capitalizeAtStart,
    consistentHyphenation,
    commonMistakes,
    description,
    tags,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
