import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';
import { getCircleSize } from './utils';
import { SCORE_SIZE } from './const';

const COLOR_BG = '#E6EAF6';
const COLOR_LOW = '#D43434';
const COLOR_MEDIUM = '#FFCA00';
const COLOR_HIGH = COLORS.success.active;

export default makeStyles(
  (theme) => ({
    root: {
      position: 'relative',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      [`&[data-size="${SCORE_SIZE.small}"]`]: {
        width: getCircleSize(SCORE_SIZE.small),
        height: getCircleSize(SCORE_SIZE.small),

        '& $score': {
          fontSize: theme.typography.pxToRem(9),
          lineHeight: getCircleSize(SCORE_SIZE.small),
        },

        '& $diamondIcon': {
          width: getCircleSize(SCORE_SIZE.small),
          height: getCircleSize(SCORE_SIZE.small),
        },
      },

      [`&[data-size="${SCORE_SIZE.tableSmall}"]`]: {
        width: getCircleSize(SCORE_SIZE.tableSmall),
        height: getCircleSize(SCORE_SIZE.tableSmall),

        '& $score': {
          fontSize: theme.typography.pxToRem(10),
          lineHeight: getCircleSize(SCORE_SIZE.tableSmall),
        },

        '& $diamondIcon': {
          width: getCircleSize(SCORE_SIZE.tableSmall),
          height: getCircleSize(SCORE_SIZE.tableSmall),
        },
      },

      [`&[data-size="${SCORE_SIZE.medium}"]`]: {
        width: getCircleSize(SCORE_SIZE.medium),
        height: getCircleSize(SCORE_SIZE.medium),

        '& $score': {
          fontSize: theme.typography.pxToRem(12),
          lineHeight: getCircleSize(SCORE_SIZE.medium),
        },

        '& $diamondIcon': {
          width: getCircleSize(SCORE_SIZE.medium),
          height: getCircleSize(SCORE_SIZE.medium),
        },
      },

      [`&[data-size="${SCORE_SIZE.large}"]`]: {
        width: getCircleSize(SCORE_SIZE.large),
        height: getCircleSize(SCORE_SIZE.large),

        '& $score': {
          fontSize: theme.typography.pxToRem(15),
          lineHeight: getCircleSize(SCORE_SIZE.large),
        },

        '& $diamondIcon': {
          width: getCircleSize(SCORE_SIZE.large),
          height: getCircleSize(SCORE_SIZE.large),
        },
      },

      [`&[data-size="${SCORE_SIZE.extraLarge}"]`]: {
        width: getCircleSize(SCORE_SIZE.extraLarge),
        height: getCircleSize(SCORE_SIZE.extraLarge),

        '& $score': {
          fontSize: theme.typography.pxToRem(14),
          lineHeight: getCircleSize(SCORE_SIZE.extraLarge),
        },

        '& $diamondIcon': {
          width: getCircleSize(SCORE_SIZE.extraLarge),
          height: getCircleSize(SCORE_SIZE.extraLarge),
        },
      },

      [`&[data-size="${SCORE_SIZE.xxl}"]`]: {
        width: getCircleSize(SCORE_SIZE.xxl),
        height: getCircleSize(SCORE_SIZE.xxl),

        '& $score': {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: getCircleSize(SCORE_SIZE.xxl),
        },

        '& $diamondIcon': {
          width: getCircleSize(SCORE_SIZE.xxl),
          height: getCircleSize(SCORE_SIZE.xxl),
        },
      },

      [`&[data-size="${SCORE_SIZE.xxxl}"]`]: {
        width: getCircleSize(SCORE_SIZE.xxxl),
        height: getCircleSize(SCORE_SIZE.xxxl),

        '& $score': {
          fontSize: theme.typography.pxToRem(16),
          lineHeight: getCircleSize(SCORE_SIZE.xxxl),
        },

        '& $diamondIcon': {
          width: getCircleSize(SCORE_SIZE.xxxl),
          height: getCircleSize(SCORE_SIZE.xxxl),
        },
      },

      '&$clickable': {
        cursor: 'pointer',
      },

      '&[data-score="low"] $circleCircle': {
        stroke: COLOR_LOW,
      },
      '&[data-score="medium"] $circleCircle': {
        stroke: COLOR_MEDIUM,
      },
      '&[data-score="high"] $circleCircle': {
        stroke: COLOR_HIGH,
      },
    },
    clickable: {},
    circleBgRoot: {
      position: 'absolute',
    },
    circleBgCircle: {
      stroke: COLOR_BG,
      strokeWidth: ({ strokeWidth }) => strokeWidth || '3px',
    },
    circleRoot: {
      position: 'absolute',
    },
    circleCircle: {
      strokeWidth: ({ strokeWidth }) => strokeWidth || '3px',
      strokeLinecap: 'round',
    },
    circleLoading: {
      position: 'absolute',

      '& circle': {
        strokeWidth: ({ strokeWidth }) => strokeWidth || '3px',
      },
    },

    circleLoadingGray: {
      color: COLORS.gray.gray60,
    },

    score: {
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.neutral.secondary,
    },

    scoreHidden: {
      visibility: 'hidden',
    },

    locked: {},
    diamondIcon: {
      position: 'absolute',
      fill: 'none',
    },
  }),
  { name: 'Score' }
);
