import { isNaN, uniqueId } from 'lodash';

import { AUDIENCE_CHART_ITEMS_COUNT } from 'src/data/demographics';
import { CREATE_PERSONA_ID } from './const';

const RADIAN = Math.PI / 180;
const eps = 1e-5;

/**
 * Convert polar coordinates to cartesian
 * @param {number} cx - center x
 * @param {number} cy - center y
 * @param {number} radius - radius
 * @param {number} angle - angle
 * @returns {{x: number, y: number}} - cartesian coordinates
 */
export const polarToCartesian = (cx, cy, radius, angle) => ({
  x: cx + Math.cos(-RADIAN * angle) * radius,
  y: cy + Math.sin(-RADIAN * angle) * radius,
});

/**
 * Get label horizontal anchor for polar chart
 * @param {number} angle - angle
 * @returns {string} - anchor
 */
export const getPolarHorizontalLabelAnchor = (angle) => {
  const cos = Math.cos(-angle * RADIAN);

  if (cos > eps) {
    return 'start';
  } else if (cos < -eps) {
    return 'end';
  }
  return 'center';
};

/**
 * Get label vertical anchor for polar chart
 * @param {number} angle - angle
 * @returns {string} - anchor
 */
export const getPolarVerticalLabelAnchor = (angle) => {
  const sin = Math.sin(-angle * RADIAN);

  if (sin > eps) {
    return 'center';
  } else if (sin < -eps) {
    return 'start';
  }
  return 'end';
};

/**
 * Get axis ticks for polar chart
 * @param axis - polar axis
 * @returns {[number]} - ticks
 */
export const getPolarAxisTicks = (axis) => {
  if (!axis) {
    return [];
  }

  if (axis.ticks || axis.niceTicks) {
    return (axis.ticks || axis.niceTicks)
      .map((entry) => axis.scale(entry))
      .filter((value) => !isNaN(value));
  }

  return axis.scale.domain().map((entry) => axis.scale(entry));
};

/**
 * Prepare data for radar chart
 * @param audiencesData - audiences data
 * @param loading - loading flag
 * @returns {*}
 */
export const prepareChartData = (audiencesData, loading) => {
  if (loading) {
    return fillLoadingData();
  }

  const chartData = convertDataToChartData(audiencesData);
  const prepared = fillEmptyData(chartData);
  adjustDataForSinglePersona(prepared);
  return prepared;
};

/**
 * Create data item for radar chart
 * @param {object} persona - persona object
 * @param {number} score - score value
 * @returns {{id: string, name: string, score: number, persona: Object }} - data item
 */
const createDataItem = (persona = null, score = 0) => {
  const id = persona ? persona.id : uniqueId('empty_item_');
  return {
    id,
    name: persona?.name || id,
    score,
    persona,
  };
};

const createAddNewItem = () => ({
  id: CREATE_PERSONA_ID,
  score: 0,
  persona: {
    id: CREATE_PERSONA_ID,
  },
});

const fillEmptyData = (data) => {
  if (data.length >= AUDIENCE_CHART_ITEMS_COUNT) {
    return data;
  }

  const emptyItems = Array(AUDIENCE_CHART_ITEMS_COUNT - data.length)
    .fill(null)
    .map(() => createDataItem());
  if (emptyItems.length < AUDIENCE_CHART_ITEMS_COUNT) {
    emptyItems[emptyItems.length - 1] = createAddNewItem();
  }
  return [...data, ...emptyItems];
};

const fillLoadingData = () =>
  Array(AUDIENCE_CHART_ITEMS_COUNT)
    .fill(null)
    .map(() => createDataItem(null, 100));

const convertDataToChartData = (audiencesData) => {
  if (!audiencesData) {
    return [];
  }

  return audiencesData.map((audience) => {
    const { persona, score } = audience;
    return createDataItem(persona, score);
  });
};

const adjustDataForSinglePersona = (data) => {
  const hasOnlySinglePersona = data.filter((item) => !!item?.persona).length === 1;
  if (!hasOnlySinglePersona) {
    return;
  }

  data[1].score = 10;
  data[data.length - 1].score = 10;
};
