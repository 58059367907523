import { useCallback } from 'react';

import { useUiActions, useUserActions } from 'src/store';
import { useTrackingEvent } from 'src/services';
import * as events from 'src/lib/events';
import { ONBOARDING_STAGE } from 'src/data/onboarding';

const STAGE = ONBOARDING_STAGE.webinarBanner2;
const MAX_BANNER_DISPLAY_COUNT = 4;

export const useHandlers = () => {
  const uiActions = useUiActions();
  const userActions = useUserActions();
  const trackEvent = useTrackingEvent();

  const handleClose = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const count = Number(userActions.getOnboardingStageValue(STAGE)) || 0;

      uiActions.setShowWebinarBanner(false);
      userActions.updateOnboardingStageValue(STAGE, String(count + 1));

      if (count >= MAX_BANNER_DISPLAY_COUNT) {
        userActions.finishOnboardingStage(STAGE);
      }
    },
    [uiActions, userActions]
  );

  const handleClick = useCallback(() => {
    trackEvent(events.DASHBOARD.clickedWebinarBanner);
  }, [trackEvent]);

  return { handleClose, handleClick };
};
