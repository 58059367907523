import { UniqMap } from 'src/lib/UniqMap';
import { GENERATION_TOOL_GROUP } from './const';

const GROUP_PROPS = new UniqMap();
GROUP_PROPS.add(GENERATION_TOOL_GROUP.generalWriting, {
  title: 'General Writing',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.ads, {
  title: 'Ads',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.landingPage, {
  title: 'Website',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.social, {
  title: 'Social',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.email, {
  title: 'Email',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.ecommerce, {
  title: 'Ecommerce',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.anyCopy, {
  title: 'General Prompt',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.articleBlog, {
  title: 'Blog',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.boost, {
  title: 'Content Improver',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.campaigns, {
  title: 'Campaigns',
});
GROUP_PROPS.add(GENERATION_TOOL_GROUP.customMultiPrompt, {
  title: 'Saved Templates',
});

const getGroupProp = (type, propName, defaultValue = null) => {
  const props = GROUP_PROPS.get(type);
  if (props && props[propName] != null) {
    return props[propName];
  }
  return defaultValue;
};

export const getToolGroupTitle = (toolGroup, defaultValue = '< group title not found >') =>
  getGroupProp(toolGroup, 'title', defaultValue);
