import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 692,
      maxWidth: 692,
    },
    dialogContent: {
      padding: theme.spacing(2, 4, 3),
    },
    icon: {
      fontSize: theme.typography.pxToRem(48),
      marginBottom: theme.spacing(2),
    },
    upgradeContainer: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2, 3),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: COLORS.primary.silver,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
    },
    default: {
      marginTop: theme.spacing(4),
    },
  }),
  { name: 'CustomModelSuccessPopup' }
);
