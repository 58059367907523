import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: 'white',
    },
    container: {
      display: 'flex',
      columnGap: theme.spacing(2),
      alignItems: 'center',
    },
    score: {
      fontWeight: theme.typography.fontWeightSemiBold + '!important',
    },
    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.gray.black,
      maxWidth: 420,
    },
    button: {
      verticalAlign: 'baseline',
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
    },
    settingsIcon: {
      fontSize: theme.typography.pxToRem(16),
      margin: theme.spacing(0.5),
      cursor: 'pointer',
      color: COLORS.gray.gray40,
      marginLeft: 'auto',
    },
  }),
  { name: 'ScoringMethod' }
);
