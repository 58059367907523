import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation GenerateFullBlogPost(
    $projectId: ID!
    $maxWords: Int
    $debugAcPrompt: String
    $customerPersonaId: ID
    $tovInput: TovInput
  ) {
    generateFullBlogPost(
      projectId: $projectId
      maxWords: $maxWords
      debugAcPrompt: $debugAcPrompt
      customerPersonaId: $customerPersonaId
      tovInput: $tovInput
    ) {
      id
    }
  }
`;

export const generateFullBlogPost = ({
  projectId,
  maxWords,
  debugAcPrompt,
  customerPersona,
  tovInput,
}) => {
  const variables = {
    projectId,
    maxWords,
    debugAcPrompt,
    customerPersonaId: customerPersona?.id,
    tovInput,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
