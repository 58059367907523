import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconClearFormatting = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26172 1.65234C2.2263 1.65234 2.19123 1.65932 2.15852 1.67288C2.1258 1.68644 2.09608 1.70632 2.07105 1.73137C2.04602 1.75643 2.02617 1.78617 2.01264 1.8189C1.9991 1.85162 1.99216 1.8867 1.99219 1.92211L1.99219 2.26172C1.99219 2.67593 1.6564 3.01172 1.24219 3.01172C0.827974 3.01172 0.492188 2.67593 0.492188 2.26172V1.92307C0.492188 1.92297 0.492188 1.92316 0.492188 1.92307C0.492069 1.69078 0.537684 1.46046 0.626434 1.24579C0.715269 1.03092 0.845579 0.835666 1.00992 0.671183C1.17425 0.506701 1.36939 0.376217 1.58419 0.287192C1.79898 0.198166 2.02921 0.152344 2.26172 0.152344H9.73828C9.97079 0.152344 10.201 0.198166 10.4158 0.287192C10.6306 0.376217 10.8257 0.506701 10.9901 0.671183C11.1544 0.835667 11.2847 1.03092 11.3736 1.24579C11.4623 1.46046 11.5079 1.6905 11.5078 1.92278C11.5078 1.92256 11.5078 1.923 11.5078 1.92278M11.5078 1.92278V2.26262C11.5078 2.67684 11.172 3.01262 10.7578 3.01262C10.3436 3.01262 10.0078 2.67684 10.0078 2.26262V1.92278C10.0078 1.88737 10.0009 1.85162 9.98737 1.8189C9.97383 1.78617 9.95399 1.75643 9.92895 1.73137C9.90392 1.70632 9.8742 1.68644 9.84148 1.67288C9.80877 1.65932 9.7737 1.65234 9.73828 1.65234H2.26172"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0.75C6.41421 0.75 6.75 1.08579 6.75 1.5V11.0969C6.75 11.5111 6.41421 11.8469 6 11.8469C5.58579 11.8469 5.25 11.5111 5.25 11.0969V1.5C5.25 1.08579 5.58579 0.75 6 0.75Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.21094 11.0977C3.21094 10.6834 3.54672 10.3477 3.96094 10.3477H8.03906C8.45328 10.3477 8.78906 10.6834 8.78906 11.0977C8.78906 11.5119 8.45328 11.8477 8.03906 11.8477H3.96094C3.54672 11.8477 3.21094 11.5119 3.21094 11.0977Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3956 11.7893C11.0974 12.0768 10.6226 12.0681 10.3351 11.7699L0.211357 1.26893C-0.0761303 0.970729 -0.0674456 0.495935 0.230755 0.208447C0.528956 -0.0790405 1.00375 -0.0703558 1.29124 0.227845L11.415 10.7288C11.7025 11.027 11.6938 11.5018 11.3956 11.7893Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconClearFormatting.displayName = 'IconClearFormatting';
