import { createStateSelectorHook } from 'src/lib/sweetState';
import { ProjectStore, useCurrentCustomerId, useCurrentWorkspaceId } from 'src/store';

const useProjectState = createStateSelectorHook(ProjectStore, [
  'loadingProjects',
  'loadedProjects',
]);

export const useIsLoadingProjects = () => {
  const currentCustomerId = useCurrentCustomerId();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const { loadingProjects, loadedProjects } = useProjectState();

  return loadingProjects || !loadedProjects || !currentCustomerId || !currentWorkspaceId;
};
