import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconRedo = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.625 0.623047C12.2108 0.623047 11.875 0.958833 11.875 1.37305V4.37305H8.875C8.46079 4.37305 8.125 4.70883 8.125 5.12305C8.125 5.53726 8.46079 5.87305 8.875 5.87305H12.625C13.0392 5.87305 13.375 5.53726 13.375 5.12305V1.37305C13.375 0.958833 13.0392 0.623047 12.625 0.623047Z"
        fill="#444444"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8716 0.683371C6.71376 0.522951 5.5341 0.683957 4.4616 1.14878C3.38909 1.61361 2.46504 2.36437 1.79045 3.31897C1.11587 4.27357 0.716724 5.39527 0.636666 6.56142C0.556608 7.72758 0.798715 8.8933 1.33652 9.93113C1.87432 10.969 2.68711 11.839 3.68603 12.446C4.68495 13.053 5.83154 13.3737 7.00044 13.373C7.41465 13.3728 7.75024 13.0368 7.75 12.6226C7.74975 12.2084 7.41377 11.8728 6.99956 11.873C6.10569 11.8736 5.22889 11.6283 4.46501 11.1641C3.70113 10.6999 3.07958 10.0346 2.66832 9.241C2.25706 8.44736 2.07192 7.55593 2.13314 6.66416C2.19436 5.77239 2.49959 4.91462 3.01545 4.18464C3.53131 3.45465 4.23794 2.88054 5.05809 2.52508C5.87824 2.16963 6.78033 2.0465 7.66574 2.16918C8.55115 2.29185 9.38579 2.6556 10.0784 3.22065C10.771 3.7857 11.2949 4.5303 11.5929 5.37305C11.731 5.76357 12.1595 5.96823 12.55 5.83016C12.9405 5.69208 13.1452 5.26357 13.0071 4.87305C12.6175 3.771 11.9323 2.79729 11.0266 2.05837C10.1209 1.31946 9.02944 0.843791 7.8716 0.683371Z"
        fill="#444444"
      />
    </SvgIcon>
  ))
);

IconRedo.displayName = 'IconRedo';
