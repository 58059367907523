import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  () => ({
    buttonUpdate: {
      height: 'auto',
      padding: '10px 8px',
      textTransform: 'uppercase',
      lineHeight: '1',
    },
  }),
  { name: 'BuildRelevanceChecker' }
);
