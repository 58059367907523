import { useCallback } from 'react';

import { useCustomerHasLiveScoring } from 'src/store';
import { CONFIRM_POPUP, usePopups, useUpgradePopup } from 'src/services/popup';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { CONFIRM_POPUP_MODE } from 'src/components/ConfirmPopup';
import { usePopupCloseHandlerContextValue } from './services';
import { usePopupActions } from './store';

export const useHandlers = ({ onSubmit, onApplyAll }) => {
  const popupActions = usePopupActions();
  const popups = usePopups();
  const handleClose = usePopupCloseHandlerContextValue();
  const openUpgradePopup = useUpgradePopup();
  const customerHasLiveScoring = useCustomerHasLiveScoring();

  const handleConfirm = useCallback(async () => {
    if (!customerHasLiveScoring) {
      openUpgradePopup(FEATURE_LIMITS.liveScoring);
      return;
    }

    const scoringMethod = popupActions.getScoringMethod();

    if (!scoringMethod) return;

    onSubmit(scoringMethod);
  }, [customerHasLiveScoring, openUpgradePopup, popupActions, onSubmit]);

  const handleChange = useCallback(
    (id) => {
      popupActions.selectTaxonomy(id);
    },
    [popupActions]
  );

  const handleApplyAll = useCallback(() => {
    if (!customerHasLiveScoring) {
      openUpgradePopup(FEATURE_LIMITS.liveScoring);
      return;
    }
    const scoringMethod = popupActions.getScoringMethod();

    if (!scoringMethod) return;

    popups.openPopup(CONFIRM_POPUP, {
      title: 'Confirm data set change for all the scores in this document?',
      content: 'This will result in the loss of existing scores. Are you sure?',
      confirm: 'Confirm',
      cancel: 'Cancel',
      mode: CONFIRM_POPUP_MODE.confirmation,
      onConfirm: async () => {
        popups.closePopup(CONFIRM_POPUP);
        popupActions.setIsLoading(true);
        await onApplyAll(scoringMethod);
        popupActions.setIsLoading(false);
        handleClose();
      },
      onCancel: () => popups.closePopup(CONFIRM_POPUP),
    });
  }, [customerHasLiveScoring, openUpgradePopup, popupActions, onApplyAll, popups, handleClose]);

  return {
    handleConfirm,
    handleClose,
    handleChange,
    handleApplyAll,
  };
};
