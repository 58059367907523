import { useCallback } from 'react';

import { useUserActions } from 'src/store';
import { useErrorHandler, useTrackingEvent } from 'src/services';
import { ONBOARDING_STAGE } from 'src/data/onboarding';
import * as events from 'src/lib/events';

export const useHandlers = ({ setIsOpen }) => {
  const userActions = useUserActions();
  const trackEvent = useTrackingEvent();
  const handleError = useErrorHandler();

  const handleClose = useCallback(async () => {
    try {
      await userActions.finishOnboardingStage(ONBOARDING_STAGE.g2ReviewBoostClaimed);
      trackEvent(events.HOME.confirmG2ReviewBoostClaimedClick, {
        page: events.EVENT_PAGE.homePage,
      });
      setIsOpen(false);
    } catch (error) {
      handleError(error);
    }
  }, [setIsOpen, userActions, trackEvent, handleError]);

  return { handleClose };
};
