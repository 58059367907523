import { useCurrentCustomer } from './useCurrentCustomer';
import { BILLING_CYCLES, getCustomerBillingCyclePeriod } from 'src/data/subscription';
import { useHasActivePaidSubscription } from './useHasActivePaidSubscription';

export const useIsCurrentCustomerOnAnnualBillingCycle = () => {
  const currentCustomer = useCurrentCustomer();
  const billingCycle = getCustomerBillingCyclePeriod(currentCustomer);
  const isAnnualBillingCycle = billingCycle === BILLING_CYCLES.annually;
  const hasPaidSubscription = useHasActivePaidSubscription();

  return hasPaidSubscription && isAnnualBillingCycle;
};
