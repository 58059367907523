import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  dialogTitle: {
    paddingBottom: '0',
  },
  title: {
    textAlign: 'center',
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',

    padding: '30px',
    '&:first-child': {
      padding: '30px',
    },
  },
}));
