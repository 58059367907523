import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { CustomTypography } from 'src/components/CustomTypography';
import {
  AGE_GROUP_APPEAL,
  getAgeGroupAppealTitle,
  getAgeHistogramAppeal,
} from 'src/data/demographics';
import { HistogramAge } from 'src/components/HistogramAge';
import { Loader } from './components/Loader';
import { InsightBox } from '../InsightBox';
import { useStyles } from './useStyles';

export const HistogramAgeScore = ({ className, classes, loading, score, noneAppeal, data }) => {
  const styles = useStyles({ classes });

  const ageAppeal = useMemo(() => getAgeHistogramAppeal(data || []), [data]);
  const ageTitle = getAgeGroupAppealTitle(score, noneAppeal, ageAppeal);

  return (
    <InsightBox className={cx(styles.root, className)}>
      <CustomTypography
        className={styles.title}
        variant="medium12"
      >
        {ageTitle}
      </CustomTypography>

      {loading ? (
        <Loader />
      ) : (
        <HistogramAge
          data={data}
          margin={{ left: 0, right: 0, bottom: 0, top: 0 }}
          forcedAppeal={noneAppeal ? AGE_GROUP_APPEAL.none : null}
          xAxisProps={{ padding: { left: 0, right: 0 } }}
          hideGrid
          displayHighlighting
        />
      )}
    </InsightBox>
  );
};

HistogramAgeScore.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  loading: PropTypes.bool,
  noneAppeal: PropTypes.bool,
  score: PropTypes.number,
};

HistogramAgeScore.defaultProps = {
  className: null,
  classes: null,
  noneAppeal: null,
  data: [],
  loading: false,
  score: 0,
};
