import { useEffect, useState } from 'react';
import { initial, pull } from 'lodash';

const findById = (list, searchId) => list.find(({ id }) => id === searchId);

export const useVisiblePersonas = ({
  loading,
  personas,
  suggestedPersonas,
  selectedPersona,
  visibleCount,
}) => {
  const [visible, setVisible] = useState([]);
  const [merged, setMerged] = useState([]);

  useEffect(() => {
    setMerged([...personas, ...(suggestedPersonas || [])]);
  }, [personas, suggestedPersonas, visibleCount]);

  useEffect(() => {
    if (loading) {
      return;
    }

    setVisible((visible) => {
      const newList = Array(visible.length);

      merged.forEach((possiblyNewPersona) => {
        const existingIndex = visible.findIndex(({ id }) => id === possiblyNewPersona.id);
        if (existingIndex !== -1) {
          //keep updated personas in the same place
          newList[existingIndex] = possiblyNewPersona;
        } else {
          //new personas go to the end
          newList.push(possiblyNewPersona);
        }
      });

      //removed personas are removed from the local list
      pull(newList, undefined);

      return newList.slice(0, visibleCount);
    });
  }, [merged, loading, visibleCount]);

  useEffect(() => {
    if (loading) {
      setVisible((visible) => {
        const placeholders = Array(visibleCount).fill({ isPlaceholder: true });
        return [...visible, ...placeholders].slice(0, visibleCount);
      });
    }
  }, [loading, visibleCount]);

  useEffect(() => {
    if (selectedPersona?.id && visible.length) {
      const hiddenSelected = visible.findIndex(({ id }) => id === selectedPersona.id) === -1;
      const hiddenPersona = hiddenSelected && findById(merged, selectedPersona.id);

      if (hiddenPersona) {
        setVisible((visible) => [hiddenPersona, ...initial(visible)]);
      }
    }
  }, [selectedPersona, visible, merged]);

  return visible;
};
