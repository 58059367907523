import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { IconArrow } from 'src/assets/icons/IconArrow';
import { ROTATE_DIRECTION } from 'src/data/ui';
import { useStyles } from './useStyles';

export const ArrowIcon = ({ className, direction, ...restProps }) => {
  const styles = useStyles({ direction });
  return (
    <IconArrow
      className={cx(styles.root, className)}
      {...restProps}
    />
  );
};

ArrowIcon.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.oneOf(Object.values(ROTATE_DIRECTION)),
};
ArrowIcon.defaultProps = {
  className: '',
  direction: ROTATE_DIRECTION.right,
};
