import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useCurrentCustomerId } from 'src/store';
import { useFreshRef } from 'src/lib/hooks';
import { PopupStoreContainer, usePopupActions } from './store';
import { PopupCloseHandlerContextProvider } from './services';
import { ScoringMethodPopup } from './ScoringMethodPopup';

const Container = ({ open, initialValue, onClose, ...restProps }) => {
  const popupActions = usePopupActions();

  const initialValueRef = useFreshRef(initialValue);
  useEffect(() => {
    if (open) {
      popupActions.initSelection(initialValueRef.current);
    }
  }, [open, initialValueRef, popupActions]);

  return (
    <PopupCloseHandlerContextProvider value={onClose}>
      <ScoringMethodPopup
        open={open}
        {...restProps}
      />
    </PopupCloseHandlerContextProvider>
  );
};
Container.propTypes = {
  open: PropTypes.bool.isRequired,
  initialValue: PropTypes.shape(),
};
Container.defaultProps = {
  initialValue: null,
};

const StoreWrapper = (props) => {
  const currentCustomerId = useCurrentCustomerId();

  return (
    <PopupStoreContainer currentCustomerId={currentCustomerId}>
      <Container {...props} />
    </PopupStoreContainer>
  );
};
StoreWrapper.propTypes = {};
StoreWrapper.defaultProps = {};

export default StoreWrapper;
