import { makeStyles, darken } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    icon: {
      color: COLORS.neutral.mediumGray,
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.spacing(0.1),

      '&:hover': {
        color: darken(COLORS.neutral.mediumGray, 0.2),
      },
    },
    iconXs: {
      fontSize: theme.typography.pxToRem(10),
    },
    iconS: {
      fontSize: theme.typography.pxToRem(12),
    },
    tooltip: {
      backgroundColor: COLORS.neutral.secondary,
    },
    contentWrapper: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
    },
    contentIcon: {
      marginLeft: '-5px',
      marginRight: '8px',

      '& svg': {
        display: 'block',
        fontSize: theme.typography.pxToRem(16),
      },
    },
    texts: {
      display: 'flex',
      flexFlow: 'column nowrap',
      flex: '1',
    },
    title: {
      color: COLORS.neutral.white,
      marginBottom: theme.spacing(1),
    },
    content: {
      color: COLORS.neutral.silver,
      whiteSpace: 'pre-wrap',
    },
    mobilePaper: {
      backgroundColor: COLORS.neutral.secondary,
    },
    mobileButtonClose: {
      width: '100%',
    },
  }),
  { name: 'Information' }
);
