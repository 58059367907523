import { useEffect } from 'react';

import { APPLICATION_MODE, getApplicationHost, getApplicationMode } from 'src/data/system';
import { useSystemActions } from 'src/store';

/**
 * If on initial App load we have relevant param in URL - switch app mode
 */
export const useSetupSystemStoreData = () => {
  const systemActions = useSystemActions();

  useEffect(() => {
    const applicationMode = getApplicationMode();
    if (applicationMode === APPLICATION_MODE.infinityExtension) {
      systemActions.activateApplicationMode(APPLICATION_MODE.infinityExtension);
    }

    const applicationHost = getApplicationHost();
    if (applicationHost) {
      systemActions.setApplicationHost(applicationHost);
    }
  }, [systemActions]);
};
