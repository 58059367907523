import { orderBy } from 'lodash';

/**
 * Gender
 */
export const GENDER = {
  female: 'female',
  male: 'male',
  doesntMatter: 'doesnt_matter',
};

const GENDER_LABEL = {
  [GENDER.female]: 'Female',
  [GENDER.male]: 'Male',
  [GENDER.doesntMatter]: "Doesn't matter",
};

export const getGenderLabel = (tone) => GENDER_LABEL[tone] || '';

/**
 * Gender tone
 */
export const GENDER_TONE = {
  feminine: 'feminine',
  neutral: 'neutral',
  masculine: 'masculine',
};
const DEFAULT_GENDER_TONE = GENDER_TONE.neutral;

const GENDER_TONE_PROPS = {
  [GENDER_TONE.feminine]: { title: 'Female tone of voice' },
  [GENDER_TONE.masculine]: { title: 'Male tone of voice' },
  [GENDER_TONE.neutral]: { title: 'Gender-neutral tone of voice' },
};

export const getGenderToneTitle = (genderTone) => {
  const alignedTone = genderTone || DEFAULT_GENDER_TONE;
  return GENDER_TONE_PROPS[alignedTone]?.title || '';
};

export function isDefaultGenderTone(tone) {
  return tone === DEFAULT_GENDER_TONE;
}

export const getHistogramCategoryValue = (data, categoryName) => {
  const category = data ? data.find((entry) => entry.name === categoryName) : null;
  return (category && category.value) || 0;
};

const GENDER_MINIMAL_DIFF = 10;
export const getGenderHistogramTone = (data) => {
  const femaleValue = getHistogramCategoryValue(data, GENDER.female);
  const maleValue = getHistogramCategoryValue(data, GENDER.male);

  const isMinimalDiff = Math.abs(femaleValue - maleValue) < GENDER_MINIMAL_DIFF;
  if (isMinimalDiff) {
    return GENDER_TONE.neutral;
  }
  return femaleValue > maleValue ? GENDER_TONE.feminine : GENDER_TONE.masculine;
};

/**
 * Age groups
 */
export const AGE_GROUPS = [
  {
    localValue: 0,
    bucketEnd: 13,
    bucketStart: 13,
    label: '13',
  },
  {
    localValue: 1,
    bucketEnd: 24,
    bucketStart: 25,
    label: '25',
  },
  {
    localValue: 2,
    bucketEnd: 44,
    bucketStart: 45,
    label: '45',
  },
  {
    localValue: 3,
    bucketEnd: 65,
    bucketStart: 65,
    label: '65+',
  },
];

export const MIN_AGE_GROUP = AGE_GROUPS[0];
export const MAX_AGE_GROUP = AGE_GROUPS[AGE_GROUPS.length - 1];

export const getAgeGroupLabel = (value) => {
  const ageGroup = AGE_GROUPS.find((a) => a.bucketEnd === value);
  return ageGroup ? ageGroup.label : value;
};

export const AGE_GROUP_APPEAL = {
  youngest: 'youngest',
  younger: 'younger',
  oldest: 'oldest',
  older: 'older',
  middle: 'middle',
  neutral: 'neutral',
  none: 'none',
};

export const getAgeGroupAppealTitle = (score, noneAppeal, ageGroupAppeal) => {
  if (!score) {
    return 'Not appealing to any age group';
  }

  const getAppealAgeTitle = (ageGroupAppeal) => {
    switch (ageGroupAppeal) {
      case AGE_GROUP_APPEAL.youngest:
        return 'Appealing to younger age groups';
      case AGE_GROUP_APPEAL.oldest:
        return 'Appealing to older age groups';
      case AGE_GROUP_APPEAL.middle:
        return 'Appealing to young and middle-age adults';
      case AGE_GROUP_APPEAL.none:
      case AGE_GROUP_APPEAL.neutral:
      default:
        return 'Appealing to all age groups';
    }
  };

  const getNoneAppealAgeTitle = (ageGroupAppeal) => {
    switch (ageGroupAppeal) {
      case AGE_GROUP_APPEAL.youngest:
        return 'Somewhat appealing to younger age groups';
      case AGE_GROUP_APPEAL.oldest:
        return 'Somewhat appealing to older age groups';
      case AGE_GROUP_APPEAL.middle:
        return 'Somewhat appealing to young and middle-age adults';
      case AGE_GROUP_APPEAL.none:
      case AGE_GROUP_APPEAL.neutral:
      default:
        return 'Somewhat appealing to all age groups';
    }
  };

  return noneAppeal ? getNoneAppealAgeTitle(ageGroupAppeal) : getAppealAgeTitle(ageGroupAppeal);
};

const AGE_MINIMAL_DIFF = 10;
const calcAgeAverage = (data) => {
  const sum = (data || []).reduce((acc, entry) => acc + (entry.value || 0), 0);
  return sum / (data.length || 1);
};
export const getAgeHistogramAppeal = (data) => {
  const average = calcAgeAverage(data);
  const youngestAverage = calcAgeAverage(data.slice(0, 3));
  const oldestAverage = calcAgeAverage(data.slice(-3));
  const middleAverage = calcAgeAverage(data.slice(2, 5));

  if (youngestAverage - average > AGE_MINIMAL_DIFF) {
    return AGE_GROUP_APPEAL.youngest;
  }
  if (middleAverage - average > AGE_MINIMAL_DIFF) {
    return AGE_GROUP_APPEAL.middle;
  }
  if (oldestAverage - average > AGE_MINIMAL_DIFF) {
    return AGE_GROUP_APPEAL.oldest;
  }
  return AGE_GROUP_APPEAL.neutral;
};

export const AUDIENCE_CHART_ITEMS_COUNT = 5;

/**
 * Sort audiences by "isSuggested" and "score", bringing "saved" items to the top
 * @param {[object]} audiences - array of audiences
 * @param {object} options - additional options
 * @param {string} options.usedPersonaName - "name" of audience that was used for generation
 * @returns {[object]} - sorted audiences
 */
export const takeTopAudiences = (audiences, options) => {
  if (!audiences) {
    return [];
  }

  const usedPersonaIteratee = (item) => {
    if (!options?.usedPersonaName) {
      return true;
    }
    return item?.persona?.name === options?.usedPersonaName;
  };

  // Sort by "isSuggested" and "score", bringing "saved" items to the top
  const sortedBySuggested = orderBy(
    audiences,
    [usedPersonaIteratee, 'persona.isSuggested', 'score'],
    ['desc', 'asc', 'desc']
  );

  // Take only top N items
  const onlyTopItems = sortedBySuggested.slice(0, AUDIENCE_CHART_ITEMS_COUNT);

  // Resort by "score" only
  return orderBy(onlyTopItems, ['score'], ['desc']);
};
