import { useCallback } from 'react';

import { PERSONA_SELECTION_POPUP } from '../types';
import { usePopups } from '../services';

export const useSelectPersonaPopup = () => {
  const popups = usePopups();

  /**
   * Display persona selection popup
   * @param {Object} options
   * @param {Function} options.onSubmit - callback to be called when selection gets confirmed
   * @param {boolean} options.displaySuggested - whether to display suggested personas
   * @returns {Promise}
   */
  return useCallback(
    ({ displaySuggested = false }) => {
      return new Promise((resolve) => {
        popups.openPopup(PERSONA_SELECTION_POPUP, {
          displaySuggested,
          onSubmit: (result) => {
            popups.closePopup(PERSONA_SELECTION_POPUP);
            resolve(result);
          },
          onClose: () => {
            popups.closePopup(PERSONA_SELECTION_POPUP);
            resolve(null);
          },
        });
      });
    },
    [popups]
  );
};
