import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

export const InsightsLeafDefault = forwardRef(
  ({ attributes, leaf, children, ...restProps }, ref) => (
    <span
      ref={ref}
      {...attributes}
      {...(leaf.decoration && { 'data-decoration': leaf.decoration })}
      {...restProps}
    >
      {children}
    </span>
  )
);

InsightsLeafDefault.propTypes = {
  attributes: PropTypes.shape({}),
  leaf: PropTypes.shape({
    decoration: PropTypes.string,
  }),
  children: PropTypes.shape({}),
};

InsightsLeafDefault.defaultProps = {
  attributes: null,
  leaf: null,
  children: null,
};
