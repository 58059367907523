import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isString } from 'lodash';
import { Tooltip } from '@material-ui/core';

import Information from 'src/components/Information';
import TextLink from 'src/components/TextLink';
import { TruncatedTypography } from 'src/components/TruncatedTypography';
import { AnnotationRating } from 'src/components/AnnotationRating';
import { WINNING_ANNOTATION_HINT } from 'src/data/annotation';
import { countWords } from 'src/lib/string';
import { useStyles } from './useStyles';

export const AnnotationListItem = ({
  classes,
  className,
  annotation,
  interactive,
  onUseClick,
  hint,
  toolMaxWordsPerKeyword,
}) => {
  const styles = useStyles({ classes });

  const localData = useMemo(() => {
    if (isString(annotation)) {
      return {
        name: annotation,
        score: 0,
      };
    }
    return annotation;
  }, [annotation]);

  const wordsCountExceedsLimit =
    toolMaxWordsPerKeyword && countWords(localData.name) > toolMaxWordsPerKeyword;
  const displayGenerateMore = interactive && !wordsCountExceedsLimit;

  if (!localData.name) {
    //avoid rendering the component in case it hase no name (but does have a score for example)
    return null;
  }

  return (
    <div
      className={cx(styles.root, className, {
        [styles.interactive]: interactive,
      })}
    >
      <div className={styles.textWrapper}>
        <TruncatedTypography className={styles.text}>{localData.name}</TruncatedTypography>

        {localData.score > 0 && (
          <Tooltip title={WINNING_ANNOTATION_HINT}>
            <AnnotationRating
              className="pl-0.5"
              rating={localData.score}
            />
          </Tooltip>
        )}
      </div>

      {displayGenerateMore && (
        <TextLink
          className={styles.link}
          onClick={() => onUseClick && onUseClick(localData.name)}
        >
          Generate more
          {hint && (
            <Information
              className={styles.hint}
              classes={{ tooltip: styles.tooltip }}
              content={hint}
              isQuestion
              placement="bottom"
            />
          )}
        </TextLink>
      )}
    </div>
  );
};

AnnotationListItem.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  annotation: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  interactive: PropTypes.bool,
  onUseClick: PropTypes.func,
  hint: PropTypes.string,
  toolMaxWordsPerKeyword: PropTypes.number,
};

AnnotationListItem.defaultProps = {
  className: null,
  classes: null,
  interactive: false,
  hint: null,
  onUseClick: null,
  toolMaxWordsPerKeyword: null,
};
