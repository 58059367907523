import { useCallback } from 'react';

import { ONBOARDING_STAGE } from 'src/data/onboarding';
import { useCurrentUser, useOnboardingActions, useUserActions } from 'src/store';

export const useAccountsOnboarding = () => {
  const userActions = useUserActions();

  const onboardingActions = useOnboardingActions();
  const currentUser = useCurrentUser();

  const startAccountOnboarding = useCallback(() => {
    if (!currentUser) return;

    const stageIsFinished = userActions.isFinishedOnboardingStage(ONBOARDING_STAGE.accounts);

    if (stageIsFinished) return;

    // We don't want to start another flow, if one is already in progress
    if (onboardingActions.getIsActive()) {
      return;
    }

    onboardingActions.startOnboarding(ONBOARDING_STAGE.accounts);
  }, [currentUser, onboardingActions, userActions]);

  return { startAccountOnboarding };
};
