import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { BENCHMARK_CONFIG_FRAGMENT } from './schema';

const QUERY = gql`
  query PerformanceCenterGlobalBenchmark($customerId: ID!) {
    benchmarkConfig(customerId: $customerId) {
      ...BenchmarkConfig
    }
  }
  ${BENCHMARK_CONFIG_FRAGMENT}
`;

export const queryPerformanceCenterGlobalBenchmark = (customerId) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      customerId,
    },
  }).then(({ data }) => getSingleProp(data));
};
