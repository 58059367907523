import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: COLORS.gray.gray40,
      padding: theme.spacing(0.75),

      '&$horizontal': {
        '& svg': {
          transform: 'rotate(90deg)',
        },
      },
      '&$small': {
        padding: theme.spacing(0.5),
        '& svg': {
          fontSize: theme.typography.pxToRem(20),
        },
      },

      '&:hover': {
        color: COLORS.primary.default,
        backgroundColor: 'transparent',
      },
    },
    horizontal: {},
    small: {},
    title: {
      color: COLORS.gray.gray40,
      padding: theme.spacing(1),
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
    },
    menuRoot: {},
    buttonClose: {
      ...theme.typography.medium12,
      position: 'absolute',
      top: 0,
      right: theme.spacing(1),
      padding: theme.spacing(1),
      height: 'auto',
    },
  }),
  { name: 'ContextMenu' }
);
