import { omit } from 'lodash';

import { GRAPHQL_ERROR_CODES } from 'src/data/graphqlErrorCodes';
// import { BackendError } from 'src/lib/errors';

export const getSingleProp = (obj) => (obj ? Object.values(obj)[0] : null);

// Unwrap edges-node to simple 'data' array
export const unwrapEdges = (edges) => edges.map((edge) => edge.node);

export const stripGraphQLErrorPrefix = (error) => {
  const message = error ? error.message : error;
  return (message || '').replace(/^GraphQL error:/, '').trim();
};

export const parseGraphQLError = (error) => {
  if (!error) {
    return [];
  }

  if (error.networkError) {
    return [
      {
        message: `Network error: ${error.networkError.message}`,
        code: GRAPHQL_ERROR_CODES.networkError,
      },
    ];
  }

  const errorsNode = error?.length
    ? // Errors thrown directly from query
      error
    : // Mutation errors
      error?.graphQLErrors;

  if (errorsNode?.length > 0) {
    return errorsNode.map((gqlError) => {
      return {
        message: gqlError.message,
        code: gqlError?.extensions?.code || GRAPHQL_ERROR_CODES.undefined,
        errorProperties: gqlError?.extensions?.error_properties,
      };
    });
  }

  return [
    {
      message: error.message,
      code: GRAPHQL_ERROR_CODES.undefined,
    },
  ];
};

export const doesParsedGraphQLErrorContainErrorCode = ({ parsedError, errorCode }) => {
  const result = parsedError.some((error) => error.code === errorCode);

  return result;
};

export const getParsedGraphQLFirstError = (error) => {
  const parsedErrors = parseGraphQLError(error);

  return parsedErrors[0];
};

const takeErrorMessage = (error) => error.message;

export const parseErrors = (error) => {
  if (!error) {
    return [];
  }

  if (error.networkError) {
    return [`Network error: ${error.networkError.message}`];
  }
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors.map(takeErrorMessage);
  }
  return [error.message];
};

export const throwIfResultHasErrors = (result) => {
  const errors = result?.errors || [];
  if (errors.length > 0) {
    // TODO: consider changing to
    //   const merged = errors.map(takeErrorMessage).join('\n');
    //   throw new BackendError(merged);
    throw errors;
  }
};

export const withoutTypename = (obj) => omit(obj, ['__typename']);
