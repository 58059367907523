import React from 'react';
import PropTypes from 'prop-types';
import { useSlate } from 'slate-react';

import { SLATE_EDITOR_ACTIONS } from 'src/lib/slate';
import { isEditorActionActive, isEditorActionDisabled } from '../../../utils';
import { EditorActionButton } from '../../EditorActionButton';

export const ToolbarFormatActions = ({ className, editorActions, onAction, classes }) => {
  const editor = useSlate();

  return (
    <div className={className}>
      {editorActions.map((action) => (
        <EditorActionButton
          key={action}
          action={action}
          onAction={onAction}
          selected={isEditorActionActive(editor, action)}
          disabled={isEditorActionDisabled(editor, action)}
          classes={classes}
        />
      ))}
    </div>
  );
};

ToolbarFormatActions.propTypes = {
  className: PropTypes.string,
  editorActions: PropTypes.arrayOf(PropTypes.oneOf(SLATE_EDITOR_ACTIONS)).isRequired,
  onAction: PropTypes.func.isRequired,
  classes: PropTypes.shape(),
};
