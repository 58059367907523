import gql from 'graphql-tag';

import { mapPersonaForBackendInput } from 'src/data/persona';
import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_PROMPT_BLOCKS } from './schema';

const MUTATION = gql`
  mutation SetupMultiPromptRunner(
    $projectId: ID!
    $generationTool: GenerationToolEnum!
    $subGenerationToolsProps: JSON
    $brief: BriefInput
    $inputText: String
    $scoringMethod: ScoringMethodInput
    $suggestionMode: String
    $language: String!
    $languageTargetFormality: LanguageTargetFormalityEnum
    $numberOfVariations: Int!
    $tov: String
    $customTovId: ID
    $customFormulaId: ID
    $debugAcPrompt: String
    $freestyleToolId: ID
    $freestyleToolAllInstructions: String
    $freestyleToolAllInstructionsData: JSON
    $customerPersonaId: ID
    $customerPersona: CustomerPersonaInput
    $annotations: [SuggestionAnnotationInput!]
    $sourceType: String
    $url: String
    $manualInstructions: String
    $avoidWords: [String!]
    $onlyImproveScore: Boolean
    $originalVariationId: ID
    $extractedBrief: String
    $urlExtractions: [UrlExtractionInput!]
    $callToAction: String
    $maxChars: Int
    $promptFields: JSON
    $promptFieldImages: JSON
    $promptFieldDocuments: JSON
    $customMultiPromptId: ID
    $csvId: ID
  ) {
    setupMultiPromptRunner(
      projectId: $projectId
      generationTool: $generationTool
      subGenerationToolsProps: $subGenerationToolsProps
      brief: $brief
      inputText: $inputText
      scoringMethod: $scoringMethod
      suggestionMode: $suggestionMode
      language: $language
      languageTargetFormality: $languageTargetFormality
      numberOfVariations: $numberOfVariations
      tov: $tov
      customTovId: $customTovId
      customFormulaId: $customFormulaId
      debugAcPrompt: $debugAcPrompt
      freestyleToolId: $freestyleToolId
      freestyleToolAllInstructions: $freestyleToolAllInstructions
      freestyleToolAllInstructionsData: $freestyleToolAllInstructionsData
      customerPersonaId: $customerPersonaId
      customerPersona: $customerPersona
      annotations: $annotations
      sourceType: $sourceType
      url: $url
      manualInstructions: $manualInstructions
      avoidWords: $avoidWords
      onlyImproveScore: $onlyImproveScore
      originalVariationId: $originalVariationId
      extractedBrief: $extractedBrief
      urlExtractions: $urlExtractions
      callToAction: $callToAction
      maxChars: $maxChars
      promptFields: $promptFields
      promptFieldImages: $promptFieldImages
      promptFieldDocuments: $promptFieldDocuments
      customMultiPromptId: $customMultiPromptId
      csvId: $csvId
    ) {
      ...PromptBlocks
    }
  }
  ${FRAGMENT_PROMPT_BLOCKS}
`;

export const setupMultiPromptRunner = async ({
  projectId,
  generationTool,
  subGenerationToolsProps,
  brief,
  inputText,
  scoringMethod,
  suggestionMode,
  language,
  numberOfVariations,
  tov,
  customTovId,
  customFormulaId,
  debugAcPrompt,
  freestyleToolId,
  freestyleToolAllInstructions,
  freestyleToolAllInstructionsData,
  languageTargetFormality,
  customerPersona,
  annotations,
  sourceType,
  url,
  manualInstructions,
  avoidWords,
  onlyImproveScore,
  originalVariationId,
  extractedBrief,
  urlExtractions,
  callToAction,
  maxChars,
  promptFields,
  promptFieldImages,
  promptFieldDocuments,
  customMultiPromptId,
  csvId,
}) => {
  const variables = {
    projectId,
    generationTool,
    subGenerationToolsProps: subGenerationToolsProps
      ? JSON.stringify(subGenerationToolsProps)
      : null,
    brief: null,
    inputText,
    scoringMethod,
    suggestionMode,
    language,
    numberOfVariations,
    tov,
    customTovId,
    customFormulaId,
    debugAcPrompt,
    freestyleToolId,
    freestyleToolAllInstructions,
    freestyleToolAllInstructionsData: JSON.stringify(freestyleToolAllInstructionsData),
    languageTargetFormality,
    customerPersonaId: customerPersona?.id,
    customerPersona: mapPersonaForBackendInput(customerPersona),
    annotations,
    sourceType,
    url,
    manualInstructions,
    avoidWords,
    onlyImproveScore,
    originalVariationId,
    extractedBrief,
    urlExtractions,
    callToAction,
    maxChars,
    promptFields: JSON.stringify(promptFields),
    promptFieldImages: JSON.stringify(promptFieldImages),
    promptFieldDocuments: JSON.stringify(promptFieldDocuments),
    customMultiPromptId,
    csvId,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
