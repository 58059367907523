import { createStore } from 'react-sweet-state';
import { cloneDeep, findIndex } from 'lodash';

import { captureMessage } from 'src/lib/sentry';
import {
  createSuggestionFreestyleTool,
  deleteSuggestionFreestyleTool,
  querySuggestionFreestyleTools,
  updateSuggestionFreestyleTool,
} from 'src/graphql/freestyleTools';
import { GENERATION_TOOL } from 'src/data/generationTool';

const STORE_NAME = 'freestyle-tools-store';

const INITIAL_STATE = {
  currentFreestyleTool: null,
  freestyleTools: [],
  loadingFreestyleTools: false,
  savingFreestyleTool: false,
  deletingFreestyleTool: false,
  filteredFreestyleTools: [],
  errors: null,
  lastPromptAsset: null,
  lastPromptUrl: null,

  customerFreestyleTools: [],
  loadingCustomerFreestyleTools: false,
};

const setError =
  (field, error) =>
  ({ getState, setState }) => {
    const { errors } = getState();
    setState({ errors: { ...errors, [field]: error } });
  };

/**
 * Private actions
 */
const PRIVATE_ACTIONS = {
  clearStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  mergeToolInCache:
    (tool) =>
    ({ getState, setState }) => {
      const { freestyleTools: list } = getState();
      const newList = list.filter((w) => w.id !== tool.id);
      const updatedList = [tool, ...newList];
      setState({ freestyleTools: updatedList });
    },

  removeToolFromCache:
    (toolId) =>
    ({ getState, setState }) => {
      const { freestyleTools } = getState();
      const filteredTools = freestyleTools.filter((t) => t.id !== toolId);
      setState({ freestyleTools: [...filteredTools] });
    },
};

/**
 * Public actions
 */
export const ACTIONS = {
  init:
    (customerId, workspaceId) =>
    async ({ dispatch }) => {
      if (!customerId || !workspaceId) {
        return;
      }

      try {
        await dispatch(ACTIONS.queryWorkspaceFreestyleTools({ customerId, workspaceId }));
      } catch (error) {
        // Report "warning" to Sentry
        captureMessage('Error while calling "queryWorkspaceFreestyleTools" GQL query', {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'FreestyleToolsStore -> init',
          },
        });
      }
    },

  queryCustomerFreestyleTools:
    () =>
    async ({ setState }, { currentCustomerId }) => {
      setState({ loadingCustomerFreestyleTools: true });
      try {
        const result = await querySuggestionFreestyleTools({ customerId: currentCustomerId });
        setState({ customerFreestyleTools: result });
      } finally {
        setState({ loadingCustomerFreestyleTools: false });
      }
    },

  queryWorkspaceFreestyleTools:
    ({ customerId, workspaceId }) =>
    async ({ setState }, { currentCustomerId }) => {
      setState({ loadingFreestyleTools: true });
      try {
        const result = await querySuggestionFreestyleTools({
          customerId: customerId || currentCustomerId,
          workspaceId: workspaceId,
        });
        setState({ freestyleTools: result });
      } finally {
        setState({ loadingFreestyleTools: false });
      }
    },

  getFreestyleTool:
    (toolId) =>
    ({ getState }) => {
      const { freestyleTools } = getState();
      return freestyleTools.find((t) => t.id === toolId);
    },

  getFreestyleTools:
    () =>
    ({ getState }) => {
      return getState().freestyleTools;
    },

  havePromptsForGenerationTool:
    (generationTool) =>
    ({ getState }) => {
      const { freestyleTools } = getState();
      return freestyleTools.some((t) => t.generationTool === generationTool);
    },

  setFilteredFreestyleTools:
    (filteredFreestyleTools) =>
    ({ setState }) => {
      setState({ filteredFreestyleTools });
    },

  moveToolToStart:
    (tool) =>
    ({ dispatch }) => {
      dispatch(
        PRIVATE_ACTIONS.mergeToolInCache({
          ...tool,
          generationTool: tool.generationTool || GENERATION_TOOL.freestyle,
        })
      );
    },

  createFreestyleTool:
    ({ tool, workspaceId }) =>
    async ({ setState, dispatch }, { currentCustomerId }) => {
      setState({ savingFreestyleTool: true });
      try {
        const createdTool = await createSuggestionFreestyleTool({
          customerId: currentCustomerId,
          workspaceId,
          name: tool.name,
          allInstructionsData: tool.allInstructionsData,
          generationTool: tool.generationTool || GENERATION_TOOL.freestyle,
        });
        dispatch(PRIVATE_ACTIONS.mergeToolInCache(createdTool));
        dispatch(setError('Name', null));
        return createdTool;
      } catch (error) {
        if (error.message.includes('already exists')) {
          dispatch(setError('Name', 'Another prompt with this name already exist.'));
        } else {
          throw error;
        }
      } finally {
        setState({ savingFreestyleTool: false });
      }
    },

  clearNameErrorMessage:
    () =>
    ({ dispatch }) => {
      dispatch(setError('Name', null));
    },

  updateFreestyleTool:
    ({ id, tool }) =>
    async ({ setState, dispatch }) => {
      setState({ savingFreestyleTool: true });
      try {
        const updatedTool = await updateSuggestionFreestyleTool({
          id: id,
          name: tool.name,
          generationTool: tool.generationTool,
          allInstructionsData: tool.allInstructionsData,
        });
        dispatch(PRIVATE_ACTIONS.removeToolFromCache(id));
        dispatch(PRIVATE_ACTIONS.mergeToolInCache(updatedTool));
        return updatedTool;
      } finally {
        setState({ savingFreestyleTool: false });
      }
    },

  updateFreestyleTools:
    (editedTool) =>
    ({ getState, setState }) => {
      const { freestyleTools } = getState();
      const list = freestyleTools;
      const existingIndex = findIndex(list, (tool) => tool.id === editedTool.id);

      if (existingIndex !== -1) {
        list.splice(existingIndex, 1);
        list.unshift(editedTool);
        setState({ freestyleTools: list });
      }
    },

  setCurrentFreestyleTool:
    (tool) =>
    ({ setState }) => {
      setState({ currentFreestyleTool: tool });
    },

  getCurrentFreestyleTool:
    () =>
    ({ getState }) => {
      const { currentFreestyleTool } = getState();
      return currentFreestyleTool;
    },

  deleteFreestyleTool:
    (toolId) =>
    async ({ setState, dispatch }) => {
      setState({ deletingFreestyleTool: true });
      try {
        await deleteSuggestionFreestyleTool(toolId);
        dispatch(PRIVATE_ACTIONS.removeToolFromCache(toolId));
      } finally {
        setState({ deletingFreestyleTool: false });
      }
    },

  setLastPromptAsset:
    (asset) =>
    ({ setState }) => {
      setState({ lastPromptAsset: asset });
    },

  setLastPromptUrl:
    (url) =>
    ({ setState }) => {
      setState({ lastPromptUrl: url });
    },
};

export const FreestyleToolsStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: STORE_NAME,
});
