import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconPersonalBio = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 30 30"
        data-fill-none
      >
        <rect
          x="3"
          y="3"
          width="24"
          height="24"
          rx="4"
          fill="#28354D"
        />
        <path
          d="M20 10C19.45 10 18.9792 9.80417 18.5875 9.4125C18.1958 9.02083 18 8.55 18 8C18 7.45 18.1958 6.97917 18.5875 6.5875C18.9792 6.19583 19.45 6 20 6C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8C22 8.55 21.8042 9.02083 21.4125 9.4125C21.0208 9.80417 20.55 10 20 10ZM16.5 14C16.3583 14 16.2397 13.952 16.144 13.856C16.048 13.7603 16 13.6417 16 13.5V12.6C16 12.325 16.0708 12.0667 16.2125 11.825C16.3542 11.5833 16.55 11.4 16.8 11.275C17.225 11.0583 17.7042 10.875 18.2375 10.725C18.7708 10.575 19.3583 10.5 20 10.5C20.6417 10.5 21.2292 10.575 21.7625 10.725C22.2958 10.875 22.775 11.0583 23.2 11.275C23.45 11.4 23.6458 11.5833 23.7875 11.825C23.9292 12.0667 24 12.325 24 12.6V13.5C24 13.6417 23.952 13.7603 23.856 13.856C23.7603 13.952 23.6417 14 23.5 14H16.5ZM17 13H23V12.6C23 12.5083 22.9772 12.425 22.9315 12.35C22.8855 12.275 22.825 12.2167 22.75 12.175C22.45 12.025 22.0647 11.875 21.594 11.725C21.123 11.575 20.5917 11.5 20 11.5C19.4083 11.5 18.877 11.575 18.406 11.725C17.9353 11.875 17.55 12.025 17.25 12.175C17.175 12.2167 17.1147 12.275 17.069 12.35C17.023 12.425 17 12.5083 17 12.6V13ZM20 9C20.275 9 20.5105 8.902 20.7065 8.706C20.9022 8.51033 21 8.275 21 8C21 7.725 20.9022 7.4895 20.7065 7.2935C20.5105 7.09783 20.275 7 20 7C19.725 7 19.4897 7.09783 19.294 7.2935C19.098 7.4895 19 7.725 19 8C19 8.275 19.098 8.51033 19.294 8.706C19.4897 8.902 19.725 9 20 9Z"
          fill="white"
        />
        <path
          d="M15.9272 8H8V21.9892C8 21.9892 15.4724 21.9892 21.212 21.9892V17.5851"
          stroke="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.215 15.1582H10.3164V14.002H14.215V15.1582ZM17.7887 17.4707H10.3164V16.3145H17.7887V17.4707ZM10.3164 19.7832H17.7887V18.627H10.3164V19.7832Z"
          fill="white"
        />
      </SvgIcon>
    );
  })
);
