import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NotificationSuccess = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 18 18">
    <path
      d="M9 18C13.9705 18 18 13.9705 18 9C18 4.02944 13.9705 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9705 4.02944 18 9 18Z"
      fill="#32A97C"
    />
    <path
      d="M6.71697 8.27516C6.332 7.90491 5.6989 7.89664 5.30292 8.25658C4.90694 8.61651 4.89802 9.20842 5.28301 9.57866L6.71697 8.27516ZM8.72216 11.5448L8.00517 12.1965L8.84066 13L9.53643 12.0876L8.72216 11.5448ZM13.8142 6.4778C14.1348 6.05736 14.0302 5.47351 13.5805 5.17375C13.1308 4.87399 12.5063 4.97182 12.1857 5.39227L13.8142 6.4778ZM5.28301 9.57866L8.00517 12.1965L9.43915 10.8931L6.71697 8.27516L5.28301 9.57866ZM9.53643 12.0876L13.8142 6.4778L12.1857 5.39227L7.90797 11.0021L9.53643 12.0876Z"
      fill="white"
    />
  </SvgIcon>
));

export default React.memo(NotificationSuccess);
