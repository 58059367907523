import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      bottom: 12,
      right: 146,
      height: 48,
      display: 'flex',
      backgroundColor: COLORS.primary.active,
      borderRadius: theme.shape.borderRadiusSemiLarge,
      boxShadow: '0px 0px 4px -2px rgba(0, 0, 0, 0.45), 0px 0px 6px -1px rgba(0, 0, 0, 0.15)',
      zIndex: theme.zIndex.modal,
      padding: theme.spacing(2),
      alignItems: 'center',
      columnGap: theme.spacing(1),
      '&$isInsightsPanelOpen': {
        right: theme.spacing(2) + 580,
      },
    },
    isInsightsPanelOpen: {},
    loader: {
      color: COLORS.neutral.white,
    },
    title: {
      color: COLORS.neutral.white,
    },
  }),
  { name: 'DataExtractionIndicator' }
);
