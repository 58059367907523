import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconBulletedList = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.200897 2.50363C0.200897 1.87491 0.710572 1.36523 1.33929 1.36523C1.968 1.36523 2.47768 1.87491 2.47768 2.50363C2.47768 3.13234 1.96801 3.64202 1.33929 3.64202C0.710571 3.64202 0.200897 3.13234 0.200897 2.50363ZM1.33929 2.86523C1.539 2.86523 1.7009 2.70334 1.7009 2.50363C1.7009 2.30392 1.539 2.14202 1.33929 2.14202C1.13958 2.14202 0.977683 2.30392 0.977683 2.50363C0.977683 2.70334 1.13958 2.86523 1.33929 2.86523Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.30801 2.50391C3.30801 2.08969 3.6438 1.75391 4.05801 1.75391H11.0491C11.4633 1.75391 11.7991 2.08969 11.7991 2.50391C11.7991 2.91812 11.4633 3.25391 11.0491 3.25391H4.05801C3.6438 3.25391 3.30801 2.91812 3.30801 2.50391Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.200897 5.99972C0.200897 5.37101 0.710572 4.86133 1.33929 4.86133C1.968 4.86133 2.47768 5.37101 2.47768 5.99972C2.47768 6.62844 1.968 7.13811 1.33929 7.13811C0.710572 7.13811 0.200897 6.62843 0.200897 5.99972ZM1.33929 6.36133C1.539 6.36133 1.7009 6.19943 1.7009 5.99972C1.7009 5.80001 1.539 5.63811 1.33929 5.63811C1.13958 5.63811 0.977683 5.80001 0.977683 5.99972C0.977683 6.19943 1.13958 6.36133 1.33929 6.36133Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.30803 6C3.30803 5.58579 3.64382 5.25 4.05803 5.25H11.0491C11.4633 5.25 11.7991 5.58579 11.7991 6C11.7991 6.41421 11.4633 6.75 11.0491 6.75H4.05803C3.64382 6.75 3.30803 6.41421 3.30803 6Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.200897 9.49581C0.200897 8.86717 0.710533 8.35742 1.33929 8.35742C1.96804 8.35742 2.47768 8.86717 2.47768 9.49581C2.47768 10.1245 1.96804 10.6342 1.33929 10.6342C0.710532 10.6342 0.200897 10.1245 0.200897 9.49581ZM1.33929 9.85742C1.53904 9.85742 1.7009 9.69552 1.7009 9.49581C1.7009 9.29611 1.53904 9.13421 1.33929 9.13421C1.13954 9.13421 0.977683 9.29611 0.977683 9.49581C0.977683 9.69552 1.13954 9.85742 1.33929 9.85742Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.30801 9.49609C3.30801 9.08188 3.6438 8.74609 4.05801 8.74609H11.0491C11.4633 8.74609 11.7991 9.08188 11.7991 9.49609C11.7991 9.91031 11.4633 10.2461 11.0491 10.2461H4.05801C3.6438 10.2461 3.30801 9.91031 3.30801 9.49609Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconBulletedList.displayName = 'IconBulletedList';
