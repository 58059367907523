import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_BRAND_VOICE } from './schema';

const QUERY = gql`
  query BrandVoices($customerId: ID!, $workspaceId: ID!) {
    brandVoices(customerId: $customerId, workspaceId: $workspaceId) {
      ...BrandVoice
    }
  }
  ${FRAGMENT_BRAND_VOICE}
`;

export const queryBrandVoices = async (customerId, workspaceId) => {
  const result = await executeGqlQuery({
    query: QUERY,
    variables: { customerId, workspaceId },
  });
  return getSingleProp(result.data);
};
