import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const IconItalic = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      ref={ref}
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.21121 0.251365C8.59782 0.400059 8.79068 0.834004 8.64199 1.22061L4.75806 11.3188C4.60936 11.7054 4.17542 11.8983 3.78881 11.7496C3.40221 11.6009 3.20934 11.167 3.35804 10.7804L7.24197 0.68214C7.39066 0.295535 7.82461 0.102671 8.21121 0.251365Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.08482 0.951172C4.08482 0.536958 4.42061 0.201172 4.83482 0.201172H10.6607C11.0749 0.201172 11.4107 0.536958 11.4107 0.951172C11.4107 1.36539 11.0749 1.70117 10.6607 1.70117H4.83482C4.42061 1.70117 4.08482 1.36539 4.08482 0.951172Z"
        fill="#626266"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.589294 11.0508C0.589294 10.6366 0.925081 10.3008 1.33929 10.3008H7.16519C7.5794 10.3008 7.91519 10.6366 7.91519 11.0508C7.91519 11.465 7.5794 11.8008 7.16519 11.8008H1.33929C0.925081 11.8008 0.589294 11.465 0.589294 11.0508Z"
        fill="#626266"
      />
    </SvgIcon>
  ))
);

IconItalic.displayName = 'IconItalic';
