import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 'fit-content',
      cursor: 'default',
      borderRadius: theme.shape.borderRadiusSmall,
    },
    selectable: {
      cursor: 'pointer',
    },
    selected: {},
    disabled: {},
    default: {
      backgroundColor: COLORS.gray.gray80,
      border: 'none',

      '&$disabled': {
        backgroundColor: COLORS.gray.gray70,
      },
      '&:hover': {
        backgroundColor: COLORS.gray.gray80,
      },
    },
    outlined: {
      borderColor: COLORS.gray.gray60,
      color: COLORS.gray.black,

      '&$disabled': {
        backgroundColor: COLORS.gray.gray70,
      },

      '& svg': {
        color: COLORS.gray.gray30,
      },

      '&:hover': {
        backgroundColor: COLORS.primary.light,

        '&:is($closeIcon)': {
          color: COLORS.gray.gray30,
        },
      },

      '&$selected': {
        borderColor: COLORS.primary.default,
        color: COLORS.primary.default,
        backgroundColor: COLORS.primary.silver,

        '& svg:is($checkIcon)': {
          color: COLORS.primary.default,
        },

        '&:hover': {
          backgroundColor: COLORS.primary.silver,
          '& svg:is($checkIcon)': {
            color: COLORS.primary.active,
          },
          '&:not($disabled)': {
            borderColor: COLORS.primary.active,
            color: COLORS.primary.active,
          },
        },
      },
    },
    small: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      padding: theme.spacing(0.25, 0.75),
      height: 20,
    },
    medium: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      padding: theme.spacing(0.75),
      height: 24,
    },
    iconSmall: {},
    icon: {
      height: theme.typography.pxToRem(16),
      width: theme.typography.pxToRem(16),

      '&$iconSmall': {
        height: theme.typography.pxToRem(12),
        width: theme.typography.pxToRem(12),
      },
    },
    plusIcon: {
      height: theme.typography.pxToRem(14),
      width: theme.typography.pxToRem(14),

      '&$iconSmall': {
        height: theme.typography.pxToRem(10),
        width: theme.typography.pxToRem(10),
      },
    },
    checkIcon: {},
    closeIcon: {
      transform: 'rotate(135deg)',
    },
  }),
  { name: 'CustomChip' }
);
