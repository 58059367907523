import { Node as SlateNode, Text as SlateText } from 'slate';

import { SLATE_TEXT_FORMATS } from 'src/lib/slate';
import {
  createBlockQuote,
  createLinkNode,
  createParagraph,
  createTextNode,
} from '../templateCreators';
import { textToSlate } from './deserialize';

export const extractBankPlaceholders = (slateValue) => {
  const placeholders = {};
  let placeholderCounter = 0;

  const createPlaceholder = () => `<!#PH${++placeholderCounter}#!>`;

  const updatedValue = slateValue.map((paragraph) => {
    const updatedChildren = paragraph.children.map((node) => {
      if (node.type === SLATE_TEXT_FORMATS.BANK_MESSAGE) {
        const key = createPlaceholder();
        placeholders[key] = {
          type: SLATE_TEXT_FORMATS.BANK_MESSAGE,
          id: node.bankMessageId,
          body: node.bankMessageBody,
        };
        return { text: key };
      } else if (node.type === SLATE_TEXT_FORMATS.PRODUCT_DESCRIPTION) {
        const key = createPlaceholder();
        placeholders[key] = {
          type: SLATE_TEXT_FORMATS.PRODUCT_DESCRIPTION,
          title: node.title,
          article: node.article,
        };
        return { text: key };
      } else if (node.type === SLATE_TEXT_FORMATS.PROMPT_IMAGE) {
        const key = createPlaceholder();
        placeholders[key] = {
          type: SLATE_TEXT_FORMATS.PROMPT_IMAGE,
          title: 'image description',
          article: node.children[0].text,
          id: node.children[0].id || node.id,
        };
        return { text: key };
      } else if (node.type === SLATE_TEXT_FORMATS.PROMPT_DOCUMENT) {
        const key = createPlaceholder();
        placeholders[key] = {
          type: SLATE_TEXT_FORMATS.PROMPT_DOCUMENT,
          title: 'document content',
          article: node.children[0].text,
          id: node.children[0].id || node.id,
        };
        return { text: key };
      } else if (node.type === SLATE_TEXT_FORMATS.RESOURCE_URL) {
        const key = createPlaceholder();
        placeholders[key] = {
          type: SLATE_TEXT_FORMATS.RESOURCE_URL,
          url: node.resourceUrl,
        };
        return { text: key };
      }
      return node;
    });

    return {
      ...paragraph,
      children: updatedChildren,
    };
  });

  return { updatedValue, placeholders };
};

export const extractBlogSectionPlaceholders = ({ slateValue, initialCounter }) => {
  const placeholders = {};
  let placeholderCounter = initialCounter || 0;

  const createLinkPlaceholder = () => `<!#LINK${placeholderCounter++}#!>`;
  const createQuotePlaceholder = () => `<!#QUOTE${placeholderCounter++}#!>`;

  const processBlocks = (blocks) =>
    blocks.map((node) => {
      switch (node.type) {
        case SLATE_TEXT_FORMATS.LINK: {
          if (node.children?.[0]?.text) {
            const key = createLinkPlaceholder();
            placeholders[key] = {
              type: SLATE_TEXT_FORMATS.LINK,
              url: node.url,
              text: node.children?.[0]?.text || '',
            };
            return { text: key };
          }
          return { text: '' };
        }

        case SLATE_TEXT_FORMATS.BLOCK_QUOTE: {
          const key = createQuotePlaceholder();
          placeholders[key] = {
            type: SLATE_TEXT_FORMATS.BLOCK_QUOTE,
            text: SlateNode.string(node),
          };
          return createParagraph({ text: key });
        }

        default: {
          if (node.children?.length > 0) {
            return {
              ...node,
              children: processBlocks(node.children),
            };
          }
          return node;
        }
      }
    });

  const updatedValue = processBlocks(slateValue);
  return { updatedValue, placeholders, counter: placeholderCounter };
};

/**
 * Convert text along with passed placeholders to Slate fragment
 * @param {string} generatedText - Text with placeholders
 * @param {object} [placeholders] - Placeholders data
 * @returns {import('slate').Element[]} - Slate fragment
 */
export const convertTextWithPlaceholdersToSlateFragment = ({ generatedText, placeholders }) => {
  if (!generatedText) {
    return [];
  }

  // Convert text to Slate fragment
  const fragment = textToSlate(generatedText);

  // If we don't have placeholders, we can return the fragment as is
  if (!placeholders || Object.keys(placeholders).length === 0) {
    return fragment;
  }

  const withRestoredQuotes = restoreQuotes({ fragment, placeholders });
  return restoreLinks({ fragment: withRestoredQuotes, placeholders });
};

const restoreQuotes = ({ fragment, placeholders }) => {
  return fragment.map((node) => {
    const nodeText = SlateNode.string(node);
    const firstBlockQuotePlaceholder = getFirstBlockQuotePlaceholder(nodeText);
    if (firstBlockQuotePlaceholder) {
      const placeholderData = placeholders[firstBlockQuotePlaceholder];
      if (placeholderData) {
        return createBlockQuote({ text: placeholderData.text });
      }
    }

    return node;
  });
};

const restoreLinks = ({ fragment, placeholders }) => {
  const result = [];
  fragment.forEach((node) => {
    // Replace links in text nodes
    if (SlateText.isText(node)) {
      const parts = textWithLinkPlaceholdersToSlateFragment({ text: node.text, placeholders });
      result.push(...parts);
      return;
    }

    // Recursively process children of some node types
    if (
      [
        SLATE_TEXT_FORMATS.PARAGRAPH,
        SLATE_TEXT_FORMATS.BULLETED_LIST,
        SLATE_TEXT_FORMATS.NUMBERED_LIST,
        SLATE_TEXT_FORMATS.LIST_ITEM,
      ].includes(node.type)
    ) {
      result.push({
        ...node,
        children: restoreLinks({ fragment: node.children, placeholders }),
      });
      return;
    }

    // Just take other nodes unchanged
    result.push(node);
  });
  return result;
};

const getFirstBlockQuotePlaceholder = (text) => {
  const regex = /<!#QUOTE.*?!>/;
  const match = text.match(regex);
  return match?.[0] || null;
};

const textWithLinkPlaceholdersToSlateFragment = ({ text, placeholders }) => {
  const result = [];

  const parts = splitTextByLinkPlaceholders(text);
  parts.forEach((part) => {
    const placeholderData = placeholders[part];
    if (placeholderData) {
      result.push(createLinkNode({ url: placeholderData.url, text: placeholderData.text }));
    } else if (part !== '') {
      result.push(createTextNode({ text: part }));
    }
  });

  return result;
};

const splitTextByLinkPlaceholders = (text) => {
  const regex = /(<!#LINK.*?!>)/;
  return text.split(regex);
};
