import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      maxWidth: theme.centeredContainerMaxWidth,
      minWidth: theme.centeredContainerMinWidth,
      margin: '0 auto',
      paddingBottom: theme.spacing(10), // account for "zendesk" help widget

      '@media screen and (max-width: 1439px)': {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },

      '@media screen and (max-width: 1280px)': {
        maxWidth: 990,
      },
    },
  }),
  { name: 'LayoutContainerCentered' }
);
