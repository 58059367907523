export const TRACE_AC_CALL = Symbol('TRACE_AC_CALL');
export const CUSTOMER_ID_CHANGED = Symbol('CUSTOMER_ID_CHANGED');

export const COLLECT_DOCUMENT_VARIATIONS_FOR_EXPORT = Symbol(
  'COLLECT_DOCUMENT_VARIATIONS_FOR_EXPORT'
);
export const COLLECTED_DOCUMENT_VARIATIONS_FOR_EXPORT = Symbol(
  'COLLECTED_DOCUMENT_VARIATIONS_FOR_EXPORT'
);

export const DISPLAY_VARIATION_DETAILS = Symbol('DISPLAY_VARIATION_DETAILS');
export const OPEN_DETAILS_PANEL = Symbol('OPEN_DETAILS_PANEL');
export const CLOSE_VARIATION_DETAILS = Symbol('CLOSE_VARIATION_DETAILS');

export const SCROLL_TO_SELECTED_GENERATION_MODE = Symbol('SCROLL_TO_SELECTED_GENERATION_MODE');
export const OPEN_GENERATION_PANEL_MENU = Symbol('OPEN_GENERATION_PANEL_MENU');
