import { ONBOARDING_STEP } from 'src/data/onboarding';
import { SPOTLIGHT_TYPE } from '../components/Spotlight';
import { TooltipContentWorkspaces } from '../components/TooltipContentWorkspaces';

export const STEPS_WORKSPACES_DATA = [
  {
    id: ONBOARDING_STEP.workspaces,
    target: '[data-onboarding="WorkspaceButton"]',
    contentClass: TooltipContentWorkspaces,
    content: {
      dontShowAgain: true,
    },
    options: {
      placement: 'bottom-start',
      showOverlay: true,
      spotlights: [{ type: SPOTLIGHT_TYPE.rounded }],
    },
  },
];
