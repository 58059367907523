import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useStyles } from './useStyles';

const DEFAULT_PLAYER_OPTIONS = {
  seo: false,
  videoFoam: true,
};

export const WISTIA_PLAYER_MODE = {
  default: 'default',
  autoplay: 'autoplay',
  silentLooped: 'silentLooped',
};

export const WistiaEmbed = ({ className, videoId, mode, aspectRatio }) => {
  const styles = useStyles();
  const [rootElement, setRootElement] = useState(null);

  // Prepare player options and serialize them to a string
  const playerOptions = preparePlayerOptions({ mode });
  const serializedOptions = serializePlayerOptions(playerOptions);

  useEffect(() => {
    if (!rootElement || !videoId) {
      return;
    }

    // Wistia player template
    const responsivePadding = responsivePaddingFromAspectRatio(aspectRatio);
    rootElement.innerHTML = `
      <div class="wistia_responsive_padding" style="padding:${responsivePadding};position:relative;">
        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
          <div class="wistia_embed wistia_async_${videoId} ${serializedOptions}" style="height:100%;width:100%">&nbsp;</div>
        </div>
      </div>
    `;

    // Wistia player script
    const script = document.createElement('script');
    script.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script.async = true;
    rootElement.appendChild(script);

    return () => {
      // Cleanup code
      rootElement.innerHTML = '';
    };
  }, [rootElement, videoId, serializedOptions, aspectRatio]);

  return (
    <div
      className={cx(className, styles.root, {
        [styles.silentLooped]: mode === WISTIA_PLAYER_MODE.silentLooped,
      })}
      ref={setRootElement}
    />
  );
};

WistiaEmbed.propTypes = {
  className: PropTypes.string,
  videoId: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(Object.values(WISTIA_PLAYER_MODE)),
  aspectRatio: PropTypes.number,
};

WistiaEmbed.defaultProps = {
  className: null,
  mode: WISTIA_PLAYER_MODE.default,
  aspectRatio: 16 / 9,
};

const preparePlayerOptions = ({ mode }) => {
  const result = {
    ...DEFAULT_PLAYER_OPTIONS,
  };

  if (mode === WISTIA_PLAYER_MODE.autoplay) {
    result.autoPlay = true;
  }

  if (mode === WISTIA_PLAYER_MODE.silentLooped) {
    result.autoPlay = true;
    result.endVideoBehavior = 'loop';
    result.muted = true;
    result.volumeControl = false;
    result.playbar = false;
    result.smallPlayButton = false;
    result.settingsControl = false;
    result.fullscreenButton = false;
  }

  return result;
};

const serializePlayerOptions = (options) => {
  return Object.entries(options)
    .map(([key, value]) => `${key}=${value}`)
    .join(' ');
};

const responsivePaddingFromAspectRatio = (aspectRatio) => {
  return `${(1 / aspectRatio) * 100}% 0 0 0`;
};
