import { useCallback } from 'react';

import { usePopups, BRAND_BRIEF_POPUP } from 'src/services/popup';

export const useBrandBriefPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ brandBrief, brandVoice }) => {
      return new Promise((resolve) => {
        popups.openPopup(BRAND_BRIEF_POPUP, {
          brandBrief,
          brandVoice,
          onClose: (result) => {
            popups.closePopup(BRAND_BRIEF_POPUP);
            resolve(result);
          },
        });
      });
    },
    [popups]
  );
};
