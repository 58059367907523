import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query ContinuousOptimizationInstruction($campaignId: ID!) {
    continuousOptimizationInstruction(campaignId: $campaignId) {
      jsSnippet
    }
  }
`;

export function queryCOInstruction(campaignId) {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      campaignId,
    },
  }).then(({ data }) => getSingleProp(data));
}
