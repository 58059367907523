function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');

  // Place in top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '0';

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = '0';

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of white box if rendered for any reason.
  textArea.style.background = 'transparent';

  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    // Do nothing
    console.error(err);
  }

  document.body.removeChild(textArea);
}

/**
 * Copies text to clipboard
 * @param {string} text - text to copy
 * @returns {Promise<void>}
 */
export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return Promise.resolve();
  }

  const writePromise = navigator.clipboard.writeText(text);

  if (writePromise instanceof Promise) {
    return writePromise.catch(() => {
      // Do nothing
    });
  } else {
    fallbackCopyTextToClipboard(text);
    return Promise.resolve();
  }
}

/**
 * Copies HTML and text to clipboard
 * @param {string} htmlContent - HTML content
 * @param {string} textContent - text content
 * @returns {Promise<void>}
 */
export function copyHtmlAndTextToClipboard(htmlContent, textContent) {
  if (!navigator.clipboard || !window.ClipboardItem) {
    fallbackCopyTextToClipboard(textContent);
    return Promise.resolve();
  }

  const clipboardItem = new window.ClipboardItem({
    'text/html': new Blob([htmlContent], { type: 'text/html' }),
    'text/plain': new Blob([textContent], { type: 'text/plain' }),
  });

  const writePromise = navigator.clipboard.write([clipboardItem]);

  if (writePromise instanceof Promise) {
    return writePromise.catch(() => {
      // Do nothing
    });
  } else {
    fallbackCopyTextToClipboard(textContent);
    return Promise.resolve();
  }
}
