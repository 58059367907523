import React from 'react';

import { NOTIFICATION_TYPE } from 'src/data/notifications';
import PartyingFace from 'src/assets/icons/PartyingFace';

const PartyingFaceComponent = <PartyingFace style={{ fontSize: 45 }} />;

const NOTIFICATION_HERO_IMAGE_MAP = {
  [NOTIFICATION_TYPE.growthCampaignDDFeatures]: PartyingFaceComponent,
  [NOTIFICATION_TYPE.basicUsersAccessToDDFeatures]: PartyingFaceComponent,
  [NOTIFICATION_TYPE.basicTieredAccessToDDFeatures]: PartyingFaceComponent,
  [NOTIFICATION_TYPE.walkthroughWebinarBonusWords]: PartyingFaceComponent,
};
export const getHeroImageComponent = (notificationType) =>
  NOTIFICATION_HERO_IMAGE_MAP[notificationType];

export const prepareContentSentences = (message) => message.split('\n');
