import gql from 'graphql-tag';

import { getSingleProp, unwrapEdges } from 'src/lib/graphql';
import { PROJECT_TYPE } from 'src/data/project';
import { executeGqlQuery } from 'src/graphql/client';

const PROJECTS_PAGE_SIZE = 50; // let's fetch 50 projects tops for now

const QUERY = gql`
  query WTMProjectsForCIP(
    $customerId: ID!
    $workspaceId: ID!
    $filter: JSON!
  ) {
    associatedProjects(
      customerId: $customerId
      workspaceId: $workspaceId
      first: ${PROJECTS_PAGE_SIZE}
      filter: $filter
    ) {
      edges {
        node {
          id
          name
          type
          brief {
            ... on UnifiedProductBrief {
              id
              url
            }
          }
          continuousOptimizationCampaign {
            id
            status
          }
          continuousOptimizationSegment {
            id
          }
        }
      }
    }
  }
`;

export const queryWTMProjectsForCIP = async ({ customerId, workspaceId }) => {
  const filter = { type: PROJECT_TYPE.continuousOptimization };
  const variables = {
    customerId,
    filter: JSON.stringify(filter),
    workspaceId,
  };

  const result = await executeGqlQuery({
    query: QUERY,
    variables,
  });
  const projects = getSingleProp(result.data);
  return unwrapEdges(projects?.edges);
};
