import { useCallback } from 'react';

import { useCurrentCustomer } from 'src/store';
import { YOUTUBE_VIDEO_ID } from 'src/const';
import * as events from 'src/lib/events';
import { getCustomerPlan } from 'src/data/subscription';
import { useTrackingEvent } from 'src/services/useTrackingEvent';
import { useExplainerVideoPopup } from 'src/views/ExplainerVideoPopup';

export const useHandleExplainerClick = (onPause, onContinue) => {
  const currentCustomer = useCurrentCustomer();
  const trackingEvent = useTrackingEvent();

  const trackExplainerVideoClick = useCallback(() => {
    trackingEvent(events.WORKSPACES.clickedExplainerVideo, {
      currentPlan: getCustomerPlan(currentCustomer),
    });
  }, [currentCustomer, trackingEvent]);

  const openExplainerVideoPopup = useExplainerVideoPopup(YOUTUBE_VIDEO_ID.explainerVideoWorkspaces);

  return useCallback(() => {
    onPause();
    trackExplainerVideoClick();
    openExplainerVideoPopup(onContinue);
  }, [openExplainerVideoPopup, onPause, onContinue, trackExplainerVideoClick]);
};
