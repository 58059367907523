import { useCallback, useMemo } from 'react';

import { GENERATION_TOOL } from 'src/data/generationTool';
import { useAnalyzerTonesOfVoice } from '../useAnalyzerTonesOfVoice';
import { useAnalyzerToneOfVoiceSelectionPopup, useToneOfVoicePopup } from '../popup';
import { PAGES } from '../useTrackingEvent';

export const useToneOfVoiceProps = ({
  toneOfVoiceInsightsData,
  generationTool,
  applyingAnalyzedToneOfVoice,
  handleApplyAnalyzedToneOfVoice,
  disabled,
  loading,
}) => {
  const { analyzerToneOfVoice, analyzerTonesOfVoice } = useAnalyzerTonesOfVoice(generationTool);
  const openAnalyzerToneOfVoiceSelectionPopup = useAnalyzerToneOfVoiceSelectionPopup();
  const openToneOfVoicePopup = useToneOfVoicePopup();

  const handleSettingsClick = useCallback(() => {
    openAnalyzerToneOfVoiceSelectionPopup(generationTool);
  }, [openAnalyzerToneOfVoiceSelectionPopup, generationTool]);

  const handleOpenToneOfVoicePopup = useCallback(
    ({ toneOfVoice, options }) => {
      openToneOfVoicePopup({
        page: PAGES.editor,
        toneOfVoice,
        ...options,
      });
    },
    [openToneOfVoicePopup]
  );

  return useMemo(() => {
    if (generationTool === GENERATION_TOOL.googleRSA) {
      // google RSA can't be tone of voice analyzed
      return null;
    }
    return {
      onSettingsClick: handleSettingsClick,
      toneOfVoiceInsights: toneOfVoiceInsightsData?.items,
      displayBlogEmptyState: toneOfVoiceInsightsData?.displayBlogEmptyState,
      analyzerToneOfVoice,
      onOpenToneOfVoicePopup: handleOpenToneOfVoicePopup,
      availableTovCount: analyzerTonesOfVoice.length,
      onApplyToneOfVoice: handleApplyAnalyzedToneOfVoice,
      applyingAnalyzedToneOfVoice,
      loading,
      disabled,
    };
  }, [
    generationTool,
    handleSettingsClick,
    toneOfVoiceInsightsData?.items,
    toneOfVoiceInsightsData?.displayBlogEmptyState,
    applyingAnalyzedToneOfVoice,
    handleApplyAnalyzedToneOfVoice,
    analyzerToneOfVoice,
    handleOpenToneOfVoicePopup,
    analyzerTonesOfVoice,
    loading,
    disabled,
  ]);
};
