import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1.5),
      gap: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadius,
      border: theme.shape.borderGray60,
    },
    buttonApply: {
      backgroundColor: COLORS.primary.silver,
      alignSelf: 'flex-end',
      height: 32,
      '&:hover': {
        backgroundColor: COLORS.primary.light,
      },
    },
  }),
  { name: 'ToneOfVoiceInsightsItem' }
);
