import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      borderRadius: '50%',
      backgroundColor: '#ECF8FF',
      height: 40,
      width: 40,
      alignItems: 'center',
      justifyContent: 'center',
    },

    score: {
      color: COLORS.gray.black,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
    },
  }),
  { name: 'Score' }
);
