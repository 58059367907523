// returns string encoded with sha1 algorithm, returns empty string if browser does not support
export const encodeSha1 = async (text) => {
  try {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const hash = await crypto.subtle.digest('SHA-1', data);
    return Array.from(new Uint8Array(hash))
      .map((v) => v.toString(16).padStart(2, '0'))
      .join('');
  } catch (e) {
    return '';
  }
};
