import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { AI_TEXT_SIZE } from 'src/data/generation';

const MUTATION = gql`
  mutation UpdateBlogPostBuilderContent(
    $projectId: ID!
    $title: String!
    $titleScore: Int
    $introParagraph: String!
    $introManualKeywords: [String!]
    $introKeywordsUsage: JSON
    $introInstructions: String
    $introGenerationLength: BlogContentGenerationLengthEnum
  ) {
    updateBlogPostBuilderContent(
      projectId: $projectId
      title: $title
      titleScore: $titleScore
      introParagraph: $introParagraph
      introManualKeywords: $introManualKeywords
      introKeywordsUsage: $introKeywordsUsage
      introInstructions: $introInstructions
      introGenerationLength: $introGenerationLength
    ) {
      id
    }
  }
`;

export const updateBlogPostBuilderContent = ({
  projectId,
  title,
  titleScore,
  introParagraph,
  introManualKeywords,
  introKeywordsUsage,
  instructions,
  introGenerationLength = AI_TEXT_SIZE.medium,
}) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: {
      projectId,
      title,
      titleScore,
      introParagraph,
      introManualKeywords,
      introKeywordsUsage: introKeywordsUsage ? JSON.stringify(introKeywordsUsage) : null,
      introInstructions: instructions,
      introGenerationLength,
    },
  }).then(({ data }) => getSingleProp(data));
};
