import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION } from './schema';

const MUTATION = gql`
  mutation CreateBlogPostBuilderOutlineSection(
    $projectId: ID!
    $headline: String!
    $order: Int!
    $afterSectionId: ID
    $instructions: String
  ) {
    createBlogPostBuilderOutlineSection(
      projectId: $projectId
      headline: $headline
      order: $order
      afterSectionId: $afterSectionId
      instructions: $instructions
    ) {
      ...BlogPostBuilderOutlineSection
    }
  }
  ${FRAGMENT_PROJECT_BLOG_CONTENT_OUTLINE_SECTION}
`;

export const createBlogPostBuilderOutlineSection = ({
  projectId,
  headline,
  order,
  afterSectionId,
  instructions,
}) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { projectId, headline, order, afterSectionId, instructions },
  }).then(({ data }) => getSingleProp(data));
};
