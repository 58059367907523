import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',

      '&$expanded': {
        '& $icon': {
          transform: 'rotate(0deg)',
        },
      },
    },
    expanded: {},

    button: {
      minWidth: 'auto',
      height: 'auto',
      flexShrink: '0',
      padding: '4px 6px',
    },
    icon: {
      fontSize: theme.typography.pxToRem(18),
      color: theme.palette.primary.main,
      transform: 'rotate(-90deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    firstArrow: {
      color: COLORS.neutral.secondary,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    hint: {
      color: '#8E969D',
      fontSize: theme.typography.pxToRem(14),
    },
  }),
  { name: 'ToggledHeader' }
);
