export const PROJECT_CATEGORY = {
  ads: 'ads',
  landingPage: 'landing_page',
  websitePersonalization: 'website_personalization',
  productDescription: 'product_description',
  productListing: 'product_listing',
  social: 'social',
  blog: 'blog',
  email: 'email',
  sms: 'sms',
  canvas: 'canvas',
};

export const ADS_PROJECT_TYPE = {
  facebookAd: 'facebook_ad',
  googleAd: 'google_ad',
  taboolaAd: 'taboola_ad',
  outbrainAd: 'outbrain_ad',
  linkedinAd: 'linkedin_ad',
  twitterAd: 'twitter_ad',
  pinterestAd: 'pinterest_ad',
  teadsSingleImage: 'teads_single_image',
  teadsSingleVideo: 'teads_single_video',
  teadsCarousel: 'teads_carousel',
  teadsSocialLayout1: 'teads_social_layout_1',
  teadsSocialLayout2: 'teads_social_layout_2',
};

export const CANVAS_PROJECT_TYPE = {
  canvas: 'canvas',
};

export const PRODUCT_LISTING_PROJECT_TYPE = {
  amazonProductDescription: 'amazon_product_description',
  walmartProductListing: 'walmart_product_listing',
};

export const SOCIAL_PROJECT_TYPE = {
  facebookSocial: 'facebook_social',
  instagramSocial: 'instagram_social',
  twitterSocial: 'twitter_social',
  linkedinSocial: 'linkedin_social',
  pinterestSocial: 'pinterest_social',
  youtubeSocial: 'youtube_social',
};

export const EMAIL_PROJECT_TYPE = {
  emailSubjectLine: 'email_subject_line',
  emailPromotional: 'email_promotional',
  emailColdOutreach: 'email_cold_outreach',
};

export const SMS_PROJECT_TYPE = {
  sms: 'sms',
};

export const SHOPIFY_PROJECT_TYPE = {
  shopifyLandingPage: 'shopify_landing_page',
  shopifyProduct: 'shopify_product',
  shopifyProductsDescription: 'shopify_products_description',
};

export const DEPRECATED_PROJECT_TYPE = {
  blogPost: 'blog_post',
  productDescription: 'product_description',
  featuresToBenefits: 'features_to_benefits',
  seoMetaDescription: 'seo_meta_description',
  landingPageBuilder: 'landing_page_builder',
  ...CANVAS_PROJECT_TYPE,
  ...ADS_PROJECT_TYPE,
  ...PRODUCT_LISTING_PROJECT_TYPE,
  ...SOCIAL_PROJECT_TYPE,
  ...EMAIL_PROJECT_TYPE,
  ...SMS_PROJECT_TYPE,
  ...SHOPIFY_PROJECT_TYPE,
};

export const ACTIVE_PROJECT_TYPE = {
  document: 'document_editor',
  blogPostBuilder: 'blog_post_builder',
  continuousOptimization: 'product_landing_page_url',
  wtmHomePage: 'wtm_home_page',
};

/**
 * Project type
 * @enum {string}
 */
export const PROJECT_TYPE = {
  ...ACTIVE_PROJECT_TYPE,
  ...DEPRECATED_PROJECT_TYPE,
};

export const PROJECT_STATUS = {
  created: 'created',
  submitted: 'submitted',
  done: 'done',
  doneWithErrors: 'done_with_errors',
  briefApprovalWaits: 'brief_waits_ac_content',
  briefProcessingError: 'brief_processing_error',
  blogDraft: 'blog_draft',
  blogEditing: 'blog_editing',
  legacy: 'legacy',
};

export const PROJECT_READONLY_HINT = 'This project is now view-only';

export const BAREBONE_PROJECT_ID = 'barebone_project';

export const ACCESS_STATE_TYPE = {
  read_only: 'read_only',
  read_write: 'read_write',
};

export const PROJECT_STATE = {
  active: 'active',
  archived: 'archived',
};

export const DEFAULT_DOCUMENT_NAME = 'Untitled document';

export const DEFAULT_BLOG_NAME = 'Untitled blog project';

export const PROJECT_GENERATE_FROM = {
  description: 'description',
  url: 'url',
};

export const PROJECTS_FILTER = {
  currentUser: 'currentUser',
  allProjects: 'allProjects',
};

export const ERROR_LOADING =
  'Your projects can’t be fetched due to a temporary issue. Please try again later.';
export const ACTIONS_LOCATION = {
  blog: 'blog',
  document: 'document',
};

export const CAPITALIZATION_LABEL = 'Capitalization';
