import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_BRAND_VOICE } from './schema';

const MUTATION = gql`
  mutation CreateBrandVoice($name: String!, $customerId: ID!, $workspaceId: ID) {
    createBrandVoice(name: $name, customerId: $customerId, workspaceId: $workspaceId) {
      ...BrandVoice
    }
  }
  ${FRAGMENT_BRAND_VOICE}
`;

export const createBrandVoice = ({ name, customerId, workspaceId }) => {
  const variables = {
    name,
    customerId,
    workspaceId,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
