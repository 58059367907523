import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconEmailTool = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 -2 18 20"
      data-fill-none
    >
      <path
        d="M13.7826 3H2.21739C1.89452 3 1.58487 3.1317 1.35657 3.36612C1.12826 3.60054 1 3.91848 1 4.25V11.75C1 12.0815 1.12826 12.3995 1.35657 12.6339C1.58487 12.8683 1.89452 13 2.21739 13H13.7826C14.1055 13 14.4151 12.8683 14.6434 12.6339C14.8717 12.3995 15 12.0815 15 11.75V4.25C15 3.91848 14.8717 3.60054 14.6434 3.36612C14.4151 3.1317 14.1055 3 13.7826 3Z"
        fill="#E3E3E3"
      />
      <path
        d="M2.18675 3C1.87201 3 1.57015 3.1317 1.34759 3.36612C1.12503 3.60054 1 3.91848 1 4.25V11.75C1 12.0815 1.12503 12.3995 1.34759 12.6339C1.57015 12.8683 1.87201 13 2.18675 13H2.50599L12 3H2.18675Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.998371 3.01727C1.31936 2.68767 1.75758 2.5 2.21739 2.5H13.7826C14.2424 2.5 14.6806 2.68767 15.0016 3.01727C15.3222 3.34641 15.5 3.79007 15.5 4.25V11.75C15.5 12.2099 15.3222 12.6536 15.0016 12.9827C14.6806 13.3123 14.2424 13.5 13.7826 13.5H2.21739C1.75758 13.5 1.31936 13.3123 0.998371 12.9827C0.677815 12.6536 0.5 12.2099 0.5 11.75V4.25C0.5 3.79007 0.677815 3.34641 0.998371 3.01727ZM2.21739 3.5C2.03146 3.5 1.85038 3.57572 1.71476 3.71497C1.57871 3.85467 1.5 4.04689 1.5 4.25V11.75C1.5 11.9531 1.57871 12.1453 1.71476 12.285C1.85038 12.4243 2.03146 12.5 2.21739 12.5H13.7826C13.9685 12.5 14.1496 12.4243 14.2852 12.285C14.4213 12.1453 14.5 11.9531 14.5 11.75V4.25C14.5 4.04689 14.4213 3.85467 14.2852 3.71497C14.1496 3.57572 13.9685 3.5 13.7826 3.5H2.21739Z"
        fill="#191919"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.11832 3.17704C1.29669 2.96624 1.61218 2.93995 1.82298 3.11832L8.00001 8.34503L14.177 3.11832C14.3878 2.93995 14.7033 2.96624 14.8817 3.17704C15.0601 3.38784 15.0338 3.70333 14.823 3.8817L8.32298 9.3817C8.13656 9.53945 7.86346 9.53945 7.67704 9.3817L1.17704 3.8817C0.966236 3.70333 0.939945 3.38784 1.11832 3.17704Z"
        fill="#191919"
      />
    </SvgIcon>
  ))
);
