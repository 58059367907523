import { useMemo } from 'react';
import { timeout } from 'src/lib/helpers';
import { useCurrentCustomer, useCustomerActions } from 'src/store';
import * as env from 'src/lib/env';
import { useAfterCancelSubscriptionPopup } from '../popup';

const CHURNKEY_APP_ID = env.getChurnkeyAppId();
const CHURNKEY_MODE = env.getChurnkeyMode();

export const useChurnkey = () => {
  const { stripeCustomerId, churnkeyHash, billingConfiguration } = useCurrentCustomer() || {};
  const { stripeId } = billingConfiguration?.subscriptionConfig || {};
  const customerActions = useCustomerActions();
  const openAfterCancelSubscriptionPopup = useAfterCancelSubscriptionPopup();

  return useMemo(() => {
    const options = {
      subscriptionId: stripeId,
      customerId: stripeCustomerId,
      authHash: churnkeyHash,
      appId: CHURNKEY_APP_ID,
      mode: CHURNKEY_MODE,
      provider: 'stripe',
    };

    const refreshCurrentCustomer = async () => {
      const BE_PROCESSING_CHURNKEY_REQUEST_TIMEOUT = 5_000;

      await timeout(BE_PROCESSING_CHURNKEY_REQUEST_TIMEOUT);
      customerActions.refreshCurrentCustomer();
    };
    const customHandler = () => {
      return new Promise((resolve, reject) => {
        try {
          // We want to disable churnkey Stripe actions mechanism and only use BE webhooks
          // thats why we use this empty function to override Stripes default mechanism

          resolve({});
        } catch (e) {
          reject({});
        }
      });
    };

    const initShow = () =>
      window.churnkey?.init('restart', {
        ...options,
        handlePause: customHandler,
        handleCancel: customHandler,
        onPause: () => {
          refreshCurrentCustomer();
        },
        onCancel: () => {
          refreshCurrentCustomer();
        },
        onDiscount: () => {
          refreshCurrentCustomer();
        },
        onGoToAccount: () => {
          openAfterCancelSubscriptionPopup();
        },
        i18n: {
          messages: {
            en: {
              goToAccount: 'Continue',
            },
          },
        },
      });

    const hide = () => {
      // hide can be called before churnkey is initialized
      window.churnkey?.hide?.();
      window.churnkey?.hidePauseWall?.();
    };

    const checkPause = () => {
      window.churnkey?.check('pause', options);
    };

    return {
      initShow,
      checkPause,
      hide,
    };
  }, [churnkeyHash, stripeCustomerId, stripeId, customerActions, openAfterCancelSubscriptionPopup]);
};
