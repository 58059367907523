import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query ProjectStatus($projectId: Int!) {
    project(id: $projectId) {
      id
      status
      errors
    }
  }
`;

export const queryProjectStatus = (projectId) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
    },
  }).then(({ data }) => getSingleProp(data));
