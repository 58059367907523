import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation PerformanceCenterConnectAccount(
    $accountId: ID!
    $customerId: ID!
    $workspaceId: ID
    $dataSource: PerformanceCenterDataSource!
    $customModelBrandVoiceId: ID
    $createTalkingPoints: Boolean
  ) {
    performanceCenterConnectAccount(
      accountId: $accountId
      customerId: $customerId
      workspaceId: $workspaceId
      dataSource: $dataSource
      customModelBrandVoiceId: $customModelBrandVoiceId
      createTalkingPoints: $createTalkingPoints
    ) {
      customDatasetId
      id
    }
  }
`;

export const performanceCenterConnectAccount = ({
  accountId,
  customerId,
  workspaceId,
  dataSource,
  customModelBrandVoiceId,
  createTalkingPoints = false,
}) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: {
      accountId,
      customerId,
      workspaceId,
      dataSource,
      customModelBrandVoiceId,
      createTalkingPoints,
    },
  }).then(({ data }) => getSingleProp(data));
};
