import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  () => ({
    root: {
      position: 'fixed',
      minWidth: '100vw',
      minHeight: '100vh',
      mixBlendMode: 'darken',
      pointerEvents: 'auto',
    },

    spotlight: {},
    circle: {},
    rounded: {},
  }),
  { name: 'Backdrop' }
);
