import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';
import { FRAGMENT_RECOMPOSE_REQUEST, FRAGMENT_SUGGEST_REQUEST } from 'src/graphql/generation';

const QUERY = gql`
  query ProjectGenerationResults($projectId: Int!, $assetType: String, $cursor: String) {
    project(id: $projectId) {
      generationRequests(assetType: $assetType, cursor: $cursor) {
        edges {
          node {
            ... on RecomposeRequest {
              ...RecomposeRequest
            }
            ... on SuggestionRequest {
              ...SuggestionRequest
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
  ${FRAGMENT_RECOMPOSE_REQUEST}
  ${FRAGMENT_SUGGEST_REQUEST}
`;

export const queryGenerationResults = (projectId, assetType, cursor) =>
  executeGqlQuery({
    query: QUERY,
    variables: {
      projectId,
      assetType,
      cursor,
    },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => result?.generationRequests);
