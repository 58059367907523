import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useSvgId } from 'src/lib/hooks';

export const MetaIcon = memo(
  forwardRef((props, ref) => {
    const svgId1 = useSvgId();
    const svgId2 = useSvgId();
    const svgId3 = useSvgId();

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 62 40"
      >
        <g clipPath={`url(#${svgId1})`}>
          <path
            d="M7.39363 26.3874C7.39363 28.6911 7.89834 30.4524 8.55803 31.5204C8.90957 32.1285 9.4158 32.6327 10.0253 32.9817C10.6348 33.3308 11.3258 33.5123 12.0282 33.5079C13.7245 33.5079 15.2764 33.089 18.2669 28.9508C20.6627 25.6356 23.4858 20.9927 25.3873 18.0607L28.6041 13.1183C30.8387 9.68586 33.4209 5.87016 36.3905 3.27539C38.8093 1.17277 41.4209 0 44.0491 0C48.4617 0 52.6648 2.55707 55.8816 7.35288C59.402 12.6052 61.1172 19.2209 61.1172 26.0482C61.1172 30.1068 60.3172 33.089 58.9559 35.445C57.6428 37.7236 55.0711 40 50.7591 40V33.5079C54.4512 33.5079 55.3664 30.1152 55.3664 26.2325C55.3664 20.6995 54.0763 14.5592 51.2345 10.1717C49.2177 7.05969 46.6041 5.15811 43.7287 5.15811C40.6188 5.15811 38.1161 7.50366 35.3036 11.6859C33.8083 13.9079 32.2732 16.6157 30.5497 19.6712L28.6523 23.022C24.8407 29.7801 23.8753 31.3194 21.9695 33.8597C18.6355 38.3246 15.7832 40 12.0282 40C7.57373 40 4.75698 38.0712 3.02295 35.1644C1.58839 32.7958 0.888916 29.688 0.888916 26.1466L7.39363 26.3874Z"
            fill="#0081FB"
          />
          <path
            d="M6.01782 7.81152C9.00002 3.21466 13.3037 0 18.2398 0C21.0985 0 23.9403 0.837696 26.9079 3.26911C30.1539 5.91832 33.6094 10.2806 37.9299 17.4702L39.4775 20.0503C43.2136 26.2743 45.3414 29.4743 46.5833 30.9864C48.1833 32.9257 49.3058 33.4995 50.7592 33.4995C54.4513 33.4995 55.3665 30.1068 55.3665 26.2241L61.1047 26.044C61.1047 30.1026 60.3047 33.0848 58.9435 35.4408C57.643 37.7236 55.0712 40 50.7592 40C48.0786 40 45.7037 39.4178 43.0775 36.9403C41.0587 35.0387 38.6984 31.6607 36.8827 28.6241L31.4817 19.6021C28.7718 15.0743 26.2859 11.6984 24.8471 10.178C23.2974 8.52565 21.31 6.54031 18.1351 6.54031C15.5655 6.54031 13.3833 8.34346 11.5571 11.1016L6.01782 7.81152Z"
            fill={`url(#${svgId2})`}
          />
          <path
            d="M18.135 6.54031C15.5654 6.54031 13.3832 8.34346 11.557 11.1016C8.97478 14.999 7.39363 20.8042 7.39363 26.3874C7.39363 28.6911 7.89834 30.4524 8.55803 31.5204L3.01248 35.1644C1.58839 32.7958 0.888916 29.688 0.888916 26.1466C0.888916 19.7068 2.65646 12.9948 6.01771 7.81152C8.99991 3.21466 13.3036 0 18.2397 0L18.135 6.54031Z"
            fill={`url(#${svgId3})`}
          />
        </g>
        <defs>
          <linearGradient
            id={svgId2}
            x1="13.9445"
            y1="18.9634"
            x2="55.4105"
            y2="21.0576"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0064E1" />
            <stop
              offset="0.4"
              stopColor="#0064E1"
            />
            <stop
              offset="0.83"
              stopColor="#0073EE"
            />
            <stop
              offset="1"
              stopColor="#0082FB"
            />
          </linearGradient>
          <linearGradient
            id={svgId3}
            x1="9.56326"
            y1="29.1099"
            x2="9.56326"
            y2="13.822"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0082FB" />
            <stop
              offset="1"
              stopColor="#0064E0"
            />
          </linearGradient>
          <clipPath id={svgId1}>
            <rect
              width="60.222"
              height="40"
              fill="white"
              transform="translate(0.888916)"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
