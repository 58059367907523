import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const UnlinkIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5435 10.2006C12.3575 10.2006 12.1715 10.1294 12.0297 9.9877C11.7455 9.7036 11.7455 9.24425 12.0297 8.96015L13.4977 7.49214C14.1671 6.82932 14.5347 5.94415 14.5347 4.99724C14.5347 3.05032 12.9504 1.46534 11.0028 1.46534C10.0566 1.46534 9.17143 1.83301 8.51022 2.50022L7.03994 3.97035C6.75584 4.25459 6.29649 4.25459 6.01239 3.97035C5.72829 3.68625 5.72829 3.2269 6.01239 2.9428L7.4804 1.47413C8.41494 0.532271 9.66645 0.0118408 11.0028 0.0118408C13.752 0.0118408 15.9882 2.24805 15.9882 4.99724C15.9882 6.33511 15.4678 7.58582 14.5224 8.52262L13.0566 9.9877C12.9149 10.1294 12.7295 10.2006 12.5435 10.2006Z"
          fill="#6F80A2"
        />
        <path
          d="M4.98552 16C2.23633 16 0.00012207 13.7638 0.00012207 11.0146C0.00012207 9.6767 0.520552 8.42599 1.466 7.48919L2.93175 6.02411C3.21598 5.74001 3.6752 5.74001 3.95943 6.02411C4.24353 6.30821 4.24353 6.76756 3.95943 7.05166L2.49143 8.51967C1.82129 9.18248 1.45363 10.0677 1.45363 11.0146C1.45363 12.9615 3.03794 14.5465 4.98552 14.5465C5.93177 14.5465 6.81694 14.1788 7.47816 13.5116L8.94843 12.0415C9.23253 11.7572 9.69188 11.7572 9.97598 12.0415C10.2601 12.3256 10.2601 12.7849 9.97598 13.069L8.50797 14.5378C7.57343 15.4795 6.32206 16 4.98552 16Z"
          fill="#6F80A2"
        />
        <path
          d="M9.5349 7.19192C9.34887 7.19192 9.16284 7.12073 9.02112 6.97901C8.73702 6.69491 8.73702 6.23556 9.02112 5.95146L10.3873 4.58525C10.6716 4.30101 11.1308 4.30101 11.415 4.58525C11.6991 4.86935 11.6991 5.3287 11.415 5.6128L10.0487 6.97901C9.90696 7.12073 9.72093 7.19192 9.5349 7.19192Z"
          fill="#6F80A2"
        />
        <path
          d="M5.0873 11.6396C4.90127 11.6396 4.71511 11.5684 4.57353 11.4267C4.28929 11.1424 4.28929 10.6832 4.57353 10.399L5.93974 9.03278C6.22384 8.74868 6.68319 8.74868 6.96729 9.03278C7.25152 9.31688 7.25152 9.77623 6.96729 10.0603L5.60108 11.4267C5.45936 11.5684 5.27333 11.6396 5.0873 11.6396Z"
          fill="#6F80A2"
        />
        <path
          d="M15.2615 15.9883C15.0754 15.9883 14.8894 15.9171 14.7477 15.7754L0.213175 1.24073C-0.0710584 0.956627 -0.0710584 0.497276 0.213175 0.213175C0.497276 -0.0710584 0.956494 -0.0710584 1.24073 0.213175L15.7754 14.7477C16.0595 15.0319 16.0595 15.4911 15.7754 15.7754C15.6337 15.9171 15.4475 15.9883 15.2615 15.9883Z"
          fill="#6F80A2"
        />
      </SvgIcon>
    );
  })
);
