import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1.5),

      '&$vertical': {
        flexDirection: 'row',

        '& $label': {
          fontSize: theme.typography.pxToRem(12),
          lineHeight: theme.typography.pxToRem(16),
          fontWeight: theme.typography.fontWeightRegular,
        },
      },

      '&:not($vertical)': {
        '& $button': {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
      },
    },
    vertical: {},

    button: {},
    label: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      fontWeight: theme.typography.fontWeightSemiBold,
      color: COLORS.success.active,
    },
  }),
  { name: 'BusinessBookDemoButton' }
);
