import gql from 'graphql-tag';

import { VARIATION_FRAGMENT } from 'src/graphql/variation';
import { CUSTOMER_PERSONA_FRAGMENT } from 'src/graphql/customerPersona';
import { FRAGMENT_CUSTOM_TONE_OF_VOICE_TOV_TRAIT } from 'src/graphql/toneOfVoice';

export const FRAGMENT_RECOMPOSE_REQUEST = gql`
  fragment RecomposeRequest on RecomposeRequest {
    id
    originalVariationId
    originalVariationScore
    generationTool
    assetType
    briefTitle
    briefBody
    emailPromotion
    productName
    inputTexts
    genderTone
    agePreset
    recomposeMode
    keywords
    tovId
    tovTitle
    enterpriseModel {
      id
      title
    }
    generationGuidance {
      keywords
      campaignKeywords
      features
      benefits
      pains
      avatar
      tov
      genGuideFreeform
      avoidWords
    }
    language
    languageTargetFormality
    contentTaxonomyId
    createdAt
    sourceType
    variations {
      ...Variation
    }
    tov {
      customName
      customId
      defaultPreset
      manualAttributes
      draftTov {
        traits {
          ...TovTrait
        }
        personality
        description
      }
    }
  }
  ${VARIATION_FRAGMENT}
  ${FRAGMENT_CUSTOM_TONE_OF_VOICE_TOV_TRAIT}
`;

export const FRAGMENT_PROMPT_BLOCK = gql`
  fragment PromptBlock on PromptBlock {
    blockId
    generationTool
    prompt
  }
`;

export const FRAGMENT_PROMPT_BLOCKS = gql`
  fragment PromptBlocks on PromptBlocks {
    chainId
    csvChainId
    blocks {
      ...PromptBlock
    }
  }
  ${FRAGMENT_PROMPT_BLOCK}
`;

export const FRAGMENT_SUGGEST_REQUEST = gql`
  fragment SuggestionRequest on SuggestionRequest {
    id
    generationTool
    assetType
    briefTitle
    briefBody
    emailPromotion
    keywords
    suggestionMode
    link
    inputText
    originalVariationId
    originalVariationScore
    personalizedSamples
    personalizedName
    personalizedDescription
    customFormulaName
    customTovName
    enterpriseModel {
      id
      title
    }
    enterpriseScoringModel {
      id
      title
    }
    tov {
      customName
      customId
      defaultPreset
      manualAttributes
      draftTov {
        traits {
          ...TovTrait
        }
        personality
        description
      }
    }
    suggestionAnnotationType
    suggestionAnnotationContent
    suggestionAnnotationMinAge
    suggestionAnnotationMaxAge
    language
    languageTargetFormality
    createdAt
    contentTaxonomyId
    freestyleToolId
    freestyleToolAllInstructions
    freestyleToolAllInstructionsData
    groupId
    sourceType
    generationGuidance {
      keywords
      campaignKeywords
      features
      benefits
      pains
      avatar
      tov
      genGuideFreeform
      avoidWords
      hashtags
      url
      copyPurpose
      casing
    }
    customerPersona {
      ...CustomerPersona
    }

    variations {
      ...Variation
    }
    mainFewerReceivedReason
    subGenerationToolsProps
    chainId
    csvChainId
    chainGenerationTool
    blockId
    multiPromptStepIndex
    multiPromptStepName
    customMultiPromptId
    customGenerationModel {
      accountName
      accountId
      id
    }
    promptImageName
    promptDocumentName
    promptImageNames
    promptDocumentNames
    isGenerating
    csvMetaData
    maxChars
  }
  ${VARIATION_FRAGMENT}
  ${CUSTOMER_PERSONA_FRAGMENT}
  ${FRAGMENT_CUSTOM_TONE_OF_VOICE_TOV_TRAIT}
`;

export const FRAGMENT_CONTENT_VALIDATION = gql`
  fragment ContentValidation on ContentValidation {
    label
    reason
    reasonDetails
  }
`;
