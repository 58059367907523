import * as env from 'src/lib/env';

const CHURNKEY_APP_ID = env.getChurnkeyAppId();

export const injectChurnkeyScript = () => {
  if (!window.churnkey || !window.churnkey.created) {
    window.churnkey = { created: true };

    const js = document.createElement('script');
    js.src = `https://assets.churnkey.co/js/app.js?appId=${CHURNKEY_APP_ID}`;
    js.async = true;

    const scriptEl = document.getElementsByTagName('script')[0];
    scriptEl.parentNode.insertBefore(js, scriptEl);
  }
};
