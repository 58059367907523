import React from 'react';
import { Helmet } from 'react-helmet-async';

import { SuspenseFallback } from 'src/components/SuspenseFallback';
import ErrorBoundary from 'src/services/ErrorBoundary';
import { lazyWithRetry } from 'src/utils/lazy';
import { NavigationBar } from 'src/views/NavigationBar';

const AsyncPage = lazyWithRetry(() => import(/* webpackChunkName: "CheckoutPage" */ './Container'));

export const CheckoutPage = (props) => (
  <ErrorBoundary>
    <Helmet>
      <title>Checkout</title>
    </Helmet>

    <NavigationBar
      displayBackButton
      displayUserItems
    />

    <React.Suspense fallback={<SuspenseFallback />}>
      <AsyncPage {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
