import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateProjectTaxonomy($id: Int!, $contentTaxonomyId: ID) {
    updateProject(id: $id, contentTaxonomyId: $contentTaxonomyId) {
      id
    }
  }
`;

export const updateProjectTaxonomy = (id, contentTaxonomyId) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { id, contentTaxonomyId },
  }).then(({ data }) => getSingleProp(data));
};
