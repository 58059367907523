import { useMemo } from 'react';
import { flatMap } from 'lodash';

import { createStateSelectorHook } from 'src/lib/sweetState';
import { PerformanceStore } from 'src/store';

const usePerformanceState = createStateSelectorHook(PerformanceStore, ['lastExtractionDate']);

/**
 * Provide the newest last extraction date from the performance store
 * @returns {string} - The newest last extraction date
 */
export const useNewestLastExtractionDate = () => {
  const { lastExtractionDate } = usePerformanceState();
  return useMemo(() => {
    // Flatten "lastExtractionDate" object to array of string dates
    const stringDates = flatMap(lastExtractionDate, (field) => flatMap(field));
    // Take the newest date
    return stringDates.reduce((acc, current) => (current && current > acc ? current : acc), '');
  }, [lastExtractionDate]);
};
