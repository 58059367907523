import React, { useCallback } from 'react';

import { PRIVACY_SETTINGS_FEATURES } from 'src/data/privacy';
import { useCurrentCustomer, useNotificationActions } from 'src/store';
import { ROUTE_PARAMS } from 'src/routes';

const FEATURE_TO_TEXT_MAPPER = {
  [PRIVACY_SETTINGS_FEATURES.TRAINING_DATA]: 'train other models using your data',
  [PRIVACY_SETTINGS_FEATURES.EXTERNAL_LLM]: 'access to public LLMs',
};

const getNotificationTexts = (feature) => {
  switch (feature) {
    case PRIVACY_SETTINGS_FEATURES.EXTERNAL_LANGUAGE_TRANSLATION:
      return {
        description: [
          'For languages other than English, please enable ',
          <i key={1}>external language translation</i>,
          ' in your',
        ],
        linkText: 'privacy settings',
      };
    case PRIVACY_SETTINGS_FEATURES.EXTERNAL_PLAGIARISM_DETECTION:
      return {
        description: ['Please enable ', <i key={1}>external plagiarism checking</i>, ' in your'],
        linkText: 'privacy settings',
      };
    case PRIVACY_SETTINGS_FEATURES.EXTERNAL_WEB_SEARCH:
      return {
        description: [
          'To get facts and statistics, please enable ',
          <i key={1}>external web search engines</i>,
          ' in your',
        ],
        linkText: 'privacy settings',
      };
    default:
      return {
        description: `Change privacy settings to ${FEATURE_TO_TEXT_MAPPER[feature]}.`,
        linkText: 'Open settings',
      };
  }
};

export const useCustomerPrivacySettings = () => {
  const currentCustomer = useCurrentCustomer();
  const notifications = useNotificationActions();

  const privacySettings = currentCustomer?.privacySettings;
  const isFeatureRestricted = useCallback(
    (feature, displayNotification = true) => {
      const { description, linkText } = getNotificationTexts(feature);

      // In case privacySettings is defined, verify if the feature is restricted - display a notification
      if (privacySettings && !privacySettings[feature]) {
        if (displayNotification) {
          notifications.displayWarning(
            <>
              <span>{description}</span>{' '}
              <a
                href={`/settings?${ROUTE_PARAMS.scrollTo}=privacy_settings`}
                target="_blank"
                rel="noreferrer"
              >
                {linkText}
              </a>
            </>
          );
        }

        return true;
      }
      return false;
    },
    [privacySettings, notifications]
  );

  return { isFeatureRestricted };
};
