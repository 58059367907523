import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

import { STONLY_ID } from 'src/lib/stonly';
import { isEnglishInput } from 'src/lib/regex';
import { StonlyIcon } from 'src/components/StonlyIcon';
import { getFleschReadingEaseScore, getScoreDescription } from '../../lib';
import { InsightBox } from '../InsightBox';
import { Score } from './components/Score';
import { CopyMetadata } from './components/CopyMetadata';
import { useStyles } from './useStyles';

export const ReadabilityScore = ({ copyText }) => {
  const styles = useStyles();

  const { score, subtitle } = useMemo(() => {
    const isEnglishText = isEnglishInput(copyText, true);
    const score = isEnglishText ? getFleschReadingEaseScore(copyText) : '--';
    const subtitle = isEnglishText
      ? getScoreDescription(score, true)?.description
      : 'This readability score only applies to English content';

    return { score, subtitle };
  }, [copyText]);

  return (
    <InsightBox
      title={
        <>
          Readability score
          <StonlyIcon
            className={styles.stonlyHintIcon}
            stonlyId={STONLY_ID.readabilityScore}
          />
        </>
      }
      subtitle={subtitle}
      topRightSlot={<Score score={score} />}
    >
      <Divider className={styles.divider} />

      <CopyMetadata copyText={copyText} />
    </InsightBox>
  );
};

ReadabilityScore.propTypes = {
  copyText: PropTypes.string,
};

ReadabilityScore.defaultProps = {
  copyText: null,
};
