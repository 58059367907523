import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query CurrentUserIntegrationAccounts {
    me {
      id

      integrationAccounts {
        status
        accountId
        accountName
        accountType
        connectedOn
      }
    }
  }
`;

export const queryCurrentUserIntegrationAccounts = (options) => {
  return executeGqlQuery({
    query: QUERY,
    options: {
      abortSignal: options?.abortSignal,
    },
  }).then(({ data }) => getSingleProp(data));
};
