import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation SendResetPasswordRequest($email: String!) {
    sendResetPasswordRequest(email: $email) {
      id
      status
    }
  }
`;

export const sendResetPasswordRequest = (email) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { email },
  }).then(({ data }) => getSingleProp(data));
