import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconWalmart = memo(
  forwardRef((props, ref) => (
    <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
      <circle cx="15" cy="15" r="12" fill="#1D7AD9" />
      <path
        d="M14.9969 7C14.3992 7 13.9253 7.34072 13.9253 7.75431L14.2889 11.9394C14.3276 12.1915 14.6313 12.3865 14.9988 12.3865C15.3663 12.3865 15.6681 12.1896 15.7087 11.9394L16.0743 7.75431C16.0743 7.34072 15.5965 7.00197 14.9988 7.00197L14.9969 7ZM9.08181 10.3481C8.89387 10.3733 8.71487 10.445 8.56043 10.5569C8.406 10.6689 8.28085 10.8176 8.1959 10.9902C7.89609 11.518 7.94832 12.1068 8.29842 12.3136L12.0393 14.0862C12.2734 14.1748 12.5906 14.0054 12.7744 13.6923C12.9678 13.3673 12.9446 13.003 12.7512 12.8415L9.37195 10.4308C9.28363 10.3799 9.18326 10.3547 9.08181 10.358V10.3481ZM20.9139 10.3481C20.8124 10.3448 20.712 10.37 20.6237 10.421L17.2445 12.8434C17.0511 13.0049 17.0375 13.3693 17.2213 13.6942C17.4051 14.0172 17.7223 14.1866 17.9563 14.0881L21.6972 12.3156C22.0512 12.1088 22.1015 11.5278 21.8036 10.9921C21.717 10.82 21.5902 10.6722 21.4344 10.5617C21.2786 10.4511 21.0985 10.3811 20.91 10.358L20.9139 10.3481ZM12.1805 15.8626C12.133 15.8603 12.0855 15.8684 12.0413 15.8863L8.30036 17.6706C7.94832 17.8794 7.89609 18.4584 8.1959 18.9961C8.49572 19.5337 9.02185 19.772 9.37195 19.5652L12.7512 17.1585C12.9446 16.9951 12.9601 16.6307 12.7763 16.3077C12.72 16.1915 12.6359 16.0915 12.5318 16.0168C12.4278 15.9422 12.307 15.8953 12.1805 15.8804V15.8626ZM17.8171 15.8626C17.6906 15.8775 17.5698 15.9245 17.4658 15.9991C17.3617 16.0738 17.2776 16.1738 17.2213 16.29V16.29C17.0279 16.613 17.053 16.9774 17.2464 17.1408L20.6256 19.5692C20.9757 19.7661 21.5019 19.5258 21.8036 19C22.1054 18.4742 22.0512 17.8833 21.6972 17.6745L17.9563 15.8981C17.912 15.8804 17.8646 15.8723 17.8171 15.8744V15.8626ZM14.9969 17.6155C14.6313 17.6155 14.3276 17.8124 14.2889 18.0606L13.9253 22.2457C13.9253 22.6593 14.4011 23 14.9988 23C15.5965 23 16.0743 22.6593 16.0743 22.2457L15.7087 18.0645C15.6681 17.8124 15.3663 17.6194 14.9988 17.6194L14.9969 17.6155Z"
        fill="#FFC220"
      />
    </SvgIcon>
  ))
);
