import { useCallback } from 'react';

import { usePopups } from '../services';
import { DOWNGRADE_CONFIRMATION_PLAN } from '../types';

export const useDowngradePlanConfirmationPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ plan, tier, isFromMonthlyToAnnually }) => {
      return new Promise((resolve, reject) => {
        popups.openPopup(DOWNGRADE_CONFIRMATION_PLAN, {
          plan,
          tier,
          isFromMonthlyToAnnually,
          onConfirm: () => {
            popups.closePopup(DOWNGRADE_CONFIRMATION_PLAN);
            resolve();
          },
          onClose: () => {
            popups.closePopup(DOWNGRADE_CONFIRMATION_PLAN);
            reject();
          },
        });
      });
    },
    [popups]
  );
};
