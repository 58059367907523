import IconApps from '@material-ui/icons/Apps';

import {
  SOCIAL_CHANNEL,
  NATIVE_CHANNEL,
  WEBSITE_CHANNEL,
  EMAILS_CHANNEL,
  PLATFORM_CHANNEL,
  REVIEWS_CHANNEL,
} from 'src/data/channel';
import { RESOURCE_DOCUMENT, isEmailCsvResourceDocument } from 'src/data/performance';
import IconPlatformYoutube from 'src/assets/icons/PlatformYoutube';
import IconChannelTwitter from 'src/assets/icons/ChannelTwitter';
import IconChannelInstagram from 'src/assets/icons/ChannelInstagram';
import IconChannelGoogle from 'src/assets/icons/ChannelGoogle';
import IconChannelTaboola from 'src/assets/icons/ChannelTaboola';
import IconChannelOutbrain from 'src/assets/icons/ChannelOutbrain';
import IconChannelRevcontent from 'src/assets/icons/ChannelRevcontent';
import IconChannelVerizon from 'src/assets/icons/ChannelVerizon';
import { ChannelPinterest } from 'src/assets/icons/ChannelPinterest';
import { IconChannelLinkedin } from 'src/assets/icons/IconChannelLinkedin';
import { MetaIcon } from 'src/assets/icons/ChannelMeta';
import { ChannelLandingPagesIcon } from 'src/assets/icons/ChannelLandingPages';
import { ChannelHomepageIcon } from 'src/assets/icons/ChannelHomepage';
import { ChannelHubspotIcon } from 'src/assets/icons/ChannelHubspot';
import { ChannelThoughtLeadershipIcon } from 'src/assets/icons/ChannelThoughtLeadership';
import { ChannelCompetitorsIcon } from 'src/assets/icons/ChannelCompetitors';
import { ChannelFacebookPages } from 'src/assets/icons/ChannelFacebookPages';
import { ChannelProductPageIcon } from 'src/assets/icons/ChannelProductPage';
import { IconEmail } from 'src/assets/icons/IconEmail';
import { IconDocument } from 'src/assets/icons/IconDocument';
import { IconChannelWTM } from 'src/assets/icons/channels/IconChannelWTM';
import PerfectHeadline from 'src/assets/icons/generationTools/PerfectHeadline';
import { TrustpilotIcon } from 'src/assets/icons/ChannelTrustpilot';
import { AmazonIcon } from 'src/assets/icons/ChannelAmazon';
import { IconResourceTypePdf } from 'src/assets/icons/ResourceTypePdf';
import { IconResourceTypeUrl } from 'src/assets/icons/ResourceTypeUrl';
import { ChannelSalesforceIcon } from 'src/assets/icons/ChannelSalesforce';
import { ChannelMarketoIcon } from 'src/assets/icons/ChannelMarketo';
import { ChannelBrazeIcon } from 'src/assets/icons/ChannelBraze';

export function pickChannelIcon(channel) {
  if (channel === SOCIAL_CHANNEL.facebook) {
    return MetaIcon;
  }
  if (channel === SOCIAL_CHANNEL.instagram) {
    return IconChannelInstagram;
  }
  if ([SOCIAL_CHANNEL.linkedin, SOCIAL_CHANNEL.linkedinSocial].includes(channel)) {
    return IconChannelLinkedin;
  }
  if ([SOCIAL_CHANNEL.twitter, SOCIAL_CHANNEL.twitterSocial].includes(channel)) {
    return IconChannelTwitter;
  }
  if (channel === SOCIAL_CHANNEL.google) {
    return IconChannelGoogle;
  }
  if (channel === SOCIAL_CHANNEL.youtube) {
    return IconPlatformYoutube;
  }
  if (channel === SOCIAL_CHANNEL.pinterest) {
    return ChannelPinterest;
  }
  if (channel === NATIVE_CHANNEL.taboola) {
    return IconChannelTaboola;
  }
  if (channel === NATIVE_CHANNEL.outbrain) {
    return IconChannelOutbrain;
  }
  if (channel === NATIVE_CHANNEL.revcontent) {
    return IconChannelRevcontent;
  }
  if (channel === NATIVE_CHANNEL.verizon) {
    return IconChannelVerizon;
  }
  if (channel === REVIEWS_CHANNEL.trustpilot) {
    return TrustpilotIcon;
  }
  if (channel === REVIEWS_CHANNEL.amazon) {
    return AmazonIcon;
  }
  if (channel === WEBSITE_CHANNEL.homepage) {
    return ChannelHomepageIcon;
  }
  if (channel === WEBSITE_CHANNEL.landingPage) {
    return ChannelLandingPagesIcon;
  }
  if (channel === WEBSITE_CHANNEL.competitors) {
    return ChannelCompetitorsIcon;
  }
  if (channel === WEBSITE_CHANNEL.thoughtLeadership) {
    return ChannelThoughtLeadershipIcon;
  }
  if (channel === WEBSITE_CHANNEL.productPage) {
    return ChannelProductPageIcon;
  }
  if (channel === WEBSITE_CHANNEL.other) {
    return IconResourceTypeUrl;
  }
  if (channel === EMAILS_CHANNEL.hubspot) {
    return ChannelHubspotIcon;
  }
  if (channel === EMAILS_CHANNEL.salesforce) {
    return ChannelSalesforceIcon;
  }
  if (channel === EMAILS_CHANNEL.marketo) {
    return ChannelMarketoIcon;
  }
  if (channel === EMAILS_CHANNEL.braze) {
    return ChannelBrazeIcon;
  }
  if (channel === SOCIAL_CHANNEL.facebookPages) {
    return ChannelFacebookPages;
  }
  if (
    isEmailCsvResourceDocument(channel) ||
    channel === RESOURCE_DOCUMENT.emailCsvResource ||
    channel === PLATFORM_CHANNEL.emailChannel
  ) {
    return IconEmail;
  }
  if (channel === RESOURCE_DOCUMENT.googleDocs) {
    return IconDocument;
  }
  if (channel === PLATFORM_CHANNEL.projectWtm) {
    return IconChannelWTM;
  }
  if (channel === PLATFORM_CHANNEL.blogUrls) {
    return PerfectHeadline;
  }
  if (channel === PLATFORM_CHANNEL.generalDocument) {
    return IconResourceTypePdf;
  }

  return IconApps;
}
