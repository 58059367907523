import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'start',
      rowGap: theme.spacing(0.5),
    },
    title: {
      color: COLORS.gray.gray30,
    },
    description: {
      color: COLORS.gray.gray30,
    },
    buttonCta: {
      ...theme.typography.medium12,
    },
  }),
  { name: 'EmptyBanner' }
);
