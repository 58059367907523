import React, { memo, forwardRef } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ChannelSalesforceIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.9261 5.42244C10.6951 4.6213 11.7665 4.12383 12.9509 4.12383C14.5252 4.12383 15.8992 5.00191 16.6307 6.30548C17.2852 6.01313 17.9941 5.86244 18.7109 5.8633C21.5507 5.8633 23.8526 8.18557 23.8526 11.0507C23.8526 13.9158 21.5507 16.2381 18.7109 16.2381C18.3637 16.2381 18.0251 16.2034 17.6977 16.1374C17.0536 17.286 15.8254 18.0623 14.4164 18.0623C13.8266 18.0623 13.2686 17.9267 12.7722 17.6838C12.119 19.2203 10.5973 20.2972 8.82418 20.2972C6.97775 20.2972 5.40366 19.1285 4.79975 17.49C4.53067 17.5468 4.25641 17.5754 3.9814 17.5753C1.78253 17.5756 1.10585e-05 15.7745 1.10585e-05 13.5527C-0.00163856 12.8486 0.181298 12.1563 0.530585 11.5449C0.879872 10.9336 1.38331 10.4244 1.99071 10.0683C1.73872 9.48763 1.60907 8.8613 1.60984 8.22835C1.60984 5.67287 3.68401 3.60157 6.24262 3.60157C6.95539 3.60073 7.6587 3.76465 8.29766 4.08051C8.93662 4.39637 9.49394 4.85563 9.9261 5.42244Z"
          fill="#00A1E0"
        />
      </SvgIcon>
    );
  })
);
