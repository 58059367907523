import { useCallback, useState } from 'react';

import { CHANNEL } from 'src/data/channel';
import {
  CONNECT_TO_CHANNEL_ORIGIN,
  EMAIL_CSV_RESOURCES,
  shouldChanelConnectManually,
} from 'src/data/performance';
import { usePerformanceActions, useNotificationActions, useIsCurrentUserAdmin } from 'src/store';
import { useAddResource, useChannelAccounts, useCipEvents } from 'src/services';
import { useConnectToChannelManuallyPopup } from './popup/popups';
import {
  AD_ACCOUNTS_SELECTION_POPUP,
  useAdAccountsSelectionPopup,
  useConnectToChannelPopup,
  usePopups,
} from './popup';
import { useRestrictActionPopup } from './useRestrictActionPopup';
import { useAuthorizeIntegration } from './useAuthorizeIntegration';

export const useConnectToChannel = (trackingEventAttributes = {}) => {
  const notifications = useNotificationActions();
  const performanceActions = usePerformanceActions();
  const openAdAccountsSelectionPopup = useAdAccountsSelectionPopup();
  const openConnectToChannelManuallyPopup = useConnectToChannelManuallyPopup();
  const isCurrentUserAdmin = useIsCurrentUserAdmin();
  const openRestrictActionPopup = useRestrictActionPopup();
  const openConnectToChannelPopup = useConnectToChannelPopup();
  const onAddResource = useAddResource(trackingEventAttributes);
  const { connectedAccounts } = useChannelAccounts();
  const cipEvents = useCipEvents();
  const popups = usePopups();
  const authorizeIntegration = useAuthorizeIntegration();

  const [connecting, setConnecting] = useState();

  const connectToChannel = useCallback(
    async ({
      channel,
      brandVoiceId,
      customModel,
      isTrainingModel,
      onConnectSuccess,
      connected,
      isTalkingPointsView,
      displayEmailIntegrations,
      origin,
    }) => {
      const fromIntegrationsPage = origin === CONNECT_TO_CHANNEL_ORIGIN.integrationsAndResources;
      const isEmailChannel = channel === CHANNEL.emailChannel;
      if (isEmailChannel && !connected) {
        const selectedChannel = await openConnectToChannelPopup({
          displayIntegrations: false,
          displayResourcesTemplates: true,
          displayEmailIntegrations,
          title: 'Email',
          subTitle: 'Select one of the options to import email data',
          isTrainingModel,
          brandVoiceId,
          onConnectSuccess,
          isTalkingPointsView,
        });
        if (selectedChannel) {
          if (EMAIL_CSV_RESOURCES.includes(selectedChannel)) {
            await onAddResource();
            onConnectSuccess?.();
            return;
          } else {
            channel = selectedChannel;
          }
        } else {
          return;
        }
      }
      if (
        shouldChanelConnectManually(channel) &&
        ((!displayEmailIntegrations && !connectedAccounts[channel]?.length) || fromIntegrationsPage)
      ) {
        await openConnectToChannelManuallyPopup({
          isTrainingModel,
          isTalkingPointsView,
          brandVoiceId,
          onConnectSuccess,
          channel,
        });
        return;
      }

      if (
        performanceActions.isChannelConnected(channel) ||
        (isEmailChannel && connected) ||
        ((isTrainingModel || isTalkingPointsView) && connectedAccounts[channel]?.length)
      ) {
        await openAdAccountsSelectionPopup({
          channel,
          brandVoiceId,
          customModel,
          isTrainingModel,
          onConnectSuccess,
          isTalkingPointsView,
        });
        return;
      }

      if (!isCurrentUserAdmin) {
        openRestrictActionPopup();
        return;
      }
      cipEvents.trackConnectToChannel(channel, origin);

      setConnecting(true);
      if (channel === CHANNEL.twitterSocial) {
        authorizeIntegration(channel)
          .catch((error) => {
            popups.closePopup(AD_ACCOUNTS_SELECTION_POPUP);
            if (error) {
              notifications.displayError(error);
            }
          })
          .finally(() => {
            setConnecting(false);
          });
      } else {
        performanceActions.connectToChannel(channel).catch((error) => {
          popups.closePopup(AD_ACCOUNTS_SELECTION_POPUP);
          if (error) {
            notifications.displayError(error);
          }
        });
      }
      cipEvents.trackChannelConnected(channel, origin);

      openAdAccountsSelectionPopup({
        channel,
        brandVoiceId,
        customModel,
        isTrainingModel,
        onConnectSuccess,
        isTalkingPointsView,
      });
    },
    [
      isCurrentUserAdmin,
      openRestrictActionPopup,
      openConnectToChannelManuallyPopup,
      openAdAccountsSelectionPopup,
      performanceActions,
      notifications,
      popups,
      openConnectToChannelPopup,
      onAddResource,
      connectedAccounts,
      cipEvents,
      authorizeIntegration,
    ]
  );

  return {
    connectToChannel,
    connecting,
  };
};
