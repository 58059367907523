import { orderBy } from 'lodash';

export const sortBrandBriefs = (items) =>
  orderBy(items, ['updatedAt', 'name', 'id'], ['desc', 'asc', 'asc']);

export const BRAND_BRIEF_TYPE = {
  productDescription: 'product_description',
  companyDescription: 'company_description',
  oneLiner: 'one_liner',
  elevatorPitch: 'elevator_pitch',
  whoWeAre: 'who_we_are',
  other: 'other',
};

const BRAND_BRIEF_TYPE_PROPS = {
  [BRAND_BRIEF_TYPE.productDescription]: {
    label: 'Product Description',
    titlePlaceholder: 'The name of the product associated with this asset',
    bodyPlaceholder:
      'Provide a detailed description of the product. This should highlight key features, benefits, and any other relevant information.',
  },
  [BRAND_BRIEF_TYPE.companyDescription]: {
    label: 'Company Description',
    titlePlaceholder: 'The name of the company associated with this asset',
    bodyPlaceholder: 'Provide a detailed description of the company.',
  },
  [BRAND_BRIEF_TYPE.oneLiner]: {
    label: 'One-liner',
    bodyPlaceholder: 'Paste your one-liner here',
  },
  [BRAND_BRIEF_TYPE.elevatorPitch]: {
    label: 'Elevator Pitch',
    bodyPlaceholder: 'Paste your elevator pitch here',
  },
  [BRAND_BRIEF_TYPE.whoWeAre]: {
    label: 'Who We Are',
    bodyPlaceholder: 'Paste your "Who We Are" here',
  },
  [BRAND_BRIEF_TYPE.other]: {
    label: 'Other',
    bodyPlaceholder: 'Provide content for for this asset',
  },
};

export const getBrandBriefTypeLabel = (briefType) => BRAND_BRIEF_TYPE_PROPS[briefType]?.label;

export const brandBriefTypeHasTitle = (briefType) =>
  [BRAND_BRIEF_TYPE.productDescription, BRAND_BRIEF_TYPE.companyDescription].includes(briefType);

export const getBrandBriefTypeTitlePlaceholder = (briefType) =>
  BRAND_BRIEF_TYPE_PROPS[briefType]?.titlePlaceholder;

export const getBrandBriefTypeBodyPlaceholder = (briefType) =>
  BRAND_BRIEF_TYPE_PROPS[briefType]?.bodyPlaceholder;
