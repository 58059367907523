import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      minHeight: 30,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      color: COLORS.gray.gray40,
    },
  }),
  { name: 'ItemSubheader' }
);
