import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useHistory } from 'src/lib/hooks/useHistory';
import { customerSubscriptionExpired } from 'src/data/customer';
import { ROUTES } from 'src/routes';
import { useCurrentCustomer } from 'src/store';
import { useInviteAcceptPopup } from 'src/services/popup';
import { useIsApplicationModeInfinity } from 'src/services';
import useViewState from './useViewState';

const RootPage = () => {
  const history = useHistory();
  const location = useLocation();

  const currentCustomer = useCurrentCustomer();
  const { currentUser, currentUserLoading } = useViewState();

  const isInfinityAppMode = useIsApplicationModeInfinity();
  const showInviteAcceptModalIfNeeded = useInviteAcceptPopup();

  useEffect(() => {
    if (currentUserLoading) {
      return;
    }

    // Go straight to "infinity page" in case we are in "infinity" app mode
    if (isInfinityAppMode) {
      history.replace(ROUTES.EXTERNAL_INFINITY_EXTENSION);
      return;
    }

    // Navigate to 'email verification' page if needed
    if (currentUser && !currentUser.isConfirmed) {
      history.replace(ROUTES.NOT_VERIFIED);
      return;
    }

    // Check if customer is allowed to use the Platform
    if (!currentCustomer || customerSubscriptionExpired(currentCustomer)) {
      return;
    }

    showInviteAcceptModalIfNeeded();

    // Navigate to dashboard by default
    history.replace(`${ROUTES.DASHBOARD}${location.search}`);
  }, [
    currentUser,
    currentUserLoading,
    currentCustomer,
    history,
    isInfinityAppMode,
    showInviteAcceptModalIfNeeded,
    location.search,
  ]);

  return null;
};

export default RootPage;
