import { makeStyles } from '@material-ui/core';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'relative',
      width: 210,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3.75, 1.25, 3),
      backgroundColor: COLORS.neutral.white,
      border: `1px solid ${COLORS.primary.light}`,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 3px 20px rgba(117, 117, 117, 0.12)',
    },
    title: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(19),
      textAlign: 'center',

      '& b': {
        fontWeight: theme.typography.fontWeightSemiBold,
      },
    },

    actionBtn: {
      marginTop: theme.spacing(1.5),
    },
    bannerImage: {
      width: 132,
      height: 122,
    },
    buttonClose: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      padding: '9px',
      '& svg': {
        fontSize: theme.typography.pxToRem(8),
      },
    },
  }),
  { name: 'DDUpgradePanel' }
);
