import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_TONE_OF_VOICE_INSIGHTS } from './schema';

const MUTATION = gql`
  mutation ExtractCustomToneOfVoiceInsights($projectId: ID!, $text: String!, $customTovId: ID!) {
    extractCustomToneOfVoiceInsights(
      projectId: $projectId
      text: $text
      customTovId: $customTovId
    ) {
      ...CustomToneOfVoiceInsights
    }
  }

  ${FRAGMENT_CUSTOM_TONE_OF_VOICE_INSIGHTS}
`;

export const extractCustomToneOfVoiceInsights = ({ projectId, text, customTovId }) => {
  const variables = {
    projectId,
    text,
    customTovId,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  }).then(({ data }) => getSingleProp(data));
};
