import gql from 'graphql-tag';

export const FRAGMENT_PERFORMANCE_CENTER_TEXT_VARIATIONS_RESULT = gql`
  fragment PerformanceCenterTextVariationsResult on PerformanceCenterTextVariationsResult {
    id
    text
    weightedAvgCtr
    lifetimeImpressions
    textType
    startDate
    sourceAnyword
    numberOfInstances
    keywords
    campaignNames
    lifetimeConversions
    sortIndex
    performance
  }
`;

export const FRAGMENT_RESOURCE = gql`
  fragment PerformanceCenterResource on PerformanceCenterResource {
    id
    name
    dataSource
    connected
  }
`;

export const FRAGMENT_CUSTOMER_RESOURCE = gql`
  fragment PerformanceCenterCustomerResource on PerformanceCenterCustomerResource {
    id
    content
    contentType
    name
    status
    tags
    file {
      originalUrl
      compressedUrl
      thumbnailUrl
      imageWidth
      imageHeight
      fileSize
      fileType
      fileName
      pageCount
    }
    createdAt
    userCreatedBy {
      id
      name
    }
  }
`;

export const FRAGMENT_PERFORMANCE_CENTER_INTEGRATION_REQUEST = gql`
  fragment PerformanceCenterIntegrationRequest on PerformanceCenterIntegrationRequest {
    id
    accountId
    customDatasetId
    avgCompletionPercent
    campaigns {
      id
      name
    }
    campaignsCopyImpressions {
      campaignName
      copyText
      impressions
    }
    dataSource
    errors
    status
    updatedAt
    connectedOn
  }
`;

export const FRAGMENT_PERFORMANCE_CENTER_INTEGRATION_REQUEST_ONLY_STATUS = gql`
  fragment PerformanceCenterIntegrationRequestStatus on PerformanceCenterIntegrationRequest {
    id
    status
    errors
    avgCompletionPercent
  }
`;

export const FRAGMENT_INTEGRATION_ACCOUNT = gql`
  fragment PerformanceCenterIntegrationAccount on PerformanceCenterIntegrationAccount {
    status
    accountId
    accountName
    accountType
    url
    connectedOn
  }
`;

export const FRAGMENT_WORKSPACE_ASSOCIATED_ACCOUNTS = gql`
  fragment WorkspaceAssociatedAccounts on Workspace {
    associatedAccounts {
      ...PerformanceCenterIntegrationAccount
    }
  }
  ${FRAGMENT_INTEGRATION_ACCOUNT}
`;

export const FRAGMENT_AD_ACCOUNT = gql`
  fragment UserAdAccount on UserAdAccount {
    ... on BrazeUserAdAccount {
      id
      name
    }
  }
`;

export const COMPARISON_SET_FRAGMENT = gql`
  fragment ComparisonSet on ComparisonSet {
    name
    description
    userGoal
    examples
    examplesWithMetrics {
      text
      metric
    }
  }
`;

export const BENCHMARK_CONFIG_FRAGMENT = gql`
  fragment BenchmarkConfig on BenchmarkConfig {
    userCreated
    account {
      id
      name
      channel
    }
    comparisonSets {
      ...ComparisonSet
    }
    assetType
  }
  ${COMPARISON_SET_FRAGMENT}
`;

export const INTEGRATION_REQUEST_INFO_FRAGMENT = gql`
  fragment IntegrationAuthorizationRequestInfo on IntegrationAuthorizationRequestInfo {
    type
    url
  }
`;
