import { useCallback } from 'react';

import { usePopups } from 'src/services/popup';
import { CONNECT_CHANNEL_MANUALLY_POPUP } from '../types';

export const useConnectToChannelManuallyPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ channel, isTrainingModel, isTalkingPointsView, brandVoiceId, onConnectSuccess }) => {
      return new Promise((resolve) => {
        popups.openPopup(CONNECT_CHANNEL_MANUALLY_POPUP, {
          onSubmit: (data) => {
            popups.closePopup(CONNECT_CHANNEL_MANUALLY_POPUP);
            resolve(data);
          },
          onCancel: () => {
            resolve(null);
            popups.closePopup(CONNECT_CHANNEL_MANUALLY_POPUP);
          },
          channel,
          isTrainingModel,
          isTalkingPointsView,
          brandVoiceId,
          onConnectSuccess,
        });
      });
    },
    [popups]
  );
};
