import gql from 'graphql-tag';
import { get } from 'lodash';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query CustomerGeneratedWords($id: ID!, $startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    customer(id: $id) {
      id
      histogram {
        generatedWords(startDate: $startDate, endDate: $endDate) {
          date
          value
        }
      }
    }
  }
`;

export const queryGeneratedWords = ({ customerId, startDate, endDate }) =>
  executeGqlQuery({
    query: QUERY,
    variables: { id: customerId, startDate, endDate },
  })
    .then(({ data }) => getSingleProp(data))
    .then((customer) => get(customer, 'histogram.generatedWords'));
