import React from 'react';
import { CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { AnywordLogo } from 'src/assets/icons/AnywordLogo';
import CenterPageLayout from 'src/components/PageLayout';
import Panel from 'src/components/Panel';
import useStyles from './useStyles';

const VerifyEmailCallback = () => {
  const styles = useStyles();

  return (
    <CenterPageLayout className={styles.root}>
      <div className={styles.centerWrapper}>
        <Panel className={styles.panel}>
          <AnywordLogo className={styles.logo} />

          <Typography className={styles.title} variant="h6">
            Verifying email
          </Typography>

          <CircularProgress className={styles.loader} size={48} />
        </Panel>
      </div>
    </CenterPageLayout>
  );
};

export default VerifyEmailCallback;
