import {
  AI_RECOMPOSE_MODE,
  AI_SUGGESTION_MODE,
  REWERITE_TOOL_MODES,
} from 'src/data/generation/const';
import { GENERATION_TOOL } from './const';

const TOOL_MODE_MAP = {
  /**
   * "basic" tools
   */
  [GENERATION_TOOL.basicContentImprover]: [AI_RECOMPOSE_MODE.default, AI_RECOMPOSE_MODE.polished],
  [GENERATION_TOOL.basicEli5]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicSentenceExpander]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicAida]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicPas]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicBab]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicPerfectHeadline]: [AI_SUGGESTION_MODE.engaging],
  [GENERATION_TOOL.basicPersuasiveBulletPoint]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicFeatureToBenefitsPractical]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicValueProposition]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicProductDescriptionList]: [AI_SUGGESTION_MODE.list],
  [GENERATION_TOOL.basicProductDescriptionRelaxed]: [AI_RECOMPOSE_MODE.default],
  [GENERATION_TOOL.basicBlogIdeas]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicVideoIdeas]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicTalkingPointsToParagraph]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicProductReview]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.generalProductListing]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.summarizer]: [
    AI_SUGGESTION_MODE.summarize,
    AI_SUGGESTION_MODE.tldr,
    AI_SUGGESTION_MODE.topic,
  ],
  [GENERATION_TOOL.basicPersonalBio]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicCompanyBio]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicEngagingQuestions]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicQuoraAnswers]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.generalPrompt]: [AI_SUGGESTION_MODE.default],

  /**
   * "basic DD" tools
   */
  [GENERATION_TOOL.basicFacebookAd]: [AI_SUGGESTION_MODE.creative],
  [GENERATION_TOOL.basicGoogleAd]: [AI_SUGGESTION_MODE.creative],
  [GENERATION_TOOL.basicTwitterAd]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicLinkedinTextAd]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicPinterestAd]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicLandingPage]: [AI_SUGGESTION_MODE.standard],
  [GENERATION_TOOL.basicLandingPageSeo]: [AI_SUGGESTION_MODE.creative],
  [GENERATION_TOOL.basicInstagramSocial]: [AI_SUGGESTION_MODE.creative],
  [GENERATION_TOOL.basicFacebookSocial]: [AI_SUGGESTION_MODE.creative],
  [GENERATION_TOOL.basicTwitterSocial]: [AI_SUGGESTION_MODE.creative],
  [GENERATION_TOOL.basicLinkedinSocial]: [AI_SUGGESTION_MODE.creative],
  [GENERATION_TOOL.basicYoutubeSocial]: [AI_SUGGESTION_MODE.creative],
  [GENERATION_TOOL.basicPinterestPinSocial]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.basicColdEmail]: [AI_SUGGESTION_MODE.standard],
  [GENERATION_TOOL.basicPromotionalEmail]: [AI_SUGGESTION_MODE.engaging],
  [GENERATION_TOOL.basicEmailSubjectLine]: [AI_SUGGESTION_MODE.standard],
  [GENERATION_TOOL.basicSms]: [AI_SUGGESTION_MODE.sms160],

  /**
   * "product" data-driven tools
   */
  [GENERATION_TOOL.facebookPrimaryTextProduct]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.AIDA,
    AI_SUGGESTION_MODE.BAB,
    AI_SUGGESTION_MODE.FAB,
    AI_SUGGESTION_MODE.PAS,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.facebookHeadlineProduct]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.question,
    // AI_SUGGESTION_MODE.hook, not supported by AC currently
    AI_SUGGESTION_MODE.CTA,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.googleHeadlineProduct]: [
    AI_SUGGESTION_MODE.fluent,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.googleDescriptionProduct]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.googleRSA]: [AI_SUGGESTION_MODE.auto],
  [GENERATION_TOOL.facebookMixtureAd]: [AI_SUGGESTION_MODE.auto],
  [GENERATION_TOOL.twitterMixtureAd]: [AI_SUGGESTION_MODE.auto],
  [GENERATION_TOOL.pinterestMixtureAd]: [AI_SUGGESTION_MODE.auto],
  [GENERATION_TOOL.linkedinMixtureAd]: [AI_SUGGESTION_MODE.auto],
  [GENERATION_TOOL.taboolaHeadlineProduct]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.benefit,
    AI_SUGGESTION_MODE.brand,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.outbrainHeadlineProduct]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.benefit,
    AI_SUGGESTION_MODE.brand,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.twitterTitleProduct]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.benefit,
    AI_SUGGESTION_MODE.feature,
    AI_SUGGESTION_MODE.pain,
    AI_SUGGESTION_MODE.brand,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.twitterCopyProduct]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.AIDA,
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.list,
    AI_SUGGESTION_MODE.PAS,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.pinterestTitleProduct]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.pinterestDescriptionProduct]: [
    AI_SUGGESTION_MODE.engaging,
    // AI_SUGGESTION_MODE.creative, was same text as engaging need to check if there is a difference and should be shown
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.linkedinHeadlineSingleImage]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.benefit,
    AI_SUGGESTION_MODE.feature,
    AI_SUGGESTION_MODE.pain,
    AI_SUGGESTION_MODE.brand,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.linkedinIntroSingleImage]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.AIDA,
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.list,
    AI_SUGGESTION_MODE.PAS,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.articleBlogHeadline]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.productLaunchMultiPrompt]: [AI_SUGGESTION_MODE.engaging],
  [GENERATION_TOOL.landingPageProductAnnouncementMultiPrompt]: [AI_SUGGESTION_MODE.engaging],
  [GENERATION_TOOL.emailDripMultiPrompt]: [AI_SUGGESTION_MODE.engaging],
  [GENERATION_TOOL.customMultiPrompt]: [AI_SUGGESTION_MODE.engaging],

  /**
   * "landing" data-driven tools
   */
  [GENERATION_TOOL.landingHeadline]: [
    AI_SUGGESTION_MODE.standard,
    AI_SUGGESTION_MODE.pain,
    AI_SUGGESTION_MODE.feature,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.landingSubheader]: [
    AI_SUGGESTION_MODE.standard,
    AI_SUGGESTION_MODE.PAS,
    AI_SUGGESTION_MODE.FAB,
    AI_SUGGESTION_MODE.BAB,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.landingCTA]: [AI_SUGGESTION_MODE.standard],
  [GENERATION_TOOL.landingBenefits]: [AI_SUGGESTION_MODE.list, AI_SUGGESTION_MODE.customFormula],
  [GENERATION_TOOL.landingFeatures]: [AI_SUGGESTION_MODE.list, AI_SUGGESTION_MODE.customFormula],
  [GENERATION_TOOL.landingSEOMetadata]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],

  /**
   * "product listing" data-driven tools
   */
  [GENERATION_TOOL.amazonProductListing]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.walmartProductListing]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.customFormula,
  ],
  /**
   * deprecated "product listing" data-driven tools
   */
  [GENERATION_TOOL.amazonProductDescription]: [
    AI_SUGGESTION_MODE.default,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.amazonFeatureList]: [
    AI_SUGGESTION_MODE.default,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.walmartProductDescription]: [
    AI_SUGGESTION_MODE.default,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.walmartFeatureList]: [
    AI_SUGGESTION_MODE.default,
    AI_SUGGESTION_MODE.customFormula,
  ],

  /**
   * "social" data-driven tools
   */
  [GENERATION_TOOL.instagramCaption]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.AIDA,
    AI_SUGGESTION_MODE.PAS,
    // AI_SUGGESTION_MODE.BAB, disabled for now until we have ac support
    // AI_SUGGESTION_MODE.fourPs, disabled for now until we have ac support
    // AI_SUGGESTION_MODE.oneTwoThreeFourFormula,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.facebookPost]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.AIDA,
    AI_SUGGESTION_MODE.PAS,
    AI_SUGGESTION_MODE.fourPs,
    AI_SUGGESTION_MODE.BAB,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.tweet]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.hookBodyCta,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.linkedinPost]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.PAS,
    AI_SUGGESTION_MODE.AIDA,
    AI_SUGGESTION_MODE.BAB,
    AI_SUGGESTION_MODE.fourPs,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.pinterestPostDescription]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.pinterestPostTitle]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.youtubeTitle]: [AI_SUGGESTION_MODE.creative, AI_SUGGESTION_MODE.customFormula],
  [GENERATION_TOOL.youtubeDescription]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],

  /**
   * "content" data-driven tools
   */
  [GENERATION_TOOL.facebookPrimaryTextContent]: [
    AI_SUGGESTION_MODE.standard,
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.quotes,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.facebookHeadlineContent]: [
    AI_SUGGESTION_MODE.standard,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.taboolaHeadlineContent]: [
    AI_SUGGESTION_MODE.standard,
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.outbrainHeadlineContent]: [
    AI_SUGGESTION_MODE.standard,
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.twitterCopyContent]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.pinterestTitleContent]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.pinterestDescriptionContent]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  /**
   * deprecated generation tools
   */
  [GENERATION_TOOL.linkedinHeadlineContent]: [AI_SUGGESTION_MODE.creative],
  [GENERATION_TOOL.linkedinIntroContent]: [AI_SUGGESTION_MODE.creative],

  /**
   * "email" and "sms" data-driven tools
   */
  [GENERATION_TOOL.emailSubjectLine]: [
    AI_SUGGESTION_MODE.standard,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.emailPromotionalSubjectLine]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.emailPromotionalBody]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.emailColdOutreachSubjectLine]: [
    AI_SUGGESTION_MODE.standard,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.emailColdOutreachBody]: [
    AI_SUGGESTION_MODE.standard,
    AI_SUGGESTION_MODE.customFormula,
    AI_SUGGESTION_MODE.AIDA,
    AI_SUGGESTION_MODE.PAS,
    AI_SUGGESTION_MODE.BAB,
    AI_SUGGESTION_MODE.guided,
  ],
  [GENERATION_TOOL.emailSubject]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.pain,
    AI_SUGGESTION_MODE.attention,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.emailBody]: [
    AI_SUGGESTION_MODE.engaging,
    AI_SUGGESTION_MODE.PAS,
    AI_SUGGESTION_MODE.AIDA,
    AI_SUGGESTION_MODE.fourPs,
    AI_SUGGESTION_MODE.FAB,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.emailMixture]: [AI_SUGGESTION_MODE.engaging, AI_SUGGESTION_MODE.customFormula],
  [GENERATION_TOOL.smsPromotional]: [
    AI_SUGGESTION_MODE.sms320,
    AI_SUGGESTION_MODE.sms160,
    AI_SUGGESTION_MODE.sms480,
  ],

  /**
   * "teads" data-driven tools
   */
  [GENERATION_TOOL.teadsSingleImageHeadline]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.teadsSingleImageDescription]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.teadsSingleVideoHeadline]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.teadsSingleVideoDescription]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.teadsCarouselHeadline]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.teadsSocialLayout1Headline]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.teadsSocialLayout1PrimaryText]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.teadsSocialLayout1Description]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],
  [GENERATION_TOOL.teadsSocialLayout2PrimaryText]: [
    AI_SUGGESTION_MODE.creative,
    AI_SUGGESTION_MODE.customFormula,
  ],

  /**
   * Freestyle tools
   */
  [GENERATION_TOOL.freestyle]: [AI_SUGGESTION_MODE.default, AI_SUGGESTION_MODE.customFormula],
  [GENERATION_TOOL.createYourOwn]: [AI_SUGGESTION_MODE.default],

  /**
   * LPCO tools
   */
  [GENERATION_TOOL.lpcoLandingHeadline]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.lpcoLandingCTA]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.lpcoLandingProductDescription]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.lpcoLandingProductName]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.lpcoLandingCopy]: [AI_SUGGESTION_MODE.default],
  [GENERATION_TOOL.wtmDefault]: [AI_SUGGESTION_MODE.default],

  /**
   * Blog wizard tools
   */
  [GENERATION_TOOL.blogDescription]: [AI_SUGGESTION_MODE.default],

  /**
   * Rewrite tools
   */
  [GENERATION_TOOL.facebookHeadlineProductRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.facebookPrimaryTextProductRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.googleHeadlineProductRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.googleDescriptionProductRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.outbrainHeadlineProductRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.taboolaHeadlineProductRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.twitterCopyProductRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.pinterestDescriptionProductRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.landingHeadlineRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.landingSubheaderRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.landingSEOMetadataRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.emailBodyRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.emailSubjectLineRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.emailColdOutreachBodyRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.emailPromotionalBodyRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.smsPromotionalRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.linkedinHeadlineSingleImageRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.linkedinIntroSingleImageRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.instagramCaptionRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.facebookPostRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.pinterestPinTitleRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.pinterestPinDescriptionRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.tweetRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.linkedinPostRewrite]: REWERITE_TOOL_MODES,
  [GENERATION_TOOL.emailSubjectRewrite]: REWERITE_TOOL_MODES,
};

export const getToolGenerationModes = (generationTool) => TOOL_MODE_MAP[generationTool] || [];

export const getToolDefaultGenerationMode = (generationTool) => {
  const modes = getToolGenerationModes(generationTool);
  return modes[0];
};
