import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';

import { IconInformation } from 'src/assets/icons/IconInformation';
import { IconInformationInverted } from 'src/assets/icons/IconInformationInverted';
import IconQuestion from 'src/assets/icons/Question';
import { CustomButton } from 'src/components/CustomButton';
import { CustomTooltip } from 'src/components/CustomTooltip';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { isMobileDevice } from 'src/lib/userAgent';
import { useStyles } from './useStyles';

export const INFORMATION_SIZE = {
  default: 'default',
  small: 's',
  extraSmall: 'xs',
};

const getTooltipIcon = (isQuestion, useMaterialIcon, displayInvertedIcon) => {
  if (isQuestion) {
    return useMaterialIcon ? HelpOutline : IconQuestion;
  }
  return displayInvertedIcon ? IconInformationInverted : IconInformation;
};

const Information = ({
  className,
  classes,
  title,
  content,
  icon,
  interactive,
  isQuestion,
  open,
  placement,
  useMaterialIcon,
  size,
  displayInvertedIcon,
}) => {
  const styles = useStyles({ classes });
  const [isOpened, setIsOpened] = useState(false);
  const isMobile = isMobileDevice();

  const TooltipIcon = getTooltipIcon(isQuestion, useMaterialIcon, displayInvertedIcon);

  const iconStyles = {
    [styles.iconXs]: size === INFORMATION_SIZE.extraSmall,
    [styles.iconS]: size === INFORMATION_SIZE.small,
  };

  const tooltipContent = (
    <div className={styles.contentWrapper}>
      {icon && <div className={styles.contentIcon}>{icon}</div>}
      <div className={styles.texts}>
        {title && (
          <CustomTypography
            className={styles.title}
            variant={isMobile ? TYPOGRAPHY_VARIANT.medium16 : TYPOGRAPHY_VARIANT.medium14}
          >
            {title}
          </CustomTypography>
        )}
        <CustomTypography
          className={styles.content}
          variant={isMobile ? TYPOGRAPHY_VARIANT.paragraph16 : TYPOGRAPHY_VARIANT.paragraph14}
        >
          {content}
        </CustomTypography>
      </div>
    </div>
  );

  const handleClose = () => setIsOpened(false);

  return isMobile ? (
    <>
      <TooltipIcon
        className={cx(styles.icon, className, iconStyles)}
        onClick={() => setIsOpened(true)}
      />
      <Dialog
        open={isOpened}
        onClose={handleClose}
        classes={{ paper: styles.mobilePaper }}
      >
        <DialogContent>{tooltipContent}</DialogContent>
        <DialogActions>
          <CustomButton
            className={styles.mobileButtonClose}
            onClick={handleClose}
          >
            Close
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <CustomTooltip
      classes={{ tooltip: styles.tooltip }}
      title={tooltipContent}
      placement={placement}
      interactive={interactive}
      open={open}
    >
      <TooltipIcon className={cx(styles.icon, className, iconStyles)} />
    </CustomTooltip>
  );
};

Information.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.node,
  interactive: PropTypes.bool,
  isQuestion: PropTypes.bool,
  open: PropTypes.bool,
  placement: PropTypes.string,
  useMaterialIcon: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(INFORMATION_SIZE)),
  displayInvertedIcon: PropTypes.bool,
};

Information.defaultProps = {
  className: null,
  classes: null,
  title: null,
  icon: null,
  interactive: false,
  isQuestion: false,
  open: undefined,
  placement: 'top',
  useMaterialIcon: false,
  size: INFORMATION_SIZE.default,
  displayInvertedIcon: false,
};

export default React.memo(Information);
