import { capitalize } from 'lodash';

import { withoutTypename } from 'src/lib/graphql';
import { formatPercentageWithOneDecimal } from 'src/utils/format';
import { ASSET_TYPE } from '../asset';
import { CHANNEL } from '../channel';
import { ADS_CHANNELS, EMAILS_CHANNELS, RESOURCE_DOCUMENT, SOCIAL_CHANNELS } from '../performance';
import { USER_GOAL_PROPS } from './const';

/**
 * Retrieves the channel associated with a specific asset type.
 *
 * @param {string} assetType - The asset type identifier.
 * @returns {string|null} - The corresponding channel or null if not found.
 */
export const getChannelFromAssetType = (assetType) => {
  switch (assetType) {
    case ASSET_TYPE.facebookPrimaryText:
    case ASSET_TYPE.facebookHeadline:
    case ASSET_TYPE.facebookMixtureAd:
      return CHANNEL.facebook;
    case ASSET_TYPE.facebookPost:
      return CHANNEL.facebookPages;
    case ASSET_TYPE.googleDescription:
    case ASSET_TYPE.googleHeadline:
      return CHANNEL.google;
    case ASSET_TYPE.linkedinSingleImageHeadline:
    case ASSET_TYPE.linkedinSingleImageIntro:
    case ASSET_TYPE.linkedinMixtureAd:
      return CHANNEL.linkedin;
    case ASSET_TYPE.emailSubjectLine:
    case ASSET_TYPE.emailPromotionalBody:
    case ASSET_TYPE.emailPromotionalSubjectLine:
    case ASSET_TYPE.emailColdOutreachBody:
    case ASSET_TYPE.emailColdOutreachSubjectLine:
    case ASSET_TYPE.emailSubject:
    case ASSET_TYPE.emailBody:
      return CHANNEL.emailChannel;
    case ASSET_TYPE.instagramCaption:
      return CHANNEL.instagram;
    case ASSET_TYPE.linkedinPost:
      return CHANNEL.linkedinSocial;
    case ASSET_TYPE.tweet:
      return CHANNEL.twitterSocial;
    default:
      return null;
  }
};

/**
 * Retrieves the label associated with a channel item.
 *
 * @param {string} channel - The channel identifier.
 * @returns {string} - The label associated with the channel item.
 */
export const getChannelItemLabel = (channel) =>
  SOCIAL_CHANNELS.includes(channel)
    ? 'account'
    : EMAILS_CHANNELS.includes(channel)
    ? 'account'
    : ADS_CHANNELS
    ? 'account'
    : '';

/**
 * Retrieves email channels associated with a specific asset type.
 *
 * @param {string} assetType - The asset type identifier.
 * @returns {array} - The array of email channels associated with the asset type.
 */
export const getEmailChannelsFromAssetType = (assetType) => {
  switch (assetType) {
    case ASSET_TYPE.emailPromotionalBody:
      return [RESOURCE_DOCUMENT.emailPromotionCsvResource, ...EMAILS_CHANNELS];
    case ASSET_TYPE.emailColdOutreachBody:
      return [RESOURCE_DOCUMENT.emailColdCsvResource, ...EMAILS_CHANNELS];
    case ASSET_TYPE.emailSubject:
      return [RESOURCE_DOCUMENT.emailSubjectLineCsvResource, ...EMAILS_CHANNELS];
    case ASSET_TYPE.emailBody:
      return [
        RESOURCE_DOCUMENT.emailPromotionCsvResource,
        RESOURCE_DOCUMENT.emailColdCsvResource,
        ...EMAILS_CHANNELS,
      ];
    case ASSET_TYPE.emailMixture:
      return [
        RESOURCE_DOCUMENT.emailSubjectLineCsvResource,
        RESOURCE_DOCUMENT.emailPromotionCsvResource,
        RESOURCE_DOCUMENT.emailColdCsvResource,
        ...EMAILS_CHANNELS,
      ];
    default:
      return EMAILS_CHANNELS;
  }
};

/**
 * Merges two benchmark configurations.
 *
 * @param {object} benchmarkConfig - The original benchmark configuration.
 * @param {object} newBenchmarkConfig - The new benchmark configuration to merge.
 * @returns {object} - The merged benchmark configuration.
 */
export const mergeBenchmarkConfigs = (benchmarkConfig, newBenchmarkConfig) => {
  const preparedBenchmarkConfig = {
    ...benchmarkConfig,
    ...newBenchmarkConfig,
    comparisonSet: {
      ...(benchmarkConfig?.comparisonSet || {}),
      ...(newBenchmarkConfig?.comparisonSet || {}),
    },
  };

  return preparedBenchmarkConfig;
};

/**
 * Retrieves the label associated with a user goal.
 *
 * @param {string} userGoal - The user goal identifier.
 * @returns {string} - The label associated with the user goal.
 */
export const getUserGoalLabel = (userGoal) =>
  USER_GOAL_PROPS[userGoal]?.label || capitalize(userGoal).replaceAll('_', ' ');

/**
 * Checks if a user goal is expressed as a percentage.
 *
 * @param {string} userGoal - The user goal identifier.
 * @returns {boolean} - True if the user goal is expressed as a percentage, false otherwise.
 */
export const getIsPercentageUserGoal = (userGoal) =>
  USER_GOAL_PROPS[userGoal]?.isPercentage || false;

/**
 * Checks if a user goal's metric value should be multiplied.
 *
 * @param {string} userGoal - The user goal identifier.
 * @returns {boolean} - True if the metric value should be multiplied, false otherwise.
 */
export const getShouldMultipleMetricValue = (userGoal) =>
  USER_GOAL_PROPS[userGoal]?.shouldMultipleMetricValue || false;

/**
 * Retrieves the percentage representation of a user goal metric.
 *
 * @param {string} userGoal - The user goal identifier.
 * @param {number} metric - The metric value to format.
 * @returns {string} - The formatted percentage representation of the metric.
 */
export const getPercentageUserGoalMetric = (userGoal, metric) => {
  if (getShouldMultipleMetricValue(userGoal)) {
    return formatPercentageWithOneDecimal(metric * 100);
  } else {
    return formatPercentageWithOneDecimal(metric);
  }
};

/**
 * Retrieves a benchmark configuration from a global list based on asset type.
 *
 * @param {array} globalBenchmarkConfigs - The array of global benchmark configurations.
 * @param {string} assetType - The asset type identifier to match.
 * @returns {object|null} - The matching benchmark configuration or null if not found.
 */
export const getBenchmarkConfigFromGlobal = (globalBenchmarkConfigs, assetType) => {
  const filtered = globalBenchmarkConfigs?.filter((c) => c.assetType === assetType);
  const benchmarkConfig = filtered?.[0];

  return benchmarkConfig
    ? {
        comparisonSet: { userGoal: benchmarkConfig?.comparisonSets?.[0]?.userGoal },
        ...benchmarkConfig,
      }
    : null;
};

/**
 * Aligns examples by filtering out deleted examples from custom comparison sets.
 *
 * @param {array} customComparisonSets - The array of custom comparison sets.
 * @param {array} deletedExamples - The array of deleted examples.
 * @returns {array} - The aligned examples.
 */
export const alignExamples = (customComparisonSets, deletedExamples) => {
  return customComparisonSets.map((customComparisonSet) =>
    customComparisonSet.examples.reduce((result, example) => {
      if (!deletedExamples.includes(example)) {
        result.push(example);
      }
      return result;
    }, [])
  );
};

/**
 * Aligns examples with metrics by filtering out deleted examples from custom comparison sets.
 *
 * @param {array} customComparisonSets - The array of custom comparison sets.
 * @param {array} deletedExamples - The array of deleted examples.
 * @returns {array} - The aligned examples with metrics.
 */
export const alignExamplesWithMetrics = (customComparisonSets, deletedExamples) => {
  return customComparisonSets.map((customComparisonSet) =>
    customComparisonSet.examplesWithMetrics.reduce((result, exampleWithMetrics) => {
      if (!deletedExamples.includes(exampleWithMetrics.text)) {
        result.push(withoutTypename(exampleWithMetrics));
      }
      return result;
    }, [])
  );
};

/**
 * Retrieves a comparison set from the list based on criteria in the benchmark configuration.
 *
 * @param {object} benchmarkConfig - The benchmark configuration object.
 * @param {array} comparisonSets - The array of comparison sets to search from.
 * @returns {object|null} - The matching comparison set, or null if not found.
 */
export const getComparisonSetFromBenchmarkConfig = (benchmarkConfig, comparisonSets) => {
  // If benchmarkConfig is falsy or comparisonSets is empty, return null
  if (!benchmarkConfig || !comparisonSets?.length) {
    return null;
  }

  // Find the comparison set that matches userGoal and name in benchmarkConfig
  return comparisonSets.find(
    (comparisonSet) =>
      comparisonSet?.userGoal === benchmarkConfig.comparisonSet?.userGoal &&
      comparisonSet?.name === benchmarkConfig.comparisonSet?.name
  );
};

/**
 * Retrieves the selected comparison set based on the benchmark configuration.
 *
 * @param {object} benchmarkConfig - The benchmark configuration object.
 * @param {array} [comparisonSets] - The optional array of comparison sets.
 * @returns {object} - The selected comparison set.
 */
export const getSelectedComparisonSet = (benchmarkConfig, comparisonSets) => {
  return (
    benchmarkConfig.comparisonSet ||
    getComparisonSetFromBenchmarkConfig(
      benchmarkConfig.comparisonSet,
      benchmarkConfig.comparisonSets
    ) ||
    getComparisonSetFromBenchmarkConfig(benchmarkConfig.comparisonSet, comparisonSets) ||
    comparisonSets?.[0]
  );
};
