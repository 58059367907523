import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom-v5-compat';
import cx from 'classnames';

import { TruncatedSpan } from 'src/components/TruncatedSpan';
import { SidebarButton } from '../SidebarButton';
import { useStyles } from './useStyles';
import { camelCase } from 'lodash';

const getComponent = (item) => {
  if (item.path) {
    if (item.externalLink) {
      return 'a';
    }

    if (!item.disableNavigation) {
      return NavLink;
    }
  }

  return undefined;
};

const getButtonProps = (item) => {
  if (item.path) {
    if (item.externalLink) {
      return {
        href: item.path,
        target: '_blank',
      };
    }

    if (!item.disableNavigation) {
      return {
        to: item.path,
      };
    }
  }

  return {};
};

export const SidebarLink = ({ className, item, active, onClick, ...restProps }) => {
  const styles = useStyles();
  const component = getComponent(item);

  const handleClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  return (
    <SidebarButton
      className={cx(styles.root, className)}
      item={item}
      active={active}
      component={component}
      onClick={handleClick}
      data-testid={`navigationSidebarLink_${camelCase(item.label)}`}
      {...getButtonProps(item)}
      {...restProps}
    >
      <TruncatedSpan>{item.label}</TruncatedSpan>
    </SidebarButton>
  );
};

SidebarLink.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape().isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SidebarLink.defaultProps = {
  className: '',
  active: false,
};
