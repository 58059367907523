import React, { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const PersonaAvatarSeven = memo(
  forwardRef(({ opaque, ...props }, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 72 72"
        data-fill-none
      >
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M72 36.0137C71.9973 43.1333 69.8836 50.0921 65.9263 56.0105C61.9689 61.9289 56.3455 66.5409 49.7671 69.2636C43.1887 71.9863 35.9508 72.6972 28.9684 71.3066C21.9859 69.916 15.5726 66.4862 10.5393 61.451C5.50601 56.4158 2.0787 50.0011 0.690743 43.0182C-0.697213 36.0353 0.016507 28.7976 2.74166 22.2202C5.46682 15.6429 10.081 10.0213 16.0009 6.06614C21.9208 2.11102 28.8805 5.16611e-07 36 0C40.7288 -3.43129e-07 45.4112 0.931621 49.7798 2.74165C54.1484 4.55167 58.1176 7.20464 61.4607 10.549C64.8038 13.8934 67.4552 17.8636 69.2636 22.2329C71.072 26.6022 72.0018 31.285 72 36.0137Z"
            fill="#8CB2FF"
          />
          <path
            d="M67.5467 36.0116C67.5467 42.2502 65.6967 48.3487 62.2307 53.5359C58.7648 58.7231 53.8384 62.766 48.0747 65.1534C42.311 67.5408 35.9688 68.1655 29.8501 66.9484C23.7314 65.7313 18.111 62.7271 13.6996 58.3158C9.28829 53.9044 6.28413 48.284 5.06704 42.1653C3.84995 36.0466 4.4746 29.7044 6.86201 23.9407C9.24941 18.177 13.2923 13.2507 18.4795 9.78468C23.6667 6.31871 29.7652 4.46875 36.0038 4.46875C40.1461 4.46875 44.2478 5.28463 48.0747 6.86981C51.9017 8.45498 55.3789 10.7784 58.308 13.7074C61.237 16.6365 63.5604 20.1137 65.1456 23.9407C66.7308 27.7676 67.5467 31.8693 67.5467 36.0116Z"
            fill="white"
          />
          <path
            d="M54.7731 40.0453C54.7001 40.4184 54.5774 40.78 54.4083 41.1205C53.8592 42.2222 53.2052 43.2684 52.4554 44.2446C52.0824 44.7164 50.4065 45.243 50.4065 45.243L50.3901 45.3061C50.3133 45.5612 50.0253 46.5458 49.7455 47.4044C49.7263 47.4674 49.7043 47.5333 49.6797 47.5991C49.655 47.6649 49.633 47.7308 49.6029 47.813V47.8268C49.1201 49.0638 47.4497 52.4046 47.2851 52.7337V52.7474L46.9478 57.3719V57.4652C46.9478 58.0604 47.1096 64.9477 47.4387 66.8512C47.5603 67.5088 47.8001 68.1389 48.1464 68.7109C48.3432 69.0363 48.5632 69.347 48.8047 69.6407C41.0085 72.5882 32.4334 72.752 24.5304 70.1042C24.6045 69.797 24.6867 69.4432 24.7773 69.0592C25.2819 66.8841 25.9869 63.749 26.1487 62.4407V62.3886C26.1789 62.1582 26.2008 61.8757 26.2227 61.5657V61.5246C26.3955 58.8201 26.3325 53.4688 26.3325 53.4688L25.6961 51.9246L23.2906 46.1646L22.9258 45.2512C22.103 45.2156 20.7315 43.8798 20.6328 43.7865C20.6328 43.7865 19.0035 41.1534 18.4906 39.0332C18.3178 38.3118 18.2739 37.648 18.4632 37.168C18.6068 36.7964 18.8117 36.4515 19.0694 36.1477C19.5312 35.5938 20.1711 35.2176 20.8797 35.0834L21.5544 28.6103L22.9752 23.3989L35.3345 17.8281L42.4495 18.0558L51.6819 24.496L52.2086 27.5954L52.8998 34.5733L53.3414 35.0944L53.5855 35.3852L54.6086 36.592L54.7704 36.7813C54.773 36.8914 54.7821 37.0013 54.7978 37.1104C54.8905 38.0869 54.8823 39.0704 54.7731 40.0453Z"
            fill="#8CB2FF"
          />
          <path
            d="M41.7157 26.8389C43.2608 26.8389 44.5134 25.7767 44.5134 24.4663C44.5134 23.156 43.2608 22.0938 41.7157 22.0938C40.1706 22.0938 38.918 23.156 38.918 24.4663C38.918 25.7767 40.1706 26.8389 41.7157 26.8389Z"
            fill="black"
          />
          <path
            d="M46.2246 27.7448C47.6971 27.7448 48.8907 26.0845 48.8907 24.0365C48.8907 21.9884 47.6971 20.3281 46.2246 20.3281C44.7522 20.3281 43.5586 21.9884 43.5586 24.0365C43.5586 26.0845 44.7522 27.7448 46.2246 27.7448Z"
            fill="black"
          />
          <path
            d="M35.1632 26.8394C36.4766 26.8394 37.5413 25.9134 37.5413 24.7712C37.5413 23.6291 36.4766 22.7031 35.1632 22.7031C33.8499 22.7031 32.7852 23.6291 32.7852 24.7712C32.7852 25.9134 33.8499 26.8394 35.1632 26.8394Z"
            fill="black"
          />
          <path
            d="M37.4888 29.6189C38.8562 29.0217 39.3838 27.2075 38.6673 25.5665C37.9507 23.9256 36.2613 23.0795 34.8939 23.6766C33.5265 24.2737 32.9989 26.088 33.7154 27.7289C34.432 29.3698 36.1214 30.216 37.4888 29.6189Z"
            fill="black"
          />
          <path
            d="M42.7505 29.8142C44.1179 29.2171 44.6456 27.4028 43.929 25.7619C43.2124 24.121 41.523 23.2748 40.1556 23.8719C38.7882 24.4691 38.2606 26.2833 38.9771 27.9243C39.6937 29.5652 41.3831 30.4113 42.7505 29.8142Z"
            fill="black"
          />
          <path
            d="M45.6222 31.1362C47.6198 30.2638 48.6584 28.2264 47.9419 26.5855C47.2253 24.9446 45.025 24.3215 43.0273 25.1939C41.0296 26.0663 39.991 28.1037 40.7076 29.7446C41.4241 31.3855 43.6245 32.0085 45.6222 31.1362Z"
            fill="black"
          />
          <path
            d="M16.7617 26.839C18.1554 26.839 19.2851 25.9953 19.2851 24.9547C19.2851 23.914 18.1554 23.0703 16.7617 23.0703C15.3681 23.0703 14.2383 23.914 14.2383 24.9547C14.2383 25.9953 15.3681 26.839 16.7617 26.839Z"
            fill="black"
          />
          <path
            d="M14.832 30.5265C16.2257 30.5265 17.3555 29.6828 17.3555 28.6422C17.3555 27.6015 16.2257 26.7578 14.832 26.7578C13.4384 26.7578 12.3086 27.6015 12.3086 28.6422C12.3086 29.6828 13.4384 30.5265 14.832 30.5265Z"
            fill="black"
          />
          <path
            d="M12.7773 39.3468C14.171 39.3468 15.3008 38.5032 15.3008 37.4625C15.3008 36.4218 14.171 35.5781 12.7773 35.5781C11.3837 35.5781 10.2539 36.4218 10.2539 37.4625C10.2539 38.5032 11.3837 39.3468 12.7773 39.3468Z"
            fill="black"
          />
          <path
            d="M16.7113 26.8122C16.911 26.2821 16.4236 25.6077 15.6227 25.306C14.8217 25.0043 14.0105 25.1895 13.8108 25.7197C13.6111 26.2499 14.0985 26.9243 14.8995 27.226C15.7004 27.5276 16.5116 27.3424 16.7113 26.8122Z"
            fill="black"
          />
          <path
            d="M12.917 43.0022C13.7014 42.6597 14.1535 41.9611 13.9267 41.4419C13.7 40.9227 12.8803 40.7795 12.096 41.122C11.3116 41.4645 10.8596 42.1631 11.0863 42.6823C11.313 43.2015 12.1327 43.3447 12.917 43.0022Z"
            fill="black"
          />
          <path
            d="M18.1806 21.6701C20.0257 21.6701 21.5214 20.894 21.5214 19.9366C21.5214 18.9792 20.0257 18.2031 18.1806 18.2031C16.3356 18.2031 14.8398 18.9792 14.8398 19.9366C14.8398 20.894 16.3356 21.6701 18.1806 21.6701Z"
            fill="black"
          />
          <path
            d="M20.3027 19.0024C22.2584 19.0024 23.8438 18.0348 23.8438 16.8411C23.8438 15.6474 22.2584 14.6797 20.3027 14.6797C18.3471 14.6797 16.7617 15.6474 16.7617 16.8411C16.7617 18.0348 18.3471 19.0024 20.3027 19.0024Z"
            fill="black"
          />
          <path
            d="M21.7539 15.3631C22.3795 15.3631 22.8867 14.528 22.8867 13.498C22.8867 12.4679 22.3795 11.6328 21.7539 11.6328C21.1283 11.6328 20.6211 12.4679 20.6211 13.498C20.6211 14.528 21.1283 15.3631 21.7539 15.3631Z"
            fill="black"
          />
          <path
            d="M33.9683 25.5694C34.4029 24.4154 33.9784 23.1874 33.0201 22.8264C32.0618 22.4654 30.9326 23.1083 30.4979 24.2622C30.0633 25.4161 30.4878 26.6442 31.4461 27.0051C32.4044 27.3661 33.5336 26.7233 33.9683 25.5694Z"
            fill="black"
          />
          <path
            d="M49.4157 30.8573C49.9465 29.4482 49.5999 28.0133 48.6416 27.6523C47.6833 27.2914 46.4762 28.1411 45.9454 29.5502C45.4146 30.9593 45.7612 32.3942 46.7195 32.7551C47.6778 33.1161 48.885 32.2664 49.4157 30.8573Z"
            fill="black"
          />
          <path
            d="M53.1004 34.0026C53.6311 32.5935 52.98 31.0438 51.646 30.5414C50.3121 30.0389 48.8004 30.7739 48.2696 32.183C47.7389 33.5921 48.39 35.1417 49.724 35.6442C51.0579 36.1466 52.5696 35.4117 53.1004 34.0026Z"
            fill="black"
          />
          <path
            d="M53.6629 30.151C54.1936 28.7419 53.5425 27.1923 52.2085 26.6898C50.8746 26.1873 49.3629 26.9223 48.8321 28.3314C48.3014 29.7405 48.9525 31.2901 50.2865 31.7926C51.6204 32.2951 53.1321 31.5601 53.6629 30.151Z"
            fill="black"
          />
          <path
            d="M41.6089 29.6709C42.0435 28.517 41.619 27.2889 40.6607 26.928C39.7024 26.567 38.5732 27.2098 38.1386 28.3637C37.7039 29.5177 38.1284 30.7457 39.0867 31.1067C40.045 31.4677 41.1742 30.8249 41.6089 29.6709Z"
            fill="black"
          />
          <path
            d="M27.0765 16.8367C29.164 16.8367 30.8562 15.4269 30.8562 13.6879C30.8562 11.9488 29.164 10.5391 27.0765 10.5391C24.9891 10.5391 23.2969 11.9488 23.2969 13.6879C23.2969 15.4269 24.9891 16.8367 27.0765 16.8367Z"
            fill="black"
          />
          <path
            d="M30.6519 13.4975C31.6138 13.4975 32.3936 12.2634 32.3936 10.7409C32.3936 9.21853 31.6138 7.98438 30.6519 7.98438C29.6899 7.98438 28.9102 9.21853 28.9102 10.7409C28.9102 12.2634 29.6899 13.4975 30.6519 13.4975Z"
            fill="black"
          />
          <path
            d="M25.0746 19.7664C26.8848 19.7664 28.3523 18.2989 28.3523 16.4887C28.3523 14.6784 26.8848 13.2109 25.0746 13.2109C23.2644 13.2109 21.7969 14.6784 21.7969 16.4887C21.7969 18.2989 23.2644 19.7664 25.0746 19.7664Z"
            fill="black"
          />
          <path
            d="M24.6518 27.0536C27.3482 27.0536 29.5341 24.8678 29.5341 22.1713C29.5341 19.4749 27.3482 17.2891 24.6518 17.2891C21.9554 17.2891 19.7695 19.4749 19.7695 22.1713C19.7695 24.8678 21.9554 27.0536 24.6518 27.0536Z"
            fill="black"
          />
          <path
            d="M21.2143 29.6552C23.9107 29.6552 26.0966 27.4693 26.0966 24.7729C26.0966 22.0765 23.9107 19.8906 21.2143 19.8906C18.5179 19.8906 16.332 22.0765 16.332 24.7729C16.332 27.4693 18.5179 29.6552 21.2143 29.6552Z"
            fill="black"
          />
          <path
            d="M37.5382 27.2925C38.8516 27.2925 39.9163 26.3666 39.9163 25.2244C39.9163 24.0822 38.8516 23.1562 37.5382 23.1562C36.2248 23.1562 35.1602 24.0822 35.1602 25.2244C35.1602 26.3666 36.2248 27.2925 37.5382 27.2925Z"
            fill="black"
          />
          <path
            d="M23.296 36.5815C24.6094 36.5815 25.6741 35.6556 25.6741 34.5134C25.6741 33.3712 24.6094 32.4453 23.296 32.4453C21.9827 32.4453 20.918 33.3712 20.918 34.5134C20.918 35.6556 21.9827 36.5815 23.296 36.5815Z"
            fill="black"
          />
          <path
            d="M24.1906 33.7784C25.5039 33.7784 26.5686 32.6265 26.5686 31.2056C26.5686 29.7847 25.5039 28.6328 24.1906 28.6328C22.8772 28.6328 21.8125 29.7847 21.8125 31.2056C21.8125 32.6265 22.8772 33.7784 24.1906 33.7784Z"
            fill="black"
          />
          <path
            d="M24.8077 29.63C26.1211 29.63 27.1858 28.4781 27.1858 27.0572C27.1858 25.6363 26.1211 24.4844 24.8077 24.4844C23.4944 24.4844 22.4297 25.6363 22.4297 27.0572C22.4297 28.4781 23.4944 29.63 24.8077 29.63Z"
            fill="black"
          />
          <path
            d="M42.1887 27.3821C43.7232 27.3821 44.9672 26.4169 44.9672 25.2262C44.9672 24.0355 43.7232 23.0703 42.1887 23.0703C40.6541 23.0703 39.4102 24.0355 39.4102 25.2262C39.4102 26.4169 40.6541 27.3821 42.1887 27.3821Z"
            fill="black"
          />
          <path
            d="M56.2852 26.839C57.6788 26.839 58.8086 25.9953 58.8086 24.9547C58.8086 23.914 57.6788 23.0703 56.2852 23.0703C54.8915 23.0703 53.7617 23.914 53.7617 24.9547C53.7617 25.9953 54.8915 26.839 56.2852 26.839Z"
            fill="black"
          />
          <path
            d="M56.2594 23.4518C57.0603 23.1501 57.5477 22.4758 57.348 21.9456C57.1483 21.4154 56.3371 21.2302 55.5362 21.5319C54.7352 21.8336 54.2478 22.5079 54.4475 23.0381C54.6472 23.5683 55.4584 23.7535 56.2594 23.4518Z"
            fill="black"
          />
          <path
            d="M54.8603 21.6701C56.7054 21.6701 58.2011 20.894 58.2011 19.9366C58.2011 18.9792 56.7054 18.2031 54.8603 18.2031C53.0153 18.2031 51.5195 18.9792 51.5195 19.9366C51.5195 20.894 53.0153 21.6701 54.8603 21.6701Z"
            fill="black"
          />
          <path
            d="M52.7442 19.0024C54.6998 19.0024 56.2852 18.0348 56.2852 16.8411C56.2852 15.6474 54.6998 14.6797 52.7442 14.6797C50.7885 14.6797 49.2031 15.6474 49.2031 16.8411C49.2031 18.0348 50.7885 19.0024 52.7442 19.0024Z"
            fill="black"
          />
          <path
            d="M51.293 15.3631C51.9186 15.3631 52.4258 14.528 52.4258 13.498C52.4258 12.4679 51.9186 11.6328 51.293 11.6328C50.6673 11.6328 50.1602 12.4679 50.1602 13.498C50.1602 14.528 50.6673 15.3631 51.293 15.3631Z"
            fill="black"
          />
          <path
            d="M45.9711 16.8367C48.0585 16.8367 49.7507 15.4269 49.7507 13.6879C49.7507 11.9488 48.0585 10.5391 45.9711 10.5391C43.8836 10.5391 42.1914 11.9488 42.1914 13.6879C42.1914 15.4269 43.8836 16.8367 45.9711 16.8367Z"
            fill="black"
          />
          <path
            d="M33.4175 13.3022C34.3794 13.3022 35.1592 12.068 35.1592 10.5456C35.1592 9.02322 34.3794 7.78906 33.4175 7.78906C32.4556 7.78906 31.6758 9.02322 31.6758 10.5456C31.6758 12.068 32.4556 13.3022 33.4175 13.3022Z"
            fill="black"
          />
          <path
            d="M47.9691 19.7664C49.7794 19.7664 51.2468 18.2989 51.2468 16.4887C51.2468 14.6784 49.7794 13.2109 47.9691 13.2109C46.1589 13.2109 44.6914 14.6784 44.6914 16.4887C44.6914 18.2989 46.1589 19.7664 47.9691 19.7664Z"
            fill="black"
          />
          <path
            d="M48.394 27.0536C51.0904 27.0536 53.2763 24.8678 53.2763 22.1713C53.2763 19.4749 51.0904 17.2891 48.394 17.2891C45.6976 17.2891 43.5117 19.4749 43.5117 22.1713C43.5117 24.8678 45.6976 27.0536 48.394 27.0536Z"
            fill="black"
          />
          <path
            d="M51.8315 29.6552C54.5279 29.6552 56.7138 27.4693 56.7138 24.7729C56.7138 22.0765 54.5279 19.8906 51.8315 19.8906C49.1351 19.8906 46.9492 22.0765 46.9492 24.7729C46.9492 27.4693 49.1351 29.6552 51.8315 29.6552Z"
            fill="black"
          />
          <path
            d="M34.2727 9.50547C34.2727 9.50547 36.8263 9.1105 36.9772 12.0892C36.9772 12.0892 39.5308 7.37702 43.2419 8.52353C46.953 9.67005 42.4519 12.5336 42.4519 12.5336L47.9733 18.343L45.5047 23.1786L40.3756 26.4316L32.3857 24.9423L26.6641 24.454L27.5253 17.2787L28.5182 13.9654L34.2727 9.50547Z"
            fill="black"
          />
          <path
            d="M51.6534 35.3545C51.9758 35.1592 52.35 35.0667 52.7263 35.0893C53.1026 35.1118 53.4631 35.2483 53.7599 35.4807C54.3486 35.9608 54.7432 36.6381 54.8708 37.387C55.082 38.5284 54.9669 39.7063 54.5389 40.7854C54.1089 41.8589 53.531 42.8671 52.8218 43.7806C52.3117 44.4718 51.6644 45.1931 50.8086 45.2507"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.3633 40.3879C51.3872 39.9512 51.5746 39.5395 51.8881 39.2346C52.2017 38.9297 52.6184 38.7539 53.0556 38.7422"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.7895 35.2305C21.4668 35.035 21.0924 34.9421 20.7157 34.9641C20.3391 34.9862 19.978 35.1221 19.6803 35.3539C19.096 35.8415 18.7066 36.523 18.5831 37.2739C18.3689 38.415 18.4791 39.5934 18.9013 40.675C19.3315 41.7465 19.9074 42.7535 20.6128 43.6675C21.123 44.3614 21.7703 45.0828 22.6289 45.1377"
            stroke="#080808"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.0818 40.2629C22.0572 39.826 21.8692 39.4142 21.5553 39.1094C21.2413 38.8046 20.8242 38.6289 20.3867 38.6172"
            stroke="#080808"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.1946 53.125C46.8862 56.4839 46.8064 59.8599 46.956 63.2297C47.052 65.3115 47.2742 67.4866 48.418 69.2311"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.3307 53.4766C26.4678 58.976 26.4623 64.629 24.5039 69.7719"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.6838 35.2734C51.6838 48.8972 44.983 59.94 36.7215 59.94C28.8769 59.94 22.4422 49.9889 21.8086 37.3278"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.332 53.4766C26.332 53.4766 28.0381 64.1737 36.4806 64.1737C44.9231 64.1737 46.9035 53.9428 46.9035 53.9428C46.9035 53.9428 42.0925 60.4736 36.7549 60.2185C31.4173 59.9634 26.332 53.4766 26.332 53.4766Z"
            fill="black"
          />
          <path
            d="M32.3983 43.7734C32.2949 44.2149 32.3645 44.6792 32.5931 45.0708C32.7134 45.2602 32.8951 45.4024 33.1078 45.4738C33.3205 45.5452 33.5513 45.5414 33.7615 45.463C33.826 45.427 33.8941 45.3976 33.9645 45.3753C33.9997 45.3661 34.0368 45.3677 34.0711 45.38C34.1053 45.3922 34.1351 45.4144 34.1565 45.4438"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.0625 45.3747C34.8948 46.1175 35.9266 46.6001 37.0303 46.7626C38.1274 46.8805 39.326 46.4883 39.9871 45.6024C40.0949 45.4132 40.2463 45.2524 40.4287 45.1333C40.5559 45.0836 40.6884 45.0486 40.8236 45.0291C41.181 44.9311 41.4862 44.6981 41.6751 44.3793C41.8639 44.0605 41.9215 43.6808 41.8358 43.3203"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.6379 48.4897C43.4349 48.0069 43.4514 47.6915 43.0125 47.4062C43.1986 47.5726 43.3385 47.7842 43.4185 48.0206C41.9192 49.0522 40.2054 49.7302 38.4062 50.0034C36.6069 50.2766 34.7691 50.1379 33.0312 49.5978C33.0889 50.7224 33.8294 51.6906 34.6139 52.497C35.0497 52.9902 35.5769 53.3945 36.1663 53.6874C36.667 53.885 37.2036 53.9751 37.7413 53.952C38.279 53.9288 38.8059 53.7929 39.2877 53.553C40.245 53.0625 41.0866 52.3733 41.7563 51.5315C42.5343 50.619 43.1687 49.5933 43.6379 48.4897Z"
            fill="white"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M44.2078 11.8761C44.1526 10.705 43.8118 9.5652 43.2152 8.55599C42.6186 7.54677 41.7842 6.69879 40.7847 6.08594C40.2588 7.47657 40.1204 8.98392 40.3843 10.4471C40.5203 11.1753 40.8011 11.8688 41.21 12.4864C41.619 13.1041 42.1478 13.6334 42.7651 14.043"
            fill="#43C493"
          />
          <path
            d="M44.2078 11.8761C44.1526 10.705 43.8118 9.5652 43.2152 8.55599C42.6186 7.54677 41.7842 6.69879 40.7847 6.08594C40.2588 7.47657 40.1204 8.98392 40.3843 10.4471C40.5203 11.1753 40.8011 11.8688 41.21 12.4864C41.619 13.1041 42.1478 13.6334 42.7651 14.043"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.2969 9.77344C42.8455 10.5343 43.1445 11.4466 43.1526 12.3846"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.9102 12.4212C47.4999 11.4722 48.5257 10.8606 49.6009 10.5588C50.6883 10.3006 51.8055 10.1889 52.9225 10.227C53.2063 10.1934 53.4927 10.2635 53.7289 10.4244C53.4191 11.8835 52.5674 13.1708 51.3457 14.0265C50.124 14.8822 48.6231 15.2427 47.146 15.0352"
            fill="#43C493"
          />
          <path
            d="M46.9102 12.4212C47.4999 11.4722 48.5257 10.8606 49.6009 10.5588C50.6883 10.3006 51.8055 10.1889 52.9225 10.227C53.2063 10.1934 53.4927 10.2635 53.7289 10.4244C53.4191 11.8835 52.5674 13.1708 51.3457 14.0265C50.124 14.8822 48.6231 15.2427 47.146 15.0352"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.3125 13.6563C47.6948 13.2496 48.1369 12.9037 48.6236 12.6305C48.6998 12.5695 48.7916 12.531 48.8885 12.5194C48.9854 12.5077 49.0837 12.5234 49.1722 12.5646"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.6758 15.2369C50.908 18.3963 53.7119 23.1074 54.471 28.334V28.2462C52.0271 24.8396 49.0511 21.6908 45.3016 19.8174C43.0561 18.7369 40.6457 18.0398 38.1702 17.7548C36.0746 17.4805 33.9187 17.4421 31.8616 17.9688C29.7084 18.5173 27.7473 19.6885 25.9727 21.0188C23.1366 23.139 20.5967 25.7804 19.1484 29.0142C20.5355 24.7715 23.068 20.9946 26.4664 18.1004C29.8182 15.2917 34.0531 13.4787 38.4198 13.2949C39.6184 13.2428 40.7813 13.55 41.9635 13.7365C42.1994 13.7722 46.6648 15.2286 46.6648 15.2286"
            fill="#43C493"
          />
          <path
            d="M46.6758 15.2369C50.908 18.3963 53.7119 23.1074 54.471 28.334V28.2462C52.0271 24.8396 49.0511 21.6908 45.3016 19.8174C43.0561 18.7369 40.6457 18.0398 38.1702 17.7548C36.0746 17.4805 33.9187 17.4421 31.8616 17.9688C29.7084 18.5173 27.7473 19.6885 25.9727 21.0188C23.1366 23.139 20.5967 25.7804 19.1484 29.0142C20.5355 24.7715 23.068 20.9946 26.4664 18.1004C29.8182 15.2917 34.0531 13.4787 38.4197 13.2949C39.6184 13.2428 40.7813 13.55 41.9635 13.7365C42.1994 13.7722 46.6648 15.2286 46.6648 15.2286"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.2371 13.6069C47.0496 13.9482 46.9224 14.3194 46.8613 14.704C46.7982 15.0933 46.5844 15.4422 46.2661 15.675C45.8656 15.9136 45.3527 15.7902 44.9111 15.6421C44.2993 15.4371 43.7019 15.1916 43.1228 14.907C43.0798 14.8906 43.0413 14.8643 43.0103 14.8302C42.9662 14.769 42.9448 14.6943 42.95 14.619C42.9476 14.1845 43.021 13.7529 43.1666 13.3436C43.3833 12.7319 43.8167 12.1367 44.4503 11.9941C44.8593 11.9359 45.2764 11.9783 45.6654 12.1175C46.0956 12.2044 46.499 12.3924 46.8421 12.6661C47.1712 12.9733 47.3385 13.4615 47.2371 13.6069Z"
            fill="#43C493"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.6227 34.3031L20.7121 34.8818C20.7121 34.8818 18.751 35.1561 18.6522 36.9362C18.5535 38.7164 18.9951 41.2261 18.9951 41.2261L21.0605 44.4352L23.1149 46.0617L25.4408 51.6709C25.4408 51.6709 26.4721 52.971 26.3295 53.9612C26.1869 54.9513 26.3569 58.827 26.3569 58.827L24.1873 60.3657L20.8794 55.6782C20.8794 55.6782 11.9542 50.8261 13.2323 42.3232C13.2323 42.3232 9.0056 38.2089 14.4474 30.4055C14.4474 30.4055 13.3503 26.9084 16.7569 25.814C20.1635 24.7196 23.4467 26.9111 23.4467 26.9111L22.9256 30.5646L21.6227 34.3031Z"
            fill="black"
          />
          <path
            d="M22.8 44.7542C22.8521 44.362 22.5806 43.9999 22.2789 43.7476C22.0976 43.5756 21.8695 43.4611 21.6233 43.4184C21.179 43.3718 20.7785 43.7064 20.5262 44.0795C20.1725 44.6629 19.983 45.331 19.9776 46.0132C19.9341 46.7107 20.0514 47.4089 20.3205 48.0538C20.5946 48.7003 21.0774 49.2363 21.6919 49.5761C22.0175 49.7482 22.3752 49.851 22.7424 49.8779C23.1488 49.9168 23.5567 49.8204 23.9026 49.6036C23.9756 49.5639 24.0393 49.5091 24.0894 49.4429C24.1396 49.3767 24.175 49.3006 24.1934 49.2196C24.1986 49.1265 24.1855 49.0333 24.155 48.9453L23.749 47.412C23.7438 47.38 23.7306 47.3499 23.7106 47.3243C23.6503 47.2557 23.5406 47.2941 23.4364 47.2996C23.1621 47.3215 22.9673 47.0253 22.8631 46.7702C22.6162 46.1613 22.6958 45.5222 22.8 44.7542Z"
            fill="white"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.4648 35.4046C16.1918 34.018 16.8376 31.9546 15.9072 30.7959C14.9768 29.6371 12.8227 29.8217 11.0957 31.2082C9.36873 32.5947 8.72296 34.6581 9.65332 35.8169C10.5837 36.9757 12.7379 36.7911 14.4648 35.4046Z"
            fill="black"
          />
          <path
            d="M16.4281 46.718C16.921 44.5588 16.1461 42.5404 14.6973 42.2096C13.2486 41.8789 11.6745 43.3611 11.1816 45.5202C10.6887 47.6794 11.4635 49.6978 12.9123 50.0286C14.3611 50.3593 15.9352 48.8771 16.4281 46.718Z"
            fill="black"
          />
          <path
            d="M19.6312 48.9602C20.1241 46.801 19.3493 44.7826 17.9005 44.4518C16.4517 44.1211 14.8776 45.6033 14.3847 47.7624C13.8918 49.9215 14.6667 51.94 16.1154 52.2708C17.5642 52.6015 19.1383 51.1193 19.6312 48.9602Z"
            fill="black"
          />
          <path
            d="M17.0083 51.2422C16.565 51.7487 16.2897 52.3803 16.2204 53.0499C16.1511 53.7195 16.2913 54.3941 16.6216 54.9807C17.7791 57.079 19.7539 55.9242 19.7539 55.9242C19.7539 55.9242 20.0885 59.4186 21.4271 59.6326C22.7656 59.8465 23.0975 59.2376 23.0975 59.2376C23.0975 59.2376 23.55 61.1384 24.8913 61.3359C26.2325 61.5334 26.3724 58.8234 26.3724 58.8234L22.8259 55.8063L17.0083 51.2422Z"
            fill="black"
          />
          <path
            d="M56.7148 26.839C58.1085 26.839 59.2383 25.9953 59.2383 24.9547C59.2383 23.914 58.1085 23.0703 56.7148 23.0703C55.3212 23.0703 54.1914 23.914 54.1914 24.9547C54.1914 25.9953 55.3212 26.839 56.7148 26.839Z"
            fill="black"
          />
          <path
            d="M58.6445 30.5265C60.0382 30.5265 61.1679 29.6828 61.1679 28.6422C61.1679 27.6015 60.0382 26.7578 58.6445 26.7578C57.2509 26.7578 56.1211 27.6015 56.1211 28.6422C56.1211 29.6828 57.2509 30.5265 58.6445 30.5265Z"
            fill="black"
          />
          <path
            d="M60.6953 39.3468C62.089 39.3468 63.2187 38.5032 63.2187 37.4625C63.2187 36.4218 62.089 35.5781 60.6953 35.5781C59.3017 35.5781 58.1719 36.4218 58.1719 37.4625C58.1719 38.5032 59.3017 39.3468 60.6953 39.3468Z"
            fill="black"
          />
          <path
            d="M58.5719 27.2409C59.3728 26.9392 59.8602 26.2648 59.6605 25.7346C59.4608 25.2045 58.6496 25.0192 57.8487 25.3209C57.0477 25.6226 56.5603 26.297 56.76 26.8272C56.9597 27.3574 57.7709 27.5426 58.5719 27.2409Z"
            fill="black"
          />
          <path
            d="M62.3877 42.6753C62.6144 42.156 62.1623 41.4575 61.378 41.115C60.5936 40.7725 59.774 40.9157 59.5472 41.4349C59.3205 41.9541 59.7726 42.6527 60.5569 42.9952C61.3413 43.3377 62.1609 43.1945 62.3877 42.6753Z"
            fill="black"
          />
          <path
            d="M52.2612 29.6552C54.9576 29.6552 57.1435 27.4693 57.1435 24.7729C57.1435 22.0765 54.9576 19.8906 52.2612 19.8906C49.5648 19.8906 47.3789 22.0765 47.3789 24.7729C47.3789 27.4693 49.5648 29.6552 52.2612 29.6552Z"
            fill="black"
          />
          <path
            d="M51.8475 34.3031L52.7608 34.8818C52.7608 34.8818 54.722 35.1561 54.8207 36.9362C54.9195 38.7164 54.4696 41.2261 54.4696 41.2261L52.4015 44.4352L50.3553 46.0562L48.0294 51.6654C48.0294 51.6654 46.9981 52.9655 47.1407 53.9557C47.2833 54.9458 47.1133 58.8215 47.1133 58.8215L49.2829 60.3602L52.5908 55.6782C52.5908 55.6782 61.516 50.8261 60.2296 42.3232C60.2296 42.3232 64.4564 38.2089 59.0118 30.4055C59.0118 30.4055 60.1089 26.9084 56.7051 25.814C53.3012 24.7196 50.0125 26.9111 50.0125 26.9111L50.5391 30.5591L51.8475 34.3031Z"
            fill="black"
          />
          <path
            d="M63.8252 35.8291C64.7555 34.6704 64.1098 32.607 62.3828 31.2204C60.6558 29.8339 58.5016 29.6493 57.5713 30.8081C56.6409 31.9669 57.2867 34.0303 59.0137 35.4168C60.7406 36.8033 62.8948 36.9879 63.8252 35.8291Z"
            fill="black"
          />
          <path
            d="M60.5684 50.0248C62.0172 49.694 62.7921 47.6755 62.2992 45.5164C61.8062 43.3573 60.2322 41.8751 58.7834 42.2058C57.3346 42.5366 56.5597 44.555 57.0527 46.7142C57.5456 48.8733 59.1197 50.3555 60.5684 50.0248Z"
            fill="black"
          />
          <path
            d="M57.3614 52.2591C58.8102 51.9284 59.5851 49.9099 59.0921 47.7508C58.5992 45.5916 57.0252 44.1094 55.5764 44.4402C54.1276 44.7709 53.3527 46.7894 53.8456 48.9485C54.3386 51.1077 55.9126 52.5899 57.3614 52.2591Z"
            fill="black"
          />
          <path
            d="M56.4676 51.2422C56.913 51.7473 57.1906 52.3784 57.2618 53.0481C57.3331 53.7178 57.1945 54.3931 56.8653 54.9807C55.7106 57.079 53.7357 55.9242 53.7357 55.9242C53.7357 55.9242 53.4011 59.4186 52.0626 59.6326C50.724 59.8465 50.3894 59.2376 50.3894 59.2376C50.3894 59.2376 49.9396 61.1384 48.5956 61.3359C47.2516 61.5334 47.1172 58.8234 47.1172 58.8234L50.6637 55.8063L56.4676 51.2422Z"
            fill="black"
          />
          <path
            d="M50.7316 44.7515C50.6795 44.3592 50.951 43.9972 51.2527 43.7448C51.4363 43.5751 51.6648 43.4618 51.911 43.4184C52.3553 43.3718 52.7531 43.7064 53.0081 44.0795C53.3618 44.6629 53.5513 45.331 53.5567 46.0132C53.6003 46.7107 53.483 47.4089 53.2139 48.0539C52.9398 48.7003 52.4569 49.2363 51.8424 49.5761C51.5169 49.7483 51.1592 49.851 50.7919 49.8779C50.387 49.9209 49.9793 49.8294 49.6317 49.6173C49.5587 49.5776 49.495 49.5229 49.4449 49.4566C49.3948 49.3904 49.3593 49.3143 49.3409 49.2333C49.3358 49.1402 49.3488 49.0471 49.3793 48.959L49.7963 47.412C49.8016 47.38 49.8148 47.3499 49.8347 47.3243C49.895 47.2557 50.0047 47.2941 50.1089 47.2996C50.3832 47.3215 50.578 47.0253 50.6822 46.7702C50.9044 46.1585 50.8358 45.5195 50.7316 44.7515Z"
            fill="white"
            stroke="black"
            strokeWidth="0.548571"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.9365 32.2846C33.9882 32.6494 33.978 33.0204 33.9063 33.3818C33.8904 33.4821 33.8488 33.5766 33.7857 33.6561C33.6799 33.747 33.5465 33.7992 33.4071 33.8042C31.0236 34.1553 29.6522 33.475 27.1836 34.9507C28.8156 32.9649 31.1223 32.2243 33.9365 32.2846Z"
            fill="black"
          />
          <path
            d="M29.653 40.6463C30.115 40.6463 30.4896 39.8371 30.4896 38.8388C30.4896 37.8405 30.115 37.0312 29.653 37.0312C29.191 37.0312 28.8164 37.8405 28.8164 38.8388C28.8164 39.8371 29.191 40.6463 29.653 40.6463Z"
            fill="black"
          />
          <path
            d="M29.6523 37.0291C29.6523 37.0291 28.0395 37.2211 27.2578 36.7109C27.2578 36.7109 28.6292 38.0193 29.4686 38.0494C30.3079 38.0796 29.6523 37.0291 29.6523 37.0291Z"
            fill="black"
          />
          <path
            d="M39.4798 32.2846C39.4253 32.6493 39.4355 33.0207 39.5099 33.3818C39.5259 33.4821 39.5674 33.5766 39.6306 33.6561C39.7363 33.747 39.8698 33.7992 40.0091 33.8042C42.3927 34.1553 43.7641 33.475 46.2299 34.9507C44.5979 32.9649 42.2912 32.2243 39.4798 32.2846Z"
            fill="black"
          />
          <path
            d="M43.7624 40.6463C44.2244 40.6463 44.5989 39.8371 44.5989 38.8388C44.5989 37.8405 44.2244 37.0312 43.7624 37.0312C43.3003 37.0312 42.9258 37.8405 42.9258 38.8388C42.9258 39.8371 43.3003 40.6463 43.7624 40.6463Z"
            fill="black"
          />
          <path
            d="M43.7749 37.0291C43.7749 37.0291 45.3877 37.2211 46.1694 36.7109C46.1694 36.7109 44.798 38.0193 43.9587 38.0494C43.1194 38.0796 43.7749 37.0291 43.7749 37.0291Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="72"
              height="72"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);

PersonaAvatarSeven.propTypes = {
  opaque: PropTypes.bool,
};
PersonaAvatarSeven.defaultProps = {
  opaque: false,
};
