import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      backgroundColor: 'white',
      borderRadius: '8px',

      opacity: 1,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.shortest,
      }),

      '&[data-popper-reference-hidden]': {
        opacity: 0,
        pointerEvents: 'none',
      },

      '&$hidden': {
        display: 'none',
      },
    },
    hidden: {},
  }),
  { name: 'Backdrop' }
);
