import { useCallback } from 'react';

import { usePopups, CUSTOM_FORMULA_POPUP } from 'src/services/popup';

export const useCustomFormulaPopup = () => {
  const popups = usePopups();

  return useCallback(
    ({ customFormula, generationTool, brandVoice, filteredTools } = {}) => {
      return new Promise((resolve) => {
        popups.openPopup(CUSTOM_FORMULA_POPUP, {
          customFormula,
          generationTool,
          brandVoice,
          filteredTools,
          onClose: (result) => {
            popups.closePopup(CUSTOM_FORMULA_POPUP);
            resolve(result);
          },
        });
      });
    },
    [popups]
  );
};
