import { createStateSelectorHook } from 'src/lib/sweetState';
import { WorkspaceStore } from 'src/store';

const useWorkspaceState = createStateSelectorHook(WorkspaceStore, [
  'loadingWorkspaces',
  'loadingCurrentWorkspace',
]);

export const useViewState = () => {
  const workspaceState = useWorkspaceState();

  return workspaceState;
};
