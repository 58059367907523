import { createStore } from 'react-sweet-state';
import { cloneDeep } from 'lodash';

const INITIAL_STATE = {
  menuItemId: null,
  collapseItemId: null,
};

export const PRIVATE_ACTIONS = {
  initStore:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },
};

export const ACTIONS = {
  toggleMenuItem:
    (id, keepOpen) =>
    ({ getState, setState }) => {
      const { menuItemId: current } = getState();
      if (keepOpen && current === id) {
        return;
      }
      setState({ menuItemId: current !== id ? id : null });
    },
  toggleCollapseItem:
    (id, keepOpen) =>
    ({ getState, setState }) => {
      const { collapseItemId: current } = getState();
      if (keepOpen && current === id) {
        return;
      }
      setState({ collapseItemId: current !== id ? id : null });
    },
  expandMenuState:
    ({ menuItemId, collapseItemId }) =>
    ({ setState }) => {
      setState({ menuItemId, collapseItemId });
    },
  collapseAll:
    () =>
    ({ setState }) => {
      setState({ menuItemId: null, collapseItemId: null });
    },
  getMenuState:
    () =>
    ({ getState }) => {
      const { menuItemId, collapseItemId } = getState();
      return {
        menuItemId,
        collapseItemId,
      };
    },
};

export const MainStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: 'navigation-sidebar',
});
