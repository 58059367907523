import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { DialogTitle, Typography } from '@material-ui/core';

import { useStyles } from './useStyles';
import { PopupCloseButton } from '../PopupCloseButton';

export const PopupHeader = ({ className, classes, title, onClose }) => {
  const styles = useStyles({ classes });

  return (
    <DialogTitle
      className={cx(className, styles.root)}
      disableTypography
    >
      {title && <Typography className={styles.title}>{title}</Typography>}
      {onClose && (
        <PopupCloseButton
          className={styles.buttonClose}
          onClick={onClose}
        />
      )}
    </DialogTitle>
  );
};

PopupHeader.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  onClose: PropTypes.func,
  title: PropTypes.string,
};

PopupHeader.defaultProps = {
  className: null,
  classes: null,
  onClose: null,
  title: null,
};
