import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(1),
    },
    term: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      gap: theme.spacing(0.5),
      minWidth: 0,
      color: COLORS.text.primary,
    },
    termType: {
      flexShrink: 0,
    },
    buttonApply: {
      minWidth: 'unset',
      padding: theme.spacing(1, 1.5),
    },
  }),
  { name: 'TermHeader' }
);
