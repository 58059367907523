import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const CustomFormControlLabel = withStyles(
  (theme) => ({
    root: {
      margin: '0',
      gap: theme.spacing(1),
    },
    label: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.text.primary,
    },
    disabled: {},
  }),
  { name: 'CustomFormControlLabel' }
)(FormControlLabel);

export default CustomFormControlLabel;
