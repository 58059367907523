import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CircularProgress, Fade } from '@material-ui/core';

import { useStyles } from './useStyles';

const DEFAULT_SPINNER_DELAY = 100;

const propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  spinnerDelay: PropTypes.number,
};

const defaultProps = {
  className: null,
  classes: null,
  spinnerDelay: DEFAULT_SPINNER_DELAY,
};

export const SuspenseFallbackPopup = ({ className, classes, spinnerDelay }) => {
  const styles = useStyles({ classes });
  const [spinnerVisible, setSpinnerVisible] = useState(!(spinnerDelay > 0));

  useEffect(() => {
    if (!spinnerDelay) {
      return;
    }

    setSpinnerVisible(false);
    const timer = setTimeout(() => setSpinnerVisible(true), spinnerDelay);
    return () => clearTimeout(timer);
  }, [spinnerDelay]);

  return (
    <div className={cx(styles.root, className)}>
      {spinnerVisible && (
        <Fade in>
          <div className={styles.panel}>
            <CircularProgress size={40} />
          </div>
        </Fade>
      )}
    </div>
  );
};

SuspenseFallbackPopup.displayName = 'SuspenseFallbackPopup';
SuspenseFallbackPopup.propTypes = propTypes;
SuspenseFallbackPopup.defaultProps = defaultProps;
