import { makeStyles } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  () => ({
    root: {
      color: COLORS.neutral.mediumLightGray,
      textTransform: 'uppercase',
    },
  }),
  { name: 'SidebarHeader' }
);
