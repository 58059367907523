import { createHook } from 'react-sweet-state';

import Store from './Store';

const selector = (state) => {
  const user = state.currentUser;
  return (user && user.id) || null;
};

const useHook = createHook(Store, {
  selector,
});

export const useCurrentUserId = () => {
  const [id] = useHook();
  return id;
};
