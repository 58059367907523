import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';

import { AI_SUGGESTION_MODE } from 'src/data/generation';
import {
  doesToolSupportEnhancedImprovements,
  isBasicGenerationTool,
  shouldDisableTOVModes,
} from 'src/data/generationTool';
import { BaseItem } from '../BaseItem';
import { BriefItem } from '../BriefItem';
import { FrameworkItem } from '../FrameworkItem';
import { GenerationToolItem } from '../GenerationToolItem';
import { TovItem } from '../TovItem';
import { useStyles } from './useStyles';

const serializeItems = (items) => items.join(', ');

const mergeTalkingPoints = (generationGuidance) => {
  const { campaignKeywords, keywords, features, benefits } = generationGuidance || {};
  const talkingPoints = [];
  if (keywords?.length > 0) {
    talkingPoints.push(...keywords);
  }
  if (features?.length > 0) {
    talkingPoints.push(...features);
  }
  if (benefits?.length > 0) {
    talkingPoints.push(...benefits);
  }

  //campaign keywords might be sent also as keywords (for legacy reasons) - so remove them from the array
  return talkingPoints.filter((tp) => !(campaignKeywords || []).includes(tp));
};

export const Details = ({
  className,
  generationData,
  displayGenerationTool,
  collapsibleBrief,
  vertical,
  multiline,
  baseItemClasses,
  isRecompose,
}) => {
  const {
    generationTool,
    keywords: legacyKeywords,
    personalizedName,
    suggestionMode,
    generationGuidance,
    productName,
    customGenerationModel,
    multiPromptStepName,
    tov,
    promptImageNames,
    promptDocumentNames,
  } = generationData;

  const styles = useStyles();

  // Hint: copyPurpose is how the BE saves callToAction. Use the copyPurpose to display the callToAction that
  // the user chose. @Dov
  const {
    avatar,
    campaignKeywords,
    hashtags,
    url,
    casing,
    copyPurpose: callToAction,
  } = generationGuidance || {};
  const talkingPoints = mergeTalkingPoints(generationGuidance);
  const isBasicTool = isBasicGenerationTool(generationTool);
  const isDisabledTOV = shouldDisableTOVModes(generationTool);
  const isImproveExisting = isRecompose && !isBasicTool; // basic content improver should be excluded
  const isDisplayBrief = !isRecompose;
  const isPersonalizedDetails = suggestionMode === AI_SUGGESTION_MODE.personalized;
  const isQuotesMode = suggestionMode === AI_SUGGESTION_MODE.quoteUrl;
  const isToolSupportsEnhancedImprovements = doesToolSupportEnhancedImprovements(generationTool);
  const shouldShowFrameworkItem = !isImproveExisting || !isToolSupportsEnhancedImprovements;
  const customModelName = customGenerationModel?.accountName;

  return (
    <>
      {url && (
        <BaseItem
          label="URL"
          vertical={vertical}
          classes={baseItemClasses}
          hideTitleColon={multiline}
        >
          {url}
        </BaseItem>
      )}
      {multiPromptStepName && (
        <Typography className={styles.title}>{multiPromptStepName}</Typography>
      )}
      {displayGenerationTool && (
        <Typography className={cx(className, !!url && 'mt-1')}>
          <GenerationToolItem
            generationData={generationData}
            vertical={vertical}
            classes={baseItemClasses}
            multiline={multiline}
          />
        </Typography>
      )}
      {isDisplayBrief ? (
        <Typography className={className}>
          <BriefItem
            generationTool={generationTool}
            requestData={generationData}
            collapsible={collapsibleBrief}
            vertical={vertical}
            baseItemClasses={baseItemClasses}
            multiline={multiline}
          />
        </Typography>
      ) : (
        !!productName && (
          <BaseItem
            label="Product Name"
            vertical={vertical}
            classes={baseItemClasses}
            hideTitleColon={multiline}
          >
            {productName}
          </BaseItem>
        )
      )}
      {promptImageNames && (
        <BaseItem
          label="Prompt Images"
          vertical={vertical}
          classes={baseItemClasses}
          hideTitleColon={multiline}
        >
          {promptImageNames}
        </BaseItem>
      )}
      {promptDocumentNames && (
        <BaseItem
          label="Prompt Documents"
          vertical={vertical}
          classes={baseItemClasses}
          hideTitleColon={multiline}
        >
          {promptDocumentNames}
        </BaseItem>
      )}
      {callToAction?.length > 0 && (
        <BaseItem
          label="What is your call to action about"
          vertical={vertical}
          classes={baseItemClasses}
          hideTitleColon={multiline}
        >
          {callToAction}
        </BaseItem>
      )}
      <Typography className={className}>
        {!!avatar && (
          <BaseItem
            label="Target audience"
            vertical={vertical}
            classes={baseItemClasses}
            hideTitleColon={multiline}
          >
            {avatar}
          </BaseItem>
        )}

        {!isPersonalizedDetails && (
          <>
            {!isDisabledTOV && !isQuotesMode && (
              <TovItem
                tov={tov}
                customModelName={customModelName}
                vertical={vertical}
                multiline={multiline}
                classes={baseItemClasses}
              />
            )}

            {legacyKeywords && talkingPoints?.length === 0 && (
              <BaseItem
                label="Keywords"
                vertical={vertical}
                classes={baseItemClasses}
                hideTitleColon={multiline}
              >
                {legacyKeywords}
              </BaseItem>
            )}

            {talkingPoints?.length > 0 && (
              <BaseItem
                label="Talking points"
                vertical={vertical}
                classes={baseItemClasses}
                hideTitleColon={multiline}
              >
                {serializeItems(talkingPoints)}
              </BaseItem>
            )}

            {campaignKeywords?.length > 0 && (
              <BaseItem
                label="Campaign Keywords"
                vertical={vertical}
                classes={baseItemClasses}
                hideTitleColon={multiline}
              >
                {serializeItems(campaignKeywords)}
              </BaseItem>
            )}

            {hashtags?.length > 0 && (
              <BaseItem
                label="Hashtags"
                vertical={vertical}
                classes={baseItemClasses}
                hideTitleColon={multiline}
              >
                {serializeItems(hashtags)}
              </BaseItem>
            )}

            {shouldShowFrameworkItem && !customModelName && (
              <FrameworkItem
                generationTool={generationTool}
                requestData={generationData}
                vertical={vertical}
                baseItemClasses={baseItemClasses}
                multiline={multiline}
                casing={casing}
                mixtureWrapperStyle={styles.mixtureWrapper}
              />
            )}
          </>
        )}
      </Typography>

      {isPersonalizedDetails && (
        <BaseItem
          label="Example Set"
          vertical={vertical}
          classes={baseItemClasses}
          hideTitleColon={multiline}
        >
          {personalizedName}
        </BaseItem>
      )}
    </>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  generationData: PropTypes.shape({
    briefTitle: PropTypes.string,
    briefBody: PropTypes.string,
    keywords: PropTypes.string,
    freestyleToolAllInstructions: PropTypes.string,
    personalizedName: PropTypes.string,
    suggestionMode: PropTypes.string,
  }).isRequired,
  displayGenerationTool: PropTypes.bool,
  collapsibleBrief: PropTypes.bool,
  vertical: PropTypes.bool,
};

Details.defaultProps = {
  className: '',
  displayGenerationTool: false,
  collapsibleBrief: false,
  vertical: false,
};
