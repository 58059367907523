import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      color: COLORS.neutral.mediumGray,
      fontWeight: theme.typography.fontWeightMedium,
    },
    tooltip: {},
  }),
  { name: 'TextCTextAndWordCounterounter' }
);
