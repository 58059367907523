import { useCallback } from 'react';

import {
  useCustomFeatureLimitsData,
  useCurrentCustomerId,
  useCurrentWorkspaceId,
  useNotificationActions,
  usePerformanceActions,
  useCustomerActions,
} from 'src/store';
import { useCanCustomerCreateResource } from 'src/services';
import { useAddResourcePopup } from 'src/services/popup';
import { FEATURE_LIMITS } from 'src/data/featureLimits';
import { checkCustomerResourcesExistence } from 'src/graphql/performance';
import {
  getCreatedResourceMessage,
  RESOURCE_CONTENT_TYPE,
  RESOURCE_TAG,
} from 'src/data/performance';
import { captureMessage } from 'src/lib/sentry';
import * as events from 'src/lib/events';
import { PAGES, useTrackingEvent } from 'src/services/useTrackingEvent';

export const useAddResource = (trackingEventAttributes = {}) => {
  const openAddResourcePopup = useAddResourcePopup();
  const performanceActions = usePerformanceActions();
  const customerActions = useCustomerActions();
  const currentCustomerId = useCurrentCustomerId();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const notificationActions = useNotificationActions();
  const limitData = useCustomFeatureLimitsData(FEATURE_LIMITS.resourcesPerCustomer);
  const availableResources = limitData?.limit - limitData?.currentCount;
  const canCustomerCreateResource = useCanCustomerCreateResource();
  const trackingEvent = useTrackingEvent();

  return useCallback(
    async (urls = [], tags) => {
      if (!(await canCustomerCreateResource(urls))) {
        return false;
      }

      let createdResources = [];
      if (urls.length > 0) {
        const urlsInput = urls.map((url) => ({
          content: url,
          type: RESOURCE_CONTENT_TYPE.url,
        }));

        try {
          const existenceResult = await checkCustomerResourcesExistence({
            customerId: currentCustomerId,
            workspaceId: currentWorkspaceId,
            inputs: urlsInput,
          });
          const nonExistingUrls = existenceResult.reduce(
            (acc, res) => (res.exist ? acc : [...acc, res.content]),
            []
          );
          if (nonExistingUrls.length > 0) {
            const list = nonExistingUrls.map((url) => ({
              contentType: RESOURCE_CONTENT_TYPE.url,
              content: url,
              tags: tags || [RESOURCE_TAG.general],
            }));
            const createdResult = await performanceActions.createResourcesSync(list);
            if (createdResult?.length > 0) {
              createdResources = createdResult;
            }
          }
        } catch (error) {
          captureMessage('Error when adding a resource', {
            level: 'warning',
            extra: {
              error: error.toString(),
              source: 'src/services/useAddResource.js -> useAddResource',
              urls,
              currentCustomerId,
            },
          });

          // add *sync resource* is a side effect action, when using URLs in tov, personas, blog, etc.
          // we want to hide this error from the user and continue with the original action
          return true;
        }
      } else {
        const res = await openAddResourcePopup();
        if (res) {
          createdResources = [res];
        }
      }

      const createdResourcesLength = createdResources.length;
      if (createdResourcesLength > 0) {
        trackingEvent(events.RESOURCES.resourceCreated, {
          page: PAGES.resourcesIntegrations,
          ...trackingEventAttributes,
          plan: limitData.plan,
          limit: limitData.limit,
          currentUsage: limitData.currentCount,
          resourceType: RESOURCE_CONTENT_TYPE.url,
          autoCreation: !!urls.length,
        });

        const message =
          createdResourcesLength > 1
            ? `${createdResourcesLength} resources have been successfully added to Anyword (${
                availableResources - createdResourcesLength
              } resources left)`
            : getCreatedResourceMessage(createdResources[0], availableResources);

        performanceActions.queryWorkspaceResources();
        customerActions.refreshCurrentCustomerFeatureLimit();
        notificationActions.displaySuccess(message, null, { timeout: 5000 });
      }
      return createdResources;
    },
    [
      openAddResourcePopup,
      notificationActions,
      performanceActions,
      availableResources,
      currentCustomerId,
      currentWorkspaceId,
      canCustomerCreateResource,
      trackingEvent,
      limitData,
      trackingEventAttributes,
      customerActions,
    ]
  );
};
