import { BRIEF_TYPE } from 'src/data/brief/const';
import { isAssetDisabledAnnotations } from 'src/data/asset';
import { CASING_TYPE } from 'src/data/generation';
import {
  BLANK_SAVED_PROMPT_ID,
  GENERATION_TOOL,
  GENERATION_TOOL_BADGE_TYPE,
  GENERATION_TOOL_BASIC,
  GENERATION_TOOL_DD,
  GENERATION_TOOL_DD_TEADS,
  GENERATION_TOOL_DEPRECATED,
  GENERATION_TOOL_FREESTYLE,
  GENERATION_TOOL_REWRITE,
} from './const';
import {
  doesToolSupportSuggestKeywords,
  getToolBriefType,
  getToolDefaultAssetType,
} from './toolProps';
import { isGenerationToolInSocialGroup } from './toolsGroupsMap';

export const isBasicGenerationTool = (tool) => Object.values(GENERATION_TOOL_BASIC).includes(tool);

export const isDataDrivenGenerationTool = (tool) =>
  [
    ...Object.values(GENERATION_TOOL_DD),
    ...Object.values(GENERATION_TOOL_REWRITE),
    ...Object.values(GENERATION_TOOL_DD_TEADS),
  ].includes(tool);

export const isSocialGenerationTool = (tool) =>
  [
    GENERATION_TOOL_DD.instagramCaption,
    GENERATION_TOOL_DD.facebookPost,
    GENERATION_TOOL_DD.tweet,
    GENERATION_TOOL_DD.linkedinPost,
    GENERATION_TOOL_DD.youtubeTitle,
    GENERATION_TOOL_DD.youtubeDescription,
    GENERATION_TOOL_DD.pinterestPostTitle,
    GENERATION_TOOL_DD.pinterestPostDescription,
  ].includes(tool);

export const isEmailGenerationTool = (tool) =>
  [GENERATION_TOOL.emailSubject, GENERATION_TOOL.emailBody, GENERATION_TOOL.emailMixture].includes(
    tool
  );

export const isAdsGenerationTool = (tool) =>
  [
    GENERATION_TOOL.linkedinMixtureAd,
    GENERATION_TOOL.twitterMixtureAd,
    GENERATION_TOOL.pinterestMixtureAd,
    GENERATION_TOOL.facebookMixtureAd,
    GENERATION_TOOL.googleRSA,
    GENERATION_TOOL.facebookPrimaryTextProduct,
    GENERATION_TOOL.facebookHeadlineProduct,
    GENERATION_TOOL.googleDescriptionProduct,
    GENERATION_TOOL.googleHeadlineProduct,
    GENERATION_TOOL.outbrainHeadlineProduct,
    GENERATION_TOOL.taboolaHeadlineProduct,
    GENERATION_TOOL.twitterCopyProduct,
    GENERATION_TOOL.twitterTitleProduct,
    GENERATION_TOOL.pinterestDescriptionProduct,
    GENERATION_TOOL.pinterestTitleProduct,
  ].includes(tool);

export const isTeadsGenerationTool = (tool) =>
  Object.values(GENERATION_TOOL_DD_TEADS).includes(tool);

export const isRewriteGenerationTool = (tool) =>
  Object.values(GENERATION_TOOL_REWRITE).includes(tool);

/**
 * Using includes instead of === because freestyle tools are strings
 * in the following format 'freestyle_<tool_id>'
 */
export const isFreestyleGenerationTool = (tool) =>
  tool ? tool.startsWith(GENERATION_TOOL_FREESTYLE.freestyle) : false;

export const isCreateYourOwnTool = (tool) => tool === GENERATION_TOOL_FREESTYLE.createYourOwn;

export const isDeprecatedGenerationTool = (tool) =>
  Object.values(GENERATION_TOOL_DEPRECATED).includes(tool);

export const mapGenerationTool = (tool) => {
  if (isFreestyleGenerationTool(tool) && !isCreateYourOwnTool(tool)) {
    return GENERATION_TOOL_FREESTYLE.freestyle;
  }

  return tool;
};

export const alignToolForGeneration = (tool) => {
  // Rewrite generation tool for "freestyle" tools - should always be `freestyle`
  if (isFreestyleGenerationTool(tool)) {
    return GENERATION_TOOL.freestyle;
  }

  return tool;
};

export const isGenerationToolWithProductFields = (tool) => {
  const briefType = getToolBriefType(tool);

  switch (briefType) {
    case BRIEF_TYPE.shopifyProductBrief:
    case BRIEF_TYPE.unifiedProduct:
    case BRIEF_TYPE.unifiedPromotionalEmail:
      return true;

    default:
      return false;
  }
};

export const isContextVisibleForRewriteGenerationTool = (tool) =>
  GENERATION_TOOL_REWRITE.emailSubjectLineRewrite === tool;

/**
 * Try to find relevant generation tool for asset type. Notice, that there is no 1-1 relation between these two entities.
 * Some generation tools have the same asset type.
 * @param {ASSET_TYPE} assetType - asset type
 * @returns {GENERATION_TOOL|null} - generation tool
 */
export const getGenerationToolForAssetType = (assetType) => {
  const tools = Object.values(GENERATION_TOOL);
  for (let i = 0; i <= tools.length - 1; i += 1) {
    const tool = tools[i];
    const toolAssetType = getToolDefaultAssetType(tool);
    if (toolAssetType === assetType) {
      return tool;
    }
  }

  return null;
};

/**
 * Get a list of generation tools for the passed asset type.
 * @param {ASSET_TYPE} assetType - asset type
 * @returns {GENERATION_TOOL[]} - list of generation tools
 */
export const getGenerationToolsByAssetType = (assetType) => {
  const tools = Object.values(GENERATION_TOOL);
  return tools.filter((tool) => getToolDefaultAssetType(tool) === assetType);
};

export const generationToolAllowsScores = (tool) => isDataDrivenGenerationTool(tool);

export const generationToolAllowsScoresForNonEnglish = (tool) => isTeadsGenerationTool(tool);

export const actualizeGenerationTool = (tool) => {
  if (isDeprecatedGenerationTool(tool)) {
    return GENERATION_TOOL.DEFAULT;
  }
  return tool;
};

export const getBadgeType = (tool, newTools, improvedTools) => {
  if (improvedTools.includes(tool)) {
    return GENERATION_TOOL_BADGE_TYPE.improved;
  }
  if (newTools.includes(tool)) {
    return GENERATION_TOOL_BADGE_TYPE.new;
  }
  return null;
};

const LIST_ID_DELIMITER = ' - ';
export const formatGenerationToolListId = (generationTool, toolId = null) => {
  const result = [];
  if (generationTool) {
    result.push(generationTool);
  }
  result.push(LIST_ID_DELIMITER);
  if (toolId) {
    result.push(toolId);
  }
  return result.join('');
};

export const getToolFromGenerationToolListId = (listId) =>
  listId?.split(LIST_ID_DELIMITER)[0] || null;
export const getIdFromGenerationToolListId = (listId) =>
  listId?.split(LIST_ID_DELIMITER)[1] || null;

export const isTalkingPointsExtractionDisabledForTool = (generationTool) => {
  // Do not try to extract for "basic" tools
  if (
    isBasicGenerationTool(generationTool) &&
    ![GENERATION_TOOL.basicProductReview, GENERATION_TOOL.generalProductListing].includes(
      generationTool
    )
  ) {
    return true;
  }

  // "annotations" can be specifically disabled to related asset type
  const assetType = getToolDefaultAssetType(generationTool);
  if (isAssetDisabledAnnotations(assetType)) {
    return true;
  }

  // Tool should support generation with annotations in order to support annotations extraction
  return !doesToolSupportSuggestKeywords(generationTool);
};

export const getFormulaTitle = (generationTool) => {
  switch (generationTool) {
    case GENERATION_TOOL.summarizer:
      return 'Summary type';
    case GENERATION_TOOL.basicContentImprover:
      return 'How to improve';
    default:
      return 'Formula';
  }
};

export const doesToolSupportEnhancedImprovements = (generationTool) => {
  return isDataDrivenGenerationTool(generationTool) && generationTool !== GENERATION_TOOL.emailBody;
};

export const doesToolSupportsRewriteProductName = (generationTool) =>
  !isGenerationToolInSocialGroup(generationTool) &&
  generationTool !== GENERATION_TOOL.basicContentImprover;

const BLANK_PROMPT_TOOL_CONTENT = {
  [GENERATION_TOOL.generalPrompt]:
    'You are an expert marketer tasked with writing [specifics you want to focus on]',
  [GENERATION_TOOL.googleRSA]: 'Write a Google responsive search ad about [product description].',
};
export const createBlankSavedPrompt = (generationTool) => {
  const entity = isAdsGenerationTool(generationTool) ? 'an ad' : 'a post';
  const emptyName = generationTool === GENERATION_TOOL.generalPrompt;
  const content = BLANK_PROMPT_TOOL_CONTENT[generationTool] || `Write ${entity} about:`;

  return {
    id: BLANK_SAVED_PROMPT_ID,
    generationTool,
    name: !emptyName ? `Write ${entity} about:` : '',
    allInstructionsData: {
      html: `<p>${content}</p>`,
    },
  };
};

export const determineVariationGenerationTool = ({ generationTool, classifiedGenerationTool }) => {
  if (
    !isDataDrivenGenerationTool(generationTool) ||
    ((generationTool === GENERATION_TOOL.emailBody || // email_body is classifiable even though it's dd
      generationTool === GENERATION_TOOL.emailMixture) && // email_mixture is classifiable even though it's dd
      classifiedGenerationTool)
  ) {
    return classifiedGenerationTool;
  }

  return generationTool;
};

export const isPresetFreestyleToolId = (freestyleToolId) => freestyleToolId.startsWith('pp');

export const getDefaultCaseType = (tool) => {
  if (!tool) {
    return null;
  }
  switch (tool) {
    case GENERATION_TOOL.googleHeadlineProduct:
      return CASING_TYPE.title;
    default:
      return null;
  }
};

export const isContentImproverTool = (generationTool) =>
  generationTool === GENERATION_TOOL.basicContentImprover;
