import { useMemo } from 'react';
import { createStateSelectorHook } from 'src/lib/sweetState';
import { MultiPromptTemplatesStore } from 'src/store';
import { usePrepareMultiPromptTemplates } from 'src/services';

const useMultiPromptTemplatesState = createStateSelectorHook(MultiPromptTemplatesStore, [
  'multiPromptTemplates',
]);
export const useReleasedMultiPromptTemplates = () => {
  const { multiPromptTemplates } = useMultiPromptTemplatesState();
  const preparedMultiPromptTemplates = usePrepareMultiPromptTemplates(multiPromptTemplates);

  return useMemo(() => {
    return preparedMultiPromptTemplates.filter(
      (multiPromptTemplate) => multiPromptTemplate.status === 'released'
    );
  }, [preparedMultiPromptTemplates]);
};
