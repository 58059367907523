import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query CampaignCtrPerformance(
    $campaignId: ID!
    $segmentId: ID
    $startDate: ISO8601Date
    $endDate: ISO8601Date
  ) {
    campaignCtrPerformance(
      campaignId: $campaignId
      segmentId: $segmentId
      startDate: $startDate
      endDate: $endDate
    ) {
      upliftPercentage
      totalBaseline {
        clicks
        views
      }
      totalAnyword {
        clicks
        views
      }
      performanceRows {
        dateTime
        isTraffic
        clicks
        views
      }
      resultsState
    }
  }
`;

export function queryCampaignCtrPerformance({ campaignId, segmentId, startDate, endDate }) {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      campaignId,
      segmentId,
      startDate,
      endDate,
    },
  }).then(({ data }) => getSingleProp(data));
}
