import { GENERATION_TOOL_GROUP } from 'src/data/generationTool';

export const MAX_DOCUMENT_WIDTH = 1100;
export const MIN_DOCUMENT_WIDTH = 680;
export const TOOLBAR_HEIGHT = 72;

export const DISPLAY_MODE = {
  document: 'document',
  list: 'list',
};

export const clampDisplayMode = (mode, defaultMode = null) => {
  const values = Object.values(DISPLAY_MODE);
  if (values.includes(mode)) {
    return mode;
  }
  return defaultMode;
};

export const PUBSUB_TOPIC_FILL_GUIDANCE_MODE_DATA = Symbol('TOPIC_FILL_GUIDANCE_MODE_DATA');

export const SIDEBAR_GENERIC_ITEM = {
  search: 'search',
  mostPopular: 'most_popular',
  moreItems: 'more_items',
};

export const SIDEBAR_TOOL_GROUPS = [
  GENERATION_TOOL_GROUP.boost,
  GENERATION_TOOL_GROUP.anyCopy,
  GENERATION_TOOL_GROUP.campaigns,
  GENERATION_TOOL_GROUP.social,
  GENERATION_TOOL_GROUP.ads,
  GENERATION_TOOL_GROUP.landingPage,
  GENERATION_TOOL_GROUP.email,
  GENERATION_TOOL_GROUP.ecommerce,
  GENERATION_TOOL_GROUP.generalWriting,
];

export const GENERATE_USING_OPTIONS = {
  defaultSuggestion: 'Default suggestion',
  removedTalkingPoints: 'Removed talking points',
  manualInstructions: 'Manual instructions',
};

export const DEFAULT_SUGGEST_IMPROVEMENT = 'Add engaging adjectives and verbs';

export const MANUAL_SUGGEST_IMPROVEMENT = 'Add your own instructions';

export const IMPORT_BRIEF_SOURCE = {
  fromUrl: 'from_url',
  brandBrief: 'brand_brief',
};
