import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';

import { usePopups } from 'src/services/popup';

const checkIsTopModal = (stack, type) => stack[0] === type;

const propTypes = {
  modalTypes: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
    })
  ).isRequired,
  state: PropTypes.shape({
    stack: PropTypes.array,
  }).isRequired,
};

const defaultProps = {
  state: {},
};

export const CommonPopup = ({ modalTypes, state }) => {
  const popups = usePopups();

  if (isEmpty(state)) {
    return null;
  }

  return modalTypes.map((popup) => {
    const popupState = state[popup.type];
    if (isEmpty(popupState)) {
      return null;
    }

    const { popupProps } = popupState;

    const handleClose = (...args) => {
      // Mark popup as 'closed' in service data
      popups.closePopup(popup.type);

      // If popup has 'onClose' handler - call it
      if (has(popupProps, 'onClose') && isFunction(popupProps.onClose)) {
        popupProps.onClose(...args);
      }
    };

    // If popup is transient, then we do not render it in closed state
    if (!popupState.open && popup.transient) {
      return null;
    }

    return React.createElement(popup.component, {
      key: popupState.key,
      ...popupProps,
      open: popupState.open,
      onClose: handleClose,

      isTopModal: checkIsTopModal(state.stack, popup.type),
    });
  });
};

CommonPopup.propTypes = propTypes;
CommonPopup.defaultProps = defaultProps;
