import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_TONE_OF_VOICE } from './schema';

const MUTATION = gql`
  mutation CreateCustomToneOfVoice(
    $brandVoiceId: ID!
    $name: String!
    $description: String
    $defaultForGenerationTools: [GenerationToolEnum!]
    $traits: [Trait!]
    $personality: TovDefaultPresetEnum
  ) {
    createCustomToneOfVoice(
      brandVoiceId: $brandVoiceId
      name: $name
      description: $description
      defaultForGenerationTools: $defaultForGenerationTools
      traits: $traits
      personality: $personality
    ) {
      ...CustomToneOfVoice
    }
  }

  ${FRAGMENT_CUSTOM_TONE_OF_VOICE}
`;

export const createCustomToneOfVoice = ({
  brandVoiceId,
  name,
  description,
  defaultForGenerationTools,
  traits,
  personality,
}) => {
  const variables = {
    brandVoiceId,
    name,
    description,
    defaultForGenerationTools,
    traits,
    personality,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
