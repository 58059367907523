import { isUserAdminForCustomer } from 'src/data/user';
import { useCurrentCustomerId } from 'src/store/customer';
import useCurrentUser from './useCurrentUser';

export const useIsCurrentUserAdmin = () => {
  const user = useCurrentUser();
  const customerId = useCurrentCustomerId();

  return isUserAdminForCustomer(user, customerId);
};
