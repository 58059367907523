import { useCallback } from 'react';

import { useHistory } from 'src/lib/hooks/useHistory';
import { useCustomerActions } from 'src/store';
import { ROUTES } from 'src/routes';
import { captureMessage } from 'src/lib/sentry';

export const useSelectCurrentCustomer = () => {
  const history = useHistory();
  const customerStoreActions = useCustomerActions();

  const handleSelectCustomer = useCallback(
    async (customerId) => {
      try {
        await customerStoreActions.selectCurrentCustomer(customerId);

        history.push(ROUTES.ROOT);

        return true;
      } catch (error) {
        captureMessage("Couldn't select customer", {
          level: 'warning',
          extra: {
            error: error.toString(),
            source: 'useSelectCurrentCustomer',
          },
        });
      }

      return false;
    },
    [customerStoreActions, history]
  );

  return {
    handleSelectCustomer,
  };
};
