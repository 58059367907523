import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation DeleteCustomFormula($id: ID!) {
    deleteCustomFormula(id: $id) {
      id
    }
  }
`;

export const deleteCustomFormula = (id) => {
  const variables = {
    id,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
