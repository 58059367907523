export const ITEM_TYPE = {
  link: 'link',
  menu: 'menu',
  submenu: 'submenu',
  collapse: 'collapse',
  header: 'header',
};

export const SIDEBAR_ACTION = {
  createBrandVoice: 'createBrandVoice',
  editBrandVoice: 'editBrandVoice',
  deleteBrandVoice: 'deleteBrandVoice',
};
