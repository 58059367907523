import { createStateSelectorHook } from 'src/lib/sweetState';
import { PerformanceStore } from 'src/store';

const usePerformanceState = createStateSelectorHook(PerformanceStore, [
  'loadingAccounts',
  'accountsSortField',
  'accountsSortDirection',
  'connectingToChannel',
  'accounts',
]);

export const useViewState = (channel) => {
  const { loadingAccounts, accountsSortField, accountsSortDirection, connectingToChannel } =
    usePerformanceState();

  return {
    loadingAccounts,
    accountsSortField,
    accountsSortDirection,
    connectingToChannel: connectingToChannel[channel] || false,
  };
};
