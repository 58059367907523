import { createStore } from 'react-sweet-state';

import { reportVariation } from 'src/graphql/variation';
import { countWords } from 'src/lib/string';

export const NAME = 'global-variation';

const INITIAL_STATE = {
  reportingIds: [],
  disabled: true,
  reason: '',
  comment: null,
  commentLengthError: false,
};

/**
 * Private actions
 */
export const PRIVATE_ACTIONS = {
  markReporting: (id, reporting) => ({ getState, setState }) => {
    const { reportingIds } = getState();
    const newList = reporting
      ? [...reportingIds, id]
      : reportingIds.filter((n) => n !== id);
    setState({ reportingIds: newList });
  },
};

/**
 * Public available actions
 */
export const ACTIONS = {
  reportVariation: (variationId, reason, comment) => ({
    dispatch,
    setState,
  }) => {
    dispatch(PRIVATE_ACTIONS.markReporting(variationId, true));

    return reportVariation(variationId, reason, comment).finally(() => {
      dispatch(PRIVATE_ACTIONS.markReporting(variationId, false));
      setState({ reason: '', comment: null, disabled: true });
    });
  },

  isReportingVariation: (variationId) => ({ getState }) => {
    const { reportingIds } = getState();
    return reportingIds.includes(variationId);
  },

  handleRadioChange: (e) => ({ setState, getState }) => {
    const reason = e.target.value;
    const { comment } = getState();
    if (reason === 'other') {
      if (!comment || comment.length < 1) {
        setState({ disabled: true });
      }
    } else {
      setState({ disabled: false, commentLengthError: false });
    }
    setState({ reason });
  },

  handleCommentChange: (e) => ({ setState }) => {
    const comment = e.target.value;
    const words = countWords(comment.trim());
    if (words < 3) {
      setState({
        commentLengthError: true,
        disabled: true,
      });
    } else {
      setState({
        commentLengthError: false,
        disabled: false,
      });
    }
    setState({ comment });
  },

  isCommentDisabled: () => ({ getState }) => {
    return getState().reason !== 'other';
  },
  isSendingRequest: () => ({ getState }) => {
    return getState().sendingRequest;
  },
};

const Store = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});

export default Store;
