import { init, reset, track } from './mixpanel';
import { getDistinctId } from './utils';
import * as EVENTS from './events';
import { identifyUser } from './identifyUser';

export default {
  init,
  reset,
  track,
  getDistinctId,
  identifyUser,
  EVENTS,
};
