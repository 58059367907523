import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_MULTI_PROMPT_TEMPLATE } from '../schema';

const MUTATION = gql`
  mutation DeleteReleasedExpertMultiPromptTemplate($id: ID!) {
    deleteReleasedExpertMultiPromptTemplate(id: $id) {
      ...CustomerMultiPromptTemplate
    }
  }
  ${FRAGMENT_MULTI_PROMPT_TEMPLATE}
`;

export const deleteReleasedExpertMultiPromptTemplate = async ({ id }) => {
  const variables = {
    id,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
