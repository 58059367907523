import { TEXT_ANNOTATION_TYPE } from './const';

export const WINNING_ANNOTATION_HINT =
  'It’s a winning talking point!\nWe recommend using it for more engaging copy.';

const LABELS = {
  [TEXT_ANNOTATION_TYPE.emotions]: 'Emotions conveyed',
  [TEXT_ANNOTATION_TYPE.mainOfferings]: 'Main offerings',
  [TEXT_ANNOTATION_TYPE.features]: 'Features',
  [TEXT_ANNOTATION_TYPE.ctas]: 'CTA',
  [TEXT_ANNOTATION_TYPE.pains]: 'Pains',
  [TEXT_ANNOTATION_TYPE.benefits]: 'Benefits',
  [TEXT_ANNOTATION_TYPE.campaignKeywords]: 'Campaign keywords',
  [TEXT_ANNOTATION_TYPE.headlineType]: 'Headline type',
};

export const getTextAnnotationTypeLabel = (type) => LABELS[type] || '';
