import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { GENDER, getGenderLabel } from 'src/data/demographics';
import { useStyles } from './useStyles';

export const HistogramGenderSimple = ({ className, gender }) => {
  const styles = useStyles({
    male: gender.male || 0,
    female: gender.female || 0,
  });

  if (!gender.male && !gender.female) {
    return null;
  }

  return (
    <div className={cx(styles.GenderBar, className)}>
      <div className={styles.Box}>
        <div className={cx(styles.BoxElement, styles.BoxMale)}>
          <div className={cx(styles.Label, styles.LabelMale)}>
            {getGenderLabel(GENDER.male)}
          </div>
          <div className={cx(styles.Value, styles.ValueMale)} />
        </div>

        <div className={styles.Separator} />

        <div className={cx(styles.BoxElement, styles.BoxFemale)}>
          <div className={cx(styles.Label, styles.LabelFemale)}>
            {getGenderLabel(GENDER.female)}
          </div>
          <div className={cx(styles.Value, styles.ValueFemale)} />
        </div>
      </div>
    </div>
  );
};

HistogramGenderSimple.propTypes = {
  className: PropTypes.string,
  gender: PropTypes.shape({
    male: PropTypes.number,
    female: PropTypes.number,
  }).isRequired,
};
HistogramGenderSimple.defaultProps = {
  className: null,
};
