import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconLandingPageSeo = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 20 20"
      data-fill-none
    >
      <path
        d="M15.8502 14.4339V15.5774C15.8502 15.8108 15.661 16 15.4277 16H4.42258C4.18919 16 4 15.8108 4 15.5774V4.30099C4 4.06761 4.18919 3.87842 4.42257 3.87842H15.4277C15.661 3.87842 15.8502 4.06761 15.8502 4.30099V5.64112"
        stroke="#2F393E"
        strokeWidth="0.865827"
      />
      <path
        d="M7.68046 6.93322L5.15926 9.73066C5.05728 9.84382 5.05728 10.0273 5.15926 10.1404L7.68046 12.9379"
        stroke="#4285F4"
        strokeWidth="0.827417"
        strokeLinecap="round"
      />
      <path
        d="M14.1496 6.93322L16.6708 9.73066C16.7728 9.84382 16.7728 10.0273 16.6708 10.1404L14.1496 12.9379"
        stroke="#4285F4"
        strokeWidth="0.827417"
        strokeLinecap="round"
      />
      <path
        d="M9.13476 12.0449L12.8301 7.94471"
        stroke="#4285F4"
        strokeWidth="0.827417"
        strokeLinecap="round"
      />
    </SvgIcon>
  ))
);
