import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconSummarize = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 30 30"
        data-fill-none
      >
        <rect
          x="3"
          y="3"
          width="24"
          height="24"
          rx="4"
          fill="#28354D"
        />
        <path
          d="M16.875 9.375V13.125H20.625"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.25 8.75L16.875 13.125"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.375 16.875H13.125V20.625"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.75 21.25L13.125 16.875"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
