import { useLayoutEffect, useState } from 'react';

import { useFreshRef } from 'src/lib/hooks';

export const focusElement = (element) => {
  if (!element) return;

  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'start',
  });
  element.focus({ preventScroll: true });
};

export const focusInputByName = (inputName) => {
  const input = document.querySelector(`input[name=${inputName}], textarea[name=${inputName}]`);

  focusElement(input);
};

export const useDOMElement = (rootElement, query) => {
  const [element, setElement] = useState(null);
  const elementRef = useFreshRef(element);
  const [currentQuery, setCurrentQuery] = useState(null);

  // Observe "element" being removed from DOM
  useLayoutEffect(() => {
    if (!rootElement) {
      return undefined;
    }

    const observer = new MutationObserver(() => {
      if (elementRef.current && !document.body.contains(elementRef.current)) {
        setElement(null);
      }
    });

    observer.observe(rootElement, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [rootElement, elementRef]);

  // Listen to "query" and "element" changes
  useLayoutEffect(() => {
    if (element) {
      return undefined;
    }

    if (!rootElement || !query) {
      setElement(null);
      return undefined;
    }

    // Search for DOM target element
    const target = rootElement.querySelector(query);
    if (target) {
      setElement(target);
      setCurrentQuery(query);
      return undefined;
    }

    // If no element was found right away, run searching periodically
    setElement(null);
    const interval = setInterval(() => {
      const targetInterval = rootElement.querySelector(query);
      if (targetInterval) {
        setElement(targetInterval);
        setCurrentQuery(query);

        clearInterval(interval);
      }
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, [rootElement, query, element]);

  if (currentQuery && currentQuery !== query) {
    return null;
  }
  return element;
};

export const getDomFirstSelectionRange = () => {
  const domSelection = window.getSelection();
  if (domSelection.rangeCount === 0) {
    return null;
  }

  return domSelection.getRangeAt(0);
};

export const checkEventTargetClosestByTestIds = (event, testIds) => {
  return testIds.some((testId) => event.target.closest(`[data-testid="${testId}"]`));
};

export const clearAllDomSelection = () => {
  const domSelection = window.getSelection();
  if (domSelection.rangeCount === 0) {
    return;
  }

  domSelection.removeAllRanges();
};
