import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_PROJECT_FOLDER } from './schema';

const MUTATION = gql`
  mutation UpdateProjectFolder($id: ID!, $name: String, $onSidebar: Boolean) {
    updateProjectFolder(id: $id, name: $name, onSidebar: $onSidebar) {
      ...ProjectFolder
    }
  }
  ${FRAGMENT_PROJECT_FOLDER}
`;

export const updateProjectFolder = ({ id, name, onSidebar }) => {
  const variables = {
    id,
    name,
    onSidebar,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
