import { SUBSCRIPTION_PLAN } from 'src/data/subscription';
import { trackMarketoEvent as mutationTrackMarketoEvent } from 'src/graphql/event';
import { captureMessage } from 'src/lib/sentry';

export const MARKETO_EVENT = {
  clickedUpgradeButton: 'clicked_upgrade_button',
};

export const MARKETO_SUBSCRIPTION_ACTION = {
  buyNow: 'buy_now',
  bookDemo: 'book_a_demo',
};

const MARKETO_SUBSCRIPTION_PLAN_VALUE = {
  basic: 'basic',
  starter: 'starter',
  dataDriven: 'data_driven',
  businessSelfServe: 'business_self_serve',
  business: 'business',
  enterprise: 'enterprise',
  acquisition: 'acquisition',
  conversion: 'conversion',
  engagement: 'engagement',
};

export const trackMarketoEvent = (eventName, primaryValue, attributes) => {
  mutationTrackMarketoEvent(eventName, primaryValue, attributes).catch((error) => {
    captureMessage('Error tracking Marketo event', {
      level: 'warning',
      extra: {
        error: error.toString(),
        source: 'lib/events/marketo -> trackMarketoEvent',
        eventName,
        primaryValue,
        attributes,
      },
    });
  });
};

export const convertSubscriptionPlanForMarketo = (plan) => {
  switch (plan) {
    case SUBSCRIPTION_PLAN.basic:
      return MARKETO_SUBSCRIPTION_PLAN_VALUE.basic;

    case SUBSCRIPTION_PLAN.starter:
      return MARKETO_SUBSCRIPTION_PLAN_VALUE.starter;

    case SUBSCRIPTION_PLAN.dataDriven:
    case SUBSCRIPTION_PLAN.dd_teams:
      return MARKETO_SUBSCRIPTION_PLAN_VALUE.dataDriven;

    case SUBSCRIPTION_PLAN.business_self_serve:
      return MARKETO_SUBSCRIPTION_PLAN_VALUE.businessSelfServe;

    case SUBSCRIPTION_PLAN.proAgency:
    case SUBSCRIPTION_PLAN.proEcommerce:
    case SUBSCRIPTION_PLAN.proPublisher:
      return MARKETO_SUBSCRIPTION_PLAN_VALUE.business;

    case SUBSCRIPTION_PLAN.enterprise:
      return MARKETO_SUBSCRIPTION_PLAN_VALUE.enterprise;
    case SUBSCRIPTION_PLAN.acquisition:
      return MARKETO_SUBSCRIPTION_PLAN_VALUE.acquisition;
    case SUBSCRIPTION_PLAN.conversion:
      return MARKETO_SUBSCRIPTION_PLAN_VALUE.conversion;
    case SUBSCRIPTION_PLAN.engagement:
      return MARKETO_SUBSCRIPTION_PLAN_VALUE.engagement;

    default:
      return plan;
  }
};
