import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip, Typography } from '@material-ui/core';

import { useIsTranslated } from 'src/lib/hooks';
import { useStyles } from './useStyles';

export const TranslatedTooltipWrapper = ({ children, className }) => {
  const styles = useStyles();
  const isTranslated = useIsTranslated();

  return (
    <Tooltip
      title={
        isTranslated ? (
          <div className={cx('notranslate', styles.tooltip)}>
            <Typography className={styles.text}>
              Editing is disabled for translated text.
            </Typography>
            <Typography className={styles.text}>
              To enable editing, please turn off browser translation.
            </Typography>
          </div>
        ) : (
          ''
        )
      }
    >
      <div className={cx(styles.root, className)}>{children}</div>
    </Tooltip>
  );
};

TranslatedTooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

TranslatedTooltipWrapper.defaultProps = {
  className: '',
};
