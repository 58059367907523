import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const IconFreestyleTool = memo(
  forwardRef((props, ref) => (
    <SvgIcon
      {...props}
      ref={ref}
      viewBox="0 0 34 34"
    >
      <rect
        x="4"
        y="4.5"
        width="26"
        height="25"
        rx="3.71427"
        fill={COLORS.primary.default}
      />
      <path
        d="M10.6218 22.5172L10.6216 22.5174C10.4719 22.6383 10.2533 22.5324 10.2533 22.357V20.2145C10.2538 20.1842 10.2611 20.1542 10.2749 20.1265C10.2887 20.0988 10.3088 20.0739 10.3339 20.0539L10.334 20.0538L14.2656 16.9025L14.3259 16.8542L14.2656 16.8059L10.334 13.6546L10.334 13.6545L10.3325 13.6534C10.2804 13.6141 10.2533 13.5574 10.2533 13.4944V11.3513H10.2533L10.2533 11.351C10.2531 11.3115 10.2647 11.2725 10.2872 11.2387C10.3097 11.2048 10.3421 11.1774 10.3811 11.1601C10.42 11.1428 10.4635 11.1364 10.5063 11.142C10.5491 11.1475 10.5891 11.1646 10.6215 11.1909L10.6215 11.1909L10.6218 11.1912L17.4968 16.6905C17.602 16.7751 17.602 16.9298 17.4968 17.0144L10.6218 22.5172ZM17.908 22.5686C17.7962 22.5686 17.7013 22.4742 17.7013 22.357V20.7164C17.7013 20.5993 17.7962 20.5049 17.908 20.5049H24.5324C24.6441 20.5049 24.739 20.5993 24.739 20.7164V22.357C24.739 22.4742 24.6441 22.5686 24.5324 22.5686H17.908Z"
        fill="white"
        stroke={COLORS.primary.default}
        strokeWidth="0.123809"
      />
    </SvgIcon>
  ))
);
