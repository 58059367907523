import PropTypes from 'prop-types';

import { PlagiarismCheckResult } from './components';
import { useStyles } from './useStyles';

export const PlagiarismCheckResults = ({ classes, plagiarismCheckResults }) => {
  const styles = useStyles({ classes });
  return (
    <div className={styles.root}>
      {plagiarismCheckResults?.map((checkResult) => (
        <PlagiarismCheckResult
          key={checkResult.id}
          classes={{ resultBox: styles.resultBox }}
          checkResult={checkResult}
        />
      ))}
    </div>
  );
};

PlagiarismCheckResults.propTypes = {
  classes: PropTypes.shape({}),
  plagiarismCheckResults: PropTypes.array.isRequired,
};

PlagiarismCheckResults.defaultProps = {
  classes: null,
};
