import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      backgroundColor: COLORS.neutral.silver,
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
    title: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(19),
      marginBottom: theme.spacing(0.5),
    },
    copyTextContainer: {
      backgroundColor: COLORS.neutral.white,
      border: `1px solid ${COLORS.primary.silver}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0.75, 2.5),
      borderRadius: theme.shape.borderRadius,
    },
    copyText: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      color: COLORS.neutral.mediumGray,
    },
    scoreWrapper: {
      marginRight: theme.typography.pxToRem(4),
      flexShrink: 0,
    },
    diamondIcon: {
      fontSize: theme.typography.pxToRem(45),
    },
    icon: {
      position: 'absolute',
      left: theme.spacing(3.5),
      top: theme.spacing(2),
      width: 20,
      height: 20,
      backgroundColor: COLORS.primary.silver,
      borderRadius: 100,
    },
  }),
  { name: 'DisplayVariation' }
);
