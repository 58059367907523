import { useCallback } from 'react';

import { usePopups } from '../services';
import { CHANGE_HEADLINE_POPUP } from '../types';

export const useChangeHeadlinePopup = () => {
  const popups = usePopups();

  return useCallback(
    (headline) => {
      return new Promise((resolve) => {
        popups.openPopup(CHANGE_HEADLINE_POPUP, {
          onSubmit: (value) => {
            popups.closePopup(CHANGE_HEADLINE_POPUP);
            resolve(value);
          },
          onClose: () => {
            popups.closePopup(CHANGE_HEADLINE_POPUP);
            resolve();
          },
          headline,
        });
      });
    },
    [popups]
  );
};
