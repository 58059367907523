import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CUSTOM_FORMULA } from './schema';

const MUTATION = gql`
  mutation CreateCustomFormula(
    $brandVoiceId: ID!
    $generationTool: GenerationToolEnum!
    $name: String!
    $body: String!
    $isDefault: Boolean
  ) {
    createCustomFormula(
      brandVoiceId: $brandVoiceId
      generationTool: $generationTool
      name: $name
      body: $body
      isDefault: $isDefault
    ) {
      ...CustomFormula
    }
  }
  ${FRAGMENT_CUSTOM_FORMULA}
`;

export const createCustomFormula = ({ brandVoiceId, generationTool, name, body, isDefault }) => {
  const variables = {
    brandVoiceId,
    generationTool,
    name,
    body,
    isDefault,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
