import { getQueryParams } from 'src/lib/browser';

const LinkedinAuthCallback = () => {
  const queryParams = getQueryParams(window.location.search);

  if (window.opener) {
    window.opener.postMessage(queryParams, '*');
  }

  return null;
};

export default LinkedinAuthCallback;
