import { parseJson } from 'src/lib/common';

export const BOOKMARK_TYPE = {
  projectType: 'project_type',
};

const PROJECT_TYPE_NAME_DELIMITER = ',';
export const formatProjectTypeBookmarkName = (
  projectType,
  generationTool = null
) => {
  if (generationTool) {
    return `${projectType}${PROJECT_TYPE_NAME_DELIMITER}${generationTool}`;
  }
  return `${projectType}`;
};

export const getDataFromProjectTypeBookmarkName = (name) => {
  const data = (name || '').split(PROJECT_TYPE_NAME_DELIMITER);
  return { projectType: data[0], generationTool: data[1] };
};

export const prepareBookmark = (bookmark) => ({
  ...bookmark,
  metadata: parseJson(bookmark.metadata),
});
