/**
 * Typography variant
 * @enum {string}
 * @readonly
 */
export const TYPOGRAPHY_VARIANT = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  bold12: 'bold12',
  bold14: 'bold14',
  bold16: 'bold16',
  semiBold12: 'semiBold12',
  semiBold14: 'semiBold14',
  semiBold16: 'semiBold16',
  medium12: 'medium12',
  medium14: 'medium14',
  medium16: 'medium16',
  regular12: 'regular12',
  regular14: 'regular14',
  regular16: 'regular16',
  paragraph12: 'paragraph12',
  paragraph14: 'paragraph14',
  paragraph16: 'paragraph16',
};
