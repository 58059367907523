import { useCallback } from 'react';

import { GENERATION_TOOL } from 'src/data/generationTool';
import { PROJECT_TYPE } from 'src/data/project';
import { useCreateProject } from './useCreateProject';

export const useGenerationToolPopupSubmitHandler = () => {
  const createProject = useCreateProject();

  return useCallback(
    ({ tool, freestyleToolId, customMultiPromptId, customMultiPromptGroup }) => {
      if (tool === GENERATION_TOOL.blogDescription) {
        createProject(PROJECT_TYPE.blogPostBuilder);
        return;
      }

      createProject(PROJECT_TYPE.document, {
        generationTool: tool,
        freestyleGenerationToolId: freestyleToolId,
        customMultiPromptId,
        customMultiPromptGroup,
      });
    },
    [createProject]
  );
};
