import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const useStyles = makeStyles({
  updateProgress: {
    width: '30px',
    height: '30px',
    flexShrink: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ScoreLoader = ({ ...props }) => {
  const styles = useStyles();
  return (
    <div className={styles.updateProgress} {...props}>
      <CircularProgress size={24} />
    </div>
  );
};

export default ScoreLoader;
