import React, { useState } from 'react';
import { Button, DialogContent } from '@material-ui/core';

import { CustomDialog } from 'src/components/CustomDialog';
import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { RocketIcon } from 'src/assets/icons/Rocket';
import { useG2ReviewBoostClaimedPopup } from './useG2ReviewBoostClaimedPopup';
import { useHandlers } from './useHandlers';
import { useStyles } from './useStyles';

export const G2ReviewBoostClaimedPopup = () => {
  const styles = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const { handleClose } = useHandlers({ setIsOpen });

  useG2ReviewBoostClaimedPopup({ isOpen, setIsOpen });

  return (
    <CustomDialog
      className={styles.root}
      classes={{ dialogActions: styles.dialogActions }}
      open={isOpen}
      onClose={handleClose}
      data-testid="G2ReviewBoostClaimedPopup"
      dialogActionsSlot={
        <Button
          className={styles.button}
          onClick={handleClose}
          variant="contained"
          color="secondary"
          startIcon={<RocketIcon />}
        >
          Go boost’em!
        </Button>
      }
      displayCloseIcon
    >
      <DialogContent className={styles.dialogContent}>
        <CustomTypography
          variant={TYPOGRAPHY_VARIANT.h2}
          align="center"
        >
          🎉
        </CustomTypography>
        <CustomTypography
          className={styles.title}
          variant={TYPOGRAPHY_VARIANT.h2}
          align="center"
        >
          Thank You for Your Review!
        </CustomTypography>
        <CustomTypography
          variant={TYPOGRAPHY_VARIANT.paragraph16}
          align="center"
        >
          Your 5 additional boosts have been added to your account.
          <br />
          Enjoy the extra power and thank you for helping us improve!
        </CustomTypography>
      </DialogContent>
    </CustomDialog>
  );
};

G2ReviewBoostClaimedPopup.propTypes = {};

G2ReviewBoostClaimedPopup.defaultProps = {};
