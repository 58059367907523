import { createStore } from 'react-sweet-state';
import { cloneDeep, map, orderBy, without } from 'lodash';

import { pauseCOCampaign, startCOCampaign } from 'src/graphql/continuousOptimization';
import {
  queryChannelsAuthState,
  performanceCenterConnectAccount,
  performanceCenterRefreshAccount,
  getPerformanceCenterResultDocument,
  queryProjectForNewCopies,
  unauthorizeChannel,
  createResourceAccount,
  queryPerformanceCenterGlobalBenchmark,
  performanceCenterDisconnectAccount,
  createResourcesSync,
  deleteResource,
  extractResource,
  extractResourceTitle,
  queryCustomerResource,
  updateResource,
  checkCustomerResourcesExistence,
  queryWorkspaceAssociatedAccounts,
  queryWorkspaceAssociatedResources,
  updateAccountName,
  integrationAuthorize,
  bulkDeleteResources,
} from 'src/graphql/performance';
import { queryWTMProjectsForCIP } from 'src/graphql/project';
import { alignChannelWithBackend, CHANNEL, alignChannelForUnauthorize } from 'src/data/channel';
import { queryCustomerAccounts } from 'src/graphql/customer';
import { queryCurrentUserIntegrationAccounts } from 'src/graphql/user';
import { isAbortError, isAsyncRequestError } from 'src/lib/errors';
import {
  convertAccountsListToChannelMap,
  isIntegrationChannel,
  isMetaChannel,
  RESOURCE_CONTENT_TYPE,
  shouldChanelConnectManually,
  PERFORMANCE_EXTRACTION_STATUS,
} from 'src/data/performance';
import { SORT_DIRECTION } from 'src/data/common';
import { isCampaignDraft, isCampaignRunning } from 'src/data/campaignStatus';
import { SOCIAL_AUTH_STATE } from 'src/data/user';
import isURL from 'validator/lib/isURL';
import { captureMessage } from 'src/lib/sentry';
import {
  connectToChannel,
  isCopiesExtractionError,
  waitForCopiesExtraction,
  waitForExtraction,
} from './utils';

const NAME = 'performance-center-store';

const parsePerformance = (copy) => {
  try {
    return JSON.parse(copy.performance || '{}');
  } catch (e) {
    return {};
  }
};

const mapCopy = (copy) => {
  const parsedPerformanceCopy = parsePerformance(copy);
  return {
    ...copy,
    weightedCtr: copy.weightedAvgCtr,
    impressions: copy.lifetimeImpressions,
    openRate: parsedPerformanceCopy.opens,
    ...parsedPerformanceCopy,
  };
};
const prepareCopies = (copies) => copies.map(mapCopy);

const INITIAL_STATE = {
  loadingAuthState: false,
  connectingToChannel: {},

  authState: {},
  authStateFetched: false,

  accounts: {},
  customerAccounts: {},
  resources: [],
  customerResources: [],

  isExtractingResourceTitle: false,

  accountsSortField: null,
  accountsSortDirection: null,

  wtmProjects: [],

  copies: {},
  lastExtractionDate: {},
  campaigns: {},
  avgCompletionPercent: {},
  extractionStatus: {},

  loadingStatus: {},

  campaignsCopyImpressions: {},

  accountsFetched: false,
  isCreatingResource: false,
  isUpdatingResource: false,
  loadingAccounts: false,
  loadingCustomerAccounts: false,
  loadingResources: false,
  loadingCustomerResources: false,

  authErrorMessage: {},
  errorMessage: {},

  deletedResourceAccounts: [],

  globalBenchmarkConfigs: [],
  loadingGlobalBenchmark: false,

  warning: {},

  runningTalkingPointsViewAccountIds: [],
  bulkDeletingResources: false,
};

const channelAbortControllers = new Map();

/**
 * Private actions
 */
export const PRIVATE_ACTIONS = {
  setCopies:
    (channel, accountId, newCopies) =>
    ({ getState, setState }) => {
      if (!newCopies?.length) {
        return;
      }
      const preparedCopies = prepareCopies(newCopies || []);

      const sorted = orderBy(preparedCopies, 'sortIndex', SORT_DIRECTION.asc);

      const { copies } = getState();

      const channelCopies = copies[channel] || {};

      channelCopies[accountId] = sorted;

      setState({ copies: { ...copies, [channel]: channelCopies } });
    },

  setAvgCompletionPercent:
    (channel, accountId, newAvgCompletionPercent) =>
    ({ getState, setState }) => {
      const { avgCompletionPercent } = getState();

      const channelAvgCompletionPercent = avgCompletionPercent[channel] || {};

      channelAvgCompletionPercent[accountId] = newAvgCompletionPercent;

      setState({
        avgCompletionPercent: {
          ...avgCompletionPercent,
          [channel]: channelAvgCompletionPercent,
        },
      });
    },

  setLastExtractionDate:
    (channel, accountId, connectedOn) =>
    ({ getState, setState }) => {
      const { lastExtractionDate, accounts } = getState();

      const channelAccounts = accounts[channel] || [];

      const account = channelAccounts.find((account) => account.id === accountId);

      if (account) {
        account.connectedOn = connectedOn;
      }

      const channelLastExtractionDate = lastExtractionDate[channel] || {};

      channelLastExtractionDate[accountId] = connectedOn;

      setState({
        accounts: { ...accounts, [channel]: [...channelAccounts] },
        lastExtractionDate: { ...lastExtractionDate, [channel]: channelLastExtractionDate },
      });
    },

  getLastExtractionDate:
    (channel, accountId) =>
    ({ getState }) => {
      const { lastExtractionDate } = getState();

      return lastExtractionDate[channel]?.[accountId];
    },

  setCampaigns:
    (channel, accountId, newCampaigns) =>
    ({ getState, setState }) => {
      const { campaigns } = getState();

      const channelCampaigns = campaigns[channel] || {};

      channelCampaigns[accountId] = newCampaigns;

      setState({
        campaigns: { ...campaigns, [channel]: channelCampaigns },
      });
    },

  setCampaignsCopyImpressions:
    (newCampaignsCopyImpressions, channel) =>
    ({ getState, setState }) => {
      const { campaignsCopyImpressions } = getState();

      setState({
        campaignsCopyImpressions: {
          ...campaignsCopyImpressions,
          [channel]: newCampaignsCopyImpressions,
        },
      });
    },

  getChannelAccounts:
    (channel) =>
    ({ getState }) => {
      const { accounts } = getState();

      return accounts[channel];
    },

  setChannelAccounts:
    (channel, newAccounts) =>
    ({ getState, setState }) => {
      const { accounts } = getState();

      setState({
        accounts: { ...accounts, [channel]: newAccounts },
      });
    },

  setLoadingStatus:
    (channel, accountId, loading) =>
    ({ getState, setState }) => {
      const { loadingStatus } = getState();

      setState({
        loadingStatus: {
          ...loadingStatus,
          [channel]: { ...loadingStatus[channel], [accountId]: loading },
        },
      });
    },

  setConnectingToChannel:
    (channel, connecting) =>
    ({ getState, setState }) => {
      const { connectingToChannel } = getState();

      setState({
        connectingToChannel: { ...connectingToChannel, [channel]: connecting },
      });
    },

  getIsLoading:
    (channel, accountId) =>
    ({ getState }) => {
      const { loadingStatus } = getState();

      const channelLoadingCopies = loadingStatus[channel] || {};

      return channelLoadingCopies?.[accountId];
    },

  getExtractionStatus:
    (channel, accountId) =>
    ({ getState }) => {
      const { extractionStatus } = getState();

      const channelExtractionStatus = extractionStatus[channel] || {};

      return channelExtractionStatus[accountId];
    },

  mergeToAccountList:
    (channel, account) =>
    ({ getState, setState }) => {
      const { accounts, customerAccounts } = getState();

      const channelAccounts = accounts[channel] || [];

      if (channelAccounts.some(({ id }) => account.id === id)) {
        return;
      }

      setState({
        accounts: { ...accounts, [channel]: [...(accounts[channel] || []), account] },
        customerAccounts: {
          ...(customerAccounts || {}),
          [channel]: [...(customerAccounts[channel] || []), account],
        },
      });
    },

  setChannelAuthState:
    (channel, authState) =>
    ({ getState, setState }) => {
      const { authState: currentAuthState } = getState();

      let updatedAuthState;
      if (isMetaChannel(channel)) {
        updatedAuthState = {
          ...currentAuthState,
          [CHANNEL.facebook]: authState,
          [CHANNEL.facebookPages]: authState,
          [CHANNEL.instagram]: authState,
        };
      } else {
        updatedAuthState = { ...currentAuthState, [channel]: authState };
      }

      setState({
        authState: updatedAuthState,
      });
    },

  setChannelWarning:
    (channel, warningText) =>
    ({ getState, setState }) => {
      const { warning } = getState();

      setState({ warning: { ...warning, [channel]: warningText } });
    },

  removeAccountFromChannel:
    (channel, accountId) =>
    ({ getState, setState }) => {
      const { accounts, lastExtractionDate, copies, loadingStatus, customerAccounts } = getState();

      const channelAccounts = accounts[channel].map((account) =>
        account.id === accountId
          ? { ...account, status: PERFORMANCE_EXTRACTION_STATUS.disconnected }
          : account
      );

      const channelCustomerAccounts = (customerAccounts[channel] || []).map((account) =>
        account.id === accountId
          ? { ...account, status: PERFORMANCE_EXTRACTION_STATUS.disconnected }
          : account
      );

      const channelCopies = copies[channel] || {};

      delete channelCopies[accountId];

      const channelLastExtractionDate = lastExtractionDate[channel] || {};

      delete channelLastExtractionDate[accountId];

      const channelLoadingStatus = loadingStatus[channel] || {};

      delete channelLoadingStatus[accountId];

      setState({
        accounts: { ...accounts, [channel]: channelAccounts },
        lastExtractionDate: { ...lastExtractionDate, [channel]: channelLastExtractionDate },
        copies: { ...copies, [channel]: channelCopies },
        loadingStatus: { ...loadingStatus, [channel]: channelLoadingStatus },
        customerAccounts: { ...(customerAccounts || []), [channel]: channelCustomerAccounts },
      });
    },

  extractWithPoling:
    (channel, accountId) =>
    async ({ dispatch }, { notifications, currentCustomerId }) => {
      const alignedChannel = alignChannelWithBackend(channel);

      dispatch(PRIVATE_ACTIONS.setLoadingStatus(channel, accountId, true));

      channelAbortControllers.set(channel, new AbortController());

      const setAvgCompletionPercent = (channel, accountId, avgCompletionPercent) => {
        dispatch(PRIVATE_ACTIONS.setAvgCompletionPercent(channel, accountId, avgCompletionPercent));
      };

      const disconnectAccount = (channel, failedResourceAccountId) => {
        dispatch(ACTIONS.disconnectAccount(channel, failedResourceAccountId));
      };

      const mergeAccountStatus = (channel, accountId, extractionStatus) => {
        dispatch(ACTIONS.mergeAccountStatus(channel, accountId, extractionStatus));
      };

      try {
        const result = await waitForCopiesExtraction({
          channel: alignedChannel,
          customerId: currentCustomerId,
          accountId,
          notifications,
          options: {
            abortSignal: channelAbortControllers.get(channel)?.signal,
          },
          setAvgCompletionPercent,
          disconnectAccount,
          setChannelWarning: PRIVATE_ACTIONS.setChannelWarning,
          mergeAccountStatus,
        });
        channelAbortControllers.delete(channel);

        if (!result) {
          return;
        }

        dispatch(PRIVATE_ACTIONS.setLastExtractionDate(channel, accountId, result?.connectedOn));
        if (isIntegrationChannel(channel)) {
          dispatch(PRIVATE_ACTIONS.setCopies(channel, accountId, result?.copies));
          dispatch(PRIVATE_ACTIONS.setCampaigns(channel, accountId, result?.campaigns));
          dispatch(
            PRIVATE_ACTIONS.setCampaignsCopyImpressions(
              channel,
              accountId,
              result?.campaignsCopyImpressions
            )
          );
        }
        return result;
      } catch (error) {
        dispatch(PRIVATE_ACTIONS.setLastExtractionDate(channel, accountId, null));
        dispatch(PRIVATE_ACTIONS.setErrorMessage(channel, accountId, error.message));
        if (isAsyncRequestError(error)) {
          dispatch(ACTIONS.queryChannelsAuthState());
        }
        if (isCopiesExtractionError(error)) {
          dispatch(ACTIONS.queryChannelsAuthState(true));
          dispatch(ACTIONS.setAuthErrorMessage(channel, error.message));
          return;
        }
        if (!isAbortError(error)) {
          throw error;
        }
        mergeAccountStatus(channel, accountId, PERFORMANCE_EXTRACTION_STATUS.disconnected);
      } finally {
        dispatch(PRIVATE_ACTIONS.setLoadingStatus(channel, accountId, false));
      }
    },

  setErrorMessage:
    (channel, accountId, message) =>
    async ({ getState, setState }) => {
      const { errorMessage } = getState();

      const channelErrorMessage = errorMessage[channel] || {};

      channelErrorMessage[accountId] = message;

      setState({
        errorMessage: { ...errorMessage, [channel]: channelErrorMessage },
      });
    },

  toggleRunningTalkingPointsViewAccountId:
    (accountId) =>
    ({ getState, setState }) => {
      const { runningTalkingPointsViewAccountIds } = getState();

      const isExist = runningTalkingPointsViewAccountIds.includes(accountId);

      const newList = isExist
        ? without(runningTalkingPointsViewAccountIds, accountId)
        : [...runningTalkingPointsViewAccountIds, accountId];

      setState({
        runningTalkingPointsViewAccountIds: newList,
      });
    },

  getResourcesByContentType:
    (contentType) =>
    ({ getState }) => {
      const { resources } = getState();
      return resources?.filter((resource) => resource.contentType === contentType) || [];
    },
};

/**
 * Public available actions
 */
export const ACTIONS = {
  init:
    () =>
    ({ setState }) => {
      setState(cloneDeep(INITIAL_STATE));
    },

  queryCustomerAccounts:
    () =>
    async ({ setState }, { notifications, currentCustomerId }) => {
      try {
        setState({ loadingCustomerAccounts: true });
        const customer = await queryCustomerAccounts(currentCustomerId);
        if (!customer) {
          captureMessage('"queryCustomerAccounts" GQL query returned empty data', {
            level: 'warning',
            extra: {
              source: 'PerformanceStore -> queryCustomerAccounts',
            },
          });
          throw new Error('Customer invalid');
        }

        setState({
          customerAccounts: convertAccountsListToChannelMap(customer.accounts),
        });
      } catch (error) {
        notifications.displayError(error);
      } finally {
        setState({ loadingCustomerAccounts: false });
      }
    },

  queryWorkspaceAccounts:
    (workspaceId) =>
    async ({ setState }, { notifications, currentCustomerId, currentWorkspaceId }) => {
      try {
        setState({ loadingAccounts: true });
        const accounts = await queryWorkspaceAssociatedAccounts({
          customerId: currentCustomerId,
          id: workspaceId || currentWorkspaceId,
        });
        if (!accounts) {
          captureMessage('"queryWorkspaceAssociatedAccounts" GQL query returned empty data', {
            level: 'warning',
            extra: {
              source: 'PerformanceStore -> queryWorkspaceAccounts',
            },
          });
          throw new Error('Customer invalid');
        }

        setState({
          accounts: convertAccountsListToChannelMap(accounts),
        });
      } catch (error) {
        notifications.displayError(error);
      } finally {
        setState({ accountsFetched: true, loadingAccounts: false });
      }
    },

  queryUserIntegrationAccounts:
    () =>
    async ({ setState }, { notifications }) => {
      try {
        setState({ loadingAccounts: true });
        const user = await queryCurrentUserIntegrationAccounts();

        if (!user) {
          captureMessage('"queryCurrentUserIntegrationAccounts" GQL query returned empty data', {
            level: 'warning',
            extra: {
              source: 'PerformanceStore -> queryCurrentUserIntegrationAccounts',
            },
          });
          throw new Error('User invalid');
        }

        setState({
          userAccounts: convertAccountsListToChannelMap(user.integrationAccounts),
        });
      } catch (error) {
        notifications.displayError(error);
      } finally {
        setState({ accountsFetched: true, loadingAccounts: false });
      }
    },

  queryCustomerResources:
    () =>
    async ({ setState }, { notifications, currentCustomerId }) => {
      try {
        setState({ loadingCustomerResources: true });
        const customerResources = await queryCustomerResource(currentCustomerId);

        setState({
          customerResources,
        });
      } catch (error) {
        notifications.displayError(error);
      } finally {
        setState({ loadingCustomerResources: false });
      }
    },

  queryWorkspaceResources:
    (workspaceId) =>
    async ({ setState }, { notifications, currentCustomerId, currentWorkspaceId }) => {
      try {
        setState({ loadingResources: true });
        const resources = await queryWorkspaceAssociatedResources({
          customerId: currentCustomerId,
          id: workspaceId || currentWorkspaceId,
        });

        if (!resources) {
          captureMessage('"queryWorkspaceAssociatedResources" GQL query returned empty data', {
            level: 'warning',
            extra: {
              source: 'PerformanceStore -> queryWorkspaceResources',
            },
          });
          throw new Error('Customer invalid');
        }

        setState({
          resources,
        });
      } catch (error) {
        notifications.displayError(error);
      } finally {
        setState({ loadingResources: false });
      }
    },

  queryChannelsAuthState:
    (force) =>
    async ({ getState, setState }, { notifications }) => {
      const { authStateFetched } = getState();
      if (authStateFetched && !force) {
        return;
      }

      setState({ loadingAuthState: true });
      try {
        const {
          googleAuthState,
          facebookAuthState,
          linkedinAuthState,
          linkedinSocialAuthState,
          hubspotAuthState,
          salesforceData,
          twitterSocialData,
        } = await queryChannelsAuthState();

        const { authState } = getState();
        authState[CHANNEL.google] = googleAuthState;
        authState[CHANNEL.facebook] = facebookAuthState;
        authState[CHANNEL.linkedin] = linkedinAuthState;
        authState[CHANNEL.linkedinSocial] = linkedinSocialAuthState;
        authState[CHANNEL.hubspot] = hubspotAuthState;
        authState[CHANNEL.facebookPages] = facebookAuthState;
        authState[CHANNEL.instagram] = facebookAuthState;
        authState[CHANNEL.salesforce] = salesforceData;
        authState[CHANNEL.twitterSocial] = twitterSocialData;

        setState({
          authState,
          authStateFetched: true,
        });
      } catch (error) {
        notifications.displayError(error);
      } finally {
        setState({ loadingAuthState: false });
      }
    },

  connectToChannel:
    (channel) =>
    async ({ dispatch }, { currentCustomerId }) => {
      dispatch(PRIVATE_ACTIONS.setConnectingToChannel(channel, true));

      try {
        const result = await connectToChannel(channel, currentCustomerId);
        await dispatch(ACTIONS.queryUserIntegrationAccounts());
        dispatch(PRIVATE_ACTIONS.setChannelAuthState(channel, result?.authState));
        return result?.authState === SOCIAL_AUTH_STATE.authorized;
      } finally {
        dispatch(PRIVATE_ACTIONS.setConnectingToChannel(channel, false));
      }
    },

  isChannelConnected:
    (channel) =>
    ({ getState }) => {
      const { authState } = getState();
      if (shouldChanelConnectManually(channel)) {
        return true;
      }
      return authState[channel] === SOCIAL_AUTH_STATE.authorized;
    },

  disconnectFromChannel:
    (channel) =>
    async ({ dispatch }, { currentCustomerId }) => {
      dispatch(PRIVATE_ACTIONS.setConnectingToChannel(channel, true));

      const alignedChannel = alignChannelForUnauthorize(channel);
      try {
        await unauthorizeChannel(alignedChannel, currentCustomerId);

        dispatch(PRIVATE_ACTIONS.setChannelAuthState(channel, SOCIAL_AUTH_STATE.unauthorized));
        const accounts = dispatch(PRIVATE_ACTIONS.getChannelAccounts(channel));

        accounts?.forEach((account) => {
          dispatch(PRIVATE_ACTIONS.removeAccountFromChannel(channel, account.id));
        });
      } finally {
        dispatch(PRIVATE_ACTIONS.setConnectingToChannel(channel, false));
      }
    },

  createResourceAccount:
    ({ csvFile, channel, brandVoiceId, onCreateSuccess, onConnectSuccess, isTalkingPointsView }) =>
    async ({ setState, dispatch }, { currentCustomerId, currentWorkspaceId }) => {
      setState({ isCreatingResource: true });

      try {
        const result = await createResourceAccount({
          customerId: currentCustomerId,
          name: csvFile?.name.replace('.csv', ' (.csv)'),
          file: csvFile,
          dataSource: channel,
        });

        const preparedAccount = {
          name: result.accountName,
          id: result.accountId,
          channel,
          ...result,
        };
        onCreateSuccess?.(preparedAccount);
        return dispatch(
          ACTIONS.connectPerformanceCenterAccount({
            account: preparedAccount,
            workspaceId: currentWorkspaceId,
            customModelBrandVoiceId: brandVoiceId,
            onConnectSuccess,
            isTalkingPointsView,
          })
        );
      } finally {
        setState({ isCreatingResource: false });
      }
    },
  createResourcesSync:
    (resources) =>
    async ({ setState, dispatch }, { currentCustomerId, currentWorkspaceId }) => {
      setState({ isCreatingResource: true });
      try {
        return createResourcesSync({
          customerId: currentCustomerId,
          workspaceId: currentWorkspaceId,
          resources,
        });
      } finally {
        setState({ isCreatingResource: false });
      }
    },

  setCreatingResource:
    (isCreatingResource) =>
    ({ setState }) => {
      setState({ isCreatingResource });
    },

  resetAvgCompletionPercent:
    (channel, accountId) =>
    ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.setAvgCompletionPercent(channel, accountId, 0));
    },

  displayExtractingProgressIndicator:
    (channel, accountId, promise) =>
    async ({ dispatch }) => {
      dispatch(PRIVATE_ACTIONS.setAvgCompletionPercent(channel, accountId, 0));
      dispatch(PRIVATE_ACTIONS.setLoadingStatus(channel, accountId, true));

      dispatch(PRIVATE_ACTIONS.toggleRunningTalkingPointsViewAccountId(accountId));

      try {
        await promise;
      } finally {
        dispatch(PRIVATE_ACTIONS.setAvgCompletionPercent(channel, accountId, 100));
        dispatch(PRIVATE_ACTIONS.setLoadingStatus(channel, accountId, false));
      }
    },

  connectPerformanceCenterAccount:
    ({ account, workspaceId, customModelBrandVoiceId, onConnectSuccess, isTalkingPointsView }) =>
    async ({ dispatch }, { notifications, currentCustomerId }) => {
      const { channel, id } = account;

      dispatch(PRIVATE_ACTIONS.setAvgCompletionPercent(channel, id, 0));
      dispatch(PRIVATE_ACTIONS.setLoadingStatus(channel, id, true));

      const alignedChannel = alignChannelWithBackend(channel);

      channelAbortControllers.set(channel, new AbortController());

      if (isTalkingPointsView) {
        dispatch(PRIVATE_ACTIONS.toggleRunningTalkingPointsViewAccountId(id));
      }

      dispatch(PRIVATE_ACTIONS.mergeToAccountList(channel, account));

      try {
        await performanceCenterConnectAccount(
          {
            accountId: id,
            customerId: currentCustomerId,
            workspaceId,
            dataSource: alignedChannel,
            customModelBrandVoiceId,
            createTalkingPoints: isTalkingPointsView,
          },
          {
            abortSignal: channelAbortControllers.get(channel)?.signal,
          }
        );
        const result = await dispatch(PRIVATE_ACTIONS.extractWithPoling(channel, id));

        onConnectSuccess?.({
          customModelId: result.customDatasetId,
          brandVoiceId: customModelBrandVoiceId,
        });

        return result;
      } catch (error) {
        if (!isAbortError(error)) {
          notifications.displayError(error);
        }
      }
    },

  refreshAccount:
    ({ channel, accountId }) =>
    async ({ dispatch }, { notifications, currentCustomerId }) => {
      dispatch(PRIVATE_ACTIONS.setAvgCompletionPercent(channel, accountId, 0));
      dispatch(PRIVATE_ACTIONS.setLoadingStatus(channel, accountId, true));

      channelAbortControllers.set(channel, new AbortController());
      try {
        await performanceCenterRefreshAccount(
          {
            accountId,
            customerId: currentCustomerId,
            dataSource: alignChannelWithBackend(channel),
          },
          {
            abortSignal: channelAbortControllers.get(channel)?.signal,
          }
        );

        return dispatch(PRIVATE_ACTIONS.extractWithPoling(channel, accountId));
      } catch (error) {
        if (!isAbortError(error)) {
          notifications.displayError(error);
        }
      }
    },

  disconnectAccount:
    (channel, accountId) =>
    async ({ dispatch }, { currentCustomerId, notifications }) => {
      try {
        await performanceCenterDisconnectAccount({
          customerId: currentCustomerId,
          accountId,
          dataSource: alignChannelWithBackend(channel),
        });

        dispatch(PRIVATE_ACTIONS.removeAccountFromChannel(channel, accountId));
      } catch (error) {
        notifications.displayError(error);
      }
    },

  getLastExtractionDate: PRIVATE_ACTIONS.getLastExtractionDate,
  setLastExtractionDate: PRIVATE_ACTIONS.setLastExtractionDate,

  getLastExtraction:
    (channel, accountId) =>
    async ({ dispatch }) => {
      const lastExtractionDate = dispatch(
        PRIVATE_ACTIONS.getLastExtractionDate(channel, accountId)
      );
      const loadingStatus = dispatch(PRIVATE_ACTIONS.getIsLoading(channel, accountId));
      const extractionStatus = dispatch(PRIVATE_ACTIONS.getExtractionStatus(channel, accountId));

      // don't fetch copies again if already did on same session
      if (
        lastExtractionDate ||
        loadingStatus ||
        extractionStatus === PERFORMANCE_EXTRACTION_STATUS.nullableResults
      ) {
        return;
      }

      return dispatch(PRIVATE_ACTIONS.extractWithPoling(channel, accountId));
    },

  sortAccounts:
    (field, channel) =>
    ({ getState, setState, dispatch }) => {
      const accounts = dispatch(PRIVATE_ACTIONS.getChannelAccounts(channel));
      const { accountsSortField, accountsSortDirection } = getState();

      const sameField = accountsSortField === field;
      const sortedAsc = accountsSortDirection === SORT_DIRECTION.asc;
      const newDirection = sameField && sortedAsc ? SORT_DIRECTION.desc : SORT_DIRECTION.asc;

      const sorted = orderBy(accounts, [field], [newDirection]);

      dispatch(PRIVATE_ACTIONS.setChannelAccounts(channel, sorted));

      setState({
        accountsSortField: field,
        accountsSortDirection: newDirection,
      });
    },

  getPerformanceCenterResultDocument:
    (adId) =>
    async (_, { notifications, currentCustomerId }) => {
      try {
        return await getPerformanceCenterResultDocument(adId, currentCustomerId);
      } catch (error) {
        notifications.displayError(error);
      }
    },

  getProjectForNewCopies:
    () =>
    async (_, { currentWorkspaceId }) => {
      return queryProjectForNewCopies(currentWorkspaceId);
    },

  clearStore: PRIVATE_ACTIONS.clearStore,

  cancelWaitRequests: (channel) => () => {
    if (channel) {
      if (channelAbortControllers.has(channel)) {
        channelAbortControllers.get(channel)?.abort();
        channelAbortControllers.delete(channel);
      }
    } else {
      channelAbortControllers.forEach((channelAbortController) => {
        channelAbortController.abort();
      });
      channelAbortControllers.clear();
    }
  },

  setAuthErrorMessage:
    (channel, message) =>
    ({ getState, setState }) => {
      const { authErrorMessage } = getState();

      setState({
        authErrorMessage: {
          ...authErrorMessage,
          [channel]: message,
        },
      });
    },
  clearAuthErrorMessage:
    (channel) =>
    ({ getState, setState }) => {
      const { authErrorMessage } = getState();

      delete authErrorMessage[channel];

      setState({
        authErrorMessage,
      });
    },

  getChannelAuthState:
    (channel) =>
    ({ getState }) => {
      return getState().authState[channel];
    },
  setLoadingGlobalBenchmark:
    (loadingGlobalBenchmark) =>
    async ({ setState }) => {
      setState({ loadingGlobalBenchmark });
    },
  queryPerformanceCenterGlobalBenchmark:
    () =>
    async ({ setState }, { currentCustomerId, notifications }) => {
      setState({ loadingGlobalBenchmark: true });

      try {
        // TODO: pass "workspace IDs" directly to the query, to limit the amount of data fetched from BE
        const globalBenchmarkConfigs = await queryPerformanceCenterGlobalBenchmark(
          currentCustomerId
        );

        setState({ globalBenchmarkConfigs });
      } catch (error) {
        notifications.displayError(error);
      } finally {
        setState({ loadingGlobalBenchmark: false });
      }
    },

  /**
   * Refresh WTM integration info: "auth state" and "active projects list"
   * @param {string} workspaceId - workspace id
   * @return {Promise}
   */
  refreshWTMIntegrationInfo:
    (workspaceId) =>
    async ({ setState, dispatch }, { currentCustomerId, currentWorkspaceId }) => {
      try {
        const result = await queryWTMProjectsForCIP({
          customerId: currentCustomerId,
          workspaceId: workspaceId || currentWorkspaceId,
        });
        const withNonDraftCampaign = result.filter(
          (project) => !isCampaignDraft(project?.continuousOptimizationCampaign?.status)
        );

        // Store WTM projects to display on "manage integration" popup
        setState({ wtmProjects: withNonDraftCampaign });

        // Store "WTM project" auth state
        const authState =
          withNonDraftCampaign.length > 0
            ? SOCIAL_AUTH_STATE.authorized
            : SOCIAL_AUTH_STATE.unauthorized;
        dispatch(PRIVATE_ACTIONS.setChannelAuthState(CHANNEL.projectWtm, authState));
      } catch (error) {
        // In case of an error - set auth state to "unauthorized"
        dispatch(
          PRIVATE_ACTIONS.setChannelAuthState(CHANNEL.projectWtm, SOCIAL_AUTH_STATE.unauthorized)
        );
      }
    },

  /**
   * Start/Pause whole WTM campaign and get the new "status"
   * @param {string} projectId - project id
   * @return {Promise<CAMPAIGN_STATUS>} - new campaign status
   */
  toggleWTMIntegrationStatus:
    (projectId) =>
    async ({ getState, setState }) => {
      // Get project and campaign from stored list
      const { wtmProjects } = getState();
      const project = wtmProjects.find((item) => item.id === projectId);
      const campaign = project?.continuousOptimizationCampaign;
      if (!campaign) {
        throw new Error('WTM campaign not found');
      }

      // Start or pause campaign and get the new status
      const result = isCampaignRunning(campaign.status)
        ? await pauseCOCampaign(campaign.id)
        : await startCOCampaign(campaign.id);

      // Rewrite status in stored projects
      const newList = wtmProjects.map((item) => {
        if (item.id === projectId) {
          return {
            ...item,
            continuousOptimizationCampaign: {
              ...item.continuousOptimizationCampaign,
              status: result.status,
            },
          };
        }
        return item;
      });
      setState({ wtmProjects: newList });

      // Return new status
      return result.status;
    },

  removeWTMIntegrationProject:
    (projectId) =>
    ({ getState, setState }) => {
      const { wtmProjects } = getState();
      const newList = wtmProjects.filter((item) => item.id !== projectId);
      setState({ wtmProjects: newList });
    },

  mergeAccountStatus:
    (channel, accountId, status) =>
    ({ getState, setState }) => {
      const { accounts, extractionStatus } = getState();

      const channelAccounts = accounts[channel] || [];

      const updatedAccounts = channelAccounts.map((account) => {
        if (account.id === accountId) {
          return { ...account, status };
        }
        return account;
      });

      setState({
        accounts: { ...accounts, [channel]: updatedAccounts },
        extractionStatus: {
          ...extractionStatus,
          [channel]: { ...extractionStatus[channel], [accountId]: status },
        },
      });
    },

  mergeAccountName:
    (channel, accountId, name) =>
    ({ getState, setState }) => {
      if (!name) {
        return;
      }
      const { accounts, customerAccounts } = getState();

      const channelAccounts = accounts[channel] || [];

      const customerChannelAccounts = customerAccounts[channel] || [];

      const updatedChannelAccounts = channelAccounts.map((account) => {
        if (account.id === accountId) {
          return { ...account, name };
        }
        return account;
      });

      const updatedCustomerChannelAccounts = customerChannelAccounts.map((account) => {
        if (account.id === accountId) {
          return { ...account, name };
        }
        return account;
      });

      setState({
        accounts: { ...accounts, [channel]: updatedChannelAccounts },
        channelAccounts: { ...channelAccounts, [channel]: updatedCustomerChannelAccounts },
      });
    },

  deleteResource:
    (id) =>
    async ({ getState, setState }, { notifications }) => {
      const { resources, customerResources } = getState();
      try {
        const deletedResource = await deleteResource(id);

        const newList = resources.filter((r) => r.id !== deletedResource.id);
        const newCustomerList = customerResources.filter((r) => r.id !== deletedResource.id);

        setState({
          resources: newList,
          customerResources: newCustomerList,
        });
        notifications.displaySuccess('Resource deleted');
      } catch (error) {
        notifications.displayError(error);
      }
    },

  bulkDeleteResources:
    (ids) =>
    async ({ getState, setState }, { notifications }) => {
      const { resources, customerResources } = getState();
      try {
        setState({ bulkDeletingResources: true });

        const deletedResources = await bulkDeleteResources(ids);

        const deletedResourcesIds = map(deletedResources, 'id');

        const newList = resources.filter((r) => !deletedResourcesIds.includes(r.id));
        const newCustomerList = customerResources.filter(
          (r) => !deletedResourcesIds.includes(r.id)
        );

        setState({
          resources: newList,
          customerResources: newCustomerList,
        });
      } finally {
        setState({ bulkDeletingResources: false });
      }
    },

  extractResourceTitle:
    (url) =>
    async ({ setState }, { currentCustomerId }) => {
      if (!isURL(url)) {
        return;
      }
      setState({ isExtractingResourceTitle: true });
      try {
        const result = await extractResourceTitle({
          customerId: currentCustomerId,
          input: { content: url, type: RESOURCE_CONTENT_TYPE.url },
        });

        const resource = await waitForExtraction({ id: result.id });

        return resource.title;
      } finally {
        setState({ isExtractingResourceTitle: false });
      }
    },

  extractResource:
    ({ name, url, tags, file, contentType }) =>
    async ({ getState, setState }, { currentCustomerId, currentWorkspaceId }) => {
      const { resources, customerResources } = getState();
      try {
        setState({ isCreatingResource: true });
        const result = await extractResource({
          customerId: currentCustomerId,
          workspaceId: currentWorkspaceId,
          input: {
            content: file ? null : url,
            contentType,
            name,
            tags,
            file,
          },
        });

        const extractionResult = await waitForExtraction({ id: result.id });

        const results = await queryCustomerResource(
          currentCustomerId,
          extractionResult.resource_id
        );

        if (results?.length) {
          setState({
            resources: [...results, ...resources],
            customerResources: [...results, ...customerResources],
          });
        }

        return results?.[0];
      } finally {
        setState({ isCreatingResource: false });
      }
    },

  updateResource:
    ({ id, name, tags }) =>
    async ({ getState, setState }, { currentCustomerId, currentWorkspaceId }) => {
      const { resources, customerResources } = getState();
      try {
        setState({ isUpdatingResource: true });
        const updatedResource = await updateResource({
          id,
          customerId: currentCustomerId,
          workspaceId: currentWorkspaceId,
          input: { name, tags },
        });

        const newList = resources.map((resource) =>
          resource.id === updatedResource.id
            ? { ...resource, name: updateResource.name, tags: updateResource.tags }
            : resource
        );

        const newCustomerList = customerResources.map((resource) =>
          resource.id === updatedResource.id
            ? { ...resource, name: updateResource.name, tags: updateResource.tags }
            : resource
        );

        setState({
          resources: newList,
          customerResources: newCustomerList,
        });

        return updatedResource;
      } finally {
        setState({ isUpdatingResource: false });
      }
    },

  validateResourcesExistence:
    ({ urls, file, type }) =>
    ({ _ }, { currentCustomerId, currentWorkspaceId }) => {
      const inputs = file
        ? [{ file, type }]
        : urls?.map((url) => ({
            content: url,
            type: RESOURCE_CONTENT_TYPE.url,
          }));
      return checkCustomerResourcesExistence({
        customerId: currentCustomerId,
        workspaceId: currentWorkspaceId,
        inputs,
      });
    },

  setIsCreatingResource:
    (isCreatingResource) =>
    ({ setState }) => {
      setState({ isCreatingResource });
    },

  mergeToAccountList: PRIVATE_ACTIONS.mergeToAccountList,

  renameChannelAccount:
    (account, newAccountName) =>
    async ({ dispatch }, { currentCustomerId }) => {
      const result = await updateAccountName({
        customerId: currentCustomerId,
        accountId: account.id,
        dataSource: alignChannelWithBackend(account.channel),
        newAccountName,
      });
      dispatch(ACTIONS.mergeAccountName(account.channel, account.id, result?.name));
    },

  getDocumentResource:
    (id) =>
    ({ dispatch }) => {
      const resources = dispatch(
        PRIVATE_ACTIONS.getResourcesByContentType(RESOURCE_CONTENT_TYPE.document)
      );
      return resources.find((resource) => resource.id === id);
    },

  pushToGlobalBenchmarkConfig:
    ({ account, assetType, newComparisonSets }) =>
    ({ getState, setState }) => {
      const { globalBenchmarkConfigs } = getState();

      const newList = globalBenchmarkConfigs.map((c) =>
        c.account.id === account.id && c.assetType === assetType
          ? { ...c, comparisonSets: [...newComparisonSets, ...c.comparisonSets] }
          : c
      );

      setState({ globalBenchmarkConfigs: newList });
    },

  getGlobalBenchmarkConfigs:
    () =>
    ({ getState }) => {
      return getState().globalBenchmarkConfigs;
    },

  integrationAuthorize:
    (channel, credentials) =>
    async ({ dispatch }, { currentCustomerId }) => {
      const result = await integrationAuthorize({
        customerId: currentCustomerId,
        dataSource: alignChannelWithBackend(channel),
        credentials,
      });
      await dispatch(ACTIONS.queryUserIntegrationAccounts());
      await dispatch(PRIVATE_ACTIONS.setChannelAuthState(channel, SOCIAL_AUTH_STATE.authorized));
      return result;
    },
};

export const PerformanceStore = createStore({
  initialState: INITIAL_STATE,
  actions: ACTIONS,
  name: NAME,
});
