import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation ConfirmTokenLogin($authToken: String!, $loginDevice: String!) {
    confirmTokenLogin(authToken: $authToken, loginDevice: $loginDevice) {
      token
    }
  }
`;

export const confirmTokenLogin = (authToken, loginDevice) =>
  executeGqlMutation({
    mutation: MUTATION,
    variables: { authToken, loginDevice },
  }).then(({ data }) => getSingleProp(data));
