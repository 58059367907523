import React, { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

import { generateSvgId } from 'src/lib/svg';

export const PersonaAvatarFour = memo(
  forwardRef(({ opaque, ...props }, ref) => {
    const svgId = useMemo(() => generateSvgId(), []);

    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 72 72"
        data-fill-none
      >
        <g clipPath={`url(#${svgId})`}>
          <path
            d="M72.0002 35.9946C72.0013 43.1151 69.8908 50.076 65.9356 55.997C61.9804 61.918 56.3582 66.533 49.7799 69.2586C43.2017 71.9841 35.963 72.6976 28.9792 71.3089C21.9954 69.9203 15.5803 66.4918 10.5452 61.457C5.51003 56.4222 2.08102 50.0074 0.691815 43.0237C-0.697392 36.04 0.0156013 28.8013 2.74062 22.2228C5.46565 15.6444 10.0803 10.0218 16.001 6.06616C21.9216 2.1105 28.8824 -0.000542286 36.0029 1.04491e-07C45.5475 0.00653773 54.6993 3.80083 61.4487 10.5496C68.198 17.2984 71.993 26.45 72.0002 35.9946Z"
            fill="#8CB2FF"
          />
          <path
            d="M54.6642 41.6311C54.1312 42.4421 53.459 43.1525 52.6785 43.7293C52.5882 43.7971 52.4753 43.8276 52.3631 43.8143C52.0724 43.7786 51.9051 43.4221 51.9051 43.4221L51.8584 43.5784V43.6031L49.8728 50.4049L48.485 55.1579C48.485 55.1579 48.2272 55.5912 47.994 57.7305C47.9666 57.9801 47.9392 58.2544 47.9145 58.5533C47.8898 58.8523 47.8706 59.1018 47.8487 59.4008V59.4172C47.8048 60.0563 47.7664 60.7886 47.7335 61.6114V61.7403C47.5607 66.2218 48.6468 68.4104 49.1048 69.1455V69.1592C49.209 69.3155 49.2804 69.4005 49.2968 69.4334L49.2557 69.4855C41.3045 72.6173 32.501 72.8311 24.4072 70.0889L24.5772 69.6364C24.953 68.6408 25.5591 67.0665 25.9184 66.3452C26.4313 65.303 26.7028 59.1951 26.7412 58.4381C26.7412 58.3888 26.7412 58.3613 26.7412 58.3613L26.7659 58.2324L28.7351 48.3781L28.7982 48.0627L28.4965 47.8652L22.619 44.0529L22.3228 43.8609L22.1308 43.7347C21.6056 43.6774 21.1093 43.4655 20.7046 43.1259L20.6882 43.1122C20.6089 43.0474 20.5355 42.9758 20.4688 42.8982C19.6005 41.7592 19.0133 40.4315 18.7546 39.0229C18.712 38.7502 18.712 38.4727 18.7546 38.2001C18.7975 37.9604 18.8683 37.7266 18.9658 37.5034C18.967 37.498 18.967 37.4924 18.9658 37.487C19.3052 36.774 19.8 36.1461 20.4139 35.6494C20.5839 35.504 20.7403 35.3751 20.8664 35.2846C20.9259 35.2399 20.9903 35.2022 21.0584 35.1722C21.4568 35.0479 21.8873 35.0772 22.2652 35.2545L22.3914 35.3093L22.438 35.3312L22.5422 28.3155L22.6821 27.8328L22.715 27.7231L23.184 26.0995L24.9969 24.0918L25.0161 24.0672L26.179 22.7809L39.703 17.2188L46.79 20.3179C46.79 20.3179 50.0099 26.3518 50.9259 27.8164C51.4992 28.7434 51.6473 31.3462 51.6774 33.1536C51.6774 33.6884 51.6774 34.1519 51.6774 34.4838C51.6774 34.8156 51.6774 35.0158 51.6774 35.0158L51.757 35.0405L51.8776 35.0817L52.2397 35.1969L52.3933 35.2462L53.1804 35.5013L53.6494 35.6521C53.6494 35.6521 54.283 35.7536 54.8315 36.7492C55.0918 37.2505 55.2766 37.7874 55.38 38.3427C55.38 38.3893 55.3992 38.4414 55.4102 38.4908C55.6461 39.6646 55.2374 40.7507 54.6642 41.6311Z"
            fill="#DEE6F7"
          />
          <path
            d="M22.4536 35.3512L20.8711 35.3019L21.0713 25.9494C21.0713 25.9494 21.5732 19.3012 22.6648 18.4592C23.7564 17.6172 23.2133 17.3621 23.2133 17.3621L22.6483 17.2332C22.6483 17.2332 23.8496 14.4906 26.7212 13.8049C29.5927 13.1193 32.755 12.5625 32.755 12.5625L39.2743 13.0233L43.1497 14.2355L44.3208 14.9404L44.0027 13.7775L48.0508 15.8509L51.1692 18.8404C51.1692 18.8404 51.992 20.135 52.0578 20.7164C52.0989 21.0568 52.1182 21.3994 52.1154 21.7422C52.1154 21.7422 53.111 22.7405 53.2783 23.2095C53.4456 23.6785 53.9174 29.9263 53.9174 29.9263L53.4703 35.247L51.896 35.1839C51.896 35.1839 51.3831 28.7167 50.3108 26.86C49.2384 25.0032 46.4985 23.1793 46.4985 23.1793L45.4892 22.8283L46.1639 22.3702L44.7925 21.6544L40.5963 21.4295L38.7724 22.1289L34.2169 23.5002L26.2632 23.961L24.7986 24.1365L23.2407 25.8452L22.7498 27.0684L22.4536 35.3512Z"
            fill="white"
          />
          <path
            d="M22.543 35.9922L23.5852 40.3804C23.5852 40.3804 25.409 45.6984 26.9916 46.4911C28.5741 47.2837 28.5274 47.5388 28.5274 47.5388C28.5274 47.5388 31.6431 45.1581 36.7198 45.2157C41.7964 45.2733 44.38 46.3375 44.38 46.3375L45.9104 47.5388C45.9104 47.5388 49.3058 44.9991 50.37 41.291C51.4341 37.5829 51.6672 35.9922 51.6672 35.9922L51.9086 46.5212L51.2476 52.5551C51.2476 52.5551 50.2849 55.6186 49.8763 56.1754C49.4676 56.7322 47.9893 57.7277 47.9893 57.7277L46.8731 55.5199L46.6317 58.4381C46.6317 58.4381 45.6965 60.1714 41.5276 60.9915C38.8301 61.4953 36.0904 61.7396 33.3463 61.721L28.1654 59.6887C28.1654 59.6887 24.3257 55.9203 23.939 54.1403C23.5523 52.3604 22.6499 47.8322 22.6499 47.8322L22.543 35.9922Z"
            fill="white"
          />
          <path
            d="M25.4225 32.4981C25.4225 32.4981 28.4998 32.5201 30.2112 32.1114C30.998 31.9409 31.773 31.7201 32.5315 31.4504C32.5315 31.4504 32.9895 29.2563 32.7619 28.6666C32.5342 28.077 31.7389 27.6875 31.7389 27.6875L29.3061 27.7999L24.8164 29.1384L25.4225 32.4981Z"
            fill="white"
          />
          <path
            d="M41.6539 31.2914C41.6539 31.2914 41.4043 29.2399 41.6539 28.6695C41.9035 28.099 42.8634 27.5312 42.8634 27.5312L48.5956 28.6695L49.4705 29.6513L48.985 32.394L48.3926 32.8931L43.4558 32.1636L41.6539 31.2914Z"
            fill="white"
          />
          <path
            d="M36.4309 31.1406C36.8148 34.5909 35.7205 37.9973 34.7853 41.3515C34.6262 41.9165 34.4726 42.5391 34.7194 43.0712C34.873 43.3671 35.1044 43.6156 35.3887 43.7898C35.9932 44.1895 36.7112 44.382 37.4347 44.3383"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.4883 39.1641C41.6219 39.5844 41.8629 39.9626 42.1877 40.2611C42.4314 40.4336 42.7141 40.5429 43.0105 40.5793"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M32.5306 39.125C32.4985 39.6282 32.2919 40.1044 31.9464 40.4716C31.8361 40.5943 31.6978 40.6884 31.5432 40.7459C31.4657 40.7746 31.3823 40.7836 31.3004 40.7722C31.2186 40.7607 31.1408 40.729 31.0742 40.6801"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M51.8906 35.1875C53.0233 35.2204 54.551 36.0734 55.0365 37.1074C55.4947 38.1572 55.5474 39.3395 55.1846 40.426C54.7927 41.7091 53.99 42.828 52.8999 43.6102C52.6476 43.7912 52.2801 43.9448 52.0524 43.7336"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.7724 38.2188C53.3227 38.3745 52.918 38.6378 52.5934 38.9858C52.2688 39.3339 52.0342 39.756 51.9102 40.2154"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.7877 35.2422C20.655 35.2751 19.6238 36.065 19.1356 37.0907C18.6787 38.1408 18.6259 39.3227 18.9875 40.4094C19.3814 41.6923 20.1848 42.8108 21.2749 43.5936C21.5272 43.7746 21.8947 43.9282 22.1224 43.717"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.4062 38.2188C20.8563 38.3743 21.2615 38.6376 21.5866 38.9856C21.9117 39.3336 22.1467 39.7558 22.2713 40.2154"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M45.6991 47.7655C45.1106 46.9973 44.318 46.41 43.4117 46.0706C40.6937 44.9132 37.6137 45.0201 34.6873 45.4096C32.4712 45.7058 30.1784 46.1967 28.4725 47.579C28.4231 48.1276 28.8756 48.613 29.3336 48.9504C30.1647 49.5983 31.2061 49.9158 32.2573 49.8417C32.5575 49.2345 32.9041 48.6513 33.294 48.0974L32.9485 50.0364C35.6459 50.6507 38.4405 50.7057 41.16 50.1983C42.8385 49.8829 44.5417 49.3179 45.7676 48.1276L45.6991 47.7655Z"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M45.7773 47.6051C47.0198 47.0565 47.8755 45.8827 48.5639 44.7033C50.3848 41.5109 51.4603 37.948 51.7097 34.2812C51.9593 37.4353 51.9017 40.6031 51.8441 43.7654C51.8057 46.0253 51.7646 48.288 51.5314 50.537C51.3312 52.487 50.9829 54.4727 49.9489 56.143"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.8724 27.8203C39.7509 28.332 39.701 28.8582 39.7243 29.3836C39.7387 29.6461 39.8061 29.903 39.9225 30.1387C40.039 30.3744 40.202 30.584 40.4018 30.755"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M42.4948 27.7146C42.2864 27.761 42.0954 27.8655 41.944 28.016C41.7925 28.1665 41.6868 28.3568 41.6391 28.5648C41.4592 29.4066 41.4761 30.2786 41.6884 31.1128C41.7168 31.2796 41.7909 31.4353 41.9024 31.5626C42.0243 31.6624 42.1681 31.7319 42.322 31.7655C44.2631 32.3411 46.2693 32.6683 48.2927 32.7392C48.4689 32.7431 48.6413 32.6877 48.7821 32.5817C48.923 32.4757 49.024 32.3254 49.0689 32.155C49.2794 31.3758 49.4099 30.5772 49.4584 29.7716C49.4705 29.6106 49.4331 29.4497 49.3512 29.3105C49.2694 29.1713 49.147 29.0604 49.0003 28.9927C47.4068 28.2872 45.7104 27.8418 43.9758 27.6735C43.4831 27.6078 42.9831 27.6217 42.4948 27.7146V27.7146Z"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.4071 27.8203C34.5264 28.3324 34.5753 28.8583 34.5524 29.3836C34.5395 29.6464 34.4727 29.9038 34.3562 30.1397C34.2396 30.3756 34.0759 30.585 33.875 30.755"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M31.7821 27.7146C31.9905 27.761 32.1815 27.8655 32.3329 28.016C32.4844 28.1665 32.5901 28.3568 32.6379 28.5648C32.8186 29.4064 32.8027 30.2784 32.5912 31.1128C32.5606 31.2794 32.4857 31.4347 32.3746 31.5626C32.2527 31.6624 32.1089 31.7319 31.9549 31.7655C30.0148 32.3411 28.0094 32.6683 25.9869 32.7392C25.8104 32.7433 25.6376 32.6879 25.4963 32.582C25.355 32.476 25.2535 32.3256 25.208 32.155C24.9988 31.3756 24.8683 30.5771 24.8185 29.7716C24.8067 29.6103 24.8445 29.4492 24.9268 29.3099C25.0092 29.1707 25.1322 29.06 25.2793 28.9927C26.8718 28.2868 28.5673 27.8414 30.3011 27.6735C30.7938 27.6078 31.2939 27.6217 31.7821 27.7146V27.7146Z"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M23.6583 20.1016C22.7787 22.2393 22.4751 24.5704 22.778 26.8622C23.913 24.6658 25.5339 22.757 27.5173 21.2809"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.6719 24.1169C27.8945 23.6643 31.1775 23.8015 34.3973 23.3352C37.6172 22.869 40.9139 21.6896 42.9764 19.1719"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.9453 21.8069C41.0226 21.4648 42.1581 21.3443 43.2832 21.4527C44.4083 21.5611 45.4999 21.8962 46.492 22.4378L44.4817 22.8821C47.4273 22.9561 50.0273 25.3285 50.8172 28.1644C51.079 29.2433 51.2297 30.3461 51.267 31.4556C51.3356 32.5033 51.6181 33.551 51.6894 34.5987"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.4424 35.5119C53.7828 32.1524 53.8644 28.7717 53.6865 25.3997C53.6179 24.086 53.4122 22.5885 52.0902 21.9166C52.2083 20.9076 51.9398 19.8914 51.3388 19.0724C50.7378 18.2639 50.0152 17.5534 49.1967 16.9661C47.5484 15.6879 45.7951 14.5512 43.9555 13.5679L44.3642 15.1312C43.3138 14.0945 41.8464 13.6173 40.412 13.2745C36.0923 12.246 31.471 12.1774 27.2665 13.6008C25.2342 14.292 23.1525 15.5042 22.4476 17.5311L23.819 17.2157C22.3461 18.2551 21.8607 20.1942 21.5755 21.9742C20.8653 26.3747 20.7004 30.8461 21.0845 35.287C21.3057 35.2045 21.5415 35.1679 21.7773 35.1797C22.0131 35.1915 22.244 35.2514 22.4559 35.3556"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.749 27.0781C22.5383 29.7964 22.4989 32.5253 22.6311 35.2485"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.7329 58.3438C26.3187 61.72 26.5683 65.2525 25.2738 68.3983C25.097 68.8684 24.842 69.3051 24.5195 69.6901"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M47.9056 57.7969C47.922 59.7935 47.6313 61.7847 47.645 63.7813C47.6587 65.778 48.0619 67.8569 49.2906 69.4312"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.4693 38.8648C28.9313 38.8648 29.3058 38.0556 29.3058 37.0574C29.3058 36.0592 28.9313 35.25 28.4693 35.25C28.0073 35.25 27.6328 36.0592 27.6328 37.0574C27.6328 38.0556 28.0073 38.8648 28.4693 38.8648Z"
            fill="black"
          />
          <path
            d="M44.6646 38.8648C45.1266 38.8648 45.5011 38.0556 45.5011 37.0574C45.5011 36.0592 45.1266 35.25 44.6646 35.25C44.2026 35.25 43.8281 36.0592 43.8281 37.0574C43.8281 38.0556 44.2026 38.8648 44.6646 38.8648Z"
            fill="black"
          />
          <path
            d="M33.6055 52.5625C33.6055 52.5625 35.8435 54.6606 40.4018 52.8806"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.7833 49.2344C29.7833 49.2344 26.8103 55.0241 29.9561 60.589"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M44.1719 49.2344C44.1719 49.2344 45.9656 56.3488 44.4626 59.8155"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M48.4667 45.5406C48.4667 45.5406 50.6169 49.043 49.8627 56.1849C49.4016 56.9389 48.6884 57.5054 47.8496 57.7838C47.4002 56.5994 47.0892 55.3671 46.9225 54.1114L46.7964 57.8222C46.8037 58.062 46.7626 58.3008 46.6757 58.5243C46.5604 58.7334 46.3991 58.9134 46.204 59.0509C45.1069 59.9231 43.7877 60.4497 42.4548 60.8611C39.5146 61.7716 36.3716 62.1803 33.3382 61.6455C30.3048 61.1107 27.3948 59.5666 25.5573 57.1146C23.6758 54.6133 23.0203 51.4072 22.6638 48.297C22.1669 43.9446 22.154 39.5505 22.6254 35.1953C23.0806 37.6088 23.3823 40.0334 24.2408 42.2878C25.0992 44.5423 26.3471 46.4704 28.4837 47.5894"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M36.148 61.8822C36.148 61.8822 34.6697 58.4457 35.0345 55.3438"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.0586 61.1758C40.2779 60.0627 40.3927 58.9314 40.4014 57.7969"
            stroke="black"
            strokeWidth="0.54962"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.7305 60.5847C26.7305 60.5847 30.2959 67.0163 37.83 66.7118C45.3641 66.4074 47.7036 61.7504 47.7036 61.7504L47.8599 58.7471L47.7502 58.1108L47.1331 56.0703L46.7683 58.61L45.5945 59.6275L43.0986 60.837L40.2902 61.5447L38.096 61.7613L34.9749 61.6571C34.9749 61.6571 30.2027 60.5189 30.1506 60.5847C30.0985 60.6505 27.0047 58.909 27.0047 58.909H26.7881L26.7305 60.5847Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id={svgId}>
            <rect
              width="72"
              height="72"
              fill="white"
            />
          </clipPath>
        </defs>
      </SvgIcon>
    );
  })
);
PersonaAvatarFour.propTypes = {
  opaque: PropTypes.bool,
};
PersonaAvatarFour.defaultProps = {
  opaque: false,
};
