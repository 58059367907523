import { makeStyles } from '@material-ui/core/styles';

import DataDrivenDocumentsPopupHeaderBG from 'src/assets/img/data_driven_documents_popup_header.jpg';
import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    paper: {
      maxWidth: '480px',
      backgroundColor: theme.palette.common.white,
    },
    dialogTitle: {
      backgroundColor: COLORS.primary.default,
      backgroundImage: `url(${DataDrivenDocumentsPopupHeaderBG})`,
    },
    title: {
      fontFamily: theme.typography.fontRobotoSlab,
      fontSize: theme.typography.pxToRem(26),
      lineHeight: theme.typography.pxToRem(34.8),
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white,
      textShadow: '1.2px 2.5px 3.7px 0px rgba(10, 54, 104, 0.35)',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
    },
    description: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22.4),
      whiteSpace: 'pre-wrap',
      '& + &': {
        margin: theme.spacing(2, 0, 3.75),
      },
    },
    dialogContent: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      padding: theme.spacing(3.75, 4),
    },
    iframe: {
      borderRadius: theme.shape.borderRadius,
      border: 0,
      width: 416,
      height: 234,
    },
    gotIt: {
      margin: theme.spacing(4.5, 'auto', 0),
    },
  }),
  { name: 'DataDrivenDocumentsPopup' }
);
