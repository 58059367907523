import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const MAX_WORKSPACE_ITEMS = 10;

export const useStyles = makeStyles(
  (theme) => ({
    paper: {
      backgroundColor: theme.palette.darkSelected,
      boxShadow: '0px 4px 26px -6px rgba(0, 0, 0, 0.25)',
      padding: 0,
    },
    searchItem: {
      margin: theme.spacing(1.5, 1, 0),
    },
    workspaceItems: {
      maxHeight: 38 * MAX_WORKSPACE_ITEMS,
      overflowY: 'auto',
      outline: 'none',
      padding: theme.spacing(1.5, 1),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      outline: 'none',
      padding: theme.spacing(0.5, 1, 0.5, 2),
      borderBottom: '1px solid rgba(90, 112, 150, 0.2)',

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    productWorkspaces: {
      '& span': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightMedium,
        color: COLORS.neutral.silver,
      },
    },
    addNew: {
      minWidth: 'unset',
      color: theme.palette.common.white,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      padding: theme.spacing(1),
      borderRadius: 4,

      '&:hover': {
        backgroundColor: theme.palette.darkHoverSecondary,
      },
    },
    workspaceItem: {
      borderRadius: 6,

      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    item: {
      padding: theme.spacing(1.5, 1),
    },
    itemIcon: {
      fontSize: theme.typography.pxToRem(16),
      marginRight: '8px',
    },
    itemText: {
      '& span': {
        fontSize: theme.typography.pxToRem(13),
        color: theme.palette.common.white,
        lineHeight: theme.typography.pxToRem(16),
      },
    },
  }),
  { name: 'WorkspaceMenu' }
);
