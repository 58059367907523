/* eslint-disable no-param-reassign */
import { Transforms } from 'slate';

import { SLATE_TEXT_FORMATS } from 'src/lib/slate';
import { htmlToSlate } from '../lib';

const removeVariationWrapping = (nodes) =>
  nodes.reduce((acc, node) => {
    if (node.type === SLATE_TEXT_FORMATS.DD_VARIATION) {
      return [...acc, ...node.children];
    }
    return [...acc, node];
  }, []);

export const withHtml = (editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const html = data.getData('text/html');

    if (html) {
      const parsed = htmlToSlate(html);
      const cleared = removeVariationWrapping(parsed);
      Transforms.insertFragment(editor, cleared);
      return;
    }

    insertData(data);
  };

  return editor;
};
