import gql from 'graphql-tag';

export const FRAGMENT_MULTI_PROMPT_INPUT = gql`
  fragment MultiPromptInput on MultiPromptInput {
    name
    description
  }
`;

export const FRAGMENT_MULTI_PROMPT_INPUTS = gql`
  fragment MultiPromptInputs on MultiPromptInputs {
    inputs {
      ...MultiPromptInput
    }
  }
  ${FRAGMENT_MULTI_PROMPT_INPUT}
`;

export const FRAGMENT_MULTI_PROMPT_BLOCK = gql`
  fragment MultiPromptBlock on MultiPromptBlock {
    name
    dependentInputs
    generatedPrompt
    type
    reasoning
  }
`;

export const FRAGMENT_MULTI_PROMPT_BLOCKS = gql`
  fragment MultiPromptBlocks on MultiPromptBlocks {
    blocks {
      ...MultiPromptBlock
    }
  }
  ${FRAGMENT_MULTI_PROMPT_BLOCK}
`;

export const FRAGMENT_CSV_FILE = gql`
  fragment CSVFile on CSVFile {
    id
    name
    headers
    sampleData
    size
  }
`;

export const FRAGMENT_MULTI_PROMPT_TEMPLATE = gql`
  fragment CustomerMultiPromptTemplate on CustomerMultiPromptTemplate {
    id
    name
    description
    info
    fields
    blocks
    status
    templateGroup
    smallImage
    bigImage
    iconImage
    isReleasedSrc
    primaryKey
    selectedHeaders
    templateType
    csvFile {
      ...CSVFile
    }
  }
  ${FRAGMENT_CSV_FILE}
`;

export const FRAGMENT_MULTI_PROMPT_TEMPLATES = gql`
  fragment CustomerMultiPromptTemplates on CustomerMultiPromptTemplates {
    templates {
      ...CustomerMultiPromptTemplate
    }
  }
  ${FRAGMENT_MULTI_PROMPT_TEMPLATE}
`;
