import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';
import marketingTextBG from './assets/marketingTextBG.svg';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
    },
    paper: {
      overflow: 'hidden',
      width: 1000,
      maxWidth: 1000,
      height: 600,
      maxHeight: 600,
    },
    dialogContent: {
      padding: theme.spacing(5.5, 5.5, 6.25),
    },
    title: {
      fontSize: theme.typography.pxToRem(32),
      lineHeight: theme.typography.pxToRem(48),
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(2.25),
    },
    description: {
      marginBottom: theme.spacing(4.5),
    },
    marketingText: {
      padding: theme.spacing(1.75),
      backgroundImage: `url(${marketingTextBG})`,
      width: 422,
      height: 95,
      '& p': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(22),
        color: COLORS.neutral.darkGray,
      },
    },
    rightBlockImg: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    textsWithIcons: {
      width: 499,
      margin: theme.spacing(4.5, 0, 5),
      display: 'flex',
      columnGap: theme.spacing(1),
      rowGap: theme.spacing(3),
      flexWrap: 'wrap',
    },
    textWithIcon: {
      display: 'flex',
      alignItems: 'flex-start',
      columnGap: theme.spacing(2.25),
    },
    itemTexts: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(0.75),
      width: 177,
    },
    itemTitle: {
      lineHeight: theme.typography.pxToRem(15),
    },
    itemDescription: {
      lineHeight: theme.typography.pxToRem(15),
      whiteSpace: 'pre-wrap',
    },
    itemIconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '14.62px 14.62px 36.54px 0px rgba(19, 17, 118, 0.07)',
      borderRadius: theme.shape.borderRadius,
      width: 40,
      height: 40,
    },
    submitButton: {
      borderRadius: 50,
    },
  }),
  { name: 'WelcomeRetrialPopup' }
);
