import React from 'react';
import cx from 'classnames';
import { Menu, MenuItem } from '@material-ui/core';

import PlusIcon from 'src/assets/icons/Plus';
import { BlogWizardIcon, EditorIcon } from 'src/assets/icons/navbar-links';
import { CustomButton } from 'src/components/CustomButton';
import { STONLY_ID } from 'src/lib/stonly';
import { usePopoverAnchor } from 'src/lib/hooks';
import { useHandlers } from './useHandlers';
import { useStyles } from './useStyles';

const MENU_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  getContentAnchorEl: null,
};

export const CreateContentItem = ({ className }) => {
  const styles = useStyles();

  const [anchorEl, openNewProjectMenu, closeNewProjectMenu] = usePopoverAnchor();

  const { handleNewProjectClick, handleCreateDataDrivenProject, handleCreateBlogProject } =
    useHandlers({ openNewProjectMenu, closeNewProjectMenu });

  return (
    <>
      <CustomButton
        className={cx(styles.root, className)}
        onClick={handleNewProjectClick}
        startIcon={<PlusIcon />}
        data-stonly-id={STONLY_ID.createDocumentButton}
        data-testid="createContentButton"
      >
        Create content
      </CustomButton>

      <Menu
        classes={{
          paper: styles.menu_paper,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeNewProjectMenu}
        variant="menu"
        keepMounted
        {...MENU_PROPS}
      >
        <MenuItem
          className={styles.menuItem}
          onClick={handleCreateDataDrivenProject}
          data-testid="editorSelector"
        >
          <EditorIcon />
          Data-driven Editor
        </MenuItem>
        <MenuItem
          className={styles.menuItem}
          onClick={handleCreateBlogProject}
          data-testid="blogSelector"
        >
          <BlogWizardIcon />
          Blog Wizard
        </MenuItem>
      </Menu>
    </>
  );
};
