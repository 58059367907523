import { createStateSelectorHook } from 'src/lib/sweetState';
import { PerformanceStore } from './PerformanceStore';

const usePerformanceState = createStateSelectorHook(PerformanceStore, ['lastExtractionDate']);

export const usePerformanceChannelCategoryDates = (channels) => {
  const { lastExtractionDate } = usePerformanceState();

  return channels.map((channel) => lastExtractionDate[channel]).join('_');
};
