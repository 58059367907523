import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { DetectedBrandTermSkeleton, DetectedBrandTerm } from 'src/components/DetectedBrandTerm';
import { InsightBox } from '../../../InsightBox';
import { prepareDetectedTerms } from '../../lib';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  brandVoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  detectedTerms: PropTypes.arrayOf(
    PropTypes.shape({
      brandTerm: PropTypes.shape({}).isRequired,
      termInText: PropTypes.string.isRequired,
    })
  ).isRequired,
  onApplyBrandTerm: PropTypes.func.isRequired,
  onBrandTermHover: PropTypes.func,
  onBrandTermClick: PropTypes.func,
  hoverable: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: null,
  onBrandTermHover: null,
  onBrandTermClick: null,
  hoverable: false,
  loading: false,
  disabled: false,
};

export const BrandTermsItem = ({
  className,
  brandVoice,
  detectedTerms,
  onApplyBrandTerm,
  onBrandTermHover,
  onBrandTermClick,
  hoverable,
  loading,
  disabled,
}) => {
  const styles = useStyles();

  const preparedItems = prepareDetectedTerms(detectedTerms, brandVoice.id);
  if (!preparedItems.length) {
    return null;
  }

  return (
    <InsightBox
      className={cx(styles.root, className)}
      classes={{ title: styles.title }}
      brandVoiceId={brandVoice.id}
    >
      {loading ? (
        <DetectedBrandTermSkeleton />
      ) : (
        preparedItems.map((item, index) => (
          <DetectedBrandTerm
            className={styles.detectedBrandTerm}
            key={index}
            detectedBrandTerm={item}
            onApplyClick={onApplyBrandTerm}
            onHover={onBrandTermHover}
            onClick={onBrandTermClick}
            disabled={disabled}
            hoverable={hoverable}
          />
        ))
      )}
    </InsightBox>
  );
};

BrandTermsItem.propTypes = propTypes;
BrandTermsItem.defaultProps = defaultProps;
