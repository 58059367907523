import React from 'react';
import { Button, DialogContent, IconButton } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useConfirmationDialogActions } from 'src/store/confirmationDialog';
import IconClose from 'src/assets/icons/Close';
import { useStyles } from './useStyles';
import { useViewState } from './useViewState';

export const ConfirmationDialog = () => {
  const {
    open,
    title,
    message,
    buttonCancel,
    buttonOK,
    hideCloseIcon,
  } = useViewState();

  const styles = useStyles();

  const { onCancel, onOK } = useConfirmationDialogActions();

  if (!open) {
    return null;
  }

  return (
    <Dialog
      classes={{ paper: styles.root }}
      open={open}
      onClose={onCancel}
      maxWidth="xs"
      fullWidth
      data-testid="PopupConfirmReactivation"
    >
      <DialogTitle className={styles.dialogTitle} disableTypography>
        <Typography className={styles.title}>{title}</Typography>
      </DialogTitle>

      <DialogContent className={styles.dialogContent}>
        <Typography className={styles.content}>{message}</Typography>
      </DialogContent>

      <DialogActions className={styles.dialogActions}>
        <Button
          className={styles.buttonCancel}
          onClick={onCancel}
          variant="text"
          color="primary"
          aria-label="Cancel"
        >
          {buttonCancel}
        </Button>

        <Button
          className={styles.buttonSubmit}
          onClick={onOK}
          type="submit"
          variant="contained"
          color="primary"
          aria-label="Reactivate"
        >
          {buttonOK}
        </Button>
      </DialogActions>

      {!hideCloseIcon ? (
        <IconButton className={styles.buttonClose} onClick={onCancel}>
          <IconClose />
        </IconButton>
      ) : null}
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {};
ConfirmationDialog.defaultProps = {};
