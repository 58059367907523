import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import round from 'lodash/round';

import CustomLinearProgress from '../CustomLinearProgress';
import useStyles from './useStyles';

const calculateProgress = (used, limit) => {
  if (limit !== 0) {
    return round((used / limit) * 100, 2);
  }
  return 0;
};

export const UsageProgress = ({ className, classes, value, limit }) => {
  const styles = useStyles({ classes });

  const progress = calculateProgress(value, limit);

  return (
    <div className={cx(styles.root, className)}>
      <CustomLinearProgress
        className={styles.linearProgress}
        classes={{ bar: styles.bar }}
        variant="determinate"
        value={Math.min(progress, 100)}
      />
    </div>
  );
};

UsageProgress.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape(),
  value: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};
UsageProgress.defaultProps = {
  className: null,
  classes: null,
};
