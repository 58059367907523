import { useEffect } from 'react';
import { intersection } from 'lodash'; // Import lodash's intersection method

import { isBrandTermActive } from 'src/data/brandTerm';
import { useBrandTermsActions, useBrandVoicesAssets, useCurrentProject } from 'src/store';

export const useMaintainBrandRulesData = () => {
  const brandTermsActions = useBrandTermsActions();
  const { loadingBrandVoiceAssets, brandVoicesAssets } = useBrandVoicesAssets();
  const currentProject = useCurrentProject();

  useEffect(() => {
    if (loadingBrandVoiceAssets) {
      return;
    }

    const brandVoicesIds = brandVoicesAssets.reduce(
      (acc, item) => {
        if (item.brandTerms.filter(isBrandTermActive).length > 0) {
          acc.withBrandRules.push(item.id);
        }
        acc.all.push(item.id);
        return acc;
      },
      { withBrandRules: [], all: [] }
    );

    // If we don't have any brand voice with brand rules, we should disable brand vocabulary
    if (brandVoicesIds.withBrandRules.length === 0) {
      brandTermsActions.setBrandRulesEnabled(false);
      return;
    }

    const selectedIds = intersection(
      currentProject?.brandVoiceIdsForBrandTerms || [],
      brandVoicesIds.withBrandRules
    );

    brandTermsActions.setBrandVoiceIdsForBrandTerms(
      selectedIds.length > 0 ? selectedIds : brandVoicesIds.withBrandRules
    );
  }, [brandTermsActions, loadingBrandVoiceAssets, brandVoicesAssets, currentProject]);
};
