import { createContainer } from 'react-sweet-state';

import { CustomFormulasStore } from './CustomFormulasStore';

export const CustomFormulasStoreContainer = createContainer(CustomFormulasStore, {
  onInit:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
