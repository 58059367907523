import React from 'react';
import PropTypes from 'prop-types';

import { CustomTypography, TYPOGRAPHY_VARIANT } from 'src/components/CustomTypography';
import { useStyles } from './useStyles';

export const EmptyResults = ({ comparisonSetName, onSettingsClick }) => {
  const styles = useStyles();

  return (
    <div>
      {comparisonSetName ? (
        <CustomTypography
          className={styles.subtitle}
          variant={TYPOGRAPHY_VARIANT.regular14}
        >
          We couldn't find any similar text in the benchmark set: <u>{comparisonSetName}.</u>
        </CustomTypography>
      ) : (
        <CustomTypography
          className={styles.subtitle}
          variant={TYPOGRAPHY_VARIANT.regular14}
        >
          We couldn't find any similar texts to benchmark against.
        </CustomTypography>
      )}
      <CustomTypography
        className={styles.change}
        variant={TYPOGRAPHY_VARIANT.regular14}
        onClick={onSettingsClick}
      >
        Change benchmark settings
      </CustomTypography>
    </div>
  );
};

EmptyResults.propTypes = {
  comparisonSetName: PropTypes.string,
  onSettingsClick: PropTypes.func.isRequired,
};
