import * as log from 'src/lib/log';
import * as events from 'src/lib/events';
import { captureMessage } from 'src/lib/sentry';

export const EVENTS = {
  userSignUp: 'user_sign_up',
  userConfirmation: 'user_confirmation',
  googleAuthFirstTime: 'google_auth_first_time',
  customerUpgradeProRequested: 'customer_upgrade_pro_requested',
  customerUpgradeAceRequested: 'customer_upgrade_ace_requested',
  firstQuery: 'first_query',
  firstCopyGeneration: 'first_copy_generation',
  completedPurchase: 'completed_purchase',
  openedCancellationPopup: 'opened_cancellation_popup',
  openedTiersPopup: 'opened_tiers_popup',
  clickedPlanBuyNowButton: 'clicked_plan_buy_now_button',
  completedTierUpgrade: 'completed_tier_upgrade_purchase',
  completedOveragePurchase: 'completed_overage_purchase',
  userTrack: 'user_track',
  projectCreated: 'project_created',
  onboardingCompleted: 'onboarding_completed',
  systemError401: 'system.error_401',
  systemError500: 'system.error_500',
  systemSentryEvent: 'system.sentry_event',
  aiQueryMade: 'ai_query_made',
  rephraseQueryMade: 'rephrase_query_made',
  assetQueryMadePostfix: '_query_made',
  copySavedVariation: 'copy_saved_variation',
  copySavedVariationPostfix: '_copy_saved_variation',
  firstIntegration: 'first_integration',
  secondTargetAudience: '2_target_audiences',
  cipAccountsConnected: 'cip_accounts_connected',
  clickedVariationDetails: 'project_variation_clicked_details',
  clickedRecomposeDetails: 'project_variation_project_variation_clicked_details_recompose',
  addedTalkingPoints: 'document_talking_points_added',
};

let reportedNotLoaded = false;
const reportNotLoaded = (eventName, attributes) => {
  if (reportedNotLoaded) {
    return;
  }

  // Report this event only once per session
  reportedNotLoaded = true;

  log.warning('GTM is not loaded');
  events.trackUserEventNoAuth(events.SYSTEM.gtmInaccessible, {
    eventName,
    attributes,
  });
};

export function pushDataLayerEvent(eventName, attributes = null) {
  // If GTM lib is not loaded - report to BE
  if (!window.google_tag_manager) {
    reportNotLoaded(eventName, attributes);
    return;
  }

  const event = {
    ...attributes,
    event: eventName,
  };

  try {
    window.dataLayer.push(event);
  } catch (error) {
    // In case or error in GTM event - report "warning" to Sentry
    captureMessage('Error while firing GTM event', {
      level: 'warning',
      extra: {
        error: error.toString(),
        source: 'lib/gtm.js -> pushDataLayerEvent',
        eventName,
        attributes,
      },
    });
  }
}
