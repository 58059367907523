import { withStyles, alpha } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 25,
    borderRadius: 32,
  },
  colorPrimary: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  bar: {
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export default CustomLinearProgress;
