import { createContainer } from 'react-sweet-state';

import { PopupStore } from './PopupStore';

export const PopupStoreContainer = createContainer(PopupStore, {
  onInit: () => ({ setState }, { state }) => {
    if (state) {
      setState(state);
    }
  },
  onUpdate: () => ({ setState }, { state }) => {
    if (state) {
      setState(state);
    }
  },
});
