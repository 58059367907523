import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { useStyles } from './useStyles';

export const Score = ({ score }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography className={styles.score}>{score}</Typography>
    </div>
  );
};

Score.propTypes = {
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
