import {
  AD_ASSET_TYPE,
  ASSET_TYPE,
  BLOG_POST_ASSET_TYPE,
  EMAIL_ASSET_TYPE,
  LANDING_ASSET_TYPE,
  SMS_ASSET_TYPE,
  CANVAS_ASSET_TYPE,
} from './const';

export const convertDeprecatedAssetType = (type) => {
  if (type === 'social_copy') {
    return ASSET_TYPE.facebookPrimaryText;
  }
  if (type === 'native_headline') {
    return ASSET_TYPE.outbrainHeadline;
  }
  return type;
};

export const isAdAssetType = (type) => Object.values(AD_ASSET_TYPE).includes(type);

export const isLandingAssetType = (type) => Object.values(LANDING_ASSET_TYPE).includes(type);

export const isEmailAssetType = (type) => Object.values(EMAIL_ASSET_TYPE).includes(type);

export const isBlogPostAssetType = (type) => Object.values(BLOG_POST_ASSET_TYPE).includes(type);

export const isSmsAssetType = (type) => Object.values(SMS_ASSET_TYPE).includes(type);

export const isCanvasAssetType = (type) => Object.values(CANVAS_ASSET_TYPE).includes(type);

export const isCanvasFreestyleAssetType = (type) =>
  type.includes(CANVAS_ASSET_TYPE.canvasFreestyle);

export const assetTypeSupportsFormats = (assetType) => !isEmailAssetType(assetType);

export const isAnnotationsAssetType = (assetType) =>
  ![ASSET_TYPE.landingBuilderCta].includes(assetType);
