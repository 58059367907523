import gql from 'graphql-tag';

export const FRAGMENT_CUSTOM_TONE_OF_VOICE_TOV_TRAIT = gql`
  fragment TovTrait on TovTrait {
    trait
    butNot
  }
`;

export const FRAGMENT_CUSTOM_TONE_OF_VOICE = gql`
  fragment CustomToneOfVoice on CustomToneOfVoice {
    id
    name
    description
    defaultForGenerationTools
    status
    brandVoice {
      id
      name
    }
    traits {
      ...TovTrait
    }
    personality
  }
  ${FRAGMENT_CUSTOM_TONE_OF_VOICE_TOV_TRAIT}
`;

export const FRAGMENT_CUSTOM_TONE_OF_VOICE_INSIGHTS = gql`
  fragment CustomToneOfVoiceInsights on CustomToneOfVoiceInsights {
    trait {
      ...TovTrait
    }
    classification
    reason
  }
  ${FRAGMENT_CUSTOM_TONE_OF_VOICE_TOV_TRAIT}
`;
