import { useCallback } from 'react';

import {
  useConnectToChannel,
  useTrackingEvent,
  useBrandVoices,
  useHistoryPushOrInfinityOpen,
  useEmailConnectedAccounts,
  PAGES,
  useErrorHandler,
} from 'src/services';
import * as events from 'src/lib/events';
import { ROUTES } from 'src/routes';
import { CHANNEL } from 'src/data/channel';
import { CUSTOM_MODEL_STATUS } from 'src/data/customModel';
import {
  useBrandVoicesActions,
  useCustomModelsActions,
  useNotificationActions,
  useProjectActions,
} from 'src/store';
import {
  useAccountExtractionLoaderPopup,
  useBlogCustomModelPopup,
  useConnectToChannelPopup,
  useCustomModelPopup,
  useCustomModelSuccessPopup,
} from 'src/services/popup';
import { getToolChannel } from 'src/data/generationTool';
import { getIsEmailChannel, isBlogUrlsChannel, isMetaChannel } from 'src/data/performance';
import { BRAND_VOICE_ASSET } from 'src/data/common';
import { injectFacebookSDK } from 'src/utils/facebook';

export const useCreateCustomModel = (generationTool, onSelect) => {
  const { brandVoices } = useBrandVoices();
  const brandVoicesActions = useBrandVoicesActions();
  const openCustomModelPopup = useCustomModelPopup(generationTool);
  const openCustomModelSuccessPopup = useCustomModelSuccessPopup();
  const customModelsActions = useCustomModelsActions();
  const notifications = useNotificationActions();
  const trackEvent = useTrackingEvent();
  const projectActions = useProjectActions();
  const { connectToChannel } = useConnectToChannel({
    page: PAGES.editor,
    origin: BRAND_VOICE_ASSET.customModel,
  });
  const handleError = useErrorHandler();
  const historyPushOrInfinityOpen = useHistoryPushOrInfinityOpen();
  const openConnectToChannelPopup = useConnectToChannelPopup();
  const openBlogCustomModelPopup = useBlogCustomModelPopup();
  const openAccountExtractionLoaderPopup = useAccountExtractionLoaderPopup();
  const emailConnectedAccounts = useEmailConnectedAccounts();

  return useCallback(
    async (onToneOfVoicePopupClose) => {
      let channel = getToolChannel(generationTool);

      trackEvent(events.DOCUMENT.clickedOpenCreateCustomModelPopup, {
        page: events.EVENT_PAGE.editor,
        generationTool,
        channel,
      });

      if (brandVoices.length > 1) {
        onToneOfVoicePopupClose?.();
        historyPushOrInfinityOpen(
          `${ROUTES.BRAND_VOICE}/${brandVoices[0].id}${ROUTES.CUSTOM_MODELS}`
        );
        return;
      }

      if (brandVoices.length === 1) {
        const brandVoiceId = brandVoices[0].id;
        const shouldProceed = onToneOfVoicePopupClose ? true : await openCustomModelPopup();

        if (shouldProceed) {
          try {
            const onConnectSuccess = async ({ customModelId, brandVoiceId }) => {
              const customModels = await customModelsActions.queryCustomModels([brandVoiceId]);
              const customModel = customModels.find((cm) => cm.id === customModelId);
              if (customModel?.processingError) {
                notifications.displayError('Failed to create custom model');
              } else if (customModel?.status === CUSTOM_MODEL_STATUS.active) {
                await brandVoicesActions.queryBrandVoicesAssets();
                projectActions.setSelectedCustomModelId(customModel.id);
                onSelect?.(customModelId);
                openCustomModelSuccessPopup({ customModel });
              }
            };
            onToneOfVoicePopupClose?.();

            if (isMetaChannel(channel)) {
              injectFacebookSDK();
            }

            if (channel === CHANNEL.emailChannel && !emailConnectedAccounts.length) {
              const emailChannel = await openConnectToChannelPopup({
                isTrainingModel: true,
                brandVoiceId,
                title: 'Train a custom model',
                subTitle: 'Select the data you want train the model on',
                displayIntegrations: false,
                displayResourcesTemplates: true,
                displayEmailIntegrations: true,
                onConnectSuccess,
              });
              if (!getIsEmailChannel(emailChannel)) {
                return;
              } else {
                channel = emailChannel;
              }
            } else if (isBlogUrlsChannel(channel)) {
              const result = await openBlogCustomModelPopup({ brandVoiceId, onConnectSuccess });

              if (result) {
                openAccountExtractionLoaderPopup({
                  customModelAccount: result,
                  isTrainingModel: true,
                });
              }
              return;
            }

            await connectToChannel({
              channel,
              brandVoiceId,
              isTrainingModel: true,
              emailConnectedAccounts,
              onConnectSuccess,
              connected: !!emailConnectedAccounts.length,
            });
            trackEvent(events.CUSTOM_MODEL.clickedModelTrained, {
              page: events.EVENT_PAGE.editor,
              generationTool,
              channel,
            });
          } catch (error) {
            handleError(error);
          }
        }
      }
    },
    [
      trackEvent,
      brandVoices,
      openCustomModelPopup,
      connectToChannel,
      historyPushOrInfinityOpen,
      brandVoicesActions,
      openCustomModelSuccessPopup,
      notifications,
      projectActions,
      generationTool,
      handleError,
      customModelsActions,
      openConnectToChannelPopup,
      onSelect,
      openBlogCustomModelPopup,
      openAccountExtractionLoaderPopup,
      emailConnectedAccounts,
    ]
  );
};
