/* eslint-disable react/no-array-index-key */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { match, parse } from './utils';
import useStyles from './useStyles';

const ListOption = ({ className, option, inputValue, ...restProps }) => {
  const styles = useStyles();

  const matches = match(option.name, inputValue);
  const parts = parse(option.name, matches);

  return (
    <li
      className={cx(className, styles.root)}
      {...restProps}
    >
      {parts.map((part, index) => (
        <span
          key={index}
          {...(part.highlight ? { 'data-highlight': true } : null)}
        >
          {part.text}
        </span>
      ))}
    </li>
  );
};

ListOption.propTypes = {
  className: PropTypes.string,
  option: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  inputValue: PropTypes.string.isRequired,
};

ListOption.defaultProps = {
  className: null,
};

export default React.memo(ListOption);
