import { useEffect, useRef } from 'react';
import { createPopper } from '@popperjs/core';

export const usePopper = ({ referenceElement, popperElement, options }) => {
  const popperInstanceRef = useRef(null);

  useEffect(() => {
    if (!referenceElement || !popperElement) {
      return undefined;
    }

    popperInstanceRef.current = createPopper(referenceElement, popperElement, options);

    return () => {
      if (popperInstanceRef.current) {
        popperInstanceRef.current.destroy();
      }
    };
  }, [referenceElement, popperElement, options]);

  return popperInstanceRef;
};
