import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';

const MUTATION = gql`
  mutation UpdateProjectPersonas($projectId: ID!, $personaIds: [ID!]!) {
    updateProjectPersonas(projectId: $projectId, personaIds: $personaIds) {
      customerPersonas {
        id
      }
    }
  }
`;

export const updateProjectPersonas = (personaIds, projectId) => {
  const variables = {
    personaIds,
    projectId,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
