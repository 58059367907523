import { useCallback } from 'react';

import { CUSTOMER_PERSONA_POPUP, usePopups } from 'src/services/popup';

export const useCreateOrUpdateCustomerPersona = (isBackButtonShown) => {
  const popups = usePopups();

  return useCallback(
    ({ customerPersona = null, onUpdatedCallback = null, brandVoice }) => {
      popups.openPopup(CUSTOMER_PERSONA_POPUP, {
        customerPersona,
        isBackButtonShown,
        onUpdatedCallback,
        brandVoice,
        onCancel: () => {
          popups.closePopup(CUSTOMER_PERSONA_POPUP);
        },
      });
    },
    [popups, isBackButtonShown]
  );
};
