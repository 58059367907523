import { useMemo } from 'react';

import { createProjectFolderSidebarItem } from '../lib';

export const useProjectFolderItems = (projectFolders) => {
  return useMemo(() => {
    const sidebarFolders = projectFolders.filter((folder) => folder.onSidebar);
    return sidebarFolders.map(createProjectFolderSidebarItem);
  }, [projectFolders]);
};
