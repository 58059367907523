import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip, Typography } from '@material-ui/core';

import { formatNumber, formatPercentage } from 'src/utils/format';
import { DEFAULT_TAXONOMY_NAME } from 'src/data/common';
import Score, { SCORE_SIZE, getScoreProps } from 'src/components/Score';
import { ButtonText } from 'src/components/ButtonText';
import { IconSettings } from 'src/assets/icons/IconSettings';
import { Loader } from './components/Loader';
import { useStyles } from './useStyles';

const MINIMUM_BASED_ON_COUNT = 15;

export const ScoringMethod = ({
  className,
  score,
  basedOnValue,
  loading,
  blocked,
  locked,
  readOnly,
  scoringMethodName,
  onScoringMethodClick,
  displayScoringMethod,
}) => {
  const styles = useStyles();

  const displayedName = scoringMethodName || DEFAULT_TAXONOMY_NAME;
  const similarCopiesFormatted = formatNumber(blocked ? 0 : basedOnValue || 0);

  const { textScore } = getScoreProps(score);

  const basedOnLabel =
    basedOnValue >= MINIMUM_BASED_ON_COUNT
      ? `Better than ${formatPercentage(textScore)} of ${similarCopiesFormatted}`
      : `We didn't find enough`;

  if (loading) {
    return <Loader />;
  }

  const isClickable = onScoringMethodClick && !readOnly && !locked;

  return (
    <div className={cx(styles.root, className)}>
      <div className={styles.container}>
        <Score
          classes={{ score: styles.score }}
          score={score}
          size={SCORE_SIZE.xxxl}
          locked={locked}
          strokeWidth={4}
        />
        {displayScoringMethod && (
          <>
            <Typography className={styles.title}>
              {basedOnLabel} similar texts in Anyword's{' '}
              <Tooltip
                arrow
                title={
                  isClickable
                    ? 'Select the topic or industry most relevant to your product/content. This will help score your copy variations more accurately.'
                    : ''
                }
              >
                <ButtonText
                  className={styles.button}
                  onClick={!readOnly && !locked ? onScoringMethodClick : undefined}
                >
                  {displayedName}
                </ButtonText>
              </Tooltip>{' '}
              data set
            </Typography>
            {isClickable && (
              <Tooltip title={''}>
                <IconSettings
                  className={styles.settingsIcon}
                  onClick={!readOnly && !locked ? onScoringMethodClick : undefined}
                />
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ScoringMethod.propTypes = {
  className: PropTypes.string,
  score: PropTypes.number,
  basedOnValue: PropTypes.number,
  loading: PropTypes.bool,
  blocked: PropTypes.bool,
  locked: PropTypes.bool,
  readOnly: PropTypes.bool,
  scoringMethodName: PropTypes.string,
  onScoringMethodClick: PropTypes.func,
  displayScoringMethod: PropTypes.bool,
};

ScoringMethod.defaultValues = {
  className: null,
  score: null,
  basedOnValue: null,
  loading: null,
  blocked: null,
  locked: null,
  readOnly: null,
  scoringMethodName: null,
  onScoringMethodClick: null,
  displayScoringMethod: false,
};
