import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const PAS = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 30 30" data-fill-none>
    <rect x="3" y="3" width="24" height="24" rx="4" fill="#28354D" />
    <path
      d="M16.0208 8.19727H7.51172V23.2133C7.51172 23.2133 15.5326 23.2133 21.6935 23.2133V16.4769"
      stroke="white"
      strokeWidth="1.12273"
    />
    <path
      d="M9.75781 15.3535H13.5434"
      stroke="white"
      strokeWidth="1.12273"
      strokeLinejoin="round"
    />
    <path
      d="M9.75781 17.5996H17.0135"
      stroke="white"
      strokeWidth="1.12273"
      strokeLinejoin="round"
    />
    <path
      d="M9.75781 19.8447H17.0135"
      stroke="white"
      strokeWidth="1.12273"
      strokeLinejoin="round"
    />
    <path
      d="M20.9401 11.2816H18.7511V10.1417H20.9401C21.3213 10.1417 21.6294 10.0806 21.8642 9.95839C22.0991 9.83618 22.2705 9.66816 22.3783 9.4543C22.4899 9.23663 22.5458 8.98841 22.5458 8.70964C22.5458 8.44614 22.4899 8.19983 22.3783 7.9707C22.2705 7.73776 22.0991 7.55063 21.8642 7.40934C21.6294 7.26804 21.3213 7.1974 20.9401 7.1974H19.1958V14.392H17.7461V6.05176H20.9401C21.5908 6.05176 22.1434 6.16632 22.5978 6.39545C23.056 6.62076 23.4044 6.9339 23.6432 7.33487C23.8819 7.73203 24.0013 8.18646 24.0013 8.69818C24.0013 9.23663 23.8819 9.69871 23.6432 10.0844C23.4044 10.4701 23.056 10.7661 22.5978 10.9723C22.1434 11.1785 21.5908 11.2816 20.9401 11.2816Z"
      fill="white"
    />
  </SvgIcon>
));

export default React.memo(PAS);
