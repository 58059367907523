import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: '100%',
      maxWidth: '100%',

      display: 'flex',
      flexFlow: 'column nowrap',

      padding: theme.spacing(2, 1.5),
      backgroundColor: COLORS.neutral.white,
      border: theme.shape.borderGray70,
      borderRadius: theme.shape.borderRadius,

      '&$hoverable': {
        transition: theme.transitions.create(['border-color'], {
          duration: theme.transitions.duration.short,
        }),

        '&:not($disabled):hover': {
          borderColor: COLORS.primary.default,
        },
      },
    },
    hoverable: {},
    disabled: {},
    hasReplacements: {
      padding: theme.spacing(0.75, 0.5, 2, 1.5),
    },

    header: {},
    replacementTerms: {
      marginLeft: theme.spacing(1.75),
    },
  }),
  { name: 'DetectedBrandTerm' }
);
