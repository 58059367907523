import { createContainer } from 'react-sweet-state';

import { PerformanceStore, ACTIONS } from './PerformanceStore';

export const PerformanceStoreContainer = createContainer(PerformanceStore, {
  onInit:
    () =>
    ({ setState, dispatch }, { state }) => {
      dispatch(ACTIONS.init());

      if (state) {
        setState(state);
      }
    },
  onUpdate:
    () =>
    ({ setState }, { state }) => {
      if (state) {
        setState(state);
      }
    },
});
