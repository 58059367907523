import React, { forwardRef, memo } from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconCampaigns = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
        data-fill-none
      >
        <path
          d="M4.79721 7.95779C4.79721 7.72967 4.88783 7.51089 5.04914 7.34958C5.21045 7.18828 5.42922 7.09766 5.65735 7.09766H18.5594C18.7875 7.09766 19.0063 7.18828 19.1676 7.34958C19.3289 7.51089 19.4195 7.72967 19.4195 7.95779V15.699C19.4195 15.9271 19.3289 16.1459 19.1676 16.3072C19.0063 16.4685 18.7875 16.5591 18.5594 16.5591H5.65735C5.42922 16.5591 5.21045 16.4685 5.04914 16.3072C4.88783 16.1459 4.79721 15.9271 4.79721 15.699V7.95779Z"
          fill="#C2F3FF"
        />
        <path
          d="M2.21689 11.3982C2.21689 11.1701 2.30751 10.9513 2.46882 10.79C2.63012 10.6287 2.8489 10.5381 3.07702 10.5381H15.979C16.2072 10.5381 16.4259 10.6287 16.5873 10.79C16.7486 10.9513 16.8392 11.1701 16.8392 11.3982V19.1394C16.8392 19.3676 16.7486 19.5863 16.5873 19.7476C16.4259 19.9089 16.2072 19.9996 15.979 19.9996H3.07702C2.8489 19.9996 2.63012 19.9089 2.46882 19.7476C2.30751 19.5863 2.21689 19.3676 2.21689 19.1394V11.3982Z"
          fill="#C2F3FF"
        />
        <path
          d="M5.65735 7.09766C5.42922 7.09766 5.21045 7.18828 5.04914 7.34958C4.88783 7.51089 4.79721 7.72967 4.79721 7.95779V15.699C4.79721 15.9271 4.88783 16.1459 5.04914 16.3072C5.21045 16.4685 5.42922 16.5591 5.65735 16.5591H18.5594C18.7875 16.5591 19.0063 16.4685 19.1676 16.3072C19.3289 16.1459 19.4195 15.9271 19.4195 15.699V14.8389"
          stroke="#191919"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.05661 4.79373C7.05661 4.56561 7.14723 4.34683 7.30854 4.18552C7.46984 4.02421 7.68862 3.93359 7.91675 3.93359H20.8188C21.0469 3.93359 21.2657 4.02421 21.427 4.18552C21.5883 4.34683 21.6789 4.56561 21.6789 4.79373V12.5349C21.6789 12.7631 21.5883 12.9818 21.427 13.1431C21.2657 13.3045 21.0469 13.3951 20.8188 13.3951H7.91675C7.68862 13.3951 7.46984 13.3045 7.30854 13.1431C7.14723 12.9818 7.05661 12.7631 7.05661 12.5349V4.79373Z"
          fill="#66E1FF"
        />
        <path
          d="M7.37766 4.51834C7.37766 4.29022 7.46828 4.07144 7.62958 3.91013C7.79089 3.74882 8.00967 3.6582 8.23779 3.6582H21.1398C21.3679 3.6582 21.5867 3.74882 21.748 3.91013C21.9093 4.07144 21.9999 4.29022 21.9999 4.51834V12.2596C21.9999 12.4877 21.9093 12.7065 21.748 12.8678C21.5867 13.0291 21.3679 13.1197 21.1398 13.1197H8.23779C8.00967 13.1197 7.79089 13.0291 7.62958 12.8678C7.46828 12.7065 7.37766 12.4877 7.37766 12.2596V4.51834Z"
          stroke="#191919"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.07702 10.5381C2.8489 10.5381 2.63012 10.6287 2.46882 10.79C2.30751 10.9513 2.21689 11.1701 2.21689 11.3982V19.1394C2.21689 19.3676 2.30751 19.5863 2.46882 19.7476C2.63012 19.9089 2.8489 19.9996 3.07702 19.9996H15.979C16.2072 19.9996 16.4259 19.9089 16.5873 19.7476C16.7486 19.5863 16.8392 19.3676 16.8392 19.1394V18.2793"
          stroke="#191919"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </SvgIcon>
    );
  })
);
