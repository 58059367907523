import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(
  (theme) => ({
    root: {
      fontSize: theme.typography.pxToRem(30),
    },
  }),
  { name: 'ChannelIcon' }
);
