import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_PROJECT_FOLDER } from './schema';

const MUTATION = gql`
  mutation CreateProjectFolder($workspaceId: ID!, $name: String!) {
    createProjectFolder(workspaceId: $workspaceId, name: $name) {
      ...ProjectFolder
    }
  }
  ${FRAGMENT_PROJECT_FOLDER}
`;

export const createProjectFolder = ({ workspaceId, name }) => {
  const variables = {
    workspaceId,
    name,
  };

  return executeGqlMutation({
    mutation: MUTATION,
    variables,
  }).then(({ data }) => getSingleProp(data));
};
