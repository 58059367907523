import {
  CHANNEL,
  EMAILS_CHANNEL,
  REVIEWS_CHANNEL,
  WEBSITE_CHANNEL,
  PLATFORM_CHANNEL,
} from 'src/data/channel';
import { GENERATION_TOOL } from 'src/data/generationTool';
import { EMAIL_CSV_RESOURCES, RESOURCE_DOCUMENT } from './resources';

export const ADS_CHANNELS = [CHANNEL.google, CHANNEL.facebook, CHANNEL.linkedin];
export const REVIEWS_CHANNELS = [REVIEWS_CHANNEL.amazon, REVIEWS_CHANNEL.trustpilot];
export const WEBSITE_CHANNELS = Object.values(WEBSITE_CHANNEL);

export const URL_ACCOUNTS = [...REVIEWS_CHANNELS, ...WEBSITE_CHANNELS];
export const EMAILS_CHANNELS = Object.values(EMAILS_CHANNEL);
export const SOCIAL_CHANNELS = [CHANNEL.linkedinSocial, CHANNEL.facebookPages, CHANNEL.instagram];
export const PLATFORM_CHANNELS = Object.values(PLATFORM_CHANNEL);

export const RESOURCE_DOCUMENTS = Object.values(RESOURCE_DOCUMENT);

export const PERFORMANCE_CHANNELS = [
  ...WEBSITE_CHANNELS,
  ...REVIEWS_CHANNELS,
  ...ADS_CHANNELS,
  ...SOCIAL_CHANNELS,
  ...EMAILS_CHANNELS,
  ...RESOURCE_DOCUMENTS,
];

export const INTEGRATION_CHANNELS = [
  ...EMAILS_CHANNELS,
  ...ADS_CHANNELS,
  ...SOCIAL_CHANNELS,
  CHANNEL.twitterSocial,
  PLATFORM_CHANNEL.projectWtm,
];

export const RESOURCE_CHANNELS = [...WEBSITE_CHANNELS, ...REVIEWS_CHANNELS];

export const RESOURCES = [...RESOURCE_CHANNELS, ...RESOURCE_DOCUMENTS];

export const ACCOUNT_FIELD = {
  toggle: 'toggle',
  id: 'id',
  name: 'name',
  connectedState: 'connectedState',
};

export const RESOURCE_TABLE_FIELDS = {
  name: 'name',
  tags: 'tags',
  menu: 'menu',
};

export const COPY_FIELD = {
  boost: 'boost',
  text: 'text',
  textType: 'textType',
  keywords: 'keywords',
  startDate: 'startDate',
  sourceAnyword: 'sourceAnyword',
  numberOfInstances: 'numberOfInstances',
  impressions: 'impressions',
  weightedCtr: 'weightedCtr',
  campaignNames: 'campaignNames',
  improve: 'improve',
  lifetimeConversions: 'lifetimeConversions',
  opens: 'opens',
  shares: 'shares',
  reactions: 'reactions',
  clicks: 'clicks',
  likes: 'likes',
  engagement: 'engagement',
  comments: 'comments',
  likesCount: 'like_count',
  quoteCount: 'quote_count',
  replyCount: 'reply_count',
  retweetCount: 'retweet_count',
  bookmarkCount: 'bookmark_count',
  impressionCount: 'impression_count',
};

export const TALKING_POINT_FIELD = {
  talkingPoint: 'talkingPoint',
  rating: 'rating',
  impressions: 'impressions',
  numberOfInstances: 'numberOfInstances',
  textType: 'textType',
};

export const TALKING_POINTS_VIEW_STATUS = {
  created: 'created',
  active: 'active',
  archived: 'archived',
};

export const CIP_ADS_PER_PAGE = 25;

export const CONNECT_TO_CHANNEL_ORIGIN = {
  integrationsAndResources: 'integrationsAndResources',
  benchmark: 'benchmark',
  copyAnalytics: 'copyAnalytics',
  customModels: 'customModels',
  onboarding: 'onboarding',
};

export const CHANNEL_TO_GENERATION_TOOL_MAPPER = {
  [CHANNEL.facebookPages]: GENERATION_TOOL.facebookPost,
  [CHANNEL.google]: GENERATION_TOOL.googleDescriptionProduct,
  [CHANNEL.facebook]: GENERATION_TOOL.facebookMixtureAd,
  [CHANNEL.linkedin]: GENERATION_TOOL.linkedinMixtureAd,
  [CHANNEL.hubspot]: GENERATION_TOOL.emailMixture,
  [CHANNEL.instagram]: GENERATION_TOOL.instagramCaption,
  [CHANNEL.linkedinSocial]: GENERATION_TOOL.linkedinPost,
  [RESOURCE_DOCUMENT.emailSubjectLineCsvResource]: GENERATION_TOOL.emailSubject,
  [RESOURCE_DOCUMENT.emailPromotionCsvResource]: GENERATION_TOOL.emailMixture,
  [RESOURCE_DOCUMENT.emailColdCsvResource]: GENERATION_TOOL.emailMixture,
  [RESOURCE_DOCUMENT.emailCsvResource]: GENERATION_TOOL.emailMixture,
  [CHANNEL.emailChannel]: GENERATION_TOOL.emailMixture,
};

export const EMAILS_CHANNELS_AND_RESOURCES = [...EMAIL_CSV_RESOURCES, ...EMAILS_CHANNELS];
