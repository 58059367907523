import { useCallback } from 'react';
import { Typography } from '@material-ui/core';

import {
  useCustomerActions,
  useNotificationActions,
  useWorkspaceActions,
  useIsCurrentUserAdmin,
} from 'src/store';
import { RESTRICT_ACTION_TYPE, useRestrictActionPopup, useErrorHandler } from 'src/services';
import { CONFIRM_POPUP, usePopups } from 'src/services/popup';
import { CONFIRM_POPUP_MODE } from 'src/components/ConfirmPopup';

export const useDeleteWorkspace = () => {
  const popups = usePopups();
  const customerActions = useCustomerActions();
  const workspaceActions = useWorkspaceActions();
  const notificationActions = useNotificationActions();
  const handleError = useErrorHandler();
  const openRestrictActionPopup = useRestrictActionPopup();
  const isCurrentUserAdmin = useIsCurrentUserAdmin();

  return useCallback(
    (workspace) => {
      const currentWorkspaces = workspaceActions.getCurrentWorkspaces();
      if (!isCurrentUserAdmin) {
        openRestrictActionPopup({ actionType: RESTRICT_ACTION_TYPE.memberRestrictAccess });
        return;
      }
      if (currentWorkspaces.length === 1) {
        openRestrictActionPopup({ actionType: RESTRICT_ACTION_TYPE.deleteLastWorkspace });
        return;
      }
      const onConfirm = async () => {
        popups.closePopup(CONFIRM_POPUP);

        try {
          await workspaceActions.deleteWorkspace(workspace.id);
          notificationActions.displaySuccess(`Workspace '${workspace.name}' deleted`);
          customerActions.refreshCurrentCustomerFeatureLimit();
        } catch (error) {
          handleError(error);
        }
      };

      const onCancel = () => {
        popups.closePopup(CONFIRM_POPUP);
      };

      popups.openPopup(CONFIRM_POPUP, {
        title: 'Delete Workspace',
        content: (
          <Typography>
            Are you sure you want to delete <b>{workspace.name}</b> workspace?
            <br />
            All generated and saved content in this workspace will be lost.
          </Typography>
        ),
        confirm: 'Delete workspace',
        cancel: 'Cancel',
        mode: CONFIRM_POPUP_MODE.warning,
        onConfirm,
        onCancel,
      });
    },
    [
      isCurrentUserAdmin,
      openRestrictActionPopup,
      popups,
      workspaceActions,
      notificationActions,
      customerActions,
      handleError,
    ]
  );
};
