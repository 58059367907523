import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Skeleton } from '@material-ui/lab';

import { CustomButtonBase } from 'src/components/CustomButtonBase';
import { ChevronIcon } from 'src/components/ChevronIcon';
import { ROTATE_DIRECTION } from 'src/data/ui';
import { STONLY_ID } from 'src/lib/stonly';
import { WorkspaceName } from '../WorkspaceName';
import { useStyles } from './useStyles';

const propTypes = {
  className: PropTypes.string,
  currentWorkspace: PropTypes.shape({}),
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  className: null,
  open: false,
  loadingWorkspaces: false,
};

export const WorkspaceButton = ({ className, currentWorkspace, open, onClick, loading }) => {
  const styles = useStyles();

  const workspaceName = currentWorkspace?.name || '';

  if (loading) {
    return (
      <Skeleton
        className={cx(styles.root, className, styles.skeleton)}
        variant="rect"
      />
    );
  }

  return (
    <CustomButtonBase
      className={cx(styles.root, className, {
        [styles.expanded]: open,
      })}
      onClick={onClick}
      data-testid="workspaceButton"
      data-onboarding="WorkspaceButton"
      data-stonly-id={STONLY_ID.workspaceButton}
    >
      <WorkspaceName
        className={styles.label}
        name={workspaceName}
      />

      <ChevronIcon
        className={styles.iconExpand}
        direction={open ? ROTATE_DIRECTION.up : ROTATE_DIRECTION.down}
      />
    </CustomButtonBase>
  );
};

WorkspaceButton.displayName = 'WorkspaceButton';
WorkspaceButton.propTypes = propTypes;
WorkspaceButton.defaultProps = defaultProps;
