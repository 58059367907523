import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import { CustomAlert } from 'src/components/CustomAlert';
import { isLocalhost } from 'src/lib/url';
import { useCurrentUser, useNotificationActions } from 'src/store';
import { useIsApplicationModeInfinity } from 'src/services';
import { checkRemoteTag } from './utils';
import useViewState from './useViewState';
import useStyles from './useStyles';

const INTERVAL_CHECK = 60 * 1000; // 1 minute

const propTypes = {
  classes: PropTypes.shape({}),
};

const defaultProps = {
  classes: null,
};

const BuildRelevanceChecker = ({ classes }) => {
  const styles = useStyles({ classes });

  const isAppModeInfinity = useIsApplicationModeInfinity();
  const { upgradeAvailable } = useViewState();
  const notificationActions = useNotificationActions();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (isLocalhost(window.location.href) || !currentUser) {
      return undefined;
    }

    const check = () => {
      checkRemoteTag((remoteTagIsSame) => {
        if (remoteTagIsSame) {
          return;
        }

        // Do not display the "upgrade available" if the Platform is in "infinity" mode
        if (!isAppModeInfinity) {
          notificationActions.displayUpgradeAvailable();
        }
      });
    };

    check();
    const interval = setInterval(check, INTERVAL_CHECK);

    return () => clearInterval(interval);
  }, [notificationActions, isAppModeInfinity, currentUser]);

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  if (!currentUser) {
    return null;
  }

  return (
    <Snackbar
      open={upgradeAvailable}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      data-testid="BuildRelevanceChecker"
    >
      <CustomAlert
        title="New version available!"
        severity="success"
        action={
          <Button
            className={styles.buttonUpdate}
            onClick={handleReload}
            color="inherit"
            size="small"
            data-testid="BuildRelevanceChecker__UpdateButton"
          >
            Update
          </Button>
        }
      />
    </Snackbar>
  );
};

BuildRelevanceChecker.propTypes = propTypes;
BuildRelevanceChecker.defaultProps = defaultProps;

export default BuildRelevanceChecker;
