import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';
import { CUSTOM_BUTTON_VARIANT, CUSTOM_BUTTON_COLOR, CUSTOM_BUTTON_SIZE } from './const';

export const useStyles = makeStyles(
  (theme) => ({
    root: (props) => ({
      minWidth: 75,
      height: 'auto',

      ...calculateStyles(theme, props),
    }),
    loading: {},
    disabled: {},
  }),
  { name: 'CustomButton' }
);

const calculatePadding = (theme, size) => {
  switch (size) {
    case CUSTOM_BUTTON_SIZE.large:
      return theme.spacing(1.625, 1.5);
    case CUSTOM_BUTTON_SIZE.small:
      return theme.spacing(1, 1.5);
    case CUSTOM_BUTTON_SIZE.smaller:
      return theme.spacing(0.75, 1.5);

    case CUSTOM_BUTTON_SIZE.medium:
    default:
      return theme.spacing(1.5, 1.5);
  }
};

const calculateFontStyles = (theme, size) => {
  switch (size) {
    case CUSTOM_BUTTON_SIZE.large:
      return theme.typography.medium16;
    case CUSTOM_BUTTON_SIZE.small:
      return theme.typography.medium14;
    case CUSTOM_BUTTON_SIZE.smaller:
      return theme.typography.medium12;

    case CUSTOM_BUTTON_SIZE.medium:
    default:
      return theme.typography.medium14;
  }
};

const VARIANT_STYLES = {
  [CUSTOM_BUTTON_VARIANT.contained]: {
    color: COLORS.neutral.white,

    '&[disabled]': {
      backgroundColor: COLORS.gray.gray90,
      color: COLORS.gray.gray50,
    },
  },
  [CUSTOM_BUTTON_VARIANT.outlined]: {
    backgroundColor: COLORS.gray.gray90,

    '&[disabled]': {
      backgroundColor: COLORS.gray.gray90,
      color: COLORS.gray.gray50,
    },
  },

  [CUSTOM_BUTTON_VARIANT.text]: {
    backgroundColor: 'transparent',

    '&[disabled]': {
      color: COLORS.gray.gray50,
    },
  },
};

const VARIANT_COLOR_STYLES = {
  // Contained
  [CUSTOM_BUTTON_VARIANT.contained]: {
    [CUSTOM_BUTTON_COLOR.gray]: {
      color: COLORS.neutral.white,
      backgroundColor: COLORS.gray.gray40,
      '&:hover': {
        backgroundColor: COLORS.gray.gray30,
      },
    },
    [CUSTOM_BUTTON_COLOR.primary]: {
      color: COLORS.neutral.white,
      backgroundColor: COLORS.primary.default,
      '&:hover': {
        backgroundColor: COLORS.primary.active,
      },
    },
    [CUSTOM_BUTTON_COLOR.upsell]: {
      color: COLORS.neutral.white,
      backgroundColor: COLORS.success.default,
      '&:hover': {
        backgroundColor: COLORS.success.active,
      },
    },
    [CUSTOM_BUTTON_COLOR.danger]: {
      color: COLORS.neutral.white,
      backgroundColor: COLORS.error.default,
      '&:hover': {
        backgroundColor: COLORS.error.active,
      },
    },
    [CUSTOM_BUTTON_COLOR.silver]: {
      color: COLORS.primary.default,
      backgroundColor: COLORS.primary.silver,
      '&:hover': {
        backgroundColor: COLORS.primary.medium,
      },
    },
  },
  // Outlined
  [CUSTOM_BUTTON_VARIANT.outlined]: {
    [CUSTOM_BUTTON_COLOR.gray]: {
      color: COLORS.gray.black,
      '&:hover': {
        backgroundColor: COLORS.gray.gray80,
      },
    },
    [CUSTOM_BUTTON_COLOR.primary]: {
      color: COLORS.primary.default,
      '&:hover': {
        backgroundColor: COLORS.primary.silver,
      },
    },
    [CUSTOM_BUTTON_COLOR.upsell]: {
      color: COLORS.success.default,
      '&:hover': {
        backgroundColor: COLORS.success.light,
      },
    },
    [CUSTOM_BUTTON_COLOR.danger]: {
      color: COLORS.error.default,
      '&:hover': {
        backgroundColor: COLORS.error.light,
      },
    },
    [CUSTOM_BUTTON_COLOR.silver]: {
      color: COLORS.primary.default,
      '&:hover': {
        backgroundColor: COLORS.error.silver,
      },
    },
  },
  // Text
  [CUSTOM_BUTTON_VARIANT.text]: {
    [CUSTOM_BUTTON_COLOR.gray]: {
      color: COLORS.gray.black,

      '&:hover': {
        backgroundColor: COLORS.gray.gray80,
      },
    },
    [CUSTOM_BUTTON_COLOR.primary]: {
      color: COLORS.primary.default,

      '&:hover': {
        backgroundColor: COLORS.primary.silver,
      },
    },
    [CUSTOM_BUTTON_COLOR.upsell]: {
      color: COLORS.success.default,

      '&:hover': {
        backgroundColor: COLORS.success.light,
      },
    },
    [CUSTOM_BUTTON_COLOR.danger]: {
      color: COLORS.error.default,

      '&:hover': {
        backgroundColor: COLORS.error.light,
      },
    },
    [CUSTOM_BUTTON_COLOR.silver]: {
      color: COLORS.primary.default,

      '&:hover': {
        backgroundColor: COLORS.error.silver,
      },
    },
  },
};

const getVariantColorStyles = (variant, color) => ({
  ...VARIANT_STYLES[variant],
  ...VARIANT_COLOR_STYLES[variant]?.[color],
});

/**
 * Dynamically calculate styles, so it will be easier to override them in-place
 * @param theme
 * @param props
 * @returns {{}}
 */
const calculateStyles = (theme, props) => {
  // Padding
  const padding = calculatePadding(theme, props.size);

  // Sizes
  const fontStyles = calculateFontStyles(theme, props.size);

  // Variants + Colors
  const variantColorStyles = getVariantColorStyles(props.variant, props.color);

  return {
    padding,
    ...fontStyles,
    ...variantColorStyles,
  };
};
