/**
 * Social channels
 * @enum {string}
 * @readonly
 */
export const SOCIAL_CHANNEL = {
  facebook: 'facebook',
  instagram: 'instagram',
  linkedin: 'linkedin',
  linkedinSocial: 'linkedin_social',
  twitter: 'twitter',
  google: 'google',
  youtube: 'youtube',
  pinterest: 'pinterest',
  facebookPages: 'facebook_pages',
  twitterSocial: 'twitter_social',
};

/**
 * Native channels
 * @enum {string}
 * @readonly
 */
export const NATIVE_CHANNEL = {
  taboola: 'taboola',
  outbrain: 'outbrain',
  revcontent: 'revcontent',
  verizon: 'verizon',
  amazon: 'amazon',
  walmart: 'walmart',
  teads: 'teads',
};

/**
 * Reviews channels
 * @enum {string}
 * @readonly
 */
export const REVIEWS_CHANNEL = {
  trustpilot: 'trustpilot',
  amazon: 'amazon',
  g2: 'g2',
};

/**
 * Website channels
 * @enum {string}
 * @readonly
 */
export const WEBSITE_CHANNEL = {
  homepage: 'home_page',
  productPage: 'product_page',
  landingPage: 'landing_page',
  competitors: 'competitors',
  thoughtLeadership: 'thought_leadership',
  other: 'other',
};

/**
 * Emails channels
 * @enum {string}
 * @readonly
 */
export const EMAILS_CHANNEL = {
  hubspot: 'hubspot',
  salesforce: 'salesforce',
  marketo: 'marketo',
  braze: 'braze',
};

/**
 * Native Platform channels
 * @enum {string}
 * @readonly
 */
export const PLATFORM_CHANNEL = {
  projectWtm: 'project_wtm',
  blogUrls: 'blog_urls',
  generalDocument: 'general_document',
  emailChannel: 'email_channel',
  general: 'general',
};

/**
 * Channels combined enum
 * @enum {string}
 * @readonly
 */
export const CHANNEL = {
  ...SOCIAL_CHANNEL,
  ...NATIVE_CHANNEL,
  ...REVIEWS_CHANNEL,
  ...WEBSITE_CHANNEL,
  ...EMAILS_CHANNEL,
  ...PLATFORM_CHANNEL,
};

const CHANNEL_PROPS = {
  [CHANNEL.facebook]: {
    label: 'Facebook',
    performanceLabel: 'Meta ads',
    talkingPointLabel: 'Meta ads',
    performanceShortLabel: 'Meta',
    description:
      'Connect your Meta ads account and Anyword will learn to write ads customized to your writing style. Historical data from your account will be used to help create new highly converting ads as well as improve existing running ads.',
  },
  [CHANNEL.facebookPages]: {
    label: 'Facebook',
    performanceLabel: 'Facebook page',
    talkingPointLabel: 'Facebook page',
    performanceShortLabel: 'Facebook',
    description:
      'Connect your Facebook page, and Anyword will learn your writing style. Historical data will also be used to improve the effectiveness of newly created content.',
  },
  [CHANNEL.instagram]: {
    label: 'Instagram',
    performanceLabel: 'Instagram account',
    talkingPointLabel: 'Instagram',
    performanceShortLabel: 'Instagram',
    description:
      'Learn what talking points actually worked in your Instagram captions and train custom models that generate high performing posts that are personalized for your business.',
  },
  [CHANNEL.twitter]: {
    label: 'X',
  },

  [CHANNEL.twitterSocial]: {
    label: 'X',
    performanceLabel: 'X (Twitter) account',
    talkingPointLabel: 'X (Twitter)',
    performanceShortLabel: 'X',
    description:
      'Connect your X page, and Anyword will learn your writing style. Historical data will also be used to improve the effectiveness of newly created content.',
  },
  [CHANNEL.pinterest]: {
    label: 'Pinterest',
  },
  [CHANNEL.linkedin]: {
    label: 'LinkedIn',
    performanceLabel: 'LinkedIn ads',
    talkingPointLabel: 'LinkedIn ads',
    performanceShortLabel: 'LinkedIn',
    description:
      'Connect your LinkedIn page, and Anyword will learn your writing style. Historical data will also be used to improve the effectiveness of newly created content.',
  },
  [CHANNEL.linkedinSocial]: {
    label: 'LinkedIn',
    performanceLabel: 'LinkedIn page',
    talkingPointLabel: 'LinkedIn page',
    performanceShortLabel: 'LinkedIn',
    description:
      'Connect your LinkedIn page, and Anyword will learn your writing style. Historical data will also be used to improve the effectiveness of newly created content.',
  },
  [CHANNEL.google]: {
    label: 'Google',
    performanceLabel: 'Google ads',
    talkingPointLabel: 'Google ads',
    performanceShortLabel: 'Google',
    description:
      'Historical data from your ad account will be used to help create new highly converting ads as well as improve existing running ads.',
  },
  [CHANNEL.youtube]: {
    label: 'YouTube',
  },
  [CHANNEL.taboola]: {
    label: 'Taboola',
  },
  [CHANNEL.outbrain]: {
    label: 'Outbrain',
  },
  [CHANNEL.revcontent]: {
    label: 'Revcontent',
  },
  [CHANNEL.teads]: {
    label: 'Teads',
  },
  [CHANNEL.verizon]: {
    label: 'Verizon Media',
  },
  [CHANNEL.amazon]: {
    label: 'Amazon',
  },
  [CHANNEL.walmart]: {
    label: 'Walmart',
  },
  [CHANNEL.trustpilot]: {
    label: 'Trustpilot',
    talkingPointLabel: 'Webpage',
    performanceLabel: 'Trustpilot reviews',
    description:
      'Make sure your messaging reflects the voice of your most satisfied customers by importing reviews and high-performing talking points.',
  },
  [CHANNEL.amazon]: {
    label: 'Amazon',
    talkingPointLabel: 'Webpage',
    performanceLabel: 'Amazon reviews',
    description:
      'Make sure your messaging reflects the voice of your most satisfied customers by importing reviews and high-performing talking points.',
  },
  [CHANNEL.g2]: {
    label: 'G2',
    performanceLabel: 'G2 reviews',
    description: '',
  },
  [CHANNEL.homepage]: {
    label: 'Homepage',
    talkingPointLabel: 'Webpage',
    description:
      'Connect your homepage for more effective, on-brand content generation. Use it as a source to set your brand voice and access talking points that resonate most with your audience.',
  },
  [CHANNEL.landingPage]: {
    label: 'Landing page',
    talkingPointLabel: 'Webpage',
    description:
      'Connect your landing pages for more effective, on-brand content generation. Use it as a source to set your brand voice and access talking points that resonate most with your audience.',
  },
  [CHANNEL.competitors]: {
    label: 'Competitor',
    talkingPointLabel: 'Webpage',
    performanceLabel: 'Competitor website',
    description:
      'Uncover your competitors brand voice and discover their most impactful words for a better understanding.',
  },
  [CHANNEL.thoughtLeadership]: {
    label: 'Thought leadership',
    talkingPointLabel: 'Webpage',
    description:
      'Get powerful talking points from industry thought leadership (articles, blogs...) to make your content more engaging.',
  },
  [CHANNEL.productPage]: {
    label: 'Product information',
    talkingPointLabel: 'Webpage',
    description:
      'Get powerful talking points from industry product page (articles, blogs...) to make your content more engaging.',
  },
  [CHANNEL.other]: {
    label: 'Webpage',
    performanceLabel: 'Webpage',
    talkingPointLabel: 'Webpage',
    performanceShortLabel: 'Webpage',
    description: '',
  },
  [CHANNEL.hubspot]: {
    label: 'HubSpot',
    performanceLabel: 'HubSpot',
    description:
      "Connect your HubSpot account, and Anyword will learn to write emails tailored to your brand voice. We'll also use your historical data to optimize existing emails and enhance their performance.",
    talkingPointLabel: 'HubSpot',
  },
  [CHANNEL.salesforce]: {
    label: 'Salesforce Marketing Cloud',
    performanceLabel: 'Salesforce Marketing Cloud',
    description:
      "Connect your Salesforce account, and Anyword will learn to write emails tailored to your brand voice. We'll also use your historical data to optimize existing emails and enhance their performance.",
    talkingPointLabel: 'Salesforce Marketing Cloud',
  },
  [CHANNEL.marketo]: {
    label: 'Marketo',
    performanceLabel: 'Marketo',
    description:
      "Connect your Marketo account, and Anyword will learn to write emails tailored to your brand voice. We'll also use your historical data to optimize existing emails and enhance their performance.",
    talkingPointLabel: 'Marketo',
  },
  [CHANNEL.braze]: {
    label: 'Braze',
    performanceLabel: 'Braze',
    description:
      'Learn what talking points actually worked in your emails and train custom models that generate high performing emails that are personalized for your business.',
    talkingPointLabel: 'Braze',
  },
  [CHANNEL.projectWtm]: {
    label: 'Website',
    description:
      'Analyze your website content with instant predictive performance scores. Add Anyword JS to your page to test and improve copy in real-time.',
  },
  [CHANNEL.blogUrls]: {
    label: 'Blog posts and articles',
  },
  [CHANNEL.generalDocument]: {
    label: 'PDF document',
    talkingPointLabel: 'PDF',
  },
  [CHANNEL.emailChannel]: {
    label: 'Email',
    performanceLabel: 'Email performance report (CSV)',
    talkingPointLabel: 'Email performance report (CSV)',
    description:
      "Upload your email campaign performance data from any third-party platform, and Anyword will learn to write emails tailored to your brand voice. We'll also use your historical data to optimize existing emails and enhance their performance.",
  },
  [CHANNEL.general]: {
    label: 'General',
  },
};

export const getChannelLabel = (channel) => {
  const props = CHANNEL_PROPS[channel];
  return props?.label || '';
};

export const getTalkingPointChannelLabel = (channel) => {
  const props = CHANNEL_PROPS[channel];
  return props?.talkingPointLabel || '';
};

export const getChannelDescription = (channel) => {
  const props = CHANNEL_PROPS[channel];
  return props?.description || '';
};

export const getChannelPerformanceLabel = (channel) => {
  const props = CHANNEL_PROPS[channel];
  return props?.performanceLabel || props?.label || '';
};

export const getChannelPerformanceShortLabel = (channel) => {
  const props = CHANNEL_PROPS[channel];
  return props?.performanceShortLabel || props?.performanceLabel || props?.label || '';
};

const BACKEND_CHANNEL = {
  hubspot: 'hubspot_emails',
  instagram: 'instagram_account',
  trustpilot: 'reviews_trustpilot',
  amazon: 'reviews_amazon',
  salesforce: 'salesforce_emails',
  marketo: 'marketo_emails',
  braze: 'braze_emails',
};

const BACKEND_CHANNELS_MAPPER = {
  [CHANNEL.hubspot]: BACKEND_CHANNEL.hubspot,
  [CHANNEL.instagram]: BACKEND_CHANNEL.instagram,
  [CHANNEL.trustpilot]: BACKEND_CHANNEL.trustpilot,
  [CHANNEL.amazon]: BACKEND_CHANNEL.amazon,
  [CHANNEL.salesforce]: BACKEND_CHANNEL.salesforce,
  [CHANNEL.marketo]: BACKEND_CHANNEL.marketo,
  [CHANNEL.braze]: BACKEND_CHANNEL.braze,
};

const FRONTEND_CHANNELS_MAPPER = {
  [BACKEND_CHANNEL.hubspot]: CHANNEL.hubspot,
  [BACKEND_CHANNEL.instagram]: CHANNEL.instagram,
  [BACKEND_CHANNEL.trustpilot]: CHANNEL.trustpilot,
  [BACKEND_CHANNEL.amazon]: CHANNEL.amazon,
  [BACKEND_CHANNEL.salesforce]: CHANNEL.salesforce,
  [BACKEND_CHANNEL.marketo]: CHANNEL.marketo,
  [BACKEND_CHANNEL.braze]: CHANNEL.braze,
};

export const alignChannelWithBackend = (channel) => BACKEND_CHANNELS_MAPPER[channel] || channel;

export const alignChannelFromBackend = (channel) => FRONTEND_CHANNELS_MAPPER[channel] || channel;

export const alignChannelForUnauthorize = (channel) =>
  [CHANNEL.facebookPages, CHANNEL.instagram].includes(channel)
    ? CHANNEL.facebook
    : alignChannelWithBackend(channel);
