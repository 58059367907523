import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_MULTI_PROMPT_TEMPLATE } from './schema';

const MUTATION = gql`
  mutation CreateCustomerMultiPromptTemplate(
    $customerId: ID!
    $workspaceId: ID
    $name: String!
    $description: String!
    $info: String
    $fields: JSON!
    $blocks: JSON!
    $status: String
    $csvId: ID
    $templateType: String
    $primaryKey: String
    $selectedHeaders: [String!]
  ) {
    createCustomerMultiPromptTemplate(
      customerId: $customerId
      workspaceId: $workspaceId
      description: $description
      info: $info
      name: $name
      fields: $fields
      blocks: $blocks
      status: $status
      csvId: $csvId
      templateType: $templateType
      primaryKey: $primaryKey
      selectedHeaders: $selectedHeaders
    ) {
      ...CustomerMultiPromptTemplate
    }
  }
  ${FRAGMENT_MULTI_PROMPT_TEMPLATE}
`;

export const createCustomerMultiPromptTemplate = async ({
  customerId,
  workspaceId,
  name,
  description,
  info,
  fields,
  blocks,
  status,
  csvId,
  templateType,
  primaryKey,
  selectedHeaders,
}) => {
  const variables = {
    customerId,
    workspaceId,
    name,
    description,
    info,
    fields,
    blocks,
    status,
    csvId,
    templateType,
    primaryKey,
    selectedHeaders,
  };

  const response = await executeGqlMutation({
    mutation: MUTATION,
    variables,
    options: {
      traceAcRequests: true,
    },
  });
  return getSingleProp(response.data);
};
