import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export default makeStyles(
  (theme) => ({
    root: {
      position: 'relative',

      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',

      '&$haveVisibleResults': {
        boxShadow: '0px 6px 16px rgba(207, 216, 238, 0.5)',

        '& $input': {
          zIndex: '2',
          borderBottomLeftRadius: '0',
          borderBottomRightRadius: '0',
        },

        '& $input $notchedOutline': {
          borderColor: 'transparent',
        },

        '& $listbox': {
          boxShadow: '0px 6px 8px rgba(23, 43, 77, 0.06)',
        },
      },
    },
    haveVisibleResults: {},

    customInput: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(16),
      padding: '0 9px',
      paddingLeft: theme.spacing(2),

      transition: theme.transitions.create('border-radius', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    input: {
      padding: theme.spacing(1.75, 0.5, 1.75, 0),
    },
    icon: {
      fontSize: theme.typography.pxToRem(16),
      color: COLORS.gray.gray40,
    },
    focused: {},
    notchedOutline: {
      transition: theme.transitions.create('border-color', {
        duration: theme.transitions.duration.shortest,
      }),
    },

    listbox: {
      position: 'absolute',
      width: '100%',
      maxHeight: 136,
      top: 52,
      zIndex: 1,
      overflow: 'auto',

      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${COLORS.neutral.lightGray}`,
      padding: 0,
    },
    listTitle: {
      padding: theme.spacing(1, 2, 0),
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: COLORS.neutral.mediumLightGray,
      textTransform: 'uppercase',
    },
    list: {
      margin: 0,
      listStyle: 'none',
      padding: theme.spacing(1),
    },
    option: {
      padding: theme.spacing(1.5, 2),
      borderRadius: theme.shape.borderRadius,
      ...theme.typography.regular14,
      color: COLORS.gray.black,
    },

    defaultOptions: {
      marginTop: theme.spacing(1.5),
    },
  }),
  { name: 'ContentTaxonomyAutocomplete' }
);
