import React, { useState, useMemo, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Box, Typography, Collapse } from '@material-ui/core';

import { ButtonText } from 'src/components/ButtonText';
import { Loader } from '../Loader';
import { useStyles } from './useStyles';

export const InsightBox = ({
  className,
  classes,
  children,
  title,
  subtitle,
  collapsableContent,
  topRightSlot,
  onExpand,
  loading,
}) => {
  const [expanded, setExpanded] = useState(false);
  const styles = useStyles({ classes, expanded });

  const hasCollapsableContent = useMemo(() => {
    if (Array.isArray(collapsableContent)) {
      return collapsableContent.length > 0;
    }

    return !!collapsableContent;
  }, [collapsableContent]);

  const handleCollapseToggle = useCallback(() => {
    if (!expanded && onExpand) {
      onExpand();
    }
    setExpanded((ex) => !ex);
  }, [expanded, onExpand]);

  if (loading) {
    return <Loader className={cx(className, styles.root, styles.loader)} />;
  }

  return (
    <Box className={cx(styles.root, className)}>
      {(title || subtitle) && (
        <div className={cx(styles.titlesWrapper, { [styles.rightMargin]: !!topRightSlot })}>
          {title && (
            <Typography
              component={'span'}
              className={styles.title}
            >
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              component={'span'}
              className={styles.subtitle}
            >
              {subtitle}
            </Typography>
          )}
        </div>
      )}

      {children}

      {hasCollapsableContent && (
        <div className={styles.collapseWrapper}>
          <Collapse
            classes={{ wrapperInner: styles.collapseWrapperInner }}
            in={expanded}
          >
            {collapsableContent}
          </Collapse>

          <ButtonText
            className={styles.expandButton}
            onClick={handleCollapseToggle}
          >
            Show {expanded ? 'less' : 'more'}
          </ButtonText>
        </div>
      )}

      {topRightSlot && <div className={styles.topRightSlot}>{topRightSlot}</div>}
    </Box>
  );
};

InsightBox.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  topRightSlot: PropTypes.node,
  collapsableContent: PropTypes.node,
  onExpand: PropTypes.func,
  loading: PropTypes.bool,
};

InsightBox.defaultProps = {
  className: null,
  classes: null,
  children: null,
  title: null,
  subtitle: null,
  topRightSlot: null,
  collapsableContent: null,
  onExpand: null,
  loading: false,
};
