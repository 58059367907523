import { useEffect } from 'react';

import {
  INCOMING_MESSAGE,
  messagePlatformReady,
  messagePlatformInfo,
  mapWorkspaceForInfinity,
  mapUserForInfinity,
  mapCustomerForInfinity,
} from 'src/data/infinity';
import * as log from 'src/lib/log';
import { sendMessageToParent, subscribeToMessages } from 'src/lib/messages';
import * as events from 'src/lib/events';
import { useUserActions, useCustomerActions, useWorkspaceActions } from 'src/store';
import { useTrackInfinityEvent } from 'src/services';
import Authentication from 'src/utils/Authentication';

export const useSetupExtensionCommunication = () => {
  const userActions = useUserActions();
  const customerActions = useCustomerActions();
  const workspaceActions = useWorkspaceActions();
  const trackInfinityEvent = useTrackInfinityEvent();

  // Set up base communication with extension
  useEffect(() => {
    /**
     * Handle incoming "check info" message from Extension
     */
    const processPlatformInfoCheckMessage = () => {
      const user = userActions.getCurrentUser();
      const customer = customerActions.getCurrentCustomer();
      const workspace = workspaceActions.getCurrentWorkspace();

      const message = messagePlatformInfo({
        authenticated: Boolean(user),
        user: user ? mapUserForInfinity(user) : null,
        customer: customer ? mapCustomerForInfinity(customer) : null,
        workspace: workspace ? mapWorkspaceForInfinity(workspace) : null,
      });
      sendMessageToParent(message);
    };

    /**
     * Log in the Platform
     */
    const processLoginMessage = async (content) => {
      try {
        await userActions.loginWithToken(content?.token);
        trackInfinityEvent(events.INFINITY.loggedIn, content.attributes, { noAuth: true });
      } catch (error) {
        // do nothing
      }
    };

    /**
     * Logout from the Platform
     */
    const processLogoutMessage = () => {
      // Don't do anything if Platform is not authenticated
      if (!Authentication.isAuthenticated()) {
        return;
      }

      userActions.logout(true);
      trackInfinityEvent(
        events.SYSTEM.logout,
        {
          reason: events.LOGOUT_REASON.extensionMessage,
        },
        { noAuth: true }
      );
    };

    /**
     * Track event from Extension
     * @param {object} content - event data
     */
    const processTrackEventMessage = (content) => {
      const name = events.prefixExtensionEventName(content.name);
      trackInfinityEvent(name, content.attributes, { noAuth: true });
    };

    /**
     * Activate customer/workspace
     */
    const processActivateWorkspaceMessage = async (content) => {
      try {
        if (content.customerId) {
          await customerActions.selectCurrentCustomer(content.customerId);
        }
        if (content.workspaceId) {
          await workspaceActions.selectCurrentWorkspace(content.workspaceId);
        }
      } catch (error) {
        // do nothing for now
      }
    };

    const handlers = {
      [INCOMING_MESSAGE.platformInfoCheck]: processPlatformInfoCheckMessage,
      [INCOMING_MESSAGE.login]: processLoginMessage,
      [INCOMING_MESSAGE.logout]: processLogoutMessage,
      [INCOMING_MESSAGE.activateWorkspace]: processActivateWorkspaceMessage,

      // deprecated - starting from from Infinity 0.0.44.1 we track events through BG script
      [INCOMING_MESSAGE.trackEvent]: processTrackEventMessage,
    };

    // Subscribe to messages from parent (Infinity)
    const unsubscribe = subscribeToMessages((message) => {
      if (!message?.data) {
        return;
      }

      const { type, content } = message.data;

      // Handle message, if handler is defined for its type
      const handler = handlers[type];
      if (handler) {
        log.message(`Received "${type}" basic message from Infinity`);
        handler(content);
      }
    });

    // Notify Extension that Platform is ready for messaging
    const message = messagePlatformReady();
    sendMessageToParent(message);

    log.message('Extension "basic" communication is set up');

    return () => {
      unsubscribe();
    };
  }, [userActions, customerActions, workspaceActions, trackInfinityEvent]);
};
