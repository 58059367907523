import { makeStyles } from '@material-ui/core';

const WEBINAR_BANNER_HEIGHT = 56;

export const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      height: '100vh',
      width: '100%',
      '@media screen and (max-width: 450px)': {
        height: '100dvh',
      },
    },

    webinarBanner: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      height: WEBINAR_BANNER_HEIGHT,
    },
    content: {
      flex: '1 1 auto',
      overflow: 'auto',
    },
    overflowHidden: {
      overflow: 'hidden',
    },
    extraMarginTop: {
      marginTop: WEBINAR_BANNER_HEIGHT,
    },
  }),
  { name: 'PrivateRouteWrapper' }
);
