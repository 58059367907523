import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useStyles } from './useStyles';

export const Loader = () => {
  const styles = useStyles();

  return (
    <>
      <TableRow
        className={styles.row}
        tabIndex={-1}
      >
        <TableCell className={styles.cell}>
          <Skeleton
            className={styles.skeleton}
            animation="wave"
            width={250}
            height={18}
          />
        </TableCell>
        <TableCell className={styles.cell}></TableCell>
        <TableCell className={styles.cell}>
          <Skeleton
            className={styles.skeleton}
            animation="wave"
            width={64}
            height={18}
          />
        </TableCell>
      </TableRow>
      <TableRow
        className={styles.row}
        tabIndex={-1}
      >
        <TableCell className={styles.cell}>
          <Skeleton
            className={styles.skeleton}
            animation="wave"
            width={135}
            height={18}
          />
        </TableCell>
        <TableCell className={styles.cell}></TableCell>
        <TableCell className={styles.cell}>
          <Skeleton
            className={styles.skeleton}
            animation="wave"
            width={64}
            height={18}
          />
        </TableCell>
      </TableRow>
      <TableRow
        className={styles.row}
        tabIndex={-1}
      >
        <TableCell className={styles.cell}>
          <Skeleton
            className={styles.skeleton}
            animation="wave"
            width={191}
            height={18}
          />
        </TableCell>
        <TableCell className={styles.cell}></TableCell>
        <TableCell className={styles.cell}>
          <Skeleton
            className={styles.skeleton}
            animation="wave"
            width={64}
            height={18}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
