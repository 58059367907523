import { makeStyles } from '@material-ui/core';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 280,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: COLORS.neutral.secondary,
      overflowY: 'hidden',
    },
    logoWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(2, 2, 1),
    },
    logo: {},
    createContentWrapper: {
      padding: theme.spacing(2),
    },
    createContentItem: {
      width: '100%',
    },
    scrollable: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: theme.spacing(0, 2),
      scrollbarColor: `${COLORS.gray.gray60} transparent`,
    },
    homeItem: {
      marginBottom: theme.spacing(0.5),
    },
    divider: {
      borderBottom: theme.shape.borderDarkGray,
      margin: theme.spacing(2, -2, 3),
    },
    folderItems: {},
    sidebarItems: {},
    workspaceItem: {
      borderTop: theme.shape.borderDarkGray,
      flexShrink: 0,
    },
  }),
  { name: 'NavigationSidebarPanel' }
);
