import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    header: {
      marginBottom: theme.spacing(0.5),
    },
    account: {
      display: 'block',
    },
  }),
  { name: 'TooltipRowsContent' }
);
