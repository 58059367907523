import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const IconNewLock = React.forwardRef((props, ref) => (
  <SvgIcon {...props} ref={ref} viewBox="0 0 16 16" data-fill-none>
    <path
      d="M11.6992 5.80029V4.15529C11.6979 3.2659 11.344 2.41331 10.7151 1.78441C10.0862 1.15551 9.23361 0.801615 8.34422 0.800293H8.05422C7.16482 0.801615 6.31223 1.15551 5.68334 1.78441C5.05444 2.41331 4.70054 3.2659 4.69922 4.15529V5.80029C4.30139 5.80029 3.91986 5.95833 3.63856 6.23963C3.35725 6.52094 3.19922 6.90247 3.19922 7.30029V11.9503C3.20054 12.7058 3.50123 13.4299 4.03542 13.9641C4.56962 14.4983 5.29376 14.799 6.04922 14.8003H10.3492C11.1047 14.799 11.8288 14.4983 12.363 13.9641C12.8972 13.4299 13.1979 12.7058 13.1992 11.9503V7.30029C13.1992 6.90247 13.0412 6.52094 12.7599 6.23963C12.4786 5.95833 12.097 5.80029 11.6992 5.80029ZM5.69922 4.15529C5.69922 3.53071 5.94733 2.9317 6.38898 2.49006C6.83063 2.04841 7.42963 1.80029 8.05422 1.80029H8.34422C8.9688 1.80029 9.56781 2.04841 10.0095 2.49006C10.4511 2.9317 10.6992 3.53071 10.6992 4.15529V5.80029H5.69922V4.15529ZM12.1992 11.9503C12.1992 12.4409 12.0043 12.9115 11.6574 13.2584C11.3104 13.6054 10.8399 13.8003 10.3492 13.8003H6.04922C5.55857 13.8003 5.08801 13.6054 4.74107 13.2584C4.39413 12.9115 4.19922 12.4409 4.19922 11.9503V7.30029C4.19922 7.16768 4.2519 7.04051 4.34567 6.94674C4.43943 6.85297 4.56661 6.80029 4.69922 6.80029H11.6992C11.8318 6.80029 11.959 6.85297 12.0528 6.94674C12.1465 7.04051 12.1992 7.16768 12.1992 7.30029V11.9503Z"
      fill="#F7F9FD"
    />
    <path
      d="M8.69922 10.16V11.8C8.69922 11.9326 8.64654 12.0598 8.55277 12.1535C8.459 12.2473 8.33183 12.3 8.19922 12.3C8.06661 12.3 7.93943 12.2473 7.84567 12.1535C7.7519 12.0598 7.69922 11.9326 7.69922 11.8V10.16C7.50858 10.0499 7.35958 9.88001 7.27534 9.67663C7.1911 9.47325 7.17632 9.24776 7.23329 9.03513C7.29027 8.82249 7.41581 8.6346 7.59046 8.50059C7.7651 8.36658 7.97908 8.29395 8.19922 8.29395C8.41935 8.29395 8.63334 8.36658 8.80798 8.50059C8.98263 8.6346 9.10817 8.82249 9.16515 9.03513C9.22212 9.24776 9.20734 9.47325 9.1231 9.67663C9.03886 9.88001 8.88986 10.0499 8.69922 10.16Z"
      fill="#F7F9FD"
    />
  </SvgIcon>
));
