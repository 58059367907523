import { TALKING_POINTS_VIEW_STATUS } from 'src/data/performance';
import { promisedWait } from 'src/lib/common';
import { AbortError, ExtractionError } from 'src/lib/errors';
import { queryTalkingPointsView } from 'src/graphql/talkingPointsViews';

const CREATION_POLLING_INTERVAL = 5000;

export const waitForTalkingPointViewCreation = async ({
  id,
  customerId,
  workspaceId,
  options,
  onResult,
}) => {
  if (options?.abortSignal?.aborted) {
    throw new AbortError();
  }

  const result = await queryTalkingPointsView({
    customerId,
    workspaceId,
    talkingPointsViewId: id,
  });
  if (!result) {
    return;
  }

  onResult(result);

  if (result?.status === TALKING_POINTS_VIEW_STATUS.active) {
    return result;
  }
  if (result?.status === TALKING_POINTS_VIEW_STATUS.archived) {
    throw new ExtractionError('Failed to create talking points view');
  }

  // Wait sometime and check again
  await promisedWait(CREATION_POLLING_INTERVAL);

  return waitForTalkingPointViewCreation({
    id,
    customerId,
    workspaceId,
    options,
    onResult,
  });
};
