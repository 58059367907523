import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: 400,
      height: 'auto',
      display: 'flex',
      flexFlow: 'column',
      backgroundColor: COLORS.neutral.white,
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0px 1px 27px rgba(31, 44, 75, 0.1)',
      zIndex: theme.zIndex.modal,
      padding: theme.spacing(3, 4),
      alignItems: 'center',
      gap: theme.spacing(2),
    },

    wide: { width: 406 },

    title: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(24),
      fontFamily: theme.typography.fontRobotoSlab,
      fontWeight: theme.typography.fontWeightSemiBold,
    },

    icon: {
      marginRight: theme.spacing(1.5),
    },

    subtitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(24),
    },

    titlesContainer: {
      gap: theme.spacing(0.5),
      display: 'flex',
      flexDirection: 'column',
    },

    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },

    arrowIcon: {
      fontSize: theme.typography.pxToRem(16),
    },
    button: {
      width: '100%',
    },
  }),
  { name: 'UpgradePopover' }
);
