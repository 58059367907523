import { useEffect } from 'react';

import Authentication from 'src/utils/Authentication';
import { useUserActions } from 'src/store';
import { useIsAuthTokenGuardActive } from 'src/store/common/useIsAuthTokenGuardActive';
import { ROUTES } from 'src/routes';
import { useHistory } from 'src/lib/hooks/useHistory';
import * as events from 'src/lib/events';
import { useTrackingEvent } from './useTrackingEvent';

const AuthTokenGuard = () => {
  const history = useHistory();
  const trackEvent = useTrackingEvent();
  const userActions = useUserActions();

  const isAuthTokenGuardActive = useIsAuthTokenGuardActive();

  useEffect(() => {
    if (!isAuthTokenGuardActive) {
      return undefined;
    }

    const listener = (oldToken, newToken) => {
      // If we didn't have old token, then this is new login - force load root page
      if (!oldToken) {
        window.location.assign(ROUTES.ROOT);
        return;
      }

      // Logout current app if 'new token' is empty - this means we were logged out on some other tab
      if (!newToken) {
        // Fire "logout" event, in "noAuth" mode, because current token is invalid already
        trackEvent(
          events.SYSTEM.logout,
          {
            reason: events.LOGOUT_REASON.authTokenGuard,
          },
          { noAuth: true }
        );

        userActions.logout(true);
        history.replace(ROUTES.SIGN_IN);
        return;
      }

      // Reload tab if 'new token' is different from previously used - this means login attempt on other tab
      if (oldToken !== newToken) {
        window.location.reload();
      }
    };

    // 'subscribe' to token changes, it returns 'unsubscribe' function,
    //   so 'effect' would clear everything on unmount
    return Authentication.subscribeToTokenChanges(listener);
  }, [isAuthTokenGuardActive, history, userActions, trackEvent]);

  return null;
};

export default AuthTokenGuard;
