import React from 'react';
import MuiCheckbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { CheckboxDefault } from 'src/assets/icons/CheckboxDefault';
import { CheckboxChecked } from 'src/assets/icons/CheckboxChecked';
import { CheckboxIndeterminate } from 'src/assets/icons/CheckboxIndeterminate';
import { useStyles } from './useStyles';

export const Checkbox = ({ iconClassName, ...props }) => {
  const styles = useStyles();

  return (
    <MuiCheckbox
      className={styles.root}
      data-testid="checkbox"
      classes={{ colorPrimary: styles.root }}
      icon={<CheckboxDefault className={iconClassName} />}
      checkedIcon={<CheckboxChecked className={iconClassName} />}
      indeterminateIcon={<CheckboxIndeterminate className={iconClassName} />}
      color="primary"
      disableRipple
      {...props}
    />
  );
};

Checkbox.propTypes = {
  iconClassName: PropTypes.string,
};

Checkbox.defaultProps = {
  iconClassName: null,
};
