import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlQuery } from 'src/graphql/client';

const QUERY = gql`
  query ProjectForNewCopies($workspaceId: ID!) {
    projectForNewCopies(workspaceId: $workspaceId) {
      id
    }
  }
`;

export const queryProjectForNewCopies = (workspaceId) => {
  return executeGqlQuery({
    query: QUERY,
    variables: {
      workspaceId,
    },
  })
    .then(({ data }) => getSingleProp(data))
    .then((result) => result);
};
