import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { usePerformanceActions, useCurrentWorkspaceId, useCurrentCustomerId } from 'src/store';
import { useNewestLastExtractionDate } from './useNewestLastExtractionDate';

/**
 * This hook is used to maintain the performance store state.
 */
export const useMaintainPerformanceStoreState = () => {
  useMaintainCommonStoreState();
  useMaintainGlobalBenchmarkState();
};

const useMaintainCommonStoreState = () => {
  const currentCustomerId = useCurrentCustomerId();
  const currentWorkspaceId = useCurrentWorkspaceId();
  const performanceActions = usePerformanceActions();

  useEffect(() => {
    if (!currentWorkspaceId) {
      return;
    }

    performanceActions.queryWorkspaceResources();
    performanceActions.queryWorkspaceAccounts();

    return () => {
      performanceActions.cancelWaitRequests();
    };
  }, [performanceActions, currentWorkspaceId]);

  useEffect(() => {
    if (!currentCustomerId) {
      return;
    }

    performanceActions.queryChannelsAuthState();
    performanceActions.queryUserIntegrationAccounts();

    return () => {
      performanceActions.cancelWaitRequests();
    };
  }, [performanceActions, currentCustomerId]);
};

const useMaintainGlobalBenchmarkState = () => {
  const currentWorkspaceId = useCurrentWorkspaceId();
  const newestLastExtractionDate = useNewestLastExtractionDate();

  const performanceActions = usePerformanceActions();

  // Debounce the global benchmark refresh to avoid too many requests
  // (in case "last extraction date" is updated multiple times in a short period of time)
  const refreshGlobalBenchmark = useDebouncedCallback(() => {
    performanceActions.queryPerformanceCenterGlobalBenchmark();
  }, 3000);

  useEffect(() => {
    if (!currentWorkspaceId) {
      return;
    }

    performanceActions.setLoadingGlobalBenchmark(true);
    // Refresh the global benchmark
    refreshGlobalBenchmark();

    // Cancel pending requests when the workspace changes
    return () => {
      refreshGlobalBenchmark.cancel();
    };
  }, [currentWorkspaceId, refreshGlobalBenchmark, newestLastExtractionDate, performanceActions]);
};
