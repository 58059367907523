export const PROJECT_CREATION_ERROR = {
  nonEnglishContent: 'CLIENT_NON_ENGLISH_CONTENT',
  notEnoughText: 'CLIENT_NOT_ENOUGH_TEXT',
  textUrlOrQuestions: 'CLIENT_TEXT_URL_OR_QUESTIONS',
  nonRelevantUrlOrQnaBrief: 'CLIENT_NON_RELEVANT_URL_OR_QNA_BRIEF',
  unsupportedLangManualBrief: 'CLIENT_UNSUPPORTED_LANG_MANUAL_BRIEF',
  clientDomainNotFound: 'CLIENT_DOMAIN_NOT_FOUND',
  unsupportedLangUrlBrief: 'CLIENT_UNSUPPORTED_LANG_URL_BRIEF',
};

export const isProjectCreationErrorAllowed = (errorCode) =>
  [
    PROJECT_CREATION_ERROR.notEnoughText,
    PROJECT_CREATION_ERROR.textUrlOrQuestions,
    PROJECT_CREATION_ERROR.nonRelevantUrlOrQnaBrief,
  ].includes(errorCode);

export class ProjectCreationError extends Error {
  constructor(code, message) {
    super(message);
    this.name = 'ProjectCreationError';
    this.code = code;
    this.message = message;
  }
}
