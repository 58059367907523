import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@material-ui/core';

import { FieldLabel } from 'src/components/FieldLabel';
import { StonlyIcon } from 'src/components/StonlyIcon';
import useStyles from './useStyles';

export const FieldHeader = ({
  classes,
  className,
  label,
  subtitle,
  hint,
  isHintQuestion,
  titleStonlyId,
  isOptional,
  LabelProps,
  ...restProps
}) => {
  const styles = useStyles({ classes });

  return (
    <div
      className={cx(styles.root, className)}
      {...restProps}
    >
      <FieldLabel
        className={styles.label}
        label={label}
        hint={hint}
        isQuestion={isHintQuestion}
        isOptional={isOptional}
        {...LabelProps}
      />

      {subtitle && <Typography className={styles.subtitle}>{subtitle}</Typography>}

      {titleStonlyId && <StonlyIcon stonlyId={titleStonlyId} />}
    </div>
  );
};

FieldHeader.propTypes = {
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.string,
  isHintQuestion: PropTypes.bool,
  isOptional: PropTypes.bool,
  titleStonlyId: PropTypes.string,
  LabelProps: PropTypes.shape(),
};

FieldHeader.defaultProps = {
  classes: null,
  className: null,
  hint: null,
  label: '',
  subtitle: '',
  isHintQuestion: false,
  isOptional: false,
  titleStonlyId: '',
  LabelProps: null,
};
