import { getChannelPerformanceLabel } from 'src/data/channel';
import { STORAGE_KEY_OAUTH_DATA } from 'src/data/storage';
import { parseJson } from 'src/lib/common';
import { ChannelAuthError } from 'src/lib/errors';
import * as storage from 'src/lib/storage';
import { openPopup } from 'src/utils/popup';

const POPUP_OPTIONS = {
  name: 'integration-popup',
  width: 600,
  height: 800,
};

const openIntegrationPopup = (authUrl, popupOptions = {}) =>
  new Promise((resolve, reject) => {
    const resultHandler = (authData) => {
      popup.close();

      if (!authData) {
        reject();
        return;
      }

      if (authData.errorMessage) {
        reject(new Error(authData.errorMessage));
        return;
      }

      resolve(authData);
    };

    const handleStorageEvent = (event) => {
      if (event.key === STORAGE_KEY_OAUTH_DATA) {
        const authData = parseJson(event.newValue);
        if (authData) {
          window.removeEventListener('storage', handleStorageEvent);
          storage.removeItem(STORAGE_KEY_OAUTH_DATA);
          resultHandler(authData);
        }
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    // Open integration popup
    const popup = openPopup(authUrl, { ...POPUP_OPTIONS, ...popupOptions });
    if (!popup) {
      reject(new Error('Cannot open pop-up. Redirecting…'));
      window.removeEventListener('storage', handleStorageEvent);
      return;
    }
    popup.focus();
  });

export const authorizeIntegration = async (authUrl, channel) => {
  const authData = await openIntegrationPopup(authUrl);

  if (authData?.code) {
    return authData;
  } else {
    return new ChannelAuthError(
      `Error in ${getChannelPerformanceLabel(channel)} Authorization: No auth code`
    );
  }
};
