import gql from 'graphql-tag';

import { getSingleProp } from 'src/lib/graphql';
import { executeGqlMutation } from 'src/graphql/client';
import { FRAGMENT_CO_VARIANT } from './schema';

const MUTATION = gql`
  mutation updateCOVariant($id: ID!, $status: String!) {
    updateContinuousOptimizationVariantStatus(id: $id, status: $status) {
      ...ContinuousOptimizationVariant
    }
  }
  ${FRAGMENT_CO_VARIANT}
`;

export const updateCOVariantStatus = (id, status) => {
  return executeGqlMutation({
    mutation: MUTATION,
    variables: { id, status },
  }).then(({ data }) => getSingleProp(data));
};
