import { useMemo } from 'react';

import { useCustomerSubscriptionInfo } from 'src/store';
import {
  getSubscriptionPlanFeatures,
  getTierOrPlanProperty,
  isBasicPlan,
} from 'src/data/subscription';
import { formatNumber } from 'src/utils/format';

// Get full list of features
export const usePlanFeatures = (plan) => {
  return useMemo(() => getSubscriptionPlanFeatures(plan), [plan]);
};

export const useDowngradePlanFeatures = (plan, tier) => {
  const { currentPlan, currentTier } = useCustomerSubscriptionInfo();

  // Subscription "words limit"
  const currentPlanFeatures = usePlanFeatures(currentPlan);
  const newPlanFeatures = usePlanFeatures(plan);

  const currentPlanWordsLimit = getTierOrPlanProperty(currentPlan, currentTier, 'wordsLimit', 0);
  const newPlanWordsLimit = getTierOrPlanProperty(plan, tier, 'wordsLimit', 0);

  const mappedFeatures = currentPlanFeatures.map((currentPlanFeature) => {
    if (currentPlanFeature.wordCreditsFeature && newPlanWordsLimit >= currentPlanWordsLimit) {
      return false;
    }

    if (currentPlanFeature.seatsLimitFeature) {
      return isBasicPlan(plan)
        ? { text: 'Multiple users - all of your teammates will be removed' }
        : false;
    }

    const isSameFeatureInNewPlan = newPlanFeatures.some(
      (newPlanFeature) => currentPlanFeature.text === newPlanFeature.text
    );

    if (isSameFeatureInNewPlan) {
      return false;
    }

    return currentPlanFeature;
  });
  const filteredFeatures = mappedFeatures.filter((feature) => feature);

  return {
    features: filteredFeatures,
    textFormatObject: {
      wordsLimit: formatNumber(currentPlanWordsLimit),
    },
  };
};
