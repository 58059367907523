import { parseJson } from 'src/lib/common';

export const prepareUser = ({ accounts, companyInformation, ...rest }) => {
  const user = {
    ...rest,
    companyInformation: parseJson(companyInformation),
  };

  user.customers = accounts
    ? accounts.edges.map((edge) => ({
        role: edge.role,
        ...edge.node,
      }))
    : [];

  return user;
};
