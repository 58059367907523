export const PROMPT_MENU_TYPE = {
  url: 'url',
  product: 'product',
  default: 'default',
  bank: 'bank',
  image: 'image',
  document: 'document',
  all: 'all',
};

export const PROMPT_TAG_SEARCH = {
  url: 'url',
  link: 'link',
  productDetails: 'product details',
  productDescription: 'product description',
};
