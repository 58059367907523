import React, { forwardRef, memo } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ThreeDotsIcon = memo(
  forwardRef((props, ref) => {
    return (
      <SvgIcon
        {...props}
        ref={ref}
        viewBox="0 0 24 24"
      >
        <circle
          cx="18"
          cy="12"
          r="1.75"
          fill="currentColor"
        />
        <circle
          cx="12"
          cy="12"
          r="1.75"
          fill="currentColor"
        />
        <circle
          cx="6"
          cy="12"
          r="1.75"
          fill="currentColor"
        />
      </SvgIcon>
    );
  })
);
