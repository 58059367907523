import { createStateSelectorHook } from 'src/lib/sweetState';
import { SOCIAL_AUTH_STATE } from 'src/data/user';
import { PerformanceStore } from './PerformanceStore';
import { CHANNEL } from 'src/data/channel';

const usePerformanceState = createStateSelectorHook(PerformanceStore, ['authState']);

export const useIsChannelConnected = (channel) => {
  const { authState } = usePerformanceState();

  const alignedChannel = channel === CHANNEL.facebookPages ? CHANNEL.facebook : channel;

  return authState[alignedChannel] === SOCIAL_AUTH_STATE.authorized;
};
