import { makeStyles } from '@material-ui/core/styles';

import { COLORS } from 'src/data/ui';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'start',
    },
    iconArrow: {
      margin: theme.spacing(0, 0.75),
      fontSize: theme.typography.pxToRem(14),
      strokeWidth: 2.5,
      color: COLORS.gray.gray30,
    },
    termText: {},
  }),
  { name: 'ReplacementTerms' }
);
